import cn from 'classnames';
import Select from 'components/select';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_TEAM_OPTION } from './constants';
import './styles.scss';

const teamsToOptions = teams => {
  const options = teams.map(team => ({ value: team.id, label: team.name }));
  return [DEFAULT_TEAM_OPTION, ...options];
};

const TeamFilter = ({ isDisabled, onChange, onSearch, selected, teams }) => {
  const { teamProvider } = useContext(AdvisorContext);

  useEffect(() => {
    teamProvider.getList();
  }, []);

  const onChangeHandler = team => {
    teamProvider.setSelectedTeam(team);
    if (onChange) onChange(team.value);
    if (onSearch) {
      const params = { page: 1 };
      if (team.value !== 0) params.team = team.value;
      onSearch(params);
    }
  };

  return (
    <div className={cn({ 'team-filter-disabled': isDisabled })}>
      <Select onChange={onChangeHandler} options={teamsToOptions(teams)} value={selected} />
    </div>
  );
};

TeamFilter.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  selected: PropTypes.number,
  teams: PropTypes.arrayOf(PropTypes.object)
};

TeamFilter.defaultProps = {
  isDisabled: false,
  onChange: null,
  onSearch: null,
  selected: DEFAULT_TEAM_OPTION,
  teams: []
};

export default connect(state => ({
  teams: state.teams.list,
  selected: state.teams.selected
}))(TeamFilter);
