import Disclosure from 'components/disclosure';
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { LANGUAGE_URL_PARAM } from 'lang/constants';
import { getLocale } from 'lang/utils';
import PropTypes from 'prop-types';
import InAppProvider from 'providers/in-app';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getWidgetUrl } from 'utils/utils';
import './styles.scss';

const ContentStep = ({ step }) => (
  <div className="content-step">
    <img src={`/img/prism-questions-guest/screenshot-${step}.png`} alt={`Step #${step}`} />
    <div>
      <div className="content-step__position">{step}</div>
      <div className="content-step__description">
        <h3>
          <FormattedMessage id={`rtq.intro.step-${step}.title`} />
        </h3>
        <span>
          <FormattedMessage id={`rtq.intro.step-${step}.description`} />
        </span>
      </div>
    </div>
  </div>
);

ContentStep.propTypes = {
  step: PropTypes.number.isRequired
};

export const PrismQuestionsGuest = ({ inAppProvider, params: { advisorId, widgetToken } }) => {
  const [advisor, setAdvisor] = useState(null);
  const params = new URLSearchParams(window.location.search);

  const getGuest = async () => {
    const { data } = await inAppProvider.getGuest(widgetToken, advisorId);
    if (data?.id && data?.token)
      // reload to force the app to load user from the session token in the URL
      document.location.href = getWidgetUrl(data.id, {
        advisor: advisorId,
        [LANGUAGE_URL_PARAM]: getLocale(),
        [QUESTIONNAIRE_TEMPLATE_URL_PARAM]: params.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM),
        session: data.token,
        source: params.get('source'),
        step: 'questions',
        'widget-token': widgetToken
      });
    else toast.error(() => <FormattedMessage id="common.general-error" />);
  };

  const getAdvisor = async () => {
    const { data } = await inAppProvider.getAdvisor(widgetToken, advisorId);
    setAdvisor(data);
  };

  useEffect(() => {
    getAdvisor();
  }, [widgetToken, advisorId]);

  return (
    <div className="prism-questions-guest prism-questions-container prism-questions-begin-container prism-target-questionnaire-start">
      <div className="prism-questions-title">
        <img
          src={advisor?.company?.logo || '/img/logos/stratifi-logo.svg'}
          alt={advisor?.company?.logo ? advisor.company.name : 'StratiFi'}
        />
        <h1>
          <FormattedMessage id="rtq.intro.title" />
        </h1>
        <p>
          <FormattedMessage id="rtq.intro.subtitle" />
        </p>
        <button type="button" className="btn btn-primary btn-lg" onClick={getGuest}>
          <FormattedMessage id="rtq.common.get-started" />
        </button>
      </div>

      <div className="prism-questions-content">
        <h2>
          <FormattedMessage id="rtq.intro.how-it-works" />
        </h2>
        <div className="prism-questions-content__steps">
          <ContentStep step={1} />
          <ContentStep step={2} />
          <ContentStep step={3} />
        </div>
      </div>

      <Disclosure />
    </div>
  );
};

PrismQuestionsGuest.propTypes = {
  inAppProvider: PropTypes.object.isRequired,
  params: PropTypes.shape({
    advisorId: PropTypes.string.isRequired,
    widgetToken: PropTypes.string.isRequired
  }).isRequired
};

export default connect(
  null,
  dispatch => ({ inAppProvider: new InAppProvider({ dispatch }) })
)(PrismQuestionsGuest);
