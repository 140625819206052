import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React from 'react';

const DynamicTableActionButton = ({ disabled, height, iconId, label, onClick }) => {
  const tooltipId = `header-action-${iconId}`;
  return (
    <TooltipV2
      className="dynamic-table-header-action"
      id={tooltipId}
      label={label}
      place="top"
      renderTooltip={!!label}
    >
      <div data-for={tooltipId} data-tip="">
        <button
          aria-label={label}
          className="btn btn-link text-dark dynamic-table-header-action-btn"
          disabled={disabled}
          onClick={onClick}
          type="button"
        >
          <img src={`/img/icons/dynamic-table/${iconId}.svg`} alt={label} style={{ height }} />
        </button>
      </div>
    </TooltipV2>
  );
};

DynamicTableActionButton.propTypes = {
  disabled: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconId: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

DynamicTableActionButton.defaultProps = {
  disabled: false,
  height: 15,
  label: ''
};

export default DynamicTableActionButton;
