import { CustomerSupportEmailLink } from 'constants/contact';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { getPriceName, isYearlyPrice } from 'utils/billing';
import { withCurrencyFormat } from 'utils/utils';
import PromoCode from '../promo-code';
import './styles.scss';

const CheckoutPaymentSummary = ({
  onChangeSelectedPrice,
  onApplyPromoCode,
  onClearPromoCode,
  prices,
  promoCode,
  selectedPrice
}) => {
  const trialDays = selectedPrice.trial_days || 0;
  const freeTrialEndDate = moment()
    .add(trialDays, 'days')
    .format('ll');

  const monthlyEquivalentPrice =
    selectedPrice && isYearlyPrice(selectedPrice)
      ? prices.find(
          price => price.name === selectedPrice.name && price.recurring?.interval === 'month'
        )
      : null;

  return (
    <div id="CheckoutPaymentSummary">
      <div className="checkout-summary">
        <div className="checkout-summary__row">
          <p>Selected plan</p>
          <p className="text-primary text-capitalize font-weight-bold">
            {getPriceName(selectedPrice)} (
            <button
              type="button"
              className="btn btn-link change-selected-price"
              onClick={onChangeSelectedPrice}
            >
              Change
            </button>
            )
          </p>
        </div>
        <div className="checkout-summary__row">
          <p>Amount (first charge on {freeTrialEndDate})</p>
          <p className="text-primary font-weight-bold">
            {withCurrencyFormat(selectedPrice.amount)}
            {isYearlyPrice(selectedPrice) ? '/yr' : '/mo'}
          </p>
        </div>
        {monthlyEquivalentPrice && (
          <div className="checkout-summary__row">
            <p>Discount (Yearly Plan promotion)</p>
            <p className="text-primary font-weight-bold">
              {withCurrencyFormat(selectedPrice.amount - monthlyEquivalentPrice.amount * 12)}
            </p>
          </div>
        )}

        <PromoCode promoCode={promoCode} onApply={onApplyPromoCode} onClear={onClearPromoCode} />
      </div>
      <div className="checkout-total">
        <div className="checkout-total__row">
          <p>{`Total${trialDays ? ' (after free trial)' : ''}`}</p>
          <p>{withCurrencyFormat(selectedPrice.amount)}</p>
        </div>
      </div>
      <div className="checkout-disclaimer">
        <p>
          {trialDays
            ? `After your free trial ends on ${freeTrialEndDate} you will be charged `
            : 'You will be charged '}
          the full price minus any applicable discounts. When your subscription renews you will be
          charged the full amount, unless you cancel your membership in accordance with the StratiFi{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.stratifi.com/terms">
            Terms of Use
          </a>
          . For questions please contact us at <CustomerSupportEmailLink />.
        </p>
      </div>
    </div>
  );
};

CheckoutPaymentSummary.defaultProps = {
  prices: [],
  promoCode: null,
  selectedPrice: null
};

CheckoutPaymentSummary.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.object),
  onChangeSelectedPrice: PropTypes.func.isRequired,
  onApplyPromoCode: PropTypes.func.isRequired,
  onClearPromoCode: PropTypes.func.isRequired,
  promoCode: PropTypes.object,
  selectedPrice: PropTypes.object
};

export default CheckoutPaymentSummary;
