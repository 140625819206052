/* eslint-disable no-restricted-syntax, no-await-in-loop */
import PDFMerger from 'pdf-merger-js';

export const getInvestorReportCoverData = async (
  investor,
  investorProvider,
  prospectProvider,
  params = {}
) => {
  if (investor?.id) {
    const provider = investor.is_prospect ? prospectProvider : investorProvider;
    return provider.getReportCoverData(investor.id, params);
  }
  return {};
};

export const getReportMetadata = (title, subtitle) => ({
  title: subtitle ? `${title} for ${subtitle}` : title,
  author: 'StratiFi'
});

/**
 * Generate a single report from the downloaded external documents, and subsequently
 * adjust the metadata required by the final document.
 *
 * @param {element} report
 * @param {number} totalPages
 * @param {object} attachments
 * @param {array} downloadedExternalDocuments
 * @param {func} setDownloadedExternalDocuments
 * @param {object} metadata
 * @returns
 */
export const generateReport = async (
  report,
  totalPages,
  attachments,
  downloadedExternalDocuments,
  setDownloadedExternalDocuments,
  metadata
) => {
  // Starts downloading all the external documents (if needed)
  const externalDocuments = [];
  for (const attachmentKey of Object.keys(attachments)) {
    const attachment = attachments[attachmentKey];
    const externalDocumentAlreadyDownloaded = downloadedExternalDocuments.find(
      d => d.url === attachment.url
    );

    let arrayBuffer;
    if (externalDocumentAlreadyDownloaded)
      arrayBuffer = externalDocumentAlreadyDownloaded.arrayBuffer;
    else
      try {
        const response = await fetch(attachment.url);
        arrayBuffer = await response.arrayBuffer();
      } catch {
        continue;
      }

    externalDocuments.push({
      arrayBuffer,
      pageNumber: attachment.pageNumber,
      url: attachment.url
    });
  }
  setDownloadedExternalDocuments(externalDocuments);

  // Verify if the base report can be downloaded as the ObjectURL may be invalid
  // due to the number of re-renders
  let reportArrayBuffer;
  try {
    const response = await fetch(report);
    reportArrayBuffer = await response.arrayBuffer();
  } catch {
    return null;
  }

  try {
    // Starts merging all the parts involved in the generation of the final document
    let fromPageNumber = 1;
    const merger = new PDFMerger();
    for (const externalDocument of externalDocuments) {
      await merger.add(reportArrayBuffer, `${fromPageNumber}-${externalDocument.pageNumber}`);
      await merger.add(externalDocument.arrayBuffer);
      fromPageNumber = externalDocument.pageNumber + 1;
    }
    await merger.add(reportArrayBuffer, `${fromPageNumber}-${totalPages}`);

    // Sets the metadata of the document (title, author)
    await merger.setMetadata(metadata);

    const mergedDocument = await merger.saveAsBlob();
    return URL.createObjectURL(mergedDocument);
  } catch {
    return null;
  }
};
