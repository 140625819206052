// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-providers-btn-style, .social-providers #microsoft-signin > button, .social-providers #google-signin > button {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 41px;
  padding: 0 12px;
  color: #5e5e5e;
  background: white;
  border: 1px solid #8c8c8c;
  font-size: 15px;
  gap: 12px;
}

.social-providers {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.social-providers > div {
  display: flex;
  flex: 0 1 210px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/signin-form/social-auth/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,yBAAA;EACA,eAAA;EACA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AADF;AAGE;EACE,aAAA;EACA,eAAA;AADJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.social-providers-btn-style {\n  display: flex;\n  flex-grow: 1;\n  align-items: center;\n  justify-content: center;\n  height: 41px;\n  padding: 0 12px;\n  color: #5e5e5e;\n  background: white;\n  border: 1px solid #8c8c8c;\n  font-size: 15px;\n  gap: 12px;\n}\n\n.social-providers {\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n\n  & > div {\n    display: flex;\n    flex: 0 1 210px;\n  }\n\n  // Sign-In Branding Guidelines\n  // https://developers.google.com/identity/branding-guidelines\n  #google-signin > button {\n    @extend .social-providers-btn-style;\n  }\n\n  // Pictogram and “Sign in with Microsoft”\n  // https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-apps#pictogram-and-sign-in-with-microsoft\n  #microsoft-signin > button {\n    @extend .social-providers-btn-style;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
