import { PDFViewer } from '@react-pdf/renderer';
import IPSReport from 'components/advisor/ips/report';
import ProposalReport from 'components/advisor/proposal/report';
import AccountOrModelPrismReport from 'components/advisor/risk-analysis/report/account-or-model-prism';
import InvestorPrismReport from 'components/advisor/risk-analysis/report/investor-prism';
import RiskToleranceQuestionnaireCombinedReport from 'containers/risk-tolerance-questionnaire/report/combined';
import RiskToleranceQuestionnaireSingleReport from 'containers/risk-tolerance-questionnaire/report/single';
import PropTypes from 'prop-types';
import UserProvider from 'providers/user';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  ORIENTATIONS,
  PORTRAIT_ORIENTATION,
  PORTRAIT_ORIENTATION_OPTION,
  SIZES,
  SIZE_A4,
  SIZE_A4_OPTION
} from '../constants';
import EmptyReport from '../empty';
import {
  ipsProps,
  prismAccountProps,
  prismInvestorProps,
  prismModelProps,
  proposalProps,
  rtqCombinedProps,
  rtqSingleProps
} from './mocks';
import './styles.scss';

const EMPTY_REPORT = { label: 'Empty Report', value: 0, component: EmptyReport };
const PROPOSAL_REPORT = { label: 'Proposal Report', value: 1, component: ProposalReport };
const IPS_REPORT = { label: 'IPS Report', value: 2, component: IPSReport };
const ACCOUNT_PRISM_REPORT = {
  label: 'Account PRISM Report',
  value: 3,
  component: AccountOrModelPrismReport
};
const MODEL_PRISM_REPORT = {
  label: 'Model PRISM Report',
  value: 4,
  component: AccountOrModelPrismReport
};
const INVESTOR_PRISM_REPORT = {
  label: 'Investor PRISM Report',
  value: 5,
  component: InvestorPrismReport
};
const RTQ_SINGLE_REPORT = {
  label: 'RTQ Single Report',
  value: 6,
  component: RiskToleranceQuestionnaireSingleReport
};
const RTQ_COMBINED_REPORT = {
  label: 'RTQ Combined Report',
  value: 7,
  component: RiskToleranceQuestionnaireCombinedReport
};

const REPORTS = [
  EMPTY_REPORT,
  PROPOSAL_REPORT,
  IPS_REPORT,
  ACCOUNT_PRISM_REPORT,
  MODEL_PRISM_REPORT,
  INVESTOR_PRISM_REPORT,
  RTQ_SINGLE_REPORT,
  RTQ_COMBINED_REPORT
];

const DevelopmentReportViewer = ({ user, userProvider }) => {
  const [report, setReport] = useState(RTQ_SINGLE_REPORT);
  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);
  const [size, setSize] = useState(SIZE_A4);
  const [, setTotalPages] = useState(0);

  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  let reportProps = {
    collapsedHoldings: [],
    collapsedRegions: [],
    collapsedSectors: [],
    collapsedTopHoldings: [],
    collapsedStyles: [],
    orientation,
    setTotalPages,
    size,
    user,
    withCustomBranding
  };
  if (report.label === PROPOSAL_REPORT.label) reportProps = { ...reportProps, ...proposalProps };
  if (report.label === IPS_REPORT.label) reportProps = { ...reportProps, ...ipsProps };
  if (report.label === ACCOUNT_PRISM_REPORT.label)
    reportProps = { ...reportProps, ...prismAccountProps };
  if (report.label === MODEL_PRISM_REPORT.label)
    reportProps = { ...reportProps, ...prismModelProps };
  if (report.label === INVESTOR_PRISM_REPORT.label)
    reportProps = { ...reportProps, ...prismInvestorProps };
  if (report.label === RTQ_SINGLE_REPORT.label) reportProps = { ...reportProps, ...rtqSingleProps };
  if (report.label === RTQ_COMBINED_REPORT.label)
    reportProps = { ...reportProps, ...rtqCombinedProps };

  const handleOnChangeOrientation = ({ value }) => {
    setOrientation(value);
  };

  const handleOnChangeSize = ({ value }) => {
    setSize(value);
  };

  const handleOnChangeReport = report => {
    setReport({ ...report });
  };

  const ReportComponent = report.component;

  return (
    <div className="reports-viewer">
      <div className="reports-viewer__selector">
        <Select
          defaultValue={RTQ_SINGLE_REPORT}
          isSearchable={false}
          onChange={handleOnChangeReport}
          options={REPORTS}
        />
        <Select
          defaultValue={PORTRAIT_ORIENTATION_OPTION}
          isSearchable={false}
          onChange={handleOnChangeOrientation}
          options={ORIENTATIONS}
        />
        <Select
          defaultValue={SIZE_A4_OPTION}
          isSearchable={false}
          onChange={handleOnChangeSize}
          options={SIZES}
        />
      </div>
      <PDFViewer className="reports-viewer__content">
        <ReportComponent {...reportProps} />
      </PDFViewer>
    </div>
  );
};

DevelopmentReportViewer.propTypes = {
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

export default connect(
  state => ({ user: state.auth.user }),
  dispatch => ({
    userProvider: new UserProvider({ dispatch })
  })
)(DevelopmentReportViewer);
