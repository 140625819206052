import { MODEL_PROPOSAL_TYPE, SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import { digestContent } from 'components/advisor/templates/meta/editor-options';
import { PROPOSAL_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';
import Dinero from 'dinero.js';
import _ from 'lodash';
import { getTargetInvestors } from 'utils/utils';

const prepareMetadata = (proposal, user, proposalType) => {
  const metadata = _.cloneDeep(
    proposal.template_content || proposal.template.content || PROPOSAL_DEFAULT_TEMPLATE
  );

  const data = {
    advisor: { user },
    company: { name: user.advisor.company_name || user.advisor.company.name },
    target: { name: proposal.target.display_name },
    fees: {
      target: `${(proposal.target_management_fee * 100).toFixed(2)}%`,
      recommended: `${(proposal.recommended_management_fee * 100).toFixed(2)}%`,
      benchmark: `${(proposal.benchmark_management_fee * 100).toFixed(2)}%`
    }
  };

  if (proposalType !== MODEL_PROPOSAL_TYPE && proposalType !== SECURITY_PROPOSAL_TYPE) {
    data.investors = getTargetInvestors(proposal);
    data.target.value = Dinero({
      amount: parseInt(parseFloat(proposal.target.value) * 100, 10)
    }).toFormat('$0,0');
    data.tolerance = proposal.target.target_score_summary?.overall;
  }

  // replace metadata tags with real content
  _.forOwn(metadata, (properties, key) => {
    _.forOwn(properties, (value, property) => {
      if (typeof value === 'string') metadata[key][property] = digestContent(value, data);
    });
  });

  return metadata;
};

export default prepareMetadata;
