export const SECURITY_TYPE_CONCENTRATION_CHART_ID = 'securityTypeConcentrationChart';

const OWNERSHIP_INSTRUMENTS = 'Ownership Instruments';
const DEBT_INSTRUMENTS = 'Debt Instruments';
const DERIVATIVE_INSTRUMENTS = 'Derivative Instruments';
const FUNDS_AND_INVESTMENT_PRODUCTS = 'Funds and Investment Products';
const ALTERNATIVE_INVESTMENTS = 'Alternative Investments';
const CASH_AND_CASH_EQUIVALENTS = 'Cash and Cash Equivalents';
const CUSTOM_SECURITIES = 'Custom Securities';
const MISCELLANEOUS = 'Miscellaneous';

export const SECURITY_TYPE_CATEGORIES = [
  OWNERSHIP_INSTRUMENTS,
  DEBT_INSTRUMENTS,
  DERIVATIVE_INSTRUMENTS,
  FUNDS_AND_INVESTMENT_PRODUCTS,
  ALTERNATIVE_INVESTMENTS,
  CASH_AND_CASH_EQUIVALENTS,
  CUSTOM_SECURITIES,
  MISCELLANEOUS
];

const SECURITY_TYPE_COMMON_STOCK = 'Common Stock';
const SECURITY_TYPE_ETP = 'ETP';
const SECURITY_TYPE_MUTUAL_FUND = 'Mutual Fund';
const SECURITY_TYPE_BOND = 'Bond';
const SECURITY_TYPE_CASH = 'Cash';
const SECURITY_TYPE_PRIVATE_INVESTMENT = 'Private Investment';
const SECURITY_TYPE_CUSTOM_SECURITY = 'Custom Security';
const SECURITY_TYPE_ANNUITY = 'Annuity';
const SECURITY_TYPE_OPTION = 'Option';
const SECURITY_TYPE_STRUCTURED_PRODUCT = 'Structured Product';
const SECURITY_TYPE_FUTURE = 'Future';
const SECURITY_TYPE_PREFERRED_STOCK = 'Preferred Stock';
const SECURITY_TYPE_ETF = 'ETF';
const SECURITY_TYPE_ADR = 'ADR';
const SECURITY_TYPE_CD = 'Certificate of Deposit';
const SECURITY_TYPE_TACTICAL_STRATEGY = 'Tactical Strategy';
export const SECURITY_TYPE_OTHER = 'Other';

const SECURITY_TYPE_COMMON_STOCK_COLOR = '#EBD1D2';
const SECURITY_TYPE_ETP_COLOR = '#BAA434';
const SECURITY_TYPE_MUTUAL_FUND_COLOR = '#5AA1ED';
const SECURITY_TYPE_BOND_COLOR = '#ECB75A';
const SECURITY_TYPE_CASH_COLOR = '#4DA1A0';
const SECURITY_TYPE_PRIVATE_INVESTMENT_COLOR = '#1D5DA1';
const SECURITY_TYPE_CUSTOM_SECURITY_COLOR = '#A1762D';
const SECURITY_TYPE_ANNUITY_COLOR = '#9AB048';
const SECURITY_TYPE_OPTION_COLOR = '#BCD5E5';
const SECURITY_TYPE_STRUCTURED_PRODUCT_COLOR = '#BCD5CC';
const SECURITY_TYPE_FUTURE_COLOR = '#8BCDE6';
const SECURITY_TYPE_PREFERRED_STOCK_COLOR = '#55EED';
const SECURITY_TYPE_ETF_COLOR = '#ECDF6C';
const SECURITY_TYPE_ADR_COLOR = '#878269';
const SECURITY_TYPE_CD_COLOR = '#F78E52';
const SECURITY_TYPE_TACTICAL_STRATEGY_COLOR = '#E580FF';
const SECURITY_TYPE_OTHER_COLOR = '#7C7C7C';

export const SECURITY_TYPES = {
  // Ownership Instruments
  [SECURITY_TYPE_COMMON_STOCK]: {
    category: OWNERSHIP_INSTRUMENTS,
    color: SECURITY_TYPE_COMMON_STOCK_COLOR
  },
  [SECURITY_TYPE_PREFERRED_STOCK]: {
    category: OWNERSHIP_INSTRUMENTS,
    color: SECURITY_TYPE_PREFERRED_STOCK_COLOR
  },
  [SECURITY_TYPE_ADR]: {
    category: OWNERSHIP_INSTRUMENTS,
    color: SECURITY_TYPE_ADR_COLOR
  },

  // Debt Instruments
  [SECURITY_TYPE_BOND]: { category: DEBT_INSTRUMENTS, color: SECURITY_TYPE_BOND_COLOR },
  [SECURITY_TYPE_CD]: { category: DEBT_INSTRUMENTS, color: SECURITY_TYPE_CD_COLOR },

  // Derivative Instruments
  [SECURITY_TYPE_OPTION]: { category: DERIVATIVE_INSTRUMENTS, color: SECURITY_TYPE_OPTION_COLOR },
  [SECURITY_TYPE_FUTURE]: { category: DERIVATIVE_INSTRUMENTS, color: SECURITY_TYPE_FUTURE_COLOR },

  // Funds and Investment Products
  [SECURITY_TYPE_ETP]: {
    category: FUNDS_AND_INVESTMENT_PRODUCTS,
    color: SECURITY_TYPE_ETP_COLOR
  },
  [SECURITY_TYPE_MUTUAL_FUND]: {
    category: FUNDS_AND_INVESTMENT_PRODUCTS,
    color: SECURITY_TYPE_MUTUAL_FUND_COLOR
  },
  [SECURITY_TYPE_ETF]: {
    category: FUNDS_AND_INVESTMENT_PRODUCTS,
    color: SECURITY_TYPE_ETF_COLOR
  },
  [SECURITY_TYPE_STRUCTURED_PRODUCT]: {
    category: FUNDS_AND_INVESTMENT_PRODUCTS,
    color: SECURITY_TYPE_STRUCTURED_PRODUCT_COLOR
  },

  // Alternative Investments
  [SECURITY_TYPE_PRIVATE_INVESTMENT]: {
    category: ALTERNATIVE_INVESTMENTS,
    color: SECURITY_TYPE_PRIVATE_INVESTMENT_COLOR
  },
  [SECURITY_TYPE_ANNUITY]: {
    category: ALTERNATIVE_INVESTMENTS,
    color: SECURITY_TYPE_ANNUITY_COLOR
  },
  [SECURITY_TYPE_TACTICAL_STRATEGY]: {
    category: ALTERNATIVE_INVESTMENTS,
    color: SECURITY_TYPE_TACTICAL_STRATEGY_COLOR
  },

  // Cash and Cash Equivalents
  [SECURITY_TYPE_CASH]: {
    category: CASH_AND_CASH_EQUIVALENTS,
    color: SECURITY_TYPE_CASH_COLOR
  },

  // Custom Securities
  [SECURITY_TYPE_CUSTOM_SECURITY]: {
    category: CUSTOM_SECURITIES,
    color: SECURITY_TYPE_CUSTOM_SECURITY_COLOR
  },

  // Miscellaneous
  [SECURITY_TYPE_OTHER]: { category: MISCELLANEOUS, color: SECURITY_TYPE_OTHER_COLOR }
};
