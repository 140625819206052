/* eslint-disable no-underscore-dangle */
import CacheManager from 'utils/caching';
import Storage from '../utils/storage';
import ajaxProvider from './ajax';

class DataProvider {
  constructor({
    dispatch,
    options = {},
    provider = ajaxProvider,
    storage = Storage,
    cacheManager = CacheManager
  }) {
    const { action, suffix, prefix, params } = options;

    this._dispatch = dispatch;
    this._storage = storage;
    this._cacheManager = cacheManager;

    provider.configure({ dispatch, options, storage, provider });
    this._provider = provider;

    this._actionPrefix = prefix ? `${prefix.toUpperCase()}_` : '';
    this._actionSuffix = suffix ? `_${suffix.toUpperCase()}` : '';
    this._action = action != undefined ? !!action : true;

    this._params = { page: 1, page_size: 20, ...params };
  }

  get provider() {
    return this._provider;
  }

  get storage() {
    return this._storage;
  }

  get params() {
    return this._params;
  }

  _isObject = data =>
    // probably the best solution
    Object.prototype.toString.call(data) == '[object Object]';

  _isArray = data =>
    // probably the best solution
    Array.isArray(data);

  form(data, path = '') {
    // experimental
    // A. flatten data for ReduxForm digestion
    // example: {a: 'A', b: {b1: 'B1', b2: 'B2'}} -> {a: 'A', b__b1: 'B1', b__b2: 'B2'}
    // B. replace null values with empty strings

    if (this._isArray(data)) return data.map(value => this.form(value));

    if (this._isObject(data)) {
      const obj = {};

      Object.keys(data).forEach(prop => {
        const formObj = this.form(data[prop], `${path + prop}__`);

        if (this._isObject(formObj)) Object.assign(obj, formObj);
        else obj[path + prop] = formObj;
      });

      return obj;
    }

    return data === null ? '' : data;
  }

  formData = data => {
    const formData = new FormData();
    data = data || {};

    for (const prop in data) typeof data[prop] !== 'undefined' && formData.append(prop, data[prop]);

    return formData;
  };

  dispatch(action, data) {
    const _action = {
      type: action + this._actionSuffix,
      ...data
    };

    this._action && this._dispatch(_action);
  }

  countPage = (params, data) => {
    if (!data.data.results) return 0;
    return Math.min(
      params && params.page_size ? (params.page || 1) * params.page_size : data.data.results.length,
      data.data.count
    );
  };
}

export default DataProvider;
