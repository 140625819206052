const clientTabs = {
  COMPLIANCE_NOTES: 'compliance-notes',
  FACT_FINDER_FORMS: 'fact-finder-forms',
  INVESTMENT_OBJECTIVES: 'investment-objectives',
  IPS: 'ips',
  OVERVIEW: 'overview',
  PRISM: 'prism',
  PROPOSAL: 'proposals',
  RISK_TOLERANCE: 'risk-tolerance'
};

export const modelDetailTabs = {
  OVERVIEW: 'overview',
  PRISM: 'risk-analysis',
  PERFORMANCE: 'performance-analysis',
  STRATEGY: 'strategy',
  ACCESS_CONTROL: 'access-control'
};

export const SecurityDetailTabs = {
  OVERVIEW: 'overview',
  PRISM: 'returns-risk',
  RETURNS: 'returns',
  UNDERLYING_HOLDING: 'underlying-holding',
  ACCESS_CONTROL: 'access-control',
  PERFORMANCE: 'performance-analysis'
};

export const SecurityCreateTabs = {
  OVERVIEW: 'overview',
  RETURNS: 'returns',
  UNDERLYING_HOLDING: 'underlying-holding'
};

export const MarketplaceDetailTabs = {
  ABOUT: 'about',
  OVERVIEW: 'overview',
  PORTFOLIO_RISK: 'portfolio-risk',
  COMPARE_ANALYZE: 'compare-analyze',
  STRATEGY: 'strategy'
};

export default clientTabs;
