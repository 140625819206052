import cn from 'classnames';
import GoalsHint from 'components/advisor/goals-hint';
import AccountsWithoutGoal from 'components/advisor/investors/accounts-without-goal';
import DeleteModal from 'components/advisor/investors/delete-modal';
import NewInvestorGoal from 'components/advisor/investors/new-investor-goal';
import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import InvestorProvider from 'providers/investor';
import ProspectProvider from 'providers/prospects';
import React, { Component, Fragment } from 'react';
import { Draggable, Droppable } from 'react-drag-and-drop';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { allModelsWithPrismSelector } from 'selectors/models';
import { OrderingTableMixin } from 'utils/mixins';
import { getMatchingPrismModels } from 'utils/utils';
import InvestorAccountListRow from '../investor-account-list-row';
import InvestorGoalListRow from '../investor-goal-list-row';
import './styles.scss';

const ACCOUNT_MAP_SORTING = {
  name: a => a.display_name,
  value: a => a.value,
  prism: a => a?.prism_overall ?? 0,
  target: a => a?.target_score_summary?.overall ?? 0,
  drift: ({ drift_summary: driftSummary }) =>
    driftSummary?.overall ? driftSummary.overall.toFixed(1) : -999
};

const GOAL_MAP_SORTING = {
  name: g => g.name,
  value: g => g.value,
  accounts: g => g.accounts.length,
  prism: g => g?.aggregated_prism_scores?.overall ?? 0,
  target: g => g?.aggregated_target_scores?.overall ?? 0,
  drift: ({ drift_summary: driftSummary }) =>
    driftSummary?.overall ? driftSummary.overall.toFixed(1) : 0
};

const defaultOrder = '-id';

export class InvestorsAccountList extends Component {
  constructor(props) {
    super(props);
    OrderingTableMixin.applyMixin(this);

    this.state = {
      deleteModal: {
        item: null,
        type: null,
        show: false,
        submitting: false
      },
      accountsLoaded: false,
      sortColumn: defaultOrder
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.setGoal = this.setGoal.bind(this);
    this.getAccountExcludePrism = this.getAccountExcludePrism.bind(this);
    this.getOverallTargetCss = this.getOverallTargetCss.bind(this);
    this.displayOverallTargetScore = this.displayOverallTargetScore.bind(this);
    this.fetchInvestorData = this.fetchInvestorData.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  componentDidMount() {
    const { investorGoalsProvider, modelProvider } = this.context;
    const { investor } = this.props;

    this.fetchInvestorData();

    investorGoalsProvider.list({ investor: investor.id });
    modelProvider.listAll();
    window.addEventListener('drag', this.dragScrollHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('drag', this.dragScrollHandler, false);
  }

  fetchInvestorData() {
    const {
      provider,
      params: { id },
      ordering
    } = this.props;
    const accountsParams = {};

    if (ordering) accountsParams.ordering = ordering;

    provider.getAccounts(id, accountsParams).then(() => {
      this.setState({
        accountsLoaded: true
      });
    });
  }

  sortBy = ordering => {
    const params = {};
    if (ordering) params.ordering = ordering;
    this.setState({ sortColumn: ordering });
  };

  toggleDeleteModal(e, item, type) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (_.isEmpty(item))
      return this.setState({
        deleteModal: { show: false, submitting: false, item: null, type: null }
      });

    return this.setState({
      deleteModal: {
        show: true,
        item,
        type,
        investor: null
      }
    });
  }

  handleDelete(item, type) {
    const { deleteModal } = this.state;
    const { accountProvider, investorGoalsProvider } = this.context;
    this.setState({
      deleteModal: {
        ...deleteModal,
        submitting: true
      }
    });
    if (type === 'account')
      accountProvider.delete(item).then(() => {
        this.fetchInvestorData();
        this.deleteModal.modal.hide();
        this.sortBy();
        this.setState({
          deleteModal: { show: false, submitting: false, investor: null, account: null }
        });
        toast.success(() => (
          <div>
            Account <b>{item.display_name}</b> was deleted successfully.
          </div>
        ));
      });
    else if (type === 'goal')
      investorGoalsProvider.delete(item).then(() => {
        this.setState({
          deleteModal: { show: false, submitting: false, investor: null, account: null }
        });
        toast.success(() => (
          <div>
            Goal <b>{item.name}</b> was deleted successfully.
          </div>
        ));
      });
  }

  setGoal(data, goal) {
    const { investor } = this.props;
    const { accountProvider, investorGoalsProvider } = this.context;
    const accountWithGoal = data.split('-');
    const accountId = parseInt(accountWithGoal[0], 10);
    const previousGoal = accountWithGoal[1] ? parseInt(accountWithGoal[1], 10) : null;
    if (previousGoal !== goal)
      accountProvider.updatePatch(accountId, { goal }).then(() => {
        investorGoalsProvider.list({ investor: investor.id });
        toast.success(() => <div>Goal Assigned successfully.</div>);
      });
  }

  applySort(items, map) {
    const { sortColumn } = this.state;
    let order = 'asc';
    let sort = 'id';

    if (sortColumn) {
      if (sortColumn.includes('-')) order = 'desc';
      sort = sortColumn.replace('-', '');
    }
    return _.orderBy(items, [map[sort]], [order]);
  }

  getTotalValue() {
    const { accounts } = this.props;
    let targetOverall = 0;
    let totalAccounts = 0;
    let totalTargetAccounts = 0;
    let totalValue = 0;

    accounts.forEach(account => {
      if (!account.excluded) {
        totalAccounts += 1;
        totalValue += account.value;
        if (account.target_score_summary && account.target_score_summary.overall) {
          targetOverall += account.target_score_summary.overall;
          totalTargetAccounts += 1;
        }
      }
    });

    return {
      totalAccounts,
      totalValue: Math.round(totalValue),
      targetOverall: totalTargetAccounts ? (targetOverall / totalTargetAccounts).toFixed(1) : '-'
    };
  }

  setOrder = (currentOrder, order) => (order === currentOrder ? 'desc' : 'asc');

  getAccountExcludePrism(account) {
    const { excludeAccount } = this.props;
    if (!account.excluded)
      return (
        <span>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            data-tip="You can exclude this account from aggregated portfolio value and PRISM rating.
              You might want this if the PRISM rating is intentionally high or low and shouldn't affect
              the overall PRISM rating"
            className="add-account-back account-details-add-back"
            src="/img/icons/user_minus.svg"
            alt="remove account"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              excludeAccount(account.id, true);
            }}
            onKeyDown={() => {}}
          />
          <ReactTooltip className="tutorial-tip" effect="solid" place="right" />
        </span>
      );

    return (
      <span>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          data-tip="Add this account back to aggregated portfolio value and PRISM rating"
          className="add-account-back"
          src="/img/icons/user_plus.svg"
          alt="add account back"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            excludeAccount(account.id, false);
          }}
          onKeyDown={() => {}}
        />
        <ReactTooltip className="tutorial-tip" effect="solid" place="right" />
      </span>
    );
  }

  getOverallTargetCss(score) {
    const { investor } = this.props;
    const targetCss = investor.target_questionnaire_score;

    return cn(
      { 'risk-badge-with-score': targetCss },
      { 'risk-badge-with-manual-score': !targetCss },
      'dark',
      `risk-level-${Math.ceil(score)}`
    );
  }

  displayOverallTargetScore(score) {
    const { investor } = this.props;

    if (investor.target_questionnaire_score)
      return <span className={this.getOverallTargetCss(score)}>{score}</span>;
    return (
      <span data-tip="Risk Tolerance manually entered" data-for="target-score">
        <span className={this.getOverallTargetCss(score)}>{score}</span>
        <ReactTooltip
          id="target-score"
          className="manual-score-tutorial-tip"
          effect="solid"
          place="top"
        />
      </span>
    );
  }

  render() {
    const { accountsWithoutGoal, goals, historical, investor, isProspect, models } = this.props;

    const {
      addAccount,
      authProvider,
      modelProvider,
      toggleEditAccountModal,
      user,
      user: {
        advisor: {
          company: {
            goal_investments_read_only: goalInvestmentsReadOnly,
            tolerance_to_goals_mapping: goalsMapping
          }
        }
      }
    } = this.context;

    const { accountsLoaded, deleteModal, sortColumn } = this.state;

    const canAddAccounts = isProspect
      ? authProvider.hasAddProspectsPermissions(user)
      : authProvider.hasAddClientsPermissions(user);

    const { drift_summary: driftSummary, is_healthy: isHealthy } = investor;
    const investorType = investor.is_prospect ? 'prospect' : 'client';
    const totalObj = this.getTotalValue();

    const prismOverall = investor?.aggregated_prism_scores?.overall;
    const targetOverall = investor?.aggregated_target_scores?.overall;
    const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';

    if (!accountsLoaded) return <SpinnerLoader spinnerLoading />;

    const sortedAccountsWithoutGoal = this.applySort(accountsWithoutGoal, ACCOUNT_MAP_SORTING);
    const sortedGoals = this.applySort(goals, GOAL_MAP_SORTING);

    const RowComponent = goalInvestmentsReadOnly ? Fragment : Draggable;
    const SectionComponent = goalInvestmentsReadOnly ? Fragment : Droppable;

    return (
      <div id="InvestorAccountList" className="account-tables-container">
        <div className="help-box">
          <div>
            <strong className="text-help">Click account name to view more details</strong>
          </div>
          <GoalsHint goalsMapping={goalsMapping} />
        </div>

        {!_.isEmpty(sortedGoals) || !goalInvestmentsReadOnly ? (
          <>
            <table className="table table-investor-accounts">
              <thead>
                <tr>
                  <td width="20%" rowSpan={1}>
                    <span>Investment Goal</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                    <span
                      className="sort-icon"
                      onClick={this.sortByColumn.bind(
                        this,
                        'name',
                        this.setOrder(sortColumn, 'name')
                      )}
                      role="button"
                    >
                      {this.renderSortIcon('name')}
                    </span>
                  </td>
                  <td width="21%" rowSpan={1} className="text-sm-center">
                    <span>Account</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                    <span
                      className="sort-icon"
                      onClick={this.sortByColumn.bind(
                        this,
                        'accounts',
                        this.setOrder(sortColumn, 'accounts')
                      )}
                      role="button"
                    >
                      {this.renderSortIcon('accounts')}
                    </span>
                  </td>
                  <td width="16%" rowSpan={1} className="text-sm-right">
                    <span>Portfolio Value&nbsp;</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                    <span
                      className="sort-icon"
                      onClick={this.sortByColumn.bind(
                        this,
                        'value',
                        this.setOrder(sortColumn, 'value')
                      )}
                      role="button"
                    >
                      {this.renderSortIcon('value')}
                    </span>
                  </td>
                  <td width="8%" rowSpan={1}>
                    <span>PRISM&nbsp;</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                    <span
                      className="sort-icon"
                      onClick={this.sortByColumn.bind(
                        this,
                        'prism',
                        this.setOrder(sortColumn, 'prism')
                      )}
                      role="button"
                    >
                      {this.renderSortIcon('prism')}
                    </span>
                  </td>
                  <td width="20%" rowSpan={1} className="custom-line-height text-sm-center">
                    <span>Risk Tolerance</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                    <span
                      className="sort-icon"
                      onClick={this.sortByColumn.bind(
                        this,
                        'target',
                        this.setOrder(sortColumn, 'target')
                      )}
                      role="button"
                    >
                      {this.renderSortIcon('target')}
                    </span>
                  </td>
                  <td width="10%" rowSpan={1} className="text-sm-center">
                    <span>Drift</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                    <span
                      className="sort-icon"
                      onClick={this.sortByColumn.bind(
                        this,
                        'drift',
                        this.setOrder(sortColumn, 'drift')
                      )}
                      role="button"
                    >
                      {this.renderSortIcon('drift')}
                    </span>
                  </td>
                  <td width="5%" className="toggle-col" rowSpan={1} />
                </tr>
              </thead>
              {!_.isEmpty(sortedGoals) &&
                sortedGoals.map(goal => (
                  <SectionComponent
                    types={['account']}
                    onDrop={data => this.setGoal(data.account, goal.id)}
                    wrapperComponent={{ type: 'div' }}
                    key={`droppable-${goal.id}`}
                  >
                    <InvestorGoalListRow
                      goal={goal}
                      key={`goal-row-${goal.id}`}
                      toggleDeleteModal={this.toggleDeleteModal}
                      goalInvestmentsReadOnly={goalInvestmentsReadOnly}
                    >
                      {this.applySort(goal.accounts, ACCOUNT_MAP_SORTING).map(account => {
                        const { target_score_summary: ts, prism_score_summary: ps } = account;
                        const targetAccount = ts ? ts.overall : null;
                        const prismAccount = ps && ps.overall ? ps.overall : null;
                        const matchingModels =
                          targetAccount && models.length
                            ? getMatchingPrismModels(models, targetAccount, prismAccount)
                            : [];
                        const className = account.excluded ? 'less-opacity' : '';
                        return (
                          <RowComponent
                            type="account"
                            data={`${account.id}-${account.goal}`}
                            className="Draggable"
                            key={`draggable-account-${account.id}`}
                          >
                            <InvestorAccountListRow
                              goalInvestmentsReadOnly={goalInvestmentsReadOnly}
                              account={{ ...account, investor }}
                              toggleEditAccountModal={toggleEditAccountModal}
                              historical={historical}
                              key={account.id}
                              matchingModels={matchingModels}
                              modelProvider={modelProvider}
                              toggleDeleteModal={this.toggleDeleteModal}
                              className={className}
                              getAccountExcludePrism={this.getAccountExcludePrism}
                              investorType={investorType}
                              investor={investor}
                            />
                          </RowComponent>
                        );
                      })}
                    </InvestorGoalListRow>
                  </SectionComponent>
                ))}
            </table>
            {!goalInvestmentsReadOnly && (
              <NewInvestorGoal investorId={investor.id} advisorId={investor.advisor.id} />
            )}
          </>
        ) : null}

        {!_.isEmpty(sortedAccountsWithoutGoal) || !goalInvestmentsReadOnly ? (
          <>
            <AccountsWithoutGoal
              setGoal={this.setGoal}
              goalInvestmentsReadOnly={goalInvestmentsReadOnly}
            >
              {!_.isEmpty(sortedAccountsWithoutGoal) && (
                <table className="table-accounts-without-goal">
                  {sortedAccountsWithoutGoal.map((account, index) => {
                    const { target_score_summary: ts, prism_score_summary: ps } = account;
                    const targetAccount = ts ? ts.overall : null;
                    const prismAccount = ps && ps.overall ? ps.overall : null;
                    const matchingModels =
                      targetAccount && models.length
                        ? getMatchingPrismModels(models, targetAccount, prismAccount)
                        : [];
                    const className = account.excluded ? 'less-opacity' : '';
                    return (
                      <RowComponent
                        type="account"
                        data={`${account.id}-`}
                        className="Draggable"
                        key={`draggable-account-${account.id}`}
                      >
                        <InvestorAccountListRow
                          goalInvestmentsReadOnly={goalInvestmentsReadOnly}
                          account={{ ...account, investor }}
                          toggleEditAccountModal={toggleEditAccountModal}
                          historical={historical}
                          idx={index}
                          key={account.id}
                          matchingModels={matchingModels}
                          modelProvider={modelProvider}
                          toggleDeleteModal={this.toggleDeleteModal}
                          className={className}
                          getAccountExcludePrism={this.getAccountExcludePrism}
                          investorType={investorType}
                          investor={investor}
                        />
                      </RowComponent>
                    );
                  })}
                </table>
              )}
            </AccountsWithoutGoal>
            <hr className="total-separator" />
          </>
        ) : null}

        <div className="totals-for-investor">
          <div className="total-accounts">Total Accounts: {totalObj.totalAccounts}</div>
          <div className="total-value">
            <FormattedNumber value={totalObj.totalValue} format="currency" />
          </div>
          <div className="total-prism">
            {prismOverall ? (
              <span className={`dark risk-badge-with-score risk-level-${Math.ceil(prismOverall)}`}>
                {prismOverall.toFixed(1)}
              </span>
            ) : (
              '-'
            )}
          </div>
          <div className="total-target">
            {targetOverall && targetOverall !== '-'
              ? this.displayOverallTargetScore(targetOverall.toFixed(1))
              : '-'}
          </div>
          <div className={cn('total-drift', 'drift', { red: !isHealthy })}>{drift}</div>
          <div className="total-rest" />
        </div>

        {canAddAccounts && (
          <button
            className="btn btn-outline-secondary add-account"
            onClick={addAccount}
            type="button"
          >
            Add More Accounts
          </button>
        )}

        {deleteModal.show && (
          <DeleteModal
            {...deleteModal}
            onHide={this.toggleDeleteModal}
            onDelete={this.handleDelete}
            ref={c => {
              this.deleteModal = c;
            }}
          />
        )}
      </div>
    );
  }
}

InvestorsAccountList.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  addAccount: PropTypes.func.isRequired,
  investorGoalsProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

InvestorsAccountList.propTypes = {
  accounts: PropTypes.array.isRequired,
  accountsWithoutGoal: PropTypes.array,
  from: PropTypes.string,
  goals: PropTypes.array,
  historical: PropTypes.object,
  investor: PropTypes.object.isRequired,
  models: PropTypes.array,
  ordering: PropTypes.string,
  pagination: PropTypes.object,
  params: PropTypes.object.isRequired,
  prismOverall: PropTypes.string,
  provider: PropTypes.object.isRequired,
  excludeAccount: PropTypes.func,
  routerActions: PropTypes.object.isRequired
};

InvestorsAccountList.defaultProps = {
  accountsWithoutGoal: [],
  from: '',
  goals: [],
  historical: {},
  models: [],
  ordering: undefined,
  pagination: {},
  prismOverall: '',
  excludeAccount: () => {}
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname },
    params: { id: investorId }
  } = ownProps;

  const { investorProvider, prospectProvider } = dispatchProps;
  const {
    prospectAccounts,
    prospectAccountsMeta,
    investorAccounts,
    investorAccountsMeta,
    investor,
    investorsGoals,
    prospect
  } = stateProps;

  let investorGoals = investorsGoals[investorId];

  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const provider = isProspect ? prospectProvider : investorProvider;
  const accounts = isProspect ? prospectAccounts : investorAccounts;
  const accountsMeta = isProspect ? prospectAccountsMeta : investorAccountsMeta;
  const accountsWithoutGoal = accounts.filter(a => !a.goal);

  if (investorGoals)
    investorGoals = investorGoals.map(goal => ({
      ...goal,
      accounts: accounts.filter(account => account.goal === goal.id),
      value: accounts
        .filter(account => account.goal === goal.id)
        .reduce((accumulated, account) => {
          if (!account.excluded) accumulated += account.value;
          return accumulated;
        }, 0)
    }));

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    accounts,
    accountsMeta,
    accountsWithoutGoal,
    goals: investorGoals,
    investor: isProspect ? prospect : investor,
    isProspect,
    ordering: accountsMeta.ordering,
    provider
  };
}

export default connect(
  state => ({
    historical: state.accounts.historical,
    households: state.households.list,
    investor: state.investors.view,
    investorAccounts: state.investors.viewAccounts || [],
    investorAccountsMeta: state.investors.viewAccountsMeta || {},
    investorsGoals: state.investorsGoals.investors,
    marketStore: state.market,
    models: allModelsWithPrismSelector(state),
    prospect: state.prospects.view,
    prospectAccounts: state.prospects.viewAccounts || [],
    prospectAccountsMeta: state.prospects.viewAccountsMeta || {}
  }),
  dispatch => ({
    investorProvider: new InvestorProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch)
  }),
  mergeProps
)(InvestorsAccountList);
