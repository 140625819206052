/* eslint-disable import/prefer-default-export */
import { PROPOSAL_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import {
  BEAR_BURNDOWN_CHART_ID,
  BULL_BURNDOWN_CHART_ID
} from '../../body/sections/burndown/constants';
import { CLUSTERED_COLUMN_CHART_ID } from '../../body/sections/market-scenario-analysis/constants';
import { HISTORICAL_RETURNS_CHART_ID } from '../../body/sections/performance/constants';
import {
  BENCHMARK_POSITIONS_ANALYSIS_CHART_ID,
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID,
  TARGET_POSITIONS_ANALYSIS_CHART_ID
} from '../../distribution-summary/constants';

const PROPOSAL_REPORT_CHARTS = [
  BEAR_BURNDOWN_CHART_ID, // burndown
  BENCHMARK_POSITIONS_ANALYSIS_CHART_ID, // holdings
  BULL_BURNDOWN_CHART_ID, // burndown
  CLUSTERED_COLUMN_CHART_ID, // marketScenarioAnalysis
  HISTORICAL_RETURNS_CHART_ID, // performance
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID, // holdings
  TARGET_POSITIONS_ANALYSIS_CHART_ID, // holdings
  SECTOR_EXPOSURE_CHART_ID, // sector-exposure
  GEOGRAPHIC_EXPOSURE_CHART_ID // geographic-exposure
];

const PROPOSAL_REPORT_RECOMMENDED_CHARTS = [RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID];
const PROPOSAL_REPORT_BENCHMARK_CHARTS = [BENCHMARK_POSITIONS_ANALYSIS_CHART_ID];

const BURNDOWN_SECTION = 'burndown';
const HOLDINGS_SECTION = 'holdings';
const MARKET_SCENARIO_ANALYSIS_SECTION = 'marketScenarioAnalysis';
const PERFORMANCE_SECTION = 'performance';

const PROPOSAL_SECTION_CHARTS = {
  [BEAR_BURNDOWN_CHART_ID]: BURNDOWN_SECTION,
  [BENCHMARK_POSITIONS_ANALYSIS_CHART_ID]: HOLDINGS_SECTION,
  [BULL_BURNDOWN_CHART_ID]: BURNDOWN_SECTION,
  [CLUSTERED_COLUMN_CHART_ID]: MARKET_SCENARIO_ANALYSIS_SECTION,
  [HISTORICAL_RETURNS_CHART_ID]: PERFORMANCE_SECTION,
  [RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID]: HOLDINGS_SECTION,
  [TARGET_POSITIONS_ANALYSIS_CHART_ID]: HOLDINGS_SECTION
};

export const getFullyLoadedCharts = (proposal, savedCharts) => {
  const templateContent =
    proposal.template_content || proposal.template.content || PROPOSAL_DEFAULT_TEMPLATE;

  // removes all recommended or benchmark related charts when required
  const byRequiredCharts = chart =>
    (proposal.benchmark || !PROPOSAL_REPORT_BENCHMARK_CHARTS.includes(chart)) &&
    (proposal.recommended || !PROPOSAL_REPORT_RECOMMENDED_CHARTS.includes(chart));

  // removes all charts that are not needed because the section is not visible
  const byVisibleSections = chart => {
    const section = PROPOSAL_SECTION_CHARTS[chart];
    if (
      !section ||
      (templateContent[section] &&
        templateContent[section].hidden &&
        !templateContent[section].allowHide)
    )
      return false;
    return true;
  };

  const charts = PROPOSAL_REPORT_CHARTS.filter(byRequiredCharts).filter(byVisibleSections);
  return charts.every(chart => Object.prototype.hasOwnProperty.call(savedCharts, chart));
};
