import FocusedPrismFactorsChart from 'components/charts/focused-prism-factors-chart/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';

const ProposalRiskFactorComparison = ({ breakSection, metadata, proposal }) => {
  const { body, title } = metadata;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <FocusedPrismFactorsChart
        recommendedName={proposal.recommended_label}
        recommendedSummary={proposal?.recommended?.prism_score_summary}
        targetName={proposal.target_label}
        targetSummary={proposal.target.prism_score_summary}
      />
    </BaseSection>
  );
};

ProposalRiskFactorComparison.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired
};

ProposalRiskFactorComparison.defaultProps = {
  breakSection: false
};

export default ProposalRiskFactorComparison;
