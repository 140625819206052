import InlineTextEditor from 'components/inline-text-editor';
import SortableList from 'components/sortable-list';
import SimpleSpinner from 'components/utils/simple-spinner';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const TaxonomyRow = ({
  collapseMap,
  depth,
  nested,
  onChangeLabel,
  onChangeOrder,
  slug,
  text,
  toggleCollapsed,
  updateMap
}) => (
  <div key={slug} className={`level${depth}`}>
    <div className="header">
      <InlineTextEditor
        text={text}
        onChange={label => onChangeLabel(slug, label)}
        updating={updateMap[slug]}
      />
      <div className="actions">
        {updateMap[slug] && <SimpleSpinner />}
        {!!nested?.length && (
          <button
            className="btn-arrow btn-transparent collapse-action"
            type="button"
            onClick={() => toggleCollapsed(slug)}
          >
            <img
              width="17px"
              height="17px"
              alt="toggle"
              src={`/img/icons/${collapseMap[slug] ? 'down' : 'up'}arrow_blue.svg`}
            />
          </button>
        )}
      </div>
    </div>
    {!collapseMap[slug] && !!nested?.length && (
      <div className="content">
        <SortableList
          type="l2"
          items={nested}
          onChange={onChangeOrder}
          component={TaxonomyRow}
          collapseMap={collapseMap}
          updateMap={updateMap}
          disabled={Object.values(updateMap).some(i => i)}
          onChangeLabel={onChangeLabel}
          toggleCollapsed={toggleCollapsed}
          depth={2}
        />
      </div>
    )}
  </div>
);

TaxonomyRow.propTypes = {
  collapseMap: PropTypes.object.isRequired,
  depth: PropTypes.number,
  nested: PropTypes.array,
  onChangeLabel: PropTypes.func.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  updateMap: PropTypes.object.isRequired
};

TaxonomyRow.defaultProps = {
  depth: 1,
  nested: []
};

export default TaxonomyRow;
