import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles, { SIGNATURE_BOX_BACKGROUND_COLOR } from './styles';

const SignaturePlace = ({ isCodeValue, title, value }) => {
  /**
   * Changing the `fontFamily` attribute is important because throughout the report,
   * the Roboto font is often used, and many characters are actually represented with the
   * equivalent glyph in the font, preventing the generated text from being exactly the same
   * as what is displayed.
   *
   * For this reason, it's important to use a font that @react-pdf/renderer supports by default,
   * as it ensures that the generated code can be correctly interpreted by the backend to later
   * inject the corresponding signature and date at the time of signing/approval.
   */
  const signatureBoxStyle = isCodeValue ? { justifyContent: 'flex-start' } : {};
  const signatureBoxTextStyle = isCodeValue
    ? { fontFamily: 'Courier', fontSize: 5, color: SIGNATURE_BOX_BACKGROUND_COLOR }
    : {};
  return (
    <>
      <View style={[styles.signatureBox, signatureBoxStyle]}>
        <Text style={[styles.signatureBoxText, signatureBoxTextStyle]}>{value}</Text>
      </View>
      <Text style={styles.signatureText}>{title}</Text>
    </>
  );
};

SignaturePlace.defaultProps = {
  isCodeValue: false,
  value: ''
};

SignaturePlace.propTypes = {
  isCodeValue: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default SignaturePlace;
