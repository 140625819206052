import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import QuestionnaireTemplatesDetail from './detail';
import QuestionnaireTemplatesList from './list';
import './styles.scss';
import QuestionnaireTemplatePropTypes from './types';

const QuestionnaireTemplates = ({ id, templates }) => {
  const { questionProvider } = useContext(AdvisorContext);

  useEffect(() => {
    questionProvider.listQuestionnaireTemplates({ with_all_questionnaires: true });
  }, []);

  return (
    <div id="questionnaire-templates">
      {!id && <QuestionnaireTemplatesList onCreate={() => {}} templates={templates} />}
      {id && templates.length ? (
        <QuestionnaireTemplatesDetail
          template={templates?.find(t => t.id === Number.parseInt(id, 10))}
        />
      ) : null}
    </div>
  );
};

QuestionnaireTemplates.propTypes = {
  id: PropTypes.string,
  templates: PropTypes.arrayOf(QuestionnaireTemplatePropTypes).isRequired
};

QuestionnaireTemplates.defaultProps = {
  id: null
};

export default connect(state => ({
  templates: Object.values(state.questions.templates)
}))(QuestionnaireTemplates);
