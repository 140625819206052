import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const SignatureRequestTrailInline = ({ reviews }) => {
  const completedReviews = reviews.filter(review => !!review.reviewed_at);
  const waitingReviews = reviews.filter(review => !review.reviewed_at && review?.mail?.sent);
  const pendingReviews = reviews.filter(review => !review.reviewed_at && !review?.mail?.sent);

  const goToAuditTrail = () => {
    const element = document.getElementById('signature-request-trail');
    if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  const getReviewsStatusFor = (category, reviews, withDetails = true) => {
    if (!reviews.length) return null;
    let text = `${reviews.length} ${category}`;
    if (withDetails) {
      const signers = reviews
        .map(review => {
          if (review.advisor)
            return `${review.advisor.user.first_name} ${review.advisor.user.last_name}`;
          return review.investor.full_name;
        })
        .join(', ');
      text = `${text} (${signers})`;
    }
    return text;
  };

  const categories = [
    getReviewsStatusFor('signers', reviews, false),
    getReviewsStatusFor('completed', completedReviews),
    getReviewsStatusFor('waiting', waitingReviews),
    getReviewsStatusFor('pending', pendingReviews)
  ]
    .filter(category => !!category)
    .join(' | ');

  return (
    <div id="signature-request-trail-inline">
      {categories}
      <button className="btn btn-link" onClick={goToAuditTrail} type="button">
        View audit trail
      </button>
    </div>
  );
};

SignatureRequestTrailInline.propTypes = {
  reviews: PropTypes.array.isRequired
};

export default SignatureRequestTrailInline;
