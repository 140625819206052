import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CreateInvestorForm from './form';
import './styles.scss';

const CreateInvestorModal = ({ createInvestor, show }) => (
  <Modal
    backdrop="static"
    backdropClassName="light-backdrop"
    className="modal-lg"
    id="create-investor-modal"
    keyboard="false"
    show={show}
  >
    <ModalBody>
      <div className="text-center">
        <img src="/img/logos/stratifi-logo.svg" alt="StratiFi" />
        <h1>
          <FormattedMessage id="rtq.guest.modal.title" />
        </h1>
        <p>
          <FormattedMessage id="rtq.guest.modal.description" />
        </p>
      </div>
      <CreateInvestorForm createInvestor={createInvestor} />
    </ModalBody>
  </Modal>
);

CreateInvestorModal.propTypes = {
  createInvestor: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired
};

export default CreateInvestorModal;
