import React from 'react';
import './styles.scss';

const IconsSection = () => (
  <section id="icons-section">
    <h2>Icons</h2>

    <ul>
      <li>
        <i className="fs-icon-arrow-up" />
      </li>
      <li>
        <i className="fs-icon-arrow-down" />
      </li>
      <li>
        <i className="fs-icon-arrow-left" />
      </li>
      <li>
        <i className="fs-icon-arrow-right" />
      </li>
      <li>
        <i className="fs-icon-address" />
      </li>
      <li>
        <i className="fs-icon-arrow-blue" />
      </li>
      <li>
        <i className="fs-icon-cancel-round" />
      </li>
      <li>
        <i className="fs-icon-checkbox-blue" />
      </li>
      <li>
        <i className="fs-icon-checkbox-bg-line" />
      </li>
      <li>
        <i className="fs-icon-checkmark-linear" />
      </li>
      <li>
        <i className="fs-icon-close-button" />
      </li>
      <li>
        <i className="fs-icon-edit-icon-blue" />
      </li>
      <li>
        <i className="fs-icon-phone" />
      </li>
      <li>
        <i className="fs-icon-search" />
      </li>
      <li>
        <i className="fs-icon-snooze" />
      </li>
      <li>
        <i className="fs-icon-target-pointer" />
      </li>
      <li>
        <i className="fs-icon-view-icon" />
      </li>
      <li>
        <i className="fs-icon-xmark-linear" />
      </li>
      <li>
        <i className="fs-icon-angle-right" />
      </li>
      <li>
        <i className="fs-icon-star-o" />
      </li>
      <li>
        <i className="fs-icon-star" />
      </li>
      <li>
        <i className="fs-icon-trash" />
      </li>
      <li>
        <i className="fs-icon-home" />
      </li>
      <li>
        <i className="fs-icon-exclamation-circle" />
      </li>
      <li>
        <i className="fs-icon-play-circle-o" />
      </li>
      <li>
        <i className="fs-icon-minus-circle" />
      </li>
      <li>
        <i className="icon-competitive" />
      </li>
      <li>
        <i className="icon-phone_expense" />
      </li>
      <li>
        <i className="icon-help-tips" />
      </li>
      <li>
        <i className="icon-email" />
      </li>
      <li>
        <i className="icon-facebook" />
      </li>
      <li>
        <i className="icon-download" />
      </li>
      <li>
        <i className="icon-ebook_icon" />
      </li>
      <li>
        <i className="icon-play" />
      </li>
      <li>
        <i className="icon-small-cap" />
      </li>
      <li>
        <i className="icon-strategies_nav" />
      </li>
      <li>
        <i className="icon-clients_nav" />
      </li>
      <li>
        <i className="icon-dashboard_nav" />
      </li>
      <li>
        <i className="icon-checkmark_circle" />
      </li>
      <li>
        <i className="icon-document_line" />
      </li>
      <li>
        <i className="icon-two-arrows" />
      </li>
      <li>
        <i className="icon-linkedin" />
      </li>
      <li>
        <i className="icon-twitter" />
      </li>
      <li>
        <i className="icon-compliments" />
      </li>
      <li>
        <i className="icon-accounts2" />
      </li>
      <li>
        <i className="icon-custodian" />
      </li>
      <li>
        <i className="icon-risk" />
      </li>
      <li>
        <i className="icon-transparent" />
      </li>
      <li>
        <i className="icon-clients" />
      </li>
      <li>
        <i className="icon-proposals" />
      </li>
      <li>
        <i className="icon-down" />
      </li>
      <li>
        <i className="icon-up" />
      </li>
      <li>
        <i className="icon-table-arrow" />
      </li>
      <li>
        <i className="icon-advisor_placeholder" />
      </li>
      <li>
        <i className="icon-plus" />
      </li>
      <li>
        <i className="icon-drawer" />
      </li>
      <li>
        <i className="icon-accounts" />
      </li>
      <li>
        <i className="icon-add_item" />
      </li>
      <li>
        <i className="icon-arrow" />
      </li>
      <li>
        <i className="icon-bar_graph" />
      </li>
      <li>
        <i className="icon-calendar" />
      </li>
      <li>
        <i className="icon-checkmark" />
      </li>
      <li>
        <i className="icon-default_portfolio" />
      </li>
      <li>
        <i className="icon-edit" />
      </li>
      <li>
        <i className="icon-exit" />
      </li>
      <li>
        <i className="icon-filter" />
      </li>
      <li>
        <i className="icon-graph2" />
      </li>
      <li>
        <i className="icon-percentage" />
      </li>
      <li>
        <i className="icon-person" />
      </li>
      <li>
        <i className="icon-profile_icn" />
      </li>
      <li>
        <i className="icon-remove" />
      </li>
      <li>
        <i className="icon-risk_management" />
      </li>
      <li>
        <i className="icon-search_icn" />
      </li>
      <li>
        <i className="icon-summary_icn" />
      </li>
      <li>
        <i className="icon-world" />
      </li>
      <li>
        <i className="icon-shield" />
      </li>
      <li>
        <i className="icon-shield-check" />
      </li>
      <li>
        <i className="icon-shield-alert" />
      </li>
      <li>
        <i className="icon-shield-cross" />
      </li>
      <li>
        <i className="icon-lock" />
      </li>
      <li>
        <i className="icon-unlock" />
      </li>
      <li>
        <i className="icon-key" />
      </li>
      <li>
        <i className="icon-trash" />
      </li>
      <li>
        <i className="icon-flag2" />
      </li>
      <li>
        <i className="icon-envelope" />
      </li>
      <li>
        <i className="icon-envelope-open" />
      </li>
      <li>
        <i className="icon-paper-plane" />
      </li>
      <li>
        <i className="icon-hdd-down" />
      </li>
      <li>
        <i className="icon-register" />
      </li>
      <li>
        <i className="icon-equalizer" />
      </li>
      <li>
        <i className="icon-library2" />
      </li>
      <li>
        <i className="icon-profile" />
      </li>
      <li>
        <i className="icon-user" />
      </li>
      <li>
        <i className="icon-user-plus" />
      </li>
      <li>
        <i className="icon-user-minus" />
      </li>
      <li>
        <i className="icon-user-lock" />
      </li>
      <li>
        <i className="icon-users2" />
      </li>
      <li>
        <i className="icon-users-plus" />
      </li>
      <li>
        <i className="icon-users-minus" />
      </li>
      <li>
        <i className="icon-calendar-full" />
      </li>
      <li>
        <i className="icon-escape" />
      </li>
      <li>
        <i className="icon-enter2" />
      </li>
      <li>
        <i className="icon-pie-chart2" />
      </li>
      <li>
        <i className="icon-graph" />
      </li>
      <li>
        <i className="icon-chart-bars" />
      </li>
      <li>
        <i className="icon-loading" />
      </li>
      <li>
        <i className="icon-refresh" />
      </li>
      <li>
        <i className="icon-refresh2" />
      </li>
      <li>
        <i className="icon-cross" />
      </li>
      <li>
        <i className="icon-check" />
      </li>
      <li>
        <i className="icon-checkmark-circle" />
      </li>
      <li>
        <i className="icon-cross-circle" />
      </li>
      <li>
        <i className="icon-funnel" />
      </li>
      <li>
        <i className="icon-plus-square" />
      </li>
      <li>
        <i className="icon-circle" />
      </li>
      <li>
        <i className="icon-radio-button" />
      </li>
      <li>
        <i className="icon-pencil-line" />
      </li>
      <li>
        <i className="fs-icon-angle-right" />
      </li>
      <li>
        <i className="fs-icon-dot" />
      </li>
      <li>
        <i className="fs-icon-bell" />
      </li>
      <li>
        <i className="fs-icon-bell-o" />
      </li>
      <li>
        <i className="fs-icon-bell-slash" />
      </li>
      <li>
        <i className="fs-icon-bell-slash-o" />
      </li>
      <li>
        <i className="fs-icon-eye" />
      </li>
      <li>
        <i className="fs-icon-eye-slash" />
      </li>
      <li>
        <i className="fs-icon-plus" />
      </li>
      <li>
        <i className="fs-icon-comment" />
      </li>
      <li>
        <i className="fs-icon-info" />
      </li>
      <li>
        <i className="fs-icon-info-circled" />
      </li>
      <li>
        <i className="fs-icon-user" />
      </li>
      <li>
        <i className="fs-icon-user-add" />
      </li>
      <li>
        <i className="fs-icon-users" />
      </li>
      <li>
        <i className="fs-icon-attention" />
      </li>
      <li>
        <i className="fs-icon-attach" />
      </li>
      <li>
        <i className="fs-icon-arrows-ccw" />
      </li>
      <li>
        <i className="fs-icon-arrow-combo" />
      </li>
      <li>
        <i className="fs-icon-help-circled" />
      </li>
    </ul>
  </section>
);

export default IconsSection;
