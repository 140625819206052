// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#security-underlying-holding-container .security-type-concentration-section {
  margin-top: 30px;
}
#security-underlying-holding-container .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}
#security-underlying-holding-container .header h2 {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: normal;
}
#security-underlying-holding-container .header .right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
#security-underlying-holding-container .header .right-container .btn.btn-primary {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/security/details/underlying-holding/styles.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,mBAAA;AAHJ;AAKI;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAHN;AAMI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAJN;AAMM;EACE,gBAAA;AAJR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#security-underlying-holding-container {\n  .security-type-concentration-section {\n    margin-top: 30px;\n  }\n\n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    width: 100%;\n    margin-bottom: 10px;\n\n    h2 {\n      margin-bottom: 0;\n      font-size: 2rem;\n      line-height: normal;\n    }\n\n    .right-container {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      gap: 10px;\n\n      .btn.btn-primary {\n        font-weight: 400;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
