import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import ErrorMessage from 'components/error-message';
import { VerboseErrorInput as Input, FormGroup } from 'components/form';
import Choice from 'components/form/choice';
import { BackendValidation } from 'hocs/backend-validation';
import { validation } from 'utils/form';

const SUPPLY_ORION_CREDENTIALS_STEP = 1;
const SELECT_ORION_REPRESENTATIVES_STEP = 2;

const STATUS_MESSAGE = {
  exit: {
    status: 'exit',
    message: 'The process ended but was not satisfactory.'
  },
  success: {
    status: 'success',
    message: 'Thank you! Your integration should be live in the next 24-48 hours.'
  },
  unknown: {
    status: 'unknown',
    message: 'An unexpected error has occurred. Please try again later.'
  }
};

const validate = values => {
  const errors = {};
  errors.username = errors.username || validation.required(values.username);
  errors.password = errors.password || validation.required(values.password);
  return errors;
};

class OrionIntegrationComponent extends Component {
  constructor() {
    super();

    this.form = null;
    this.initialState = {
      loading: false,
      integration: null,
      representatives: [],
      selectedRepresentatives: {},
      step: SUPPLY_ORION_CREDENTIALS_STEP
    };
    this.state = { ...this.initialState };

    this.onSubmitCredentials = this.onSubmitCredentials.bind(this);
  }

  finish = (status = STATUS_MESSAGE.exit) => {
    const { onComplete, skipError } = this.props;
    onComplete(status);
    skipError();
    this.setState(this.initialState);
  };

  onSubmitCredentials(values) {
    const { registerError, selectedSyncData } = this.props;
    const { integrationProvider } = this.context;

    this.setState({ loading: true });

    integrationProvider
      .setOrionIntegrationInfo({ ...selectedSyncData, ...values })
      .then(({ data }) => {
        if (!data) this.finish(STATUS_MESSAGE.unknown);
        else if (data.is_authenticated)
          if (data.representatives && !_.isEmpty(data.representatives)) {
            const representatives = data.representatives.map(r => ({ id: r.id, name: r.repNo }));
            const selectedRepresentatives = data.representatives.reduce(
              (acc, sr) => ({ ...acc, [sr.id]: false }),
              {}
            );
            this.setState({
              loading: false,
              integration: data.id,
              representatives,
              selectedRepresentatives,
              step: SELECT_ORION_REPRESENTATIVES_STEP
            });
          } else this.finish(STATUS_MESSAGE.exit);
        else {
          this.setState({ loading: false });
          registerError({
            error: {
              reason: 'Authentication Error',
              message: 'Unable to login with the provided credentials'
            }
          });
        }
      })
      .catch(() => this.finish(STATUS_MESSAGE.unknown));
  }

  onSubmitRepresentatives = () => {
    const { integration, selectedRepresentatives } = this.state;
    const { integrationProvider } = this.context;
    const representatives = Object.keys(selectedRepresentatives)
      .filter(sr => selectedRepresentatives[sr])
      .join(',');

    this.setState({ loading: true });

    if (integration)
      integrationProvider
        .updateConnectedIntegration(integration, { provider_id: representatives })
        .then(({ data }) => {
          if (!data) this.finish(STATUS_MESSAGE.unknown);
          else this.finish(STATUS_MESSAGE.success);
        })
        .catch(() => this.finish(STATUS_MESSAGE.unknown));

    this.finish(STATUS_MESSAGE.unknown);
  };

  onToggleRepresentative = optionId => {
    const { selectedRepresentatives } = this.state;
    this.setState({
      selectedRepresentatives: {
        ...selectedRepresentatives,
        [optionId]: !selectedRepresentatives[optionId]
      }
    });
  };

  render() {
    let error;
    const { loading, representatives, selectedRepresentatives, step } = this.state;
    const {
      backendErrors,
      fields,
      handleSubmit,
      provider: { name, image },
      skipError,
      submitting
    } = this.props;

    if (!_.isEmpty(backendErrors)) error = backendErrors.general;

    const hasSelectedRepresentatives = Object.keys(selectedRepresentatives).some(
      r => selectedRepresentatives[r]
    );

    return (
      <div id="orion-integration">
        {step === SUPPLY_ORION_CREDENTIALS_STEP && (
          <>
            <img src={image} alt={name} width={300} />
            <h1>Please enter your credentials for {name}</h1>
            <form onSubmit={handleSubmit(this.onSubmitCredentials)} autoComplete="off">
              <FormGroup className="form-group" {...fields.username} skipError={skipError}>
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  {...fields.username}
                  skipError={skipError}
                  placeholder={`${name} Username`}
                />
              </FormGroup>
              <FormGroup className="form-group" {...fields.password} skipError={skipError}>
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  {...fields.password}
                  skipError={skipError}
                  placeholder={`${name} Password`}
                />
              </FormGroup>

              {error && <ErrorMessage message={error} />}

              <div className="actions">
                <button
                  type="button"
                  className="btn btn-secondary-2"
                  data-dismiss="modal"
                  aria-label="Close"
                  disabled={loading || submitting}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={loading || submitting}>
                  Submit
                </button>
              </div>
            </form>
          </>
        )}
        {step === SELECT_ORION_REPRESENTATIVES_STEP && (
          <>
            <h1>Please select the representatives</h1>
            <div className="orion-representatives">
              {representatives.map(rep => (
                <div key={rep.id}>
                  <Choice
                    checked={selectedRepresentatives[rep.id]}
                    toggle={() => this.onToggleRepresentative(rep.id)}
                  />
                  <span className="orion-representatives__label">{rep.name}</span>
                </div>
              ))}
            </div>
            <div className="actions center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmitRepresentatives}
                disabled={loading || !hasSelectedRepresentatives}
              >
                Continue
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

OrionIntegrationComponent.contextTypes = {
  integrationProvider: PropTypes.object.isRequired
};

OrionIntegrationComponent.propTypes = {
  onStart: PropTypes.func,
  onComplete: PropTypes.func,
  provider: PropTypes.object.isRequired,
  selectedSyncData: PropTypes.object,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

OrionIntegrationComponent.defaultProps = {
  onStart: () => {},
  onComplete: () => {},
  selectedSyncData: {}
};

const OrionIntegration = reduxForm({
  form: 'editOrionInfo',
  fields: ['username', 'password'],
  initialValues: {},
  validate
})(BackendValidation(OrionIntegrationComponent));

export default OrionIntegration;
