import Choice from 'components/form/choice';
import { AdvisorContext } from 'containers/advisor';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { COMPANY_POLICY_2FA } from '../utils/constants';

const handleUpdateError = (message = COMPANY_POLICY_2FA.ERROR) => {
  toast.error(() => <div>{message}</div>);
};

const TwoFactorCompanyPolicy = () => {
  const {
    authProvider,
    companyProvider,
    user: {
      advisor: {
        company: { enforce_second_factor: enforceSecondFactor }
      }
    }
  } = useContext(AdvisorContext);

  const changeCompanySettings = async values => {
    try {
      const { data } = await companyProvider.update(values);
      if (data) {
        await authProvider.getUser();
        toast.success(() => <div>{COMPANY_POLICY_2FA.SUCCESS}</div>);
      } else handleUpdateError();
    } catch {
      handleUpdateError();
    }
  };

  const onChangeEnforceSecondFactor = () => {
    changeCompanySettings({ enforce_second_factor: !enforceSecondFactor });
  };

  return (
    <div id="two-factor-company-policy" className="form-container">
      <div>
        <h4>{COMPANY_POLICY_2FA.TITLE}</h4>
        <p>{COMPANY_POLICY_2FA.DESCRIPTION}</p>
      </div>
      <div>
        <p>{COMPANY_POLICY_2FA.HELPER_TEXT}</p>
        <div className="row row-enforce-second-factor">
          <div className="col">
            <Choice
              checked={enforceSecondFactor}
              title={COMPANY_POLICY_2FA.OPTION_TEXT}
              toggle={onChangeEnforceSecondFactor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorCompanyPolicy;
