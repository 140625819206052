import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const FACTOR_TYPES = {
  downside: 'downside',
  upside: 'upside'
};

const PRISM_MAX_SCORE = 10;
const GRAPH_BAR_MAX_PERCENTAGE_WIDTH = 40;

const Factor = ({ children, factorType, title }) => (
  <div className={cs('factor', factorType)}>
    <div className="factor__title">
      <span>{title}</span>
    </div>
    <div className="factor__content">{children}</div>
  </div>
);

Factor.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  factorType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const FactorRow = ({ title, values }) => {
  const key = title.toLowerCase().replace(/\s/g, '-');
  const bars = values.map(value => {
    const score = Number.parseFloat((value || 0).toFixed(1));
    return `${(score / PRISM_MAX_SCORE) * GRAPH_BAR_MAX_PERCENTAGE_WIDTH}%`;
  });

  return (
    <div className="factor-row-wrapper">
      <div className="factor-row">
        <div className="factor-row__first-half" />
        <div className="factor-row__second-half" />
        <div className="factor-row__title">{title}</div>
      </div>
      {bars.map((width, i) => (
        <div
          // up to 2 bars are always rendered, which will not change order
          // and can be repeated in width. For that reason, we use the index
          // as part of the key
          key={`${key}-${i}`}
          className={`graph-bar graph-bar-${i}${bars.length}`}
          data-testid="graph-bar"
          style={{ width }}
        />
      ))}
    </div>
  );
};

FactorRow.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired
};

const FactorScale = () => (
  <div className="factor-scale">
    <span className="factor-scale__value">{PRISM_MAX_SCORE}</span>
    <span className="factor-scale__value">{PRISM_MAX_SCORE / 2}</span>
  </div>
);

const FocusedPrismFactorsChart = ({
  recommendedName,
  recommendedSummary,
  targetName,
  targetSummary
}) => {
  const concentrated = [targetSummary.concentrated];
  const correlation = [targetSummary.correlation];
  const tail = [targetSummary.tail];
  const volatility = [targetSummary.volatility];

  if (recommendedSummary) {
    concentrated.push(recommendedSummary.concentrated);
    correlation.push(recommendedSummary.correlation);
    tail.push(recommendedSummary.tail);
    volatility.push(recommendedSummary.volatility);
  }

  return (
    <div id="focused-prism-factors-chart">
      {recommendedSummary && (
        <div className="focused-prism-factors-chart__legend">
          <div className="legend__box box__target" />
          <div className="legend__title">{targetName || 'Current portfolio'}</div>
          <div className="legend__box box__recommended" />
          <div className="legend__title">{recommendedName || 'Model'}</div>
        </div>
      )}
      <div className="focused-prism-factors-chart__chart">
        <Factor title="Downside Focused PRISM Factors" factorType={FACTOR_TYPES.downside}>
          <FactorRow title="Tail Event Risk" values={tail} />
          <FactorRow title="Diversification Risk" values={correlation} />
          <FactorScale />
        </Factor>
        <Factor title="Upside Focused PRISM Factors" factorType={FACTOR_TYPES.upside}>
          <FactorRow title="Volatility Risk" values={volatility} />
          <FactorRow title="Concentrated Stock Risk" values={concentrated} />
          <FactorScale />
        </Factor>
      </div>
    </div>
  );
};

FocusedPrismFactorsChart.propTypes = {
  recommendedName: PropTypes.string,
  recommendedSummary: PropTypes.object,
  targetName: PropTypes.string,
  targetSummary: PropTypes.object.isRequired
};

FocusedPrismFactorsChart.defaultProps = {
  recommendedName: '',
  recommendedSummary: null,
  targetName: ''
};

export default FocusedPrismFactorsChart;
