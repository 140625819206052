/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

export const PAGINATION_VALUES = [10, 20, 50, 100];

export const getDefaultPageSize = defaultValue => {
  const defaultPageSize = localStorage.getItem(window.btoa(window.location.pathname));
  return defaultPageSize ? Number(defaultPageSize) : defaultValue ?? 10;
};

export const setDefaultPageSize = pageSize =>
  localStorage.setItem(window.btoa(window.location.pathname), pageSize);

const setSortingFromOrdering = order => ({
  id: order.replace('-', ''),
  desc: order.startsWith('-')
});

export const initializeTableState = (
  params,
  initialParams,
  setPagination,
  setSearch,
  setSorting
) => {
  const { page, page_size, ordering, search, ...rest } = { ...initialParams, ...params };

  if (search) setSearch(search);

  if (ordering) setSorting(ordering.map(setSortingFromOrdering));

  if (page || page_size)
    setPagination({
      pageIndex: page ? page - 1 : 0,
      pageSize: page_size ?? getDefaultPageSize()
    });

  Object.keys({ ...initialParams, ...rest }).forEach(key => {
    if (initialParams[key] && !params[key]) params[key] = initialParams[key];
  });
};

const IndeterminateCheckbox = ({ className, indeterminate, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    if (typeof indeterminate === 'boolean')
      ref.current.indeterminate = !rest.checked && indeterminate;
  }, [ref, indeterminate]);

  return (
    <input
      className={className}
      ref={ref}
      style={{ cursor: 'pointer', width: 15, height: 15 }}
      type="checkbox"
      {...rest}
    />
  );
};

IndeterminateCheckbox.propTypes = {
  className: PropTypes.string,
  indeterminate: PropTypes.bool
};

IndeterminateCheckbox.defaultProps = {
  className: '',
  indeterminate: false
};

// Custom functions to handle top-level rows
const getIsAllTopRowsSelected = table => {
  // Logic to check if all top-level rows are selected
  const topRows = table.getRowModel().rows;
  return topRows.length && topRows.every(row => row.getIsSelected());
};

const getIsSomeTopRowsSelected = table => {
  // Logic to check if some top-level rows are selected
  const topRows = table.getRowModel().rows;
  return topRows.some(row => row.getIsSelected()) && !getIsAllTopRowsSelected(table);
};

const toggleAllTopRowsSelected = table => {
  // Logic to toggle all top-level rows
  const topRows = table.getRowModel().rows;
  const isSelected = !getIsAllTopRowsSelected(table);
  topRows.forEach(row => row.toggleSelected(isSelected));
};

export const selectColumn = {
  id: 'select',
  header: ({ table }) => (
    <IndeterminateCheckbox
      {...{
        checked: getIsAllTopRowsSelected(table),
        indeterminate: getIsSomeTopRowsSelected(table),
        onChange: () => toggleAllTopRowsSelected(table)
      }}
    />
  ),
  cell: ({ row }) => (
    <IndeterminateCheckbox
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler()
      }}
    />
  ),
  enableSorting: false,
  meta: { className: () => 'sdt__checkbox' }
};

export const expanderColumn = {
  id: 'expander',
  header: '',
  cell: ({ row }) =>
    row.getCanExpand() && (
      <button
        className="btn btn-link sdt__expand"
        onClick={row.getToggleExpandedHandler()}
        style={{ cursor: 'pointer' }}
        type="button"
      >
        {row.getIsExpanded() ? (
          <span className="fs-icon-angle-up" />
        ) : (
          <span className="fs-icon-angle-down" />
        )}
      </button>
    ),
  enableSorting: false,
  meta: {
    className: () => 'text-center',
    style: () => ({ width: 60 })
  }
};
