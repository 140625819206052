import cn from 'classnames';
import HouseholdsInvestorListAddRow from 'components/advisor/households/households-investor-list-add-row';
import HouseholdsInvestorListRow from 'components/advisor/households/households-investor-list-row';
import UnlinkClientModal from 'components/advisor/investors/unlink-client-modal';
import AggregatedRiskScoreBubble from 'components/advisor/utils/score-bubble/aggregated-risk';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import Disclosure from 'components/disclosure';
import CancelIcon from 'components/svg-icons/cancel-icon';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import './styles.scss';

class HouseholdsInvestorList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newInvestor: false,
      unlinkHouseholdModal: {
        show: false,
        investor: null
      }
    };
    this.toggleUnlinkHouseholdModal = this.toggleUnlinkHouseholdModal.bind(this);
    this.unlinkHousehold = this.unlinkHousehold.bind(this);
    this.getList = this.getList.bind(this);
  }

  unlinkHousehold = investorObject => {
    this.setState(prevState => ({
      unlinkHouseholdModal: { ...prevState.unlinkHouseholdModal, submitting: true }
    }));

    const { investorProvider, prospectProvider, householdProvider } = this.context;
    const { household } = this.props;
    const provider = investorObject.is_prospect ? prospectProvider : investorProvider;

    provider.unlinkHousehold(investorObject.id).then(() => {
      householdProvider
        .get(household.id)
        .then(() => {
          this.unlinkHouseholdModal.modal.hide();
        })
        .finally(() => {
          this.setState(prevState => ({
            unlinkHouseholdModal: { ...prevState.unlinkHouseholdModal, submitting: false }
          }));
        });
    });
  };

  toggleUnlinkHouseholdModal(investor) {
    if (_.isEmpty(investor))
      return this.setState({
        unlinkHouseholdModal: { show: false, submitting: false, investor: null }
      });

    return this.setState({
      unlinkHouseholdModal: {
        show: true,
        investor
      }
    });
  }

  addRow() {
    this.setState({
      newInvestor: true
    });
  }

  addMember(suggestion) {
    const { investorProvider, prospectProvider } = this.context;
    const { household } = this.props;
    const newUserId = suggestion.id;
    const params = {
      id: household.id
    };
    const provider = suggestion.is_prospect ? prospectProvider : investorProvider;
    provider.linkHousehold(newUserId, params).then(() => {
      this.setState({ newInvestor: false });
      this.getList();
    });
  }

  cancelAddMember() {
    this.setState({
      newInvestor: false
    });
  }

  redirectToInvestorPage = (item, tab = 'holdings', note) => {
    this.props.routerActions.push({ pathname: `/advisor/investors/${item}` });

    return false;
  };

  getList() {
    const { householdProvider } = this.context;
    const { household } = this.props;
    return householdProvider.get(household.id);
  }

  render() {
    const {
      allInvestors,
      household,
      householdSuggestedMembers,
      investorAccounts,
      numAccounts,
      portfolioValue
    } = this.props;

    const {
      accountProvider,
      householdProvider,
      investorProvider,
      routerActions,
      showTargetScoreWizard,
      user
    } = this.context;

    const { drift_summary: driftSummary, is_healthy: isHealthy } = household;
    const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';

    let accounts;
    if (household?.accounts?.length) ({ accounts } = household);
    else if (household?.investors?.length)
      accounts = household.investors.reduce((acum, i) => acum.concat(i.accounts || []), []);

    const customHousehold = { ...household, accounts };

    return (
      <div className="households-container">
        <table className="table expandable-table">
          <thead>
            <tr>
              <td width="27%" className="text-sm-left">
                House Member
              </td>
              <td width="24%" className="text-sm-center">
                Account
              </td>
              <td width="17%" rowSpan={1} className="text-sm-right">
                Portfolio Value
              </td>
              <td width="4%" rowSpan={1} />
              <td width="10%" rowSpan={1} className="text-sm-center">
                PRISM
              </td>
              <td width="8%" rowSpan={1} className="text-sm-center">
                Risk Tolerance
              </td>
              <td width="8%" rowSpan={1} className="text-sm-center">
                Drift
              </td>
              <td width="2%" />
            </tr>
          </thead>
          {(investorAccounts.length > 0 ? investorAccounts : []).map((investor, index) => (
            <HouseholdsInvestorListRow
              key={investor.id}
              investor={investor}
              household={household}
              toggleUnlinkHouseholdModal={this.toggleUnlinkHouseholdModal}
              unlinkunlinkHousehold={this.unlinkHousehold}
            />
          ))}

          {this.state.newInvestor && (
            <HouseholdsInvestorListAddRow
              household={this.props.household}
              addMember={this.addMember.bind(this)}
              cancelAddMember={this.cancelAddMember.bind(this)}
              routerActions={routerActions}
              investorProvider={investorProvider}
              accountProvider={accountProvider}
              householdProvider={householdProvider}
              currentUser={user}
              investors={allInvestors}
              householdSuggestedMembers={householdSuggestedMembers}
            />
          )}

          {!this.state.newInvestor && (
            <tbody id="add-row">
              <tr>
                <td onClick={this.addRow.bind(this)}>
                  <a style={{ display: 'flex' }}>
                    <CancelIcon className="plus-symbol" />
                    <span>Add House Members</span>
                  </a>
                </td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          )}
        </table>

        <table className="table households-table table-household-accounts households-total-table">
          <thead>
            <tr className="total">
              <td width="27%" className="text-sm-center">
                Total House Members
              </td>
              <td width="24%" className="text-sm-center">
                Total Accounts&nbsp;
              </td>
              <td width="17%" rowSpan={1} className="text-sm-right">
                Total Value&nbsp;
              </td>
              <td width="4%" />
              <td width="10%" rowSpan={1} className="text-sm-center">
                PRISM Overall
              </td>
              <td width="8%" rowSpan={1} className="text-sm-center">
                Risk Tolerance Overall
              </td>
              <td width="8%" rowSpan={1} className="text-sm-center" />
              <td width="2%" className="text-sm-right " />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-sm-center">{investorAccounts.length}</td>
              <td className="text-sm-center">{numAccounts}</td>
              <td className="text-sm-right">
                <FormattedNumber value={portfolioValue} format="currency" />
              </td>
              <td />
              <td className="text-sm-center">
                <AggregatedRiskScoreBubble element={customHousehold} />
              </td>
              <td className="text-sm-center">
                <AggregatedToleranceScoreBubble
                  element={customHousehold}
                  onClick={() => showTargetScoreWizard()}
                />
              </td>
              <td className={cn('text-sm-center', 'drift', { red: !isHealthy })}>
                <span>{drift}</span>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        {this.state.unlinkHouseholdModal.show && (
          <UnlinkClientModal
            {...this.state.unlinkHouseholdModal}
            onHide={this.toggleUnlinkHouseholdModal}
            onUnlink={this.unlinkHousehold}
            ref={c => (this.unlinkHouseholdModal = c)}
          />
        )}

        <Disclosure />
      </div>
    );
  }
}

HouseholdsInvestorList.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

HouseholdsInvestorList.defaultProps = {
  from: '',
  ordering: '',
  prismOverall: ''
};

HouseholdsInvestorList.propTypes = {
  allInvestors: PropTypes.array.isRequired,
  from: PropTypes.string,
  household: PropTypes.object.isRequired,
  householdSuggestedMembers: PropTypes.array.isRequired,
  investorAccounts: PropTypes.array.isRequired,
  numAccounts: PropTypes.number.isRequired,
  ordering: PropTypes.string,
  portfolioValue: PropTypes.number.isRequired,
  prismOverall: PropTypes.string
};

export default HouseholdsInvestorList;
