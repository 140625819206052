import cn from 'classnames';
import { FormGroup } from 'components/form';
import PropTypes from 'prop-types';
import React from 'react';
import { filterDefaultProps } from '../../types';

const FilterContainer = ({ attrs, children, description, filters, label }) => {
  const filterKeys = Object.keys(filters).filter(
    key => Number.isFinite(filters[key]) || filters[key]
  );
  return (
    <FormGroup className="form-group">
      <label
        className={cn({ 'font-weight-bold': filterKeys.some(key => attrs.includes(key)) })}
        htmlFor={label.toLowerCase().replace(/ /g, '-')}
      >
        {label}
        {description && (
          <>
            <br />
            <span className="font-italic" style={{ fontSize: '0.75rem', maxWidth: 250 }}>
              {description}
            </span>
          </>
        )}
      </label>
      {children}
    </FormGroup>
  );
};

FilterContainer.propTypes = {
  attrs: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
  description: PropTypes.string,
  filters: PropTypes.object,
  label: PropTypes.string.isRequired
};

FilterContainer.defaultProps = { ...filterDefaultProps, description: null };

export default FilterContainer;
