import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const INTEGRATIONS_STAT = 'integrations';

const STAT_URLS = {
  accounts: null,
  clients: '/advisor/investors',
  households: '/advisor/households',
  [INTEGRATIONS_STAT]: '/advisor/integrations/connected',
  prospects: '/advisor/prospects'
};

const UserDetails = ({ advisorId, stats }) => {
  const renderCommonStat = key => {
    const text = `${stats[key]} ${key}`;
    const hasUrl = !!STAT_URLS[key];
    const url = hasUrl ? new URL(STAT_URLS[key], window.location.origin) : null;
    if (url) url.searchParams.set('by_advisor', advisorId);
    return (
      <li key={key}>
        {hasUrl ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ) : (
          text
        )}
      </li>
    );
  };

  const renderIntegrationStat = key => {
    const integrations = Object.entries(stats[key]);
    return integrations.map(([provider, quantity]) => (
      <li key={`integration-${provider.toLowerCase()}`}>
        <a href={STAT_URLS[key]} target="_blank" rel="noopener noreferrer">
          {quantity} {provider} integration
        </a>
      </li>
    ));
  };

  return (
    <div className="advisor-stats__details">
      <ul>
        {Object.keys(stats)
          .filter(key => !!stats[key])
          .map(key =>
            key === INTEGRATIONS_STAT ? renderIntegrationStat(key) : renderCommonStat(key)
          )}
      </ul>
    </div>
  );
};

UserDetails.propTypes = {
  advisorId: PropTypes.number.isRequired,
  stats: PropTypes.object.isRequired
};

export default UserDetails;
