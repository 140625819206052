// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#provider-connection form {
  width: 400px;
  margin: 0 auto;
  padding-top: 2rem;
}
#provider-connection form .form-group {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
#provider-connection form .form-group > label {
  margin: 0.45rem 0;
}
#provider-connection form .form-group > fieldset {
  min-height: 4rem;
}
#provider-connection form .form-group > fieldset input {
  min-width: 15rem;
}
#provider-connection form .error-message {
  margin-bottom: 1.5rem;
}
#provider-connection iframe {
  border: 0;
}
#provider-connection .actions {
  display: flex;
  justify-content: center;
  margin: 1.5rem auto 0;
  max-width: 21rem;
}
#provider-connection .actions > button {
  min-width: 10rem;
  margin: 0 10px;
}
#provider-connection .actions.center {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/integrations-dashboard/providers/styles.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,cAAA;EACA,iBAAA;AAFJ;AAII;EACE,aAAA;EACA,6BAAA;EACA,uBAAA;AAFN;AAIM;EACE,iBAAA;AAFR;AAKM;EACE,gBAAA;AAHR;AAKQ;EACE,gBAAA;AAHV;AAQI;EACE,qBAAA;AANN;AAUE;EACE,SAAA;AARJ;AAWE;EACE,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,gBAAA;AATJ;AAWI;EACE,gBAAA;EACA,cAAA;AATN;AAYI;EACE,uBAAA;AAVN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#provider-connection {\n  form {\n    width: 400px;\n    margin: 0 auto;\n    padding-top: 2rem;\n\n    .form-group {\n      display: flex;\n      justify-content: space-around;\n      align-items: flex-start;\n\n      & > label {\n        margin: 0.45rem 0;\n      }\n\n      & > fieldset {\n        min-height: 4rem;\n\n        input {\n          min-width: 15rem;\n        }\n      }\n    }\n\n    .error-message {\n      margin-bottom: 1.5rem;\n    }\n  }\n\n  iframe {\n    border: 0;\n  }\n\n  .actions {\n    display: flex;\n    justify-content: center;\n    margin: 1.5rem auto 0;\n    max-width: 21rem;\n\n    & > button {\n      min-width: 10rem;\n      margin: 0 10px;\n    }\n\n    &.center {\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
