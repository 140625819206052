import AccountHeader from 'components/advisor/risk-analysis/report/sections/account-header';
import RiskToleranceScoreCapacityAndPersonality from 'components/utils/risk-tolerance-score-capacity-and-personality/pdf';
import { AdvisorContext } from 'containers/advisor';
import DiscussionTopicsTop from 'containers/risk-tolerance-questionnaire/result/discussion-topics/top/pdf';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import { BaseCoverDataPropTypes } from 'reports/base/cover/types';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import { selectQuestionnaire } from 'utils/questionnaire';
import RiskToleranceQuestionnaireContent from '../sections/risk-tolerance-questionnaire-content';

const RiskToleranceQuestionnaireCombinedReport = ({
  accounts,
  coverData,
  discussionTopics,
  investor,
  orientation,
  questionnaires,
  size,
  subtitle,
  title,
  user,
  withCustomBranding
}) => {
  const context = useMemo(
    () => ({
      accounts,
      discussionTopics,
      investor,
      orientation,
      questionnaires,
      user,
      withCustomBranding
    }),
    [
      JSON.stringify(accounts),
      JSON.stringify(discussionTopics),
      JSON.stringify(investor),
      JSON.stringify(questionnaires),
      JSON.stringify(user),
      orientation,
      withCustomBranding
    ]
  );

  const advisor = { ...investor.advisor, company: user.advisor.company };

  return (
    <BaseDocument title={title && subtitle ? `${title} for ${subtitle}` : title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={advisor}
          data={coverData}
          orientation={orientation}
          size={size}
          subtitle={subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={advisor}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
        >
          {accounts.map((account, idx) => (
            <Fragment key={account.id}>
              <AccountHeader account={account} breakSection={!!idx} position={idx + 1} />

              <RiskToleranceScoreCapacityAndPersonality
                investorName={investor?.full_name}
                targetSummary={account.target_score_summary}
                latestUpdate={account?.target_score_updated_at}
              />

              <DiscussionTopicsTop discussionTopics={discussionTopics} />

              <RiskToleranceQuestionnaireContent
                questions={selectQuestionnaire(questionnaires, investor.id, account.id)}
              />
            </Fragment>
          ))}

          {!!user?.advisor?.company?.report_disclosure && (
            <CustomSection
              breakSection
              title="Disclosure"
              body={user.advisor.company.report_disclosure}
            />
          )}
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

RiskToleranceQuestionnaireCombinedReport.propTypes = {
  accounts: PropTypes.array.isRequired,
  coverData: PropTypes.shape(BaseCoverDataPropTypes),
  discussionTopics: PropTypes.array,
  investor: PropTypes.object.isRequired,
  orientation: PropTypes.string,
  questionnaires: PropTypes.object.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

RiskToleranceQuestionnaireCombinedReport.defaultProps = {
  coverData: {},
  discussionTopics: [],
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  title: 'Risk Tolerance Questionnaire',
  withCustomBranding: false
};

export default RiskToleranceQuestionnaireCombinedReport;
