// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  .account-details-tolerance .risk-container {
    display: none;
  }
  .account-details-container .account-details-wrapper .print-target-answers {
    display: block;
  }
  .account-details-container .account-details-wrapper .print-target-answers.hide {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/accounts/details/view/account-details-tolerance/styles.scss"],"names":[],"mappings":"AAAA;EAEI;IACE,aAAA;EAAJ;EAIA;IACE,cAAA;EAFF;EAIE;IACE,aAAA;EAFJ;AACF","sourcesContent":["@media print {\n  .account-details-tolerance {\n    .risk-container {\n      display: none;\n    }\n  }\n\n  .account-details-container .account-details-wrapper .print-target-answers {\n    display: block;\n\n    &.hide {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
