import ModelStrategyStats from 'components/advisor/model-portfolio-strategy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class AdvisorModelStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const { accountProvider, modelProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    Promise.all([
      modelProvider.getStrategyStats(id),
      accountProvider.es.list({ matching_model: id })
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { stats, accounts } = this.props;
    const { loading } = this.state;
    return <ModelStrategyStats loading={loading} stats={stats} distribution={accounts} />;
  }
}

AdvisorModelStrategy.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  stats: PropTypes.object
};

AdvisorModelStrategy.defaultProps = {
  stats: null
};

AdvisorModelStrategy.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired
};

export default connect(state => ({
  stats: state.models.strategyStats,
  accounts: state.accounts.esList
}))(AdvisorModelStrategy);
