import ClientAutocomplete from 'containers/accounts-integration/client-autocomplete';
import PropTypes from 'prop-types';
import React from 'react';

const IntegrationAccountClientCell = ({ integration, onClientAssignment }) => (
  <ClientAutocomplete
    integration={{ id: integration.id, name: integration.name }}
    investorName={integration.account_investor_name}
    key={integration.id}
    onClientAssignment={onClientAssignment}
  />
);

IntegrationAccountClientCell.propTypes = {
  integration: PropTypes.object.isRequired,
  onClientAssignment: PropTypes.func.isRequired
};

export default IntegrationAccountClientCell;
