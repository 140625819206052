import {
  PORTFOLIO_CREATE,
  PORTFOLIO_DELETE,
  PORTFOLIO_EDIT,
  PORTFOLIO_GET,
  PORTFOLIO_LIST,
  PORTFOLIO_UPDATE,
  PORTFOLIO_VIEW
} from 'constants/actions';

export default function portfolios(state = {}, action) {
  switch (action.type) {
    case PORTFOLIO_VIEW:
      return { ...state, view: action.data.data };

    case PORTFOLIO_EDIT:
      return { ...state, edit: action.data };

    case PORTFOLIO_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case PORTFOLIO_GET:
      return { ...state, view: action.data };

    case PORTFOLIO_CREATE:
      return { ...state, view: action.data };

    case PORTFOLIO_UPDATE:
      return { ...state, view: state.view ? action.data : null };

    case PORTFOLIO_DELETE:
      // not implemented
      return state;

    default:
      return state || null;
  }
}
