import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PortfolioComparisonChart from 'components/charts/portfolio-comparison-chart';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';

const ProposalSummary = ({ actionsDisabled, metadata, propertyKey, proposal, setMetadata }) => {
  const isDraft = proposal?.target?.target_label;
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  const hasRecommended = !_.isEmpty(proposal.recommended_analytics);

  return (
    <EditBoxText {...proposalMetadata}>
      <div id="returns-summary">
        <DateValue isDraft={isDraft} proposal={proposal} withManagementFee />
        <div className={cn('fancy-grid', { 'no-recommended': !hasRecommended })}>
          <div className="header" />
          <div className="header">Starting Value</div>
          <div className="header">{proposal.target_label || 'Current Portfolio'}</div>
          {hasRecommended && (
            <>
              <div className="header">{proposal.recommended_label}</div>
              <div className="header">
                {proposal.target_label || 'Current'} vs {proposal.recommended_label}
              </div>
            </>
          )}

          <div className="header">Total Value</div>
          <div>{formatMoney(proposal.starting_value)}</div>
          <div>{formatMoney(proposal.target_analytics.cumulative_value)}</div>
          {hasRecommended && (
            <>
              <div>{formatMoney(proposal.recommended_analytics.cumulative_value)}</div>
              <div>
                <PortfolioComparisonChart
                  id="value-comparison"
                  isDraft={isDraft}
                  initial={proposal.starting_value}
                  target={parseInt(proposal.target_analytics.cumulative_value, 10)}
                  recommended={parseInt(proposal.recommended_analytics.cumulative_value, 10)}
                  withMoneyFormat
                />
              </div>
            </>
          )}

          <div className="header">Annualized Portfolio Return</div>
          <div>-</div>
          <div>{formatPercentage(proposal.target_analytics.summary.annualized_return)}</div>
          {hasRecommended && (
            <>
              <div>
                {formatPercentage(proposal.recommended_analytics.summary.annualized_return)}
              </div>
              <div>
                <PortfolioComparisonChart
                  id="annualized-return-comparison"
                  isDraft={isDraft}
                  target={proposal.target_analytics.summary.annualized_return}
                  recommended={proposal.recommended_analytics.summary.annualized_return}
                />
              </div>
            </>
          )}

          <div className="header">Annualized Portfolio Risk</div>
          <div>-</div>
          <div>{formatPercentage(proposal.target_analytics.summary.annualized_volatility)}</div>
          {hasRecommended && (
            <>
              <div>
                {formatPercentage(proposal.recommended_analytics.summary.annualized_volatility)}
              </div>
              <div>
                <PortfolioComparisonChart
                  id="annualized-risk-comparison"
                  isDraft={isDraft}
                  target={proposal.target_analytics.summary.annualized_volatility}
                  recommended={proposal.recommended_analytics.summary.annualized_volatility}
                  reverse
                />
              </div>
            </>
          )}
        </div>
      </div>
    </EditBoxText>
  );
};

ProposalSummary.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalSummary;
