/* eslint-disable import/prefer-default-export */
const AVG_MARKET_RETURN = 8 / 100;

export const calculateRecovery = (loss, amount) => {
  const lossPercent = loss / 100;
  const lossAmount = (lossPercent * amount).toFixed(0);
  const recoverPercent = Math.abs(lossPercent) / (1 - Math.abs(lossPercent));
  const totalYears = Math.abs(Math.log(1 + recoverPercent) / Math.log(1 + AVG_MARKET_RETURN));

  return {
    lossAmount,
    recoverPercent,
    totalYears: Number(totalYears.toFixed(1))
  };
};
