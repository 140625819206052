import { PRICE_CLEAR, PRICE_GET, PRICE_LIST, PRICE_SET } from 'constants/actions';

const initialState = {
  list: [],
  view: null,
  selected: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRICE_LIST:
      return {
        ...state,
        list: action.data
      };
    case PRICE_GET:
      return {
        ...state,
        view: action.data
      };
    case PRICE_SET:
      return {
        ...state,
        selected: action.data
      };
    case PRICE_CLEAR:
      return {
        ...state,
        selected: null
      };

    default:
      return state;
  }
}
