import React from 'react';
import FilterContainer from '../common/filter-container';
import RiskLevelFilter from '../common/risk-level';
import { ACCOUNT_DRIFT_LEVEL_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const AccountDriftLevelFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer attrs={[ACCOUNT_DRIFT_LEVEL_ATTR]} filters={filters} label="Account Drift Level">
    <RiskLevelFilter
      attr={ACCOUNT_DRIFT_LEVEL_ATTR}
      filters={filters}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

AccountDriftLevelFilter.propTypes = { ...filterPropTypes };

AccountDriftLevelFilter.defaultProps = { ...filterDefaultProps };

export default AccountDriftLevelFilter;
