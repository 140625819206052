import classnames from 'classnames';
import FeatureLimitReachedModal from 'components/advisor/feature-limit';
import { modes } from 'components/advisor/investors/edit';
import ShareAccountEmail from 'components/advisor/investors/share-account-email';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { trackAmplitudeEvent } from 'utils/tracking';
import './styles.scss';

export class CreateAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailsLimitReached: false,
      showEmailForm: false,
      emailSent: false,
      isCopyLink: false
    };
    this.toggleEmailForm = this.toggleEmailForm.bind(this);
    this.verifyEmailsLimit = this.verifyEmailsLimit.bind(this);
    this.hideEmailsLimitReached = this.hideEmailsLimitReached.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.showManualUploadModal = this.showManualUploadModal.bind(this);
    this.routeOnClick = this.routeOnClick.bind(this);
    this.handleCopyUrl = this.handleCopyUrl.bind(this);
    this.renderLinkCopied = this.renderLinkCopied.bind(this);
  }

  componentDidMount() {
    trackAmplitudeEvent('add_account_modal.viewed');
  }

  routeOnClick(to) {
    const { routerActions } = this.props;
    this.modal.hide();
    routerActions.push(to);
  }

  onHideModal() {
    const { onHide } = this.props;
    const { showEmailsLimitReached } = this.state;
    if (!showEmailsLimitReached) {
      this.setState({ showEmailForm: false, emailSent: false, isCopyLink: false });
      onHide();
    }
  }

  showManualUploadModal() {
    const { investor, showManualModal, accountDefault } = this.props;
    trackAmplitudeEvent('add_account.manual');
    this.onHideModal();
    if (accountDefault) return showManualModal(investor, accountDefault, modes.UPDATE);
    return showManualModal(investor, {}, modes.CREATE);
  }

  verifyEmailsLimit() {
    const { user, userProvider } = this.context;
    const isEntry = userProvider.isEntry(user);
    trackAmplitudeEvent('add_account.connect.email');
    if (isEntry && user?.advisor?.company?.available_connections === 0)
      this.setState({ showEmailsLimitReached: true });
    else this.toggleEmailForm();
  }

  hideEmailsLimitReached() {
    this.setState({ showEmailsLimitReached: false });
  }

  toggleEmailForm() {
    const {
      user: {
        advisor: {
          company: { disable_advisor_emails: disableAdvisorEmails }
        }
      }
    } = this.context;

    if (disableAdvisorEmails) {
      toast.info('Sending emails is disabled. Contact your administrator to enable this feature.');
      return;
    }

    const { showEmailForm } = this.state;
    this.setState({ showEmailForm: !showEmailForm });
  }

  sendMail(email, cc, sender, subject, message, investorId) {
    const { clientProvider } = this.context;
    return clientProvider
      .sendConnectInvitation(investorId, { email, cc, sender, subject, message })
      .then(() => this.setState({ emailSent: true, showEmailForm: false }));
  }

  handleCopyUrl() {
    this.setState({ isCopyLink: true });
    trackAmplitudeEvent('add_account.connect.copy_link');
  }

  renderLinkCopied() {
    const { investor } = this.props;
    return (
      <div className="create-account-link-copied-container">
        <h1>
          <span role="img" aria-label="thumbs-up">
            👍
          </span>{' '}
          Link copied!
        </h1>
        <p>Once {investor.full_name} completes accounts linking, you will get an email.</p>
        <button type="button" onClick={this.onHideModal} className="btn btn-primary">
          Sounds great
        </button>
      </div>
    );
  }

  render() {
    const {
      user: {
        advisor: {
          company: { disable_advisor_emails: disableAdvisorEmails, byallaccount_integration }
        }
      }
    } = this.context;
    const { show, investor, fileId, uploadCSVOption, heldAway } = this.props;
    const { emailSent, showEmailForm, showEmailsLimitReached, isCopyLink } = this.state;
    if (!investor) return null;

    const linkToCopy = `${investor.connect_url}${
      investor.connect_url.match('\\?') ? '&' : '?'
    }held_away=${heldAway}`;

    return (
      <>
        <Modal
          id="create-account-modal"
          ref={c => {
            this.modal = c;
          }}
          className={classnames('modal-lg', { 'modal-dialog--action-email': showEmailForm })}
          show={show && !showEmailsLimitReached}
          onHidden={this.onHideModal}
        >
          <ModalHeader />
          <ModalBody>
            {isCopyLink
              ? this.renderLinkCopied()
              : !showEmailForm &&
                !emailSent && (
                  <div>
                    <p className="text-center header">
                      Upload portfolio for {investor.is_prospect ? 'prospect' : ''} client
                    </p>
                    <p className="text-center name">
                      <b>{investor.full_name}</b>
                    </p>
                    <p className="text-center option">Please select an option to upload</p>
                    <div className="input-section">
                      <div className="create-account">
                        <div className="row">
                          <span>
                            Manually input portfolio on the platform
                            <i className="right-arrow" />
                          </span>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.showManualUploadModal}
                          >
                            Input Manually
                          </button>
                        </div>
                        <div className="row">
                          <span>
                            Upload your CSV File
                            <i className="right-arrow" />
                            <a
                              key="getcsvtemplate"
                              href="/csv/account_portfolio.csv"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="download-csv-link"
                            >
                              Download CSV template
                            </a>
                          </span>
                          <input
                            id={fileId}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={e => {
                              uploadCSVOption(e, investor);
                              trackAmplitudeEvent('add_account.csv');
                            }}
                            className="hidden"
                          />
                          <label htmlFor={fileId} className="btn btn-primary">
                            Upload CSV
                          </label>
                        </div>
                        <div className="row investor-create-email-link-container">
                          <span>
                            Invite client to link account(s) <br />
                            <span id="sub-text">
                              {disableAdvisorEmails
                                ? 'Copy the link of the web page'
                                : 'Send an email to the client, or copy the link of the web page'}
                            </span>
                            <i className="right-arrow" />
                          </span>
                          <div>
                            {!disableAdvisorEmails && (
                              <button
                                disabled={!byallaccount_integration}
                                type="button"
                                className="btn btn-primary"
                                onClick={this.verifyEmailsLimit}
                              >
                                Send an email
                              </button>
                            )}
                            <CopyToClipboard text={linkToCopy} onCopy={this.handleCopyUrl}>
                              <button
                                disabled={!byallaccount_integration}
                                type="button"
                                className="btn investor-create-email-link-container__copy-link"
                              >
                                Copy the link
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

            {showEmailForm && (
              <ShareAccountEmail
                investor={investor}
                hideModal={() => this.modal.hide()}
                onSend={this.sendMail}
                heldAway={heldAway}
              />
            )}

            {emailSent && (
              <div>
                <h3 className="text-sm-center">Thank You!</h3>
                <div className="text-sm-center" style={{ 'margin-top': '40px' }}>
                  Email has been sent to {investor.full_name}.
                </div>
                <div className="text-sm-center mt-2">
                  <button
                    type="submit"
                    style={{ padding: '8px 80px', 'margin-top': '30px' }}
                    className="btn btn-primary btn-shadow"
                    onClick={() => this.modal.hide()}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>
        <FeatureLimitReachedModal
          content={{
            kind: 'invitations',
            action: 'sending'
          }}
          show={showEmailsLimitReached}
          onHide={this.hideEmailsLimitReached}
        />
      </>
    );
  }
}

CreateAccountModal.contextTypes = {
  clientProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

CreateAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  showManualModal: PropTypes.func.isRequired,
  investor: PropTypes.object.isRequired,
  routerActions: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  uploadCSVOption: PropTypes.func.isRequired,
  fileId: PropTypes.string,
  accountDefault: PropTypes.object
};

CreateAccountModal.defaultProps = {
  fileId: '',
  accountDefault: null
};

export default CreateAccountModal;
