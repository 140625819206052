import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import DEFAULT_TEMPLATES from '../defaults';
import ReportTemplatesDetail from './detail';
import ReportTemplatesList from './list';
import './styles.scss';
import ReportTemplatePropTypes from './types';

const ReportTemplates = ({ id, router, templates, title, type }) => {
  const { templateProvider } = useContext(AdvisorContext);

  const filteredTemplates = templates.filter(t => t.type === type);

  const create = async () => {
    const values = {
      type,
      content: DEFAULT_TEMPLATES[type],
      settings: DEFAULT_TEMPLATES[`${type}_settings`]
    };
    const errorMessage = 'Something went wrong saving your template';
    try {
      const { data, error } = await templateProvider.create(values);
      if (error) toast.error(errorMessage);
      else {
        toast.success('👍 Great job! A new template was created.');
        router.push({
          pathname: `/advisor/templates/${data.type}/${data.id}/`,
          state: { isNew: true }
        });
      }
    } catch {
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    templateProvider.list({ type });
  }, [type]);

  return (
    <div id="report-templates">
      {!id && (
        <ReportTemplatesList
          templates={filteredTemplates}
          title={title}
          onCreate={create}
          type={type}
        />
      )}
      {id && templates.length ? (
        <ReportTemplatesDetail
          template={filteredTemplates?.find(t => t.id === Number.parseInt(id, 10))}
          title={title}
        />
      ) : null}
    </div>
  );
};

ReportTemplates.propTypes = {
  id: PropTypes.string,
  router: PropTypes.object.isRequired,
  templates: PropTypes.arrayOf(ReportTemplatePropTypes).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

ReportTemplates.defaultProps = {
  id: null
};

export default connect(state => ({
  templates: Object.values(state.templates)
}))(withRouter(ReportTemplates));
