import { TEAM_LIST, TEAM_CREATE, TEAM_EDIT, TEAM_DELETE, TEAM_SELECT } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

export default class TeamProvider extends DataProvider {
  getList() {
    return this.provider.get(`${config.apiBase}teams/`).then(response => {
      this.dispatch(TEAM_LIST, { data: response.data });
      return response.data;
    });
  }

  updateTeam(team) {
    return this.provider.patch(`${config.apiBase}teams/${team.id}/`, team).then(response => {
      this.dispatch(TEAM_EDIT, { data: response.data });
      return response;
    });
  }

  createTeam(team) {
    return this.provider.post(`${config.apiBase}teams/`, team).then(response => {
      this.dispatch(TEAM_CREATE, { data: response.data });
      return response;
    });
  }

  deleteTeam(id) {
    return this.provider.delete(`${config.apiBase}teams/${id}/`).then(response => {
      this.dispatch(TEAM_DELETE, { data: id });
      return response;
    });
  }

  setSelectedTeam(id) {
    this.dispatch(TEAM_SELECT, { data: id });
  }
}
