import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

const ModelPortfolioBenchmark = ({ benchmark, prismOverall, targetOverall }) => {
  const getPosition = score => ({ left: `calc(${score * 10}%` });

  const getDot = (name, prefix, score) => (
    <span>
      <span
        data-tip={name}
        data-for={`${prefix}-benchmark-dot`}
        className={`benchmark-info-${prefix}`}
        style={getPosition(score)}
      />
      <ReactTooltip
        className={`${prefix}-target-tip`}
        effect="solid"
        id={`${prefix}-benchmark-dot`}
        place="top"
      />
    </span>
  );

  const getBenchmarkValue = (benchmark, reverse = false) => (
    <div
      key={`benchmark-value-${benchmark.id}`}
      className={cn(
        'benchmark-scores__badge',
        `score-badge-risk-level-${Math.ceil(benchmark.prism_score_summary.overall)}`,
        { 'badge--reverse': reverse }
      )}
      style={{
        ...getPosition(benchmark.prism_score_summary.overall),
        top: reverse ? '-80px' : '45px'
      }}
    >
      <span>{benchmark.prism_score_summary.overall}</span>
      <div className="badge__tooltip">{benchmark.display_name}</div>
    </div>
  );

  return (
    <div className="benchmark-container box-container">
      <div className="benchmark-heading">Benchmark PRISM Rating &trade;</div>
      <div className="benchmark-scores">
        <div className="score-line">
          {benchmark.length > 0
            ? benchmark
                .filter(b => b.id % 2 === 0 && !_.isEmpty(b.prism_score_summary))
                .map(b => getBenchmarkValue(b, true))
            : ''}

          <div className="score-line-spectrum">
            {prismOverall && getDot('Current PRISM', 'prism', prismOverall)}
            {targetOverall && getDot('Risk Tolerance', 'target', targetOverall)}
          </div>

          {benchmark.length > 0
            ? benchmark
                .filter(b => b.id % 2 !== 0 && !_.isEmpty(b.prism_score_summary))
                .map(b => getBenchmarkValue(b))
            : ''}

          <div className="score-line-legend score-badge-container target-score-badge-container-small">
            <div className="left benchmark-left">
              <strong>1</strong>
            </div>
            <div className="right benchmark-right">
              <strong>10</strong>
            </div>
          </div>
        </div>
      </div>

      <ul className="benchmark-container__legend">
        <li>
          <span className="benchmark-info-prism" />
          Current PRISM
        </li>
        <li className={cn({ 'legend--hide': !targetOverall })}>
          <span className="benchmark-info-target" />
          Risk Tolerance
        </li>
      </ul>
    </div>
  );
};

ModelPortfolioBenchmark.propTypes = {
  prismOverall: PropTypes.number,
  targetOverall: PropTypes.number,
  benchmark: PropTypes.array
};

ModelPortfolioBenchmark.defaultProps = {
  prismOverall: null,
  targetOverall: null,
  benchmark: []
};

export default ModelPortfolioBenchmark;
