import PropTypes from 'prop-types';
import React from 'react';
import { withCurrencyFormat } from 'utils/utils';
import RiskOverall from '../risk-overall';
import './styles.scss';

export const layouts = {
  BOX: 'box',
  ROW: 'row'
};

const RiskAnalysisTargetScenario = ({
  score,
  value,
  name,
  extraOpts,
  layout,
  overallStyles,
  scenario,
  smaller
}) => {
  const marketPerformance = {
    percentage: `${Math.round(scenario.market_performance * 10000) / 100}%`,
    sign: scenario.market_performance > 0 ? '+' : '',
    styles: scenario.market_performance > 0 ? 'up' : 'down'
  };
  const portfolioPerformance = {
    percentage: `${Math.round(scenario.portfolio_performance * 10000) / 100}%`,
    sign: scenario.portfolio_performance > 0 ? '+' : '',
    styles: scenario.portfolio_performance > 0 ? 'up' : 'down'
  };

  return (
    <div className={`scenario-container ${layout}`}>
      <div className="result-container">
        <div className="result-heading">{name}</div>
        <div className="result-scores">
          <RiskOverall
            score={score}
            compact={layout === layouts.BOX}
            options={extraOpts}
            style={overallStyles}
          />
        </div>
      </div>
      {scenario && (
        <div className="score-description">
          In this scenario, the market&apos;s performance was{' '}
          <span className={marketPerformance.styles}>{`${marketPerformance.sign}${
            marketPerformance.percentage
          }`}</span>{' '}
          and this portfolio&apos;s performance may have been{' '}
          <span className={portfolioPerformance.styles}>{`${portfolioPerformance.sign}${
            portfolioPerformance.percentage
          }`}</span>
          {value && (
            <p className="gain-loss">
              Gain/Loss:{' '}
              <span className={value * scenario.portfolio_performance > 0 ? 'up' : 'down'}>
                {portfolioPerformance.sign}
                {withCurrencyFormat(value * scenario.portfolio_performance, 'standard', 0)}
              </span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

RiskAnalysisTargetScenario.propTypes = {
  extraOpts: PropTypes.object,
  layout: PropTypes.string,
  name: PropTypes.string.isRequired,
  overallStyles: PropTypes.object,
  scenario: PropTypes.object,
  score: PropTypes.number.isRequired,
  value: PropTypes.number
};

RiskAnalysisTargetScenario.defaultProps = {
  extraOpts: null,
  layout: layouts.BOX,
  overallStyles: null,
  scenario: null,
  value: null
};

export default RiskAnalysisTargetScenario;
