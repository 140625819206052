import Choice from 'components/form/choice';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';
import './styles.scss';

const FREQUENCY_OPTIONS = [
  { value: 'daily', label: 'Daily' },
  { value: 'monthly', label: 'Monthly' }
];
const VALUE_TYPE_OPTIONS = [
  { value: 'prices', label: 'Prices' },
  { value: 'returns', label: 'Returns' }
];
const VALUE_FORMAT_OPTIONS = [
  { value: 'decimal', label: 'Decimal' },
  { value: 'percentage', label: 'Percentage' }
];
const INTERPOLATION_MODEL_OPTIONS = [
  { value: 'resample', label: 'Resample' },
  { value: 'beta', label: 'Beta based on proxy returns' }
];

const SecurityReturnsParams = ({ onChangeFields, fields }) => {
  const selectedTypeValue =
    VALUE_TYPE_OPTIONS.find(option => option.value === fields.type.value) || null;
  const [fileColumnsOptions, setFileColumnsOptions] = useState([]);

  useEffect(() => {
    if (fields.file_columns && fields.file_columns.value.length > 0) {
      const options = fields.file_has_header.value
        ? fields.file_columns.value.map((column, index) => ({
            value: index,
            label: column
          }))
        : Array.from({ length: fields.file_columns.value.length }, (_, i) => ({
            value: i,
            label: String.fromCharCode(65 + i).toUpperCase() // Label as A, B, C, etc.
          }));

      setFileColumnsOptions(options);
    } else setFileColumnsOptions([]);
  }, [fields.file_columns?.value, fields.file_has_header?.value]);

  const handleDateColumnChange = selectedOption => {
    onChangeFields(fields.date_column)(selectedOption);
    if (fields.file_has_header.value && selectedOption) {
      const columnName = fields.file_columns.value[selectedOption.value];
      fields.date_column_name.onChange(columnName || '');
    } else fields.date_column_name.onChange('');
  };

  const handleValueColumnChange = selectedOption => {
    onChangeFields(fields.value_column)(selectedOption);
    if (fields.file_has_header.value && selectedOption) {
      const columnName = fields.file_columns.value[selectedOption.value];
      fields.value_column_name.onChange(columnName || '');
    } else fields.value_column_name.onChange('');
  };

  const selectedFrequencyValue = FREQUENCY_OPTIONS.find(
    option => option.value === fields.frequency.value
  );

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

  const interpolationIsHiden = true;

  return (
    <section id="security-returns-params-container">
      <div className="risk-score-result-container">
        <div className="returns-params__wrapper">
          <div className="security-margin-wrapper">
            <div className="row">
              <div className="col-12 security-file-header">
                <Choice
                  {...fields.file_has_header}
                  title="The file includes a header row?"
                  toggle={onChangeFields(fields.file_has_header)}
                />
              </div>
            </div>
            <div className="row file-config">
              <div className="col-6">
                <div className="file-type">
                  <h5>Date Column</h5>
                  <Select
                    value={
                      fileColumnsOptions.find(
                        option => option.value === fields.date_column.value
                      ) ||
                      fileColumnsOptions[0] ||
                      null
                    }
                    onChange={handleDateColumnChange}
                    options={fileColumnsOptions}
                    placeholder="Select Date Column"
                    isSearchable={false}
                    styles={{
                      container: styles => ({
                        ...styles,
                        maxWidth: 334
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        height: '38px'
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="file-type">
                  <h5>Price/Return Column</h5>
                  <Select
                    value={
                      fileColumnsOptions.find(
                        option => option.value === fields.value_column.value
                      ) ||
                      fileColumnsOptions[0] ||
                      null
                    }
                    onChange={handleValueColumnChange}
                    options={fileColumnsOptions}
                    placeholder="Select Price/Return Column"
                    isSearchable={false}
                    styles={{
                      container: styles => ({
                        ...styles,
                        maxWidth: 334
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        height: '38px'
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row file-config">
              <div className="col-6">
                <div className="file-type">
                  <h5>Value Type</h5>
                  <Select
                    value={selectedTypeValue}
                    onChange={onChangeFields(fields.type)}
                    options={VALUE_TYPE_OPTIONS}
                    placeholder="Select Value Type"
                    isSearchable={false}
                    styles={{
                      container: styles => ({
                        ...styles,
                        maxWidth: 334
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        height: '38px'
                      })
                    }}
                  />
                  {fields.type.value === 'prices' && (
                    <span className="example__text">Dollar amounts, eg. $123,45</span>
                  )}
                  {fields.type.value === 'returns' && (
                    <span className="example__text">Returns values, eg. 0.02 and 2%</span>
                  )}
                </div>
                <div className="file-frequency">
                  <h5>Frequency</h5>
                  <Select
                    value={selectedFrequencyValue}
                    onChange={onChangeFields(fields.frequency)}
                    options={FREQUENCY_OPTIONS}
                    isSearchable={false}
                    placeholder="Select Frequency"
                    styles={{
                      container: styles => ({
                        ...styles,
                        maxWidth: 334
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        height: '38px'
                      })
                    }}
                  />
                  {fields.frequency.value === 'daily' && (
                    <span className="example__text">End-of-day prices or returns</span>
                  )}
                  {fields.frequency.value === 'monthly' && (
                    <span className="example__text">End-of-month prices or returns</span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="file-format">
                  {fields?.type?.value === 'returns' && (
                    <>
                      <h5>Value Format</h5>
                      <Select
                        value={
                          VALUE_FORMAT_OPTIONS.find(
                            option => option.value === fields.format.value
                          ) || null
                        }
                        onChange={onChangeFields(fields.format)}
                        options={VALUE_FORMAT_OPTIONS}
                        isSearchable={false}
                        placeholder={capitalizeFirstLetter(fields.format.initialValue)}
                        styles={{
                          container: styles => ({
                            ...styles,
                            maxWidth: 334,
                            height: '38px'
                          }),
                          valueContainer: styles => ({
                            ...styles,
                            height: '38px'
                          })
                        }}
                      />
                    </>
                  )}
                  {fields.format.value === 'decimal' && fields?.type?.value === 'returns' && (
                    <span className="example__text">A return of 2% is represented as 0.02</span>
                  )}
                  {fields.format.value === 'percentage' && fields?.type?.value === 'returns' && (
                    <span className="example__text">A return of 2% is represented as 2</span>
                  )}
                </div>
                {fields?.frequency?.value === 'monthly' && !interpolationIsHiden && (
                  <div className="file-interpolation">
                    <h5>Interpolation Model</h5>
                    <Select
                      value={
                        INTERPOLATION_MODEL_OPTIONS.find(
                          option => option.value === fields.interpolation.value
                        ) || null
                      }
                      onChange={onChangeFields(fields.interpolation)}
                      options={INTERPOLATION_MODEL_OPTIONS}
                      isSearchable={false}
                      placeholder={capitalizeFirstLetter(fields.interpolation.initialValue)}
                      styles={{
                        container: styles => ({
                          ...styles,
                          maxWidth: 334,
                          height: '38px'
                        }),
                        valueContainer: styles => ({
                          ...styles,
                          height: '38px'
                        })
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SecurityReturnsParams.defaultProps = {
  fields: {},
  onChangeFields: () => {}
};

SecurityReturnsParams.propTypes = {
  fields: PropTypes.object,
  onChangeFields: PropTypes.func
};

const SecurityReturnsParamsWithOutRout = withRouter(SecurityReturnsParams);

export default SecurityReturnsParamsWithOutRout;
