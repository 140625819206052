import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import { formatNumber } from './utils';

const Row = ({
  benchmark,
  className,
  collapsed,
  color,
  icon,
  isDraft,
  label,
  recommended,
  target,
  toggleRow
}) => {
  const toggle = () => {
    if (toggleRow) toggleRow(label);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className={cn('row', className)} onClick={toggle}>
      <div
        className="col category"
        style={{ borderLeftWidth: 8, borderLeftStyle: 'solid', borderLeftColor: color }}
      >
        {toggleRow && <i className={cn('icon-arrow', { turned: collapsed })} />}
        {icon && <img src={icon} alt={label} />}
        {label}
      </div>
      <div className="col">
        {isDraft && (
          <div
            className={cn('subcol diff', {
              green: target > recommended,
              red: target < recommended
            })}
          >
            {formatNumber(target - recommended, true)}
          </div>
        )}
        <div className="subcol">{formatNumber(target)}</div>
      </div>
      {!_.isNil(recommended) && (
        <div className="col">
          {!isDraft && (
            <div
              className={cn('subcol diff', {
                green: recommended > target,
                red: recommended < target
              })}
            >
              {formatNumber(recommended - target, true)}
            </div>
          )}
          <div className="subcol">{formatNumber(recommended)}</div>
        </div>
      )}
      {!_.isNil(benchmark) && <div className="col">{formatNumber(benchmark)}</div>}
    </div>
  );
};

Row.propTypes = {
  benchmark: PropTypes.number,
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  isDraft: PropTypes.bool,
  label: PropTypes.string.isRequired,
  recommended: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
  toggleRow: PropTypes.func
};

Row.defaultProps = {
  benchmark: null,
  className: '',
  collapsed: false,
  color: 'transparent',
  icon: null,
  isDraft: false,
  toggleRow: null
};

export default Row;
