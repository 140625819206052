import PaginationDropdown from 'components/utils/pagination-dropdown';
import PropTypes from 'prop-types';
import React from 'react';

const CollaborationPagination = ({ className, paginationCallback }) => {
  const handlePagination = ({ value }) => {
    paginationCallback(value);
  };

  return (
    <div>
      <PaginationDropdown
        className={className}
        isSearchable={false}
        onChange={handlePagination}
        styles={{
          container: styles => ({
            ...styles,
            minWidth: 150,
            borderRadius: '0px'
          }),
          control: styles => ({
            ...styles,
            height: '30px',
            borderRadius: '0px',
            minHeight: '35px',
            fontSize: '14px',
            borderColor: '#c8d0d4'
          }),
          singleValue: styles => ({
            ...styles,
            marginTop: '-2px',
            color: '#4f5a5d',
            fontWeight: '500'
          }),
          option: styles => ({ ...styles, fontSize: '14px', fontWeight: '400' })
        }}
      />
    </div>
  );
};

CollaborationPagination.defaultProps = {
  className: ''
};

CollaborationPagination.propTypes = {
  className: PropTypes.string,
  paginationCallback: PropTypes.func.isRequired
};

export default CollaborationPagination;
