// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#invite-user-modal input.form-control {
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/company-users/invite-user-modal/styles.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;AAFJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#invite-user-modal {\n  input.form-control {\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
