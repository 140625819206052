import { StyleSheet } from '@react-pdf/renderer';
import { FOR_LANDSCAPE_ORIENTATION, TABLE_HEADER_BACKGROUND_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  headerCell: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR, marginTop: 10 },
  chart: {
    objectFit: 'scale-down',
    height: 400,
    [FOR_LANDSCAPE_ORIENTATION]: {
      height: 350
    }
  }
});

export default styles;
