// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#RiskToleranceQuestionnaireCombinedReportViewer .modal-dialog {
  min-width: 800px;
}
#RiskToleranceQuestionnaireCombinedReportViewer .modal-body {
  padding: 1rem 0;
}
#RiskToleranceQuestionnaireCombinedReportViewer .modal-content {
  padding: 0;
}
#RiskToleranceQuestionnaireCombinedReportViewer .modal-content .close {
  position: absolute;
  z-index: 999;
  right: 1rem;
  top: 1rem;
}
#RiskToleranceQuestionnaireCombinedReportViewer .btn-outline-primary {
  font-weight: 400;
}
#RiskToleranceQuestionnaireCombinedReportViewer > .btn-outline-primary {
  margin-top: 1.5rem;
}
#RiskToleranceQuestionnaireCombinedReportViewer .arrow-icon {
  padding: 0 0 0.15rem 0.15rem;
}
#RiskToleranceQuestionnaireCombinedReportViewer #add-account-tooltip {
  text-align: left;
  font-size: 0.95rem;
  padding: 1rem 2rem;
}
#RiskToleranceQuestionnaireCombinedReportViewer #add-account-tooltip .tooltip-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.25rem;
  pointer-events: auto;
  cursor: pointer;
}

.combined-risk-tolerance-portal {
  display: none;
}
.combined-risk-tolerance-portal .print-target-answers {
  position: absolute;
}
@media print {
  .combined-risk-tolerance-portal {
    display: block;
  }
  .combined-risk-tolerance-portal .print-target-answers {
    position: relative;
    page-break-after: always;
  }
  .combined-risk-tolerance-portal .print-target-questionnaire {
    page-break-after: always;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/risk-tolerance-questionnaire/report/combined/viewer/styles.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,UAAA;AAJJ;AAMI;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAJN;AAQE;EACE,gBAAA;AANJ;AASE;EACE,kBAAA;AAPJ;AAUE;EACE,4BAAA;AARJ;AAWE;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;AATJ;AAWI;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;AATN;;AAcA;EACE,aAAA;AAXF;AAaE;EACE,kBAAA;AAXJ;AAcE;EAPF;IAQI,cAAA;EAXF;EAaE;IACE,kBAAA;IACA,wBAAA;EAXJ;EAcE;IACE,wBAAA;EAZJ;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#RiskToleranceQuestionnaireCombinedReportViewer {\n  .modal-dialog {\n    min-width: 800px;\n  }\n\n  .modal-body {\n    padding: 1rem 0;\n  }\n\n  .modal-content {\n    padding: 0;\n\n    .close {\n      position: absolute;\n      z-index: 999;\n      right: 1rem;\n      top: 1rem;\n    }\n  }\n\n  .btn-outline-primary {\n    font-weight: 400;\n  }\n\n  & > .btn-outline-primary {\n    margin-top: 1.5rem;\n  }\n\n  .arrow-icon {\n    padding: 0 0 0.15rem 0.15rem;\n  }\n\n  #add-account-tooltip {\n    text-align: left;\n    font-size: 0.95rem;\n    padding: 1rem 2rem;\n\n    .tooltip-close-button {\n      position: absolute;\n      top: 0.5rem;\n      right: 0.5rem;\n      width: 1.25rem;\n      pointer-events: auto;\n      cursor: pointer;\n    }\n  }\n}\n\n.combined-risk-tolerance-portal {\n  display: none;\n\n  .print-target-answers {\n    position: absolute;\n  }\n\n  @media print {\n    display: block;\n\n    .print-target-answers {\n      position: relative;\n      page-break-after: always;\n    }\n\n    .print-target-questionnaire {\n      page-break-after: always;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
