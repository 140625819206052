import { customButton, digest, getEditorOptions } from 'components/utils/wysiwyg/options';
import {
  IPS_REVIEW_EMAIL,
  QUESTIONNAIRE_EMAIL
} from 'containers/advisor/templates/company-meta/contants';
import { validation } from 'utils/form';

export const QUESTIONNAIRE_URL = '%QUESTIONNAIRE_URL%';
export const REVIEW_URL = '%REVIEW_URL%';

export const emailEditorOptions = (target = '') => {
  const buttons = {};
  if (target === QUESTIONNAIRE_EMAIL)
    buttons.questionnaireURL = customButton('Questionnaire URL', QUESTIONNAIRE_URL);
  if (target === IPS_REVIEW_EMAIL) buttons.reviewURL = customButton('Review URL', REVIEW_URL);
  return getEditorOptions({ buttons });
};

const BUTTON_STYLE = `
  padding: 0 10px;
  background-color: #09acf8;
  color: white;
  width: 150px;
  line-height: 40px;
  height: 40px;
  font-size: 16px;
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;`;

export const digestMessage = (message, data, preview = false) => {
  const values = {};
  if (data.investor && data.investor.id) values.client = data.investor.user || data.investor;
  if (data.advisor) {
    values.advisor = data.advisor.user;
    values.company = {
      name: data.advisor.company_name || data.advisor.company?.name
    };
  }
  if (data.action) {
    const button = preview
      ? `<span style="${BUTTON_STYLE}" class="action-button">${data.action.text}</span>`
      : `<a href="${data.action.url}" style="${BUTTON_STYLE}" class="action-button">${data.action.text}</a>`;
    values[QUESTIONNAIRE_URL] = button;
    values[REVIEW_URL] = button;
  }

  let digestedMessage = digest(message, values);

  // append the disclosure if there is one
  if (data.disclosure)
    digestedMessage = `${digestedMessage}<br /><br /><br /><div style="font-size: 11px;">${data.disclosure}</div>`;

  return digestedMessage;
};

export const validateMessage = message =>
  validation.required(message) || validation.nonEmptyHtml(message);
