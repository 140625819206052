import {
  CLASSIFICATIONS_GET,
  CLASSIFICATIONS_TAXONOMY_GET,
  SECURITY_BENCHMARKS_LIST,
  SECURITY_GET,
  SECURITY_LIST_ES,
  SECURITY_LOADED_CSV_POSITIONS,
  SECURITY_SEARCH
} from 'constants/actions';

const initialState = {
  benchmarks: [],
  esList: [],
  positions: null
};

export function marketSecurities(state = initialState, action) {
  switch (action.type) {
    case SECURITY_SEARCH:
      return { ...state, search: action.data };

    case SECURITY_GET:
      return { ...state, view: action.data };

    case SECURITY_BENCHMARKS_LIST:
      return { ...state, benchmarks: action.data };

    case SECURITY_LOADED_CSV_POSITIONS:
      return { ...state, ...action.data };

    case SECURITY_LIST_ES:
      return { ...state, esList: action.data };

    default:
      return state;
  }
}

export default function market(state = {}, action) {
  if (action.type === CLASSIFICATIONS_GET) return { ...state, classifications: action.data };

  if (action.type === CLASSIFICATIONS_TAXONOMY_GET)
    return { ...state, taxonomies: action.data || [] };

  return { ...state, securities: marketSecurities(state.securities, action) };
}
