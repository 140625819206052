// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-fallback {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px;
}
.error-fallback .error-fallback__brand {
  width: 300px;
  margin-bottom: 75px;
}
.error-fallback .error-fallback__content {
  max-width: 1200px;
}
.error-fallback .error-fallback__content p {
  margin-bottom: 0;
}
.error-fallback .error-fallback__content span {
  font-weight: 400;
}
.error-fallback .error-fallback__content pre {
  width: 100%;
  max-height: 400px;
  margin: 1.5rem auto 2rem auto;
  padding: 20px 30px;
  background-color: white;
  border-radius: 5px;
  white-space: pre-wrap;
  box-shadow: 0 25px 50px 0 rgba(62, 62, 62, 0.15);
}
.error-fallback .error-fallback__content button {
  min-width: 150px;
  margin-top: 2rem;
}
.error-fallback .error-fallback__content.production {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/error-fallback/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;AADF;AAGE;EACE,YAAA;EACA,mBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAKI;EACE,gBAAA;AAHN;AAMI;EACE,WAAA;EACA,iBAAA;EACA,6BAAA;EACA,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qBAAA;EACA,gDAAA;AAJN;AAOI;EACE,gBAAA;EACA,gBAAA;AALN;AAQI;EACE,WAAA;EACA,kBAAA;AANN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.error-fallback {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  width: fit-content;\n  min-height: 100vh;\n  margin: 0 auto;\n  padding: 30px;\n\n  .error-fallback__brand {\n    width: 300px;\n    margin-bottom: 75px;\n  }\n\n  .error-fallback__content {\n    max-width: 1200px;\n\n    p {\n      margin-bottom: 0;\n    }\n\n    span {\n      font-weight: 400;\n    }\n\n    pre {\n      width: 100%;\n      max-height: 400px;\n      margin: 1.5rem auto 2rem auto;\n      padding: 20px 30px;\n      background-color: white;\n      border-radius: 5px;\n      white-space: pre-wrap;\n      box-shadow: 0 25px 50px 0 rgba(62, 62, 62, 0.15);\n    }\n\n    button {\n      min-width: 150px;\n      margin-top: 2rem;\n    }\n\n    &.production {\n      width: 100%;\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
