/* eslint-disable class-methods-use-this */
import config from 'app/config';
import clone from 'clone';
import PropTypes from 'prop-types';
import { withCurrencyFormat } from 'utils/utils';
import Chart from '..';

const { serial } = config.chart;

class SingleYearOutcomeChart extends Chart {
  getOptions = () => {
    const { amount } = this.props;
    const defaultOptions = { ...clone(serial.returnInvestmentHorizon, false) };
    const graphs = defaultOptions.graphs.map(element => ({
      ...element,
      fixedColumnWidth: 55,
      labelFunction({ values: { value } }) {
        return withCurrencyFormat((value * amount) / 100, 'standard', 0);
      }
    }));
    return { ...defaultOptions, graphs };
  };
}

SingleYearOutcomeChart.propTypes = {
  amount: PropTypes.number.isRequired
};

export default SingleYearOutcomeChart;
