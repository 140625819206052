import classnames from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import TooltipV2 from 'components/tooltip-v2';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { selectTargetScore } from 'utils/utils';
import './styles.scss';

const AggregatedToleranceScoreBubble = ({ element, className, onClick, overview, scoreName }) => {
  // investors, prospects, goals, households, etc have aggregated scores
  const isClient = !_.isEmpty(element.user) && !_.isEmpty(element.advisor);
  const isHousehold = !_.isEmpty(element.accounts) && !_.isEmpty(element.investors);
  const { accounts = [] } = element;

  // evaluate the accounts. This will be used for determine the widgets to show.
  const hasAccounts = !!accounts.length;
  let allWithTarget = hasAccounts;
  let allSent = hasAccounts;
  let allExcluded = hasAccounts;
  let someManual = false;
  let someFromQuestionnaire = false;
  let scoreData;

  if (element.aggregated_target_scores && !_.isEmpty(element.aggregated_target_scores)) {
    scoreData = element.aggregated_target_scores;
    someManual = !element.target_questionnaire_score;
  } else if (element.investor_target_score && !_.isEmpty(element.investor_target_score)) {
    scoreData = element.investor_target_score.data;
    someManual = element.investor_target_score.manual;
  }

  if (hasAccounts)
    accounts.forEach(a => {
      if ((!a.target_score_summary || _.isEmpty(a.target_score_summary)) && !a.excluded)
        allWithTarget = false;
      if (a.target_score_manual && !a.excluded) someManual = true;
      if (!a.target_score_manual && !_.isEmpty(a.target_score_summary) && !a.excluded)
        someFromQuestionnaire = true;
      if (allSent && !a.target_questionnaire_mail_sent && !a.excluded) allSent = false;
      if (allExcluded && !a.excluded) allExcluded = false;
    });
  else if (!_.isEmpty(element.investor_target_score)) {
    someFromQuestionnaire = !someManual;
    allWithTarget = true;
  }

  const onClickHandler = () => onClick(element);

  const score = selectTargetScore(scoreData, scoreName);
  const tooltipId = `target-score-tooltip-${element.id}`;

  if (score && score !== 0) {
    const url = `/advisor/${element.is_prospect ? 'prospects' : 'investors'}/${
      element.id
    }/risk-tolerance`;

    const bubble = (
      <ScoreBubble
        score={score}
        className={classnames('tolerance-bubble -aggregated', className, {
          '-manual': someManual && !someFromQuestionnaire
        })}
        url={isHousehold ? null : url}
      />
    );

    if (!isClient && !isHousehold) return bubble;

    if (someManual && !someFromQuestionnaire)
      return (
        <TooltipV2
          className="target-score-tooltip"
          id={tooltipId}
          label="Risk tolerance manually entered"
          place="top"
        >
          <span
            className="manual-score-box"
            data-for={tooltipId}
            data-testid="manual-score-box"
            data-tip=""
          >
            {bubble}
          </span>
        </TooltipV2>
      );

    if (hasAccounts && !allWithTarget)
      return (
        <TooltipV2
          className="target-score-tooltip"
          id={tooltipId}
          label="Missing risk tolerance for some of the accounts"
          place="top"
        >
          <span
            className="score-with-exclamation"
            data-for={tooltipId}
            data-tip=""
            onClick={onClickHandler}
            onKeyUp={() => {}}
            role="button"
            tabIndex="0"
          >
            <i className="fs-icon-exclamation-circle" />
            {bubble}
          </span>
        </TooltipV2>
      );

    return bubble;
  }

  if (allExcluded) return null;

  if (!overview && (element.target_questionnaire_mail_sent || allSent)) {
    let sentDate = element.target_questionnaire_mail_sent_date;
    if (!sentDate && element.accounts && element.accounts.length)
      sentDate = element.accounts[0].target_questionnaire_mail_sent_date;
    if (!sentDate) sentDate = '2020-01-01';

    return (
      <TooltipV2
        className="target-score-tooltip"
        id={tooltipId}
        label={`Questionnaire sent to the client ${moment
          .utc(sentDate)
          .fromNow()}.\nClick the icon to re-send.`}
        place="top"
      >
        <span
          className="target-email-sent"
          data-for={tooltipId}
          data-tip=""
          onClick={onClickHandler}
          onKeyUp={() => {}}
          role="button"
          tabIndex="0"
        >
          <img src="/img/pending_button.svg" alt="Email Sent" />
        </span>
      </TooltipV2>
    );
  }

  if (onClick)
    return (
      <button
        className="btn table-btn get-target-score-btn btn-solid"
        onClick={onClickHandler}
        type="button"
      >
        +
      </button>
    );

  return null;
};

AggregatedToleranceScoreBubble.defaultProps = {
  className: '',
  scoreName: '',
  onClick: null,
  overview: false
};

AggregatedToleranceScoreBubble.propTypes = {
  className: PropTypes.string,
  element: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  overview: PropTypes.bool,
  scoreName: PropTypes.string
};

export default AggregatedToleranceScoreBubble;
