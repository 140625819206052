// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-household-modal .btn-success {
  margin: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/households/csv-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ","sourcesContent":[".success-household-modal {\n  .btn-success {\n    margin: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
