import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { FOLLOWEE, FOLLOWERS } from '../constants';
import columns from './columns';
import './styles.scss';

const AccessEndorsementsList = ({ data, defaultPageSize, onOpenModal, querySearch }) => (
  <ReactTable
    columns={columns(onOpenModal)}
    data={data}
    defaultFilterMethod={(_, row) =>
      [FOLLOWEE, FOLLOWERS].some(filter =>
        row[filter].toLowerCase().includes(querySearch.toLowerCase())
      )
    }
    defaultPageSize={defaultPageSize}
    filtered={[{ id: FOLLOWERS, value: querySearch }]}
    minRows={1}
    noDataText={<div style={{ height: 50 }}>No endorsements found.</div>}
    pageSize={defaultPageSize}
    resizable={false}
    showPageSizeOptions={false}
    showPagination
    sortable
  />
);

AccessEndorsementsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  querySearch: PropTypes.string.isRequired
};

export default AccessEndorsementsList;
