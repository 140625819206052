import config from 'app/config';
import {
  investorAccountsSelector,
  investorAccountsWithPrismSelector
} from 'components/advisor/investors/selectors';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { combinePortfolios, weightedMean } from 'utils/utils';

export const investorsGoalsSelector = state => state.investorsGoals.investors;

export const totalAssetsSelector = createSelector([investorAccountsSelector], accounts => {
  if (_.isEmpty(accounts)) return 0;
  return accounts
    .filter(account => account.excluded === false)
    .reduce((acc, account) => acc + account.value, 0);
});

export const mostRiskyPositionsSelector = createSelector([investorAccountsSelector], accounts => {
  const riskyPositions = [];
  if (_.isEmpty(accounts)) return null;
  accounts.forEach(a => {
    if (!_.isEmpty(a.most_risky_positions)) {
      const positions = a.most_risky_positions.map(riskyPosition => {
        const positionInPortfolio = a.positions.find(p => p.ticker === riskyPosition.ticker);
        return {
          ...riskyPosition,
          account: { displayName: a.display_name, id: a.id },
          value: positionInPortfolio.value,
          prismScoreSummary: positionInPortfolio.prism_score_summary
        };
      });
      riskyPositions.push(...positions);
    }
  });
  return [...riskyPositions].sort((a, b) => b.risk - a.risk);
});

export const scenariosSelector = createSelector([investorAccountsWithPrismSelector], accounts => {
  const { prism: prismConfig } = config;
  const scenarios = [];
  prismConfig.scenarios.forEach((name, i) => {
    // consider prism for prism with missings scenarios
    if (!accounts.reduce((b, a) => !!a.prism_score.output[name] && b, true)) return;
    const scenario = {
      name,
      score: weightedMean(
        accounts.map(a => a.prism_score.output[name].risk_scores[0].overall_score),
        accounts.map(a => a.value)
      ),
      key: `scenario-${i}`
    };
    scenarios.push(scenario);
  });

  return scenarios;
});

export const combinedPortfoliosSelector = createSelector([investorAccountsSelector], accounts => {
  if (!accounts || _.isEmpty(accounts)) return null;
  return combinePortfolios(accounts);
});
