import React from 'react';
import FilterContainer from '../common/filter-container';
import RangeValueFilter from '../common/range-value';
import { ACCOUNT_TOTAL_MAX_ATTR, ACCOUNT_TOTAL_MIN_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const AccountTotalFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[ACCOUNT_TOTAL_MAX_ATTR, ACCOUNT_TOTAL_MIN_ATTR]}
    filters={filters}
    label="Account Total"
  >
    <RangeValueFilter
      filters={filters}
      maxAttr={ACCOUNT_TOTAL_MAX_ATTR}
      minAttr={ACCOUNT_TOTAL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

AccountTotalFilter.propTypes = { ...filterPropTypes };

AccountTotalFilter.defaultProps = { ...filterDefaultProps };

export default AccountTotalFilter;
