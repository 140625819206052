// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis-container {
  display: flex;
  gap: 10px;
}
.analysis-container .RiskOverall {
  display: flex;
  flex-direction: column;
}
.analysis-container .RiskOverall .speedometer {
  height: 120px;
}
.analysis-container .RiskOverall .overall-prism-target {
  text-align: center;
}
.analysis-container .risk-analysis-target-score {
  flex: 1 1 25%;
}
.analysis-container .risk-analysis-target-score .box-heading {
  text-align: center;
}
.analysis-container .risk-analysis-target-score .box-content {
  flex-direction: column;
}
.analysis-container .risk-analysis-target-score .box-content .box-element.box-pie {
  padding: 0;
}
.analysis-container .risk-analysis-target-score .box-content .box-element.score-description {
  margin: 4px 0 0;
  height: 185px;
  flex: inherit;
  font-size: 13px;
  align-items: flex-start;
}
.analysis-container .risk-analysis-target-score .box-content .box-element.box-analysis {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/risk-analysis/prism-risk-analysis/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;AACJ;AACI;EACE,aAAA;AACN;AAEI;EACE,kBAAA;AAAN;AAIE;EACE,aAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAKI;EACE,sBAAA;AAHN;AAMQ;EACE,UAAA;AAJV;AAOQ;EACE,eAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;AALV;AAQQ;EACE,aAAA;AANV","sourcesContent":[".analysis-container {\n  display: flex;\n  gap: 10px;\n\n  .RiskOverall {\n    display: flex;\n    flex-direction: column;\n\n    .speedometer {\n      height: 120px;\n    }\n\n    .overall-prism-target {\n      text-align: center;\n    }\n  }\n\n  .risk-analysis-target-score {\n    flex: 1 1 25%;\n\n    .box-heading {\n      text-align: center;\n    }\n\n    .box-content {\n      flex-direction: column;\n\n      .box-element {\n        &.box-pie {\n          padding: 0;\n        }\n\n        &.score-description {\n          margin: 4px 0 0;\n          height: 185px;\n          flex: inherit;\n          font-size: 13px;\n          align-items: flex-start;\n        }\n\n        &.box-analysis {\n          display: none;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
