import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './styles.scss';

const MAX_CC_COMPANY_EMAILS_ALLOWED = 1;

const CompanyCcEmails = ({ onSubmitEmails, savedEmailAddresses }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddresses, setEmailAddresses] = useState([]);

  useEffect(() => {
    if (savedEmailAddresses !== '') setEmailAddresses(savedEmailAddresses.split(','));
  }, [savedEmailAddresses]);

  const handleAddEmail = event => {
    event.preventDefault();
    if (emailAddress !== '') {
      emailAddresses.push(emailAddress);
      onSubmitEmails({ cc_email_addresses: emailAddresses.join(',') });
    }
  };

  const handleOnChange = event => {
    setEmailAddress(event.target.value);
  };

  const handleRemoveEmail = () => {
    setEmailAddresses([]);
    onSubmitEmails({ cc_email_addresses: '' });
  };

  return (
    <div id="CompanyCcEmails">
      <h1>
        BCC for company email{' '}
        <small>
          All emails will be forwarded to the following email address for compliance purpose.
        </small>
      </h1>
      <div className="content">
        <div className="row">
          <div className="col">
            <form onSubmit={handleAddEmail} autoComplete="off">
              <div className="form-row row-cc-email">
                <div className="form-group col-md-5">
                  <input
                    type="email"
                    aria-label="email"
                    placeholder="Email address"
                    disabled={emailAddresses.length >= MAX_CC_COMPANY_EMAILS_ALLOWED}
                    className="form-control"
                    onChange={handleOnChange}
                    value={emailAddress}
                  />
                </div>
                <button
                  type="submit"
                  aria-label="save-email"
                  disabled={emailAddresses.length >= MAX_CC_COMPANY_EMAILS_ALLOWED}
                  className="btn btn-primary col-md-2"
                >
                  Save Email
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="email-addresses" aria-label="email-addresses">
              {emailAddresses.length > 0
                ? emailAddresses.map(email => (
                    <div className="email-tag" key={email}>
                      <p className="email-tag__text">{email}</p>
                      <button
                        type="button"
                        aria-label="remove-email"
                        onClick={handleRemoveEmail}
                        className="email-tag__remove"
                      >
                        ✖
                      </button>
                    </div>
                  ))
                : 'Email address has not been configured'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyCcEmails.propTypes = {
  onSubmitEmails: PropTypes.func.isRequired,
  savedEmailAddresses: PropTypes.string.isRequired
};

export default CompanyCcEmails;
