// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signature-request-modal h3 {
  padding: 2rem 5.625rem 0 5.625rem;
  font-size: 22px;
}
#signature-request-modal .modal-dialog {
  min-width: 800px;
}
#signature-request-modal .modal-dialog .modal-content {
  padding: 1rem 0;
}
#signature-request-modal .draft-status-disclaimer {
  font-size: 12px;
  margin: 15px 50px;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/proposal/header/investor/form/signature-request-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,iCAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAKE;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAHJ","sourcesContent":["#signature-request-modal {\n  h3 {\n    padding: 2rem 5.625rem 0 5.625rem;\n    font-size: 22px;\n  }\n\n  .modal-dialog {\n    min-width: 800px;\n\n    .modal-content {\n      padding: 1rem 0;\n    }\n  }\n\n  .draft-status-disclaimer {\n    font-size: 12px;\n    margin: 15px 50px;\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
