import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getFilterSubtitle } from 'utils/utils';
import CreateHouseholdModal from '../create-modal';
import HouseholdsCSVModal from '../csv-modal';
import './styles.scss';

class HouseholdsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { showCreateHouseholdModal: false, showCSVModal: false };
  }

  refreshList = page => () => {
    const { householdProvider } = this.context;
    const { meta } = this.props;
    if (page > 0) householdProvider.list({ ...(meta?.params || {}), page });
  };

  showCreateHouseholdModal = () => this.setState({ showCreateHouseholdModal: true });

  hideCreateHouseholdModal = () => this.setState({ showCreateHouseholdModal: false });

  showCSVModal = () => this.setState({ showCSVModal: true });

  hideCSVModal = () => this.setState({ showCSVModal: false });

  onCSVSubmitted = () => {
    const { householdProvider } = this.context;
    const { meta } = this.props;
    householdProvider.list(meta.params);
  };

  render() {
    const { householdProvider, investorProvider, location } = this.context;
    const { meta, householdSuggestedMembers } = this.props;
    const { showCreateHouseholdModal, showCSVModal } = this.state;

    const subtitle = getFilterSubtitle(location.query);

    return (
      <div className="households-header">
        <h2 className="households-header__title">
          Households
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </h2>
        <div className="households-header__actions">
          <button
            type="button"
            className="btn btn-primary household-button"
            onClick={this.showCreateHouseholdModal}
          >
            Create a Household
          </button>
          <button
            type="button"
            className="btn btn-secondary household-button"
            onClick={this.showCSVModal}
          >
            CSV Update
          </button>
        </div>

        <CreateHouseholdModal
          householdProvider={householdProvider}
          householdsMeta={meta}
          householdSuggestedMembers={householdSuggestedMembers}
          investorProvider={investorProvider}
          onHidden={this.hideCreateHouseholdModal}
          show={showCreateHouseholdModal}
        />

        <HouseholdsCSVModal
          className="update-modal"
          close={this.hideCSVModal}
          householdProvider={householdProvider}
          onSubmitted={this.onCSVSubmitted}
          show={showCSVModal}
          updateHouseholds={this.refreshList(1)}
        />
      </div>
    );
  }
}

HouseholdsHeader.contextTypes = {
  householdProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

HouseholdsHeader.propTypes = {
  households: PropTypes.array.isRequired,
  householdSuggestedMembers: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired
};

export default HouseholdsHeader;
