import MultiCheckboxField from 'components/form/multi-checkbox-field';
import PropTypes from 'prop-types';
import React from 'react';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useLossReaction from '../hooks';

const LossReactionQuestion = ({ question, field, ...props }) => {
  const { choices, questionText } = useLossReaction({ question });

  return (
    <Question
      {...props}
      question={{ ...question, question: questionText }}
      nextDisabled={field.invalid}
      className="choice"
    >
      <MultiCheckboxField
        field={field}
        options={choices}
        multiple={question.data?.multiple_answers_allowed}
      />
    </Question>
  );
};

LossReactionQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default LossReactionQuestion;
