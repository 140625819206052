import LoadingWave from 'components/loading-wave';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router';
import './styles.scss';

const EmailVerification = ({ params: { verificationKey } }) => {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const { authProvider } = useContext(AuthenticationContext);

  const setError = () => {
    setVerified(false);
    setLoading(false);
  };

  useEffect(() => {
    async function verifyEmail() {
      setLoading(true);

      try {
        const verification = await authProvider.verifyEmail(verificationKey);
        if (verification?.data?.detail === 'ok') setVerified(true);
      } catch {
        setError();
        return;
      }

      try {
        const user = await authProvider.getUser(true);
        if (user?.id) setAuthenticated(true);
      } catch {
        // mute login attempt errors
      }

      setLoading(false);
    }

    verifyEmail();
  }, [verificationKey, authProvider]);

  return (
    <div id="EmailVerification">
      {loading && (
        <div>
          <p>Validating...</p>
          <LoadingWave />
        </div>
      )}
      {!loading && !verified && (
        <div>
          <h1 className="text-sm-center signup-title">Sorry!</h1>
          <p>We could not verify your email. Please try again.</p>
          <p>
            If the problem persists, please contact StratiFi customer support at{' '}
            <CustomerSupportEmailLink /> or call <CustomerSupportPhoneLink />.
          </p>
        </div>
      )}
      {!loading && verified && !authenticated && (
        <div>
          <span role="img" aria-label="tada">
            🎉
          </span>
          <h1 className="text-sm-center signup-title mb-1">Congratulations!</h1>
          <p className="mb-2">Your email address was successfully verified!</p>
          <Link className="btn btn-primary btn-fixed-width" to="/signin">
            Sign in
          </Link>
        </div>
      )}
      {!loading && verified && authenticated && (
        <div>
          <p>You will be redirected automatically...</p>
          <LoadingWave />
          <Link className="btn btn-primary btn-fixed-width mt-2" to="/checkout">
            Go to checkout
          </Link>
        </div>
      )}
    </div>
  );
};

EmailVerification.contextTypes = {
  authProvider: PropTypes.object.isRequired
};

EmailVerification.propTypes = {
  params: PropTypes.shape({
    verificationKey: PropTypes.string.isRequired
  }).isRequired
};

export default EmailVerification;
