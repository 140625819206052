import PropTypes from 'prop-types';
import React from 'react';

const LanguageButton = ({ currentLocale, locale, handleLocaleChange }) => (
  <button
    type="button"
    className="btn btn-link"
    disabled={currentLocale === locale}
    onClick={handleLocaleChange}
  >
    {locale}
  </button>
);

LanguageButton.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  handleLocaleChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};

export default LanguageButton;
