import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import './styles.scss';

const EnhancedDropzone = ({ accept, children, maxSize, onDrop, maxFiles, showPreviews }) => {
  const [files, setFiles] = useState([]);

  const handleClear = () => {
    setFiles([]);
  };

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach(file => {
          if (file.errors[0].code === 'file-too-large')
            toast.error(`File is too large. Maximum size is ${maxSize / 1024 / 1024}MB.`);
          else if (file.errors[0].code === 'file-invalid-type')
            toast.error(`Invalid file type. Accepted types are ${accept}.`);
        });

        if (rejectedFiles.length > maxFiles) {
          toast.error(`You can only upload up to ${maxFiles} files.`);
          return;
        }
      }

      const mappedFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: file.name + new Date().getTime() // Ensure unique key
        })
      );

      setFiles(mappedFiles);
      onDrop(mappedFiles);
    },
    [accept, maxSize, maxFiles, onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    onDrop: handleDrop,
    maxFiles
  });

  const previews =
    showPreviews &&
    files.map(file => (
      <div key={file.id} className="dropzone-preview">
        <img src={file.preview} alt="Preview" />
      </div>
    ));

  return (
    <div id="dropzone-enhanced">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {(!showPreviews || previews.length === 0) && children}
        {showPreviews && previews.length > 0 && (
          <div className="dropzone-preview-container">
            <div>{previews}</div>
          </div>
        )}
      </div>
      {showPreviews && previews.length > 0 && (
        <div id="dropzone-previews-controls">
          <button
            aria-label="Clear Sign"
            className="trash-btn btn btn-secondary"
            onClick={handleClear}
            title="Clear"
            type="button"
          >
            <TrashCanIcon className="signature-icon" title="Clear" />
          </button>
        </div>
      )}
    </div>
  );
};

EnhancedDropzone.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.any.isRequired,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  showPreviews: PropTypes.bool
};

EnhancedDropzone.defaultProps = {
  accept: 'image/jpeg,image/png',
  maxSize: Infinity,
  maxFiles: Infinity,
  showPreviews: false
};

export default EnhancedDropzone;
