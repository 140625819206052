import { StyleSheet } from '@react-pdf/renderer';
import { LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  chart: {
    flexGrow: 1,
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: LIGHT_GRAPHITE_COLOR
  },
  bar: { width: 55 },
  text: { textAlign: 'center', marginTop: 5 }
});

export default styles;
