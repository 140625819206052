export const ACTIVE_2FA = {
  TITLE: 'Two-Factor Authentication',
  CHOOSE_METHOD_TITLE: 'How Would You Like to Secure Your Account?',
  CHOOSE_METHOD: 'Choose how you will confirm it is you when you log in on the web.',
  DESCRIPTION: `Two-factor authentication is an enhanced security measure. Once enabled, you'll be required to give two types of identification when you log in to StratiFi.`,
  AUTHENTICATION_APP_METHOD: `Enter a verification code from an app like Google Authenticator, Authy or Duo.`,
  ENABLED_MESSAGE: `To disable your device, please provide your account password. Once disabled, this device will no longer function to verify your identity during the login process. Please note that this action cannot be undone.`,
  EMAIL_METHOD: `Enter a verification code sent via email.`,
  ENABLED_BUTTON: 'Setup',
  DISABLED_BUTTON: 'Disable',
  CANCELED_BUTTON: 'Cancel',
  VISUAL_CUE: ['Recommended', 'Enabled'],
  SUCCESS: '2FA has been disabled',
  ERROR: 'Something went wrong. Please try again or contact your administrator'
};

export const COMPANY_POLICY_2FA = {
  TITLE: 'Two-Factor Authentication Company Policy',
  DESCRIPTION: 'Secure your company by implementing Two-Factor Authentication for all your users.',
  HELPER_TEXT:
    'Enable two-factor authentication to add an extra layer of security to your account. All users within your organization are required to enable 2FA for increased security protection.',
  OPTION_TEXT: 'Require 2FA for all users',
  SUCCESS: 'Your settings has been saved.',
  ERROR: 'There was an error updating your settings.'
};

export const SETUP_2FA = {
  TITLE: 'Register Two-Factor Authenticator',
  DESCRIPTION:
    'Use a verification password authenticator on your mobile device or computer to enable two-factor authentication (2FA).',
  HELPER_TEXT: {
    APP: [
      'Please scan the QR code below with an authenticator app. We recommend cloud-based mobile authenticator apps, such as',
      'They can restore access if you lose your hardware device.'
    ],
    EMAIL: [
      `We've sent a verification code to your email address`,
      `Please check your inbox and enter the verification code in the form below to complete the Email 2FA setup.`
    ]
  },
  QR_CODE_PROBLEMS: `Can't scan the code? To add the entry manually, provide the following details to the application on your device.`,
  REGISTER_APP_BUTTON: 'Register with two-factor app',
  REGISTER_EMAIL_BUTTON: 'Register with two-factor email',
  SUCCESS: 'Registered Successfully',
  ERROR: 'Something went wrong. Please try again or contact your administrator',
  HYPER_LINKS: [
    {
      LABEL: 'Duo Mobile',
      LINK: 'https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app'
    },
    { LABEL: 'Authy', LINK: 'https://authy.com/' },
    {
      LABEL: 'Microsoft Authenticator',
      LINK: 'https://www.microsoft.com/en-us/security/mobile-authenticator-app'
    }
  ]
};

export const DEVICES_2FA = {
  EMAIL_METHOD_INPUT: 'Enter the verification code received in your email.',
  APP_METHOD_INPUT:
    'Enter the verification code from the two-factor authentication app on your device.',
  REMEMBER_DEVICE: 'Remember me on this device for 15 days',
  AUTHENTICATING: 'Authenticating...',
  VERIFY_CODE: 'Verify Code',
  HELPER_TEXT: {
    APP: [
      'The verification code can be found in the authenticator app on your device, such as Google Authenticator, Microsoft Authenticator, or Authy.',
      `If you are an iPhone user, verification codes could also be in the "Passwords" section of your device's settings.`,
      `If you've lost your device, please contact`,
      'for assistance.'
    ],
    EMAIL: [
      'The verification code has been sent to your email address. Please check your inbox or spam folder and enter the code provided.',
      `If you cannot find the email, please ensure you have entered the correct email address or check if it was delivered to a different folder in your email client.`,
      `If you're still having trouble receiving the code, please contact`,
      'for assistance.'
    ]
  }
};
