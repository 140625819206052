/* eslint-disable import/prefer-default-export */
import { questionFlavors as qf, questionTypes as qt, rangeMode } from 'constants/questionnaire';

const getValueByPercentage = (value, percentage) => Math.round((value * percentage) / 100);

const formatToCurrency = value => value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

const getAnswerByParsedQuestion = question => {
  let answer = question.answer.value;

  if (
    question.question_type === qt.RANGE &&
    question.data &&
    question.data.slider_mode === rangeMode.ADVANCED_SLIDER
  )
    answer = formatToCurrency(question.answer.value);

  if (question.question_type === qt.CUSTOM && question.data?.flavor === qf.WITHDRAWAL_REQUIREMENT)
    answer = Math.abs(question.answer.duration - question.answer.start);
  else if (question.question_type === qt.CUSTOM && question.data?.flavor === qf.VALUE_REQUIREMENT)
    answer = formatToCurrency(question.answer.value);

  return answer;
};

export const getParsedQuestion = (questions, question) => {
  if (!questions.length) return [];
  if (!question) return '';
  const answer8 = questions.find(element => element.position === 8);
  const answer9 = questions.find(element => element.position === 9);
  const answer10 = questions.find(element => element.position === 10);
  return question
    .replace(/<answer9>/, answer9 && answer9.answer ? getAnswerByParsedQuestion(answer9) : 0)
    .replace(
      /<answer8>/,
      formatToCurrency(answer8 && answer8.answer ? getAnswerByParsedQuestion(answer8) : 0)
    )
    .replace(
      /<get20ofanswer8>/,
      formatToCurrency(
        getValueByPercentage(
          answer8 && answer8.answer ? getAnswerByParsedQuestion(answer8) : 100000,
          20
        )
      ).toString()
    )
    .replace(
      /<answer10>/,
      formatToCurrency(answer10 && answer10.answer ? getAnswerByParsedQuestion(answer10) : 100000)
    );
};
