import React from 'react';

export default ({ style, className }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    viewBox="0 0 52.08 66.15"
  >
    <title>trashbin</title>
    <path
      d="M233,84.28H281c0,1.26,0,2.48,0,3.71a2.75,2.75,0,0,1-.86,2.41,3.36,3.36,0,0,0-1,2.64c0,13.71-.05,27.41.07,41.11,0,3.81-2.63,6.11-6.23,6.08-6.38,0-12.76,0-19.14,0H241.08a6.51,6.51,0,0,1-4.51-1.46,5.19,5.19,0,0,1-1.75-4.12q0-14.41,0-28.83c0-4.24,0-8.47,0-12.71a3.82,3.82,0,0,0-1.23-3,1.93,1.93,0,0,1-.62-1.58C233,87.15,233,85.76,233,84.28Zm11.65,11.15v33.08h4.14V95.43Zm14.49,33.08V95.44H255v33.07ZM266.41,112v6.74c0,3,0,6,0,9,0,.67.2.87.86.83s1.6,0,2.4,0c1,0,1,0,1-1V95.91c0-.4-.16-.54-.56-.53-.92,0-1.85,0-2.77,0s-1,0-1,.91Z"
      transform="translate(-230.92 -74.08)"
    />
    <path
      d="M230.92,83.05c0-.94,0-1.81,0-2.68,0-.54.38-.45.7-.45h10c.71,0,1.41,0,2.11,0a.83.83,0,0,0,.62-.4c1.25-3.65,4-5.29,7.78-5.38,3.61-.08,7.23-.05,10.84.08a7.38,7.38,0,0,1,6.62,5.14c.13.51.41.59.88.59,3.91,0,7.81,0,11.72,0H283v3.13Zm34.25-3.18a.74.74,0,0,0-.08-.24A3.79,3.79,0,0,0,261.56,78c-2.4,0-4.8,0-7.2,0-1,0-1.94,0-2.9.08a3.3,3.3,0,0,0-2.71,1.84Z"
      transform="translate(-230.92 -74.08)"
    />
  </svg>
);
