import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import PrismDistributionChart from 'components/charts/prism-distribution-chart';
import { CUSTOMER_SUPPORT_EMAIL } from 'constants/contact';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';
import './styles.scss';

const ModelStrategyStats = ({ distribution, stats, loading }) => {
  const hasInfo = !!(stats && stats.total_accounts && distribution.length);
  return (
    <div id="ModelStrategyStats">
      <div className="stats-content">
        {loading && <LoadingPrismDataMessage />}

        {!loading &&
          !hasInfo &&
          `There is no associated account. Please contact ${CUSTOMER_SUPPORT_EMAIL} to connect accounts to the model.`}

        {!loading && hasInfo && (
          <>
            <h5>Associated Accounts PRISM Rating Distribution</h5>
            <div id="strategy-accounts">
              <div id="strategy-accounts-distribution" className="card">
                <PrismDistributionChart distribution={distribution} title="Securities" />
              </div>
              <div id="strategy-accounts-stats" className="card">
                <div>
                  <span>Total number of associated accounts</span>
                  <span className="stat-number">{stats.total_accounts}</span>
                </div>
                <div>
                  <span>Total associated assets</span>
                  <span className="stat-number">{formatMoney(stats.total_assets)}</span>
                </div>
                <div>
                  <span>Overall PRISM rating of associated accounts</span>
                  <span>
                    {stats.accounts_prism_overall ? (
                      <ScoreBubble score={stats.accounts_prism_overall} className="risk-bubble" />
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
                <div>
                  <span>Overall drift of portfolio risk from strategy risk</span>
                  <span className="stat-number">{stats.drift ? stats.drift.toFixed(1) : '-'}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ModelStrategyStats.propTypes = {
  distribution: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.func.isRequired,
  stats: PropTypes.object
};

ModelStrategyStats.defaultProps = {
  distribution: [],
  stats: null
};

export default ModelStrategyStats;
