import { CLICK_INTEGRATION, REQUEST_INTEGRATION } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import ConnectIntegrationModal from '../connect-integration-modal';
import { hasCustomIntegrationHandler } from '../utils';
import './styles.scss';

const AvailableProvider = ({ provider }) => {
  const { image, is_enabled: isEnabled, name, external_url: externalURL } = provider;
  const { actionProvider, integrationProvider, routerActions } = useContext(AdvisorContext);

  const [isShown, setIsShown] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const showModal = () => {
    setIsShown(true);
    actionProvider.slack({ verb: CLICK_INTEGRATION, data: { provider: name } });
    actionProvider.storeAction({ verb: CLICK_INTEGRATION, data: { provider: name } });
  };

  const hideModal = () => {
    setIsShown(false);
    integrationProvider.getConnected();
  };

  const getConnectedIntegrations = () => {
    setIsShown(false);
    integrationProvider.getConnected();
    routerActions.push('/advisor/integrations/connected');
  };

  const requestIntegration = () => {
    const params = { verb: REQUEST_INTEGRATION, data: { provider: name } };
    actionProvider.slack(params);
    actionProvider.storeAction(params);

    toast.success(
      () => (
        <>
          <p>
            👍 We&apos;ve received your connection request for <strong>{name}</strong>! If we need
            any additional information, we&apos;ll be in touch.
          </p>
          <p>Thank you for your interest!</p>
        </>
      ),
      {
        toastId: `${name}-request`,
        autoClose: 10000
      }
    );

    setIsRequested(true);
  };

  return (
    <>
      <div className="available-integration-provider">
        {image && <img src={image} alt={name} />}
        {!image && (
          <div className="available-integration-provider__no-brand">
            <span>{name}</span>
          </div>
        )}
        {hasCustomIntegrationHandler(provider) && (
          <button
            aria-label="connect"
            type="button"
            disabled={!isEnabled}
            className="btn btn-connect btn-primary"
            onClick={showModal}
          >
            Connect
          </button>
        )}
        {!hasCustomIntegrationHandler(provider) && externalURL && (
          <a
            href={externalURL}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-link btn-request"
          >
            {isEnabled ? 'How to connect?' : 'Request'}
          </a>
        )}
        {!hasCustomIntegrationHandler(provider) && !externalURL && (
          <button
            aria-label="request"
            type="button"
            disabled={isRequested}
            className="btn btn-link btn-request"
            onClick={requestIntegration}
          >
            Request
          </button>
        )}
      </div>

      {isShown && (
        <ConnectIntegrationModal
          onCancel={hideModal}
          onConnect={getConnectedIntegrations}
          provider={provider}
          show={isShown}
        />
      )}
    </>
  );
};

AvailableProvider.defaultProps = {};

AvailableProvider.propTypes = {
  provider: PropTypes.shape({
    is_enabled: PropTypes.bool.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    external_url: PropTypes.string
  }).isRequired
};

export default AvailableProvider;
