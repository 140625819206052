/* eslint-disable no-underscore-dangle */
import cn from 'classnames';
import { SECTION_LOCKED_MAP, SECTOR_EXPOSURE } from 'components/advisor/section-locked/utils';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import { SECURITY_SECTORS } from 'components/advisor/risk-analysis/securities/common/utils';
import SectionLocked from 'components/advisor/section-locked';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { formatPercentage, getTaxonomyLevelIterator } from 'utils/utils';
import HorizontalBarChart from 'app/components/charts/horizontal-bar-chart';
import Row from '../common/row';
import {
  buildCollapsibleMap,
  getCollapsedCategories,
  getDistributionData,
  getLevelPercent
} from '../common/utils';
import { COLORS } from './options';
import './styles.scss';

const SectorExposureDistribution = ({
  benchmark,
  benchmarkName,
  isDraft,
  onChartReady,
  recommended,
  recommendedName,
  setCollapsedSectors,
  start,
  startingValue,
  target,
  targetName,
  width
}) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          sector_exposure_enabled: sectorExposureEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const [collapsibleMap, setCollapsibleMap] = useState({});

  const { name, proposalIpsId } = SECTION_LOCKED_MAP[SECTOR_EXPOSURE];

  if (!sectorExposureEnabled)
    return (
      <div id="sector-exposure-distribution">
        <SectionLocked id={proposalIpsId} name={name} />
      </div>
    );

  const {
    distributionTable,
    target: { chart: targetChart, value: targetValue, total: targetProcessedTotal },
    recommended: {
      chart: recommendedChart,
      value: recommendedValue,
      total: recommendedProcessedTotal
    },
    benchmark: { chart: benchmarkChart, value: benchmarkValue, total: benchmarkProcessedTotal }
  } = getDistributionData(target, recommended, benchmark, SECURITY_SECTORS);

  const chartData = {
    target: targetChart,
    recommended: recommendedChart,
    benchmark: benchmarkChart
  };

  const toggleRow = id => {
    setCollapsibleMap(prevCollapsibleMap => ({
      ...prevCollapsibleMap,
      [id]: !prevCollapsibleMap[id]
    }));
  };

  useEffect(() => {
    const collapsibleMap = buildCollapsibleMap(distributionTable, true);
    setCollapsibleMap(collapsibleMap);
  }, []);

  useEffect(() => {
    setCollapsedSectors(getCollapsedCategories(collapsibleMap));
  }, [JSON.stringify(collapsibleMap)]);

  return (
    <div id="sector-exposure-distribution">
      <div className="style-box-chart-container target">
        <div className="use-portfolio-container">
          <HorizontalBarChart
            data={chartData}
            multirows={['target', 'recommended', 'benchmark']}
            onChartReady={onChartReady}
            width={width}
          />
        </div>
      </div>
      <DateValue proposal={{ start, starting_value: startingValue }} />
      <div
        className={cn('fancy-grid', 'holdings', {
          'no-recommended': !recommended,
          'no-benchmark': !benchmark
        })}
      >
        <div className="transparent" />
        {target && (
          <div style={{ borderTopColor: COLORS['target'] }} className="header">
            {targetName || 'Current Portfolio'}
          </div>
        )}
        {recommended && (
          <div style={{ borderTopColor: COLORS['recommended'] }} className="header">
            {recommendedName || 'Recommended'}
          </div>
        )}
        {benchmark && (
          <div style={{ borderTopColor: COLORS['benchmark'] }} className="header">
            {benchmarkName || 'Benchmark'}
          </div>
        )}
      </div>

      <div className="sector-exposure-table">
        {getTaxonomyLevelIterator(distributionTable).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row
              className="row-title depth-1"
              collapsed={expandAssetDetails && collapsibleMap[l1Key]}
              color={l1.__color}
              icon={l1.__icon}
              isDraft={isDraft}
              label={l1Key}
              toggleRow={expandAssetDetails ? toggleRow : null}
              target={getLevelPercent(l1.__target_value, targetValue)}
              recommended={
                recommended ? getLevelPercent(l1.__recommended_value, recommendedValue) : null
              }
              benchmark={benchmark ? getLevelPercent(l1.__benchmark_value, benchmarkValue) : null}
            />

            {expandAssetDetails &&
              !collapsibleMap[l1Key] &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row
                    className={cn('depth-2', { 'with-parent-icon': !!l1.__icon })}
                    collapsed={expandAssetDetails && collapsibleMap[l2Key]}
                    isDraft={isDraft}
                    label={l2Key}
                    target={getLevelPercent(l2.__target_value, targetValue)}
                    recommended={
                      recommended ? getLevelPercent(l2.__recommended_value, recommendedValue) : null
                    }
                    benchmark={
                      benchmark ? getLevelPercent(l2.__benchmark_value, benchmarkValue) : null
                    }
                  />
                </Fragment>
              ))}
          </Fragment>
        ))}

        <div className="row depth-1 row-total">
          <div className="col category">Total Portfolio</div>
          <div className="col">
            {isDraft && <div className="subcol diff" />}
            <div className="subcol">{formatPercentage(targetProcessedTotal / targetValue)}</div>
          </div>
          {recommended && (
            <div className="col">
              {!isDraft && <div className="subcol diff" />}
              <div className="subcol">
                {formatPercentage(
                  recommendedValue ? recommendedProcessedTotal / recommendedValue : 0
                )}
              </div>
            </div>
          )}
          {benchmark && (
            <div className="col">
              {formatPercentage(benchmarkValue ? benchmarkProcessedTotal / benchmarkValue : 0)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SectorExposureDistribution.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  isDraft: PropTypes.bool,
  onChartReady: PropTypes.func,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  setCollapsedSectors: PropTypes.func,
  start: PropTypes.string,
  startingValue: PropTypes.number,
  target: PropTypes.object,
  targetName: PropTypes.string,
  width: PropTypes.string
};

SectorExposureDistribution.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  isDraft: false,
  onChartReady: null,
  recommended: null,
  recommendedName: '',
  setCollapsedSectors: () => {},
  start: null,
  startingValue: null,
  target: null,
  targetName: '',
  width: '100%'
};

export default SectorExposureDistribution;
