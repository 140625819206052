import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { ACCOUNT_DRIFT_OVERALL_MAX_ATTR, ACCOUNT_DRIFT_OVERALL_MIN_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const AccountDriftOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[ACCOUNT_DRIFT_OVERALL_MAX_ATTR, ACCOUNT_DRIFT_OVERALL_MIN_ATTR]}
    filters={filters}
    label="Account Drift Overall"
  >
    <MinMaxSliderFilter
      filters={filters}
      maxAttr={ACCOUNT_DRIFT_OVERALL_MAX_ATTR}
      minAttr={ACCOUNT_DRIFT_OVERALL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

AccountDriftOverallFilter.propTypes = { ...filterPropTypes };

AccountDriftOverallFilter.defaultProps = { ...filterDefaultProps };

export default AccountDriftOverallFilter;
