import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SpinnerLoader = ({ spinnerLoading }) => (
  <div
    className={cs('spinner', {
      'hidden-xs-up': !spinnerLoading
    })}
  >
    <img src="/img/icons/spinner.png" alt="Loading" />
  </div>
);

SpinnerLoader.propTypes = {
  spinnerLoading: PropTypes.bool
};

SpinnerLoader.defaultProps = {
  spinnerLoading: false
};

export default SpinnerLoader;
