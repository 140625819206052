// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Dashboard .advisor-filter .stratifi-select .stratifi-sel__single-value {
  font-size: 20px;
}
#Dashboard .advisor-filter .stratifi-select .stratifi-sel__control {
  min-width: 255px;
  max-height: 38px;
  align-content: center;
  justify-content: center;
  place-content: center;
}

.team-filter-disabled {
  pointer-events: none;
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/team-filter/styles.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;AADN;AAII;EACE,gBAAA;EACA,gBAAA;EACA,qBAAA;EAAA,uBAAA;EAAA,qBAAA;AAFN;;AAOA;EACE,oBAAA;EACA,YAAA;AAJF","sourcesContent":["#Dashboard {\n  .advisor-filter {\n    .stratifi-select .stratifi-sel__single-value {\n      font-size: 20px;\n    }\n\n    .stratifi-select .stratifi-sel__control {\n      min-width: 255px;\n      max-height: 38px;\n      place-content: center;\n    }\n  }\n}\n\n.team-filter-disabled {\n  pointer-events: none;\n  opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
