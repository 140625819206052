import FeatureLocked from 'components/advisor/feature-locked';
import { AdvisorContext } from 'containers/advisor';
import { InvestorContext } from 'containers/investor';
import React, { useContext } from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withSubscription = ({ plan, id, name, inline }) => WrappedComponent => {
  const WithSubscription = ({ ...props }) => {
    const { route } = props;

    const mode = route?.flavor === 'investor' ? 'investor' : 'advisor';
    const context = mode === 'investor' ? InvestorContext : AdvisorContext;

    const { user, userProvider } = useContext(context);
    if (!userProvider.hasAccess(user, plan))
      return <FeatureLocked id={id} name={name} inline={inline} mode={mode} />;
    return <WrappedComponent {...props} />;
  };
  WithSubscription.displayName = `WithSubscription(${getDisplayName(WrappedComponent)})`;
  return WithSubscription;
};

export default withSubscription;
