import { digestContent } from 'components/advisor/templates/meta/editor-options';

import _ from 'lodash';

const prepareMetadata = (content, user) => {
  const metadata = _.cloneDeep(content);

  const data = {
    advisor: { user },
    company: {
      name: user.advisor.company_name || user.advisor.company.name
    }
  };

  // replace metadata tags with real content
  _.forOwn(metadata, (properties, key) => {
    _.forOwn(properties, (value, property) => {
      if (typeof value === 'string') metadata[key][property] = digestContent(value, data);
    });
  });

  return metadata;
};

export default prepareMetadata;
