// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#standard-main-container {
  padding-left: 205px;
}

@media print {
  #standard-main-container {
    padding-left: 0;
  }
  .container {
    max-width: none;
    max-width: initial;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/standard-container/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE;IACE,eAAA;EACF;EAEA;IACE,eAAA;IAAA,kBAAA;EAAF;AACF","sourcesContent":["#standard-main-container {\n  padding-left: 205px;\n}\n\n@media print {\n  #standard-main-container {\n    padding-left: 0;\n  }\n\n  .container {\n    max-width: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
