import EditBoxText from 'components/advisor/utils/edit-box-text';
import FocusedPrismFactorsChart from 'components/charts/focused-prism-factors-chart';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';

const ProposalRiskFactorComparison = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  setMetadata
}) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  return (
    <EditBoxText {...proposalMetadata}>
      <div className="focused-prism-factors-container box-container">
        <div className="box-content no-border">
          <FocusedPrismFactorsChart
            recommendedName={proposal.recommended_label}
            recommendedSummary={proposal?.recommended?.prism_score_summary}
            targetName={proposal.target_label}
            targetSummary={proposal.target.prism_score_summary}
          />
        </div>
      </div>
    </EditBoxText>
  );
};

ProposalRiskFactorComparison.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalRiskFactorComparison;
