import React from 'react';

export default ({ className }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 43.65 41.47"
  >
    <title>household_icon</title>
    <path
      className="cls-1"
      d="M180.39,163.53l8.11,7.23q6.57,5.82,13.15,11.63c.41.36.46.59.07,1a14.18,14.18,0,0,0-1.41,1.59c-.36.47-.6.38-1,0l-13.25-11.75c-1.77-1.58-3.57-3.14-5.32-4.74-.41-.37-.65-.31-1,0q-9.22,8.21-18.46,16.39c-.52.47-.79.42-1.2-.09-.53-.66-1.14-1.27-1.72-1.9v-.14a1.86,1.86,0,0,0,.19-.11l21.16-18.74c.13-.11.28-.21.26-.41Z"
      transform="translate(-158.34 -163.53)"
    />
    <path
      className="cls-1"
      d="M195.64,194.44v9.11c0,1.18-.26,1.44-1.41,1.44-2.82,0-5.64,0-8.45,0-.56,0-.7-.15-.69-.69,0-2.5,0-5,0-7.5,0-1-.28-1.25-1.27-1.26h-7.2c-.95,0-1.24.29-1.24,1.23,0,2.52,0,5,0,7.56,0,.52-.14.66-.65.66-2.89,0-5.78,0-8.67,0-.9,0-1.23-.33-1.23-1.25q0-9.3,0-18.59a1.09,1.09,0,0,1,.4-.89q7.31-6.44,14.58-12.9c.31-.28.49-.34.84,0q7.29,6.49,14.63,12.95a1.15,1.15,0,0,1,.35,1Z"
      transform="translate(-158.34 -163.53)"
    />
    <path
      className="cls-1"
      d="M164.85,174.34v-1.5c0-2,0-3.92,0-5.88,0-.51.14-.67.66-.66,1.42,0,2.84,0,4.26,0,.53,0,.71.17.63.67V167a4,4,0,0,1-1.86,4.23,37.25,37.25,0,0,0-3.13,2.78C165.27,174.17,165.17,174.37,164.85,174.34Z"
      transform="translate(-158.34 -163.53)"
    />
  </svg>
);
