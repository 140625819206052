// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown.dropdown__multi-select .asset-classes-choice .c-input.c-checkbox {
  margin-right: 10px;
}
.dropdown.dropdown__multi-select .asset-classes-choice .asset-classes-choice-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 85%;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/table/dynamic/filters/common/multi-select/styles.scss"],"names":[],"mappings":"AAII;EACE,kBAAA;AAHN;AAMI;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAJN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.dropdown.dropdown__multi-select {\n  .asset-classes-choice {\n    .c-input.c-checkbox {\n      margin-right: 10px;\n    }\n\n    .asset-classes-choice-title {\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      overflow: hidden;\n      max-width: 85%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
