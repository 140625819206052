/* eslint-disable no-underscore-dangle */
import {
  ADVISOR_DELETE,
  ADVISOR_DOWNLOADING_DATA,
  ADVISOR_DOWNLOADING_DATA_FINISHED,
  ADVISOR_EDIT,
  ADVISOR_GET,
  ADVISOR_LIST,
  ADVISOR_LIST_FOLLOWERS,
  ADVISOR_UPDATE,
  ADVISOR_VIEW
} from 'constants/actions';
import config from '../config';
import AdvisorsElasticProvider from './advisors.es';
import DataProvider from './data';

class AdvisorProvider extends DataProvider {
  static PAGE_SIZE = 20;

  get es() {
    if (!this._es) this._es = new AdvisorsElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  view(advisor) {
    this.dispatch(ADVISOR_VIEW, {
      data: advisor
    });
  }

  edit(advisor) {
    this.dispatch(ADVISOR_EDIT, {
      data: advisor
    });
  }

  listSecurityAdvisors(params) {
    return this.provider
      .get(`${config.apiBase}advisors/`, { ...params })
      .then(({ data, error }) => {
        if (!error) {
          this._params = params;
          this.dispatch(ADVISOR_LIST, {
            data: {
              data: data.results || [],
              meta: { params, count: data.count, totalPages: data.total_pages }
            }
          });
        } else this.dispatch(ADVISOR_LIST, { data: { data: [], meta: {} } });
        return data;
      });
  }

  clearAdvisors() {
    this.dispatch(ADVISOR_LIST, { data: { data: [], meta: {} } });
  }

  list(params = {}) {
    return this.provider.get(`${config.apiBase}advisors/`, params).then(response => {
      if (!response.error) {
        this._params = { ...params };
        this.dispatch(ADVISOR_LIST, {
          data: {
            data: response.data.results || [],
            meta: {
              params,
              count: response.data.count,
              next: response.data.next,
              previous: response.data.previous,
              total_pages: response.data.total_pages
            }
          }
        });
      } else this.dispatch(ADVISOR_LIST, { data: null });
      return response;
    });
  }

  get(id) {
    // GET '/api/advisors/:id'
    const promise = this.provider.get(`${config.apiBase}advisors/${id}/`).then(data => {
      if (!data.error)
        this.dispatch(ADVISOR_GET, {
          data: data.data
        });
      return data;
    });

    return promise;
  }

  update(advisor, currentUserId, list = false) {
    const data = { ...advisor };
    if (!data.id) return this.create(advisor);

    // PUT 'api/advisors/:id'
    const promise = this.provider.put(`${config.apiBase}advisors/${data.id}/`, data).then(data => {
      if (!data.error) {
        if (currentUserId === advisor.id) this.dispatch(ADVISOR_UPDATE, { data: data.data });
        if (list) this.list(this._params);
      }
      return data;
    });

    return promise;
  }

  delete(advisor, list = false) {
    // DELETE '/api/advisors/:id'
    const promise = this.provider.delete(`${config.apiBase}advisors/${advisor.id}/`).then(data => {
      if (!data.error) {
        this.dispatch(ADVISOR_DELETE, {
          data: advisor
        });

        if (list) this.list(this._params);
      }
    });

    return promise;
  }

  setAnswers(answers) {
    return this.provider.post(`${config.apiBase}advisors/answers/`, { answer: answers });
  }

  downloadInvestorCSV(params) {
    this.dispatch(ADVISOR_DOWNLOADING_DATA);
    this.provider
      .requestNoJSON(`${config.apiBase}advisors/download_investors_information_csv/`, null, params)
      .then(response => Promise.all([response.blob(), response]))
      .then(([data, response]) => (response.ok ? Promise.resolve(data) : Promise.reject(data)))
      .then(data => {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'investor_information.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.dispatch(ADVISOR_DOWNLOADING_DATA_FINISHED);
      });
  }

  uploadInvestorCSV(file) {
    return this.provider.postFormData(
      `${config.apiBase}advisors/upload_investors_information_csv/`,
      file
    );
  }

  getAdvisorProfile(id) {
    return this.provider.get(`${config.apiBase}advisors/${id}/`);
  }

  updateAdvisorProfile(id, data) {
    return this.provider.patch(`${config.apiBase}advisors/${id}/`, data);
  }

  updateAdvisorProfileImage(id, file, field = 'image') {
    const formData = this.formData({ file });
    return this.provider.postFormData(
      `${config.apiBase}advisors/${id}/${field}/`,
      formData,
      null,
      'PATCH'
    );
  }

  moveInvestors(id, investors, targetAdvisor) {
    const ids = investors.map(x => x.id);
    return this.provider.post(`${config.apiBase}advisors/${id}/move/`, {
      target: targetAdvisor,
      investors: ids
    });
  }

  moveHousehold(id, households, targetAdvisor) {
    const ids = households.map(x => x.id);
    return this.provider.post(`${config.apiBase}advisors/${id}/move/`, {
      target: targetAdvisor,
      households: ids
    });
  }

  sendConfirmationEmail(subject, investors) {
    return this.provider.post(`${config.apiBase}advisors/send_confirmation_mail/`, {
      subject,
      investors
    });
  }

  transferAdmin(data) {
    return this.provider.post(`${config.apiBase}advisors/${data.id}/transfer_admin/`, data);
  }

  listFollowers() {
    return this.provider.get(`${config.apiBase}advisors/followers/`).then(response => {
      if (!response.error) this.dispatch(ADVISOR_LIST_FOLLOWERS, { data: response.data });
      return response;
    });
  }

  editFollowers(followeeId, followers) {
    return this.provider.post(`${config.apiBase}advisors/${followeeId}/followers/`, {
      followers
    });
  }
}

export default AdvisorProvider;
