import { StyleSheet } from '@react-pdf/renderer';
import { BASE_FONT_FAMILY, BASE_PAGE_TITLE, LIGHT_GRAPHITE_COLOR } from '../../styles';

export const styles = StyleSheet.create({
  title: { ...BASE_PAGE_TITLE },
  body: { ...BASE_FONT_FAMILY, fontSize: 10 }
});

export const stylesheet = {
  '*': { ...BASE_FONT_FAMILY },
  'html, body': { margin: 0, padding: 0 },
  'table tr td': {
    border: 1,
    borderColor: LIGHT_GRAPHITE_COLOR,
    borderStyle: 'solid',
    paddingHorizontal: 10,
    paddingVertical: 5
  }
};
