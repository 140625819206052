import PropTypes from 'prop-types';

const ReportTemplatePropTypes = PropTypes.shape({
  content: PropTypes.object,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
});

export default ReportTemplatePropTypes;
