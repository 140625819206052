// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Billing.billing-hero-message {
  display: grid;
  grid-template-rows: auto min-content;
  min-height: calc(100vh - 72px);
}
#Billing.billing-hero-message > div {
  align-self: center;
}
#Billing h1 {
  margin-top: 3rem;
  font-size: 1.75rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/billing/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,oCAAA;EACA,8BAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAME;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;AAJJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#Billing {\n  &.billing-hero-message {\n    display: grid;\n    grid-template-rows: auto min-content;\n    min-height: calc(100vh - 72px);\n\n    & > div {\n      align-self: center;\n    }\n  }\n\n  h1 {\n    margin-top: 3rem;\n    font-size: 1.75rem;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
