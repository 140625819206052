import cn from 'classnames';
import Disclosure from 'components/disclosure';
import Choice from 'components/form/choice';
import SignatureInput from 'components/form/signature-input';
import SpinnerLoader from 'components/performance-spinner';
import { AuthenticationContext } from 'containers/auth';
import HeaderAnonymous from 'containers/layout/header-anonymous';
import PropTypes from 'prop-types';
import IPSProvider from 'providers/ips';
import ProposalProvider from 'providers/proposal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReportThumbnail from 'reports/thumbnail';
import ReportViewer from 'reports/viewer';
import { recordLogRocketEvent } from 'utils/tracking';
import { getReportUrl } from 'utils/utils';
import { IPS_REPORT_TYPE } from '../proposal-or-ips-reports/constants';
import SignatureRequestMessage from './message';
import './styles.scss';

const PROSPECT_INVESTOR_TYPE = 'prospect';

const SIGNATURE_REQUEST_INITIAL_STEP = 0;
const SIGNATURE_REQUEST_REVIEW_STEP = 1;
const SIGNATURE_REQUEST_SIGN_STEP = 2;
const SIGNATURE_REQUEST_SUMMARY_STEP = 3;

const SignatureRequest = ({ ipsProvider, proposalProvider }) => {
  const { user } = useContext(AuthenticationContext);

  const signatureRef = useRef(null);

  const [acknowledgeReview, setAcknowledgeReview] = useState(false);
  const [canSubmitSign, setCanSubmitSign] = useState(false);
  const [loading, setLoading] = useState(true);
  const [review, setReview] = useState({});
  const [step, setStep] = useState(SIGNATURE_REQUEST_INITIAL_STEP);
  const [submitting, setSubmitting] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const reportId = Number(params.get('report'));
  const reportType = params.get('report_type');
  const reportInvestorId = Number(params.get('report_investor'));
  const reportInvestorType = params.get('report_investor_type');
  const provider = reportType === IPS_REPORT_TYPE ? ipsProvider : proposalProvider;
  const token = params.get('token');

  if (!token) return null;

  const onChangeStep = step => () => {
    setStep(step);
  };

  const onChangeSubmitSignStatus = status => () => {
    setCanSubmitSign(status);
  };

  const onSignatureSubmit = async () => {
    const signature = signatureRef.current;
    if (signature) {
      setSubmitting(true);
      const signerType = review.advisor ? 'advisor' : 'investor';
      const signetId = review.advisor ? review.advisor.id : review.investor.id;
      await provider
        .uploadSign(
          token,
          reportId,
          reportInvestorId,
          reportInvestorType === PROSPECT_INVESTOR_TYPE,
          { signature: signature.toDataURL(), [signerType]: signetId }
        )
        .then(() => {
          setStep(SIGNATURE_REQUEST_SUMMARY_STEP);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const onAcknowledgeReview = async () => {
    setSubmitting(true);
    const signerType = review.advisor ? 'advisor' : 'investor';
    const signetId = review.advisor ? review.advisor.id : review.investor.id;
    await provider
      .acknowledgeReview(
        token,
        reportId,
        reportInvestorId,
        reportInvestorType === PROSPECT_INVESTOR_TYPE,
        { [signerType]: signetId }
      )
      .then(() => {
        setStep(SIGNATURE_REQUEST_SUMMARY_STEP);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSetAcknowledgeReview = () => {
    setAcknowledgeReview(prevAcknowledgeReview => !prevAcknowledgeReview);
  };

  useEffect(() => {
    const fetchReportReview = async () => {
      recordLogRocketEvent('Review Report');
      await provider
        .getReview(token, reportId, reportInvestorId, reportInvestorType === PROSPECT_INVESTOR_TYPE)
        .then(({ data: review, error }) => {
          if (review && !error) {
            setReview(review);
            if (!review.mail.read)
              provider.markReviewMailAsRead(
                token,
                reportId,
                reportInvestorId,
                reportInvestorType === PROSPECT_INVESTOR_TYPE
              );
          }
        });
      setLoading(false);
    };
    fetchReportReview();
  }, []);

  useEffect(() => {
    if (review.reviewed_at) setStep(SIGNATURE_REQUEST_REVIEW_STEP);
  }, [JSON.stringify(review)]);

  if (loading) return <SpinnerLoader spinnerLoading />;

  if (!review.report)
    return <SignatureRequestMessage messageId="signature-request.request-error" />;

  const reviewerName = review.advisor
    ? `${review.advisor.user.first_name} ${review.advisor.user.last_name}`
    : review.investor.full_name;

  return (
    <div className="investor-container">
      <HeaderAnonymous advisor={review.requested_by} name={reviewerName} />

      <div className="investor-container-wrapper">
        <div className="signature-request">
          <div className="signature-request__header">
            <h1>
              <FormattedMessage
                id="signature-request.title"
                values={{ type: reportType, investorName: reviewerName }}
              />
            </h1>
          </div>

          <div
            className={cn('signature-request__content', {
              'content--summary': step === SIGNATURE_REQUEST_SUMMARY_STEP
            })}
          >
            {step === SIGNATURE_REQUEST_INITIAL_STEP && (
              <>
                <FormattedMessage
                  id="signature-request.description"
                  values={{ type: reportType }}
                />
                <button
                  aria-label="Start Review"
                  className="btn btn-primary btn-signature"
                  onClick={onChangeStep(SIGNATURE_REQUEST_REVIEW_STEP)}
                  type="button"
                >
                  <FormattedMessage
                    id="signature-request.initial-button"
                    values={{ type: reportType }}
                  />
                </button>
                <ReportThumbnail url={getReportUrl(review.report)} />
              </>
            )}

            {step === SIGNATURE_REQUEST_REVIEW_STEP && review.reviewed_at && (
              <>
                <ReportViewer url={getReportUrl(review.report)} />
                <div className="alert alert-success" role="alert">
                  <i className="fs-icon-info-circled" />
                  <FormattedMessage id="signature-request.request-signed" />
                </div>
              </>
            )}

            {step === SIGNATURE_REQUEST_REVIEW_STEP && !review.reviewed_at && (
              <>
                <ReportViewer url={getReportUrl(review.report)} />
                {review.requires_signature ? (
                  <button
                    aria-label="Sign Report"
                    className="btn btn-success btn-signature"
                    onClick={onChangeStep(SIGNATURE_REQUEST_SIGN_STEP)}
                    type="button"
                  >
                    <FormattedMessage id="signature-request.sign-button" values={{ submitting }} />
                  </button>
                ) : (
                  <>
                    <div className="acknowledge-report-confirmation">
                      <Choice
                        checked={acknowledgeReview}
                        id="acknowledge-report-confirmation-toggle"
                        toggle={onSetAcknowledgeReview}
                      />
                      <span className="provided-sync-data__label">
                        <FormattedMessage id="signature-request.acknowledge-confirmation" />
                      </span>
                    </div>
                    <button
                      aria-label="Acknowledge Report"
                      className="btn btn-success btn-signature"
                      disabled={submitting || !acknowledgeReview}
                      onClick={onAcknowledgeReview}
                      type="button"
                    >
                      <FormattedMessage
                        id="signature-request.submit-button"
                        values={{ submitting }}
                      />
                    </button>
                  </>
                )}
              </>
            )}

            {step === SIGNATURE_REQUEST_SIGN_STEP && (
              <>
                <SignatureInput
                  onBeginStroke={onChangeSubmitSignStatus(true)}
                  onResetStroke={onChangeSubmitSignStatus(false)}
                  signatureRef={signatureRef}
                />
                <div className="content__actions">
                  <button
                    aria-label="Review Report"
                    className="btn btn-outline-secondary btn-signature"
                    onClick={onChangeStep(SIGNATURE_REQUEST_REVIEW_STEP)}
                    type="button"
                  >
                    <FormattedMessage id="signature-request.back-button" />
                  </button>
                  <button
                    aria-label="Sign Report"
                    className="btn btn-primary btn-signature"
                    disabled={submitting || !canSubmitSign}
                    onClick={onSignatureSubmit}
                    type="button"
                  >
                    <FormattedMessage id="signature-request.sign-button" values={{ submitting }} />
                  </button>
                </div>
              </>
            )}

            {step === SIGNATURE_REQUEST_SUMMARY_STEP && (
              <>
                <FormattedMessage
                  id="signature-request.summary-title"
                  values={{
                    title: str => <p className="font-weight-bold">{str}</p>,
                    type: reportType
                  }}
                />
                <FormattedMessage
                  id="signature-request.summary-description"
                  values={{ p: str => <p>{str}</p> }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <Disclosure />
    </div>
  );
};

SignatureRequest.propTypes = {
  ipsProvider: PropTypes.object.isRequired,
  proposalProvider: PropTypes.object.isRequired
};

export default connect(null, dispatch => ({
  ipsProvider: new IPSProvider({ dispatch }),
  proposalProvider: new ProposalProvider({ dispatch })
}))(SignatureRequest);
