import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PlanFeature = ({ description, ...plansTypes }) => (
  <div className="plan-feature">
    <div className="description">{description}</div>
    {Object.entries(plansTypes).map(([level, feature], index) => (
      <div
        className={cs('status', { [level]: level, active: !!feature, inactive: !feature })}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {typeof feature === 'string' && feature}
        {typeof feature === 'boolean' && <i className="feature-check" />}
      </div>
    ))}
  </div>
);

PlanFeature.propTypes = {
  description: PropTypes.string.isRequired,
  starter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  professional: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  business: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  enterprise: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

PlanFeature.defaultProps = {
  starter: false,
  professional: false,
  business: false,
  enterprise: false
};

export default PlanFeature;
