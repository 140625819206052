import { FormGroup, VerboseErrorInput } from 'components/form';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validate = values => {
  const errors = {};
  errors.first_name = errors.first_name || validation.required(values.first_name);
  errors.last_name = errors.last_name || validation.required(values.last_name);
  errors.email =
    errors.email || validation.required(values.email) || validation.email(values.email);
  return errors;
};

const CreateInvestorForm = ({ createInvestor, fields, handleSubmit, invalid, submitting }) => {
  const onSubmit = async values => {
    await createInvestor(values);
  };

  return (
    <div id="create-investor-form">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="form-group" {...fields.first_name}>
          <label htmlFor="first-name">
            <FormattedMessage id="rtq.guest.form.first-name" />
          </label>
          <VerboseErrorInput {...fields.first_name} type="text" className="form-control" />
        </FormGroup>
        <FormGroup className="form-group" {...fields.last_name}>
          <label htmlFor="last-name">
            <FormattedMessage id="rtq.guest.form.last-name" />
          </label>
          <VerboseErrorInput {...fields.last_name} type="text" className="form-control" />
        </FormGroup>
        <FormGroup className="form-group" {...fields.email}>
          <label htmlFor="email">
            <FormattedMessage id="rtq.guest.form.email" />
          </label>
          <VerboseErrorInput {...fields.email} type="text" className="form-control" />
        </FormGroup>
        <div className="pt-2">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={invalid || submitting}
          >
            <FormattedMessage id="rtq.guest.form.continue" values={{ submitting }} />
          </button>
        </div>
      </form>
    </div>
  );
};

CreateInvestorForm.propTypes = {
  createInvestor: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'createInvestor',
  fields: ['first_name', 'last_name', 'email'],
  initialValues: {},
  validate
})(CreateInvestorForm);
