import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Exporting } from '@amcharts/amcharts5/plugins/exporting';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { COLORS } from 'components/advisor/proposal/securities/sector-exposure-distribution/options';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';

const HorizontalBarChart = ({ id, data, multirows, onChartReady, width }) => {
  const chartId = id || SECTOR_EXPOSURE_CHART_ID;

  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);
    // Disable root animations
    root.duration = 0;

    // eslint-disable-next-line no-underscore-dangle
    root._logo.dispose();

    // Set root background to white
    root.container.set(
      'background',
      am5.Rectangle.new(root, {
        fill: am5.color(0xffffff)
      })
    );

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );
    // Set chart default state with no animation
    chart.states.create('default', { duration: 0 });
    chart.states.create('hidden', { duration: 0 });

    const xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.set('visible', false);

    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.set('visible', true);

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: yRenderer,
        categoryField: 'title'
      })
    );

    if (multirows && multirows.length > 0) {
      const allData = [];
      const categories = new Set();

      multirows.reverse().forEach((rowKey, rowIndex) => {
        if (data[rowKey].length === 0) return;
        const color = COLORS[rowKey];

        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: `Series ${rowIndex + 1}`,
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'value',
            categoryYField: 'title',
            sequencedInterpolation: true,
            calculateAggregates: true
          })
        );

        // Set series default state with no animation
        series.states.create('default', { duration: 0 });
        series.states.create('hidden', { duration: 0 });

        series.columns.template.setAll({
          tooltipHTML:
            '<div style="padding:5px; background:white; border:1px solid gray;">{categoryY}: {valueX}</div>',
          cornerRadiusTR: 5,
          cornerRadiusBR: 5,
          fillOpacity: 1,
          strokeOpacity: 1,
          fill: color,
          stroke: color
        });

        const seriesData = data[rowKey].map(item => ({
          ...item,
          color: color
        }));
        series.data.setAll(seriesData);

        seriesData.forEach(item => {
          categories.add(item.title);
        });

        allData.push(...seriesData);
      });

      yAxis.data.setAll([...categories].map(title => ({ title })));
    } else {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series 1',
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: 'value',
          categoryYField: 'title',
          sequencedInterpolation: true,
          calculateAggregates: true
        })
      );

      // Set series default state with no animation
      series.states.create('default', { duration: 0 });
      series.states.create('hidden', { duration: 0 });

      series.columns.template.setAll({
        tooltipHTML:
          '<div style="padding:5px; background:white; border:1px solid gray;">{categoryY}: {valueX}%</div>',
        cornerRadiusTR: 5,
        cornerRadiusBR: 5,
        fillOpacity: 1,
        strokeOpacity: 1
      });

      series.columns.template.adapters.add('fill', (fill, target) =>
        am5.color(target.dataItem.dataContext.color)
      );

      series.columns.template.adapters.add('stroke', (stroke, target) =>
        am5.color(target.dataItem.dataContext.color)
      );

      yAxis.data.setAll(data);
      series.data.setAll(data);
    }

    // chart.appear(1000, 100);

    chartRef.current = chart;

    if (onChartReady) {
      const exporting = Exporting.new(root, {
        pngOptions: {
          quality: 1,
          minWidth: 1100,
          maxHeight: 500,
          maintainPixelRatio: false
        }
      });

      exporting.export('png').then(data => {
        onChartReady(chartId, data);
      });
    }

    return () => {
      root.dispose();
    };
  }, []);

  const dataLength = Math.max(
    data?.length || 0,
    data?.target?.length || 0,
    data?.recommended?.length || 0,
    data?.benchmark?.length || 0
  );

  return (
    <div className="horizontal-bar-chart">
      <div
        id={chartId}
        ref={chartRef}
        style={{
          minWidth: width,
          height: `${Math.min(dataLength * 80, 440).toString()}px`
        }}
      />
    </div>
  );
};

HorizontalBarChart.propsTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  multirows: PropTypes.array,
  onChartReady: PropTypes.func,
  width: PropTypes.string
};

HorizontalBarChart.defaultProps = {
  id: null,
  multirows: null,
  onChartReady: null,
  width: '100%'
};

export default HorizontalBarChart;
