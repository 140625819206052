import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  CYAN_COLOR,
  GRAPHITE_COLOR
} from 'reports/base/styles';

const TITLE_DOWNSIDE_BACKGROUND_COLOR = '#efd8d8';
const GRAPH_BAR_DOWNSIDE_COLOR = '#b9423f';
const TITLE_UPSIDE_BACKGROUND_COLOR = '#e4f3d0';
const GRAPH_BAR_UPSIDE_COLOR = '#4eb63d';
const DASHED_BORDER_COLOR = '#bcbfc4';
const FACTOR_ROW_BACKGROUND_COLOR = '#f0f3f4';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2 },
  headerCell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  headerText: { textAlign: 'center' },
  legend: { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: 8 },
  legendBox: { width: 10, height: 10, marginRight: 5 },
  targetLegendBox: { backgroundColor: GRAPHITE_COLOR },
  recommendedLegendBox: { marginLeft: 15, backgroundColor: CYAN_COLOR },

  factorRowWrapper: { position: 'relative' },
  factorRow: {
    flexGrow: 1,
    flexFlow: 'row wrap',
    height: 64,
    marginBottom: 1,
    backgroundColor: FACTOR_ROW_BACKGROUND_COLOR
  },
  factorRowTitle: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 130,
    justifyContent: 'center',
    padding: 5
  },
  factorRowSeparatorWrapper: {
    position: 'relative',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    flexDirection: 'row'
  },
  factorRowSeparator: { flexGrow: 1, flexShrink: 1, flexBasis: 0, justifyContent: 'center' },
  factorBar: {
    position: 'absolute',
    width: '100%',
    height: 11
  },
  factorScale: { position: 'absolute', bottom: -15, width: '100%' },
  factorScaleText: { flexGrow: 1, flexShrink: 1, flexBasis: 0, textAlign: 'right' },

  downsideHeaderCell: { backgroundColor: TITLE_DOWNSIDE_BACKGROUND_COLOR },
  upsideHeaderCell: { backgroundColor: TITLE_UPSIDE_BACKGROUND_COLOR, marginRight: 0 },
  downsideFactorRow: { flexDirection: 'row' },
  upsideFactorRow: { flexDirection: 'row-reverse' },
  downsideFactorRowTitle: { textAlign: 'left' },
  upsideFactorRowTitle: { textAlign: 'right' },
  downsideFactorRowSeparator: {
    alignItems: 'flex-end',
    borderLeftWidth: '0.1mm',
    borderLeftStyle: 'dashed',
    borderLeftColor: DASHED_BORDER_COLOR
  },
  upsideFactorRowSeparator: {
    alignItems: 'flex-start',
    borderRightWidth: '0.1mm',
    borderRightStyle: 'dashed',
    borderRightColor: DASHED_BORDER_COLOR
  },
  downsideFactorBar: { backgroundColor: GRAPH_BAR_DOWNSIDE_COLOR },
  upsideFactorBar: { backgroundColor: GRAPH_BAR_UPSIDE_COLOR },
  downsideFactorScale: { flexDirection: 'row', justifyContent: 'flex-end' },
  upsideFactorScale: { flexDirection: 'row-reverse', justifyContent: 'flex-end' },
  downsideFactorScaleText: { textAlign: 'left' },
  upsideFactorScaleText: { textAlign: 'right' }
});

export default styles;
