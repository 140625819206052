import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const AnnualTaxableIncomeEmptyQuestion = ({ questions, question }) => {
  const questionText = question.data?.non_retired_question || question.question;
  const {
    estimated_annual_income: estimatedAnnualIncomeText,
    estimated_yearly_contribution: estimatedYearlyContributionText,
    if_non_retired: ifNonRetiredText
  } = question.data?.constants || {};
  return (
    <ReportEmptyQuestion questions={questions} question={{ ...question, question: questionText }}>
      <View style={styles.multiOptions}>
        <Text>{estimatedAnnualIncomeText}</Text>
        <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
      </View>
      <View style={styles.multiOptions}>
        <Text>{estimatedYearlyContributionText}</Text>
        <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
        <Text>{ifNonRetiredText}</Text>
      </View>
    </ReportEmptyQuestion>
  );
};

AnnualTaxableIncomeEmptyQuestion.propTypes = propTypes;

export default AnnualTaxableIncomeEmptyQuestion;
