import PropTypes from 'prop-types';
import React from 'react';

import { FormGroup, VerboseErrorInput } from 'components/form';

const NameModelForm = ({ name, value }) => (
  <div className="row">
    <div className="col-sm-12">
      <FormGroup {...name}>
        <VerboseErrorInput
          type="text"
          label="Account name"
          className="form-control"
          {...name}
          errorAfterLabel
          placeholder={value}
        />
      </FormGroup>
    </div>
  </div>
);

NameModelForm.propTypes = {
  name: PropTypes.object.isRequired,
  value: PropTypes.string
};

NameModelForm.defaultProps = { value: '' };

export default NameModelForm;
