import React from 'react';
import { Scrollchor } from 'react-scrollchor';
import ButtonsSection from './buttons-section';
import ColorsSection from './colors-section';
import IconsSection from './icons-section';
import './styles.scss';
import TypographySection from './typography-section';

const StyleGuide = () => (
  <div id="style-guide">
    <header>
      <div className="container">
        <div className="logo">
          <img src="/img/logos/stratifi-logo.svg" alt="logo" />
        </div>
        <h1>Style Guide</h1>
      </div>
    </header>
    <div className="container style-guide--container">
      <div className="side-menu">
        <ul>
          <li>
            <Scrollchor to="#colors-section" className="nav-link">
              Color
            </Scrollchor>
          </li>
          <li>
            <Scrollchor to="#typography-section" className="nav-link">
              Typography
            </Scrollchor>
          </li>
          <li>
            <Scrollchor to="#buttons-section" className="nav-link">
              Buttons
            </Scrollchor>
          </li>
          <li>
            <Scrollchor to="#icons-section" className="nav-link">
              Icons
            </Scrollchor>
          </li>
        </ul>
      </div>
      <div className="body">
        <ColorsSection />
        <TypographySection />
        <ButtonsSection />
        <IconsSection />
      </div>
    </div>
  </div>
);

export default StyleGuide;
