import { Document } from '@react-pdf/renderer';
import { trackAmplitudeEvent } from 'utils/tracking';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import ReportViewerModal from 'reports/viewer/modal';
import AccountOrModelPrismReport from '..';
import { ACCOUNT_TARGET_TYPE, MODEL_TARGET_TYPE } from '../../../constants';
import { DEFAULT_PORTFOLIO_TOTAL_ASSETS } from '../../../securities/common/utils';
import { getFullyLoadedCharts } from './utils';

const AccountOrModelPrismReportViewer = ({
  accountSavedCharts,
  benchmarks,
  modelSavedCharts,
  portfolio,
  riskAnalysis,
  taxonomies,
  type
}) => {
  if (!portfolio) return null;

  const [coverData, setCoverData] = useState({});
  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);

  const { accountProvider, user, userProvider } = useContext(AdvisorContext);

  // adds by default a value of 100 in case of a model portfolio
  if (type === MODEL_TARGET_TYPE && (!portfolio.value || !Number.isFinite(portfolio.value)))
    portfolio.value = DEFAULT_PORTFOLIO_TOTAL_ASSETS;

  const savedCharts = type === ACCOUNT_TARGET_TYPE ? accountSavedCharts : modelSavedCharts;
  const fullyLoadedCharts = getFullyLoadedCharts(portfolio, type, savedCharts, user);

  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const changeOrientation = value => {
    setOrientation(value);
  };

  const onLoad = () => {
    trackAmplitudeEvent('model_analysis.downloaded', {
      id: portfolio.id,
      model: portfolio.name,
      with_recommended: portfolio.is_benchmark,
      with_benchmark: !!benchmarks
    });
  };

  useEffect(() => {
    if (type === ACCOUNT_TARGET_TYPE)
      accountProvider.getReportCoverData(portfolio.id).then(data => {
        setCoverData(data);
      });
  }, [JSON.stringify(portfolio)]);

  // the report has to be memoized to avoid making unnecessary renders that slow down the
  // entire UI. Additionally, if the chart images are not yet loaded, a <Document /> is returned
  // to also avoid slowing down the UI
  const report = useMemo(
    () =>
      fullyLoadedCharts ? (
        <AccountOrModelPrismReport
          benchmarks={benchmarks}
          coverData={coverData}
          orientation={orientation}
          portfolio={{ ...portfolio, ...savedCharts }}
          riskAnalysis={riskAnalysis}
          subtitle={portfolio.name}
          taxonomies={taxonomies}
          type={type}
          user={user}
          withCustomBranding={withCustomBranding}
        />
      ) : (
        <Document />
      ),
    [
      JSON.stringify(benchmarks),
      JSON.stringify(coverData),
      JSON.stringify(portfolio),
      JSON.stringify(riskAnalysis),
      JSON.stringify(savedCharts),
      orientation,
      type
    ]
  );

  return (
    <ReportViewerModal
      changeOrientation={changeOrientation}
      fullyLoadedReportAssets={fullyLoadedCharts}
      orientation={orientation}
      report={report}
      onLoad={onLoad}
    />
  );
};

AccountOrModelPrismReportViewer.propTypes = {
  accountSavedCharts: PropTypes.object.isRequired,
  benchmarks: PropTypes.array.isRequired,
  modelSavedCharts: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  riskAnalysis: PropTypes.object.isRequired,
  taxonomies: PropTypes.array,
  type: PropTypes.string.isRequired
};

AccountOrModelPrismReportViewer.defaultProps = {
  taxonomies: []
};

export default connect(state => ({
  accountSavedCharts: state.accounts.charts,
  modelSavedCharts: state.models.charts,
  taxonomies: state.market.taxonomies
}))(React.memo(AccountOrModelPrismReportViewer));
