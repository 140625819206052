import SelectAdvisor from 'components/advisor/SelectAdvisor';
import GenericModal from 'components/generic-modal';
import LoadingButton from 'components/loading-button';
import { AdvisorContext } from 'containers/advisor';
import PropType from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import './styles.scss';

export const TYPE = {
  investor: 'investor',
  household: 'household'
};

export const MoveFromAdvisor = ({ isOpen, onMove, onRequestClose, selectedElement, type }) => {
  const { advisorProvider, authProvider, user } = useContext(AdvisorContext);

  const [selectedAdvisorId, setSelectedAdvisorId] = useState();
  const [moving, setMoving] = useState(false);

  if (!authProvider.hasManagerPermissions(user)) return null;

  const handleMove = useCallback(() => {
    setMoving(true);
    onMove(selectedAdvisorId).finally(() => {
      setMoving(false);
    });
    advisorProvider.es.list({ get_all_advisors: true });
  }, [onMove, selectedAdvisorId]);

  let displayType = type === TYPE.investor ? 'client' : 'household';
  if (selectedElement.length > 1) displayType += 's';

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="move-clients"
      styles={{ content: { height: `${325 + (30 * selectedElement.length) / 3}px` } }}
    >
      <h2 data-testid="contact-title" className="title">
        Move to another advisor
      </h2>
      <p>Moving selected {displayType} to the following advisor.</p>

      <SelectAdvisor
        className="stratifi-select"
        onSelect={({ value: advisorId }) => setSelectedAdvisorId(advisorId)}
      />

      <div className="investor-list">
        {selectedElement.map(el => (
          <div key={el.id}>{el.name}</div>
        ))}
      </div>

      <div className="actions">
        <button type="button" className="btn btn-secondary-2" onClick={onRequestClose}>
          Cancel
        </button>
        <LoadingButton
          className="btn btn-primary"
          loading={moving}
          disabled={!selectedAdvisorId || moving}
          onClick={handleMove}
        >
          Submit
        </LoadingButton>
      </div>
    </GenericModal>
  );
};

MoveFromAdvisor.propTypes = {
  isOpen: PropType.bool,
  onMove: PropType.func.isRequired,
  onRequestClose: PropType.func.isRequired,
  selectedElement: PropType.array,
  type: PropType.string
};

MoveFromAdvisor.defaultProps = {
  isOpen: false,
  selectedElement: [],
  type: TYPE.investor
};

export default MoveFromAdvisor;
