import {
  COMPANY_LIMITS_GET,
  COMPANY_LIST_COLLEAGUES,
  COMPANY_STATS_GET,
  COMPANY_TEMPLATES_GET,
  COMPANY_TEMPLATES_UPDATE
} from 'constants/actions';

const initialState = {
  limits: null,

  stats: null,
  templates: {}
};

export default function proxies(state = initialState, action) {
  switch (action.type) {
    case COMPANY_LIST_COLLEAGUES:
      return { ...state, colleagues: action.data };

    case COMPANY_STATS_GET:
      return { ...state, stats: { ...action.data } };

    case COMPANY_TEMPLATES_GET:
      return { ...state, templates: { ...action.data } };

    case COMPANY_LIMITS_GET:
      return { ...state, limits: { ...action.data } };

    case COMPANY_TEMPLATES_UPDATE:
      return { ...state, templates: { ...action.data } };

    default:
      return state || null;
  }
}
