import cn from 'classnames';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import PropTypes from 'prop-types';
import React from 'react';

const SimpleSignatureRow = ({ onDelete, target }) => (
  <div className={cn('signature-section', { 'signature-section--simple': !onDelete })}>
    <div className="signature-section__box">
      <div />
      <span>{target}</span>
    </div>
    <div className="signature-section__box">
      <div />
      <span>Signature</span>
    </div>
    <div className="signature-section__box">
      <div />
      <span>Date</span>
    </div>
    {onDelete && (
      <div className="signature-section__remove">
        <TrashCanIcon className="trash-icon" onClick={onDelete} title="Remove Signature" />
      </div>
    )}
  </div>
);

SimpleSignatureRow.defaultProps = {
  onDelete: null,
  target: 'Advisor Name'
};

SimpleSignatureRow.propTypes = {
  onDelete: PropTypes.func,
  target: PropTypes.string
};

export default SimpleSignatureRow;
