// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#icons-section {
  font-size: 1.5em;
}
#icons-section h2 {
  margin-bottom: 30px;
}
#icons-section ul {
  list-style: none;
  text-align: center;
}
#icons-section ul li {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/style-guide/icons-section/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAEE;EACE,mBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,kBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;EACA,qBAAA;EACA,cAAA;AACN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#icons-section {\n  font-size: 1.5em;\n  h2 {\n    margin-bottom: 30px;\n  }\n  ul {\n    list-style: none;\n    text-align: center;\n    li {\n      width: 60px;\n      height: 60px;\n      display: inline-block;\n      margin: 10px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
