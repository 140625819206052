import cn from 'classnames';
import Radio from 'components/form/radio';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import './styles.scss';

const ValueRequirementQuestionContent = ({ inputProps, isAnnual, refs, toggleIsAnnual }) => {
  const isRetired = refs['retire-in'] <= 0;

  useEffect(() => {
    if (isRetired) toggleIsAnnual(true);
  }, [isRetired]);

  return (
    <>
      <div className={cn({ '-checked': isAnnual })}>
        <label htmlFor="amount">
          {!isRetired && <Radio checked={isAnnual} onChange={toggleIsAnnual} />}
          <span>I will need an annual income of</span>
          <NumericFormat {...inputProps} disabled={!isAnnual} />
          <span>from this investment.</span>
        </label>
        <p className="option-description">
          How much do you think you will need to withdraw each year when you are in retirement?
          Consider what percent of your portfolio will need to be liquidated to cover living
          expenses, leisure activities, and others.
        </p>
      </div>
      {!isRetired && (
        <div className={cn({ '-checked': !isAnnual })}>
          <label htmlFor="amount">
            <Radio checked={!isAnnual} onChange={toggleIsAnnual} />
            <span>I need</span>
            <NumericFormat {...inputProps} disabled={isAnnual} />
            <span>in {refs['retire-in']} years from this investment.</span>
          </label>
        </div>
      )}
    </>
  );
};

ValueRequirementQuestionContent.propTypes = {
  inputProps: PropTypes.object.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  refs: PropTypes.object.isRequired,
  toggleIsAnnual: PropTypes.func.isRequired
};

export default ValueRequirementQuestionContent;
