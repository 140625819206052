import moment from 'moment';
import { REVIEWED } from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const SignatureRequestTrail = ({ report, reviews }) => {
  if (!reviews.length) return null;

  const [firstReview] = reviews;

  let entries = [];
  const signers = reviews.map(review => {
    const signer = review.advisor
      ? `${review.advisor.user.first_name} ${review.advisor.user.last_name}`
      : review.investor.full_name;
    return `${signer} (${review.mail.email})`;
  });

  reviews.forEach(review => {
    const signer = review.advisor
      ? `${review.advisor.user.first_name} ${review.advisor.user.last_name}`
      : review.investor.full_name;

    if (review?.mail?.sent)
      entries.push({
        type: 'sent',
        date: moment.utc(review.mail.sent),
        text: [`Sent to ${signer} (${review.mail.email})`]
      });

    if (review?.mail?.read)
      entries.push({
        type: 'read',
        date: moment.utc(review.mail.read),
        text: [`Viewed by ${signer} (${review.mail.email})`]
      });

    if (review?.reviewed_at)
      entries.push({
        type: 'signed',
        date: moment.utc(review.reviewed_at),
        text: [`Signed by ${signer} (${review.mail.email})`]
      });
  });
  entries = entries.sort((a, b) => a.date.format('x') - b.date.format('x'));

  entries.unshift({
    date: moment.utc(firstReview.created),
    text: [
      `${reviews.length} review request(s) created by ${firstReview.requested_by.user.first_name} ${firstReview.requested_by.user.last_name}`,
      `Signers: ${signers.slice(0, -1).join(', ')} and ${signers.slice(-1)}`
    ]
  });

  if (report.status === REVIEWED)
    entries.push({ date: moment.utc(report.modified), text: ['Complete'] });

  return (
    <div id="signature-request-trail">
      <h2>Signature Audit Trail</h2>
      {entries.map(entry => (
        <div className="trail-entry">
          <span className="trail-entry__date">{entry.date.format('YYYY-MM-DD, h:mm:ss A')}</span>
          {entry.type && (
            <img src={`/img/icons/signature-request-trail/${entry.type}.svg`} alt={entry.type} />
          )}
          <div className="trail-entry__text">
            {entry.text.map(text => (
              <span>{text}</span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

SignatureRequestTrail.propTypes = {
  report: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired
};

export default SignatureRequestTrail;
