// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ModelsMarketplace .header {
  margin-bottom: 0px;
  padding-top: 20px;
}
#ModelsMarketplace .header h2 {
  font-size: 2rem;
}
#ModelsMarketplace .header h3 {
  font-size: 24px;
}
#ModelsMarketplace .models_list {
  position: relative;
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}
#ModelsMarketplace .models_list__providers {
  gap: 20px 22px;
}
#ModelsMarketplace .models_list__portfolio {
  gap: 25px 25px;
}
#ModelsMarketplace .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/models-marketplace/styles.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,iBAAA;AAFJ;AAGI;EACE,eAAA;AADN;AAGI;EACE,eAAA;AADN;AAKE;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;AAHJ;AAII;EACE,cAAA;AAFN;AAKI;EACE,cAAA;AAHN;AAWE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AATJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#ModelsMarketplace {\n  .header {\n    margin-bottom: 0px;\n    padding-top: 20px;\n    h2 {\n      font-size: 2rem;\n    }\n    h3 {\n      font-size: 24px;\n    }\n  }\n\n  .models_list {\n    position: relative;\n    margin-top: 15px;\n    margin-bottom: 30px;\n    display: flex;\n    flex-wrap: wrap;\n    &__providers {\n      gap: 20px 22px;\n    }\n\n    &__portfolio {\n      gap: 25px 25px;\n    }\n  }\n\n  .portfolio-model-card {\n    // margin: auto;\n  }\n\n  .loading-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
