/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import Disclosure from 'components/disclosure';
import { preferredValuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CollaborationPagination from 'pages/collaboration-hub/pagination';
import CollaborationSearch from 'pages/collaboration-hub/search';
import DynamicTable from 'containers/table/dynamic';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TeamTable from 'pages/teams/team-table';
import { toast } from 'react-toastify';
import './styles.scss';

const tabs = {
  TEAMS: 'teams',
  ADVISORS: 'advisors'
};

const DEFAULT_TAB = tabs.TEAMS;
const VISIBILITY_PUBLIC = 'public';
const VISIBILITY_PRIVATE = 'private';

const SecurityAccessControl = ({ advisors, params, meta, security }) => {
  const { advisorProvider, customSecurityProvider, teamProvider } = useContext(AdvisorContext);

  const [selectedOption, setSelectedOption] = useState(VISIBILITY_PRIVATE);
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [teamData, setTeamData] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);

  const enabledActions = true;
  const debounceDelay = 500;
  const securityID = params?.id;
  const DEFAULT_ROLES_ORDERING = ['-role', 'user__first_name'];

  const fetchUsers = async params => advisorProvider.listSecurityAdvisors({ ...params });

  const onAddOrRemoveAdvisor = _.debounce(advisor => {
    const advisorHasAccess = security.advisors.find(a => a.id === advisor.id);
    const securityAdvisors = {};

    if (_.isEmpty(advisorHasAccess))
      securityAdvisors.advisors = [
        ...security.advisors.map(securityAdvisors => securityAdvisors.id),
        advisor.id
      ];
    else {
      securityAdvisors.advisors = security.advisors.filter(
        securityAdvisors => securityAdvisors.id !== advisor.id
      );
      securityAdvisors.advisors = securityAdvisors.advisors.map(items => items.id);
    }

    customSecurityProvider.edit(securityID, securityAdvisors).then(() => {
      toast.success('🎊 The security information was saved successfully.');
    });
  }, debounceDelay);

  const onAddOrRemoveAdvisorHandler = advisor => () => {
    onAddOrRemoveAdvisor(advisor);
  };

  const onAddOrRemoveTeam = _.debounce(rowInfo => {
    const { original: team } = rowInfo;
    const hasAccess = security.teams.find(t => t.id === team.id);
    const securityTeams = {};

    if (_.isEmpty(hasAccess))
      securityTeams.teams = [...security.teams.map(securityTeam => securityTeam.id), team.id];
    else {
      securityTeams.teams = security.teams.filter(securityTeam => securityTeam.id !== team.id);
      securityTeams.teams = securityTeams.teams.map(items => items.id);
    }

    customSecurityProvider.edit(securityID, securityTeams).then(() => {
      toast.success('🎊 The security information was saved successfully.');
    });
  }, debounceDelay);

  const advisorColumns = [
    {
      accessorFn: row => `${row.user.first_name} ${row.user.last_name}`,
      id: 'user__first_name',
      name: 'Name',

      meta: { style: () => ({ width: 350 }) }
    },
    {
      accessorFn: row => row.role,
      id: 'actions',
      enableSorting: false,
      name: 'Actions',
      cell: advisor => (
        <div className="advisors-actions-container">
          <button
            type="button"
            className={cn(
              { 'disabled-action-btn': security.advisors.map(a => a.id).includes(advisor.id) },
              'advisors-btn-transparent'
            )}
            onClick={onAddOrRemoveAdvisorHandler(advisor)}
            aria-label="add"
            disabled={!security.advisors.map(a => a.id).includes(advisor.id)}
          >
            <i
              className={cn(
                { 'disabled-action-btn': security.advisors.map(a => a.id).includes(advisor.id) },
                'icon-cross-circle',
                'security--icon-cross'
              )}
            />
          </button>
          <button
            type="button"
            className={cn(
              { 'disabled-action-btn': !security.advisors.map(a => a.id).includes(advisor.id) },
              'advisors-btn-transparent'
            )}
            onClick={onAddOrRemoveAdvisorHandler(advisor)}
            aria-label="remove"
            disabled={security.advisors.map(a => a.id).includes(advisor.id)}
          >
            <i
              className={cn(
                { 'disabled-action-btn': !security.advisors.map(a => a.id).includes(advisor.id) },
                'icon-checkmark-circle ',
                'security--icon-checkmark'
              )}
            />
          </button>
        </div>
      ),
      meta: { className: () => 'text-center', style: () => ({ width: 125 }) }
    }
  ];

  useEffect(() => {
    if (security) setSelectedOption(security.custom_visibility);
    teamProvider.getList().then(response => {
      setTeamData(response);
    });
    advisorProvider.clearAdvisors();
  }, []);

  const handleOptionChange = useCallback(
    _.debounce(values => {
      const securityVisibility = { custom_visibility: values.target.value };

      customSecurityProvider.edit(securityID, securityVisibility);
      setSelectedOption(values.target.value);
    }, debounceDelay),
    [customSecurityProvider]
  );

  return (
    <form autoComplete="off">
      <section id="security-access-control-container" className="risk-analysis">
        <div>
          <div className="security-access-control--input-radio">
            <label className="input-radio-container radio-text__public">
              <input
                type="radio"
                value={VISIBILITY_PUBLIC}
                checked={selectedOption === VISIBILITY_PUBLIC}
                onChange={handleOptionChange}
              />
              <span className="input-radio-container__checkmark" />
              <span className="access-control--radio-text">
                Public: Visible for all the advisors of my company.
              </span>
            </label>
          </div>
          <div>
            <label className="input-radio-container radio-text__private">
              <input
                type="radio"
                value={VISIBILITY_PRIVATE}
                checked={selectedOption === VISIBILITY_PRIVATE}
                onChange={handleOptionChange}
                className="input-radio-container__checkmark"
              />
              <span className="input-radio-container__checkmark" />
              <span className="access-control--radio-text">
                Private: Apply granular access control.
              </span>
            </label>
          </div>
        </div>
        {selectedOption === VISIBILITY_PRIVATE && (
          <div className="access-control--tabs">
            <ul className="security-details-view__tabs tabs">
              <li className={cn({ active: tab === tabs.TEAMS })}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setTab(tabs.TEAMS)}
                  onKeyUp={() => {}}
                >
                  Teams
                </div>
              </li>
              <li className={cn({ active: tab === tabs.ADVISORS })}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setTab(tabs.ADVISORS)}
                  onKeyUp={() => {}}
                >
                  Advisor
                </div>
              </li>
            </ul>
            {tab === tabs.TEAMS && (
              <div>
                <div className="team-view__header security-team">
                  <div className="team-view__header-actions">
                    <CollaborationSearch searchCallback={setQuerySearch} />
                  </div>
                </div>
                <div className="team-view__content">
                  <TeamTable
                    data={teamData}
                    defaultPageSize={pageSize}
                    querySearch={querySearch}
                    enabledActions={enabledActions}
                    callbackActions={onAddOrRemoveTeam}
                    security={security}
                  />
                  <div className="access-control-team--pagination">
                    <CollaborationPagination paginationCallback={setPageSize} />
                  </div>
                </div>
              </div>
            )}

            {tab === tabs.ADVISORS && (
              <div>
                <DynamicTable
                  columns={advisorColumns}
                  data={advisors}
                  initialParams={{
                    ordering: DEFAULT_ROLES_ORDERING
                  }}
                  label="Advisor"
                  meta={meta}
                  onFetchData={fetchUsers}
                  hasFilter={false}
                />
              </div>
            )}
          </div>
        )}
      </section>
      <Disclosure />
    </form>
  );
};

SecurityAccessControl.propTypes = {
  advisors: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  initializeForm: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  security: state.customSecurity.currentSecurity,
  advisors: state.advisors.list,
  meta: state.advisors.listMeta
});

const SecurityAccessControlWithoutRout = withRouter(SecurityAccessControl);

export default connect(mapStateToProps)(SecurityAccessControlWithoutRout);
