/* eslint-disable no-restricted-syntax */
import { queries, useWindowSize } from 'hooks/window-size';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import React, { useEffect, useMemo, useState } from 'react';
import { FixedBadge, SliderBadge } from './badge';
import SCORE_MARK_POSITIONS from './constants';
import SliderDot from './dot';
import './styles.scss';

const RiskRanges = ({
  fixedScore,
  fixedScoreLabel,
  disabled,
  flavor,
  handleScore,
  id,
  scoreSummary,
  ...rest
}) => {
  const [sliderValue, setSliderValue] = useState(scoreSummary?.overall || 1);
  const isInvestor = flavor === 'investor';
  const isPhone = useWindowSize(queries.phone);

  const badges = useMemo(() => {
    const riskRanges = {};

    for (const key of Object.keys(SCORE_MARK_POSITIONS))
      riskRanges[key] = (
        <SliderBadge mark={SCORE_MARK_POSITIONS[key]} level={key} sliderValue={sliderValue} />
      );
    if (fixedScore?.overall)
      riskRanges[fixedScore.overall] = (
        <FixedBadge label={fixedScoreLabel} score={fixedScore.overall} />
      );
    return riskRanges;
  }, [sliderValue]);

  useEffect(() => {
    let handleResize;
    const sliderElem = document.querySelector(`#${id} .rc-slider`);
    const trackElem = document.querySelector(`#${id} .rc-slider-track`);

    if (id && sliderElem?.offsetWidth && trackElem) {
      handleResize = () => {
        if (isPhone && isInvestor)
          trackElem.style.backgroundSize = `auto ${sliderElem.offsetHeight}px`;
        else trackElem.style.backgroundSize = `${sliderElem.offsetWidth}px`;
      };

      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (id) window.removeEventListener('resize', handleResize);
    };
  }, [isPhone, flavor, id]);

  return (
    <div className="risk-ranges" data-flavor={flavor}>
      <div className="risk-slider" id={id}>
        <Slider
          min={1}
          max={10}
          step={0.1}
          marks={badges}
          disabled={disabled}
          reverse={isPhone && isInvestor}
          vertical={isPhone && isInvestor}
          className="rc-risk-slider"
          defaultValue={sliderValue}
          dotStyle={{ display: 'none' }}
          onAfterChange={val => {
            setSliderValue(val);
            handleScore(val);
          }}
          handle={SliderDot}
          {...rest}
        />
      </div>
    </div>
  );
};

RiskRanges.defaultProps = {
  disabled: true,
  fixedScore: null,
  fixedScoreLabel: '',
  flavor: 'advisor',
  handleScore: () => {}
};

RiskRanges.propTypes = {
  disabled: PropTypes.bool,
  fixedScore: PropTypes.object,
  fixedScoreLabel: PropTypes.string,
  flavor: PropTypes.string,
  handleScore: PropTypes.func,
  id: PropTypes.string.isRequired,
  scoreSummary: PropTypes.object.isRequired
};

export default RiskRanges;
