import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_PAGE_BODY_TEXT,
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  PRISM_COLOR_0,
  PRISM_COLOR_9,
  TABLE_CELL_HEADER_01,
  TABLE_CELL_HEADER_02,
  TABLE_CELL_HEADER_03
} from 'reports/base/styles';

const TAXES_BACKGROUND_COLOR = '#4f5a5d';

export const DEPTH_LEVEL_1_BACKGROUND_COLOR = '#dbf0fb';
export const DEPTH_LEVEL_2_BACKGROUND_COLOR = '#f6f7f7';

export const POSITIVE_DIFF_COLOR = PRISM_COLOR_0;
export const NEGATIVE_DIFF_COLOR = PRISM_COLOR_9;

export const styles = StyleSheet.create({
  wrapper: { ...BASE_PAGE_BODY_TEXT, marginBottom: 40 },
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  cellText: { textAlign: 'center' },
  cellTextLevel1: { fontWeight: 700 },
  firstCellTextLevel2: { paddingLeft: 10 },
  firstCellIconTextLevel2: { paddingLeft: 25 },
  targetHeaderCell: { ...TABLE_CELL_HEADER_01 },
  recommendedHeaderCell: { ...TABLE_CELL_HEADER_02 },
  benchmarkHeaderCell: { ...TABLE_CELL_HEADER_03 },
  recommendedCell: { flexDirection: 'row', justifyContent: 'space-around' },
  recommendedCellText: { width: 40 },
  recommendedPositiveText: { color: POSITIVE_DIFF_COLOR },
  recommendedNegativeText: { color: NEGATIVE_DIFF_COLOR },
  rowLevel1: { backgroundColor: DEPTH_LEVEL_1_BACKGROUND_COLOR },
  rowLevel2: { backgroundColor: DEPTH_LEVEL_2_BACKGROUND_COLOR },
  taxRow: { backgroundColor: TAXES_BACKGROUND_COLOR, fontWeight: 700 },
  taxCellText: { color: 'white' },
  chartContainer: {
    ...BASE_TABLE_CELL,
    alignItems: 'center',
    marginRight: 2,
    paddingVertical: 8,
    paddingHorizontal: 10
  },
  chart: { flexGrow: 1, transform: 'scale(0.85)' }
});

export default styles;
