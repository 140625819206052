import { Path, Svg, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { PRISM_COLOR_0, PRISM_COLOR_4, PRISM_COLOR_9 } from 'reports/base/styles';

const IconDefaultProps = {
  height: 35,
  style: {},
  width: 35
};

const IconProps = {
  height: PropTypes.number,
  style: PropTypes.object,
  width: PropTypes.number
};

export const HighFinancialCapacityIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 28.4 34.8">
      <Path
        fill={PRISM_COLOR_0}
        fillRule="evenodd"
        d="m0,6.3L14.2,0l14.2,6.3v9.5c0,8.8-6.1,17-14.2,19C6,32.8,0,24.6,0,15.8V6.3Zm4.7,12.7l6.3,6.3,12.7-12.7-2.2-2.2-10.5,10.4-4.1-4.1-2.2,2.3Z"
      />
    </Svg>
  </View>
);

HighFinancialCapacityIcon.propTypes = { ...IconProps };
HighFinancialCapacityIcon.defaultProps = { ...IconDefaultProps };

export const ModerateFinancialCapacityIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 36.97 38.6">
      <Path
        fill={PRISM_COLOR_4}
        fillRule="evenodd"
        d="m7.6,3.2l-5.4,2.1c-.2.1-.4.3-.5.6h0c-.1.2-.1.5,0,.8l5.7,14.3c.1.2.3.3.5.3s.4-.2.4-.5v-3l-3.7-9.2c.9-.3,1.3-1.3,1-2.1l2.4-1c.2-.1.3-.2.3-.4v-1.5c0-.2-.1-.3-.2-.4-.2-.1-.3-.1-.5,0h0Zm19.9,19.4V1c0-.3-.1-.5-.3-.7h0c-.2-.2-.4-.3-.7-.3h-15.5c-.3,0-.5.1-.7.3h0c-.2.2-.4.4-.4.7v31.5c0,.3.1.5.3.7h0c.2.2.4.3.7.3h6.9c-.4-.7-.6-1.6-.8-2.4h-3.5c0-.9-.7-1.6-1.7-1.6V4c.9,0,1.7-.7,1.7-1.6h10.2c0,.9.7,1.6,1.7,1.6v19.8c0,.2.1.3.3.4.2.1.3.1.5,0,.2-.2.5-.3.7-.6.2-.2.4-.5.6-.8,0,0,0-.1,0-.2h0Zm9.3,3l-.9,7.4c-.2,1.5.1,3.2,0,4.4,0,.5-.5,1.1-1.3,1.1h-10.2c-.7,0-1.2-.4-1.6-.9l-3.8-4.5c-.5-.6-.7-1.8-.8-2.5l-2.2-10c-.7-3.3,4.5-3.1,5.3-.4l1.2,4.9c.2.8,1.3.6,2.5.6,1.1,0,1.9-.5,2.8-1.3.7-.7,1.1-1.6,1.1-2.6v-10.6c0-.3.3-.6.7-.2l.6.6c.7.7.8,3.1,1.6,4.1,1.1,1.5,2.2,3,3.3,4,1.9,1.6,2.1,2.8,1.7,5.9h0Zm-12.8-8.8c0,1.1-.3,2.1-.9,2.9-.1.2-.3.2-.4.2-.2,0-.3-.1-.4-.3-1.5-3.5-6.5-3.4-7.4-.5-.1.2-.2.3-.4.3s-.4-.1-.5-.3c-.4-.7-.6-1.6-.6-2.4,0-2.9,2.4-5.3,5.3-5.3,3,.1,5.3,2.5,5.3,5.4h0Zm-3.9-8.4c0,.7-.6,1.3-1.3,1.3s-1.3-.6-1.3-1.3.6-1.3,1.3-1.3c.7,0,1.3.6,1.3,1.3Z"
      />
    </Svg>
  </View>
);

ModerateFinancialCapacityIcon.propTypes = { ...IconProps };
ModerateFinancialCapacityIcon.defaultProps = { ...IconDefaultProps };

export const LowFinancialCapacityIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 30.98 38">
      <Path
        fill={PRISM_COLOR_9}
        fillRule="evenodd"
        d="m6.87,0h8.2c.3,0,.6.3.6.6v2.4c0,.2-.1.3-.2.4-1.7,1.8-2.8,4.3-2.8,7s1.1,5.2,2.8,7c.1.1.2.3.2.4v2.7c0,.3.3.6.6.6h5.2c.2,0,.4.1.5.3s.1.4,0,.6l-10.5,15.7c-.1.2-.3.3-.5.3s-.4-.1-.5-.3L.07,22.1c-.1-.2-.1-.4,0-.6s.3-.3.5-.3h5.2c.3,0,.6-.3.6-.6V.6c0-.3.2-.6.5-.6h0Zm16,2.1c4.5,0,8.1,3.7,8.1,8.3s-3.6,8.3-8.1,8.3-8.1-3.7-8.1-8.3c-.1-4.6,3.6-8.3,8.1-8.3Zm3.7,3.5l.4.3c.2.2.3.6.1.8l-7.1,8.4c-.2.2-.6.3-.8.1l-.4-.3c-.2-.2-.3-.6-.1-.8l7.1-8.4c.2-.3.6-.3.8-.1Zm-7-.5c-1.1,0-2,.9-2,2v.9c0,1.1.9,2,2,2s2-.9,2-2v-.9c0-1.1-.9-2-2-2Zm6.6,5.6c-1.1,0-2,.9-2,2v.9c0,1.1.9,2,2,2s2-.9,2-2v-.9c-.1-1-.9-2-2-2h0Zm0,1.3c.4,0,.8.4.8.8v.9c0,.4-.4.8-.8.8s-.8-.4-.8-.8v-.9c0-.5.3-.8.8-.8Zm-6.6-5.7c.4,0,.8.4.8.8v.9c0,.4-.4.8-.8.8s-.8-.4-.8-.8v-.9c0-.4.3-.8.8-.8Z"
      />
    </Svg>
  </View>
);

LowFinancialCapacityIcon.propTypes = { ...IconProps };
LowFinancialCapacityIcon.defaultProps = { ...IconDefaultProps };
