/* eslint-disable no-await-in-loop, no-restricted-syntax, prefer-destructuring */
import cn from 'classnames';
import { EditBox, PageBreak } from 'components/advisor/utils/edit-box';
import { VerboseErrorInput } from 'components/form';
import QuillField from 'components/utils/wysiwyg';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { numToRiskScaleString } from 'utils/utils';
import { IPSPropTypes } from '../types';
import AccountsBreakdown from './accounts-breakdown';
import './styles.scss';
import { processAccountGoalsAssignment, processUncreatedGoals } from './utils';

class IPSExecutiveSummary extends EditBox {
  constructor(props) {
    super(props);
    this.state = { modifiedAccounts: {}, uncreatedGoals: {} };
  }

  componentDidUpdate(prevProps) {
    const { editing: prevEditing } = prevProps;
    const { editing } = this.props;

    // allows clearing the `modifiedAccounts` and `uncreatedGoals` states when not editing
    if (prevEditing !== editing && !editing)
      this.setState({ modifiedAccounts: {}, uncreatedGoals: {} });
  }

  /**
   * The original `onSubmit` method of the `EditBox` parent class is returned, which,
   * being a promise, guarantees that the UI remains disabled while all the new steps
   * are executed.
   */
  async onSubmit(values) {
    const { accountProvider, investorGoalsProvider } = this.context;
    const { modifiedAccounts, uncreatedGoals } = this.state;

    const createdGoals = await processUncreatedGoals(uncreatedGoals, investorGoalsProvider);
    await processAccountGoalsAssignment(modifiedAccounts, createdGoals, accountProvider);

    return super.onSubmit(values).finally(() => {
      this.setState({ modifiedAccounts: {}, uncreatedGoals: {} });
    });
  }

  setAccountGoal = (investorId, accountId, goalIdOrName) => {
    this.setState(prevState => ({
      modifiedAccounts: {
        ...prevState.modifiedAccounts,
        [accountId]: { goal: goalIdOrName ?? null, investor: investorId }
      }
    }));
    if (goalIdOrName && !Number.isFinite(goalIdOrName)) {
      const uncreatedInvestorGoals = this.state.uncreatedGoals[investorId];
      if (!uncreatedInvestorGoals?.includes(goalIdOrName))
        this.setState(prevState => {
          const uncreatedInvestorGoals = prevState.uncreatedGoals[investorId];
          return {
            uncreatedGoals: {
              ...prevState.uncreatedGoals,
              [investorId]: uncreatedInvestorGoals
                ? [...uncreatedInvestorGoals, goalIdOrName]
                : [goalIdOrName]
            }
          };
        });
    }
  };

  render() {
    const {
      actionsDisabled,
      allowChangeText,
      body,
      className,
      currentAssets,
      customActions,
      editing,
      fields,
      hidden,
      investmentHorizon,
      ips,
      riskToleranceScore,
      submitting,
      title
    } = this.props;

    const bodyContent =
      body && body.match(/^(<h2>|<p><h2>)/) ? body : `<h2>${title}</h2>${body || ''}`;

    return (
      <>
        {customActions?.breakSection?.value && !hidden && <PageBreak />}
        <div className={cn('edit-box', 'edit-box-summary', className, { hidden })}>
          {this.getHeader({ actionsDisabled })}

          <div className="body">
            {!editing && (
              <>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: bodyContent + fields.prepend.value }} />

                <div className="read-only-field">
                  <span className="ro-label">Current Assets:</span>
                  <span className="field-box">{currentAssets}</span>
                </div>

                <div className="read-only-field">
                  <span className="ro-label">Risk Tolerance:</span>
                  <span
                    className={`risk-badge-with-score risk-level-${Math.ceil(riskToleranceScore)}`}
                  >
                    {riskToleranceScore}
                  </span>
                  <span style={{ marginRight: 4 }}>of 10 -</span>
                  <span className="risk-verbose">{numToRiskScaleString(riskToleranceScore)}</span>
                </div>

                <div className="read-only-field">
                  <span className="ro-label">Investment Horizon:</span>
                  <span className="field-box">{investmentHorizon || '-'}</span>
                  years
                </div>
              </>
            )}

            {editing && (
              <>
                {allowChangeText ? (
                  <br />
                ) : (
                  // eslint-disable-next-line react/no-danger
                  <div dangerouslySetInnerHTML={{ __html: this.readOnlyContent(bodyContent) }} />
                )}

                <QuillField
                  className="body-wysiwyg no-edit"
                  disabled={!editing || submitting}
                  field={allowChangeText ? fields.body : fields.prepend}
                  options={{ height: 200 }}
                />

                <VerboseErrorInput
                  className="form-control"
                  disabled={submitting}
                  fieldsetClassName="form-group-money"
                  label="Current Assets:"
                  style={{ width: 300 }}
                  type="text"
                  {...fields.currentAssets}
                />

                <VerboseErrorInput
                  className="form-control"
                  disabled={submitting}
                  label="Investment Horizon:"
                  style={{ width: 300 }}
                  type="text"
                  {...fields.investmentHorizon}
                />
              </>
            )}

            <AccountsBreakdown
              editing={editing}
              ips={ips}
              loading={submitting}
              setAccountGoal={this.setAccountGoal}
            />
          </div>
        </div>
      </>
    );
  }
}

IPSExecutiveSummary.propTypes = {
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

IPSExecutiveSummary.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  investorGoalsProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default compose(
  connect((state, props) => ({
    form: `${props.propertyKey}EditBox`,
    user: state.auth.user
  })),
  reduxForm({
    fields: ['body', 'currentAssets', 'investmentHorizon', 'prepend'],
    initialValues: {}
  })
)(IPSExecutiveSummary);
