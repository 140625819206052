/* eslint-disable camelcase */
import { VerboseErrorInput as Input } from 'components/form';
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import TwoFactorCompanyPolicy from '../company-policy';
import { SETUP_2FA } from '../utils/constants';
import { formHasError, handleToastError, handleToastSuccess, validate } from '../utils/helpers';
import TwoFactorSetupContent from './content';

const TwoFactorSetup = ({ fields, activeSetupScreen, setupScreenType }) => {
  const { authProvider, user } = useContext(AuthenticationContext);

  const [configureData, setConfigureData] = useState(null);

  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

  useEffect(() => {
    const payload = { device_type: setupScreenType };
    authProvider.configure2FADevice(payload).then(({ data, _ }) => {
      setConfigureData(data);
    });
  }, []);

  const handleRegister2FADevice = e => {
    e.preventDefault();

    const id = configureData?.device_id;
    const data = new FormData(e.target);
    const payload = { ...Object.fromEntries(data.entries()), device_type: setupScreenType };
    authProvider
      .register2FADevice(id, payload)
      .then(resp =>
        handleToastSuccess(resp, SETUP_2FA.SUCCESS, () => {
          authProvider.getUser().then(() => {
            activeSetupScreen(false);
          });
        })
      )
      .catch(() => handleToastError(SETUP_2FA.ERROR));
  };

  return (
    <div className="container-fluid table-white">
      <div className="form-container">
        <div>
          <h4>{SETUP_2FA.TITLE}</h4>
          <p>{SETUP_2FA.DESCRIPTION}</p>
        </div>
        <div>
          <form onSubmit={handleRegister2FADevice}>
            <TwoFactorSetupContent
              email={user?.email}
              qrCode={configureData?.qr_code}
              setupScreenType={setupScreenType}
            />
            <Input
              autoFocus
              type="number"
              label="Verification code"
              {...fields?.pin}
              onChange={e => {
                const { value } = e.target;
                if (value.length <= 6) fields.pin.onChange(value);
              }}
            />
            <Input type="password" label="Current password" {...fields?.password} />
            <button disabled={formHasError(fields)} type="submit" className="btn btn-primary">
              {setupScreenType !== 'email'
                ? SETUP_2FA.REGISTER_APP_BUTTON
                : SETUP_2FA.REGISTER_EMAIL_BUTTON}
            </button>
          </form>
        </div>
      </div>
      {isComplianceOrAbove && <TwoFactorCompanyPolicy />}
    </div>
  );
};

TwoFactorSetup.propTypes = {
  fields: PropTypes.object.isRequired,
  activeSetupScreen: PropTypes.func.isRequired,
  setupScreenType: PropTypes.string.isRequired
};

const reduxFormToProps = {
  form: 'TwoFactor',
  fields: ['pin', 'password'],
  validate
};

export default reduxForm(reduxFormToProps)(TwoFactorSetup);
