import {
  CLEAR_QUESTIONS,
  GET_QUESTIONNAIRE_TEMPLATE,
  GET_QUESTIONNAIRE_TEMPLATE_QUESTIONS,
  GET_QUESTIONS,
  LIST_QUESTIONNAIRE_TEMPLATES,
  SAVE_ANSWER,
  SAVE_DOCUMENTATION_NOTE
} from 'constants/actions';
import { decamelizeKeys } from 'humps';
import { getLocale } from 'lang/utils';
import _ from 'lodash';
import { getQuestionnaireId, getQuestionnaireIdFromTemplate } from 'utils/questionnaire';
import config from '../config';
import DataProvider from './data';

export default class QuestionProvider extends DataProvider {
  listQuestionnaireTemplates(params = {}) {
    return this.provider
      .get(`${config.apiBase}questionnaire-templates/`, params)
      .then(({ data }) => {
        if (data) {
          this.dispatch(LIST_QUESTIONNAIRE_TEMPLATES, { data });
          return data;
        }
        return null;
      });
  }

  getQuestionnaireTemplate(id) {
    const locale = getLocale();
    return this.provider
      .get(`${config.apiBase}questionnaire-templates/${id}/?lang=${locale}`)
      .then(({ data }) => {
        if (data) {
          const questions = _.sortBy(
            data.questions.map(q => ({
              answer: q.answer,
              position: q.position,
              question_number: q.question_number,
              ...q.question_version
            })),
            ['position']
          );
          const template = { ...data, questions };
          this.dispatch(GET_QUESTIONNAIRE_TEMPLATE, { data: template });
          return template;
        }
        return null;
      });
  }

  getTemplateQuestions(templateId, investorId, accountId) {
    const params = {};

    if (investorId) {
      params.investor = investorId;
      if (accountId) params.account = accountId;
    }

    const locale = getLocale();

    return this.provider
      .get(`${config.apiBase}questionnaire-templates/${templateId}/questions/${locale}/`, params)
      .then(({ data, error }) => {
        if (error) throw Error(error.message);
        const questions = data.map(q => ({
          answer: q.answer,
          position: q.position,
          question_number: q.question_number,
          ...q.question_version
        }));
        const questionnaireId = getQuestionnaireIdFromTemplate(templateId, investorId, accountId);
        this.dispatch(GET_QUESTIONNAIRE_TEMPLATE_QUESTIONS, {
          data: _.sortBy(questions, ['position']),
          questionnaireId
        });
        return questions;
      });
  }

  listQuestionnaires(investorId, isProspect = false) {
    return this.provider.get(
      `${config.apiBase}${isProspect ? 'prospects' : 'investors'}/${investorId}/questionnaires/`
    );
  }

  getQuestionnaire(investorId, questionnaireId, isProspect = false, accountId = null) {
    const params = {};

    if (accountId) params.account = accountId;

    return this.provider
      .get(
        `${config.apiBase}${
          isProspect ? 'prospects' : 'investors'
        }/${investorId}/questionnaires/${questionnaireId}/`,
        params
      )
      .then(response => {
        if (!_.isEmpty(response.data)) {
          const questionnaireId = getQuestionnaireId(investorId, accountId);
          const questions = response.data.answers.map(q => ({
            answer: q.answer,
            position: q.position,
            question_number: q.question_number,
            ...q.question_version
          }));
          this.dispatch(GET_QUESTIONS, {
            data: _.sortBy(questions, ['position']),
            questionnaireId
          });
        }
        return response;
      });
  }

  setQuestionnaire(questionnaire, investorId, accountId = null) {
    const questionnaireId = getQuestionnaireId(investorId, accountId);
    const questions = questionnaire.answers.map(q => ({
      answer: q.answer,
      position: q.position,
      question_number: q.question_number,
      ...q.question_version
    }));
    this.dispatch(GET_QUESTIONS, { data: _.sortBy(questions, ['position']), questionnaireId });
  }

  saveAnswer = (question, questionnaireId, answer) => {
    this.dispatch(SAVE_ANSWER, { data: { questionnaireId, index: question.position, answer } });
  };

  saveDocumentationNotes = data => {
    this.dispatch(SAVE_DOCUMENTATION_NOTE, {
      data
    });
  };

  answerQuestionnaire(templateId, answers, investorId, accountIds) {
    return this.provider.post(`${config.apiBase}investors/${investorId}/questionnaires/`, {
      template: templateId,
      accounts: accountIds ?? [],
      answers: decamelizeKeys(answers)
    });
  }

  updateQuestionnaire(investorId, questionnaireId, data, isProspect = false) {
    return this.provider.patch(
      `${config.apiBase}${
        isProspect ? 'prospects' : 'investors'
      }/${investorId}/questionnaires/${questionnaireId}/`,
      data
    );
  }

  assignQuestionnaire(investorId, questionnaireId, isProspect = false) {
    return this.provider.post(
      `${config.apiBase}${
        isProspect ? 'prospects' : 'investors'
      }/${investorId}/questionnaires/${questionnaireId}/assign/`
    );
  }

  clearQuestionnaires() {
    this.dispatch(CLEAR_QUESTIONS);
  }
}
