/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { animation, transform, spinnerElements } from './helpers';
import '../styles.scss';

const Spinner = ({ size, color }) => (
  <div className="button__spinner" role="button" style={{ transform: `scale(${size})` }}>
    {spinnerElements.map((el, i) => (
      <div key={i} className="bar" style={{ transform: transform(el.rotate) }}>
        <div
          className="bar__shadow"
          style={{ animation: animation(el.linear), background: color }}
        />
      </div>
    ))}
  </div>
);

Spinner.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

export default Spinner;
