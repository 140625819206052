import { createSelector } from 'reselect';
import { modelPortfoliosWithPrismIntentsInProgressSelector } from './prism';

export const allModelsSelector = state => state.models.all;

const prismScoresInProgress = state => state.models.prismScoresInProgress;

export const modelsWithPrismScoresInProgressSelector = createSelector(
  [prismScoresInProgress, modelPortfoliosWithPrismIntentsInProgressSelector],
  (prismScoresInProgress, prismScoreIntentsInProgress) => [
    ...prismScoresInProgress,
    ...prismScoreIntentsInProgress
  ]
);

export const allModelsWithPrismSelector = createSelector([allModelsSelector], models =>
  models.filter(m => !!m.prism_overall)
);
