import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import './styles.scss';

const SubscriptionBanner = ({ children, url }) => (
  <div id="SubscriptionBanner">
    {url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src="/img/logos/prism_symbol_white.png" alt="" />
        {children}
      </a>
    ) : (
      <Link to="/advisor/me/billing/">
        <img src="/img/logos/prism_symbol_white.png" alt="" />
        {children}
      </Link>
    )}
  </div>
);

SubscriptionBanner.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string
};

SubscriptionBanner.defaultProps = {
  url: ''
};

export default SubscriptionBanner;
