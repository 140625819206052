import PropTypes from 'prop-types';

export const BaseCoverAddressPropTypes = {
  address1: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  postcode: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

export const BaseCoverDataPropTypes = {
  prepared_for: PropTypes.string,
  prepared_by: PropTypes.string,
  address: PropTypes.shape(BaseCoverAddressPropTypes),
  company_name: PropTypes.string,
  logo: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string
};
