import { INVITATION_LIST } from 'constants/actions/invitations';
import DataProvider from 'providers/data';
import config from '../config';

class InvitationProvider extends DataProvider {
  create(data) {
    return this.provider.post(`${config.apiBase}invitations/`, data);
  }

  retrieve(id) {
    return this.provider.get(`${config.apiBase}invitations/${id}/`);
  }

  update(id, data) {
    return this.provider.patch(`${config.apiBase}invitations/${id}/`, data);
  }

  delete(id) {
    return this.provider.delete(`${config.apiBase}invitations/${id}/`);
  }

  list() {
    return this.provider.get(`${config.apiBase}invitations/`).then(({ data }) => {
      this.dispatch(INVITATION_LIST, { data });
      return data;
    });
  }

  send(id) {
    return this.provider.post(`${config.apiBase}invitations/${id}/send/`);
  }

  validate(key) {
    return this.provider.post(`${config.apiBase}invitations/${key}/validate/`);
  }
}

export default InvitationProvider;
