/* eslint-disable import/prefer-default-export */

const getScenarioPerformance = (target, title) => {
  // target can be a model or an account
  const scenarios = (target.scenarios || target.prism_score.scenarios).filter(
    scenario => scenario.name === title
  );
  if (!scenarios.length) return null;
  return Math.round(scenarios[0].portfolio_performance * 10000) / 100;
};

export const getScenariosPerformance = (scenarios, target, recommended, benchmark) =>
  scenarios.map(scenario => ({
    title: scenario.name,
    target: getScenarioPerformance(target, scenario.name),
    recommended: recommended ? getScenarioPerformance(recommended, scenario.name) : undefined,
    benchmark: benchmark ? getScenarioPerformance(benchmark, scenario.name) : undefined
  }));
