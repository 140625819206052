import React from 'react';

const PortraitLandscapeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 387.55 383.97"
  >
    <path d="m346.23,108.44c3.17-3.24,5.71-5.94,8.35-8.52,5.04-4.94,11.78-5.22,16.42-.76,4.76,4.58,4.67,11.53-.42,16.69-8.95,9.07-17.96,18.09-27.04,27.04-5.41,5.33-11.83,5.27-17.21-.07-9.05-8.98-18.06-17.99-27.04-27.04-4.87-4.91-5.05-11.9-.57-16.41,4.56-4.6,11.36-4.48,16.39.4,2.58,2.5,5.01,5.15,7.51,7.74.39-.18.79-.36,1.18-.54-1.21-5.48-2.01-11.09-3.7-16.42-9.51-29.88-36.58-50.05-67.96-50.91-8.54-.23-14.16-5.71-13.12-12.78.89-6.05,5.6-9.91,12.17-9.81,27.24.42,50.32,10.57,69.03,30.29,15.33,16.15,23.65,35.58,25.73,57.72.08.81.14,1.63.29,3.42Z" />
    <path d="m375.55,180.66h-172.24V12c0-6.6-5.4-12-12-12H12C5.4,0,0,5.4,0,12v269.75c0,6.6,5.4,12,12,12h81.8v78.22c0,6.6,5.4,12,12,12h269.75c6.6,0,12-5.4,12-12v-179.31c0-6.6-5.4-12-12-12ZM19.85,274.87V18.87h163.61v256H19.85Zm348.82,89.25H112.67v-70.37h78.64c6.6,0,12-5.4,12-12v-81.24h165.36v163.61Z" />
  </svg>
);

export default PortraitLandscapeIcon;
