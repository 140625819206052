// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sector-exposure-distribution .header {
  border-top: 5px solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/proposal/securities/sector-exposure-distribution/styles.scss"],"names":[],"mappings":"AACE;EACE,iCAAA;AAAJ","sourcesContent":["#sector-exposure-distribution {\n  .header {\n    border-top: 5px solid transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
