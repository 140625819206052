// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-endorsements {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.access-endorsements .access-endorsements__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}
.access-endorsements .access-endorsements__header h2 {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.access-endorsements .access-endorsements__header .btn-add-access-endorsements {
  height: 35px;
  font-size: 0.9rem;
}
.access-endorsements .access-endorsements__header-actions {
  display: flex;
  gap: 0.8rem;
}
.access-endorsements .access-endorsements__header-actions .form-group.search {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/access-endorsements/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;AADJ;AAGI;EACE,iBAAA;EACA,gBAAA;AADN;AAII;EACE,YAAA;EACA,iBAAA;AAFN;AAME;EACE,aAAA;EACA,WAAA;AAJJ;AAMI;EACE,gBAAA;AAJN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.access-endorsements {\n  display: flex;\n  flex-direction: column;\n  margin-top: 2rem;\n\n  .access-endorsements__header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 0.75rem;\n\n    h2 {\n      font-size: 1.6rem;\n      margin-bottom: 0;\n    }\n\n    .btn-add-access-endorsements {\n      height: 35px;\n      font-size: 0.9rem;\n    }\n  }\n\n  .access-endorsements__header-actions {\n    display: flex;\n    gap: 0.8rem;\n\n    .form-group.search {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
