import React from 'react';
import ReactModal from 'react-modal';
import PropType from 'prop-types';

import './styles.scss';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#app');

const GenericModal = ({
  children,
  isOpen = false,
  onRequestClose,
  className,
  styles,
  ...extra
}) => (
  <ReactModal
    closeOnEscape
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    portalClassName="stratifi-modal"
    className={`stratifi-modal-content ${className}`}
    overlayClassName="stratifi-modal-overlay"
    style={styles}
    {...extra}
  >
    <button type="button" className="close" onClick={onRequestClose} aria-label="Close">
      <span className="icon-remove" />
    </button>
    {children}
  </ReactModal>
);

GenericModal.propTypes = {
  isOpen: PropType.bool.isRequired,
  onRequestClose: PropType.func.isRequired,
  className: PropType.string,
  styles: PropType.object
};

GenericModal.defaultProps = {
  className: '',
  styles: {}
};

export default GenericModal;
