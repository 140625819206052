import { CLICK_HOUSEHOLD, HOUSEHOLD_APP_MODEL } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router';

const InvestorHouseholdCell = ({ investor }) => {
  const { actionProvider } = useContext(AdvisorContext);

  const onHouseholdClick = householdId => () =>
    actionProvider.slack({
      verb: CLICK_HOUSEHOLD,
      target_app_model: HOUSEHOLD_APP_MODEL,
      target_id: householdId
    });

  if (!investor.household) return <span className="text-muted">Individual</span>;

  return (
    <Link
      onClick={onHouseholdClick(investor.household.id)}
      to={`/advisor/households/${investor.household.id}`}
    >
      {investor.household.name}
    </Link>
  );
};

InvestorHouseholdCell.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorHouseholdCell;
