import React, { useState, memo } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import TableExpander from './table-expander';
import columns from './table-columns';

import {
  MAIN_DELETE_COLUMN,
  MAIN_EXPANDER_COLUMN,
  MAIN_EDIT_COLUMN,
  MAIN_ACTIONS_COLUMN
} from '../constants';

const TeamTable = ({
  data,
  querySearch,
  callbackActions,
  callbackDelete,
  callbackEdit,
  editEnabled,
  enabledActions,
  defaultPageSize,
  security
}) => {
  const [expanded, setExpanded] = useState({});
  const filter = [{ id: 'teamName', value: querySearch }];

  const handleFilterMethod = (_, row) =>
    Object.values(row).some(value => {
      let stringValue = String(value).toLowerCase();
      const filterValue = filter[0].value.toLowerCase();

      if (typeof value === 'object' && !isEmpty(value?.team_memberships)) {
        const members = value.team_memberships
          .map(({ advisor: { user } }) => `${user?.first_name} ${user?.last_name} (${user?.email})`)
          .join(', ');

        stringValue = members.toLowerCase();
      }
      return stringValue.includes(filterValue);
    });

  const handleModal = (rowInfo, callBack) => {
    const { original: team } = rowInfo;
    callBack({ team, show: true });
  };

  const handleExpanderColumn = rowInfo => {
    const path = rowInfo.nestingPath[0];
    const diff = { [path]: !expanded[path] };
    setExpanded({
      ...expanded,
      ...diff
    });
  };

  const handleClickElements = (_, rowInfo, column) => {
    if (MAIN_EDIT_COLUMN === column.id)
      return {
        onClick: e => {
          e.stopPropagation();
          handleModal(rowInfo, callbackEdit);
        }
      };

    if (MAIN_DELETE_COLUMN === column.id)
      return {
        onClick: e => {
          e.stopPropagation();
          handleModal(rowInfo, callbackDelete);
        }
      };

    if (MAIN_EXPANDER_COLUMN === column.id)
      return {
        onClick: e => {
          e.stopPropagation();
          handleExpanderColumn(rowInfo);
        }
      };

    if (MAIN_ACTIONS_COLUMN === column.id)
      return {
        onClick: e => {
          e.stopPropagation();
          callbackActions(rowInfo);
        }
      };
    return {};
  };

  const subComponent = data =>
    !enabledActions && (
      <TableExpander {...data} callbackEdit={callbackEdit} editEnabled={editEnabled} />
    );

  return (
    <ReactTable
      minRows={1}
      data={data}
      filtered={filter}
      expanded={expanded}
      SubComponent={subComponent}
      columns={columns(editEnabled, enabledActions, security)}
      getTdProps={handleClickElements}
      noDataText={<div style={{ height: 50 }}>No teams found.</div>}
      defaultFilterMethod={handleFilterMethod}
      showPageSizeOptions={false}
      defaultPageSize={defaultPageSize}
      pageSize={defaultPageSize}
      resizable={false}
      showPagination
      sortable
    />
  );
};

TeamTable.defaultProps = {
  callbackDelete: () => {},
  callbackEdit: () => {},
  callbackActions: () => {},
  enabledActions: false,
  data: [],
  querySearch: '',
  security: {}
};

TeamTable.propTypes = {
  callbackActions: PropTypes.func,
  callbackDelete: PropTypes.func,
  callbackEdit: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  defaultPageSize: PropTypes.number.isRequired,
  editEnabled: PropTypes.bool.isRequired,
  enabledActions: PropTypes.bool,
  querySearch: PropTypes.string,
  security: PropTypes.object
};

export default memo(TeamTable);
