import { FETCHING, MODEL_LIST_ES } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class ModelsElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}search/model_portfolio/`, params, null, null, true)
      .then(({ data }) => {
        this.dispatch(MODEL_LIST_ES, { data: data.results });
        return data.results;
      });
  }
}

export default ModelsElasticProvider;
