import { StyleSheet } from '@react-pdf/renderer';
import {
  PRISM_COLOR_0,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_5,
  PRISM_COLOR_9,
  PRISM_OFF_COLOR
} from '../../styles';

const styles = StyleSheet.create({
  box: {
    width: 28,
    minWidth: 28,
    paddingVertical: 5,
    backgroundColor: PRISM_OFF_COLOR,
    borderRadius: 2
  },
  bgColor0: { backgroundColor: PRISM_COLOR_0 },
  bgColor1: { backgroundColor: PRISM_COLOR_0 },
  bgColor2: { backgroundColor: PRISM_COLOR_2 },
  bgColor3: { backgroundColor: PRISM_COLOR_2 },
  bgColor4: { backgroundColor: PRISM_COLOR_3 },
  bgColor5: { backgroundColor: PRISM_COLOR_3 },
  bgColor6: { backgroundColor: PRISM_COLOR_5 },
  bgColor7: { backgroundColor: PRISM_COLOR_5 },
  bgColor8: { backgroundColor: PRISM_COLOR_5 },
  bgColor9: { backgroundColor: PRISM_COLOR_5 },
  bgColor10: { backgroundColor: PRISM_COLOR_9 },
  text: { color: 'white', fontWeight: 700, textAlign: 'center' }
});

export default styles;
