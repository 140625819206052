import PropTypes from 'prop-types';
import { ROLES } from 'providers/auth';
import React from 'react';
import Select from 'react-select';

const RolesSelect = ({ defaultValue, disabled, id, onChange }) => {
  const backgroundStyles = state => {
    if (state.isSelected) return '#e6f6fc';
    if (state.isFocused) return '#e4e8ed';
    return 'white';
  };

  const roleOptions = Object.keys(ROLES.labels).map(key => ({
    label: (
      <span key={key}>
        <img alt="logo" src={ROLES.images[key]} className="user-logo small" /> {ROLES.labels[key]}
      </span>
    ),
    value: key
  }));

  const selectedRole = roleOptions.find(({ value }) => value === defaultValue) ?? {};

  return (
    <Select
      className="roles-select"
      isClearable={false}
      isDisabled={disabled}
      isSearchable={false}
      key={id}
      onChange={onChange}
      options={roleOptions}
      styles={{
        option: (styles, state) => ({
          ...styles,
          color: 'black',
          background: backgroundStyles(state),
          cursor: state.isFocused && !state.isSelected ? 'pointer' : 'normal'
        })
      }}
      value={selectedRole}
    />
  );
};

RolesSelect.defaultProps = {
  defaultValue: '',
  disabled: false
};

RolesSelect.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RolesSelect;
