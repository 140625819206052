// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stratifi-modal-content {
  position: relative;
  width: 600px;
  margin: 82px auto;
  min-height: 400px;
  min-width: 400px;
  background-color: white;
  padding: 35px;
  border: 1px solid #d3d6db;
}
.stratifi-modal-content .close {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 10px;
}
.stratifi-modal-content h2.title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.stratifi-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/app/components/generic-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;AACF;AACE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AACJ;AAEE;EACE,eAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,qCAAA;EACA,YAAA;AADF","sourcesContent":[".stratifi-modal-content {\n  position: relative;\n  width: 600px;\n  margin: 82px auto;\n  min-height: 400px;\n  min-width: 400px;\n  background-color: white;\n  padding: 35px;\n  border: 1px solid #d3d6db;\n\n  .close {\n    position: absolute;\n    z-index: 999;\n    right: 10px;\n    top: 10px;\n  }\n\n  h2.title {\n    font-size: 20px;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n}\n\n.stratifi-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(255, 255, 255, 0.85);\n  z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
