/* eslint-disable camelcase */
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { GOOGLE_SOCIAL_PROVIDER } from '../constants';

const SCOPE = 'email profile openid';

/**
 * Google currently recommends using Google Identity Services (GIS) to authenticate and authorize
 * an user using a minimal scope (email, profile, and openid). However, the problem with this
 * mechanism is that it requires getting an initial token called `credentials`, which cannot be
 * used by django-allauth, which actually requires an `access_token`.
 *
 * For this reason, when using the button that Google recommends:
 * https://developers.google.com/identity/gsi/web/guides/display-button#button_rendering,
 * it would be necessary to log in, and later, an `access_token` would have to be requested,
 * causing the user to have to deal with two popups instead of just one.
 *
 * This leads us to the fact that the best option is to use the implicit grant model:
 * https://developers.google.com/identity/oauth2/web/guides/use-token-model
 */
const GoogleSignin = ({ onLogin, setProcessing, setSocialProvider, trustedDeviceId }) => {
  const { authProvider } = useContext(AuthenticationContext);

  const handleErrorCallback = () => {
    setProcessing(false);
  };

  const handleLogin = () => {
    setProcessing(true);
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.GOOGLE_CLIENT_ID,
      scope: SCOPE,
      callback: ({ access_token }) => {
        setSocialProvider(GOOGLE_SOCIAL_PROVIDER, access_token);
        authProvider
          .loginWithGoogle({ access_token, trusted_device_id: trustedDeviceId })
          .then(onLogin({}))
          .finally(() => {
            setProcessing(false);
          });
      },
      error_callback: handleErrorCallback
    });
    client.requestAccessToken();
  };

  return (
    <div id="google-signin">
      <button type="button" onClick={handleLogin}>
        <img src="/img/sso/google-logo.svg" alt="Google" />
        Sign in with Google
      </button>
    </div>
  );
};

GoogleSignin.defaultProps = {
  trustedDeviceId: undefined
};

GoogleSignin.propTypes = {
  onLogin: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  setSocialProvider: PropTypes.func.isRequired,
  trustedDeviceId: PropTypes.string
};

export default GoogleSignin;
