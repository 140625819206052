import { AdvisorContext } from 'containers/advisor';
import React, { useContext, useMemo } from 'react';
import { getNoteTypeOptions } from 'utils/drift';
import FilterContainer from '../common/filter-container';
import MultiSelectFilter from '../common/multi-select';
import { EXCEPTION_TYPE_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const ExceptionTypeFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const {
    user: {
      advisor: {
        company: { exceptions_tags: exceptionsTags }
      }
    }
  } = useContext(AdvisorContext);

  const onChangeHandler = options => {
    const selected = options.map(option => option.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [EXCEPTION_TYPE_ATTR]: selected.length ? selected : undefined
    }));
    setRequiresPageIndexReset(true);
  };

  const exceptionTypeOptions = useMemo(
    () => getNoteTypeOptions(exceptionsTags),
    [JSON.stringify(exceptionsTags)]
  );

  return (
    <FilterContainer attrs={[EXCEPTION_TYPE_ATTR]} filters={filters} label="Type">
      <MultiSelectFilter
        defaultOptions={exceptionTypeOptions.filter(type =>
          (filters?.[EXCEPTION_TYPE_ATTR] || []).includes(type.value)
        )}
        onChange={onChangeHandler}
        options={exceptionTypeOptions}
        placeholder="View All"
      />
    </FilterContainer>
  );
};

ExceptionTypeFilter.propTypes = { ...filterPropTypes };

ExceptionTypeFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionTypeFilter;
