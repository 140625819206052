import { StyleSheet } from '@react-pdf/renderer';
import {
  TABLE_CELL_CATEGORY_01,
  TABLE_CELL_CATEGORY_02,
  TABLE_CELL_CATEGORY_03,
  TABLE_HEADER_BACKGROUND_COLOR
} from 'reports/base/styles';

export const styles = StyleSheet.create({
  contentCell: {
    paddingVertical: 10,
    paddingHorizontal: 5
  },
  contentFirstCell: { flexBasis: 130, flexGrow: 0 },
  firstRow: { marginTop: 10 },
  headerCell: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR },
  headerFirstCell: { backgroundColor: 'transparent', flexBasis: 130, flexGrow: 0 },
  recommendedCell: { ...TABLE_CELL_CATEGORY_02 },
  benchmarkCell: { ...TABLE_CELL_CATEGORY_03 },
  targetCell: { ...TABLE_CELL_CATEGORY_01 }
});

export default styles;
