import PropTypes from 'prop-types';
import React from 'react';
import PersonalityType from './personality-type';
import RiskCapacity from './risk-capacity';
import './styles.scss';

const ScoreContext = ({ flavor, investorName, scoreSummary }) => (
  <div className="score-context" data-flavor={flavor}>
    <PersonalityType flavor={flavor} investorName={investorName} score={scoreSummary.overall} />
    {!!scoreSummary?.capacity && <RiskCapacity score={scoreSummary.capacity} />}
  </div>
);

ScoreContext.defaultProps = {
  flavor: 'advisor',
  investorName: ''
};

ScoreContext.propTypes = {
  flavor: PropTypes.string,
  investorName: PropTypes.string,
  scoreSummary: PropTypes.shape({
    overall: PropTypes.number.isRequired,
    capacity: PropTypes.number
  }).isRequired
};

export default ScoreContext;
