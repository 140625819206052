import ModelMetaForm from 'components/advisor/models/model-meta';
import { FormGroup } from 'components/form';
import { CREATE_MODEL_PORTFOLIO_SUCCESS } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import { BackendValidation } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { formatPercentage } from 'utils/utils';
import { PRIVATE_VISIBILITY } from '../constants';
import './styles.scss';

const validate = values => {
  const errors = {};
  errors.name = errors.name || validation.required(values.name);
  errors.portfolios = (values.portfolios || []).map(values => {
    const errors = {};
    if (values.deleted) return errors;
    errors.weight =
      errors.weight ||
      validation.required(values.weight) ||
      validation.float(values.weight) ||
      validation.floatPositive(values.weight) ||
      (Number.parseFloat(values.weight) < 0.1 && Number.parseFloat(values.weight) > 100.0
        ? 'Invalid percentage value'
        : undefined);
    return errors;
  });

  const weightTotal = values.portfolios.reduce((total, p) => {
    if (!p.weight || p.deleted) return total;
    const currentWeight = typeof p.weight === 'string' ? Number.parseFloat(p.weight) : p.weight;
    return total + currentWeight;
  }, 0);

  if (typeof weightTotal !== 'undefined' && Number.parseFloat(weightTotal.toFixed(2)) !== 100)
    errors.total = 'Total weight should be 100%';

  return errors;
};

const ModelCombineForm = ({
  error,
  errors,
  fields,
  handleSubmit,
  initializeForm,
  meta,
  onCreate,
  onHide,
  onLimitReached,
  portfolios,
  submitting,
  values
}) => {
  const { actionProvider, companyProvider, modelProvider } = useContext(AdvisorContext);

  useEffect(() => {
    initializeForm({ ...values, visibility: PRIVATE_VISIBILITY });
  }, []);

  const getPortfolioName = id => {
    const portfolio = portfolios.find(p => p.id === id);
    return portfolio ? portfolio.name : null;
  };

  const onSubmit = values => {
    let portfolio = null;
    return modelProvider.combine(values, false).then(result => {
      if (result.error) {
        if (result.error.code === 402 && onLimitReached) onLimitReached();
        else if (result.error.errors) return Promise.reject(result.error.errors);
      } else {
        portfolio = result.data;

        companyProvider.getLimits();
        modelProvider.updateSelectedId([]);
        modelProvider.list(meta.params);
        modelProvider.view();
        modelProvider.edit();

        if (portfolio && onCreate) onCreate(portfolio);
        if (portfolio && onHide) onHide(portfolio);

        actionProvider.slack({ verb: CREATE_MODEL_PORTFOLIO_SUCCESS });

        toast.success(() => (
          <div>
            Portfolio <b>{portfolio.name}</b> was created successfully.
          </div>
        ));
      }
      return result;
    });
  };

  const totalWeight = () => {
    const { portfolios } = values;
    let total = portfolios.reduce((sum, { deleted, weight }) => {
      if (deleted) return sum;
      const currentWeight = typeof weight === 'string' ? Number.parseFloat(weight) : weight;
      sum += Number.parseFloat(currentWeight.toFixed(2)) || 0;
      return sum;
    }, 0);
    if (typeof total === 'string') total = Number.parseFloat(total);
    return total;
  };

  return (
    <div id="combine-model-portfolios-form">
      <h3>Combine Model Portfolios</h3>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="portfolios">
          {fields.portfolios.map(({ portfolio, weight, deleted }) =>
            deleted.value ? (
              false
            ) : (
              <div key={portfolio.value} className="portfolio-row row">
                <div className="col-sm-8">
                  <span className="portfolio-name">{getPortfolioName(portfolio.value)}</span>
                </div>

                <div className="col-sm-4">
                  <FormGroup {...weight}>
                    <NumericFormat
                      className="form-control portfolio-value"
                      name="value"
                      thousandSeparator
                      allowNegative
                      value={weight.value}
                      onValueChange={weight.onChange}
                      isNumericString
                    />
                  </FormGroup>
                  {weight.touched && weight.error && (
                    <span className="text-danger error">{weight.error}</span>
                  )}
                </div>

                <div className="action">
                  <button
                    aria-label="Remove"
                    onClick={() => {
                      deleted.onChange(true);
                    }}
                    type="button"
                  >
                    <span className="fs-icon-trash-1 trash-icon" />
                  </button>
                </div>
              </div>
            )
          )}
          <div className="totals-row">
            <div className="row">
              <div className="col-sm-8">
                <span className="portfolio-name">
                  Total: {values.portfolios.filter(p => p.weight && !p.deleted).length} models
                </span>
              </div>
              <div className="col-sm-4">
                <span className="portfolio-weight">{formatPercentage(totalWeight(), 1, 0)}</span>
              </div>
            </div>
          </div>
          {errors.total && !!fields.portfolios.filter(p => p.weight.touched).length && (
            <p className="text-danger error total-error">{errors.total}</p>
          )}
        </div>

        {/* Form section with `name` and `type` fields: */}
        <ModelMetaForm
          isBenchmark={fields.is_benchmark}
          isStrategy={fields.is_strategy}
          name={fields.name}
          team={fields.team}
          trackingParams={{ action: 'Combining' }}
          type={fields.type}
          visibility={fields.visibility}
        />

        {error && <p className="text-danger">{error}</p>}

        <div className="text-sm-center">
          {/* don't use "submit" button type to prevent unintentional submitting by enter */}

          <div>
            <button type="submit" disabled={submitting} className="btn btn-primary btn-title">
              Combine and create a new model
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

ModelCombineForm.propTypes = {
  error: PropTypes.string,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  onCreate: PropTypes.func,
  onHide: PropTypes.func,
  onLimitReached: PropTypes.func.isRequired,
  portfolios: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired
};

ModelCombineForm.defaultProps = {
  error: null,
  onCreate: null,
  onHide: null
};

export default reduxForm({
  form: 'combineModels',
  fields: [
    'name',
    'team',
    'type',
    'visibility',
    'is_benchmark',
    'is_strategy',
    'total', // hidden, just to save wrong total value error
    'portfolios[].portfolio', // hidden
    'portfolios[].weight',
    'portfolios[].deleted'
  ],
  validate
})(BackendValidation(ModelCombineForm));
