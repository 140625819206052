/* eslint-disable no-underscore-dangle */
import {
  CHANGE_CLASS_NAME,
  CLEAR_RISK_ANALYSIS,
  MODEL_ANALYZE_RISK,
  MODEL_CLEAR,
  MODEL_COMMON_BENCHMARKS,
  MODEL_CREATE,
  MODEL_DELETE,
  MODEL_EDIT,
  MODEL_GET,
  MODEL_GET_BY_ID,
  MODEL_GET_STRATEGY_STATS,
  MODEL_MARKETPLACE_REQUEST_SUBSCRIPTION,
  MODEL_LIST,
  MODEL_LIST_ALL,
  MODEL_MARKETPLACE_LIST,
  MODEL_MARKETPLACE_GET,
  MODEL_MARKETPLACE_PROVIDERS,
  MODEL_PRISM_SCORE_COMPLETED,
  MODEL_PRISM_SCORE_FAILED,
  MODEL_PRISM_SCORE_IN_PROGRESS,
  MODEL_SAVE_CHART_IMAGE,
  MODEL_UPDATE,
  MODEL_UPDATE_SELECTED_IDS,
  MODEL_VIEW,
  REQUIRE_RISK_ANALYSIS,
  RISK_ANALYSIS_LAUNCHED
} from 'constants/actions';
import moment from 'moment';
import config from '../config';
import DataProvider from './data';
import ModelsElasticProvider from './models.es';

export const getScoreDataByScoreName = (prismData, scoreName) =>
  prismData.score_list.find(pd => pd.name === scoreName);

class ModelProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new ModelsElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  view(model, reset = false) {
    if (reset) this.dispatch(MODEL_GET, { data: null });
    this.dispatch(MODEL_VIEW, { data: model });
  }

  edit(model, reset = false) {
    if (reset) this.dispatch(MODEL_GET, { data: null });
    this.dispatch(MODEL_EDIT, { data: model });
  }

  list(params) {
    return this._provider.get(`${config.apiBase}models/`, params).then(({ data, error }) => {
      if (!error) {
        this._params = params;
        this.dispatch(MODEL_LIST, {
          data: {
            data: data.results || [],
            meta: { params, count: data.count, totalPages: data.total_pages }
          }
        });
      } else this.dispatch(MODEL_LIST, { data: { data: [], meta: {} } });
      return data;
    });
  }

  getMarketplaces(params) {
    return this._provider
      .get(`${config.apiBase}models/marketplace`, params)
      .then(({ data, error }) => {
        if (!error) {
          this._params = params;
          this.dispatch(MODEL_MARKETPLACE_LIST, {
            data: {
              data: data.results || [],
              meta: { params, count: data.count, totalPages: data.total_pages }
            }
          });
        } else this.dispatch(MODEL_MARKETPLACE_LIST, { data: { data: [], meta: {} } });
        return data;
      });
  }

  getMarketplace(id) {
    return this._provider.get(`${config.apiBase}models/marketplace/${id}/`).then(data => {
      if (!data.error) this.dispatch(MODEL_MARKETPLACE_GET, { data: data.data });
      return data;
    });
  }

  getMarketplaceProviders(params) {
    return this._provider
      .get(`${config.apiBase}models/marketplace/providers`, params)
      .then(({ data, error }) => {
        if (!error) {
          this._params = params;
          this.dispatch(MODEL_MARKETPLACE_PROVIDERS, {
            data: {
              data: data || []
            }
          });
        } else this.dispatch(MODEL_MARKETPLACE_PROVIDERS, { data: { data: [] } });
        return data;
      });
  }

  listAll(params) {
    return this._provider
      .get(`${config.apiBase}models/list-all/`, params)
      .then(({ data, error }) => {
        if (!error) this.dispatch(MODEL_LIST_ALL, { data });
        return data;
      });
  }

  getCommonBenchmarks() {
    const params = { is_benchmark: true, is_common: true };
    this._provider.get(`${config.apiBase}models/`, params).then(data => {
      if (!data.error)
        this.dispatch(MODEL_COMMON_BENCHMARKS, { data: { data: data.data.results || [] } });
      else this.dispatch(MODEL_COMMON_BENCHMARKS, { data: null });
      return data;
    });
  }

  get(id) {
    return this._provider.get(`${config.apiBase}models/${id}/`).then(data => {
      if (!data.error) this.dispatch(MODEL_GET, { data: data.data });
      return data;
    });
  }

  getById(id) {
    return this.provider.get(`${config.apiBase}models/${id}/`).then(data => {
      if (!data.error) this.dispatch(MODEL_GET_BY_ID, { id, data: data.data });
      return data;
    });
  }

  create(model) {
    return this.provider.post(`${config.apiBase}models/`, model).then(resp => {
      if (!resp.error) this.dispatch(MODEL_CREATE, { data: resp.data });
      return resp;
    });
  }

  createDraft(model) {
    return this.provider.post(`${config.apiBase}models/`, {
      ...model,
      is_draft: true,
      name: `${model.name} (#${new Date().valueOf()})`
    });
  }

  update(model, list = false) {
    if (!model.id) return this.create(model);
    return this._provider.put(`${config.apiBase}models/${model.id}/`, model).then(data => {
      if (!data.error) {
        this.dispatch(MODEL_UPDATE, { data: data.data });
        if (list) this.list(this._params);
      }
      return data;
    });
  }

  updateModelName(model, data) {
    return this._provider.patch(`${config.apiBase}models/${model.id}/`, data).then(response => {
      if (!response.error)
        this.dispatch(MODEL_UPDATE, { data: { ...model, name: response.data.name } });
      return data;
    });
  }

  patchModel(modelID, data) {
    return this._provider.patch(`${config.apiBase}models/${modelID}/`, data).then(response => {
      if (!response.error) this.dispatch(MODEL_VIEW, { data: response.data });
      return data;
    });
  }

  delete(modelId, list = false) {
    return this._provider.delete(`${config.apiBase}models/${modelId}/`).then(data => {
      if (!data.error) {
        this.dispatch(MODEL_DELETE, { data: modelId });
        if (list) this.list(this._params);
      }
      return data;
    });
  }

  combine(data, list = false) {
    return this._provider.post(`${config.apiBase}models/combine/`, data).then(data => {
      if (!data.error) {
        this.dispatch(MODEL_CREATE, { data: data.data });
        if (list) this.list(this._params);
      }
      return data;
    });
  }

  clearModel() {
    this.dispatch(MODEL_CLEAR, {});
  }

  getStrategyStats(id) {
    return this.provider
      .get(`${config.apiBase}models/${id}/strategy_stats/`)
      .then(({ data, error }) => {
        if (!error) this.dispatch(MODEL_GET_STRATEGY_STATS, { data });
        return data;
      });
  }

  riskAnalysisPrecomputed(rawData) {
    this.dispatch(MODEL_ANALYZE_RISK, { data: { ...rawData } });
  }

  clearRiskAnalysis() {
    this.dispatch(CLEAR_RISK_ANALYSIS, {});
  }

  updatePrism(id) {
    this.dispatch(RISK_ANALYSIS_LAUNCHED, { data: { id } });
    this.dispatch(MODEL_PRISM_SCORE_IN_PROGRESS, { id });

    return this.provider
      .post(`${config.apiBase}models/${id}/update_prism_score/`)
      .then(data => {
        if (data.error) throw new Error(data.error);
        this.dispatch(MODEL_PRISM_SCORE_COMPLETED, { id });
        return data;
      })
      .catch(error => {
        this.dispatch(MODEL_PRISM_SCORE_FAILED, { id });
        throw error;
      });
  }

  downloadCSV(id) {
    return this._provider
      .requestNoJSON(`${config.apiBase}models/${id}/csv/`)
      .then(response => Promise.all([response.blob(), response]))
      .then(([data, response]) => (response.ok ? Promise.resolve(data) : Promise.reject(data)))
      .then(data => {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'models.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  uploadCSV(id, file) {
    return this._provider.postFormData(`${config.apiBase}models/${id}/csv/`, file);
  }

  downloadPortfoliosCSV(params = {}) {
    return this.provider
      .requestNoJSON(`${config.apiBase}models/download_information_csv/`, null, params, true)
      .then(response => response.blob())
      .then(blob => {
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = objectURL;
        link.download = `StratiFi Models ${moment().format('ll')}.csv`;
        link.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(objectURL);
        }, 250);
      });
  }

  uploadPortfoliosCSV(files) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    return this.provider.postFormData(`${config.apiBase}models/upload_information_csv/`, formData);
  }

  getReportCoverData(modelId, params) {
    return this.provider
      .get(`${config.apiBase}models/${modelId}/report/`, params)
      .then(({ data }) => data);
  }

  changeBtnClass(className) {
    this.dispatch(CHANGE_CLASS_NAME, { data: { buttonClass: className } });
  }

  updateSelectedId(selectedModelIds) {
    this.dispatch(MODEL_UPDATE_SELECTED_IDS, { data: { selectedModelIds } });
  }

  requireRiskAnalysis(id) {
    this.dispatch(REQUIRE_RISK_ANALYSIS, { data: { id } });
  }

  saveChartImage(data) {
    this.dispatch(MODEL_SAVE_CHART_IMAGE, { data });
  }

  postSubscription(marketplaceId) {
    return this.provider
      .post(`${config.apiBase}models/marketplace/${marketplaceId}/subscribe/`)
      .then(() => {
        this.dispatch(MODEL_MARKETPLACE_REQUEST_SUBSCRIPTION, { data: { id: marketplaceId } });
      });
  }
}

export default ModelProvider;
