/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { accountsWithPrismIntentsInProgressSelector } from './prism';

const prismScoresInProgress = state => state.accounts.prismScoresInProgress;

export const accountsWithPrismScoresInProgressSelector = createSelector(
  [prismScoresInProgress, accountsWithPrismIntentsInProgressSelector],
  (prismScoresInProgress, prismScoreIntentsInProgress) => [
    ...prismScoresInProgress,
    ...prismScoreIntentsInProgress
  ]
);
