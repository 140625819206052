import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE, BASE_TABLE_CELL, BASE_TABLE_ROW } from 'reports/base/styles';

const ROW_BACKGROUND_COLOR = '#f6f7f7';

export const styles = StyleSheet.create({
  container: { ...BASE_TABLE_ROW },
  table: { ...BASE_TABLE, marginTop: 5 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  cellText: { textAlign: 'center' },
  cellTextLevel1: { fontWeight: 700 },
  firstCellTextLevel2: { paddingLeft: 10 },
  firstCellIconTextLevel2: { paddingLeft: 25 },
  rowLevel: {
    backgroundColor: ROW_BACKGROUND_COLOR,
    marginBottom: 0,
    borderLeft: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: 'black'
  },
  rowTotal: { borderLeft: 5, borderLeftStyle: 'solid', borderLeftColor: 'white' },
  chartContainer: { flexGrow: 1, maxWidth: 140, paddingTop: 17 },
  summaryContainer: { ...BASE_TABLE_CELL, paddingTop: 0, paddingBottom: 9, paddingHorizontal: 10 },
  chart: { objectFit: 'contain', maxHeight: 120, marginBottom: 15 },
  horizontalChart: { objectFit: 'contain', marginBottom: 15 }
});

export default styles;
