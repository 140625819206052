import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import { TYPE_ANNUAL, TYPE_ONCE } from '../hooks';

const ValueRequirementReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  return (
    <ReadOnlyQuestion question={question}>
      <p>
        {question.answer.type === TYPE_ANNUAL && 'I will need an annual income of '}
        {question.answer.type === TYPE_ONCE && 'I need '}
        {formatMoney(question.answer.amount)} from this investment
      </p>
    </ReadOnlyQuestion>
  );
};

ValueRequirementReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ValueRequirementReadOnlyQuestion;
