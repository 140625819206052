import arrowDownIcon from 'assets/img/icons/arrow-down-white.svg';
import cn from 'classnames';
import DisplayScore from 'components/utils/DisplayScore';
import RiskToleranceQuestionnaireReadOnly from 'containers/risk-tolerance-questionnaire/questionnaire/read-only';
import RiskToleranceQuestionnaireSingleReportViewer from 'containers/risk-tolerance-questionnaire/report/single/viewer';
import DiscussionTopicsTop from 'containers/risk-tolerance-questionnaire/result/discussion-topics/top';
import {
  capacityInfo,
  personalityInfo
} from 'containers/risk-tolerance-questionnaire/result/score-context/utils/helpers';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const ToggableQuestionnaire = ({ defaultHidden, investor, questionnaire, reportCoverData }) => {
  const [hiddenQuestionnaire, setHiddenQuestionnaire] = useState(defaultHidden);

  const toggleDiscussionTopics = () => {
    setHiddenQuestionnaire(prevValue => !prevValue);
  };

  const { is_scored: isScored } = questionnaire.template;

  const account = {
    investor: { ...investor, investor_target_score: { data: questionnaire.score_data } },
    target_score_updated_at: questionnaire.modified
  };
  const questions = _.sortBy(
    questionnaire.answers.map(q => ({
      answer: q.answer,
      position: q.position,
      question_number: q.question_number,
      ...q.question_version
    })),
    ['position']
  );

  let personality;
  let personalityLevel;
  let capacity;
  let capacityLevel;
  if (isScored && questionnaire.score_data?.overall) {
    personality = personalityInfo(questionnaire.score_data.overall);
    personalityLevel = Math.ceil(questionnaire.score_data.overall);
  }
  if (isScored && questionnaire.score_data?.capacity) {
    capacity = capacityInfo(questionnaire.score_data.capacity);
    capacityLevel = Math.ceil(questionnaire.score_data.capacity);
  }

  return (
    <div className="toggable-questionnaire">
      <div className="toggable-questionnaire__header" id="toggable-questionnaire-header">
        <div className="header__title">
          <p className="title__date">{moment.utc(questionnaire.created).format('ll')}</p>
          {isScored && questionnaire.score_data?.overall && (
            <DisplayScore score={questionnaire.score_data.overall} />
          )}
          <p className="title__text">
            {questionnaire.template.name} answered by {questionnaire.answered_by}
          </p>
        </div>

        <div className="header__actions">
          <RiskToleranceQuestionnaireSingleReportViewer
            account={account}
            questionnaire={{ ...questionnaire, questions }}
            reportCoverData={reportCoverData}
          />
          <button
            aria-controls="toggable-questionnaire-content"
            aria-expanded="false"
            className={cn('btn', 'btn-secondary', 'font-weight-normal', {
              collapsed: hiddenQuestionnaire
            })}
            data-target="#toggable-questionnaire-content"
            onClick={toggleDiscussionTopics}
            type="button"
          >
            {hiddenQuestionnaire ? 'View Details' : 'Hide Details'}
            <img src={arrowDownIcon} className="arrow-icon" alt="arrow-icon" />
          </button>
        </div>
      </div>

      <div
        aria-labelledby="toggable-questionnaire-header"
        className={cn('collapse', { show: !hiddenQuestionnaire })}
        data-parent="#toggable-questionnaire-toggleable"
        id="toggable-questionnaire-content"
      >
        <div className="toggable-questionnaire__body">
          {isScored && (personality || capacity) && (
            <div className="body__personality-and-capacity">
              {personality && (
                <div className="personality">
                  <img alt="Personality" src={personality.src} height={35} />
                  <span className={`personality--level-${personalityLevel}`}>
                    {personality.label}
                  </span>
                </div>
              )}
              {capacity && (
                <div className="capacity">
                  <img alt="Capacity" src={capacity.src} height={35} />
                  <span className={`capacity--level-${capacityLevel}`}>{capacity.label}</span>
                </div>
              )}
            </div>
          )}

          {!!questionnaire.discussion_topics.length && (
            <div className="body__topics">
              <div className="section-title">Topics to discuss</div>
              <DiscussionTopicsTop discussionTopics={questionnaire.discussion_topics} />
            </div>
          )}

          <div className="body__answers">
            <div className="section-title">Form Answers</div>
            <RiskToleranceQuestionnaireReadOnly
              form={`risk-tolerance-questionnaire-read-only__investor-questionnaire-${questionnaire.id}`}
              questions={questions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ToggableQuestionnaire.defaultProps = {
  defaultHidden: true,
  questionnaire: {},
  reportCoverData: {}
};

ToggableQuestionnaire.propTypes = {
  defaultHidden: PropTypes.bool,
  investor: PropTypes.object.isRequired,
  questionnaire: PropTypes.object,
  reportCoverData: PropTypes.object
};

export default ToggableQuestionnaire;
