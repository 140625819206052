// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketplace-details__title {
  margin: 2.5rem 0 0 0;
  display: flex;
  justify-content: space-between;
}
.marketplace-details li > button {
  background: none;
}
@keyframes wiggle {
  0%, 100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}
.marketplace-details .wiggle {
  display: inline-block;
  animation: wiggle 0.5s infinite;
}
.marketplace-details .rejected-msg {
  font-size: 0.8rem;
  margin: auto 0px;
}
.marketplace-details .portfolio-model__link {
  height: 36px;
  color: white;
  margin: auto 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/marketplace/details/styles.scss"],"names":[],"mappings":"AAGE;EACE,oBAAA;EACA,aAAA;EACA,8BAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AASE;EACE;IAEE,wBAAA;EARJ;EAUE;IACE,uBAAA;EARJ;AACF;AAWE;EACE,qBAAA;EACA,+BAAA;AATJ;AAYE;EACE,iBAAA;EACA,gBAAA;AAVJ;AAaE;EACE,YAAA;EACA,YAAA;EACA,gBAAA;AAXJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.marketplace-details {\n  &__title {\n    margin: 2.5rem 0 0 0;\n    display: flex;\n    justify-content: space-between;\n  }\n\n  li > button {\n    background: none;\n  }\n\n  $rotation-angle: 3deg;\n  $animation-duration: 0.5s;\n\n  @keyframes wiggle {\n    0%,\n    100% {\n      transform: rotate(-$rotation-angle);\n    }\n    50% {\n      transform: rotate($rotation-angle);\n    }\n  }\n\n  .wiggle {\n    display: inline-block;\n    animation: wiggle $animation-duration infinite;\n  }\n\n  .rejected-msg {\n    font-size: 0.8rem;\n    margin: auto 0px;\n  }\n\n  .portfolio-model__link {\n    height: 36px;\n    color: white;\n    margin: auto 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
