import PropTypes from 'prop-types';
import React from 'react';
import { QuestionPropTypes } from '../../props';
import './styles.scss';

const SectionSeparatorReadOnly = ({ question }) => (
  <div className="section-separator-read-only">
    <p>{question.question}</p>
  </div>
);

SectionSeparatorReadOnly.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default SectionSeparatorReadOnly;
