import React from 'react';
import FilterContainer from '../common/filter-container';
import RiskLevelFilter from '../common/risk-level';
import { ACCOUNT_RISK_LEVEL_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const AccountRiskLevelFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer attrs={[ACCOUNT_RISK_LEVEL_ATTR]} filters={filters} label="Account Risk Level">
    <RiskLevelFilter
      attr={ACCOUNT_RISK_LEVEL_ATTR}
      filters={filters}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

AccountRiskLevelFilter.propTypes = { ...filterPropTypes };

AccountRiskLevelFilter.defaultProps = { ...filterDefaultProps };

export default AccountRiskLevelFilter;
