import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import { getTolerableLossAnswer } from '../utils';

const TolerableLossQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  return (
    <ReportQuestion question={question}>
      <Text>{getTolerableLossAnswer(question)}</Text>
    </ReportQuestion>
  );
};

TolerableLossQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default TolerableLossQuestion;
