import { FormGroup, VerboseErrorInput } from 'components/form';
import PropTypes from 'prop-types';
import React from 'react';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import './styles.scss';

const TextQuestion = ({ question, field, ...props }) => (
  <Question {...props} nextDisabled={field.invalid} question={question}>
    <FormGroup {...field}>
      <VerboseErrorInput type="text" className="form-control" {...field} />
    </FormGroup>
  </Question>
);

TextQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default TextQuestion;
