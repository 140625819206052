import { AuthenticationContext } from 'containers/auth';
import HeaderInvestor from 'containers/layout/header-investor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AccountProvider from 'providers/account';
import ActionProvider from 'providers/actions';
import AuthProvider from 'providers/auth';
import IntegrationProvider from 'providers/integrations';
import InvestorProvider from 'providers/investor';
import InvitationProvider from 'providers/invitation';
import IPSProvider from 'providers/ips';
import ProposalProvider from 'providers/proposal';
import ProspectProvider from 'providers/prospects';
import QuestionProvider from 'providers/question';
import UserProvider from 'providers/user';
import React, { Component, createContext, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';

export const InvestorContext = createContext({});

export const InvestorSessionRedirect = ({ params: { id, legacyToken, splat } }) => {
  /**
   * Given a legacy token, exchange it by a session token.
   * Used only for backward compatibility URLs that were already sent by email
   * with the form /investor/:id/:legacyToken/*
   * Notice that the parameter `splat` contains the URL fragment after the token.
   */
  const { authProvider } = useContext(AuthenticationContext);
  useEffect(() => {
    authProvider.getSession(legacyToken).then(({ data }) => {
      if (data && data.token)
        document.location.href = `/investor/${id}/${splat}?session=${data.token}`;
    });
  }, [id, legacyToken]);
  return null;
};

class Investor extends Component {
  constructor(props) {
    super(props);

    this.getClientProvider = this.getClientProvider.bind(this);

    const clientProvider = this.getClientProvider(props.user, props.location.pathname);

    this.state = {
      context: {
        accountProvider: props.accountProvider,
        actionProvider: props.actionProvider,
        authProvider: props.authProvider,
        clientProvider,
        integrationProvider: props.integrationProvider,
        investorProvider: props.investorProvider,
        invitationProvider: props.invitationProvider,
        ipsProvider: props.ipsProvider,
        proposalProvider: props.proposalProvider,
        prospectProvider: props.prospectProvider,
        questionProvider: props.questionProvider,
        routerActions: props.routerActions,
        user: props.user,
        userProvider: props.userProvider
      }
    };
  }

  getChildContext() {
    const { context } = this.state;
    return context;
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;
    const { context } = this.state;

    let clientProvider;
    if (!_.isEqual(user, prevUser)) {
      clientProvider = this.getClientProvider(user);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ context: { ...context, clientProvider, user } });
    }
  }

  getClientProvider = user => {
    const { investorProvider, prospectProvider } = this.props;
    const isProspectUser = user && user.investor && user.investor.is_prospect;
    return isProspectUser ? prospectProvider : investorProvider;
  };

  render() {
    const { children } = this.props;
    return (
      <InvestorContext.Provider value={this.getChildContext()}>
        <div className="investor-container">
          <HeaderInvestor />
          <div className="investor-container-wrapper">
            {children}
            <div className="brand" />
          </div>
        </div>
      </InvestorContext.Provider>
    );
  }
}

const InvestorContextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  integrationProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  invitationProvider: PropTypes.object.isRequired,
  ipsProvider: PropTypes.object.isRequired,
  proposalProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  questionProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

Investor.propTypes = {
  ...InvestorContextTypes,
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

Investor.childContextTypes = {
  ...InvestorContextTypes,
  clientProvider: PropTypes.object.isRequired
};

export default connect(
  state => ({ user: state.auth.user }),
  dispatch => ({
    accountProvider: new AccountProvider({ dispatch }),
    actionProvider: new ActionProvider({ dispatch }),
    authProvider: new AuthProvider({ dispatch }),
    integrationProvider: new IntegrationProvider({ dispatch }),
    investorProvider: new InvestorProvider({ dispatch }),
    invitationProvider: new InvitationProvider({ dispatch }),
    ipsProvider: new IPSProvider({ dispatch }),
    proposalProvider: new ProposalProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch }),
    questionProvider: new QuestionProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch),
    userProvider: new UserProvider({ dispatch })
  })
)(Investor);
