import { Text, View } from '@react-pdf/renderer';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Html from 'react-pdf-html';
import { DEFAULT_SECONDARY_COLOR } from '../../utils';
import imgRenderer from './renderers/img';
import liRenderer from './renderers/li';
import { styles, stylesheet } from './styles';
import { sanitizeHtmlContent } from './utils';

const BULLET_STYLES = { paddingRight: 5 };
const CONTENT_STYLES = { flexGrow: 1, flexBasis: 0 };

const CustomSection = ({ breakSection, body, bodyStyles, htmlStyles, sectionStyles, title }) => {
  const { orientation, user, withCustomBranding } = useContext(AdvisorContext);

  const brandSecondaryColor =
    (withCustomBranding && user.advisor.company?.brand_secondary_color) || DEFAULT_SECONDARY_COLOR;

  return (
    <View break={breakSection} style={sectionStyles}>
      {!!title && <Text style={[styles.title, { color: brandSecondaryColor }]}>{title}</Text>}
      {!!body && (
        <Html
          style={[styles.body, bodyStyles]}
          stylesheet={{ ...stylesheet, htmlStyles }}
          renderers={{
            img: imgRenderer(orientation),
            li: liRenderer(BULLET_STYLES, CONTENT_STYLES)
          }}
        >
          {sanitizeHtmlContent(body)}
        </Html>
      )}
    </View>
  );
};

CustomSection.propTypes = {
  breakSection: PropTypes.bool,
  body: PropTypes.string,
  bodyStyles: PropTypes.object,
  htmlStyles: PropTypes.object,
  sectionStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string
};

CustomSection.defaultProps = {
  breakSection: false,
  body: '',
  bodyStyles: {},
  htmlStyles: {},
  sectionStyles: {},
  title: ''
};

export default CustomSection;
