import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';
import './styles.scss';

const ENTER_KEY = 'Enter';

const renderSuggestion = suggestion => <span>{suggestion.name}</span>;

class NewInvestorGoal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      goalName: '',
      suggestions: []
    };

    this.onSave = this.onSave.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.retrieveSuggestions = _.debounce(this.retrieveSuggestions, 500);
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  retrieveSuggestions(name) {
    const { investorGoalsProvider } = this.context;
    const { goalName } = this.state;

    investorGoalsProvider.es.search({ search: name }).then(data => {
      let suggestions = _.uniqBy(data, suggestion => suggestion.name);

      // Show the whole list with alphabetical order if there is not a current value.
      if (!goalName) suggestions = _.orderBy(suggestions, ['name'], ['asc']);

      this.setState({ suggestions });
      return data;
    });
  }

  toggleEditing() {
    const { editing } = this.state;
    this.setState({ editing: !editing, goalName: '' }, () => {
      if (!editing) this.nameInput.focus();
    });
  }

  onSuggestionsFetchRequested(value) {
    this.setState({ goalName: value });
    this.retrieveSuggestions(value);
  }

  onSuggestionSelected(_, { suggestion }) {
    if (suggestion && suggestion.name)
      this.setState({
        goalName: suggestion.name,
        suggestions: []
      });
  }

  onSave() {
    const { investorId } = this.props;
    const { investorGoalsProvider } = this.context;
    const { goalName } = this.state;

    investorGoalsProvider.create({ name: goalName, investor: investorId }).then(() => {
      toast.success(() => (
        <div>
          Goal <b>{goalName}</b> was created successfully.
        </div>
      ));
    });
    this.setState({ goalName: '' });
    this.toggleEditing();
  }

  render() {
    const { editing, goalName, suggestions } = this.state;
    const canSave = !!goalName;

    return (
      <div className="new-investor-goal">
        <i className="fs-icon-goal" />
        {editing ? (
          <div className="editing">
            <div className="input-holder">
              <Autosuggest
                className="form-control "
                suggestions={suggestions || []}
                getSuggestionValue={s => `${s.name}`}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                shouldRenderSuggestions={() => !!suggestions.length}
                ref={autosuggest => {
                  if (autosuggest !== null) this.nameInput = autosuggest.input;
                }}
                onSuggestionsFetchRequested={({ value }) => this.onSuggestionsFetchRequested(value)}
                highlightFirstSuggestion
                inputProps={{
                  placeholder: '',
                  type: 'text',
                  className: 'form-control',
                  value: goalName,
                  onChange: (_, { newValue }) => {
                    this.onSuggestionsFetchRequested(newValue);
                    this.setState({ goalName: newValue });
                  },
                  onKeyUp: e => {
                    if (e.key === ENTER_KEY && canSave) this.onSave();
                  },
                  onBlur: () => !canSave && this.toggleEditing(),
                  onFocus: () => this.retrieveSuggestions(goalName)
                }}
                theme={{
                  container: 'react-autosuggest__container',
                  containerOpen: 'open react-autosuggest__container--open',
                  suggestionsContainer: 'dropdown-menu react-autosuggest__suggestions-container',
                  suggestion: 'dropdown-item-new react-autosuggest__suggestion',
                  suggestionFocused: 'react-autosuggest__suggestion--focused'
                }}
              />
              <div className="underline" />
            </div>
            <button
              type="button"
              onClick={this.onSave}
              disabled={!canSave}
              className="btn-save btn-transparent"
            >
              <img className="icon" src="/img/checkmark_filled_blue.svg" alt="save" />
            </button>
            <button type="button" onClick={this.toggleEditing} className="btn-save btn-transparent">
              <img className="icon" src="/img/cancel_round.svg" alt="discard" />
            </button>
          </div>
        ) : (
          <button className="addNewBtn" type="button" onClick={this.toggleEditing}>
            Add New Goal
          </button>
        )}
      </div>
    );
  }
}

NewInvestorGoal.propTypes = {
  investorId: PropTypes.number.isRequired,
  advisorId: PropTypes.number
};

NewInvestorGoal.defaultProps = {
  advisorId: null
};

NewInvestorGoal.contextTypes = {
  investorGoalsProvider: PropTypes.object.isRequired
};

export default NewInvestorGoal;
