import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import './styles.scss';

const ApproveModal = ({ show, onHidden, onApproved }) => {
  const hide = () => onHidden(false);

  return (
    <Modal id="approve-template-modal" className="modal-lg" show={show} onHidden={hide}>
      <ModalHeader />
      <ModalBody>
        <div className="t-modal">
          <div className="header">
            <h3 className="header__title">Approve template</h3>
          </div>
          <div className="content">
            <p className="content__p">Are you sure you want to approve this template?</p>
            <p className="content__p">
              <span className="fs-icon-exclamation-circle text-danger f-16" /> You can&apos;t revert
              this action
            </p>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-secondary btn-secondary-transparent"
              onClick={hide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                onApproved();
                hide();
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ApproveModal;
