import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from '../modal';

export default class TableItemDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.onHideModal = this.onHideModal.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onHideModal() {
    const { onHide } = this.props;
    onHide();
  }

  onClick() {
    const { items, onDelete } = this.props;
    onDelete(items);
  }

  render() {
    const { show, submitting, items, label, verb } = this.props;
    const text = items.length > 1 ? `${label}s` : label;
    return (
      <Modal
        id="delete-client-modal"
        ref={c => (this.modal = c)}
        className="modal-lg"
        show={show}
        onHidden={this.onHideModal}
      >
        <ModalHeader />
        <ModalBody>
          <h3 className="modal-title">
            {verb} {label}?
          </h3>
          <p className="description top">
            <div className="text">
              {' '}
              Are you sure you want to <span className="lowercase">{verb}</span>
              <span className="coloured-text">
                {' '}
                {items.length} {text}
              </span>
              ?
            </div>
            <div className="text text__warning padding">
              <span className="fs-icon-exclamation-circle warning-icon" />
              You can&apos;t revert this change.
            </div>
          </p>
          <div className="text-sm-center actions">
            <button type="button" className="btn cancel" onClick={this.onHideModal}>
              Cancel
            </button>
            <LoadingButton
              className="btn btn-danger delete"
              loading={submitting}
              onClick={this.onClick}
            >
              {verb}
            </LoadingButton>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

TableItemDeleteModal.propTypes = {
  items: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  verb: PropTypes.string.isRequired,
  label: PropTypes.string
};

TableItemDeleteModal.defaultProps = {
  label: null,
  submitting: false
};
