import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { CLICK_IMPORT_ACCOUNTS_CSV, CLICK_EXPORT_ACCOUNTS_CSV } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import SpinnerLoader from 'components/performance-spinner';
import BulkUpdateModal from 'components/advisor/bulk-update';

const BulkUpdateAccountsModal = ({ investor, onHide, selectedPortfolioIds, show }) => {
  const { actionProvider, investorProvider, prospectProvider } = useContext(AdvisorContext);

  const provider = investor.is_prospect ? prospectProvider : investorProvider;
  const downloadPortfoliosHandler = async params =>
    provider.downloadPortfoliosCSV(investor, params);
  const uploadPortfoliosHandler = async files => provider.uploadPortfoliosCSV(investor, files);

  const exportPortfolios = async ({ exporting, setExporting }) => {
    if (exporting) return;

    setExporting(true);

    actionProvider.slack({
      verb: CLICK_EXPORT_ACCOUNTS_CSV
    });

    const params = {};
    if (!_.isEmpty(selectedPortfolioIds)) params.ids = selectedPortfolioIds;

    try {
      await downloadPortfoliosHandler(params);
    } catch (e) {
      toast.error('Sorry, something went wrong while exporting the CSV files.');
    } finally {
      setExporting(false);
    }
  };

  const importPortfolios = ({ files, setImporting, onCancel }) => {
    actionProvider.slack({
      verb: CLICK_IMPORT_ACCOUNTS_CSV
    });
    setImporting(true);
    uploadPortfoliosHandler(files)
      .then(({ data }) => {
        if (data?.task_id) {
          toast.success(
            <div className="loading-action-toast">
              <SpinnerLoader spinnerLoading />
              <span>
                We&apos;ve received your request and are currently processing {files.length}{' '}
                Account(s) file(s). Please feel free to continue using our platform; we&apos;ll
                notify you once the processing is complete.
              </span>
            </div>,
            { toastId: data.task_id, closeButton: true, autoClose: false, closeOnClick: false }
          );
          onCancel();
        } else throw new Error();
      })
      .catch(() => {
        toast.error('Sorry, something went wrong while importing the CSV files.');
      })
      .finally(() => {
        setImporting(false);
      });
  };

  return (
    <BulkUpdateModal
      onImportHandler={importPortfolios}
      onExportHandler={exportPortfolios}
      onHide={onHide}
      show={show}
      investor={investor}
      selectedPortfolioIds={selectedPortfolioIds}
    />
  );
};

BulkUpdateAccountsModal.defaultProps = {
  investor: null
};

BulkUpdateAccountsModal.propTypes = {
  investor: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  selectedPortfolioIds: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired
};

export default BulkUpdateAccountsModal;
