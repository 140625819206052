import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

const QuestionMark = ({ tooltipMessage }) => (
  <>
    <span data-tip={tooltipMessage} data-for="question-mark" className="question-mark">
      ?
    </span>
    <ReactTooltip id="question-mark" effect="solid" place="right" />
  </>
);

QuestionMark.propTypes = {
  tooltipMessage: PropTypes.string
};

QuestionMark.defaultProps = {
  tooltipMessage: ''
};

export default QuestionMark;
