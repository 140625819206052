import React from 'react';
import PropTypes from 'prop-types';
import { SETUP_2FA } from '../../utils/constants';

const TwoFactorSetupContent = ({ email, qrCode, setupScreenType }) => {
  const renderHyperLink = (href, label) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  );

  const helperTextContent = () => {
    if (setupScreenType === 'email')
      return (
        <span>
          {SETUP_2FA.HELPER_TEXT.EMAIL[0]} <b>{email}</b>. {SETUP_2FA.HELPER_TEXT.EMAIL[1]}
        </span>
      );

    return (
      <span>
        {SETUP_2FA.HELPER_TEXT.APP[0]}{' '}
        {renderHyperLink(SETUP_2FA.HYPER_LINKS[0].LINK, SETUP_2FA.HYPER_LINKS[0].LABEL)}
        {', '}
        {renderHyperLink(SETUP_2FA.HYPER_LINKS[1].LINK, SETUP_2FA.HYPER_LINKS[1].LABEL)}
        {' or '}
        {renderHyperLink(SETUP_2FA.HYPER_LINKS[2].LINK, SETUP_2FA.HYPER_LINKS[2].LABEL)}
        {'. '}
        {SETUP_2FA.HELPER_TEXT.APP[1]}
      </span>
    );
  };

  const qrCodeContent = () => {
    if (setupScreenType === 'email') return null;
    return (
      <div className="qr-container">
        <div>{qrCode ? <img src={qrCode} alt="QR" /> : 'Loading'}</div>
      </div>
    );
  };

  return (
    <>
      <div>
        <p className="helper-text">{helperTextContent()}</p>
      </div>
      {qrCodeContent()}
    </>
  );
};

TwoFactorSetupContent.defaultProps = {
  qrCode: ''
};

TwoFactorSetupContent.propTypes = {
  email: PropTypes.string.isRequired,
  qrCode: PropTypes.string,
  setupScreenType: PropTypes.string.isRequired
};

export default TwoFactorSetupContent;
