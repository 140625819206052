import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { capacityInfo } from '../../utils/helpers';
import styles from './styles';

const RiskCapacity = ({ score }) => {
  const { reportIcon: ReportIcon, ...capacity } = capacityInfo(score);
  const level = Math.ceil(score);
  return (
    <View style={styles.container}>
      <ReportIcon style={styles.badge} />
      <Text style={[styles[`levelColor${level}`], styles.capacity]}>{capacity.label}</Text>
      <Text>{capacity.description}</Text>
    </View>
  );
};

RiskCapacity.propTypes = {
  score: PropTypes.number.isRequired
};

export default RiskCapacity;
