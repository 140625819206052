// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details-prism .risk-score-result-container {
  padding-top: 0;
}
.account-details-prism .risk-score-result-container .prism-rating-page-container {
  padding: 0;
  width: auto;
  width: initial;
}
.account-details-prism .risk-score-result-container .prism-rating-page-container .heading {
  margin: 0;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account-details-prism/styles.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEI;EACE,UAAA;EACA,WAAA;EAAA,cAAA;AAAN;AAEM;EACE,SAAA;EACA,YAAA;AAAR","sourcesContent":[".account-details-prism {\n  .risk-score-result-container {\n    padding-top: 0;\n\n    .prism-rating-page-container {\n      padding: 0;\n      width: initial;\n\n      .heading {\n        margin: 0;\n        height: 50px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
