// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-value {
  display: flex;
  gap: 10px;
}
.range-value .form-control {
  min-height: 42px;
  border-radius: 4px;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/table/dynamic/filters/common/range-value/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;AADF;AAGE;EACE,gBAAA;EACA,kBAAA;EACA,iBAAA;AADJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.range-value {\n  display: flex;\n  gap: 10px;\n\n  .form-control {\n    min-height: 42px;\n    border-radius: 4px;\n    font-size: 0.9rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
