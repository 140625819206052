import { View } from '@react-pdf/renderer';
import TargetScoreLine from 'components/advisor/risk-analysis/target-score-line/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import PrismSection from 'reports/base/sections/prism';
import styles from './styles';

const PrismRating = ({ breakSection, prismSummary, targetSummary, title }) => (
  <BaseSection breakSection={breakSection} title={title} wrap={false}>
    <View style={styles.container}>
      <PrismSection risk={prismSummary?.overall} tolerance={targetSummary?.overall} />
      <View>
        <TargetScoreLine name="Tail Event Risk Rating" value={prismSummary.tail} />
        <TargetScoreLine name="Diversification Risk Rating" value={prismSummary.correlation} />
        <TargetScoreLine name="Concentrated Stock Risk Rating" value={prismSummary.concentrated} />
        <TargetScoreLine name="Volatility Risk Rating" value={prismSummary.volatility} />
      </View>
    </View>
  </BaseSection>
);

PrismRating.propTypes = {
  breakSection: PropTypes.bool,
  prismSummary: PropTypes.object.isRequired,
  targetSummary: PropTypes.object,
  title: PropTypes.string
};

PrismRating.defaultProps = {
  breakSection: false,
  targetSummary: {},
  title: 'Portfolio Risk'
};

export default PrismRating;
