import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';

const ScenarioPerformance = ({ className, percentage, startingValue }) => (
  <div>
    <div className={cn('box', className)} />
    {percentage ? (
      <div className={cn('title', { positive: percentage > 0, negative: percentage < 0 })}>
        <FormattedNumber
          value={percentage / 100}
          format="percent"
          maximumFractionDigits={0}
          signDisplay="exceptZero"
        />{' '}
        (
        <FormattedNumber
          value={(percentage * startingValue) / 100}
          format="currency"
          signDisplay="exceptZero"
        />
        )
      </div>
    ) : (
      <div className="title">-</div>
    )}
  </div>
);

ScenarioPerformance.propTypes = {
  className: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  startingValue: PropTypes.number.isRequired
};

ScenarioPerformance.defaultProps = {
  className: ''
};

export default ScenarioPerformance;
