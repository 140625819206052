/* eslint-disable no-underscore-dangle */
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage, withCurrencyFormat } from 'utils/utils';
import styles from './styles';

const Row = ({
  color,
  depth,
  hiddenVal,
  icon: IconComponent,
  label,
  level,
  total,
  withParentIcon
}) => {
  const value = level.__value;
  return (
    <View style={[styles.row, styles.rowLevel, { borderLeftColor: color }]} wrap={false}>
      <View
        style={[
          styles.cell,
          { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }
        ]}
      >
        {IconComponent && <IconComponent height={15} />}
        <Text
          style={[
            styles[`cellTextLevel${depth}`],
            styles[`firstCell${withParentIcon ? 'Icon' : ''}TextLevel${depth}`]
          ]}
        >
          {label}
        </Text>
      </View>
      <View style={styles.cell}>
        {hiddenVal || (
          <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
            {withCurrencyFormat(value, 'standard', 0)}
          </Text>
        )}
      </View>
      <View style={[styles.cell, { marginRight: 0 }]}>
        <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
          {formatPercentage(value / total, 100, 2)}
        </Text>
      </View>
    </View>
  );
};

Row.propTypes = {
  color: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  hiddenVal: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  label: PropTypes.string.isRequired,
  level: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  withParentIcon: PropTypes.bool
};

Row.defaultProps = {
  hiddenVal: false,
  icon: null,
  withParentIcon: false
};

export default Row;
