import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const DEFAULT_ANIMATION_STEPS = 4;
const LOADER_EMPTY_VALUE = 500;
const LOADER_ADDITIONAL_VALUE_TO_FULL = 190; // loader circle is full when total value is equals to 690

const SplashLoading = ({ duration, text }) => {
  const [strokeValue, setStrokeValue] = useState(LOADER_EMPTY_VALUE);

  useEffect(() => {
    const interval = setInterval(() => {
      setStrokeValue(
        prevStrokeValue =>
          prevStrokeValue + LOADER_ADDITIONAL_VALUE_TO_FULL / DEFAULT_ANIMATION_STEPS
      );
    }, (duration * 1000) / DEFAULT_ANIMATION_STEPS);
    setTimeout(() => {
      clearInterval(interval);
    }, duration * 1000);
    return () => interval && clearInterval(interval);
  }, []);

  return (
    <div className="spinner-overlay">
      <h6 className="spinner-text animated fadeInUp">
        {text || <FormattedMessage id="common.loading" />}
      </h6>
      <svg
        className="spinner"
        width="200px"
        height="200px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        style={{ top: '50%' }}
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
        <circle
          className="main-path"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
          strokeDasharray={strokeValue}
          style={{ transition: `stroke-dasharray ${duration / 4}s` }}
        />
      </svg>
    </div>
  );
};

SplashLoading.defaultProps = {
  text: ''
};

SplashLoading.propTypes = {
  duration: PropTypes.number.isRequired,
  text: PropTypes.string
};

export default SplashLoading;
