import { View } from '@react-pdf/renderer';
import IPSDistributionSummary from 'components/advisor/ips/body/sections/distribution-summary/pdf';
import IPSExecutiveSummary from 'components/advisor/ips/body/sections/executive-summary/pdf';
import IPSGeographicExposure from 'components/advisor/ips/body/sections/geographic-exposure/pdf';
import IPSInvestmentStyle from 'components/advisor/ips/body/sections/investment-style/pdf';
import IPSMatchingModels from 'components/advisor/ips/body/sections/matching-models/pdf';
import IPSPrism from 'components/advisor/ips/body/sections/prism/pdf';
import IPSRiskTolerance from 'components/advisor/ips/body/sections/risk-tolerance/pdf';
import IPSSectorExposure from 'components/advisor/ips/body/sections/sector-exposure/pdf';
import IPSTopHoldings from 'components/advisor/ips/body/sections/top-holdings/pdf';
import { IPSPropTypes } from 'components/advisor/ips/body/sections/types';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import { BaseCoverDataPropTypes } from 'reports/base/cover/types';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import { byNonFixedSections, byTemplateSectionOrder } from 'utils/utils';
import prepareMetadata from '../utils';
import SectionWrapper from './section-wrapper';

const ACCOUNT_ONLY_SECTION = 'accountOnly';

const IPS_COMPONENTS = {
  purpose: CustomSection,
  background: CustomSection,
  executiveSummary: IPSExecutiveSummary,
  statementOfObjectives: CustomSection,
  matchingModels: IPSMatchingModels,
  riskTolerance: IPSRiskTolerance,
  riskToleranceChart: IPSPrism,
  assetClassPreference: CustomSection,
  distributionSummary: IPSDistributionSummary,
  governance: CustomSection,
  expectedReturns: CustomSection,
  investmentStyle: IPSInvestmentStyle,
  regionExposure: IPSGeographicExposure,
  topHoldings: IPSTopHoldings,
  sectorExposure: IPSSectorExposure,
  investmentPolicyReview: CustomSection
};

const IPSReport = ({
  collapsedHoldings,
  collapsedRegions,
  collapsedSectors,
  collapsedStyles,
  collapsedTopHoldings,
  coverData,
  ips,
  orientation,
  questionnaires,
  setAttachments,
  setTotalPages,
  size,
  subtitle,
  taxonomies,
  title,
  totalPages,
  user,
  withCustomBranding
}) => {
  const getSectionsMetadata = () => {
    const bySectionHidden = ([_, metadata]) => !metadata.hidden;
    const bySectionType = ([_, metaSection]) => !metaSection[ACCOUNT_ONLY_SECTION];

    const sections = prepareMetadata(ips, user);

    return Object.entries(sections)
      .filter(bySectionHidden)
      .filter(bySectionType)
      .sort(byTemplateSectionOrder);
  };

  const sections = getSectionsMetadata();
  const bodySections = sections.filter(byNonFixedSections);
  const footerSection = sections.find(([_, metadata]) => metadata.footer);

  const context = useMemo(
    () => ({
      collapsedHoldings,
      collapsedRegions,
      collapsedSectors,
      collapsedStyles,
      collapsedTopHoldings,
      orientation,
      questionnaires,
      taxonomies,
      user,
      withCustomBranding
    }),
    [
      JSON.stringify(collapsedHoldings),
      JSON.stringify(collapsedRegions),
      JSON.stringify(collapsedSectors),
      JSON.stringify(collapsedStyles),
      JSON.stringify(collapsedTopHoldings),
      JSON.stringify(questionnaires),
      JSON.stringify(taxonomies),
      JSON.stringify(user),
      orientation,
      withCustomBranding
    ]
  );

  const advisor = ips?.proposal?.target?.investor?.advisor
    ? { ...ips.proposal.target.investor.advisor, company: user.advisor.company }
    : user.advisor;
  const withPageNumber = bodySections.every(([_, metadata]) => !metadata.attachment);

  return (
    <BaseDocument title={title && subtitle ? `${title} for ${subtitle}` : title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={advisor}
          data={coverData}
          orientation={orientation}
          size={size}
          subtitle={ips.subtitle || subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={advisor}
          footer={footerSection ? footerSection[1] : null}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
          withPageNumber={withPageNumber}
        >
          {bodySections.map(([key, metadata]) => (
            <SectionWrapper
              body={metadata.body}
              component={IPS_COMPONENTS[key]}
              ips={ips}
              key={key}
              metadata={metadata}
              orientation={orientation}
              propertyKey={key}
              questionnaires={questionnaires}
              setAttachments={setAttachments}
              title={metadata.title}
            />
          ))}

          {!!ips?.proposal?.disclosure && (
            <CustomSection breakSection title="Disclosure" body={ips.proposal.disclosure} />
          )}

          {/* This is a dummy component responsible only for obtaining the total number of pages in the report */}
          <View
            render={({ totalPages: documentTotalPages }) => {
              if (Number.isFinite(documentTotalPages) && documentTotalPages !== totalPages)
                setTotalPages(documentTotalPages);
            }}
          />
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

IPSReport.propTypes = {
  collapsedHoldings: PropTypes.array.isRequired,
  collapsedRegions: PropTypes.array.isRequired,
  collapsedSectors: PropTypes.array.isRequired,
  collapsedStyles: PropTypes.array.isRequired,
  collapsedTopHoldings: PropTypes.array.isRequired,
  coverData: PropTypes.shape(BaseCoverDataPropTypes),
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  orientation: PropTypes.string,
  questionnaires: PropTypes.object.isRequired,
  setAttachments: PropTypes.func.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  taxonomies: PropTypes.array,
  title: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

IPSReport.defaultProps = {
  coverData: {},
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  taxonomies: [],
  title: 'Investment Policy Statement',
  withCustomBranding: false
};

export default IPSReport;
