import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SCORE_LEVELS } from 'utils/scores';
import {
  BalancedIcon,
  CautiousIcon,
  GrowthOrientedIcon,
  HighRiskTakerIcon,
  RiskAverseIcon
} from '../personality-type/pdf/icons';
import {
  HighFinancialCapacityIcon,
  LowFinancialCapacityIcon,
  ModerateFinancialCapacityIcon
} from '../risk-capacity/pdf/icons';

export const PERSONALITY_TYPES = {
  RISK_AVERSE: {
    ...SCORE_LEVELS.RISK_AVERSE,
    description: {
      advisor: <FormattedMessage id="score.personality.advisor-risk-averse" />,
      investor: <FormattedMessage id="score.personality.investor-risk-averse" />
    },
    mark: {
      position: 'top',
      value: 1.6
    },
    src: '/img/icons/personality/risk-averse.svg',
    reportIcon: RiskAverseIcon
  },
  CAUTIOUS: {
    ...SCORE_LEVELS.CAUTIOUS,
    description: {
      advisor: <FormattedMessage id="score.personality.advisor-cautious" />,
      investor: <FormattedMessage id="score.personality.investor-cautious" />
    },
    mark: {
      position: 'bottom',
      value: 2.1
    },
    src: '/img/icons/personality/cautious.svg',
    reportIcon: CautiousIcon
  },
  BALANCED: {
    ...SCORE_LEVELS.BALANCED,
    description: {
      advisor: <FormattedMessage id="score.personality.advisor-balanced" />,
      investor: <FormattedMessage id="score.personality.investor-balanced" />
    },
    mark: {
      position: 'top',
      value: 4.1
    },
    src: '/img/icons/personality/balanced.svg',
    reportIcon: BalancedIcon
  },
  GROWTH_ORIENTED: {
    ...SCORE_LEVELS.GROWTH_ORIENTED,
    description: {
      advisor: <FormattedMessage id="score.personality.advisor-growth-oriented" />,
      investor: <FormattedMessage id="score.personality.investor-growth-oriented" />
    },
    mark: {
      position: 'bottom',
      value: 6.1
    },
    src: '/img/icons/personality/growth-oriented.svg',
    reportIcon: GrowthOrientedIcon
  },
  HIGH_RISK_TAKER: {
    ...SCORE_LEVELS.HIGH_RISK_TAKER,
    description: {
      advisor: <FormattedMessage id="score.personality.advisor-high-risk-taker" />,
      investor: <FormattedMessage id="score.personality.investor-high-risk-taker" />
    },
    mark: {
      position: 'top',
      value: 9.1
    },
    src: '/img/icons/personality/high-risk-taker.svg',
    reportIcon: HighRiskTakerIcon
  }
};

export const CAPACITY_RANGES = {
  LOW_CAPACITY: {
    min: 1,
    max: 3,
    label: <FormattedMessage id="score.capacity.low" />,
    description: <FormattedMessage id="score.capacity.low-description" />,
    src: '/img/icons/financial-capacity/low.svg',
    reportIcon: LowFinancialCapacityIcon
  },
  MODERATE_CAPACITY: {
    min: 3.1,
    max: 6,
    label: <FormattedMessage id="score.capacity.moderate" />,
    description: <FormattedMessage id="score.capacity.moderate-description" />,
    src: '/img/icons/financial-capacity/moderate.svg',
    reportIcon: ModerateFinancialCapacityIcon
  },
  HIGH_CAPACITY: {
    min: 6.1,
    max: 10,
    label: <FormattedMessage id="score.capacity.high" />,
    description: <FormattedMessage id="score.capacity.high-description" />,
    src: '/img/icons/financial-capacity/high.svg',
    reportIcon: HighFinancialCapacityIcon
  }
};
