/* global DISPLAY_SHORT_DATE_FORMAT */
import classnames from 'classnames';
import TooltipV2 from 'components/tooltip-v2';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import ConnectIntegrationModal from '../connect-integration-modal';
import DisconnectIntegrationModal from '../disconnect-integration-modal';
import { API_ENROLLMENT_ONLY, getSyncDataOptions } from '../utils';

import './styles.scss';

const DEFAULT_CHARLES_SCHWAB_INTEGRATION_ERROR =
  'Something went wrong with your Charles Schwab integration';
const INTEGRATION_STATUS_FAILED = 'failed';
const FIRM_NOT_ENROLLED_ERROR_CODE = 'FIRM_NOT_ENROLLED';

const INTEGRATION_STATUS = {
  ok: 'Successful',
  syncing: 'Syncing',
  enrolled: 'Enrolled',
  [INTEGRATION_STATUS_FAILED]: 'Failed'
};

const ConnectedProvider = ({ hasManagementIntegrationPermissions, integration }) => {
  const { integrationProvider } = useContext(AdvisorContext);

  const [isDisconnectModalShown, setIsDisconnectModalShown] = useState(false);
  const [isSetupModalShown, setIsSetupModalShown] = useState(false);

  const {
    extra_data: extraData,
    last_successful_sync: lastSync,
    provider_details: providerDetails,
    status
  } = integration;
  const { image, name } = providerDetails;

  const hasApiEnrollmentOnlyEnabled = !!integration[API_ENROLLMENT_ONLY];
  const providedSyncData = getSyncDataOptions(providerDetails);
  const prevSelectedSyncData = getSyncDataOptions(integration);
  const tooltipId = 'firm-not-enrolled-tooltip';

  if (hasApiEnrollmentOnlyEnabled)
    prevSelectedSyncData.push({
      id: API_ENROLLMENT_ONLY,
      name: 'API enrollment only',
      description: ''
    });

  const failedIntegration =
    status === INTEGRATION_STATUS_FAILED || (extraData?.errors && !_.isEmpty(extraData.errors));
  const failedCharlesSchwabIntegration =
    failedIntegration &&
    (providerDetails.provider === 'Charles Schwab' ||
      providerDetails.provider === 'Charles Schwab OAuth');
  const hasEnrollmentData = extraData?.enrollment && !_.isEmpty(extraData.enrollment);

  const showDisconnectModal = () => {
    setIsDisconnectModalShown(true);
  };

  const hideDisconnectModal = () => {
    setIsDisconnectModalShown(false);
    integrationProvider.getConnected();
  };

  const showSetupModal = () => {
    setIsSetupModalShown(true);
  };

  const hideSetupModal = () => {
    setIsSetupModalShown(false);
    integrationProvider.getConnected();
  };

  const disconnectIntegration = async () => {
    await integrationProvider.disconnectIntegration(integration.id);
    await integrationProvider.getConnected();
  };

  const displayStatusBadgeMessage = () => {
    if (failedCharlesSchwabIntegration) {
      const [error] = extraData.errors;
      if (error.code === FIRM_NOT_ENROLLED_ERROR_CODE)
        return (
          <TooltipV2
            className="firm-not-enrolled-tooltip"
            id={tooltipId}
            label="Your firm is currently not enrolled in the Gateway Data API, likely due to insufficient permissions. To resolve this, please ask your firm administrator to complete the enrollment. If you need further assistance contact our support team."
            place="top"
          >
            <p className="firm-not-enrolled text-center mb-0" data-for={tooltipId} data-tip="">
              <span>Firm not enrolled</span>
              <span className="question-mark">?</span>
            </p>
          </TooltipV2>
        );
      const errors = extraData.errors.map(e => {
        if (e.detail || e.message) return `${e.detail || e.message}${e.code ? ` (${e.code})` : ''}`;
        return DEFAULT_CHARLES_SCHWAB_INTEGRATION_ERROR;
      });
      return Array.from(new Set(errors)).join('. ');
    }
    return INTEGRATION_STATUS[status];
  };

  return (
    <>
      <div className="connected-integration-provider">
        {image && <img src={image} alt={name} />}

        {!image && (
          <div className="connected-integration-provider__no-brand">
            <span>{name}</span>
          </div>
        )}

        <div className="synced-data">
          <ul>
            {providedSyncData.map(item => (
              <li
                key={item.id}
                className={classnames('synced-data__label', { active: integration[item.id] })}
              >
                <i className="icon-checkmark" /> {item.name}
              </li>
            ))}
          </ul>
        </div>

        {hasManagementIntegrationPermissions && (
          <>
            <button
              aria-label="update"
              type="button"
              className="btn btn-outline-primary"
              onClick={showSetupModal}
            >
              Update
            </button>
            <button
              aria-label="disconnect"
              type="button"
              className="btn btn-outline-danger"
              onClick={showDisconnectModal}
            >
              Disconnect
            </button>
          </>
        )}

        <div className="integration-status">
          <span
            style={{ textAlign: failedCharlesSchwabIntegration ? 'left' : 'center' }}
            className={classnames('integration-status__badge', {
              'badge--error': failedIntegration
            })}
          >
            {displayStatusBadgeMessage()}
          </span>{' '}
          <span className="integration-status__date">
            {lastSync ? moment.utc(lastSync).format(DISPLAY_SHORT_DATE_FORMAT) : 'Not yet synced.'}
          </span>
          {hasEnrollmentData && !failedIntegration && (
            <div className="integration-status__enrollment">
              {extraData.enrollment?.status && (
                <span className="enrollment__status">
                  Enrollment status: {extraData.enrollment.status}
                </span>
              )}
              {extraData.enrollment?.date && (
                <span className="enrollment__date">
                  Last enrolled by: {moment.utc(extraData.enrollment.date).format('ll')}
                </span>
              )}
            </div>
          )}
        </div>
      </div>

      {isSetupModalShown && (
        <ConnectIntegrationModal
          integration={integration}
          onCancel={hideSetupModal}
          onConnect={hideSetupModal}
          prevSelectedSyncData={prevSelectedSyncData}
          provider={providerDetails}
          show={isSetupModalShown}
        />
      )}

      {isDisconnectModalShown && (
        <DisconnectIntegrationModal
          onCancel={hideDisconnectModal}
          onDisconnect={disconnectIntegration}
          provider={providerDetails}
          show={isDisconnectModalShown}
        />
      )}
    </>
  );
};

ConnectedProvider.propTypes = {
  hasManagementIntegrationPermissions: PropTypes.bool.isRequired,
  integration: PropTypes.shape({
    extra_data: PropTypes.object,
    id: PropTypes.number,
    last_successful_sync: PropTypes.string,
    modified: PropTypes.string.isRequired,
    provider_details: PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired
    }).isRequired,
    status: PropTypes.string.isRequired
  }).isRequired
};

export default ConnectedProvider;
