// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proposal .proposal-container {
  outline: none;
  max-width: 1120px;
  margin: 0 auto 50px auto;
}

.proposal-accounts-error {
  padding: 2rem;
  text-align: center;
  background-color: white;
  border: 1px solid #c8d0d4;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/proposal/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,iBAAA;EACA,wBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,yBAAA;AAHF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.proposal {\n  .proposal-container {\n    outline: none;\n    max-width: 1120px;\n    margin: 0 auto 50px auto;\n  }\n}\n\n.proposal-accounts-error {\n  padding: 2rem;\n  text-align: center;\n  background-color: white;\n  border: 1px solid $graphite-grayish-blue-2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
