import {
  CARD_DELETE,
  CARD_LIST,
  CARD_SETUP_INTENT,
  CARD_SET_AS_DEFAULT,
  FETCHING,
  FETCHING_FAILURE
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class CardProvider extends DataProvider {
  async list() {
    this.dispatch(FETCHING);
    const data = await this.provider.get(`${config.apiBase}billing/paymentmethods/`);
    if (!data.error)
      this.dispatch(CARD_LIST, {
        data: data.data
      });
    return data.data;
  }

  async setupIntent() {
    this.dispatch(FETCHING);
    const data = await this.provider.post(`${config.apiBase}billing/paymentmethods/`);
    if (!data.error) this.dispatch(CARD_SETUP_INTENT);
    else this.dispatch(FETCHING_FAILURE);
    return data;
  }

  async setAsDefault(cardId) {
    this.dispatch(FETCHING);
    const data = await this.provider.post(
      `${config.apiBase}billing/paymentmethods/${cardId}/set_default/`
    );
    if (!data.error) this.dispatch(CARD_SET_AS_DEFAULT);
    else this.dispatch(FETCHING_FAILURE);
    return data;
  }

  async delete(cardId) {
    this.dispatch(FETCHING);
    const data = await this.provider.delete(`${config.apiBase}billing/paymentmethods/${cardId}/`);
    if (!data.error) this.dispatch(CARD_DELETE);
    else this.dispatch(FETCHING_FAILURE);
    return data;
  }
}

export default CardProvider;
