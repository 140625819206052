// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#PromoCode input {
  width: 200px;
  font-size: 1.25em;
  letter-spacing: 1px;
  font-family: monospace;
}
#PromoCode .add-discount {
  padding: 0.375rem 0;
}
#PromoCode .btn-outline-primary {
  margin-right: 5px;
}
#PromoCode .actions > button {
  margin-right: 10px;
}
#PromoCode .validated-promo-code {
  font-weight: 500;
}
#PromoCode .validated-promo-code .code {
  text-transform: uppercase;
  font-size: 1.25em;
}
#PromoCode .validated-promo-code .remove-promo-code {
  display: inline-block;
  margin: 0 5px;
}
#PromoCode .validated-promo-code .coupon {
  display: block;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/checkout/payment/promo-code/styles.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,iBAAA;AAJJ;AAOE;EACE,kBAAA;AALJ;AAQE;EACE,gBAAA;AANJ;AAQI;EACE,yBAAA;EACA,iBAAA;AANN;AASI;EACE,qBAAA;EACA,aAAA;AAPN;AAUI;EACE,cAAA;EACA,gBAAA;AARN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#PromoCode {\n  input {\n    width: 200px;\n    font-size: 1.25em;\n    letter-spacing: 1px;\n    font-family: monospace;\n  }\n\n  .add-discount {\n    padding: 0.375rem 0;\n  }\n\n  .btn-outline-primary {\n    margin-right: 5px;\n  }\n\n  .actions > button {\n    margin-right: 10px;\n  }\n\n  .validated-promo-code {\n    font-weight: 500;\n\n    .code {\n      text-transform: uppercase;\n      font-size: 1.25em;\n    }\n\n    .remove-promo-code {\n      display: inline-block;\n      margin: 0 5px;\n    }\n\n    .coupon {\n      display: block;\n      font-size: 0.9em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
