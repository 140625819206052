import ScoreBubbleDescription from 'components/advisor/utils/score-bubble-description';
import { saveAs } from 'file-saver';
import useInterval from 'hooks/useInterval';
import * as htmlToImage from 'html-to-image';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import SpeedMeter from './speed-meeter';
import './styles.scss';

const MULTIPLIER_DEFAULT = 1;
const MULTIPLIER_PERCENT = 10;

const SCORE_STEP = 0.1;
const SCORE_START = 0.1;
const SCORE_END = 10;

const CHART_OPTIONS = [
  { value: undefined, label: 'Select the image version' },
  { value: 'large', label: 'Large' },
  { value: 'medium', label: 'Medium' },
  { value: 'compact', label: 'Compact' }
];

const SCORE_STEPS = [
  { value: undefined, label: 'Select the scores scale' },
  { value: MULTIPLIER_DEFAULT, label: '1-10' },
  { value: MULTIPLIER_PERCENT, label: '1-100' }
];

/**
 * InvestorPrismGenerate component
 *
 * @returns {JSX.Element}
 */

const InvestorPrismGenerate = () => {
  /* States */
  const [layout, setLayout] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [multiplier, setMultiplier] = useState(MULTIPLIER_DEFAULT);
  const [score, setScore] = useState(0);
  const [show, setShow] = useState(true);

  const step = SCORE_STEP * multiplier;
  const minValue = SCORE_START * multiplier;
  const maxValue = SCORE_END * multiplier;

  const scoreDisplay = multiplier === MULTIPLIER_PERCENT ? score.toFixed(0) : score.toFixed(1);
  const scoreValue = multiplier === MULTIPLIER_PERCENT ? score / 10 : score;

  /* Functions */

  const getCanvas = () => {
    htmlToImage.toCanvas(document.getElementById(`prims-canvas-${layout}-section`)).then(canvas => {
      const amount = document.getElementsByClassName(`${layout}-section`);
      canvas.setAttribute('class', `${layout}-section`);
      canvas.setAttribute(`${layout}-section-${amount ? amount.length + 1 : 0}`, score);
      canvas.setAttribute('id', `canvas-${layout}-section-${amount ? amount.length + 1 : 0}`);
      canvas.style.visibility = 'hidden';
      document.getElementById('investor-prism-generate-container__canvas-list').appendChild(canvas);
    });
  };

  const start = e => {
    e.preventDefault();
    setScore(minValue);
    setShow(true);
    setProcessing(true);
  };

  const getImageName = valueAttr =>
    `${valueAttr}${multiplier === MULTIPLIER_DEFAULT && valueAttr.length === 1 ? '.0' : ''}`;

  const download = async () => {
    const zip = new JSZip();
    const img = zip.folder('graphics');

    const buildZipImagesPromise = new Promise(async resolve => {
      const canvasElementList = document.getElementsByClassName(`${layout}-section`);

      for (let index = 0; index < canvasElementList.length; index += 1) {
        const number = `canvas-${layout}-section-${index + 1}`;
        const imgUrl = document.getElementById(number).toDataURL();
        const element = document.getElementById(number);
        const valueAttr = element.getAttribute(`${layout}-section-${index + 1}`);
        const newImage = imgUrl.replace('data:image/png;base64,', '');
        const imageName = getImageName(valueAttr);
        img.file(`${imageName}.png`, newImage, { base64: true });
      }
      await zip.generateAsync({ type: 'blob' }).then(content => {
        resolve(saveAs(content, 'media.zip'));
      });
    });

    buildZipImagesPromise.then(() => {
      const elements = document.getElementsByTagName('canvas');
      while (elements[0]) elements[0].parentNode.removeChild(elements[0]);
      setScore(0);
      setMultiplier(null);
      setLayout(null);
      setShow(true);
    });
  };

  /* Check if the current canvas is rendered */
  const checkCanvas = () => {
    const canvasElementsFirst = document.getElementsByClassName(`${layout}-section`);
    let found = false;
    for (let index = 0; index < canvasElementsFirst.length; index += 1) {
      const number = `canvas-${layout}-section-${index + 1}`;
      const element = document.getElementById(number);
      const valueAttr = element.getAttribute(`${layout}-section-${index + 1}`);
      if (valueAttr === String(score)) found = true;
    }
    return found;
  };

  const onChangeMultiplier = event => {
    setMultiplier(Number.parseFloat(event.target.value));
    setLayout(null);
  };

  const onChangeVersion = event => {
    const target = event.target.value;
    if (target) setLayout(target);
    else setLayout(null);
  };

  /* Effetcs */
  useEffect(() => {
    if (score && show) getCanvas();
  }, [score, show]);

  /* useInterval hook */
  useInterval(() => {
    if (processing)
      if (checkCanvas()) {
        setShow(false);
        /* Check the canvas validation of the sections in order to continue with the next interaction */
        const newScore = Number.parseFloat(
          (score === maxValue ? minValue : score + step).toFixed(1)
        );

        setScore(newScore);
        if (newScore >= maxValue) setProcessing(false);
        setShow(true);
      }
  }, 1000);

  return (
    <div className="investor-prism-generate-container">
      <form onSubmit={start}>
        <h3>Prism media generator</h3>

        <div>
          <select onChange={onChangeMultiplier} disabled={processing}>
            {SCORE_STEPS.map(element => (
              <option key={element.value} value={element.value} selected={element.value === step}>
                {element.label}
              </option>
            ))}
          </select>
          <select onChange={onChangeVersion} disabled={processing}>
            {CHART_OPTIONS.map(element => (
              <option key={element.value} value={element.value} selected={element.value === layout}>
                {element.label}
              </option>
            ))}
          </select>

          {multiplier && layout && (
            <>
              <button type="submit" className="btn-primary" disabled={processing}>
                Start
              </button>
              <button
                type="button"
                className="btn-primary"
                onClick={download}
                disabled={processing}
              >
                Download images
              </button>
            </>
          )}
        </div>
      </form>

      {processing && (
        <div className="investor-prism-generate-container__title">Generating images ...</div>
      )}

      <div className="investor-prism-generate-container__renders-container">
        {layout === 'large' && (
          <div id="prims-canvas-large-section">
            <div className="large-layout-container">
              <div className="large-layout-container__header" style={{ width: '100%' }}>
                <div className="second-layout-container__header_title">Portfolio Risk</div>
              </div>
              <div
                className="large-layout-container__speed-logo-container"
                style={{ width: '100%' }}
              >
                {show && (
                  <SpeedMeter score={scoreValue} scoreDisplay={scoreDisplay} toValue={maxValue} />
                )}
                <img src="/img/logos/logo-stratifi2.svg" alt="prism logo" />
              </div>
            </div>
          </div>
        )}

        {layout === 'medium' && (
          <div id="prims-canvas-medium-section">
            <div className="medium-layout-container">
              <div className="large-layout-container__header" style={{ width: '100%' }}>
                <div className="second-layout-container__header_title">Portfolio Risk</div>
              </div>

              <div className="medium-layout-container__graphic">
                {show && (
                  <SpeedMeter
                    score={scoreValue}
                    scoreDisplay={scoreDisplay}
                    compact
                    toValue={maxValue}
                  />
                )}
                <img src="/img/logos/logo-stratifi2.svg" alt="prism logo" />
              </div>
            </div>
          </div>
        )}

        {layout === 'compact' && (
          <div id="prims-canvas-compact-section" className="compact-layout-container">
            <ScoreBubbleDescription
              score={scoreValue}
              scoreDisplay={scoreDisplay === '10.0' ? '10' : scoreDisplay}
            />
          </div>
        )}
      </div>

      <div id="investor-prism-generate-container__canvas-list" />
    </div>
  );
};

export default InvestorPrismGenerate;
