/* eslint-disable import/prefer-default-export */
import { isAnalyst } from 'providers/auth';
import { validation } from 'utils/form';

export const validate = (
  values,
  {
    user: {
      advisor: { role }
    }
  }
) => {
  const errors = {
    user: {}
  };

  errors.first_name = errors.first_name || validation.required(values.first_name);
  errors.last_name = errors.last_name || validation.required(values.last_name);
  errors.email = errors.email || validation.required(values.email);
  errors.email = errors.email || validation.email(values.email);
  errors.phone = errors.phone || (values.phone && validation.internationalPhone(values.phone));

  if (isAnalyst(role)) errors.advisor = errors.advisor || validation.required(values.advisor);

  return errors;
};
