import {
  FETCHING,
  HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION,
  HOUSEHOLD_GET_PRISM_DISTRIBUTION,
  HOUSEHOLD_LIST_ES
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class HouseholdsElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/household/`, params)
      .then(({ data }) => {
        this.dispatch(HOUSEHOLD_LIST_ES, {
          data: data.results.map(household => ({
            ...household,
            url: `/advisor/households/${household.id}/`
          }))
        });
        return data;
      });

    return promise;
  }

  getPrismDistribution(params) {
    return this.provider
      .get(`${config.apiBase}search/household/risk_levels_distribution/`, params)
      .then(({ data }) => this.dispatch(HOUSEHOLD_GET_PRISM_DISTRIBUTION, { data }));
  }

  getDriftLevelDistribution(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/household/drift_levels_distribution/`, params)
      .then(({ data }) => {
        this.dispatch(HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION, { data });
        return data;
      });

    return promise;
  }
}

export default HouseholdsElasticProvider;
