/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

export const getLevelPercent = (value, total) => (total ? (value ?? 0) / total : '-');

export const processTaxonomy = (attr, portfolioTaxonomy, resultObj) =>
  Object.entries(portfolioTaxonomy)
    .filter(
      ([key, level]) => !_.isPlainObject(key) && _.isPlainObject(level) && !key.startsWith('__')
    )
    .forEach(([key, level]) => {
      if (!resultObj[key]) resultObj[key] = {};
      resultObj[key][`__${attr}_value`] = level.__value;
      if (_.isNil(resultObj[key].__order)) resultObj[key].__order = level.__order;
      processTaxonomy(attr, level, resultObj[key]);
    });

export const formatNumber = (value, sign) => {
  if (!value) return '-';
  return `${sign && value > 0 ? '+' : ''}${Math.round(value * 10000) / 100}%`;
};

export const buildCollapsibleMap = data =>
  Object.entries(data)
    .filter(([k]) => !k.startsWith('__'))
    .reduce((acum, [key, level]) => {
      if (_.isPlainObject(level)) return { ...acum, [key]: false, ...buildCollapsibleMap(level) };
      return { ...acum };
    }, {});
