import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'components/modal';

import { CANCEL_BUTTON, DELETE_BUTTON } from '../constants';

const TeamDelete = ({ data, onHide, onConfirm }) => (
  <Modal id="team-delete-modal" className="modal-sm" onHide={() => onHide({})} show={data.show}>
    <ModalHeader />
    <ModalBody>
      <div className="text-center mt-3 mb-5">
        <p>
          Are you sure you want to delete <b>{data?.team?.name}</b> team?
        </p>
      </div>
      <div className="mt-3 mb-4 text-center d-flex justify-content-center">
        <button type="button" className="btn btn-black mr-3 col-3" onClick={() => onHide({})}>
          {CANCEL_BUTTON}
        </button>
        <button
          type="button"
          className="btn btn-primary col-3"
          onClick={() => onConfirm(data?.team?.id)}
        >
          {DELETE_BUTTON}
        </button>
      </div>
    </ModalBody>
  </Modal>
);

TeamDelete.defaultProps = {
  data: {}
};

TeamDelete.propTypes = {
  data: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export default memo(TeamDelete);
