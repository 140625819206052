/* eslint-disable no-underscore-dangle, react/static-property-placement */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export const propTypesCheck = {
  registerError: PropTypes.func,
  skipError: PropTypes.func,
  backendErrors: PropTypes.object,
  errorsProvider: PropTypes.object
};

export const BackendValidation = (WrappedComp, mapping) => {
  class BackendValidationWrapper extends Component {
    static propTypes = {
      invalid: PropTypes.bool.isRequired,
      fields: PropTypes.object.isRequired
    };

    constructor(...args) {
      super(...args);
      this.state = { errors: {} };
      this.mapping = mapping;
      this.registerError = this.registerError.bind(this);
      this.skipError = this.skipError.bind(this);
    }

    _handleFieldError(errors) {
      const newErrors = {};
      Object.keys(errors).forEach(key => {
        newErrors[key] = Array.isArray(errors[key]) ? errors[key].join(' ') : errors[key];
      });
      this.setState({ errors: newErrors });
    }

    _handleGeneralError(reason, message) {
      this.setState({ errors: { general: `${reason}: ${message}` } });
    }

    _getFormFields(errors) {
      const { fields } = this.props;
      if (!this.mapping)
        Object.keys(errors).forEach(field => {
          if (!Object.prototype.hasOwnProperty.call(errors, field)) return;
          if (!Object.prototype.hasOwnProperty.call(fields, field)) return;
          fields[field].error = fields[field].error || errors[field];
          fields[field].touched = true;
          fields[field].skipError = this.skipFieldError.bind(this, field);
        });
      return fields;
    }

    registerError(data) {
      const { reason, message, errors } = data.error;
      if (errors && !_.isEmpty(errors)) this._handleFieldError(errors);
      else this._handleGeneralError(reason, message);
    }

    skipError() {
      this.setState({ errors: {} });
    }

    skipFieldError(field) {
      const newState = { ...this.state };
      const { fields } = this.props;
      delete newState.errors[field];
      fields[field].error = null;
      this.setState(newState);
    }

    render() {
      const { errors } = this.state;
      const { invalid } = this.props;

      return (
        <WrappedComp
          {...this.props}
          fields={this._getFormFields(errors)}
          invalid={invalid || !_.isEmpty(errors)}
          backendErrors={errors}
          registerError={this.registerError}
          skipError={this.skipError}
        />
      );
    }
  }

  return BackendValidationWrapper;
};
