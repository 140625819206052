import cn from 'classnames';
import { advisorsToOptions } from 'components/advisor/SelectAdvisor/utils';
import {
  QUESTIONNAIRE_URL,
  REVIEW_URL,
  digestMessage,
  emailEditorOptions,
  validateMessage
} from 'components/advisor/templates/email/editor-options';
import { VerboseErrorInput } from 'components/form';
import InlineLabeledInput from 'components/form/inline-labeled-input';
import Select from 'components/select';
import QuillField from 'components/utils/wysiwyg';
import { AdvisorContext } from 'containers/advisor';
import {
  IPS_REVIEW_EMAIL,
  QUESTIONNAIRE_EMAIL
} from 'containers/advisor/templates/company-meta/contants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { isAnalyst } from 'providers/auth';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import './styles.scss';

const LAYOUT = { label: 2, input: 10 };

const MESSAGE_VALIDATION_MAPPING = {
  [IPS_REVIEW_EMAIL]: REVIEW_URL,
  [QUESTIONNAIRE_EMAIL]: QUESTIONNAIRE_URL
};

const validate = type => values => {
  const errors = {};
  errors.email = errors.email || validation.required(values.email);
  errors.message =
    errors.message ||
    validateMessage(values.message) ||
    validation.variableExistance(values.message, MESSAGE_VALIDATION_MAPPING[type]);
  return errors;
};

const SendEmailForm = ({
  action,
  className,
  emailDisabled,
  fields,
  handleSubmit,
  invalid,
  investor,
  onCancel,
  submit,
  submitting,
  template,
  title,
  type
}) => {
  const [editing, setEditing] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const [advisor, setAdvisor] = useState();

  const {
    authProvider,
    investorProvider,
    prospectProvider,
    user,
    user: {
      advisor: {
        company: { email_disclosure: disclosure, cc_email_addresses: bcc }
      }
    }
  } = useContext(AdvisorContext);

  const setDefaultMessage = () => {
    fields.message.autofill(fields.message.initialValue);
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const getMessagePreview = () =>
    digestMessage(fields.message.value, { investor, advisor, disclosure, action }, true);

  const onSubmit = async ({ email, cc, sender, subject, message }) =>
    submit({
      email,
      cc,
      sender,
      subject,
      msgGenerator: ({ eInvestor, eAction }) =>
        digestMessage(message, { advisor, investor: eInvestor, disclosure, action: eAction })
    });

  const handleOnSubmit = event => {
    // prevents the event from propagating and the Proposal/IPS
    // from being re-generated when this component is used during the
    // creation of the reviews
    event.stopPropagation();
    return handleSubmit(onSubmit)(event);
  };

  const handleAdvisorChange = (newAdvisor, autofill = false) => {
    if (!newAdvisor) return;
    setAdvisor(newAdvisor);

    if (autofill) fields.sender.autofill(newAdvisor.id);
    else fields.sender.onChange(newAdvisor.id);

    if (fields.cc.pristine || fields.cc.autofilled)
      fields.cc.autofill(
        [fields.cc.initialValue, newAdvisor?.user?.email].filter(Boolean).join(', ')
      );
  };

  const onSelectAdvisor = ({ value }) => {
    if (value === advisor?.id) return;
    const newAdvisor = advisors.find(a => a.id === value);
    handleAdvisorChange(newAdvisor);
  };

  useEffect(async () => {
    const userWithoutAdvisor = _.cloneDeep(user);
    delete userWithoutAdvisor.advisor;
    const loggedAdvisor = { ...user.advisor, userWithoutAdvisor };

    let managers;
    if (investor?.id) {
      const provider = investor.is_prospect ? prospectProvider : investorProvider;
      managers = await provider.getManagers(investor.id);
    } else managers = [loggedAdvisor];

    // ensure that the logged advisor is in the list
    const loggedAdvisorInList = managers.find(a => a.id === loggedAdvisor.id);
    if (!loggedAdvisorInList) managers.push(loggedAdvisor);

    // set the advisors list
    setAdvisors(managers);

    // set the default advisor based on these rules:
    // 1. if the logged user is in the list and is not an analyst, use it as default
    // 2. pick the first user with advisor role in the list
    // 3.pick the first user in the list
    let defaultAdvisor = isAnalyst(loggedAdvisor.role)
      ? managers.find(a => a.role === !isAnalyst(a))
      : loggedAdvisor;

    if (!defaultAdvisor) [defaultAdvisor] = managers;
    handleAdvisorChange(defaultAdvisor, true);
  }, [user.advisor.id, investor?.id]);

  const advisorOptions = useMemo(
    () => advisorsToOptions(advisors, user),
    [JSON.stringify(advisors), user.advisor.id]
  );

  return (
    <form autoComplete="off" className={cn('send-email-form', className)} onSubmit={handleOnSubmit}>
      {title && <h3 className="text-sm-center mb-1">{title}</h3>}

      {!emailDisabled && (
        <InlineLabeledInput
          label="Email"
          inputComponent={VerboseErrorInput}
          sizes={LAYOUT}
          disabled={emailDisabled}
          inputProps={{ className: 'form-control', ...fields.email }}
        />
      )}

      <InlineLabeledInput
        label="Sender"
        inputComponent={Select}
        sizes={LAYOUT}
        helperText="Select the advisor who sends the email to the investor."
        inputProps={{
          onChange: onSelectAdvisor,
          options: advisorOptions,
          value: advisorOptions.find(o => o.value === advisor?.id),
          disabled: advisorOptions.length <= 1
        }}
      />

      <InlineLabeledInput
        label="CC"
        inputComponent={VerboseErrorInput}
        sizes={LAYOUT}
        inputProps={{ className: 'form-control', ...fields.cc }}
      />

      <InlineLabeledInput
        label="BCC"
        inputComponent={VerboseErrorInput}
        sizes={LAYOUT}
        disabled
        inputProps={{ className: 'form-control', value: bcc }}
      />

      <InlineLabeledInput
        label="Subject"
        inputComponent={VerboseErrorInput}
        sizes={LAYOUT}
        inputProps={{ className: 'form-control', ...fields.subject }}
      />

      <div className="row inline-labeled-input message-actions">
        <div className="col-sm-12">
          {!fields.message.pristine && (
            <button className="btn" onClick={setDefaultMessage} type="button">
              Use Default Message
            </button>
          )}
          <button className="btn btn-primary dark-button" onClick={toggleEditing} type="button">
            {editing ? 'Preview' : 'Edit'}
          </button>
        </div>
      </div>

      {!editing && (
        <div className="row inline-labeled-input email-body-form">
          <label htmlFor="message" className="col-sm-2 message-label">
            Message
          </label>
          <div className="col-sm-10">
            <div
              className="message email-message-preview"
              dangerouslySetInnerHTML={{ __html: getMessagePreview() }}
            />
            {fields.message.touched && fields.message.error && (
              <span className="text-danger error">{fields.message.error}</span>
            )}
          </div>
        </div>
      )}

      {editing && (
        <InlineLabeledInput
          label="Message"
          inputComponent={QuillField}
          sizes={LAYOUT}
          inputProps={{
            className: 'form-control message',
            field: fields.message,
            options: emailEditorOptions(type)
          }}
        />
      )}

      {authProvider.hasCompliancePermissions(user) && (
        <div className="row inline-labeled-input template-actions">
          <div className="col-sm-12">
            {template && (
              <a className="cta" href={template.url} target="_blank" rel="noopener noreferrer">
                {template.text}
              </a>
            )}
            <a
              className="cta"
              href="/advisor/templates/email-disclosure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Edit Disclosure
            </a>
          </div>
        </div>
      )}

      <div className="row inline-labeled-input">
        <div className="col-sm-2" />
        <div className="col-sm-10 email-actions-container">
          <button className="btn btn-shadow cancel-button" onClick={onCancel} type="button">
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-shadow save-and-send-button"
            disabled={invalid || submitting || editing}
          >
            {submitting ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </form>
  );
};

SendEmailForm.propTypes = {
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  className: PropTypes.string,
  emailDisabled: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  investor: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  template: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  title: PropTypes.string.isRequired,
  type: PropTypes.string
};

SendEmailForm.defaultProps = {
  action: null,
  className: '',
  emailDisabled: false,
  template: null,
  type: QUESTIONNAIRE_EMAIL
};

export default reduxForm(
  {
    form: 'send-email-form',
    fields: ['email', 'cc', 'sender', 'subject', 'message']
  },
  (state, props) => ({ validate: validate(props.type ?? QUESTIONNAIRE_EMAIL) })
)(SendEmailForm);
