import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  GRAPHITE_COLOR,
  LIGHT_GRAPHITE_COLOR,
  FOR_LANDSCAPE_ORIENTATION
} from 'reports/base/styles';

const styles = StyleSheet.create({
  container: {
    ...BASE_TABLE,
    border: 1,
    borderColor: LIGHT_GRAPHITE_COLOR,
    borderStyle: 'solid',
    padding: 5,
    [FOR_LANDSCAPE_ORIENTATION]: {
      padding: 0
    }
  },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: {
    ...BASE_TABLE_CELL,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    [FOR_LANDSCAPE_ORIENTATION]: {
      paddingVertical: 0
    }
  },
  position: {
    fontSize: 28,
    fontWeight: 'bold',
    color: LIGHT_GRAPHITE_COLOR
  },
  text: {
    paddingHorizontal: 5
  },
  otherContent: {
    color: GRAPHITE_COLOR
  }
});

export default styles;
