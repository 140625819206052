/* eslint-disable import/prefer-default-export */
import { roundThousands } from 'utils/questionnaire';
import { questionTypes as qt } from 'constants/questionnaire';
import { formatMoney, formatPercentage } from 'utils/utils';

export const getTolerableLossAnswer = question => {
  const refs = question.data?.refs || {};

  const initialInvestment =
    question.question_type === qt.LEGACY
      ? roundThousands(Number(refs['assets-invested'])) || 0
      : roundThousands(Number(refs['liquid-assets'])) || 0;
  const lossPercentage = question.answer.value;
  const lossAmount = (initialInvestment * lossPercentage) / 100;

  // The legacy version of this question shows the $ amount.
  // The new version shows both the current loss percentage + the $ amount.
  return question.question_type === qt.LEGACY
    ? formatMoney(Math.abs(lossAmount))
    : `${formatPercentage(lossPercentage, 1)} (${formatMoney(lossAmount)})`;
};
