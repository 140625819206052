import SigninForm from 'components/signin-form';
import HeaderAuth from 'containers/layout/header-dark';
import HeroFormContainer from 'pages/utils/hero-form-container';
import PropTypes from 'prop-types';
import ActionProvider from 'providers/actions';
import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const Signin = ({ actionProvider }) => (
  <div id="Signin">
    <HeaderAuth />
    <div className="signin-wrapper">
      <HeroFormContainer>
        <SigninForm actionProvider={actionProvider} />
      </HeroFormContainer>
    </div>
  </div>
);

Signin.propTypes = {
  actionProvider: PropTypes.object.isRequired
};

export default connect(
  state => state,
  dispatch => ({
    actionProvider: new ActionProvider({ dispatch })
  })
)(Signin);
