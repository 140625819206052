import { FETCHING, FETCHING_FAILURE } from 'constants/actions';
import { TEMPLATE_CREATE, TEMPLATE_GET, TEMPLATE_LIST, TEMPLATE_UPDATE } from 'constants/templates';
import config from '../config';
import DataProvider from './data';

export default class TemplateProvider extends DataProvider {
  list(params = {}) {
    this.dispatch(FETCHING);
    return this.provider.get(`${config.apiBase}templates/`, params).then(({ data, error }) => {
      if (!error) this.dispatch(TEMPLATE_LIST, { data });
      else this.dispatch(FETCHING_FAILURE);
      return { data, error };
    });
  }

  create(data) {
    this.dispatch(FETCHING);
    return this.provider.post(`${config.apiBase}templates/`, data).then(({ data, error }) => {
      if (!error) this.dispatch(TEMPLATE_CREATE, { data });
      else this.dispatch(FETCHING_FAILURE);
      return { data, error };
    });
  }

  get(id) {
    this.dispatch(FETCHING);
    return this.provider.get(`${config.apiBase}templates/${id}/`).then(({ data, error }) => {
      if (!error) this.dispatch(TEMPLATE_GET, { data });
      else this.dispatch(FETCHING_FAILURE);
      return { data, error };
    });
  }

  update(id, data) {
    this.dispatch(FETCHING);
    return this.provider.put(`${config.apiBase}templates/${id}/`, data).then(({ data, error }) => {
      if (!error) this.dispatch(TEMPLATE_UPDATE, { data });
      else this.dispatch(FETCHING_FAILURE);
      return { data, error };
    });
  }

  partialUpdate(id, data) {
    this.dispatch(FETCHING);
    return this.provider
      .patch(`${config.apiBase}templates/${id}/`, data)
      .then(({ data, error }) => {
        if (!error) this.dispatch(TEMPLATE_UPDATE, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  delete(id) {
    return this.provider.delete(`${config.apiBase}templates/${id}/`);
  }

  archive(id) {
    return this.provider
      .post(`${config.apiBase}templates/${id}/archive/`)
      .then(({ data, error }) => {
        if (!error) this.dispatch(TEMPLATE_UPDATE, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  unarchive(id) {
    return this.provider
      .post(`${config.apiBase}templates/${id}/unarchive/`)
      .then(({ data, error }) => {
        if (!error) this.dispatch(TEMPLATE_UPDATE, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  approve(id) {
    return this.provider
      .post(`${config.apiBase}templates/${id}/approve/`)
      .then(({ data, error }) => {
        if (!error) this.dispatch(TEMPLATE_UPDATE, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }
}
