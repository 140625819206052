export const valuesPerPage = [
  { value: 10, label: '10 per page' },
  { value: 20, label: '20 per page' },
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' }
];

export const preferredValuesPerPage = () =>
  JSON.parse(localStorage.getItem(window.btoa(window.location.pathname))) || valuesPerPage[0];

export const setPageSelected = pageSelected =>
  localStorage.setItem(window.btoa(window.location.pathname), JSON.stringify(pageSelected));
