/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import arrowRightIcon from 'assets/img/icons/arrow-right-white.svg';
import tooltipCloseButton from 'assets/img/tooltip-close-button.svg';
import ClientSelectAccounts from 'components/advisor/investors/client-select-accounts-modal';
import {
  investorAccountsSelector,
  investorSelector,
  isProspectSelector
} from 'components/advisor/investors/selectors';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import { getInvestorReportCoverData } from 'reports/utils';
import ReportViewerModal from 'reports/viewer/modal';
import RiskToleranceQuestionnaireCombinedReport from '..';
import './styles.scss';

const RiskToleranceQuestionnaireCombinedReportViewer = (
  { accounts, discussionTopics, investor, isProspect, questionnaires },
  context
) => {
  // referencing child context to allow `addAccount` use
  // https://reactjs.org/docs/legacy-context.html#referencing-context-in-stateless-function-components
  const { addAccount } = context;
  const { authProvider, investorProvider, prospectProvider, user, userProvider } =
    useContext(AdvisorContext);

  const [coverData, setCoverData] = useState({});
  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);
  const [selectingAccounts, setSelectingAccounts] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const addAccountTooltipRef = useRef(null);

  const withCustomBranding = userProvider.isBusinessOrAbove(user);
  const subtitle = investor.full_name;

  const changeOrientation = value => {
    setOrientation(value);
  };

  const openAccountsModal = () => {
    setSelectingAccounts(true);
  };

  const hideAccountsModal = () => {
    setSelectingAccounts(false);
  };

  const onSelectAccounts = selectedAccountIds => {
    setSelectingAccounts(false);
    setSelectedAccountIds(selectedAccountIds);
    setShowModal(true);
  };

  useEffect(() => {
    if (isProspect && !accounts.length) ReactTooltip.show(addAccountTooltipRef.current);
  }, []);

  useEffect(() => {
    getInvestorReportCoverData(investor, investorProvider, prospectProvider).then(data => {
      setCoverData(data);
    });
  }, [JSON.stringify(investor)]);

  return (
    <div id="RiskToleranceQuestionnaireCombinedReportViewer" className="text-right">
      {accounts.length > 1 && (
        <button
          type="button"
          onClick={openAccountsModal}
          className="btn btn-outline-primary font-weight-normal"
          disabled={_.isEmpty(questionnaires)}
        >
          Download Combined PDF
        </button>
      )}

      {isProspect && !accounts.length && authProvider.hasAddProspectsPermissions(user) && (
        <>
          <button
            data-tip=""
            data-for="add-account-tooltip"
            data-event="fake-event"
            type="button"
            onClick={() => addAccount()}
            className="btn btn-primary ml-1"
            ref={addAccountTooltipRef}
          >
            Add accounts to see portfolio risk{' '}
            <img src={arrowRightIcon} alt="arrow-icon" className="arrow-icon" />
          </button>
          <ReactTooltip id="add-account-tooltip" effect="solid" place="bottom" clickable>
            <img
              alt="Close"
              className="tooltip-close-button"
              onClick={() => ReactTooltip.hide(addAccountTooltipRef.current)}
              onKeyDown={() => {}}
              src={tooltipCloseButton}
            />
            Add an account to see the portfolio risk and compare it to the risk tolerance. Account
            can be added by manually entering, uploading CSV, or inviting the client to connect
            his/her accounts.
          </ReactTooltip>
        </>
      )}

      <Modal
        id="CombinedRiskToleranceAccounts"
        show={selectingAccounts}
        onHidden={hideAccountsModal}
      >
        <ModalHeader />
        <ModalBody>
          <ClientSelectAccounts accounts={accounts} onSelectAccounts={onSelectAccounts} />
        </ModalBody>
      </Modal>

      {showModal && (
        <ReportViewerModal
          changeOrientation={changeOrientation}
          fullyLoadedReportAssets
          onHidden={() => {
            setShowModal(false);
          }}
          orientation={orientation}
          report={
            <RiskToleranceQuestionnaireCombinedReport
              accounts={accounts.filter(account => selectedAccountIds.includes(account.id))}
              coverData={coverData}
              discussionTopics={discussionTopics}
              investor={investor}
              orientation={orientation}
              questionnaires={questionnaires}
              subtitle={subtitle}
              user={user}
              withCustomBranding={withCustomBranding}
            />
          }
          showButton={false}
          showModal={showModal}
        />
      )}
    </div>
  );
};

RiskToleranceQuestionnaireCombinedReportViewer.contextTypes = {
  addAccount: PropTypes.func.isRequired
};

RiskToleranceQuestionnaireCombinedReportViewer.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  discussionTopics: PropTypes.array,
  investor: PropTypes.object,
  isProspect: PropTypes.bool.isRequired,
  questionnaires: PropTypes.object.isRequired
};

RiskToleranceQuestionnaireCombinedReportViewer.defaultProps = {
  accounts: [],
  discussionTopics: [],
  investor: null
};

export default connect(
  state => ({
    accounts: investorAccountsSelector(state),
    investor: investorSelector(state),
    isProspect: isProspectSelector(state),
    questionnaires: state.questions.questionnaires
  }),
  null
)(RiskToleranceQuestionnaireCombinedReportViewer);
