/* eslint-disable react/no-did-update-set-state,react/no-unused-state */
import cn from 'classnames';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

export const LAYOUTS = {
  BOX: 'box',
  INLINE: 'inline'
};

const Widget = ({
  children,
  className,
  dataset,
  datasets,
  empty,
  emptyMessage,
  hint,
  id,
  layout,
  loading,
  page,
  restricted,
  setDataset,
  title
}) => {
  const onKeyPressHandler = newDataset => e => {
    if (e.charCode === 13) setDataset(newDataset);
  };
  const onChangeDataset = newDataset => {
    setDataset(newDataset);
  };

  let datasetSelector = null;
  if (restricted) datasetSelector = dataset;
  else if (datasets.length > 1)
    datasetSelector = (
      <div className="dropdown">
        <button className="btn-widget dropdown-toggle" type="button" data-toggle="dropdown">
          <span>{dataset}</span>
          <i className="icon-arrow" />
        </button>
        <ul className="dropdown-menu">
          {Object.values(datasets).map(name => (
            <li
              className={cn('dropdown-item', {
                disabled: name === dataset
              })}
              role="option"
              aria-selected={name === dataset}
              key={name}
              onKeyPress={onKeyPressHandler(name)}
              onClick={() => onChangeDataset(name)}
            >
              {name}
            </li>
          ))}
        </ul>
      </div>
    );

  return (
    <div id={id} className={`widget card widget-${layout} ${className}`}>
      {loading && <LoadingPrismDataMessage />}
      {layout === LAYOUTS.BOX && (
        <div className="header">
          <div className="title">
            {dataset} {title}
            {hint && (
              <span className="tip">
                <span data-tip="" data-for={`${id}-hint`} className="info">
                  i
                </span>
                <ReactTooltip
                  className="tutorial-tip"
                  effect="solid"
                  id={`${id}-hint`}
                  place="top"
                  type="light"
                >
                  {hint}
                </ReactTooltip>
              </span>
            )}
          </div>
          <div className="actions">{datasetSelector}</div>
          {page && (
            <Link className="page" to={page}>
              View All
            </Link>
          )}
        </div>
      )}
      {layout === LAYOUTS.INLINE && (
        <div className="actions">
          {datasetSelector}
          <div className="hint-container">{hint}</div>
        </div>
      )}
      <div className="card-content">
        {!loading && !empty && children}
        {!loading && empty && (
          <div className="empty">
            <span>
              {emptyMessage ?? `There are no ${dataset ? `${dataset} with ` : ''} ${title}.`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

Widget.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]).isRequired,
  className: PropTypes.string,
  empty: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  layout: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dataset: PropTypes.string,
  datasets: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.string,
  restricted: PropTypes.bool.isRequired,
  setDataset: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

Widget.defaultProps = {
  className: '',
  dataset: '',
  datasets: [],
  emptyMessage: null,
  hint: null,
  id: '',
  layout: LAYOUTS.BOX,
  page: null,
  setDataset: () => {}
};

export default Widget;
