import Dropzone from 'components/dropzone';
import { VerboseErrorInput as Input, Textarea } from 'components/form';
import PhoneField from 'components/form/phone-field';
import SpinnerLoader from 'components/performance-spinner';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ROLES } from 'providers/auth';
import React, { useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validateUser = values => {
  const errors = {};
  errors.first_name = errors.first_name || validation.required(values.first_name);
  errors.last_name = errors.last_name || validation.required(values.last_name);
  errors.email =
    errors.email || validation.required(values.email) || validation.email(values.email);
  return errors;
};

const validate = values => {
  const errors = {};
  errors.phone = errors.phone || (values.phone && validation.internationalPhone(values.phone));
  errors.user = validateUser(values.user);
  return errors;
};

const AdvisorProfileForm = ({
  errors,
  fields,
  handleSubmit,
  initializeForm,
  registerError,
  setAdvisorUser,
  title,
  updateAdvisorProfile,
  updateAdvisorProfileImage,
  user
}) => {
  const emailAlertRef = useRef(null);

  useEffect(() => {
    if (!user) return;
    const values = {
      phone: user.advisor.phone,
      title: user.advisor.title,
      user: {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email
      },
      website: user.advisor.website
    };
    initializeForm(values);
  }, [JSON.stringify(user)]);

  const onSubmit = useCallback(
    async values => {
      if (values.user.email === user.email) delete values.user.email;

      const response = await updateAdvisorProfile(values);

      if (response.error) {
        toast.error('Sorry, something went wrong');
        registerError(response);
      } else {
        toast.success('Profile saved successfully');
        setAdvisorUser();
      }
    },
    [JSON.stringify(user)]
  );

  const onSubmitFile = (fieldName, message) =>
    useCallback(
      async files => {
        let response;
        if (Array.isArray(files) && files.length)
          response = await updateAdvisorProfileImage(files[0], fieldName);
        else response = await updateAdvisorProfile({ [fieldName]: null });

        if (response.error) toast.error('There was a problem saving this file.');
        else {
          toast.success(message);
          setAdvisorUser();
        }
      },
      [fieldName]
    );

  const onSubmitImage = onSubmitFile('image', 'Profile picture is successfully updated');

  const onBlurEmailAlert = () => {
    if (emailAlertRef.current) ReactTooltip.hide(emailAlertRef.current);
  };
  const onFocusEmailAlert = () => {
    if (emailAlertRef.current) ReactTooltip.show(emailAlertRef.current);
  };

  if (!user) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="advisor-profile-form">
      <h1>{title}</h1>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="content">
          <div className="col col-photo">
            <span className="label">Profile photo</span>
            <div className="image-box">
              <Dropzone onDrop={onSubmitImage}>
                {user.advisor.image ? (
                  <>
                    <img src={user.advisor.image} alt="" />
                    <div id="image-dropzone-area" className="dropzone-area overlay">
                      Change photo
                    </div>
                  </>
                ) : (
                  <div id="image-dropzone-area" className="dropzone-area empty">
                    Click to upload
                    <small>Or drag file here</small>
                  </div>
                )}
              </Dropzone>
              <div className="help-text">
                <span>Recommended width: 400px or higher. File type: jpg, png</span>
              </div>
            </div>
          </div>
          <div className="col col-data">
            <div className="row row-name">
              <div className="col">
                <span className="label">First Name</span>
                <Input
                  type="text"
                  className="form-control"
                  {...fields.user.first_name}
                  error={fields.user.first_name?.error || fields.user?.error?.first_name}
                />
              </div>
              <div className="col">
                <span className="label">Last Name</span>
                <Input
                  type="text"
                  className="form-control"
                  {...fields.user.last_name}
                  error={fields.user.last_name?.error || fields.user?.error?.last_name}
                />
              </div>
            </div>
            <div className="row row-contact">
              <div className="col">
                <span className="label">Phone</span>
                <PhoneField {...fields.phone} />
              </div>
              <div
                className="col"
                data-event="fake-event"
                data-for="email-alert"
                data-tip="Updating the email will change the login credentials"
                ref={emailAlertRef}
              >
                <span className="label">Email</span>
                <Input
                  type="email"
                  className="form-control"
                  {...fields.user.email}
                  error={fields.user.email?.error || fields.user?.error?.email}
                  onFocus={onFocusEmailAlert}
                  onBlur={onBlurEmailAlert}
                />
              </div>
            </div>
            <ReactTooltip id="email-alert" effect="solid" place="bottom" />
            <div className="row row-title">
              <div className="col">
                <span className="label">Title</span>
                <Textarea className="form-control" rows={3} {...fields.title} />
              </div>
            </div>
            <div className="row row-role">
              <div className="col">
                <span className="label">Role</span>
                <span key={user.advisor.role} className="role">
                  {ROLES.labels[user.advisor.role]}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="actions">
          <button type="submit" className="btn btn-primary" disabled={errors && !_.isEmpty(errors)}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

AdvisorProfileForm.defaultProps = {
  title: 'My Profile'
};

AdvisorProfileForm.propTypes = {
  errors: PropTypes.object.isRequired,
  setAdvisorUser: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateAdvisorProfile: PropTypes.func.isRequired,
  updateAdvisorProfileImage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'editAdvisor',
  fields: ['phone', 'title', 'user.email', 'user.first_name', 'user.last_name', 'website'],
  initialValues: {},
  validate
})(BackendValidation(AdvisorProfileForm));
