import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import UpsideDownsideChart from 'components/charts/upside-downside-chart';
import PropTypes from 'prop-types';
import React from 'react';
import { downUpCaptures } from 'utils/prism';
import { withCurrencyFormat } from 'utils/utils';
import './styles.scss';

const PerformanceContent = ({ capturePercentage, negative, value }) => {
  if (!capturePercentage) return <p>-</p>;
  return (
    <p style={{ marginTop: 5 }}>
      {capturePercentage}%
      {value && (
        <>
          {' '}
          ({negative ? '-' : '+'}
          {withCurrencyFormat((value * capturePercentage) / 100, 'standard', 0)})
        </>
      )}
    </p>
  );
};

PerformanceContent.propTypes = {
  capturePercentage: PropTypes.number,
  negative: PropTypes.bool,
  value: PropTypes.number
};

PerformanceContent.defaultProps = {
  capturePercentage: null,
  negative: false,
  value: null
};

const PrismRatingUpsideDownside = ({
  market,
  options,
  prismSummary: {
    overall: overallScore,
    tail: tailScore,
    volatility: volatilityScore,
    downside_capture_ratio: downCapture,
    upside_capture_ratio: upCapture
  },
  style,
  targetSummary,
  value
}) => {
  const prismOverall = overallScore ? overallScore.toFixed(1) : null;
  const targetOverall = targetSummary?.overall && targetSummary.overall.toFixed(1);

  if (
    upCapture === undefined ||
    downCapture === undefined ||
    upCapture === null ||
    downCapture === null
  )
    [upCapture, downCapture] = downUpCaptures(volatilityScore, tailScore);

  const upCapturePercentage = Math.round(upCapture * market.up);
  const downCapturePercentage = Math.round(downCapture * market.down);

  return (
    <div className="prism-rating-scores-container-v2">
      <div className="prism-rating-scores result-scores">
        {prismOverall && (
          <RiskOverall
            smaller
            options={options}
            style={style}
            score={prismOverall}
            target={targetOverall}
          />
        )}
        <div className="down-up-chart">
          <div className="timeframe">
            Next
            <span>12</span>
            Months
          </div>
          <div>
            <UpsideDownsideChart
              target={{ upside: upCapture / 10, downside: downCapture / 10 }}
              withLabels
            />
          </div>
        </div>
      </div>
      <div className="description">
        <div className="scenario-description">
          Historically, S&amp;P 500 has been between <span className="down">-{market.down}%</span>{' '}
          and <span className="up">+{market.up}%</span> about 90% of the time. If S&amp;P 500 was
          down <span className="down">{market.down}%</span> then this portfolio&apos;s performance
          may be{' '}
          {downCapturePercentage ? <span className="down">-{downCapturePercentage}%</span> : '-'}{' '}
          and if S&amp;P 500 was up <span className="up">{market.up}%</span> then this
          portfolio&apos;s performance may be{' '}
          {upCapturePercentage ? <span className="up">{upCapturePercentage}%</span> : '-'}
          {downCapture + upCapture === 0 ? (
            <p>Downside capture: - Upside capture: -</p>
          ) : (
            <p>
              Downside capture: {Math.round(downCapture * 100)}% - Upside capture:{' '}
              {Math.round(upCapture * 100)}%
            </p>
          )}
        </div>
        <div className="down-up">
          <div className="down">
            <span className="caption">Downside performance</span>
            <PerformanceContent capturePercentage={downCapturePercentage} value={value} negative />
          </div>
          <div className="up">
            <span className="caption">Upside performance</span>
            <PerformanceContent capturePercentage={upCapturePercentage} value={value} />
          </div>
        </div>
      </div>
    </div>
  );
};

PrismRatingUpsideDownside.propTypes = {
  market: PropTypes.object.isRequired,
  options: PropTypes.object,
  prismSummary: PropTypes.object.isRequired,
  style: PropTypes.object,
  targetSummary: PropTypes.object,
  value: PropTypes.number
};

PrismRatingUpsideDownside.defaultProps = {
  options: {},
  style: {},
  targetSummary: {},
  value: null
};

export default PrismRatingUpsideDownside;
