import DataProvider from 'providers/data';
import config from '../config';

class InAppProvider extends DataProvider {
  getAdvisor(token, id) {
    return this.provider.get(`${config.apiBase}in-app/${token}/${id}/`);
  }

  getGuest(token, id) {
    return this.provider.post(`${config.apiBase}in-app/${token}/${id}/guest/`);
  }

  createInvestor(token, id, firstName, lastName, email) {
    return this.provider.post(`${config.apiBase}in-app/${token}/${id}/investors/`, {
      first_name: firstName,
      last_name: lastName,
      email
    });
  }
}

export default InAppProvider;
