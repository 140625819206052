import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SuccessfullyCopiedAction = ({ name, onClose }) => (
  <div id="SuccessfullyCopiedAction" className="successfully-tolerance-action">
    <h1>
      <span role="img" aria-label="thumbs-up">
        👍
      </span>{' '}
      Link copied!
    </h1>
    <p>
      Once {name} completes the questionnaire through this link, you will get an update via email
      and notification.
    </p>
    <button type="button" onClick={onClose} className="btn btn-primary">
      Sounds great
    </button>
  </div>
);

SuccessfullyCopiedAction.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const SuccessfullyEmailedAction = ({ name, onClose }) => (
  <div id="SuccessfullyEmailedAction" className="successfully-tolerance-action">
    <h1>
      <span role="img" aria-label="celebration">
        🎉
      </span>{' '}
      Congratulations!
    </h1>
    <p className="font-weight-bold">You&apos;ve successfully sent a risk tolerance assessment.</p>
    <p>
      Once {name} completes the questionnaire, you will get an update via email and notification.
    </p>
    <button type="button" onClick={onClose} className="btn btn-primary">
      Got it
    </button>
  </div>
);

SuccessfullyEmailedAction.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { SuccessfullyCopiedAction, SuccessfullyEmailedAction };
