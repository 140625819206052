import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import AccountRiskTolerance from '../account-risk';
import './styles.scss';

const AccountDetailsTolerance = (props, context) => {
  // referencing child context to allow `account` and `questions` use
  // https://reactjs.org/docs/legacy-context.html#referencing-context-in-stateless-function-components
  const { account, questions } = context;

  if (_.isEmpty(account)) return null;

  return (
    <div className="account-details-tolerance account-target-container">
      <AccountRiskTolerance account={account} questions={questions} showQuestionnaire />
    </div>
  );
};

AccountDetailsTolerance.contextTypes = {
  account: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object.isRequired)
};

export default AccountDetailsTolerance;
