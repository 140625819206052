import Widget from 'components/advisor/dashboard/widgets/';
import useWidget, {
  DATASETS,
  widgetStorePropTypes,
  widgetStoreSelector
} from 'components/advisor/dashboard/widgets/hooks';
import PrismDistributionChart from 'components/charts/prism-distribution-chart';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const processor = data =>
  data.filter(item => item.prism_overall && (item.total_positions ?? item.total_accounts));

const RiskDistributionWidget = ({ store }) => {
  const fetch = (provider, params) => provider.es.list({ ...params, get_all: true });
  const { data, dataset, ...widget } = useWidget({
    fetch,
    store,
    processor,
    companyModeRestricted: true
  });

  const { authProvider, routerActions } = useContext(AdvisorContext);

  const onClick = ({ item: { dataContext } }) => {
    if (dataset === DATASETS.ADVISORS) authProvider.setAdvisorByManager(dataContext.itemId);
    else routerActions.push({ pathname: dataContext.url });
  };
  const title = (
    <>
      PRISM Rating <sup className="prism-rating-tm">TM</sup>&nbsp;Distribution
    </>
  );
  const emptyMessage = (
    <>
      There are no {dataset} with PRISM Rating <sup className="prism-rating-tm">TM</sup>
    </>
  );
  return (
    <Widget
      {...widget}
      emptyMessage={emptyMessage}
      dataset={dataset}
      title={title}
      id="RiskDistributionWidget"
    >
      <PrismDistributionChart
        distribution={data}
        handleClick={onClick}
        title={dataset === 'Accounts' ? 'Securities' : 'Accounts'}
        id={`prism-distribution-chart-${Math.random()}`}
      />
    </Widget>
  );
};

RiskDistributionWidget.propTypes = {
  store: PropTypes.shape(widgetStorePropTypes).isRequired
};

export default connect(state => ({
  store: widgetStoreSelector('esList')(state)
}))(RiskDistributionWidget);
