import config from 'app/config';
import Chart from 'components/charts';
import PositionTypeChart from 'components/charts/position-type-chart';
import TreemapChart from 'components/charts/treemap-chart';
import PropTypes from 'prop-types';
import React from 'react';
import RiskOverall from '../risk-overall';
import { CONCENTRATED_SCORE, CORRELATION_SCORE, TAIL_SCORE, VOLATILITY_SCORE } from './constants';
import './styles.scss';

const RiskAnalysisTargetScore = ({
  chartData,
  chartData2,
  chartId,
  onChartReady,
  overallOptions,
  overallStyles,
  prefix,
  score,
  scoreName,
  scoreOptions,
  scoreStyles,
  target,
  text,
  title
}) => (
  <div className="risk-analysis-target-score box-container">
    <div className="box-heading">
      <span>{title}</span>
    </div>
    <div className="box-content">
      <div className="box-element box-pie">
        <RiskOverall
          className={`target-score-overall-gauge-full-${scoreName}`}
          score={score}
          target={target}
          noLabels
          options={overallOptions}
          style={overallStyles}
          compact
        />
      </div>
      <div className="box-element box-analysis">
        {scoreName === TAIL_SCORE && chartData && (
          <div className="tail-analysis-box">
            <Chart
              className={`${prefix}-median-portfolio-move`}
              data={chartData.reverse()}
              id={chartId}
              options={{
                ...config.chart.bar.medianPortfolioMove,
                ...scoreOptions,
                ...{ legend: null, categoryAxis: { position: 'top' } },
                ...{ valueAxes: [{ unit: '%', position: 'right' }] }
              }}
              onChartReady={onChartReady}
              style={{ width: 465, height: 200, ...scoreStyles }}
            />
            <div className="legend">
              <div className="row1">
                <div className="blue1-box" />
                <span className="legend-label">Current Portfolio</span>
              </div>
              <div className="row1">
                <div className="blue2-box" />
                <span className="legend-label">Benchmark 60/40 Portfolio</span>
              </div>
            </div>
          </div>
        )}
        {scoreName === CORRELATION_SCORE && chartData && (
          <Chart
            className={`${prefix}-correlation`}
            data={chartData}
            id={chartId}
            options={{ ...config.chart.bar.assetCorrelation, ...scoreOptions }}
            onChartReady={onChartReady}
            style={{ width: 480, height: 200, ...scoreStyles }}
          />
        )}
        {scoreName === CONCENTRATED_SCORE && chartData && (
          <TreemapChart
            data={chartData}
            height={165}
            id={chartId}
            onChartReady={onChartReady}
            width={400}
            {...scoreStyles}
          />
        )}
        {scoreName === VOLATILITY_SCORE && chartData2 && (
          <div>
            <PositionTypeChart
              id={chartId}
              data={chartData2}
              options={{ ...config.chart.pie.volatilityDiversification, ...scoreOptions }}
              onChartReady={onChartReady}
              style={{ width: 350, height: 192, display: 'inline-block', ...scoreStyles }}
            />
          </div>
        )}
      </div>
      <div className="box-element score-description">{text}</div>
    </div>
  </div>
);

RiskAnalysisTargetScore.propTypes = {
  chartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  chartData2: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  chartId: PropTypes.string,
  onChartReady: PropTypes.func,
  overallOptions: PropTypes.object,
  overallStyles: PropTypes.object,
  prefix: PropTypes.string,
  score: PropTypes.number.isRequired,
  scoreName: PropTypes.string.isRequired,
  scoreOptions: PropTypes.object,
  scoreStyles: PropTypes.object,
  target: PropTypes.number,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

RiskAnalysisTargetScore.defaultProps = {
  chartData: null,
  chartData2: null,
  chartId: undefined,
  onChartReady: undefined,
  overallOptions: {},
  overallStyles: {},
  prefix: 'custom',
  scoreOptions: {},
  scoreStyles: {},
  target: undefined
};

export default RiskAnalysisTargetScore;
