import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_CELL, BASE_TABLE_ROW, LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

const styles = StyleSheet.create({
  factors: {
    flexGrow: 1
  },
  factor: {
    flexGrow: 0,
    marginBottom: 15,
    border: 1,
    borderColor: LIGHT_GRAPHITE_COLOR,
    borderStyle: 'solid'
  },
  factorTitle: {
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    textAlign: 'left',
    backgroundColor: LIGHT_GRAPHITE_COLOR
  },
  factorContent: { ...BASE_TABLE_ROW },
  factorCell: { ...BASE_TABLE_CELL, alignItems: 'center' },
  factorText: { fontSize: 8, paddingHorizontal: 20 },
  factorChart: { objectFit: 'contain' },
  factorChartLegend: { ...BASE_TABLE_ROW, alignItems: 'center', marginTop: 5, fontSize: 8 },
  factorChartLegendBox: { flexDirection: 'row', alignItems: 'center' },
  factorChartLegendDot: { width: 8, height: 8, borderRadius: 8, marginRight: 3 }
});

export default styles;
