import { Document } from '@react-pdf/renderer';
import { combinedPortfoliosSelector } from 'components/advisor/investors/details/prism-investor/selectors';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import { getInvestorReportCoverData } from 'reports/utils';
import ReportViewerModal from 'reports/viewer/modal';
import InvestorPrismReport from '..';
import { getAccountCharts, getFullyLoadedCharts } from './utils';

const InvestorPrismReportViewer = ({
  accounts,
  accountSavedCharts,
  benchmarks,
  combinedPortfolios,
  investor,
  investorSavedCharts,
  scenarios,
  taxonomies,
  totalAssets
}) => {
  if (!accounts || !investor) return null;

  const [coverData, setCoverData] = useState({});
  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);

  const { investorProvider, prospectProvider, user, userProvider } = useContext(AdvisorContext);

  const { fullyLoadedAccountCharts, fullyLoadedInvestorCharts } = getFullyLoadedCharts(
    combinedPortfolios,
    accounts,
    accountSavedCharts,
    investorSavedCharts,
    user
  );
  const fullyLoadedCharts = fullyLoadedAccountCharts && fullyLoadedInvestorCharts;

  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  useEffect(() => {
    getInvestorReportCoverData(investor, investorProvider, prospectProvider).then(data => {
      setCoverData(data);
    });
  }, [JSON.stringify(investor)]);

  // the report has to be memoized to avoid making unnecessary renders that slow down the
  // entire UI. Additionally, if the chart images are not yet loaded, a <Document /> is returned
  // to also avoid slowing down the UI
  const report = useMemo(
    () =>
      fullyLoadedCharts ? (
        <InvestorPrismReport
          accounts={accounts.map(account => ({
            ...account,
            ...getAccountCharts(account.id, accountSavedCharts)
          }))}
          benchmarks={benchmarks}
          coverData={coverData}
          orientation={orientation}
          investor={{ ...investor, ...investorSavedCharts }}
          scenarios={scenarios}
          subtitle={investor.full_name}
          taxonomies={taxonomies}
          totalAssets={totalAssets}
          user={user}
          withCustomBranding={withCustomBranding}
        />
      ) : (
        <Document />
      ),
    [
      JSON.stringify(accounts),
      JSON.stringify(accountSavedCharts),
      JSON.stringify(benchmarks),
      JSON.stringify(coverData),
      JSON.stringify(investor),
      JSON.stringify(investorSavedCharts),
      JSON.stringify(scenarios),
      orientation,
      totalAssets
    ]
  );

  const changeOrientation = value => {
    setOrientation(value);
  };

  return (
    <ReportViewerModal
      changeOrientation={changeOrientation}
      fullyLoadedReportAssets={fullyLoadedCharts}
      orientation={orientation}
      report={report}
    />
  );
};

InvestorPrismReportViewer.propTypes = {
  accounts: PropTypes.array.isRequired,
  accountSavedCharts: PropTypes.object.isRequired,
  benchmarks: PropTypes.array.isRequired,
  combinedPortfolios: PropTypes.object.isRequired,
  investor: PropTypes.object.isRequired,
  investorSavedCharts: PropTypes.object.isRequired,
  scenarios: PropTypes.array.isRequired,
  taxonomies: PropTypes.array,
  totalAssets: PropTypes.number.isRequired
};

InvestorPrismReportViewer.defaultProps = {
  taxonomies: []
};

export default connect(state => ({
  accountSavedCharts: state.accounts.charts,
  combinedPortfolios: combinedPortfoliosSelector(state),
  investorSavedCharts: state.investors.charts,
  taxonomies: state.market.taxonomies
}))(React.memo(InvestorPrismReportViewer));
