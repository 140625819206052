import cn from 'classnames';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback } from 'react';
import useSlider from '../hooks';
import '../styles.scss';

const RangeSlider = ({
  className,
  defaultValue,
  getTooltipContent,
  handleDisabled,
  labels,
  onChange,
  onSlide,
  size,
  withHandle,
  withTooltip
}) => {
  const { markToValue, sliderProps, valueToMark } = useSlider({
    getTooltipContent,
    handleDisabled,
    labels,
    size,
    withHandle,
    withTooltip
  });

  /* Handlers */
  const onSliderChange = useCallback(
    range => {
      const newValue = range.map(v => markToValue(v));
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <div className={cn(`range-slider-wrapper ${className}`, { '-withHandle': withHandle })}>
      <Range
        {...sliderProps}
        pushable={1}
        className="range"
        defaultValue={defaultValue.map(v => valueToMark(v))}
        onChange={onSlide}
        onAfterChange={onSliderChange}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  getTooltipContent: PropTypes.func.isRequired,
  handleDisabled: PropTypes.array,
  labels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSlide: PropTypes.func.isRequired,
  size: PropTypes.number,
  tooltip: PropTypes.shape({
    format: PropTypes.string,
    multiplier: PropTypes.number,
    unit: PropTypes.string
  }),
  withHandle: PropTypes.bool,
  withTooltip: PropTypes.bool
};

RangeSlider.defaultProps = {
  className: '',
  defaultValue: [],
  handleDisabled: [],
  size: 0,
  tooltip: null,
  withHandle: false,
  withTooltip: false
};

export default RangeSlider;
