import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import SignatureInput from 'components/form/signature-input';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import EditIcon from 'components/svg-icons/edit-icon';
import PropTypes from 'prop-types';
import Dropzone from 'components/dropzone-enhanced';
import './styles.scss';

const UploadSignature = ({ setUploadedFile }) => {
  const handleDrop = files => {
    setUploadedFile(files[0]);
  };

  return (
    <div>
      <p>…or import a signature image from your computer.</p>
      <div className="col-logo">
        <Dropzone onDrop={handleDrop} showPreviews maxFiles={1} height={160}>
          <div id="signature-dropzone-area" className="dropzone-area empty">
            Click to upload
            <small>Or drag file here</small>
          </div>
        </Dropzone>
      </div>
      <div>Recommended width 900px or higher</div>
      <div>File type .jpg .png</div>
    </div>
  );
};

const MeSignature = ({ signature, updateUserSignature, deleteUserSignature }) => {
  const [signatureUrl, setSignatureUrl] = useState(signature);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const signatureRef = useRef(null);
  const [submitHandDrawn, setSubmitHandDrawn] = useState(false);

  const onChangeSubmitSignStatus = status => () => {
    setSubmitHandDrawn(status);
  };

  const DeleteSignature = async () => {
    await deleteUserSignature();
    toast.success('👏 Your signature has been deleted');
    setSignatureUrl(signature);
  };

  const SaveChanges = async () => {
    setIsEditMode(false);

    if (submitHandDrawn) {
      const signatureDraw = signatureRef.current?.toDataURL();
      const blob = await fetch(signatureDraw).then(r => r.blob());
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      await updateUserSignature(file);
      toast.success('👏 Your signature has been saved');
    } else if (uploadedFile) {
      await updateUserSignature(uploadedFile);
      toast.success('👏 Your signature has been saved');
    }
  };

  const EnableEditMode = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    setSignatureUrl(signature);
  }, [signature]);

  return (
    <div id="me-signature">
      {/* HEADER */}
      <div>
        <h2>Digital Signature</h2>
        <p>
          Save your digital signature for easily sign IPS and other documents generated in StratiFi.
        </p>
      </div>

      {/* BODY */}
      <div className="me-signature-body">
        <div>
          {(!signatureUrl || isEditMode) && (
            <div className="me-signature-edit">
              <div>
                <p>Draw the signature in the box below…</p>
                <SignatureInput
                  onBeginStroke={onChangeSubmitSignStatus(true)}
                  onResetStroke={onChangeSubmitSignStatus(false)}
                  signatureRef={signatureRef}
                />
              </div>
              <UploadSignature setUploadedFile={setUploadedFile} />
            </div>
          )}
          {signatureUrl && !isEditMode && (
            <div id="signature-preview-container">
              <div id="signature-preview">
                <div className="signature-img-container">
                  <img className="signature-img" src={signatureUrl} alt="Preview" />
                </div>
                <button id="signature-preview-overlay" type="button" onClick={EnableEditMode}>
                  <div>Update your signature</div>
                </button>
              </div>

              <div className="buttons-options">
                <button
                  className="edit-signature-btn"
                  aria-label="Delete Sign"
                  onClick={EnableEditMode}
                  title="Delete Sign"
                  type="button"
                >
                  <EditIcon className="signature-edit-icon" title="Clear" />
                </button>
                <button
                  aria-label="Clear Sign"
                  className="delete-signature-btn btn btn-secondary"
                  onClick={DeleteSignature}
                  title="Clear"
                  type="button"
                >
                  <TrashCanIcon className="signature-icon" title="Clear" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* FOOTER */}
      <div className="actions">
        <button type="button" className="btn btn-primary" onClick={SaveChanges}>
          Save
        </button>
      </div>
    </div>
  );
};

MeSignature.propTypes = {
  signature: PropTypes.string,
  updateUserSignature: PropTypes.func.isRequired,
  deleteUserSignature: PropTypes.func.isRequired
};

MeSignature.defaultProps = {
  signature: null
};

UploadSignature.propTypes = {
  setUploadedFile: PropTypes.func.isRequired
};

export default MeSignature;
