import { AdvisorContext } from 'containers/advisor';
import { trackAmplitudeEvent } from 'utils/tracking';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ARCHIVED,
  ARCHIVED_NOTIFICATION_VERB,
  DELETED_NOTIFICATION_VERB,
  IPS_REPORT_TYPE,
  RESTORED_NOTIFICATION_VERB
} from './constants';
import ProposalOrIpsReportsList from './list';
import './styles.scss';

const bulkNotification = (type, verb) =>
  toast.success(`${type === IPS_REPORT_TYPE ? 'IPS' : 'Proposal'}s ${verb} successfully`);

const ProposalOrIpsReports = ({
  ipsReports,
  location: { pathname },
  params: { id: clientId },
  proposalReports,
  route: { type }
}) => {
  const { ipsProvider, proposalProvider } = useContext(AdvisorContext);

  const [loading, setLoading] = useState(false);

  const isProspect = pathname.includes('advisor/prospects');
  const provider = type === IPS_REPORT_TYPE ? ipsProvider : proposalProvider;
  const isIPS = type === IPS_REPORT_TYPE;

  useEffect(() => {
    provider.getReports(clientId, isProspect);
  }, [clientId, type]);

  const bulkDelete = async reportIds => {
    setLoading(true);
    const promises = [];
    reportIds.forEach(reportId => {
      const amplitudEvent = isIPS ? 'ips.deleted' : 'proposal.deleted';
      const amplitudePost = {
        id: reportId,
        investor: clientId,
        is_prospect: isProspect
      };
      trackAmplitudeEvent(amplitudEvent, amplitudePost);

      promises.push(provider.deleteReport(reportId, clientId, isProspect));
    });
    try {
      await Promise.all(promises);
      await provider.getReports(clientId, isProspect);
      bulkNotification(type, DELETED_NOTIFICATION_VERB);
    } finally {
      setLoading(false);
    }
  };

  const bulkUpdate = async (reportIds, data) => {
    setLoading(true);
    const promises = [];
    reportIds.forEach(reportId => {
      promises.push(provider.updateReport(data, reportId, clientId, isProspect));
    });
    try {
      await Promise.all(promises);
      await provider.getReports(clientId, isProspect);
      provider.clearCurrentReport();
      bulkNotification(
        type,
        data.status === ARCHIVED ? ARCHIVED_NOTIFICATION_VERB : RESTORED_NOTIFICATION_VERB
      );
    } finally {
      setLoading(false);
    }
  };

  const reports = type === IPS_REPORT_TYPE ? ipsReports[clientId] : proposalReports[clientId];

  return (
    <div id="proposal-or-ips-reports">
      <ProposalOrIpsReportsList
        bulkDelete={bulkDelete}
        bulkUpdate={bulkUpdate}
        loading={loading}
        reports={reports || []}
        type={type}
      />
    </div>
  );
};

ProposalOrIpsReports.propTypes = {
  ipsReports: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  proposalReports: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default connect(state => ({
  ipsReports: state.ips.reports,
  proposalReports: state.proposals.reports
}))(ProposalOrIpsReports);
