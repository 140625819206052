// MAIN CONSTANTS

export const TITLE = 'Teams';
export const CREATE_SUCCESS = 'Team created successfully';
export const EDIT_SUCCESS = 'Team edited successfully';
export const DELETE_SUCCESS = 'Team deleted successfully';
export const ERROR_MESSAGE = 'Somethig went wrong. Please try again later';

// MAIN TABLE

export const MAIN_ACTIONS_COLUMN = 'actions';
export const MAIN_DELETE_COLUMN = 'delete';
export const MAIN_EXPANDER_COLUMN = 'expander';
export const MAIN_EDIT_COLUMN = 'edit';

// MAIN TABLE EXPANDER

export const PRIMARY_MEMBER = 'Primary';
export const BUTTON_TEXT = 'Add member';
export const EMPTY_MEMBERS_MESSAGE = 'Your team is empty';
export const NO_DESCRIPTION = 'No description';
export const TEAM_DESCRIPTION = 'Team description:';
export const TEAM_MEMBERS = 'Team members:';

// MODAL EDIT

export const CANCEL_BUTTON = 'Cancel';
export const SAVE_BUTTON = 'Save';
export const PRIMARY_COLUMN = 'primary';
export const DELETE_COLUMN = 'remove';
export const REMOVE_MEMBER_ICON = 'Remove';
export const DELETE_BUTTON = 'Delete';
