import { BlobProvider } from '@react-pdf/renderer';
import cn from 'classnames';
import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReportViewer from '..';
import { PORTRAIT_ORIENTATION } from '../../constants';
import './styles.scss';

const ReportViewerModal = ({
  buttonClassName,
  buttonText,
  changeOrientation,
  fullyLoadedReportAssets,
  isInvalidTemplate,
  onHidden,
  orientation,
  report,
  showButton,
  showModal,
  url,
  onLoad
}) => {
  const [isShown, setIsShown] = useState(showModal);

  useEffect(() => {
    if (isShown !== showModal) setIsShown(showModal);
  }, [showModal]);

  const hide = () => {
    setIsShown(false);
    onHidden();
  };

  const show = () => {
    setIsShown(true);
  };

  return (
    <div id="report-viewer">
      {showButton && (
        <button
          type="button"
          aria-label="open-modal"
          onClick={show}
          className={cn(buttonClassName, 'pdf-report')}
          disabled={isInvalidTemplate || !fullyLoadedReportAssets}
        >
          <div>
            {isInvalidTemplate ? <span className="fs-icon-lock lock-icon" /> : null}
            {!fullyLoadedReportAssets ? `Loading ${buttonText} ...` : buttonText}
          </div>
        </button>
      )}

      {ReactDOM.createPortal(
        <Modal
          id="report-viewer-modal"
          className="report-viewer-modal modal-lg"
          show={isShown}
          onHidden={hide}
        >
          <ModalBody>
            <div className="report-viewer-modal__content">
              {url && (
                <ReportViewer
                  changeOrientation={changeOrientation}
                  height={700}
                  orientation={orientation}
                  url={url}
                  withCloseButton
                  onLoad={onLoad}
                />
              )}

              {!url && report && (
                <BlobProvider document={report}>
                  {({ url, loading, error }) => {
                    if (loading) return <p>Loading ...</p>;
                    if (error) return <p>Something went wrong while generating the report</p>;
                    return (
                      <ReportViewer
                        changeOrientation={changeOrientation}
                        height={700}
                        orientation={orientation}
                        url={url}
                        withCloseButton
                        onLoad={onLoad}
                      />
                    );
                  }}
                </BlobProvider>
              )}
            </div>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </div>
  );
};

ReportViewerModal.propTypes = {
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  changeOrientation: PropTypes.func,
  fullyLoadedReportAssets: PropTypes.bool,
  isInvalidTemplate: PropTypes.bool,
  onHidden: PropTypes.func,
  orientation: PropTypes.string,
  report: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showButton: PropTypes.bool,
  showModal: PropTypes.bool,
  url: PropTypes.string,
  onLoad: PropTypes.func
};

ReportViewerModal.defaultProps = {
  buttonClassName: 'btn btn-secondary',
  buttonText: 'Download Report',
  changeOrientation: () => {},
  fullyLoadedReportAssets: false,
  isInvalidTemplate: false,
  onHidden: () => {},
  orientation: PORTRAIT_ORIENTATION,
  report: null,
  showButton: true,
  showModal: false,
  url: null,
  onLoad: null
};

export default ReportViewerModal;
