import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { setPrecision } from 'utils/utils';
import SelectorRow from './selector-row';
import './styles.scss';
import {
  ACCOUNT_TYPE,
  MODEL_PORTFOLIO_TYPE,
  SECURITY_TYPE,
  WEIGHT_AS_AMOUNT_FIELD_WIDTH,
  getKey
} from './utils';

const WeightedPortfolioSelector = ({
  defaultValues,
  onChange,
  portfoliosTotalAmount,
  scope,
  type,
  withPercentages
}) => {
  const [data, setData] = useState({});
  const [showingEmptyRow, showEmptyRow] = useState(false);

  const getTotalWeightOrAmount = () =>
    setPrecision(
      Object.values(data).reduce((acum, i) => acum + i.weight, 0),
      2
    );

  const isEmptyData = _.isEmpty(data);
  const totalWeightOrAmount = withPercentages && isEmptyData ? 100 : getTotalWeightOrAmount();
  const error = withPercentages && totalWeightOrAmount !== 100;
  const numericFormatProps = withPercentages
    ? { suffix: '%' }
    : { prefix: '$', thousandSeparator: true };

  useEffect(() => {
    const defaultData = defaultValues
      ? defaultValues.reduce((acum, s) => ({ ...acum, [getKey(s)]: s }), {})
      : {};
    setData(defaultData);
  }, [JSON.stringify(defaultValues)]);

  const setSelection = (selection, source) => {
    const selections = { ...data };
    const value = { ...selection };

    // set the default weight depending on:
    // - if withPercentages is true: sets the weight to reach 100%
    // - if withPercentages is false:
    //   - sets the weight (amount in $) of the matching account amount (if applicable)
    //   - sets the weight (remaining amount in $) to reach the portfolios total amount ($) or 0
    if (!Number.isFinite(value.weight))
      value.weight = withPercentages
        ? setPrecision(Math.max(100 - getTotalWeightOrAmount(), 0), 2)
        : setPrecision(
            value.amount ?? Math.max(portfoliosTotalAmount - getTotalWeightOrAmount(), 0),
            2
          );

    // if there is `source`, it means that an existing selection is being modified, so it's
    // necessary to take its `amount` and `weight` to transfer it to the new selection
    if (selections[source]) {
      const prevSelection = selections[source];
      value.amount = prevSelection.amount;
      value.weight = prevSelection.weight;
      value.order = prevSelection.order;
      delete selections[source];
    }

    // add/update the current selection
    const key = getKey(value);
    const current = data[key] ?? { order: Object.keys(selections).length };
    selections[key] = { ...current, ...value };

    // save the data
    setData(selections);
    onChange(Object.values(selections));

    // hide the empty row if required
    if (!data[key]) showEmptyRow(false);
  };

  const unsetSelection = selection => {
    const selections = { ...data };
    const key = getKey(selection);
    delete selections[key];

    // save the data
    setData(selections);
    onChange(Object.values(selections));
  };

  const getPortfolioLabel = () => {
    if (type === ACCOUNT_TYPE) return 'Add an account';
    if (type === SECURITY_TYPE) return 'Add a benchmark';
    return 'Add a model';
  };

  const selectorProps = {
    error,
    onChange: setSelection,
    onRemove: unsetSelection,
    scope,
    type,
    withPercentages
  };

  return (
    <div className="weighted-portfolio-selector">
      {Object.keys(data)
        .sort((keyA, keyB) => data[keyA].order - data[keyB].order)
        .map(key => (
          <SelectorRow key={key} {...selectorProps} type={data[key].type} value={data[key]} />
        ))}

      {(isEmptyData || showingEmptyRow) && <SelectorRow {...selectorProps} />}

      <div className="add-position-row">
        <button className="btn btn-transparent" onClick={() => showEmptyRow(true)} type="button">
          <i className="icon-add_item" /> <span>{getPortfolioLabel()}</span>
        </button>
      </div>

      {Object.keys(data).length > 1 && (
        <div className={cn('total-row', { error })}>
          <div className="total-row__label">Total</div>
          <NumericFormat
            allowNegative={false}
            className="total-row__value"
            decimalScale={2}
            disabled
            style={withPercentages ? {} : { maxWidth: WEIGHT_AS_AMOUNT_FIELD_WIDTH }}
            value={totalWeightOrAmount}
            {...numericFormatProps}
          />
          {withPercentages && totalWeightOrAmount !== 100 && (
            <span className="total-row__error">
              {Math.abs(totalWeightOrAmount - 100).toFixed(2)}%{' '}
              {totalWeightOrAmount > 100 ? 'Overweight' : 'Underweight'}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

WeightedPortfolioSelector.propTypes = {
  defaultValues: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
  onChange: PropTypes.func.isRequired,
  portfoliosTotalAmount: PropTypes.number,
  scope: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  type: PropTypes.string,
  withPercentages: PropTypes.bool
};

WeightedPortfolioSelector.defaultProps = {
  defaultValues: null,
  portfoliosTotalAmount: 0,
  type: MODEL_PORTFOLIO_TYPE,
  withPercentages: true
};

export default WeightedPortfolioSelector;
