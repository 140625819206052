import PropTypes from 'prop-types';
import React from 'react';
import AnnualTaxableIncomeQuestionContent from '..';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import { useAnnualTaxableIncome } from '../hooks';

const AnnualTaxableIncomeQuestion = ({ field, question, ...props }) => {
  const annualTaxableIncomeProps = useAnnualTaxableIncome({ field, question });
  return (
    <Question
      {...props}
      question={{ ...question, question: annualTaxableIncomeProps.questionText }}
      nextDisabled={field.income.invalid || field.contribution.invalid}
    >
      <AnnualTaxableIncomeQuestionContent {...annualTaxableIncomeProps} />
    </Question>
  );
};

AnnualTaxableIncomeQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default AnnualTaxableIncomeQuestion;
