export const ARCHIVED = 'archived';
export const DRAFT = 'draft';
export const PUBLISHED = 'published';
export const REVIEW_REQUESTED = 'review_requested';
export const PARTIALLY_REVIEWED = 'partially_reviewed';
export const REVIEWED = 'reviewed';

export const isReportReadOnly = status =>
  status === PUBLISHED ||
  status === REVIEW_REQUESTED ||
  status === PARTIALLY_REVIEWED ||
  status === REVIEWED;

export const hasReportReviews = status =>
  status === REVIEW_REQUESTED || status === PARTIALLY_REVIEWED || status === REVIEWED;

export const PROPOSAL_REPORT_TYPE = 'proposal';
export const IPS_REPORT_TYPE = 'ips';

export const ARCHIVED_NOTIFICATION_VERB = 'archived';
export const DELETED_NOTIFICATION_VERB = 'deleted';
export const RESTORED_NOTIFICATION_VERB = 'restored';
