import React from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_2FA } from '../utils/constants';

const TwoFactorMethod = ({
  onDisable,
  onSetup,
  isMethodActive,
  isRecommended,
  methodTitle,
  methodDescription
}) => {
  const actionButton = value => (
    <button
      type="button"
      onClick={() => (isMethodActive ? onDisable() : onSetup())}
      className={`btn btn-${isMethodActive ? 'danger' : 'outline-primary'}`}
    >
      {value}
    </button>
  );

  const visualCueCard = () => {
    if (!isMethodActive && !isRecommended) return null;
    return (
      <span className="visual-cue" data-enabled={isMethodActive}>
        {isRecommended && !isMethodActive ? ACTIVE_2FA.VISUAL_CUE[0] : ACTIVE_2FA.VISUAL_CUE[1]}
      </span>
    );
  };

  return (
    <div className="method-container">
      <div className="content">
        <div className="status">
          <h3>{methodTitle}</h3>
          {visualCueCard()}
        </div>
        <p className="description">{methodDescription}</p>
      </div>
      <div className="actions">
        {actionButton(isMethodActive ? ACTIVE_2FA.DISABLED_BUTTON : ACTIVE_2FA.ENABLED_BUTTON)}
      </div>
    </div>
  );
};

TwoFactorMethod.defaultProps = {
  isRecommended: false,
  isMethodActive: false,
  onSetup: () => {},
  onDisable: () => {}
};

TwoFactorMethod.propTypes = {
  onSetup: PropTypes.func,
  onDisable: PropTypes.func,
  isRecommended: PropTypes.bool,
  isMethodActive: PropTypes.bool,
  name: PropTypes.string.isRequired,
  methodTitle: PropTypes.string.isRequired,
  methodDescription: PropTypes.string.isRequired
};

export default TwoFactorMethod;
