import DataProvider from './data';
import config from '../config';

class ActionProvider extends DataProvider {
  storeAction(data) {
    this.provider.post(`${config.apiBase}actions/`, data);
  }

  slack(data) {
    this.provider.post(`${config.apiBase}actions/slack/`, data);
  }
}

export default ActionProvider;
