import { formatPercentage } from 'utils/utils';

export const LARGE_TYPE = 'Large';
export const MID_TYPE = 'Mid';
export const SMALL_TYPE = 'Small';

export const VALUE_TYPE = 'Value';
export const CORE_TYPE = 'Core';
export const GROWTH_TYPE = 'Growth';

export const LARGE_VALUE = 'Large Value';
export const LARGE_CORE = 'Large Core';
export const LARGE_GROWTH = 'Large Growth';
export const MID_VALUE = 'Mid Value';
export const MID_CORE = 'Mid Core';
export const MID_GROWTH = 'Mid Growth';
export const SMALL_VALUE = 'Small Value';
export const SMALL_CORE = 'Small Core';
export const SMALL_GROWTH = 'Small Growth';

export const EMPTY_TILE = 'empty';
export const HEADER_TILE = 'header';
export const RESULT_TILE = 'result';
export const TYPE_TILE = 'type';

export const STYLE_BOX_RANGE_COLORS = [
  { min: 0, max: 0.1, background: '#DBF0FB', text: '#135775' },
  { min: 0.1, max: 0.25, background: '#B3E5FC', text: '#064A69' },
  { min: 0.25, max: 0.5, background: '#81D4FA', text: '#043C57' },
  { min: 0.5, max: 1, background: '#4FC3F7', text: '#032E42' }
];

export const getStyle = (data, name) => {
  const style = data.find(style => style.name === name);

  if (style) {
    const percentage = Math.round(style.percentage * 10000) / 10000;
    const color = STYLE_BOX_RANGE_COLORS.find(
      range => percentage >= range.min && percentage <= range.max
    );
    return {
      backgroundColor: style.background || color.background,
      color: style.text || color.text,
      display: formatPercentage(percentage, 100, 0),
      type: RESULT_TILE,
      value: percentage
    };
  }

  return {
    backgroundColor: '#ECEFF1',
    text: '#7E92A0',
    display: '-',
    type: RESULT_TILE,
    value: null
  };
};

export const getTiles = (data, isReport = false) => {
  const tiles = [
    [
      { display: '', type: EMPTY_TILE },
      { display: VALUE_TYPE, type: HEADER_TILE },
      { display: CORE_TYPE, type: HEADER_TILE },
      { display: GROWTH_TYPE, type: HEADER_TILE }
    ],
    [
      { display: LARGE_TYPE, type: TYPE_TILE },
      getStyle(data, LARGE_VALUE),
      getStyle(data, LARGE_CORE),
      getStyle(data, LARGE_GROWTH)
    ],
    [
      { display: MID_TYPE, type: TYPE_TILE },
      getStyle(data, MID_VALUE),
      getStyle(data, MID_CORE),
      getStyle(data, MID_GROWTH)
    ],
    [
      { display: SMALL_TYPE, type: TYPE_TILE },
      getStyle(data, SMALL_VALUE),
      getStyle(data, SMALL_CORE),
      getStyle(data, SMALL_GROWTH)
    ]
  ];
  return isReport ? tiles : tiles.flat(1);
};
