import PropTypes from 'prop-types';
import React from 'react';

const SignatureIcon = ({ className, style, title, ...rest }) => (
  <svg
    data-name="Signature"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
    viewBox="0 0 1024 1024"
    {...rest}
  >
    <title>{title}</title>
    <path d="M866.133333 836.266667c-6.4-4.266667-136.533333-102.4-445.866666-25.6-162.133333 40.533333-245.333333 21.333333-245.333334 21.333333-10.666667-2.133333-23.466667 4.266667-25.6 14.933333-2.133333 10.666667 4.266667 23.466667 14.933334 25.6 2.133333 0 25.6 6.4 68.266666 6.4 44.8 0 110.933333-6.4 196.266667-27.733333 285.866667-70.4 407.466667 17.066667 407.466667 17.066667 8.533333 6.4 23.466667 6.4 29.866666-4.266667 10.666667-6.4 8.533333-21.333333 0-27.733333zM153.6 755.2c2.133333 0 2.133333 2.133333 4.266667 2.133333h2.133333c2.133333 0 2.133333 0 4.266667 2.133334H170.666667l187.733333-44.8c2.133333 0 6.4-2.133333 8.533333-4.266667l484.266667-384-21.333333-27.733333 21.333333 27.733333c19.2-14.933333 21.333333-40.533333 6.4-59.733333l-74.666667-93.866667c-14.933333-19.2-40.533333-21.333333-59.733333-6.4l100.266667 125.866667-83.2 66.133333-74.666667-93.866667 83.2-66.133333L725.333333 166.4l-484.266666 384c-4.266667 2.133333-6.4 4.266667-6.4 6.4l-85.333334 170.666667v2.133333c0 2.133333 0 2.133333-2.133333 4.266667V740.266667c0 6.4 0 6.4 2.133333 8.533333 0 2.133333 2.133333 2.133333 2.133334 4.266667l2.133333 2.133333z m117.333333-174.933333l364.8-288 74.666667 93.866666-366.933333 288-138.666667 32 66.133333-125.866666z" />
  </svg>
);

SignatureIcon.defaultProps = {
  className: '',
  style: {},
  title: 'Signature'
};

SignatureIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string
};

export default SignatureIcon;
