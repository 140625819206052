import {
  INTEGRATION_COUNT_UNASSIGNED_ACCOUNTS,
  INTEGRATION_LIST_SYNCED_ACCOUNTS
} from 'constants/actions/integrations';
import config from '../config';
import DataProvider from './data';

class IntegrationElasticProvider extends DataProvider {
  countUnassignedSyncedAccounts() {
    return this.provider
      .get(`${config.apiBase}search/integration/`, { assigned: false })
      .then(({ data }) => {
        if (!data.error)
          this.dispatch(INTEGRATION_COUNT_UNASSIGNED_ACCOUNTS, { count: data.count });
        return data;
      });
  }

  listSyncedAccounts(params) {
    return this.provider
      .get(`${config.apiBase}search/integration/`, params, null, null, true)
      .then(({ data }) => {
        if (!data.error)
          this.dispatch(INTEGRATION_LIST_SYNCED_ACCOUNTS, {
            results: data.results || [],
            meta: { params, count: data.count, totalPages: data.total_pages }
          });
        else this.dispatch(INTEGRATION_LIST_SYNCED_ACCOUNTS, { results: [], meta: {} });
        return data;
      });
  }
}

export default IntegrationElasticProvider;
