import cn from 'classnames';
import { DriftBox, SortableHeader } from 'components/utils/react-table';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { EXCEPTION_STATUS } from 'utils/drift';
import { fromNow } from 'utils/utils';
import './styles.scss';

export const AccountExceptionsRow = ({
  id,
  exceptionsByInvestor,
  openNotesModal,
  headers,
  nonce,
  isClient
}) => {
  const { investorProvider } = useContext(AdvisorContext);

  useEffect(() => {
    if (isClient) investorProvider.getExceptions(id);
  }, [investorProvider, nonce, isClient]);

  const isSnoozedOrClosed = status =>
    [EXCEPTION_STATUS.SNOOZED, EXCEPTION_STATUS.CLOSED].includes(status);

  const columns = [
    {
      Header: <SortableHeader title="Status" />,
      id: 'status',
      minWidth: 140,
      accessor: ({ status }) => status,
      Cell: ({ original: exception }) => (
        <div className="status-col">
          <button
            type="button"
            className={cn('btn btn-transparent')}
            style={{ cursor: 'pointer' }}
            onClick={e => {
              openNotesModal(e, {
                accountId: exception.account.id,
                exceptionId: exception.id,
                investorId: exception.account.investor.id,
                tags: exception.tags
              });
            }}
          >
            {exception.status}
          </button>
        </div>
      )
    },
    {
      Header: <SortableHeader title="Account with exceptions" />,
      id: 'account-name',
      className: 'justify-left account-col',
      headerClassName: 'justify-left',
      minWidth: 280,
      accessor: ({ account }) => account.display_name,
      Cell: ({ original: { account, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>{account.display_name}</span>
      )
    },
    {
      Header: <SortableHeader title="Number" />,
      id: 'account-number',
      className: 'justify-left account-col',
      headerClassName: 'justify-left',
      minWidth: 140,
      accessor: ({ account }) => account.number,
      Cell: ({ original: { account, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>{account.number || '-'}</span>
      )
    },
    {
      Header: <SortableHeader title="Portfolio Value" />,
      id: 'account-value',
      minWidth: 180,
      accessor: ({ account }) => account.value,
      Cell: ({ original: { account, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>
          <FormattedNumber value={account.value} format="currency" />
        </span>
      )
    },
    {
      Header: <SortableHeader title="Drift" />,
      id: 'drift',
      className: 'drift-row',
      minWidth: 80,
      accessor: ({ account }) => account.drift_summary?.overall,
      Cell: ({
        original: {
          account: { drift_summary: driftSummary, is_healthy: isHealthy },
          status
        }
      }) => {
        const drift = driftSummary?.overall ?? null;
        return <DriftBox isHealthy={isHealthy} snoozed={status === 'Snoozed'} value={drift} />;
      }
    },
    {
      Header: <SortableHeader title="Age" />,
      id: 'age',
      minWidth: 140,
      accessor: ({ age }) => age,
      Cell: ({ original: { age, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>
          {_.isNil(age) ? '-' : `${age} days old`}
        </span>
      )
    },
    {
      Header: <SortableHeader title="Last updated" />,
      id: 'modified',
      minWidth: 140,
      accessor: ({ modified }) => modified,
      Cell: ({ original: { modified, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>{fromNow(modified)} ago</span>
      )
    }
  ];

  return (
    <ReactTable
      className="exception-row"
      columns={columns}
      data={exceptionsByInvestor[id]}
      getTheadProps={() => (headers ? {} : { style: { display: 'none' } })}
      minRows={1}
      noDataText="No Exceptions"
      showPagination={false}
      showPageSizeOptions={false}
      sortable={headers}
    />
  );
};

AccountExceptionsRow.propTypes = {
  exceptionsByInvestor: PropTypes.object,
  headers: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isClient: PropTypes.bool,
  nonce: PropTypes.number,
  openNotesModal: PropTypes.func.isRequired
};

AccountExceptionsRow.defaultProps = {
  exceptionsByInvestor: {},
  headers: false,
  nonce: 0,
  isClient: true
};

export default connect(state => ({
  exceptionsByInvestor: state.investors.exceptions
}))(AccountExceptionsRow);
