import { FormGroup, VerboseErrorInput } from 'components/form';
import PasswordHelper from 'components/password-helper';
import { AuthenticationContext } from 'containers/auth';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validate = values => {
  const errors = {};

  errors.uid = errors.uid || validation.required(values.uid);
  errors.token = errors.token || validation.required(values.token);
  errors.new_password1 = errors.new_password1 || validation.required(values.new_password1);
  errors.new_password2 = errors.new_password2 || validation.required(values.new_password2);

  if (
    values.new_password1 &&
    values.new_password2 &&
    values.new_password1 !== values.new_password2
  ) {
    errors.new_password1 = errors.new_password1 || 'Password mismatch';
    errors.new_password2 = errors.new_password2 || 'Password mismatch';
  }

  return errors;
};

const PasswordResetConfirm = ({
  fields,
  handleSubmit,
  invalid,
  nextStep,
  registerError,
  resetForm,
  submitting
}) => {
  const [invalidToken, setInvalidToken] = useState(false);
  const [passwordIsSecure, setPasswordIsSecure] = useState(false);

  const { authProvider } = useContext(AuthenticationContext);

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit(values => {
      authProvider
        .resetPasswordConfirm(values)
        .then(({ error }) => {
          if (error) {
            setInvalidToken(true);
            if (error.errors && (error.errors.token || error.errors.uid))
              toast.error(() => (
                <div>Your request could not be processed. Try resetting your password again.</div>
              ));
            return Promise.reject(error.errors);
          }
          nextStep();
          return Promise.resolve();
        })
        .catch(registerError)
        .finally(() => {
          resetForm();
        });
    })(event);
  };

  return (
    <div>
      <h1 className="text-sm-center signup-title mt-3 mb-1">Set new password</h1>
      <p className="mb-2">Then set new password twice.</p>
      <form autoComplete="off" onSubmit={onSubmit}>
        <FormGroup className="form-group" {...fields.new_password1}>
          <label>New password</label>
          <VerboseErrorInput
            type="password"
            className="form-control sign-up-field"
            {...fields.new_password1}
            placeholder="Enter new password"
          />
        </FormGroup>
        <PasswordHelper
          value={fields.new_password1.value}
          onValid={bool => {
            setPasswordIsSecure(bool);
          }}
        />
        <FormGroup className="form-group" {...fields.new_password2}>
          <label>Repeat new password</label>
          <VerboseErrorInput
            type="password"
            className="form-control sign-up-field"
            {...fields.new_password2}
            placeholder="Enter new password one more time"
          />
        </FormGroup>

        {invalidToken && (
          <div className="token-error-message">
            <p>This token is invalid or was already used.</p>
          </div>
        )}

        <div className="center-block pt-2">
          <button
            type="submit"
            className="center-block btn btn-primary btn-block"
            disabled={invalid || submitting || !passwordIsSecure}
          >
            {submitting ? 'Processing...' : 'Reset password'}
          </button>
        </div>
      </form>
    </div>
  );
};

PasswordResetConfirm.contextTypes = {
  authProvider: PropTypes.object.isRequired
};

PasswordResetConfirm.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'resetPassword',
  fields: ['uid', 'token', 'new_password1', 'new_password2'],
  initialValues: {},
  enableReinitialize: true,
  validate
})(BackendValidation(PasswordResetConfirm));
