import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useUpsideDownsideData from './hooks';
import './styles.scss';

export const THEME = {
  default: 'default',
  proposal: 'proposal'
};

const Header = ({ columns, scale, withLabels }) => {
  const createHeaderGridColumns = (columns, even = true) =>
    Array.from(Array(columns)).map((c, i, arr) => {
      const center = Math.round(columns / 2);
      const styles = even && i !== 0 && i !== arr.length - 1 ? { gridColumn: 'span 2' } : {};

      let value = `${(i + 1 - center) * 10}%`;
      if (i + 1 < center) value = `-${(center - (i + 1)) * 10}%`;
      if (i + 1 === center) value = 0;

      return (
        <div key={`udc-header-${value}`} style={styles}>
          {value}
        </div>
      );
    });

  return (
    <>
      {withLabels && <div />}
      <div
        className={cn('content__header', {
          'header--even': columns % 2 === 0,
          'header--odd': columns % 2 !== 0
        })}
        style={{
          width: scale * 2,
          gridTemplateColumns: `repeat(${columns % 2 === 0 ? columns * 2 : columns}, 1fr)`
        }}
      >
        {columns % 2 === 0
          ? createHeaderGridColumns(columns + 1)
          : createHeaderGridColumns(columns, false)}
      </div>
    </>
  );
};

Header.propTypes = {
  columns: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  withLabels: PropTypes.bool.isRequired
};

export const UpsideDownsideChart = ({
  benchmark,
  benchmarkName,
  target,
  targetName,
  recommended,
  recommendedName,
  theme,
  scale,
  withLabels,
  withLegend,
  withRecommended
}) => {
  const {
    gridColumnsNumber,
    spyPerformance,
    targetPerformance,
    recommendedPerformance,
    benchmarkPerformance
  } = useUpsideDownsideData({ benchmark, recommended, target });

  const contentMaxWidth = withLabels ? {} : { maxWidth: scale * 2 };
  const gridTemplateColumns = withLabels ? '135px repeat(2, 1fr)' : 'repeat(2, 1fr)';
  const recommendedBar = recommended ? recommendedPerformance : spyPerformance;
  const useProposalTheme = theme === THEME.proposal;

  // the index is used as key since this component is only responsible for rendering the
  // chart guides and there's no attribute that can be used as id
  const createGridColumns = number => Array.from(Array(number)).map((_, i) => <div key={i} />);

  return (
    <div id="upside-downside-chart" style={contentMaxWidth}>
      {withLegend && (
        <div className="upside-downside-chart__legend">
          <div className="legend__box box__target" />
          <div className="legend__title">{targetName || 'Current portfolio'}</div>
          {withRecommended && (
            <>
              <div className="legend__box box__recommended" />
              <div className="legend__title">
                {recommended ? recommendedName || 'Model' : 'S&P 500'}
              </div>
            </>
          )}
          {benchmarkPerformance && (
            <>
              <div className="legend__box box__benchmark" />
              <div className="legend__title">{benchmarkName || 'Benchmark'}</div>
            </>
          )}
        </div>
      )}
      <div className="upside-downside-chart__content" style={{ gridTemplateColumns }}>
        <Header columns={gridColumnsNumber} scale={scale} withLabels={withLabels} />

        {withLabels && (
          <div className="content__labels">
            <p>{targetName || 'Current portfolio'}</p>
            {withRecommended && <p>{recommended ? recommendedName || 'Model' : 'S&P 500'}</p>}
            {benchmarkPerformance && <p>{benchmarkName || 'Benchmark'}</p>}
          </div>
        )}

        <div
          className="content__downside"
          style={{ width: scale, gridTemplateColumns: `repeat(${gridColumnsNumber}, 1fr)` }}
        >
          {createGridColumns(gridColumnsNumber)}
          <div className="downside__bars">
            <div
              className={cn('target-bar', { 'proposal-theme': useProposalTheme })}
              style={{ width: `${targetPerformance.down * 100}%` }}
            />
            {withRecommended && (
              <div
                className={cn('recommended-bar', { 'proposal-theme': useProposalTheme })}
                style={{ width: `${recommendedBar.down * 100}%` }}
              />
            )}
            {benchmarkPerformance && (
              <div
                className={cn('benchmark-bar', { 'proposal-theme': useProposalTheme })}
                style={{ width: `${benchmarkPerformance.down * 100}%` }}
              />
            )}
          </div>
        </div>

        <div
          className="content__upside"
          style={{ width: scale, gridTemplateColumns: `repeat(${gridColumnsNumber}, 1fr)` }}
        >
          {createGridColumns(gridColumnsNumber)}
          <div className="upside__bars">
            <div
              className={cn('target-bar', { 'proposal-theme': useProposalTheme })}
              style={{ width: `${targetPerformance.up * 100}%` }}
            />
            {withRecommended && (
              <div
                className={cn('recommended-bar', { 'proposal-theme': useProposalTheme })}
                style={{ width: `${recommendedBar.up * 100}%` }}
              />
            )}
            {benchmarkPerformance && (
              <div
                className={cn('benchmark-bar', { 'proposal-theme': useProposalTheme })}
                style={{ width: `${benchmarkPerformance.up * 100}%` }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UpsideDownsideChart.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  scale: PropTypes.number,
  target: PropTypes.object.isRequired,
  targetName: PropTypes.string,
  theme: PropTypes.string,
  withLabels: PropTypes.bool,
  withLegend: PropTypes.bool,
  withRecommended: PropTypes.bool
};

UpsideDownsideChart.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  recommended: null,
  recommendedName: '',
  scale: 220,
  targetName: '',
  theme: THEME.default,
  withLabels: false,
  withLegend: false,
  withRecommended: true
};

export default UpsideDownsideChart;
