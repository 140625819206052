import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import TooltipV2 from 'components/tooltip-v2';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const ProposalFormPublishModal = ({
  buttonDisabled,
  label,
  onPublish,
  reportType,
  title,
  tooltipText
}) => {
  const [isShown, setIsShown] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const handleOnSubmit = async () => {
    setSubmitting(true);
    await onPublish();
    setSubmitting(false);
    hide();
  };

  return (
    <>
      <TooltipV2 place="bottom" id="publish-report-tooltip" label={tooltipText}>
        <div data-for="publish-report-tooltip" data-tip="">
          <button
            className="btn btn-success"
            disabled={buttonDisabled}
            onClick={show}
            type="button"
          >
            Publish
          </button>
        </div>
      </TooltipV2>

      {ReactDOM.createPortal(
        <Modal id="publish-proposal-form-modal" className="modal-lg" show={isShown} onHidden={hide}>
          <ModalHeader />
          <ModalBody>
            <h3 className="modal-title">{title}</h3>
            <div className="description top">
              <div className="text">
                Once published, the {reportType} will be locked. You will only have access to the
                PDF version and cannot add or edit any part of it.
              </div>
              <div className="text mt-4">
                Are you sure you want to publish <span className="font-weight-bold">{label}</span>?
              </div>
              <div className="text text__warning padding">
                <span className="fs-icon-exclamation-circle warning-icon" />
                This action cannot be undone
              </div>
            </div>
            <div className="text-sm-center actions">
              <button type="button" className="btn cancel" onClick={hide}>
                Cancel
              </button>
              <LoadingButton
                className="btn btn-primary"
                loading={submitting}
                onClick={handleOnSubmit}
              >
                Publish
              </LoadingButton>
            </div>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

ProposalFormPublishModal.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onPublish: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired
};

export default ProposalFormPublishModal;
