/* eslint-disable jsx-a11y/anchor-is-valid */
import PermissionScopeWarning from 'components/permission-scope-warning';
import { questionFlavors as qf, questionTypes as qt } from 'constants/questionnaire';
import AddressReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/address/read-only';
import AnnualTaxableIncomeReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/annual-taxable-income/read-only';
import BirthdayRetirementReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/birthday-retirement/read-only';
import CashInvestableAssetsReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/cash-investable-assets/read-only';
import ChoicesReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/choices/read-only';
import ExpectedReturnReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/expected-return/read-only';
import GoalApproachReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/goal-approach/read-only';
import LossReactionReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/loss-reaction/read-only';
import NumericReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/numeric/read-only';
import PortfolioConcentrationReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/portfolio-concentration/read-only';
import RangeReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/range/read-only';
import SectionSeparatorReadOnly from 'containers/risk-tolerance-questionnaire/question/section-separator/read-only';
import SingleYearOutcomeReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/single-year-outcome/read-only';
import TextReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/text/read-only';
import TolerableLossReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/tolerable-loss/read-only';
import ValueRequirementReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/value-requirement/read-only';
import WithdrawalRequirementReadOnlyQuestion from 'containers/risk-tolerance-questionnaire/question/withdrawal-requirement/read-only';
import withRiskToleranceForm from 'containers/risk-tolerance-questionnaire/questions/form';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const getComponentForQuestion = (type, flavor) => {
  if (type === qt.CUSTOM) {
    if (flavor === qf.ADDRESS) return AddressReadOnlyQuestion;
    if (flavor === qf.ANNUAL_TAXABLE_INCOME) return AnnualTaxableIncomeReadOnlyQuestion;
    if (flavor === qf.BIRTHDAY_RETIREMENT) return BirthdayRetirementReadOnlyQuestion;
    if (flavor === qf.CASH_INVESTABLE_ASSETS) return CashInvestableAssetsReadOnlyQuestion;
    if (flavor === qf.EXPECTED_RETURN) return ExpectedReturnReadOnlyQuestion;
    if (flavor === qf.GOAL_APPROACH) return GoalApproachReadOnlyQuestion;
    if (flavor === qf.LOSS_REACTION) return LossReactionReadOnlyQuestion;
    if (flavor === qf.PORTFOLIO_CONCENTRATION) return PortfolioConcentrationReadOnlyQuestion;
    if (flavor === qf.SINGLE_YEAR_OUTCOME) return SingleYearOutcomeReadOnlyQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return TolerableLossReadOnlyQuestion;
    if (flavor === qf.VALUE_REQUIREMENT) return ValueRequirementReadOnlyQuestion;
    if (flavor === qf.WITHDRAWAL_REQUIREMENT) return WithdrawalRequirementReadOnlyQuestion;
  }
  if (type === qt.LEGACY) {
    if (flavor === qf.BIRTHDAY_RETIREMENT) return BirthdayRetirementReadOnlyQuestion;
    if (flavor === qf.EXPECTED_RETURN) return ExpectedReturnReadOnlyQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return TolerableLossReadOnlyQuestion;
  }
  if (type === qt.CHOICE) return ChoicesReadOnlyQuestion;
  if (type === qt.NUMERIC) return NumericReadOnlyQuestion;
  if (type === qt.RANGE) return RangeReadOnlyQuestion;
  if (type === qt.SECTION) return SectionSeparatorReadOnly;
  if (type === qt.TEXT) return TextReadOnlyQuestion;
  return null;
};

const renderQuestion = question => {
  const QuestionComponent = getComponentForQuestion(question.question_type, question.data?.flavor);
  if (QuestionComponent) return <QuestionComponent question={question} />;
  return null;
};

const RiskToleranceQuestionnaireReadOnly = ({ account, questions }) => (
  <div className="risk-tolerance-questionnaire-read-only">
    <div className="questions-wrapper">
      {account && <PermissionScopeWarning account={account} />}
      {questions.map(question => (
        <div key={question.id} className="risk-tolerance-questionnaire-read-only__container">
          {renderQuestion(question)}
        </div>
      ))}
    </div>
  </div>
);

RiskToleranceQuestionnaireReadOnly.defaultProps = {
  account: null
};

RiskToleranceQuestionnaireReadOnly.propTypes = {
  account: PropTypes.object,
  questions: PropTypes.array.isRequired
};

export default withRiskToleranceForm({ form: 'risk-tolerance-questionnaire-read-only' })(
  RiskToleranceQuestionnaireReadOnly
);
