import config from 'app/config';
import classnames from 'classnames';
import RiskGaugeChart from 'components/charts/risk-gauge-chart';
import PropTypes from 'prop-types';
import React from 'react';
import { isNum, numToRiskScaleString } from 'utils/utils';
import './styles.scss';

const SpeedMeter = ({
  id,
  score,
  scoreDisplay,
  target,
  options,
  compact,
  small,
  smaller,
  style,
  toValue
}) => {
  if (!isNum(score)) return 'Portfolio without score';

  const scoreOverall = Math.round(score);

  const targetOverall = target ? Math.round(target) : null;

  const commonParams = {
    id,
    value: scoreOverall,
    ...{ ...(small && { radius: 70, innerRadius: 54 }) },
    ...{
      ...(smaller && {
        radius: 56,
        innerRadius: 43,
        arrowRadius: '35%',
        arrowNailRadius: 5,
        arrowStartWith: 10
      })
    },
    ...options
  };
  const { targetScoreResult } = config.chart.gauge;
  const withoutTargetParams = {
    options: {
      ...targetScoreResult,
      allLabels: [
        { ...targetScoreResult.allLabels[0] },
        {
          ...targetScoreResult.allLabels[1],
          text: toValue
        }
      ]
    }
  };

  return (
    <div
      className={classnames('portfolio-risk-container', {
        compact,
        small: small || smaller,
        smaller
      })}
    >
      <div className="portfolio-risk-container__speedometer">
        <RiskGaugeChart {...commonParams} {...withoutTargetParams} style={style} />
      </div>
      <div
        className={`portfolio-risk-container__score-representation score-representation-${Math.ceil(
          score
        )} score-representation-full-prism  `}
      >
        <div className={`overall-prism-full prism-${targetOverall ? 'with' : 'without'}-target`}>
          <div className={`number ${!compact ? 'custom-number' : ''}`}>{`${scoreDisplay} `}</div>

          <div className="text">{numToRiskScaleString(score)}</div>
        </div>
      </div>
    </div>
  );
};

SpeedMeter.defaultProps = {
  compact: false,
  id: null,
  noLabels: false,
  options: {},
  small: false,
  smaller: false,
  style: null,
  target: null
};

SpeedMeter.propTypes = {
  compact: PropTypes.bool,
  id: PropTypes.string,
  noLabels: PropTypes.bool,
  options: PropTypes.object,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scoreDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  small: PropTypes.bool,
  smaller: PropTypes.bool,
  style: PropTypes.object,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  toValue: PropTypes.number.isRequired
};

export default SpeedMeter;
