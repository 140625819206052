// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TargetScoreWizard .modal-dialog {
  min-width: 800px;
}
#TargetScoreWizard .modal-dialog.modal-dialog--action-email {
  width: 75vw;
}
#TargetScoreWizard .modal-dialog--action-manual .modal-content {
  padding: 1rem 3rem;
}
#TargetScoreWizard .modal-body {
  padding: 1rem 0;
}
#TargetScoreWizard .modal-content {
  padding: 0;
}
#TargetScoreWizard .modal-content .close {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 10px;
}

@media print {
  #TargetScoreWizard {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/target-score-wizard/styles.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAKE;EACE,WAAA;AAHJ;AAOI;EACE,kBAAA;AALN;AASE;EACE,eAAA;AAPJ;AAUE;EACE,UAAA;AARJ;AAUI;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AARN;;AAaA;EACE;IACE,wBAAA;EAVF;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#TargetScoreWizard {\n  .modal-dialog {\n    min-width: 800px;\n  }\n\n  .modal-dialog.modal-dialog--action-email {\n    width: 75vw;\n  }\n\n  .modal-dialog--action-manual {\n    .modal-content {\n      padding: 1rem 3rem;\n    }\n  }\n\n  .modal-body {\n    padding: 1rem 0;\n  }\n\n  .modal-content {\n    padding: 0;\n\n    .close {\n      position: absolute;\n      z-index: 999;\n      right: 10px;\n      top: 10px;\n    }\n  }\n}\n\n@media print {\n  #TargetScoreWizard {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
