import React from 'react';

export default ({ className }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.04 38.03"
    className={className}
  >
    <defs>
      <style>{'.cancel-cls-1{fill: #afafaf;}'}</style>
    </defs>
    <title>Close_button</title>
    <path
      className="cancel-cls-1"
      d="M80.43,53.57a19,19,0,1,0,0,26.89A19,19,0,0,0,80.43,53.57ZM79.3,79.34a17.43,17.43,0,1,1,0-24.65A17.45,17.45,0,0,1,79.3,79.34Z"
      transform="translate(-47.96 -48)"
    />
    <polygon
      className="cls-1"
      points="26.72 10.24 19 17.94 11.32 10.28 10.24 11.35 17.92 19.02 10.24 26.68 11.32 27.75 19 20.09 26.72 27.79 27.8 26.71 20.08 19.02 27.8 11.32 26.72 10.24"
    />
  </svg>
);
