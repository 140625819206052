import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ProgressBar = ({ completed, fillerColor }) => (
  <div className="bar">
    <div className="bar__filler" style={{ width: `${completed}%`, backgroundColor: fillerColor }} />
  </div>
);

ProgressBar.defaultProps = {
  fillerColor: '#0bd318'
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  fillerColor: PropTypes.string
};

export default ProgressBar;
