import React from 'react';
import './index.scss';

const Checkbox = props => (
  <div className="input-checkbox-container">
    <input type="checkbox" {...props} />
    <span className="input-checkbox-container__checkmark" />
  </div>
);

export default Checkbox;
