import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormGroup, VerboseErrorInput } from 'components/form';
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';

const RISK_RED_COLOR = '#d6353c';

const STRIPE_CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      fontWeight: 400,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased'
    },
    invalid: {
      iconColor: RISK_RED_COLOR,
      color: RISK_RED_COLOR
    }
  }
};

const CheckoutPaymentForm = ({ getPaymentMethodPromise, showCardHolderField }) => {
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  const { user } = useContext(AuthenticationContext);

  const stripe = useStripe();
  const elements = useElements();

  const billingDetails = {
    email: user.email,
    phone: user.advisor.phone,
    name: `${user.first_name} ${user.last_name}`
  };

  const handleChange = event => {
    setError(event.error);
    setCardComplete(event.complete);
  };

  const paymentMethodPromise = () =>
    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

  useEffect(() => {
    // stripe card element autofocus
    if (elements && elements.getElement('card')) {
      const cardElement = elements.getElement('card');
      cardElement.on('ready', () => {
        cardElement.focus();
      });
    }
  }, []);

  useEffect(() => {
    if (cardComplete) {
      if (error) {
        elements.getElement('card').focus();
        return;
      }
      getPaymentMethodPromise(paymentMethodPromise);
    } else getPaymentMethodPromise(null);
  }, [cardComplete]);

  return (
    <div id="CheckoutPaymentForm">
      {showCardHolderField && (
        <FormGroup className="form-group">
          <label>Card holder name</label>
          <VerboseErrorInput
            type="text"
            className="form-control sign-up-field card-holder-name-input"
            placeholder="Card holder name"
            name="card-holder-name"
            value={billingDetails.name}
            disabled
          />
        </FormGroup>
      )}
      <CardElement
        className="sign-up-field"
        options={STRIPE_CARD_ELEMENT_OPTIONS}
        onChange={handleChange}
      />
      <div className="payment-form-error-message">
        <p>{error && error.message}</p>
      </div>
    </div>
  );
};

CheckoutPaymentForm.contextTypes = {
  user: PropTypes.object.isRequired
};

CheckoutPaymentForm.defaultProps = {
  showCardHolderField: false
};

CheckoutPaymentForm.propTypes = {
  getPaymentMethodPromise: PropTypes.func.isRequired,
  showCardHolderField: PropTypes.bool
};

export default CheckoutPaymentForm;
