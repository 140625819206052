import arrowDownIcon from 'assets/img/icons/arrow-down-white.svg';
import cn from 'classnames';
import { AdvisorContext } from 'containers/advisor';
import DiscussionTopicsTop from 'containers/risk-tolerance-questionnaire/result/discussion-topics/top';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { sanitizeUrl } from 'utils/utils';
import validator from 'validator';
import './styles.scss';

const HIDE_DISCUSSION_TOPICS = 'hideDiscussionTopics';

const DiscussionTopicsToggleable = ({ discussionTopics }) => {
  const { user } = useContext(AdvisorContext);

  const [hideDiscussionTopics, setHideDiscussionTopics] = useState(
    window.localStorage && window.localStorage.getItem(HIDE_DISCUSSION_TOPICS) === 'true'
  );

  const toggleDiscussionTopics = () => {
    setHideDiscussionTopics(prevHideDiscussionTopics => {
      if (window.localStorage)
        window.localStorage.setItem(HIDE_DISCUSSION_TOPICS, !prevHideDiscussionTopics);
      return !prevHideDiscussionTopics;
    });
  };

  if (!discussionTopics.length) return null;

  const meetingCalendarLink = sanitizeUrl(user.advisor.meeting_calendar_link);

  return (
    <div className="accordion" id="discussion-topics-toggleable">
      <div className="card">
        <div className="card-header" id="discussion-topics-header">
          <p className="font-weight-bold mb-0">Topics to discuss with the investor</p>
          <div className="card-header__actions">
            {validator.isURL(meetingCalendarLink) && (
              <a
                className="btn btn-outline-primary font-weight-normal"
                href={meetingCalendarLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                See my calendar
              </a>
            )}
            <button
              aria-controls="discussion-topics-content"
              aria-expanded="false"
              className={cn('btn', 'btn-secondary', 'font-weight-normal', {
                collapsed: hideDiscussionTopics
              })}
              data-target="#discussion-topics-content"
              onClick={toggleDiscussionTopics}
              type="button"
            >
              View Topics
              <img src={arrowDownIcon} className="arrow-icon" alt="arrow-icon" />
            </button>
          </div>
        </div>

        <div
          aria-labelledby="discussion-topics-header"
          className={cn('collapse', { show: !hideDiscussionTopics })}
          data-parent="#discussion-topics-toggleable"
          id="discussion-topics-content"
        >
          <div className="card-body">
            <DiscussionTopicsTop discussionTopics={discussionTopics} />
          </div>
        </div>
      </div>
    </div>
  );
};

DiscussionTopicsToggleable.defaultProps = {
  discussionTopics: []
};

DiscussionTopicsToggleable.propTypes = {
  discussionTopics: PropTypes.array
};

export default DiscussionTopicsToggleable;
