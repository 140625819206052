/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import arrowDownIcon from 'assets/img/icons/arrow-down-white.svg';
import cn from 'classnames';
import Tooltip, { Directions } from 'components/tooltip';
import DisplayScore from 'components/utils/DisplayScore';
import { AdvisorContext } from 'containers/advisor';
import RiskToleranceQuestionnaireReadOnly from 'containers/risk-tolerance-questionnaire/questionnaire/read-only';
import RiskToleranceQuestionnaireSingleReportViewer from 'containers/risk-tolerance-questionnaire/report/single/viewer';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router';
import './styles.scss';

const MANUAL_RISK_TOLERANCE_TOOLTIP = {
  text: 'Risk Tolerance manually entered',
  direction: Directions.TOP,
  className: 'manual-score-tutorial-tip-account'
};

const ToggableAccountQuestionnaire = ({
  account,
  account: { target_score_manual: targetScoreManual },
  defaultExpanded,
  idx,
  investorId,
  questionnaire
}) => {
  const { showTargetScoreWizard } = useContext(AdvisorContext);

  const [expanded, setExpanded] = useState(defaultExpanded);

  const toggleContent = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="toggable-account-questionnaire">
      {idx && <div className="counter">{idx}</div>}
      <div className="toggable-header">
        <div className="score-div">
          <div className="score">
            {!_.isEmpty(account.target_score_summary) && (
              <DisplayScore
                score={account.target_score_summary.overall}
                manualScore={account.target_score_manual}
                tooltip={account.target_score_manual ? MANUAL_RISK_TOLERANCE_TOOLTIP : undefined}
              />
            )}
          </div>
          <div className="name">
            <Link to={`/advisor/investors/${investorId}/account/${account.id}`}>
              {account.display_name}
            </Link>
          </div>
          <span className="updated">
            {account.target_score_updated_at && !account.target_score_manual
              ? ` - Last updated: ${moment.utc(account.target_score_updated_at).fromNow()}`
              : ' - Risk tolerance questionnaire not answered'}
          </span>
        </div>

        <div className="info">
          {account.target_score_manual ? (
            <span className="actions">
              <Tooltip
                text="Tolerance manually entered"
                direction={Directions.BOTTOM}
                style={{ fontSize: 13, width: 200, height: 38, paddingTop: 10, opacity: 0.8 }}
              >
                <button type="button" onClick={toggleContent} className="btn btn-secondary">
                  View answers
                  <img
                    src={arrowDownIcon}
                    className={cn('arrow-icon', { expanded })}
                    alt="arrow-icon"
                  />
                </button>
              </Tooltip>
            </span>
          ) : (
            <span className="actions">
              <RiskToleranceQuestionnaireSingleReportViewer
                account={account}
                questionnaire={questionnaire}
              />
              <button type="button" className="btn btn-secondary" onClick={toggleContent}>
                View answers
                <img
                  src={arrowDownIcon}
                  className={cn('arrow-icon', { expanded })}
                  alt="arrow-icon"
                />
              </button>
            </span>
          )}
        </div>
      </div>

      <div className={cn('toggable-content', { expanded })}>
        {questionnaire.questions && (
          <div className="questionnaire">
            {(targetScoreManual || _.isEmpty(account.target_score_summary)) && (
              <div className="heading">
                Form Answers
                <span className="send-email">
                  <span className="fs-icon-exclamation-circle warning-icon" />
                  Risk tolerance questionnaire was not answered for this account. You can fill out
                  here or{' '}
                  <span className="send-email-btn cta" onClick={() => showTargetScoreWizard()}>
                    send to the client.
                  </span>
                </span>
              </div>
            )}
            <RiskToleranceQuestionnaireReadOnly
              account={account}
              form={`risk-tolerance-questionnaire-read-only__account-${account.id}`}
              questions={questionnaire.questions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ToggableAccountQuestionnaire.defaultProps = {
  defaultExpanded: false,
  idx: null
};

ToggableAccountQuestionnaire.propTypes = {
  account: PropTypes.object.isRequired,
  defaultExpanded: PropTypes.bool,
  idx: PropTypes.number,
  investorId: PropTypes.number.isRequired,
  questionnaire: PropTypes.object.isRequired
};

export default ToggableAccountQuestionnaire;
