export const ALLOWED_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];
export const ALLOWED_IMAGE_FILE_SIZE = 1048576;
export const ALLOWEB_KEY_CODES = [8, 46, 37, 38, 39, 40];

export const BASE_REACT_SUMMERNOTE_OPTIONS = {
  height: 360,
  dialogsInBody: true,
  toolbar: [
    ['style', ['style']],
    ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
    ['fontsize', ['fontsize']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture']],
    ['view', ['codeview']],
    ['misc', ['undo', 'redo']]
  ],
  popover: {
    image: [
      ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter']],
      ['remove', ['removeMedia']]
    ],
    link: [['link', ['linkDialogShow', 'unlink']]],
    table: [
      ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
      ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
    ]
  },
  // This only allows to show the max filesize on Insert Image modal.
  // The handling of the upload and validation of the images must be done
  // manually using onImageUpload since the library for React does not have
  // this functionality
  maximumImageFileSize: ALLOWED_IMAGE_FILE_SIZE
};
