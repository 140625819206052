import PropTypes from 'prop-types';

const ProposalOrIpsReportsPropTypes = PropTypes.shape({
  created_by: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  investor: PropTypes.number.isRequired,
  ips: PropTypes.number,
  last_updated_by: PropTypes.object.isRequired,
  last_uploaded_by: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  pdf_report: PropTypes.string.isRequired,
  proposal: PropTypes.number,
  status: PropTypes.string.isRequired,
  uploaded_date: PropTypes.string.isRequired
});

export default ProposalOrIpsReportsPropTypes;
