/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import CancelIcon from './svg-icons/cancel-icon';
import SearchIcon from './svg-icons/search-icon';

const SelectedItem = ({ renderedItem, onRemove }) => (
  <div className="selected-item">
    {renderedItem}
    <button type="button" className="remove-selected-item-btn" onClick={onRemove}>
      &#8212;
    </button>
  </div>
);

SelectedItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  renderedItem: PropTypes.func.isRequired
};

class MultipleInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      searchIcon: props.searchIcon,
      stagedSuggestion: null
    };
  }

  onChange = event => {
    const { value } = event.target;
    const { inputProps } = this.props;
    const { stagedSuggestion } = this.state;

    if (value === 0) {
      event.preventDefault();
      return;
    }

    const newState = { inputValue: value };
    if (stagedSuggestion) newState.stagedSuggestion = null;
    this.setState(newState);

    inputProps.onChange(value);
  };

  stageSuggestion = (_, { suggestion }) => {
    const { autosuggestProps, onItemStaged, searchIcon } = this.props;

    this.setState({
      stagedSuggestion: suggestion,
      inputValue: autosuggestProps.getSuggestionValue(suggestion)
    });

    if (searchIcon) this.setState({ searchIcon: false });
    else if (onItemStaged) onItemStaged(suggestion);
  };

  shouldRenderSuggestions = value => {
    const { stagedSuggestion } = this.state;
    return !stagedSuggestion && value.trim().length > 0;
  };

  addSuggestion = () => {
    const { searchIcon, stagedSuggestion } = this.state;
    const { onItemAdded, searchIcon: propSearchIcon } = this.props;

    onItemAdded(stagedSuggestion);
    this.setState({ stagedSuggestion: null, inputValue: '' });

    if (propSearchIcon && !searchIcon) this.setState({ searchIcon: true });
  };

  render() {
    const { inputValue, searchIcon, stagedSuggestion } = this.state;
    const {
      addButton,
      autosuggestProps: { suggestions, getSuggestionValue, renderSuggestion },
      cancelIcon,
      disabled,
      getItemId,
      inputProps: { type, placeholder },
      onCancel,
      onItemRemoved,
      renderSelectedItem,
      theme,
      selectedItems
    } = this.props;

    return (
      <div className={cn('multiple-input', { 'member-add': cancelIcon })}>
        <Autosuggest
          className="form-control"
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          inputProps={{ placeholder, type, value: inputValue, onChange: this.onChange, disabled }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={({ value }) => this.setState({ inputValue: value })}
          onSuggestionSelected={this.stageSuggestion}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          theme={theme}
        />

        {addButton && stagedSuggestion && (
          <button type="button" className="btn-add-to-selected" onClick={this.addSuggestion}>
            + Add
          </button>
        )}

        {searchIcon && (
          <span className="search-icon-span" onClick={onCancel}>
            <SearchIcon className="create-modal-icon fill-grey" />
          </span>
        )}

        {cancelIcon && (
          <span className="cancel-icon-span" onClick={onCancel}>
            <CancelIcon className="create-modal-icon" />
          </span>
        )}

        {selectedItems && (
          <div className="selected-items-container">
            {selectedItems.map(item => (
              <SelectedItem
                key={getItemId(item)}
                renderedItem={renderSelectedItem(item)}
                onRemove={() => onItemRemoved(item)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

MultipleInput.propTypes = {
  addButton: PropTypes.bool,
  autosuggestProps: PropTypes.shape({
    suggestions: PropTypes.array.isRequired,
    getSuggestionValue: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired
  }).isRequired,
  cancelIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  getItemId: PropTypes.func,
  inputProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func,
  onItemAdded: PropTypes.func,
  onItemRemoved: PropTypes.func.isRequired,
  onItemStaged: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  renderSelectedItem: PropTypes.func.isRequired,
  searchIcon: PropTypes.bool,
  theme: PropTypes.object
};

MultipleInput.defaultProps = {
  addButton: false,
  cancelIcon: false,
  disabled: false,
  getItemId: s => s.id,
  onCancel: () => {},
  onItemAdded: () => {},
  onItemStaged: null,
  searchIcon: false,
  theme: {}
};

export default MultipleInput;
