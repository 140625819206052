import Choice from 'components/form/choice';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const CreateAccountsAutomatically = ({ onComplete }) => {
  const [createAccountsAutomatically, setCreateAccountsAutomatically] = useState(false);

  const onToggleCreateAccountsAutomatically = () => {
    setCreateAccountsAutomatically(!createAccountsAutomatically);
  };

  const onSubmit = () => {
    // This is used only for BAA integrations. The idea is to overwrite
    // the sync_clients attribute based on what the user wants to happen in the end.
    // These are the cases:
    // - sync_clients is false = It won't create a new model Account,
    //   only Integration model will be created instead.
    // - sync_clients is false = It will create a new model Account.
    if (onComplete) onComplete({ sync_clients: createAccountsAutomatically });
  };

  return (
    <div id="create-accounts-automatically">
      <h1>Do you want to create clients automatically?</h1>
      <div className="map-clients-accounts">
        <div className="map-clients-accounts__description">
          <p className="map-clients-accounts__description__warning">
            <span className="fs-icon-exclamation-circle warning-icon" /> If you allow, the system
            will guess client names, and it might not be 100% accurate.
          </p>
          <p>
            If you want to use CRM or CSV for more accurate client names, skip this and click
            submit. You will be able to manually map each of the clients to the accounts later.
          </p>
        </div>
        <Choice
          checked={createAccountsAutomatically}
          toggle={onToggleCreateAccountsAutomatically}
        />
        <span className="map-clients-accounts__label">
          Create clients and map to associated accounts automatically
        </span>
      </div>
      <div className="buttons-wrapper">
        <button type="button" data-dismiss="modal" className="btn btn-outline-primary">
          Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

CreateAccountsAutomatically.propTypes = {
  onComplete: PropTypes.func
};

CreateAccountsAutomatically.defaultProps = {
  onComplete: null
};

export default CreateAccountsAutomatically;
