import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import RangeSliderFilter from '../range-slider';

const DEFAULT_MIN_RANGE_VALUE = 0;
const DEFAULT_MAX_RANGE_VALUE = 10;
const DEFAULT_RANGE = [DEFAULT_MIN_RANGE_VALUE, DEFAULT_MAX_RANGE_VALUE];

const MinMaxSliderFilter = ({
  filters,
  key,
  maxAttr,
  minAttr,
  setFilters,
  setRequiresPageIndexReset
}) => {
  const getDefaultValue = () => {
    if (!filters[minAttr] && !filters[maxAttr]) return DEFAULT_RANGE;
    if (!filters[minAttr] && filters[maxAttr] !== DEFAULT_MAX_RANGE_VALUE)
      return [DEFAULT_MIN_RANGE_VALUE, filters[maxAttr]];
    if (filters[minAttr] !== DEFAULT_MIN_RANGE_VALUE && !filters[maxAttr])
      return [filters[minAttr], DEFAULT_MAX_RANGE_VALUE];
    return [filters[minAttr], filters[maxAttr]];
  };

  const defaultValue = getDefaultValue();

  const delayedOnChangeHandler = useCallback(
    _.debounce((min, max) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [minAttr]: min !== DEFAULT_MIN_RANGE_VALUE ? min : undefined,
        [maxAttr]: max !== DEFAULT_MAX_RANGE_VALUE ? max : undefined
      }));
      setRequiresPageIndexReset(true);
    }, 250),
    [minAttr, maxAttr]
  );

  const onChangeHandler = values => {
    const [min, max] = values;
    delayedOnChangeHandler(min, max);
  };

  return (
    <RangeSliderFilter
      defaultValue={defaultValue}
      key={key}
      max={10}
      min={0}
      onChange={onChangeHandler}
      steps={0.1}
    />
  );
};

MinMaxSliderFilter.propTypes = {
  filters: PropTypes.object,
  key: PropTypes.string,
  maxAttr: PropTypes.string.isRequired,
  minAttr: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  setRequiresPageIndexReset: PropTypes.func.isRequired
};

MinMaxSliderFilter.defaultProps = {
  filters: {},
  key: undefined
};

export default MinMaxSliderFilter;
