import Signature from 'components/advisor/templates/sections/signature/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import { getTargetInvestors } from 'utils/utils';
import { IPSPropTypes } from '../../types';

const IPSSignature = ({ ips, ...rest }) => {
  const investors = getTargetInvestors(ips.proposal);
  return <Signature {...rest} proposalOrIpsId={ips.id} investors={investors} />;
};

IPSSignature.propTypes = {
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

export default IPSSignature;
