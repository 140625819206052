import PropTypes from 'prop-types';
import React from 'react';

export const SimpleSpinner = ({ light, size }) => (
  <img
    src={light ? '/img/spinners/circle-base-white.svg' : '/img/spinners/circle-base.svg'}
    alt="In progress"
    width={size || '20'}
  />
);

SimpleSpinner.defaultProps = {
  light: false,
  size: null
};

SimpleSpinner.propTypes = {
  light: PropTypes.bool,
  size: PropTypes.string
};

export default SimpleSpinner;
