import { MODEL_PROPOSAL_TYPE, SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import MatchingModelsChart from 'components/charts/matching-models-chart/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { MetadataPropTypes } from '../../types';

const ProposalMatchingModels = ({ breakSection, metadata, proposalType, proposal }) => {
  if (proposalType === MODEL_PROPOSAL_TYPE || proposalType === SECURITY_PROPOSAL_TYPE) return null;

  const { body, title } = metadata;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap>
      <MatchingModelsChart proposal={proposal} />
    </BaseSection>
  );
};

ProposalMatchingModels.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  proposal: PropTypes.object.isRequired
};

ProposalMatchingModels.defaultProps = {
  breakSection: false
};

export default ProposalMatchingModels;
