// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-question.address > .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.full-question.address > .content .address-form {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.full-question.address > .content .form-group span.label {
  font-size: 0.9rem;
}
.full-question.address > .content input.form-control {
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/risk-tolerance-questionnaire/question/address/full/styles.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;AAKI;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAHN;AAMI;EACE,iBAAA;AAJN;AAOI;EACE,kBAAA;AALN","sourcesContent":["@import 'assets/scss/commons/app-mixin';\n@import 'assets/scss/commons/variables';\n\n.full-question.address {\n  & > .content {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n\n    .address-form {\n      display: flex;\n      flex-direction: column;\n      flex-basis: 100%;\n    }\n\n    .form-group span.label {\n      font-size: 0.9rem;\n    }\n\n    input.form-control {\n      border-radius: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
