import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import RiskScoreBubble from 'components/advisor/utils/score-bubble/risk';
import ToleranceScoreBubble from 'components/advisor/utils/score-bubble/tolerance';
import {
  OVERALL_FACTOR_ATTR,
  getPrismFactorName
} from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { accountsWithScoredPrismIntentsSelector } from 'selectors/prism';
import './styles.scss';

const InvestorAccountsRow = ({
  data: investor,
  isProspect: investorIsProspect,
  getPrismScore,
  meta,
  prismScoresInProgress,
  scoredPrismIntents,
  showCreateAccountModal,
  showModal
}) => {
  const prismFactorAttr = meta?.params?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
  const prismFactorName = getPrismFactorName(prismFactorAttr);

  const onCreateAccountHandler = account => () => {
    showCreateAccountModal(investor, account);
  };

  const onEditAccountHandler = account => () => {
    showModal(investor, account);
  };

  const columns = [
    { id: 'account-select' },
    {
      id: 'account-name',
      accessorFn: row => row.name,
      cell: ({ row: { original: account } }) => (
        <Link
          to={`/advisor/${investorIsProspect ? 'prospects' : 'investors'}/${
            account.investor_id
          }/account/${account.id}/overview`}
        >
          {account.display_name}
        </Link>
      ),
      colSpan: 3
    },
    {
      accessorFn: row => (row.value ? row.value : 0),
      id: 'account-value',
      cell: ({ row: { original: account } }) =>
        account.value ? (
          <button
            aria-label={account.value}
            className="btn btn-link"
            onClick={onEditAccountHandler(account)}
            type="button"
          >
            <FormattedNumber value={account.value} format="currency" />
          </button>
        ) : (
          <button
            className="btn table-btn get-target-score-btn btn-solid"
            onClick={onCreateAccountHandler(account)}
            type="button"
          >
            +
          </button>
        ),
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        row.prism_score_summary?.[prismFactorAttr]
          ? row.prism_score_summary[prismFactorAttr]
          : null,
      id: 'account-prism',
      cell: ({ row: { original: account } }) => {
        if (!account.prism_score_summary && scoredPrismIntents[account.id])
          account.prism_score_summary = { overall: scoredPrismIntents[account.id] };

        return (
          <RiskScoreBubble
            element={account}
            inProgress={prismScoresInProgress.includes(account.id)}
            onGenerate={getPrismScore}
            scoreName={prismFactorName}
          />
        );
      },
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        row.target_score_summary?.overall ? row.target_score_summary.overall : null,
      id: 'account-risk-tolerance',
      cell: ({ row: { original: account } }) => {
        const investorData = { id: account.investor_id };

        if (investor) if (!account.target_score_summary) return '-';
        return (
          <ToleranceScoreBubble
            element={account}
            investor={investorData}
            isProspect={investorIsProspect}
          />
        );
      },
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row => (row.drift_summary?.overall ? row.drift_summary.overall.toFixed(1) : null),
      id: 'account-drift',
      cell: ({ row: { original: account } }) =>
        account.drift_summary?.overall ? account.drift_summary.overall.toFixed(1) : '-',
      meta: {
        className: ({ row: { original: account } }) =>
          cn(
            'text-center',
            { 'td-drift': !!account.drift_summary?.overall },
            { 'td-drift--red': !account.is_healthy }
          ),
        style: () => ({ width: 75 })
      }
    },
    { id: 'account-expander' }
  ];

  const table = useReactTable({
    columns,
    data: investor.accounts,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id
  });

  return table.getRowModel().rows.map(row => (
    <tr
      className={cn('tr-account', { 'tr-account--excluded': row.original?.excluded })}
      key={row.id}
    >
      {row.getVisibleCells().map(cell => {
        const meta = cell.column.columnDef?.meta || {};
        const columnProps = {
          className: meta?.className ? meta.className(cell.getContext()) : undefined,
          style: meta?.style ? meta.style(cell.getContext()) : {}
        };
        return (
          <td {...columnProps} colSpan={cell.column.columnDef?.colSpan ?? 1} key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  ));
};

InvestorAccountsRow.propTypes = {
  data: PropTypes.object.isRequired,
  isProspect: PropTypes.bool.isRequired,
  getPrismScore: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  scoredPrismIntents: PropTypes.object.isRequired,
  showCreateAccountModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state),
  scoredPrismIntents: accountsWithScoredPrismIntentsSelector(state)
}))(InvestorAccountsRow);
