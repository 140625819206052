import { Image, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import Row from 'reports/base/table';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import { BEAR_BURNDOWN_CHART_ID, BULL_BURNDOWN_CHART_ID } from '../constants';
import styles from './styles';

const ProposalBurndown = ({ breakSection, metadata, proposal }) => {
  const { body, title } = metadata;

  const header = ['Potential best outcome', 'Potential worst outcome'];

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <ExtraDataSection
        proposal={{
          starting_value: proposal.starting_value,
          yearlyWithdrawal: proposal.yearly_withdrawal_rate
        }}
      />
      <Row elements={header} cellStyle={styles.headerCell} />
      <View style={styles.chartWrapper}>
        <Image src={proposal[BULL_BURNDOWN_CHART_ID]} style={styles.chart} />
        <Image src={proposal[BEAR_BURNDOWN_CHART_ID]} style={styles.chart} />
      </View>
    </BaseSection>
  );
};

ProposalBurndown.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired
};

ProposalBurndown.defaultProps = {
  breakSection: false
};

export default ProposalBurndown;
