import { useRef, useEffect } from 'react';

/* Hook settings intervals properly */

const useInterval = (callback, delay) => {
  const savedCallback = useRef(() => true);

  const tick = () => {
    savedCallback.current();
  };

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
