import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import ScoreBubble from 'reports/base/charts/score-bubble';
import BaseSection from 'reports/base/sections/base';
import Row from 'reports/base/table';
import { formatMoney, numToRiskScaleString } from 'utils/utils';
import { getAccountsFromProposal } from '../../risk-tolerance/utils';
import { IPSPropTypes, MetadataPropTypes } from '../../types';
import styles from './styles';

const IPSExecutiveSummary = ({ breakSection, ips, metadata }) => {
  const { body, currentAssets, investmentHorizon, riskToleranceScore, title } = metadata;

  const accounts = getAccountsFromProposal(ips.proposal);

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap>
      <View wrap={false}>
        <View style={styles.row}>
          <Text>Current Assets: </Text>
          <Text style={styles.highlightedText}>{currentAssets}</Text>
        </View>

        <View style={styles.row}>
          <Text>Risk Tolerance: </Text>
          <ScoreBubble score={Number(riskToleranceScore)} />
          <Text> of 10 - </Text>
          <Text style={styles.highlightedText}>
            {numToRiskScaleString(Number(riskToleranceScore))}
          </Text>
        </View>

        <View style={styles.row}>
          <Text>Investment Horizon: </Text>
          <Text style={styles.highlightedText}>{investmentHorizon || '-'}</Text>
          <Text> years</Text>
        </View>
      </View>

      <View style={styles.accountsBreakdown}>
        <Row
          cellStyle={styles.accountsBreakdownTitleRow}
          elements={['Account', 'Current Assets', 'Risk Tolerance', 'Investment Objective']}
        />
        {accounts.map(account => (
          <Row
            cellStyle={styles.accountsBreakdownContentRow}
            elements={[
              <Text style={{ textAlign: 'center' }}>{account.display_name}</Text>,
              <Text>{formatMoney(account.value)}</Text>,
              <ScoreBubble
                score={account.target_score_summary?.overall ?? account.target_overall}
              />,
              <Text style={{ textAlign: 'center' }}>{account.goal_name || '-'}</Text>
            ]}
            key={account.id}
            withCustomElements
          />
        ))}
      </View>
    </BaseSection>
  );
};

IPSExecutiveSummary.propTypes = {
  breakSection: PropTypes.bool,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired
};

IPSExecutiveSummary.defaultProps = {
  breakSection: false
};

export default IPSExecutiveSummary;
