import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE, BASE_TABLE_CELL, BASE_TABLE_ROW } from '../styles';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2 },
  lastCell: { marginRight: 0 },
  text: { textAlign: 'center' }
});

export default styles;
