/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import UndoIcon from 'components/svg-icons/undo-icon';
import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import './styles.scss';

const SignatureInput = ({
  onBeginStroke,
  onResetStroke,
  options,
  redrawOnResize,
  signatureRef,
  ...rest
}) => {
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [dirty, setDirty] = useState(false);

  const canvasRef = useRef(null);
  const signaturePadRef = signatureRef || useRef(null);

  const handleBeginStroke = () => {
    onBeginStroke();
    setDirty(true);
  };

  const handleResetStroke = () => {
    onResetStroke();
    setDirty(false);
  };

  const scaleCanvas = canvas => {
    const signaturePad = signaturePadRef.current;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const width = (rest.width || canvas.offsetWidth) * ratio;
    const height = (rest.height || canvas.offsetHeight) * ratio;

    if (width === canvasWidth && height === canvasHeight) return;

    let data;

    if (redrawOnResize && signaturePad && !signaturePad.isEmpty()) data = signaturePad.toDataURL();

    canvas.width = width;
    canvas.height = height;

    setCanvasWidth(width);
    setCanvasHeight(height);

    const ctx = canvas.getContext('2d');
    if (ctx) ctx.scale(ratio, ratio);

    if (data) signaturePad.fromDataURL(data);
    else if (signaturePad) {
      signaturePad.clear();
      handleResetStroke();
    }
  };

  const handleResize = () => {
    const canvas = canvasRef.current;
    if (canvas) scaleCanvas(canvas);
  };

  const handleClear = () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad && !signaturePad.isEmpty()) {
      signaturePad.clear();
      handleResetStroke();
    }
  };

  const handleUndo = () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad && !signaturePad.isEmpty()) {
      const data = signaturePad.toData();
      if (data) {
        data.pop();
        signaturePad.fromData(data);
        if (signaturePad.isEmpty()) handleResetStroke();
      }
    }
  };

  useEffect(() => {
    const { height, width } = rest;
    const canvas = canvasRef.current;

    if (canvas) {
      if (!width || !height) {
        canvas.style.width = '100%';
        window.addEventListener('resize', handleResize);
      }
      const signaturePad = new SignaturePad(canvas, options);
      signaturePad.addEventListener('beginStroke', () => {
        if (!dirty) handleBeginStroke();
      });
      signaturePadRef.current = signaturePad;
      scaleCanvas(canvas);
    }

    return () => {
      if (!width || !height) window.removeEventListener('resize', handleResize);
      if (signaturePadRef.current) signaturePadRef.current.off();
      onResetStroke();
    };
  }, []);

  return (
    <div className="signature-input-container">
      <canvas className="signature-input" ref={canvasRef} />
      <div className="signature-actions">
        <button
          aria-label="Undo Action"
          className="btn btn-secondary"
          onClick={handleUndo}
          type="button"
        >
          <UndoIcon className="signature-icon" title="Undo" />
        </button>
        <button
          aria-label="Clear Sign"
          className="btn btn-secondary"
          onClick={handleClear}
          title="Clear"
          type="button"
        >
          <TrashCanIcon className="signature-icon" title="Clear" />
        </button>
      </div>
    </div>
  );
};

SignatureInput.defaultProps = {
  height: 150,
  onBeginStroke: () => {},
  onResetStroke: () => {},
  options: {},
  redrawOnResize: false,
  signatureRef: null,
  width: null
};

SignatureInput.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBeginStroke: PropTypes.func,
  onResetStroke: PropTypes.func,
  options: PropTypes.object,
  redrawOnResize: PropTypes.bool,
  signatureRef: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default SignatureInput;
