import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const useInput = ({ field, question }) => {
  const minValue = question.data?.min_value ?? Number.NEGATIVE_INFINITY;
  const maxValue = question.data?.max_value ?? Number.POSITIVE_INFINITY;

  const onInputChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (value !== field.value) field.onChange(Math.min(Math.max(value, minValue), maxValue));
    }, 500)
  );

  // Set default value if required
  useEffect(() => {
    let defaultValue =
      field.initialValue ||
      question.default ||
      question.data?.default_value?.value ||
      question.data?.default_value;

    if (!Number.isFinite(defaultValue)) defaultValue = 0;

    if (field.autofill && !field.touched && !Number.isFinite(field.value))
      field.autofill(defaultValue);
  }, [
    field.initialValue,
    field.touched,
    question.default,
    question.data?.default_value?.value,
    question.data?.default_value
  ]);

  const inputProps = {
    defaultValue: field.value,
    thousandSeparator: true,
    allowNegative: false,
    prefix: '$',
    onValueChange: onInputChange
  };

  if (Number.isFinite(field.value)) inputProps.value = field.value;

  return {
    inputProps
  };
};

useInput.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default useInput;
