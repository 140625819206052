import CoverSection from 'components/advisor/templates/sections/cover';
import PropTypes from 'prop-types';
import React from 'react';

const IPSCoverSection = props => <CoverSection {...props} />;

IPSCoverSection.propTypes = {
  propertyKey: PropTypes.string.isRequired
};

export default IPSCoverSection;
