export const GENERATED_PRISM_PDF = 'generatedPrismPDF';
export const ADDED_NOTE = 'addedNote';
export const SIGNED_IN = 'signedIn';
export const ADDED_PROSPECT = 'addedProspect';
export const ADDED_CLIENT = 'addedClient';
export const ADDED_PROSPECT_ACCOUNT_MANUALLY = 'addedProspectAccountManually';

export const SENT_QUESTIONNAIRE = 'sentQuestionnaire';
export const SENT_QUESTIONNAIRE_EMAIL = 'sentQuestionnaireEmail';
export const CLICK_QUESTIONNAIRE_LINK = 'clickQuestionnaireLink';

export const CLICK_EXPORT_ACCOUNTS_CSV = 'clickExportAccountsCSV';
export const CLICK_IMPORT_ACCOUNTS_CSV = 'clickImportAccountsCSV';

export const CLICK_COPY_MODEL_PORTFOLIO_BUTTON = 'clickCopyModelPortfolioButton';
export const CLICK_EDIT_MODEL_PORTFOLIO_BUTTON = 'clickEditModelPortfolioButton';
export const CLICK_EXPORT_MODEL_PORTFOLIOS_CSV = 'clickExportModelPortfoliosCSV';
export const CLICK_IMPORT_MODEL_PORTFOLIOS_CSV = 'clickImportModelPortfoliosCSV';
export const CLICK_UPLOAD_MODEL_PORTFOLIO_CSV = 'clickUploadModelPortfolioCSV';
export const CLICK_UPLOAD_MODEL_PORTFOLIO_MANUAL = 'clickUploadModelPortfolioManual';
export const CREATE_MODEL_PORTFOLIO_SUCCESS = 'createModelPortfolioSuccess';

export const VIEW_PRISM_REPORT = 'viewPRISMReport';
export const GENERATE_PROPOSAL = 'generateProposal';
export const GENERATE_IPS = 'generateIPS';
export const GENERATE_PROPOSAL_PDF = 'generateProposalPDF';
export const RECEIVE_RISK_SCORE = 'receiveRiskScore';
export const MANUAL_RISK_SCORE = 'manualRiskScore';
export const CLICK_BOOK_WIDGET = {
  Accounts: 'clickBookWidgetAccounts',
  Clients: 'clickBookWidgetClients',
  Households: 'clickBookWidgetHouseholds',
  Prospects: 'clickBookWidgetProspects'
};
export const CLICK_DRIFT_LEVEL_DISTRIBUTION_WIDGET = {
  Accounts: 'clickDriftLevelDistributionAccounts',
  Clients: 'clickDriftLevelDistributionClients',
  Households: 'clickDriftLevelDistributionHouseholds',
  Prospects: 'clickDriftLevelDistributionProspects'
};
export const CLICK_DRIFT_WIDGET_NOTE = 'clickDriftWidgetNote';
export const CLICK_RISK_WIDGET_VIEW = 'clickRiskWidgetView';
export const CLICK_RISK_WIDGET_SCORE = 'clickRiskWidgetScore';
export const LEFT_COMPLIANCE_NOTE = 'leftComplianceNote';
export const EXCLUDE_ACCOUNT = 'excludeAccount';
export const FAILED_TO_FIND_TICKER = 'failedToFindTicker';
export const CLICK_CLIENT = 'clickClient';
export const CLICK_HOUSEHOLD = 'clickHousehold';

export const ADVISOR_APP_MODEL = 'advisor.Advisor';
export const MODEL_PORTFOLIO_APP_MODEL = 'asset.ModelPortfolio';
export const INVESTOR_APP_MODEL = 'investor.Investor';
export const HOUSEHOLD_APP_MODEL = 'investor.Household';
export const ACCOUNT_APP_MODEL = 'asset.Account';
export const SECURITY_APP_MODEL = 'asset.Security';

export const CLICK_INVITE_TO_SHARE_BUTTON_CLIENT = 'clickInviteToShareButtonClient';
export const CLICK_INVITE_TO_SHARE_BUTTON_PROSPECT = 'clickInviteToShareButtonProspect';

export const CLICK_INTEGRATION = 'clickIntegration';
export const MSTAR_INTEGRATION_COMPLETED = 'mstarIntegrationCompleted';
export const OPEN_MSTAR_INTEGRATION_WIDGET = 'openMstarIntegrationWidget';
export const REQUEST_INTEGRATION = 'requestIntegration';

export const REQUEST_LOCKED_FEATURE_ACCESS = 'requestLockedFeatureAccess';
