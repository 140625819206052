/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { formatPercentage } from 'utils/utils';
import './styles.scss';
import { getTiles, STYLE_BOX_RANGE_COLORS } from './utils';

const MorningstarStyleBoxChart = ({ data, withLegend }) => {
  const tiles = useMemo(() => getTiles(data), [JSON.stringify(data)]);
  return (
    <div className="morningstar-style-box-chart">
      <div className="style-box-chart">
        {tiles.map(({ backgroundColor, color, display, type }, idx) => (
          <div
            className="style-box-chart__item"
            data-type={type}
            key={`tile-${idx}`}
            style={{ backgroundColor, color }}
          >
            <span>{display}</span>
          </div>
        ))}
      </div>
      {withLegend && (
        <div className="style-box-legend">
          <pre className="style-box-legend__item">Weight %</pre>
          {STYLE_BOX_RANGE_COLORS.map(range => {
            const min = range.min === 0 ? ' 0%' : formatPercentage(range.min, 100, 0);
            const max = formatPercentage(range.max, 100, 0);
            return (
              <pre key={`${range.min}-${range.max}`} className="style-box-legend__item">
                <div className="item__box" style={{ backgroundColor: range.background }} />
                {min} - {max}
              </pre>
            );
          })}
        </div>
      )}
    </div>
  );
};

MorningstarStyleBoxChart.defaultProps = {
  withLegend: false
};

MorningstarStyleBoxChart.propTypes = {
  data: PropTypes.array.isRequired,
  withLegend: PropTypes.bool
};

export default React.memo(MorningstarStyleBoxChart);
