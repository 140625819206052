import PropTypes from 'prop-types';

export const QuestionPropTypes = {
  question_type: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  description: PropTypes.string
};

export const QuestionFieldPropTypes = {
  dirty: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired
};

export default {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  field: PropTypes.shape(QuestionFieldPropTypes),
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isSectionSeparator: PropTypes.bool,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};
