import Disclosure from 'components/disclosure';
import LoadingWave from 'components/loading-wave';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePlaidLink } from 'react-plaid-link';
import { useIntegrationToken } from './hooks';
import './styles.scss';

const ConnectAccount = ({ params }) => {
  const {
    connected,
    connectedAccounts,
    handlePlaidIntegrationPublicToken,
    isOAuthRedirect,
    loading,
    receivedRedirectUri,
    token
  } = useIntegrationToken(params);

  const onSuccess = useCallback(publicToken => {
    handlePlaidIntegrationPublicToken(publicToken);
  }, []);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    ...(isOAuthRedirect && { receivedRedirectUri })
  });

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) open();
  }, [ready, open, isOAuthRedirect]);

  return (
    <>
      <div className="connect">
        <div className="content card">
          <div className="title">
            <h4>
              <FormattedMessage id="connect.title" />
            </h4>
          </div>
          {!connected && (
            <>
              <div className="content-header">
                <p>
                  <FormattedMessage id="connect.description" />{' '}
                  <span role="img" aria-label="chart">
                    📈
                  </span>
                </p>
                <p>
                  <FormattedMessage id="connect.secure" />{' '}
                  <span role="img" aria-label="lock">
                    🔒
                  </span>
                </p>
              </div>
              <div className="content-steps">
                <p>
                  <FormattedMessage id="connect.steps" />
                </p>
                <ol className="steps">
                  <li>
                    <FormattedMessage id="connect.step1" />
                  </li>
                  <li>
                    <FormattedMessage id="connect.step2" />
                  </li>
                  <li>
                    <FormattedMessage id="connect.step3" />
                  </li>
                </ol>
              </div>

              {loading && <LoadingWave />}
              {!loading && token && (
                <div className="content-actions">
                  <button type="button" className="btn btn-primary btn-lg" onClick={() => open()}>
                    <FormattedMessage id="connect.cta" />
                  </button>
                  {!!connectedAccounts && (
                    <span>
                      <FormattedMessage id="connect.status" values={{ connectedAccounts }} />
                    </span>
                  )}
                </div>
              )}
              {!loading && !token && (
                <p>
                  <FormattedMessage id="connect.help" />{' '}
                  <a href="mailto:support@stratifi.com">support@stratifi.com</a>.
                </p>
              )}
            </>
          )}
          {connected && (
            <div className="connected-message">
              <p>
                <FormattedMessage id="connect.success1" />{' '}
                <span role="img" aria-label="tada">
                  🎉
                </span>
              </p>
              <p>
                <FormattedMessage id="connect.success2" />{' '}
                <span role="img" aria-label="sparkles">
                  ✨
                </span>
              </p>
              <p>
                <FormattedMessage id="connect.success3" />{' '}
                <span role="img" aria-label="raised-hands">
                  🙌
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      <Disclosure />
    </>
  );
};

ConnectAccount.propTypes = {
  params: PropTypes.object.isRequired
};

export default ConnectAccount;
