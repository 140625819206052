import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { ageCalculator } from 'utils/questionnaire';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

export const TYPE_ANNUAL = 'annual';
export const TYPE_ONCE = 'once';

const DEFAULT_INVESTMENT_PERCENTAGE = 0.05;

const useValueRequirement = ({ question, field }) => {
  const isValidAmount = value => !_.isUndefined(value) && value !== '';

  const refs = question.data?.refs || {};

  const initialInvestment =
    refs['liquid-assets'] && refs['percentage-assets-invested']
      ? Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])
      : 0;

  const age = ageCalculator(refs.birthday);
  const retirement = Number(refs.retirement) || 0;
  refs['retire-in'] = retirement - age;
  const isRetired = refs['retire-in'] <= 0;

  const isAnnual = field.type.value === TYPE_ANNUAL;

  const defaultAmount = initialInvestment * DEFAULT_INVESTMENT_PERCENTAGE;

  const toggleIsAnnual = useCallback(() => {
    const newType = isAnnual ? TYPE_ONCE : TYPE_ANNUAL;
    if (field.type.value !== newType) field.type.onChange(newType);
  }, [isAnnual, field.type]);

  const onAmountChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (isValidAmount(value) && value !== field.amount.value) field.amount.onChange(value);
    }, 500),
    [field.amount.value]
  );

  useEffect(() => {
    if (!field.type.value || (isRetired && field.type.value !== TYPE_ANNUAL))
      field.type.autofill(TYPE_ANNUAL);
  }, [field.type.value, isRetired]);

  useEffect(() => {
    if (!field.amount.touched && !isValidAmount(field.amount.value))
      field.amount.autofill(defaultAmount);
  }, [field.amount.touched, field.amount.value, defaultAmount]);

  const inputProps = {
    allowNegative: false,
    onValueChange: onAmountChange,
    prefix: '$',
    thousandSeparator: true,
    value: field.amount.value
  };

  return {
    amount: field.amount.value,
    type: field.type.value,
    inputProps,
    isAnnual,
    refs,
    toggleIsAnnual
  };
};

useValueRequirement.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default useValueRequirement;
