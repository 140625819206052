// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signature-input-container {
  width: 100%;
  max-width: 700px;
}
.signature-input-container .signature-input {
  padding: 2px;
  border: 2px dashed #d3d6db;
}
.signature-input-container .signature-actions {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
}
.signature-input-container .signature-actions > button {
  display: flex;
  padding: 8px;
  border-radius: 100px;
}
.signature-input-container .signature-actions .signature-icon {
  width: 12px;
  height: 12px;
  fill: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/signature-input/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;AADF;AAGE;EACE,YAAA;EACA,0BAAA;AADJ;AAIE;EACE,aAAA;EACA,yBAAA;EACA,QAAA;AAFJ;AAII;EACE,aAAA;EACA,YAAA;EACA,oBAAA;AAFN;AAKI;EACE,WAAA;EACA,YAAA;EACA,WAAA;AAHN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.signature-input-container {\n  width: 100%;\n  max-width: 700px;\n\n  .signature-input {\n    padding: 2px;\n    border: 2px dashed $graphite-grayish-blue;\n  }\n\n  .signature-actions {\n    display: flex;\n    justify-content: flex-end;\n    gap: 2px;\n\n    & > button {\n      display: flex;\n      padding: 8px;\n      border-radius: 100px;\n    }\n\n    .signature-icon {\n      width: 12px;\n      height: 12px;\n      fill: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
