import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { choicesToOptions, interpolateRef, roundThousands } from 'utils/questionnaire';
import { withCurrencyFormat } from 'utils/utils';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const usePortfolioConcentration = ({ question, field }) => {
  const refs = question.data?.refs || {};

  // total amount that the user invested or is comfortable investing
  const initialInvestment = roundThousands(
    Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])
  );

  refs['assets-invested'] =
    withCurrencyFormat(initialInvestment, 'standard', 0).replace('M', 'MM') || 0;

  const questionText = useMemo(
    () => interpolateRef(question.question, refs),
    [JSON.stringify(refs)]
  );

  const choices = choicesToOptions(question.data?.options);

  /*
   * If a field if provided, means that this hook is being used in a form.
   * We must attach some effects to keep the field values updated
   */
  if (field)
    useEffect(() => {
      const defaultValue =
        // field.initialValue ||
        question.default || question.data?.default_value?.value || question.data?.default_value;
      if (!field.touched && !field.value) field.autofill(defaultValue);
    }, [
      // field.initialValue,
      field.touched,
      question.default,
      question.data?.default_value?.value,
      question.data?.default_value
    ]);

  return {
    choices,
    initialInvestment,
    questionText
  };
};

usePortfolioConcentration.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default usePortfolioConcentration;
