import React from 'react';
import { Link } from 'react-router';

const PasswordResetDone = () => (
  <div>
    <h1 className="text-sm-center signup-title mt-3 mb-1">Password was updated</h1>
    <p className="mb-2 text-sm-center">
      New password has been set. Now you can login using new password.
    </p>
    <div className="text-sm-center center-block">
      <Link className="center-block btn btn-primary btn-block" to="/signin">
        Back to Sign in
      </Link>
    </div>
  </div>
);

export default PasswordResetDone;
