import AutosizeInput from 'components/autosize-input';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';

const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

const InlineTextEditor = ({
  actionsDisabled,
  defaultEditing,
  onChange,
  text,
  updating,
  validation
}) => {
  const [editing, setEditing] = useState(defaultEditing);
  const [value, setValue] = useState(defaultEditing ? text : '');

  // if no `validation` function is passed, it defaults to `true`. Otherwise, the validation
  // coming from `utils/form` is applied which returns a string with the error if there is one,
  // or `undefined` if there is no error.
  const isValid = !validation || !validation(value);

  const inputRef = useRef();

  const handleNameChange = e => {
    setValue(e.target.value);
  };

  const toggleEditNameMode = e => {
    e.stopPropagation();
    e.preventDefault();

    if (!actionsDisabled) {
      setEditing(!editing);
      setValue(text);
    }
  };

  const onSave = e => {
    if (!isValid) return;
    if (e) e.stopPropagation();
    if (value !== text) onChange(value);
    if (editing) setEditing(false);
  };

  const onCancel = e => {
    if (e) e.stopPropagation();
    if (editing) setEditing(false);
  };

  useEffect(() => {
    if (defaultEditing && inputRef?.current) inputRef.current.select();
  }, [defaultEditing, inputRef?.current]);

  return (
    <div className="inline-text-editor">
      {!editing && (
        <AdministrativePermissionsTooltip
          renderTooltip={actionsDisabled}
          tooltipId="edit-template-name"
          wrapperClassName="text-wrapper"
        >
          <span
            role="button"
            tabIndex={0}
            onClick={toggleEditNameMode}
            onKeyUp={e => {
              if (e.key === ENTER_KEY) toggleEditNameMode();
            }}
          >
            {updating && value ? value : text}
          </span>
          {!updating && (
            <button type="button" className="btn-transparent" onClick={toggleEditNameMode}>
              <img className="icon edit-icon" src="/img/edit_icon_blue.svg" alt="edit" />
            </button>
          )}
        </AdministrativePermissionsTooltip>
      )}

      {editing && (
        <span>
          <div className="editor-wrapper">
            <AutosizeInput
              autoFocus
              id="autosizeInput-text-editor"
              maxWidth="200px"
              name="text-editor"
              onBlur={handleNameChange}
              onChange={handleNameChange}
              onKeyUp={e => {
                if (e.key === ENTER_KEY) onSave();
                if (e.key === ESCAPE_KEY) onCancel();
              }}
              value={value}
            />
            <div className="underline" />
          </div>
          <button
            className="btn-save btn-transparent"
            disabled={!isValid}
            onClick={onSave}
            type="button"
          >
            <img className="icon" src="/img/checkmark_filled_blue.svg" alt="save" />
          </button>
          <button
            type="button"
            className="btn-discard btn-transparent"
            onClick={toggleEditNameMode}
          >
            <img className="icon" src="/img/cancel_round.svg" alt="discard" />
          </button>
        </span>
      )}
    </div>
  );
};

InlineTextEditor.propTypes = {
  actionsDisabled: PropTypes.bool,
  defaultEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  updating: PropTypes.bool,
  validation: PropTypes.func
};

InlineTextEditor.defaultProps = {
  actionsDisabled: false,
  defaultEditing: false,
  updating: false,
  validation: null
};

export default InlineTextEditor;
