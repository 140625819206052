import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { numToRiskScaleString } from 'utils/utils';
import RiskGauge from '../../charts/risk-gauge';
import styles from './styles';

const PrismSection = ({ risk, tolerance }) => {
  const roundedRisk = Math.round(risk ?? 0);
  const roundedTolerance = Math.round(tolerance ?? 0);

  if (!risk)
    return (
      <View style={styles.body}>
        <Text>Portfolio without score</Text>
      </View>
    );

  return (
    <View style={styles.body}>
      <RiskGauge width={80} height={80} risk={risk} tolerance={tolerance} />
      <View style={styles.textContainer}>
        <View style={[styles.text, styles[`color${roundedRisk}`]]}>
          <Text style={{ fontSize: 20, fontWeight: 700 }}>{Number(risk).toFixed(1)} </Text>
          <Text>{numToRiskScaleString(risk)}</Text>
        </View>
        {tolerance && (
          <View style={styles.text}>
            <Text style={styles.toleranceText}>Tolerance:</Text>
            <Text style={{ ...styles[`color${roundedTolerance}`], fontWeight: 700 }}>
              {Number(tolerance).toFixed(1)}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

PrismSection.propTypes = {
  risk: PropTypes.number,
  tolerance: PropTypes.number
};

PrismSection.defaultProps = {
  risk: undefined,
  tolerance: undefined
};

export default PrismSection;
