import React from 'react';

import withSubscription from 'hocs/subscription-validation';

const ClientsWrapper = ({ children }) => <>{children}</>;

export default withSubscription({
  plan: 'professional',
  id: 'clients',
  name: 'Clients management'
})(ClientsWrapper);
