import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'app/constants/questionnaire';
import Heading from 'components/advisor/prism-questions/heading';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import { AuthenticationContext } from 'containers/auth';
import { InvestorContext } from 'containers/investor';
import QuestionnaireIntro from 'containers/risk-tolerance-questionnaire/intro';
import QuestionnaireQuestions from 'containers/risk-tolerance-questionnaire/questions';
import QuestionnaireResult from 'containers/risk-tolerance-questionnaire/result';
import { LANGUAGE_URL_PARAM } from 'lang/constants';
import { getLocale } from 'lang/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import InAppProvider from 'providers/in-app';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getWidgetUrl } from 'utils/utils';
import CreateInvestorModal from '../guest/create-investor-modal';

const STEPS = ['begin', 'questions', 'result'];

const QuestionnaireWizard = ({
  flavor,
  inAppProvider,
  initialStep,
  investors,
  isMarketing,
  isProspect,
  mode,
  onQuestionnaireSave,
  questions
}) => {
  const { authProvider, user, routerActions } = useContext(AuthenticationContext);
  const Context = useMemo(
    () => (authProvider.isInvestor(user) ? InvestorContext : AdvisorContext),
    [JSON.stringify(user)]
  );
  const { questionProvider } = useContext(Context);

  const isDefaultInvestor = user?.investor?.is_default;
  const query = new URLSearchParams(window.location.search);
  const questionnaireTemplateId = query.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM);

  const [isSaving, setSaving] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [questionnaireTemplate, setQuestionnaireTemplate] = useState(null);
  const [showCreateInvestorModal, setShowCreateInvestorModal] = useState(false);
  const [step, setStep] = useState(
    initialStep && STEPS.includes(initialStep) ? initialStep : 'begin'
  );

  const questionnaireId = questionnaire?.id || query.get('questionnaire');
  const scoreSummary = questionnaire?.template?.is_scored ? questionnaire?.score_data : null;

  useEffect(() => {
    setSaving(true);
    questionProvider
      .getQuestionnaireTemplate(questionnaireTemplateId)
      .then(questionnaireTemplate => {
        setQuestionnaireTemplate(questionnaireTemplate);
      })
      .finally(() => {
        setSaving(false);
      });
  }, []);

  /**
   * Allows tracking in Amplitude of any user starting the RTQ
   */
  useEffect(() => {
    if (user) {
      const isGuest = !!user?.investor?.data?.advisor_id;
      const userType = flavor;
      const role = user?.advisor?.role || (user.investor?.is_prospect ? 'prospect' : 'investor');
      const questionnaireType = isGuest ? 'widget' : 'standard';
      trackAmplitudeEvent('rtq.started', { role, userType, questionnaireType });
    }
  }, [JSON.stringify(user)]);

  /**
   * If a default investor (anonymous) completes the RTQ through the widget,
   * the modal that will allow the creation of the investor is displayed.
   */
  useEffect(() => {
    if (step === 'result' && isDefaultInvestor) setShowCreateInvestorModal(true);
  }, [step, isDefaultInvestor]);

  /**
   * Allows assigning the RTQ to the logged in user and to obtain the `data.score_data`.
   *
   * This is a special case that only happens if:
   * - The params `investor` and `questionnaire` are in the URL.
   * - The RTQ stage is equals to `result`.
   * - The questionnaire `is_scored` but there is no `score_data` in the questionnaire, which
   *   indicates that the user was redirected to that URL.
   *
   * This ensures that the rest of the process remains exactly the same even for
   * the special case where the RTQ is completed through the widget.
   */
  useEffect(() => {
    const investorId = query.get('investor');
    if (questionnaireId && investorId && step === 'result' && !scoreSummary && investors.length) {
      setSaving(true);
      questionProvider.assignQuestionnaire(investorId, questionnaireId).then(() => {
        const [investor] = investors;
        questionProvider
          .getQuestionnaire(investor.id, questionnaireId, investor.is_prospect)
          .then(({ data }) => {
            setQuestionnaire(data);
          })
          .finally(() => {
            setSaving(false);
          });
      });
    }
  }, [investors.length, questionnaireId, step, JSON.stringify(scoreSummary)]);

  /**
   * Allows creating an investor using the `advisor` and `widget-token` params
   * from the URL. If successfully created, it's forced to redirect to the `results`
   * step using the credentials of the new investor.
   *
   * @param {object} investor - The investor data
   * @returns {Promise}
   */
  const onCreateInvestor = ({ first_name: firstName, last_name: lastName, email }) =>
    inAppProvider
      .createInvestor(query.get('widget-token'), query.get('advisor'), firstName, lastName, email)
      .then(({ data }) => {
        if (data?.id && data?.token) {
          // reload to force the app to load user from the session token in the URL
          const [investor] = investors;
          document.location.href = getWidgetUrl(data.id, {
            [LANGUAGE_URL_PARAM]: getLocale(),
            [QUESTIONNAIRE_TEMPLATE_URL_PARAM]: query.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM),
            investor: investor.id,
            questionnaire: questionnaireId,
            session: data.token,
            source: query.get('source'),
            step: 'result'
          });
        } else toast.error(() => <FormattedMessage id="common.general-error" />);
      });

  /**
   * Saves the questionnaire answers. Upon receiving the answers, the promises
   * are created and if they are solved correctly, the `questionnaireId` and the
   * `questionnaire` (if possible) are assigned.
   *
   * If the RTQ is being completed from the widget, `data.score_data` will be set
   * to `null` to force an investor to be created first before getting the results.
   *
   * @param {array} answers - The questionnaire answers
   * @returns {Promise}
   */
  const saveQuestionnaire = answers => {
    setSaving(true);
    const promises = [];
    investors.forEach(investor => {
      if (mode.startsWith('investor'))
        promises.push(
          questionProvider.answerQuestionnaire(questionnaireTemplateId, answers, investor.id)
        );
      else
        promises.push(
          questionProvider.answerQuestionnaire(
            questionnaireTemplateId,
            answers,
            investor.id,
            investor.accounts.map(a => a.id)
          )
        );
    });

    return Promise.all(promises)
      .then(response => {
        const [{ data, error }] = response;
        if (!error) setQuestionnaire(data);

        onQuestionnaireSave(!!error);
        setStep('result');
        return { data, error };
      })
      .finally(() => {
        setSaving(false);
      });
  };

  /**
   * Updates the questionnaire score for all investors.
   * @param {string} questionnaireId - The questionnaire ID
   * @param {object} newScore - The new score
   * @param {boolean} isProspect - Indicates if the investor is a prospect
   *
   * @returns {Promise}
   */
  const onUpdateScore = (questionnaireId, newScore, isProspect) => {
    const promises = [];
    investors.forEach(investor => {
      promises.push(
        questionProvider.updateQuestionnaire(investor.id, questionnaireId, newScore, isProspect)
      );
    });
    return Promise.all(promises).then(response => {
      const [{ data, error }] = response;
      if (!error) setQuestionnaire(data);
      return response;
    });
  };

  const onQuestionnaireBegin = useCallback(() => {
    setStep('questions');
  }, [setStep]);

  const onQuestionnaireCancel = useCallback(() => {
    routerActions.goBack();
  }, []);

  if (isSaving || _.isEmpty(investors) || _.isEmpty(questions))
    return <SpinnerLoader spinnerLoading />;

  const childProps = {
    flavor,
    investors,
    mode,
    questionnaireId,
    questionnaireTemplate,
    questionProvider,
    user
  };

  return (
    <>
      <Heading
        flavor={flavor}
        investors={investors}
        mode={mode}
        questionnaireTemplate={questionnaireTemplate}
        step={step}
      />

      {!isSaving && step === 'begin' && (
        <QuestionnaireIntro
          {...childProps}
          isProspect={isProspect}
          onQuestionnaireBegin={onQuestionnaireBegin}
          onQuestionnaireCancel={onQuestionnaireCancel}
        />
      )}

      {!isSaving && step === 'questions' && !!questions && investors && !!investors.length && (
        <QuestionnaireQuestions
          {...childProps}
          saveQuestionnaire={saveQuestionnaire}
          questions={questions}
        />
      )}

      {!isSaving && step === 'result' && investors && investors.length && (
        <QuestionnaireResult
          {...childProps}
          onQuestionnaireRestart={onQuestionnaireBegin}
          questionnaire={questionnaire}
          onUpdateScore={onUpdateScore}
          isMarketing={isMarketing}
        />
      )}

      <CreateInvestorModal createInvestor={onCreateInvestor} show={showCreateInvestorModal} />
    </>
  );
};

QuestionnaireWizard.propTypes = {
  flavor: PropTypes.string,
  inAppProvider: PropTypes.object.isRequired,
  initialStep: PropTypes.string,
  investors: PropTypes.array.isRequired,
  isMarketing: PropTypes.bool,
  isProspect: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  onQuestionnaireSave: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired
};

QuestionnaireWizard.defaultProps = {
  flavor: 'advisor',
  initialStep: null,
  isMarketing: false,
  isProspect: false
};

export default connect(null, dispatch => ({ inAppProvider: new InAppProvider({ dispatch }) }))(
  QuestionnaireWizard
);
