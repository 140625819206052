import React from 'react';
import FilterContainer from '../common/filter-container';
import RangeValueFilter from '../common/range-value';
import { INVESTOR_TOTAL_MAX_ATTR, INVESTOR_TOTAL_MIN_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const InvestorTotalFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[INVESTOR_TOTAL_MAX_ATTR, INVESTOR_TOTAL_MIN_ATTR]}
    filters={filters}
    label="Investor Total"
  >
    <RangeValueFilter
      filters={filters}
      maxAttr={INVESTOR_TOTAL_MAX_ATTR}
      minAttr={INVESTOR_TOTAL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

InvestorTotalFilter.propTypes = { ...filterPropTypes };

InvestorTotalFilter.defaultProps = { ...filterDefaultProps };

export default InvestorTotalFilter;
