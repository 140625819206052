export const getReviewRequestUrl = (reviewUrl, reportId, reportType, reportInvestor) => {
  const url = new URL(reviewUrl);
  url.searchParams.set('report', reportId);
  url.searchParams.set('report_type', reportType);
  url.searchParams.set('report_investor', reportInvestor.id);
  url.searchParams.set(
    'report_investor_type',
    reportInvestor.is_prospect ? 'prospect' : 'investor'
  );
  return url.href;
};

export const buildMessage = (
  signer,
  reportId,
  reportType,
  reportInvestor,
  reviewUrl,
  msgGenerator
) => {
  const action = {
    text: 'Review',
    url: getReviewRequestUrl(reviewUrl, reportId, reportType, reportInvestor)
  };
  return msgGenerator({ eInvestor: signer, eAction: action });
};
