import SendEmailForm from 'components/advisor/investors/email-form';
import {
  CLICK_INVITE_TO_SHARE_BUTTON_CLIENT,
  CLICK_INVITE_TO_SHARE_BUTTON_PROSPECT
} from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import { DEFAULT_CONNECT_EMAIL_TEMPLATE } from 'containers/advisor/templates/defaults';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { trackAmplitudeEvent } from 'utils/tracking';

const getConnectURL = (investor, heldAway) => {
  const url = new URL(investor.connect_url);
  url.searchParams.append('held_away', heldAway);
  return url.toString();
};

const ShareAccountEmail = ({ investor, onSend, hideModal, heldAway }) => {
  const { actionProvider } = useContext(AdvisorContext);
  const action = { text: 'Connect', url: getConnectURL(investor, heldAway) };

  const onSubmit = async ({ email, cc, sender, subject, msgGenerator }) => {
    actionProvider.storeAction({
      verb: investor.is_prospect
        ? CLICK_INVITE_TO_SHARE_BUTTON_PROSPECT
        : CLICK_INVITE_TO_SHARE_BUTTON_CLIENT
    });
    trackAmplitudeEvent('add_account.connect.email_sent');
    const digestedMessage = msgGenerator({ eInvestor: investor, eAction: action });
    return onSend(email, cc, sender, subject, digestedMessage, investor.id);
  };

  return (
    <SendEmailForm
      onCancel={hideModal}
      title="Share Your Account"
      className="share-account-email"
      investor={investor}
      initialValues={{ ...DEFAULT_CONNECT_EMAIL_TEMPLATE, email: investor.email }}
      submit={onSubmit}
      action={action}
    />
  );
};

ShareAccountEmail.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  heldAway: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  investor: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default ShareAccountEmail;
