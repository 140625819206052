import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { RISK_GREEN_COLOR, RISK_RED_COLOR } from 'reports/base/styles';
import styles from './styles';

const ScenarioPerformance = ({ color, percentage, startingValue, style }) => {
  const textColor = () => {
    if (percentage > 0) return { color: RISK_GREEN_COLOR };
    if (percentage < 0) return { color: RISK_RED_COLOR };
    return {};
  };

  return (
    <View style={[styles.performance, style]}>
      <View style={[styles.performanceBox, { backgroundColor: color }]} />
      {percentage ? (
        <Text style={textColor()}>
          <FormattedNumber
            value={percentage / 100}
            format="percent"
            maximumFractionDigits={0}
            signDisplay="exceptZero"
          />{' '}
          (
          <FormattedNumber
            value={(percentage * startingValue) / 100}
            format="currency"
            signDisplay="exceptZero"
          />
          )
        </Text>
      ) : (
        <Text>-</Text>
      )}
    </View>
  );
};

ScenarioPerformance.defaultProps = {
  style: {}
};

ScenarioPerformance.propTypes = {
  color: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  startingValue: PropTypes.number.isRequired,
  style: PropTypes.object
};

export default ScenarioPerformance;
