// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#meeting-calendar h1.title {
  margin-bottom: 0.25rem;
}
#meeting-calendar > span {
  display: block;
  font-size: 1rem;
  font-weight: 300;
  padding-bottom: 0.5rem;
}
#meeting-calendar .content {
  padding: 37px;
  display: flex;
  border: 1px solid #c8c7c7;
  background-color: white;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/meeting-calendar/styles.scss","webpack://./src/assets/scss/commons/_app-box.scss"],"names":[],"mappings":"AAIE;EACE,sBAAA;AAHJ;AAME;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;AAJJ;AAOE;ECZA,aAAA;EACA,aAAA;EACA,yBAAA;EACA,uBAAA;EDWE,sBAAA;AAFJ","sourcesContent":["@import 'assets/scss/commons/app-box';\n@import 'assets/scss/commons/variables';\n\n#meeting-calendar {\n  h1.title {\n    margin-bottom: 0.25rem;\n  }\n\n  & > span {\n    display: block;\n    font-size: 1rem;\n    font-weight: 300;\n    padding-bottom: 0.5rem;\n  }\n\n  .content {\n    @include box;\n    flex-direction: column;\n  }\n}\n","@import 'assets/scss/commons/variables';\n\n@mixin box {\n  padding: 37px;\n  display: flex;\n  border: 1px solid $silver;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
