import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  CYAN_COLOR,
  GRAPHITE_COLOR,
  LIGHT_GRAPHITE_COLOR,
  LILAC_COLOR,
  RISK_GREEN_COLOR,
  RISK_RED_COLOR
} from 'reports/base/styles';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 15 },
  row: { ...BASE_TABLE_ROW, marginBottom: 0 },
  cell: { ...BASE_TABLE_CELL, marginRight: 0, paddingVertical: 0, paddingHorizontal: 0 },
  labels: {
    flexBasis: 130,
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20
  },
  label: { marginVertical: 7 },
  header: { marginBottom: 5 },
  headerCell: { ...BASE_TABLE_CELL, padding: 0, fontSize: 8, textAlign: 'center' },
  chart: { minHeight: 100 },
  grid: { ...BASE_TABLE_ROW },
  downsideCell: {
    ...BASE_TABLE_CELL,
    borderLeftWidth: '0.1mm',
    borderLeftStyle: 'dashed',
    borderLeftColor: LIGHT_GRAPHITE_COLOR
  },
  upsideCell: {
    ...BASE_TABLE_CELL,
    borderRightWidth: '0.1mm',
    borderRightStyle: 'dashed',
    borderRightColor: LIGHT_GRAPHITE_COLOR
  },
  firstDownsideCell: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'black'
  },
  firstUpsideCell: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: 'black'
  },
  barsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center'
  },
  downsideBar: {
    backgroundColor: RISK_RED_COLOR,
    width: 0,
    height: 11,
    marginVertical: 7,
    marginRight: 1
  },
  upsideBar: {
    backgroundColor: RISK_GREEN_COLOR,
    width: 0,
    height: 11,
    marginVertical: 7
  },
  proposalTargetBar: { backgroundColor: GRAPHITE_COLOR },
  proposalRecommendedBar: { backgroundColor: CYAN_COLOR },
  proposalBenchmarkBar: { backgroundColor: LILAC_COLOR }
});

export default styles;
