// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details-header[data-strategy=true] {
  margin-bottom: 40px !important;
}
.account-details-header .strategy-name {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}
.account-details-header .actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}
.account-details-header .actions .action-heldaway {
  cursor: pointer;
  height: 20px;
}
.account-details-header .actions .tooltip-heldaway {
  pointer-events: auto !important;
  width: 200px;
  box-shadow: 0px 12px 16px -4px rgb(179, 179, 179);
}
.account-details-header .actions .tooltip-heldaway::after {
  top: -2px;
  right: 10px;
  transform: rotate(-90deg);
}
.account-details-header .actions .tooltip-heldaway button {
  background: none;
}
.account-details-header .actions .asset-classes-choice {
  margin: 0 14px 0 0;
}
.account-details-header .actions .asset-classes-choice.small .c-input.c-checkbox .c-indicator.icon-checkmark {
  margin: 4px 0 0 10px;
}
.account-details-header .actions .show-more {
  border: 0;
  background: none;
  position: relative;
}
.account-details-header .actions .show-more .fs-icon-dot {
  line-height: 24px;
  font-size: 24px;
  height: 24px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/accounts/details/header/styles.scss"],"names":[],"mappings":"AAGE;EACE,8BAAA;AAFJ;AAKE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAHJ;AAME;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAJJ;AAMI;EACE,eAAA;EACA,YAAA;AAJN;AAOI;EACE,+BAAA;EACA,YAAA;EAGA,iDAAA;AALN;AAOM;EACE,SAAA;EACA,WAAA;EACA,yBAAA;AALR;AAQM;EACE,gBAAA;AANR;AAUI;EACE,kBAAA;AARN;AAUM;EACE,oBAAA;AARR;AAYI;EACE,SAAA;EACA,gBAAA;EACA,kBAAA;AAVN;AAYM;EACE,iBAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;AAVR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.account-details-header {\n  &[data-strategy='true'] {\n    margin-bottom: 40px !important;\n  }\n\n  .strategy-name {\n    position: absolute;\n    font-size: 14px;\n    font-weight: 500;\n    margin-top: 10px;\n  }\n\n  .actions {\n    margin-left: auto;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    .action-heldaway {\n      cursor: pointer;\n      height: 20px;\n    }\n\n    .tooltip-heldaway {\n      pointer-events: auto !important;\n      width: 200px;\n      -webkit-box-shadow: 0px 12px 16px -4px rgba(179, 179, 179, 1);\n      -moz-box-shadow: 0px 12px 16px -4px rgba(179, 179, 179, 1);\n      box-shadow: 0px 12px 16px -4px rgba(179, 179, 179, 1);\n\n      &::after {\n        top: -2px;\n        right: 10px;\n        transform: rotate(-90deg);\n      }\n\n      button {\n        background: none;\n      }\n    }\n\n    .asset-classes-choice {\n      margin: 0 14px 0 0;\n\n      &.small .c-input.c-checkbox .c-indicator.icon-checkmark {\n        margin: 4px 0 0 10px;\n      }\n    }\n\n    .show-more {\n      border: 0;\n      background: none;\n      position: relative;\n\n      .fs-icon-dot {\n        line-height: 24px;\n        font-size: 24px;\n        height: 24px;\n        display: block;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
