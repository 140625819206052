/* eslint-disable class-methods-use-this, react/no-unstable-nested-components */
import cn from 'classnames';
import AccountTags from 'components/advisor/accounts/account-tags';
import FeatureLimitReachedModal from 'components/advisor/feature-limit';
import GoalsHint from 'components/advisor/goals-hint';
import MoveAccounts from 'components/advisor/investors/move-accounts';
import ModelAutosuggest from 'components/advisor/model-portfolio-autocomplete';
import AggregatedRiskScoreBubble from 'components/advisor/utils/score-bubble/aggregated-risk';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import RiskScoreBubble from 'components/advisor/utils/score-bubble/risk';
import ToleranceScoreBubble from 'components/advisor/utils/score-bubble/tolerance';
import Choice from 'components/form/choice';
import LoadingButton from 'components/loading-button';
import { SortableHeader } from 'components/utils/react-table';
import TableItemDeleteModal from 'components/utils/table-item-delete-modal';
import { TAX_OPTIONS } from 'constants/accounts';
import withDriftThreshold from 'hocs/drift-threshold';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Select from 'react-select';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { accountsWithScoredPrismIntentsSelector } from 'selectors/prism';
import { triggerAccountPrismScore } from 'utils/prism';
import { getScoreAgg } from 'utils/utils';
import BulkUpdateAccountsModal from '../BulkUpdateAccounts';
import './styles.scss';

const SELECT_VALUES = [
  { name: 'View All', value: 'all' },
  { name: 'Excluded', value: 'excluded' },
  { name: 'Held-away', value: 'held-away' }
];

class InvestorAccountsOverviewTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bulkUpdateModalShown: false,
      checkBox: {},
      copyingAccountsToModels: false,
      deleteAccountModal: { show: false, items: {} },
      filter: SELECT_VALUES[0].value,
      loadingExcluded: false,
      modelsLimitModal: { show: false },
      moveAccountsModal: { show: false },
      totalMatchingModelOverall: 0,
      totalMatchingModelOverallLoading: true
    };

    this.investorAccountOverviewTable = React.createRef();
  }

  componentDidMount() {
    const { prismProvider } = this.context;
    const { accounts } = this.props;

    const updatedCheckBox = [];
    for (let i = 0; i < accounts.length; i += 1) updatedCheckBox[accounts[i].id] = false;
    updatedCheckBox.checkAll = false;

    const newCheckBox = { ...updatedCheckBox };
    this.setState({ checkBox: newCheckBox }, () => {
      this.updateSelectedIds();
    });

    this.getWeightedAverageScoreModels();

    if (window.location.hash === '#accounts')
      this.investorAccountOverviewTable.current.scrollIntoView({ behavior: 'smooth' });

    prismProvider.getAccountPrismIntents();
  }

  componentDidUpdate(prevProps) {
    const { accounts } = this.props;
    const { accounts: prevAccounts } = prevProps;

    if (!_.isEqual(this.getExcludedAccounts(prevAccounts), this.getExcludedAccounts(accounts)))
      this.setState({ loadingExcluded: false });

    if (prevAccounts.length !== accounts.length) {
      this.updateCheckBox();
      this.getWeightedAverageScoreModels();
    }
  }

  getWeightedAverageScoreModels = () => {
    const { accounts } = this.props;
    const filteredAccounts = this.filterAccounts(accounts);
    const modelsWithAccountValue = filteredAccounts
      .filter(account => account.target_model && !account.excluded)
      .map(account => ({
        ...account.target_model,
        prism_score_summary: { overall: account.target_model.prism_overall },
        value: account.value
      }));

    if (modelsWithAccountValue.length) {
      const totalPrismScore = getScoreAgg(modelsWithAccountValue);
      this.setState({ totalMatchingModelOverall: totalPrismScore.overall });
    } else this.setState({ totalMatchingModelOverall: 0, totalMatchingModelOverallLoading: false });
  };

  getExcludedAccounts = accounts =>
    accounts.reduce((acc, account) => ({ ...acc, [account.id]: account.excluded }), {});

  selectAllCheckBox = () => {
    const { checkBox } = this.state;
    const newCheckBox = {};
    const keys = Object.keys(checkBox || {});
    const { checkAll } = checkBox;
    keys.map(key => {
      newCheckBox[key] = !checkAll;
    });
    this.setState({ checkBox: newCheckBox }, () => {
      this.updateSelectedIds();
    });
  };

  checkBoxClick = (ev, id) => {
    const { checkBox } = this.state;
    checkBox[id] = !checkBox[id];
    this.setState({
      checkBox: { ...checkBox }
    });
    this.updateSelectedIds();
  };

  updateSelectedIds = () => {
    const { checkBox } = this.state;
    const { accountProvider } = this.context;
    const selectedIds = [];
    const keys = Object.keys(checkBox);
    for (let i = 0; i < keys.length; i += 1)
      if (checkBox[keys[i]] && keys[i] !== 'checkAll') selectedIds.push(keys[i]);
    if (selectedIds.length !== 0) accountProvider.changeBtnClass('btn-selected');
    else accountProvider.changeBtnClass('btn-not-selected');

    accountProvider.updateSelectedId(selectedIds);
  };

  updateCheckBox = () => {
    const { accounts } = this.props;
    const updatedCheckBox = [];
    for (let i = 0; i < accounts.length; i += 1) updatedCheckBox[accounts[i].id] = false;
    updatedCheckBox.checkAll = false;
    const newCheckBox = { ...updatedCheckBox };
    this.setState({ checkBox: newCheckBox }, () => {
      this.updateSelectedIds();
    });
  };

  excludeFromPrism = (ev, account) => {
    const { getData } = this.props;
    const { accountProvider } = this.context;

    this.setState({ loadingExcluded: true });

    accountProvider.updatePatch(account.id, { excluded: !account.excluded }).then(() => {
      getData();
      this.getWeightedAverageScoreModels();
    });
  };

  showDeleteAccountModal = event => {
    if (event) event.stopPropagation();
    const { selectedAccountIds } = this.props;
    this.setState({ deleteAccountModal: { show: true, items: selectedAccountIds } });
  };

  hideDeleteAccountModal = () => {
    this.setState({ deleteAccountModal: { show: false, items: null } });
  };

  showBulkUpdateModal = () => {
    this.setState({ bulkUpdateModalShown: true });
  };

  hideBulkUpdateModal = () => {
    this.setState({ bulkUpdateModalShown: false });
  };

  changeFilter = event => {
    this.setState({ filter: event.target.value });
  };

  filterAccounts = accounts => {
    const { filter } = this.state;
    let filteredAccounts;
    switch (filter) {
      case 'excluded':
        filteredAccounts = accounts.filter(a => a.excluded);
        break;
      case 'held-away':
        filteredAccounts = accounts.filter(a => a.held_away);
        break;
      default:
        filteredAccounts = accounts;
    }

    return filteredAccounts;
  };

  deleteSelectedAccounts = account => {
    const { accountProvider } = this.context;
    const { getData } = this.props;

    account.forEach(item => {
      accountProvider.deleteUsingId(item).then(() => {
        this.updateCheckBox();
        getData();
      });
    });
    this.deleteModal.modal.hide();
    accountProvider.updateSelectedId([]);
    toast.success(() => <div>Accounts removed successfully.</div>);
  };

  toggleMoveAccountsModal = () => {
    const { moveAccountsModal } = this.state;
    this.setState({
      moveAccountsModal: {
        show: !moveAccountsModal.show
      }
    });
  };

  moveAccounts = investorId => {
    const { selectedAccountIds, getData, investor } = this.props;
    const { accountProvider, investorProvider, prospectProvider } = this.context;
    const { moveAccountsModal } = this.state;
    const clientProvider = investor.is_prospect ? prospectProvider : investorProvider;
    clientProvider
      .moveAccounts(investorId, selectedAccountIds)
      .then(() => {
        toast.success(() => <div>Accounts moved successfully.</div>);
        getData();
      })
      .catch(() => toast.error(() => <div>Sorry, something went wrong.</div>));
    this.setState({
      moveAccountsModal: {
        show: !moveAccountsModal.show
      }
    });
    accountProvider.updateSelectedId([]);
    this.updateCheckBox();
  };

  copyAccountsToModels = () => {
    const { selectedAccountIds } = this.props;
    const { accountProvider, routerActions } = this.context;

    this.setState({ copyingAccountsToModels: true });

    accountProvider
      .copyAccountsToModels(selectedAccountIds)
      .then(responses => {
        const errors = responses.filter(r => !!r.error);
        if (responses.length === errors.length) {
          const errorTypes = new Set(errors.map(r => r.error.code));
          if (errorTypes.size === 1 && errorTypes.has(402)) this.setLimitReached();
          else
            toast.error(() => <div>Sorry! Something went wrong while copying the accounts.</div>);
        } else {
          const ids = responses.filter(r => !!r.data && !!r.data.id).map(r => r.data.id);
          const successPlural = `${ids.length > 1 ? 's' : ''}`;

          const totalErrors = responses.length - ids.length;
          const errorPlural = `${totalErrors > 1 ? 's' : ''}`;

          toast.success(() => (
            <div>
              <div>
                {ids.length} Account{successPlural} copied.
              </div>

              {totalErrors > 0 && (
                <div>
                  {totalErrors} error{errorPlural}.
                </div>
              )}
            </div>
          ));

          if (ids.length === 1)
            routerActions.push({ pathname: `/advisor/models/${ids[0]}/risk-analysis` });
          else if (ids.length > 1) routerActions.push({ pathname: `/advisor/models` });
        }
      })
      .catch(() => toast.error(() => <div>Sorry, something went wrong.</div>))
      .finally(() => {
        this.setState({ copyingAccountsToModels: false });
      });
  };

  setLimitReached = () => {
    this.setState({ modelsLimitModal: { show: true } });
  };

  unsetLimitReached = () => {
    this.setState({ modelsLimitModal: { show: false } });
  };

  getPrismScore = accountId => {
    const { accountProvider } = this.context;
    const { getData } = this.props;

    // getData is a function prop that returns and array of promises
    triggerAccountPrismScore(accountId, accountProvider, getData);
  };

  onChangeTaxStatus = account => selection => {
    const newTaxStatus = selection ? selection.value : '';
    const { accountProvider } = this.context;
    accountProvider
      .updatePatch(account.id, { tax_status: newTaxStatus })
      .then(({ data, error }) => {
        if (error) {
          toast.error(() => <div>Sorry, something went wrong.</div>);
          return;
        }

        if (!data.tax_status)
          toast.success(() => <div>Tax status for {account.name} is not defined.</div>);
        else {
          const tax = TAX_OPTIONS.find(tax => tax.value === data.tax_status);
          if (!tax) toast.error(() => <div>Sorry, something went wrong.</div>);
          else
            toast.success(() => (
              <div>
                <span role="img" aria-label="thumbs-up">
                  👍
                </span>{' '}
                Tax status for {account.name} is {tax.label}.
              </div>
            ));
        }
      });
  };

  onChangeMatchingModel = (account, model) => {
    const strategyId = model ? model.id : null;
    const { investor } = this.props;
    const { accountProvider, investorProvider } = this.context;
    accountProvider.updatePatch(account.id, { matching_model: strategyId }).then(({ error }) => {
      if (error) {
        toast.error(() => <div>Sorry, something went wrong.</div>);
        return;
      }

      if (!strategyId)
        toast.success(() => <div>Matching model for {account.name} is not defined.</div>);
      else
        toast.success(() => (
          <div>
            <span role="img" aria-label="thumbs-up">
              👍
            </span>{' '}
            {account.name} is matched with {model.name}.
          </div>
        ));

      this.getWeightedAverageScoreModels();

      if (model?.is_strategy || account?.target_model?.is_strategy)
        investorProvider.get(investor.id);
    });
  };

  isValidNumber = number => _.isNumber(number) && !_.isNaN(number);

  render() {
    const {
      accounts,
      investor,
      isHealthyDrift,
      prismScoresInProgress,
      scoredPrismIntents,
      selectedAccountIds
    } = this.props;

    const {
      bulkUpdateModalShown,
      checkBox,
      copyingAccountsToModels,
      deleteAccountModal,
      filter,
      loadingExcluded,
      modelsLimitModal,
      moveAccountsModal,
      totalMatchingModelOverall,
      totalMatchingModelOverallLoading
    } = this.state;

    const {
      addAccount,
      authProvider,
      showTargetScoreWizard,
      user,
      user: {
        advisor: {
          company: { tolerance_to_goals_mapping: goalsMapping }
        }
      }
    } = this.context;

    const { drift_summary: driftSummary } = investor;

    const filteredAccounts = this.filterAccounts(accounts);
    const investorPrismOverall = investor?.aggregated_prism_scores?.overall;
    const investorTargetOverall = investor?.aggregated_target_scores?.overall;
    const investorDrift = driftSummary?.overall ? driftSummary.overall : '-';
    const whichPath = investor.is_prospect ? 'prospects' : 'investors';

    const canAddAccounts = investor.is_prospect
      ? authProvider.hasAddProspectsPermissions(user)
      : authProvider.hasAddClientsPermissions(user);

    let countAccounts = 0;
    let totalValue = 0;
    let prismAgg = investorPrismOverall;
    let targetAgg = investorTargetOverall;
    let driftAgg = investorDrift;

    filteredAccounts
      .filter(a => !a.excluded || filter === 'excluded')
      .forEach(account => {
        countAccounts += 1;
        totalValue += account.value;
      });

    if (filter !== 'all') {
      prismAgg = getScoreAgg(filteredAccounts).overall;
      targetAgg = getScoreAgg(filteredAccounts, 'target_score_summary').overall;
      if (this.isValidNumber(prismAgg) && this.isValidNumber(targetAgg))
        driftAgg = prismAgg - targetAgg;
      else driftAgg = '-';
    }

    const customInvestor = {
      ...investor,
      accounts: filteredAccounts,
      aggregated_prism_scores: { overall: prismAgg },
      aggregated_target_scores: { overall: targetAgg }
    };

    let trash;
    if (selectedAccountIds.length <= 0)
      trash = (
        <span>
          <span className="fs-icon-trash-1 trash-icon disabled" />
        </span>
      );
    else
      trash = (
        <a onClick={event => this.showDeleteAccountModal(event)}>
          <span className="fs-icon-trash-1 trash-icon" />
        </a>
      );

    const tableColumns = [
      {
        Header: () => (
          <Choice
            small
            checked={checkBox.checkAll}
            toggle={event => this.selectAllCheckBox(event)}
          />
        ),
        id: 21,
        width: 50,
        accessor: p => (
          <Choice
            small
            checked={!!checkBox[p.id]}
            id={`checkbox-${p.id}`}
            toggle={event => this.checkBoxClick(event, p.id)}
          />
        ),
        sortable: false
      },
      {
        Header: () => <SortableHeader title="Account" />,
        id: 'advisorName',
        accessor: item => item.name,
        sortable: true,
        width: 240,
        minWidth: 200,
        className: 'text-left',
        headerClassName: 'text-left',
        Cell: ({ original: account }) => (
          <span>
            <Link to={`/advisor/${whichPath}/${investor.id}/account/${account.id}`}>
              {account.display_name}
            </Link>
            <AccountTags account={account} />
          </span>
        ),
        Footer: (
          <span>
            <b>Total Accounts : {countAccounts} </b>
          </span>
        )
      },
      {
        Header: () => <SortableHeader title="Portfolio Value" />,
        id: 6,
        width: 135,
        filterable: false,
        accessor: item => (item && item.value) || 0,
        Cell: ({ original: account }) =>
          account.value ? (
            <FormattedNumber value={account.value} format="currency" />
          ) : (
            <span>-</span>
          ),
        Footer: (
          <b>
            <FormattedNumber value={totalValue} format="currency" />
          </b>
        )
      },
      {
        Header: () => <SortableHeader title="PRISM" />,
        id: 7,
        width: 85,
        filterable: false,
        accessor: item =>
          (item && item.prism_score_summary && item.prism_score_summary.overall) || 0,
        Cell: ({ original: account }) => {
          const isMathedModelStrategy = account?.target_model?.is_strategy;
          return (
            <RiskScoreBubble
              element={
                !account.prism_score_summary && scoredPrismIntents[account.id]
                  ? { ...account, prism_score_summary: { overall: scoredPrismIntents[account.id] } }
                  : account
              }
              inProgress={prismScoresInProgress.includes(account.id)}
              onGenerate={() => this.getPrismScore(account.id)}
              isStrategy={isMathedModelStrategy}
            />
          );
        },
        Footer: <AggregatedRiskScoreBubble element={customInvestor} />
      },
      {
        Header: () => <SortableHeader title="Risk Tolerance" />,
        id: 8,
        width: 100,
        filterable: false,
        accessor: item =>
          (item && item.target_score_summary && item.target_score_summary.overall) || 0,
        Cell: ({ original: account }) =>
          account.target_score_summary ? (
            <ToleranceScoreBubble
              element={account}
              investor={investor}
              onGenerate={() => showTargetScoreWizard()}
              isProspect={investor.is_prospect}
            />
          ) : (
            '-'
          ),
        Footer: (
          <AggregatedToleranceScoreBubble
            element={customInvestor}
            onClick={() => showTargetScoreWizard()}
          />
        )
      },
      {
        Header: () => <SortableHeader title="Drift" />,
        id: 9,
        width: 70,
        className: 'drift-td',
        filterable: false,
        accessor: item => item?.drift_summary?.overall ?? 0,
        Cell: ({ original: account }) => {
          const { drift_summary: driftSummary, is_healthy: isHealthy } = account;
          if (driftSummary?.overall)
            return (
              <div className={cn('drift', { red: !isHealthy })}>
                {driftSummary.overall.toFixed(1)}
              </div>
            );
          return <span>-</span>;
        },
        Footer: (
          <span
            className={cn('drift', { red: filter !== 'excluded' && !isHealthyDrift(driftAgg) })}
          >
            <b>{this.isValidNumber(driftAgg) ? Math.abs(driftAgg).toFixed(1) : '-'}</b>
          </span>
        )
      },
      {
        Header: 'Exclude',
        id: 10,
        width: 55,
        sortable: false,
        accessor: item => !item.excluded,
        Cell: ({ original: account }) => (
          <span>
            {loadingExcluded ? (
              <LoadingButton className="btn btn-solid table-btn exclude-loading" loading />
            ) : (
              <Choice
                small
                title=""
                id={`excluded-${account.id}`}
                checked={account.excluded}
                toggle={event => this.excludeFromPrism(event, account)}
              />
            )}
          </span>
        )
      },
      {
        Header: 'Tax status',
        id: 'tax-status',
        width: 120,
        className: 'select-col',
        Cell: ({ original: account }) => (
          <Select
            defaultValue={
              account.tax_status ? TAX_OPTIONS.find(tax => tax.value === account.tax_status) : null
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={this.onChangeTaxStatus(account)}
            options={TAX_OPTIONS}
            className="tax-select"
            isClearable
          />
        )
      },
      {
        Header: 'Matching Model',
        id: 'matching-model',
        className: 'select-col investor-account-overview-table__matching-model',
        filterable: false,
        width: 'auto',
        Cell: ({ original: account }) => (
          <ModelAutosuggest
            key={account.id}
            id={`account-${account.id}`}
            selected={account.target_model}
            onSelect={_.partial(this.onChangeMatchingModel, account)}
          />
        ),
        Footer: (
          <div className="investor-account-overview-table__matching-model__footer">
            <AggregatedRiskScoreBubble
              element={{
                aggregated_prism_scores: { overall: totalMatchingModelOverall }
              }}
              inProgress={totalMatchingModelOverallLoading}
              isListView={totalMatchingModelOverallLoading}
              loadingButtonClassName="btn btn-solid table-btn total-loading-button"
            />
          </div>
        )
      }
    ];

    return (
      <div
        ref={this.investorAccountOverviewTable}
        className="investor-account-overview-table"
        id="accounts-overview-table"
      >
        <MoveAccounts
          isOpen={moveAccountsModal.show}
          onRequestClose={this.toggleMoveAccountsModal}
          moveAccounts={this.moveAccounts}
          currentInvestor={investor}
        />

        <div className="actions">
          <div className="actions--left">
            {trash}
            <LoadingButton
              className="btn btn-secondary move-accounts-btn"
              disabled={!selectedAccountIds || !selectedAccountIds.length}
              loading={copyingAccountsToModels}
              onClick={this.copyAccountsToModels}
            >
              Copy to model
            </LoadingButton>
            <button
              type="button"
              disabled={!selectedAccountIds || !selectedAccountIds.length}
              className="btn btn-secondary-2 move-accounts-btn"
              onClick={this.toggleMoveAccountsModal}
            >
              Assign to another client
            </button>
          </div>
          <div className="actions--right">
            {canAddAccounts && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary add-account-btn"
                  onClick={() => addAccount()}
                >
                  Add Account
                </button>
                <button
                  type="button"
                  className="btn btn-secondary add-account-btn"
                  onClick={this.showBulkUpdateModal}
                >
                  Bulk Update
                </button>
              </>
            )}
            <div className="c-select-wrap">
              <select
                className="c-select filter"
                onChange={event => this.changeFilter(event)}
                defaultValue={SELECT_VALUES[0]}
              >
                {SELECT_VALUES.map(item => (
                  <option value={item.value} key={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <ReactTable
            data={filteredAccounts}
            columns={tableColumns}
            minRows={1}
            showPageSizeOptions={false}
            resizable={false}
            noDataText={
              <span>
                No Accounts found.{' '}
                {canAddAccounts && <a onClick={() => addAccount()}>Add accounts now.</a>}
              </span>
            }
            ref={c => {
              this.reactTable = c;
            }}
          />
          <GoalsHint goalsMapping={goalsMapping} />
          {deleteAccountModal.show && (
            <TableItemDeleteModal
              {...deleteAccountModal}
              onHide={this.hideDeleteAccountModal}
              onDelete={this.deleteSelectedAccounts}
              label="Account"
              verb="Delete"
              ref={c => {
                this.deleteModal = c;
              }}
            />
          )}
        </div>

        <FeatureLimitReachedModal
          content={{
            action: 'generating',
            kind: 'model portfolios'
          }}
          show={modelsLimitModal.show}
          onHide={this.unsetLimitReached}
        />

        <BulkUpdateAccountsModal
          investor={investor}
          onHide={this.hideBulkUpdateModal}
          selectedPortfolioIds={selectedAccountIds}
          show={bulkUpdateModalShown}
        />
      </div>
    );
  }
}

InvestorAccountsOverviewTable.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  addAccount: PropTypes.func.isRequired,
  authProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  prismProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

InvestorAccountsOverviewTable.propTypes = {
  accounts: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  investor: PropTypes.object.isRequired,
  isHealthyDrift: PropTypes.func.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  scoredPrismIntents: PropTypes.object.isRequired,
  selectedAccountIds: PropTypes.array
};

InvestorAccountsOverviewTable.defaultProps = {
  selectedAccountIds: []
};

export default compose(
  withDriftThreshold,
  connect(state => ({
    prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state),
    scoredPrismIntents: accountsWithScoredPrismIntentsSelector(state)
  }))
)(InvestorAccountsOverviewTable);
