/* eslint-disable class-methods-use-this */
import MultipleInput from 'components/multiple-input';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HouseholdsInvestorListAddRow extends Component {
  constructor(props) {
    super(props);
    this.onInvestorNameChange = _.debounce(this.onInvestorNameChange, 750);
  }

  filterExistingUsers = (investors, members) =>
    _.differenceWith(investors, members, (i, m) => i.id === m.investor_id);

  onInvestorNameChange = newValue => {
    const { householdProvider } = this.props;
    householdProvider.searchSuggestions({ search: newValue || undefined });
  };

  renderInvestor = inv => <span>{inv.full_name}</span>;

  render() {
    const { household, addMember, cancelAddMember, householdSuggestedMembers } = this.props;
    const members = household.accounts;
    const filteredInvestors = this.filterExistingUsers(householdSuggestedMembers, members);

    return (
      <tbody>
        <tr>
          <td width="8%" className="client-search">
            <div className="input-section input-section-no-margin">
              <MultipleInput
                autosuggestProps={{
                  // eslint-disable-next-line camelcase
                  suggestions: filteredInvestors || [],
                  getSuggestionValue: s => s.full_name,
                  renderSuggestion: this.renderInvestor
                }}
                cancelIcon
                inputProps={{
                  placeholder: 'Search by client name',
                  type: 'search',
                  onChange: this.onInvestorNameChange
                }}
                onItemStaged={addMember}
                onCancel={cancelAddMember}
                onItemRemoved={this.removeInvestor}
                renderSelectedItem={this.renderInvestor}
                theme={{
                  container: 'react-autosuggest__container',
                  containerOpen: 'open react-autosuggest__container--open',
                  input: 'form-control household-member-search react-autosuggest__input',
                  suggestionsContainer:
                    'dropdown-menu react-household-autosuggest__suggestions-container',
                  suggestion: 'dropdown-item-new react-autosuggest__suggestion'
                }}
              />
            </div>
          </td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
      </tbody>
    );
  }
}

HouseholdsInvestorListAddRow.propTypes = {
  accountProvider: PropTypes.object.isRequired,
  addMember: PropTypes.func.isRequired,
  cancelAddMember: PropTypes.func.isRequired,
  household: PropTypes.object.isRequired, // already fetched (see parent component)
  householdSuggestedMembers: PropTypes.array.isRequired,
  householdProvider: PropTypes.object.isRequired,
  investors: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired
};

export default HouseholdsInvestorListAddRow;
