import MultiCheckboxField from 'components/form/multi-checkbox-field';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { choicesToOptions } from 'utils/questionnaire';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import { OTHER_VALUE } from '../constants';
import './styles.scss';

const GoalApproachQuestion = ({ question, field, ...props }) => {
  const goalOptions = choicesToOptions(question.data?.goals);
  const approachOptions = choicesToOptions(question.data?.approaches);

  return (
    <Question
      {...props}
      question={question}
      nextDisabled={
        field.approach.invalid ||
        field.goal.invalid ||
        field.other_approach.invalid ||
        field.other_goal.invalid
      }
    >
      <div className="goals">
        <p>
          <FormattedMessage id="rtq.question-2.goal-title" />
        </p>
        <MultiCheckboxField
          field={field.goal}
          flavor={question.data?.flavor}
          multiple={question.data?.multiple_answers_allowed}
          options={goalOptions}
          otherField={field.other_goal}
          otherValue={OTHER_VALUE}
        />
      </div>
      <div className="approaches">
        <p>
          <FormattedMessage id="rtq.question-2.approach-title" />
        </p>
        <MultiCheckboxField
          field={field.approach}
          flavor={question.data?.flavor}
          multiple={question.data?.multiple_answers_allowed}
          options={approachOptions}
          otherField={field.other_approach}
          otherValue={OTHER_VALUE}
        />
      </div>
    </Question>
  );
};

GoalApproachQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired,
  flavor: PropTypes.string.isRequired
};

export default GoalApproachQuestion;
