import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const DEFAULT_INVESTMENT_VALUE = 500000;
const DEFAULT_PERCENTAGE_VALUE = 1;

const useCashInvestableAssets = ({ field, question, totalAssets }) => {
  const [estimatedInvested, setEstimatedInvested] = useState(0);

  const isValidAmount = value => !_.isUndefined(value) && value !== '';

  const onInvestmentChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (isValidAmount(value) && value !== field.investment.value)
        field.investment.onChange(value);
    }, 500),
    [field.investment.value]
  );

  const onPercentageChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (isValidAmount(value) && value !== field.percentage.value)
        field.percentage.onChange(value / 100);
    }, 500),
    [field.percentage.value]
  );

  useEffect(() => {
    if (isValidAmount(field.percentage.value) && isValidAmount(field.investment.value))
      setEstimatedInvested(field.percentage.value * field.investment.value);
  }, [field.percentage.value, field.investment.value]);

  useEffect(() => {
    if (!field.investment.touched && !isValidAmount(field.investment.value))
      field.investment.autofill(
        totalAssets || (question.data?.default_value?.investment ?? DEFAULT_INVESTMENT_VALUE)
      );
  }, [
    totalAssets,
    field.investment.touched,
    field.investment.value,
    question.data?.default_value?.investment
  ]);

  useEffect(() => {
    if (!field.percentage.touched && !isValidAmount(field.percentage.value)) {
      const defaultPercentage =
        question.data?.default_value?.percentage ?? DEFAULT_PERCENTAGE_VALUE;
      field.percentage.autofill(defaultPercentage);
    }
  }, [field.percentage.touched, field.percentage.value, question.data?.default_value?.percentage]);

  const investmentInputProps = {
    allowNegative: true,
    className: cn('input-investment', {
      'has-danger': field.investment.touched && field.investment.error
    }),
    decimalScale: 2,
    onValueChange: onInvestmentChange,
    prefix: '$',
    thousandSeparator: true,
    value: field.investment.value
  };

  const percentageInputProps = {
    allowNegative: false,
    className: cn('input-percentage', {
      'has-danger': field.percentage.touched && field.percentage.error
    }),
    decimalScale: 2,
    onValueChange: onPercentageChange,
    suffix: '%',
    value: field.percentage.value * 100
  };

  return {
    estimatedInvested,
    investmentInputProps,
    percentageInputProps
  };
};

useCashInvestableAssets.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired,
  totalAssets: PropTypes.number.isRequired
};

export default useCashInvestableAssets;
