export default {
  type: 'serial',
  categoryField: 'index',
  categoryAxis: {
    autoGridCount: false,
    axisThickness: 2,
    gridCount: 0,
    labelsEnabled: false,
    showFirstLabel: false,
    startOnAxis: true
  },
  colors: ['#7c8c94', '#31E3FF'],
  graphs: [
    {
      id: 'target',
      lineThickness: 2,
      title: 'Current Portfolio',
      valueField: 'target'
    },
    {
      id: 'recommended',
      lineThickness: 2,
      title: 'Recommended',
      valueField: 'recommended'
    }
  ],
  valueAxes: [
    {
      id: 'ValueAxis-1',
      axisThickness: 0,
      dashLength: 5,
      inside: true,
      minimum: 0,
      showFirstLabel: false,
      tickLength: 0,
      unit: '$',
      unitPosition: 'left',
      usePrefixes: true
    }
  ],
  balloon: {
    borderColor: '#DFE1E5',
    borderThickness: 1,
    color: '#000',
    fillAlpha: 1,
    shadowAlpha: 0,
    textAlign: 'left'
  },
  chartCursor: {
    enabled: true,
    categoryBalloonEnabled: false,
    cursorAlpha: 0,
    graphBulletAlpha: 0,
    selectionAlpha: 0,
    valueLineAlpha: 0
  },
  legend: {
    enabled: true,
    align: 'right',
    marginLeft: 0,
    marginRight: 0,
    markerLabelGap: 7,
    markerSize: 14,
    position: 'top',
    valueWidth: 0
  }
};
