import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SCORE_LEVELS = {
  RISK_AVERSE: {
    label: <FormattedMessage id="score.level.risk-averse" />,
    min: 1,
    max: 2
  },
  CAUTIOUS: {
    label: <FormattedMessage id="score.level.cautious" />,
    min: 2.1,
    max: 4
  },
  BALANCED: {
    label: <FormattedMessage id="score.level.balanced" />,
    min: 4.1,
    max: 6
  },
  GROWTH_ORIENTED: {
    label: <FormattedMessage id="score.level.growth-oriented" />,
    min: 6.1,
    max: 9
  },
  HIGH_RISK_TAKER: {
    label: <FormattedMessage id="score.level.high-risk-taker" />,
    min: 9.1,
    max: 10
  }
};

/*
  This function is used to get the score level based on the score
  @param {number} score
  @return {object} score level
*/
export const getScoreLevel = (score, levels = SCORE_LEVELS) =>
  Object.values(levels).find(range => score >= range.min && score <= range.max) || {};
