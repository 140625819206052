import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AdvisorContext } from 'containers/advisor';
import BulkUpdateModal from 'components/advisor/bulk-update';

const BulkUpdateSecuritiesModal = ({ onHide, selectedPortfolioIds, show }) => {
  const { customSecurityProvider, routerActions } = useContext(AdvisorContext);
  const uploadPortfoliosHandler = async files => customSecurityProvider.saveBulkReturnsCSV(files);

  const importPortfolios = ({ files, setImporting, setFiles }) => {
    setImporting(true);
    uploadPortfoliosHandler(files).then(() => {
      const filteredFileNames = {
        filenames: files.filter(file => file.name.includes('.csv')).map(file => file.name)
      };
      customSecurityProvider.checkBulkFileCSVName(filteredFileNames).finally(() => {
        setImporting(false);
        routerActions.push('/advisor/securities/bulk');
        setFiles([]);
        onHide();
      });
    });
  };

  return (
    <BulkUpdateModal
      onImportHandler={importPortfolios}
      onHide={onHide}
      show={show}
      selectedPortfolioIds={selectedPortfolioIds}
      startInStepTwo
    />
  );
};

BulkUpdateSecuritiesModal.defaultProps = {
  investor: null
};

BulkUpdateSecuritiesModal.propTypes = {
  investor: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  selectedPortfolioIds: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired
};

export default BulkUpdateSecuritiesModal;
