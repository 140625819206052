import ExpandableButton from 'app/components/expandable-button';
import { investorAccountsSelector, investorSelector } from 'components/advisor/investors/selectors';
import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import Card from 'components/card';
import Legend from 'containers/risk-tolerance-questionnaire/result/legend';
import RiskRanges from 'containers/risk-tolerance-questionnaire/result/risk-ranges';
import PersonalityType from 'containers/risk-tolerance-questionnaire/result/score-context/personality-type';
import RiskCapacity from 'containers/risk-tolerance-questionnaire/result/score-context/risk-capacity';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getRiskToleranceLatestUpdate } from 'utils/utils';
import './styles.scss';

const RiskToleranceScoreCapacityAndPersonality = ({
  accounts,
  fixedScore,
  fixedScoreLabel,
  investor,
  lastQuestionnaire,
  latestUpdate,
  manualScore,
  scoreSummary,
  showTargetScoreWizard
}) => {
  const [showRiskLegend, setShowRiskLegend] = useState(false);

  const overallScoreLatestUpdate = getRiskToleranceLatestUpdate(investor, accounts, [latestUpdate]);
  const riskWillingnessCardStyles = manualScore ? { alignItems: 'center' } : {};

  const toggleRiskLenged = () => {
    setShowRiskLegend(prevShowRiskLeged => !prevShowRiskLeged);
  };

  return (
    <div className="rts-capacity-and-personality-wrapper">
      <div
        className="rts-capacity-and-personality"
        style={{
          gridTemplateColumns: scoreSummary?.capacity ? 'min-content auto auto' : 'min-content auto'
        }}
      >
        <Card header="Risk Tolerance">
          <RiskOverall score={scoreSummary?.overall} small />
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => showTargetScoreWizard()}
          >
            Update Risk Tolerance
          </button>
          {overallScoreLatestUpdate && (
            <div className="latest-update">{`(Score latest update: ${moment
              .utc(overallScoreLatestUpdate)
              .fromNow()})`}</div>
          )}
        </Card>
        <Card header="Risk Willingness" contentStyles={riskWillingnessCardStyles}>
          {manualScore ? (
            <div className="hide-scores">
              <span className="fs-icon-lock lock-icon" />
              <span className="hide-scores-text">
                Send the risk tolerance questionnaire to the client to see the risk capacity.
              </span>
            </div>
          ) : (
            <>
              <PersonalityType
                flavor="advisor"
                investorName={investor?.full_name}
                score={scoreSummary.overall}
              />
              {scoreSummary?.overall &&
                scoreSummary?.tolerance &&
                scoreSummary.overall !== scoreSummary.tolerance && (
                  <div className="adjusted-score-alert">
                    <p>
                      {lastQuestionnaire?.answered_by} adjusted the questionnaire score.
                      <br />
                      The original value was <ScoreBubble score={scoreSummary.tolerance} />
                    </p>
                  </div>
                )}
            </>
          )}
        </Card>
        {!!scoreSummary?.capacity && (
          <Card header="Risk Ability">
            <RiskCapacity score={scoreSummary.capacity} />
          </Card>
        )}
      </div>

      <Card
        header={
          <div className="risk-header">
            What do these risk ranges mean?
            <ExpandableButton
              isCollapsed={showRiskLegend}
              buttonLabel="What does this mean?"
              onClick={toggleRiskLenged}
            />
          </div>
        }
      >
        <RiskRanges
          fixedScore={fixedScore}
          fixedScoreLabel={fixedScoreLabel}
          id="range-risk-tolerance"
          scoreSummary={scoreSummary}
          value={scoreSummary.overall}
        />
        {showRiskLegend && <Legend withCapacity={!!scoreSummary?.capacity} />}
      </Card>
    </div>
  );
};

RiskToleranceScoreCapacityAndPersonality.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  fixedScore: PropTypes.object,
  fixedScoreLabel: PropTypes.string,
  investor: PropTypes.object,
  lastQuestionnaire: PropTypes.object,
  latestUpdate: PropTypes.string,
  manualScore: PropTypes.bool,
  showTargetScoreWizard: PropTypes.func,
  scoreSummary: PropTypes.object.isRequired
};

RiskToleranceScoreCapacityAndPersonality.defaultProps = {
  accounts: [],
  fixedScore: null,
  fixedScoreLabel: '',
  investor: null,
  lastQuestionnaire: {},
  latestUpdate: null,
  manualScore: false,
  showTargetScoreWizard: () => {}
};

export default connect(
  state => ({
    accounts: investorAccountsSelector(state),
    investor: investorSelector(state)
  }),
  null
)(RiskToleranceScoreCapacityAndPersonality);
