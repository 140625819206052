import {
  FETCHING,
  SECURITY_BENCHMARKS_LIST,
  SECURITY_LIST_ES,
  SECURITY_SEARCH
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class MarketElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}search/security/`, params, null, null, true)
      .then(({ data }) => {
        this.dispatch(SECURITY_LIST_ES, { data: data.results });
        return data.results;
      });
  }

  securitySearch(search, filters) {
    const params = search ? { search, ...filters } : { ...filters };
    return this.provider.get(`${config.apiBase}search/security/`, params).then(({ data }) => {
      if (!data.error)
        this.dispatch(SECURITY_SEARCH, {
          data: params.search ? data.results : []
        });
      else
        this.dispatch(SECURITY_SEARCH, {
          data: null
        });
      return data;
    });
  }

  getSecurityBenchmarks() {
    return this.provider
      .get(`${config.apiBase}search/security/`, { is_benchmark: true, get_all: true })
      .then(({ data }) => {
        if (!data.error)
          this.dispatch(SECURITY_BENCHMARKS_LIST, {
            data: data.results
          });
        return data;
      });
  }
}

export default MarketElasticProvider;
