import ExpandableButton from 'app/components/expandable-button';
import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { trackAmplitudeEvent } from 'utils/tracking';
import Legend from '../legend';
import RiskRanges from '../risk-ranges';
import PersonalityType from '../score-context/personality-type';
import './styles.scss';

const AdjustScore = ({
  changeScore,
  closeModal,
  flavor,
  investorName,
  scoreSummary,
  showModal
}) => {
  const [overallScore, setOverallScore] = useState(scoreSummary.overall);
  const [showRiskLegend, setShowRiskLegend] = useState(false);

  const toggleRiskLenged = () => {
    setShowRiskLegend(prevShowRiskLeged => !prevShowRiskLeged);
  };

  return ReactDOM.createPortal(
    <Modal
      className="modal-lg"
      id="adjust-score-modal"
      modalStyle={{ display: showModal ? 'block' : 'none' }}
      onHidden={closeModal}
      show={showModal}
    >
      <ModalBody>
        <div className="adjust-score">
          <div className="adjust-score__title">
            <h4>
              <FormattedMessage id="rtq.result.adjust-score.title" values={{ flavor }} />
            </h4>
          </div>
          <div className="slider-container">
            <RiskRanges
              disabled={false}
              flavor={flavor}
              handleScore={setOverallScore}
              id="range-adjust-score"
              scoreSummary={scoreSummary}
            />
          </div>
          <div className="score-container">
            <PersonalityType
              className="score-container__personality-type"
              displayIcon={false}
              flavor={flavor}
              investorName={investorName}
              score={overallScore}
            />
          </div>
          <div className="buttons-container">
            <button
              type="button"
              className="btn btn-outline-primary font-weight-normal"
              onClick={() => {
                closeModal();
                trackAmplitudeEvent('rtq.adjust.cancel');
              }}
            >
              <FormattedMessage id="common.cancel" />
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                changeScore(overallScore);
                trackAmplitudeEvent('rtq.adjust.submit');
              }}
            >
              <FormattedMessage
                id="rtq.result.score-summary.feels-like-me-button"
                values={{ flavor, icon: <>&#x1F44D;</>, investorName }}
              />
            </button>
          </div>
          <div className="expandable-legend">
            <div className="expandable-legend__button">
              <ExpandableButton
                isCollapsed={showRiskLegend}
                buttonLabel="What does this mean?"
                onClick={toggleRiskLenged}
              />
            </div>
            {showRiskLegend && (
              <Legend
                parentClassName="expandable-legend__modal-legend"
                withCapacity={!!scoreSummary?.capacity}
              />
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>,
    document.getElementById('app-portal')
  );
};

AdjustScore.defaultProps = {
  changeScore: () => {},
  closeModal: () => {},
  flavor: 'advisor',
  investorName: '',
  showModal: false
};

AdjustScore.propTypes = {
  changeScore: PropTypes.func,
  closeModal: PropTypes.func,
  flavor: PropTypes.string,
  investorName: PropTypes.string,
  scoreSummary: PropTypes.object.isRequired,
  showModal: PropTypes.bool
};

export default AdjustScore;
