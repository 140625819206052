// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Signup .signup-wrapper {
  margin-top: 30px;
}
#Signup .signup-wrapper .hero-form-container {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/signup/styles.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAII;EACE,cAAA;AAFN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#Signup {\n  .signup-wrapper {\n    margin-top: 30px;\n\n    .hero-form-container {\n      margin: 0 auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
