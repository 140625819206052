import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import ErrorMessage from 'components/error-message';
import { VerboseErrorInput as Input, FormGroup } from 'components/form';
import { BackendValidation } from 'hocs/backend-validation';
import { validation } from 'utils/form';

const STATUS_MESSAGE = {
  exit: {
    status: 'exit',
    message: 'The process ended but was not satisfactory.'
  },
  success: {
    status: 'success',
    message: 'Thank you! Your integration should be live in the next 24-48 hours.'
  },
  unknown: {
    status: 'unknown',
    message: 'An unexpected error has occurred. Please try again later.'
  }
};

const validate = (values, props) => {
  const errors = {};
  errors.username = errors.username || validation.required(values.username);
  errors.password = errors.password || validation.required(values.password);
  return errors;
};

class BlackDiamondIntegrationComponent extends Component {
  constructor() {
    super();

    this.form = null;
    this.initialState = { loading: false };
    this.state = { ...this.initialState };

    this.onSubmit = this.onSubmit.bind(this);
  }

  finish = (status = STATUS_MESSAGE.exit) => {
    const { onComplete, skipError } = this.props;
    onComplete(status);
    skipError();
    this.setState(this.initialState);
  };

  onSubmit(values) {
    const { registerError, selectedSyncData } = this.props;
    const { integrationProvider } = this.context;

    this.setState({ loading: true });

    integrationProvider
      .setBlackDiamondIntegrationInfo({ ...selectedSyncData, ...values })
      .then(({ data }) => {
        if (!data) this.finish(STATUS_MESSAGE.unknown);
        else if (data.is_authenticated) this.finish(STATUS_MESSAGE.success);
        else {
          this.setState({ loading: false });
          registerError({
            error: {
              reason: 'Authentication Error',
              message: 'Unable to login with the provided credentials'
            }
          });
        }
      })
      .catch(() => this.finish(STATUS_MESSAGE.unknown));
  }

  render() {
    let error;
    const { loading } = this.state;
    const {
      backendErrors,
      fields,
      handleSubmit,
      provider: { name, image },
      skipError,
      submitting
    } = this.props;
    if (!_.isEmpty(backendErrors)) error = backendErrors.general;

    return (
      <div id="black-diamond-integration">
        <img src={image} alt={name} width={300} />
        <h1>Please enter your credentials for {name}</h1>
        <form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          <FormGroup className="form-group" {...fields.username} skipError={skipError}>
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              className="form-control"
              {...fields.username}
              skipError={skipError}
              placeholder={`${name} Username`}
            />
          </FormGroup>
          <FormGroup className="form-group" {...fields.password} skipError={skipError}>
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              className="form-control"
              {...fields.password}
              skipError={skipError}
              placeholder={`${name} Password`}
            />
          </FormGroup>

          {error && <ErrorMessage message={error} />}

          <div className="actions">
            <button
              type="button"
              className="btn btn-secondary-2"
              data-dismiss="modal"
              aria-label="Close"
              disabled={loading || submitting}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading || submitting}>
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

BlackDiamondIntegrationComponent.contextTypes = {
  integrationProvider: PropTypes.object.isRequired
};

BlackDiamondIntegrationComponent.propTypes = {
  onStart: PropTypes.func,
  onComplete: PropTypes.func,
  provider: PropTypes.object.isRequired,
  selectedSyncData: PropTypes.object,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

BlackDiamondIntegrationComponent.defaultProps = {
  onStart: () => {},
  onComplete: () => {},
  selectedSyncData: {}
};

const BlackDiamondIntegration = reduxForm({
  form: 'editBlackDiamondInfo',
  fields: ['username', 'password'],
  initialValues: {},
  validate
})(BackendValidation(BlackDiamondIntegrationComponent));

export default BlackDiamondIntegration;
