import { getCode, getData, getName } from 'country-list';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

export const US_CODE = 'US';

const CountryDropDown = ({ onChange, value }) => {
  const countryOptions = useMemo(
    () => getData()?.map(({ name }) => ({ label: name, value: name })),
    []
  );

  const handleOnChange = ({ value }) => {
    onChange(getCode(value));
  };

  const mapValue = value
    ? {
        label: getName(value),
        value: getName(value)
      }
    : null;

  return (
    <Select
      isClearable={false}
      onChange={handleOnChange}
      options={countryOptions}
      defaultValue={mapValue}
      value={mapValue}
      styles={{
        valueContainer: styles => ({
          ...styles,
          minHeight: '38px',
          height: '38px'
        }),
        control: styles => ({
          ...styles,
          height: '35px',
          minHeight: '38px',
          fontSize: '15px'
        }),
        singleValue: styles => ({
          ...styles,
          marginTop: '-2px',
          color: '#4f5a5d',
          fontWeight: '400'
        }),
        input: styles => ({ ...styles, color: '#4f5a5d', position: 'absolute', marginTop: '-2px' }),
        option: styles => ({ ...styles, fontSize: '15px', fontWeight: '400' })
      }}
    />
  );
};

CountryDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default CountryDropDown;
