import cn from 'classnames';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import { ROLES, isAdmin } from 'providers/auth';
import React, { useContext } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import DeactivateUserModal from '../../deactivate-user-modal';
import RolesSelect from '../../roles-select';
import { USER_ROLE_CHANGE_ERROR_MESSAGE, USER_ROLE_CHANGE_SUCCESSFUL_MESSAGE } from '../constants';

const AdvisorRow = ({ advisor, refreshUsers }) => {
  const { advisorProvider, authProvider, user } = useContext(AdvisorContext);

  const id = `advisor-${advisor.id}`;
  const isLoggedUser = user.advisor.id === advisor.id;
  const name = `${advisor.user.first_name} ${advisor.user.last_name}`;

  const onRoleChange = option => {
    advisorProvider
      .update({ id: advisor.id, role: option.value }, user.id)
      .then(response => {
        if (response.error) throw new Error();
        else {
          toast.success(USER_ROLE_CHANGE_SUCCESSFUL_MESSAGE(name, ROLES.labels[option.value]));
          refreshUsers();
          if (isLoggedUser) authProvider.getUser();
        }
      })
      .catch(() => toast.error(USER_ROLE_CHANGE_ERROR_MESSAGE));
  };

  return (
    <tr key={id} className={cn('parent-row', { 'logged-user': isLoggedUser })}>
      <td width="25%">{`${name} ${isAdmin(advisor.role) ? ' (Owner)' : ''}`}</td>

      <td aria-label="User Role Selection" className="text-left">
        <RolesSelect
          defaultValue={advisor.role}
          disabled={!!advisor.deleted}
          id={`role-${id}`}
          onChange={onRoleChange}
        />
      </td>

      <td className="text-left">
        {advisor.deleted && <span className="member-inactive">Inactive</span>}
        {!advisor.deleted && advisor.delete_requested && (
          <span className="member-inactive">Delete requested</span>
        )}
        {!advisor.deleted && !advisor.delete_requested && (
          <span className="member-active">Active</span>
        )}
      </td>

      <td className="text-left">
        {advisor.has_mfa ? (
          <span className="member-active">Enabled</span>
        ) : (
          <span className="member-inactive">Disabled</span>
        )}
      </td>

      <td className="text-right">
        {!advisor.deleted && authProvider.hasManagerPermissionsOrAbove(user) && (
          <Link
            className="btn btn-link"
            to={`/advisor/${isLoggedUser ? 'me' : advisor.id}/profile`}
          >
            View Profile
          </Link>
        )}
      </td>

      <td className="action action-cell hidden-bg">
        {!advisor.deleted &&
          !advisor.delete_requested &&
          !isAdmin(advisor.role) &&
          !isLoggedUser && <DeactivateUserModal advisor={advisor} refreshUsers={refreshUsers} />}
      </td>
    </tr>
  );
};

AdvisorRow.propTypes = {
  advisor: PropTypes.object.isRequired,
  refreshUsers: PropTypes.func.isRequired
};

export default AdvisorRow;
