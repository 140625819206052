import { createSelector } from 'reselect';

const templatesListSelector = state => Object.values(state.templates);

const publishedAndDraftTemplatesSelector = state =>
  state
    .filter(t => ['published', 'draft'].includes(t.status))
    .map(el => ({ ...el, value: el.id, label: el.name }));

export const proposalTemplatesSelector = createSelector([templatesListSelector], templates =>
  templates.filter(t => t.type === 'proposal')
);

export const proposalTemplateListSelector = createSelector([proposalTemplatesSelector], templates =>
  publishedAndDraftTemplatesSelector(templates)
);

export const ipsTemplatesSelector = createSelector([templatesListSelector], templates =>
  templates.filter(t => t.type === 'ips')
);

export const ipsTemplateListSelector = createSelector([ipsTemplatesSelector], templates =>
  publishedAndDraftTemplatesSelector(templates)
);
