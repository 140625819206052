import { Text, View } from '@react-pdf/renderer';
import { questionTypes as qt } from 'constants/questionnaire';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import useExpectedReturn from '../hooks';

const ExpectedReturnQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  if (question.question_type === qt.LEGACY)
    return (
      <ReportQuestion question={question}>
        <Text>{formatMoney(question.answer.amount)}</Text>
      </ReportQuestion>
    );

  const { questionText } = useExpectedReturn({ question });
  return (
    <ReportQuestion question={{ ...question, question: questionText }}>
      <View>
        <Text>Yearly return expected: {formatPercentage(question.answer.expected, 1)}</Text>
      </View>
      <View>
        <Text>Yearly return needed: {formatPercentage(question.answer.needed, 1)}</Text>
      </View>
    </ReportQuestion>
  );
};

ExpectedReturnQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ExpectedReturnQuestion;
