import cn from 'classnames';
import Slider from 'components/slider/base';
import { questionSlugs as qs } from 'constants/questionnaire';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import { ageCalculator, roundThousands } from 'utils/questionnaire';
import { withCurrencyFormat } from 'utils/utils';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useInput from '../hooks';
import './styles.scss';

const RangeQuestion = ({ field, question, ...props }) => {
  const refs = question.data?.refs ?? {};

  const labels = question.data?.labels ?? [];
  const labelValues = labels.map(label => label.value);
  const firstLabelValue = Math.min(...labelValues);
  const lastLabelValue = Math.max(...labelValues);

  const { inputProps } = useInput({ field, question });

  const getTooltipContent = useCallback(currentVal => {
    if (question.data?.tooltip_first_label && firstLabelValue === currentVal)
      return question.data.tooltip_first_label;
    if (question.data?.tooltip_last_label && lastLabelValue === currentVal)
      return question.data.tooltip_last_label;

    let content = currentVal;

    if (question.slug === qs.TIME_HORIZON) {
      const age = ageCalculator(refs.birthday);
      content += age; // current value + age
      if (lastLabelValue === currentVal) content += '+'; // XX+ Year(s)
    }

    if (question.data?.unit === 'percent') content = `${Number(currentVal) * 100}%`;

    if (question.data?.base && refs[question.data?.base]) {
      let label = roundThousands(Number(refs[question.data?.base]) * Number(currentVal));
      if (question.data?.base_format === 'currency')
        label = withCurrencyFormat(label, 'standard', 0);
      content = `${content} (${label})`;
    }

    if (question.data?.tooltip_prefix) content = `${question.data.tooltip_prefix} ${content}`;
    if (question.data?.tooltip_suffix) content = `${content} ${question.data.tooltip_suffix}`;

    return content;
  }, []);

  const sliderProps = question.data?.tooltip
    ? { withHandle: true, withTooltip: true }
    : { size: 1 };

  return (
    <Question {...props} question={question} nextDisabled={field.invalid}>
      <div className="slider-content" data-range-question={String(question.position)}>
        <Slider
          {...sliderProps}
          className={cn({ '-withDots': question.data?.dots })}
          defaultValue={field.value}
          getTooltipContent={getTooltipContent}
          labels={labels}
          onChange={field.onChange}
          value={field.value}
        />
      </div>

      {question.data?.input && (
        <div className="range-value">
          <NumericFormat {...inputProps} />
        </div>
      )}
      {field.touched && field.error && <div className="error">{field.error}</div>}
    </Question>
  );
};

RangeQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default RangeQuestion;
