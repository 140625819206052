import { AdvisorContext } from 'containers/advisor';
import ConnectedProvider from 'containers/integrations-dashboard/connected-provider';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { integrationsConnectedSelector } from 'selectors/integrations';
import './styles.scss';

const ConnectedIntegrations = ({ integrations }) => {
  const { authProvider, user } = useContext(AdvisorContext);
  return (
    <div id="connected-integrations">
      {_.isEmpty(integrations) ? (
        <p className="no-connected-integrations">You have not connected integrations.</p>
      ) : (
        integrations
          .filter(i => !!i.provider_details)
          .map(integration => (
            <ConnectedProvider
              key={integration.id}
              hasManagementIntegrationPermissions={
                authProvider.hasManagerPermissions(user) ||
                user.advisor.company.multiple_integrations
              }
              integration={integration}
            />
          ))
      )}
    </div>
  );
};

ConnectedIntegrations.propTypes = {
  integrations: PropTypes.array.isRequired
};

export default connect(state => ({
  integrations: integrationsConnectedSelector(state)
}))(ConnectedIntegrations);
