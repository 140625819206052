import { StyleSheet } from '@react-pdf/renderer';
import { RISK_RED_COLOR, TABLE_HEADER_BACKGROUND_COLOR } from 'reports/base/styles';

const EMPTY_BAR_COLOR = '#f6f7f7';

export const styles = StyleSheet.create({
  headerCell: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR },
  text: { textAlign: 'center' },
  score: { alignItems: 'center' },
  barContainer: { flexGrow: 1, justifyContent: 'center' },
  barPercent: { color: RISK_RED_COLOR, fontSize: 8, textAlign: 'right' },
  bar: { position: 'relative', width: '100%', height: 7, backgroundColor: EMPTY_BAR_COLOR },
  barWeight: { position: 'absolute', top: 0, right: 0, height: 7, backgroundColor: RISK_RED_COLOR }
});

export default styles;
