// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-tolerance-questionnaire-read-only {
  font-size: 0.9rem;
}
.risk-tolerance-questionnaire-read-only .questions-wrapper {
  background: white;
  border: 1px solid #c8d0d4;
  border-top: 0;
}
.risk-tolerance-questionnaire-read-only .questions-wrapper .risk-tolerance-questionnaire-read-only__container:last-child .read-only-question {
  border-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/risk-tolerance-questionnaire/questionnaire/read-only/styles.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAGE;EACE,iBAAA;EACA,yBAAA;EACA,aAAA;AADJ;AAGI;EACE,gBAAA;AADN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.risk-tolerance-questionnaire-read-only {\n  font-size: 0.9rem;\n\n  .questions-wrapper {\n    background: white;\n    border: 1px solid $graphite-grayish-blue-2;\n    border-top: 0;\n\n    & .risk-tolerance-questionnaire-read-only__container:last-child .read-only-question {\n      border-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
