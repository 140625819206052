import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const isProduction = process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { message, stack } = error;
  return (
    <div className="error-fallback">
      <img className="error-fallback__brand" src="/img/logos/stratifi-logo.svg" alt="StratiFi" />
      <div className={cn('error-fallback__content', { production: isProduction })}>
        <h1>Oops!</h1>
        <p>
          We're sorry! Something went wrong{' '}
          <span role="img" aria-label="crying-face">
            😢
          </span>
        </p>
        {!isProduction && message && <span>{message}</span>}
        {!isProduction && stack && <pre>{stack}</pre>}
        <button type="button" className="btn btn-primary" onClick={resetErrorBoundary}>
          Retry
        </button>
      </div>
    </div>
  );
};

ErrorFallback.defaultProps = {
  error: {},
  resetErrorBoundary: () => {}
};

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
};

export default ErrorFallback;
