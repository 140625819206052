import cn from 'classnames';
import AdvisorFilter from 'components/advisor/advisor-filter';
import TeamFilter from 'components/advisor/team-filter';
import LoadingWave from 'components/loading-wave';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import './styles.scss';

const DashboardHeader = ({ stats, advisors, teams, selectedTeam }) => {
  const [loading, setLoading] = useState(false);
  const [teamDisabled, setTeamDisabled] = useState(false);
  const [showTeams, setShowTeams] = useState(false);
  const [showAdvisors, setShowAdvisors] = useState(false);
  const [showUsername, setShowUsername] = useState(true);
  const [advisorDisabled, setAdvisorDisabled] = useState(true);
  const { advisorByManager, authProvider, user, advisorProvider } = useContext(AdvisorContext);
  const hasCompliancePermissionsOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

  const handleChangeAdvisor = advisorId => {
    // advisorId did not actually changed.
    if (advisorByManager && advisorByManager === advisorId) return;
    authProvider.setAdvisorByManager(advisorId);
  };

  useEffect(() => {
    if (hasCompliancePermissionsOrAbove && advisors.length > 0) {
      setShowUsername(false);
      setShowAdvisors(true);
    }
  }, [advisors]);

  useEffect(() => {
    if (teams.length > 0) setShowTeams(true);
  }, [teams]);

  useEffect(() => {
    if (selectedTeam === 0) setAdvisorDisabled(false);
    else setAdvisorDisabled(true);
  }, [selectedTeam]);

  useEffect(() => {
    if (advisorByManager === 0) setTeamDisabled(false);
    else setTeamDisabled(true);
  }, [advisorByManager]);

  useEffect(() => {
    /* fetchData */
    async function fetchData() {
      setLoading(true);
      const filters = {};
      if (selectedTeam !== 0) filters.by_team = selectedTeam;
      if (!hasCompliancePermissionsOrAbove) filters.by_advisor = user.advisor.id;
      else if (advisorByManager && advisorByManager !== 0) filters.by_advisor = advisorByManager;

      await advisorProvider.es.getStats(filters);

      setLoading(false);
    }
    fetchData();
  }, [user, advisorByManager, selectedTeam]);

  const empty = _.isEmpty(stats);

  return (
    <div id="DashboardHeader" className="header">
      {!loading && !empty && stats.prism_overall ? (
        <div className={`risk-badge-with-score risk-level-${Math.ceil(stats.prism_overall)}`}>
          {stats.prism_overall.toFixed(1)}
        </div>
      ) : (
        <div className="risk-badge-without-score" />
      )}
      <div style={{ display: 'flex' }}>
        {showUsername && (
          <div>
            <span style={{ fontSize: '20px', marginLeft: '20px' }}>
              {user.first_name} {user.last_name}
            </span>
          </div>
        )}
        {showTeams && (
          <div
            className={cn({ disabled: teamDisabled }, 'advisor-filter')}
            style={{ marginLeft: '20px' }}
          >
            <TeamFilter />
          </div>
        )}
        {hasCompliancePermissionsOrAbove && showAdvisors && (
          <div className={cn({ disabled: advisorDisabled })}>
            <div className="advisor-name">
              <AdvisorFilter onChange={handleChangeAdvisor} />
            </div>
          </div>
        )}
      </div>
      <div className="info-container">
        <div className="info households">
          <div className="num-label">Households</div>
          <div className="number link">
            {loading && <LoadingWave />}
            {!loading && empty && '-'}
            {!loading && !empty && <Link to="/advisor/households">{stats.total_households}</Link>}
          </div>
        </div>
        <div className="info clients">
          <div className="num-label">Clients</div>
          <div className="number link">
            {loading && <LoadingWave />}
            {!loading && empty && '-'}
            {!loading && !empty && <Link to="/advisor/investors">{stats.total_clients}</Link>}
          </div>
        </div>
        <div className="info accounts">
          <div className="num-label">Accounts</div>
          {loading && <LoadingWave />}
          {!loading && empty && '-'}
          {!loading && !empty && <div className="number">{stats.total_accounts}</div>}
        </div>
        <div className={cn('info assets', { loaded: stats && stats.total_assets })}>
          <div className="num-label">Total Assets</div>
          <div className="number">
            {loading && <LoadingWave />}
            {!loading && empty && '-'}
            {!loading && !empty && <FormattedNumber value={stats.total_assets} format="currency" />}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {
  stats: PropTypes.shape({
    prism_overall: PropTypes.number,
    total_assets: PropTypes.number,
    total_account: PropTypes.number,
    total_household: PropTypes.number,
    total_investor: PropTypes.number
  }),
  advisors: PropTypes.array,
  teams: PropTypes.array,
  selectedTeam: PropTypes.number
};

DashboardHeader.defaultProps = {
  stats: {},
  advisors: [],
  teams: [],
  selectedTeam: 0
};

export default connect(state => ({
  stats: state.advisors.stats,
  advisors: state.advisors.esList,
  teams: state.teams.list,
  selectedTeam: state.teams.selected.value
}))(DashboardHeader);
