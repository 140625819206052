import TopHoldingsDistribution from 'components/advisor/proposal/securities/top-holdings-distribution/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { IPSPropTypes, MetadataPropTypes } from '../../types';

const IPSTopHoldings = ({ breakSection, ips: { proposal }, metadata }) => {
  const {
    user: {
      advisor: {
        company: { top_holdings_enabled: topHoldingsEnabled }
      }
    }
  } = useContext(AdvisorContext);

  if (!topHoldingsEnabled) return null;

  const distributionProps = {
    target: proposal.target,
    targetName: proposal.target_label
  };

  const hasRecommended = !!proposal.recommended;
  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = proposal.recommended;
  }

  return (
    <BaseSection body={metadata.body} title={metadata.title} breakSection={breakSection} wrap>
      <TopHoldingsDistribution proposal={proposal} {...distributionProps} />
    </BaseSection>
  );
};

IPSTopHoldings.propTypes = {
  breakSection: PropTypes.bool,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired
};

IPSTopHoldings.defaultProps = {
  breakSection: false
};

export default IPSTopHoldings;
