import {
  customButton,
  customButtonGroup,
  digest,
  getEditorOptions
} from 'components/utils/wysiwyg/options';

export const ACCOUNT_NAME = '%ACCOUNT_NAME%';
export const ACCOUNT_VALUE = '%ACCOUNT_VALUE%';
export const ADVISOR_NAME = '%ADVISOR_NAME%';
export const ANSWER_LINE = '%ANSWER_LINE%';
export const BENCHMARK_MANAGEMENT_FEE = '%BENCHMARK_MANAGEMENT_FEE%';
export const CLIENT_NAME = '%CLIENT_NAME%';
export const COMPANY_NAME = '%COMPANY_NAME%';
export const CURRENT_MANAGEMENT_FEE = '%CURRENT_MANAGEMENT_FEE%';
export const EMAIL_DISCLOSURE = '%EMAIL_DISCLOSURE%';
export const MANAGEMENT_FEE = '%MANAGEMENT_FEE%';
export const PROPOSED_MANAGEMENT_FEE = '%PROPOSED_MANAGEMENT_FEE%';
export const RISK_TOLERANCE_SCORE = '%RISK_TOLERANCE_SCORE%';

const EMPTY_LINE = '________';

const sectionEditorOptions = getEditorOptions();

const proposalSectionEditorOptions = getEditorOptions({
  buttons: {
    fees: customButtonGroup('Management Fees', [
      { text: 'Current Portfolio', code: CURRENT_MANAGEMENT_FEE },
      { text: 'Proposed Portfolio', code: PROPOSED_MANAGEMENT_FEE },
      { text: 'Benchmark', code: BENCHMARK_MANAGEMENT_FEE }
    ])
  }
});

const ipsSectionEditorOptions = getEditorOptions({
  buttons: {
    account: customButtonGroup('Account', [
      { text: 'Name', code: ACCOUNT_NAME },
      { text: 'Value', code: ACCOUNT_VALUE },
      { text: 'Tolerance Score', code: RISK_TOLERANCE_SCORE }
    ]),
    answer: customButton('Answer Placeholder', ANSWER_LINE)
  }
});

const crsSectionEditorOptions = getEditorOptions({ withClient: false });

export const getSectionEditorOptions = target => {
  if (target === 'ips') return ipsSectionEditorOptions;
  if (target === 'proposal') return proposalSectionEditorOptions;
  if (target === 'crs_metadata') return crsSectionEditorOptions;
  return sectionEditorOptions;
};

export const digestContent = (message, data) => {
  const values = {};
  if (Array.isArray(data.investors) && data.investors.length) values.clients = data.investors;
  if (data.investor?.id) values.client = data.investor.user;
  if (data.advisor) values.advisor = data.advisor.user;
  if (data.company) values.company = data.company;

  if (data.target) {
    values[ACCOUNT_NAME] = data.target.name;
    values[ACCOUNT_VALUE] = data.target.value;
    values[RISK_TOLERANCE_SCORE] = data.target.tolerance;
  }

  if (data.answer) values[ANSWER_LINE] = EMPTY_LINE;

  if (data.fees?.target) values[CURRENT_MANAGEMENT_FEE] = data.fees.target;
  if (data.fees?.benchmark) values[BENCHMARK_MANAGEMENT_FEE] = data.fees.benchmark;
  if (data.fees?.recommended) {
    values[MANAGEMENT_FEE] = data.fees.recommended;
    values[PROPOSED_MANAGEMENT_FEE] = data.fees.recommended;
  }

  return digest(message, values);
};
