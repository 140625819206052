import {
  IndicatorsContainer,
  OptimizedGroupMenuList,
  Option,
  SingleValue
} from 'components/advisor/generic-selector';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import Select, { createFilter } from 'react-select';
import { PROPOSAL_SELECT_STYLES } from '../../header/common/form/utils';
import { NO_MODEL_PORTFOLIOS_MESSAGE, UPLOAD_SUGGESTION_MESSAGE } from '../constants';

const PortfolioSelector = ({ defaultValue, placeholder, suggestions, onChange }) => {
  // get flattened list of options
  const allSuggestions = suggestions.reduce((acum, category) => [...acum, ...category.options], []);

  if (!allSuggestions.length)
    return (
      <div className="recommended">
        <p>
          {NO_MODEL_PORTFOLIOS_MESSAGE}{' '}
          <Link to="/advisor/models" className="link">
            {UPLOAD_SUGGESTION_MESSAGE}
          </Link>
          .
        </p>
      </div>
    );

  // find the selected object
  const defaultSuggestion =
    Array.isArray(defaultValue) && defaultValue.length ? defaultValue[0] : defaultValue;

  // after finding the selected object, it's used with the help of JSON.stringify
  // to have a unique key that allows the Select component to be re-rendered when
  // the selected option changes. This is ideal when the same portfolio is being
  // compared and changes are saved, causing a change in the PRISM score.
  const defaultKey = JSON.stringify(defaultSuggestion);

  const setSelection = selection => onChange([{ ...selection, weight: 100 }]);

  return (
    <Select
      className="model-select"
      components={{ MenuList: OptimizedGroupMenuList, Option, SingleValue, IndicatorsContainer }}
      defaultValue={defaultSuggestion}
      filterOption={createFilter({ ignoreAccents: false })}
      isSearchable
      key={defaultKey}
      onChange={setSelection}
      options={suggestions}
      placeholder={placeholder || 'Select a model'}
      styles={PROPOSAL_SELECT_STYLES}
    />
  );
};

PortfolioSelector.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired
};

PortfolioSelector.defaultProps = {
  defaultValue: null,
  placeholder: ''
};

export default PortfolioSelector;
