import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ageCalculator, roundThousands } from 'utils/questionnaire';
import { getExpectedPotentialReturn } from '../expected-return/full/utils';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';
import { calculateRecovery } from '../utils';

const DEFAULT_LOSS = -10;
const DEFAULT_POTENTIAL_LOSS_FACTOR = 2;

const useTolerableLoss = ({ question, field }) => {
  const refs = question.data?.refs || {};

  const isEntityQuestion = question.data?.is_entity || false;

  // number of years until the user will start withdrawing the funds.
  const withdrawalStartsIn = Number(refs['withdrawal-start']) - ageCalculator(refs.birthday);

  // total amount that the user invested or is comfortable investing
  const initialInvestment = roundThousands(
    Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])
  );

  // expected potential loss percentage
  const expectedPotentialLoss = getExpectedPotentialReturn(refs['expected-return'])?.negative ?? 0;

  const labels = useMemo(() => {
    // slider labels filtered by the expected potential loss percentage
    // e.g. if the expected potential loss percentage is -15.75, then the
    // labels will be [-50, -16] instead of [-50, 0]
    const labels = (question.data?.labels || [])
      .filter(({ value }) => value <= expectedPotentialLoss)
      .sort((a, b) => a.value - b.value);
    // customize the label of the last element to show the percentage for the right
    // limit of the slider
    labels[labels.length - 1].text = `${labels[labels.length - 1].value}%`;
    return labels;
  }, [expectedPotentialLoss, JSON.stringify(question.data?.labels)]);

  /* States */
  const [potentialLoss, setPotentialLoss] = useState(null);
  const [gain, setGain] = useState(null);
  const [recoveryTime, setRecoveryTime] = useState(null);

  /* Functions */
  const setRecoveryFromLoss = loss => {
    const { lossAmount, recoverPercent, totalYears } = calculateRecovery(loss, initialInvestment);
    setPotentialLoss(Math.abs(lossAmount));
    setGain(Math.round(recoverPercent * 100));
    setRecoveryTime(totalYears);
  };

  const onChange = useCallback(
    (currentLoss, autofill = false) => {
      if (autofill) field.autofill(currentLoss);
      else field.onChange(currentLoss);
      setRecoveryFromLoss(currentLoss);
    },
    [field, setRecoveryFromLoss]
  );

  let defaultLoss = expectedPotentialLoss
    ? expectedPotentialLoss * DEFAULT_POTENTIAL_LOSS_FACTOR
    : question.default ?? question.data?.default_value ?? DEFAULT_LOSS;
  if (Number.isFinite(field.value)) defaultLoss = field.value;
  else if (Number.isFinite(field.initialValue)) defaultLoss = field.initialValue;

  /* Effects */

  // if there is a default loss percentage but the potential loss amount and
  // the recovery gain are still null calculate them and set them
  useEffect(() => {
    if (
      Number.isFinite(parseFloat(defaultLoss)) &&
      Number.isFinite(parseFloat(initialInvestment)) &&
      (potentialLoss === null || gain === null)
    )
      setRecoveryFromLoss(defaultLoss);
  }, [defaultLoss, potentialLoss, gain, initialInvestment]);

  // if there is a default loss percentage but the field had not been set, update it
  useEffect(() => {
    if (!field.touched && (_.isUndefined(field.value) || field.value === ''))
      onChange(defaultLoss, true);
  }, [field.touched, field.value, defaultLoss]);

  return {
    defaultLoss,
    gain,
    initialInvestment,
    isEntityQuestion,
    labels,
    onChange,
    potentialLoss,
    recoveryTime,
    withdrawalStartsIn
  };
};

useTolerableLoss.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default useTolerableLoss;
