import React from 'react';
import PropTypes from 'prop-types';

import HeaderAuth from 'containers/layout/header-dark';
import HeroFormContainer from 'pages/utils/hero-form-container';

import './styles.scss';

const Signup = ({ children }) => (
  <div id="Signup">
    <HeaderAuth />
    <div className="signup-wrapper">
      <HeroFormContainer>{children}</HeroFormContainer>
    </div>
  </div>
);

Signup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default Signup;
