import { getThresholdBreak } from 'reports/base/utils';
import { ITEMS_THRESHOLD, PORTRAIT_ORIENTATION } from 'reports/constants';

const getTableThresholdValues = (body, orientation, targetPositions, recommendedPositions) => {
  const maxItemsNumberPerPage = orientation === PORTRAIT_ORIENTATION ? 35 : 24;
  const maxItemsNumberForFirstPage = orientation === PORTRAIT_ORIENTATION ? 26 : 14;

  /**
   * If the target or the recommended number of items is within the threshold, a break is needed.
   * Only applies if the section does not have custom content (body).
   */
  const targetThresholdBreak =
    !body &&
    getThresholdBreak(
      targetPositions.length,
      maxItemsNumberForFirstPage,
      maxItemsNumberPerPage,
      ITEMS_THRESHOLD
    );
  const recommendedThresholdBreak =
    !body &&
    getThresholdBreak(
      recommendedPositions.length,
      maxItemsNumberForFirstPage,
      maxItemsNumberPerPage,
      ITEMS_THRESHOLD
    );

  /**
   * The difference of items between the target and the recommended is calculated.
   * If either requires a break, we assume that both will need it.
   */
  const itemsNumberDiff = targetPositions.length - recommendedPositions.length;
  let targetThresholdValue =
    targetThresholdBreak || recommendedThresholdBreak ? ITEMS_THRESHOLD : 0;
  let recommendedThresholdValue =
    targetThresholdBreak || recommendedThresholdBreak ? ITEMS_THRESHOLD : 0;

  /**
   * If the absolute value of the difference of items between the target and the recommended are less than
   * or equal to the threshold value, it means that one or both of them should be broken to the next page.
   * To prevent one of the two from breaking and noticing a visual difference in items, both will be broken
   * by calculating a dynamic threshold that will depend on the value of the difference in items:
   *
   * - If the difference in the number of items is negative, it means that the target has fewer items.
   * - If the difference in the number of items is positive, it means that the recommended has fewer items.
   * - If the difference in the number of items is zero, there will be no change, so both will be broken equally.
   *
   * Since at the beginning it is assumed that both should be broken, if the difference in the number of items
   * is greater than the threshold, it means that only one of the two is the one that really needs to be broken.
   * For this reason, if the list should not be broken, or the opposite list has a greater number of items, it
   * will be left unbroken.
   */
  if (Math.abs(itemsNumberDiff) <= ITEMS_THRESHOLD) {
    if (itemsNumberDiff < 0) targetThresholdValue -= Math.abs(itemsNumberDiff);
    if (itemsNumberDiff > 0) recommendedThresholdValue -= Math.abs(itemsNumberDiff);
  } else {
    if (!targetThresholdBreak || recommendedPositions.length > targetPositions.length)
      targetThresholdValue = 0;
    if (!recommendedThresholdBreak || targetPositions.length > recommendedPositions.length)
      recommendedThresholdValue = 0;
  }

  return { targetThresholdValue, recommendedThresholdValue };
};

export default getTableThresholdValues;
