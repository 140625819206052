import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import propTypes from '../../props';
import styles from './styles';

const ReportQuestion = ({ children, question }) => (
  <View style={styles.row} wrap={false}>
    <View style={styles.cell}>
      <Text>
        {question.question_number ?? question.position}. {question.question}
      </Text>
    </View>
    <View style={[styles.cell, styles.answerCell]}>{children}</View>
  </View>
);

ReportQuestion.propTypes = propTypes;

export const ReportQuestionSkeleton = ({ question }) => (
  <ReportQuestion question={question}>
    <Text>-</Text>
  </ReportQuestion>
);

ReportQuestionSkeleton.propTypes = propTypes;

export default ReportQuestion;
