import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_ROW,
  PRISM_COLOR_0,
  PRISM_COLOR_1,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_4,
  PRISM_COLOR_5,
  PRISM_COLOR_6,
  PRISM_COLOR_7,
  PRISM_COLOR_8,
  PRISM_COLOR_9,
  PRISM_OFF_COLOR
} from 'reports/base/styles';

const BLUE_COLOR = '#09acf8';
const LIGHT_BLUE_COLOR = '#92dcf8';

const styles = StyleSheet.create({
  fColor0: { fill: PRISM_COLOR_0 },
  fColor1: { fill: PRISM_COLOR_0 },
  fColor2: { fill: PRISM_COLOR_1 },
  fColor3: { fill: PRISM_COLOR_2 },
  fColor4: { fill: PRISM_COLOR_3 },
  fColor5: { fill: PRISM_COLOR_4 },
  fColor6: { fill: PRISM_COLOR_5 },
  fColor7: { fill: PRISM_COLOR_6 },
  fColor8: { fill: PRISM_COLOR_7 },
  fColor9: { fill: PRISM_COLOR_8 },
  fColor10: { fill: PRISM_COLOR_9 },
  fColorOff: { fill: PRISM_OFF_COLOR },
  benchmarkContainer: { ...BASE_TABLE, width: 440, margin: 'auto' },
  benchmark: { ...BASE_TABLE_ROW, position: 'relative', height: 40, paddingHorizontal: 20 },
  benchmarkTickerBox: { fill: PRISM_OFF_COLOR },
  benchmarkScoreBox: { fill: LIGHT_BLUE_COLOR },
  benchmarkScoreBoxText: { fill: 'white', fontSize: 36 },
  benchmarkScoreLineContainer: {
    ...BASE_TABLE_ROW,
    position: 'relative',
    justifyContent: 'center'
  },
  benchmarkScoreLine: { marginVertical: 15 },
  prismScore: { fill: BLUE_COLOR },
  targetScore: { fill: LIGHT_BLUE_COLOR },
  benchmarkLegend: { ...BASE_TABLE_ROW, marginTop: 25, paddingHorizontal: 20, fontSize: 8 },
  benchmarkLegendBox: { ...BASE_TABLE_ROW, alignItems: 'center' },
  prismLegend: {
    width: 12,
    height: 12,
    marginRight: 3,
    borderRadius: 12,
    backgroundColor: BLUE_COLOR
  },
  targetLegend: {
    width: 12,
    height: 12,
    marginRight: 3,
    borderRadius: 12,
    backgroundColor: LIGHT_BLUE_COLOR
  }
});

export default styles;
