import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { CustomerSupportEmailLink } from 'constants/contact';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

const BulkUpdatePortfoliosSummaryModal = ({ investor, onClose, summary }) => {
  const { routerActions } = useContext(AdvisorContext);

  if (!summary) return null;

  const createdMessage = investor
    ? `${summary.created} New ${summary.created > 1 ? 'accounts were' : 'account was'} created`
    : `${summary.created} New ${summary.created > 1 ? 'models were' : 'model was'} created`;

  const updatedMessage = investor
    ? `${summary.updated} Existing ${summary.updated > 1 ? 'accounts were' : 'account was'} updated`
    : `${summary.updated} Existing ${summary.updated > 1 ? 'models were' : 'model was'} updated`;

  const skippedMessage = investor
    ? `${summary.skipped} ${summary.skipped > 1 ? 'Accounts were' : 'Account was'} skipped`
    : `${summary.skipped} ${summary.skipped > 1 ? 'Models were' : 'Model was'} skipped`;

  const handleOnClose = () => {
    onClose();
    if (investor)
      routerActions.push(
        `/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${investor.id}/overview`
      );
    else routerActions.push('/advisor/models');
  };

  return ReactDOM.createPortal(
    <Modal id="bulk-update-portfolio-modal" className="modal-lg" show={summary} onHidden={onClose}>
      <ModalHeader />
      <ModalBody>
        <h2 id="modal-header">
          {investor ? `Importing Accounts for ${investor.name}` : 'Importing Model Portfolios'}
        </h2>
        <div className="bulk-update-portfolio">
          <p className="bulk-update-portfolio__info">
            <span role="img" aria-label="celebration">
              🎉
            </span>{' '}
            Great job! See below for the summary
          </p>
          <div className="bulk-update-portfolio__summary">
            {!!summary.created && (
              <div className="summary__item">
                <i className="fs-icon-checkmark-linear text-success" /> {createdMessage}
              </div>
            )}
            {!!summary.updated && (
              <div className="summary__item">
                <i className="fs-icon-checkmark-linear text-success" /> {updatedMessage}
              </div>
            )}
            {!!summary.skipped && (
              <div className="summary__item">
                <i className="fs-icon-checkmark-linear text-success" /> {skippedMessage}
              </div>
            )}
            {!_.isEmpty(summary.failed) && (
              <div className="summary__item not-imported">
                <i className="fs-icon-xmark-linear" /> {summary.failed.join(', ')}{' '}
                {summary.failed.length > 1 ? 'were' : 'was'} not imported
              </div>
            )}
          </div>
          {!_.isEmpty(summary.failed) && (
            <p className="bulk-update-portfolio__danger">
              Please check the CSV file(s) and try again. If you continuously get this error,
              contact us at <CustomerSupportEmailLink />.
            </p>
          )}
          {(!!summary.created || !!summary.updated) && (
            <p className="bulk-update-portfolio__prism-in-process">
              The PRISM scores for the {investor ? 'accounts' : 'models'} are being updated.
            </p>
          )}
          <div className="bulk-update-portfolio__actions">
            <button type="button" className="btn btn-primary go-back" onClick={handleOnClose}>
              {investor ? `Go to ${investor.name} profile` : 'Go back to the models list'}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>,
    document.getElementById('app-portal')
  );
};

BulkUpdatePortfoliosSummaryModal.defaultProps = {
  investor: null,
  summary: null
};

BulkUpdatePortfoliosSummaryModal.propTypes = {
  investor: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  summary: PropTypes.object
};

export default BulkUpdatePortfoliosSummaryModal;
