import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const SingleSelectFilter = ({ defaultValue, onChange, options, placeholder }) => (
  <Select
    className="dropdown dropdown__single-select"
    classNamePrefix="select"
    defaultValue={defaultValue}
    isSearchable={false}
    menuPortalTarget={document.body}
    onChange={onChange}
    options={options}
    placeholder={placeholder}
    styles={{
      control: styles => ({ ...styles, fontSize: '0.9rem', minHeight: 42 }),
      option: styles => ({ ...styles, fontSize: '0.85rem' }),
      menuPortal: styles => ({ ...styles, zIndex: 1051 }),
      menu: styles => ({ ...styles, zIndex: 1051 })
    }}
  />
);

SingleSelectFilter.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  placeholder: PropTypes.string
};

SingleSelectFilter.defaultProps = {
  defaultValue: {},
  placeholder: ''
};

export default SingleSelectFilter;
