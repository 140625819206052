import {
  ACCOUNT_CLEAR_ES,
  ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION,
  ACCOUNT_LIST_ES,
  FETCHING,
  GET_ACCOUNTS_BY_RISK_LEVEL,
  GET_ACCOUNT_PRISM_DISTRIBUTION
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class AccountsElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}search/account/`, params, null, null, true)
      .then(({ data }) => {
        this.dispatch(ACCOUNT_LIST_ES, {
          data: data.results.map(account => ({
            ...account,
            url: `/advisor/investors/${account.investor}/account/${account.id}/overview/`
          }))
        });
        return data;
      });
  }

  clearAccounts() {
    this.dispatch(ACCOUNT_CLEAR_ES);
  }

  getPrismDistribution(params) {
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}search/account/risk_levels_distribution/`, params)
      .then(({ data }) => this.dispatch(GET_ACCOUNT_PRISM_DISTRIBUTION, { data }));
  }

  getDriftLevelDistribution(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/account/drift_levels_distribution/`, params)
      .then(({ data }) => {
        this.dispatch(ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION, { data });
        return data;
      });

    return promise;
  }

  listByRiskLevel(params = {}) {
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}search/account/by_risk_level/`, params)
      .then(({ data }) => this.dispatch(GET_ACCOUNTS_BY_RISK_LEVEL, { data, params }));
  }
}

export default AccountsElasticProvider;
