// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#score-summary .score-summary__title {
  text-align: center;
  margin-bottom: 2rem;
}
#score-summary .score-summary__title > h4 {
  font-size: 2.1rem;
}
#score-summary > .spinner {
  position: relative;
  margin: 0;
}
#score-summary .risk-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  #score-summary .score-summary__title {
    font-size: 14px;
  }
  #score-summary .score-summary__title > h4 {
    font-size: 1.6rem;
    padding: 0 2rem;
  }
}

@media (max-width: 1100px) {
  .risk-header {
    flex-wrap: wrap;
    gap: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/risk-tolerance-questionnaire/result/score-summary/styles.scss","webpack://./src/assets/scss/commons/_app-mixin.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,mBAAA;AAFJ;AAII;EACE,iBAAA;AAFN;AAME;EACE,kBAAA;EACA,SAAA;AAJJ;AAOE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AALJ;ACRE;EDiBE;IACE,eAAA;EANJ;EAQI;IACE,iBAAA;IACA,eAAA;EANN;AACF;;ACLE;EDiBA;IACE,eAAA;IACA,SAAA;EARF;AACF","sourcesContent":["@import 'assets/scss/commons/app-mixin';\n\n#score-summary {\n  .score-summary__title {\n    text-align: center;\n    margin-bottom: 2rem;\n\n    & > h4 {\n      font-size: 2.1rem;\n    }\n  }\n\n  & > .spinner {\n    position: relative;\n    margin: 0;\n  }\n\n  .risk-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  @include phone {\n    .score-summary__title {\n      font-size: 14px;\n\n      & > h4 {\n        font-size: 1.6rem;\n        padding: 0 2rem;\n      }\n    }\n  }\n}\n\n@include tablet {\n  .risk-header {\n    flex-wrap: wrap;\n    gap: 1rem;\n  }\n}\n","$phone-width: 600px;\n$small-tablet-width: 800px;\n$tablet-width: 1100px;\n$small-desktop-width: 1000px;\n$desktop-width: 1101px;\n\n@mixin phone {\n  @media (max-width: #{$phone-width}) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: #{$small-tablet-width}) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: #{$tablet-width}) {\n    @content;\n  }\n}\n\n@mixin small-desktop {\n  @media (max-width: #{$small-desktop-width}) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: #{$desktop-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
