/* eslint-disable no-underscore-dangle */
import {
  PORTFOLIO_CREATE,
  PORTFOLIO_DELETE,
  PORTFOLIO_EDIT,
  PORTFOLIO_GET,
  PORTFOLIO_LIST,
  PORTFOLIO_UPDATE,
  PORTFOLIO_VIEW
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class PortfolioProvider extends DataProvider {
  view(portfolio) {
    this.dispatch(PORTFOLIO_VIEW, {
      data: portfolio
    });
  }

  edit(portfolio) {
    this.dispatch(PORTFOLIO_EDIT, {
      data: portfolio
    });
  }

  list(params) {
    params = { ...params };

    const promise =
      // GET '/api/portfolios'
      this._provider.get(`${config.apiBase}portfolios/`, params).then(data => {
        if (!data.error) {
          this._params = params;

          this.dispatch(PORTFOLIO_LIST, {
            data: {
              data: data.data.results || [],
              meta: {
                params,
                count: data.data.count,
                countPage: this.countPage(params, data)
              }
            }
          });
        } else
          this.dispatch(PORTFOLIO_LIST, {
            data: null
          });

        return data;
      });

    return promise;
  }

  get(id) {
    const promise =
      // GET '/api/portfolios/:id'
      this._provider.get(`${config.apiBase}portfolios/${id}/`).then(data => {
        if (!data.error)
          this.dispatch(PORTFOLIO_GET, {
            data: data.data
          });
        else {
          // Gerald, do something...
        }
        return data;
      });

    return promise;
  }

  create(portfolio, list = false) {
    const data = { ...portfolio };

    const promise =
      // POST 'api/portfolios'
      this._provider.post(`${config.apiBase}portfolios/`, data).then(data => {
        if (!data.error) {
          this.dispatch(PORTFOLIO_CREATE, {
            data: data.data
          });

          list && this.list(this._params);
        } else {
          // Gerald, do something...
        }
        return data;
      });

    return promise;
  }

  update(portfolio, list = false) {
    const data = { ...portfolio };
    if (!data.id) return this.create(portfolio);

    const promise =
      // PUT 'api/portfolios/:id'
      this._provider.put(`${config.apiBase}portfolios/${data.id}/`, data).then(data => {
        if (!data.error) {
          this.dispatch(PORTFOLIO_UPDATE, {
            data: data.data
          });

          list && this.list(this._params);
        } else {
          // Gerald, do something...
        }
        return data;
      });

    return promise;
  }

  // RESERVED
  delete(portfolio, list = false) {
    const promise =
      // DELETE '/api/portfolios/:id'
      this._provider.delete(`${config.apiBase}portfolios/${portfolio.id}/`).then(data => {
        if (!data.error) {
          this.dispatch(PORTFOLIO_DELETE, {
            data: portfolio
          });

          list && this.list(this._params);
        } else {
          // Gerald, do something...
        }
        return data;
      });

    return promise;
  }
}

export default PortfolioProvider;
