/* global STANDARD_DATE_FORMAT */
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getSuggestedModels } from 'utils/utils';
import '../common/styles.scss';
import {
  DEFAULT_END_DATE,
  DEFAULT_STARTING_VALUE,
  DEFAULT_START_DATE,
  DEFAULT_YEARLY_WITHDRAWAL_RATE,
  accountsToSuggestions,
  portfoliosToSuggestions,
  securitiesToSuggestions
} from '../utils';
import ProposalForm from './form';

const MODEL_TYPE_SUGGESTION = 'model_portfolio';
const PROSPECT_TYPE_SUGGESTION = 'prospect-account';
const CLIENT_TYPE_SUGGESTION = 'client-account';

const ProposalHeaderModel = ({
  loading,
  modelOptions,
  onGenerate,
  ready,
  securityOptions,
  selectedModelId,
  setLoading,
  scope,
  templateOptions
}) => {
  const [recommendedType, setRecommendedType] = useState(MODEL_TYPE_SUGGESTION);
  const [benchmarkSuggestions, setBenchmarkSuggestions] = useState([]);
  const [recommendedSuggestions, setRecommendedSuggestions] = useState([]);
  const [templateSuggestions, setTemplateSuggestions] = useState([]);

  const { accountProvider, user, proposalProvider } = useContext(AdvisorContext);

  const handleSubmit = values => {
    values.target = [
      { value: values.draftId ?? scope.id, weight: 100, type: MODEL_TYPE_SUGGESTION }
    ];
    if (!values.recommended) delete values.recommended;
    return onGenerate(values);
  };

  const loadAsyncRecommendedSuggestions = useCallback(
    _.debounce((query, callback) => {
      accountProvider.es
        .list({
          search: query || undefined,
          is_prospect: recommendedType === PROSPECT_TYPE_SUGGESTION || undefined
        })
        .then(({ results }) => {
          callback(accountsToSuggestions(results, scope));
        });
    }, 500),
    [recommendedType]
  );

  // set benchmark suggestions
  useEffect(() => {
    const suggestedPortfolios = portfoliosToSuggestions(modelOptions, MODEL_TYPE_SUGGESTION);
    const suggestions = [
      {
        label: 'Securities',
        options: securitiesToSuggestions(securityOptions)
      },
      {
        label: 'Benchmarks',
        options: suggestedPortfolios.filter(p => p.isBenchmark)
      },
      {
        label: 'Models',
        options: suggestedPortfolios.filter(p => !p.isBenchmark)
      }
    ];
    setBenchmarkSuggestions(suggestions);
  }, [JSON.stringify(securityOptions), JSON.stringify(modelOptions)]);

  // set recommended suggestions
  useEffect(() => {
    const [proposed, suggested, other] = getSuggestedModels([scope], modelOptions);
    const suggestions = [
      {
        label: 'Proposed Models',
        options: portfoliosToSuggestions(proposed, MODEL_TYPE_SUGGESTION)
      },
      {
        label: 'Matching Models',
        options: portfoliosToSuggestions(suggested, MODEL_TYPE_SUGGESTION)
      },
      {
        label: 'Other Models',
        options: portfoliosToSuggestions(other, MODEL_TYPE_SUGGESTION)
      }
    ];
    setRecommendedSuggestions(suggestions);
  }, [scope, JSON.stringify(modelOptions)]);

  // set template suggestions
  useEffect(() => {
    const suggestions = templateOptions.map(({ id, name }) => ({ value: id, label: name }));
    setTemplateSuggestions(suggestions);
  }, [JSON.stringify(templateOptions)]);

  const initialValues = useMemo(() => {
    const initialValues = {
      benchmarkManagementFee: user.advisor.benchmark_management_fee * 100,
      recommendedManagementFee: user.advisor.recommended_management_fee * 100,
      period: [DEFAULT_START_DATE, DEFAULT_END_DATE],
      startingValue: DEFAULT_STARTING_VALUE,
      targetManagementFee: user.advisor.target_management_fee * 100,
      template: proposalProvider.defaultTemplate ?? templateSuggestions?.[0]?.value,
      yearlyWithdrawalAmount: DEFAULT_STARTING_VALUE * (DEFAULT_YEARLY_WITHDRAWAL_RATE / 100),
      yearlyWithdrawalRate: DEFAULT_YEARLY_WITHDRAWAL_RATE
    };

    return initialValues;
  }, [
    selectedModelId,
    scope,
    JSON.stringify(modelOptions),
    proposalProvider.defaultTemplate,
    JSON.stringify(templateSuggestions)
  ]);

  const recommendedTypeSuggestions = [
    {
      label: "Client's account",
      value: CLIENT_TYPE_SUGGESTION
    },
    {
      label: "Prospect's account",
      value: PROSPECT_TYPE_SUGGESTION
    },
    {
      label: 'Model Portfolios',
      value: MODEL_TYPE_SUGGESTION
    }
  ];

  return (
    <ProposalForm
      allowPrint={!loading && ready}
      benchmarkSuggestions={benchmarkSuggestions}
      initialValues={initialValues}
      loading={loading}
      onGenerate={handleSubmit}
      onLoadAsyncRecommendedSuggestions={loadAsyncRecommendedSuggestions}
      onRecommendedTypeChange={setRecommendedType}
      recommendedSuggestions={recommendedSuggestions}
      recommendedTypeSuggestions={recommendedTypeSuggestions}
      reportBuilderEnabled={user.advisor.company.report_builder_enabled}
      scope={scope}
      setLoading={setLoading}
      templateOptions={templateOptions}
      templateSuggestions={templateSuggestions}
    />
  );
};

ProposalHeaderModel.defaultProps = {
  selectedModelId: null,
  setLoading: () => {}
};

ProposalHeaderModel.propTypes = {
  loading: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  modelOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGenerate: PropTypes.func.isRequired,
  scope: PropTypes.object.isRequired,
  securityOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedModelId: PropTypes.number,
  setLoading: PropTypes.func,
  templateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired
};

export default ProposalHeaderModel;
