import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { RETURNS_STEP, UNDERLYING_HOLDING_STEP } from '../../constants';
import '../styles.scss';

const PostCreate = ({
  updateStep,
  returnsStepCompleted,
  underlyingHoldingsStepCompleted,
  security
}) => {
  const { routerActions } = useContext(AdvisorContext);
  const [loading, setLoading] = useState(true);

  const handleRedirectToReturns = () => {
    updateStep(RETURNS_STEP);
  };

  const handleRedirectToUnderlyingHoldings = () => {
    updateStep(UNDERLYING_HOLDING_STEP);
  };

  const handleSkip = () => {
    routerActions.push(`/advisor/securities/${security.id}/overview`);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <SpinnerLoader spinnerLoading />;

  return (
    <div className="card">
      <div className="custom-security-create__button-wrapper">
        <div className="post-create__button-container">
          <span>
            Upload historical returns to assess your security performance and obtain a precise PRISM
            score
          </span>
          <div className="security-create-icon-button__container">
            <button
              type="button"
              onClick={handleRedirectToReturns}
              className="btn btn-outline-secondary"
              disabled={returnsStepCompleted}
            >
              Upload Historical Returns
            </button>
            {returnsStepCompleted && (
              <i className="icon-checkmark-circle security--icon-checkmark" />
            )}
          </div>
        </div>
        <div className="vertical-separator">
          <span>or</span>
        </div>
        <div className="post-create__button-container">
          <span>
            Define the underlying holdings to enable comprehensive portfolio analysis in proposal
            and other reports.
          </span>
          <div className="security-create-icon-button__container">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={handleRedirectToUnderlyingHoldings}
              disabled={underlyingHoldingsStepCompleted}
            >
              Set Underlying Holdings
            </button>
            {underlyingHoldingsStepCompleted && (
              <i className="icon-checkmark-circle security--icon-checkmark" />
            )}
          </div>
        </div>
      </div>
      <button type="button" className="btn btn-link link-button" onClick={handleSkip}>
        I&apos;ll complete this later
      </button>
    </div>
  );
};

PostCreate.propTypes = {
  underlyingHoldingsStepCompleted: PropTypes.bool.isRequired,
  updateStep: PropTypes.func.isRequired,
  returnsStepCompleted: PropTypes.bool.isRequired,
  security: PropTypes.object.isRequired
};

export default PostCreate;
