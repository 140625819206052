import { AdvisorContext } from 'containers/advisor';
import { useContext } from 'react';

const useDriftThreshold = () => {
  const {
    user: {
      advisor: {
        company: {
          drift_threshold_above: driftThresholdAbove,
          drift_threshold_below: driftThresholdBelow,
          exceptions_for_high_risk: exceptionsForHighRisk,
          exceptions_for_high_tolerance: exceptionsForHighTolerance
        }
      }
    }
  } = useContext(AdvisorContext);

  const isHealthyDrift = drift => {
    const hasHighRisk = drift > driftThresholdAbove && exceptionsForHighRisk;
    const hasHighTolerance = drift < -1 * driftThresholdBelow && exceptionsForHighTolerance;
    return !hasHighRisk && !hasHighTolerance;
  };

  return { driftThresholdAbove, driftThresholdBelow, isHealthyDrift };
};

export default useDriftThreshold;
