import { SERVER_ERROR, SKIP_ERROR, CLEAR_SERVER_ERRORS } from 'constants/actions';

export default function serverErrors(state = [], action) {
  switch (action.type) {
    case SERVER_ERROR:
      return [...state, action.data || 'backend error'];

    case SKIP_ERROR:
      return [...state.slice(0, action.data), ...state.slice(action.data + 1)];

    case CLEAR_SERVER_ERRORS:
      return [];

    default:
      return state;
  }
}
