/* eslint-disable import/no-cycle */
import { Document } from '@react-pdf/renderer';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import { getInvestorReportCoverData } from 'reports/utils';
import ReportViewerModal from 'reports/viewer/modal';
import RiskToleranceQuestionnaireEmptyReport from '..';

const RiskToleranceQuestionnaireEmptyReportViewer = ({
  investor,
  onHidden,
  questionnaireTemplateId,
  showModal
}) => {
  const { investorProvider, prospectProvider, questionProvider, user, userProvider } =
    useContext(AdvisorContext);

  const [coverData, setCoverData] = useState({});
  const [questions, setQuestions] = useState(null);
  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);
  const [title, setTitle] = useState('');

  const isAnalyticsSection = window.location.pathname === '/advisor/analytics';
  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const subtitle = !isAnalyticsSection && investor ? investor.full_name : '';

  const advisor =
    !isAnalyticsSection && investor?.advisor
      ? { ...investor.advisor, company: user.advisor.company }
      : user.advisor;

  const changeOrientation = value => {
    setOrientation(value);
  };

  useEffect(() => {
    if (questionnaireTemplateId)
      questionProvider
        .getQuestionnaireTemplate(questionnaireTemplateId)
        .then(({ name, questions }) => {
          setQuestions(questions);
          setTitle(name);
        });
  }, [questionnaireTemplateId]);

  useEffect(() => {
    if (!isAnalyticsSection)
      getInvestorReportCoverData(investor, investorProvider, prospectProvider).then(data => {
        setCoverData(data);
      });
    else setCoverData({});
  }, [JSON.stringify(investor), isAnalyticsSection]);

  // the report has to be memoized to avoid making unnecessary renders that slow down the
  // entire UI. Additionally, if the chart images are not yet loaded, a <Document /> is returned
  // to also avoid slowing down the UI
  const report = useMemo(
    () =>
      questions ? (
        <RiskToleranceQuestionnaireEmptyReport
          advisor={advisor}
          coverData={coverData}
          orientation={orientation}
          questions={questions}
          subtitle={subtitle}
          title={title}
          user={user}
          withCustomBranding={withCustomBranding}
        />
      ) : (
        <Document />
      ),
    [
      JSON.stringify(coverData),
      JSON.stringify(advisor),
      JSON.stringify(questions),
      orientation,
      subtitle,
      title
    ]
  );

  if (!questions) return null;

  return (
    <ReportViewerModal
      changeOrientation={changeOrientation}
      fullyLoadedReportAssets
      onHidden={onHidden}
      orientation={orientation}
      report={report}
      showButton={false}
      showModal={showModal}
    />
  );
};

RiskToleranceQuestionnaireEmptyReportViewer.defaultProps = {
  investor: null,
  onHidden: () => {},
  questionnaireTemplateId: undefined,
  showModal: false
};

RiskToleranceQuestionnaireEmptyReportViewer.propTypes = {
  investor: PropTypes.object,
  onHidden: PropTypes.func,
  questionnaireTemplateId: PropTypes.number,
  showModal: PropTypes.bool
};

export default RiskToleranceQuestionnaireEmptyReportViewer;
