import { Document, Page } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from '../constants';

const EmptyReport = ({ size, orientation }) => (
  <Document>
    <Page size={size} orientation={orientation} />
  </Document>
);

EmptyReport.propTypes = {
  orientation: PropTypes.string,
  size: PropTypes.string
};

EmptyReport.defaultProps = {
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4
};

export default EmptyReport;
