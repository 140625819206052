import React from 'react';
import Link from 'components/utils/link';
import DisplayScore from 'components/utils/DisplayScore';
import { modelDetailTabs as tabs } from 'constants/tabs';

// Link to Overview
export const overviewLink = ({ modelID }) => (
  <Link to={`/advisor/models/${modelID}/overview`}>Overview</Link>
);

// Link to PRISM
export const prismLink = ({ isStrategy, prismOverall, modelID }) => (
  <Link to={`/advisor/models/${modelID}/risk-analysis`}>
    {prismOverall && (
      <DisplayScore isStrategy={isStrategy} score={parseFloat(prismOverall)} className="header" />
    )}{' '}
    Portfolio Risk
  </Link>
);

export const performanceLink = ({ reportBuilderEnabled, modelID }) => (
  <Link to={`/advisor/models/${modelID}/${tabs.PERFORMANCE}`}>
    {reportBuilderEnabled ? 'Compare and Analyze' : 'Performance Analysis'}
  </Link>
);

export const strategyLink = ({ modelID }) => (
  <Link to={`/advisor/models/${modelID}/${tabs.STRATEGY}`}>Strategy</Link>
);

export const accessControlLink = ({ modelID }) => (
  <Link to={`/advisor/models/${modelID}/${tabs.ACCESS_CONTROL}`}>Access Control</Link>
);
