// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successfully-tolerance-action {
  padding: 2rem;
  text-align: center;
}
.successfully-tolerance-action h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.successfully-tolerance-action p {
  width: 60%;
  margin: 0 auto 1.5rem auto;
}
.successfully-tolerance-action p.font-weight-bold {
  margin: 0 auto;
}
.successfully-tolerance-action button {
  min-width: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/successfully-tolerance-action/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,kBAAA;AADF;AAGE;EACE,iBAAA;EACA,mBAAA;AADJ;AAIE;EACE,UAAA;EACA,0BAAA;AAFJ;AAKE;EACE,cAAA;AAHJ;AAME;EACE,gBAAA;AAJJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.successfully-tolerance-action {\n  padding: 2rem;\n  text-align: center;\n\n  h1 {\n    font-size: 1.5rem;\n    margin-bottom: 1rem;\n  }\n\n  p {\n    width: 60%;\n    margin: 0 auto 1.5rem auto;\n  }\n\n  p.font-weight-bold {\n    margin: 0 auto;\n  }\n\n  button {\n    min-width: 10rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
