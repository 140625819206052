import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { formatMoney } from 'utils/utils';
import { MODEL_TARGET_TYPE, SECURITY_TARGET_TYPE } from '../../constants';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from '../positions-analysis/constants';

const MODEL_PORTFOLIO_POSITION_CHART_OPTIONS = {
  balloonText:
    '<span className="balloon-position-analysis">' +
    '<span className="ballon-color-box" style="background-color: [[color]]"></span>' +
    '<span className="ballon-content">[[title]] [[value]]%</span>' +
    '</span>'
};

const ACCOUNT_POSITION_CHART_OPTIONS = {
  balloonText:
    '<span className="balloon-position-analysis">' +
    '<span className="ballon-color-box" style="background-color: [[color]]"></span>' +
    '<span className="ballon-content">[[title]] $[[value]]</span>' +
    '</span>'
};

const RiskAnalysisPositions = ({ portfolio, type }) => {
  const { accountProvider, modelProvider } = useContext(AdvisorContext);

  const isSecurity = type === SECURITY_TARGET_TYPE;
  const isModelPortfolio = type === MODEL_TARGET_TYPE;
  const positionChartOptions = isModelPortfolio
    ? MODEL_PORTFOLIO_POSITION_CHART_OPTIONS
    : ACCOUNT_POSITION_CHART_OPTIONS;

  const onChartReady = useCallback(
    (id, data) => {
      if (isModelPortfolio) modelProvider.saveChartImage({ [id]: data });
      else accountProvider.saveChartImage({ [id]: data });
    },
    [JSON.stringify(portfolio), type]
  );

  const chartId = isModelPortfolio
    ? PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID
    : `${PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}-${portfolio.id}`;

  return (
    <div className="box-container box-position-analysis">
      <div className="box-heading">
        <span>Allocations</span>
        <span className="portfolio-value">
          {!_.isEmpty(portfolio) && portfolio.value && `${formatMoney(portfolio.value)}`}
        </span>
      </div>
      <div className="box-content">
        {portfolio && !_.isEmpty(portfolio) && (
          <PositionsAnalysis
            hiddenVal={isModelPortfolio || isSecurity}
            id={chartId}
            onChartReady={onChartReady}
            portfolio={portfolio}
            positionChartOptions={positionChartOptions}
            showHeadingRow
          />
        )}
      </div>
    </div>
  );
};

RiskAnalysisPositions.propTypes = {
  portfolio: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default React.memo(RiskAnalysisPositions);
