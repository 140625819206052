import { CLICK_CLIENT, INVESTOR_APP_MODEL } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router';

const InvestorNameCell = ({ investor }) => {
  const { actionProvider } = useContext(AdvisorContext);

  const onClientClick = investorId => () =>
    actionProvider.slack({
      verb: CLICK_CLIENT,
      target_app_model: INVESTOR_APP_MODEL,
      target_id: investorId
    });

  return (
    <>
      <Link
        onClick={onClientClick(investor.id)}
        to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${investor.id}/overview`}
      >
        {investor.full_name}
      </Link>
      {investor.is_new && (
        <span className="investor-tag" title={moment(investor.created_at).format('L')}>
          {investor.from_widget ? 'Widget' : 'New'}
        </span>
      )}
    </>
  );
};

InvestorNameCell.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorNameCell;
