import React from 'react';
import './styles.scss';

const typographyUsage = {
  28: 'Page Heading',
  24: 'Popup Title',
  18: 'Sub Heading',
  16: 'Popup body / Buttons / Form text',
  14: 'Body / Table text'
};

const TypographySection = () => (
  <section id="typography-section">
    <h2>Typography</h2>
    <p>
      We use Roboto for all dynamic texts on the platform. No other fonts are allowed except
      graphics
    </p>
    <div className="table">
      {[28, 24, 18, 16, 14].map(fontSize => (
        <div key={fontSize} className={`row row-${fontSize}`}>
          <div className="col col-5">{typographyUsage[fontSize]}</div>
          <div className="col col-3">{fontSize}px</div>
          <div className="col col-4">Font-weight: {fontSize > 16 ? '400' : '300'}</div>
        </div>
      ))}
    </div>
  </section>
);

export default TypographySection;
