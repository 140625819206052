/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { MAX_DISCUSSION_TOPICS, OTHER_DISCUSSION_TOPIC } from './constants';
import './styles.scss';

const DiscussionTopicsTop = ({ discussionTopics }) => {
  const discussionTopicsSummary = Array.from(
    { length: MAX_DISCUSSION_TOPICS },
    (_, idx) => discussionTopics[idx] || '-'
  );
  return (
    <div id="discussion-topics-top">
      {discussionTopicsSummary.map((dt, idx) => (
        <div className="discussion-topic" key={idx}>
          <div className="discussion-topic__position">{idx + 1}</div>
          <div className="discussion-topic__text">
            {dt.startsWith(OTHER_DISCUSSION_TOPIC) ? (
              <>
                {OTHER_DISCUSSION_TOPIC} <span>{dt.replace(OTHER_DISCUSSION_TOPIC, '')}</span>
              </>
            ) : (
              dt
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

DiscussionTopicsTop.defaultProps = {
  discussionTopics: []
};

DiscussionTopicsTop.propTypes = {
  discussionTopics: PropTypes.array
};

export default DiscussionTopicsTop;
