import React from 'react';
import PropTypes from 'prop-types';

const InvestorPropertyOverview = ({ property, value }) => (
  <div className="investor-property-overview">
    <span className="property">{property}:</span>
    <span className="value">{value}</span>
  </div>
);

InvestorPropertyOverview.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired
};

export default InvestorPropertyOverview;
