import cn from 'classnames';
import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import EditBox from 'components/advisor/utils/edit-box';
import PropTypes from 'prop-types';
import React from 'react';

import { IPSPropTypes } from '../types';
import './styles.scss';

const IPSPrism = ({ ips: { proposal }, ...rest }) => {
  const hasRecommended = !!proposal.recommended;

  return (
    <EditBox {...rest}>
      <div className={cn('fancy-grid scores', { 'no-recommended': !hasRecommended })}>
        <div className="box-header left gray">{proposal.target_label || 'Current'}</div>
        {hasRecommended && (
          <div className="box-header right blue">{proposal.recommended_label || 'Recommended'}</div>
        )}
        <div className="prism-result-box left">
          <RiskOverall
            id="proposal-target-score-overall"
            score={
              proposal.target.prism_score_summary
                ? proposal.target.prism_score_summary.overall
                : null
            }
            target={
              proposal.target.target_score_summary
                ? proposal.target.target_score_summary.overall
                : null
            }
            small
          />
        </div>
        {hasRecommended && (
          <div className="prism-result-box right">
            <RiskOverall
              id="proposal-recommended-score-overall"
              score={
                proposal.recommended.prism_score_weighted ||
                proposal.recommended.prism_score_summary.overall
              }
              small
            />
          </div>
        )}
      </div>
    </EditBox>
  );
};

IPSPrism.propTypes = {
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

export default IPSPrism;
