import { MODEL_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import RiskAnalysisTarget from 'components/advisor/risk-analysis/risk-analysis-target';
import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const FAILED_TO_FETCH = 'Failed to fetch';

class RiskAnalysis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    const { riskAnalysisRequired } = this.props;
    if (riskAnalysisRequired) return this.reloadModelRisk();
    return this.loadData();
  }

  componentDidUpdate(prevProps) {
    const {
      location: prevLocation = {},
      portfolio: prevPortfolio,
      riskAnalysisRequired: prevRiskAnalysisRequired
    } = prevProps;

    const { riskAnalysisRequired, location: currentLocation = {}, portfolio } = this.props;
    const { pathname: oldPathname } = prevLocation;
    const { pathname: currentPathname } = currentLocation;

    if (currentPathname !== oldPathname) return this.loadData();
    if (prevPortfolio?.prism_score === null && portfolio?.prism_score) return this.loadData();
    if (prevPortfolio)
      if (riskAnalysisRequired && riskAnalysisRequired !== prevRiskAnalysisRequired)
        this.reloadModelRisk();
  }

  reloadModelRisk = () => this.analyzeRisk().then(this.loadData);

  analyzeRisk = async () => {
    const { modelProvider } = this.context;
    const { portfolio, riskAnalysis } = this.props;

    const creating = _.isEmpty(riskAnalysis);
    const notificationOptions = { toastId: portfolio.id };

    toast.info(`${creating ? 'Calculating' : 'Updating'} PRISM score.`, notificationOptions);

    return modelProvider
      .updatePrism(portfolio.id)
      .then(response => {
        if (response.message === FAILED_TO_FETCH) throw new Error(response.message);
        toast.success(
          `PRISM score successfully ${creating ? 'calculated' : 'updated'}.`,
          notificationOptions
        );
      })
      .catch(() => {
        toast.error(
          "We couldn't calculate the PRISM rating, please try it later or contact support for more information.",
          notificationOptions
        );
      });
  };

  loadData = () => {
    const {
      params: { id }
    } = this.props;
    const { modelProvider } = this.context;

    this.setState({ loading: true });

    return modelProvider
      .get(id)
      .then(() => {
        const { portfolio } = this.props;
        if (portfolio) {
          const portfolioRisk = portfolio.prism_score?.output
            ? { ...portfolio.prism_score.output, portfolio }
            : { portfolio };
          modelProvider.riskAnalysisPrecomputed(portfolioRisk);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { portfolio, calculating, riskAnalysis, showEditModal } = this.props;
    const { loading } = this.state;

    if (loading) return <SpinnerLoader spinnerLoading />;

    return (
      <section className="risk-analysis">
        <div className="risk-analysis-body">
          <div className="risk-analysis-body-container">
            {portfolio && !_.isEmpty(riskAnalysis) ? (
              <section>
                <RiskAnalysisTarget
                  calculating={calculating}
                  editing={showEditModal}
                  portfolio={portfolio ?? {}}
                  riskAnalysis={riskAnalysis ?? {}}
                  type={MODEL_TARGET_TYPE}
                />
              </section>
            ) : (
              <h3 className="text-sm-center placeholder-text">
                The PRISM score is not ready for this portfolio.
              </h3>
            )}
            <Disclosure />
          </div>
        </div>
      </section>
    );
  }
}

RiskAnalysis.defaultProps = {
  portfolio: {},
  riskAnalysis: {},
  riskData: undefined
};

RiskAnalysis.propTypes = {
  location: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  portfolio: PropTypes.object,
  params: PropTypes.object.isRequired,
  riskAnalysis: PropTypes.object,
  riskAnalysisRequired: PropTypes.bool.isRequired,
  riskData: PropTypes.object,
  showEditModal: PropTypes.bool.isRequired,
  calculating: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired
};

RiskAnalysis.contextTypes = {
  user: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired
};

export default connect(
  state => ({
    marketStore: state.market,
    portfolio: state.models.view,
    riskAnalysis: state.models.riskAnalysis,
    riskAnalysisRequired: state.models.riskAnalysisRequired
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { riskAnalysisRequired = {} } = stateProps;
    const { id } = ownProps.params;
    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      riskAnalysisRequired: !!riskAnalysisRequired[id]
    };
  }
)(RiskAnalysis);
