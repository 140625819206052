import cn from 'classnames';
import { Modal, ModalBody } from 'components/modal';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CreateAccountsAutomatically from '../create-accounts-automatically';
import MergeAccounts from '../merge-accounts';
import ProviderConnection from '../providers';
import PushScores from '../push-scores';
import SyncDataOptions from '../sync-data-options';
import './styles.scss';

const SELECT_SYNC_DATA_OPTIONS_STEP = 1;
const PROVIDER_CONNECTION_STEP = 2;
const PROVIDER_RESPONSE_STEP = 3;

const CREATE_ACCOUNTS_AUTOMATICALLY_STEP = 4;
const MERGE_ACCOUNTS_STEP = 5;
const PUSH_SCORES_STEP = 6;

const ConnectIntegrationModal = ({
  integration,
  onCancel,
  onConnect,
  prevSelectedSyncData,
  provider,
  show
}) => {
  const [step, setStep] = useState(SELECT_SYNC_DATA_OPTIONS_STEP);
  const [selectedSyncData, setSelectedSyncData] = useState({});
  const [response, setResponse] = useState({});

  const { provider: providerId } = provider;

  const getSelectedSyncData = data => {
    const { sync_accounts: syncAccounts, sync_clients: syncClients } = data;

    setSelectedSyncData(data);

    if (providerId === 'ByAllAccounts' && syncAccounts && syncClients)
      setStep(CREATE_ACCOUNTS_AUTOMATICALLY_STEP);
    else if (providerId === 'Orion' && syncAccounts) setStep(MERGE_ACCOUNTS_STEP);
    else if (providerId === 'Addepar') setStep(PUSH_SCORES_STEP);
    else setStep(PROVIDER_CONNECTION_STEP);
  };

  const getAdditionalConfigResponse = data => {
    setSelectedSyncData({ ...selectedSyncData, ...data });
    setStep(PROVIDER_CONNECTION_STEP);
  };

  const getProviderConnectionResponse = response => {
    setResponse(response);
    setStep(PROVIDER_RESPONSE_STEP);
  };

  return (
    <Modal
      id="connect-integration-modal"
      className={cn('modal-lg', {
        'byallaccounts-modal': step === PROVIDER_CONNECTION_STEP && providerId === 'ByAllAccounts',
        'extra-step-bigger-modal':
          step === CREATE_ACCOUNTS_AUTOMATICALLY_STEP || step === PUSH_SCORES_STEP
      })}
      show={show}
      onHidden={onCancel}
    >
      <ModalBody>
        {step === SELECT_SYNC_DATA_OPTIONS_STEP && (
          <SyncDataOptions
            onConnectOrUpdate={getSelectedSyncData}
            prevSelectedSyncData={prevSelectedSyncData}
            provider={provider}
            updating={!!prevSelectedSyncData}
          />
        )}
        {step === PROVIDER_CONNECTION_STEP && (
          <ProviderConnection
            onComplete={getProviderConnectionResponse}
            provider={provider}
            integration={integration}
            selectedSyncData={selectedSyncData}
          />
        )}
        {step === PROVIDER_RESPONSE_STEP && (
          <>
            <p>
              <b>{response.message}</b>
            </p>
            {response.status !== 'success' && response.status !== 'exit' && (
              <p className="mt-1">
                Please contact StratiFi customer support at <CustomerSupportEmailLink /> or call{' '}
                <CustomerSupportPhoneLink /> if you have any issue.
              </p>
            )}
            <button type="button" onClick={onConnect} className="btn btn-primary mt-2">
              Close
            </button>
          </>
        )}
        {step === CREATE_ACCOUNTS_AUTOMATICALLY_STEP && (
          <CreateAccountsAutomatically onComplete={getAdditionalConfigResponse} />
        )}
        {step === MERGE_ACCOUNTS_STEP && <MergeAccounts onComplete={getAdditionalConfigResponse} />}
        {step === PUSH_SCORES_STEP && <PushScores onComplete={getAdditionalConfigResponse} />}
      </ModalBody>
    </Modal>
  );
};

ConnectIntegrationModal.propTypes = {
  integration: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onConnect: PropTypes.func,
  prevSelectedSyncData: PropTypes.array,
  provider: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

ConnectIntegrationModal.defaultProps = {
  integration: null,
  onConnect: () => {},
  prevSelectedSyncData: null
};

export default ConnectIntegrationModal;
