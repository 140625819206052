import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { INVESTOR_DRIFT_OVERALL_MAX_ATTR, INVESTOR_DRIFT_OVERALL_MIN_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const InvestorDriftOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[INVESTOR_DRIFT_OVERALL_MAX_ATTR, INVESTOR_DRIFT_OVERALL_MIN_ATTR]}
    filters={filters}
    label="Investor Drift Overall"
  >
    <MinMaxSliderFilter
      filters={filters}
      maxAttr={INVESTOR_DRIFT_OVERALL_MAX_ATTR}
      minAttr={INVESTOR_DRIFT_OVERALL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

InvestorDriftOverallFilter.propTypes = { ...filterPropTypes };

InvestorDriftOverallFilter.defaultProps = { ...filterDefaultProps };

export default InvestorDriftOverallFilter;
