/* eslint-disable jsx-a11y/label-has-for */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const InlineLabeledInput = ({
  caption,
  className,
  disabled,
  helperText,
  inputComponent: InputComponent,
  inputProps,
  label,
  labelClassName,
  sizes
}) => {
  const [focused, setFocused] = useState(false);

  const onFocus = event => {
    const { onFocus } = inputProps;
    if (onFocus) onFocus(event);
    setFocused(true);
  };

  const onBlur = () => {
    const { onBlur } = inputProps;
    if (onBlur) onBlur();
    setFocused(false);
  };

  return (
    <div className={`row inline-labeled-input ${className}`}>
      <label className={cn(labelClassName, `col-sm-${sizes.label}`, { focused })}>{label}</label>
      <div className={`col-sm-${sizes.input}`}>
        {caption && <div className="caption">{caption}</div>}
        <InputComponent {...inputProps} disabled={disabled} onFocus={onFocus} onBlur={onBlur} />
        {helperText && <div className="helperText caption">{helperText}</div>}
      </div>
    </div>
  );
};

InlineLabeledInput.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  inputComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  inputProps: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  sizes: PropTypes.shape({
    label: PropTypes.number.isRequired,
    input: PropTypes.number.isRequired
  })
};

InlineLabeledInput.defaultProps = {
  caption: '',
  className: '',
  disabled: false,
  helperText: '',
  labelClassName: '',
  sizes: { label: 4, input: 8 }
};

export default InlineLabeledInput;
