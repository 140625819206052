/* globals DISPLAY_DATE_FORMAT */
import MetaTemplate from 'components/advisor/templates/meta';
import InlineTextEditor from 'components/inline-text-editor';
import SpinnerLoader from 'components/performance-spinner';
import Link from 'components/utils/link';
import { AdvisorContext } from 'containers/advisor';
import DEFAULT_TEMPLATES from 'containers/advisor/templates/defaults';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import ApproveModal from '../approve-modal/index';
import CustomizeOrderModal from '../customize-order-modal';
import { ARCHIVED } from './constants';
import ReportTemplatePropTypes from './types';

const ReportTemplatesDetail = ({ template, title, router, location }) => {
  const { templateProvider, authProvider, user } = useContext(AdvisorContext);

  const [openModal, setOpenModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  const templateContent = template.content || DEFAULT_TEMPLATES[template.type];

  const isOwner = template.created_by?.id === user.advisor.id;
  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

  const canUpdate = isComplianceOrAbove || (isOwner && !template.approved);
  const canArchive = isComplianceOrAbove || isOwner;
  const canApprove = isComplianceOrAbove;
  const canOrder =
    isComplianceOrAbove || (isOwner && !template.approved && template.settings.allowSectionsOrder);

  const templateRequest = async (
    action,
    payload,
    successMessage,
    errorMessage = 'Something went wrong saving your template'
  ) => {
    setUpdating(true);

    try {
      const { data, error } = await templateProvider[action](...payload);
      if (error) toast.error(errorMessage, { toastId: template.id });
      else toast.success(successMessage, { toastId: template.id });
      return { data, error };
    } catch {
      toast.error(errorMessage, { toastId: template.id });
    } finally {
      setUpdating(false);
    }
    return {};
  };

  const update = async values =>
    templateRequest('partialUpdate', [template.id, values], 'Template saved successfully');

  const archive = async () =>
    templateRequest('archive', [template.id], 'The template has been archived');

  const unarchive = async () =>
    templateRequest('unarchive', [template.id], 'The template has been recovered');

  const approve = async () => templateRequest('approve', [template.id], 'Template approved');

  const duplicate = async () => {
    const values = {
      name: `${template.name} (Copy)`,
      content: templateContent,
      type: template.type
    };

    const { data } = await templateRequest(
      'create',
      [values],
      'The template has been successfully duplicated'
    );
    if (data?.type) router.push(`/advisor/templates/${data.type}/`);
  };

  const saveName = async name => update({ name });
  const saveContent = async (_, content) => update({ content });
  const saveOrder = async (content, settings) => update({ content, settings });

  if (!template) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="report-templates-detail">
      <div className="header">
        <h5>
          <Link className="btn-transparent" to={`/advisor/templates/${template.type}/`}>
            &lsaquo; All {title} templates
          </Link>
        </h5>

        <div>
          <div className="name">
            <InlineTextEditor
              text={template.name}
              onChange={saveName}
              updating={updating}
              defaultEditing={location?.state?.isNew}
              actionsDisabled={!canUpdate}
            />
            <div className="tags">
              {template.approved && <span className="tag -success">Approved</span>}
              {template.status === ARCHIVED && <span className="tag">Archived</span>}
            </div>
            <br />
            {template.approved_by && template.approved ? (
              <p>
                Approved by {template.approved_by} on{' '}
                {moment(template.approved).format(DISPLAY_DATE_FORMAT)}
              </p>
            ) : null}
          </div>

          <div className="actions">
            {updating && <SpinnerLoader spinnerLoading />}

            {!updating && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-transparent"
                  onClick={duplicate}
                >
                  Make a copy
                </button>
                {canOrder && (
                  <CustomizeOrderModal
                    handleSaveTemplate={saveOrder}
                    templateContent={templateContent}
                    templateSettings={template.settings}
                  />
                )}
                {canArchive && template.status !== ARCHIVED && (
                  <button type="button" className="btn btn-red" onClick={archive}>
                    Archive
                  </button>
                )}
                {canArchive && template.status === ARCHIVED && (
                  <button type="button" className="btn btn-secondary-2" onClick={unarchive}>
                    Recover
                  </button>
                )}
                {canApprove && !template.approved && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={template.approved}
                    onClick={() => setOpenModal(true)}
                  >
                    Approve
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ApproveModal show={openModal} onHidden={setOpenModal} onApproved={approve} />

      <MetaTemplate
        actionsDisabled={!canUpdate}
        content={templateContent}
        handleSaveTemplate={saveContent}
        target={template.type}
        title={template.name}
      />
    </div>
  );
};

ReportTemplatesDetail.propTypes = {
  location: PropTypes.object.isRequired,
  template: ReportTemplatePropTypes.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  title: PropTypes.string.isRequired
};

export default withRouter(ReportTemplatesDetail);
