import { COMPANY_STATS_GET } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class CompanyElasticProvider extends DataProvider {
  getStats() {
    return this.provider
      .get(`${config.apiBase}search/company/stats/`)
      .then(({ data }) => this.dispatch(COMPANY_STATS_GET, { data }));
  }
}

export default CompanyElasticProvider;
