import { preferredValuesPerPage, setPageSelected, valuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Select from 'react-select';
import ReactTable from 'react-table';
import { COMPANY_STATUS_LIST, PRODUCT_TYPE_LIST, COMPANY_TYPE_LIST } from './constants';
import 'react-table/react-table.css'; // Import CSS for react-table
import SelectWrapper from './selectWrapper';
import './styles.scss';

const Header = columnTitle => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ paddingRight: '5px' }}>{columnTitle}</div>
    <img src="/img/sorting.svg" className="sorting" alt="" />
  </div>
);

const CellEditLink = companyId => (
  <Link to={`advisor/companies/${companyId}`}>
    <i className="icon-pencil-line" style={{ cursor: 'pointer' }} />
  </Link>
);

const BoolToCheck = bool =>
  bool ? (
    <img src="/img/icons/blue-check.svg" alt="true" />
  ) : (
    <img width="24px" height="24px" src="/img/icons/remove.svg" alt="" />
  );

const getProductLabel = productValue => {
  const product = PRODUCT_TYPE_LIST.find(p => p.value === productValue);
  return product?.label || ''; // Fallback text if product is not found
};

const getStatusLabel = statusValue => {
  const status = COMPANY_STATUS_LIST.find(s => s.value === statusValue);
  return status?.label || ''; // Fallback text if status is not found
};

export const GetHubspotLink = hubspotUrl => {
  if (!hubspotUrl) return null;
  return (
    <a
      href={hubspotUrl}
      className="embedded-document-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>See in Hubspot</span>
      <span className="embedded-document-link__icon" />
    </a>
  );
};

const StaffCompaniesTable = stateProps => {
  const { companies, meta } = stateProps;
  const [ordering, setOrdering] = useState('name');
  const [status, setStatus] = useState('');
  const [product, setProduct] = useState('');
  const [isDemoTesting, setIsDemoTesting] = useState('');
  const [search, setSearch] = useState('');
  const [hubspotFilter, setHubspotFilter] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);
  const { staffCompaniesProvider } = useContext(AdvisorContext);

  // Creating a debounced fetchData function
  const fetchData = useCallback(
    _.debounce(() => {
      staffCompaniesProvider.list({
        page,
        search,
        hubspot_linked: hubspotFilter,
        product,
        status,
        ordering,
        is_internal: isDemoTesting,
        page_size: pageSize
      });
    }, 500),
    [page, search, hubspotFilter, status, product, ordering, pageSize, isDemoTesting]
  );

  useEffect(() => {
    fetchData(); // This will now trigger debounced calls to fetchData
    // Cleanup function to cancel the debounce on unmount or when dependencies change
    return () => fetchData.cancel();
  }, [fetchData]);

  const onPageChange = pageIndex => {
    const pageNumber = pageIndex + 1;
    // Update the URL with the new page index
    setPage(pageNumber);
  };

  const onSearchChange = e => {
    onPageChange(0); // Reset to page 1 when searching
    setSearch(e.target.value);
  };

  const onSelectChange = (e, setter) => {
    onPageChange(0); // Reset to page 1 when selecting a new option
    setter(e);
  };

  const onPageSizeChange = ({ value }) => {
    setPage(1);
    setPageSize(value);
    setPageSelected({ value });
  };

  const onSortedChange = sorted => {
    const sort = sorted[0];
    const sortId = sort.id === 'hubspot_url' ? 'hubspot_id' : sort.id; // Map hubspot_url to hubspot_id
    setOrdering(`${sort.desc ? '-' : ''}${sortId}`);
  };

  const getPaginationHeader = () => {
    const preferredValue = preferredValuesPerPage();
    const currentOption = valuesPerPage.find(option => option.value === preferredValue.value);
    return (
      <Select
        className="select-pagination"
        isSearchable={false}
        onChange={onPageSizeChange}
        options={valuesPerPage}
        value={currentOption}
        styles={{
          container: styles => ({
            ...styles,
            boxSizing: 'border-box',
            minWidth: '145px',
            height: '38px'
          })
        }}
      />
    );
  };

  const columns = [
    {
      Header: Header('Company'),
      accessor: 'name',
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Product'),
      accessor: 'product',
      Cell: ({ original }) => getProductLabel(original.product),
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Status'),
      accessor: 'status',
      Cell: ({ original }) => getStatusLabel(original.status),
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('HubSpot'),
      accessor: 'hubspot_url',
      Cell: ({ original }) => GetHubspotLink(original.hubspot_url),
      sortable: true,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Created'),
      accessor: 'created_at',
      Cell: ({ original }) => (original.created_at ? original.created_at.split('T')[0] : null),
      sortable: true,
      maxWidth: 100,
      headerClassName: 'hidden'
    },
    {
      Header: Header('Demo/Testing'),
      accessor: 'is_internal',
      Cell: ({ original }) => BoolToCheck(original.is_internal),
      maxWidth: 150,
      headerClassName: 'hidden'
    },
    {
      Header: null,
      Cell: ({ original }) => CellEditLink(original.id),
      maxWidth: 50,
      headerClassName: 'hidden'
    }
  ];

  return (
    <div className="staff-companies">
      <div id="InvestorHeader" className="header" style={{ marginTop: '26px' }}>
        <div className="title-container">
          <h2 style={{ height: '38px' }}>Companies</h2>
        </div>
        <div className="right-header">
          <Link to="/advisor/companies/create">
            <button type="button" className="btn btn-primary" style={{ height: '38px' }}>
              Add Company
            </button>
          </Link>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          padding: '10px',
          background: 'rgb(227,231,237,0.3)',
          border: '1px solid rgb(33, 37, 41, 0.2)',
          borderBottom: 'none'
        }}
      >
        <div
          className="form-group search client"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            placeItems: 'center'
          }}
        >
          <div>
            <input
              className="form-control react-autosuggest__input"
              placeholder="Company, Hubspot ID, Stripe ID"
              value={search}
              onChange={onSearchChange}
              style={{ height: '38px', width: '250px' }}
            />
            <span className="icon-search_icn" />
          </div>
          <div style={{ display: 'flex', placeContent: 'center' }}>
            <SelectWrapper
              styles={{ width: '150px' }}
              options={[{ value: '', label: 'All Products' }, ...PRODUCT_TYPE_LIST]}
              value={product}
              className="list-dropdown"
              onChange={e => onSelectChange(e, setProduct)}
            />
          </div>
          <div style={{ display: 'flex', placeContent: 'center' }}>
            <SelectWrapper
              styles={{ width: '130px' }}
              options={[{ value: '', label: 'All Status' }, ...COMPANY_STATUS_LIST]}
              value={status}
              className="list-dropdown"
              onChange={e => onSelectChange(e, setStatus)}
            />
          </div>
          <div style={{ display: 'flex', placeContent: 'center' }}>
            <SelectWrapper
              styles={{ width: '160px' }}
              options={[
                { value: '', label: 'All Hubspot Status' },
                { value: 'linked', label: 'Linked' },
                { value: 'not linked', label: 'Not Linked' }
              ]}
              value={hubspotFilter}
              className="list-dropdown"
              onChange={e => onSelectChange(e, setHubspotFilter)}
            />
          </div>
          <div style={{ display: 'flex', placeContent: 'center' }}>
            <SelectWrapper
              styles={{ width: '160px' }}
              options={[{ value: '', label: 'All companies' }, ...COMPANY_TYPE_LIST]}
              value={isDemoTesting}
              className="list-dropdown"
              onChange={e => onSelectChange(e, setIsDemoTesting)}
            />
          </div>
          <div className="select-options__pagination">{getPaginationHeader()}</div>
        </div>
      </div>

      <ReactTable
        data={companies}
        columns={columns}
        pageSize={pageSize}
        className="-striped -highlight"
        page={page - 1}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortedChange={onSortedChange}
        minRows={1}
        pages={meta?.total_pages || 0}
        showPageSizeOptions={false}
        noDataText="No Companies."
        defaultSorted={[{ id: 'companyName', asc: true }]}
        resizable={false}
        manual
      />
    </div>
  );
};

export default connect(state => ({
  companies: state.staffCompanies.list,
  meta: state.staffCompanies.meta
}))(StaffCompaniesTable);
