import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class UnlinkClientModal extends Component {
  constructor(props) {
    super(props);
    this.onHideModal = this.onHideModal.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onHideModal() {
    const { onHide } = this.props;
    onHide();
  }

  onClick() {
    const { investor } = this.props;
    this.props.onUnlink(investor);
  }

  render() {
    const { show, submitting, investor } = this.props;
    const { household } = investor;
    return (
      <Modal
        id="delete-client-modal"
        ref={c => (this.modal = c)}
        className="modal-lg"
        show={show}
        onHidden={this.onHideModal}
      >
        <ModalHeader />
        <ModalBody>
          <h3 className="modal-title">Remove following client from {household.name} household?</h3>
          <p className="description top">
            <b>!</b>
            {investor.full_name}
          </p>
          <div className="text-sm-center actions">
            <LoadingButton loading={submitting} onClick={this.onClick}>
              Remove Client
            </LoadingButton>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

UnlinkClientModal.propTypes = {
  account: PropTypes.object,
  show: PropTypes.bool,
  submitting: PropTypes.bool.isRequired
};

UnlinkClientModal.defaultProps = {
  submitting: false
};
