import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import { getInvestorReportCoverData } from 'reports/utils';
import ReportViewerModal from 'reports/viewer/modal';
import RiskToleranceQuestionnaireSingleReport from '..';

const RiskToleranceQuestionnaireSingleReportViewer = ({
  account,
  questionnaire,
  reportCoverData
}) => {
  const { accountProvider, investorProvider, prospectProvider, user, userProvider } =
    useContext(AdvisorContext);

  const [coverData, setCoverData] = useState(reportCoverData);
  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);

  const subtitle = account.name || account.investor.full_name;
  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const changeOrientation = value => {
    setOrientation(value);
  };

  useEffect(() => {
    // the `RiskToleranceQuestionnaireSingleReportViewer` component is now responsible for
    // rendering both account-level and investor-level RTQs. The `account` prop can have all
    // the data that an account has, or the minimum required in the case of an investor. For that
    // reason, the following check needs to be done to get the report cover data properly
    if (_.isEmpty(reportCoverData) && account?.id)
      accountProvider.getReportCoverData(account.id).then(data => {
        setCoverData(data);
      });
    else if (_.isEmpty(reportCoverData) && account?.investor?.id)
      getInvestorReportCoverData(account.investor, investorProvider, prospectProvider).then(
        data => {
          setCoverData(data);
        }
      );
  }, [JSON.stringify(account)]);

  const report = (
    <RiskToleranceQuestionnaireSingleReport
      account={account}
      coverData={coverData}
      discussionTopics={questionnaire.discussion_topics}
      isScoredQuestionnaireTemplate={questionnaire.template?.is_scored}
      lastQuestionnaireAnsweredBy={questionnaire.answered_by}
      orientation={orientation}
      questions={questionnaire.questions}
      subtitle={subtitle}
      title={questionnaire.template?.name}
      user={user}
      withCustomBranding={withCustomBranding}
    />
  );

  return (
    <ReportViewerModal
      buttonClassName="btn btn-outline-primary font-weight-normal"
      buttonText="PDF"
      changeOrientation={changeOrientation}
      fullyLoadedReportAssets
      orientation={orientation}
      report={report}
    />
  );
};

RiskToleranceQuestionnaireSingleReportViewer.defaultProps = {
  questionnaire: {},
  reportCoverData: {}
};

RiskToleranceQuestionnaireSingleReportViewer.propTypes = {
  account: PropTypes.object.isRequired,
  questionnaire: PropTypes.object,
  reportCoverData: PropTypes.object
};

export default RiskToleranceQuestionnaireSingleReportViewer;
