import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router';

import './styles.scss';

export function getInvestorId(account) {
  let investorId;
  if ('investor' in account) investorId = account.investor.id;
  else if ('investor_id' in account) investorId = account.investor_id;
  return investorId;
}

const ScoreBubble = ({ score, url, className, scoreDisplay, isStrategy }) => {
  if (score) {
    const cssClass = `score-bubble ${className} -level-${Math.ceil(score)}${
      isStrategy ? '-strategy' : ''
    }`;
    let value = '';
    if (scoreDisplay) value = scoreDisplay;
    else value = score.toFixed(1) === '10.0' ? '10' : score.toFixed(1);
    if (url)
      return (
        <Link className={cssClass} to={url}>
          {value}
        </Link>
      );
    return <span className={cssClass}>{value}</span>;
  }
  return null;
};

ScoreBubble.defaultProps = {
  className: '',
  isStrategy: false,
  scoreDisplay: null,
  url: null
};

ScoreBubble.propTypes = {
  className: PropTypes.string,
  isStrategy: PropTypes.bool,
  scoreDisplay: PropTypes.number,
  score: PropTypes.number.isRequired,
  url: PropTypes.string
};

export default ScoreBubble;
