// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop.light-backdrop.show {
  background: white;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.85) 100%);
  opacity: 1;
}

#create-investor-modal .modal-dialog.modal-lg {
  max-width: 800px;
}
#create-investor-modal .modal-body div > h1 {
  font-size: 1.85rem;
}
#create-investor-modal .modal-body div > img {
  width: 200px;
  margin-bottom: 30px;
}
#create-investor-modal #create-investor-form {
  margin: 50px auto 25px auto;
  max-width: 350px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/guest/create-investor-modal/styles.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,wFAAA;EACA,UAAA;AADF;;AAKE;EACE,gBAAA;AAFJ;AAMI;EACE,kBAAA;AAJN;AAOI;EACE,YAAA;EACA,mBAAA;AALN;AASE;EACE,2BAAA;EACA,gBAAA;AAPJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.modal-backdrop.light-backdrop.show {\n  background: white;\n  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 100%);\n  opacity: 1;\n}\n\n#create-investor-modal {\n  .modal-dialog.modal-lg {\n    max-width: 800px;\n  }\n\n  .modal-body div {\n    & > h1 {\n      font-size: 1.85rem;\n    }\n\n    & > img {\n      width: 200px;\n      margin-bottom: 30px;\n    }\n  }\n\n  #create-investor-form {\n    margin: 50px auto 25px auto;\n    max-width: 350px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
