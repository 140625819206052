/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import './styles.scss';
import {
  RECOMMENDED_TYPE,
  TARGET_TYPE,
  byAccountAmount,
  byModelWeight,
  getEntitiesFromProposal,
  matchingModelsChartData
} from './utils';

const MatchingModelsChart = ({ proposal }) => {
  const accounts = getEntitiesFromProposal(proposal, TARGET_TYPE);
  const models = getEntitiesFromProposal(proposal, RECOMMENDED_TYPE);

  const targetName = proposal?.target_label || '';
  const recommendedName = proposal?.recommended_label || '';

  const modelsTotalValue = proposal.recommended_total_value ?? 0;
  const withPercentages = proposal.recommended_with_percentages;

  const { accountsOverallScore, accountsTotalAmount, maxNumberOfElements, modelsOverallScore } =
    matchingModelsChartData(accounts, models);

  const renderContentElements = (elements, type) =>
    Array.from(Array(maxNumberOfElements)).map((e, i) => {
      const element = elements[i];

      if (element && type === TARGET_TYPE) {
        const key = `${element.display_name.toLowerCase().replace(/\s/g, '-')}-${
          element.prism_overall
        }-${element.value}`;
        return (
          <Fragment key={key}>
            <div className="content__score-with-label">
              <ScoreBubble score={element.prism_overall} /> <p>{element.display_name}</p>
            </div>
            <div className="content__amount">{formatMoney(element.value)}</div>
            <div className="content__weight">
              {formatPercentage(element.value / accountsTotalAmount, 100, 2)}
            </div>
          </Fragment>
        );
      }

      if (element && type === RECOMMENDED_TYPE) {
        const key = `${element.display_name.toLowerCase().replace(/\s/g, '-')}-${
          element.prism_overall
        }-${element.weight}`;
        const amount = withPercentages
          ? (element.weight / 100) * accountsTotalAmount
          : element.amount ?? modelsTotalValue;
        return (
          <Fragment key={key}>
            <div className="content__score-with-label">
              <ScoreBubble score={element.prism_overall} /> <p>{element.display_name}</p>
            </div>
            <div className="content__amount">{formatMoney(amount)}</div>
            <div className="content__weight">{formatPercentage(element.weight, 1, 2)}</div>
          </Fragment>
        );
      }

      return (
        <Fragment key={`empty-${type}-${i}`}>
          <div className="content__score-with-label" />
          <div className="content__amount" />
          <div className="content__weight" />
        </Fragment>
      );
    });

  const renderTotalElement = (label, overall, amount, weight) => (
    <>
      <div className="content__score-with-label">
        <ScoreBubble score={overall} /> <p>{label}</p>
      </div>
      <div className="content__amount">{amount}</div>
      <div className="content__weight">{weight}</div>
    </>
  );

  const hasModels = !_.isEmpty(models);

  return (
    <div id="matching-models-chart" className={cn({ 'no-recommended': !hasModels })}>
      <div className="matching-models-chart__header">{targetName || 'Current portfolio'}</div>
      {hasModels && (
        <div className="matching-models-chart__header header--blue">
          {recommendedName || 'Suggested model'}
        </div>
      )}
      <div className="matching-models-chart__content">
        {renderContentElements(accounts.sort(byAccountAmount), TARGET_TYPE)}
      </div>
      {hasModels && (
        <div className="matching-models-chart__content">
          {renderContentElements(models.sort(byModelWeight), RECOMMENDED_TYPE)}
        </div>
      )}
      <div className="matching-models-chart__total">
        {renderTotalElement(
          'Total',
          accountsOverallScore,
          formatMoney(accountsTotalAmount),
          '100%'
        )}
      </div>
      {hasModels && (
        <div className="matching-models-chart__total total--blue">
          {renderTotalElement(
            'Overall',
            modelsOverallScore,
            formatMoney(withPercentages ? accountsTotalAmount : modelsTotalValue),
            '100%'
          )}
        </div>
      )}
    </div>
  );
};

MatchingModelsChart.propTypes = {
  proposal: PropTypes.object.isRequired
};

export default MatchingModelsChart;
