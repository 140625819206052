import PropTypes from 'prop-types';

export const MetadataPropTypes = {
  allowChangeText: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowHide: PropTypes.bool,
  body: PropTypes.string.isRequired,
  custom: PropTypes.bool,
  customActions: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      permission: PropTypes.string,
      default: PropTypes.bool,
      value: PropTypes.bool
    })
  ),
  hidden: PropTypes.bool,
  order: PropTypes.number,
  title: PropTypes.string.isRequired
};

export const ProposalPropTypes = {
  benchmark_analytics: PropTypes.object,
  benchmark_management_fee: PropTypes.number,
  benchmark: PropTypes.object,
  disclosure: PropTypes.string,
  metadata: PropTypes.object,
  recommended_analytics: PropTypes.object,
  recommended_management_fee: PropTypes.number,
  recommended: PropTypes.object,
  starting_value: PropTypes.number,
  subtitle: PropTypes.string,
  target_analytics: PropTypes.object,
  target_management_fee: PropTypes.number,
  target: PropTypes.object,
  template: PropTypes.object
};
