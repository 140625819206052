import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Link from 'components/utils/link';
import InvestorProvider from 'providers/investor';

import './styles.scss';

class HeaderDark extends Component {
  static childContextTypes = {
    investorProvider: PropTypes.object.isRequired
  };

  getChildContext() {
    const { investorProvider } = this.props;
    return { investorProvider };
  }

  render() {
    return (
      <nav id="HeaderDark">
        <Link className="brand-logo" to="/signin">
          <img src="/img/brand/stratifi-white.svg" alt="StratiFi" />
        </Link>
      </nav>
    );
  }
}

HeaderDark.propTypes = {
  investorProvider: PropTypes.object.isRequired
};

export default connect(
  null,
  dispatch => ({
    investorProvider: new InvestorProvider({ dispatch })
  })
)(HeaderDark);
