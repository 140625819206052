// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#FeatureLimitReached {
  padding: 0 20px;
}
#FeatureLimitReached h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.166667;
  font-style: normal;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
}
#FeatureLimitReached p {
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: center;
}
#FeatureLimitReached .actions {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#FeatureLimitReached button,
#FeatureLimitReached a {
  flex: 0 1 45%;
  max-width: 180px;
}

.modal-backdrop.fade.show + .modal-backdrop.fade.show {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/feature-limit/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,0BAAA;AACJ;AAEE;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;AAAJ;AAGE;;EAEE,aAAA;EACA,gBAAA;AADJ;;AAKA;EACE,aAAA;AAFF","sourcesContent":["#FeatureLimitReached {\n  padding: 0 20px;\n\n  h3 {\n    font-size: 1.5rem;\n    font-weight: 500;\n    line-height: 1.166667;\n    font-style: normal;\n    letter-spacing: 0em;\n    text-align: center;\n    margin-bottom: 20px;\n    text-transform: capitalize;\n  }\n\n  p {\n    font-size: 0.875rem;\n    font-style: normal;\n    line-height: 1.5;\n    letter-spacing: 0em;\n    text-align: center;\n  }\n  .actions {\n    margin-top: 30px;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n  }\n\n  button,\n  a {\n    flex: 0 1 45%;\n    max-width: 180px;\n  }\n}\n\n.modal-backdrop.fade.show + .modal-backdrop.fade.show {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
