import clone from 'clone';
import Chart from '..';
import defaultOptions from './options';

export const LEVEL_PALLET = [
  '#54B34A',
  '#73B045',
  '#92AD40',
  '#B1A93A',
  '#D2A636',
  '#F1A330',
  '#F19131',
  '#E77B35',
  '#DB6438',
  '#D14E3B',
  '#C7383E'
];

class PrismHistoryChart extends Chart {
  className = 'score-distribution-chart';

  options = {};

  getOptions(newProps) {
    const props = newProps || this.props;
    if (!this.options[props.id]) this.options[props.id] = clone(defaultOptions);

    const options = this.options[props.id];
    if (props.type) {
      options.graphs[0].valueField = `score${props.type}`;
      options.graphs[1].valueField = `target${props.type}`;
      options.graphs[0].balloonText = `PRISM: [[score${props.type}]]<br>Tolerance: [[target${
        props.type
      }]]<br>Date: [[date]]`;
      options.graphs[0].fillColors = [
        LEVEL_PALLET[Math.round(props.maxValue)],
        LEVEL_PALLET[Math.round(props.maxValue / 2)],
        LEVEL_PALLET[0]
      ];
    }
    return { ...options, ...props.options };
  }
}

export default PrismHistoryChart;
