// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.or-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.or-separator .or-separator__text {
  padding: 0 3rem;
  font-weight: 400;
}
.or-separator .or-separator__left,
.or-separator .or-separator__right {
  width: 12rem;
  height: 1px;
  background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/components/or-separator/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AAEE;;EAEE,YAAA;EACA,WAAA;EACA,uBAAA;AAAJ","sourcesContent":[".or-separator {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 2rem 0;\n\n  .or-separator__text {\n    padding: 0 3rem;\n    font-weight: 400;\n  }\n\n  .or-separator__left,\n  .or-separator__right {\n    width: 12rem;\n    height: 1px;\n    background-color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
