import { hasCustomIntegrationHandler } from 'containers/integrations-dashboard/utils';
import _ from 'lodash';
import { createSelector } from 'reselect';

export const integrationsSelector = state => state.integrations;

export const integrationsConnectedSelector = state => state.integrations.connected;
export const integrationsMetaSelector = state => state.integrations.meta;
export const integrationsProvidersSelector = state => state.integrations.providers;
export const integrationsSyncedAccountsSelector = state => state.integrations.syncedAccounts;
export const integrationsUnassignedAccountsCountSelector = state =>
  state.integrations.unassignedAccountsCount;

export const availableIntegrationsProvidersSelector = createSelector(
  [integrationsProvidersSelector, integrationsConnectedSelector],
  (providers, connectedIntegrations) => {
    if (_.isEmpty(providers)) return [];

    const integrations = connectedIntegrations ?? [];
    const connectedProviders = integrations.map(i => i.provider);
    const byConnectedProviders = p => !connectedProviders.includes(p.provider);

    return providers.filter(byConnectedProviders).sort((a, b) => {
      const pA = hasCustomIntegrationHandler(a);
      const pB = hasCustomIntegrationHandler(b);

      // If pA or pB have a custom integration handler,
      // they are sorted one index lower
      if (pA && !pB) return -1;
      if (!pA && pB) return 1;

      // There are no changes between providers sorting
      return 0;
    });
  }
);
