import React from 'react';

import './styles.scss';

const Separator = () => (
  <div className="auth-separator">
    <div className="auth-separator__left" />
    <div className="auth-separator__text">or</div>
    <div className="auth-separator__right" />
  </div>
);
export default Separator;
