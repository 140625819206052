import LoadingWave from 'components/loading-wave';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

const ComplianceOverall = ({ stats }) => {
  if (!stats) return null;

  return (
    <div className="company-overall-details">
      <div className="info">
        <div className="num-label">Advisors</div>
        <div className="number">
          {Number.isInteger(stats.total_advisors) ? (
            <FormattedNumber value={stats.total_advisors} />
          ) : (
            <LoadingWave />
          )}
        </div>
      </div>
      <div className="info">
        <div className="num-label">Prospects</div>
        <div className="number">
          {Number.isInteger(stats.total_prospects) ? (
            <FormattedNumber value={stats.total_prospects} />
          ) : (
            <LoadingWave />
          )}
        </div>
      </div>
      <div className="info">
        <div className="num-label">Clients</div>
        <div className="number">
          {Number.isInteger(stats.total_clients) ? (
            <FormattedNumber value={stats.total_clients} />
          ) : (
            <LoadingWave />
          )}
        </div>
      </div>
      <div className="info">
        <div className="num-label">Households</div>
        <div className="number">
          {Number.isInteger(stats.total_households) ? (
            <FormattedNumber value={stats.total_households} />
          ) : (
            <LoadingWave />
          )}
        </div>
      </div>
      <div className="info">
        <div className="num-label">Accounts</div>
        <div className="number">
          {Number.isInteger(stats.total_accounts) ? (
            <FormattedNumber value={stats.total_accounts} />
          ) : (
            <LoadingWave />
          )}
        </div>
      </div>
      <div className="info">
        <div className="num-label">Total Assets</div>
        <div className="number">
          {typeof stats.total_assets === 'number' ? (
            <FormattedNumber value={stats.total_assets} format="currency" />
          ) : (
            <LoadingWave />
          )}
        </div>
      </div>
      <div className="tooltip-div">
        <span>
          <span
            data-tip="This box shows your firm level number of each item. Total prospective individuals,
            clients, households (excluding prospective households), accounts (excluding prospective and
            held-away) and total asset value (also excluding prospective ahd held-away)."
            data-for="question-mark"
            className="question-mark"
          >
            ?
          </span>
          <ReactTooltip
            id="question-mark"
            className="question-mark-tip"
            effect="solid"
            place="left"
          />
        </span>
      </div>
    </div>
  );
};

ComplianceOverall.propTypes = {
  stats: PropTypes.shape({
    total_accounts: PropTypes.number,
    total_advisors: PropTypes.number,
    total_assets: PropTypes.number,
    total_clients: PropTypes.number,
    total_households: PropTypes.number,
    total_investors: PropTypes.number,
    total_prospects: PropTypes.number
  }).isRequired
};

export default ComplianceOverall;
