import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { getPrismFactorName, OVERALL_FACTOR_ATTR } from '../common/prism-factor/utils';
import { ACCOUNT_PRISM_FACTOR_ATTRS, PRISM_FACTOR_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';
import { getAccountMinMaxPrismFactorAttrs } from '../utils';

const AccountPrismOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const prismFactorAttr = filters?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
  const prismFactorName = getPrismFactorName(prismFactorAttr);

  const [minAttr, maxAttr] = getAccountMinMaxPrismFactorAttrs(prismFactorAttr);

  return (
    <FilterContainer
      attrs={ACCOUNT_PRISM_FACTOR_ATTRS}
      filters={filters}
      label={prismFactorName ? `Account PRISM ${prismFactorName}` : 'Account PRISM Overall'}
    >
      <MinMaxSliderFilter
        filters={filters}
        key={`account-${minAttr}-${maxAttr}`}
        maxAttr={maxAttr}
        minAttr={minAttr}
        setFilters={setFilters}
        setRequiresPageIndexReset={setRequiresPageIndexReset}
      />
    </FilterContainer>
  );
};

AccountPrismOverallFilter.propTypes = { ...filterPropTypes };

AccountPrismOverallFilter.defaultProps = { ...filterDefaultProps };

export default AccountPrismOverallFilter;
