import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { SIGNED_IN } from 'constants/actstream';
import { AuthenticationContext } from 'containers/auth';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import LoadingWave from '../loading-wave';
import DeviceRequiredForm from './device-required-form';
import EmailForm from './email-form';
import MagicLink from './magic-link';
import PassForm from './password-form';
import PinCodeForm from './pin-code-form';
import { GOOGLE_SOCIAL_PROVIDER, MICROSOFT_SOCIAL_PROVIDER } from './social-auth/constants';

const DUPLICATED_2FA_DEVICE = 'Duplicated2FADevice';
const MAGIC_LINK_METHOD = 'magic_link';
const REQUIRED_OR_INVALID_2FA_DEVICE = 'RequiredOrInvalid2FADevice';
const REQUIRED_OR_INVALID_PIN_CODE = 'RequiredOrInvalidVerificationCode';
const THROTTLED = 'Throttled';
const VALIDATION_ERROR = 'ValidationError';

const validate = values => {
  const errors = {};
  if (!values?.access_token) {
    errors.email =
      errors.email || validation.required(values.email) || validation.email(values.email);
    if (values.is_valid_email)
      errors.password = errors.password || validation.required(values.password);
  }
  if (values?.pin !== undefined) errors.pin = errors.pin || validation.required(values.pin);
  return errors;
};

const SigninForm = ({
  actionProvider,
  backendErrors,
  fields,
  handleSubmit,
  registerError,
  skipError,
  submitting,
  values
}) => {
  const { authProvider, trustedDeviceProvider } = useContext(AuthenticationContext);

  const emailRef = useRef(null);
  const pinRef = useRef(null);

  const [devices, setDevices] = useState([]);
  const [isDeviceRequired, setIsDeviceRequired] = useState(false);
  const [isPinCodeRequired, setIsPinCodeRequired] = useState(false);
  const [loadingNewEmailPin, setLoadingNewEmailPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');

  const params = new URLSearchParams(window.location.search);
  const emailParam = params.get('email');
  const tokenParam = params.get('token');

  const removeMagicLinkUrlParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('email');
    url.searchParams.delete('token');
    window.history.replaceState(null, '', url);
  };

  const sendMagicLink = () =>
    authProvider.sendMagicLink({ email: fields.email.value }).then(response => {
      if (response.error) {
        registerError({
          error: {
            message: response.error instanceof Object ? response.error.message : response.error
          }
        });
        return response;
      }
      skipError();
      fields.password.autofill('');
      fields.is_magic_link.onChange(true);
      return response;
    });

  const onUseMagicLink = () => {
    fields.password.autofill('');
    sendMagicLink();
  };

  const onUsePassword = () => {
    skipError();
    fields.password.autofill('');
    fields.is_magic_link.onChange(false);
  };

  /**
   * Handles the login process:
   * - If the user is in the response, the login was successful.
   * - If the user is not in the response, the login failed. The allowed devices are
   *   verified and the necessary changes are made to request the verification code.
   *   Ultimately, the resulting error is logged no matter which of the cases originated it.
   */
  const onLogin =
    values =>
    ({ data, error }) => {
      if (data && data.user) {
        if (values.remember)
          trustedDeviceProvider.register(values.trusted_device_id, window.navigator.userAgent);
        return actionProvider.slack({ verb: SIGNED_IN });
      }

      const allowedDevices = error?.errors?.allowed_devices;
      const socialAccountUid = error?.errors?.social_account_uid;

      // saves the `social_account_uid` related to the user who is trying to login (if possible)
      if (socialAccountUid) fields.social_account_uid.onChange(socialAccountUid);

      // shows the form to enter the verification code
      if (
        [REQUIRED_OR_INVALID_PIN_CODE, DUPLICATED_2FA_DEVICE].includes(error?.reason) &&
        allowedDevices &&
        !isDeviceRequired
      ) {
        setDevices(allowedDevices);
        setIsPinCodeRequired(true);
        if (allowedDevices?.length === 1 && allowedDevices[0] === 'email')
          fields?.device_type.onChange('email');

        // saves the new one-time password generated by the backend in case of using
        // the magic link method to sign in when the pin code is requested
        if (error?.errors?.otp_password) fields.password.onChange(error.errors.otp_password);
      }

      // shows the form to setup a device for the first time if the company has 2FA enforcement enabled
      if (error?.reason === REQUIRED_OR_INVALID_2FA_DEVICE && error?.errors?.totp_key) {
        setIsDeviceRequired(true);
        fields.totp_key.onChange(error.errors.totp_key);
      }

      // shows errors related to the device's first-time setup and verification code
      if ((values?.pin || values?.totp_key) && error?.message)
        return toast.error(() => <div>{error?.errors?.error_message || error?.message}</div>);

      // changes the login method if the magic-link is being used, but it fails
      if (
        emailParam &&
        tokenParam &&
        fields.is_magic_link.value &&
        error?.reason === VALIDATION_ERROR
      ) {
        removeMagicLinkUrlParams();
        onUsePassword();
        return registerError({
          error: {
            message:
              'Looks like something went wrong validating the magic link. Please try a different login method.'
          }
        });
      }

      return registerError({ error });
    };

  const onChangeEmail = () => {
    fields.is_valid_email.onChange(false);
    fields.email.autofill('');
    fields.password.autofill('');
    skipError();
  };

  const onCheckEmail = values => {
    if (values.password) delete values.password;
    return authProvider
      .checkEmail(values)
      .then(async ({ data, error }) => {
        if (error) {
          if (error.reason !== THROTTLED)
            error.message =
              'This account cannot be found. Please use a different account or sign up for a new account';
          else error.message = 'Too many login attempts. Please try again later.';
          registerError({ error });
          setLoading(false);
        } else {
          setUsername(data?.first_name || data?.email);
          if (data?.recommended_method === MAGIC_LINK_METHOD && !tokenParam) await sendMagicLink();
          fields.is_valid_email.onChange(true);
          skipError();
        }
      })
      .catch(registerError);
  };

  const onSubmit = async values => {
    if (values?.social_provider === GOOGLE_SOCIAL_PROVIDER)
      return authProvider.loginWithGoogle(values).then(onLogin(values)).catch(registerError);

    if (values?.social_provider === MICROSOFT_SOCIAL_PROVIDER)
      return authProvider.loginWithMicrosoft(values).then(onLogin(values)).catch(registerError);

    return authProvider.login(values).then(onLogin(values)).catch(registerError);
  };

  const setSocialProvider = (provider, accessToken) => {
    fields.social_provider.onChange(provider);
    fields.access_token.onChange(accessToken);
  };

  /**
   * Loads the `FingerprintJS` library and gets the visitor id to be used
   * as the trusted device id.
   */
  useEffect(() => {
    if (!fields.trusted_device_id.value)
      FingerprintJS.load()
        .then(fp => fp.get())
        .then(({ visitorId }) => {
          fields.trusted_device_id.onChange(visitorId);
        });
  }, [fields.trusted_device_id.value]);

  /**
   * Defines all necessary values from the parameters received in the URL
   */
  useEffect(() => {
    if (emailParam) {
      // convert the string to its constituent bytes in UTF-8, and then encode the bytes
      // https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
      const emailBytes = Uint8Array.from(atob(emailParam), m => m.codePointAt(0));
      const email = new TextDecoder().decode(emailBytes);
      fields.email.onChange(email);
    }
    if (tokenParam) {
      fields.is_magic_link.autofill(true);
      fields.password.autofill(tokenParam);
    }
  }, [emailParam, tokenParam]);

  /**
   * If the `email` parameter is received in the URL, this email is used in the form values,
   * and if it hasn't been validated yet, the `onCheckEmail` function is executed.
   */
  useEffect(() => {
    const processAutomaticCheckEmail = async () => {
      if (emailParam && values.email && !values.is_valid_email) {
        setLoading(true);
        await onCheckEmail(values);
      }
    };
    processAutomaticCheckEmail();
  }, [emailParam, values.email, values.is_valid_email]);

  /**
   * If the `onCheckEmail` function is executed and the email is valid, the `is_valid_email`
   * attribute changes to `true`. If there is a `token` parameter in the URL, that would indicate
   * that it's trying to do an automatic login via a magic link, so it waits until the
   * `trusted_device_id` attribute is set in order to try to log in as if the user had done it.
   */
  useEffect(() => {
    const processAutomaticLogin = async () => {
      if (
        tokenParam &&
        values.email &&
        values.is_valid_email &&
        values.password &&
        values.trusted_device_id &&
        !isPinCodeRequired
      ) {
        await onSubmit(values);
        setLoading(false);
      }
    };
    processAutomaticLogin();
  }, [tokenParam, values.email, values.is_valid_email, values.password, values.trusted_device_id]);

  /**
   * If the verification code is required, focus on the pin form field
   */
  useEffect(() => {
    if (isPinCodeRequired) {
      if (pinRef.current) pinRef.current.focus();
    } else if (emailRef.current) emailRef.current.focus();
  }, [isPinCodeRequired]);

  /**
   * If the verification code is required, request a new pin code for
   * the email device.
   */
  useEffect(() => {
    if (isPinCodeRequired && fields?.device_type?.value === 'email') {
      setLoadingNewEmailPin(true);

      const payload = {
        access_token: fields?.access_token?.value,
        device_type: fields?.device_type?.value,
        email: fields?.email?.value,
        password: fields?.password?.value,
        social_account_uid: fields?.social_account_uid?.value
      };

      authProvider
        .newEmailPin2FADevice(payload)
        .then(({ data, error }) => {
          if (error) toast.error(error?.message || error);
          else toast.success(() => data?.detail);
        })
        .catch(() => {
          toast.error(() => 'Something went wrong. Please try again.');
        })
        .finally(() => setLoadingNewEmailPin(false));
    }
  }, [isPinCodeRequired, fields?.device_type?.value]);

  if (loading) return <LoadingWave />;

  if (isPinCodeRequired && !isDeviceRequired)
    return (
      <PinCodeForm
        disabledMehod={loadingNewEmailPin}
        devices={devices}
        fields={fields}
        onSubmit={handleSubmit(onSubmit)}
        pinRef={pinRef}
        submitting={submitting}
      />
    );

  if (isDeviceRequired && !isPinCodeRequired)
    return (
      <DeviceRequiredForm
        fields={fields}
        onSubmit={handleSubmit(onSubmit)}
        submitting={submitting}
      />
    );

  if (!isPinCodeRequired && !isDeviceRequired && !fields.is_valid_email.value)
    return (
      <EmailForm
        backendErrors={backendErrors}
        emailRef={emailRef}
        fields={fields}
        onCheckEmail={handleSubmit(onCheckEmail)}
        onLogin={onLogin}
        setSocialProvider={setSocialProvider}
        skipError={skipError}
        submitting={submitting}
      />
    );

  if (
    !isPinCodeRequired &&
    !isDeviceRequired &&
    fields.is_valid_email.value &&
    fields.is_magic_link.value
  )
    return (
      <MagicLink
        backendErrors={backendErrors}
        fields={fields}
        onChangeEmail={onChangeEmail}
        onSendMagicLink={sendMagicLink}
        onUsePassword={onUsePassword}
        skipError={skipError}
        username={username}
      />
    );

  if (
    !isPinCodeRequired &&
    !isDeviceRequired &&
    fields.is_valid_email.value &&
    !fields.is_magic_link.value
  )
    return (
      <PassForm
        backendErrors={backendErrors}
        fields={fields}
        onChangeEmail={onChangeEmail}
        onSubmit={handleSubmit(onSubmit)}
        onUseMagicLink={onUseMagicLink}
        skipError={skipError}
        submitting={submitting}
        username={username}
      />
    );

  return null;
};

SigninForm.propTypes = {
  actionProvider: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'signin',
  fields: [
    'access_token',
    'device_type',
    'email',
    'is_magic_link',
    'is_valid_email',
    'password',
    'pin',
    'remember',
    'social_account_uid',
    'social_provider',
    'totp_key',
    'trusted_device_id'
  ],
  initialValues: { is_magic_link: false, is_valid_email: false, remember: false },
  validate
})(BackendValidation(SigninForm));
