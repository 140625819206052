import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import { DEFAULT_AMOUNT, PotentialGainLossText } from '../utils';

const SingleYearOutcomeQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  const defaultAmount = question.data?.default_amount || DEFAULT_AMOUNT;
  const selectedOption = question.data.labels.find(label => label.value === question.answer.value);

  return (
    <ReportQuestion question={question}>
      <Text>
        <PotentialGainLossText defaultAmount={defaultAmount} option={selectedOption} />
      </Text>
    </ReportQuestion>
  );
};

SingleYearOutcomeQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default SingleYearOutcomeQuestion;
