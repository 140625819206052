import _ from 'lodash';
import { getTargetInvestors } from 'utils/utils';

export const ADVISOR_SIGNER_TYPE = 'advisor';
export const INVESTOR_SIGNER_TYPE = 'investor';

export const SIGNATURE_TYPE_ATTR = 'signatureType';
export const SIGNER_ID_ATTR = 'id';
export const SIGNER_NAME_ATTR = 'name';
export const SIGNER_TYPE_ATTR = 'type';

export const SIGNATURE_TYPE = 'signature';
export const ACKNOWLEDGMENT_TYPE = 'acknowledgment';

export const ME_PREFIX = '[Me]';

export const DEFAULT_SIGNER = {
  [SIGNER_TYPE_ATTR]: ADVISOR_SIGNER_TYPE,
  [SIGNER_ID_ATTR]: null,
  [SIGNER_NAME_ATTR]: null,
  [SIGNATURE_TYPE_ATTR]: null
};

export const getSignerTypeOptions = () => [
  { label: 'Advisor', value: ADVISOR_SIGNER_TYPE },
  { label: 'Investor', value: INVESTOR_SIGNER_TYPE }
];

export const getManagerOptions = (user, managers, signers, editing) => {
  const managerOptions = _.orderBy(
    managers
      .filter(manager => manager.id !== user.advisor.id)
      .map(manager => ({
        label: `${manager.user.first_name} ${manager.user.last_name}`,
        value: manager.id
      })),
    ['label'],
    ['desc']
  );

  managerOptions.unshift({
    label: `${ME_PREFIX} ${user.first_name} ${user.last_name}`,
    value: user.advisor.id
  });

  return managerOptions.filter(manager => {
    if (editing)
      return !signers
        .filter(signer => signer.type === ADVISOR_SIGNER_TYPE && !!signer.id)
        .map(signer => signer.id)
        .includes(manager.value);
    return true;
  });
};

export const getInvestorOptions = (investors, signers, editing) =>
  _.orderBy(
    investors
      .filter(investor => {
        if (editing)
          return !signers
            .filter(signer => signer.type === INVESTOR_SIGNER_TYPE && !!signer.id)
            .map(signer => signer.id)
            .includes(investor.id);

        return true;
      })
      .map(investor => ({ label: investor.name, value: investor.id })),
    ['label'],
    ['desc']
  );

export const getSignatureTypeOptions = () => [
  { label: 'Signature', value: SIGNATURE_TYPE },
  { label: 'Acknowledgment', value: ACKNOWLEDGMENT_TYPE }
];

export const getRequiredSigners = (templateContent, managers, investors) => {
  const requiredSigners = Object.values(templateContent)
    .filter(section => section.signature)
    .reduce((acc, section) => {
      if (section?.signers?.length) return [...acc, ...section.signers];
      return [];
    }, []);

  const uniqueRequiredSigners = _.uniqBy(
    requiredSigners,
    signer => `${signer[SIGNER_TYPE_ATTR]}-${signer[SIGNER_ID_ATTR]}`
  ).map(signer => {
    const requiresSignature = requiredSigners
      .filter(
        s =>
          s[SIGNER_ID_ATTR] === signer[SIGNER_ID_ATTR] &&
          s[SIGNER_TYPE_ATTR] === signer[SIGNER_TYPE_ATTR]
      )
      .some(s => s[SIGNATURE_TYPE_ATTR] === SIGNATURE_TYPE);
    return {
      ...signer,
      [SIGNATURE_TYPE_ATTR]: requiresSignature ? SIGNATURE_TYPE : ACKNOWLEDGMENT_TYPE
    };
  });

  return uniqueRequiredSigners
    .map(signer => {
      if (signer.type === ADVISOR_SIGNER_TYPE) {
        const manager = managers.find(manager => manager.id === signer.id);
        if (manager) return { ...signer, email: manager.user.email };
      }
      if (signer.type === INVESTOR_SIGNER_TYPE) {
        const investor = investors.find(investor => investor.id === signer.id);
        if (investor) return { ...signer, email: investor.email };
      }
      return signer;
    })
    .sort((a, b) => {
      // sort by type, "investor" first
      if (a.type !== b.type) return a.type === 'investor' ? -1 : 1;
      // if type is the same, sort by name alphabetically
      return a.name.localeCompare(b.name);
    });
};

export const setProposalSigners = (content, proposal, proposalProvider) => {
  const investors = getTargetInvestors(proposal);
  const signatureSectionKeys = Object.entries(content)
    .filter(([key, data]) => !!data.signature)
    .map(([key, data]) => key);

  const signers = investors.map(investor => ({
    [SIGNER_TYPE_ATTR]: INVESTOR_SIGNER_TYPE,
    [SIGNER_ID_ATTR]: investor.id,
    [SIGNER_NAME_ATTR]: investor.name,
    [SIGNATURE_TYPE_ATTR]: SIGNATURE_TYPE
  }));

  signatureSectionKeys.forEach(key => {
    content[key] = { ...content[key], signers };
  });

  return proposalProvider.update(proposal.id, { template_content: { ...content } });
};
