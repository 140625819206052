import DistributionSummary from 'components/advisor/proposal/distribution-summary/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';

const ProposalHoldings = ({ breakSection, metadata, orientation, proposal, proposalType }) => {
  const { body, title } = metadata;

  return (
    <BaseSection body={body} title={title} breakSection={breakSection} wrap>
      <DistributionSummary
        benchmark={proposal.benchmark}
        benchmarkName={proposal.benchmark_label}
        orientation={orientation}
        proposal={proposal}
        proposalType={proposalType}
        recommended={proposal.recommended}
        recommendedName={proposal.recommended_label}
        start={proposal.start}
        startingValue={proposal.starting_value}
        target={proposal.target}
        targetName={proposal.target_label}
      />
    </BaseSection>
  );
};

ProposalHoldings.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  orientation: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalHoldings.defaultProps = {
  breakSection: false
};

export default ProposalHoldings;
