/* eslint-disable jsx-a11y/anchor-is-valid */
import cn from 'classnames';
import { VerboseErrorInput as Input } from 'components/form';
import Choice from 'components/form/choice';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import './styles.scss';

const validate = values => {
  const errors = {};
  errors.meeting_calendar_link =
    errors.meeting_calendar_link ||
    (values.meeting_calendar_link && validation.url(values.meeting_calendar_link));
  return errors;
};

const MeetingCalendar = ({
  errors,
  fields,
  handleSubmit,
  initializeForm,
  registerError,
  setAdvisorUser,
  title,
  updateAdvisorProfile,
  user
}) => {
  const onSubmit = useCallback(async values => {
    const response = await updateAdvisorProfile(values);
    if (response.error) registerError(response);
    else {
      toast.success('Meeting calendar options saved successfully');
      setAdvisorUser();
    }
  }, []);

  useEffect(() => {
    const values = {
      meeting_calendar_link: user.advisor.meeting_calendar_link,
      share_meeting_calendar_link: user.advisor.share_meeting_calendar_link
    };
    initializeForm(values);
  }, [JSON.stringify(user)]);

  return (
    <div id="meeting-calendar">
      <h1 id="share-your-calendar" className="title">
        {title}
      </h1>
      <span>Add your calendar link below so investors can easily schedule meetings with you.</span>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="content">
          <div className="row">
            <div className="col">
              <Choice
                {...fields.share_meeting_calendar_link}
                toggle={fields.share_meeting_calendar_link.onChange}
                title="I want to share my calendar link"
              />
            </div>
          </div>
          <div className="row row-calendar-link">
            <div className="col">
              <span className="label">Your calendar link</span>
              <Input
                className="form-control"
                placeholder="https://my-calendar-service.com/abc123"
                type="text"
                {...fields.meeting_calendar_link}
              />
            </div>
            <div className="col">
              <span className="label">What investors will see</span>
              <a
                className={cn('btn', 'btn-primary', { disabled: errors.meeting_calendar_link })}
                href={
                  !errors.meeting_calendar_link ? fields.meeting_calendar_link.value : undefined
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                Book a meeting
              </a>
            </div>
          </div>
        </div>

        <div className="actions">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

MeetingCalendar.defaultProps = {
  title: 'Share Your Calendar'
};

MeetingCalendar.propTypes = {
  setAdvisorUser: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateAdvisorProfile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'editMeetingCalendar',
  fields: ['meeting_calendar_link', 'share_meeting_calendar_link'],
  initialValues: { meeting_calendar_link: '' },
  validate
})(BackendValidation(MeetingCalendar));
