import cn from 'classnames';
import { VerboseErrorInput as Input } from 'components/form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import './styles.scss';

const ColorPicker = ({
  className,
  defaultColor,
  disableAlpha,
  disabled,
  error,
  getSelectedColor,
  helpText,
  inputClassName,
  label,
  labelClassName,
  touched
}) => {
  const [color, setColor] = useState(defaultColor);
  const [display, setDisplay] = useState(false);

  const handleColorPickerDisplay = () => {
    setDisplay(!display);
  };

  const handleOnChange = event => {
    const { value } = event.target;
    setColor(value);
    setTimeout(() => {
      getSelectedColor(value);
    }, 500);
  };

  const handlePickerOnChange = color => {
    setColor(color.hex);
  };

  const handlePickerOnChangeComplete = color => {
    getSelectedColor(color.hex);
  };

  return (
    <div className={cn('color-picker', className)}>
      <div className={cn('color-picker__text', { disabled })}>
        {label && (
          <label className={cn(labelClassName)} htmlFor="color">
            {label}
          </label>
        )}
        {helpText && <span className="text--help">{helpText}</span>}
      </div>
      <div
        className="color-picker__example"
        style={{ backgroundColor: color }}
        onClick={handleColorPickerDisplay}
      />
      <Input
        type="text"
        className={cn('color-picker__input', inputClassName)}
        disabled={disabled}
        maxLength={7}
        name="color"
        onChange={handleOnChange}
        onFocus={handleColorPickerDisplay}
        value={color}
        error={error}
        touched={touched}
      />

      {display && (
        <div className="color-picker__modal">
          <div
            role="none"
            className="modal__backdrop"
            onClick={handleColorPickerDisplay}
            onKeyDown={handleColorPickerDisplay}
          />
          <ChromePicker
            color={color}
            disableAlpha={disableAlpha}
            onChange={handlePickerOnChange}
            onChangeComplete={handlePickerOnChangeComplete}
          />
        </div>
      )}
    </div>
  );
};

ColorPicker.defaultProps = {
  className: undefined,
  defaultColor: '',
  disableAlpha: true,
  disabled: false,
  error: '',
  getSelectedColor: () => {},
  helpText: null,
  inputClassName: undefined,
  label: null,
  labelClassName: '',
  touched: false
};

ColorPicker.propTypes = {
  className: PropTypes.string,
  defaultColor: PropTypes.string,
  disableAlpha: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  getSelectedColor: PropTypes.func,
  helpText: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  touched: PropTypes.bool
};

export default ColorPicker;
