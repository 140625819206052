import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  LIGHT_CYAN_COLOR,
  LIGHT_GRAPHITE_COLOR,
  TABLE_CELL_HEADER_01,
  TABLE_CELL_HEADER_02,
  TABLE_HEADER_BACKGROUND_COLOR
} from 'reports/base/styles';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  cellText: { textAlign: 'center' },
  contentRow: { minHeight: 45 },
  contentCell: {
    paddingVertical: 10,
    paddingHorizontal: 5
  },
  contentFirstCell: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR, flexBasis: 130, flexGrow: 0 },
  headerCell: {
    borderTopWidth: 5,
    borderTopStyle: 'solid',
    borderTopColor: TABLE_HEADER_BACKGROUND_COLOR,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: TABLE_HEADER_BACKGROUND_COLOR,
    backgroundColor: TABLE_HEADER_BACKGROUND_COLOR
  },
  headerFirstCell: { flexBasis: 130, flexGrow: 0 },
  targetHeaderCell: {
    ...TABLE_CELL_HEADER_01,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR
  },
  recommendedHeaderCell: {
    ...TABLE_CELL_HEADER_02,
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_CYAN_COLOR
  }
});

export default styles;
