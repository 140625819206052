/* eslint-disable import/no-import-module-exports, import/no-unresolved */
import * as Sentry from '@sentry/browser';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import 'amcharts3/amcharts/plugins/export/export.css';
import Authentication from 'containers/auth';
import { createHistory } from 'history';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { useRouterHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { checkBrowserSupport } from 'utils/utils';
import ErrorFallback from './app/error-fallback';
import './assets/scss/commons/stratifi.scss';
import { getFormats, getLocale, getMessages } from './lang/utils';
import setupStore from './setupStore';

const ROOT_ELEMENT = document.getElementById('app');

const browserSupport = checkBrowserSupport();

// init: react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

// init: sentry
if (process.env.SENTRY_ENVIRONMENT && process.env.SENTRY_PUBLIC_KEY)
  Sentry.init({
    // Main options
    dsn: process.env.SENTRY_PUBLIC_KEY,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [new RewriteFramesIntegration({})],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Capture Replay for 0% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  });

// init: router history
const browserHistory = useRouterHistory(createHistory)({ basename: '/' });

// init: store + history
const store = setupStore(browserHistory);
const history = syncHistoryWithStore(browserHistory, store);

// setting up the intl provider
const locale = getLocale();
const formats = getFormats();
const messages = getMessages(locale);

// App component
const App = () => {
  const AppRouter = require('./app').default; // eslint-disable-line global-require
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale={locale} formats={formats} messages={messages}>
        <Authentication>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AppRouter history={history} store={store} />
          </ErrorBoundary>
        </Authentication>
      </IntlProvider>
    </Provider>,
    ROOT_ELEMENT
  );
};

if (browserSupport === 0)
  $('#browser-support').append(
    `<p class="browser-upgrade">
      <span>You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</span>
    </p>`
  );
else {
  if (browserSupport === -1)
    $('#browser-support').append(
      '<p class="browser-unspecified">We have not tested our application in this browser. The application may work incorrectly.</p>'
    );

  if (module.hot)
    // enable webpack hot module replacement for app
    module.hot.accept('./app', () => {
      App();
    });

  App();
}
