import clone from 'clone';
import PropTypes from 'prop-types';
import Chart from '..';

class XYDistributionChart extends Chart {
  className = 'chart-distributions chart-xy';

  getOptions() {
    const themes = this.context.config.chart;
    const { xTitle, yTitle } = this.props;
    const finalXTitle = xTitle || themes.bubble.distribution.valueAxes[0].title;
    const finalYTitle = yTitle || themes.bubble.distribution.valueAxes[1].title;
    let options = {
      ...clone(themes.common.default, false),
      ...clone(themes.bubble.default, false),
      ...clone(themes.bubble.distribution, false),
      ...this.props.options
    };

    options = {
      ...options,
      valueAxes: [
        {
          ...options.valueAxes[0],
          title: finalXTitle
        },
        {
          ...options.valueAxes[1],
          title: finalYTitle
        }
      ]
    };
    return options;
  }
}

XYDistributionChart.contextTypes = {
  config: PropTypes.object.isRequired
};

XYDistributionChart.propTypes = {
  id: PropTypes.string.isRequired,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  data: PropTypes.array.isRequired,
  options: PropTypes.object
};

export default XYDistributionChart;
