export const PRODUCT_STARTER = 'starter';
export const PRODUCT_STARTER_LABEL = 'Starter';
export const PRODUCT_PROFESSIONAL = 'professional';
export const PRODUCT_PROFESSIONAL_LABEL = 'Professional';
export const PRODUCT_BUSINESS = 'business';
export const PRODUCT_BUSINESS_LABEL = 'Business';
export const PRODUCT_ENTERPRISE = 'enterprise';
export const PRODUCT_ENTERPRISE_LABEL = 'Enterprise';

export const PRODUCT_TYPE_LIST = [
  {
    value: PRODUCT_STARTER,
    label: PRODUCT_STARTER_LABEL
  },
  {
    value: PRODUCT_PROFESSIONAL,
    label: PRODUCT_PROFESSIONAL_LABEL
  },
  {
    value: PRODUCT_BUSINESS,
    label: PRODUCT_BUSINESS_LABEL
  },
  {
    value: PRODUCT_ENTERPRISE,
    label: PRODUCT_ENTERPRISE_LABEL
  }
];

export const COMPANY_STATUS_PENDING = 'pending';
export const COMPANY_STATUS_PENDING_LABEL = 'Pending Review';
export const COMPANY_STATUS_TRIAL = 'trial';
export const COMPANY_STATUS_TRIAL_LABEL = 'Trial';
export const COMPANY_STATUS_ACTIVE = 'active';
export const COMPANY_STATUS_ACTIVE_LABEL = 'Active';
export const COMPANY_STATUS_INACTIVE = 'inactive';
export const COMPANY_STATUS_INACTIVE_LABEL = 'Inactive';

export const COMPANY_STATUS_LIST = [
  {
    value: COMPANY_STATUS_PENDING,
    label: COMPANY_STATUS_PENDING_LABEL
  },
  {
    value: COMPANY_STATUS_TRIAL,
    label: COMPANY_STATUS_TRIAL_LABEL
  },
  {
    value: COMPANY_STATUS_ACTIVE,
    label: COMPANY_STATUS_ACTIVE_LABEL
  },
  {
    value: COMPANY_STATUS_INACTIVE,
    label: COMPANY_STATUS_INACTIVE_LABEL
  }
];

export const COMPANY_TYPE_LIST = [
  {
    value: true,
    label: 'Demo/Testing Companies'
  },
  {
    value: false,
    label: 'Real Customers'
  }
];
