import _ from 'lodash';
import { createSelector } from 'reselect';

const pathNameSelector = state => state.routing.locationBeforeTransitions.pathname;
const investorViewSelector = state => state.investors.view;
const prospectViewSelector = state => state.prospects.view;

export const isProspectSelector = createSelector([pathNameSelector], pathName =>
  pathName.includes('advisor/prospects/')
);

export const investorSelector = createSelector(
  [isProspectSelector, prospectViewSelector, investorViewSelector],
  (isProspect, prospect, investor) => {
    if (isProspect) return prospect;
    return investor;
  }
);

const investorViewAccountsSelector = state => state.investors.viewAccounts;
const prospectViewAccountsSelector = state => state.prospects.viewAccounts;
const investorViewAccountsSelectorWH = state => state.investors.viewAccountsWithHousehold;
const prospectViewAccountsSelectorWH = state => state.prospects.viewAccountsWithHousehold;

export const investorAccountsSelector = createSelector(
  [isProspectSelector, investorViewAccountsSelector, prospectViewAccountsSelector],
  (isProspect, investorViewAccounts, prospectViewAccounts) => {
    if (isProspect) return prospectViewAccounts.filter(item => item.excluded === false);

    return investorViewAccounts.filter(item => item.excluded === false);
  }
);

export const investorAccountsWithHouseholdSelector = createSelector(
  [isProspectSelector, investorViewAccountsSelectorWH, prospectViewAccountsSelectorWH],
  (isProspect, investorViewAccounts, prospectViewAccounts) => {
    if (isProspect) return prospectViewAccounts;

    return investorViewAccounts;
  }
);

export const investorAccountsWithPrismSelector = createSelector(
  [investorAccountsSelector],
  accounts => accounts.filter(a => !_.isEmpty(a.prism_score) && !_.isEmpty(a.prism_score_summary))
);
