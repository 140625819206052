import SelectAdvisor from 'components/advisor/SelectAdvisor';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import _ from 'lodash';
import CollaborationSelect from 'pages/collaboration-hub/select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import columns from './columns';
import './styles.scss';

const normalizeFollowers = followers =>
  followers.map(follower => ({
    id: follower.id,
    name: `${follower.user.first_name} ${follower.user.last_name}`
  }));

const AccessEndorsementsEditModal = ({ data, onConfirm, onHide, show }) => {
  const [followee, setFollowee] = useState(data.followee);
  const [followers, setFollowers] = useState(normalizeFollowers(data.followers));

  const isCreating = _.isEmpty(data.followee);
  const isSubmitDisabled = _.isEmpty(followee) || (_.isEmpty(followers) && isCreating);
  const selectedFollowers = followers.map(follower => follower.id);
  const selectedFollowerOptions = followee?.id
    ? [...selectedFollowers, followee.id]
    : selectedFollowers;

  const onSelectFollowee = option => {
    if (option) setFollowee({ id: option.value, name: option.label });
    else setFollowee({});
  };

  const onRemoveFollower = followerId => {
    setFollowers(followers.filter(follower => follower.id !== followerId));
  };

  const onAddFollower = option => {
    setFollowers(prevFollowers => [...prevFollowers, { id: option.value, name: option.label }]);
  };

  const onSaveHandler = async () => {
    await onConfirm(
      followee.id,
      followers.map(follower => follower.id),
      `You have successfully updated the access endorsements for ${followee.name}'s information.`
    );
    onHide();
  };

  useEffect(() => {
    setFollowee(data.followee);
    setFollowers(normalizeFollowers(data.followers));
  }, [isCreating]);

  return (
    <Modal id="access-endorsements-edit-modal" className="modal-lg" onHide={onHide} show={show}>
      <ModalHeader />
      <ModalBody>
        <div className="text-left mb-4">
          <h4>{isCreating ? 'Create' : 'Edit'} Access Endorsements</h4>
        </div>
        <div className="edit-modal">
          <div className="edit-modal__detail">
            <label htmlFor="advisor">
              <b className="d-block">Advisor</b>
              {isCreating ? (
                <SelectAdvisor
                  isClearable
                  name="advisor"
                  onSelect={onSelectFollowee}
                  useSearchIcon={false}
                />
              ) : (
                <span className="h6">{followee.name}</span>
              )}
            </label>

            <label htmlFor="collaboration-member">
              <b>Select Authorized Advisor</b>
              <CollaborationSelect
                onAdd={onAddFollower}
                selectedOptions={selectedFollowerOptions}
              />
            </label>
          </div>

          <div className="edit-modal__followers">
            <ReactTable
              minRows={1}
              columns={columns(onRemoveFollower)}
              data={followers}
              noDataText={<div style={{ marginTop: 50 }}>No advisors found.</div>}
              showPagination={false}
              sortable={false}
            />
          </div>

          <div className="text-right edit-modal__buttons">
            <button type="button" className="btn btn-black mr-2" onClick={onHide}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={isSubmitDisabled}
              onClick={onSaveHandler}
            >
              Save
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

AccessEndorsementsEditModal.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AccessEndorsementsEditModal;
