import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import './styles.scss';

const convertPrice = (amount, recurring) => {
  if (!recurring?.interval) return amount;
  const period = recurring.interval === 'year' ? 12 : 1;
  const cycles = recurring.interval_count * period;
  return amount / cycles;
};

const Plan = ({
  id: planId,
  description,
  name,
  amount,
  label,
  recurring,
  metadata,
  trial_days: trialDays,
  onSelectPrice,
  upgrading
}) => {
  const normalizedAmount = convertPrice(amount, recurring);
  const priceSummary = {
    id: planId,
    name,
    interval: recurring?.interval,
    amount,
    label,
    trialDays
  };

  return (
    <div
      className={cs('Plan', {
        '-recommended': metadata.recommended,
        '-free': normalizedAmount === 0
      })}
    >
      {metadata.recommended && <div className="ribbon">RECOMMENDED</div>}
      <div className="name">{name}</div>

      <div className="description">{description}</div>
      <div className="amount">
        <FormattedNumber value={normalizedAmount} format="currency" />
      </div>
      <div className="billing-info">
        {metadata.amount_info && (
          <>
            {metadata.amount_info}
            <br />
            {normalizedAmount > 0 ? <>when paid {recurring.interval}ly</> : <br />}
          </>
        )}
      </div>
      <button
        onClick={() => onSelectPrice(priceSummary)}
        className="btn btn-select-plan"
        type="button"
        data-action="select-plan"
        data-plan={name.toLowerCase()}
      >
        <span className="initial">{upgrading ? 'Change' : 'Get Started'}</span>
      </button>
      {!upgrading && !!trialDays && <div className="extra-info">Free trial included</div>}
    </div>
  );
};

Plan.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  metadata: PropTypes.shape({
    amount_info: PropTypes.string,
    recommended: PropTypes.string
  }),
  recurring: PropTypes.shape({
    interval: PropTypes.string,
    interval_count: PropTypes.number
  }),
  trial_days: PropTypes.number.isRequired,
  onSelectPrice: PropTypes.func.isRequired,
  upgrading: PropTypes.bool
};

Plan.defaultProps = {
  metadata: {},
  recurring: {},
  upgrading: false
};

export default Plan;
