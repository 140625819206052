import cn from 'classnames';
import SpinnerLoader from 'components/performance-spinner';
import { SecurityDetailTabs as tabs } from 'constants/tabs';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  accessControlLink,
  compareAnalyzeLink,
  overviewLink,
  returnsLink,
  riskReturnsLink,
  underlyingHoldingLink
} from './links';
import './styles.scss';

const DEFAULT_TAB = tabs.OVERVIEW;

const CustomSecurityDetail = ({ children, location, params, security }) => {
  const { marketProvider, customSecurityProvider } = useContext(AdvisorContext);

  const [tab, setTab] = useState(DEFAULT_TAB);
  const [loading, setLoading] = useState(true);

  const securityID = params?.id;

  const setCurrentTab = () => {
    const matchingTab = Object.values(tabs).find(value => location.pathname.includes(value));
    if (matchingTab && tab !== matchingTab) setTab(matchingTab);
  };

  useEffect(() => {
    setLoading(true);
    setCurrentTab();
    marketProvider.getClassifications();
    customSecurityProvider.get(securityID).finally(() => {
      setLoading(false);
    });
    return () => {
      customSecurityProvider.clear();
    };
  }, []);

  useEffect(() => {
    setCurrentTab();
    setLoading(true);
    customSecurityProvider.get(securityID).finally(() => {
      setLoading(false);
    });
  }, [location.pathname]);

  if (loading) return <SpinnerLoader spinnerLoading />;

  return (
    <div className="security-details-view__holder">
      <div className="security-details-view__title">
        <div className="security-details-view__model-name">
          {security.ticker_name && <h3>{security.ticker_name}</h3>}
        </div>
        <ul className="security-details-view__tabs tabs">
          <li className={cn({ active: tab === tabs.OVERVIEW })}>{overviewLink({ securityID })}</li>
          <li className={cn({ active: tab === tabs.RETURNS })}>{returnsLink({ securityID })}</li>
          <li className={cn({ active: tab === tabs.PRISM })}>{riskReturnsLink({ securityID })}</li>
          <li className={cn({ active: tab === tabs.UNDERLYING_HOLDING })}>
            {underlyingHoldingLink({ securityID })}
          </li>
          <li className={cn({ active: tab === tabs.ACCESS_CONTROL })}>
            {accessControlLink({ securityID })}
          </li>
          <li className={cn({ active: tab === tabs.PERFORMANCE })}>
            {compareAnalyzeLink({ securityID })}
          </li>
        </ul>
      </div>
      {children}
    </div>
  );
};

CustomSecurityDetail.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired
};

CustomSecurityDetail.contextTypes = {
  marketProvider: PropTypes.object.isRequired,
  customSecurityProvider: PropTypes.object.isRequired
};

const CustomSecurityDetailWithRouter = withRouter(CustomSecurityDetail);

export default connect(state => ({
  security: state.customSecurity.currentSecurity
}))(CustomSecurityDetailWithRouter);
