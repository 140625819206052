import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { validation } from 'utils/form';
import DraggableEmail from './draggable-email';
import './styles.scss';

const ProposalSignatureRequestEmailForm = ({ nextStep, requiredSigners, setRequiredSigners }) => {
  const [reorderEnabled, setReorderEnabled] = useState(false);

  const hasSomeInvalidEmail = requiredSigners.some(
    signer => !!(validation.required(signer?.email) || validation.email(signer.email))
  );

  const onMoveItemHandler = useCallback(
    (fromIndex, toIndex) => {
      const updatedRequiredSigners = [...requiredSigners];
      const [movedItem] = updatedRequiredSigners.splice(fromIndex, 1);
      updatedRequiredSigners.splice(toIndex, 0, movedItem);

      const reorderedRequiredSigners = updatedRequiredSigners.map((item, index) => ({
        ...item,
        priority: reorderEnabled ? index : 0
      }));

      setRequiredSigners(reorderedRequiredSigners);
    },
    [JSON.stringify(requiredSigners), reorderEnabled]
  );

  const onChangeHandler = (id, type, email) => {
    setRequiredSigners(prevRequiredSigners =>
      prevRequiredSigners.map(item =>
        item.id === id && item.type === type ? { ...item, email } : item
      )
    );
  };

  const onToggleHandler = () => {
    setReorderEnabled(prevReorderEnabled => !prevReorderEnabled);
  };

  useEffect(() => {
    if (reorderEnabled)
      setRequiredSigners(prevData => prevData.map((item, index) => ({ ...item, priority: index })));
    else setRequiredSigners(prevData => prevData.map(item => ({ ...item, priority: 0 })));
  }, [reorderEnabled]);

  return (
    <div id="signature-request-email-form">
      <div className="reorder-toggle">
        <label htmlFor="reorder">
          <Toggle
            className="toggle-primary"
            defaultChecked={reorderEnabled}
            onChange={onToggleHandler}
          />
          <span>Set signers order</span>
        </label>
      </div>

      <DndProvider backend={HTML5Backend}>
        {requiredSigners
          .sort((a, b) => a.priority - b.priority)
          .map((item, index) => (
            <DraggableEmail
              index={index}
              item={item}
              key={`${item.id}-${item.type}`}
              onChangeHandler={onChangeHandler}
              onMoveItemHandler={onMoveItemHandler}
              reorderEnabled={reorderEnabled}
            />
          ))}
      </DndProvider>

      <div className="actions">
        <button
          className="btn btn-primary btn-shadow"
          disabled={hasSomeInvalidEmail}
          onClick={nextStep}
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

ProposalSignatureRequestEmailForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  requiredSigners: PropTypes.array.isRequired,
  setRequiredSigners: PropTypes.func.isRequired
};

export default ProposalSignatureRequestEmailForm;
