// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#DriftExceptionsWidget .__react_component_tooltip.place-top:before,
#DriftExceptionsWidget .__react_component_tooltip.place-top:after {
  left: 94%;
}
#DriftExceptionsWidget .card-content {
  padding: 0 30px;
}
#DriftExceptionsWidget .expand-arrow-left,
#DriftExceptionsWidget .expand-arrow-right {
  position: absolute;
  top: 42%;
  width: 30px;
  height: 16px;
  fill: #afafaf;
  stroke: #afafaf;
  cursor: pointer;
}
#DriftExceptionsWidget .expand-arrow-left.page-not-available,
#DriftExceptionsWidget .expand-arrow-right.page-not-available {
  fill: #ebeeef;
  stroke: #ebeeef;
}
#DriftExceptionsWidget .expand-arrow-right {
  right: 0;
  transform: rotate(-90deg);
}
#DriftExceptionsWidget .expand-arrow-left {
  left: 0;
  transform: rotate(90deg);
}
#DriftExceptionsWidget .counter {
  position: absolute;
  right: 14px;
  top: 57px;
  font-size: 0.9em;
}
#DriftExceptionsWidget .slick-slider {
  max-width: 100%;
}
#DriftExceptionsWidget .slick-slider .slick-arrow {
  top: 42%;
}
#DriftExceptionsWidget .slick-slider .slick-arrow.slick-prev {
  left: -28px;
}
#DriftExceptionsWidget .slick-slider .slick-arrow.slick-next {
  right: -15px;
}
#DriftExceptionsWidget .page-available:hover {
  fill: #09acf8;
  stroke: #09acf8;
}
#DriftExceptionsWidget .slick-prev:before,
#DriftExceptionsWidget .slick-next:before {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/dashboard/widgets/drift-exceptions-widget/styles.scss"],"names":[],"mappings":"AAGE;;EAEE,SAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;;EAEE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;AAJJ;AAMI;;EACE,aAAA;EACA,eAAA;AAHN;AAOE;EACE,QAAA;EACA,yBAAA;AALJ;AAQE;EACE,OAAA;EACA,wBAAA;AANJ;AASE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,gBAAA;AAPJ;AAUE;EACE,eAAA;AARJ;AAUI;EACE,QAAA;AARN;AAUM;EACE,WAAA;AARR;AAUM;EACE,YAAA;AARR;AAaE;EACE,aAAA;EACA,eAAA;AAXJ;AAcE;;EAEE,aAAA;AAZJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#DriftExceptionsWidget {\n  .__react_component_tooltip.place-top:before,\n  .__react_component_tooltip.place-top:after {\n    left: 94%;\n  }\n\n  .card-content {\n    padding: 0 30px;\n  }\n\n  .expand-arrow-left,\n  .expand-arrow-right {\n    position: absolute;\n    top: 42%;\n    width: 30px;\n    height: 16px;\n    fill: #afafaf;\n    stroke: #afafaf;\n    cursor: pointer;\n\n    &.page-not-available {\n      fill: #ebeeef;\n      stroke: #ebeeef;\n    }\n  }\n\n  .expand-arrow-right {\n    right: 0;\n    transform: rotate(-90deg);\n  }\n\n  .expand-arrow-left {\n    left: 0;\n    transform: rotate(90deg);\n  }\n\n  .counter {\n    position: absolute;\n    right: 14px;\n    top: 57px;\n    font-size: 0.9em;\n  }\n\n  .slick-slider {\n    max-width: 100%;\n\n    .slick-arrow {\n      top: 42%;\n\n      &.slick-prev {\n        left: -28px;\n      }\n      &.slick-next {\n        right: -15px;\n      }\n    }\n  }\n\n  .page-available:hover {\n    fill: #09acf8;\n    stroke: #09acf8;\n  }\n\n  .slick-prev:before,\n  .slick-next:before {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
