/* eslint-disable default-param-last */
import {
  CLEAR_QUESTIONS,
  GET_QUESTIONNAIRE_TEMPLATE,
  GET_QUESTIONNAIRE_TEMPLATE_QUESTIONS,
  GET_QUESTIONS,
  LIST_QUESTIONNAIRE_TEMPLATES,
  SAVE_ANSWER,
  SAVE_DOCUMENTATION_NOTE
} from 'constants/actions';

export const initialState = {
  documentationNotes: {},
  questionnaires: {},
  templates: {}
};

const saveAnswer = (state, action) => {
  const { questionnaireId, index, answer } = action.data;
  const questionnaire = state.questionnaires[questionnaireId] || [];
  const questionIndex = index - 1;
  return {
    ...state,
    questionnaires: {
      ...state.questionnaires,
      [questionnaireId]: [
        ...questionnaire.slice(0, questionIndex),
        { ...questionnaire[questionIndex], answer },
        ...questionnaire.slice(questionIndex + 1)
      ]
    }
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        questionnaires: {
          ...state.questionnaires,
          [action.questionnaireId]: action.data
        }
      };

    case SAVE_ANSWER:
      return saveAnswer(state, action);

    case SAVE_DOCUMENTATION_NOTE:
      return {
        ...state,
        documentationNotes: { ...state.data, ...action.data }
      };

    case LIST_QUESTIONNAIRE_TEMPLATES:
      return {
        ...state,
        templates: action.data.reduce((acc, template) => ({ ...acc, [template.id]: template }), {})
      };

    case GET_QUESTIONNAIRE_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.data.id]: action.data
        }
      };

    case GET_QUESTIONNAIRE_TEMPLATE_QUESTIONS:
      return {
        ...state,
        questionnaires: {
          ...state.questionnaires,
          [action.questionnaireId]: action.data
        }
      };

    case CLEAR_QUESTIONS:
      return { ...state, questionnaires: {} };

    default:
      return state;
  }
};
