import cn from 'classnames';
import { FormGroup, VerboseErrorInput } from 'components/form';
import PhoneField from 'components/form/phone-field';
import PasswordHelper from 'components/password-helper';
import { AuthenticationContext } from 'containers/auth';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validate = values => {
  const errors = {};
  errors.first_name = errors.first_name || validation.required(values.first_name);
  errors.last_name = errors.last_name || validation.required(values.last_name);
  errors.email =
    errors.email || validation.required(values.email) || validation.email(values.email);
  errors.phone =
    errors.phone ||
    validation.required(values.phone) ||
    validation.internationalPhone(values.phone);
  errors.password1 = errors.password1 || validation.password(values.password1);
  errors.terms = errors.terms || validation.required(values.terms) || !values.terms;

  if (!values.invitation)
    errors.company_name = errors.company_name || validation.required(values.company_name);

  return errors;
};

export const SignupForm = ({
  fields,
  handleSubmit,
  initializeForm,
  invalid,
  params: { invitationKey },
  submitFailed,
  submitting
}) => {
  const { invitationProvider, authProvider, routerActions } = useContext(AuthenticationContext);

  const redirectUser = async invitation => {
    if (invitation) await authProvider.getUser(true);
    else routerActions.push(`/signup/verification-sent/`);
  };

  const validateInvitation = async key => {
    const { data: invitation, error } = await invitationProvider.validate(key);
    if (error) toast.error(() => <div>Your invitation is invalid or has expired!</div>);
    else
      initializeForm({
        invitation: key,
        first_name: invitation.first_name,
        last_name: invitation.last_name,
        email: invitation.email
      });
  };

  useEffect(() => {
    if (invitationKey) validateInvitation(invitationKey);
  }, [invitationKey]);

  const onSubmit = values =>
    new Promise((resolve, reject) => {
      const searchQuery = new URLSearchParams(window.location.search);
      const price = searchQuery.get('plan');
      if (price) values.price = price;

      const rep = searchQuery.get('rep');
      if (rep) values.rep = rep;

      authProvider.signup(values).then(({ data, error }) => {
        if (!_.isEmpty(data)) {
          resolve();
          redirectUser(values.invitation);
        } else if (error && error.errors) reject(error.errors);
        else {
          reject();
          toast.error(() => <div>Sorry, something went wrong.</div>);
        }
      });
    });

  return (
    <div className="signup-form">
      <h3 className="text-lg-center text-md-center text-sm-center signup-title">
        Create an Account
      </h3>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="on" className="mt-1">
        <div className="row">
          <div className="col-md-6">
            <FormGroup {...fields.first_name}>
              <label htmlFor="first-name" className="sign-up-label">
                First name
              </label>
              <VerboseErrorInput
                placeholder="Enter your first name"
                type="text"
                className="form-control sign-up-field"
                {...fields.first_name}
                autoFocus
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup {...fields.last_name}>
              <label htmlFor="last-name" className="sign-up-label">
                Last name
              </label>
              <VerboseErrorInput
                placeholder="Enter your last name"
                type="text"
                className="form-control sign-up-field"
                {...fields.last_name}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormGroup {...fields.email}>
              <label htmlFor="email" className="sign-up-label">
                Email
              </label>
              <VerboseErrorInput
                placeholder="Enter your email"
                type="text"
                className="form-control sign-up-field"
                {...fields.email}
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup {...fields.phone}>
              <label htmlFor="phone" className="sign-up-label">
                Phone
              </label>
              <PhoneField
                {...fields.phone}
                className="form-control sign-up-field"
                placeholder="Enter your phone"
              />
            </FormGroup>
          </div>
        </div>
        {!fields.invitation.value && (
          <div className="row">
            <div className="col-md-12">
              <FormGroup {...fields.company_name}>
                <label htmlFor="company-name" className="sign-up-label">
                  Company Name
                </label>
                <VerboseErrorInput
                  placeholder="Enter your company name"
                  type="text"
                  className="form-control sign-up-field"
                  {...fields.company_name}
                />
              </FormGroup>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <FormGroup {...fields.password1}>
              <label htmlFor="password" className="sign-up-label">
                Password
              </label>
              <div data-tip data-for="global" data-event="focusin" data-event-off="focusout">
                <VerboseErrorInput
                  placeholder="Enter password"
                  type="password"
                  className="form-control sign-up-field"
                  {...fields.password1}
                />
              </div>
              <ReactTooltip
                border="true"
                place="top"
                effect="solid"
                id="global"
                type="light"
                className="password-tooltip"
              >
                <PasswordHelper value={fields.password1.value} />
              </ReactTooltip>
            </FormGroup>
          </div>
        </div>
        <div className="mt-1 terms-statement text-center">
          <input id="terms-of-use" type="checkbox" {...fields.terms} />
          <label
            htmlFor="terms-of-use"
            className={cn({
              'text-danger':
                (!fields.terms.pristine && !fields.terms.valid) ||
                (submitFailed && !fields.terms.valid)
            })}
          >
            By clicking on the button below you accept our{' '}
            <a
              className="underline-decoration"
              href="https://www.stratifi.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              className="underline-decoration"
              href="https://www.stratifi.com/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Privacy Policy
            </a>
          </label>
        </div>
        <div className="mt-1 center-block">
          <input
            disabled={submitting || invalid}
            value={submitting ? 'Processing...' : 'Sign Up'}
            type="submit"
            className="center-block btn btn-primary btn-block"
          />
        </div>
        <div className="mt-2 text-center">
          Already a member? <Link to="/signin">Login</Link>
        </div>
      </form>
    </div>
  );
};

SignupForm.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  invitationProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired
};

SignupForm.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'signup',
  fields: [
    'first_name',
    'last_name',
    'email',
    'password1',
    'company_name',
    'phone',
    'terms',
    'invitation'
  ],
  validate
})(SignupForm);
