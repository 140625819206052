import PropTypes from 'prop-types';
import React from 'react';

const PaginationFooter = ({
  count,
  page,
  totalPages,
  pageSize,
  onPreviousPageChangeHandler,
  onPageSizeChangeHandler,
  onPageChangeHandler,
  onNextPageChangeHandler,
  paginationValues
}) => (
  <div className="sdt">
    <div className="sdt__pagination">
      <div className="pagination__options">
        <div className="options__records">
          <span>{count} Records</span>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          disabled={page === 1}
          onClick={onPreviousPageChangeHandler}
        >
          Previous
        </button>
        <div className="options__page">
          <span>Page</span>
          <input
            className="form-control"
            max={count}
            min={1}
            onChange={onPageChangeHandler}
            type="number"
            value={page}
          />
          <span>of {totalPages}</span>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          disabled={page === totalPages}
          onClick={onNextPageChangeHandler}
        >
          Next
        </button>
        <div className="options__page-size">
          <select onChange={onPageSizeChangeHandler} value={pageSize}>
            {paginationValues.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} / page
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </div>
);

PaginationFooter.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  pageSize: PropTypes.number,
  onPreviousPageChangeHandler: PropTypes.func,
  onPageSizeChangeHandler: PropTypes.func,
  onPageChangeHandler: PropTypes.func,
  onNextPageChangeHandler: PropTypes.func,
  paginationValues: PropTypes.array
};

PaginationFooter.defaultProps = {
  count: 0,
  page: 1,
  totalPages: 1,
  pageSize: 10,
  onPreviousPageChangeHandler: () => {},
  onPageSizeChangeHandler: () => {},
  onPageChangeHandler: () => {},
  onNextPageChangeHandler: () => {},
  paginationValues: []
};

export default PaginationFooter;
