// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-button .btn {
  height: 35px;
  font-size: 0.9rem;
  padding-right: 30px;
  position: relative;
}
.expandable-button .arrow-icon {
  font-size: 1.3rem;
  margin-left: 3px;
  position: absolute;
  margin-top: -1px;
}
.expandable-button .collapsed .arrow-icon {
  margin-top: -9px;
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/app/components/expandable-button/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,yBAAA;AAAJ","sourcesContent":[".expandable-button {\n  .btn {\n    height: 35px;\n    font-size: 0.9rem;\n    padding-right: 30px;\n    position: relative;\n  }\n  .arrow-icon {\n    font-size: 1.3rem;\n    margin-left: 3px;\n    position: absolute;\n    margin-top: -1px;\n  }\n  .collapsed .arrow-icon {\n    margin-top: -9px;\n    transform: rotate(180deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
