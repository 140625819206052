import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import { MODEL_PROPOSAL_TYPE, SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import HistoricalReturnsChart from 'components/charts/historical-returns-chart';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { formatMoney } from 'utils/utils';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import { HISTORICAL_RETURNS_CHART_ID } from './constants';
import './styles.scss';

const mergeReturns = ({ target, recommended, benchmark }) => {
  const accumulate =
    attrName =>
    (accumulator, { date, value }) => {
      accumulator[date] = { ...accumulator[date], date, [attrName]: value };
      return accumulator;
    };

  let returns = {};
  returns = target.reduce(accumulate('target'), returns);
  if (recommended) returns = recommended.reduce(accumulate('recommended'), returns);
  if (benchmark) returns = benchmark.reduce(accumulate('benchmark'), returns);
  return _.sortBy(Object.values(returns), [i => i.date]);
};

const ProposalPerformance = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  proposalCharts,
  proposalType,
  setMetadata
}) => {
  const { proposalProvider } = useContext(AdvisorContext);
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const hasBenchmark = !!proposal.benchmark;
  const hasRecommended = !!proposal.recommended;
  const isModelOrSecurityProposalType =
    proposalType === MODEL_PROPOSAL_TYPE || proposalType === SECURITY_PROPOSAL_TYPE;

  const mergedReturns = useMemo(
    () =>
      mergeReturns({
        target: proposal.target_analytics.cumulatives,
        recommended: hasRecommended ? proposal.recommended_analytics.cumulatives : null,
        benchmark: hasBenchmark ? proposal.benchmark_analytics.cumulatives : null
      }),
    [JSON.stringify(proposal)]
  );

  const chartOptions = useMemo(
    () => ({
      benchmarkName: proposal.benchmark_label,
      recommendedName: proposal.recommended_label,
      targetName: isModelOrSecurityProposalType ? proposal.target_label : ''
    }),
    [JSON.stringify(proposal)]
  );

  const onChartReady = useCallback(
    (id, data) => {
      proposalProvider.saveChartImage({ [id]: data });
    },
    [proposalCharts[HISTORICAL_RETURNS_CHART_ID]]
  );

  return (
    <EditBoxText {...proposalMetadata}>
      <DateValue proposal={proposal} withManagementFee />

      <div
        className={cn('fancy-grid', {
          'no-recommended': !hasRecommended,
          'no-benchmark': !hasBenchmark
        })}
      >
        <div>Starting Value</div>
        <div>{proposal.target_label || 'Current Portfolio'}</div>
        {hasRecommended && <div>{proposal.recommended_label}</div>}
        {hasBenchmark && <div>{proposal.benchmark_label}</div>}

        <div>{formatMoney(proposal.starting_value)}</div>
        <div>{formatMoney(proposal.target_analytics.cumulative_value)}</div>
        {hasRecommended && (
          <div>{formatMoney(proposal.recommended_analytics.cumulative_value)}</div>
        )}
        {hasBenchmark && <div>{formatMoney(proposal.benchmark_analytics.cumulative_value)}</div>}
      </div>

      <HistoricalReturnsChart
        id={HISTORICAL_RETURNS_CHART_ID}
        data={mergedReturns}
        hasRecommended={hasRecommended}
        hasBenchmark={hasBenchmark}
        proposalType={proposalType}
        onChartReady={onChartReady}
        options={chartOptions}
      />
    </EditBoxText>
  );
};

ProposalPerformance.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalCharts: PropTypes.object.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalPerformance;
