import { CLICK_HOUSEHOLD, HOUSEHOLD_APP_MODEL } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router';

const HouseholdNameCell = ({ household }) => {
  const { actionProvider } = useContext(AdvisorContext);

  const onHouseholdClick = householdId => () =>
    actionProvider.slack({
      verb: CLICK_HOUSEHOLD,
      target_app_model: HOUSEHOLD_APP_MODEL,
      target_id: householdId
    });

  return (
    <Link onClick={onHouseholdClick(household.id)} to={`/advisor/households/${household.id}`}>
      {household.name}
    </Link>
  );
};

HouseholdNameCell.propTypes = {
  household: PropTypes.object.isRequired
};

export default HouseholdNameCell;
