import { MODEL_PROPOSAL_TYPE, SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import MatchingModelsChart from 'components/charts/matching-models-chart';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes } from '../types';

const ProposalMatchingModels = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposalType,
  setMetadata,
  proposal
}) => {
  if (proposalType === MODEL_PROPOSAL_TYPE || proposalType === SECURITY_PROPOSAL_TYPE) return null;
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  return (
    <EditBoxText {...proposalMetadata}>
      <div className="matching-models-chart-container box-container">
        <MatchingModelsChart proposal={proposal} />
      </div>
    </EditBoxText>
  );
};

ProposalMatchingModels.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.object.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalMatchingModels;
