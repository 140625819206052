import { digestContent } from 'components/advisor/templates/meta/editor-options';
import { IPS_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';
import Dinero from 'dinero.js';
import _ from 'lodash';
import { getTargetInvestors } from 'utils/utils';

const prepareMetadata = (ips, user) => {
  const metadata = _.cloneDeep(
    ips.proposal.template_content || ips.proposal.template.content || IPS_DEFAULT_TEMPLATE
  );

  const data = {
    investors: getTargetInvestors(ips.proposal),
    advisor: { user },
    company: {
      name: user.advisor.company_name || user.advisor.company.name
    },
    target: {
      name: ips.proposal.target.display_name,
      value: Dinero({
        amount: parseInt(parseFloat(ips.proposal.target.value) * 100, 10)
      }).toFormat('$0,0'),
      tolerance: ips.proposal.target?.target_score_summary?.overall
    },
    answer: true
  };

  // replace metadata tags with real content
  _.forOwn(metadata, (properties, key) => {
    _.forOwn(properties, (value, property) => {
      if (typeof value === 'string') metadata[key][property] = digestContent(value, data);
    });
  });

  return metadata;
};

export default prepareMetadata;
