/* eslint-disable import/prefer-default-export */

const BASE_TITLE = 'StratiFi';
const SEPARATOR = '|';

export const setPageTitleFromPath = path => {
  // regular expressions to identify patterns in the path
  const patterns = [
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/account\/\d+\/overview\/?$/,
      title: type => `${type} - Account - Overview`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/account\/\d+\/prism\/?$/,
      title: type => `${type} - Account - Portfolio Risk`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/account\/\d+\/risk-tolerance\/?$/,
      title: type => `${type} - Account - Risk Tolerance`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/overview\/?$/,
      title: type => `${type} - Overview`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/prism\/?$/,
      title: type => `${type} - Portfolio Risk`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/risk-tolerance\/?$/,
      title: type => `${type} - Risk Tolerance`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/fact-finder-forms\/?$/,
      title: type => `${type} - Fact Finder Forms`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/ips(?:\/.*)?\/?$/,
      title: type => `${type} - IPS`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/proposals(?:\/.*)?\/?$/,
      title: type => `${type} - Proposal`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/investment-objectives\/?$/,
      title: type => `${type} - Investment Objectives`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/compliance-notes\/?$/,
      title: type => `${type} - Drift Exception & Notes`
    },
    {
      pattern: /^\/advisor\/(investors|prospects)(\/\d+)?\/target-rating\/?$/,
      title: type => `${type} - RTQ`
    },
    { pattern: /^\/advisor\/(investors|prospects)\/?$/, title: type => `${type}s` },
    { pattern: /^\/advisor\/households\/\d+\/?$/, title: 'Household' },
    { pattern: /^\/advisor\/households\/?$/, title: 'Households' },
    {
      pattern: /^\/advisor\/models\/\d+\/risk-analysis\/?$/,
      title: 'Model Portfolio - Portfolio Risk'
    },
    {
      pattern: /^\/advisor\/models\/\d+\/performance-analysis\/?$/,
      title: 'Model Portfolio - Compare & Analyze'
    },
    { pattern: /^\/advisor\/models\/\d+\/strategy\/?$/, title: 'Model Portfolio - Strategy' },
    { pattern: /^\/advisor\/models\/?$/, title: 'Model Portfolios' },
    { pattern: /^\/advisor\/analytics\/?$/, title: 'Analytics' },
    { pattern: /^\/advisor\/surveillance\/?$/, title: 'Surveillance' },
    { pattern: /^\/advisor\/drift-exceptions\/?$/, title: 'Drift Exceptions' },
    { pattern: /^\/advisor\/integrations(?:\/.*)?\/?$/, title: 'Integrations' },
    { pattern: /^\/advisor\/me\/company\/?$/, title: 'Business Profile' },
    { pattern: /^\/advisor\/me\/profile\/?$/, title: 'My Profile' },
    { pattern: /^\/advisor\/me\/profile\/config\/?$/, title: 'My Profile' },
    { pattern: /^\/advisor\/me\/profile\/crs-metadata\/?$/, title: 'Form CRS' },
    { pattern: /^\/advisor\/accounts\/?$/, title: 'Accounts' },
    { pattern: /^\/advisor\/securities\/?$/, title: 'Security Master' },
    { pattern: /^\/advisor\/securities\/create\/?$/, title: 'Create Security' },
    { pattern: /^\/advisor\/securities\/\d+\/overview\/?$/, title: 'Security - Overview' },
    { pattern: /^\/advisor\/securities\/\d+\/returns\/?$/, title: 'Security - Returns' },
    { pattern: /^\/advisor\/securities\/\d+\/returns-risk\/?$/, title: 'Security - Returns Risk' },
    {
      pattern: /^\/advisor\/securities\/\d+\/underlying-holding\/?$/,
      title: 'Security - Underlying Holding'
    },
    {
      pattern: /^\/advisor\/securities\/\d+\/access-control\/?$/,
      title: 'Security - Access Control'
    },
    {
      pattern: /^\/advisor\/securities\/\d+\/performance-analysis\/?$/,
      title: 'Security - Compare & Analyze'
    },
    { pattern: /^\/advisor\/templates\/crs\/?$/, title: 'Form CRS' },
    { pattern: /^\/advisor\/templates\/ips\/?$/, title: 'IPS Templates' },
    { pattern: /^\/advisor\/templates\/proposal\/?$/, title: 'Proposal Templates' },
    {
      pattern: /^\/advisor\/templates\/fact-finder-forms(?:\/.*)?\/?$/,
      title: 'Fact Finder Forms Templates'
    },
    {
      pattern: /^\/advisor\/templates\/questionnaire-email\/?$/,
      title: 'RTQ Email Template'
    },
    {
      pattern: /^\/advisor\/templates\/ips-review-email\/?$/,
      title: 'IPS Review Email Template'
    },
    {
      pattern: /^\/advisor\/templates\/report-disclosure\/?$/,
      title: 'Report Disclosure Template'
    },
    { pattern: /^\/advisor\/templates\/email-disclosure\/?$/, title: 'Email Disclosure Template' },
    { pattern: /^\/advisor\/templates\/assets-classification\/?$/, title: 'Assets Classification' },
    { pattern: /^\/advisor\/me\/teams\/?$/, title: 'Teams' },
    { pattern: /^\/advisor\/me\/access-endorsements\/?$/, title: 'Access Endorsements' },
    { pattern: /^\/advisor\/me\/users\/?$/, title: 'Users' },
    { pattern: /^\/advisor\/me\/billing\/?$/, title: 'Billing' },
    { pattern: /^\/advisor\/me\/security\/two-factor\/?$/, title: '2FA Setup' },
    { pattern: /^\/advisor\/me\/security\/change-password\/?$/, title: 'Change Password' }
  ];

  // look for a pattern that matches the path
  const matchedPattern = patterns.find(item => item.pattern.test(path));

  // set the corresponding document.title if a pattern is found
  // otherwise, set the default title
  if (matchedPattern) {
    const type = path.includes('/prospects') ? 'Prospect' : 'Client';
    const title =
      typeof matchedPattern.title === 'function'
        ? matchedPattern.title(type)
        : matchedPattern.title;
    document.title = `${BASE_TITLE} ${SEPARATOR} ${title}`;
  } else document.title = BASE_TITLE;
};
