import PropTypes from 'prop-types';
import React from 'react';
import ValueRequirementQuestionContent from '..';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useValueRequirement from '../hooks';

const ValueRequirementQuestion = ({ question, field, ...props }) => {
  const valueRequirementProps = useValueRequirement({ field, question });

  return (
    <Question
      {...props}
      question={question}
      nextDisabled={field.type.invalid || field.amount.invalid}
    >
      <ValueRequirementQuestionContent {...valueRequirementProps} />
    </Question>
  );
};

ValueRequirementQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ValueRequirementQuestion;
