import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

const IntegrationAccountNameCell = ({ integration }) => {
  const url = integration.account_investor
    ? `/advisor/${integration.account_investor_is_prospect ? 'prospects' : 'investors'}/${
        integration.account_investor
      }/account/${integration.target_id}/overview`
    : null;

  if (integration.name || integration.number) {
    const integrationNumber = integration.number || '(No number)';
    const integrationName = integration.name || '(No account name)';

    if (url)
      return (
        <Link to={url}>
          {integrationNumber}
          <br />({integrationName})
        </Link>
      );

    return (
      <>
        {integrationNumber}
        <br />({integrationName})
      </>
    );
  }

  return '(No account data)';
};

IntegrationAccountNameCell.propTypes = {
  integration: PropTypes.object.isRequired
};

export default IntegrationAccountNameCell;
