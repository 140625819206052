import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  title: {
    lineHeight: '32px'
  },
  getTemplateLink: {
    fontSize: 13
  },
  // To override modal creation styling on PRISM page and potential further style conflicts
  button: {
    padding: '.375rem 1rem',
    fontSize: '1rem'
  },
  copyButton: {
    verticalAlign: 'top',
    marginLeft: '1em'
  }
};

const ModelsHeader = ({
  copyButtonProps,
  id,
  modelId,
  modelProvider,
  onCSVInputChange,
  prospectModal,
  title,
  isViewOnly
}) => (
  <div className="row mb-2">
    <div className="col-md-6">
      <h3 style={styles.title}>{title}</h3>
    </div>
    <div className="col-md-6 text-sm-right">
      {copyButtonProps && !prospectModal && (
        <button
          type="button"
          onClick={copyButtonProps.onClick}
          disabled={copyButtonProps.disabled}
          className="btn btn-black"
          style={{ ...styles.button, ...styles.copyButton }}
        >
          Create a copy
        </button>
      )}
      {!isViewOnly && (
        <>
          <input
            id={id}
            type="file"
            style={{ display: 'none' }}
            onChange={onCSVInputChange}
            className="hidden"
          />
          <label htmlFor={id} className="btn btn-primary ml-1" style={styles.button}>
            Upload CSV
          </label>
        </>
      )}
      <br />
      {(['Edit Account', 'Edit Model Portfolio'].includes(title) || isViewOnly) && modelId ? (
        <span
          className="cta"
          role="link"
          key="downloadcsvfile"
          tabIndex={0}
          onClick={() => modelProvider.downloadCSV(modelId)}
          onKeyUp={() => {}}
          style={styles.getTemplateLink}
        >
          Download CSV File
        </span>
      ) : (
        <a
          key="getcsvtemplate"
          href={
            title === 'Create Model Portfolio'
              ? '/csv/model_portfolio.csv'
              : '/csv/account_portfolio.csv'
          }
          target="blank"
          rel="noopener noreferrer"
          style={styles.getTemplateLink}
        >
          CSV Template
        </a>
      )}
    </div>
  </div>
);

ModelsHeader.propTypes = {
  copyButtonProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  }),
  id: PropTypes.string.isRequired,
  modelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modelProvider: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onCSVInputChange: PropTypes.func,
  prospectModal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isViewOnly: PropTypes.bool
};

ModelsHeader.defaultProps = {
  copyButtonProps: null,
  modelId: null,
  onCSVInputChange: null,
  prospectModal: false,
  isViewOnly: false
};

export default ModelsHeader;
