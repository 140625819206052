import { PROMO_CODE_CLEAR, PROMO_CODE_GET } from 'constants/actions';

export const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROMO_CODE_GET:
      return { ...action.data };
    case PROMO_CODE_CLEAR:
      return initialState;
    default:
      return state;
  }
}
