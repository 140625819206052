import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import TargetScoreLine from 'components/advisor/risk-analysis/target-score-line';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const PrismRating = ({ manualScore, options, prismSummary, style, targetSummary }) => {
  const concentratedScore = prismSummary.concentrated;
  const volatilityScore = prismSummary.volatility;
  const correlationScore = prismSummary.correlation;
  const tailScore = prismSummary.tail;
  const prismOverall = prismSummary.overall ? prismSummary.overall.toFixed(1) : null;
  const targetOverall = targetSummary?.overall && targetSummary.overall.toFixed(1);

  return (
    <div className="prism-rating-scores-container">
      <div className="prism-rating-scores result-scores">
        {prismOverall && (
          <RiskOverall
            options={options}
            style={style}
            score={prismOverall}
            target={targetOverall}
          />
        )}
        <div className="score-lines">
          {manualScore && (
            <div className="hide-scores">
              <span className="fs-icon-lock lock-icon" />
              <span className="hide-scores-text">
                Send the risk tolerance questionnaire to the client to see the 4 risk tolerance
                factors.
              </span>
            </div>
          )}
          {!!tailScore && <TargetScoreLine name="Tail Event Risk Rating" value={tailScore} />}
          {!!correlationScore && (
            <TargetScoreLine name="Diversification Risk Rating" value={correlationScore} />
          )}
          {!!concentratedScore && (
            <TargetScoreLine name="Concentrated Stock Risk Rating" value={concentratedScore} />
          )}
          {!!volatilityScore && (
            <TargetScoreLine name="Volatility Risk Rating" value={volatilityScore} />
          )}
        </div>
      </div>
    </div>
  );
};

PrismRating.propTypes = {
  manualScore: PropTypes.bool,
  options: PropTypes.object,
  prismSummary: PropTypes.object.isRequired,
  style: PropTypes.object,
  targetSummary: PropTypes.object
};

PrismRating.defaultProps = {
  manualScore: false,
  options: {},
  style: {},
  targetSummary: {}
};

export default PrismRating;
