import DistributionSummary from 'components/advisor/proposal/distribution-summary';
import {
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID,
  TARGET_POSITIONS_ANALYSIS_CHART_ID
} from 'components/advisor/proposal/distribution-summary/constants';
import EditBox from 'components/advisor/utils/edit-box';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { combinePortfolios } from 'utils/utils';
import { IPSPropTypes } from '../types';

const IPSDistributionSummary = ({ ipsCharts, ips, ...rest }) => {
  const { ipsProvider } = useContext(AdvisorContext);

  const {
    proposal,
    recommended_snapshots: recommendedSnapshots,
    target_snapshots: targetSnapshots
  } = ips;

  const distributionProps = {
    target: combinePortfolios(targetSnapshots),
    targetName: proposal.target_label
  };

  const hasRecommended = !!proposal.recommended;
  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = combinePortfolios(recommendedSnapshots);

    // Assign weighted positions instead of the plain ones if possible
    if (proposal?.recommended?.positions)
      distributionProps.recommended.positions = proposal?.recommended?.positions;
  }

  const onChartReady = useCallback(
    (id, data) => {
      ipsProvider.saveChartImage({ [id]: data });
    },
    [
      ipsCharts[TARGET_POSITIONS_ANALYSIS_CHART_ID],
      ipsCharts[RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID]
    ]
  );

  const setCollapsedHoldings = data => {
    ipsProvider.setCollapsedHoldings(data);
  };

  return (
    <EditBox {...rest}>
      <DistributionSummary
        onChartReady={onChartReady}
        setCollapsedHoldings={setCollapsedHoldings}
        {...distributionProps}
      />
    </EditBox>
  );
};

IPSDistributionSummary.propTypes = {
  ipsCharts: PropTypes.object.isRequired,
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

export default IPSDistributionSummary;
