import HeaderAuth from 'containers/layout/header-dark';
import MultiStepView from 'hocs/multistep-view';
import ResetPasswordConfirm from 'pages/password-reset/confirm';
import ResetPasswordDone from 'pages/password-reset/done';
import ResetPasswordRequest from 'pages/password-reset/request';
import HeroFormContainer from 'pages/utils/hero-form-container';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const PasswordReset = ({ location }) => {
  const { query } = location;
  const uid = query.uid || null;
  const token = query.token || null;

  const config = [
    {
      title: 'Send reset password email',
      component: ResetPasswordRequest
    },
    {
      title: 'Set new password',
      component: ResetPasswordConfirm,
      stepProps: { initialValues: { uid, token } }
    },
    {
      title: 'Password reset done',
      component: ResetPasswordDone
    }
  ];

  const initialStep = uid && token ? 1 : 0;

  return (
    <div id="PasswordReset">
      <HeaderAuth />
      <div className="password-reset-wrapper">
        <HeroFormContainer>
          <MultiStepView config={config} initialStep={initialStep} />
        </HeroFormContainer>
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  location: PropTypes.object.isRequired
};

export default PasswordReset;
