import AdvisorProfile from 'app/containers/advisor/advisor-profile';
import cn from 'classnames';
import Disclosure from 'components/disclosure';
import Link from 'components/utils/link';
import CRS from 'containers/advisor/crs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

const DEFAULT_PROFILE_TAB_TITLE = 'My Profile';

const PROFILE_TAB = 'config';
const CRS_TAB = 'crs-metadata';

const Profile = ({ params: { id, target } }) => {
  const [tabs, setTabs] = useState({
    [PROFILE_TAB]: DEFAULT_PROFILE_TAB_TITLE,
    [CRS_TAB]: 'Form CRS'
  });

  const currentTab = target || PROFILE_TAB;

  const setProfileTitle = title => {
    if (title) setTabs({ ...tabs, [PROFILE_TAB]: title });
    else setTabs({ ...tabs, [PROFILE_TAB]: DEFAULT_PROFILE_TAB_TITLE });
  };

  return (
    <div id="advisor-profile">
      <ul className="tabs">
        {Object.entries(tabs).map(([key, title]) => (
          <li className={cn({ active: key === currentTab })} key={key}>
            <Link className="btn-transparent" to={`/advisor/me/profile/${key}/`}>
              {title}
            </Link>
          </li>
        ))}
      </ul>

      {currentTab === PROFILE_TAB && (
        <AdvisorProfile advisorId={id} setProfileTitle={setProfileTitle} />
      )}
      {currentTab === CRS_TAB && <CRS />}

      <Disclosure />
    </div>
  );
};

Profile.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    target: PropTypes.string
  }).isRequired
};

export default Profile;
