/* eslint-disable no-await-in-loop, no-restricted-syntax */
import EditBoxText from 'components/advisor/utils/edit-box-text';
import SimpleSpinner from 'components/utils/simple-spinner';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { selectQuestionnaire } from 'utils/questionnaire';
import { getAccountQuestionnaires, getTargetAccountIds, getTargetInvestors } from 'utils/utils';
import { IPSPropTypes } from '../types';
import IPSRiskToleranceHeader from './header';
import IPSRiskToleranceQuestionnaireToggleable from './questionnaire-toggleable';
import './styles.scss';
import { getAccountsFromProposal } from './utils';

const IPSRiskTolerance = ({ ips: { proposal }, questionnaires, ...rest }) => {
  const { accountProvider, questionProvider } = useContext(AdvisorContext);

  const [loading, setLoading] = useState(false);

  const accounts = getAccountsFromProposal(proposal);
  const investors = getTargetInvestors(proposal);
  const targetAccountIds = getTargetAccountIds(proposal);
  const riskToleranceHeader = useMemo(
    () => renderToString(<IPSRiskToleranceHeader proposal={proposal} />),
    [JSON.stringify(proposal)]
  );

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      setLoading(true);

      // clear previous stored accounts and questionnaires
      accountProvider.es.clearAccounts();
      questionProvider.clearQuestionnaires();

      for (const investor of investors)
        await questionProvider
          .listQuestionnaires(investor.id, investor.is_prospect)
          .then(({ data: questionnaires }) => {
            const scoredQuestionnaires = questionnaires.filter(q => q.template.is_scored);
            const [questionnaire] = scoredQuestionnaires;
            const hasAccountLevelQuestionnaire =
              questionnaire && questionnaire?.accounts && questionnaire.accounts.length > 0;

            if (_.isEmpty(scoredQuestionnaires)) return;

            if (hasAccountLevelQuestionnaire) {
              const accountQuestionnaires = getAccountQuestionnaires(
                targetAccountIds.filter(targetAccountId => targetAccountId === investor.id),
                scoredQuestionnaires
              );
              Object.keys(accountQuestionnaires).forEach(accountId => {
                if (accountQuestionnaires[accountId])
                  questionProvider.setQuestionnaire(
                    accountQuestionnaires[accountId],
                    investor.id,
                    accountId
                  );
              });
            } else questionProvider.setQuestionnaire(questionnaire, investor.id);
          });

      setLoading(false);
    };
    fetchQuestionnaires();
  }, [JSON.stringify(targetAccountIds)]);

  if (loading)
    return (
      <EditBoxText preBody={riskToleranceHeader} {...rest}>
        <div className="ips-risk-tolerance-questionnaires--loading">
          <SimpleSpinner size="25" /> Loading Questionnaires ...
        </div>
      </EditBoxText>
    );

  return (
    <EditBoxText preBody={riskToleranceHeader} {...rest}>
      <div className="ips-risk-tolerance-questionnaires">
        {investors.map(investor => {
          // investor-level RTQs
          const questions = selectQuestionnaire(questionnaires, investor.id);
          if (!_.isEmpty(questions))
            return (
              <IPSRiskToleranceQuestionnaireToggleable
                investor={investor}
                key={investor.id}
                questions={questions}
              />
            );
          // account-level RTQs
          return accounts
            .filter(account => account.investor.id === investor.id)
            .map(account => {
              const questions = selectQuestionnaire(questionnaires, investor.id, account.id);
              if (_.isEmpty(questions)) return null;
              return (
                <IPSRiskToleranceQuestionnaireToggleable
                  account={account}
                  investor={investor}
                  key={`${investor.id}-${account.id}`}
                  questions={questions}
                />
              );
            });
        })}
      </div>
    </EditBoxText>
  );
};

IPSRiskTolerance.propTypes = {
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  questionnaires: PropTypes.object.isRequired
};

export default connect(state => ({
  questionnaires: state.questions.questionnaires
}))(IPSRiskTolerance);
