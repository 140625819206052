// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proposal-header .search-icon {
  margin: 8px 10px;
  display: block;
  height: 16px;
}
.proposal-header .model-select {
  width: 300px;
}
.proposal-header .account-select {
  width: 95%;
}
.proposal-header .btn-box {
  font-size: 0.89rem;
  height: 27px;
  line-height: 25px;
  margin-right: 5px;
  width: 31px;
}
.proposal-header .btn-box + .btn-box-text {
  font-size: 0.89rem;
}
.proposal-header .modal-disclosure {
  height: 600px;
  height: 90vh;
}
.proposal-header .modal-disclosure .modal-content {
  max-height: 100%;
  overflow-y: auto;
}
.proposal-header .modal-disclosure p {
  white-space: pre-wrap;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/proposal/header/common/styles.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;EACA,cAAA;EACA,YAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,UAAA;AAJJ;AAOE;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;AALJ;AAOI;EACE,kBAAA;AALN;AASE;EACE,aAAA;EACA,YAAA;AAPJ;AASI;EACE,gBAAA;EACA,gBAAA;AAPN;AAUI;EACE,qBAAA;EACA,mBAAA;AARN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.proposal-header {\n  .search-icon {\n    margin: 8px 10px;\n    display: block;\n    height: 16px;\n  }\n\n  .model-select {\n    width: 300px;\n  }\n\n  .account-select {\n    width: 95%;\n  }\n\n  .btn-box {\n    font-size: 0.89rem;\n    height: 27px;\n    line-height: 25px;\n    margin-right: 5px;\n    width: 31px;\n\n    & + .btn-box-text {\n      font-size: 0.89rem;\n    }\n  }\n\n  .modal-disclosure {\n    height: 600px;\n    height: 90vh;\n\n    .modal-content {\n      max-height: 100%;\n      overflow-y: auto;\n    }\n\n    p {\n      white-space: pre-wrap;\n      text-align: justify;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
