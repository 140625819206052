import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Legend = ({ benchmarkName, recommendedName, targetName, withBenchmark, withRecommended }) => (
  <View style={styles.legend}>
    <View style={[styles.legendBox, styles.targetLegendBox]} />
    <Text>{targetName}</Text>
    {withRecommended && (
      <>
        <View style={[styles.legendBox, styles.recommendedLegendBox]} />
        <Text>{recommendedName}</Text>
      </>
    )}
    {withBenchmark && (
      <>
        <View style={[styles.legendBox, styles.benchmarkLegendBox]} />
        <Text>{benchmarkName}</Text>
      </>
    )}
  </View>
);

Legend.propTypes = {
  benchmarkName: PropTypes.string,
  recommendedName: PropTypes.string.isRequired,
  targetName: PropTypes.string.isRequired,
  withBenchmark: PropTypes.bool,
  withRecommended: PropTypes.bool
};

Legend.defaultProps = {
  benchmarkName: '',
  withBenchmark: false,
  withRecommended: false
};

export default Legend;
