import Dropzone from 'components/dropzone';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import ProgressBar from 'components/progress-bar';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AdvisorProvider from 'providers/advisor';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './styles.scss';

const UPLOADING_STEP = 1;
const PROCESSING_STEP = 2;

const MAX_PROGRESS_VALUE = 100;
const FILE_SIZE_RATIO = 0.0005;

const InvestorsCsvUpdate = ({ downloading, getList, investorsMeta }) => {
  const { advisorProvider } = useContext(AdvisorContext);

  const interval = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [droppedFileName, setDroppedFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(UPLOADING_STEP);

  const estimatedTimeToComplete = (file?.size || 0) * FILE_SIZE_RATIO;

  useEffect(() => {
    if (fileUploaded) {
      setCurrentTime(0);
      setProgress(MAX_PROGRESS_VALUE);
      if (interval.current) clearInterval(interval.current);
    } else {
      const currentProgress = (currentTime / estimatedTimeToComplete) * MAX_PROGRESS_VALUE;
      if (currentProgress < MAX_PROGRESS_VALUE) setProgress(currentProgress);
    }
  }, [currentTime, fileUploaded]);

  const show = () => {
    setIsShown(true);
  };

  const hide = () => {
    setFile(null);
    setProgress(0);
    setDroppedFileName(null);
    setFileUploaded(false);
    setStep(UPLOADING_STEP);
    setIsShown(false);
    if (interval.current) clearInterval(interval.current);
  };

  const startUploadProgressBar = () => {
    setCurrentTime(0);
    if (!estimatedTimeToComplete) return;
    interval.current = setInterval(() => {
      setCurrentTime(prevCurrentTime => prevCurrentTime + 0.5);
    }, 1000);
  };

  const onDownloadCsv = () => {
    const params = {};
    const { params: investorParams } = investorsMeta;

    if (_.has(investorParams, 'by_advisor')) params.by_advisor = investorParams.by_advisor;
    advisorProvider.downloadInvestorCSV(params);
  };

  const onDrop = files => {
    if (files.length) {
      setFile(files[0]);
      setDroppedFileName(files[0].name);
      setFileUploaded(false);
    } else toast.error('Invalid CSV file. Please check and try again.');
  };

  const onSubmit = () => {
    if (file == null) return;

    const formData = new FormData();
    formData.append('file', file);

    setStep(PROCESSING_STEP);
    startUploadProgressBar();

    advisorProvider.uploadInvestorCSV(formData).then(() => {
      setFileUploaded(true);
      getList(investorsMeta.params);
    });
  };

  return (
    <>
      <div className="additional-links">
        <button type="button" aria-label="open-modal" onClick={show} className="btn btn-secondary">
          CSV Update
        </button>
      </div>

      <Modal id="investor-csv-update" className="modal-lg" show={isShown} onHidden={hide}>
        <ModalHeader />
        <ModalBody>
          <h3>Edit Client Information</h3>

          {step === UPLOADING_STEP && (
            <>
              <ol>
                <li>
                  <span>Download current information</span>
                  <LoadingButton
                    type="button"
                    className="btn btn-primary"
                    loading={downloading}
                    onClick={onDownloadCsv}
                  >
                    Download CSV
                  </LoadingButton>
                </li>
                <li>
                  Edit the CSV file
                  <span style={{ display: 'block', color: 'red' }}>
                    (Caution: Do not edit the ID column)
                  </span>
                </li>
                <li>
                  <span>Save &amp; upload the file here</span>
                  <div className="dropzone">
                    <Dropzone onDrop={onDrop} accept="text/csv">
                      {droppedFileName || 'Drag and drop or click here to upload file'}
                    </Dropzone>
                  </div>
                </li>
              </ol>
              <div className="btn-upload-csv-wrapper">
                <button type="button" className="btn btn-primary btn-upload-csv" onClick={onSubmit}>
                  Submit
                </button>
              </div>
            </>
          )}

          {step === PROCESSING_STEP && (
            <div className="loading-step">
              {fileUploaded ? (
                <p>Thank you! You have successfully updated your clients&apos; information</p>
              ) : (
                <>
                  <p>Updating clients info... </p>
                  <p>This might take a few seconds.</p>
                </>
              )}
              <div>
                <ProgressBar completed={progress} />
              </div>
              <div className="btn-upload-csv-wrapper">
                {fileUploaded && (
                  <button
                    type="button"
                    className="btn btn-primary btn-confirm"
                    data-dismiss="modal"
                  >
                    Confirm
                  </button>
                )}
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

InvestorsCsvUpdate.propTypes = {
  downloading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  investorsMeta: PropTypes.object.isRequired
};

export default connect(
  state => ({ downloading: state.advisors.downloadingData }),
  dispatch => ({
    advisorProvider: new AdvisorProvider({ dispatch })
  })
)(InvestorsCsvUpdate);
