import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import ReportViewerModal from 'reports/viewer/modal';
import MetaTemplateReport from '..';

const MetaTemplateReportViewer = ({ content, title }) => {
  const { user, userProvider } = useContext(AdvisorContext);

  const [orientation, setOrientation] = useState(PORTRAIT_ORIENTATION);

  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const changeOrientation = value => {
    setOrientation(value);
  };

  const report = (
    <MetaTemplateReport
      content={content}
      orientation={orientation}
      title={title}
      user={user}
      withCustomBranding={withCustomBranding}
    />
  );

  return (
    <ReportViewerModal
      buttonClassName="btn btn-primary font-weight-normal"
      buttonText="Generate PDF"
      changeOrientation={changeOrientation}
      fullyLoadedReportAssets
      orientation={orientation}
      report={report}
    />
  );
};

MetaTemplateReportViewer.propTypes = {
  content: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default MetaTemplateReportViewer;
