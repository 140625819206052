import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const CashInvestableAssetsEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion questions={questions} question={question}>
    <View style={styles.multiOptions}>
      <Text>Cash and investable assets:</Text>
      <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
    </View>
    <View style={styles.multiOptions}>
      <Text>Estimated invested:</Text>
      <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
    </View>
  </ReportEmptyQuestion>
);

CashInvestableAssetsEmptyQuestion.propTypes = propTypes;

export default CashInvestableAssetsEmptyQuestion;
