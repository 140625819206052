import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ServerErrorNotification = props => {
  const { message, clickHandler } = props;

  return (
    <div className="error">
      <span className="pull-xs-left error-message">{message}</span>
      <span className="pull-xs-right close-icon error-skip" onClick={clickHandler}>
        <i className="icon-exit" />
      </span>
    </div>
  );
};

ServerErrorNotification.propTypes = {
  message: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default ServerErrorNotification;
