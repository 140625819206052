import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { ageCalculator } from 'utils/questionnaire';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const DEFAULT_RETIRED_OPTION = 'Retired';
const DEFAULT_YEARS_IN_WHICH_TO_RETIRE = 65;
const DEFAULT_OFFSET_YEARS_IN_WHICH_TO_RETIRE = 5;

const useBirthdayRetirement = ({ field, question }) => {
  // initial/default values
  const initialAge = useMemo(() => {
    const birthday =
      field.birthday.value || field.birthday?.initialValue || question?.answer?.birthday;
    return birthday ? ageCalculator(birthday) : '';
  }, []);

  // current values
  const age = useMemo(
    () => (field.birthday.value ? ageCalculator(field.birthday.value) : ''),
    [field.birthday.value]
  );

  // when the age changes, update the birthday and the retirement age
  const onChangeAge = ({ floatValue: value }) => {
    const birthday = moment().subtract(value, 'years').dayOfYear(1).format('YYYY-MM-DD');
    if (birthday !== field.birthday.value) field.birthday.onChange(birthday);
  };

  // when the retirement in years, update the retirement age
  const onChangeRetireIn = ({ floatValue: value }) => {
    const retirement = ageCalculator(field.birthday.value, value);
    if (retirement !== field.retirement.value) field.retirement.onChange(retirement);
  };

  // when age or employment value changes, update the retirement value
  useEffect(() => {
    if (field.employment.value.includes(DEFAULT_RETIRED_OPTION))
      onChangeRetireIn({ floatValue: 0 });
    else if (age) {
      const retireIn = DEFAULT_YEARS_IN_WHICH_TO_RETIRE - age;
      onChangeRetireIn({
        floatValue: retireIn <= 0 ? DEFAULT_OFFSET_YEARS_IN_WHICH_TO_RETIRE : retireIn
      });
    }
  }, [age, JSON.stringify(field.employment.value)]);

  return { initialAge, onChangeAge, onChangeRetireIn };
};

useBirthdayRetirement.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default useBirthdayRetirement;
