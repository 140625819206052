import PropTypes from 'prop-types';
import React from 'react';

const MinusIcon = ({ style, title, ...rest }) => (
  <svg
    data-name="minus-icon"
    id="minus-icon"
    style={style}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <defs>
      <style>{'.minus-cls-1{fill:#afafaf;}.minus-cls-2{fill:#fff;}'}</style>
    </defs>
    <title>{title}</title>
    <circle className="minus-cls-1" cx="10" cy="10" r="10" />
    <rect className="minus-cls-2" x="4.63" y="8.97" width="11.06" height="2" />
  </svg>
);

MinusIcon.defaultProps = {
  style: {},
  title: 'Minus'
};

MinusIcon.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string
};

export default MinusIcon;
