// Default StratiFi branding colors
export const DEFAULT_PRIMARY_COLOR = '#4ea9f2';
export const DEFAULT_SECONDARY_COLOR = '#000000';

/**
 * `@react-pdf/renderer` does a fetch internally on its <Image /> component,
 * so this functionality would take care of dealing with CORS issues if you want
 * to fetch images from Amazon S3, for example.
 *
 * Additionally, for the same case of Amazon S3, the bucket must have the
 * following CORS configuration to work properly:
 *
 * ```
 * [
 *   {
 *     AllowedHeaders: ['*'],
 *     AllowedMethods: ['GET'],
 *     AllowedOrigins: ['*'],
 *     ExposeHeaders: []
 *   }
 * ];
 * ```
 * @param {String} uri
 * @returns Object with params needed for `<Image />` component.
 */
export const getImageForReport = uri => ({
  uri,
  method: 'GET',
  headers: { 'Access-Control-Allow-Origin': '*', 'Cache-Control': 'no-cache' },
  body: ''
});

/**
 * Allows to determine when to apply a break to an element depending on the defined threshold.
 * @param {Number} itemsNumber
 * @param {Number} maxItemsNumberForFirstPage
 * @param {Number} maxItemsNumberPerPage
 * @param {Number} threshold
 * @returns True or false for applying a break to the component.
 */
export const getThresholdBreak = (
  itemsNumber,
  maxItemsNumberForFirstPage,
  maxItemsNumberPerPage,
  threshold
) => {
  // the number of items is less than or equal to what the first page allows, so no break is needed
  const itemsDiff = itemsNumber - maxItemsNumberForFirstPage;
  if (itemsDiff <= 0) return false;

  // if we subtract from itemsDiff the number of items that fit on each page and it matches
  // the range (0, threshold], a break is applied
  const pageMultiplier = Math.floor(itemsDiff / maxItemsNumberPerPage);
  const remainingItems = itemsDiff - pageMultiplier * maxItemsNumberPerPage;
  if (remainingItems > 0 && remainingItems <= threshold) return true;

  // if none of the preconditions were satisfied, a break is not needed at all
  return false;
};
