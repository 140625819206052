/* eslint-disable camelcase, react/no-unstable-nested-components */
import RiskScoreBubble from 'components/advisor/utils/score-bubble/risk';
import DynamicTable from 'containers/table/dynamic';
import DynamicTableActionButton from 'containers/table/dynamic/action-button';
import PrismFactorFilter from 'containers/table/dynamic/filters/common/prism-factor';
import {
  getPrismFactorName,
  getPrismFactorSortingId,
  OVERALL_FACTOR_ATTR
} from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import ModelOtherFilter from 'containers/table/dynamic/filters/model/other';
import ModelTypeFilter from 'containers/table/dynamic/filters/model/type';
import ModelVisibilityFilter from 'containers/table/dynamic/filters/model/visibility';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { MODEL_COPY_SUFFIX, PRIVATE_VISIBILITY, PUBLIC_VISIBILITY } from '../constants';
import './styles.scss';
import { copiedModelsToast, loadingActionToast } from './utils';

class ModelList extends PureComponent {
  componentDidMount() {
    const { prismProvider } = this.context;
    prismProvider.getModelPortfolioPrismIntents();
  }

  componentWillUnmount() {
    const { modelProvider } = this.context;
    modelProvider.updateSelectedId([]);
  }

  getList = params => {
    const { modelProvider } = this.context;
    return modelProvider.list(params);
  };

  deleteSelectedModels = async items => {
    const { modelProvider, companyProvider } = this.context;
    const { meta } = this.props;

    const promisesArray = [];
    items.forEach(id => {
      promisesArray.push(modelProvider.delete(id));
    });

    return Promise.all(promisesArray).then(() => {
      toast.success('Model Portfolio(s) removed successfully');
      modelProvider.updateSelectedId([]);
      companyProvider.getLimits();
      this.getList(meta.params);
    });
  };

  createCopyOfSelectedModels = async () => {
    const { companyProvider, modelProvider } = this.context;
    const { calculateRisk, meta, selectedModels } = this.props;

    const modelsToBeCopied = selectedModels.map(model => ({
      ...model,
      name: `${model.name} ${MODEL_COPY_SUFFIX}`
    }));

    const promisesArray = [];
    modelsToBeCopied.forEach(model => {
      const { name, type, positions } = model;
      promisesArray.push(modelProvider.create({ name, type, positions }));
    });

    const modelCopyNotification = loadingActionToast('Creating new models');
    const response = await Promise.allSettled(promisesArray);
    const fulfilledModelPromises = response.filter(p => p.status === 'fulfilled');
    const rejectedModelPromises = response.filter(p => p.status === 'rejected');

    toast.update(modelCopyNotification, { autoClose: 1 });
    if (fulfilledModelPromises.length) {
      copiedModelsToast(fulfilledModelPromises.length);
      fulfilledModelPromises.forEach(({ value }) => {
        const { data: model } = value;
        calculateRisk(model.id, model.name);
      });
    }
    if (rejectedModelPromises.length) copiedModelsToast(rejectedModelPromises.length, true);

    modelProvider.updateSelectedId([]);
    companyProvider.getLimits();
    return this.getList(meta.params);
  };

  isCalculatingPrism = modelId => {
    const { prismScoresInProgress } = this.props;
    return prismScoresInProgress.includes(modelId);
  };

  combinePortfolios = () => {
    const { combineModels } = this.props;
    combineModels();
  };

  bulkUpdatePortfolios = () => {
    const { bulkUpdate } = this.props;
    bulkUpdate();
  };

  onSelectItems = ids => {
    const { modelProvider } = this.context;
    return modelProvider.updateSelectedId(ids);
  };

  render() {
    const { authProvider } = this.context;
    const { calculateRisk, data, initialParams, meta, scoredPrismIntents, selectedModelIds, user } =
      this.props;

    const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

    const allowCombine = selectedModelIds.length > 1;
    const allowCopy = selectedModelIds.length >= 1;
    const forbiddenDelete = data
      .filter(m => selectedModelIds.includes(String(m.id)))
      .some(m => m.advisor?.id !== user.advisor?.id && !authProvider.hasManagerPermissions(user));

    const prismFactorAttr = meta?.params?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
    const prismFactorName = getPrismFactorName(prismFactorAttr);
    const prismFactorSortingId = getPrismFactorSortingId(prismFactorAttr);

    const columns = [
      {
        accessorFn: row => row.name,
        id: 'name',
        name: 'Model',
        cell: model => (
          <>
            <Link to={`/advisor/models/${model.id}/overview`}>{model.name}</Link>
            {model.is_new && <span className="new-tag">New</span>}
          </>
        ),
        meta: {
          style: () => ({ maxWidth: 330 })
        }
      },
      {
        accessorFn: row => row.total_positions,
        id: 'total_positions',
        name: 'Securities',
        meta: { className: () => 'text-center', style: () => ({ width: 110 }) }
      },
      {
        accessorFn: row => row.type,
        id: 'type',
        name: 'Model Type',
        meta: { className: () => 'text-center', style: () => ({ width: 110 }) }
      },
      {
        accessorFn: row => {
          const isSubscribed = row?.subscription_status === 'accepted';

          if (isSubscribed) return row.provider.name;
          if (row.advisor) return `${row.advisor.user.first_name} ${row.advisor.user.last_name}`;
          return 'StratiFi';
        },
        id: 'advisor__user__first_name',
        name: 'Created By',
        meta: { className: () => 'text-center', style: () => ({ width: 140 }) }
      },
      {
        accessorFn: row => row.updated_at,
        id: 'updated_at',
        name: 'Last Update',
        cell: ({ updated_at }) => (
          <FormattedDate month="short" day="numeric" year="numeric" value={updated_at} />
        ),
        meta: { className: () => 'text-center', style: () => ({ width: 120 }) }
      },
      {
        accessorFn: row => row.prism_score_summary?.[prismFactorAttr] ?? null,
        id: prismFactorSortingId,
        name: prismFactorName ? `PRISM (${prismFactorName})` : 'PRISM',
        cell: model => {
          if (!model.prism_score_summary && scoredPrismIntents[model.id])
            model.prism_score_summary = { overall: scoredPrismIntents[model.id] };
          return (
            <RiskScoreBubble
              element={model}
              inProgress={this.isCalculatingPrism(model.id)}
              onGenerate={calculateRisk}
              scoreName={prismFactorName}
              target="model-portfolio"
            />
          );
        },
        meta: { className: () => 'text-center td-prism', style: () => ({ width: 120 }) }
      },
      {
        accessorFn: row => {
          if (row.visibility === PUBLIC_VISIBILITY) return 'Company';
          if (row.visibility === PRIVATE_VISIBILITY && row.team) return row.team.name;
          return 'Private';
        },
        id: 'visibility',
        name: 'Shared With',
        meta: { className: () => 'text-center', style: () => ({ width: 120 }) }
      }
    ];

    const headerSelectionActions = (
      <div className="combine-copy-bulk-btns">
        {allowCombine && (
          <DynamicTableActionButton
            iconId="layer-group-solid"
            label="Combine selected"
            onClick={this.combinePortfolios}
          />
        )}
        {allowCopy && (
          <DynamicTableActionButton
            iconId="clone-solid"
            label="Copy selected"
            onClick={this.createCopyOfSelectedModels}
          />
        )}
      </div>
    );

    const headerFixedActions = loading => (
      <button
        className="btn btn-secondary bulk-update-btn"
        disabled={loading}
        onClick={this.bulkUpdatePortfolios}
        type="button"
      >
        Bulk Update
      </button>
    );

    return (
      <div className="model-portfolios-data-table">
        <DynamicTable
          columns={columns}
          data={data}
          enableRowSelection
          filterComponents={[
            [PrismFactorFilter],
            [ModelTypeFilter, ModelVisibilityFilter, ModelOtherFilter]
          ]}
          forbiddenDelete={forbiddenDelete}
          headerFixedActions={headerFixedActions}
          headerSelectionActions={headerSelectionActions}
          initialParams={initialParams}
          label="Model Portfolio"
          meta={meta}
          onDeleteSelectedItems={this.deleteSelectedModels}
          onFetchData={this.getList}
          onSelectItems={this.onSelectItems}
          selectedItemIds={selectedModelIds}
        />
      </div>
    );
  }
}

ModelList.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  companyProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  prismProvider: PropTypes.object.isRequired
};

ModelList.propTypes = {
  bulkUpdate: PropTypes.func.isRequired,
  calculateRisk: PropTypes.func.isRequired,
  combineModels: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  initialParams: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  scoredPrismIntents: PropTypes.object.isRequired,
  selectedModelIds: PropTypes.array.isRequired,
  selectedModels: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default ModelList;
