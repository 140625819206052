import PropTypes from 'prop-types';
import cs from 'classnames';
import React from 'react';
import Select from 'react-select';
import './styles.scss';

const CustomSelect = ({ className, useSearchIcon, ...props }) => (
  <Select
    {...props}
    className={cs({ 'stratifi-select': useSearchIcon }, { className })}
    classNamePrefix="stratifi-sel"
    styles={{
      menu: styles => ({
        ...styles,
        zIndex: 5
      }),
      menuList: styles => ({
        ...styles,
        '::-webkit-scrollbar': {
          width: '10px',
          height: '10px'
        },
        '::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#bcbfc4',
          borderRadius: '20px',
          border: '3px solid white'
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#818a91'
        }
      })
    }}
  />
);

CustomSelect.propTypes = { className: PropTypes.string, useSearchIcon: PropTypes.bool };
CustomSelect.defaultProps = { className: '', useSearchIcon: true };

export default CustomSelect;
