// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#EmailVerification > div {
  text-align: center;
}
#EmailVerification span[role=img] {
  font-size: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/auth/verification/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAGE;EACE,eAAA;AADJ","sourcesContent":["#EmailVerification {\n  & > div {\n    text-align: center;\n  }\n\n  span[role='img'] {\n    font-size: 4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
