/* eslint-disable no-underscore-dangle */
import {
  INTEGRATION_CLIENT_ASSIGNMENT,
  INTEGRATION_DELETE,
  INTEGRATION_DISCONNECT,
  INTEGRATION_GET_CONNECTED,
  INTEGRATION_LIST_PROVIDERS,
  INTEGRATION_UPDATE_CONNECTED
} from 'constants/actions/integrations';
import { sleep } from 'utils/utils';
import config from '../config';
import DataProvider from './data';
import IntegrationElasticProvider from './integrations.es';

class IntegrationProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new IntegrationElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  assignClientSyncedAccount(integrationId, investorId) {
    const promise = this.provider
      .post(`${config.apiBase}integration-links/${integrationId}/assign/`, {
        investor: investorId
      })
      .then(async response => {
        if (response.data && response.data.account) {
          await sleep(1); // Give ES time to index the changes
          this.dispatch(INTEGRATION_CLIENT_ASSIGNMENT, { data: response.data });
          return response.data;
        }
        throw Error('Something went wrong while assigning the client');
      });

    return promise;
  }

  deleteSyncedAccount(integrationId) {
    const promise = this.provider
      .delete(`${config.apiBase}integration-links/${integrationId}/`)
      .then(response => {
        if (!response.error) this.dispatch(INTEGRATION_DELETE);
        else throw Error('Something went wrong while deleting the integration');
      });

    return promise;
  }

  getConnected() {
    const promise = this.provider.get(`${config.apiBase}integrations/`).then(response => {
      if (response.data) this.dispatch(INTEGRATION_GET_CONNECTED, { data: response.data });
      return response;
    });

    return promise;
  }

  listProviders(params) {
    const promise = this.provider
      .get(`${config.apiBase}integrations/providers/`, { ...params })
      .then(response => {
        if (!response.error)
          this.dispatch(INTEGRATION_LIST_PROVIDERS, {
            results: response.data || []
          });
        else
          this.dispatch(INTEGRATION_LIST_PROVIDERS, {
            results: []
          });

        return response;
      });

    return promise;
  }

  updateConnectedIntegration(id, params) {
    const promise = this.provider
      .patch(`${config.apiBase}integrations/${id}/`, params)
      .then(response => {
        if (response.data) this.dispatch(INTEGRATION_UPDATE_CONNECTED);
        return response;
      });

    return promise;
  }

  disconnectIntegration(id) {
    return this.provider.get(`${config.apiBase}integrations/${id}/disconnect/`).then(response => {
      this.dispatch(INTEGRATION_DISCONNECT);
      return response;
    });
  }

  /* Data Providers */
  setAddeparIntegrationInfo(data) {
    return this.provider
      .post(`${config.apiBase}integrations/addepar/`, data)
      .then(({ data }) => data)
      .catch(error => error);
  }

  setBlackDiamondIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/blackdiamond/`, data);
  }

  getByAllAccountsIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/byallaccounts/`, data);
  }

  getByAllAccountsInvestorIntegrationInfo() {
    return this.provider.post(`${config.apiBase}integrations/byallaccounts/i/`);
  }

  getPlaidIntegrationToken() {
    return this.provider.get(`${config.apiBase}integrations/plaid/i/`);
  }

  setPlaidIntegrationPublicToken(data) {
    return this.provider.post(`${config.apiBase}integrations/plaid/i/`, data);
  }

  syncByAllAccountsInvestorIntegration() {
    return this.provider.post(`${config.apiBase}integrations/byallaccounts/i/sync/`);
  }

  setOrionIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/orion/`, data);
  }

  setRaymondJamesIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/raymond_james/`, data);
  }

  setRedtailIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/redtail/`, data);
  }

  setSalesforceIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/salesforce/`, data);
  }

  setAltruistIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/altruist/`, data);
  }

  setSchwabIntegrationInfo(data) {
    return this.provider
      .post(`${config.apiBase}integrations/schwab/`, data)
      .then(response => response)
      .catch(error => error);
  }

  setSchwabOauthIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/schwab-oauth/`, data);
  }

  setWealthboxIntegrationInfo(data) {
    return this.provider.post(`${config.apiBase}integrations/wealthbox/`, data);
  }
}

export default IntegrationProvider;
