import AddressForm from 'components/advisor/address-form';
import { US_CODE } from 'components/form/country-drop-down';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import './styles.scss';

const AddressQuestion = ({ field, question, ...props }) => {
  useEffect(() => {
    if (!field.country.touched && !field.country.value) field.country.autofill(US_CODE);
  }, [field.country.touched, field.country.value]);

  return (
    <Question
      {...props}
      nextDisabled={
        field.address.invalid ||
        field.city.invalid ||
        field.country.invalid ||
        field.postcode.invalid ||
        field.state.invalid
      }
      question={question}
    >
      <AddressForm
        fields={{
          address1: field.address,
          city: field.city,
          country: field.country,
          postcode: field.postcode,
          state: field.state
        }}
      />
    </Question>
  );
};

AddressQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default AddressQuestion;
