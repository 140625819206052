import cn from 'classnames';
import Widget from 'components/advisor/dashboard/widgets/';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import { CustomerSupportEmailLink } from 'constants/contact';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import './styles.scss';

const StrategiesListWidget = () => {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const { advisorByManager, modelProvider, user } = useContext(AdvisorContext);

  useEffect(() => {
    /* fetchData */
    async function fetchData() {
      setLoading(true);
      const advisorId = advisorByManager ?? user.advisor.id;
      const params = { strategies_only__gt: 0, is_benchmark: false };
      if (advisorId !== 0) params.by_advisor = advisorId;
      const response = await modelProvider.es.list(params);
      setModels(response);
      setLoading(false);
    }
    fetchData();
  }, [user, advisorByManager]);

  const empty = _.isEmpty(models);
  const emptyMessage = (
    <>
      There is no associated account for the models as strategies. Please contact{' '}
      <CustomerSupportEmailLink /> to connect accounts to the model.
    </>
  );

  return (
    <Widget
      id="StrategiesListWidget"
      empty={empty}
      loading={loading}
      restricted={false}
      title="Drift Exceptions of Portfolio Risk from Strategy"
      emptyMessage={emptyMessage}
    >
      {!empty && (
        <div className="table-container">
          <div className="strategies-table">
            <div className="strategy-row header-row">
              <div className="strategy-name" />
              <div className="total-accounts">Associated accounts</div>
              <div className="strategy-drift">Drift</div>
            </div>
            {models
              .sort(
                (m1, m2) =>
                  (m2.accounts_prism_drift_overall || 0) - (m1.accounts_prism_drift_overall || 0)
              )
              .map(m => (
                <div key={`a${m.id}`} className="strategy-row body-row">
                  <div className="strategy-name">
                    <ScoreBubble score={m.prism_overall} className="risk-bubble" />
                    <Link to={`/advisor/models/${m.id}/strategy/`}>
                      <div className="name-label">{m.name}</div>
                    </Link>
                  </div>

                  <div className=" total-accounts">
                    <FormattedNumber value={m.total_accounts} />
                  </div>

                  <div
                    className={cn('strategy-drift', {
                      red: !m.accounts_prism_drift_is_healthy
                    })}
                  >
                    {_.isNull(m.accounts_prism_drift_overall) ? (
                      '-'
                    ) : (
                      <FormattedNumber value={m.accounts_prism_drift_overall.toFixed(1)} />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Widget>
  );
};

export default StrategiesListWidget;
