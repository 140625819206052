import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import { OTHER_VALUE } from '../constants';

const GoalApproachReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const withOtherValue = answer => choice =>
    choice.value === OTHER_VALUE ? `${choice.value} (${answer})` : choice.value;

  const goalSelection = question.data?.goals
    .filter(choice => question.answer.goal.includes(choice.value))
    .map(withOtherValue(question.answer.other_goal))
    .join(', ');
  const approachSelection = question.data?.approaches
    .filter(choice => question.answer.approach.includes(choice.value))
    .map(withOtherValue(question.answer.other_approach))
    .join(', ');

  return (
    <ReadOnlyQuestion question={question}>
      <p>Goal: {goalSelection}</p>
      <p>Approach: {approachSelection}</p>
    </ReadOnlyQuestion>
  );
};

GoalApproachReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default GoalApproachReadOnlyQuestion;
