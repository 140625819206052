// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.administrative-permissions-tooltip {
  padding: 5px 10px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/components/utils/administrative-permissions-tooltip/styles.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,gBAAA;AADF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.administrative-permissions-tooltip {\n  padding: 5px 10px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
