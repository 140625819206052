// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advisor-filter-disabled {
  pointer-events: none;
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/advisor-filter/styles.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,YAAA;AACF","sourcesContent":[".advisor-filter-disabled {\n  pointer-events: none;\n  opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
