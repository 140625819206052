import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import PropTypes from 'prop-types';
import React from 'react';

const HouseholdRiskToleranceCell = ({ household, onSelectRiskToleranceHandler }) =>
  household.accounts.length ? (
    <AggregatedToleranceScoreBubble element={household} onClick={onSelectRiskToleranceHandler} />
  ) : null;

HouseholdRiskToleranceCell.propTypes = {
  household: PropTypes.object.isRequired,
  onSelectRiskToleranceHandler: PropTypes.func.isRequired
};

export default HouseholdRiskToleranceCell;
