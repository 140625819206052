// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#InvestorHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
#InvestorHeader .title-container h2 {
  margin: 0;
  font-size: 2rem;
}
#InvestorHeader .form-group.search {
  width: 230px;
}
#InvestorHeader .subtitle {
  font-size: 14px;
  margin: 0 9px;
}
#InvestorHeader .right-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  gap: 10px;
}
#InvestorHeader .right-header > .btn {
  font-weight: 400;
}
#InvestorHeader .right-header .form-group.search {
  width: 500px;
  margin: 0;
}
#InvestorHeader .right-header .form-group.search input {
  height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/investor-header/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,SAAA;EACA,eAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,eAAA;EACA,aAAA;AAHJ;AAME;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;EACA,SAAA;AAJJ;AAMI;EACE,gBAAA;AAJN;AAOI;EACE,YAAA;EACA,SAAA;AALN;AAOM;EACE,YAAA;AALR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#InvestorHeader {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n\n  .title-container h2 {\n    margin: 0;\n    font-size: 2rem;\n  }\n\n  .form-group.search {\n    width: 230px;\n  }\n\n  .subtitle {\n    font-size: 14px;\n    margin: 0 9px;\n  }\n\n  .right-header {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    padding: 0;\n    gap: 10px;\n\n    & > .btn {\n      font-weight: 400;\n    }\n\n    .form-group.search {\n      width: 500px;\n      margin: 0;\n\n      input {\n        height: 38px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
