import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import SubscriptionBanner from '..';

const ONE_DAY = 1;

const SubscriptionTrialBanner = ({ trialEnd }) => {
  const trialDaysLeft = moment(trialEnd).diff(moment(), 'days') + ONE_DAY;
  return (
    <SubscriptionBanner>
      You are using a free trial.{' '}
      {!!trialEnd &&
        `${
          trialDaysLeft <= ONE_DAY ? `Less than ${ONE_DAY} day left` : `${trialDaysLeft} days left`
        }.`}
    </SubscriptionBanner>
  );
};

SubscriptionTrialBanner.defaultProps = {
  trialEnd: ''
};

SubscriptionTrialBanner.propTypes = {
  trialEnd: PropTypes.string
};

export default SubscriptionTrialBanner;
