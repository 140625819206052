import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { LANGUAGE_URL_PARAM, SOURCE_URL_PARAM } from 'lang/constants';
import { getLocale } from 'lang/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const MarketingBanner = ({ params: { advisorId, widgetToken } }) => {
  const params = new URLSearchParams(window.location.search);
  const url = new URL(
    `/inapp-view/b/${advisorId}/${widgetToken}/target-rating/`,
    window.location.origin
  );

  url.searchParams.set(LANGUAGE_URL_PARAM, getLocale());
  url.searchParams.set(SOURCE_URL_PARAM, params.get('source') || 'widget');

  const questionnaireTemplateId = params.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM);
  if (questionnaireTemplateId)
    url.searchParams.set(QUESTIONNAIRE_TEMPLATE_URL_PARAM, questionnaireTemplateId);

  return (
    <div className="marketing-banner-wrapper">
      <section id="marketing-banner">
        <div className="flex-container">
          <div className="heading">
            <p>
              <FormattedMessage id="rtq.banner.title" />
            </p>
            <a
              className="btn btn-primary"
              href={url.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <FormattedMessage id="rtq.banner.cta" />
              </span>
              <img src="/img/arrow-right-blue.png" alt="arrow" />
            </a>
          </div>
          <div className="risk-banner">
            <img src="/img/risks-banner.png" alt="riks banner" />
            <div className="copy">
              <img src="/img/logos/prism-rating.png" alt="PRISM" />
              <span>
                <FormattedMessage id="rtq.banner.prism-description" />
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

MarketingBanner.propTypes = {
  params: PropTypes.shape({
    advisorId: PropTypes.string.isRequired,
    widgetToken: PropTypes.string.isRequired
  }).isRequired
};

export default MarketingBanner;
