import { ACCOUNT_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import RiskAnalysisTarget from 'components/advisor/risk-analysis/risk-analysis-target';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const AccountDetailsPrism = ({ account, riskAnalysis }) => {
  if (_.isEmpty(riskAnalysis) || _.isEmpty(account)) return null;
  return (
    <div className="account-details-prism">
      <RiskAnalysisTarget
        riskAnalysis={riskAnalysis}
        portfolio={account}
        type={ACCOUNT_TARGET_TYPE}
      />
    </div>
  );
};

AccountDetailsPrism.propTypes = {
  account: PropTypes.object.isRequired,
  riskAnalysis: PropTypes.object.isRequired
};

export default connect(state => ({
  account: state.accounts.view,
  riskAnalysis: state.models.riskAnalysis
}))(AccountDetailsPrism);
