import Widget, { LAYOUTS } from 'components/advisor/dashboard/widgets/';
import useWidget, {
  DATASETS,
  widgetStorePropTypes,
  widgetStoreSelector
} from 'components/advisor/dashboard/widgets/hooks';
import DriftDistributionChart from 'components/charts/drift-distribution-chart';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const getTotals = data => {
  const totals = {
    ideal: 0,
    missing: 0,
    snoozed: 0,
    warning: 0
  };

  data.forEach(item => {
    if (item.snooze) totals.snoozed += 1;
    else if (!item.target_overall) totals.missing += 1;
    else if (!item.is_healthy) totals.warning += 1;
    else totals.ideal += 1;
  });

  return totals;
};

const getHint = (dataset, data) => {
  if (!data) return null;
  const totals = getTotals(data);
  return (
    <ul className="risk-hint">
      <li>
        Ideal range ({totals.ideal} {dataset})
      </li>
      <li>
        Need Attention ({totals.warning} {dataset})
      </li>
      <li>
        Snoozed ({totals.snoozed} {dataset})
      </li>
      <li>
        Missing risk tolerance ({totals.missing} {dataset})
      </li>
    </ul>
  );
};

const BookLevelExceptionsWidget = ({ store, layout, forceAdvisor }) => {
  const fetch = (provider, params) => provider.es.list({ ...params, get_all: true });
  const { data, dataset, ...widget } = useWidget({
    fetch,
    store,
    companyModeRestricted: true,
    forceAdvisor
  });

  const { authProvider, routerActions } = useContext(AdvisorContext);
  const hint = getHint(dataset, data);
  const onClick = ({ item: { dataContext } }) => {
    if (dataset === DATASETS.ADVISORS) authProvider.setAdvisorByManager(dataContext.itemId);
    else routerActions.push({ pathname: dataContext.url });
  };

  return (
    <Widget
      {...widget}
      hint={hint}
      dataset={dataset}
      layout={layout}
      title="Book Level Exceptions"
      id="BookLevelExceptionsWidget"
    >
      <DriftDistributionChart
        id={`DriftDistributionChart-${Math.random()}`}
        distribution={data}
        handleClick={onClick}
        showLegend={layout === LAYOUTS.BOX}
      />
    </Widget>
  );
};

BookLevelExceptionsWidget.propTypes = {
  forceAdvisor: PropTypes.number,
  store: PropTypes.shape(widgetStorePropTypes).isRequired,
  layout: PropTypes.string
};

BookLevelExceptionsWidget.defaultProps = {
  forceAdvisor: null,
  layout: LAYOUTS.box
};

export default connect(state => ({
  store: widgetStoreSelector('esList')(state)
}))(BookLevelExceptionsWidget);
