import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import ArrowDown from 'reports/base/charts/arrows/down';
import ArrowUp from 'reports/base/charts/arrows/up';
import { formatPercentage } from 'utils/utils';
import styles from './styles';

const BAR_MAX_WIDTH = 100;

const Symbol = ({ diff, reverse }) => {
  let customStyles = {};
  if ((reverse && diff < 0) || (!reverse && diff > 0)) customStyles = styles.positive;
  if ((reverse && diff > 0) || (!reverse && diff < 0)) customStyles = styles.negative;

  if (diff > 0) return <ArrowUp width={7} styles={{ ...styles.symbol, ...customStyles }} />;
  if (diff < 0) return <ArrowDown width={7} styles={{ ...styles.symbol, ...customStyles }} />;
  return <Text>-</Text>;
};

Symbol.propTypes = {
  diff: PropTypes.number.isRequired,
  reverse: PropTypes.bool.isRequired
};

const PortfolioComparisonChart = ({
  initial,
  isDraft,
  recommended,
  reverse,
  target,
  withMoneyFormat
}) => {
  // this will change the direction of the arrows in this section,
  // causing the draft (current) model to be considered the recommended model
  // despite not being in the recommended position
  const diff = ((isDraft ? -1 : 1) * (recommended - target)) / initial;
  const maxValue = Math.max(target, recommended);

  const targetWidth = withMoneyFormat
    ? (target / maxValue) * BAR_MAX_WIDTH
    : target * BAR_MAX_WIDTH;
  const recommendedWidth = withMoneyFormat
    ? (recommended / maxValue) * BAR_MAX_WIDTH
    : recommended * BAR_MAX_WIDTH;

  return (
    <View style={styles.chart}>
      <View style={styles.percentage}>
        <Symbol diff={diff} reverse={reverse} />
        <Text style={styles.percentageText}>{formatPercentage(Math.abs(diff))}</Text>
      </View>
      <View style={styles.bars}>
        <View style={[styles.bar, styles.targetBar, { width: `${targetWidth}%` }]} />
        <View style={[styles.bar, styles.recommendedBar, { width: `${recommendedWidth}%` }]} />
      </View>
    </View>
  );
};

PortfolioComparisonChart.propTypes = {
  initial: PropTypes.number,
  isDraft: PropTypes.bool,
  recommended: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
  target: PropTypes.number.isRequired,
  withMoneyFormat: PropTypes.bool
};

PortfolioComparisonChart.defaultProps = {
  initial: 1,
  isDraft: false,
  reverse: false,
  withMoneyFormat: false
};

export default PortfolioComparisonChart;
