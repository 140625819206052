import Signature from 'components/advisor/templates/sections/signature/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import { getTargetInvestors } from 'utils/utils';
import { ProposalPropTypes } from '../../types';

const ProposalSignature = ({ proposal, ...rest }) => {
  const investors = getTargetInvestors(proposal);
  return <Signature {...rest} proposalOrIpsId={proposal.id} investors={investors} />;
};

ProposalSignature.propTypes = {
  proposal: PropTypes.shape(ProposalPropTypes).isRequired
};

export default ProposalSignature;
