import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const AutosizeInput = ({ type, value, autoFocus, maxWidth, minWidth, ...rest }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) inputRef.current.focus();
  }, []);

  return (
    <div className="autosize-input" style={{ maxWidth, minWidth }}>
      <div className="autosize-input__container" style={{ minWidth }}>
        <span className="autosize-input__size">{value}</span>
        <input
          {...rest}
          type={type}
          value={value}
          ref={inputRef}
          className="autosize-input__input"
        />
      </div>
    </div>
  );
};

AutosizeInput.defaultProps = {
  type: 'text',
  autoFocus: false,
  maxWidth: '100%',
  minWidth: 'auto'
};

AutosizeInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string
};

export default AutosizeInput;
