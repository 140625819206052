import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const BlankBoxWithText = ({ text, width }) => (
  <View>
    <Text style={styles.containerBlankBoxText}>{text}</Text>
    <View style={[styles.containerBlankBox, { width, marginVertical: 0 }]} />
  </View>
);

BlankBoxWithText.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const AddressEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion questions={questions} question={question} withDescription>
    <View style={{ marginTop: question?.description ? 0 : 15, marginBottom: 15 }}>
      <View style={[styles.addressRow, { marginBottom: 15 }]}>
        <BlankBoxWithText text="Address" width={310} />
        <BlankBoxWithText text="Country" width={100} />
      </View>
      <View style={styles.addressRow}>
        <BlankBoxWithText text="City" width={150} />
        <BlankBoxWithText text="State" width={150} />
        <BlankBoxWithText text="Zip code" width={100} />
      </View>
    </View>
  </ReportEmptyQuestion>
);

AddressEmptyQuestion.propTypes = propTypes;

export default AddressEmptyQuestion;
