import { DEFAULT_TEAM_OPTION } from 'components/advisor/team-filter/constants';
import { TEAM_CREATE, TEAM_DELETE, TEAM_EDIT, TEAM_LIST, TEAM_SELECT } from 'constants/actions';

const initialState = { list: [DEFAULT_TEAM_OPTION], selected: DEFAULT_TEAM_OPTION };

export default (state = initialState, action) => {
  switch (action.type) {
    case TEAM_LIST:
      return { ...state, list: action.data };

    case TEAM_CREATE:
      return { ...state, list: [...state.list, action.data] };

    case TEAM_EDIT:
      return {
        ...state,
        list: state.list.map(team => (team.id === action.data.id ? action.data : team))
      };

    case TEAM_DELETE:
      return { ...state, list: state.list.filter(team => team.id !== action.data) };

    case TEAM_SELECT:
      return { ...state, selected: action.data };

    default:
      return state;
  }
};
