import cn from 'classnames';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import AccountOrModelPrismReportViewer from 'components/advisor/risk-analysis/report/account-or-model-prism/viewer';
import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RiskAnalysisContent from './risk-analysis-content';
import RiskAnalysisPositions from './risk-analysis-positions';
import { ACCOUNT_TARGET_TYPE, MODEL_TARGET_TYPE } from '../constants';
import './styles.scss';

class RiskAnalysisTarget extends Component {
  componentDidMount() {
    const { modelProvider } = this.context;
    modelProvider.getCommonBenchmarks();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)) return true;
    return false;
  }

  render() {
    const { actions, benchmark, calculating, editing, portfolio, riskAnalysis, type } = this.props;
    if (_.isEmpty(portfolio)) return <SpinnerLoader spinnerLoading />;

    return (
      <div className="risk-score-result-container">
        <div className="prism-rating-page-container">
          <div className="box-container heading">
            {riskAnalysis.custom && (
              <AccountOrModelPrismReportViewer
                benchmarks={benchmark}
                portfolio={portfolio}
                riskAnalysis={riskAnalysis}
                type={type}
              />
            )}
            {actions}
          </div>

          {editing && (
            <div className="calculating-portfolio-risk">
              <LoadingPrismDataMessage message="Preparing risk report..." inset />
            </div>
          )}

          {!editing && (
            <div
              className={cn({
                'hide-from-report': type === ACCOUNT_TARGET_TYPE || MODEL_TARGET_TYPE
              })}
            >
              <RiskAnalysisPositions portfolio={portfolio} type={type} />
            </div>
          )}

          {!editing && calculating && (
            <div className="calculating-portfolio-risk">
              <LoadingPrismDataMessage message="Calculating Portfolio Risk..." inset />
            </div>
          )}

          {!editing && !calculating && (
            <RiskAnalysisContent
              benchmark={benchmark}
              portfolio={portfolio}
              type={type}
              riskAnalysis={riskAnalysis}
            />
          )}
        </div>
      </div>
    );
  }
}

RiskAnalysisTarget.contextTypes = {
  actionProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

RiskAnalysisTarget.defaultProps = {
  benchmark: [],
  actions: null,
  calculating: false,
  editing: false
};

RiskAnalysisTarget.propTypes = {
  actions: PropTypes.node,
  benchmark: PropTypes.array,
  calculating: PropTypes.bool,
  editing: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  riskAnalysis: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default connect(state => ({
  benchmark: state.models.benchmark
}))(RiskAnalysisTarget);
