import cn from 'classnames';
import ExpandArrow from 'components/svg-icons/expand-arrow';
import { AdvisorContext } from 'containers/advisor';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router';
import './styles.scss';

const DashboardButtonSection = () => {
  const { authProvider, printRiskToleranceQuestionnaire, user } = useContext(AdvisorContext);

  const hasAddProspectsPermissions = authProvider.hasAddProspectsPermissions(user);

  const handlePrintRiskToleranceQuestionnaire = useCallback(
    () => printRiskToleranceQuestionnaire(user.advisor.company.default_questionnaire_template_id),
    []
  );

  return (
    <div className="dashboard-button-container">
      <div className="arrow-button">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={handlePrintRiskToleranceQuestionnaire}>
          <div className="arrow-button__label">Download Form</div>
          <div className="arrow-button__icon">
            <ExpandArrow className="icon--expand-arrow" />
          </div>
        </a>
      </div>
      <div className="arrow-button">
        <Link to={{ pathname: 'advisor/models', query: { from: 'dashboard' } }}>
          <div className="arrow-button__label">Upload Model Portfolios</div>
          <div className="arrow-button__icon">
            <ExpandArrow className="icon--expand-arrow" />
          </div>
        </Link>
      </div>
      <div className={cn('arrow-button', { 'no-permissions': !hasAddProspectsPermissions })}>
        <Link to={hasAddProspectsPermissions ? 'advisor/prospects/create' : null}>
          <div className="arrow-button__label">Create a New Prospect</div>
          <div className="arrow-button__icon">
            <ExpandArrow className="icon--expand-arrow" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DashboardButtonSection;
