import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingBar, { hideLoading, showLoading } from 'react-redux-loading-bar';
import { routerActions } from 'react-router-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import config from '../../config';
import './styles.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { showingLoadingBar: false };
  }

  getChildContext() {
    const { routerActions, location } = this.props;
    const { userProvider } = this.context;
    return { config, routerActions, location, userProvider };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showingLoadingBar } = this.state;
    const { showLoading, hideLoading } = this.props;
    if (nextProps.loading.count > 0 && !showingLoadingBar) {
      this.setState({ showingLoadingBar: true });
      showLoading();
    } else if (nextProps.loading.count === 0 && showingLoadingBar) {
      hideLoading();
      this.setState({ showingLoadingBar: false });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div className="app-container">
        <LoadingBar className="global-loading-bar" />
        {children}
        <ToastContainer autoClose={5000} hideProgressBar />
      </div>
    );
  }
}

App.childContextTypes = {
  config: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

App.contextTypes = {
  userProvider: PropTypes.object.isRequired
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  hideLoading: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  showLoading: PropTypes.func.isRequired
};

export default connect(
  state => ({ loading: state.loading }),
  dispatch => ({
    hideLoading: bindActionCreators(hideLoading, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    showLoading: bindActionCreators(showLoading, dispatch)
  })
)(App);
