import { Document, Font } from '@react-pdf/renderer';
import { getFormats, getLocale, getMessages } from 'lang/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

// Google Webfonts Helper
// https://github.com/majodev/google-webfonts-helper/

// Roboto Fonts Family
// https://google-webfonts-helper.herokuapp.com/api/fonts/roboto?subsets=latin
// @react-pdf/renderer only accepts *.ttf format

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1MmgVxIIzc.ttf',
      fontStyle: 'normal',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc9.ttf',
      fontStyle: 'normal',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf',
      fontStyle: 'normal',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc9.ttf',
      fontStyle: 'normal',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc9.ttf',
      fontStyle: 'normal',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtfBBc9.ttf',
      fontStyle: 'normal',
      fontWeight: 900
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOiCnqEu92Fr1Mu51QrEzAdKg.ttf',
      fontStyle: 'italic',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TjASc6CsE.ttf',
      fontStyle: 'italic',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1Mu51xIIzc.ttf',
      fontStyle: 'italic',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51S7ACc6CsE.ttf',
      fontStyle: 'italic',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf',
      fontStyle: 'italic',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TLBCc6CsE.ttf',
      fontStyle: 'italic',
      fontWeight: 900
    }
  ]
});

// Disabling hyphenation
Font.registerHyphenationCallback(word => [word]);

const BaseDocument = ({ children, ...rest }) => {
  const locale = getLocale();
  const formats = getFormats();
  const messages = getMessages(locale);

  return (
    <IntlProvider locale={locale} formats={formats} messages={messages}>
      <Document {...rest}>{children}</Document>
    </IntlProvider>
  );
};

BaseDocument.propTypes = {
  author: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  creator: PropTypes.string,
  keywords: PropTypes.string,
  language: PropTypes.string,
  onRender: PropTypes.func,
  producer: PropTypes.string,
  subject: PropTypes.string,
  title: PropTypes.string
};

BaseDocument.defaultProps = {
  author: 'StratiFi',
  creator: 'StratiFi',
  keywords: 'StratiFi, Risk Analysis',
  language: 'English',
  onRender: () => {},
  producer: 'StratiFi',
  subject: `StratiFi Report (${moment().format('MMMM D, YYYY')})`,
  title: `StratiFi Report (${moment().format('MMMM D, YYYY')})`
};

export default BaseDocument;
