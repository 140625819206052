/* global DISPLAY_DATE_FORMAT */
import { Text, View } from '@react-pdf/renderer';
import { ProposalPropTypes } from 'components/advisor/proposal/body/sections/types';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';
import styles from './styles';

const ExtraDataSection = ({
  isDraft,
  proposal: {
    benchmark_management_fee: benchmarkManagementFee,
    starting_value: value,
    start,
    yearlyWithdrawal,
    recommended_management_fee: recommendedManagementFee,
    status,
    target_management_fee: targetManagementFee
  },
  withManagementFee
}) => {
  if (status === 'ips') return null;

  const currentManagementFee = isDraft ? recommendedManagementFee : targetManagementFee;
  const proposedManagementFee = isDraft ? targetManagementFee : recommendedManagementFee;

  return (
    <View style={styles.body}>
      {!_.isNil(yearlyWithdrawal) && (
        <Text style={styles.text}>Yearly withdrawal: {(yearlyWithdrawal * 100).toFixed(2)}%</Text>
      )}
      {withManagementFee && (
        <>
          <Text style={styles.text}>
            Current management fee {(currentManagementFee * 100).toFixed(2)}%
          </Text>
          <Text style={styles.text}>
            Proposed management fee {(proposedManagementFee * 100).toFixed(2)}%
          </Text>
          {!!benchmarkManagementFee && (
            <Text style={styles.text}>
              Benchmark management fee {(benchmarkManagementFee * 100).toFixed(2)}%
            </Text>
          )}
        </>
      )}
      {value && <Text style={styles.text}>Starting value: {formatMoney(value)}</Text>}
      {start && <Text style={styles.text}>Since {moment(start).format(DISPLAY_DATE_FORMAT)}</Text>}
    </View>
  );
};

ExtraDataSection.propTypes = {
  isDraft: PropTypes.bool,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  withManagementFee: PropTypes.bool
};

ExtraDataSection.defaultProps = {
  isDraft: false,
  withManagementFee: false
};

export default ExtraDataSection;
