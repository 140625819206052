import {
  FETCHING,
  GET_INVESTOR_PRISM_DISTRIBUTION,
  INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION,
  INVESTOR_LIST_ES
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class InvestorElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/investor/`, {
        ...params,
        is_prospect: false
      })
      .then(({ data }) => {
        this.dispatch(INVESTOR_LIST_ES, {
          data: data.results.map(investor => ({
            ...investor,
            url: `/advisor/investors/${investor.id}/`
          }))
        });
        return data;
      });

    return promise;
  }

  getPrismDistribution(params) {
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}search/investor/risk_levels_distribution/`, {
        ...params,
        is_prospect: false
      })
      .then(({ data }) => this.dispatch(GET_INVESTOR_PRISM_DISTRIBUTION, { data }));
  }

  getDriftLevelDistribution(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/investor/drift_levels_distribution/`, {
        ...params,
        is_prospect: false
      })
      .then(({ data }) => {
        this.dispatch(INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION, { data });
        return data;
      });

    return promise;
  }
}

export default InvestorElasticProvider;
