// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  padding: 0;
  border: 0;
  font-weight: 400;
}

#RemoveCardModal .modal-content .modal-body {
  padding: 15px;
}
#RemoveCardModal .modal-content .modal-body h1 {
  margin-top: 0;
  margin-bottom: 1rem;
}
#RemoveCardModal .modal-content .modal-body > p {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
#RemoveCardModal .modal-content .modal-body .actions {
  display: flex;
  justify-content: space-between;
}
#RemoveCardModal .modal-content .modal-body .actions .btn {
  display: block;
  min-width: 170px;
  font-weight: 700;
}
#RemoveCardModal .modal-content .modal-body .actions .btn-processing {
  cursor: wait;
  pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/billing/card-actions/styles.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,SAAA;EACA,gBAAA;AADF;;AAMI;EACE,aAAA;AAHN;AAKM;EACE,aAAA;EACA,mBAAA;AAHR;AAMM;EACE,gBAAA;EACA,qBAAA;AAJR;AAOM;EACE,aAAA;EACA,8BAAA;AALR;AAOQ;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;AALV;AAQQ;EACE,YAAA;EACA,oBAAA;AANV","sourcesContent":["@import 'assets/scss/commons/variables';\n\nbutton {\n  padding: 0;\n  border: 0;\n  font-weight: 400;\n}\n\n#RemoveCardModal {\n  .modal-content {\n    .modal-body {\n      padding: 15px;\n\n      h1 {\n        margin-top: 0;\n        margin-bottom: 1rem;\n      }\n\n      & > p {\n        font-weight: 700;\n        margin-bottom: 1.5rem;\n      }\n\n      .actions {\n        display: flex;\n        justify-content: space-between;\n\n        .btn {\n          display: block;\n          min-width: 170px;\n          font-weight: 700;\n        }\n\n        .btn-processing {\n          cursor: wait;\n          pointer-events: auto;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
