import cn from 'classnames';
import React from 'react';
import './styles.scss';

export const teamColumnHeader = header => (
  <span>
    {header} <img src="/img/sorting.svg" className="sorting" alt="sorting" />
  </span>
);

export const teamColumnExpander = data => {
  const { isExpanded } = data;
  const iconAngle = isExpanded ? 'down' : 'right';

  return <span className={`expander-arrow-icon fs-icon-angle-${iconAngle}`} />;
};

export const teamColumnHiden = () => <span className="column__hidden" />;

export const teamColumnEdit = ({ tdProps }) => {
  const { rest } = tdProps;
  return <div className="icon-pencil-line icon-team-edit" {...rest} />;
};

export const teamColumnActions = (security, team) => (
  <div className="advisors-actions-container">
    <button
      type="button"
      className={cn(
        { 'disabled-action-btn': security.teams.map(a => a.id).includes(team.id) },
        'advisors-btn-transparent'
      )}
      aria-label="add"
      disabled={!security.teams.map(a => a.id).includes(team.id)}
    >
      <i
        className={cn(
          { 'disabled-action-btn': security.teams.map(a => a.id).includes(team.id) },
          'icon-cross-circle',
          'security--icon-cross'
        )}
      />
    </button>
    <button
      type="button"
      className={cn(
        { 'disabled-action-btn': !security.teams.map(a => a.id).includes(team.id) },
        'advisors-btn-transparent'
      )}
      aria-label="remove"
      disabled={security.teams.map(a => a.id).includes(team.id)}
    >
      <i
        className={cn(
          { 'disabled-action-btn': !security.teams.map(a => a.id).includes(team.id) },
          'icon-checkmark-circle',
          'security--icon-checkmark'
        )}
      />
    </button>
  </div>
);

export const teamColumnDelete = ({ tdProps }) => {
  const { rest } = tdProps;
  return <div className="fs-icon-trash icon-team-delete" {...rest} />;
};

export const teamColumnRepCodes = codes => (
  <div className="team-codes">
    {codes &&
      codes.map(el => (
        <div key={el} className="team-codes__bubble">
          {el}
        </div>
      ))}
  </div>
);
