import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { withCurrencyFormat } from 'utils/utils';
import './styles.scss';

const AnnualTaxableIncomeQuestionContent = ({
  contributionInputProps,
  estimatedAnnualIncomeText,
  estimatedYearlyContributionText,
  incomeInputProps,
  isEntityQuestion,
  isRetired,
  yearlyContribution
}) => {
  const { hasError: hasContributionError } = contributionInputProps;
  return (
    <>
      <div>
        <label htmlFor="income">
          <span>{estimatedAnnualIncomeText}</span>
          <NumericFormat {...incomeInputProps} />
          <span>
            <FormattedMessage id="rtq.question-3.per-year" />
          </span>
        </label>
      </div>
      {(!isRetired || isEntityQuestion) && (
        <div>
          <label htmlFor="contribution">
            <span>{estimatedYearlyContributionText}</span>
            <NumericFormat {...contributionInputProps} />
            <span className={cn({ 'has-danger': hasContributionError })}>
              {hasContributionError ? (
                <FormattedMessage id="rtq.question-3.contribution-error" />
              ) : (
                <FormattedMessage
                  id="rtq.question-3.contributing-per-year"
                  values={{
                    value: (
                      <span className="font-weight-bold">
                        {withCurrencyFormat(yearlyContribution, 'standard', 0)}
                      </span>
                    )
                  }}
                />
              )}
            </span>
          </label>
        </div>
      )}
    </>
  );
};

AnnualTaxableIncomeQuestionContent.defaultProps = {
  estimatedAnnualIncomeText: '',
  estimatedYearlyContributionText: ''
};

AnnualTaxableIncomeQuestionContent.propTypes = {
  contributionInputProps: PropTypes.object.isRequired,
  estimatedAnnualIncomeText: PropTypes.string,
  estimatedYearlyContributionText: PropTypes.string,
  incomeInputProps: PropTypes.object.isRequired,
  isEntityQuestion: PropTypes.bool.isRequired,
  isRetired: PropTypes.bool.isRequired,
  yearlyContribution: PropTypes.number.isRequired
};

export default AnnualTaxableIncomeQuestionContent;
