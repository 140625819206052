import cn from 'classnames';
import AccountRiskTolerance from 'components/advisor/accounts/details/view/account-risk';
import { investorAccountsSelector, investorSelector } from 'components/advisor/investors/selectors';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import RiskToleranceQuestionnaireReadOnly from 'containers/risk-tolerance-questionnaire/questionnaire/read-only';
import RiskToleranceQuestionnaireSingleReportViewer from 'containers/risk-tolerance-questionnaire/report/single/viewer';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectQuestionnaire } from 'utils/questionnaire';
import {
  getAccountQuestionnaires,
  getInvestorRtqSentTimestamp,
  getInvestorTargetScore,
  getRiskToleranceLatestUpdate
} from 'utils/utils';
import ToggableAccountQuestionnaire from '../toggable-account-questionnaire';
import './styles.scss';

const InvestorDetailsTolerance = ({ accounts, investor, questionnaires }) => {
  const { questionProvider, showTargetScoreWizard } = useContext(AdvisorContext);

  const [accountQuestionnaires, setAccountQuestionnaires] = useState([]);
  const [hasAccountLevelQuestionnaire, setHasAccountLevelQuestionnaire] = useState(false);
  const [lastQuestionnaire, setLastQuestionnaire] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    questionProvider
      .listQuestionnaires(investor.id, investor.is_prospect)
      .then(({ data: questionnaires }) => {
        const scoredQuestionnaires = questionnaires.filter(q => q.template.is_scored);
        const [questionnaire] = scoredQuestionnaires;
        const hasAccountLevelQuestionnaire =
          questionnaire && questionnaire?.accounts && questionnaire.accounts.length > 0;

        setHasAccountLevelQuestionnaire(hasAccountLevelQuestionnaire);
        setLastQuestionnaire(questionnaire || {});

        if (_.isEmpty(scoredQuestionnaires)) return;

        if (hasAccountLevelQuestionnaire) {
          const accountQuestionnaires = getAccountQuestionnaires(accounts, scoredQuestionnaires);
          setAccountQuestionnaires(accountQuestionnaires);
          Object.keys(accountQuestionnaires).forEach(accountId => {
            if (accountQuestionnaires[accountId])
              questionProvider.setQuestionnaire(
                accountQuestionnaires[accountId],
                investor.id,
                accountId
              );
          });
        } else questionProvider.setQuestionnaire(questionnaire, investor.id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <SpinnerLoader spinnerLoading />;

  const { aggregated_prism_scores: aggrPrism } = investor;

  const aggrTarget = getInvestorTargetScore(investor);
  const hasTargetScore = !_.isEmpty(aggrTarget.data);
  const hasRTQ = !aggrTarget.manual;
  const investorRtqSentTimestamp = getInvestorRtqSentTimestamp(investor);

  const account = {
    investor,
    prism_score_summary: aggrPrism,
    target_questionnaire_mail_sent: true,
    target_score_manual: aggrTarget.manual,
    target_score_summary: aggrTarget.data,
    target_score_updated_at: getRiskToleranceLatestUpdate(investor, accounts)
  };

  const investorQuestions = selectQuestionnaire(questionnaires, investor.id);

  return (
    <div className="investor-details-tolerance">
      {!hasTargetScore && (
        <div
          className={cn('actions', {
            'no-aggregation': !hasTargetScore,
            'with-timestamp': !!investorRtqSentTimestamp
          })}
        >
          <h2>No Risk Tolerance yet</h2>
          {investorRtqSentTimestamp && (
            <span className="risk-tolerance-sent-timestamp">
              (You sent a questionnaire on {moment(investorRtqSentTimestamp).format('LL')})
            </span>
          )}
          <div className="no-tolerance-msg">
            After getting a score, you can generate a proposal and an IPS
          </div>
          <button
            type="button"
            className={cn('btn', {
              'btn-primary': !investorRtqSentTimestamp,
              'btn-outline-primary': !!investorRtqSentTimestamp
            })}
            onClick={() => showTargetScoreWizard()}
          >
            {investorRtqSentTimestamp ? 'Send Reminder' : 'Assess Risk Tolerance'}
          </button>
        </div>
      )}

      {hasTargetScore && (
        <div className="body">
          <AccountRiskTolerance
            account={account}
            lastQuestionnaire={lastQuestionnaire}
            showDownloadCombinedReport={hasAccountLevelQuestionnaire}
          />

          {hasRTQ && !hasAccountLevelQuestionnaire && !_.isEmpty(investorQuestions) && (
            <div className="no-account-questionnaire">
              <div className="heading">
                <span>Form Answers</span>
                <span className="actions">
                  <RiskToleranceQuestionnaireSingleReportViewer
                    account={account}
                    questionnaire={{ ...lastQuestionnaire, questions: investorQuestions }}
                  />
                </span>
              </div>

              <RiskToleranceQuestionnaireReadOnly
                account={account}
                form={`risk-tolerance-questionnaire-read-only__investor-${investor.id}`}
                questions={investorQuestions}
              />
            </div>
          )}

          {hasRTQ &&
            hasAccountLevelQuestionnaire &&
            accounts.map((account, idx) => {
              const questions = selectQuestionnaire(questionnaires, investor.id, account.id);
              if (_.isEmpty(questions)) return null;
              return (
                <ToggableAccountQuestionnaire
                  account={{ ...account, investor }}
                  defaultExpanded={idx === 0}
                  questionnaire={{ ...accountQuestionnaires[account.id], questions }}
                  investorId={investor.id}
                  key={account.id}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

InvestorDetailsTolerance.defaultProps = {
  accounts: [],
  investor: null
};

InvestorDetailsTolerance.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  investor: PropTypes.object,
  questionnaires: PropTypes.object.isRequired
};

export default connect(state => ({
  accounts: investorAccountsSelector(state),
  investor: investorSelector(state),
  questionnaires: state.questions.questionnaires
}))(InvestorDetailsTolerance);
