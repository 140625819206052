export default [
  {
    description: 'Minimum number of users',
    starter: 1,
    professional: 2,
    business: 2,
    enterprise: 'Call for detail'
  },
  {
    description: 'Prospects',
    starter: 'Up to 100',
    professional: 'Unlimited',
    business: 'Unlimited',
    enterprise: 'Unlimited'
  },
  {
    description: 'PRISM: In-depth risk factor analysis',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Investment Proposals',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Model Portfolio Builder',
    starter: 'Up to 5 models',
    professional: 'Unlimited',
    business: 'Unlimited',
    enterprise: 'Unlimited'
  },
  {
    description: 'Compare Portfolios',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Risk Profiling Questionnaire',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Topics To Discuss',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Website & Signature Risk Tolerance Widget',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Embed Calendar Link For Meetings',
    starter: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Held Away Account Sync',
    starter: 'Up to 3 clients',
    professional: 'Up to 25 clients',
    business: 'Unlimited',
    enterprise: 'Unlimited'
  },
  {
    description: 'Accounts per advisor',
    starter: false,
    professional: 'Up to 500 accounts (Contact for more)',
    business: 'Up to 500 accounts (Contact for more)',
    enterprise: 'Up to 500 accounts (Contact for more)'
  },
  {
    description: 'Clients & households',
    starter: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Integrations',
    starter: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Investment Policy Statements (IPS)',
    starter: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Basic Training',
    starter: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'Whitelabel',
    starter: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    description: 'IPS & Proposal Templates',
    starter: false,
    professional: false,
    business: true,
    enterprise: true
  },
  {
    description: 'Client Check-In',
    starter: false,
    professional: false,
    business: true,
    enterprise: true
  },
  {
    description: 'Compliance pack',
    starter: false,
    professional: false,
    business: true,
    enterprise: true
  },
  {
    description: 'Business Analytics',
    starter: false,
    professional: false,
    business: true,
    enterprise: true
  },
  {
    description: 'Team Communication',
    starter: false,
    professional: false,
    business: true,
    enterprise: true
  },
  {
    description: 'Premium training',
    starter: false,
    professional: false,
    business: true,
    enterprise: true
  },
  {
    description: 'RTQ Customization',
    starter: false,
    professional: false,
    business: false,
    enterprise: true
  },
  {
    description: 'Weekly Email Summary',
    starter: false,
    professional: false,
    business: false,
    enterprise: true
  },
  {
    description: 'Data Migration',
    starter: false,
    professional: false,
    business: false,
    enterprise: true
  },
  {
    description: 'Advisor Coaching',
    starter: false,
    professional: false,
    business: false,
    enterprise: true
  },
  {
    description: 'Customizations',
    starter: false,
    professional: false,
    business: false,
    enterprise: true
  }
];
