import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import './style.scss';

class StepsLine extends Component {
  arrayOfSteps() {
    const { titleSteps, totalSteps } = this.props;
    const steps = Array.apply(null, Array(totalSteps)).map((x, i) => {
      const step = {};
      step.title = titleSteps !== undefined ? titleSteps[i] : undefined;
      return step;
    });
    return steps;
  }

  render() {
    const steps = this.arrayOfSteps();
    const { currentStep } = this.props;
    return (
      <div id="StepsLine">
        {steps.map((step, stepIndex) => (
          <div
            key={stepIndex}
            className={cs('step', {
              passed: currentStep > stepIndex + 1,
              current: currentStep === stepIndex + 1
            })}
          >
            {stepIndex + 1 > 1 && <span className="line" />}
            <span className="number">
              {stepIndex + 1}
              {step.title !== undefined && <span className="title">{step.title}</span>}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

StepsLine.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  titleSteps: PropTypes.arrayOf(PropTypes.string),
  currentStep: PropTypes.number.isRequired
};

StepsLine.defaultProps = {
  titleSteps: []
};

export default StepsLine;
