/* eslint-disable jsx-a11y/control-has-associated-label */
import cn from 'classnames';
import AccountTags from 'components/advisor/accounts/account-tags';
import NotesList from 'components/advisor/accounts/notes-list';
import InvestmentObjectivesMatchingModels from 'components/advisor/investors/investment-objectives-matching-models';
import MissingPrismModal from 'components/advisor/missing-prism-modal';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import TopRiskAttribution from 'components/advisor/risk-analysis/risk-analysis-target/top-risk-attribution';
import ToleranceScoreBubble from 'components/advisor/utils/score-bubble/tolerance';
import PrismHistoryChart, { LEVEL_PALLET } from 'components/charts/prism-history-chart';
import ReactDateRangePicker from 'components/form/date-range-picker';
import LoadingButton from 'components/loading-button';
import TrashCan from 'components/svg-icons/trash-can-v2';
import DisplayScore from 'components/utils/DisplayScore';
import { INVESTOR_APP_MODEL, LEFT_COMPLIANCE_NOTE, VIEW_PRISM_REPORT } from 'constants/actstream';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { CSVModelMixin } from 'utils/mixins';
import { triggerAccountPrismScore } from 'utils/prism';
import './styles.scss';

const tabs = {
  COMPLIANCE_NOTES: 'compliance-notes',
  HISTORY: 'target-history-tab',
  MATCHING_MODELS: 'matching-models',
  PORTFOLIO: 'portfolio-tab',
  RISK_ATTRIBUTION: 'risk-attribution'
};

const scoreTypes = {
  concentrated: 'Concentrated',
  correlation: 'Correlation',
  overall: 'Overall',
  tail: 'Tail',
  volatility: 'Volatility'
};

const dateRangeOptions = {
  all: 'ALL',
  ytd: 'YTD',
  oneYear: '1Y',
  twoYears: '2Y',
  custom: 'custom'
};

class InvestorAccountListRow extends Component {
  constructor(props) {
    super(props);

    CSVModelMixin.applyMixin(this);
    this.state = {
      displayedGraph: scoreTypes.overall,
      message: '',
      missingPrismModal: { show: false },
      period: [
        props.historical?.[props.account.id]?.length
          ? props.historical[props.account.id][0].date
          : '2018-01-01',
        moment().format('YYYY-MM-DD')
      ],
      showDetails: false,
      tab: tabs.PORTFOLIO
    };
  }

  componentDidUpdate(prevProps) {
    const { account } = this.props;
    const { account: prevAccount } = prevProps;
    const { accountProvider } = this.context;

    // to avoid an infinite loop asking if the account changed or not,
    // the target_score_url attribute should be removed as it constantly changes
    // due to the session token it has, making the accounts different even
    // though they don't really have any other changes

    const accountCopy = JSON.parse(JSON.stringify(account));
    const prevAccountCopy = JSON.parse(JSON.stringify(prevAccount));
    delete accountCopy.target_score_url;
    delete prevAccountCopy.target_score_url;

    if (!_.isEqual(accountCopy, prevAccountCopy)) accountProvider.get(account.id);
  }

  toggleDetails = () => {
    const { account } = this.props;
    const { showDetails } = this.state;
    const { accountProvider } = this.context;
    // We are opening the details
    if (!showDetails) accountProvider.getPrismTargetHistory(account.id);
    this.setState({ showDetails: !showDetails });
  };

  hideMissingPrismModal = () => {
    this.setState({ missingPrismModal: { show: false } });
  };

  goTo(tab) {
    this.setState({ tab });
  }

  selectScoreType = event => {
    this.setState({ displayedGraph: event.target.value });
  };

  selectDateRange = data => {
    const { account } = this.props;
    const { accountProvider } = this.context;
    const { period } = this.state;
    const { target } = data;
    let { start_date: startDate, end_date: endDate } = data;
    const year = new Date().getFullYear();
    if (target)
      if (target.value === dateRangeOptions.custom) {
        [startDate, endDate] = period;
        this.setState({ showDateRangePicker: true });
      } else {
        this.setState({ showDateRangePicker: false });
        endDate = moment().format('YYYY-MM-DD');
        switch (target.value) {
          case dateRangeOptions.ytd:
            startDate = `${year}-01-01`;
            break;
          case dateRangeOptions.oneYear:
            startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
            break;
          case dateRangeOptions.twoYears:
            startDate = moment().subtract(2, 'years').format('YYYY-MM-DD');
            break;
          default:
            startDate = null;
            endDate = null;
        }
      }
    const params = startDate && endDate ? { start_date: startDate, end_date: endDate } : {};
    accountProvider.getPrismTargetHistory(account.id, params);
  };

  handleChangeNote = event => {
    this.setState({ message: event.target.value });
  };

  handleSaveNote = event => {
    event.preventDefault();

    const { account } = this.props;
    const { actionProvider, accountProvider } = this.context;
    const { message } = this.state;
    const note = { message };

    accountProvider.addNote(account, note).then(() => {
      this.setState({ message: '' });
      actionProvider.slack({ verb: LEFT_COMPLIANCE_NOTE });
    });
  };

  getPrismScore = accountId => {
    const { accountProvider } = this.context;
    triggerAccountPrismScore(accountId, accountProvider);
  };

  handleChangePeriod = period => {
    const { account } = this.props;
    const { accountProvider } = this.context;
    const [startDate, endDate] = period;
    const params = startDate && endDate ? { start_date: startDate, end_date: endDate } : {};
    accountProvider.getPrismTargetHistory(account.id, params);
    this.setState({ period });
  };

  render() {
    const {
      account,
      account: {
        drift_summary: driftSummary,
        is_healthy: isHealthy,
        most_risky_positions: riskyPositions
      },
      goalInvestmentsReadOnly,
      historical,
      investor,
      investorType,
      matchingModels,
      prismScoresInProgress,
      toggleDeleteModal,
      toggleEditAccountModal
    } = this.props;

    const {
      displayedGraph,
      message,
      missingPrismModal,
      period,
      showDateRangePicker,
      showDetails,
      tab
    } = this.state;

    const {
      actionProvider,
      portfolioProvider,
      showTargetScoreWizard,
      user: {
        advisor: {
          company: { hide_positions: hidePositions }
        }
      }
    } = this.context;

    const getLast = (type, accountId) => {
      if (historical[accountId][historical[accountId].length - 1])
        return historical[accountId][historical[accountId].length - 1][`${type}${displayedGraph}`];
      return null;
    };

    const tagOffset = id => {
      // adding ± units to prevent tags collision
      const diff = Math.abs(getLast('score', id) - getLast('target', id));
      const order = getLast('score', id) > getLast('target', id) ? 1 : -1;
      if (diff < 0.3) return 25 * order;
      if (diff < 0.5) return 15 * order;
      if (diff < 0.8) return 10 * order;
      return 0;
    };

    const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';
    const whichPath = investorType === 'client' ? 'investors' : 'prospects';

    return (
      <tbody className="InvestorAccountListRow">
        <tr>
          <td
            className={cn(
              {
                'fit-exclude-col': investorType === 'client'
              },
              'account-name-box account-name-col header-font-size'
            )}
          >
            <Link to={`/advisor/${whichPath}/${investor.id}/account/${account.id}`}>
              {account.display_name}
            </Link>
            <AccountTags account={account} />
          </td>
          <td
            className={cn(
              { 'less-opacity': account.excluded },
              'account-value-col text-sm-center header-font-size'
            )}
          >
            <FormattedNumber value={account.value} format="currency" />
          </td>
          <td
            className={cn(
              { 'less-opacity': account.excluded },
              'account-prism-col text-sm-center header-font-size'
            )}
          >
            {account.prism_score_summary &&
            account.prism_score_summary.overall &&
            !prismScoresInProgress.includes(account.id) ? (
              <Link
                to={`/advisor/investors/${investor.id}/account/${account.id}/prism`}
                onClick={() => {
                  actionProvider.slack({
                    verb: VIEW_PRISM_REPORT,
                    target_app_model: INVESTOR_APP_MODEL,
                    target_id: account.investor.id
                  });
                }}
              >
                <DisplayScore score={account.prism_score_summary.overall} />
              </Link>
            ) : (
              <LoadingButton
                className="btn get-target-score-btn"
                loading={prismScoresInProgress.includes(account.id)}
                onClick={() => this.getPrismScore(account.id)}
              >
                +
              </LoadingButton>
            )}
          </td>
          <td
            className={cn(
              { 'less-opacity': account.excluded },
              'account-target-col text-sm-center header-font-size'
            )}
          >
            <ToleranceScoreBubble
              element={account}
              onGenerate={() => showTargetScoreWizard()}
              isProspect={investor.is_prospect}
            />
          </td>
          <td
            className={cn(
              { 'less-opacity': account.excluded, red: !isHealthy },
              'text-sm-center header-font-size drift'
            )}
          >
            <span>{drift}</span>
          </td>
          <td
            className={cn(
              { 'less-opacity': account.excluded },
              'toggle-col manage-overlay-td header-font-size text-sm-right'
            )}
          >
            <button className="btn-transparent" type="button" onClick={this.toggleDetails}>
              {showDetails ? (
                <img
                  src="/img/icons/uparrow_blue.svg"
                  width="17px"
                  height="17px"
                  alt="uparrow"
                  className="collapse-action"
                />
              ) : (
                <img
                  src="/img/icons/downarrow_blue.svg"
                  width="17px"
                  height="17px"
                  alt="downarrow"
                  className="expand-action"
                />
              )}
            </button>
            {!goalInvestmentsReadOnly && (
              <a href="#Delete" onClick={e => toggleDeleteModal(e, account, 'account')}>
                <TrashCan className="trash-icon" />
              </a>
            )}
          </td>
        </tr>

        {/* Account Details collapsible */}
        <tr className={cn('positions-chart', { showDetails })}>
          <td className="details-container" colSpan={8}>
            <ul className="tabs">
              <li className={cn({ active: tab === tabs.PORTFOLIO })}>
                <button
                  className="btn-transparent"
                  type="button"
                  onClick={this.goTo.bind(this, tabs.PORTFOLIO)}
                >
                  Portfolio
                </button>
              </li>
              <li
                className={cn({
                  'time-series-tab': true,
                  active: tab === tabs.HISTORY,
                  disabled: !historical || !historical[account.id] || !historical[account.id].length
                })}
              >
                <button
                  className="btn-transparent"
                  disabled={
                    !historical || !historical[account.id] || !historical[account.id].length
                  }
                  type="button"
                  onClick={this.goTo.bind(this, tabs.HISTORY)}
                >
                  Time Series
                </button>
              </li>
              {!hidePositions && (
                <li
                  className={cn({
                    active: tab === tabs.RISK_ATTRIBUTION,
                    disabled: _.isEmpty(riskyPositions)
                  })}
                >
                  <button
                    disabled={_.isEmpty(riskyPositions)}
                    className="btn-transparent"
                    type="button"
                    onClick={this.goTo.bind(this, tabs.RISK_ATTRIBUTION)}
                  >
                    Top Risk Attribution
                  </button>
                </li>
              )}
              <li
                className={cn({
                  active: tab === tabs.MATCHING_MODELS
                })}
              >
                <button
                  className="btn-transparent"
                  type="button"
                  onClick={() => {
                    this.goTo(tabs.MATCHING_MODELS);
                  }}
                >
                  Matching Models
                </button>
              </li>
              <li
                className={cn({
                  active: tab === tabs.COMPLIANCE_NOTES
                })}
              >
                <button
                  className="btn-transparent"
                  type="button"
                  onClick={() => {
                    this.goTo(tabs.COMPLIANCE_NOTES);
                  }}
                >
                  Compliance Notes
                </button>
              </li>
            </ul>
            {tab === tabs.PORTFOLIO && (
              <div className="use-portfolio-container">
                <span className="full-name">{account.display_name}</span>
                {account.positions && account.positions.length ? (
                  <PositionsAnalysis
                    hiddenVal={false}
                    portfolio={account}
                    showHeadingRow
                    showTotalLabelPie
                  />
                ) : (
                  <div className="no-positions">This account has no positions in it.</div>
                )}
                <div className="actions">
                  <button
                    className="btn btn-primary edit-button"
                    type="button"
                    onClick={() => toggleEditAccountModal(account)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            )}
            {tab === tabs.HISTORY && (
              <div className="prism-history-chart-container">
                <div className="y-axis">
                  <ul>
                    <li>10</li>
                    <li>5</li>
                    <li>1</li>
                  </ul>
                </div>
                <div className="chart">
                  {historical && historical[account.id] && (
                    <PrismHistoryChart
                      key={2}
                      maxValue={_.max(
                        _.map(historical[account.id], x => x[`score${displayedGraph}`])
                      )}
                      id={`score-distribution-current-${account.id}`}
                      style={{ height: 300 }}
                      data={historical[account.id].length > 1 ? historical[account.id] : []}
                      type={displayedGraph}
                    />
                  )}
                  {historical && historical[account.id] && historical[account.id].length < 2 && (
                    <div className="no-data-warning">
                      Not enough data for selected date range. Please try different date range.
                    </div>
                  )}
                </div>
                {historical && historical[account.id] && historical[account.id].length > 1 ? (
                  <div className="tags">
                    <span
                      className="prism-tag"
                      style={{
                        top: `${255 - getLast('score', account.id) * 25}px`,
                        backgroundColor: LEVEL_PALLET[Math.round(getLast('score', account.id))]
                      }}
                    >
                      <div className="tail" />
                      PRISM: {getLast('score', account.id)}
                    </span>
                    {getLast('target', account.id) && (
                      <span
                        className="target-tag"
                        style={{
                          top: `${
                            255 - getLast('target', account.id) * 25 + tagOffset(account.id)
                          }px`
                        }}
                      >
                        <div className="tail" />
                        Tolerance: {getLast('target', account.id)}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="tags" />
                )}
                <div className="filters">
                  <select onChange={this.selectDateRange} className="input" name="prism">
                    <option value={dateRangeOptions.all}>Since inception</option>
                    <option value={dateRangeOptions.ytd}>YTD</option>
                    <option value={dateRangeOptions.oneYear}>1 Year</option>
                    <option value={dateRangeOptions.twoYears}>2 Year</option>
                    <option value={dateRangeOptions.custom}>Custom Range</option>
                  </select>
                  {historical && historical[account.id] && showDateRangePicker && (
                    <ReactDateRangePicker
                      className="form-control"
                      onChange={this.handleChangePeriod}
                      value={period}
                    />
                  )}
                  <select onChange={this.selectScoreType} className="input" name="prism">
                    <option value={scoreTypes.overall}>Overall PRISM Rating</option>
                    <option value={scoreTypes.tail}>Tail PRISM Rating</option>
                    <option value={scoreTypes.concentrated}>Concentrated PRISM Rating</option>
                    <option value={scoreTypes.volatility}>Volatility PRISM Rating</option>
                    <option value={scoreTypes.correlation}>Correlation PRISM Rating</option>
                  </select>
                </div>
              </div>
            )}
            {tab === tabs.MATCHING_MODELS && (
              <InvestmentObjectivesMatchingModels
                models={matchingModels}
                portfolioProvider={portfolioProvider}
                account={account}
              />
            )}
            {tab === tabs.RISK_ATTRIBUTION && !hidePositions && (
              <TopRiskAttribution
                investor={investor}
                riskyPositions={riskyPositions}
                positions={account.positions}
              />
            )}
            {tab === tabs.COMPLIANCE_NOTES && (
              <div className="compilance-notes">
                <div className="edit-section">
                  <textarea
                    cols="30"
                    rows="3"
                    placeholder="Leave note..."
                    value={message}
                    onChange={this.handleChangeNote}
                  />
                  <div className="actions">
                    <button type="button" className="btn btn-primary" onClick={this.handleSaveNote}>
                      Save
                    </button>
                  </div>
                </div>
                <NotesList target={account} />
              </div>
            )}
          </td>
        </tr>
        {missingPrismModal.show && (
          <MissingPrismModal {...missingPrismModal} onHide={this.hideMissingPrismModal} />
        )}
      </tbody>
    );
  }
}

InvestorAccountListRow.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  portfolioProvider: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

InvestorAccountListRow.propTypes = {
  account: PropTypes.object.isRequired,
  goalInvestmentsReadOnly: PropTypes.bool.isRequired,
  historical: PropTypes.object.isRequired,
  investor: PropTypes.object.isRequired,
  investorType: PropTypes.string.isRequired,
  matchingModels: PropTypes.array.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state)
}))(InvestorAccountListRow);
