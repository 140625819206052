import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withCurrencyFormat } from 'utils/utils';
import { MODEL_TARGET_TYPE } from '../../constants';
import './styles.scss';

const POSITIONS_TO_SHOW = 10;
const INITIAL_POSITIONS = 3;

const TopRiskAttributionBar = ({ percentage }) => (
  <div className="top-risk-attribution-bar">
    <span className="percentage-value">{percentage}%</span>
    <span className="percentage-holder">
      <span className="percentage-gray-bar" style={{ width: `${100 - percentage}%` }} />
    </span>
  </div>
);

TopRiskAttributionBar.propTypes = {
  percentage: PropTypes.string.isRequired
};

const TopRiskAttribution = ({
  investor,
  positions,
  riskyPositions,
  riskyPositionsOnly,
  showAllPositions,
  type
}) => {
  const [positionsToShow, setPositionsToShow] = useState(INITIAL_POSITIONS);

  const loadMore = () => {
    setPositionsToShow(prev => prev + POSITIONS_TO_SHOW);
  };

  const hideRows = () => {
    setPositionsToShow(INITIAL_POSITIONS);
  };

  let processedRiskyPositions =
    positionsToShow >= riskyPositions || showAllPositions
      ? riskyPositions
      : riskyPositions.slice(0, positionsToShow);

  // include PRISM + value from positions under risky positions.
  if (!riskyPositionsOnly)
    processedRiskyPositions = processedRiskyPositions.map(riskyPosition => {
      const position = positions.find(p => p.ticker === riskyPosition.ticker);
      if (!_.isEmpty(position))
        return {
          ...riskyPosition,
          prismScoreSummary: position.prism_score_summary,
          value: position.value
        };
      return riskyPosition;
    });

  const hasAccount =
    !_.isEmpty(processedRiskyPositions) && !_.isEmpty(processedRiskyPositions[0].account);
  const showPortfolioValue = type !== MODEL_TARGET_TYPE;

  return (
    <div className="TopRiskAttribution">
      <div className="table">
        <div className="row row-header">
          <div className="col col-asset">Asset</div>
          <div className="col col-weight">Portfolio %</div>
          {showPortfolioValue && <div className="col col-value col-center">Portfolio Value</div>}
          <div className="col col-prism">PRISM</div>
          <div className="col col-risk-attribution">Risk Attribution</div>
          {hasAccount && <div className="col col-accounts">Account</div>}
        </div>

        <TransitionGroup>
          {processedRiskyPositions.map(position => (
            <CSSTransition
              classNames="row-transition"
              key={`${position.ticker}-${position?.account?.id}`}
              timeout={300}
            >
              <div className="row row-body" key={`${position.ticker}-${position?.account?.id}`}>
                <div className="col col-asset">{position.ticker_name || position.ticker}</div>
                <div className="col col-weight">{position.weight.toFixed(2)}%</div>
                {showPortfolioValue && (
                  <div className="col col-value col-center">
                    {withCurrencyFormat(position.value)}
                  </div>
                )}
                <div className="col col-prism">
                  {!_.isEmpty(position.prismScoreSummary) && (
                    <span
                      className={cn(
                        'risk-badge-with-score',
                        `risk-level-${Math.ceil(position.prismScoreSummary.overall)}`
                      )}
                    >
                      {position.prismScoreSummary.overall.toFixed(1)}
                    </span>
                  )}
                </div>
                <div className="col col-risk-attribution">
                  <TopRiskAttributionBar percentage={(position.risk * 100).toFixed(2)} />
                </div>
                {hasAccount && (
                  <div className="col col-accounts col-center ">
                    {investor ? (
                      <Link
                        to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${
                          investor.id
                        }/account/${position.account.id}`}
                      >
                        {position.account.displayName}
                      </Link>
                    ) : (
                      position.account.displayName
                    )}
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>

      {riskyPositions.length > positionsToShow && !showAllPositions && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span className="cta load-more" onClick={loadMore}>
          Load More (
          {positionsToShow + POSITIONS_TO_SHOW > riskyPositions.length
            ? riskyPositions.length - positionsToShow
            : POSITIONS_TO_SHOW}{' '}
          more securities) <i className="icon-arrow" />
        </span>
      )}

      {positionsToShow > POSITIONS_TO_SHOW && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span className="cta load-more" onClick={hideRows}>
          Show Only top {INITIAL_POSITIONS}
          <i className="icon-arrow up" />
        </span>
      )}
    </div>
  );
};

TopRiskAttribution.propTypes = {
  investor: PropTypes.object,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      ticker: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  riskyPositions: PropTypes.arrayOf(
    PropTypes.shape({
      risk: PropTypes.number,
      ticker: PropTypes.string,
      ticker_name: PropTypes.string,
      weight: PropTypes.number
    })
  ).isRequired,
  riskyPositionsOnly: PropTypes.bool,
  showAllPositions: PropTypes.bool,
  type: PropTypes.string
};

TopRiskAttribution.defaultProps = {
  investor: null,
  positions: null,
  riskyPositionsOnly: false,
  showAllPositions: false,
  type: ''
};

export default TopRiskAttribution;
