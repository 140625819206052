/* global STANDARD_DATE_FORMAT */
import moment from 'moment';

const getDaysAgo = days => moment().subtract(days, 'days').format(STANDARD_DATE_FORMAT);

const TODAY = moment().add(1, 'days').format(STANDARD_DATE_FORMAT);

export const VIEW_ALL = { label: 'View All', value: [undefined, undefined] };

export const RISK_TOLERANCE_UPDATED = [
  { label: 'Last 7 days', value: [getDaysAgo(7), TODAY] },
  { label: 'Last 30 days', value: [getDaysAgo(30), TODAY] }
];

export const RISK_TOLERANCE_NOT_UPDATED = {
  label: 'Risk tolerance not updated for',
  options: [
    {
      label: '1 year',
      description: 'Risk tolerance not updated for 1 year',
      value: [undefined, getDaysAgo(365)]
    },
    {
      label: '2 years',
      description: 'Risk tolerance not updated for 2 years',
      value: [undefined, getDaysAgo(730)]
    },
    {
      label: '3+ years',
      description: 'Risk tolerance not updated for 3 years',
      value: [undefined, getDaysAgo(1095)]
    }
  ]
};

export const rangeOptions = [VIEW_ALL, ...RISK_TOLERANCE_UPDATED, RISK_TOLERANCE_NOT_UPDATED];
