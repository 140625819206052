// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scenario-container .score-description {
  font-weight: normal;
  font-size: 14px;
  background-color: white;
  padding: 20px;
  border: 1px solid #e3e7ee;
  margin-top: 5px;
}
.scenario-container .score-description .down {
  color: #c93539;
}
.scenario-container .score-description .up {
  color: green;
}
.scenario-container .gain-loss {
  margin: 9px 0 0 0;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/risk-analysis/risk-analysis-target-scenario/styles.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,eAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAEI;EACE,YAAA;AAAN;AAIE;EACE,iBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".scenario-container {\n  .score-description {\n    font-weight: normal;\n    font-size: 14px;\n    background-color: white;\n    padding: 20px;\n    border: 1px solid #e3e7ee;\n    margin-top: 5px;\n\n    .down {\n      color: #c93539;\n    }\n    .up {\n      color: green;\n    }\n  }\n\n  .gain-loss {\n    margin: 9px 0 0 0;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
