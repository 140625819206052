import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PasswordCheckIcon from './svg-icons/password-check-icon';

class PasswordHelper extends Component {
  constructor(props) {
    super(props);
    this.prevIsValid = false;
    this.checks = {};
  }

  componentDidMount() {
    this.checkPassword(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.checkPassword(newProps);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  hasCapitalChars = password => /[A-Z]/.test(password);

  hasLowerCaseLetter = password => /[a-z]/.test(password);

  hasNumber = password => /[0-9]/.test(password);

  hasSpecialChars = password => /[!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]/.test(password);

  hasMinLength = password => password.length >= 8;

  checkPassword(props) {
    const { onValid, value } = props;

    const capitalLetter = this.hasCapitalChars(value);
    const lowerCaseLetter = this.hasLowerCaseLetter(value);
    const minLength = this.hasMinLength(value);
    const includesNumber = this.hasNumber(value);
    const specialChar = this.hasSpecialChars(value);

    this.checks = { capitalLetter, lowerCaseLetter, minLength, includesNumber, specialChar };

    const valid = capitalLetter && lowerCaseLetter && minLength && includesNumber && specialChar;

    if (valid !== this.prevIsValid) {
      this.prevIsValid = valid;
      if (onValid) onValid(valid);
    }
  }

  render() {
    const { capitalLetter, lowerCaseLetter, minLength, includesNumber, specialChar } = this.checks;

    return (
      <div className="password-helper-container">
        <div>
          <span>
            <PasswordCheckIcon
              className={minLength ? 'green-checkmark-test' : 'checkmark-test'}
              viewbox="0 0 32 32"
            />
          </span>
          <span className={minLength ? 'green-tooltip-text' : 'tooltip-text'}>
            8 characters or more
          </span>
        </div>
        <div>
          <span>
            <PasswordCheckIcon
              className={capitalLetter ? 'green-checkmark-test' : 'checkmark-test'}
              viewbox="0 0 32 32"
            />
          </span>
          <span className={capitalLetter ? 'green-tooltip-text' : 'tooltip-text'}>
            Capital Letter{' '}
          </span>
          <br />
        </div>
        <div>
          <span>
            <PasswordCheckIcon
              className={lowerCaseLetter ? 'green-checkmark-test' : 'checkmark-test'}
              viewbox="0 0 32 32"
            />
          </span>
          <span className={lowerCaseLetter ? 'green-tooltip-text' : 'tooltip-text'}>
            Lower case letter{' '}
          </span>
          <br />
        </div>
        <div>
          <span>
            <PasswordCheckIcon
              className={includesNumber ? 'green-checkmark-test' : 'checkmark-test'}
              viewbox="0 0 32 32"
            />
          </span>
          <span className={includesNumber ? 'green-tooltip-text' : 'tooltip-text'}>Number </span>
          <br />
        </div>
        <div>
          <span>
            <PasswordCheckIcon
              className={specialChar ? 'green-checkmark-test' : 'checkmark-test'}
              viewbox="0 0 32 32"
            />
          </span>
          <span className={specialChar ? 'green-tooltip-text' : 'tooltip-text'}>
            Special character{' '}
          </span>
          <br />
        </div>
      </div>
    );
  }
}

PasswordHelper.propTypes = {
  value: PropTypes.string.isRequired,
  onValid: PropTypes.func
};

PasswordHelper.defaultProps = {
  onValid: () => {}
};

export default PasswordHelper;
