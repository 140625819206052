import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import PersonalityType from '../personality-type/pdf';
import RiskCapacity from '../risk-capacity/pdf';
import styles from './styles';

const ScoreContext = ({ flavor, investorName, scoreSummary }) => {
  if (!scoreSummary?.overall) return null;

  return (
    <View style={styles.table}>
      <View style={styles.row}>
        <View style={styles.cell}>
          <PersonalityType
            score={scoreSummary.overall}
            investorName={investorName}
            flavor={flavor}
          />
        </View>
        {!!scoreSummary?.capacity && (
          <View style={styles.cell}>
            <RiskCapacity score={scoreSummary.capacity} />
          </View>
        )}
      </View>
    </View>
  );
};

ScoreContext.defaultProps = {
  flavor: 'advisor',
  investorName: ''
};

ScoreContext.propTypes = {
  flavor: PropTypes.string,
  investorName: PropTypes.string,
  scoreSummary: PropTypes.shape({
    overall: PropTypes.number.isRequired,
    capacity: PropTypes.number
  }).isRequired
};

export default ScoreContext;
