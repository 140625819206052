import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW } from 'reports/base/styles';

const styles = StyleSheet.create({
  scoreLineContainer: { ...BASE_TABLE_ROW, alignItems: 'center', marginBottom: 5 },
  scoreLine: { marginRight: 5 },
  scoreName: { fontSize: 8 }
});

export default styles;
