import AddCardModal from 'containers/advisor/billing/add-card-modal';
import CardActions from 'containers/advisor/billing/card-actions';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CardProvider from 'providers/cards';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sortedCardsListSelector } from 'selectors/cards';
import { capFirstChar } from 'utils/utils';
import './styles.scss';

const Card = ({ card, id, is_default: isDefault, onlyOneCardSaved }) => {
  const { brand, exp_month: expMonth, exp_year: expYear, last4 } = card;

  return (
    <div className="billing-cards-container__card">
      <div className="billing-cards-container__card__name">
        {capFirstChar(brand)} Credit Card ending in {last4}
      </div>

      <div className="billing-cards-container__card__expiration">
        Expires {expMonth.toString().padStart(2, '0')}/{expYear}
      </div>

      {isDefault ? (
        <div className="billing-cards-container__card__default">Default Card</div>
      ) : (
        <CardActions card={{ brand, id, last4 }} onlyOneCardSaved={onlyOneCardSaved} />
      )}
    </div>
  );
};

Card.defaultProps = {
  onlyOneCardSaved: false
};

Card.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    exp_month: PropTypes.number.isRequired,
    exp_year: PropTypes.number.isRequired,
    last4: PropTypes.string.isRequired
  }).isRequired,
  id: PropTypes.string.isRequired,
  is_default: PropTypes.bool.isRequired,
  onlyOneCardSaved: PropTypes.bool
};

export const BillingCards = ({ cardProvider, cards }) => {
  useEffect(() => {
    cardProvider.list();
  }, []);

  return (
    <div id="BillingCards">
      <div className="billing-cards-container">
        {_.isEmpty(cards) ? (
          <div className="billing-cards-container__no-cards">You have no registered cards</div>
        ) : (
          cards.map(card => <Card key={card.id} onlyOneCardSaved={cards.length === 1} {...card} />)
        )}
      </div>
      <AddCardModal />
    </div>
  );
};

BillingCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  cardProvider: PropTypes.object.isRequired
};

export default connect(
  state => ({
    cards: sortedCardsListSelector(state)
  }),
  dispatch => ({
    cardProvider: new CardProvider({ dispatch })
  })
)(BillingCards);
