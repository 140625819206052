import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import { MODEL_PROPOSAL_TYPE, SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import BurndownChart from 'components/charts/burndown-chart';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import { BEAR_BURNDOWN_CHART_ID, BULL_BURNDOWN_CHART_ID } from './constants';
import './styles.scss';

const mergeBurndown = ({ target, recommended }) => {
  const accumulate = attrName => (burndown, value, index) => {
    burndown[index] = { ...burndown[index], index, [attrName]: value || 0 };
    return burndown;
  };

  let burndown = {};
  burndown = target.reduce(accumulate('target'), burndown);
  if (recommended) burndown = recommended.reduce(accumulate('recommended'), burndown);
  return Object.values(burndown);
};

const ProposalBurndown = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  proposalCharts,
  proposalType,
  setMetadata
}) => {
  const { proposalProvider } = useContext(AdvisorContext);
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const hasRecommended = !!proposal.recommended;
  const isModelOrSecurityProposalType =
    proposalType === MODEL_PROPOSAL_TYPE || proposalType === SECURITY_PROPOSAL_TYPE;

  const mergedBearToBull = useMemo(
    () =>
      mergeBurndown({
        target: proposal.target_analytics.burndown.bear_bull_returns,
        recommended: hasRecommended
          ? proposal.recommended_analytics.burndown.bear_bull_returns
          : null
      }),
    [JSON.stringify(proposal)]
  );

  const mergedBullToBear = useMemo(
    () =>
      mergeBurndown({
        target: proposal.target_analytics.burndown.bull_bear_returns,
        recommended: hasRecommended
          ? proposal.recommended_analytics.burndown.bull_bear_returns
          : null
      }),
    [JSON.stringify(proposal)]
  );

  const chartOptions = useMemo(
    () => ({
      recommendedName: hasRecommended ? proposal.recommended_label : '',
      targetName: isModelOrSecurityProposalType ? proposal.target_label : ''
    }),
    [JSON.stringify(proposal)]
  );

  const onChartReady = useCallback(
    (id, data) => {
      proposalProvider.saveChartImage({ [id]: data });
    },
    [proposalCharts[BULL_BURNDOWN_CHART_ID], proposalCharts[BEAR_BURNDOWN_CHART_ID]]
  );

  return (
    <EditBoxText {...proposalMetadata}>
      <DateValue
        proposal={{
          starting_value: proposal.starting_value,
          yearlyWithdrawal: proposal.yearly_withdrawal_rate
        }}
      />

      <div className="fancy-grid burndown no-printable">
        <div>Potential best outcome</div>
        <div>Potential worst outcome</div>
        <div>
          <BurndownChart
            id={BULL_BURNDOWN_CHART_ID}
            data={mergedBullToBear}
            hasRecommended={hasRecommended}
            onChartReady={onChartReady}
            options={chartOptions}
          />
        </div>
        <div>
          <BurndownChart
            id={BEAR_BURNDOWN_CHART_ID}
            data={mergedBearToBull}
            hasRecommended={hasRecommended}
            onChartReady={onChartReady}
            options={chartOptions}
          />
        </div>
      </div>

      {/* TODO: remove after migrating all proposal sections to @react-pdf/renderer */}
      <div className="fancy-grid burndown printable">
        <div className="header left">Potential best outcome</div>
        <div className="header right">Potential worst outcome</div>
        <div className="box">
          <BurndownChart
            data={mergedBullToBear}
            hasRecommended={hasRecommended}
            options={chartOptions}
            small
          />
        </div>
        <div className="box">
          <BurndownChart
            data={mergedBearToBull}
            hasRecommended={hasRecommended}
            options={chartOptions}
            small
          />
        </div>
      </div>
    </EditBoxText>
  );
};

ProposalBurndown.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalCharts: PropTypes.object.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalBurndown;
