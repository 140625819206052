import _ from 'lodash';
import React from 'react';
import { DELETE_MODAL, EDIT_MODAL } from '../constants';
import './styles.scss';

const columnHeader = header => (
  <span>
    {header} <img src="/img/sorting.svg" className="sorting" alt="sorting" />
  </span>
);

const columns = onOpenModal => [
  {
    id: 'followee',
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' },
    Header: columnHeader('Advisor'),
    accessor: data => data.name
  },
  {
    id: 'followers',
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' },
    Header: columnHeader('Authorized Advisors'),
    accessor: data =>
      _.isEmpty(data.followers)
        ? '-'
        : data.followers
            .map(follower => `${follower.user.first_name} ${follower.user.last_name}`)
            .join(', ')
  },
  {
    Header: 'Edit',
    id: 'edit',
    width: 70,
    Cell: ({ original: { id, name, followers } }) => (
      <button
        type="button"
        aria-label="Edit access endorsements"
        className="icon-pencil-line icon-access-endorsements-delete"
        onClick={() => {
          onOpenModal(EDIT_MODAL, { followee: { id, name }, followers });
        }}
        title="Edit access endorsements"
      />
    )
  },
  {
    Header: 'Delete',
    id: 'delete',
    width: 70,
    Cell: ({ original: { id, name, followers } }) => (
      <button
        type="button"
        aria-label="Remove access endorsements"
        className="fs-icon-trash icon-access-endorsements-delete"
        onClick={() => {
          onOpenModal(DELETE_MODAL, { followee: { id, name }, followers });
        }}
        title="Remove access endorsements"
      />
    )
  }
];

export default columns;
