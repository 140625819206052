// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.morningstar-style-box-chart {
  display: flex;
  gap: 20px;
}
.morningstar-style-box-chart .style-box-chart {
  display: grid;
  grid-template-columns: 20px repeat(3, 60px);
  grid-template-rows: 20px repeat(3, 60px);
  grid-gap: 5px;
  gap: 5px;
}
.morningstar-style-box-chart .style-box-chart .style-box-chart__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.morningstar-style-box-chart .style-box-chart .style-box-chart__item[data-type=result] {
  font-weight: bold;
}
.morningstar-style-box-chart .style-box-chart .style-box-chart__item[data-type=header], .morningstar-style-box-chart .style-box-chart .style-box-chart__item[data-type=type] {
  font-size: 0.8rem;
}
.morningstar-style-box-chart .style-box-chart .style-box-chart__item[data-type=header] {
  align-items: flex-end;
}
.morningstar-style-box-chart .style-box-chart .style-box-chart__item[data-type=type] > span {
  transform: rotate(-90deg);
}
.morningstar-style-box-chart .style-box-legend {
  display: flex;
  flex-direction: column;
  flex-basis: 90px;
  font-size: 0.9rem;
  gap: 3px;
}
.morningstar-style-box-chart .style-box-legend .style-box-legend__item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 5px;
}
.morningstar-style-box-chart .style-box-legend .style-box-legend__item:first-child {
  padding: 45px 0 3px 0;
  margin-bottom: 3px;
  border-bottom: 1px solid black;
}
.morningstar-style-box-chart .style-box-legend .style-box-legend__item > .item__box {
  display: block;
  width: 15px;
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/charts/morningstar-style-box-chart/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;AADF;AAGE;EACE,aAAA;EACA,2CAAA;EACA,wCAAA;EACA,aAAA;EAAA,QAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADN;AAGM;EACE,iBAAA;AADR;AAIM;EAEE,iBAAA;AAHR;AAMM;EACE,qBAAA;AAJR;AAQQ;EACE,yBAAA;AANV;AAYE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,QAAA;AAVJ;AAYI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,QAAA;AAVN;AAYM;EACE,qBAAA;EACA,kBAAA;EACA,8BAAA;AAVR;AAaM;EACE,cAAA;EACA,WAAA;EACA,YAAA;AAXR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.morningstar-style-box-chart {\n  display: flex;\n  gap: 20px;\n\n  .style-box-chart {\n    display: grid;\n    grid-template-columns: 20px repeat(3, 60px);\n    grid-template-rows: 20px repeat(3, 60px);\n    gap: 5px;\n\n    .style-box-chart__item {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n\n      &[data-type='result'] {\n        font-weight: bold;\n      }\n\n      &[data-type='header'],\n      &[data-type='type'] {\n        font-size: 0.8rem;\n      }\n\n      &[data-type='header'] {\n        align-items: flex-end;\n      }\n\n      &[data-type='type'] {\n        & > span {\n          transform: rotate(-90deg);\n        }\n      }\n    }\n  }\n\n  .style-box-legend {\n    display: flex;\n    flex-direction: column;\n    flex-basis: 90px;\n    font-size: 0.9rem;\n    gap: 3px;\n\n    .style-box-legend__item {\n      display: flex;\n      align-items: center;\n      margin-bottom: 0;\n      gap: 5px;\n\n      &:first-child {\n        padding: 45px 0 3px 0;\n        margin-bottom: 3px;\n        border-bottom: 1px solid black;\n      }\n\n      & > .item__box {\n        display: block;\n        width: 15px;\n        height: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
