/* eslint-disable default-param-last */
import { GET_ACCOUNT_PRISM_INTENTS, GET_MODEL_PORTFOLIO_PRISM_INTENTS } from 'constants/actions';

export const initialState = {
  accountPrismIntents: [],
  modelPortfolioPrismIntents: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_PRISM_INTENTS:
      return { ...state, accountPrismIntents: action.data };

    case GET_MODEL_PORTFOLIO_PRISM_INTENTS:
      return { ...state, modelPortfolioPrismIntents: action.data };

    default:
      return state;
  }
};
