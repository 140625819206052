import ErrorMessage from 'components/error-message';
import { propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React from 'react';
import MaxInvalidLoginAttemptsMessage from '../max-invalid-login-attempts-message';
import Separator from '../separator';
import '../styles.scss';
import ResendMagicLink from './resend-magic-link';

const REASON_DELIMITER = ': ';
const MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED = 'MaxInvalidLoginAttemptsExceeded';
const MAGIC_LINK_THROTTLED = 'Email sent less than 60 seconds ago.';

const MagicLinkForm = ({
  backendErrors,
  fields,
  onChangeEmail,
  onSendMagicLink,
  skipError,
  onUsePassword,
  username
}) => {
  const [errorReason, errorMessage] = backendErrors.general
    ? backendErrors.general.split(REASON_DELIMITER)
    : [];

  return (
    <div className="signup-form magic-link">
      <div className="header">
        <span role="img" aria-label="email">
          📧
        </span>
        <h1 className="signup-title">
          Hi {username}! <br /> We have sent you an email
        </h1>
      </div>

      <p className="mt-3">
        A temporary sign in link has been sent to <b>{fields.email.value}</b>.
        <br />
        This link will expire in 60 minutes.
      </p>

      <div className="actions">
        <button
          className="btn btn-link p-0 font-weight-normal"
          onClick={onChangeEmail}
          type="button"
        >
          Not you? Click here to sign in as a different user.
        </button>

        <ResendMagicLink sendMagicLink={onSendMagicLink} />
      </div>

      <div className="mt-2">
        {errorMessage && errorReason === MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED && (
          <MaxInvalidLoginAttemptsMessage
            initialSeconds={Number(errorMessage)}
            resetFormErrors={skipError}
          />
        )}

        {errorMessage &&
          errorReason !== MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED &&
          errorMessage !== MAGIC_LINK_THROTTLED && <ErrorMessage message={errorMessage} />}

        <Separator />

        <div className="bottom-links mt-3">
          <button className="btn btn-link p-0" onClick={onUsePassword} type="button">
            Login with my password
          </button>
        </div>
      </div>
    </div>
  );
};

MagicLinkForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onSendMagicLink: PropTypes.func.isRequired,
  onUsePassword: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  ...propTypesCheck
};

export default MagicLinkForm;
