import { FormGroup, VerboseErrorInput } from 'components/form';
import { CustomerSupportEmailLink } from 'constants/contact';
import TwoFactorMethod from 'containers/advisor/security/two-factor/method';
import TwoFactorSetupContent from 'containers/advisor/security/two-factor/setup/content';
import { ACTIVE_2FA, SETUP_2FA } from 'containers/advisor/security/two-factor/utils/constants';
import { AuthenticationContext } from 'containers/auth';
import { propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const POLICY_INTRODUCTION_STEP = 1;
const CONFIGURE_DEVICE_STEP = 2;
const REGISTER_DEVICE_STEP = 3;

const DeviceRequiredForm = ({ fields, onSubmit, submitting }) => {
  const { authProvider } = useContext(AuthenticationContext);

  const pinRef = useRef(null);

  const [configureData, setConfigureData] = useState(null);
  const [setupScreenType, setSetupScreenType] = useState('');
  const [step, setStep] = useState(POLICY_INTRODUCTION_STEP);

  const submitBtnMessage =
    setupScreenType !== 'email' ? SETUP_2FA.REGISTER_APP_BUTTON : SETUP_2FA.REGISTER_EMAIL_BUTTON;

  useEffect(() => {
    if (step === REGISTER_DEVICE_STEP) pinRef.current.focus();
  }, [step]);

  useEffect(() => {
    if (setupScreenType) fields?.device_type?.onChange(setupScreenType);
  }, [setupScreenType]);

  const handleConfigureDevice = screenName => {
    setSetupScreenType(screenName);

    authProvider
      .configure2FADevice({ totp_key: fields?.totp_key?.value, device_type: screenName })
      .then(({ data, error }) => {
        if (error) toast.error(() => error);
        else {
          setConfigureData(data);
          setStep(REGISTER_DEVICE_STEP);
          if (screenName === 'email') toast.success(() => 'Verification code sent to your email.');
        }
      });
  };

  return (
    <div className="signup-form">
      <h1 className="text-sm-center signup-title">
        {step === CONFIGURE_DEVICE_STEP ? ACTIVE_2FA.CHOOSE_METHOD_TITLE : ACTIVE_2FA.TITLE}
      </h1>

      {step === POLICY_INTRODUCTION_STEP && (
        <div className="mt-2">
          <p className="helper-text">
            Your company has recently implemented a Two-Factor Authentication (2FA) policy for
            increased security to protect both your personal information and sensitive company data.
          </p>
          <p className="helper-text">To continue, please configure a 2FA device to your account.</p>
          <p className="helper-text">
            If you have any questions or need assistance, please do not hesitate to contact our
            support team at <CustomerSupportEmailLink />.
          </p>
          <p className="helper-text">Thank you for your cooperation.</p>

          <div className="text-sm-center pt-2">
            <button
              type="button"
              className="btn btn-primary btn-block center-block"
              onClick={() => setStep(CONFIGURE_DEVICE_STEP)}
            >
              Enable two-factor authentication
            </button>
          </div>
        </div>
      )}

      {step === CONFIGURE_DEVICE_STEP && (
        <div className="mt-2">
          <br />
          <p>{ACTIVE_2FA.CHOOSE_METHOD}</p>
          <div>
            <TwoFactorMethod
              isRecommended
              methodTitle="Authenticator App"
              methodDescription={ACTIVE_2FA.AUTHENTICATION_APP_METHOD}
              onSetup={() => handleConfigureDevice('app')}
            />
            <br />
            <TwoFactorMethod
              methodTitle="Email"
              methodDescription={ACTIVE_2FA.EMAIL_METHOD}
              onSetup={() => handleConfigureDevice('email')}
            />
          </div>
        </div>
      )}

      {step === REGISTER_DEVICE_STEP && (
        <div className="mt-2">
          <form onSubmit={onSubmit} autoComplete="off">
            <TwoFactorSetupContent
              qrCode={configureData?.qr_code}
              setupScreenType={setupScreenType}
              email={fields?.email?.value}
            />

            <FormGroup className="form-group" {...fields.pin}>
              <VerboseErrorInput
                type="number"
                label="Verification code"
                className="form-control sign-up-field"
                {...fields.pin}
                placeholder="Enter your verification code"
                refCb={pinRef}
                onChange={e => {
                  const { value } = e.target;
                  if (value.length <= 6) fields.pin.onChange(value);
                }}
              />
            </FormGroup>

            {!fields.access_token.value && (
              <FormGroup className="form-group" {...fields.password}>
                <VerboseErrorInput
                  type="password"
                  label="Current password"
                  className="form-control sign-up-field"
                  {...fields.password}
                  placeholder="Enter your password"
                />
              </FormGroup>
            )}

            <div className="text-sm-center pt-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setStep(CONFIGURE_DEVICE_STEP);
                  setSetupScreenType('');
                  fields.pin.onChange('');
                }}
              >
                &lt; Back
              </button>
              <br />
              <br />
              <button
                type="submit"
                className="btn btn-primary btn-block center-block"
                disabled={
                  submitting ||
                  (!fields.password.value && !fields.access_token.value) ||
                  !fields.pin.value ||
                  !fields.totp_key.value
                }
              >
                {submitting ? 'Authenticating...' : submitBtnMessage}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

DeviceRequiredForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

export default DeviceRequiredForm;
