import {
  MODEL_PROPOSAL_TYPE,
  SECURITY_PROPOSAL_TYPE,
  INVESTOR_PROPOSAL_TYPE
} from 'components/advisor/proposal/constants';
import { trackAmplitudeEvent } from 'utils/tracking';
import { AdvisorContext } from 'containers/advisor';
import { camelizeKeys, decamelizeKeys } from 'humps';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ProposalHeaderInvestor from './investor';
import ProposalHeaderModel from './model';
import ProposalHeaderSecurity from './security';
import { suggestionsToPortfolios } from './utils';

const ProposalHeader = ({
  accountOptions,
  generateProposal,
  proposal,
  proposalType,
  templateOptions,
  ...props
}) => {
  const { proposalProvider } = useContext(AdvisorContext);

  const onGenerate = values => {
    proposalProvider.clear();

    if (proposalType !== SECURITY_PROPOSAL_TYPE)
      values.target = suggestionsToPortfolios(values.target, false);
    if (values.recommended)
      values.recommended = suggestionsToPortfolios(
        values.recommended,
        true,
        !values.recommendedWithPercentages
      );
    if (values.benchmark)
      values.benchmark = suggestionsToPortfolios(
        values.benchmark,
        true,
        !values.benchmarkWithPercentages
      );

    const mappingValues = {
      ...values,
      start: values.period[0] || values.start,
      end: values.period[1]
    };

    return new Promise((resolve, reject) => {
      generateProposal(decamelizeKeys(mappingValues))
        .then(response => {
          const { data, error } = response;
          if (_.isEmpty(data) || error) {
            const errors = error ? camelizeKeys(error.errors) : null;
            reject(errors);
          } else {
            const amplitudePost = {
              id: data.id,
              investor: data.target.investor.id,
              is_prospect: data.target.investor.is_prospect,
              with_recommended: !!data.recommended,
              with_benchmark: !!data.benchmark
            };
            trackAmplitudeEvent('proposal.generated', amplitudePost);
            resolve();
          }
        })
        .catch(error => {
          const errors = error ? camelizeKeys(error.errors) : null;
          reject(errors);
        });
    });
  };

  const commonProps = { ...props, onGenerate, proposal, proposalType, templateOptions };

  const renderProposalHeader = () => {
    switch (proposalType) {
      case SECURITY_PROPOSAL_TYPE:
        return <ProposalHeaderSecurity {...commonProps} />;
      case MODEL_PROPOSAL_TYPE:
        return <ProposalHeaderModel {...commonProps} />;
      case INVESTOR_PROPOSAL_TYPE:
      default:
        return (
          <ProposalHeaderInvestor
            {...commonProps}
            accountOptions={accountOptions}
            entityId={proposal?.id}
          />
        );
    }
  };

  return <div className="print-display-none proposal-header">{renderProposalHeader()}</div>;
};

ProposalHeader.defaultProps = {
  proposal: null
};

ProposalHeader.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  generateProposal: PropTypes.func.isRequired,
  proposal: PropTypes.object,
  proposalType: PropTypes.string.isRequired,
  templateOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProposalHeader;
