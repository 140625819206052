import config from 'app/config';
import RiskAnalysisTargetScore from 'components/advisor/risk-analysis/risk-analysis-target-score';
import {
  CONCENTRATED_FACTOR_CHART_ID,
  CONCENTRATED_SCORE,
  CORRELATION_FACTOR_CHART_ID,
  CORRELATION_SCORE,
  TAIL_FACTOR_CHART_ID,
  TAIL_SCORE,
  VOLATILITY_FACTOR_CHART_ID,
  VOLATILITY_SCORE
} from 'components/advisor/risk-analysis/risk-analysis-target-score/constants';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { ACCOUNT_TARGET_TYPE, INVESTOR_TARGET_TYPE, MODEL_TARGET_TYPE } from '../constants';
import { getChartData } from '../risk-analysis-target/risk-analysis-content/utils';
import './styles.scss';

const PrismRiskAnalysis = ({ portfolio, prismSummary, skipChartSaving, targetSummary, type }) => {
  const { accountProvider, investorProvider, modelProvider } = useContext(AdvisorContext);

  const getChartId = chartId => {
    if (skipChartSaving) return undefined;
    if (type === ACCOUNT_TARGET_TYPE) return `${chartId}-${portfolio.id}`;
    return chartId;
  };

  const onChartReady = useCallback(
    (id, data) => {
      if (skipChartSaving) return;
      const provider = {
        [ACCOUNT_TARGET_TYPE]: accountProvider,
        [INVESTOR_TARGET_TYPE]: investorProvider,
        [MODEL_TARGET_TYPE]: modelProvider
      };
      provider[type].saveChartImage({ [id]: data });
    },
    [JSON.stringify(portfolio), JSON.stringify(prismSummary), JSON.stringify(targetSummary), type]
  );

  const portfolioPrefix = portfolio?.investor?.user ? 'your' : 'model';

  // change chart title for tail chart
  config.chart.bar.medianPortfolioMove.graphs[0].title = `${
    portfolio?.investor?.user ? 'Your' : 'Current'
  } Portfolio`;

  return (
    <>
      <RiskAnalysisTargetScore
        chartData={getChartData(prismSummary.tail.data)}
        chartId={getChartId(TAIL_FACTOR_CHART_ID)}
        onChartReady={onChartReady}
        score={prismSummary.tail.value}
        scoreName={TAIL_SCORE}
        target={targetSummary?.tail}
        text={`Sudden and severe market losses happen. This score measures how vulnerable ${portfolioPrefix} portfolio is in the event of a market shock.`}
        title="Tail Event Risk Rating"
        titleChart="Median Portfolio Move"
      />
      <RiskAnalysisTargetScore
        chartData={getChartData(prismSummary.correlation.data)}
        chartId={getChartId(CORRELATION_FACTOR_CHART_ID)}
        onChartReady={onChartReady}
        score={prismSummary.correlation.value}
        scoreName={CORRELATION_SCORE}
        target={targetSummary?.correlation}
        text={`When correlation in ${portfolioPrefix} portfolio is high, you lose the diversification benefits of your portfolio and expose yourself to a coordinated loss event.`}
        title="Diversification Risk Rating"
        titleChart="Your asset correlation to other assets"
      />
      <RiskAnalysisTargetScore
        chartData={getChartData(prismSummary.concentrated.data)}
        chartId={getChartId(CONCENTRATED_FACTOR_CHART_ID)}
        onChartReady={onChartReady}
        score={prismSummary.concentrated.value}
        scoreName={CONCENTRATED_SCORE}
        target={targetSummary?.concentrated}
        text={`A large percentage of ${portfolioPrefix} portfolio exposure represented in only a few stocks makes your portfolio more susceptible to idiosyncratic risk.`}
        title="Concentrated Stock Risk Rating"
        titleChart="What your portfolio looks like"
      />
      <RiskAnalysisTargetScore
        chartData={getChartData(prismSummary.volatility.data)}
        chartData2={getChartData(_.omit(prismSummary.volatility.data, 'classifications'))} // it's important to omit classification from data in order to show the volatility correlation
        chartId={getChartId(VOLATILITY_FACTOR_CHART_ID)}
        onChartReady={onChartReady}
        score={prismSummary.volatility.value}
        scoreName={VOLATILITY_SCORE}
        target={targetSummary?.volatility}
        text="Breakdown of portfolio based on the gains and losses in holdings as the volatility in the markets changes. Assets that are positively correlated to volatility help reduce losses when volatility increases."
        title="Volatility Risk Rating"
        titleChart="Your asset diversification"
      />
    </>
  );
};

PrismRiskAnalysis.propTypes = {
  portfolio: PropTypes.object.isRequired,
  prismSummary: PropTypes.object.isRequired,
  skipChartSaving: PropTypes.bool,
  targetSummary: PropTypes.object,
  type: PropTypes.string.isRequired
};

PrismRiskAnalysis.defaultProps = {
  skipChartSaving: false,
  targetSummary: {}
};

export default PrismRiskAnalysis;
