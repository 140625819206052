import PropTypes from 'prop-types';
import React from 'react';

const TrashCanIcon = ({ className, style, title, ...rest }) => (
  <svg
    data-name="Trash"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
    viewBox="0 0 151 161.07"
    {...rest}
  >
    <title>{title}</title>
    <path d="M758,333.2H607V323.13H758Z" transform="translate(-607 -303)" />
    <path d="M712.7,313.07H652.3V303h60.4Z" transform="translate(-607 -303)" />
    <path d="M662.37,323.13H652.3V313.07h10.07Z" transform="translate(-607 -303)" />
    <path d="M712.7,323.13H702.63V313.07H712.7Z" transform="translate(-607 -303)" />
    <path
      d="M617.07,323.13V464.07H747.93V323.13ZM737.87,454H627.13V333.2H737.87Z"
      transform="translate(-607 -303)"
    />
    <path d="M687.53,433.87H677.47V353.33h10.06Z" transform="translate(-607 -303)" />
    <path d="M717.73,433.87H707.67V353.33h10.06Z" transform="translate(-607 -303)" />
    <path d="M657.33,433.87H647.27V353.33h10.06Z" transform="translate(-607 -303)" />
  </svg>
);

TrashCanIcon.defaultProps = {
  className: '',
  style: {},
  title: 'Trash'
};

TrashCanIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string
};

export default TrashCanIcon;
