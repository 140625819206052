import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const INTERVAL_END = 0;

const ResendMagicLink = ({ initialSeconds, sendMagicLink }) => {
  const interval = useRef(null);

  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(initialSeconds);

  const startResendCount = () => {
    interval.current = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds - 1 <= INTERVAL_END) clearInterval(interval.current);
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const onResendHandler = () => {
    setLoading(true);
    sendMagicLink()
      .then(response => {
        if (response?.error) return;
        setSeconds(initialSeconds);
        startResendCount();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    startResendCount();
    return () => interval.current && clearInterval(interval.current);
  }, []);

  return (
    <button
      className="btn btn-link p-0"
      disabled={!!seconds || loading}
      onClick={onResendHandler}
      type="button"
    >
      {seconds ? `Wait ${seconds} second${seconds > 1 ? 's' : ''} to resend` : 'Resend'}
    </button>
  );
};

ResendMagicLink.defaultProps = {
  initialSeconds: 60
};

ResendMagicLink.propTypes = {
  initialSeconds: PropTypes.number,
  sendMagicLink: PropTypes.func.isRequired
};

export default ResendMagicLink;
