/* global DISPLAY_DATE_FORMAT */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';

const DateValueLabel = ({
  isDraft,
  proposal: {
    benchmark_management_fee: benchmarkManagementFee,
    starting_value: value,
    start,
    yearlyWithdrawal,
    recommended_management_fee: recommendedManagementFee,
    status,
    target_management_fee: targetManagementFee
  },
  withManagementFee
}) => {
  if (status === 'ips') return null;

  const currentManagementFee = isDraft ? recommendedManagementFee : targetManagementFee;
  const proposedManagementFee = isDraft ? targetManagementFee : recommendedManagementFee;

  return (
    <p className="date-value-fees">
      {!_.isNil(yearlyWithdrawal) && (
        <span className="yearly-withdrawal">
          Yearly withdrawal: {(yearlyWithdrawal * 100).toFixed(2)}%
        </span>
      )}
      {withManagementFee && (
        <>
          <span>Current management fee {(currentManagementFee * 100).toFixed(2)}%</span>
          <span>Proposed management fee {(proposedManagementFee * 100).toFixed(2)}%</span>
          {!!benchmarkManagementFee && (
            <span>Benchmark management fee {(benchmarkManagementFee * 100).toFixed(2)}%</span>
          )}
        </>
      )}
      {value && <span>Starting value: {formatMoney(value)} </span>}
      {start && <span>Since {moment(start).format(DISPLAY_DATE_FORMAT)}</span>}
    </p>
  );
};

DateValueLabel.defaultProps = {
  isDraft: false,
  withManagementFee: false
};

DateValueLabel.propTypes = {
  isDraft: PropTypes.bool,
  proposal: PropTypes.object.isRequired,
  withManagementFee: PropTypes.bool
};

export default DateValueLabel;
