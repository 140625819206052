import PropTypes from 'prop-types';

const QuestionnaireTemplatePropTypes = PropTypes.shape({
  created: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  is_default: PropTypes.bool.isRequired,
  modified: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
});

export default QuestionnaireTemplatePropTypes;
