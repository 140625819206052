import {
  IndicatorsContainer,
  MenuList,
  Option,
  SingleValue
} from 'components/advisor/generic-selector';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { PROPOSAL_SELECT_STYLES } from '../../header/common/form/utils';

const AsyncPortfolioSelector = ({ loadSuggestions, onChange }) => {
  const setSelection = selection => {
    onChange([{ ...selection, weight: 100 }]);
  };
  return (
    <AsyncSelect
      cacheOptions
      className="async-account-select"
      components={{ MenuList, Option, SingleValue, IndicatorsContainer }}
      defaultOptions
      isSearchable
      loadOptions={loadSuggestions}
      onChange={setSelection}
      placeholder="Type client name to search"
      styles={PROPOSAL_SELECT_STYLES}
    />
  );
};

AsyncPortfolioSelector.propTypes = {
  loadSuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AsyncPortfolioSelector;
