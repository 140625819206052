import React from 'react';

export default ({ className, color }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(180 50 50)">
      <rect y="15" height="69.4063" fill={color || '#31e3ff'} x="15" width="10">
        <animate
          attributeName="height"
          calcMode="spline"
          values="50;70;30;50"
          keyTimes="0;0.33;0.66;1"
          dur="1"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.4s"
          repeatCount="indefinite"
        />
      </rect>
      <rect y="15" height="52.1282" fill={color || '#31e3ff'} x="35" width="10">
        <animate
          attributeName="height"
          calcMode="spline"
          values="50;70;30;50"
          keyTimes="0;0.33;0.66;1"
          dur="1"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.2s"
          repeatCount="indefinite"
        />
      </rect>
      <rect y="15" height="52.8129" fill={color || '#31e3ff'} x="55" width="10">
        <animate
          attributeName="height"
          calcMode="spline"
          values="50;70;30;50"
          keyTimes="0;0.33;0.66;1"
          dur="1"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
          begin="-0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect y="15" height="45.6744" fill={color || '#31e3ff'} x="75" width="10">
        <animate
          attributeName="height"
          calcMode="spline"
          values="50;70;30;50"
          keyTimes="0;0.33;0.66;1"
          dur="1"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
);
