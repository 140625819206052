import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

export const GoalsHintContent = ({ goalsMapping }) => (
  <div className="goals-hint-content">
    <div>
      <div>Risk tolerance</div>
      <div>Investment goal</div>
    </div>
    {Object.entries(goalsMapping)
      .sort(([, goalA], [, goalB]) => goalA.risk_tolerance - goalB.risk_tolerance)
      .map(([name, goal]) => (
        <div key={name}>
          <div style={{ backgroundColor: goal.color }}>{goal.risk_tolerance_range}</div>
          <div>
            {name}
            {goal.description ? ` (${goal.description})` : null}
          </div>
        </div>
      ))}
  </div>
);

GoalsHintContent.propTypes = {
  goalsMapping: PropTypes.object.isRequired
};

export const GoalsHint = ({ goalsMapping }) => {
  if (!goalsMapping) return null;

  const goalsMappingBox = useMemo(
    () => ReactDOMServer.renderToString(GoalsHintContent({ goalsMapping })),
    [JSON.stringify(goalsMapping)]
  );

  return (
    <div className="goals-hint" data-tip={goalsMappingBox} data-for="goals-hint-tooltip">
      <span className="question-mark" />
      <ReactTooltip id="goals-hint-tooltip" effect="solid" place="bottom" type="light" html />
      How the risk tolerance scores are mapped to the investment goals
    </div>
  );
};

GoalsHint.propTypes = {
  goalsMapping: PropTypes.object
};

GoalsHint.defaultProps = {
  goalsMapping: null
};

export default React.memo(GoalsHint);
