import DriftRangeSlider from 'components/slider/drift-range';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import FilterContainer from '../common/filter-container';
import { EXCEPTION_VALUE_RANGE_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const DEFAULT_MIN_RANGE_VALUE = 0;

const ExceptionValueRangeFilter = ({ filters, meta, setFilters, setRequiresPageIndexReset }) => {
  const defaultRange = [DEFAULT_MIN_RANGE_VALUE, meta.maxValue];

  const getDefaultValue = () => {
    if (!filters[EXCEPTION_VALUE_RANGE_ATTR]) return defaultRange;
    return filters[EXCEPTION_VALUE_RANGE_ATTR].split('__').map(value => Number.parseFloat(value));
  };

  const defaultValue = getDefaultValue();

  const delayedOnChangeHandler = useCallback(
    _.debounce(values => {
      const useDefault = JSON.stringify(values) === JSON.stringify(defaultRange);
      setFilters(prevFilters => ({
        ...prevFilters,
        [EXCEPTION_VALUE_RANGE_ATTR]: useDefault ? undefined : values.join('__')
      }));
      setRequiresPageIndexReset(true);
    }, 250),
    []
  );

  const onChangeHandler = values => {
    delayedOnChangeHandler(values);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_VALUE_RANGE_ATTR]} filters={filters} label="Value Range">
      <DriftRangeSlider
        defaultValue={defaultValue}
        max={meta.maxValue}
        min={DEFAULT_MIN_RANGE_VALUE}
        onChange={onChangeHandler}
        steps={0.1}
        useCurrencyFormat
      />
    </FilterContainer>
  );
};

ExceptionValueRangeFilter.propTypes = { ...filterPropTypes, meta: PropTypes.object.isRequired };

ExceptionValueRangeFilter.defaultProps = { ...filterDefaultProps };

export default connect(state => ({
  meta: state.advisors.esExceptionsMeta
}))(ExceptionValueRangeFilter);
