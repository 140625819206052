import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const CodeModal = ({ code, modalId, title }) => {
  const [isShown, setIsShown] = useState(false);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  return (
    <>
      <button
        type="button"
        aria-label="open-modal"
        onClick={show}
        className="btn btn-link code-link"
      >
        Show code
      </button>
      <Modal id={`code-modal-${modalId}`} className="modal-lg" show={isShown} onHidden={hide}>
        <ModalBody>
          <h2>{title}</h2>
          <div className="code-wrapper">
            <pre>{code}</pre>
            <button type="button" className="btn btn-primary" onClick={hide}>
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

CodeModal.defaultProps = {
  title: 'Code'
};

CodeModal.propTypes = {
  code: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default CodeModal;
