/* global STANDARD_DATE_FORMAT */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { RangeDatePicker } from '@y0c/react-datepicker';
import Choice from 'components/form/choice';
import WarningIcon from 'components/svg-icons/warning-icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { riskLevel } from 'utils/utils';
import './styles.scss';

const MENU_LIST_ELEMENT_HEIGHT = 35;
const MENU_LIST_ELEMENT_THRESHOLD = 75;

export const scoreColor = score =>
  ({
    low: 'green',
    neutral: 'yellow',
    high: 'red'
  })[riskLevel(score)];

export const SingleValue = ({ children, ...props }) => {
  const {
    data: { score, isStrategy }
  } = props;
  return (
    <span className="model-dropdown-option">
      <components.SingleValue {...props}>
        {score && (
          <div className={`btn btn-box -level-${Math.ceil(score)}${isStrategy ? '-strategy' : ''}`}>
            {score}
          </div>
        )}
        <span className="btn-box-text"> {children}</span>
      </components.SingleValue>
    </span>
  );
};

SingleValue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.node]).isRequired,
  data: PropTypes.object.isRequired
};

export const MenuList = ({ children, ...rest }) => (
  <components.MenuList {...rest}>{children}</components.MenuList>
);

MenuList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

/**
 * This component is a custom version of react-select's <MenuList /> component that allows
 * rendering a large number of items thanks to the use of react-window FixedSizeList.
 *
 * This component does not share legacy styles, so it can only be used in a react-select component
 * that uses the Option and SingleValue custom components, which is the most common case.
 *
 * ```
 * <Select components={{ MenuList: OptimizedGroupMenuList, Option, SingleValue }} />
 * ```
 *
 * Additionally, this component is especially focused on those react-select components that
 * load grouped options.
 *
 * ```
 * [{ label: 'Title', options: arrayOfOptions }]
 * ```
 */
export const OptimizedGroupMenuList = ({ children, ...rest }) => {
  const totalElements = Array.isArray(children)
    ? children.reduce((acc, { props: { children: group } }) => acc + group.length, 0)
    : 0;

  // allows using the default component <MenuList /> in case the threshold is not exceeded
  if (totalElements <= MENU_LIST_ELEMENT_THRESHOLD)
    return <MenuList {...rest}>{children}</MenuList>;

  const { getValue, maxHeight, options } = rest;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * MENU_LIST_ELEMENT_HEIGHT;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={MENU_LIST_ELEMENT_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ index }) => <div className="MenuList">{children[index]}</div>}
    </List>
  );
};

OptimizedGroupMenuList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  getValue: PropTypes.func.isRequired,
  maxHeight: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired
};

export const Option = props => {
  const {
    children,
    data: { score, isStrategy }
  } = props;
  return (
    <components.Option {...props}>
      <div className="model-dropdown-option">
        {score ? (
          <div className={`btn btn-box -level-${Math.ceil(score)}${isStrategy ? '-strategy' : ''}`}>
            {score}
          </div>
        ) : (
          <WarningIcon className="warning-icon" />
        )}
        <div className="model-list__name" data-strategy={isStrategy}>
          <span className="btn-box-text"> {children}</span>
          {isStrategy ? <span> Strategy</span> : null}
        </div>
      </div>
    </components.Option>
  );
};

export const InlineMultiValueContainer = ({ data }) => (
  <span className="status-option">{data.label}</span>
);

InlineMultiValueContainer.propTypes = {
  data: PropTypes.object.isRequired
};

export const MultipleSelectedContainer = ({ data, selectProps }) => {
  const lastRow = selectProps.value[selectProps.value.length - 1];
  return <span>{`${data.label}${data.label === lastRow.label ? '' : ', '}`}</span>;
};

MultipleSelectedContainer.propTypes = {
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

export const SelectedCounterContainer = ({ index, getValue, selectProps }) =>
  !index &&
  getValue().length &&
  `${selectProps.name ? `${selectProps.name}: ` : ''}${getValue().length} selected`;

SelectedCounterContainer.propTypes = {
  children: PropTypes.string.isRequired
};

export const CustomSelectedValue = ({ data, selectProps, ...props }) => (
  <components.SingleValue {...props}>
    <div>{data.description ? `${data.description} ` : `${selectProps.verb}: ${data.label}`}</div>
  </components.SingleValue>
);

CustomSelectedValue.propTypes = {
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

export const CheckboxOption = ({ label, isSelected, ...rest }) => (
  <span className="model-dropdown-option">
    <components.Option {...rest}>
      <Choice title={label} checked={isSelected} />
    </components.Option>
  </span>
);

export const SearchIndicator = () => <i className="search-icon fs-icon-search" />;

export const IndicatorsContainer = props => (
  <components.IndicatorsContainer {...props}>
    <SearchIndicator />
  </components.IndicatorsContainer>
);

export const SelectRangeDate = ({
  onChange,
  verb,
  relative,
  withRtOptions,
  optionsWithTitleStyles,
  withCustomSelectedMessage,
  customStyles
}) => {
  const [showRangeDatePicker, setShowRangeDatePicker] = useState(false);

  const getDaysAgo = days => moment().subtract(days, 'days').format(STANDARD_DATE_FORMAT);

  const getMonthsAgo = month => moment().subtract(month, 'months').format(STANDARD_DATE_FORMAT);

  const viewAllOption = { label: 'View All', value: '' };

  const today = moment().add(1, 'days').format(STANDARD_DATE_FORMAT);
  const firstDayOfMonth = moment(`${moment().year()}-${moment().month() + 1}-01`).format(
    STANDARD_DATE_FORMAT
  );

  const firstDayOfYear = moment(`${moment().year()}-01-01`).format(STANDARD_DATE_FORMAT);
  const firstDayOfLastYear = moment(`${moment().year() - 1}-01-01`).format(STANDARD_DATE_FORMAT);
  const lastDayOfLastYear = moment(`${moment().year()}-01-01`)
    .subtract(1, 'days')
    .format(STANDARD_DATE_FORMAT);

  const filterBy = ({ value, label }) => {
    if (value === 'custom') setShowRangeDatePicker(true);
    else onChange({ value, label });
  };

  const rtOptions = [
    {
      label: 'Risk tolerance not updated for',
      options: [
        {
          label: '1 year',
          description: 'Risk tolerance not updated for 1 year',
          value: [null, getDaysAgo(365)]
        },
        {
          label: '2 years',
          description: 'Risk tolerance not updated for 2 years',
          value: [null, getDaysAgo(730)]
        },
        {
          label: '3+ years',
          description: 'Risk tolerance not updated for 3 years',
          value: [null, getDaysAgo(1095)]
        }
      ]
    }
  ];

  const rangeOptions = [
    viewAllOption,
    { label: 'Last 7 days', value: [getDaysAgo(7), today] },
    { label: 'Last 30 days', value: [getDaysAgo(30), today] },
    { label: 'Custom Range', value: 'custom' }
  ];

  if (withRtOptions) rangeOptions.push(...rtOptions);

  const relativeRangeOptions = [
    { label: 'This month', value: [firstDayOfMonth, today] },
    { label: 'Last month', value: [getMonthsAgo(1), today] },
    { label: 'Last 3 months', value: [getMonthsAgo(3), today] },
    { label: 'Last 6 months', value: [getMonthsAgo(6), today] },
    { label: 'YTD', value: [firstDayOfYear, today] },
    { label: 'Last year', value: [firstDayOfLastYear, lastDayOfLastYear] },
    viewAllOption,
    { label: 'Custom Range', value: 'custom' }
  ];

  let selectComponents = {};
  if (withCustomSelectedMessage) selectComponents = { SingleValue: CustomSelectedValue };

  let singleValueComponentStyles = {
    ':before': {
      content: `"${verb}: "`
    }
  };
  if (withCustomSelectedMessage) singleValueComponentStyles = {};

  const styles = {
    singleValue: styles =>
      verb
        ? {
            ...styles,
            ...singleValueComponentStyles
          }
        : styles,
    option: (styles, { data, isSelected }) => {
      if (optionsWithTitleStyles) {
        const color = data.title ? 'grey' : isSelected ? 'white' : '#4a5356';
        return {
          ...styles,
          color,
          cursor: data.title ? 'not-allowed' : 'default',
          textAlign: 'left'
        };
      }
      return {
        ...styles
      };
    },
    control: styles => ({
      ...styles,
      ...customStyles.control
    }),
    groupHeading: styles => ({
      ...styles,
      textAlign: 'left'
    }),
    menuList: styles => ({
      ...styles,
      '::-webkit-scrollbar': {
        width: '10px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#bcbfc4',
        borderRadius: '20px',
        border: '3px solid white'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#818a91'
      }
    })
  };

  return showRangeDatePicker ? (
    <div className="range-date-picker">
      <RangeDatePicker
        onChange={(start, end) => {
          if (start && end)
            filterBy({
              value: [
                moment(start).format(STANDARD_DATE_FORMAT),
                moment(end).format(STANDARD_DATE_FORMAT)
              ],
              label: 'Custom'
            });
        }}
      />
      <i
        className="fs-icon-cancel-round"
        role="button"
        tabIndex={0}
        onKeyUp={() => {}}
        onClick={() => {
          onChange({ value: '' });
          setShowRangeDatePicker(false);
        }}
      />
    </div>
  ) : (
    <Select
      defaultValue={viewAllOption}
      onChange={filterBy}
      options={relative ? relativeRangeOptions : rangeOptions}
      className="dropdown"
      components={selectComponents}
      verb={verb}
      styles={styles}
    />
  );
};

SelectRangeDate.propTypes = {
  withCustomSelectedMessage: PropTypes.bool,
  customStyles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  optionsWithTitleStyles: PropTypes.bool,
  relative: PropTypes.bool,
  verb: PropTypes.string.isRequired,
  withRtOptions: PropTypes.bool
};

SelectRangeDate.defaultProps = {
  withCustomSelectedMessage: false,
  customStyles: { control: {} },
  optionsWithTitleStyles: false,
  relative: false,
  withRtOptions: false
};
