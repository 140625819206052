import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ageCalculator } from 'utils/questionnaire';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const BirthdayRetirementReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const { employment } = question.answer;

  const age = ageCalculator(question.answer.birthday);
  const retirement = Number(question.answer.retirement) || 0;
  const retireIn = retirement - age;

  return (
    <ReadOnlyQuestion question={question}>
      <p>{age} years old</p>
      {employment ? (
        <p>{employment}</p>
      ) : (
        <p>{retireIn > 0 ? `I\u0027m planning to retire in ${retireIn} years` : 'I am retired'}</p>
      )}
    </ReadOnlyQuestion>
  );
};

BirthdayRetirementReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default BirthdayRetirementReadOnlyQuestion;
