/* eslint-disable class-methods-use-this */
import { INVOICE_UPCOMING, SUBSCRIPTION_GET } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class SubscriptionProvider extends DataProvider {
  get() {
    return this.provider.get(`${config.apiBase}billing/subscription/`).then(({ data }) => {
      if (data) this.dispatch(SUBSCRIPTION_GET, { data });
      return { data };
    });
  }

  create(price, promoCode) {
    return this.provider
      .post(`${config.apiBase}billing/subscription/`, {
        price: price.id,
        promotion_code: promoCode
      })
      .then(({ data, error }) => ({ data, error }));
  }

  update(price) {
    return this.provider
      .put(`${config.apiBase}billing/subscription/`, { price: price.id })
      .then(({ data, error }) => ({ data, error }));
  }

  isTrialing = subscription => subscription?.status === 'trialing';

  subscriptionIsActive = subscription =>
    subscription?.status === 'active' ||
    subscription?.status === 'trialing' ||
    subscription?.status === 'past_due';

  getPrice(subscription) {
    const price = subscription?.price;
    return price?.name ? price.name.toLowerCase() : '';
  }

  // ============================  Invoice  ============================= //

  previewInvoice(params) {
    return this.provider.get(`${config.apiBase}billing/invoices/preview/`, params);
  }

  getUpcomingInvoice(params) {
    return this.previewInvoice(params).then(response => {
      if (!response.error)
        this.dispatch(INVOICE_UPCOMING, {
          data: response.data
        });
      return response;
    });
  }
}

export default SubscriptionProvider;
