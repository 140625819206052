/* eslint-disable no-underscore-dangle */
import {
  CHANGE_REPORT_ORIENTATION,
  FETCHING,
  FETCHING_FAILURE,
  PROPOSAL_CLEAR,
  PROPOSAL_CREATE,
  PROPOSAL_GET,
  PROPOSAL_REPORTS_CREATE,
  PROPOSAL_REPORTS_CREATE_REVIEW,
  PROPOSAL_REPORTS_GET,
  PROPOSAL_REPORTS_LIST,
  PROPOSAL_REPORTS_UPDATE,
  PROPOSAL_REPORT_CLEAR,
  PROPOSAL_REPORT_UNSAVED,
  PROPOSAL_SAVE_CHART_IMAGE,
  PROPOSAL_SAVE_REPORT_PDF_URL,
  PROPOSAL_SET_COLLAPSED_HOLDINGS,
  PROPOSAL_SET_COLLAPSED_REGIONS,
  PROPOSAL_SET_COLLAPSED_SECTORS,
  PROPOSAL_SET_COLLAPSED_STYLES,
  PROPOSAL_SET_COLLAPSED_TOP_HOLDINGS,
  PROPOSAL_UPDATE
} from 'constants/actions';
import { decamelizeKeys } from 'humps';
import _ from 'lodash';
import config from '../config';
import DataProvider from './data';

const DEFAULT_PROPOSAL_TEMPLATE_CACHE = 'default-proposal-template';

class ProposalProvider extends DataProvider {
  get defaultTemplate() {
    if (!this._defaultTemplate) {
      const templateId = Number.parseInt(this.storage.getItem(DEFAULT_PROPOSAL_TEMPLATE_CACHE), 10);
      if (templateId && Number.isInteger(templateId)) this._defaultTemplate = templateId;
    }
    return this._defaultTemplate;
  }

  set defaultTemplate(templateId) {
    this._defaultTemplate = templateId;
    if (templateId) this.storage.setItem(DEFAULT_PROPOSAL_TEMPLATE_CACHE, templateId);
    else this.storage.removeItem(DEFAULT_PROPOSAL_TEMPLATE_CACHE);
  }

  get(id) {
    this.dispatch(FETCHING);
    const promise = this.provider.get(`${config.apiBase}proposals/${id}/`).then(data => {
      if (!data.error) this.dispatch(PROPOSAL_GET, { data: data.data });
      else this.dispatch(FETCHING_FAILURE);
      return data;
    });
    return promise;
  }

  create(values) {
    const data = decamelizeKeys(values);

    if (_.isEmpty(values.recommended)) delete data.recommended;
    if (_.isEmpty(values.benchmark)) delete data.benchmark;

    data.starting_value = data.starting_value.replace(/,/g, '');

    // Save the last template used in localstorage for future reference
    this.defaultTemplate = values.template;

    const promise = this.provider.post(`${config.apiBase}proposals/`, data).then(result => {
      if (!result.error) this.dispatch(PROPOSAL_CREATE, { data: result.data });

      return result;
    });

    return promise;
  }

  clear() {
    this.dispatch(PROPOSAL_CLEAR);
  }

  saveChartImage(data) {
    this.dispatch(PROPOSAL_SAVE_CHART_IMAGE, { data });
  }

  setCollapsedHoldings(data) {
    this.dispatch(PROPOSAL_SET_COLLAPSED_HOLDINGS, { data });
  }

  setCollapsedStyles(data) {
    this.dispatch(PROPOSAL_SET_COLLAPSED_STYLES, { data });
  }

  setCollapsedSectors(data) {
    this.dispatch(PROPOSAL_SET_COLLAPSED_SECTORS, { data });
  }

  setCollapsedTopHoldings(data) {
    this.dispatch(PROPOSAL_SET_COLLAPSED_TOP_HOLDINGS, { data });
  }

  setCollapsedRegions(data) {
    this.dispatch(PROPOSAL_SET_COLLAPSED_REGIONS, { data });
  }

  update(proposalId, data) {
    this.dispatch(FETCHING);
    return this.provider.patch(`${config.apiBase}proposals/${proposalId}/`, data).then(response => {
      if (!response.error) this.dispatch(PROPOSAL_UPDATE, { data: response.data });
      else this.dispatch(FETCHING_FAILURE);
      return response;
    });
  }

  sendToAddepar(reportId, clientId, isProspect = false) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.post(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/send_to_addepar/`
    );
  }

  getReports(clientId, isProspect = false) {
    this.dispatch(FETCHING);
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider
      .get(`${config.apiBase}${type}/${clientId}/proposal-reports/`)
      .then(({ data, error }) => {
        if (!error) this.dispatch(PROPOSAL_REPORTS_LIST, { id: clientId, data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  getReport(reportId, clientId, isProspect = false) {
    this.dispatch(FETCHING);
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider
      .get(`${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/`)
      .then(({ data, error }) => {
        if (!error) this.dispatch(PROPOSAL_REPORTS_GET, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  getReportReviews(reportId, clientId, isProspect = false) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.get(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/reviews/`
    );
  }

  createReport(data, clientId, isProspect = false) {
    this.dispatch(FETCHING);
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider
      .post(`${config.apiBase}${type}/${clientId}/proposal-reports/`, data)
      .then(({ data, error }) => {
        if (!error) this.dispatch(PROPOSAL_REPORTS_CREATE, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  updateReport(data, reportId, clientId, isProspect = false) {
    this.dispatch(FETCHING);
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider
      .patch(`${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/`, data)
      .then(({ data, error }) => {
        if (!error) this.dispatch(PROPOSAL_REPORTS_UPDATE, { data });
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  deleteReport(reportId, clientId, isProspect = false) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider
      .delete(`${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/`)
      .then(() => {
        this.dispatch(PROPOSAL_REPORT_CLEAR);
      });
  }

  getReview(token, reportId, clientId, isProspect = false) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.get(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/review/`,
      { token }
    );
  }

  markReviewMailAsRead(token, reportId, clientId, isProspect = false) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.post(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/mark-review-mail-as-read/?token=${token}`
    );
  }

  createReview(reportId, clientId, isProspect = false, data = {}) {
    this.dispatch(FETCHING);
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider
      .post(`${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/reviews/`, data)
      .then(({ data, error }) => {
        if (!error) this.dispatch(PROPOSAL_REPORTS_CREATE_REVIEW);
        else this.dispatch(FETCHING_FAILURE);
        return { data, error };
      });
  }

  acknowledgeReview(token, reportId, clientId, isProspect = false, data = {}) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.post(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/review/?token=${token}`,
      data
    );
  }

  generateReviewMail(reportId, clientId, isProspect = false, data = {}) {
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.post(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/generate-review-mail/`,
      data
    );
  }

  async uploadSign(token, reportId, clientId, isProspect = false, data = {}) {
    const type = isProspect ? 'prospects' : 'investors';
    const signature = await fetch(data.signature)
      .then(r => r.blob())
      .then(blob => new File([blob], `sign-${reportId}.png`));
    const formData = this.formData({ ...data, signature });
    return this.provider.postFormData(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/review/?token=${token}`,
      formData,
      null
    );
  }

  async uploadPdfReport(reportUrl, reportId, clientId, isProspect = false, signed = false) {
    const document = await fetch(reportUrl)
      .then(r => r.blob())
      .then(
        blob => new File([blob], `report-${reportId}-${clientId}.pdf`, { type: 'application/pdf' })
      );

    const payload = { [signed ? 'signed_pdf_report' : 'pdf_report']: document };
    if (signed) payload.with_signature = true;

    const formData = this.formData(payload);
    const type = isProspect ? 'prospects' : 'investors';
    return this.provider.postFormData(
      `${config.apiBase}${type}/${clientId}/proposal-reports/${reportId}/upload_pdf/`,
      formData,
      null
    );
  }

  setCurrentReportUnsaved(unsaved) {
    this.dispatch(PROPOSAL_REPORT_UNSAVED, { unsaved });
  }

  saveLatestPdfReportUrl(data) {
    this.dispatch(PROPOSAL_SAVE_REPORT_PDF_URL, { data });
  }

  changeCurrentReportOrientation(data) {
    this.dispatch(CHANGE_REPORT_ORIENTATION, { data });
  }

  clearCurrentReport() {
    this.dispatch(PROPOSAL_REPORT_CLEAR);
  }
}

export default ProposalProvider;
