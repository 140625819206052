import LabelEdit from 'components/label-edit';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './styles.scss';

export const HouseholdHeader = ({ household, renameHousehold, showDeleteHouseholdModal }) => {
  const [houeseHoldName, setHouseholdName] = useState('');

  const handleOnSave = newName => {
    renameHousehold(newName);
  };

  const handleOnchange = e => {
    setHouseholdName(e?.target?.value);
  };

  useEffect(() => {
    if (houeseHoldName !== household?.name) setHouseholdName(household?.name || '');
  }, [household.name]);

  return (
    <div className="household-header">
      <div className="household-header__container">
        <LabelEdit
          inputValue={houeseHoldName}
          onSave={handleOnSave}
          label={houeseHoldName}
          inputOnChange={handleOnchange}
          toolTipText="Edit Household name"
          labelOnClick={() => setHouseholdName(houeseHoldName)}
        />
        <button
          type="button"
          aria-label="Remove"
          className="household-header__delete-btn btn btn-secondary"
          onClick={() => showDeleteHouseholdModal(household)}
        >
          <TrashCanIcon className="trash-icon" title="Remove" />
        </button>
      </div>
    </div>
  );
};

HouseholdHeader.propTypes = {
  renameHousehold: PropTypes.func.isRequired,
  household: PropTypes.object.isRequired,
  showDeleteHouseholdModal: PropTypes.func.isRequired
};

export default HouseholdHeader;
