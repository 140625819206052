import { Path, Svg, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const IconDefaultProps = {
  height: 24,
  style: {},
  width: 24
};

const IconProps = {
  height: PropTypes.number,
  style: PropTypes.object,
  width: PropTypes.number
};

export const BasicMaterialsIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#FF8A65"
        fillRule="evenodd"
        d="M7 9H3V5H7V9ZM7 14H3V10H7V14ZM8 14H12V10H8V14ZM17 14H13V10H17V14ZM8 9H12V5H8V9ZM13 9V5H17V9H13ZM18 14H22V10H18V14ZM7 19H3V15H7V19ZM8 19H12V15H8V19ZM17 19H13V15H17V19ZM18 19H22V15H18V19ZM18 9V5H22V9H18Z"
      />
    </Svg>
  </View>
);

BasicMaterialsIcon.propTypes = { ...IconProps };
BasicMaterialsIcon.defaultProps = { ...IconDefaultProps };

export const CommunicationServicesIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#9575CD"
        fillRule="evenodd"
        d="M16 1H8C6.34003 1 5 2.34003 5 4V20C5 21.66 6.34003 23 8 23H16C17.66 23 19 21.66 19 20V4C19 2.34003 17.66 1 16 1ZM14 21H10V20H14V21ZM7 18H17V4H7V18Z"
      />
    </Svg>
  </View>
);

CommunicationServicesIcon.propTypes = { ...IconProps };
CommunicationServicesIcon.defaultProps = { ...IconDefaultProps };

export const ConsumerCyclicalIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#7986CB"
        fillRule="evenodd"
        d="M17.5 4C18.1599 4 18.72 4.42 18.9199 5.00999L21 11V19C21 19.55 20.55 20 20 20H19C18.45 20 18 19.55 18 19V18H6V19C6 19.55 5.55005 20 5 20H4C3.44995 20 3 19.55 3 19V11L5.08008 5.00999C5.29004 4.42 5.84009 4 6.5 4H17.5ZM5 13.5C5 14.33 5.66992 15 6.5 15C7.33008 15 8 14.33 8 13.5C8 12.67 7.33008 12 6.5 12C5.66992 12 5 12.67 5 13.5ZM17.5 15C16.6699 15 16 14.33 16 13.5C16 12.67 16.6699 12 17.5 12C18.3301 12 19 12.67 19 13.5C19 14.33 18.3301 15 17.5 15ZM6.5 5.5L5 10H19L17.5 5.5H6.5Z"
      />
    </Svg>
  </View>
);

ConsumerCyclicalIcon.propTypes = { ...IconProps };
ConsumerCyclicalIcon.defaultProps = { ...IconDefaultProps };

export const ConsumerDefensiveIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#81C784"
        fillRule="evenodd"
        d="M1 2V4H3L6.59998 11.59L5.25 14.03C4.52002 15.37 5.47998 17 7 17H19V15H7L8.09998 13H15.55C16.3 13 16.96 12.59 17.3 11.97L20.88 5.47998C21.25 4.82001 20.77 4 20.01 4H5.21002L4.27002 2H1ZM7 18C5.90002 18 5.01001 18.9 5.01001 20C5.01001 21.1 5.90002 22 7 22C8.10004 22 9 21.1 9 20C9 18.9 8.10004 18 7 18ZM15.01 20C15.01 18.9 15.9 18 17 18C18.1 18 19 18.9 19 20C19 21.1 18.1 22 17 22C15.9 22 15.01 21.1 15.01 20Z"
      />
    </Svg>
  </View>
);

ConsumerDefensiveIcon.propTypes = { ...IconProps };
ConsumerDefensiveIcon.defaultProps = { ...IconDefaultProps };

export const EnergyIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#FFB74D"
        fillRule="evenodd"
        d="M11 0.550781H13V3.50079H11V0.550781ZM6.76001 4.84076L4.96008 3.05078L3.55005 4.46075L5.34009 6.25079L6.76001 4.84076ZM4 10.5008H1V12.5008H4V10.5008ZM20.45 4.46075L19.04 3.05078L17.25 4.84076L18.66 6.25079L20.45 4.46075ZM19.03 19.9608L17.24 18.1608L18.64 16.7608L20.4399 18.5508L19.03 19.9608ZM23 10.5008H20V12.5008H23V10.5008ZM6 11.5008C6 8.19079 8.68994 5.50079 12 5.50079C15.3101 5.50079 18 8.19079 18 11.5008C18 14.8108 15.3101 17.5008 12 17.5008C8.68994 17.5008 6 14.8108 6 11.5008ZM13 22.4508V19.5008H11V22.4508H13ZM4.96008 19.9508L3.55005 18.5408L5.34009 16.7408L6.75 18.1508L4.96008 19.9508Z"
      />
    </Svg>
  </View>
);

EnergyIcon.propTypes = { ...IconProps };
EnergyIcon.defaultProps = { ...IconDefaultProps };

export const FinancialServicesIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#4FC3F7"
        fillRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.90002 21 3 20.1 3 19V5C3 3.9 3.90002 3 5 3ZM7 17H9V10H7V17ZM13 17H11V7H13V17ZM15 17H17V13H15V17Z"
      />
    </Svg>
  </View>
);

FinancialServicesIcon.propTypes = { ...IconProps };
FinancialServicesIcon.defaultProps = { ...IconDefaultProps };

export const HealthcareIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#F06292"
        fillRule="evenodd"
        d="M5 3H19C20.1 3 21 3.90002 21 5V19C21 20.1 20.1 21 19 21H5C3.90002 21 3 20.1 3 19L3.01001 5C3.01001 3.90002 3.90002 3 5 3ZM14 14H18V10H14V6H10V10H6V14H10V18H14V14Z"
      />
    </Svg>
  </View>
);

HealthcareIcon.propTypes = { ...IconProps };
HealthcareIcon.defaultProps = { ...IconDefaultProps };

export const IndustrialsIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#A1887F"
        fillRule="evenodd"
        d="M15 5V11H21V21H3V7H9V5L12 2L15 5ZM5 19H7V17H5V19ZM7 15H5V13H7V15ZM5 11H7V9H5V11ZM11 19V17H13V19H11ZM11 13V15H13V13H11ZM11 11V9H13V11H11ZM11 5V7H13V5H11ZM19 19H17V17H19V19ZM17 15H19V13H17V15Z"
      />
    </Svg>
  </View>
);

IndustrialsIcon.propTypes = { ...IconProps };
IndustrialsIcon.defaultProps = { ...IconDefaultProps };

export const RealEstateIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#E57373"
        fillRule="evenodd"
        d="M12 7H22V21H2V3H12V7ZM4 19H6V17H4V19ZM6 15H4V13H6V15ZM4 11H6V9H4V11ZM6 7H4V5H6V7ZM8 19H10V17H8V19ZM10 15H8V13H10V15ZM8 11H10V9H8V11ZM10 7H8V5H10V7ZM20 19V9H12V11H14V13H12V15H14V17H12V19H20ZM18 11H16V13H18V11ZM16 15H18V17H16V15Z"
      />
    </Svg>
  </View>
);

RealEstateIcon.propTypes = { ...IconProps };
RealEstateIcon.defaultProps = { ...IconDefaultProps };

export const TechnologyIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#4DB6AC"
        fillRule="evenodd"
        d="M22 18V3H2V18H0V20H24V18H22ZM14 18H10V17H14V18ZM4 15H20V5H4V15Z"
      />
    </Svg>
  </View>
);

TechnologyIcon.propTypes = { ...IconProps };
TechnologyIcon.defaultProps = { ...IconDefaultProps };

export const UtilitiesIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 24 24">
      <Path
        fill="#F06292"
        fillRule="evenodd"
        d="M15 2.05078V6.86078C16.79 7.90075 18 9.83081 18 12.0508C18 15.3608 15.3101 18.0508 12 18.0508C8.68994 18.0508 6 15.3608 6 12.0508C6 9.83081 7.20996 7.90075 9 6.86078V2.05078H15ZM4 11.0508H1V13.0508H4V11.0508ZM4.96008 20.5008L3.55005 19.0908L5.34009 17.2908L6.75 18.7008L4.96008 20.5008ZM13 20.0508V23.0008H11V20.0508H13ZM20 13.0508H23V11.0508H20V13.0508ZM19.03 20.5108L17.24 18.7108L18.64 17.3108L20.4399 19.1008L19.03 20.5108Z"
      />
    </Svg>
  </View>
);

UtilitiesIcon.propTypes = { ...IconProps };
UtilitiesIcon.defaultProps = { ...IconDefaultProps };
