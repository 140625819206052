import riskTolerancePlaceholder from 'assets/img/banner-risk-tolerance.png';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import CodeModal from './code-modal';
import './styles.scss';

const DEFAULT_COPY_MESSAGE = 'The code was copied!';
const STRATIFI_LOGO = 'https://assets.stratifi.com/logo/StratiFi_Gradient_Logo.svg';

const WidgetCodeIntegration = ({ user }) => {
  const userIdToken = `${user.advisor.id}/${user.advisor.widget_token}`;
  const url = `${window.location.origin}/inapp-view/b/${userIdToken}`;
  const signatureUrl = `${url}/target-rating/?lang=en`;
  const emailSignature = `<div style="display: inline-block; font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 16px; border: 0; padding: 10px; margin: 0; background-color: #eff3f3;"><a href="${signatureUrl}" target="_blank" rel="noopener noreferrer" style="color: #525c5f; text-decoration: none;"><span style="padding-right: 10px; margin-right: 8px; border-right: 1px solid #bcbfc4;">What's your risk tolerance?</span><img src="${STRATIFI_LOGO}" alt="STRATiFi" height="13"/></a></div>`;
  const iframeCode = `<iframe src="${url}" width="100%" height="467px" frameborder="0"></iframe>`;
  const iframeWordPressCode = `[iframe src="${url}" height="467px" frameborder="0"]`;
  const buttonCode = `<div id="stratifi-btn"></div><script src="${
    window.location.origin
  }/js/stratifi-button.js" id="${userIdToken}"></script>`;

  const onCopy = (code, message = DEFAULT_COPY_MESSAGE) => async () => {
    toast.info(message);
    await navigator.clipboard.writeText(code);
  };

  const onCopyWithFormat = (code, message = DEFAULT_COPY_MESSAGE) => () => {
    const el = document.createElement('div');
    el.innerHTML = code;
    const emailSignature = el.innerHTML;

    const listener = event => {
      event.clipboardData.setData('text/html', emailSignature);
      event.clipboardData.setData('text/plain', emailSignature);
      event.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    toast.info(message);
  };

  return (
    <div id="widget-code-integration">
      <h1 className="title">Referral &amp; Lead Generation Widget</h1>
      <span>
        Generate more leads and grow revenue with StratiFi by installing our marketing widget on
        your website or include it in your signature.
      </span>
      <div className="content">
        <div className="row">
          <div className="col">
            <h2>Add the button to your email signature</h2>
            <div className="email-section">
              <div className="preview">
                <a href={signatureUrl} target="_blank" rel="noopener noreferrer">
                  <span>What&apos;s your risk tolerance?</span>
                  <img
                    src="https://assets.stratifi.com/logo/StratiFi_Gradient_Logo.svg"
                    alt="STRATiFi"
                    height="13"
                  />
                </a>
              </div>
              <button
                aria-label="on-copy-with-format"
                type="button"
                className="btn btn-primary"
                onClick={onCopyWithFormat(emailSignature, 'The email signature code was copied!')}
              >
                Copy code
              </button>
              <CodeModal code={emailSignature} modalId="email" title="Email signature code" />
            </div>
          </div>
        </div>
      </div>
      <div className="content mt-2">
        <div className="row">
          <div className="col">
            <h2>Add the banner to your website</h2>
            <img
              src={riskTolerancePlaceholder}
              className="img-fluid risk-tolerance-placeholder"
              alt="What's your risk tolerance?"
            />
            <div className="code-section">
              <div className="code-section__buttons">
                <button
                  aria-label="iframe-code"
                  type="button"
                  className="btn btn-primary"
                  onClick={onCopy(iframeCode, 'The iframe code was copied!')}
                >
                  Copy code for iframe
                </button>
                <CodeModal code={iframeCode} modalId="iframe" title="iframe code" />
              </div>
              <div className="code-section__buttons">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onCopy(iframeWordPressCode, 'The WordPress code was copied!')}
                >
                  Copy code for WordPress website
                </button>
                <CodeModal code={iframeWordPressCode} modalId="wordpress" title="WordPress code" />
              </div>
              <div className="code-section__buttons">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onCopy(buttonCode, 'The button code was copied!')}
                >
                  Copy code for the button only
                </button>
                <CodeModal code={buttonCode} modalId="button" title="Button code" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WidgetCodeIntegration.propTypes = {
  user: PropTypes.object.isRequired
};

export default WidgetCodeIntegration;
