import { StyleSheet } from '@react-pdf/renderer';
import { LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  container: {
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR
  }
});

export default styles;
