import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import { useAnnualTaxableIncomeQuestionDetails } from '../hooks';

const AnnualTaxableIncomeQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  const {
    estimatedAnnualIncomeText,
    estimatedYearlyContributionText,
    questionText
  } = useAnnualTaxableIncomeQuestionDetails({ question });

  return (
    <ReportQuestion question={{ ...question, question: questionText }}>
      <View>
        <Text>
          {estimatedAnnualIncomeText}{' '}
          {question.answer.income ? formatMoney(question.answer.income) : '-'}
        </Text>
      </View>
      <View>
        <Text>
          {estimatedYearlyContributionText}{' '}
          {question.answer.contribution
            ? `${formatPercentage(question.answer.contribution, 100)} (${formatMoney(
                question.answer.income * question.answer.contribution
              )})`
            : '-'}
        </Text>
      </View>
    </ReportQuestion>
  );
};

AnnualTaxableIncomeQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default AnnualTaxableIncomeQuestion;
