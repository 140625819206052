import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import { TYPE_ANNUAL, TYPE_ONCE } from '../hooks';

const ValueRequirementQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  return (
    <ReportQuestion question={question}>
      <Text>
        {question.answer.type === TYPE_ANNUAL && 'I will need an annual income of '}
        {question.answer.type === TYPE_ONCE && 'I need '}
        {formatMoney(question.answer.amount)} from this investment
      </Text>
    </ReportQuestion>
  );
};

ValueRequirementQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ValueRequirementQuestion;
