import { Circle, G, Path, Svg, Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const SCORES = {
  0: { position: 'M534.2,602.4l-229.8,78.3l0,0L489.8,524L534.2,602.4z', color: styles.fColor0 },
  1: { position: 'M515.1,608.1L274,579.8l0,0l234.8-61.6L515.1,608.1z', color: styles.fColor0 },
  2: { position: 'M497,605.7L287.1,483.6l0,0L527,520.8L497,605.7z', color: styles.fColor1 },
  3: { position: 'M481.3,596.1L337.5,400.5l0,0l205.2,129.7L481.3,596.1z', color: styles.fColor2 },
  4: { position: 'M470.7,581.2l-53.8-236.7l0,0l136.4,200.8L470.7,581.2z', color: styles.fColor3 },
  5: { position: 'M467,563.2l45-238.5l0,0l45,238.5L467,563.2z', color: styles.fColor4 },
  6: { position: 'M470.7,545.2l136.4-200.8l0,0l-53.8,236.7L470.7,545.2z', color: styles.fColor5 },
  7: { position: 'M481.3,530.3l205.2-129.7l0,0L542.7,596.1L481.3,530.3z', color: styles.fColor6 },
  8: { position: 'M497,520.8l239.9-37.2l0,0L527,605.7L497,520.8z', color: styles.fColor7 },
  9: { position: 'M515.1,518.3L750,579.8l0,0l-241.1,28.3L515.1,518.3z', color: styles.fColor8 },
  10: { position: 'M534.2,524l185.4,156.7l0,0l-229.8-78.3L534.2,524z', color: styles.fColor9 }
};

const RiskGauge = ({ width, height, risk, tolerance, withRiskScore }) => {
  const roundedRisk = Math.round(risk);
  const roundedTolerance = Math.round(tolerance ?? 0);

  const hideScoreBlock = value => (value >= roundedRisk ? styles.fColorOff : {});

  return (
    <>
      <Svg style={{ width, height }} viewBox="0 0 1024 1024">
        <G>
          <Path
            style={[styles.fColor0, hideScoreBlock(0)]}
            d="M176.6,752.4L75.2,810.9c-35.3-61.2-57.4-129.2-64.8-199.5l116.5-12.2C132.5,653.2,149.4,705.4,176.6,752.4z"
          />
          <Path
            style={[styles.fColor1, hideScoreBlock(1)]}
            d="M126.8,599.2L10.4,611.4C3,541.1,10.4,470.1,32.3,402.8L143.7,439C126.9,490.6,121.1,545.2,126.8,599.2z"
          />
          <Path
            style={[styles.fColor2, hideScoreBlock(2)]}
            d="M143.7,439L32.3,402.8c21.8-67.2,57.6-129.1,104.9-181.6l87,78.3C187.9,339.9,160.4,387.4,143.7,439z"
          />
          <Path
            style={[styles.fColor3, hideScoreBlock(3)]}
            d="M224.2,299.5l-87-78.3c47.3-52.5,105.1-94.5,169.7-123.3l47.6,107C304.9,227,260.5,259.2,224.2,299.5z"
          />
          <Path
            style={[styles.fColor4, hideScoreBlock(4)]}
            d="M354.5,204.9l-47.6-107C371.4,69.2,441.3,54.3,512,54.3v117.1C457.7,171.4,404.1,182.8,354.5,204.9z"
          />
          <Path
            style={[styles.fColor5, hideScoreBlock(5)]}
            d="M512,171.4V54.3c70.7,0,140.6,14.9,205.2,43.6l-47.6,107C619.9,182.8,566.3,171.4,512,171.4z"
          />
          <Path
            style={[styles.fColor6, hideScoreBlock(6)]}
            d="M669.5,204.9l47.6-107c64.6,28.8,122.4,70.8,169.7,123.3l-87,78.3C763.5,259.2,719.1,227,669.5,204.9z"
          />
          <Path
            style={[styles.fColor7, hideScoreBlock(7)]}
            d="M799.8,299.5l87-78.3c47.3,52.5,83,114.4,104.9,181.6L880.3,439C863.6,387.4,836.1,339.9,799.8,299.5z"
          />
          <Path
            style={[styles.fColor8, hideScoreBlock(8)]}
            d="M880.3,439l111.4-36.2c21.8,67.2,29.3,138.3,21.9,208.6l-116.5-12.2C902.9,545.2,897.1,490.6,880.3,439z"
          />
          <Path
            style={[styles.fColor9, hideScoreBlock(9)]}
            d="M897.2,599.2l116.5,12.2c-7.4,70.3-29.5,138.3-64.8,199.5l-101.4-58.5C874.6,705.4,891.5,653.2,897.2,599.2z"
          />
        </G>
        {tolerance && (
          <G>
            <Path style={styles.tolerance} d={SCORES[roundedTolerance].position} />
          </G>
        )}
        <G>
          <Path style={[styles.risk, SCORES[roundedRisk].color]} d={SCORES[roundedRisk].position} />
          <Circle style={[styles.risk, SCORES[roundedRisk].color]} cx="512" cy="563.2" r="46.2" />
        </G>
        <G>
          <Text x="118.2343" y="937.1733" style={[styles.text, styles.fColor0]}>
            1
          </Text>
          <Text x="778.2344" y="937.1108" style={[styles.text, styles.fColor9]}>
            10
          </Text>
        </G>
      </Svg>
      {withRiskScore && (
        <Text style={[styles.riskScoreText, { width, ...styles[`tColor${roundedRisk}`] }]}>
          {risk.toFixed(1)}
        </Text>
      )}
    </>
  );
};

RiskGauge.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  risk: PropTypes.number.isRequired,
  tolerance: PropTypes.number,
  withRiskScore: PropTypes.bool
};

RiskGauge.defaultProps = {
  width: 100,
  height: 100,
  tolerance: undefined,
  withRiskScore: false
};

export default RiskGauge;
