// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AddeparIntegration .setup {
  margin: 0 auto;
}
#AddeparIntegration .firm-subdomain {
  margin: 10px 0 10px;
  font-style: italic;
  font-family: "monospace";
  display: flex;
}
#AddeparIntegration .firm-subdomain input {
  border: none;
  border-bottom: 1px solid #999;
  text-align: center;
  font-size: 14px;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/integrations-dashboard/providers/addepar/styles.scss"],"names":[],"mappings":"AAGE;EACE,cAAA;AAFJ;AAKE;EACE,mBAAA;EACA,kBAAA;EACA,wBAAA;EACA,aAAA;AAHJ;AAKI;EACE,YAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;AAHN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#AddeparIntegration {\n  .setup {\n    margin: 0 auto;\n  }\n\n  .firm-subdomain {\n    margin: 10px 0 10px;\n    font-style: italic;\n    font-family: 'monospace';\n    display: flex;\n\n    input {\n      border: none;\n      border-bottom: 1px solid #999;\n      text-align: center;\n      font-size: 14px;\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
