import { FormGroup, VerboseErrorInput } from 'components/form';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import RolesSelect from '../roles-select';
import './styles.scss';

const DEFAULT_ROLE = 'analyst';

const validate = values => {
  const errors = {};
  errors.first_name = errors.first_name || validation.required(values.first_name);
  errors.last_name = errors.last_name || validation.required(values.last_name);
  errors.email = errors.email || validation.required(values.email);
  errors.email = errors.email || validation.email(values.email);
  errors.data__role = errors.data__role || validation.required(values.data__role);
  return errors;
};

const InviteUserModal = ({
  fields,
  handleSubmit,
  initializeForm,
  invalid,
  registerError,
  resetForm,
  submitting
}) => {
  const { invitationProvider } = useContext(AdvisorContext);

  const [isShown, setIsShown] = useState(false);

  const hide = () => {
    setIsShown(false);
    resetForm();
  };
  const show = () => setIsShown(true);

  const onChangeRoleHandler = option => {
    fields.data__role.onChange(option.value);
  };

  const onSubmit = values =>
    invitationProvider
      .create(values)
      .then(({ error }) => {
        if (error) registerError({ error });
        else {
          invitationProvider.list();
          toast.success('Your invitation has been sent');
          hide();
        }
      })
      .catch(response => {
        registerError(response);
      });

  useEffect(() => {
    initializeForm({ data__host: window.location.hostname, data__role: DEFAULT_ROLE });
  }, []);

  return (
    <>
      <button type="button" className="btn btn-primary invite-member" onClick={show}>
        Invite User
      </button>

      <Modal id="invite-user-modal" show={isShown} onHide={hide}>
        <ModalHeader />
        <ModalBody>
          <h2 className="mb-2">Invite User</h2>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
              <div className="col-sm-6">
                <FormGroup {...fields.first_name}>
                  <label htmlFor="first-name">First name</label>
                  <VerboseErrorInput type="text" className="form-control" {...fields.first_name} />
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup {...fields.last_name}>
                  <label htmlFor="last-name">Last name</label>
                  <VerboseErrorInput type="text" className="form-control" {...fields.last_name} />
                </FormGroup>
              </div>
            </div>

            <FormGroup {...fields.email}>
              <label htmlFor="email">Email address</label>
              <VerboseErrorInput type="text" className="form-control" {...fields.email} />
            </FormGroup>

            <FormGroup {...fields.data__role}>
              <label htmlFor="role">Role</label>
              <RolesSelect
                defaultValue={fields.data__role.value}
                id="invite-user-role-select"
                onChange={onChangeRoleHandler}
              />
            </FormGroup>

            <div className="text-sm-center mt-5">
              <button
                type="submit"
                className="btn btn-block btn-success"
                disabled={invalid || submitting}
              >
                Invite user
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

InviteUserModal.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'invite-user-modal',
  fields: ['first_name', 'last_name', 'email', 'data__role', 'data__host'],
  validate
})(BackendValidation(InviteUserModal));
