import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import RiskGauge from 'reports/base/charts/risk-gauge';
import { withCurrencyFormat } from 'utils/utils';
import styles from './styles';

const RiskAnalysisTargetScenario = ({ name, scenario, score, value }) => {
  const marketPerformance = {
    percentage: `${Math.round(scenario.market_performance * 10000) / 100}%`,
    sign: scenario.market_performance > 0 ? '+' : '',
    styles: scenario.market_performance > 0 ? styles.upText : styles.downText
  };
  const portfolioPerformance = {
    percentage: `${Math.round(scenario.portfolio_performance * 10000) / 100}%`,
    sign: scenario.portfolio_performance > 0 ? '+' : '',
    styles: scenario.portfolio_performance > 0 ? styles.upText : styles.downText
  };

  const extraMarginBottom = !value ? { marginBottom: 10 } : {};

  return (
    <View style={styles.scenario}>
      <Text style={styles.scenarioTitle}>{name}</Text>
      <RiskGauge width={80} height={80} risk={score} withRiskScore />
      <Text style={[styles.scenarioText, extraMarginBottom, { marginTop: 5 }]}>
        In this scenario, the market&apos;s performance was{' '}
        <Text style={marketPerformance.styles}>{`${marketPerformance.sign}${
          marketPerformance.percentage
        }`}</Text>{' '}
        and this portfolio&apos;s performance may have been{' '}
        <Text style={portfolioPerformance.styles}>{`${portfolioPerformance.sign}${
          portfolioPerformance.percentage
        }`}</Text>
      </Text>
      {value && (
        <Text style={[styles.scenarioText, styles.gainLossText]}>
          Gain/Loss:{' '}
          <Text
            style={value * scenario.portfolio_performance > 0 ? styles.upText : styles.downText}
          >
            {portfolioPerformance.sign}
            {withCurrencyFormat(value * scenario.portfolio_performance, 'standard', 0)}
          </Text>
        </Text>
      )}
    </View>
  );
};

RiskAnalysisTargetScenario.propTypes = {
  name: PropTypes.string.isRequired,
  scenario: PropTypes.object,
  score: PropTypes.number.isRequired,
  value: PropTypes.number
};

RiskAnalysisTargetScenario.defaultProps = {
  scenario: null,
  value: null
};

export default RiskAnalysisTargetScenario;
