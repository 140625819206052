import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Card = ({ children, contentStyles, header, headerStyles }) => (
  <div className="custom-card">
    {header && (
      <div className="custom-card__header" style={headerStyles}>
        {header}
      </div>
    )}
    <div className="custom-card__content" style={contentStyles}>
      {children}
    </div>
  </div>
);

Card.defaultProps = {
  children: null,
  contentStyles: {},
  header: null,
  headerStyles: {}
};

Card.propTypes = {
  children: PropTypes.node,
  contentStyles: PropTypes.object,
  header: PropTypes.node,
  headerStyles: PropTypes.object
};

export default Card;
