import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import { DEFAULT_AMOUNT, PotentialGainLossText } from '../utils';

const SingleYearOutcomeReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const defaultAmount = question.data?.default_amount || DEFAULT_AMOUNT;
  const selectedOption = question.data.labels.find(label => label.value === question.answer.value);

  return (
    <ReadOnlyQuestion question={question}>
      <p>
        <PotentialGainLossText defaultAmount={defaultAmount} option={selectedOption} />
      </p>
    </ReadOnlyQuestion>
  );
};

SingleYearOutcomeReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default SingleYearOutcomeReadOnlyQuestion;
