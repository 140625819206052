// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0de;
  overflow: hidden;
  margin: 20px 0;
  margin-bottom: 20px;
}
.bar__filler {
  height: 20px;
  background-color: #0bd318;
  transition: width 200ms ease 0s;
}`, "",{"version":3,"sources":["webpack://./src/app/components/progress-bar/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;EACA,yBAAA;EACA,+BAAA;AAEJ","sourcesContent":[".bar {\n  width: 100%;\n  height: 20px;\n  background-color: #e0e0de;\n  overflow: hidden;\n  margin: 20px 0;\n  margin-bottom: 20px;\n  &__filler {\n    height: 20px;\n    background-color: #0bd318;\n    transition: width 200ms ease 0s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
