import SectorExposureDistribution from 'components/advisor/proposal/securities/sector-exposure-distribution';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useCallback } from 'react';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';

const ProposalSectorExposure = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  proposalCharts,
  proposalType,
  setMetadata
}) => {
  const {
    proposalProvider,
    user: {
      advisor: {
        company: { sector_exposure_enabled: sectorExposureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  const onChartReady = useCallback(
    (id, data) => {
      proposalProvider.saveChartImage({ [id]: data });
    },
    [proposalCharts[SECTOR_EXPOSURE_CHART_ID]]
  );

  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata,
    isDisabled: !sectorExposureEnabled
  });

  const setCollapsedSectors = data => {
    proposalProvider.setCollapsedSectors(data);
  };

  return (
    <EditBoxText {...proposalMetadata}>
      <SectorExposureDistribution
        benchmark={proposal.benchmark}
        benchmarkName={proposal.benchmark_label}
        isDraft={proposal?.target?.is_draft}
        proposalType={proposalType}
        onChartReady={onChartReady}
        recommended={proposal.recommended}
        recommendedName={proposal.recommended_label}
        setCollapsedSectors={setCollapsedSectors}
        start={proposal.start}
        startingValue={proposal.starting_value}
        target={proposal.target}
        targetName={proposal.target_label}
      />
    </EditBoxText>
  );
};

ProposalSectorExposure.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalCharts: PropTypes.object.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalSectorExposure;
