import { StyleSheet } from '@react-pdf/renderer';
import { BASE_FONT_FAMILY, BASE_PAGE_PADDING, GRAPHITE_COLOR } from '../styles';

const styles = StyleSheet.create({
  page: {
    ...BASE_FONT_FAMILY,
    ...BASE_PAGE_PADDING,
    paddingTop: 35,
    paddingBottom: 65
  },
  headerLine: {
    position: 'absolute',
    width: '100%',
    height: 5,
    top: 15,
    marginHorizontal: 25,
    borderTopWidth: 5,
    borderTopStyle: 'solid',
    borderTopColor: 'black'
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: 'auto',
    bottom: 35,
    marginHorizontal: 25
  },
  footerImage: {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
    // https://developer.mozilla.org/en-US/docs/Web/CSS/object-position
    // object-fit: 'scale-down' allows the content to be sized as if 'none' or 'contain' were
    // specified, whichever would result in a smaller concrete object size depending on the aspect
    // ratio of the image
    objectFit: 'scale-down',
    objectPositionX: 0,
    maxWidth: 200,
    height: 35,
    marginRight: 15
  },
  footerLine: {
    flex: 1,
    height: 2,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    borderTopColor: 'black',
    position: 'relative'
  },
  pageNumber: {
    width: 24,
    marginLeft: 10,
    textAlign: 'right',
    fontSize: 12,
    color: 'grey'
  },
  footerBody: {
    width: '100%',
    fontSize: 9,
    top: 5,
    color: GRAPHITE_COLOR
  }
});

export default styles;
