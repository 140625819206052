import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const AccountTags = ({ account }) => {
  const {
    user: {
      advisor: {
        exceptions_enabled: advisorExceptionsEnabled,
        company: { exceptions_enabled: companyExceptionsEnabled }
      }
    }
  } = useContext(AdvisorContext);
  return (
    <>
      {account.held_away && <div className="account-tag">Held-Away</div>}
      {companyExceptionsEnabled && advisorExceptionsEnabled && !account.exceptions_enabled && (
        <span className="account-tag">Exceptions disabled</span>
      )}
      {(account.tags ?? []).map(t => (
        <span className="account-tag">{t}</span>
      ))}
    </>
  );
};

AccountTags.propTypes = {
  account: PropTypes.object.isRequired
};

export default AccountTags;
