import PropTypes from 'prop-types';
import React from 'react';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import './styles.scss';

const SectionSeparator = ({ question, ...props }) => (
  <Question {...props} question={question} isSectionSeparator>
    <h5>{question.question}</h5>
    <p>{question.description}</p>
  </Question>
);

SectionSeparator.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default SectionSeparator;
