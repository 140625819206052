/* global STANDARD_DATE_FORMAT */
import EditBoxText from 'components/advisor/utils/edit-box-text';
import EmbeddedDocument from 'components/advisor/utils/embedded-document';
import SignaturePlaceholder from 'components/advisor/utils/signature-placeholder';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import CoverSection from '../../sections/cover';
import FooterSection from '../../sections/footer';
import { getSectionEditorOptions } from '../editor-options';

class TemplateSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }

  handleToggleCustomAction = actionId => {
    const { propertyKey, section: oldSection, saveSection } = this.props;
    if (oldSection.customActions && oldSection.customActions[actionId]) {
      const section = {
        ...oldSection,
        customActions: {
          ...oldSection.customActions,
          [actionId]: {
            ...oldSection.customActions[actionId],
            value: !oldSection.customActions[actionId].value
          }
        }
      };
      return saveSection(propertyKey, section);
    }
    return null;
  };

  toggleCustomAction = actionId => _.partial(this.handleToggleCustomAction, actionId);

  toggleVisibility = () => {
    const { propertyKey, section: oldSection, saveSection } = this.props;
    const section = { ...oldSection, hidden: !oldSection.hidden };
    return saveSection(propertyKey, section);
  };

  toggleEditing = () => {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  };

  setBody = (
    _,
    { allowChangeText, allowEdit, allowHide, body, hidden, preparedBy, preparedFor, title, ...rest }
  ) => {
    const { propertyKey, section: oldSection, saveSection } = this.props;

    // update the section with the new content
    const section = {
      ...oldSection,
      allowChangeText,
      allowEdit,
      allowHide,
      body,
      hidden,
      preparedBy,
      preparedFor,
      title
    };

    // verify if the section has custom actions and update them if necessary
    if (rest && section.customActions)
      Object.entries(rest).forEach(([actionId, allowed]) => {
        if (section.customActions[actionId])
          section.customActions[actionId] = { ...section.customActions[actionId], allowed };
      });

    // save the section
    return saveSection(propertyKey, section);
  };

  render() {
    const {
      actionsDisabled,
      deleteSection,
      disableTitleChange,
      isCustomSection,
      propertyKey,
      section,
      showPlaceholders,
      target
    } = this.props;

    const {
      allowChangeText,
      allowEdit,
      allowHide,
      attachmentName,
      body,
      cover,
      customActions,
      footer,
      hidden,
      preparedBy,
      preparedFor,
      title,
      url
    } = section;

    const { editing } = this.state;

    const sectionEditorOptions = getSectionEditorOptions(target);
    const sectionProps = {
      actionsDisabled,
      allowChangeText,
      allowEdit,
      allowHide,
      attachmentName,
      body,
      cover,
      customActions,
      deleteSection,
      disableTitleChange,
      editing,
      footer,
      hidden,
      initialValues: { body: body || '', title },
      isCustomSection,
      options: sectionEditorOptions,
      preparedBy,
      preparedFor,
      propertyKey,
      saveChanges: this.setBody,
      target,
      title,
      toggleCustomAction: this.toggleCustomAction,
      toggleEditing: this.toggleEditing,
      toggleVisibility: this.toggleVisibility,
      url
    };

    if (showPlaceholders)
      sectionProps.placeholder = `${section.title || 'The'} ${section.type || 'information'}`;

    if (section.attachment) return <EmbeddedDocument {...sectionProps} />;

    if (section.cover) return <CoverSection {...sectionProps} />;

    if (section.footer) return <FooterSection {...sectionProps} />;

    if (section.signature) return <SignaturePlaceholder {...sectionProps} />;

    return <EditBoxText {...sectionProps} />;
  }
}

TemplateSection.defaultProps = {
  actionsDisabled: false,
  deleteSection: () => {},
  disableTitleChange: false,
  isCustomSection: false,
  showPlaceholders: false
};

TemplateSection.propTypes = {
  actionsDisabled: PropTypes.bool,
  deleteSection: PropTypes.func,
  disableTitleChange: PropTypes.bool,
  isCustomSection: PropTypes.bool,
  label: PropTypes.string.isRequired,
  propertyKey: PropTypes.string.isRequired,
  saveSection: PropTypes.func.isRequired,
  section: PropTypes.shape({
    allowChangeText: PropTypes.bool,
    allowEdit: PropTypes.bool,
    allowHide: PropTypes.bool,
    attachment: PropTypes.bool,
    attachmentName: PropTypes.string,
    body: PropTypes.string,
    cover: PropTypes.bool,
    customActions: PropTypes.objectOf(
      PropTypes.shape({
        default: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.bool
      })
    ),
    footer: PropTypes.bool,
    hidden: PropTypes.bool.isRequired,
    preparedBy: PropTypes.string,
    preparedFor: PropTypes.string,
    signature: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  showPlaceholders: PropTypes.bool,
  target: PropTypes.string.isRequired
};

export default TemplateSection;
