import AccountNotesModal from 'components/advisor/accounts/notes-modal';
import Widget from 'components/advisor/dashboard/widgets';
import useWidget, {
  DATASETS,
  widgetStorePropTypes,
  widgetStoreSelector
} from 'components/advisor/dashboard/widgets/hooks';
import ExpandArrow from 'components/svg-icons/expand-arrow';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Slider from 'react-slick';
import { EXCEPTION_STATUS } from 'utils/drift';
import AccountException from './account-exception';
import './styles.scss';

const PAGE_SIZE = 20;
const EXCEPTIONS_STATUS = [EXCEPTION_STATUS.TO_DO, EXCEPTION_STATUS.IN_PROGRESS].join('__');

const Arrow = ({ className, direction, onClick, pageAvailabilityClass }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <a className={className} onClick={onClick}>
    <ExpandArrow className={`expand-arrow-${direction} ${pageAvailabilityClass}`} />
  </a>
);

Arrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  pageAvailabilityClass: PropTypes.string.isRequired
};

Arrow.defaultProps = {
  className: '',
  onClick: () => {}
};

const DriftExceptionsWidget = ({ store }) => {
  const slider = useRef(null);
  const [slide, setSlide] = useState(0);
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);

  const fetch = async (provider, params) => {
    const response = await provider.es.listExceptions({
      ...params,
      page_size: PAGE_SIZE,
      status__terms: EXCEPTIONS_STATUS
    });
    setCount(response.count);
    return response;
  };

  const { data, empty, fetchData, dataset, loading, ...widget } = useWidget({
    fetch,
    datasets: [DATASETS.ADVISORS],
    store
  });

  const hint = (
    <div>
      By adding a note associated with an account or <br />
      client you will temporarily remove them from your alerts. Reminders will return within 6
      months if there is a change in drift or you can restore the alert from
      <br />
      <Link href="/advisor/drift-exceptions" className="go-to-snooze">
        Drift Exceptions
      </Link>
    </div>
  );

  const [showingAccountNotesModal, showAccountNotesModal] = useState(false);

  const openAccountNotesModal = () => {
    showAccountNotesModal(true);
  };

  const hideAccountNotesModal = () => showAccountNotesModal(false);

  const byAccountsWithScoreSummary = ({ prism, tolerance }) => prism && tolerance;

  const goTo = async val => {
    const newSlide = Math.min(Math.max(val, 0), count - 1);
    if (slide === newSlide) return;
    const index = newSlide % PAGE_SIZE;

    setSlide(newSlide);
    setIndex(index);

    const newPage = Math.ceil((newSlide + 1) / PAGE_SIZE);
    if (index === 0) await fetchData({ page: newPage });
    slider.current.slickGoTo(index);
  };
  const goPrev = () => {
    goTo(slide - 1);
  };
  const goNext = () => {
    goTo(slide + 1);
  };

  return (
    <Widget
      {...widget}
      empty={empty}
      hint={hint}
      dataset={dataset}
      loading={loading}
      page="/advisor/drift-exceptions"
      title="Drift Exceptions"
      id="DriftExceptionsWidget"
    >
      {!empty && (
        <>
          <span className="counter">
            {slide + 1}/{count}
          </span>

          <Arrow
            direction="left"
            pageAvailabilityClass={`page-${slide === 0 ? 'not-' : ''}available`}
            onClick={goPrev}
          />

          <Slider
            ref={slider}
            dots={false}
            infinite={false}
            draggable={false}
            lazyLoad
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
          >
            {data.filter(byAccountsWithScoreSummary).map(exception => (
              <AccountException
                key={exception.account}
                {...exception}
                openAccountNotesModal={openAccountNotesModal}
              />
            ))}
          </Slider>

          <Arrow
            direction="right"
            pageAvailabilityClass={`page-${slide === count - 1 ? 'not-' : ''}available`}
            onClick={goNext}
          />

          {showingAccountNotesModal && (
            <AccountNotesModal
              accountId={data[index].account}
              investorId={data[index].investor}
              onHide={hideAccountNotesModal}
              show
            />
          )}
        </>
      )}
    </Widget>
  );
};

DriftExceptionsWidget.propTypes = {
  store: PropTypes.shape(widgetStorePropTypes).isRequired
};

export default connect(state => ({
  store: widgetStoreSelector('esExceptions')(state)
}))(DriftExceptionsWidget);
