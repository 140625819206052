import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { withCurrencyFormat } from 'utils/utils';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useCashInvestableAssets from '../hooks';
import './styles.scss';

const CashInvestableAssetsQuestion = ({ field, question, totalAssets, ...props }) => {
  const { estimatedInvested, investmentInputProps, percentageInputProps } = useCashInvestableAssets(
    { field, question, totalAssets }
  );

  const hasPercentageError = field.percentage.dirty && field.percentage.error;

  return (
    <Question
      {...props}
      question={question}
      nextDisabled={field.investment.invalid || field.percentage.invalid}
    >
      <div>
        <label htmlFor="investment">
          <span>
            <FormattedMessage id="rtq.question-4.cash-investable-assets" />
          </span>
          <NumericFormat {...investmentInputProps} />
          <span>&nbsp;</span>
        </label>
      </div>
      <div>
        <label htmlFor="percentage">
          <span>
            <FormattedMessage id="rtq.question-4.estimated-invested" />
          </span>
          <NumericFormat {...percentageInputProps} />
          <span
            className={cn({
              'has-danger': hasPercentageError,
              'font-weight-bold': !hasPercentageError
            })}
          >
            {hasPercentageError ? (
              <FormattedMessage id="rtq.question-4.percentage-error" />
            ) : (
              withCurrencyFormat(estimatedInvested, 'standard', 0)
            )}
          </span>
        </label>
      </div>
    </Question>
  );
};

CashInvestableAssetsQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired,
  totalAssets: PropTypes.number.isRequired
};

export default CashInvestableAssetsQuestion;
