import Dropzone from 'components/dropzone';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import MinusIcon from 'components/svg-icons/minus-icon';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import './styles.scss';

const INITIAL_STEP = 1;
const UPLOAD_STEP = 2;

const ACCOUNTS_CSV_TEMPLATE_URL = '/csv/accounts.csv';
const MODEL_PORTFOLIOS_CSV_TEMPLATE_URL = '/csv/model_portfolios.csv';
const SECURITY_RETURNS_CSV_TEMPLATE_URL = '/csv/default_asset_returns.csv';

const BulkUpdateModal = ({
  investor,
  onHide,
  selectedPortfolioIds,
  show,
  startInStepTwo,
  onImportHandler,
  onExportHandler
}) => {
  const [files, setFiles] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [step, setStep] = useState(INITIAL_STEP);

  const type = (startInStepTwo && 'security') || (investor && 'accounts') || 'models';

  const onCancel = () => {
    setStep(INITIAL_STEP);
    setFiles([]);
    onHide();
  };

  const onDrop = useCallback(
    acceptedFiles => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const onRemoveFile = file => () => {
    const currentFiles = [...files];
    currentFiles.splice(currentFiles.indexOf(file), 1);
    setFiles(currentFiles);
  };

  const dropzoneFiles = files.map(file => (
    <div key={file.path} className="dropzone__file">
      <MinusIcon
        onClick={onRemoveFile(file)}
        style={{ height: 20, cursor: 'pointer' }}
        title="Remove file"
      />
      <span>{file.name}</span>
    </div>
  ));
  const hasFiles = !_.isEmpty(dropzoneFiles);

  const exportPortfoliosHandler = () => {
    onExportHandler({
      exporting,
      setExporting
    });
  };

  const importFilesHandler = () => {
    onImportHandler({
      investor,
      files,
      setImporting,
      setFiles,
      onCancel,
      onHide
    });
  };

  useEffect(() => {
    if (startInStepTwo) setStep(UPLOAD_STEP);
  }, [startInStepTwo]);

  return (
    <Modal id="bulk-update-portfolio-modal" className="modal-lg" onHidden={onHide} show={show}>
      <ModalHeader />
      <ModalBody>
        {step === INITIAL_STEP && (
          <>
            <h2 id="modal-header">How can we help?</h2>
            <div className="input-section">
              <div className="bulk-update-portfolio">
                <div className="row">
                  <span>
                    Selected {type} will be exported in a CSV format. If no {type} are selected, all{' '}
                    {type} will be exported.
                    {!_.isEmpty(selectedPortfolioIds) && (
                      <>
                        <br />
                        <b>{`${selectedPortfolioIds.length} Selected`}</b>
                      </>
                    )}
                    <i className="right-arrow" />
                  </span>
                  <LoadingButton
                    className="btn btn-primary"
                    loading={exporting}
                    disabled={exporting}
                    onClick={exportPortfoliosHandler}
                  >
                    Export {type}
                  </LoadingButton>
                </div>
                <div className="row">
                  <span>
                    Import CSV files from your computer to create or update{' '}
                    {investor ? 'accounts' : 'model portfolios'}.
                    <i className="right-arrow" />
                    <a
                      className="download-csv-link"
                      href={
                        investor ? ACCOUNTS_CSV_TEMPLATE_URL : MODEL_PORTFOLIOS_CSV_TEMPLATE_URL
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download CSV template
                    </a>
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setStep(UPLOAD_STEP);
                    }}
                  >
                    Import CSV files
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {step === UPLOAD_STEP && (
          <>
            <h2 id="modal-header">Import CSV files</h2>
            <div className="bulk-update-portfolio">
              <p className="bulk-update-portfolio__info">
                <span className="fs-icon-exclamation-circle warning-icon" /> To update your data
                correctly, please use{' '}
                <a
                  className="download-csv-link"
                  href={
                    startInStepTwo
                      ? SECURITY_RETURNS_CSV_TEMPLATE_URL
                      : MODEL_PORTFOLIOS_CSV_TEMPLATE_URL
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  our CSV template
                </a>
                .
              </p>
              <div className="dropzone" style={{ cursor: hasFiles ? 'auto' : 'pointer' }}>
                {hasFiles ? (
                  <div className="dropzone__preview">{dropzoneFiles}</div>
                ) : (
                  <Dropzone onDrop={onDrop} accept="text/csv">
                    Drag and drop or click here to upload files
                  </Dropzone>
                )}
              </div>
              <p className="bulk-update-portfolio__danger">
                CSV files with same names as existing {type} will overwrite current settings
              </p>
              <div className="bulk-update-portfolio__actions">
                <button type="button" className="btn btn-secondary-2" onClick={onCancel}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!hasFiles || importing}
                  onClick={importFilesHandler}
                >
                  {importing ? 'Importing...' : 'Import'}
                </button>
              </div>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

BulkUpdateModal.defaultProps = {
  investor: null,
  startInStepTwo: false,
  onExportHandler: () => {}
};

BulkUpdateModal.propTypes = {
  investor: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  selectedPortfolioIds: PropTypes.array.isRequired,
  onImportHandler: PropTypes.func.isRequired,
  onExportHandler: PropTypes.func,
  show: PropTypes.bool.isRequired,
  startInStepTwo: PropTypes.bool
};

export default BulkUpdateModal;
