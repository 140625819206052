/* eslint-disable no-await-in-loop, no-restricted-syntax */
import _ from 'lodash';
import { getSuggestedModels } from 'utils/utils';
import {
  accountsToSuggestions,
  portfoliosToSuggestions,
  securitiesToSuggestions
} from '../../header/utils';

export const WEIGHT_AS_AMOUNT_FIELD_WIDTH = 140;

export const MODEL_PORTFOLIO_TYPE = 'model_portfolio';
export const ACCOUNT_TYPE = 'account';
export const SECURITY_TYPE = 'security';

export const BENCHMARK_TYPE = 'benchmark';
export const RECOMMENDED_TYPE = 'recommended';
export const TARGET_TYPE = 'target';

export const ACCOUNT_OPTION = { label: 'Account', value: ACCOUNT_TYPE };
export const MODEL_PORTFOLIO_OPTION = { label: 'Model Portfolio', value: MODEL_PORTFOLIO_TYPE };
export const SECURITY_OPTION = { label: 'Security', value: SECURITY_TYPE };

export const PORTFOLIO_OPTIONS = [ACCOUNT_OPTION, MODEL_PORTFOLIO_OPTION, SECURITY_OPTION];

export const WEIGHTED_SELECT_STYLES = {
  container: styles => ({ ...styles, minWidth: 155 }),
  control: styles => ({
    ...styles,
    minHeight: 40,
    borderRadius: 0,
    boxShadow: 'none',
    border: '1px solid #ccc',
    '&:hover': {
      border: '1px solid #ccc'
    }
  }),
  menu: styles => ({ ...styles, borderRadius: 0 }),
  option: styles => ({ ...styles, fontSize: '0.9rem' }),
  placeholder: styles => ({ ...styles, fontSize: '0.9rem' }),
  singleValue: styles => ({ ...styles, fontSize: '0.9rem' })
};

export const getKey = p => (p ? `${p.type}/${p.value}` : 'empty');

export const populateSuggestions = (type, scope, setOptions) => data => {
  if (type === ACCOUNT_TYPE) setOptions(accountsToSuggestions(data.results, {}));
  if (type === MODEL_PORTFOLIO_TYPE) {
    const [proposed, suggested, other, recommendedModels] = getSuggestedModels(scope, data);
    const suggestions = [
      {
        label: 'Matching Models',
        options: portfoliosToSuggestions(suggested, MODEL_PORTFOLIO_TYPE)
      },
      {
        label: 'Proposed Models',
        options: portfoliosToSuggestions(proposed, MODEL_PORTFOLIO_TYPE)
      },
      {
        label: 'Recommended Models',
        options: portfoliosToSuggestions(recommendedModels, MODEL_PORTFOLIO_TYPE)
      },
      {
        label: 'Other Models',
        options: portfoliosToSuggestions(other, MODEL_PORTFOLIO_TYPE)
      }
    ];
    setOptions(suggestions);
  }
  if (type === SECURITY_TYPE) {
    const [benchmarks, securities] = _.partition(
      data.filter(security => Number.isFinite(security.prism_overall)),
      security => security.is_benchmark
    );
    const suggestions = [
      {
        label: 'Benchmarks',
        options: securitiesToSuggestions(benchmarks)
      },
      {
        label: 'Securities',
        options: securitiesToSuggestions(securities)
      }
    ];
    setOptions(suggestions);
  }
};

export const getAsyncPortfolioSuggestionsDefault = async (
  proposal,
  accountProvider,
  marketProvider,
  modelProvider
) => {
  const accountIds = [];
  const modelPortfolioIds = [];
  const securityIds = [];
  const suggestions = [];

  const providers = {
    [ACCOUNT_TYPE]: accountProvider,
    [MODEL_PORTFOLIO_TYPE]: modelProvider,
    [SECURITY_TYPE]: marketProvider
  };
  const sources = {
    [ACCOUNT_TYPE]: accountIds,
    [MODEL_PORTFOLIO_TYPE]: modelPortfolioIds,
    [SECURITY_TYPE]: securityIds
  };

  [BENCHMARK_TYPE, RECOMMENDED_TYPE, TARGET_TYPE]
    .filter(type => !!proposal[type])
    .forEach(type => {
      const portfolio = proposal[type];
      if (portfolio.group_details)
        portfolio.group_details.forEach(group => {
          if (group.source_type === ACCOUNT_TYPE) accountIds.push(group.id);
          else if (group.source_type === SECURITY_TYPE) securityIds.push(group.id);
          else modelPortfolioIds.push(group.id);
        });
      else if (portfolio.source_type === ACCOUNT_TYPE) accountIds.push(portfolio.id);
      else if (portfolio.source_type === SECURITY_TYPE) securityIds.push(portfolio.id);
      else modelPortfolioIds.push(portfolio.id);
    });

  for (const key of Object.keys(providers)) {
    const ids = sources[key];
    const provider = providers[key];
    const params = { ids };
    if (key === ACCOUNT_TYPE) params.all_object_types = true;
    await provider.es.list(params).then(data => {
      if (key === ACCOUNT_TYPE) suggestions.push(accountsToSuggestions(data.results, {}));
      if (key === MODEL_PORTFOLIO_TYPE)
        suggestions.push({
          label: 'Model Portfolios',
          options: portfoliosToSuggestions(data, MODEL_PORTFOLIO_TYPE)
        });
      if (key === SECURITY_TYPE)
        suggestions.push({ label: 'Securities', options: securitiesToSuggestions(data) });
    });
  }
  return suggestions;
};
