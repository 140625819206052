import {
  CUSTOM_SECURITY_CLEAR,
  CUSTOM_SECURITY_CREATE,
  CUSTOM_SECURITY_EDIT,
  CUSTOM_SECURITY_GET,
  CUSTOM_SECURITY_LIST,
  CUSTOM_SECURITY_RETURNS_BULK_SAVE,
  CUSTOM_SECURITY_RETURNS_BULK_REMOVE,
  CUSTOM_SECURITY_RETURNS_CLEAR,
  CUSTOM_SECURITY_RETURNS_CREATE,
  CUSTOM_SECURITY_RETURNS_GET,
  CUSTOM_SECURITY_SAVE_CURRENT,
  CUSTOM_SECURITY_UNDERLYING_HOLDINGS_CLEAR,
  CUSTOM_SECURITY_RETURNS_BULK_NAME_SUGGESTIONS,
  CUSTOM_SECURITY_UNDERLYING_HOLDINGS_GET,
  CUSTOM_SECURITY_SEARCH
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class CustomSecurityProvider extends DataProvider {
  edit(id, security) {
    return this.provider.patch(`${config.apiBase}securities/custom/${id}/`, security).then(data => {
      if (!data.error) this.dispatch(CUSTOM_SECURITY_EDIT, { data });
      return data;
    });
  }

  list(params = {}) {
    return this.provider
      .get(`${config.apiBase}securities/custom/`, params)
      .then(({ data, error }) => {
        if (!error)
          this.dispatch(CUSTOM_SECURITY_LIST, {
            data: data.results || [],
            meta: { params, count: data.count, totalPages: data.total_pages }
          });
        else this.dispatch(CUSTOM_SECURITY_LIST, { data: { data: [], meta: {} } });
        return data;
      });
  }

  customSecuritySearch(search) {
    const params = search ? { search } : {};
    return this.provider
      .get(`${config.apiBase}securities/custom/`, params)
      .then(({ data, error }) => {
        if (!error)
          this.dispatch(CUSTOM_SECURITY_SEARCH, {
            data: params.search ? data.results : []
          });
        else
          this.dispatch(CUSTOM_SECURITY_SEARCH, {
            data: null
          });
        return data;
      });
  }

  get(id) {
    return this.provider.get(`${config.apiBase}securities/custom/${id}/`).then(data => {
      if (!data.error)
        this.dispatch(CUSTOM_SECURITY_GET, {
          data: data.data
        });
      return data;
    });
  }

  create(security) {
    return this.provider.post(`${config.apiBase}securities/custom/`, security).then(data => {
      if (!data.error) this.dispatch(CUSTOM_SECURITY_CREATE, { data: data.data });
      return data;
    });
  }

  returnsPrism(id) {
    return this.provider
      .get(`${config.apiBase}securities/custom/${id}/returns_prism/`)
      .then(data => {
        if (!data.error)
          this.dispatch(CUSTOM_SECURITY_RETURNS_GET, {
            data: data.data
          });
        return data;
      });
  }

  uploadSecurityReturnsFileCSV(id, formData) {
    return this.provider.postFormData(
      `${config.apiBase}securities/custom/${id}/returns/`,
      formData
    );
  }

  saveSecurity(data) {
    return this.dispatch(CUSTOM_SECURITY_SAVE_CURRENT, {
      data
    });
  }

  clear() {
    return this.dispatch(CUSTOM_SECURITY_CLEAR);
  }

  clearReturns() {
    return this.dispatch(CUSTOM_SECURITY_RETURNS_CLEAR);
  }

  saveSecurityReturns(data) {
    return this.dispatch(CUSTOM_SECURITY_RETURNS_GET, {
      data
    });
  }

  saveSecurityReturnsBackbutton(data) {
    return this.dispatch(CUSTOM_SECURITY_RETURNS_CREATE, {
      data
    });
  }

  getUnderlyingHoldings(id) {
    return this.provider
      .get(`${config.apiBase}securities/custom/${id}/underlying_holdings/`)
      .then(data => {
        if (!data.error)
          this.dispatch(CUSTOM_SECURITY_UNDERLYING_HOLDINGS_GET, {
            data: data.data
          });
        return data;
      });
  }

  clearUnderlyingHoldings() {
    return this.dispatch(CUSTOM_SECURITY_UNDERLYING_HOLDINGS_CLEAR);
  }

  createUnderlyingHoldings(securityId, positions) {
    return this.provider.patch(
      `${config.apiBase}securities/custom/${securityId}/underlying_holdings/`,
      positions
    );
  }

  saveBulkReturnsCSV(files) {
    return this.dispatch(CUSTOM_SECURITY_RETURNS_BULK_SAVE, {
      files
    });
  }

  removeBulkReturnsCSV(files) {
    return this.dispatch(CUSTOM_SECURITY_RETURNS_BULK_REMOVE, {
      files
    });
  }

  checkBulkFileCSVName(files) {
    return this.provider
      .post(`${config.apiBase}securities/custom/check_files/`, files)
      .then(data => {
        if (!data.error)
          this.dispatch(CUSTOM_SECURITY_RETURNS_BULK_NAME_SUGGESTIONS, {
            data: data.data
          });
        return data;
      });
  }

  importBulkFilesCSV(files) {
    return this.provider.postFormData(`${config.apiBase}securities/custom/returns/bulk/`, files);
  }
}

export default CustomSecurityProvider;
