import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import Tooltip, { Directions } from 'components/tooltip';
import AutosizeInput from 'components/autosize-input';
import './styles.scss';

const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

const LabelEdit = forwardRef(
  (
    {
      disable,
      inputOnChange,
      inputValue,
      label,
      labelOnClick,
      labelOnKeyUp,
      onCancel,
      onSave,
      toolTipDirection,
      toolTipText
    },
    ref
  ) => {
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
      setEditMode(false);
    }, [disable]);

    const handleLabelOnClick = e => {
      if (!disable) {
        setEditMode(true);
        labelOnClick(e);
      }
    };

    const handleLabelOnKeyUp = e => {
      if (e.key === ENTER_KEY) setEditMode(true);
      labelOnKeyUp(e);
    };

    const handleOnCancel = e => {
      if (inputValue) {
        onCancel(e);
        setEditMode(false);
      }
    };

    const handleOnSave = e => {
      if (inputValue) {
        onSave(inputValue);
        setEditMode(false);
      }
    };

    const handleInputOnKeyUp = e => {
      if ([ENTER_KEY, ESCAPE_KEY].includes(e.key)) handleOnSave(e);
    };

    useImperativeHandle(ref, () => ({
      edit: () => {
        setEditMode(true);
      }
    }));

    return (
      <div className="label-edit">
        <Tooltip text={toolTipText} direction={toolTipDirection}>
          {!editMode && (
            <span className="nickname-edit">
              <div className="flexible-container">
                <span
                  tabIndex={0}
                  role="button"
                  className={cs('nickname', { 'disable-edit': disable })}
                  onClick={handleLabelOnClick}
                  onKeyUp={handleLabelOnKeyUp}
                >
                  {label}
                </span>
                <div className="underline" />
              </div>
              {!disable && (
                <button type="button" className="btn-transparent" onClick={() => setEditMode(true)}>
                  <img className="icon edit-icon" src="/img/edit_icon_blue.svg" alt="edit" />
                </button>
              )}
            </span>
          )}
          {editMode && (
            <span>
              <div className="flexible-container">
                <AutosizeInput
                  autoFocus
                  name="nickname"
                  minWidth="110px"
                  value={inputValue}
                  onChange={inputOnChange}
                  onKeyUp={handleInputOnKeyUp}
                  className="autosizeInput-nickname"
                />
                <div className="underline" />
              </div>
              <button type="button" className="btn-save btn-transparent" onClick={handleOnSave}>
                <img className="icon" src="/img/checkmark_filled_blue.svg" alt="save" />
              </button>
              <button
                type="button"
                className="btn-discard btn-transparent"
                onClick={handleOnCancel}
              >
                <img className="icon" src="/img/cancel_round.svg" alt="discard" />
              </button>
            </span>
          )}
        </Tooltip>
      </div>
    );
  }
);

LabelEdit.defaultProps = {
  disable: false,
  inputValue: '',
  label: '',
  labelOnClick: () => {},
  labelOnKeyUp: () => {},
  onCancel: () => {},
  onSave: () => {},
  inputOnChange: () => {},
  inputOnKeyUp: () => {},
  toolTipDirection: Directions.TOP,
  toolTipText: ''
};

LabelEdit.propTypes = {
  disable: PropTypes.bool,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  labelOnClick: PropTypes.func,
  labelOnKeyUp: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  inputOnChange: PropTypes.func,
  inputOnKeyUp: PropTypes.func,
  toolTipDirection: PropTypes.string,
  toolTipText: PropTypes.string
};

export default LabelEdit;
