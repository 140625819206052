// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-btn {
  position: relative;
  align-content: center;
}
.referral-btn button {
  width: 172px;
  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  border-radius: 3px;
  background-color: #615e83;
  color: white;
}
.referral-btn button img {
  pointer-events: none;
}
.referral-btn .referral_popover {
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1;
  width: 315px;
  border: 1px solid lightgray;
}
.referral-btn .referral_popover__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.9rem;
  text-align: center;
}
.referral-btn .referral_popover__title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.referral-btn:hover .referral_popover {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/referral-btn/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;AACJ;AACI;EACE,oBAAA;AACN;AAGE;EACE,aAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,uBAAA;EACA,kBAAA;EACA,uCAAA;EACA,aAAA;EACA,UAAA;EACA,YAAA;EACA,2BAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AADN;AAII;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAFN;AAOI;EACE,cAAA;AALN","sourcesContent":[".referral-btn {\n  position: relative;\n  align-content: center;\n\n  button {\n    width: 172px;\n    height: 30px;\n    flex-grow: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    padding: 10px 25px;\n    border-radius: 3px;\n    background-color: #615e83;\n    color: white;\n\n    img {\n      pointer-events: none;\n    }\n  }\n\n  .referral_popover {\n    display: none;\n    position: absolute;\n    top: 40px;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: white;\n    border-radius: 3px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    padding: 10px;\n    z-index: 1;\n    width: 315px;\n    border: 1px solid lightgray;\n\n    &__content {\n      display: flex;\n      flex-direction: column;\n      gap: 10px;\n      font-size: 0.9rem;\n      text-align: center;\n    }\n\n    &__title {\n      font-size: 1rem;\n      font-weight: 600;\n      text-align: center;\n    }\n  }\n\n  &:hover {\n    .referral_popover {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
