import React from 'react';
import { EXCEPTION_STATUS } from 'utils/drift';
import FilterContainer from '../common/filter-container';
import MultiSelectFilter from '../common/multi-select';
import { EXCEPTION_STATUS_TERMS_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const options = [
  { label: 'To Do', value: EXCEPTION_STATUS.TO_DO },
  { label: 'In Progress', value: EXCEPTION_STATUS.IN_PROGRESS },
  { label: 'Snoozed', value: EXCEPTION_STATUS.SNOOZED },
  { label: 'Resolved', value: EXCEPTION_STATUS.RESOLVED },
  { label: 'Closed', value: EXCEPTION_STATUS.CLOSED }
];

const ExceptionStatusFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const getDefaultValue = () => {
    if (!filters[EXCEPTION_STATUS_TERMS_ATTR]) return [];
    const params = new URLSearchParams(
      `${EXCEPTION_STATUS_TERMS_ATTR}=${filters[EXCEPTION_STATUS_TERMS_ATTR]}`
    );
    return params.get(EXCEPTION_STATUS_TERMS_ATTR).split('__');
  };

  const defaultValue = getDefaultValue();

  const onChangeHandler = options => {
    const selected = options.map(option => option.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [EXCEPTION_STATUS_TERMS_ATTR]: selected.length ? selected.join('__') : undefined
    }));
    setRequiresPageIndexReset(true);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_STATUS_TERMS_ATTR]} filters={filters} label="Status">
      <MultiSelectFilter
        defaultOptions={options.filter(option => defaultValue.includes(option.value))}
        onChange={onChangeHandler}
        options={options}
        placeholder="View All"
      />
    </FilterContainer>
  );
};

ExceptionStatusFilter.propTypes = { ...filterPropTypes };

ExceptionStatusFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionStatusFilter;
