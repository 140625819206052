import { Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ScoreBubble from 'reports/base/charts/score-bubble';
import BaseSection from 'reports/base/sections/base';
import Row from 'reports/base/table';
import { withCurrencyFormat } from 'utils/utils';
import { MODEL_TARGET_TYPE } from '../../../constants';
import styles from './styles';

const TopRiskAttribution = ({ positions, riskyPositions, type }) => {
  const header = ['Asset', 'Portfolio %', 'Portfolio Value', 'PRISM', 'Risk Attribution'];
  // removes the 'Portfolio Value' option as it's a Model Portfolio
  if (type === MODEL_TARGET_TYPE) delete header[2];

  const processedRiskyPositions = riskyPositions.map(riskyPosition => {
    const position = positions.find(p => p.ticker === riskyPosition.ticker);
    if (position)
      return {
        ...riskyPosition,
        prismScoreSummary: position.prism_score_summary,
        value: position.value
      };
    return riskyPosition;
  });

  if (isEmpty(processedRiskyPositions)) return null;

  return (
    <BaseSection title="Top Risk Attributions">
      <Row elements={header} cellStyle={styles.headerCell} />
      {processedRiskyPositions.map(position => {
        const content = [
          <Text style={styles.text}>{position.ticker_name || position.ticker}</Text>,
          <Text style={styles.text}>{position.weight.toFixed(2)}%</Text>,
          position.value ? (
            <Text style={styles.text}>{withCurrencyFormat(position.value)}</Text>
          ) : (
            <Text style={styles.text}>-</Text>
          ),
          position?.prismScoreSummary?.overall ? (
            <View style={styles.score}>
              <ScoreBubble score={position.prismScoreSummary.overall} />
            </View>
          ) : (
            <Text style={styles.text}>-</Text>
          ),
          position.risk ? (
            <View style={styles.barContainer}>
              <Text style={styles.barPercent}>{(position.risk * 100).toFixed(2)}%</Text>
              <View style={styles.bar}>
                <View style={[styles.barWeight, { width: `${position.risk * 100}%` }]} />
              </View>
            </View>
          ) : (
            <Text style={styles.text}>-</Text>
          )
        ];
        // removes the 'Portfolio Value' option as it's a Model Portfolio
        if (type === MODEL_TARGET_TYPE) delete content[2];
        return (
          <Row
            key={`${position.ticker}-${position?.account?.id}`}
            elements={content}
            withCustomElements
          />
        );
      })}
    </BaseSection>
  );
};

TopRiskAttribution.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      ticker: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  riskyPositions: PropTypes.arrayOf(
    PropTypes.shape({
      risk: PropTypes.number,
      ticker: PropTypes.string,
      ticker_name: PropTypes.string,
      weight: PropTypes.number
    })
  ).isRequired,
  type: PropTypes.string.isRequired
};

TopRiskAttribution.defaultProps = {
  positions: null
};

export default TopRiskAttribution;
