import { VerboseErrorInput as Input } from 'components/form';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { advisorStatsSelector } from 'selectors/advisors';
import UserDetails from './details';
import './styles.scss';

const DeactivateUserModal = ({ advisor, refreshUsers, stats }) => {
  const { advisorProvider } = useContext(AdvisorContext);

  const [advisorNameVerification, setAdvisorNameVerification] = useState('');
  const [deactivating, setDeactivating] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const name = `${advisor.user.first_name} ${advisor.user.last_name}`;
  const disableDeactivate =
    advisorNameVerification.toLocaleLowerCase() !== name.toLocaleLowerCase();
  const hasStats = Object.values(stats).some(stat => stat > 0);

  const onChangeAdvisorNameVerificationHandler = event => {
    const { value } = event.target;
    setAdvisorNameVerification(value);
  };

  const onDeactivateHandler = () => {
    setDeactivating(true);
    advisorProvider
      .delete(advisor)
      .then(async () => {
        toast.success(() => (
          <div className="deactivate-advisor-notification">
            We have received your request to delete{' '}
            <b>
              {advisor.user.first_name} {advisor.user.last_name}
            </b>
            <br />
            We are currently processing and securely archiving all related information. This may
            take a few minutes.
            <br />
            Please feel free to continue using Stratifi while we complete this task.
          </div>
        ));
        await refreshUsers();
        hide();
      })
      .finally(() => {
        setDeactivating(false);
      });
  };

  return (
    <>
      <button aria-label="Remove User" type="button" onClick={show}>
        <TrashCanIcon className="trash-icon" title="Delete Advisor" />
      </button>

      <Modal id="deactivate-user-modal" className="modal-lg" show={isShown} onHidden={hide}>
        <ModalHeader />
        <ModalBody>
          <h3 className="modal-title">Are you sure you want to deactivate this advisor?</h3>
          <p className="description top">
            <b>!</b>
            {name}
          </p>

          {hasStats && (
            <div className="advisor-stats">
              <p className="text-center">
                By deactivating this advisor the following information will be archived:
              </p>
              <UserDetails advisorId={advisor.id} stats={stats} />
              <p className="text-center advisor-stats__review-description">
                Open the links above to review the information that will be archived. If you wish to
                retain any of this information, please transfer it to another advisor.{' '}
                {process.env.HELP_MOVING_DATA_PAGE_URL && (
                  <>
                    Learn how to do this{' '}
                    <a
                      href={process.env.HELP_MOVING_DATA_PAGE_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </>
                )}
              </p>
            </div>
          )}

          <p className="description bottom danger">You can&apos;t revert this change</p>

          <div className="advisor-name">
            <p className="text-center">
              To confirm deactivation, please re-type the advisor&apos;s name below and click
              &lsquo;Deactivate&rsquo;
            </p>
            <Input
              className="form-control advisor-name__input"
              name="advisor-name-verification"
              onChange={onChangeAdvisorNameVerificationHandler}
              placeholder="Type advisor's name here"
              type="text"
              value={advisorNameVerification}
            />
          </div>

          <div className="text-sm-center actions">
            <button type="button" className="btn cancel" onClick={hide}>
              Cancel
            </button>
            <LoadingButton
              className="btn btn-danger delete"
              disabled={disableDeactivate}
              loading={deactivating}
              onClick={onDeactivateHandler}
            >
              Deactivate
            </LoadingButton>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

DeactivateUserModal.propTypes = {
  advisor: PropTypes.object.isRequired,
  refreshUsers: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired
};

export default connect((state, { advisor }) => ({
  stats: advisorStatsSelector(state, advisor.id)
}))(DeactivateUserModal);
