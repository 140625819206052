import SectionLocked from 'components/advisor/section-locked';
import { SECTION_LOCKED_MAP, SECTOR_EXPOSURE } from 'components/advisor/section-locked/utils';
import HorizontalBarChart from 'components/charts/horizontal-bar-chart';
import CollapsibleTable from 'components/collapsible-table';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import DisclaimerNote from '../common/disclaimer-note';
import { SECURITY_SECTORS, getSecurityDetails, processSecurityDetails } from '../common/utils';
import './styles.scss';
import { SECTOR_EXPOSURE_CHART_ID } from './utils';

const SectorExposure = ({ hiddenVal, id, onChartReady, portfolio, width }) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          sector_exposure_enabled: sectorExposureEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const { name, portfolioRiskId } = SECTION_LOCKED_MAP[SECTOR_EXPOSURE];

  if (!sectorExposureEnabled)
    return (
      <div id="sector-exposure">
        <SectionLocked id={portfolioRiskId} name={name} />
      </div>
    );

  const totalAssets = portfolio.value ?? 0;
  const securitySectors = getSecurityDetails(portfolio, SECURITY_SECTORS, totalAssets);

  const { data, total: totalSecurities } = processSecurityDetails(
    securitySectors,
    SECURITY_SECTORS,
    totalAssets
  );

  return (
    <div id="sector-exposure">
      <div className="chart-container">
        <HorizontalBarChart id={id} data={data.chart} onChartReady={onChartReady} width={width} />
      </div>
      <div className="collapsible-container">
        <CollapsibleTable
          bottomNameLabel="Total portfolio value"
          data={data.summary}
          expandAssetClassification={expandAssetDetails}
          headingLabels={{ name: '', value: 'Value', percent: '% of Portfolio' }}
          hiddenVal={hiddenVal}
          showBottomSection
          showHeadingRow
          showPercent
          expandAll
        />
        <DisclaimerNote totalAssets={totalAssets} totalSecurities={totalSecurities} />
      </div>
    </div>
  );
};

SectorExposure.propTypes = {
  hiddenVal: PropTypes.bool,
  id: PropTypes.string,
  onChartReady: PropTypes.func,
  portfolio: PropTypes.object.isRequired,
  width: PropTypes.string
};

SectorExposure.defaultProps = {
  hiddenVal: false,
  id: SECTOR_EXPOSURE_CHART_ID,
  onChartReady: null,
  width: '100%'
};

export default SectorExposure;
