import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import { OTHER_VALUE } from '../constants';

const GoalApproachQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  const withOtherValue = answer => choice =>
    choice.value === OTHER_VALUE ? `${choice.value} (${answer})` : choice.value;

  const goalSelection = question.data?.goals
    .filter(choice => question.answer.goal.includes(choice.value))
    .map(withOtherValue(question.answer.other_goal))
    .join(', ');
  const approachSelection = question.data?.approaches
    .filter(choice => question.answer.approach.includes(choice.value))
    .map(withOtherValue(question.answer.other_approach))
    .join(', ');

  return (
    <ReportQuestion question={question}>
      <Text>Goal: {goalSelection}</Text>
      <Text>Approach: {approachSelection}</Text>
    </ReportQuestion>
  );
};

GoalApproachQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default GoalApproachQuestion;
