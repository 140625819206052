/* global AmCharts */
/* eslint-disable no-underscore-dangle */
import config from 'app/config';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Chart extends PureComponent {
  constructor(props) {
    super(props);

    const randomHash = Math.random()
      .toString(36)
      .substring(2);

    this.chartId = props.id ? props.id : `chart-${randomHash}`;
    this.options = {};
  }

  componentDidMount() {
    const options = this.getOptions();
    const listeners = this.getListeners();
    const data = this.getData(options);
    const { fullWidth } = this.props;

    if (!this.options[this.chartId]) this.options[this.chartId] = _.cloneDeep(options);

    const config = {
      ...this.options[this.chartId],
      hideCredits: true,
      dataProvider: data,
      export: {
        enabled: true,
        // Enable the export module with an empty array menu attribute to avoid
        // generating a new download menu option for each chart in the report
        // https://github.com/amcharts/amcharts3/tree/master/amcharts/plugins/export#plugin-api
        menu: [],
        // Generates a PNG image of the chart when ready
        // https://github.com/amcharts/amcharts3/tree/master/amcharts/plugins/export#events
        onReady: this.onChartReady
      }
    };

    // set chart
    const chart = AmCharts.makeChart(this.chartId, config);
    this._chart = chart;

    // set chart event listeners
    Object.keys(listeners).forEach(prop => {
      chart.addListener(prop, listeners[prop]);
    });

    // set chart guides
    this.renderGuides(options, this.props);

    if (fullWidth) {
      if (window.matchMedia) {
        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(mql => {
          if (mql.matches) this.beforePrint();
          else this.afterPrint();
        });
      }
      window.onbeforeprint = this.beforePrint;
      window.onafterprint = this.afterPrint;
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      const options = this.getOptions(this.props);

      this._chart.dataProvider = this.getData(options, this.props);
      this._chart.validateNow();
      this._chart.validateData();

      this.renderGuides(options, this.props);
    }
  }

  componentWillUnmount() {
    this._chart.clear();
  }

  beforePrint = () => {
    const { printWidth } = this.props;
    this._chart.div.style.width = printWidth || '700px';
    this._chart.validateNow();
  };

  afterPrint = () => {
    this._chart.div.style.width = '100%';
    this._chart.validateNow();
  };

  onChartReady = (type, timedout) => {
    const { onChartReady } = this.props;
    if (onChartReady && type === 'fabric' && !timedout)
      this._chart?.export?.capture({}, () => {
        this._chart.export.toPNG({ multiplier: 2 }, data => {
          onChartReady(this.chartId, data);
        });
      });
  };

  getData(options, newProps) {
    const props = newProps || this.props;
    return props.data;
  }

  // eslint-disable-next-line class-methods-use-this
  getGuides = (options, newProps) => {
    const guides = newProps.guides || [];
    return guides;
  };

  getOptions(newProps) {
    const props = newProps || this.props;
    // eslint-disable-next-line prefer-object-spread
    return Object.assign({}, _.cloneDeep(config.chart.common.default), _.cloneDeep(props.options));
  }

  getListeners() {
    const { listeners } = this.props;
    return { ...listeners };
  }

  renderGuides(options, newProps) {
    if (this._chart.categoryAxis) {
      this._chart.categoryAxis.guides = this.getGuides(options, newProps);
      this._chart.validateNow();
    }
  }

  render() {
    const { className, style } = this.props;
    return (
      <div id={this.chartId} className={cn('chart', this.className, className)} style={style} />
    );
  }
}

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  listeners: PropTypes.array,
  onChartReady: PropTypes.func,
  options: PropTypes.object,
  printWidth: PropTypes.string,
  style: PropTypes.object
};

Chart.defaultProps = {
  className: '',
  fullWidth: false,
  id: null,
  listeners: [],
  onChartReady: null,
  options: {},
  printWidth: '',
  style: {}
};

export default Chart;
