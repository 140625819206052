/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import MoveFromAdvisor, { TYPE } from 'components/advisor/move-from-advisor';
import DynamicTable from 'containers/table/dynamic';
import DynamicTableActionButton from 'containers/table/dynamic/action-button';
import AccountDriftLevelFilter from 'containers/table/dynamic/filters/account/drift-level';
import AccountDriftOverallFilter from 'containers/table/dynamic/filters/account/drift-overall';
import AccountPrismOverallFilter from 'containers/table/dynamic/filters/account/prism-overall';
import AccountRiskLevelFilter from 'containers/table/dynamic/filters/account/risk-level';
import AccountTotalFilter from 'containers/table/dynamic/filters/account/total';
import PrismFactorFilter from 'containers/table/dynamic/filters/common/prism-factor';
import {
  getPrismFactorAggregatedSortingId,
  getPrismFactorName,
  OVERALL_FACTOR_ATTR
} from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import HouseholdDriftLevelFilter from 'containers/table/dynamic/filters/household/drift-level';
import HouseholdDriftOverallFilter from 'containers/table/dynamic/filters/household/drift-overall';
import HouseholdPrismOverallFilter from 'containers/table/dynamic/filters/household/prism-overall';
import HouseholdRiskLevelFilter from 'containers/table/dynamic/filters/household/risk-level';
import HouseholdTotalFilter from 'containers/table/dynamic/filters/household/total';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { triggerAccountPrismScore } from 'utils/prism';
import { getAdvisorIdFromSearchParams } from 'utils/utils';
import HouseholdDriftCell from './cell/household-drift';
import HouseholdNameCell from './cell/household-name';
import HouseholdPrismCell from './cell/household-prism';
import HouseholdRiskToleranceCell from './cell/household-risk-tolerance';
import HouseholdAccountsRow from './row/household-accounts';
import './styles.scss';

class HouseholdsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { moveHouseholdsModal: { show: false, selectedHouseholds: [] } };
  }

  getPrismScore = accountId => {
    const { accountProvider } = this.context;
    triggerAccountPrismScore(accountId, accountProvider);
  };

  deleteSelectedHouseholds = async items => {
    const { householdProvider } = this.context;

    const promisesArray = [];
    items.forEach(id => {
      promisesArray.push(householdProvider.delete(id));
    });

    return Promise.all(promisesArray).then(() => {
      toast.success('Household(s) removed successfully');
      householdProvider.updateSelectedId([]);
      this.refreshList();
    });
  };

  refreshList = params => {
    const { getList, meta } = this.props;
    return getList({ ...(meta?.params || {}), ...params });
  };

  onSelectItems = ids => {
    const { householdProvider } = this.context;
    return householdProvider.updateSelectedId(ids);
  };

  onSelectHouseholdRiskToleranceHandler = ({ accounts }) => {
    const { showTargetScoreWizard } = this.context;
    const investors = _.uniqBy(
      accounts.map(a => a.investor),
      'id'
    ).map(investor => ({
      ...investor,
      accounts: accounts.filter(account => account.investor.id === investor.id)
    }));
    if (investors && investors.length)
      showTargetScoreWizard({ investors, refresh: this.refreshList });
  };

  onSelectAccountRiskToleranceHandler = account => {
    const { showTargetScoreWizard } = this.context;
    showTargetScoreWizard({
      investors: [{ ...account.investor, accounts: [account] }],
      refresh: this.refreshList
    });
  };

  moveHouseholds = selectedAdvisor => {
    const { advisorProvider, householdProvider, user } = this.context;
    const { selectedHouseholds } = this.props;

    return advisorProvider
      .moveHousehold(user.advisor.id, selectedHouseholds, selectedAdvisor)
      .then(() => {
        this.setState({ moveHouseholdsModal: { show: false } });
        householdProvider.updateSelectedId([]);
        this.refreshList();
      });
  };

  toggleMoveAdvisorModal = () => {
    this.setState(prevState => ({
      moveHouseholdsModal: { show: !prevState.moveHouseholdsModal.show }
    }));
  };

  toggleMoveAdvisorModalHandler = () => {
    this.toggleMoveAdvisorModal();
  };

  getInitialParams = () => {
    const { advisorByManager, authProvider } = this.context;
    const { selectedTeam } = this.props;

    const advisorId = getAdvisorIdFromSearchParams(authProvider);
    const initialParams = { ordering: ['name'] };

    if (advisorByManager !== 0) initialParams.by_advisor = advisorByManager;
    if (advisorId) initialParams.by_advisor = advisorId;
    if (selectedTeam !== 0) initialParams.team = selectedTeam;

    return initialParams;
  };

  render() {
    const { getList, households, meta, selectedHouseholdIds, selectedHouseholds } = this.props;
    const { moveHouseholdsModal } = this.state;
    const { authProvider, user } = this.context;

    const initialParams = this.getInitialParams();

    const prismFactorAttr = meta?.params?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
    const prismFactorName = getPrismFactorName(prismFactorAttr);
    const prismFactorAggregatedSortingId = getPrismFactorAggregatedSortingId(prismFactorAttr);

    const columns = [
      {
        accessorFn: row => row.name,
        id: 'name',
        name: 'Household',
        cell: household => <HouseholdNameCell household={household} />,
        meta: {
          headerStyle: () => ({ width: 300 })
        }
      },
      {
        accessorFn: row => row.investors.length || '-',
        id: 'total_clients',
        name: 'Clients',
        meta: { className: () => 'text-center' },
        enableSorting: false
      },
      {
        accessorFn: row => row.accounts.length || '-',
        id: 'total_accounts',
        name: 'Accounts',
        meta: { className: () => 'text-center' },
        enableSorting: false
      },
      {
        accessorFn: row => row.portfolio_value || 0,
        id: 'portfolio_value',
        name: 'Portfolio Value',
        cell: household => {
          if (household.portfolio_value)
            return <FormattedNumber value={household.portfolio_value} format="currency" />;
          return '-';
        },
        meta: { className: () => 'text-center', style: () => ({ width: 185 }) },
        enableSorting: false
      },
      {
        accessorFn: row => row.aggregated_prism_scores?.[prismFactorAttr] ?? null,
        id: prismFactorAggregatedSortingId,
        name: prismFactorName ? `PRISM (${prismFactorName})` : 'PRISM',
        cell: household => <HouseholdPrismCell household={household} meta={meta} />,
        meta: { className: () => 'text-center td-prism', style: () => ({ width: 120 }) }
      },
      {
        accessorFn: row => row.aggregated_target_scores?.overall ?? null,
        id: 'aggregated_target_scores__overall',
        name: 'Risk Tolerance',
        cell: household => (
          <HouseholdRiskToleranceCell
            household={household}
            onSelectRiskToleranceHandler={this.onSelectHouseholdRiskToleranceHandler}
          />
        ),
        meta: { className: () => 'text-center', style: () => ({ width: 130 }) },
        enableSorting: false
      },
      {
        accessorFn: row => row.drift_summary?.overall ?? null,
        id: 'drift',
        name: 'Drift',
        cell: household => <HouseholdDriftCell household={household} />,
        meta: {
          className: ({ row }) =>
            cn('text-center', 'td-drift', { 'td-drift--red': !row?.original?.is_healthy }),
          style: () => ({ width: 75 })
        },
        enableSorting: false
      }
    ];

    const headerSelectionActions = (
      <div className="actions">
        {authProvider.hasManagerPermissions(user) && !!selectedHouseholdIds.length && (
          <DynamicTableActionButton
            iconId="arrow-right-arrow-left-solid"
            label="Move to another advisor"
            onClick={this.toggleMoveAdvisorModalHandler}
          />
        )}
      </div>
    );

    return (
      <div id="household-table-container">
        <MoveFromAdvisor
          isOpen={moveHouseholdsModal.show}
          onMove={this.moveHouseholds}
          onRequestClose={this.toggleMoveAdvisorModal}
          selectedElement={selectedHouseholds}
          type={TYPE.household}
        />

        <DynamicTable
          columns={columns}
          data={households.map(household => ({ ...household, subRows: household.accounts }))}
          enableExpanding
          enableRowSelection
          filterComponents={[
            [PrismFactorFilter],
            [
              HouseholdTotalFilter,
              HouseholdRiskLevelFilter,
              HouseholdDriftLevelFilter,
              HouseholdPrismOverallFilter,
              HouseholdDriftOverallFilter
            ],
            [
              AccountTotalFilter,
              AccountRiskLevelFilter,
              AccountDriftLevelFilter,
              AccountPrismOverallFilter,
              AccountDriftOverallFilter
            ]
          ]}
          headerSelectionActions={headerSelectionActions}
          initialParams={initialParams}
          label="Household"
          meta={meta}
          onDeleteSelectedItems={this.deleteSelectedHouseholds}
          onFetchData={getList}
          onSelectItems={this.onSelectItems}
          selectedItemIds={selectedHouseholdIds}
          subComponent={HouseholdAccountsRow}
          subComponentProps={{
            getPrismScore: this.getPrismScore,
            meta,
            onSelectAccountRiskToleranceHandler: this.onSelectAccountRiskToleranceHandler
          }}
        />
      </div>
    );
  }
}

HouseholdsTable.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  advisorByManager: PropTypes.number.isRequired,
  advisorProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

HouseholdsTable.propTypes = {
  getList: PropTypes.func.isRequired,
  householdProvider: PropTypes.object.isRequired,
  households: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  selectedHouseholdIds: PropTypes.array.isRequired,
  selectedHouseholds: PropTypes.array.isRequired,
  selectedTeam: PropTypes.number
};

HouseholdsTable.defaultProps = {
  selectedTeam: 0
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state),
  selectedTeam: state.teams.selected.value
}))(HouseholdsTable);
