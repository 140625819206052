import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const CashInvestableAssetsQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  return (
    <ReportQuestion question={question}>
      <View>
        <Text>Cash and investable assets: {formatMoney(question.answer.investment)}</Text>
      </View>
      <View>
        <Text>
          Estimated invested:{' '}
          {question.answer.percentage
            ? `${formatPercentage(question.answer.percentage, 100)} (${formatMoney(
                question.answer.investment * question.answer.percentage
              )})`
            : '-'}
        </Text>
      </View>
    </ReportQuestion>
  );
};

CashInvestableAssetsQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default CashInvestableAssetsQuestion;
