import { VerboseErrorInput } from 'components/form';
import InlineLabeledInput from 'components/form/inline-labeled-input';
import QuillField from 'components/utils/wysiwyg';
import DEFAULT_TEMPLATES from 'containers/advisor/templates/defaults';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { emailEditorOptions, validateMessage } from './editor-options';
import './styles.scss';

const SIZES = { label: 2, input: 10 };

const validate = values => {
  const errors = {};
  errors.message = errors.message || validateMessage(values.message);
  return errors;
};

const EmailTemplate = ({
  content,
  fields,
  handleSubmit,
  invalid,
  submitting,
  handleSaveTemplate,
  target
}) => {
  const setDefaultMessage = () => {
    fields.message.autofill(DEFAULT_TEMPLATES[target].message);
  };
  const onSubmit = values => handleSaveTemplate(target, values);

  useEffect(() => {
    fields.message.onChange(content?.message || DEFAULT_TEMPLATES[target].message);
  }, [target]);

  return (
    <form autoComplete="off" className="email-template" onSubmit={handleSubmit(onSubmit)}>
      <InlineLabeledInput
        label="CC"
        inputComponent={VerboseErrorInput}
        sizes={SIZES}
        inputProps={{ className: 'form-control', ...fields.cc }}
      />

      <InlineLabeledInput
        label="Subject"
        inputComponent={VerboseErrorInput}
        sizes={SIZES}
        inputProps={{ className: 'form-control', ...fields.subject }}
      />

      <div className="row message">
        {!fields.message.pristine && (
          <div className="col-12">
            <button className="cta" onClick={setDefaultMessage} type="button">
              Use Default Message
            </button>
          </div>
        )}
        <div className="col-12">
          <InlineLabeledInput
            inputComponent={QuillField}
            inputProps={{
              className: 'form-control',
              field: fields.message,
              options: emailEditorOptions(target)
            }}
            key={`editor-${target}`}
            label="Message"
            sizes={SIZES}
          />
        </div>
      </div>

      <div className="actions">
        <button type="submit" className="btn btn-primary" disabled={invalid || submitting}>
          Save
        </button>
      </div>
    </form>
  );
};

EmailTemplate.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSaveTemplate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired
};

export default reduxForm(
  {
    form: 'email-template-form',
    fields: ['cc', 'subject', 'message'],
    validate
  },
  (state, props) => ({ initialValues: props.content })
)(EmailTemplate);
