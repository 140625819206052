// @flow

import moment from 'moment';
import { expirations } from 'constants/cache-keys';
import Storage from './storage';

interface IStorage {
  getItem(key: string): string;
  setItem(key: string, value: string): void;
  getObject(key: string): Object;
  setObject(key: string, value: Object | Array<any>): void;
  removeItem(key: string): void;
}

export const VAL: string = 'value';
export const EXP: string = 'expiration';

export const _buildKey = (uid: number, key: string, type: string): string =>
  [uid, key, type].join('__');
export const _getExpirationArgs = (key: string): [number, string] => {
  const keys = Object.keys(expirations);
  return expirations[keys.find(k => key.includes(k))];
};

export class CacheManager {
  _storage: IStorage;

  constructor(storage: IStorage = Storage) {
    this._storage = storage;
  }

  _getUID = (): number => {
    const user = this._storage.getObject('user');
    // If the user does not exists generate a random uuid
    return user ? user.id : window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
  };

  get(key: string): mixed {
    const uid = this._getUID();
    const val = this._storage.getObject(_buildKey(uid, key, VAL));
    const expISO = this._storage.getItem(_buildKey(uid, key, EXP));

    if (new Date() > new Date(expISO)) return null;
    return val;
  }

  set(key: string, val: Object | Array<any>) {
    const uid = this._getUID();
    const exp = moment()
      .add(..._getExpirationArgs(key))
      .toDate()
      .toISOString();
    this._storage.setObject(_buildKey(uid, key, VAL), val);
    this._storage.setItem(_buildKey(uid, key, EXP), exp);
  }

  invalidate(key: string) {
    const uid = this._getUID();
    this._storage.removeItem(_buildKey(uid, key, VAL));
    this._storage.removeItem(_buildKey(uid, key, EXP));
  }
}

export default new CacheManager(Storage);
