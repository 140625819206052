import { Text, View, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import styles from './styles';

const Title = ({ text }) => (
  <View style={[styles.listItem, { marginBottom: 15 }]}>
    <View style={styles.bullet} />
    <Text style={styles.title}>{text}</Text>
  </View>
);

Title.propTypes = {
  text: PropTypes.string.isRequired
};

const ListItem = ({ style, text }) => (
  <View style={[styles.listItem, style]}>
    <View style={styles.bullet} />
    <Text>{text}</Text>
  </View>
);

ListItem.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string.isRequired
};

ListItem.defaultProps = {
  style: {}
};

const RiskFactorsDescription = () => (
  <BaseSection title="Understanding Your Risk Numbers" breakSection>
    <Text style={styles.text}>
      Managing investment risks is more complicated than buying recommended financial products,
      which is why we created an easy-to-use technology called PRISM Rating.
    </Text>
    <Text style={styles.text}>
      PRISM Rating quantifies your portfolio&apos;s exposure to four dominant investment risks that
      are harmful to your financial health if unaddressed.
    </Text>
    <ListItem text="Volatility" />
    <ListItem text="Tail Event" />
    <ListItem text="Correlation" />
    <ListItem text="Concentrated Holding" style={{ marginBottom: 15 }} />
    <Text style={styles.text}>
      Each category is assigned a one to 10 risk score that is synthesized to create an overall
      portfolio score. The lower the score, the healthier the portfolio. This is the essence of how
      Wall Street handles its money. We believe everyone deserves to benefit from the same
      risk-adjusted approach. Think of this as a trust, but verify approach to your investments.
    </Text>

    <Title text="Volatility Risk" />
    <Text style={styles.text}>
      There are two kinds of volatility, realized and implied, and they profoundly influence stock
      prices, and the entire market. Your PRISM volatility score reveals your portfolio&apos;s
      sensitivity to volatility, which BlackRock, Goldman Sachs, among others believe is an asset
      like stocks and bonds. If your investments decline when VIX rises, your portfolio is short
      volatility, and may need a “long volatility” correlation. (Don&apos;t worry. We can help.)
    </Text>

    <Title text="Tail Event Risk" />
    <View style={styles.row}>
      <View style={[styles.cell, styles.chartContainer]}>
        <Image style={styles.chart} src="/img/risk_analysis/stratifi_graph2.png" />
      </View>
      <View style={styles.cell}>
        <Text style={[styles.text, { marginBottom: 10 }]}>
          The bulk of your life&apos;s experience - personal and financial - hopefully occupies the
          the center. That&apos;s the Golden Mean. But sometimes reality is better or worse than
          expected; this describes “tails”.
        </Text>
        <View style={[styles.row, { marginBottom: 10 }]}>
          <View style={[styles.cell, styles.box, { marginRight: 5 }]}>
            <Text>
              Left tails shelter black swans, or unexpected events with devastating impacts.
            </Text>
          </View>
          <View style={[styles.cell, styles.box]}>
            <Text>
              Right tails are super happy places; think of finding unicorns, winning lotteries
              college scholarships for your kids.
            </Text>
          </View>
        </View>
        <Text style={styles.text}>
          Managing tail risk is the stuff of high finance. Even though stocks naturally rise over
          long-term, short-term performance is erratic.
        </Text>
      </View>
    </View>
    <View wrap={false}>
      <Text>&bull; 2000: Bursting of the Internet Bubble</Text>
      <Text>&bull; 2001: Sept. 11th terrorist attacks</Text>
      <Text>&bull; 2008-2009 Global Financial Crisis</Text>
      <Text style={styles.text}>&bull; 2011-2013: Europe&apos;s sovereign debt crisis</Text>
    </View>
    <Text style={styles.text}>
      PRISM&apos;s Tail Event Risk score lets you see how your portfolios reacts when circumstances
      are worse than expected.
    </Text>

    <View wrap={false}>
      <Title text="Diversification Risk" />
      <Text style={styles.text}>
        Mike Tyson, the boxing champ, once said everyone has a plan until they get hit in the mouth.
        This is why analyzing Diversification is so important; it shows if your portfolio
        diversified or pseudo-diversified. When markets move in unison - that&apos;s correlations
        working definition - stocks and often bonds move in near lockstep. This invariably scares
        and surprises investors who usually react by doubting themselves, their advisors and their
        allocations.
      </Text>
      <View style={styles.row}>
        <View style={[styles.cell, styles.chartContainer]}>
          <Image style={styles.chart} src="/img/risk_analysis/stratifi_graph3.png" />
        </View>
        <View style={styles.cell}>
          <Text style={[styles.text, { paddingLeft: 10 }]}>
            We analyze rolling six-month cross-correlations within portfolios, and also between
            portfolios and major benchmarks, such as the CBOE Volatility Index, bonds, stocks, gold
            and even real estate to determine a portfolio&apos;s true diversification.
          </Text>
        </View>
      </View>
    </View>

    <View wrap={false}>
      <Title text="Concentrated Stock Risk" />
      <Text style={styles.text}>
        A low-cost basis in a high-priced stock is reason to celebrate. It represents excellent
        investment analysis, which creates a high-quality portfolio issue. Call it the Goose that
        laid the Golden Egg Syndrome. If the goose stops laying golden eggs, investors get whacked.
      </Text>
      <View style={styles.row}>
        <View style={[styles.cell, styles.chartContainer]}>
          <Image style={styles.chart} src="/img/risk_analysis/stratifi_graph4.png" />
        </View>
        <View style={styles.cell}>
          <Text style={[styles.text, { paddingLeft: 10 }]}>
            This happens more than most anyone understands, which is why advisors try to diversify
            major stock holdings. We measure concentrated stock risk so you can understand how that
            impacts your total financial health.
          </Text>
        </View>
      </View>
    </View>
  </BaseSection>
);

export default RiskFactorsDescription;
