import cn from 'classnames';
import { SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import ProposalReportViewer from 'components/advisor/proposal/report/viewer';
import { FormGroup, VerboseErrorInput } from 'components/form';
import ReactDateRangePicker from 'components/form/date-range-picker';
import ManagementFeeField from 'components/form/management-fee-field';
import { BackendValidation } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Toggle from 'react-toggle';
import { reduxForm } from 'redux-form';
import { DateValidator, validation } from 'utils/form';
import { setPrecision } from 'utils/utils';
import WeightedPortfolioSelector from '../../../portfolio-selector/weighted';
import { SECURITY_TYPE } from '../../../portfolio-selector/weighted/utils';
import '../../common/form/styles.scss';
import { PROPOSAL_SELECT_STYLES } from '../../common/form/utils';
import {
  convertPortfoliosAmountsToPercentages,
  handleStartingValueChange,
  handleYearlyWithdrawalAmountChange,
  handleYearlyWithdrawalRateChange,
  MAX_START_DATE,
  MIN_START_DATE,
  normalizeProposalPercentageValues
} from '../../utils';

const validate = values => {
  const errors = {};

  errors.template = errors.template || validation.required(values.template);

  errors.targetManagementFee =
    errors.targetManagementFee ||
    validation.required(values.targetManagementFee) ||
    validation.isFloatPercentage(values.targetManagementFee);

  errors.recommendedManagementFee =
    errors.recommendedManagementFee ||
    validation.required(values.recommendedManagementFee) ||
    validation.isFloatPercentage(values.recommendedManagementFee);

  errors.benchmarkManagementFee =
    errors.benchmarkManagementFee ||
    validation.required(values.benchmarkManagementFee) ||
    validation.isFloatPercentage(values.benchmarkManagementFee);

  errors.yearlyWithdrawalRate =
    errors.yearlyWithdrawalRate ||
    validation.required(values.yearlyWithdrawalRate) ||
    validation.isFloatPercentage(values.yearlyWithdrawalRate);

  errors.period =
    errors.period ||
    new DateValidator(values.period?.[0])
      .required()
      .required()
      .valid()
      .min(MIN_START_DATE)
      .max(MAX_START_DATE)
      .monthDifference(values.period?.[1], 9)?.error;

  errors.yearlyWithdrawalAmount =
    errors.yearlyWithdrawalAmount || validation.floatPositive(values.yearlyWithdrawalAmount);

  errors.startingValue =
    errors.startingValue ||
    validation.required(values.startingValue) ||
    validation.floatPositive(values.startingValue) ||
    validation.nonZero(values.startingValue);

  return errors;
};

export class ProposalForm extends Component {
  constructor(props) {
    super(props);
    this.state = { benchmarkTotalValue: 100, recommendedTotalValue: 100 };
  }

  componentDidMount() {
    const { fields } = this.props;
    if (fields.recommended.initialValue.length === 0) this.setState({ recommendedTotalValue: 0 });
    fields.benchmarkWithPercentages.onChange(true);
    fields.recommendedWithPercentages.onChange(true);
  }

  handleRecommendedChange = portfolios => {
    const { fields } = this.props;
    if (!portfolios) portfolios = [];
    const recommendedTotalValue = setPrecision(
      portfolios.reduce((acum, b) => acum + b.weight, 0),
      2
    );
    this.setState({ recommendedTotalValue });
    fields.recommended.onChange(portfolios);
  };

  handleBenchmarkChange = portfolios => {
    const { fields } = this.props;
    if (!portfolios) portfolios = [];
    const benchmarkTotalValue = setPrecision(
      portfolios.reduce((acum, b) => acum + b.weight, 0),
      2
    );
    this.setState({ benchmarkTotalValue });
    fields.benchmark.onChange(portfolios);
  };

  toggleRecommendedUnit = () => {
    const {
      fields: { recommendedWithPercentages }
    } = this.props;
    recommendedWithPercentages.onChange(!recommendedWithPercentages.value);
  };

  toggleBenchmarkUnit = () => {
    const {
      fields: { benchmarkWithPercentages }
    } = this.props;
    benchmarkWithPercentages.onChange(!benchmarkWithPercentages.value);
  };

  onSubmit = async values => {
    const {
      onGenerate,
      fields: { benchmarkWithPercentages, recommendedWithPercentages }
    } = this.props;

    const { benchmarkTotalValue, recommendedTotalValue } = this.state;

    normalizeProposalPercentageValues(values);

    if (!recommendedWithPercentages.value && !!values.recommended) {
      values.recommended_total_value = recommendedTotalValue;
      values.recommended = convertPortfoliosAmountsToPercentages(values.recommended);
    }
    if (!benchmarkWithPercentages.value && !!values.benchmark) {
      values.benchmark_total_value = benchmarkTotalValue;
      values.benchmark = convertPortfoliosAmountsToPercentages(values.benchmark);
    }
    return onGenerate(values);
  };

  render() {
    const {
      allowPrint,
      error,
      fields: {
        benchmark,
        benchmarkLabel,
        benchmarkManagementFee,
        benchmarkWithPercentages,
        period,
        recommended,
        recommendedLabel,
        recommendedManagementFee,
        recommendedWithPercentages,
        startingValue,
        targetLabel,
        targetManagementFee,
        template,
        yearlyWithdrawalAmount,
        yearlyWithdrawalRate
      },
      handleSubmit,
      invalid,
      loading,
      scope,
      security,
      submitting,
      templateOptions,
      templateSuggestions
    } = this.props;
    const { benchmarkTotalValue, recommendedTotalValue } = this.state;

    const isInvalidTemplate = templateOptions.find(el => !el.approved && el.id === template.value);

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        autoComplete="off"
        className={cn('proposal-form', { 'less-opacity': loading })}
      >
        <div className="steps">
          <div className="step form-setup-values">
            <div className="step__container step__container--inline">
              <div className="step__model-name">
                <label htmlFor="target">Current target name</label>
                <VerboseErrorInput
                  className="form-control"
                  disabled
                  name="target"
                  type="text"
                  value={security.ticker_name}
                />
              </div>

              <ManagementFeeField field={targetManagementFee} />

              <FormGroup>
                <label htmlFor="targe-label">Customize label (optional)</label>
                <VerboseErrorInput
                  name="targe-label"
                  type="text"
                  {...targetLabel}
                  placeholder="Target"
                  className="form-control"
                />
              </FormGroup>
            </div>
          </div>

          <div className="step">
            <div className="step__container">
              <div className="recommended">
                <div className="recommended__label">
                  <label htmlFor="recommended">Select a matching model (optional)</label>
                  <Toggle
                    className="toggle-unit"
                    icons={{
                      checked: <span className="toggle-unit__icon">%</span>,
                      unchecked: <span className="toggle-unit__icon">$</span>
                    }}
                    id="toggle-recommended-unit"
                    onChange={this.toggleRecommendedUnit}
                    checked={!!recommendedWithPercentages.value}
                  />
                </div>
                <WeightedPortfolioSelector
                  defaultValues={recommended.value}
                  key="recommended-weighted-portfolio"
                  onChange={this.handleRecommendedChange}
                  withPercentages={!!recommendedWithPercentages.value}
                />
              </div>

              <div>
                <ManagementFeeField field={recommendedManagementFee} />
                <div className="recommended-label">
                  <label htmlFor="recommended-label">Customize label (optional)</label>
                  <VerboseErrorInput
                    name="model-label"
                    type="text"
                    {...recommendedLabel}
                    placeholder="Model"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="step">
            <div className="step__container" data-compare-analyze="true">
              <div className="benchmark">
                <div className="benchmark__label">
                  <label htmlFor="benchmark">Select a benchmark (optional)</label>
                  <Toggle
                    className="toggle-unit"
                    icons={{
                      checked: <span className="toggle-unit__icon">%</span>,
                      unchecked: <span className="toggle-unit__icon">$</span>
                    }}
                    id="toggle-benchmark-unit"
                    onChange={this.toggleBenchmarkUnit}
                    checked={!!benchmarkWithPercentages.value}
                  />
                </div>
                <WeightedPortfolioSelector
                  defaultValues={benchmark.initialValue}
                  key="benchmark-weighted-portfolio"
                  onChange={this.handleBenchmarkChange}
                  type={SECURITY_TYPE}
                  withPercentages={!!benchmarkWithPercentages.value}
                />
              </div>

              <div>
                <ManagementFeeField field={benchmarkManagementFee} />
                <div className="benchmark-label">
                  <label htmlFor="benchmark-label">Customize label (optional)</label>
                  <VerboseErrorInput
                    name="benchmark-label"
                    type="text"
                    {...benchmarkLabel}
                    placeholder="Benchmark"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="step">
            <div className="step__last-container">
              <div className="template">
                <label htmlFor="template">Template *</label>
                <Select
                  className="options"
                  onChange={template.onChange}
                  options={templateSuggestions}
                  styles={PROPOSAL_SELECT_STYLES}
                  value={templateSuggestions.find(t => t.value === template.value)}
                />
                {!!(template.touched && template.error) && (
                  <div className="text-danger" style={{ marginTop: 10, fontSize: 12 }}>
                    {template.error}
                  </div>
                )}
              </div>
              <FormGroup {...startingValue} className="starting-value form-group">
                <VerboseErrorInput
                  {...startingValue}
                  className="form-control"
                  fieldsetClassName="form-group-money"
                  label="Starting value *"
                  placeholder="Starting value"
                  type="text"
                  onChange={handleStartingValueChange(
                    startingValue,
                    yearlyWithdrawalAmount,
                    yearlyWithdrawalRate
                  )}
                />
              </FormGroup>

              <FormGroup {...period} className="start form-group">
                <VerboseErrorInput {...period} label="Period *">
                  <ReactDateRangePicker
                    className="form-control"
                    onChange={period.onChange}
                    value={period.value}
                    error={period.error}
                    feedbackMessage="Minimum 9 months required"
                  />
                </VerboseErrorInput>
              </FormGroup>
              <FormGroup {...yearlyWithdrawalRate} className="yearly-withdrawal form-group">
                <label htmlFor="yearly-withdrawal">Yearly withdrawal *</label>
                <div>
                  <VerboseErrorInput
                    {...yearlyWithdrawalRate}
                    className="form-control yearly-withdrawal__percent"
                    fieldsetClassName="form-group-percentage"
                    type="text"
                    onChange={handleYearlyWithdrawalRateChange(
                      startingValue,
                      yearlyWithdrawalAmount,
                      yearlyWithdrawalRate
                    )}
                  />
                  <VerboseErrorInput
                    {...yearlyWithdrawalAmount}
                    className="form-control yearly-withdrawal__amount"
                    fieldsetClassName="form-group-money"
                    type="text"
                    onChange={handleYearlyWithdrawalAmountChange(
                      startingValue,
                      yearlyWithdrawalAmount,
                      yearlyWithdrawalRate
                    )}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
        {error && <p className="text-danger">{error}</p>}

        <div className="form-actions">
          <button
            type="submit"
            disabled={
              submitting ||
              loading ||
              invalid ||
              (recommendedWithPercentages.value &&
                recommendedTotalValue !== 0 &&
                recommendedTotalValue !== 100) ||
              (recommendedTotalValue === 0 && !_.isEmpty(recommended.value)) ||
              (benchmarkWithPercentages.value &&
                benchmarkTotalValue !== 0 &&
                benchmarkTotalValue !== 100) ||
              (benchmarkTotalValue === 0 && !_.isEmpty(benchmark.value))
            }
            className="btn btn-primary"
          >
            Run Analysis
          </button>
          {allowPrint && (
            <ProposalReportViewer
              isInvalidTemplate={isInvalidTemplate}
              subtitle={scope.name}
              proposalType={SECURITY_PROPOSAL_TYPE}
            />
          )}
        </div>
      </form>
    );
  }
}

ProposalForm.defaultProps = {
  allowPrint: false,
  error: null,
  onGenerate: () => {},
  templateSuggestions: []
};

ProposalForm.propTypes = {
  allowPrint: PropTypes.bool,
  error: PropTypes.string,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  initializeForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onGenerate: PropTypes.func,
  scope: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  security: PropTypes.object.isRequired,
  templateOptions: PropTypes.array.isRequired,
  templateSuggestions: PropTypes.array
};

const ProposalFormWithReduxForm = reduxForm({
  form: 'generateProposal',
  touchOnChange: true,
  fields: [
    'benchmarkLabel',
    'benchmarkManagementFee',
    'benchmarkWithPercentages',
    'benchmark',
    'recommendedLabel',
    'recommendedManagementFee',
    'recommendedWithPercentages',
    'recommended',
    'period',
    'startingValue',
    'target',
    'targetLabel',
    'targetManagementFee',
    'template',
    'yearlyWithdrawalAmount',
    'yearlyWithdrawalRate'
  ],
  validate,
  overwriteOnInitialValuesChange: false
})(BackendValidation(ProposalForm));

export default connect(state => ({
  security: state.customSecurity.currentSecurity
}))(ProposalFormWithReduxForm);
