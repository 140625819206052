import React from 'react';
import FilterContainer from '../common/filter-container';
import RangeValueFilter from '../common/range-value';
import { HOUSEHOLD_TOTAL_MAX_ATTR, HOUSEHOLD_TOTAL_MIN_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const HouseholdTotalFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[HOUSEHOLD_TOTAL_MAX_ATTR, HOUSEHOLD_TOTAL_MIN_ATTR]}
    filters={filters}
    label="Household Total"
  >
    <RangeValueFilter
      filters={filters}
      maxAttr={HOUSEHOLD_TOTAL_MAX_ATTR}
      minAttr={HOUSEHOLD_TOTAL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

HouseholdTotalFilter.propTypes = { ...filterPropTypes };

HouseholdTotalFilter.defaultProps = { ...filterDefaultProps };

export default HouseholdTotalFilter;
