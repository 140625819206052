import PropTypes from 'prop-types';
import React from 'react';
import RiskAnalysisTargetScenario from '../risk-analysis-target-scenario';
import './styles.scss';

const OVERALL_GAUGE = { height: '140px', width: '200px' };
const OVERALL_OPTION = {
  innerRadius: '80%',
  radius: '100%',
  arrowRadius: '60%',
  arrowNailRadius: 5,
  arrowStartWith: 10
};

export const ScenariosScores = ({ performance, scenarios, value }) => (
  <div className="scenarios-container">
    {scenarios.map(scenario => (
      <RiskAnalysisTargetScenario
        score={parseFloat(scenario.score.toFixed(1))}
        name={scenario.name}
        key={scenario.key}
        extraOpts={OVERALL_OPTION}
        overallStyles={OVERALL_GAUGE}
        value={value}
        scenario={
          performance && scenarios.length ? performance.find(s => s.name === scenario.name) : null
        }
      />
    ))}
  </div>
);

ScenariosScores.propTypes = {
  performance: PropTypes.arrayOf(PropTypes.object),
  scenarios: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.number
};

ScenariosScores.defaultProps = {
  performance: null,
  scenarios: [],
  value: null
};

export default ScenariosScores;
