// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#CheckoutPrices {
  padding-top: 35px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/checkout/prices/styles.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#CheckoutPrices {\n  padding-top: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
