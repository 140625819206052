import cn from 'classnames';
import { LabelInput } from 'components/form';
import PasswordHelper from 'components/password-helper';
import SplashLoading from 'components/splash-loading';
import { BackendValidation } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validate = values => {
  const errors = { old_password: '', new_password1: '', new_password2: '' };

  errors.old_password = errors.old_password || validation.required(values.old_password);
  errors.new_password1 = errors.new_password1 || validation.required(values.new_password1);
  errors.new_password2 = errors.new_password2 || validation.required(values.new_password2);

  return errors;
};

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: { show: false, message: null },
      loading: false,
      passwordIsSecure: false
    };

    this.resetState = this.resetState.bind(this);
  }

  onSubmit = values => {
    const { passwordIsSecure } = this.state;
    const { authProvider } = this.context;

    if (!passwordIsSecure) {
      this.setState({
        error: {
          show: true,
          message: 'The new password does not meet requirements. Please try again.'
        }
      });
      return;
    }

    this.setState({ loading: true });
    authProvider
      .changePassword(values)
      .then(({ error }) => {
        if (error) toast.error(() => <div>Unable to change your password. Please try again.</div>);
        else toast.success(() => <div>Your password has been successfully changed.</div>);
      })
      .finally(this.resetState);
  };

  resetState = () => {
    const { resetForm } = this.props;

    this.setState({
      error: { show: false, message: null },
      loading: false,
      passwordIsSecure: false
    });

    resetForm();
  };

  render() {
    const { error, loading } = this.state;
    const { fields, handleSubmit } = this.props;

    return (
      <div id="change-password-form">
        {loading && <SplashLoading text="Changing password" duration={5} />}
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <div className={cn('container-fluid', 'table-white', { 'show-error': error.show })}>
            <div className="form-container">
              <div className="fields">
                <h4>Change Password</h4>
                <div className="row padding-top-main">
                  <LabelInput
                    type="password"
                    divClassName="width third"
                    label="Current Password"
                    key="currentPassword"
                    {...fields.old_password}
                  />
                </div>
                <div className="row padding-top-rest new-password">
                  <LabelInput
                    type="password"
                    divClassName="width third"
                    key="newPassword"
                    label="New Password"
                    {...fields.new_password1}
                  />
                </div>
                <div className="row padding-top-rest new-password">
                  <LabelInput
                    type="password"
                    divClassName="width third"
                    key="confirmPassword"
                    label="Confirm New Password"
                    {...fields.new_password2}
                  />
                </div>
              </div>
              <div className="checker-container">
                <div className={`checker ${error.show ? 'checker-error' : ''}`}>
                  <PasswordHelper
                    value={fields.new_password1.value}
                    onValid={bool =>
                      this.setState(prevState => ({ ...prevState, passwordIsSecure: bool }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {error.show && <div className="error">{error.message}</div>}
          <div className="flex justify-between align-end">
            <div />
            <div className="save-button">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ChangePasswordForm.contextTypes = {
  advisorProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

ChangePasswordForm.propTypes = {
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'changePassword',
  fields: ['old_password', 'new_password1', 'new_password2'],
  initialValues: {},
  enableReinitialize: true,
  validate
})(BackendValidation(ChangePasswordForm));
