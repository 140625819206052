import { VerboseErrorInput } from 'components/form';
import InlineLabeledInput from 'components/form/inline-labeled-input';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import MultipleInput from 'components/multiple-input';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';

export class CreateHouseholdModal extends Component {
  constructor(props) {
    super(props);

    this.initialState = { name: '', members: [] };
    this.state = { ...this.initialState };

    this.onInvestorNameChange = _.debounce(this.onInvestorNameChange, 750);
  }

  cleanUpState = () => {
    this.setState(this.initialState);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getMembersAsOptions = () => {
    const { members } = this.state;
    return members.map(m => ({
      label: m.full_name,
      value: m.id
    }));
  };

  addInvestor = inv => {
    const { members } = this.state;
    this.setState({ members: members.concat(inv) });
  };

  removeInvestor = inv => {
    const { members } = this.state;
    this.setState({
      members: members.filter(i => i.id !== inv.id)
    });
  };

  onInvestorNameChange = newValue => {
    const { householdProvider } = this.props;
    householdProvider.searchSuggestions({ search: newValue || undefined });
  };

  onSave = () => {
    const {
      fields: {
        name: { value }
      },
      householdProvider,
      householdsMeta,
      onHidden
    } = this.props;
    const { members } = this.state;

    householdProvider
      .create({
        name: value,
        member_ids: members.map(m => m.id)
      })
      .then(() => {
        householdProvider.list(householdsMeta.params);
        toast.success(() => (
          <div>
            Household <b>{value}</b> was created successfully.
          </div>
        ));
      })
      .catch(() => toast.error(() => <div>Sorry, something went wrong.</div>))
      .finally(() => {
        onHidden();
        this.cleanUpState();
      });
  };

  renderInvestor = inv => <span>{inv.full_name}</span>;

  render() {
    const { show, onHidden, householdSuggestedMembers, fields } = this.props;
    const { members } = this.state;

    return (
      <Modal id="create-household-modal" className="modal-lg" show={show} onHidden={onHidden}>
        <ModalHeader />
        <ModalBody>
          <h3 className="text-center">Create a new household</h3>
          <div className="input-section">
            <div className="household-name-form">
              <InlineLabeledInput
                label="Household Name"
                inputComponent={VerboseErrorInput}
                inputProps={{
                  className: 'form-control',
                  maxLength: 30,
                  ...fields.name
                }}
              />
            </div>
          </div>

          <div className="input-section">
            <div className="household-add-member">
              <div className="row">
                <div className="col-sm-4 zero-right-pad">
                  <label>Add House Members</label>
                </div>
                <div className="col-sm-8">
                  <MultipleInput
                    addButton
                    autosuggestProps={{
                      suggestions: householdSuggestedMembers,
                      getSuggestionValue: s => `${s.full_name}`,
                      renderSuggestion: this.renderInvestor
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.onInvestorNameChange
                    }}
                    onItemAdded={this.addInvestor}
                    onItemRemoved={this.removeInvestor}
                    renderSelectedItem={this.renderInvestor}
                    searchIcon
                    selectedItems={members}
                    theme={{
                      container: 'react-autosuggest__container',
                      containerOpen: 'open react-autosuggest__container--open',
                      suggestionsContainer:
                        'dropdown-menu react-household-autosuggest__suggestions-container',
                      suggestion: 'dropdown-item-new react-autosuggest__suggestion'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-2 text-center">
            <button type="button" className="btn btn-black mr-1" onClick={onHidden}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSave}
              disabled={!fields.name.value}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

CreateHouseholdModal.propTypes = {
  fields: PropTypes.object.isRequired,
  householdsMeta: PropTypes.object,
  householdProvider: PropTypes.object.isRequired,
  householdSuggestedMembers: PropTypes.array.isRequired,
  investorProvider: PropTypes.object.isRequired,
  onHidden: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

CreateHouseholdModal.defaultProps = {
  householdsMeta: {}
};

export default reduxForm({
  form: 'create-household-form',
  fields: ['name']
})(CreateHouseholdModal);
