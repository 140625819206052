/* eslint-disable import/prefer-default-export */
import {
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID,
  TARGET_POSITIONS_ANALYSIS_CHART_ID
} from 'components/advisor/proposal/distribution-summary/constants';
import { IPS_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';

const IPS_REPORT_CHARTS = [
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID, // distributionSummary
  TARGET_POSITIONS_ANALYSIS_CHART_ID // distributionSummary
];
const IPS_REPORT_RECOMMENDED_CHARTS = [RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID];

const DISTRIBUTION_SUMMARY_SECTION = 'distributionSummary';

const IPS_SECTION_CHARTS = {
  [RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID]: DISTRIBUTION_SUMMARY_SECTION,
  [TARGET_POSITIONS_ANALYSIS_CHART_ID]: DISTRIBUTION_SUMMARY_SECTION
};

export const getFullyLoadedCharts = (ips, savedCharts) => {
  const templateContent =
    ips.proposal.template_content || ips.proposal.template.content || IPS_DEFAULT_TEMPLATE;

  // removes all recommended related charts when required
  const byRequiredCharts = chart =>
    ips.proposal.recommended || !IPS_REPORT_RECOMMENDED_CHARTS.includes(chart);

  // removes all charts that are not needed because the section is not visible
  const byVisibleSections = chart => {
    const section = IPS_SECTION_CHARTS[chart];
    if (
      !section ||
      (templateContent[section] &&
        templateContent[section].hidden &&
        !templateContent[section].allowHide)
    )
      return false;
    return true;
  };

  const charts = IPS_REPORT_CHARTS.filter(byRequiredCharts).filter(byVisibleSections);
  return charts.every(chart => Object.prototype.hasOwnProperty.call(savedCharts, chart));
};
