import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { withCurrencyFormat } from 'utils/utils';
import styles from './styles';

const AccountHeader = ({ account, breakSection, position }) => (
  <View style={styles.container} break={breakSection} wrap={false}>
    <View style={styles.position}>
      <Text>{position}</Text>
    </View>
    <View style={styles.account}>
      <Text>{account.display_name}</Text>
      <Text>{withCurrencyFormat(account.value)}</Text>
    </View>
  </View>
);

AccountHeader.propTypes = {
  account: PropTypes.object.isRequired,
  breakSection: PropTypes.bool,
  position: PropTypes.number.isRequired
};

AccountHeader.defaultProps = {
  breakSection: true
};

export default AccountHeader;
