/* eslint-disable import/prefer-default-export, no-await-in-loop, no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getTargetInvestors } from 'utils/utils';

export const getTimeHorizon = proposal => {
  const investors = getTargetInvestors(proposal);

  let timeHorizons = investors
    .map(investor => investor?.data_points?.['time-horizon']?.value)
    .filter(timeHorizon => Number.isFinite(timeHorizon));
  timeHorizons = new Set(timeHorizons);

  if (timeHorizons.size === 1) {
    const timeHorizon = timeHorizons.values().next().value - moment().year();
    if (timeHorizon >= 1) return timeHorizon;
  }

  return null;
};

export const investorGoalsToOptions = investorGoals =>
  investorGoals.map(goal => ({ label: goal.name, value: goal.id }));

export const companyGoalsToOptions = (companyGoals, investorGoals) =>
  Object.keys(companyGoals)
    .filter(
      companyGoal =>
        !investorGoals.find(
          investorGoal => companyGoal.toLowerCase() === investorGoal.name.toLowerCase()
        )
    )
    .map(goal => ({ label: goal, value: goal }));

/**
 * Creation of all uncreated investment objectives.
 *
 * The `uncreatedGoals` format consists of an object where its key is the ID of the investor, and
 * its value is an array with all the investment objective names to be created for the investor:
 *
 * ```
 * {
 *   1: ["Aggressive Growth", "Growth"],
 *   2: ["Passive Income"]
 * }
 * ```
 *
 * @param {object} uncreatedGoals
 * @param {object} investorGoalsProvider
 * @returns The created investment objectives
 */
export const processUncreatedGoals = async (uncreatedGoals, investorGoalsProvider) => {
  if (_.isEmpty(uncreatedGoals)) return {};

  const createdGoals = {};
  for (const [investorId, goals] of Object.entries(uncreatedGoals)) {
    createdGoals[investorId] = [];
    for (const goal of goals)
      await investorGoalsProvider.create({ name: goal, investor: investorId }).then(({ data }) => {
        createdGoals[investorId].push({ id: data.id, name: data.name });
      });
  }

  return createdGoals;
};

/**
 * Process assigning the investment objectives to the accounts.
 *
 * The `modifiedAccounts` format is as follows:
 * ```
 * {
 *   1: {goal: null, investor: 123},
 *   2: {goal: 456, investor: 123},
 *   3: {goal: "Aggressive Growth", investor: 123}
 * }
 * ```
 *
 * Each key represents the ID of an account, while its value is an object where the `goal` attribute
 * represents the investment objective to be assigned.
 *
 * These are the cases that may arise for the `goal` attribute:
 * - The value is `null`, so the investment objective will be unassigned from the account.
 * - The value is a `number`, so the investment objective can be assigned directly to the account.
 * - The value is a `string`, which means that it must be replaced by the ID corresponding to
 *   the investment objective created in the end.
 *
 * @param {object} modifiedAccounts
 * @param {object} createdGoals
 * @param {object} accountProvider
 * @returns
 */
export const processAccountGoalsAssignment = async (
  modifiedAccounts,
  createdGoals,
  accountProvider
) => {
  if (_.isEmpty(modifiedAccounts)) return;

  const modifiedAccountsPromises = [];
  Object.entries(modifiedAccounts).forEach(([accountId, accountData]) => {
    if (!accountData.goal || Number.isFinite(accountData.goal))
      modifiedAccountsPromises.push(
        accountProvider.updatePatch(accountId, { goal: accountData.goal })
      );
    else {
      const goals = createdGoals[accountData.investor];
      const goal = goals.find(g => g.name === accountData.goal);
      if (goal)
        modifiedAccountsPromises.push(accountProvider.updatePatch(accountId, { goal: goal.id }));
    }
  });

  const response = await Promise.allSettled(modifiedAccountsPromises);
  const fulfilledModifiedAccountsPromises = response.filter(p => p.status === 'fulfilled');
  if (fulfilledModifiedAccountsPromises.length)
    fulfilledModifiedAccountsPromises.forEach(({ value }) => {
      const { data: account } = value;
      toast.success(
        `The investment objective for ${account.display_name} was ${
          account.goal_name ? `set to ${account.goal_name}` : 'removed'
        }`
      );
    });
};
