/* eslint-disable no-underscore-dangle */
import clone from 'clone';
import PropTypes from 'prop-types';
import Chart from '..';
import './styles.scss';

class PositionChart extends Chart {
  className = 'position-chart';

  componentDidMount() {
    super.componentDidMount();
    const { initCallback } = this.props;
    if (initCallback) initCallback(this._chart);
  }

  getOptions() {
    const themes = this.context.config.chart;
    return {
      ...clone(themes.common.default, false),
      ...clone(themes.pie.default, false),
      ...clone(themes.pie.positions, false),
      ...clone(themes.pie.assetColors, false),
      ...this.props.options
    };
  }
}

PositionChart.contextTypes = {
  config: PropTypes.object.isRequired
};

PositionChart.defaultProps = {
  options: {},
  initCallback: null
};

PositionChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
  initCallback: PropTypes.func
};

export default PositionChart;
