import { CHARGE_LIST, FETCHING } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class ChargeProvider extends DataProvider {
  async list(page) {
    this.dispatch(FETCHING);
    const response = await this.provider.get(`${config.apiBase}billing/charges/?page=${page}`);
    if (!response.error)
      this.dispatch(CHARGE_LIST, {
        data: response.data.results
      });
    return response.data;
  }
}

export default ChargeProvider;
