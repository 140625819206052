import EmbeddedDocument from 'components/advisor/utils/embedded-document';
import PropTypes from 'prop-types';
import React from 'react';
import { MetadataPropTypes } from '../types';

const IPSEmbeddedDocument = props => <EmbeddedDocument {...props} />;

IPSEmbeddedDocument.propTypes = {
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default IPSEmbeddedDocument;
