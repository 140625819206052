import React from 'react';

export default ({ className }) => (
  <svg
    id="Layer_1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.27 10.8"
  >
    <title>downarrow_blue</title>
    <line className="cls-1" x1="0.35" y1="0.35" x2="10.47" y2="10.42" />
    <line className="cls-1" x1="9.8" y1="10.45" x2="19.92" y2="0.38" />
  </svg>
);
