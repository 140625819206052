import cn from 'classnames';
import { scoreColor } from 'components/advisor/generic-selector';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import WarningIcon from 'components/svg-icons/warning-icon';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TooltipV2 from 'components/tooltip-v2';
import Typographic from 'components/typographic';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './styles.scss';

const mergePositions = (
  { positions: positions1 },
  { positions: positions2 },
  { positions: positions3 },
  target
) => {
  // if target is 1 then prefer the values from positions1 else prefer the values of positions2/3
  const o = [];
  const mapTicker = (obj, pos) => {
    obj[pos.ticker] = pos;
    return obj;
  };
  const pos1obj = positions1.reduce(mapTicker, {});
  const pos2obj = positions2.reduce(mapTicker, {});
  const pos3obj = positions3.reduce(mapTicker, {});

  // TODO: By default, sort the tickers by the weight, ascending order. Smaller numbers first and big numbers later.
  positions1
    .filter(
      // exclude rows with all weights in 0%
      pos =>
        pos.value > 0 ||
        (pos.value === 0 && (pos2obj[pos.ticker] || pos3obj[pos.ticker] || pos.new))
    )
    .forEach(pos => {
      if (target === 1) o.push(pos);
      if (target === 2) o.push(pos2obj[pos.ticker] || { ...pos, value: 0 });
      else if (target === 3) o.push(pos3obj[pos.ticker] || { ...pos, value: 0 });
    });
  positions2.forEach(pos => {
    if (pos1obj[pos.ticker]) return;
    if (target === 1) o.push({ ...pos, value: 0 });
    else if (target === 2) o.push(pos2obj[pos.ticker] || { ...pos, value: 0 });
    else if (target === 3) o.push(pos3obj[pos.ticker] || { ...pos, value: 0 });
  });
  positions3.forEach(pos => {
    if (pos1obj[pos.ticker] || pos2obj[pos.ticker]) return;
    if (target < 3) o.push({ ...pos, value: 0 });
    if (target === 3) o.push(pos3obj[pos.ticker]);
  });
  return o;
};

const sumValues = ({ positions }) =>
  Math.round(positions.reduce((value, p) => value + p.value, 0) * 100, 10) / 100;

const emptyModel = { positions: [] };

const InlineEditPortfolio = ({
  benchmarkId,
  benchmarkType,
  benchmarkName,
  model,
  targetLabel,
  recommendedId,
  recommendedType,
  recommendedName,
  setPositions: setParentPositions
}) => {
  const { accountProvider, modelProvider, marketProvider } = useContext(AdvisorContext);
  if (!model) return null;

  const [current, setCurrent] = useState({ positions: model.positions, total: sumValues(model) });
  const [benchmark, setBenchmark] = useState({ positions: [], total: 0 });
  const [recomended, setRecomended] = useState({ positions: [], total: 0 });

  const [newPositionRow, setNewPositionRow] = useState(false);
  const [tickerName, setTickerName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [expanded, expand] = useState(true);
  const [tickerError, setTickerError] = useState(false);

  // This will only be true if changes have been made in the portfolio positions
  const [isPortfolioDirty, setIsPortfolioDirty] = useState(false);

  useEffect(() => {
    if (newPositionRow) return;

    const promises = [];
    const target = { positions: current.positions };
    let recomendedR = emptyModel;
    let benchmarkR = emptyModel;

    // If changes have been made to the portfolio positions, this will allow
    // them to be saved even if the useEffect function is activated because
    // the benchmark or model has changed.
    setParentPositions(current.positions, isPortfolioDirty);

    if (benchmarkId) {
      if (benchmarkType === 'model_portfolio') promises.push(modelProvider.getById(benchmarkId));
      if (benchmarkType === 'security') promises.push(marketProvider.securityGet(benchmarkId));
    }

    if (recommendedId)
      if (recommendedType === 'model_portfolio')
        promises.push(modelProvider.getById(recommendedId));
      else if (recommendedType === 'client-account' || recommendedType === 'prospect-account')
        promises.push(accountProvider.get(recommendedId));

    Promise.all(promises).then(values => {
      const getBenchmark = data => {
        if (!data) return emptyModel;
        return benchmarkType === 'security'
          ? {
              positions: [
                {
                  ...data,
                  value: 100,
                  prism_score_summary: {
                    overall: data.prism_overall,
                    downside_capture_ratio: data.downside_capture_ratio,
                    upside_capture_ratio: data.upside_capture_ratio
                  }
                }
              ]
            }
          : data;
      };

      const getRecommended = data => {
        if (!data || !data.positions) return emptyModel;
        const total = sumValues(data);
        return recommendedType === 'model_portfolio'
          ? data
          : {
              ...data,
              positions: data.positions
                ? data.positions.map(x => ({
                    ...x,
                    value: Math.round((x.value / total) * 10000, 10) / 100
                  }))
                : []
            };
      };

      if (!_.isEmpty(values))
        if (benchmarkId && recommendedId) {
          benchmarkR = getBenchmark(values[0].data);
          recomendedR = getRecommended(values[1].data);
        } else if (recommendedId) recomendedR = getRecommended(values[0].data);
        else if (benchmarkId) benchmarkR = getBenchmark(values[0].data);

      setCurrent({
        ...current,
        positions: mergePositions(target, recomendedR, benchmarkR, 1)
      });

      if (recommendedId)
        setRecomended({
          positions: mergePositions(target, recomendedR, benchmarkR, 2),
          total: sumValues(recomendedR)
        });

      if (benchmarkId)
        setBenchmark({
          positions: mergePositions(target, recomendedR, benchmarkR, 3),
          total: sumValues(benchmarkR)
        });
    });
  }, [benchmarkId, recommendedId, newPositionRow, JSON.stringify(model.positions)]);

  const updatePositionWeight = ticker => ({ target: { value } }) => {
    const newPositions = [];
    current.positions.forEach(pos => {
      newPositions.push(pos.ticker === ticker ? { ...pos, value: Number(value) } : pos);
    });
    setCurrent({ positions: newPositions, total: sumValues({ positions: newPositions }) });
    setIsPortfolioDirty(true);
    setParentPositions(newPositions, true);
  };

  const retrieveSuggestions = value => {
    if (!value.length) return;
    marketProvider.es.securitySearch(value).then(data => {
      const suggestion = data.results.map(
        ({
          downside_capture_ratio: downside,
          prism_overall: score,
          ticker_name: tickerName,
          ticker,
          upside_capture_ratio: upside
        }) => ({
          downside,
          name: ticker,
          score,
          tickerName,
          upside
        })
      );
      setSuggestions(suggestion);
      return suggestion;
    });
  };

  const delayedRetrieveSuggestions = useCallback(
    _.debounce(value => retrieveSuggestions(value), 500),
    []
  );

  const onSuggestionsFetchRequested = value => {
    setTickerName(value);
    delayedRetrieveSuggestions(value);
  };

  const addNewTicker = name => {
    const result = suggestions.find(x => name.toUpperCase() === x.name);
    if (
      result &&
      result.tickerName &&
      !current.positions.filter(x => x.ticker === result.name).length
    ) {
      setCurrent({
        ...current,
        positions: current.positions.concat({
          ticker: result.name,
          ticker_name: result.tickerName,
          prism_score_summary: {
            overall: result.score,
            downside_capture_ratio: result.downside,
            upside_capture_ratio: result.upside
          },
          value: 0,
          new: true
        })
      });
      setNewPositionRow(false);
      setTickerName('');
      return true;
    }
    return false;
  };

  const captureWidth = x => (x < 1 ? x * 100 : 100);
  const totalError = current.total - 100;

  const recommendedNameLabel = recommendedName || (
    <>
      Portfolio name selected from step 2
      <br />
      Weight (%)
    </>
  );

  const benchmarkNameLabel = benchmarkName || (
    <>
      Benchmark name selected from step 3
      <br />
      Weight (%)
    </>
  );

  return (
    <div id="InlineEditPortfolio">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className="toggle-header" onClick={() => expand(!expanded)}>
        <h1>Compare &amp; Edit Portfolio</h1>
        <div className="expander-arrow-icon">
          {expanded ? (
            <span className="fs-icon-angle-up" />
          ) : (
            <span className="fs-icon-angle-down" />
          )}
        </div>
      </div>

      {expanded && (
        <>
          <div className="table-container">
            <div className="current-portfolio-table">
              <div className="header">{targetLabel || 'Current portfolio'}</div>
              <div className="body">
                <div className="row">
                  <div className="col col--header">PRISM</div>
                  <div className="col col-1 col--header">Ticker</div>
                  <div className="col col-3 col--header">Asset/Security Name</div>
                  <div className="col col-2 col--header">Tail Risk (Downside)</div>
                  <div className="col col-2 col--header">Volatility Risk (Upside)</div>
                  <div className="col col-2 col--header">Weight (%)</div>
                </div>
                {current.positions.map(
                  ({ ticker, ticker_name: name, prism_score_summary: scores, value, id }) => (
                    <div className="row" key={ticker}>
                      <div className="col col--center">
                        <button
                          type="button"
                          className={cn(
                            'btn',
                            'btn-box',
                            scores ? `btn-${scoreColor(scores.overall)}` : null
                          )}
                        >
                          {scores ? scores.overall : '?'}
                        </button>
                      </div>
                      <div className="col col-1 col--with-ellipsis">
                        <div className="col__ticker">
                          <Typographic id={`ticker-${id}`} label={ticker} />
                        </div>
                      </div>
                      <div className="col col-3 col--with-ellipsis">
                        <div className="col__ticker-name">
                          <Typographic id={`name-${id}`} label={name} />
                        </div>
                      </div>
                      <div className="col col-2 col--risk">
                        <div
                          className="bar"
                          style={{
                            width: captureWidth(scores ? scores.downside_capture_ratio : 0)
                          }}
                        />
                      </div>
                      <div className="col col-2 col--vol">
                        <div
                          className="bar"
                          style={{
                            width: captureWidth(scores ? scores.upside_capture_ratio : 0)
                          }}
                        />
                      </div>
                      <div className="col col-2 col-weight col-input">
                        <input
                          value={value === 0 ? 0 : String(value).replace(/^0+/g, '')}
                          onChange={updatePositionWeight(ticker)}
                          type="number"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="compare-portfolio-row">
              <div className="header">
                <TooltipV2
                  place="top"
                  id="recommended-name-id"
                  className="compare-portfolio-tooltip"
                  offset={{ top: 20, left: 5 }}
                  label={<p>{recommendedNameLabel}</p>}
                >
                  <p className="paragraph" data-for="recommended-name-id" data-tip="React-tooltip">
                    {recommendedNameLabel}
                  </p>
                </TooltipV2>
              </div>

              {recomended.positions.map(position => (
                <div className="row" key={position.ticker}>
                  <div className="col col--center">{position.value}%</div>
                </div>
              ))}
            </div>
            <div className="benchmark-row">
              <div className="header">
                <TooltipV2
                  place="top"
                  id="benchmark-name-id"
                  className="compare-portfolio-tooltip"
                  offset={{ top: 20, left: 5 }}
                  label={<p>{benchmarkNameLabel}</p>}
                >
                  <p className="paragraph" data-for="benchmark-name-id" data-tip="React-tooltip">
                    {benchmarkNameLabel}
                  </p>
                </TooltipV2>
              </div>
              {benchmark.positions.map(position => (
                <div className="row" key={position.ticker}>
                  <div className="col col--center">{position.value}%</div>
                </div>
              ))}
            </div>
          </div>

          {newPositionRow ? (
            <div style={{ display: 'flex' }}>
              <div className="current-portfolio-table">
                <div className="body">
                  <div className="row">
                    <div className="col">
                      <button type="button" className="btn btn-box btn-dashed">
                        ?
                      </button>
                    </div>
                    <div className={cn('col', 'col-1', 'col-input', { error: tickerError })}>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={({ value }) =>
                          onSuggestionsFetchRequested(value)
                        }
                        shouldRenderSuggestions={() => !!suggestions.length}
                        onSuggestionsClearRequested={() => {
                          setTickerError(!addNewTicker(tickerName));
                        }}
                        getSuggestionValue={s => s.name}
                        renderSuggestion={suggestion => (
                          <span>
                            {suggestion.score ? (
                              <ScoreBubble score={suggestion.score} />
                            ) : (
                              <span className="no-score-warning">
                                <WarningIcon className="warning-icon" title="Score not available" />
                              </span>
                            )}{' '}
                            {suggestion.name}: {suggestion.tickerName}
                          </span>
                        )}
                        inputProps={{
                          value: tickerName,
                          onKeyUp: ({ key }) => {
                            if (key === 'Enter') addNewTicker(tickerName);
                          },
                          onChange: (e, { newValue, method }) => {
                            onSuggestionsFetchRequested(newValue);
                            setTickerName(newValue);
                            setTickerError(false);
                            if (method === 'click') addNewTicker(newValue);
                          },
                          onFocus: () => retrieveSuggestions(tickerName)
                        }}
                        theme={{
                          container: 'react-autosuggest__container',
                          containerOpen: 'open react-autosuggest__container--open',
                          suggestionsContainer:
                            'dropdown-menu react-autosuggest__suggestions-container',
                          suggestion: 'dropdown-item-new react-autosuggest__suggestion',
                          suggestionFocused: 'react-autosuggest__suggestion--focused'
                        }}
                      />
                    </div>
                    <div className="col col-3" />
                    <div className="col col-2" />
                    <div className="col col-2" />
                    <div className="col col-2 col-weight col-input">
                      <input value="" type="text" disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className="compare-portfolio-row" />
              <div className="benchmark-row" />
            </div>
          ) : (
            <button
              className="btn-transparent btn-add-position"
              type="button"
              onClick={() => {
                setNewPositionRow(true);
              }}
            >
              <i className="icon-add_item" /> Add a new position
            </button>
          )}

          <div style={{ display: 'flex' }}>
            <div className="current-portfolio-table">
              <div className="body">
                <div className="row">
                  <div className="col">Totals</div>
                  <div
                    className={cn('col', 'col-2', 'col--center', {
                      'total-error': totalError !== 0
                    })}
                  >
                    {current.total}%
                    {totalError !== 0 && (
                      <span className="error-label">
                        {Math.round(Math.abs(totalError * 100)) / 100}%{' '}
                        {totalError > 0 ? 'overweight' : 'underweight'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="compare-portfolio-row">
              <div className="row">
                <div className="col col--center">{Math.round(recomended.total)}%</div>
              </div>
            </div>
            <div className="benchmark-row">
              <div className="row">
                <div className="col col--center">{Math.round(recomended.total)}%</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

InlineEditPortfolio.propTypes = {
  benchmarkId: PropTypes.number,
  benchmarkType: PropTypes.string,
  benchmarkName: PropTypes.string,
  targetLabel: PropTypes.string,
  model: PropTypes.object.isRequired,
  recommendedId: PropTypes.number,
  recommendedType: PropTypes.string,
  recommendedName: PropTypes.string,
  setPositions: PropTypes.func.isRequired
};
InlineEditPortfolio.defaultProps = {
  benchmarkId: null,
  benchmarkName: '',
  benchmarkType: '',
  targetLabel: '',
  recommendedId: null,
  recommendedType: '',
  recommendedName: ''
};

export default InlineEditPortfolio;
