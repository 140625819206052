export default {
  type: 'pie',
  balloonText: "[[title]]<br><span style='color: #30B9F8'>([[value]])</span>",
  innerRadius: '55%',
  classNameField: 'level',
  colorField: 'color',
  descriptionField: 'description',
  labelsEnabled: false,
  marginBottom: 0,
  marginTop: 0,
  percentPrecision: 0,
  titleField: 'description',
  valueField: 'value',
  allLabels: [
    {
      align: 'center',
      bold: true,
      id: 'Label-total-text',
      size: 15,
      text: 'Total',
      x: '0%',
      y: '45%'
    },
    {
      align: 'center',
      bold: true,
      id: 'Label-total-value',
      size: 15,
      text: '',
      x: '0%',
      y: '50%'
    }
  ],
  balloon: {
    borderAlpha: 0,
    borderThickness: 0,
    color: '#FFFFFF',
    fillAlpha: 1,
    fillColor: '#484A4C',
    fontSize: 15,
    showBullet: true
  },
  legend: {
    align: 'right',
    bold: true,
    color: '#484A4C',
    enabled: true,
    fontSize: 15,
    marginLeft: 5,
    marginRight: 10,
    markerBorderThickness: 0,
    markerSize: 15,
    position: 'right',
    rollOverColor: '#888A8C',
    textClickEnabled: true,
    valueText: '[[rate]]%',
    spacing: 5
  },
  titles: []
};

export const DRIFT_LEVELS_DATA_OPTIONS = {
  no_target: {
    color: '#B6D6E7',
    description: 'Missing risk tolerance',
    index: 1,
    level: 'no_target'
  },
  high_risk: {
    color: '#F6B445',
    description: 'Risk is higher than threshold',
    index: 2,
    level: 'high_risk'
  },
  high_tolerance: {
    color: '#EFDE55',
    description: 'Risk is lower than threshold',
    index: 3,
    level: 'high_tolerance'
  },
  match: {
    color: '#75CFE9',
    index: 4,
    description: 'Risk is in threshold',
    level: 'match'
  },
  snoozed: {
    color: '#9EE6FA',
    index: 5,
    description: 'Exception with notes',
    level: 'snoozed'
  },
  no_prism: {
    color: '#B6D6CC',
    description: 'Missing portfolio risk',
    index: 6,
    level: 'no_prism'
  },
  excluded: {
    color: '#4E666A',
    description: 'Excluded',
    index: 7,
    level: 'excluded'
  }
};
