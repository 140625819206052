import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { choicesToOptions, interpolateRef, roundThousands } from 'utils/questionnaire';
import { withCurrencyFormat } from 'utils/utils';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const useLossReaction = ({ question }) => {
  const refs = question.data?.refs || {};

  // total amount that the user invested or is comfortable investing
  const assetsInvested =
    roundThousands(Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])) || 0;

  // total amount lost in this hypothetical scenario
  const assetsLost = roundThousands(assetsInvested * 0.2) || 0;

  refs['assets-invested'] = withCurrencyFormat(assetsInvested, 'standard', 0).replace('M', 'MM');
  refs['assets-lost'] = withCurrencyFormat(assetsLost, 'standard', 0).replace('M', 'MM');

  const questionText = useMemo(
    () => interpolateRef(question.question, refs),
    [JSON.stringify(refs)]
  );

  const choices = choicesToOptions(question.data?.options);

  return {
    choices,
    questionText
  };
};

useLossReaction.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default useLossReaction;
