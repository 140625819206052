import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { SECURITY_RISK_OVERALL_MAX_ATTR, SECURITY_RISK_OVERALL_MIN_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const SecurityPrismOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[SECURITY_RISK_OVERALL_MAX_ATTR, SECURITY_RISK_OVERALL_MIN_ATTR]}
    filters={filters}
    label="Security PRISM Overall"
  >
    <MinMaxSliderFilter
      filters={filters}
      maxAttr={SECURITY_RISK_OVERALL_MAX_ATTR}
      minAttr={SECURITY_RISK_OVERALL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

SecurityPrismOverallFilter.propTypes = { ...filterPropTypes };

SecurityPrismOverallFilter.defaultProps = { ...filterDefaultProps };

export default SecurityPrismOverallFilter;
