// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collaboration-member {
  display: flex;
  gap: 5px;
}
.collaboration-member > div {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/collaboration-hub/select/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,QAAA;AADF;AAGE;EACE,OAAA;AADJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.collaboration-member {\n  display: flex;\n  gap: 5px;\n\n  & > div {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
