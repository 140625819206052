import { StyleSheet } from '@react-pdf/renderer';
import { LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  row: {
    paddingVertical: 20
  },
  title: {
    paddingBottom: 5,
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR,
    fontSize: 12,
    fontWeight: 'bold'
  },
  description: {
    marginTop: 5
  }
});

export default styles;
