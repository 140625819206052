import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import { MODEL_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import RiskAnalysisPositions from 'components/advisor/risk-analysis/risk-analysis-target/risk-analysis-positions';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import {
  DEFAULT_PORTFOLIO_TOTAL_ASSETS,
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL,
  showSecurityDetailsSection
} from 'components/advisor/risk-analysis/securities/common/utils';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import Disclosure from 'components/disclosure';
import ScrollCardsIndex from 'app/components/advisor/scroll-cards-index';
import SpinnerLoader from 'components/performance-spinner';
import Link from 'components/utils/link';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside';
import ScrollToTop from 'app/components/advisor/scroll-cards-index/scroll-to-top';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import NewTag from '../new-tag';
import './styles.scss';

const FAILED_TO_FETCH = 'Failed to fetch';
const MAX_RETRIES = 2;

const AdvisorModelsDetailsOverview = ({
  calculating,
  params,
  portfolio,
  riskAnalysis,
  riskAnalysisRequired,
  showEditModal
}) => {
  const {
    modelProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  let targetSummary = null;
  const score = portfolio?.prism_score_summary?.overall;
  const prismOverall = score ? score.toFixed(1) : null;
  const isStrategy = portfolio?.is_strategy;

  if (portfolio && !_.isEmpty(portfolio))
    // adds by default a value of 100 in case of a model portfolio
    portfolio.value = DEFAULT_PORTFOLIO_TOTAL_ASSETS;

  const loadData = async () => {
    setLoading(true);
    try {
      await modelProvider.get(params.id);
      if (portfolio) {
        const portfolioRisk = portfolio.prism_score?.output
          ? { ...portfolio.prism_score.output, portfolio }
          : { portfolio };
        modelProvider.riskAnalysisPrecomputed(portfolioRisk);
        // adds by default a value of 100 in case of a model portfolio
        portfolio.value = DEFAULT_PORTFOLIO_TOTAL_ASSETS;
      }
    } finally {
      setLoading(false);
    }
  };

  const analyzeRisk = async () => {
    const creating = _.isEmpty(riskAnalysis);
    const notificationOptions = { toastId: portfolio.id };

    toast.info(`${creating ? 'Calculating' : 'Updating'} PRISM score.`, notificationOptions);

    try {
      const response = await modelProvider.updatePrism(portfolio.id);
      if (response.message === FAILED_TO_FETCH) throw new Error(response.message);
      toast.success(
        `PRISM score successfully ${creating ? 'calculated' : 'updated'}.`,
        notificationOptions
      );
    } catch {
      toast.error(
        "We couldn't calculate the PRISM rating, please try it later or contact support for more information.",
        notificationOptions
      );
    }
  };

  const reloadModelRisk = async () => {
    await analyzeRisk();
    loadData();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (riskAnalysisRequired) await reloadModelRisk();
      else await loadData();

      if (riskAnalysis == null && retryCount < MAX_RETRIES)
        setRetryCount(prevCount => prevCount + 1);
    };

    fetchData();
  }, [riskAnalysisRequired, retryCount]);

  useEffect(() => {
    if (riskAnalysisRequired) reloadModelRisk();
    else loadData();
  }, []);

  useEffect(() => {
    if (portfolio?.prism_score === null) loadData();
  }, [portfolio?.prism_score]);

  useEffect(() => {
    if (riskAnalysisRequired) {
      reloadModelRisk();

      if (riskAnalysis.custom) targetSummary = portfolio.target_score_summary;
    }
  }, [riskAnalysisRequired]);

  useEffect(() => {
    if (riskAnalysis == null && retryCount < MAX_RETRIES) setRetryCount(prevCount => prevCount + 1);
  }, [riskAnalysis]);

  if (loading) return <SpinnerLoader spinnerLoading />;

  return (
    <section className="risk-analysis">
      <div className="risk-score-result-container">
        {portfolio && !_.isEmpty(riskAnalysis) && (
          <div className="risk-analysis-body">
            <div className="risk-analysis-body-container">
              <ScrollCardsIndex
                isStrategy={isStrategy}
                score={prismOverall}
                portfolio={portfolio}
              />
              {calculating || showEditModal ? (
                <div className="calculating-portfolio-risk">
                  <LoadingPrismDataMessage
                    message={`${calculating ? 'Calculating' : 'Preparing'} Portfolio Risk...`}
                    inset
                  />
                </div>
              ) : (
                <div className="result-container prism-container" id="portfolio-risk">
                  {portfolio.prism_score_summary && (
                    <div>
                      <div className="result-heading heading-model">
                        <div className="result-heading name-container">
                          Portfolio Risk <span className="sub-text">Powered By</span> PRISM Rating
                          &trade;
                        </div>
                        <span className="view-report">
                          <Link
                            to={`/advisor/models/${params.id}/risk-analysis`}
                            className="btn btn-outline-secondary btn-prism"
                          >
                            View Report
                          </Link>
                        </span>
                      </div>
                      <PrismRatingUpsideDownside
                        prismSummary={portfolio.prism_score_summary}
                        targetSummary={targetSummary}
                        value={portfolio.value}
                        market={{
                          up: company.market_upside_performance,
                          down: company.market_downside_performance
                        }}
                      />
                      <ScrollToTop />
                    </div>
                  )}
                </div>
              )}

              {portfolio ? (
                <section id="allocation">
                  <RiskAnalysisPositions portfolio={portfolio} type={MODEL_TARGET_TYPE} />
                </section>
              ) : (
                <h3 className="text-sm-center placeholder-text">
                  The PRISM score is not ready for this portfolio.
                </h3>
              )}
              <ScrollToTop />

              {showSecurityDetailsSection(portfolio, SECURITY_STYLES) && (
                <div className="result-container prism-container">
                  <div className="result-heading">
                    Investment Style <NewTag />
                  </div>
                  <div className="investment-style-container">
                    <InvestmentStyle portfolio={portfolio} hiddenVal />
                  </div>
                  <ScrollToTop />
                </div>
              )}

              {showSecurityDetailsSection(portfolio, SECURITY_SECTORS) && (
                <div className="result-container prism-container">
                  <div className="result-heading" id="sector-exposure--header">
                    Sector Exposure <NewTag />
                  </div>
                  <div className="sector-exposure-container">
                    <SectorExposure portfolio={portfolio} hiddenVal />
                  </div>
                  <ScrollToTop />
                </div>
              )}

              {showSecurityDetailsSection(portfolio, SECURITY_UNDERLYING_MODEL) && (
                <div className="result-container prism-container">
                  <div className="result-heading">
                    Top 10 Holdings <NewTag />
                  </div>
                  <div className="sector-exposure-container">
                    <TopHoldings portfolio={portfolio} hiddenVal />
                  </div>
                  <ScrollToTop />
                </div>
              )}

              {showSecurityDetailsSection(portfolio, SECURITY_REGIONS) && (
                <div className="result-container prism-container">
                  <div className="result-heading" id="geographic-exposure--header">
                    Geographic Exposure <NewTag />
                  </div>
                  <div className="geographic-exposure-container">
                    <GeographicExposure portfolio={portfolio} hiddenVal />
                  </div>
                  <ScrollToTop />
                </div>
              )}

              {calculating || showEditModal ? (
                <div className="calculating-portfolio-risk">
                  <LoadingPrismDataMessage
                    message={`${calculating ? 'Calculating' : 'Preparing'} Portfolio Risk...`}
                    inset
                  />
                </div>
              ) : (
                !_.isEmpty(portfolio.positions) && (
                  <div
                    className="security-type-concentration-section"
                    id="security-type-concentration-scroll"
                  >
                    <h3>Security Type Concentration</h3>
                    <SecurityTypeConcentration
                      id={`${SECURITY_TYPE_CONCENTRATION_CHART_ID}-${portfolio.id}`}
                      positions={portfolio.positions}
                      totalAssets={portfolio.value}
                    />
                    <ScrollToTop />
                  </div>
                )
              )}

              <Disclosure />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

AdvisorModelsDetailsOverview.defaultProps = {
  portfolio: {},
  riskAnalysis: {},
  riskData: undefined
};

AdvisorModelsDetailsOverview.propTypes = {
  calculating: PropTypes.bool.isRequired,
  marketStore: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  portfolio: PropTypes.object,
  riskAnalysis: PropTypes.object,
  riskAnalysisRequired: PropTypes.bool.isRequired,
  riskData: PropTypes.object,
  route: PropTypes.object.isRequired,
  showEditModal: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { riskAnalysisRequired = {} } = state.models;
  const { id } = ownProps.params;
  return {
    marketStore: state.market,
    portfolio: state.models.view,
    riskAnalysis: state.models.riskAnalysis,
    riskAnalysisRequired: !!riskAnalysisRequired[id]
  };
};

const AdvisorModelsDetailWithRouter = withRouter(AdvisorModelsDetailsOverview);

export default connect(mapStateToProps)(AdvisorModelsDetailWithRouter);
