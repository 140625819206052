import React from 'react';
import './styles.scss';

const LoadingWave = () => (
  <div className="loader-container">
    <div className="pl-wave">
      <div className="loader">
        <span />
      </div>
    </div>
  </div>
);

export default LoadingWave;
