import PropTypes from 'prop-types';
import React from 'react';

export const CUSTOMER_SUPPORT_EMAIL = 'support@stratifi.com';
export const CUSTOMER_SUPPORT_NUMBER = '8663412545';
export const CUSTOMER_SUPPORT_NUMBER_WITH_FORMAT = '(866) 341-2545';

export const CustomerSupportEmailLink = ({ label }) => (
  <a href={`mailto:${CUSTOMER_SUPPORT_EMAIL}`}>{label}</a>
);

CustomerSupportEmailLink.defaultProps = {
  label: CUSTOMER_SUPPORT_EMAIL
};

CustomerSupportEmailLink.propTypes = {
  label: PropTypes.string
};

export const CustomerSupportPhoneLink = ({ label }) => (
  <a href={`tel:${CUSTOMER_SUPPORT_NUMBER}`}>{label}</a>
);

CustomerSupportPhoneLink.defaultProps = {
  label: CUSTOMER_SUPPORT_NUMBER_WITH_FORMAT
};

CustomerSupportPhoneLink.propTypes = {
  label: PropTypes.string
};
