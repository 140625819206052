export default {
  startDuration: 0,
  marginRight: 0,
  marginLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  graphs: [
    {
      balloonText: `
        <span class="score-info">
          <span class="title">[[name]] $[[value]]</span>
          <span class="value-container">
            <span class="column">
              <span class="balloon-score-risk balloon-score-risk-[[roundedPrism]]">
                [[xDisplay]]
              </span>
              <span class="balloon-label">PRISM</span>
            </span>
            <span class="column">
              <span class="balloon-score-risk balloon-score-risk-[[roundedTarget]]">
                [[yDisplay]]
              </span>
              <span class="balloon-label">Tolerance</span>
            </span>
          </span>
        </span>
      `,
      bullet: 'circle',
      minBulletSize: 10,
      maxBulletSize: 10,
      bulletBorderAlpha: 0,
      bulletAlpha: 0.6,
      lineAlpha: 0,
      fillAlphas: 0,
      valueField: 'value',
      xField: 'x',
      yField: 'y',
      classNameField: 'bulletclass'
    }
  ],
  valueAxes: [
    {
      autoGridCount: false,
      axisAlpha: 0,
      axisTitleOffset: 0,
      gridThickness: 0,
      labelFrequency: 1,
      labelsEnabled: false,
      maximum: 10,
      minimum: 0,
      position: 'bottom',
      zeroGridAlpha: 0
    },
    {
      autoGridCount: false,
      axisAlpha: 0,
      axisTitleOffset: 0,
      gridCount: 12,
      gridThickness: 0,
      labelFrequency: 1,
      labelsEnabled: false,
      maximum: 10,
      minimum: -2,
      position: 'left',
      zeroGridAlpha: 0
    }
  ]
};
