import _ from 'lodash';

import {
  INVESTOR_GOAL_LIST,
  INVESTOR_GOAL_CREATE,
  INVESTOR_GOAL_DELETE,
  INVESTOR_GOAL_EDIT
} from 'constants/actions';

const initialState = {
  investors: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INVESTOR_GOAL_LIST: {
      const investorsGoals = {};

      action.data.forEach(goal => {
        investorsGoals[goal.investor] = [...(investorsGoals[goal.investor] || []), goal];
      });

      return {
        ...state,
        investors: {
          ...state.investors,
          ...investorsGoals
        }
      };
    }
    case INVESTOR_GOAL_CREATE: {
      const { investor: investorId } = action.data;
      return {
        ...state,
        investors: {
          ...state.investors,
          [investorId]: [...(state.investors[investorId] || []), action.data]
        }
      };
    }
    case INVESTOR_GOAL_DELETE: {
      const { investor: investorId, id: goalId } = action.data;
      return {
        ...state,
        investors: {
          ...state.investors,
          [investorId]: (state.investors[investorId] || []).filter(goal => goal.id !== goalId)
        }
      };
    }
    case INVESTOR_GOAL_EDIT: {
      const goalEdited = action.data;
      const { investor: investorId } = goalEdited;
      return {
        ...state,
        investors: {
          ...state.investors,
          [investorId]: [
            ...state.investors[investorId].map(goal => {
              if (goal.id === goalEdited.id) return { ...goal, ...goalEdited };
              return goal;
            })
          ]
        }
      };
    }

    default:
      return state;
  }
}
