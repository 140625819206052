const FONT_TAG_RE = /<font\s+([^>]*)>([\s\S]*?)<\/font>/gi;
const COLOR_ATTRIBUTE_RE = /color\s*=\s*["']([^"']+)["']/i;
const STYLE_ATTRIBUTE_RE = /style\s*=\s*["']([^"']+)["']/i;

export const clearInvalidRules = html =>
  html.replace(
    /(float:(.+?);|color:(.+?|)inherit;|font-weight:(.+?|)(bolder|lighter);|font-size:(.+?|)(medium|xx-small|x-small|small|large|x-large|xx-large|smaller|larger|inherit|initial);)/gi,
    ''
  );

export const replaceFontWithSpan = html => {
  const replace = (match, attributes, content) => {
    // check for `color` attribute and extract its value
    const colorMatch = attributes.match(COLOR_ATTRIBUTE_RE);
    const color = colorMatch ? `color:${colorMatch[1]};` : '';

    // check for `style` attribute and extract its value
    const styleMatch = attributes.match(STYLE_ATTRIBUTE_RE);
    const style = styleMatch ? `style="${styleMatch[1]};${color}"` : `style="${color}"`;

    // create the replacement `span` tag with preserved attributes
    return `<span ${style}>${content}</span>`;
  };
  return html.replace(FONT_TAG_RE, replace);
};

export const sanitizeHtmlContent = html => replaceFontWithSpan(clearInvalidRules(html));
