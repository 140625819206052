import NavigationAnonymous from 'containers/navigation/anonymous';
import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header';

const HeaderAnonymous = ({ advisor, name }) => (
  <Header mode="anonymous" advisor={advisor} navigation={<NavigationAnonymous name={name} />} />
);

HeaderAnonymous.propTypes = {
  advisor: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default HeaderAnonymous;
