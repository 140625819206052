import cn from 'classnames';
import Slider from 'components/slider/base';
import { getLocale } from 'lang/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useTolerableLoss from '../hooks';
import './styles.scss';

const TolerableLossQuestion = ({ question, field, ...props }) => {
  const {
    defaultLoss,
    gain,
    initialInvestment,
    isEntityQuestion,
    labels,
    onChange,
    potentialLoss,
    recoveryTime,
    withdrawalStartsIn
  } = useTolerableLoss({
    field,
    question
  });

  const locale = getLocale();
  const getTooltipContent = useCallback(value => `${value}%`, []);
  moment.locale(locale);

  const constants = question.data?.constants || {};
  const remainingMoney = initialInvestment - potentialLoss;
  const recoveryTimeDisplay = recoveryTime
    ? moment.duration(recoveryTime, 'y').humanize({ w: 0 })
    : '-';

  return (
    <Question {...props} question={question} nextDisabled={field.invalid}>
      <div className="summary">
        <FormattedMessage
          id="form.tolerable-loss.loss-description"
          defaultMessage={constants.loss_description}
          values={{
            gain,
            initialInvestment: <FormattedNumber value={initialInvestment} format="currency" />,
            isEntity: isEntityQuestion,
            potentialLoss: <FormattedNumber value={potentialLoss} format="currency" />,
            recoveryTimeDisplay,
            remainingMoney: <FormattedNumber value={remainingMoney} format="currency" />,
            value: field.value
          }}
        />
        <span className="tip">
          <span data-tip="" data-for="return-info" className="qmark">
            ?
          </span>
          <ReactTooltip className="tip" effect="solid" id="return-info" place="bottom" type="dark">
            <FormattedMessage
              id="form.tolerable-loss.expected-market-return"
              defaultMessage={constants.expected_market_return}
            />
          </ReactTooltip>
        </span>
      </div>

      <div className="losses-content">
        <div className="boxes">
          <div className="chart-box box">
            <Slider
              withHandle
              withTooltip
              className={cn({ '-withDots': question.data?.dots })}
              labels={labels}
              onChange={onChange}
              defaultValue={defaultLoss}
              value={field.value}
              getTooltipContent={getTooltipContent}
            />
          </div>
        </div>
        <div className="boxes">
          <div className="box return-box">
            <p>
              <FormattedMessage
                id="form.tolerable-loss.potential-loss"
                defaultMessage={constants.potential_loss}
              />{' '}
              ($)
            </p>
            <span className="return-metric top red">
              <FormattedNumber value={potentialLoss} format="currency" />
            </span>
          </div>
          <div className="box return-box">
            <p>
              <FormattedMessage
                id="form.tolerable-loss.recovery-return-needed"
                defaultMessage={constants.recovery_return_needed}
              />
            </p>
            <span className="return-metric green">{gain}%</span>
          </div>
          <div className="box return-box">
            <p>
              <FormattedMessage
                id="form.tolerable-loss.recovery-time"
                defaultMessage={constants.recovery_time}
                values={{ isEntity: isEntityQuestion }}
              />
            </p>
            <span className="return-metric top">{recoveryTimeDisplay}</span>
          </div>
        </div>
        <div className="looses-disclaimer">
          <FormattedMessage
            id="form.tolerable-loss.withdrawal-start"
            defaultMessage={constants.withdrawal_start}
            values={{ isEntity: isEntityQuestion, years: withdrawalStartsIn }}
          />
        </div>
      </div>
    </Question>
  );
};

TolerableLossQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default TolerableLossQuestion;
