import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const ChoicesQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  const selection = question.data?.options
    .filter(choice => question.answer.value.includes(choice.value))
    .map(c => c.display_text ?? c.text ?? c.value)
    .join(', ');

  return (
    <ReportQuestion question={question}>
      <Text>{selection}</Text>
    </ReportQuestion>
  );
};

ChoicesQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ChoicesQuestion;
