import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.onHideModal = this.onHideModal.bind(this);
    this.onClick = this.onClick.bind(this);
    this.getName = this.getName.bind(this);
  }

  onHideModal() {
    const { onHide } = this.props;
    onHide();
  }

  onClick() {
    const { item, type, onDelete } = this.props;
    onDelete(item, type);
  }

  getName() {
    const { type, item } = this.props;
    if (type === 'account') return item.display_name;
    if (type === 'investor') return item.full_name;
    if (type === 'goal') return item.name;
    return '';
  }

  render() {
    const { show, submitting, item, label, type } = this.props;

    if (!item) return null;

    return (
      <Modal
        id="delete-general-modal"
        ref={c => (this.modal = c)}
        className="modal-lg"
        show={show}
        onHidden={this.onHideModal}
      >
        <ModalHeader />
        <ModalBody>
          <h3 className="modal-title">Are you sure you want to delete this {label || type} ? </h3>
          <p className="description top">
            <b>!</b>
            {this.getName()}
          </p>
          <p className="description bottom">You can&apos;t revert this change.</p>
          <div className="text-sm-center actions">
            <LoadingButton loading={submitting} onClick={this.onClick}>
              Delete
            </LoadingButton>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  item: PropTypes.object,
  label: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  submitting: PropTypes.bool,
  type: PropTypes.string
};

DeleteModal.defaultProps = {
  item: null,
  label: '',
  show: false,
  submitting: false,
  type: ''
};
