import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cs from 'classnames';

import './styles.scss';

const FeatureLocked = ({ id, name, inline, mode }) => (
  <div id="FeatureLocked" className={cs({ inline: inline || mode === 'investor' })}>
    <div className="placeholder-img">
      <img src={`/img/pages/${id}.png`} alt="" />
    </div>

    {mode === 'advisor' && (
      <>
        <p>Upgrade your plan to unlock the {name}</p>

        <div className="actions">
          <Link className="btn btn-primary" to="/advisor/me/billing">
            Upgrade
          </Link>
        </div>
      </>
    )}
  </div>
);

FeatureLocked.propTypes = {
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string.isRequired
};

FeatureLocked.defaultProps = {
  inline: false,
  mode: 'advisor'
};

export default FeatureLocked;
