import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.9 19.9"
  >
    <title>checkmark_filled_blue</title>
    <path
      className="cls-1"
      d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0ZM8.25,14.39l-.08.07h0l-.33-.41L3.93,9.3l1.5-1.23,3,3.67,6.79-5.51,1.22,1.5Z"
      transform="translate(-0.05 -0.04)"
    />
  </svg>
);
