import cn from 'classnames';
import InlineTextEditor from 'components/inline-text-editor';
import TrashCan from 'components/svg-icons/trash-can-v2';
import DisplayScore from 'components/utils/DisplayScore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

class InvestorGoalListRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false
    };
    this.toggleDetails = this.toggleDetails.bind(this);
    this.saveName = this.saveName.bind(this);
  }

  toggleDetails = () => {
    const { showDetails } = this.state;
    this.setState({ showDetails: !showDetails });
  };

  saveName(name) {
    const { goal } = this.props;
    const { investorGoalsProvider } = this.context;

    investorGoalsProvider.update(goal.id, { name }).then(() => {
      toast.success(() => <div>Goal name Updated successfully.</div>);
    });
  }

  render() {
    const {
      goal,
      goal: {
        aggregated_prism_scores: prismScores,
        aggregated_target_scores: { overall: targetScore } = {},
        drift_summary: driftSummary,
        is_healthy: isHealthy,
        target_questionnaire_score: stratifiRiskScores
      },
      goalInvestmentsReadOnly,
      children,
      toggleDeleteModal
    } = this.props;

    const { showDetails } = this.state;

    const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';

    return (
      <tbody className={cn('InvestorGoalListRow', { toggable: !_.isEmpty(goal.accounts) })}>
        <tr onClick={this.toggleDetails}>
          <td className="goal-name">
            <i className="fs-icon-goal" />
            {goalInvestmentsReadOnly ? (
              goal.name
            ) : (
              <InlineTextEditor text={goal.name} onChange={this.saveName} />
            )}
          </td>
          <td className="text-sm-center header-font-size goal-accounts-number">
            {goal.accounts.length ? goal.accounts.length : ''}
          </td>
          <td className="text-sm-right header-font-size goal-value">
            {!!goal.value && <FormattedNumber value={goal.value} format="currency" />}
          </td>
          <td className="text-sm-center header-font-size goal-prism">
            {!_.isEmpty(prismScores) && <DisplayScore score={prismScores.overall} />}
          </td>
          <td className="text-sm-center header-font-size goal-target">
            {targetScore &&
              (stratifiRiskScores ? (
                <DisplayScore score={targetScore} manualScore={!stratifiRiskScores} />
              ) : (
                <span data-tip="Risk Tolerance manually entered" data-for="target-score">
                  <DisplayScore score={targetScore} manualScore={!stratifiRiskScores} />
                  <ReactTooltip
                    id="target-score"
                    className="manual-score-tutorial-tip"
                    effect="solid"
                    place="top"
                  />
                </span>
              ))}
          </td>
          <td
            className={cn('text-sm-center', 'header-font-size', {
              drift: 'drift',
              red: !isHealthy
            })}
          >
            <span>{drift}</span>
          </td>
          <td className="manage-overlay-td text-sm-right header-font-size" style={{ width: 0 }}>
            {!_.isEmpty(goal.accounts) && (
              <button
                className="btn-arrow btn-transparent"
                type="button"
                onClick={this.toggleDetails}
              >
                {showDetails ? (
                  <img
                    src="/img/icons/uparrow_blue.svg"
                    width="17px"
                    height="17px"
                    alt="uparrow"
                    className="collapse-action"
                  />
                ) : (
                  <img
                    src="/img/icons/downarrow_blue.svg"
                    width="17px"
                    height="17px"
                    alt="downarrow"
                    className="expand-action"
                  />
                )}
              </button>
            )}
            {!goalInvestmentsReadOnly && (
              <button
                type="button"
                onClick={e => toggleDeleteModal(e, goal, 'goal')}
                className="delete-goal"
              >
                <TrashCan className="trash-icon" />
              </button>
            )}
          </td>
        </tr>
        <tr className="separator" colSpan={8} />
        {showDetails && (
          <tr>
            <td className="accounts-container" colSpan={7}>
              {children}
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}

InvestorGoalListRow.propTypes = {
  goal: PropTypes.object.isRequired,
  goalInvestmentsReadOnly: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  toggleDeleteModal: PropTypes.func.isRequired
};

InvestorGoalListRow.defaultProps = {
  children: null
};

InvestorGoalListRow.contextTypes = {
  investorGoalsProvider: PropTypes.object.isRequired
};

export default InvestorGoalListRow;
