import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const ValueRequirementEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion questions={questions} question={question}>
    <View style={styles.multiOptions}>
      <View style={[styles.markCheck, { marginRight: 5 }]} />
      <Text>I will need an annual income of</Text>
      <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
      <Text>from this investment.</Text>
    </View>
    <View style={styles.multiOptions}>
      <View style={[styles.markCheck, { marginRight: 5 }]} />
      <Text>I need</Text>
      <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
      <Text>on my retirement from this investment.</Text>
    </View>
  </ReportEmptyQuestion>
);

ValueRequirementEmptyQuestion.propTypes = propTypes;

export default ValueRequirementEmptyQuestion;
