// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-provider-item {
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  padding: 15px;
  border: 2px solid white;
}
.model-provider-item:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.model-provider-item--active {
  border: 2px solid #09acf8;
  color: white;
}
.model-provider-item__logo {
  width: 170px;
  height: 40px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/app/components/model-provider-item/styles.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,yCAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;AADF;AAGE;EACE,yCAAA;AADJ;AAIE;EACE,yBAAA;EACA,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,YAAA;EACA,wBAAA;EACA,kCAAA;EACA,4BAAA;AAHJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.model-provider-item {\n  background-color: white;\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n  transition: all 0.3s ease;\n  padding: 15px;\n  border: 2px solid white;\n\n  &:hover {\n    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);\n  }\n\n  &--active {\n    border: 2px solid $primary;\n    color: white;\n  }\n\n  &__logo {\n    width: 170px;\n    height: 40px;\n    background-size: contain;\n    background-position: center center;\n    background-repeat: no-repeat;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
