export const POSITIVE_COLOR = '#6bb44d';
export const NEGATIVE_COLOR = '#bb3f3d';

const EXPECTED_RETURN_CHART_DATA = [
  {},
  { negative: -2.25, positive: 4.25 },
  { negative: -4.5, positive: 8.5 },
  { negative: -6.25, positive: 12.25 },
  { negative: -8, positive: 16 },
  { negative: -9.75, positive: 19.75 },
  { negative: -11.25, positive: 23.25 },
  { negative: -12.75, positive: 26.75 },
  { negative: -14.25, positive: 30.25 },
  { negative: -15.75, positive: 33.75 },
  { negative: -17, positive: 37 }
];

export default EXPECTED_RETURN_CHART_DATA;
