import { SERVER_ERROR, CLEAR_SERVER_ERRORS, SKIP_ERROR } from 'constants/actions';

export default class ServerErrorsProvider {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  registerFetchError(response, itemsName) {
    this.dispatch({
      type: SERVER_ERROR,
      data: `Sorry, internal server error occurred while fetching ${itemsName}`
    });
  }

  registerError(message) {
    this.dispatch({
      type: SERVER_ERROR,
      data: message
    });
  }

  skipError(index) {
    this.dispatch({
      type: SKIP_ERROR,
      data: index
    });
  }

  clearErrors() {
    this.dispatch({ type: CLEAR_SERVER_ERRORS });
  }
}
