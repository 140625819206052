import PropTypes from 'prop-types';
import React from 'react';
import { Document, Page } from 'react-pdf';
import './styles.scss';

const FIRST_PAGE = 1;
const WIDTH_RATIO = 176;
const HEIGHT_RATIO = 250;

const ReportThumbnailPlaceholder = ({ height, message }) => (
  <div
    className="report-thumbnail__placeholder"
    style={{ height, width: (height * WIDTH_RATIO) / HEIGHT_RATIO }}
  >
    {message}
  </div>
);

ReportThumbnailPlaceholder.propTypes = {
  height: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};

const ReportThumbnail = ({ height, url }) => {
  if (!url) return null;
  return (
    <div className="report-thumbnail">
      <Document
        file={url}
        error={
          <ReportThumbnailPlaceholder
            height={height}
            message="Something went wrong while generating the report"
          />
        }
        loading={<ReportThumbnailPlaceholder height={height} message="Loading ..." />}
      >
        <Page
          height={height}
          pageNumber={FIRST_PAGE}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </div>
  );
};

ReportThumbnail.defaultProps = {
  height: 250,
  url: null
};

ReportThumbnail.propTypes = {
  height: PropTypes.number,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element])
};

export default ReportThumbnail;
