import { TEMPLATE_CREATE, TEMPLATE_GET, TEMPLATE_LIST, TEMPLATE_UPDATE } from 'constants/templates';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEMPLATE_LIST:
      return action.data.reduce((acum, t) => ({ ...acum, [t.id]: t }), state);

    case TEMPLATE_CREATE:
    case TEMPLATE_GET:
    case TEMPLATE_UPDATE:
      return {
        ...state,
        [action.data.id]: action.data
      };

    default:
      return state;
  }
}
