import XYDistributionChart from 'components/charts/xy-distribution-chart';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import defaultOptions from './options';
import './styles.scss';

const pointStatus = item => {
  const targetScore = item.target_overall;
  const prismScore = item.prism_overall;
  const classes =
    `${Number(prismScore) === 10 ? 'maximum-prism' : ''}` +
    `${Number(targetScore) > 9.7 ? 'maximum-target' : ''}`;

  if (item.snooze) return `snoozed ${classes}`;
  if (!targetScore || !prismScore)
    return (
      `${!prismScore ? 'missing-prism ' : ''}` +
      `${!targetScore ? 'missing-target ' : ''}` +
      `${classes}`
    );

  if (item.drift_overall < 2) return `ideal ${classes}`;
  return `warning ${classes}`;
};

const distributionToYXData = distribution => {
  if (!distribution) return null;

  const distributionMapped = distribution.map(item => ({
    itemId: item.id,
    name: `${item.name} ${item.snooze ? '(Snoozed)' : ''}`,
    url: item.url,
    bulletclass: pointStatus(item),
    roundedPrism: item.prism_overall ? Math.round(item.prism_overall) : 0,
    roundedTarget: item.target_overall ? Math.round(item.target_overall) : 0,
    value: item.value,
    xDisplay: item.prism_overall ? item.prism_overall.toFixed(1) : '?',
    yDisplay: item.target_overall ? item.target_overall.toFixed(1) : '?',
    x: item.prism_overall ? item.prism_overall.toFixed(1) : 0,
    y: item.target_overall ? item.target_overall.toFixed(1) : -1
  }));

  return distributionMapped;
};

const DriftDistributionChart = ({ id, distribution, handleClick, showLegend }) => {
  if (_.isEmpty(distribution)) return null;

  const data = distributionToYXData(distribution);
  return (
    <div className="drift-distribution-chart">
      <div className="overlay-x">
        <div className="score-line" />
        <div className="score-offset" />
        <div className="score-label">PRISM Rating</div>
      </div>

      <div className="overlay-y">
        <div className="score-line" />
        <div className="score-label">Risk Tolerance</div>
      </div>

      {showLegend && (
        <div className="legend">
          <span className="blue-point" />
          Ideal range
        </div>
      )}

      <div className="chart-container">
        <XYDistributionChart
          id={id}
          data={data}
          options={defaultOptions}
          yTitle=" "
          listeners={{ clickGraphItem: handleClick }}
        />
      </div>
    </div>
  );
};

DriftDistributionChart.propTypes = {
  id: PropTypes.string,
  distribution: PropTypes.array,
  showLegend: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

DriftDistributionChart.defaultProps = {
  id: 'drift-distribution-chart',
  distribution: []
};

export default withRouter(DriftDistributionChart);
