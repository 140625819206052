import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const BAR_MAX_HEIGHT = 150;

const PotentialDownsideChart = ({ color, maxValue, value }) => {
  const height = (value / maxValue) * BAR_MAX_HEIGHT;
  return (
    <div className="potential-downside-chart">
      <div className="potential-downside-chart__bar" style={{ backgroundColor: color, height }} />
      <div className="potential-downside-chart__percentage">-{(value * 100).toFixed(2)}%</div>
    </div>
  );
};

PotentialDownsideChart.propTypes = {
  color: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default PotentialDownsideChart;
