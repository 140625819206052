import cn from 'classnames';
import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const AdministrativePermissionsTooltip = ({
  children,
  className,
  renderTooltip,
  wrapperClassName,
  text,
  tooltipId
}) => (
  <TooltipV2
    className={cn('administrative-permissions-tooltip', className)}
    id={tooltipId}
    label={text}
    renderTooltip={renderTooltip}
  >
    <div
      className={cn('administrative-permissions-tooltip__wrapper', wrapperClassName)}
      data-disabled={renderTooltip}
      data-for={tooltipId}
      data-tip=""
    >
      {children}
    </div>
  </TooltipV2>
);

AdministrativePermissionsTooltip.defaultProps = {
  className: '',
  renderTooltip: false,
  text: 'Please ask an administrative role to make changes to this template.',
  wrapperClassName: ''
};

AdministrativePermissionsTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  className: PropTypes.string,
  renderTooltip: PropTypes.bool,
  text: PropTypes.string,
  tooltipId: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string
};

export default AdministrativePermissionsTooltip;
