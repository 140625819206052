import { Path, Svg, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import {
  PRISM_COLOR_0,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_5,
  PRISM_COLOR_9
} from 'reports/base/styles';

const IconDefaultProps = {
  height: 35,
  style: {},
  width: 35
};

const IconProps = {
  height: PropTypes.number,
  style: PropTypes.object,
  width: PropTypes.number
};

export const BalancedIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 61 72">
      <Path
        fill={PRISM_COLOR_3}
        d="m.02,37.9c0-1,.4-2,1.1-2.7.7-.7,1.7-1.1,2.7-1.1h28c.8,0,1.5.2,2.1.7.6.4,1.1,1,1.4,1.7-2.4,1.3-4.4,3.1-6,5.2H3.82c-1,0-2-.4-2.7-1.1-.7-.7-1.1-1.7-1.1-2.7H.02Zm3.8-6.3h28c1.4,0,2.6-.7,3.3-1.9.7-1.2.7-2.6,0-3.8-.7-1.2-1.9-1.9-3.3-1.9h-11.5v-11.8c2.8.4,5.6.1,8.2-1.1,3.4-1.8,6.2-4.7,7.9-8.2-3.8-.7-7.8-.2-11.3,1.4-1.9,1-3.5,2.4-4.7,4.1V1.4c0-.4-.1-.7-.3-1-.3-.2-.7-.4-1-.4-.4,0-.7.2-.9.4-.2.3-.4.6-.3,1v8.2c-1.2-1.7-2.8-3.2-4.7-4.2-3.7-1.7-7.6-2.2-11.4-1.5,1.7,3.5,4.4,6.4,7.9,8.2,2.6,1.2,5.4,1.6,8.2,1.2v10.7H3.92c-1.4,0-2.6.7-3.3,1.9-.7,1.2-.7,2.6,0,3.8.6,1.2,1.9,1.9,3.2,1.9h0Zm0,20.2h21.7c.2-2.6.9-5.2,2.1-7.6H3.82c-1.4,0-2.6.7-3.3,1.9-.7,1.2-.7,2.6,0,3.8.7,1.2,2,1.9,3.3,1.9h0Zm57.2,1.3c0,4.4-1.7,8.5-4.8,11.6s-7.3,4.8-11.7,4.8-8.6-1.7-11.7-4.8c-3.1-3.1-4.8-7.3-4.8-11.6s1.7-8.5,4.8-11.6,7.3-4.8,11.7-4.8,8.6,1.7,11.7,4.8c3.1,3,4.8,7.2,4.8,11.6Zm-2.5,0c0-3.7-1.5-7.2-4.1-9.8s-6.2-4.1-9.9-4.1-7.3,1.5-9.9,4.1c-2.6,2.6-4.1,6.1-4.1,9.8s1.5,7.2,4.1,9.8c2.6,2.6,6.2,4.1,9.9,4.1s7.3-1.5,9.9-4.1c2.6-2.6,4.1-6.2,4.1-9.8ZM3.82,61.9h23.8c-1.2-2.3-2-4.9-2.1-7.6H3.82c-1.4,0-2.6.7-3.3,1.9-.7,1.2-.7,2.6,0,3.8.7,1.2,2,1.9,3.3,1.9h0Zm52.1-8.8c0,3-1.2,5.9-3.4,8-2.1,2.1-5.1,3.3-8.1,3.3s-5.9-1.2-8.1-3.3c-2.1-2.1-3.4-5-3.4-8s1.2-5.9,3.4-8c2.1-2.1,5.1-3.3,8.1-3.3s5.9,1.2,8.1,3.3c2.2,2.1,3.4,4.9,3.4,8Zm-6.3,2.5c0-1-.5-1.9-1.2-2.6-.7-.7-1.7-1.1-2.7-1.2h-2.5c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h3.8c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3h-1.3v-1.3c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3,1.3v1.3c-1.4,0-2.6.7-3.3,1.9s-.7,2.6,0,3.8,1.9,1.9,3.3,1.9h2.5c.7,0,1.3.6,1.3,1.3s-.6,1.3-1.3,1.3h-3.8c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h1.3v1.3c0,.7.6,1.3,1.3,1.3s1.3-.6,1.3-1.3v-1.3c1,0,1.9-.4,2.7-1.2.7-.7,1.1-1.6,1.2-2.6Zm-20.3,8.8H3.82c-1.4,0-2.6.7-3.3,1.9-.7,1.2-.7,2.6,0,3.8.7,1.2,2,1.9,3.3,1.9h28c1,0,2-.4,2.7-1.1.4-.4.6-.8.8-1.3-2.4-1.3-4.4-3-6-5.2h0Z"
      />
    </Svg>
  </View>
);

BalancedIcon.propTypes = { ...IconProps };
BalancedIcon.defaultProps = { ...IconDefaultProps };

export const CautiousIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 39 72">
      <Path
        fill={PRISM_COLOR_2}
        d="m12.8,27.2v10.2c-.3-.5-.6-.9-.9-1.4l-.2-4.1c-.1-1.6-.8-2.9-1.7-3.9-1-1-2.3-1.7-3.7-1.9l-4.3-.5c-.3,0-.6.1-.8.3-.2.2-.3.5-.3.8l.2,4.4h0c.1,1.4.7,2.8,1.7,3.8,1,1,2.3,1.7,3.7,1.9l3.9.5h0c.9,1.4,1.7,2.9,2.4,4.5v.8h4.3c.1-.1.1-.2.2-.3l3.9-.5h0c1.4-.2,2.7-.9,3.7-1.9s1.6-2.4,1.7-3.8l.2-4.4h0c0-.3-.1-.6-.3-.8s-.5-.3-.8-.3l-4.4.4c-1.4.2-2.7.9-3.7,1.9-1,1-1.6,2.4-1.7,3.8l-.2,4.1c-.3.5-.6.9-.9,1.4v-15.1c4.7-.4,8.9-3.1,11.1-7.4,2.2-4.2,2-9.3-.5-13.3C22.9,2.5,18.5,0,13.8,0S4.7,2.5,2.2,6.5C-.3,10.5-.5,15.6,1.7,19.8c2.2,4.2,6.4,7,11.1,7.4h0Zm-2.7-11c0-.6.5-1,1-1,.6,0,1,.5,1,1v.8c0,.9.7,1.6,1.6,1.6s1.6-.7,1.6-1.6v-.7c0-1.2-.4-1.3-1.9-1.7-1.4-.3-3.4-.8-3.4-3.6v-.7c0-.8.3-1.6.7-2.2.5-.6,1.1-1.1,1.9-1.3v-1.6c0-.6.5-1,1-1,.6,0,1,.5,1,1v1.5c.8.2,1.4.7,1.9,1.3s.7,1.4.7,2.2v.8c0,.6-.5,1-1,1-.6,0-1-.5-1-1v-.7c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6,1.6v.7c0,1.2.4,1.3,1.9,1.7,1.4.3,3.4.8,3.4,3.6v.7c0,.8-.3,1.6-.7,2.2-.5.6-1.1,1.1-1.9,1.3v1.5c0,.6-.5,1-1,1-.6,0-1-.5-1-1v-1.5c-.8-.2-1.4-.7-1.9-1.3-.5-.6-.7-1.4-.7-2.2,0,0,0-.8,0-.8Zm16.5,33.6H1.4c-.8,0-1.4-.6-1.4-1.4v-2.6c0-.8.6-1.4,1.4-1.4h25.2c.8,0,1.4.6,1.4,1.4v2.6c0,.8-.6,1.4-1.4,1.4Zm12.4,3.5v2.6c0,.8-.6,1.4-1.4,1.4H12.4c-.8,0-1.4-.6-1.4-1.4v-2.6c0-.8.6-1.4,1.4-1.4h25.2c.8,0,1.4.6,1.4,1.4h0Zm-5.8,14.7v2.6c0,.8-.6,1.4-1.4,1.4H6.6c-.8,0-1.4-.6-1.4-1.4v-2.6c0-.8.6-1.4,1.4-1.4h25.2c.8,0,1.4.7,1.4,1.4h0Zm0-7.3v2.6c0,.8-.6,1.4-1.4,1.4H6.6c-.8,0-1.4-.6-1.4-1.4v-2.6c0-.8.6-1.4,1.4-1.4h25.2c.8,0,1.4.6,1.4,1.4h0Z"
      />
    </Svg>
  </View>
);

CautiousIcon.propTypes = { ...IconProps };
CautiousIcon.defaultProps = { ...IconDefaultProps };

export const GrowthOrientedIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 80.14 70.9">
      <Path
        fill={PRISM_COLOR_5}
        d="m78.73,41.8c-5.4,4.4-9.3,7.8-12,10.3-5.6,5-7.9,7.1-12.6,7.4l-26,1.6c-.4.3-.8.6-1,.9,0-.1,0-.3-.1-.3l-6-20.4c-.4-1.1-.9-2.1-1.6-2.9l1.9-2.8c2.3-3.3,5.9-5.1,9.8-5.1h17.4c3.3,0,6,2.6,6,5.9s-2.6,5.9-6,5.9h-10.6c-1,0-1.8.8-1.8,1.9,0,1,.8,1.8,1.8,1.8h17.8c.9-.5,4.6-2.6,18.3-10.5,2.4-1.4,5-.1,5.8,2.1.7,1.3.2,3.1-1.1,4.2h0Z"
      />
      <Path
        fill={PRISM_COLOR_5}
        d="m24.03,65.7c-.6,1-1.5,1.9-2.6,2.1l-9.6,2.8c-.4.1-.9.3-1.3.3-2,0-3.8-1.4-4.4-3.3L.23,47.2c-.8-2.4.6-4.9,3.1-5.6l9.6-2.8c.4-.1.9-.1,1.3-.1,2,0,3.8,1.4,4.4,3.3l6,20.4c.1,1,0,2.2-.6,3.3h0Z"
      />
      <Path fill={PRISM_COLOR_5} d="m33.53,11.9v2.3c.8-.1,1.4-.4,1.4-1.1-.1-.8-.6-1.1-1.4-1.2Z" />
      <Path
        fill={PRISM_COLOR_5}
        d="m33.03,0c-6,0-10.8,4.9-10.8,10.8s4.9,10.8,10.8,10.8,10.8-4.9,10.8-10.8S38.93,0,33.03,0Zm.5,15.5v.9h-.9v-1c-1.1-.1-2.3-.6-3.3-1.4l1-1.1c.8.6,1.5,1,2.3,1.1v-2.5c-1.8-.4-2.9-1-2.9-2.6s1.3-2.5,2.9-2.6v-.5h.9v.6c1,.1,1.9.4,2.8,1l-.9,1.3c-.6-.4-1.3-.6-1.9-.8v2.5c1.6.4,2.9,1,2.9,2.6,0,1.5-1.3,2.5-2.9,2.5Z"
      />
      <Path fill={PRISM_COLOR_5} d="m31.43,8.9c0,.8.5,1,1.3,1.1v-2.2c-.7.1-1.3.3-1.3,1.1Z" />
      <Path
        fill={PRISM_COLOR_5}
        d="m62.73,1.9c-9,0-16.3,7.1-16.3,15.9s7.3,15.9,16.3,15.9,16.3-7.1,16.3-15.9S71.73,1.9,62.73,1.9Zm.7,22.4v1.3h-1.1v-1.4c-1.6-.1-3.1-.9-4.5-2l1.4-1.6c1,.9,2,1.4,3.1,1.6v-3.5c-2.4-.6-4-1.4-4-3.6s1.8-3.5,4-3.6v-.9h1.1v.9c1.4.1,2.6.5,3.8,1.4l-1.1,1.6c-.8-.6-1.6-.9-2.6-1v3.4c2.4.5,4,1.4,4,3.8s-1.8,3.5-4.1,3.6Z"
      />
      <Path fill={PRISM_COLOR_5} d="m60.43,14.9c0,1,.8,1.3,1.8,1.6v-3.1c-.9.1-1.8.6-1.8,1.5Z" />
      <Path fill={PRISM_COLOR_5} d="m63.43,19v3.3c1-.1,1.9-.6,1.9-1.5,0-1-.9-1.4-1.9-1.8h0Z" />
    </Svg>
  </View>
);

GrowthOrientedIcon.propTypes = { ...IconProps };
GrowthOrientedIcon.defaultProps = { ...IconDefaultProps };

export const HighRiskTakerIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 71 71">
      <Path
        fill={PRISM_COLOR_9}
        d="m71,36.4h-5.3c-.2,8-3.6,15.2-8.8,20.5-5.3,5.3-12.5,8.6-20.5,8.8v5.3c9.5-.2,18-4.2,24.2-10.4,6.2-6.2,10.2-14.8,10.4-24.2h0Zm-7.1,0h-5.3c-.2,6-2.8,11.4-6.7,15.4l-.1.1c-2,2-4.4,3.6-7,4.8-1.9.8-3.8,1.3-5.8,1.6-.9.1-1.7.2-2.6.2v5.4c7.5-.2,14.3-3.4,19.2-8.3,5-4.9,8.1-11.7,8.3-19.2Zm-5.3-1.8h5.3c-.2-7.5-3.4-14.3-8.3-19.2-5-4.9-11.7-8.1-19.2-8.3v5.3c6,.2,11.4,2.8,15.4,6.7,4,4.1,6.5,9.5,6.8,15.5Zm7.1,0h5.3c-.2-9.5-4.2-18-10.4-24.2C54.4,4.2,45.8.2,36.4,0v5.3c8,.2,15.2,3.6,20.5,8.8,5.2,5.3,8.6,12.5,8.8,20.5h0Zm-31.1,36.4v-5.3c-8-.2-15.2-3.6-20.5-8.8-5.3-5.3-8.6-12.5-8.8-20.5H0c.2,9.5,4.2,18,10.4,24.2,6.2,6.2,14.8,10.2,24.2,10.4Zm0-7.1v-5.3c-.9,0-1.7-.1-2.6-.3-2-.3-3.9-.9-5.8-1.7-2.6-1.2-4.9-2.8-6.9-4.7l-.1-.1c-4-4-6.5-9.4-6.7-15.4h-5.4c.2,7.5,3.4,14.3,8.3,19.2,4.9,5,11.7,8.1,19.2,8.3Zm0-51.5v-5.3c-7.5.2-14.3,3.4-19.2,8.3-5,5-8.1,11.7-8.3,19.2h5.3c.2-6,2.8-11.5,6.8-15.5,4-3.9,9.4-6.4,15.4-6.7Zm0-7.1V0c-9.5.2-18,4.2-24.2,10.4C4.2,16.6.2,25.2,0,34.6h5.3c.2-8,3.6-15.2,8.8-20.5,5.3-5.2,12.5-8.6,20.5-8.8h0Zm-.3,20.6c-.5-.1-.8-.6-.6-1.1.1-.5.6-.8,1.1-.6l5.4,1.5c.5.1.8.6.6,1.1l-1.5,5.4c-.1.5-.6.8-1.1.6-.5-.1-.8-.6-.6-1.1l.9-3.4-23.3,13.5c.8,2.6,2.1,5,3.8,7.1v-5.3c0-.5.4-.9.9-.9h6.8c.5,0,.9.4.9.9v11.6c1.2.5,2.4.9,3.7,1.1v-18.7c0-.5.4-.9.9-.9h6.8c.5,0,.9.4.9.9v18.8c1.3-.3,2.5-.6,3.7-1.1v-25.6c0-.5.4-.9.9-.9h6.8c.5,0,.9.4.9.9v19.2c3-3.7,4.7-8.3,4.7-13.4,0-5.9-2.4-11.2-6.2-15.1-3.9-3.9-9.2-6.2-15.1-6.2s-11.2,2.4-15,6.2-6.3,9.2-6.3,15.1c0,1.6.2,3.1.5,4.5l23-13.3-3.5-.8Zm16,24.9v-20.2h-5v23.8c1.8-.9,3.5-2.1,5-3.6Zm-12.3,5.9v-18.2h-5v18.2c.8.1,1.6.1,2.5.1s1.7,0,2.5-.1Zm-12.3-2.3v-9.9h-5v6.3c1.5,1.5,3.2,2.7,5,3.6Z"
      />
    </Svg>
  </View>
);

HighRiskTakerIcon.propTypes = { ...IconProps };
HighRiskTakerIcon.defaultProps = { ...IconDefaultProps };

export const RiskAverseIcon = ({ height, style, width }) => (
  <View style={[{ width, height }, style]}>
    <Svg style={{ width, height }} viewBox="0 0 45.94 71">
      <Path
        fill={PRISM_COLOR_0}
        d="m45.94,69.8c-.3-1.2-.9-2.3-1.8-3.2-.9-.9-2-1.5-3.2-1.7-.3-2.3-1.4-4.4-3.2-5.9-1.8-1.4-4.1-2.1-6.4-1.7-.4-1.5-1.2-2.8-2.3-3.9-1.1-1.1-2.4-1.8-3.9-2.2.5-1,1.1-2,1.8-3l4.5-.6h0c1.6-.2,3-1,4.1-2.2s1.8-2.7,1.9-4.3l.3-5.1c0-.3-.1-.6-.3-.8s-.5-.3-.8-.3l-4.9.6c-1.6.2-3,1-4.1,2.2s-1.8,2.7-1.9,4.3l-.5,5c-.5.7-.9,1.5-1.4,2.3v-18.3c5.3-.3,10.1-3.5,12.6-8.3s2.3-10.5-.5-15.2c-2.7-4.7-7.6-7.5-13-7.5s-10.3,2.8-13.1,7.5c-2.8,4.6-3,10.4-.5,15.2,2.5,4.8,7.3,7.9,12.6,8.3v12.6c-.4-.8-.9-1.5-1.4-2.3l-.3-4.8c-.1-1.6-.8-3.1-1.9-4.3s-2.5-1.9-4.1-2.2l-4.9-.6c-.3,0-.6.1-.8.3-.2.2-.3.5-.3.8l.3,5.1c.1,1.6.8,3.1,1.9,4.3,1.1,1.2,2.5,1.9,4.1,2.2l4.5.6h0c1.1,1.7,2.1,3.5,2.9,5.3v3c-1.7.2-3.3.9-4.6,2-1.3,1.1-2.2,2.6-2.7,4.3-2.3-.3-4.6.3-6.4,1.7-1.8,1.4-3,3.5-3.2,5.9-1.2.2-2.3.8-3.2,1.7-.9.9-1.5,2-1.8,3.2-.1.3,0,.6.2.8.1.3.4.4.7.4h44c.3,0,.6-.1.8-.4.2-.2.2-.5.2-.8h0ZM22.94,26.1c-.5,0-1-.4-1-1v-1.9c-.9-.2-1.6-.7-2.2-1.4-.5-.7-.8-1.6-.8-2.5v-.9c0-.5.4-1,1-1,.5,0,1,.4,1,1v.9c0,.7.4,1.4,1,1.8s1.4.4,2.1,0c.6-.4,1-1.1,1-1.8v-.9c0-1.5-.7-1.7-2.3-2.1-1.5-.4-3.7-.9-3.7-4v-.9c0-.9.3-1.8.8-2.5s1.3-1.2,2.2-1.4v-1.7c0-.5.4-1,1-1,.5,0,1,.4,1,1v1.9c.9.2,1.6.7,2.2,1.4.5.7.8,1.6.8,2.5v.9c0,.5-.4,1-1,1-.5,0-1-.4-1-1v-.9c0-.7-.4-1.4-1-1.8-.6-.4-1.4-.4-2.1,0-.6.4-1,1.1-1,1.8v.9c0,1.5.7,1.7,2.3,2.1,1.5.4,3.7.9,3.7,4v.9h0c0,.9-.3,1.8-.8,2.5s-1.3,1.2-2.2,1.4v1.9c0,.3-.1.5-.3.7-.2,0-.4.1-.7.1h0Z"
      />
    </Svg>
  </View>
);

RiskAverseIcon.propTypes = { ...IconProps };
RiskAverseIcon.defaultProps = { ...IconDefaultProps };
