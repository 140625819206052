/* eslint-disable no-restricted-syntax */
import cn from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import { formatMoney, getTargetInvestors } from 'utils/utils';
import { getAccountsFromProposal } from '../risk-tolerance/utils';
import { IPSPropTypes } from '../types';
import './styles.scss';
import { companyGoalsToOptions, investorGoalsToOptions } from './utils';

const MANUAL_TARGET_SCORE_SOURCE = 'Manual';

const AccountsBreakdown = ({ editing, ips, loading, setAccountGoal }) => {
  const {
    investorGoalsProvider,
    user: {
      advisor: {
        company: { tolerance_to_goals_mapping: goalsMapping }
      }
    }
  } = useContext(AdvisorContext);

  const [goalsByInvestor, setGoalsByInvestor] = useState({});

  const accounts = getAccountsFromProposal(ips.proposal);
  const investors = getTargetInvestors(ips.proposal);
  const investorIds = investors.map(investor => investor.id);
  const companyGoals = goalsMapping || [];

  useEffect(() => {
    for (const investorId of investorIds)
      investorGoalsProvider.list({ investor: investorId }).then(({ data }) => {
        setGoalsByInvestor(prevGoalsByInvestor => {
          const investorGoals = data.results.map(goal => ({ id: goal.id, name: goal.name }));
          const investorGoalsOptions = investorGoalsToOptions(investorGoals);
          const companyGoalsOptions = companyGoalsToOptions(companyGoals, investorGoals);
          return {
            ...prevGoalsByInvestor,
            [investorId]: [...investorGoalsOptions, ...companyGoalsOptions]
          };
        });
      });
  }, [JSON.stringify(accounts)]);

  const onChangeHandler = (investorId, accountId) => option => {
    setAccountGoal(investorId, accountId, option?.value);
  };

  return (
    <div className="accounts-breakdown">
      <div className="accounts-breakdown__header">Account</div>
      <div className="accounts-breakdown__header">Current Assets</div>
      <div className="accounts-breakdown__header">Risk Tolerance</div>
      <div className="accounts-breakdown__header">Investment Objective</div>
      {accounts.map(account => {
        const goals = goalsByInvestor[account.investor.id] || [];
        const isManualTargetScore = account.target_score_source === MANUAL_TARGET_SCORE_SOURCE;
        const targetScore = account.target_score_summary?.overall ?? account.target_overall;
        const url = `/advisor/${account.investor.is_prospect ? 'prospects' : 'investors'}/${
          account.investor.id
        }/account/${account.id}/overview`;

        return (
          <Fragment key={account.id}>
            <div>
              <Link to={url} target="_blank">
                {account.display_name}
              </Link>
            </div>
            <div>{formatMoney(account.value)}</div>
            <div>
              <ScoreBubble className={cn({ '-manual': isManualTargetScore })} score={targetScore} />
            </div>
            {editing && (
              <div className="investment-objective-select">
                <CreatableSelect
                  defaultValue={goals.find(goal => goal.value === account.goal_id)}
                  isClearable
                  isDisabled={loading}
                  onChange={onChangeHandler(account.investor.id, account.id)}
                  options={_.sortBy(goals, ['label'])}
                  placeholder="Select or create ..."
                />
              </div>
            )}
            {!editing && <div>{account.goal_name || '-'}</div>}
          </Fragment>
        );
      })}
    </div>
  );
};

AccountsBreakdown.propTypes = {
  editing: PropTypes.bool,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  loading: PropTypes.bool,
  setAccountGoal: PropTypes.func.isRequired
};

AccountsBreakdown.defaultProps = {
  editing: false,
  loading: false
};

export default AccountsBreakdown;
