import { Text, View } from '@react-pdf/renderer';
import RiskToleranceQuestionnaireContent from 'containers/risk-tolerance-questionnaire/report/sections/risk-tolerance-questionnaire-content';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ScoreBubble from 'reports/base/charts/score-bubble';
import BaseSection from 'reports/base/sections/base';
import CustomSection from 'reports/base/sections/custom';
import { selectQuestionnaire } from 'utils/questionnaire';
import { getTargetInvestors } from 'utils/utils';
import { IPSPropTypes, MetadataPropTypes } from '../../types';
import { getAccountsFromProposal } from '../utils';
import styles from './styles';

const IPSRiskTolerance = ({ breakSection, ips: { proposal }, metadata, questionnaires }) => {
  const { body, title } = metadata;

  const accounts = getAccountsFromProposal(proposal);
  const investors = getTargetInvestors(proposal);
  const riskToleranceScore = proposal.target.target_score_summary.overall;

  return (
    <BaseSection breakSection={breakSection} title={title}>
      <View style={styles.riskToleranceScore} wrap={false}>
        <Text>Your risk tolerance is </Text>
        <ScoreBubble score={Number(riskToleranceScore)} />
        <Text style={styles.highlightedText}> out of 10</Text>
      </View>
      <CustomSection body={body} />
      {investors.map((investor, investorIdx) => {
        // investor-level RTQs
        const questions = selectQuestionnaire(questionnaires, investor.id);
        if (!_.isEmpty(questions))
          return (
            <View break={investorIdx !== 0} key={investor.id}>
              <View style={styles.title} wrap={false}>
                <Text>{investor.name}</Text>
              </View>
              <RiskToleranceQuestionnaireContent
                breakSection={false}
                questions={questions}
                title=""
              />
            </View>
          );
        // account-level RTQs
        return accounts
          .filter(account => account.investor.id === investor.id)
          .map((account, accountIdx) => {
            const questions = selectQuestionnaire(questionnaires, investor.id, account.id);
            if (_.isEmpty(questions)) return null;
            return (
              <View
                break={investorIdx !== 0 || accountIdx !== 0}
                key={`${investor.id}-${account.id}`}
              >
                <View style={styles.title} wrap={false}>
                  <Text>{`${investor.name} - ${account.display_name}`}</Text>
                </View>
                <RiskToleranceQuestionnaireContent
                  breakSection={false}
                  questions={questions}
                  title=""
                />
              </View>
            );
          });
      })}
    </BaseSection>
  );
};

IPSRiskTolerance.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  questionnaires: PropTypes.object.isRequired
};

IPSRiskTolerance.defaultProps = {
  breakSection: false
};

export default IPSRiskTolerance;
