import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-calendar/dist/Calendar.css';

const ReactDateRangePicker = ({ value, onChange, className, feedbackMessage, ...rest }) => {
  // Default values
  const startDateDefault = value[0] ? moment(value[0]).toDate() : null;

  const endDateDefault = value[1] ? moment(value[1]).toDate() : null;
  // Maximum values
  const maxEndDate = moment().subtract(1, 'days').toDate();

  // Minimum values
  const minStartDate = moment('1990-01-01', 'YYYY-MM-DD').toDate();

  const handleOnChange = date => {
    let formattedDate = [];
    if (date) {
      const formattedStartDate = date[0] ? moment(date[0]).format('YYYY-MM-DD') : null;
      const formattedEndDate = date[1] ? moment(date[1]).format('YYYY-MM-DD') : null;
      formattedDate = [formattedStartDate, formattedEndDate];
    }
    onChange(formattedDate);
  };

  const dates = [startDateDefault, endDateDefault];

  return (
    <>
      <DateRangePicker
        value={dates}
        dayPlaceholder="dd"
        yearPlaceholder="yyyy"
        format="yyyy-MM-dd"
        monthPlaceholder="MM"
        maxDate={maxEndDate}
        minDate={minStartDate}
        onChange={handleOnChange}
        rangeDivider={<span>&#10230;</span>}
        className={`stratifi-date-picker ${className}`}
        {...rest}
      />
      {!rest.error && feedbackMessage && <span style={{ fontSize: 12 }}>{feedbackMessage}</span>}
    </>
  );
};

ReactDateRangePicker.defaultProps = {
  className: '',
  feedbackMessage: ''
};

ReactDateRangePicker.propTypes = {
  className: PropTypes.string,
  feedbackMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired
};

export default ReactDateRangePicker;
