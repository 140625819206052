/* eslint-disable react/no-array-index-key */
import { Text, View } from '@react-pdf/renderer';
import { MetadataPropTypes } from 'components/advisor/ips/body/sections/types';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { ADVISOR_SIGNER_TYPE, SIGNATURE_TYPE } from '../digital-signature/utils';
import SignaturePlace from './signature-place';
import styles from './styles';

const Signature = ({ breakSection, metadata, proposalOrIpsId }) => {
  const {
    user: {
      advisor: {
        company: { digital_signature_enabled: digitalSignatureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  const { body, signaturesNumber, title } = metadata;

  const signers = metadata?.signers || [];

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      {!digitalSignatureEnabled && (
        <>
          <Text style={styles.headerText}>Prepared by</Text>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.cell}>
                <SignaturePlace title="Advisor Name" />
              </View>
              <View style={styles.cell}>
                <SignaturePlace title="Signature" />
              </View>
              <View style={[styles.cell, { paddingRight: 0 }]}>
                <SignaturePlace title="Date" />
              </View>
            </View>
          </View>

          <Text style={[styles.headerText, { marginTop: 20 }]}>Approved by</Text>
          {Array.from(Array(signaturesNumber ?? 1)).map((_, idx) => (
            <View key={`signature-${idx}`} style={styles.table} wrap={false}>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <SignaturePlace title="Client Name" />
                </View>
                <View style={styles.cell}>
                  <SignaturePlace title="Signature" />
                </View>
                <View style={[styles.cell, { paddingRight: 0 }]}>
                  <SignaturePlace title="Date" />
                </View>
              </View>
            </View>
          ))}
        </>
      )}

      {digitalSignatureEnabled &&
        signers.map(signer => {
          const signerId = `${proposalOrIpsId}-${signer.id}-${signer.type}`.toUpperCase();
          const signerTitle = signer.type === ADVISOR_SIGNER_TYPE ? 'Advisor Name' : 'Client Name';
          const signatureType = signer.signatureType === SIGNATURE_TYPE ? 'SIGN' : 'ACKN';
          return (
            <View key={`signature-${signerId}`} style={styles.table} wrap={false}>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <SignaturePlace title={signerTitle} value={signer.name} />
                </View>
                <View style={styles.cell}>
                  <SignaturePlace
                    isCodeValue
                    title="Signature"
                    value={`${signerId}-${signatureType}`}
                  />
                </View>
                <View style={[styles.cell, { paddingRight: 0, flexBasis: 45 }]}>
                  <SignaturePlace isCodeValue title="Date" value={`${signerId}-DATE`} />
                </View>
              </View>
            </View>
          );
        })}
    </BaseSection>
  );
};

Signature.propTypes = {
  breakSection: PropTypes.bool,
  investors: PropTypes.array.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposalOrIpsId: PropTypes.number.isRequired
};

Signature.defaultProps = {
  breakSection: false
};

export default Signature;
