import PropTypes from 'prop-types';
import { Handle } from 'rc-slider';
import React from 'react';

const SliderDot = ({ value, ...rest }) => (
  <Handle {...rest} value={value} className={`${rest.className} risk-slider__handle-dot`}>
    <span className={`-dot-level-${Math.ceil(value)}`}>{value}</span>
  </Handle>
);

SliderDot.propTypes = {
  value: PropTypes.number.isRequired
};

export default SliderDot;
