// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sdt .sdt__table > table > tbody > tr.tr-account {
  background-color: white;
}
.sdt .sdt__table > table > tbody > tr.tr-account.tr-account--excluded * {
  opacity: 0.65;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/list/row/investor-accounts/styles.scss"],"names":[],"mappings":"AAGE;EACE,uBAAA;AAFJ;AAII;EACE,aAAA;AAFN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.sdt .sdt__table > table > tbody > {\n  tr.tr-account {\n    background-color: white;\n\n    &.tr-account--excluded * {\n      opacity: 0.65;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
