import AuthProvider from 'providers/auth';
import { PERMISSION_STAFF } from 'constants/actions/auth';

// TODO: find a way to redirect to '/' by default (now it causes an error)
export function hasPermission(store, permissions, redirect = '/signin') {
  /*
    @store: redux/flux store
    @permissions: one (String) or many ([String]) permission groups aliases
    @redirect: redirect to if user has no permission
    */

  const authProvider = new AuthProvider({ dispatch: store.dispatch });

  return (nextState, replace) => {
    const state = store.getState();
    const { user } = state.auth;
    const { location: { search, pathname } = {} } = nextState || {};
    if (
      (permissions === PERMISSION_STAFF && user?.is_staff) ||
      authProvider.hasPermission(user, permissions) ||
      authProvider.isAllowedWithoutAuth(pathname)
    )
      return true;

    if (redirect.startsWith('/signin')) authProvider.deauthenticate();
    replace(`${redirect}${search}`);
    return false;
  };
}

export function hasCompanySettings(store, statuses, value, redirect = '/advisor') {
  return (nextState, replace) => {
    const state = store.getState();
    const { user } = state.auth;
    const { location: { search } = {} } = nextState || {};

    if (user?.advisor?.company?.[statuses] === value) return true;

    replace(`${redirect}${search}`);
    return false;
  };
}

export function hasCompanyStatus(store, statuses = [true, 'trialing'], redirect = '/checkout') {
  return (nextState, replace) => {
    const state = store.getState();
    const { user } = state.auth;

    if (statuses.includes(user?.advisor?.company?.status)) return true;
    replace(redirect);
    return false;
  };
}

export function onEnterHooks(...hooks) {
  return (nextState, replace) => {
    hooks.every(hook => hook(nextState, replace));
  };
}

export function isComplianceOrAbove(store, complianceOrAboveCallback, notComplianceCallback) {
  return (nextState, replace) => {
    const state = store.getState();
    const { user } = state.auth;
    const authProvider = new AuthProvider({ dispatch: store.dispatch });
    if (authProvider.hasCompliancePermissionsOrAbove(user))
      return complianceOrAboveCallback(nextState, replace);
    return notComplianceCallback(nextState, replace);
  };
}

export function isComplianceOrAboveSingleRout(store) {
  const state = store.getState();
  const { user } = state.auth;
  const authProvider = new AuthProvider({ dispatch: store.dispatch });
  return authProvider.hasCompliancePermissionsOrAbove(user);
}
