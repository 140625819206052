import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatNumber } from '../utils';
import styles, { DEPTH_LEVEL_2_BACKGROUND_COLOR } from './styles';

const Row = ({
  benchmark,
  color,
  depth,
  icon: IconComponent,
  isDraft,
  label,
  recommended,
  target,
  withParentIcon
}) => {
  let diffStyles = {};
  if (isDraft && target > recommended) diffStyles = styles.recommendedPositiveText;
  if (isDraft && target < recommended) diffStyles = styles.recommendedNegativeText;
  if (!isDraft && recommended > target) diffStyles = styles.recommendedPositiveText;
  if (!isDraft && recommended < target) diffStyles = styles.recommendedNegativeText;

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
    borderLeftColor: color
  };

  return (
    <View style={[styles.row, styles[`rowLevel${depth}`]]} wrap={false}>
      <View style={[styles.cell, rowStyle]}>
        {IconComponent && <IconComponent height={15} />}
        <Text
          style={[
            styles[`cellTextLevel${depth}`],
            styles[`firstCell${withParentIcon ? 'Icon' : ''}TextLevel${depth}`]
          ]}
        >
          {label}
        </Text>
      </View>
      <View style={[styles.cell, styles.recommendedCell]}>
        {isDraft && (
          <Text
            style={[
              styles.cellText,
              styles.recommendedCellText,
              styles[`cellTextLevel${depth}`],
              diffStyles
            ]}
          >
            {formatNumber(target - recommended, true)}
          </Text>
        )}
        <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
          {formatNumber(target)}
        </Text>
      </View>
      {!_.isNil(recommended) && (
        <View style={[styles.cell, styles.recommendedCell]}>
          {!isDraft && (
            <Text
              style={[
                styles.cellText,
                styles.recommendedCellText,
                styles[`cellTextLevel${depth}`],
                diffStyles
              ]}
            >
              {formatNumber(recommended - target, true)}
            </Text>
          )}
          <Text
            style={[styles.cellText, styles.recommendedCellText, styles[`cellTextLevel${depth}`]]}
          >
            {formatNumber(recommended)}
          </Text>
        </View>
      )}
      {!_.isNil(benchmark) && (
        <View style={[styles.cell, { marginRight: 0 }]}>
          <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
            {formatNumber(benchmark)}
          </Text>
        </View>
      )}
    </View>
  );
};

Row.propTypes = {
  benchmark: PropTypes.number,
  color: PropTypes.string,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  isDraft: PropTypes.bool,
  label: PropTypes.string.isRequired,
  recommended: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
  withParentIcon: PropTypes.bool
};

Row.defaultProps = {
  benchmark: null,
  color: DEPTH_LEVEL_2_BACKGROUND_COLOR,
  icon: null,
  isDraft: false,
  withParentIcon: false
};

export default Row;
