import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PrismRiskAnalysis from 'components/advisor/risk-analysis/prism-risk-analysis';
import FocusedPrismFactorsChart from 'components/charts/focused-prism-factors-chart';
import ScenariosScores from 'components/advisor/risk-analysis/scenarios-scores';
import AccountOrModelPrismReportViewer from 'components/advisor/risk-analysis/report/account-or-model-prism/viewer';
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside';
import ModelPortfolioBenchmark from 'components/advisor/model-portfolio-benchmark';
import SpinnerLoader from 'components/performance-spinner';
import {
  getScenarios,
  getScoreFactors
} from 'components/advisor/risk-analysis/risk-analysis-target/risk-analysis-content/utils';
import Disclosure from 'components/disclosure';
import { MODEL_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import { SECURITY_TARGET_TYPE } from '../../constants';

const SecurityRiskReturns = ({ benchmarks, returnsData, security }) => {
  const {
    customSecurityProvider,
    routerActions,
    modelProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const [loadingRequest, setLoadingRequest] = useState(true);

  useEffect(() => {
    setLoadingRequest(true);
    customSecurityProvider.returnsPrism(security.id);
    modelProvider.getCommonBenchmarks();
    setLoadingRequest(false);
  }, []);

  if (_.isEmpty(security)) {
    toast.error('The requested security does not exist');
    routerActions.push('/advisor/securities');
  }

  if (loadingRequest) return <SpinnerLoader spinnerLoading />;

  if (_.isEmpty(returnsData)) return null;

  const { concentrated, volatility, correlation, tail } = getScoreFactors(returnsData.output);

  const riskSecurityAnalysis = {
    ...returnsData.output,
    portfolio: {
      id: security.id,
      name: security.ticker,
      prism_score_summary: returnsData.summary,
      positions: [security],
      scenarios: [...returnsData.scenarios],
      target_score_summary: returnsData.summary.overall
    }
  };
  const { scenarios, scenariosPerformance } = getScenarios(riskSecurityAnalysis);

  return (
    <div className="risk-score-result-container">
      <div className="prism-rating-page-container">
        <div className="risk-analysis-content">
          <div className="box-container heading">
            {!_.isEmpty(riskSecurityAnalysis) && (
              <AccountOrModelPrismReportViewer
                benchmarks={benchmarks}
                portfolio={riskSecurityAnalysis.portfolio}
                riskAnalysis={riskSecurityAnalysis}
                type={SECURITY_TARGET_TYPE}
              />
            )}
          </div>
          {returnsData.summary && (
            <div className="result-container prism-container">
              <div className="result-heading">
                Security Risk <span className="sub-text">Powered By</span> PRISM Rating &trade;
              </div>
              <PrismRatingUpsideDownside
                prismSummary={returnsData.summary}
                value={security.value}
                market={{
                  up: company.market_downside_performance,
                  down: company.market_upside_performance
                }}
              />
            </div>
          )}
          <div>
            {returnsData.summary && (
              <ModelPortfolioBenchmark
                benchmark={benchmarks}
                prismOverall={returnsData.summary.overall}
              />
            )}
            {!_.isEmpty(concentrated, correlation, tail, volatility) && returnsData.summary && (
              <div className="scores-container">
                <h2>PRISM Risk Analysis</h2>
                <PrismRiskAnalysis
                  portfolio={returnsData}
                  prismSummary={{ concentrated, correlation, tail, volatility }}
                  type={MODEL_TARGET_TYPE}
                />
              </div>
            )}

            {returnsData.summary && (
              <div className="focused-prism-factors-container box-container">
                <div className="box-content">
                  <FocusedPrismFactorsChart targetSummary={returnsData.summary} />
                </div>
              </div>
            )}

            {!_.isEmpty(scenarios) && returnsData.summary && (
              <div className="score-market-container">
                <div className="heading">
                  <h2>PRISM Analysis for Market Scenarios</h2>
                </div>
                <ScenariosScores
                  performance={scenariosPerformance}
                  scenarios={scenarios}
                  value={returnsData.value}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Disclosure />
    </div>
  );
};

SecurityRiskReturns.defaultProps = {
  benchmarks: []
};

SecurityRiskReturns.propTypes = {
  benchmarks: PropTypes.array,
  routerActions: PropTypes.object.isRequired,
  returnsData: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  security: state.customSecurity.currentSecurity,
  returnsData: state.customSecurity.currentSecurityReturns,
  benchmarks: state.models.benchmark
});

const SecurityRiskReturnsWithOutRout = withRouter(SecurityRiskReturns);

export default connect(mapStateToProps)(SecurityRiskReturnsWithOutRout);
