/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ageCalculator } from 'utils/questionnaire';
import { formatMoney } from 'utils/utils';
import { TYPE_ANNUAL, TYPE_ONCE } from './hooks';

export const getWithdrawalRequirementAnswer = question => {
  const refs = question.data?.refs || {};
  const birthday = refs.birthday || question.answer.birthday;
  const age = ageCalculator(birthday);
  const constants = question.data?.constants || {};
  const retireStartsIn = question.answer.start - age;
  const isEntityQuestion = question.data?.is_entity || false;
  const isRetired = retireStartsIn <= 0;

  // The default answer provides backwards compatibility
  let answer = `I plan to withdraw for ${question.answer.duration} years.`;

  if (question.answer?.type && question.answer.type === TYPE_ANNUAL)
    answer = (
      <FormattedMessage
        id="form.withdrawal-requirement.annual-income-read-only"
        defaultMessage={constants.annual_income_read_only}
        values={{
          amount: formatMoney(question.answer.amount),
          isRetired: !isEntityQuestion && isRetired,
          start: isEntityQuestion
            ? moment(birthday).year() + question.answer.start
            : question.answer.start,
          years: isEntityQuestion ? question.answer.start : question.answer.duration
        }}
      />
    );

  if (question.answer?.type && question.answer.type === TYPE_ONCE)
    answer = (
      <FormattedMessage
        id="form.withdrawal-requirement.one-shot-read-only"
        defaultMessage={constants.one_shot_read_only}
        values={{
          amount: formatMoney(question.answer.amount),
          years: retireStartsIn
        }}
      />
    );

  return answer;
};
