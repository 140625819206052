export const CONCENTRATED_FACTOR_ATTR = 'concentrated';
export const CORRELATION_FACTOR_ATTR = 'correlation';
export const OVERALL_FACTOR_ATTR = 'overall';
export const TAIL_FACTOR_ATTR = 'tail';
export const VOLATILITY_FACTOR_ATTR = 'volatility';

export const PRISM_FACTORS = {
  [CONCENTRATED_FACTOR_ATTR]: 'Concentrated Stock',
  [CORRELATION_FACTOR_ATTR]: 'Diversification',
  [TAIL_FACTOR_ATTR]: 'Tail',
  [VOLATILITY_FACTOR_ATTR]: 'Volatility'
};

export const getPrismFactorName = factor => PRISM_FACTORS[factor];

export const getPrismFactorAggregatedSortingId = factor =>
  `aggregated_prism_scores__${factor || OVERALL_FACTOR_ATTR}`;

export const getPrismFactorSortingId = factor => `prism_score__${factor || OVERALL_FACTOR_ATTR}`;
