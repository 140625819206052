import React from 'react';

export const CSVModelMixin = {
  applyMixin(self) {
    self.onCSVLoaded = this.onCSVLoaded.bind(self);
    self.onCSVInputChange = this.onCSVInputChange.bind(self);
    self.registerCSVLoadedCallback = this.registerCSVLoadedCallback.bind(self);
  },

  onCSVLoaded() {},

  onCSVInputChange(e) {
    const input = e.target;

    const reader = new FileReader();
    reader.onload = this.onCSVLoaded;
    if (input.files) reader.readAsText(input.files[0]);
  },

  registerCSVLoadedCallback(callback) {
    this.onCSVLoaded = callback;
  }
};

export const OrderingTableMixin = {
  applyMixin(self, sortByColumnName = 'sortByColumn', renderSortIconName = 'renderSortIcon') {
    self[sortByColumnName] = this.sortByColumn.bind(self);
    self[renderSortIconName] = this.renderSortIcon.bind(self);
    self.getSortBy = this.getSortBy.bind(self);
    self.getOrdering = this.getOrdering.bind(self);
  },

  getSortBy() {
    const { sortBy: sortByProp } = this.props;
    return sortByProp || this.sortBy;
  },

  getOrdering() {
    return this.props.ordering || this.state.ordering;
  },

  sortByColumn(column, type = null) {
    const sortBy = this.getSortBy();
    const ordering = this.getOrdering();
    const descOrdering = `-${column}`;
    this.setState({
      currentPage: 1
    });
    if (type && type === 'desc') {
      sortBy(`-${column}`);
      return;
    }
    if (type && type === 'asec') {
      sortBy(column);
      return;
    }
    if (column === ordering) sortBy(descOrdering);
    else if (ordering === descOrdering) sortBy(null);
    else sortBy(column);
  },

  sortByColumnId(column, id) {
    const { ordering } = this.props;
    const sortBy = this.getSortBy();
    const descOrdering = `-${column}`;
    if (column === ordering) sortBy(descOrdering);
    else if (ordering === descOrdering) sortBy(null);
    else sortBy(column, id);
  },

  renderSortIcon(column) {
    const { ordering } = this.props;
    const desc = `-${column}`;
    if (ordering === column) return String.fromCharCode(9650);

    if (ordering === desc) return String.fromCharCode(9660);

    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9.58 10.94"
      >
        <polygon style={{ fill: '#394346' }} points="0 4.83 9.55 4.72 4.83 0 0 4.83" />
        <polygon style={{ fill: '#394346' }} points="9.58 6.11 0.04 6.22 4.76 10.94 9.58 6.11" />
      </svg>
    );
  }
};

export const CumulateReturnsMixin = {
  applyMixin(self, cumulateReturnsName = 'cumulateReturns', unitedReturnsName = 'unitedReturns') {
    self[cumulateReturnsName] = this.cumulateReturns.bind(self);
    self[unitedReturnsName] = this.unitedReturns.bind(self);
  },

  unitedReturns(strategyData, benchmarkData) {
    const result = [];

    function dateUniq(item) {
      const date = new Date(item[0]);
      const string_date = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        -2
      )}-${`0${date.getDate()}`.slice(-2)}`;
      return [string_date, item[1]];
    }

    let returnsMin = strategyData.map(dateUniq);
    let returnsMax = benchmarkData.map(dateUniq);

    const dateMin = (returnsMin[0] > returnsMax[0] ? returnsMin[0] : returnsMax[0])[0];
    const dateMax = (
      returnsMin[returnsMin.length - 1] < returnsMax[returnsMax.length - 1]
        ? returnsMin[returnsMin.length - 1]
        : returnsMax[returnsMax.length - 1]
    )[0];

    returnsMin = returnsMin.filter(item => item[0] >= dateMin && item[0] <= dateMax);
    returnsMax = returnsMax.filter(item => item[0] >= dateMin && item[0] <= dateMax);

    let uniqDates = [];

    for (const date of returnsMin) if (!uniqDates.includes(date[0])) uniqDates.push(date[0]);

    for (const date of returnsMax) if (!uniqDates.includes(date[0])) uniqDates.push(date[0]);

    uniqDates = uniqDates.sort();

    for (const date of uniqDates) {
      const strategyDataForDate = returnsMin.filter(item => item[0] === date)[0];
      const benchmarkDataForDate = returnsMax.filter(item => item[0] === date)[0];

      result.push({
        date,
        tail_risk: strategyDataForDate ? strategyDataForDate[1] : 0,
        vxx: benchmarkDataForDate ? benchmarkDataForDate[1] : 0
      });
    }
    return result;
  },

  cumulateReturns(strategyData, benchData, aum) {
    let value = aum;

    let resultData = [];

    if (benchData) {
      resultData = this.unitedReturns(strategyData, benchData);
      resultData = resultData.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      });

      resultData[resultData.length - 1].s_returns = resultData[resultData.length - 1].tail_risk;
      resultData[resultData.length - 1].tail_risk = aum;
      for (let idx = resultData.length - 2; idx >= 0; idx--) {
        resultData[idx].s_returns = resultData[idx].tail_risk;
        resultData[idx].tail_risk = value / (1 + resultData[idx + 1].s_returns);
        value = resultData[idx].tail_risk;
        resultData[idx].tail_risk = resultData[idx].tail_risk.toFixed();
      }

      resultData[0].b_returns = resultData[0].vxx;
      resultData[0].vxx = value;
      for (let idx = 1; idx < resultData.length; idx++) {
        resultData[idx].b_returns = resultData[idx].vxx;
        resultData[idx].vxx = resultData[idx - 1].vxx * (1 + resultData[idx].vxx);
        value = resultData[idx].vxx;
        resultData[idx].vxx = resultData[idx].vxx.toFixed();
      }

      for (let idx = 1; idx < resultData.length; idx++) {
        resultData[idx].s_returns =
          (1 + resultData[idx].s_returns) * (1 + resultData[idx - 1].s_returns) - 1;
        resultData[idx].b_returns =
          (1 + resultData[idx].b_returns) * (1 + resultData[idx - 1].b_returns) - 1;
      }
    }

    return resultData;
  }
};
