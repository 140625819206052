import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './styles.scss';

const PhoneField = ({
  className,
  defaultCountry,
  error,
  initialValue,
  onChange,
  placeholder,
  style,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  const handleOnChange = value => {
    setValue(value);
    onChange(value);
  };

  return (
    <fieldset>
      <PhoneInput
        className={cn('phone-field', className, { 'phone-field--error': error && rest.touched })}
        defaultCountry={defaultCountry}
        international
        onChange={handleOnChange}
        placeholder={placeholder}
        style={style}
        value={value}
        {...rest}
      />
      {error && rest.touched && <span className="text-danger error">{error}</span>}
    </fieldset>
  );
};

PhoneField.propTypes = {
  className: PropTypes.string,
  defaultCountry: PropTypes.string,
  error: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object
};

PhoneField.defaultProps = {
  className: 'form-control',
  defaultCountry: 'US',
  error: undefined,
  initialValue: '',
  onChange: () => {},
  placeholder: '',
  style: {}
};

export default PhoneField;
