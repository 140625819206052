// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reports-viewer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 10px;
}
.reports-viewer .reports-viewer__selector {
  display: flex;
  width: 90vw;
  gap: 15px;
}
.reports-viewer .reports-viewer__selector > div {
  width: 300px;
}
.reports-viewer .reports-viewer__content {
  height: 90vh;
  width: 90vw;
  border: 0;
  box-shadow: 0 25px 50px 0 rgba(62, 62, 62, 0.15);
}`, "",{"version":3,"sources":["webpack://./src/app/reports/development-viewer/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;EACA,SAAA;AACJ;AACI;EACE,YAAA;AACN;AAGE;EACE,YAAA;EACA,WAAA;EACA,SAAA;EACA,gDAAA;AADJ","sourcesContent":[".reports-viewer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  gap: 10px;\n\n  .reports-viewer__selector {\n    display: flex;\n    width: 90vw;\n    gap: 15px;\n\n    & > div {\n      width: 300px;\n    }\n  }\n\n  .reports-viewer__content {\n    height: 90vh;\n    width: 90vw;\n    border: 0;\n    box-shadow: 0 25px 50px 0 rgba(62, 62, 62, 0.15);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
