import React from 'react';
import './styles.scss';

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div id="scroll-to-top">
      <button
        type="button"
        onClick={scrollToTop}
        className="btn btn-outline-secondary scorll-to-top__button"
      >
        <span className="fs-icon-angle-up" /> Scroll to Top
      </button>
    </div>
  );
};

export default ScrollToTop;
