import Disclosure from 'components/disclosure';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const SignatureRequestMessage = ({ messageId }) => (
  <>
    <div className="signature-request">
      <div className="signature-request__header" />
      <div className="signature-request__content content--message">
        <span className="fs-icon-exclamation-circle warning-icon" />
        <div>
          <FormattedMessage
            id={messageId}
            values={{ email: <CustomerSupportEmailLink />, phone: <CustomerSupportPhoneLink /> }}
          />
        </div>
      </div>
    </div>
    <Disclosure />
  </>
);

SignatureRequestMessage.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default SignatureRequestMessage;
