import { Modal, ModalBody } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Confetti from 'react-confetti';
import { Link } from 'react-router';
import './styles.scss';

export const WelcomeModalContent = ({ hide }) => {
  const { userProvider, user } = useContext(AdvisorContext);
  return (
    <>
      <h1>
        <span role="img" aria-label="hello">
          👋
        </span>{' '}
        Hi {user.first_name}, Welcome!
      </h1>
      <p>We are excited to have you on board. Now you can:</p>
      <ul>
        <li>Understand {userProvider.isEntry(user) ? 'prospects' : 'clients'} risk tolerance</li>
        <li>Manage prospects with ease</li>
        <li>Generate personalized proposals</li>
        {userProvider.isEntry(user) && <li>Analyze performances of model portfolios</li>}
        {!userProvider.isEntry(user) && <li>Create investment policy statements</li>}
      </ul>
      <div className="code-wrapper">
        <Link to="/advisor/prospects/create" className="btn btn-primary" onClick={hide}>
          Let&apos;s get started
        </Link>
      </div>
    </>
  );
};

const WelcomeModal = ({ onWelcomeAccepted }) => {
  const [isShown, setIsShown] = useState(true);

  const hide = () => {
    if (onWelcomeAccepted) onWelcomeAccepted();
    setIsShown(false);
  };

  return (
    <Modal id="WelcomeModal" className="modal-lg" show={isShown} onHidden={hide} backdrop="static">
      <Confetti style={{ transform: 'translateX(-50%)', inset: '-2rem 0px 0px 50%' }} />
      <ModalBody>
        <WelcomeModalContent hide={hide} />
      </ModalBody>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  onWelcomeAccepted: PropTypes.func
};

WelcomeModal.defaultProps = {
  onWelcomeAccepted: null
};

export default WelcomeModal;
