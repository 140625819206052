import config from '../config';
import DataProvider from './data';

class AttachmentsProvider extends DataProvider {
  uploadAttachment(file) {
    return this.provider.postFormData(`${config.apiBase}attachments/`, file);
  }
}

export default AttachmentsProvider;
