/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import LanguageOptions from '../language';

const NavigationInvestor = ({ user }) => {
  if (!user || !user?.investor) return null;

  return (
    <div className="account-info">
      {user && !user.investor.is_default && (
        <div>
          <label>
            <FormattedMessage id="rtq.header.client" />
          </label>
          <span>{user.investor.full_name}</span>
        </div>
      )}
      <LanguageOptions />
    </div>
  );
};

NavigationInvestor.defaultProps = {
  user: null
};

NavigationInvestor.propTypes = {
  user: PropTypes.object
};

export default connect(state => ({
  user: state.auth.user
}))(NavigationInvestor);
