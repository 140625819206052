import { preferredValuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import CollaborationPagination from 'pages/collaboration-hub/pagination';
import CollaborationSearch from 'pages/collaboration-hub/search';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DEFAULT_MODAL_STATE, DELETE_MODAL, EDIT_MODAL } from './constants';
import AccessEndorsementsDeleteModal from './delete-modal';
import AccessEndorsementsEditModal from './edit-modal';
import AccessEndorsementsList from './list';
import './styles.scss';

const AccessEndorsements = ({ followers }) => {
  const { advisorProvider } = useContext(AdvisorContext);

  const [modal, setModal] = useState(_.cloneDeep(DEFAULT_MODAL_STATE));
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);
  const [querySearch, setQuerySearch] = useState('');

  const onEditFollowers = (advisorId, followers, message) =>
    advisorProvider
      .editFollowers(advisorId, followers)
      .then(() => {
        advisorProvider.listFollowers();
        toast.success(message);
      })
      .catch(() => {
        toast.error('Sorry, something went wrong while editing the access endorsements.');
      });

  const onOpenModal = (type, data) => {
    setModal({ type, data });
  };

  const onCloseModal = () => {
    setModal(_.cloneDeep(DEFAULT_MODAL_STATE));
  };

  useEffect(() => {
    advisorProvider.listFollowers();
  }, []);

  return (
    <div className="access-endorsements">
      <div className="access-endorsements__header">
        <div>
          <h2>Access Endorsements</h2>
        </div>
        <div className="access-endorsements__header-actions">
          <CollaborationSearch searchCallback={setQuerySearch} />
          <CollaborationPagination paginationCallback={setPageSize} />
          <button
            type="button"
            className="btn btn-primary btn-add-access-endorsements"
            onClick={() => {
              onOpenModal(EDIT_MODAL, _.cloneDeep(DEFAULT_MODAL_STATE.data));
            }}
          >
            Add Endorsement
          </button>
        </div>
      </div>

      <div className="access-endorsements__content">
        <AccessEndorsementsList
          data={followers}
          defaultPageSize={pageSize}
          onOpenModal={onOpenModal}
          querySearch={querySearch}
        />
      </div>

      <AccessEndorsementsEditModal
        data={modal.data}
        onConfirm={onEditFollowers}
        onHide={onCloseModal}
        show={modal.type === EDIT_MODAL}
      />

      <AccessEndorsementsDeleteModal
        data={modal.data}
        onConfirm={onEditFollowers}
        onHide={onCloseModal}
        show={modal.type === DELETE_MODAL}
      />
    </div>
  );
};

AccessEndorsements.propTypes = {
  followers: PropTypes.array.isRequired
};

export default connect(state => ({
  followers: state.advisors.followers
}))(AccessEndorsements);
