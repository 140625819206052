import RiskRanges from 'containers/risk-tolerance-questionnaire/result/risk-ranges';
import PersonalityType from 'containers/risk-tolerance-questionnaire/result/score-context/personality-type';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { NumberValidator } from 'utils/form';
import './styles.scss';

const DEFAULT_SCORE = 5;

const validate = ({ score }) => ({
  score: new NumberValidator(score)
    .required()
    .min(1)
    .max(10).error
});

export const ManualToleranceForm = ({
  fields,
  handleSubmit,
  invalid,
  onCancel,
  submitting,
  targetName
}) => (
  <form autoComplete="off" className="manual-score-modal" onSubmit={handleSubmit}>
    <h3 className="text-sm-center">Manually select the risk score</h3>
    <div className="slider-container">
      <RiskRanges
        disabled={false}
        flavor="advisor"
        handleScore={fields.score.onChange}
        id="range-adjust-score"
        scoreSummary={{ overall: fields.score.value }}
      />
    </div>
    <div className="score-container">
      <PersonalityType
        className="score-container__personality-type"
        displayIcon={false}
        flavor="advisor"
        investorName={targetName}
        score={fields.score.value}
      />
    </div>
    <div className="buttons">
      <button className="btn cancel-button" onClick={onCancel} type="button">
        Cancel
      </button>
      <button className="btn btn-primary " disabled={invalid || submitting} type="submit">
        Submit
      </button>
    </div>
  </form>
);

ManualToleranceForm.defaultProps = {
  prevScore: null
};

ManualToleranceForm.propTypes = {
  fields: PropTypes.shape({ score: PropTypes.object.isRequired }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  prevScore: PropTypes.number,
  submitting: PropTypes.bool.isRequired,
  targetName: PropTypes.string.isRequired
};

export default compose(
  connect((state, props) => ({
    initialValues: { score: props.prevScore || DEFAULT_SCORE }
  })),
  reduxForm({
    form: 'account-manual-risk-score',
    fields: ['score'],
    validate
  })
)(ManualToleranceForm);
