// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-button {
  display: inline-block;
  position: relative;
}
.loading-button .button {
  overflow: hidden;
  position: relative;
}
.loading-button .button[data-loading=true] .button__label {
  transform: translateX(100%);
  opacity: 0;
}
.loading-button .button[data-loading=true] .button__spinner {
  left: 50%;
  opacity: 1;
}
.loading-button .button__label {
  opacity: 1;
}
.loading-button .button__spinner {
  top: 45%;
  left: 30%;
  width: 0px;
  z-index: 1;
  opacity: 0;
  position: absolute;
  transform: scale(0.1);
}
.loading-button .button__spinner .bar {
  top: -7.5px;
  width: 41px;
  height: 15px;
  position: absolute;
  background: transparent;
  transform-origin: left center;
  transform: rotate(0deg) translateX(60px);
}
.loading-button .button__spinner .bar__shadow {
  width: 100%;
  height: 100%;
  border-radius: 7.5px;
  box-shadow: transparent 0px 0px 1px;
}
.loading-button .button__label, .loading-button .button__spinner {
  transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
}

@keyframes spinner-line-fade-quick {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading-button/styles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;AACJ;AAEM;EACE,2BAAA;EACA,UAAA;AAAR;AAGM;EACE,SAAA;EACA,UAAA;AADR;AAKI;EACE,UAAA;AAHN;AAMI;EACE,QAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;EACA,qBAAA;AAJN;AAMM;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,6BAAA;EACA,wCAAA;AAJR;AAMQ;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,mCAAA;AAJV;AASI;EAGE,4DAAA;AARN;;AAaA;EACE;IAEE,UAAA,EAAA,oBAAA;EAXF;EAaA;IACE,UAAA;EAXF;AACF","sourcesContent":[".loading-button {\n  display: inline-block;\n  position: relative;\n\n  .button {\n    overflow: hidden;\n    position: relative;\n\n    &[data-loading='true'] {\n      .button__label {\n        transform: translateX(100%);\n        opacity: 0;\n      }\n\n      .button__spinner {\n        left: 50%;\n        opacity: 1;\n      }\n    }\n\n    &__label {\n      opacity: 1;\n    }\n\n    &__spinner {\n      top: 45%;\n      left: 30%;\n      width: 0px;\n      z-index: 1;\n      opacity: 0;\n      position: absolute;\n      transform: scale(0.1);\n\n      .bar {\n        top: -7.5px;\n        width: 41px;\n        height: 15px;\n        position: absolute;\n        background: transparent;\n        transform-origin: left center;\n        transform: rotate(0deg) translateX(60px);\n\n        &__shadow {\n          width: 100%;\n          height: 100%;\n          border-radius: 7.5px;\n          box-shadow: transparent 0px 0px 1px;\n        }\n      }\n    }\n\n    &__label,\n    &__spinner {\n      -webkit-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;\n      transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;\n    }\n  }\n}\n\n@keyframes spinner-line-fade-quick {\n  0%,\n  100% {\n    opacity: 0; /* minimum opacity */\n  }\n  1% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
