import PropTypes from 'prop-types';
import React, { Component } from 'react';

import config from '../../../../config';

const STATUS_MESSAGE = {
  declined: {
    status: 'declined',
    message: 'You declined the ByAllAccounts User Agreement.'
  },
  exit: {
    status: 'exit',
    message:
      'Your ByAllAccounts session has ended. If you completed the integration process, your data will be imported into StratiFi in the next 2-3 business days.'
  },
  'session-error': {
    status: 'session-error',
    message:
      'There was a connection error with our data providers. Please try again or contact our support team.'
  },
  timeout: {
    status: 'timeout',
    message: 'Your ByAllAccounts session has expired.'
  }
};

class ByAllAccountsIntegration extends Component {
  constructor() {
    super();

    this.form = null;
    this.initialState = { session: null, loading: false };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.getSession();
  }

  onSessionSuccess = data => {
    const session = {
      ...data,
      exit_url: `${config.apiHost}/byallaccounts-handler/exit/${data.session_id}/`,
      timeout_url: `${config.apiHost}/byallaccounts-handler/timeout/${data.session_id}/`,
      declined_url: `${config.apiHost}/byallaccounts-handler/declined/${data.session_id}/`
    };
    this.setState({ session, loading: false }, this.startSession);
  };

  onSessionError = () => {
    const { onComplete } = this.props;
    onComplete(STATUS_MESSAGE['session-error']);
    this.setState({ loading: false });
  };

  getSession = () => {
    const { integrationProvider } = this.context;
    const { selectedSyncData } = this.props;
    this.setState({ loading: true });

    integrationProvider
      .getByAllAccountsIntegrationInfo(selectedSyncData)
      .then(({ data, error }) => {
        if (error) this.onSessionError(error);
        else this.onSessionSuccess(data);
      })
      .catch(this.onSessionError);
  };

  onSessionMessage = e => {
    if (e.origin !== config.apiHost) return;

    const { session } = this.state;
    const { action, token } = e.data;

    if (token !== session.session_id) return;
    this.finishSession(STATUS_MESSAGE[action]);
  };

  startSession = () => {
    this.form.submit();
    window.addEventListener('message', this.onSessionMessage, false);
  };

  finishSession = (status = STATUS_MESSAGE.exit) => {
    const { onComplete } = this.props;
    onComplete(status);
    window.removeEventListener('message', this.onSessionMessage, false);
    this.setState(this.initialState);
  };

  render() {
    const { session, loading } = this.state;

    return (
      <div id="byallaccounts-integration">
        {!loading && session && (
          <form
            action={session.application_url}
            method="POST"
            target="baa_iframe"
            ref={f => {
              this.form = f;
            }}
          >
            <input type="hidden" name="SESSION_ID" value={session.session_id} />
            <input type="hidden" name="CSRF_TOKEN" value={session.csrf_token} />
            <input type="hidden" name="EXIT_URL" value={session.exit_url} />
            <input type="hidden" name="TIMEOUT_URL" value={session.timeout_url} />
            <input type="hidden" name="DECLINED_USER_AGREEMENT_URL" value={session.declined_url} />
          </form>
        )}

        <iframe name="baa_iframe" title="ByAllAccounts" width="100%" height="100%" />
      </div>
    );
  }
}

ByAllAccountsIntegration.contextTypes = {
  integrationProvider: PropTypes.object.isRequired
};

ByAllAccountsIntegration.propTypes = {
  onStart: PropTypes.func,
  onComplete: PropTypes.func,
  provider: PropTypes.object.isRequired,
  selectedSyncData: PropTypes.object
};

ByAllAccountsIntegration.defaultProps = {
  onStart: () => {},
  onComplete: () => {},
  selectedSyncData: {}
};

export default ByAllAccountsIntegration;
