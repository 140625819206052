import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import RiskAnalysisTargetScenario from '../../risk-analysis-target-scenario/pdf';
import styles from './styles';

const ScenariosScores = ({ performance, scenarios, value }) => (
  <BaseSection title="PRISM Analysis for Market Scenarios" wrap={false}>
    <View style={styles.scenarios}>
      {scenarios.map(scenario => (
        <RiskAnalysisTargetScenario
          key={scenario.key}
          name={scenario.name}
          scenario={
            performance && scenarios.length ? performance.find(s => s.name === scenario.name) : null
          }
          score={scenario.score}
          value={value}
        />
      ))}
    </View>
  </BaseSection>
);

ScenariosScores.propTypes = {
  performance: PropTypes.arrayOf(PropTypes.object),
  scenarios: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.number
};

ScenariosScores.defaultProps = {
  performance: null,
  scenarios: [],
  value: null
};

export default ScenariosScores;
