import { StyleSheet } from '@react-pdf/renderer';
import { CYAN_COLOR, GRAPHITE_COLOR, PRISM_COLOR_0, PRISM_COLOR_9 } from 'reports/base/styles';

export const POSITIVE_COLOR = PRISM_COLOR_0;
export const NEGATIVE_COLOR = PRISM_COLOR_9;

export const styles = StyleSheet.create({
  chart: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  percentage: { flexDirection: 'row', alignItems: 'center' },
  percentageText: { marginHorizontal: 2 },
  positive: { fill: POSITIVE_COLOR },
  negative: { fill: NEGATIVE_COLOR },
  bars: { flexGrow: 1, marginLeft: 2 },
  bar: {
    height: 8,
    marginVertical: 2
  },
  targetBar: { backgroundColor: GRAPHITE_COLOR },
  recommendedBar: { backgroundColor: CYAN_COLOR }
});

export default styles;
