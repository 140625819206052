import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import RiskScoreBubble from 'components/advisor/utils/score-bubble/risk';
import ToleranceScoreBubble from 'components/advisor/utils/score-bubble/tolerance';
import {
  getPrismFactorName,
  OVERALL_FACTOR_ATTR
} from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { accountsWithScoredPrismIntentsSelector } from 'selectors/prism';
import './styles.scss';

const HouseholdAccountsRow = ({
  data: household,
  getPrismScore,
  meta,
  onSelectAccountRiskToleranceHandler,
  prismScoresInProgress,
  scoredPrismIntents
}) => {
  const getPrismScoreHandler = accountId => () => getPrismScore(accountId);

  const prismFactorAttr = meta?.params?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
  const prismFactorName = getPrismFactorName(prismFactorAttr);

  const columns = [
    { id: 'account-select' },
    {
      id: 'account-investor-name',
      accessorFn: row => row.investor.full_name,
      cell: ({ row: { original: account } }) => {
        const url = `/advisor/${account.investor.is_prospect ? 'prospects' : 'investors'}/${
          account.investor.id
        }`;
        return <Link to={url}>{account.investor.full_name}</Link>;
      }
    },
    {
      id: 'account-name',
      accessorFn: row => row.display_name,
      cell: ({ row: { original: account } }) => {
        const url = `/advisor/${account.investor.is_prospect ? 'prospects' : 'investors'}/${
          account.investor.id
        }/account/${account.id}/overview`;
        return <Link to={url}>{account.display_name}</Link>;
      },
      colSpan: 2
    },
    {
      accessorFn: row => (row.value ? row.value : 0),
      id: 'account-value',
      cell: ({ row: { original: account } }) =>
        account.value ? <FormattedNumber value={account.value} format="currency" /> : '-',
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        row.prism_score_summary?.[prismFactorAttr]
          ? row.prism_score_summary[prismFactorAttr]
          : null,
      id: 'account-prism',
      cell: ({ row: { original: sourceAccount } }) => {
        const account = { ...sourceAccount };

        if (!account.prism_score_summary && scoredPrismIntents[account.id])
          account.prism_score_summary = { overall: scoredPrismIntents[account.id] };

        return (
          <RiskScoreBubble
            element={account}
            inProgress={prismScoresInProgress.includes(account.id)}
            onGenerate={getPrismScoreHandler(account.id)}
            scoreName={prismFactorName}
          />
        );
      },
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        row.target_score_summary?.overall ? row.target_score_summary.overall : null,
      id: 'account-risk-tolerance',
      cell: ({ row: { original: account } }) => (
        <ToleranceScoreBubble
          element={account}
          investor={account.investor}
          onGenerate={onSelectAccountRiskToleranceHandler}
        />
      ),
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row => (row.drift_summary?.overall ? row.drift_summary.overall.toFixed(1) : null),
      id: 'account-drift',
      cell: ({ row: { original: account } }) =>
        account.drift_summary?.overall ? account.drift_summary.overall.toFixed(1) : '-',
      meta: {
        className: ({ row: { original: account } }) =>
          cn('text-center', 'td-drift', { 'td-drift--red': !account.is_healthy }),
        style: () => ({ width: 75 })
      }
    },
    { id: 'account-expander' }
  ];

  const table = useReactTable({
    columns,
    data: household.accounts,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id
  });

  return table.getRowModel().rows.map(row => (
    <tr
      className={cn('tr-account', { 'tr-account--excluded': row.original?.excluded })}
      key={row.id}
    >
      {row.getVisibleCells().map(cell => {
        const meta = cell.column.columnDef?.meta || {};
        const columnProps = {
          className: meta?.className ? meta.className(cell.getContext()) : undefined,
          style: meta?.style ? meta.style(cell.getContext()) : {}
        };
        return (
          <td {...columnProps} colSpan={cell.column.columnDef?.colSpan ?? 1} key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  ));
};

HouseholdAccountsRow.propTypes = {
  data: PropTypes.object.isRequired,
  getPrismScore: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  onSelectAccountRiskToleranceHandler: PropTypes.func.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  scoredPrismIntents: PropTypes.object.isRequired
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state),
  scoredPrismIntents: accountsWithScoredPrismIntentsSelector(state)
}))(HouseholdAccountsRow);
