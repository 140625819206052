import { isEmpty } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { validation } from 'utils/form';

export const validate = fields => {
  const errors = {};
  Object.keys(fields).forEach(key => {
    errors[key] = errors[key] || validation.required(fields[key]);
  });
  return errors;
};

export const formHasError = fields => {
  const errors = {};
  Object.keys(fields).forEach(key => {
    errors[key] = fields[key] === '' || fields[key]?.error;
  });
  return Object.values(errors).find(val => val);
};

export const handleToastSuccess = (resp, successMsg, callback) => {
  const { error: errorMsg } = resp;
  if (isEmpty(errorMsg)) {
    if (callback) callback();
    return toast.success(() => <div>{successMsg}</div>);
  }
  return toast.error(() => <div>{typeof errorMsg === 'object' ? '' : errorMsg}</div>);
};

export const handleToastError = message => toast.error(() => <div>{message}</div>);
