import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import useLossReaction from '../hooks';

const LossReactionReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const { questionText } = useLossReaction({ question });

  return (
    <ReadOnlyQuestion question={{ ...question, question: questionText }}>
      <p>{question.answer.value}</p>
    </ReadOnlyQuestion>
  );
};

LossReactionReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default LossReactionReadOnlyQuestion;
