import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DeleteHouseholdModal extends Component {
  constructor(props) {
    super(props);
    this.onHideModal = this.onHideModal.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onHideModal() {
    const { onHide } = this.props;
    onHide();
  }

  onClick() {
    const { household, onDelete } = this.props;
    onDelete(household);
  }

  render() {
    const { show, submitting, household } = this.props;
    const text = household.length > 1 ? 'Households' : 'Household';
    return (
      <Modal
        id="delete-client-modal"
        ref={c => (this.modal = c)}
        className="modal-lg"
        show={show}
        onHidden={this.onHideModal}
      >
        <ModalHeader />
        <ModalBody>
          <h3 className="modal-title">Remove Households?</h3>
          <p className="description top">
            <div className="text">
              {' '}
              Are you sure you want to remove
              <span className="coloured-text">
                {' '}
                {household.length} {text}
              </span>
              ?
            </div>
            <div className="text text__warning padding">
              <span className="fs-icon-exclamation-circle warning-icon" />
              You can&apos;t revert this change.
            </div>
          </p>
          <div className="text-sm-center actions">
            <button type="button" className="btn cancel" onClick={this.onHideModal}>
              Cancel
            </button>
            <LoadingButton
              className="btn btn-danger delete"
              loading={submitting}
              onClick={this.onClick}
            >
              Remove
            </LoadingButton>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteHouseholdModal.propTypes = {
  household: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
