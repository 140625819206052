export const REGION_EXPOSURE = 'regionExposure';
export const SECTOR_EXPOSURE = 'sectorExposure';
export const TOP_HOLDINGS = 'topHoldings';
export const INVESTMENT_STYLE = 'investmentStyle';

export const SECTION_LOCKED_MAP = {
  [REGION_EXPOSURE]: {
    proposalIpsId: 'region_proposal',
    portfolioRiskId: 'regions_portfolio',
    name: 'Geographic Exposure'
  },
  [SECTOR_EXPOSURE]: {
    proposalIpsId: 'sector_proposal',
    portfolioRiskId: 'sectors_portfolio',
    name: 'Sector Exposure'
  },
  [TOP_HOLDINGS]: {
    proposalIpsId: 'holdings_proposal',
    portfolioRiskId: 'holdings_portfolio',
    name: 'Top 10 Holdings'
  },
  [INVESTMENT_STYLE]: {
    proposalIpsId: 'investment-style-distribution',
    portfolioRiskId: 'investment-style',
    name: 'Investment Style'
  }
};

export const sectionIsLocked = (key, user) => {
  const {
    sector_exposure_enabled: sectorExposureEnabled,
    region_exposure_enabled: regionExposureEnabled,
    top_holdings_enabled: topHoldingsEnabled,
    investment_style_enabled: investmentStyleEnabled
  } = user.advisor.company;
  switch (key) {
    case INVESTMENT_STYLE:
      return !investmentStyleEnabled;
    case REGION_EXPOSURE:
      return !regionExposureEnabled;
    case SECTOR_EXPOSURE:
      return !sectorExposureEnabled;
    case TOP_HOLDINGS:
      return !topHoldingsEnabled;
    default:
      return false;
  }
};
