import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MergeModalWrapper from 'containers/advisor/merge-modal/merge-modal-wrapper';
import InvestorDeleteModal from 'containers/advisor/delete-modal';
import './styles.scss';

export const InvestorDetailsHeader = ({ investor }) => {
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onClickShowMergeModal = () => {
    setShowMergeModal(true);
  };

  const onClickHideMergeModal = () => {
    setShowMergeModal(false);
  };

  const onClickShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const onClickHideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className="investor-header-details-wrapper">
      <div className="investor-header-details">
        <span className="investor-name">{investor.full_name}</span>
        <span className="investor-email">{investor.email}</span>
      </div>
      <div className="action-btn-wrapper">
        <button onClick={onClickShowMergeModal} type="button" className="btn btn-outline-secondary">
          Merge
        </button>
        <button onClick={onClickShowDeleteModal} type="button" className="btn btn-danger">
          Delete
        </button>
      </div>

      {showMergeModal && <MergeModalWrapper onClose={onClickHideMergeModal} />}
      {showDeleteModal && (
        <InvestorDeleteModal items={[investor]} onClickCloseModal={onClickHideDeleteModal} />
      )}
    </div>
  );
};

InvestorDetailsHeader.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorDetailsHeader;
