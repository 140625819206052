import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';

export const getTargetName = (investors, mode) => {
  if (mode === 'investors') return `${investors.length} Clients`;
  const [
    {
      accounts: [account],
      full_name: fullName
    }
  ] = investors;
  let targetName = fullName;
  if (mode === 'account') targetName = `${targetName}'s ${account.display_name}`;
  return targetName;
};

export const getExternalQuestionnaireURL = (investor, mode, questionnaireTemplateId) => {
  let url;

  if (mode === 'account') url = new URL(investor.accounts[0].target_score_url);
  if (mode.startsWith('investor')) url = new URL(investor.target_score_url);
  if (mode === 'accounts') {
    url = new URL(investor.target_score_url);
    url.searchParams.set('accounts', investor.accounts.map(a => a.id).join(','));
  }
  if (url && questionnaireTemplateId)
    url.searchParams.set(QUESTIONNAIRE_TEMPLATE_URL_PARAM, questionnaireTemplateId);

  return url?.href;
};

export const getRiskToleranceScore = (investor, accounts, mode) => {
  if (mode === 'account') {
    const [account] = accounts;
    return account?.target_score_summary?.overall;
  }
  if (mode === 'investor') return investor?.aggregated_target_scores?.overall;
  return null;
};

export const getUserName = investors => {
  const [{ full_name: fullName }] = investors;
  return fullName || 'Client/Prospect';
};
