import React, { useState } from 'react';
import TwoFactorActive from './active';
import TwoFactorSetup from './setup';

const TwoFactor = () => {
  const [setupScreenType, setSetupScreenType] = useState('');
  const [setupScreenEnabled, setSetupScreenEnabled] = useState(false);

  return setupScreenEnabled ? (
    <TwoFactorSetup setupScreenType={setupScreenType} activeSetupScreen={setSetupScreenEnabled} />
  ) : (
    <TwoFactorActive
      handleSetupScreenType={setSetupScreenType}
      activeSetupScreen={setSetupScreenEnabled}
    />
  );
};

export default TwoFactor;
