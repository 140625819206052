import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const WarningIcon = ({ className, disabled, title, ...rest }) => (
  <svg
    id="warning-icon"
    data-name="Warning Icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 187.76 187.76"
    className={cn('warning-icon', className)}
    {...rest}
  >
    <defs>
      <style>
        {`
          .warning-icon .cls-1, .warning-icon .cls-4{
            fill:none;
            stroke:#09acf8;
            stroke-miterlimit:10;
          }
          .warning-icon .cls-1{
            stroke-width:7px;
            stroke-dasharray:17.78 17.78;
          }
          .warning-icon .cls-2{
            opacity:0.59;
          }
          .warning-icon .cls-3{
            fill:#09acf8;
          }
          .warning-icon .stroke-disabled{
            stroke:#78d3f9;
          }
          .warning-icon .fill-disabled{
            fill:#78d3f9;
          }
        `}
      </style>
    </defs>
    {title && <title>{title}</title>}
    <path
      className={`cls-1 ${disabled ? 'stroke-disabled' : ''}`}
      d="M341.83,258c-1.62,42.29-40.59,81.26-82.87,82.86a66.55,66.55,0,0,0-14.93,0c-47.14-3.48-84.09-44.55-82.91-92.18,1.18-48.15,40.08-87.26,88-88.53s89.21,35.6,92.68,82.92A66.55,66.55,0,0,0,341.83,258Z"
      transform="translate(-157.59 -156.59)"
    />
    <g className="cls-2">
      <rect
        className={`cls-3 ${disabled ? 'fill-disabled' : ''}`}
        x="82.99"
        y="42.41"
        width="23.42"
        height="70.66"
      />
      <rect
        className={`cls-4 ${disabled ? 'stroke-disabled' : ''}`}
        x="82.99"
        y="42.41"
        width="23.42"
        height="70.66"
      />
    </g>
    <g className="cls-2">
      <circle
        className={`cls-3 ${disabled ? 'fill-disabled' : ''}`}
        cx="94.7"
        cy="135.69"
        r="11.58"
      />
      <circle
        className={`cls-4 ${disabled ? 'stroke-disabled' : ''}`}
        cx="94.7"
        cy="135.69"
        r="11.58"
      />
    </g>
  </svg>
);

WarningIcon.defaultProps = {
  className: '',
  disabled: false,
  title: ''
};

WarningIcon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string
};

export default WarningIcon;
