import DriftRangeSlider from 'components/slider/drift-range';
import _ from 'lodash';
import React, { useCallback } from 'react';
import FilterContainer from '../common/filter-container';
import { EXCEPTION_DRIFT_RANGE_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const DEFAULT_MIN_RANGE_VALUE = 0;
const DEFAULT_MAX_RANGE_VALUE = 10;
const DEFAULT_RANGE = [DEFAULT_MIN_RANGE_VALUE, DEFAULT_MAX_RANGE_VALUE];

const ExceptionDriftRangeFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const getDefaultValue = () => {
    if (!filters[EXCEPTION_DRIFT_RANGE_ATTR]) return DEFAULT_RANGE;
    return filters[EXCEPTION_DRIFT_RANGE_ATTR].split('__').map(value => Number.parseFloat(value));
  };

  const defaultValue = getDefaultValue();

  const delayedOnChangeHandler = useCallback(
    _.debounce(values => {
      const useDefault = JSON.stringify(values) === JSON.stringify(DEFAULT_RANGE);
      setFilters(prevFilters => ({
        ...prevFilters,
        [EXCEPTION_DRIFT_RANGE_ATTR]: useDefault ? undefined : values.join('__')
      }));
      setRequiresPageIndexReset(true);
    }, 250),
    []
  );

  const onChangeHandler = values => {
    delayedOnChangeHandler(values);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_DRIFT_RANGE_ATTR]} filters={filters} label="Drift Range">
      <DriftRangeSlider
        defaultValue={defaultValue}
        max={DEFAULT_MAX_RANGE_VALUE}
        min={DEFAULT_MIN_RANGE_VALUE}
        onChange={onChangeHandler}
        steps={0.1}
      />
    </FilterContainer>
  );
};

ExceptionDriftRangeFilter.propTypes = { ...filterPropTypes };

ExceptionDriftRangeFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionDriftRangeFilter;
