// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/img/icons/blue-check.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#WelcomeModal .modal-body {
  padding: 1rem 0;
}
#WelcomeModal .modal-content {
  padding: 2rem;
  text-align: center;
}
#WelcomeModal .modal-content h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
#WelcomeModal .modal-content p {
  margin: 0 auto;
}
#WelcomeModal .modal-content p.font-weight-bold {
  margin: 0 auto;
}
#WelcomeModal .modal-content ul {
  display: inline-block;
  text-align: left;
  margin: 2rem auto;
}
#WelcomeModal .modal-content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.9rem;
  list-style-type: none;
}
#WelcomeModal .modal-content ul li:before {
  content: "";
  display: inline-block;
  height: 2rem;
  width: 1.5rem;
  margin-right: 1rem;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#WelcomeModal .modal-content ul li:last-child {
  margin-bottom: 0;
}
#WelcomeModal .modal-content button {
  min-width: 17rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/welcome-modal/styles.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;AAFJ;AAKE;EACE,aAAA;EACA,kBAAA;AAHJ;AAKI;EACE,iBAAA;EACA,mBAAA;AAHN;AAMI;EACE,cAAA;AAJN;AAOI;EACE,cAAA;AALN;AAQI;EACE,qBAAA;EACA,gBAAA;EACA,iBAAA;AANN;AAQM;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;AANR;AAQQ;EACE,WAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yDAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;AANV;AASQ;EACE,gBAAA;AAPV;AAYI;EACE,gBAAA;AAVN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#WelcomeModal {\n  .modal-body {\n    padding: 1rem 0;\n  }\n\n  .modal-content {\n    padding: 2rem;\n    text-align: center;\n\n    h1 {\n      font-size: 1.5rem;\n      margin-bottom: 1rem;\n    }\n\n    p {\n      margin: 0 auto;\n    }\n\n    p.font-weight-bold {\n      margin: 0 auto;\n    }\n\n    ul {\n      display: inline-block;\n      text-align: left;\n      margin: 2rem auto;\n\n      li {\n        display: flex;\n        align-items: center;\n        margin-bottom: 0.9rem;\n        list-style-type: none;\n\n        &:before {\n          content: '';\n          display: inline-block;\n          height: 2rem;\n          width: 1.5rem;\n          margin-right: 1rem;\n          background-image: url('assets/img/icons/blue-check.svg');\n          background-position: center;\n          background-size: contain;\n          background-repeat: no-repeat;\n        }\n\n        &:last-child {\n          margin-bottom: 0;\n        }\n      }\n    }\n\n    button {\n      min-width: 17rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
