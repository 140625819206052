// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#available-integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}
@media (min-width: 1200px) {
  #available-integrations {
    grid-template-columns: repeat(4, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/integrations-dashboard/available/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;EAAA,SAAA;AADF;AAGE;EALF;IAMI,qCAAA;EAAF;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#available-integrations {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 1rem;\n\n  @media (min-width: 1200px) {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
