import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW, LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  container: { ...BASE_TABLE_ROW, marginBottom: 20 },
  position: {
    marginRight: 10,
    paddingHorizontal: 10,
    paddingVertical: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: LIGHT_GRAPHITE_COLOR,
    fontSize: 12
  },
  account: {
    ...BASE_TABLE_ROW,
    flexGrow: 1,
    paddingHorizontal: 10,
    paddingVertical: 7,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: LIGHT_GRAPHITE_COLOR,
    fontSize: 12
  }
});

export default styles;
