import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import Tooltip from 'components/tooltip';

const DisplayScore = ({ score, manualScore, className, tooltip, isStrategy }) => {
  const cls = cs(
    { 'risk-badge-with-score': !manualScore },
    { 'risk-badge-with-manual-score': manualScore },
    `risk-level-${Math.ceil(score)}${isStrategy ? '-strategy' : ''}`,
    className
  );

  if (tooltip)
    return (
      <Tooltip {...tooltip}>
        <span className={cls}>{score.toFixed(1) === '10.0' ? '10' : score.toFixed(1)}</span>
      </Tooltip>
    );

  return <span className={cls}>{score.toFixed(1) === '10.0' ? '10' : score.toFixed(1)}</span>;
};

DisplayScore.propTypes = {
  isStrategy: PropTypes.bool,
  score: PropTypes.number.isRequired,
  manualScore: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.object
};

DisplayScore.defaultProps = {
  isStrategy: false,
  manualScore: false,
  className: '',
  tooltip: null
};

export default DisplayScore;
