// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketplace-about {
  display: flex;
  justify-content: space-between;
}
.marketplace-about .top-divider {
  display: flex;
  border-top: 1px solid #e3e7ee;
  margin-top: 20px;
  padding-top: 5px;
}
.marketplace-about .card-overview {
  width: 100%;
  min-height: 500px;
}
.marketplace-about .card-overview .card-header {
  text-align: left;
}
.marketplace-about .card-overview .card-body {
  text-align: left;
}
.marketplace-about__company {
  display: inline-table;
}
.marketplace-about__company__logo {
  width: 100%;
  height: 100px;
  margin: 10px auto;
}
.marketplace-about__company__logo img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.marketplace-about__model {
  display: inline-table;
}
.marketplace-about__model__type {
  font-weight: 700;
}
.marketplace-about__model__inception-date {
  font-weight: 700;
}
.marketplace-about #security-type-concentration {
  height: 130px;
}
.marketplace-about > .col-5 {
  padding-left: 0;
}
.marketplace-about > .col-7 {
  padding-right: 0;
}
.marketplace-about .score-representation {
  text-align: 5;
}
.marketplace-about .collapsible-container {
  margin: 40px auto 40px 10px !important;
}
.marketplace-about .overall-prism-full.prism-without-target {
  display: grid;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/marketplace/details/about/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;AADF;AAGE;EACE,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,gBAAA;AADJ;AAIE;EACE,WAAA;EACA,iBAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAII;EACE,gBAAA;AAFN;AAME;EACE,qBAAA;AAJJ;AAMI;EACE,WAAA;EACA,aAAA;EACA,iBAAA;AAJN;AAMM;EACE,sBAAA;KAAA,mBAAA;EACA,WAAA;EACA,YAAA;AAJR;AASE;EACE,qBAAA;AAPJ;AASI;EACE,gBAAA;AAPN;AASI;EACE,gBAAA;AAPN;AAWE;EACE,aAAA;AATJ;AAYE;EACE,eAAA;AAVJ;AAYE;EACE,gBAAA;AAVJ;AAaE;EACE,aAAA;AAXJ;AAcE;EACE,sCAAA;AAZJ;AAeE;EACE,aAAA;EACA,kBAAA;AAbJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.marketplace-about {\n  display: flex;\n  justify-content: space-between;\n\n  .top-divider {\n    display: flex;\n    border-top: 1px solid $gray-light-3;\n    margin-top: 20px;\n    padding-top: 5px;\n  }\n\n  .card-overview {\n    width: 100%;\n    min-height: 500px;\n\n    .card-header {\n      text-align: left;\n    }\n    .card-body {\n      text-align: left;\n    }\n  }\n\n  &__company {\n    display: inline-table;\n\n    &__logo {\n      width: 100%;\n      height: 100px;\n      margin: 10px auto;\n\n      img {\n        object-fit: contain;\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }\n\n  &__model {\n    display: inline-table;\n\n    &__type {\n      font-weight: 700;\n    }\n    &__inception-date {\n      font-weight: 700;\n    }\n  }\n\n  #security-type-concentration {\n    height: 130px;\n  }\n\n  & > .col-5 {\n    padding-left: 0;\n  }\n  & > .col-7 {\n    padding-right: 0;\n  }\n\n  .score-representation {\n    text-align: 5;\n  }\n\n  .collapsible-container {\n    margin: 40px auto 40px 10px !important;\n  }\n\n  .overall-prism-full.prism-without-target {\n    display: grid;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
