// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signature-request-trail {
  margin-top: 30px;
}
#signature-request-trail > h2 {
  font-size: 1.25rem;
}
#signature-request-trail .trail-entry {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 4px 0 8px 0;
  border-bottom: 1px solid #e3e7ee;
  font-size: 14px;
  gap: 10px;
}
#signature-request-trail .trail-entry:last-child {
  border-bottom: 0;
}
#signature-request-trail .trail-entry > img {
  height: 15px;
}
#signature-request-trail .trail-entry .trail-entry__date {
  min-width: 180px;
  font-weight: 500;
}
#signature-request-trail .trail-entry .trail-entry__text {
  display: flex;
  flex-direction: column;
}

#signature-request-trail-inline {
  display: flex;
  align-items: center;
  font-size: 14px;
}
#signature-request-trail-inline > button.btn-link {
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/proposal/header/investor/form/signature-request-trail/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,gCAAA;EACA,eAAA;EACA,SAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAKI;EACE,YAAA;AAHN;AAMI;EACE,gBAAA;EACA,gBAAA;AAJN;AAOI;EACE,aAAA;EACA,sBAAA;AALN;;AAUA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAPF;AASE;EACE,eAAA;EACA,gBAAA;AAPJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#signature-request-trail {\n  margin-top: 30px;\n\n  & > h2 {\n    font-size: 1.25rem;\n  }\n\n  .trail-entry {\n    display: flex;\n    align-items: center;\n    margin-bottom: 4px;\n    padding: 4px 0 8px 0;\n    border-bottom: 1px solid $gray-light-3;\n    font-size: 14px;\n    gap: 10px;\n\n    &:last-child {\n      border-bottom: 0;\n    }\n\n    & > img {\n      height: 15px;\n    }\n\n    .trail-entry__date {\n      min-width: 180px;\n      font-weight: 500;\n    }\n\n    .trail-entry__text {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n}\n\n#signature-request-trail-inline {\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n\n  & > button.btn-link {\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
