import { color } from '@amcharts/amcharts5';
import TreemapChart from 'components/charts/treemap-chart';
import PropTypes from 'prop-types';
import React from 'react';
import {
  SECURITY_TYPES,
  SECURITY_TYPE_CATEGORIES,
  SECURITY_TYPE_CONCENTRATION_CHART_ID,
  SECURITY_TYPE_OTHER
} from './constants';
import './styles.scss';

const processPositions = (positions, totalAssets) => {
  const securityTypes = positions.reduce((acc, position) => {
    const securityType = position.security_type_name || SECURITY_TYPE_OTHER;
    acc[securityType] = (acc[securityType] || 0) + (position.value || 0);
    return acc;
  }, {});

  const securityTypeCategories = SECURITY_TYPE_CATEGORIES.reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  );

  Object.keys(securityTypes).forEach(securityType => {
    const type = SECURITY_TYPES[securityType] || SECURITY_TYPES[SECURITY_TYPE_OTHER];
    const { category, color: categoryColor } = type;
    securityTypeCategories[category].push({
      name: securityType,
      value: securityTypes[securityType] / (totalAssets || 100),
      color: color(categoryColor)
    });
  });

  return Object.entries(securityTypeCategories).map(([category, securityTypes]) => ({
    name: category,
    children: securityTypes
  }));
};

const SecurityTypeConcentration = ({ id, positions, onChartReady, totalAssets }) => {
  const data = { legend: [], options: processPositions(positions, totalAssets) };
  return (
    <div id="security-type-concentration">
      <TreemapChart
        data={data}
        height={225}
        id={id}
        initialDepth={2}
        onChartReady={onChartReady}
        showLabelPercentages
        width="100%"
      />
    </div>
  );
};

SecurityTypeConcentration.propTypes = {
  id: PropTypes.string,
  onChartReady: PropTypes.func,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      ticker: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  totalAssets: PropTypes.number
};

SecurityTypeConcentration.defaultProps = {
  id: SECURITY_TYPE_CONCENTRATION_CHART_ID,
  onChartReady: null,
  positions: null,
  totalAssets: null
};

export default SecurityTypeConcentration;
