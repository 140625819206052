import React from 'react';
import giftcardIcon from 'assets/img/icons/giftcard.svg';
import './styles.scss';

const ReferralBtn = () => {
  const { REFERRAL_FACTORY_CODE } = process.env;
  if (!REFERRAL_FACTORY_CODE) return null;

  return (
    <div className="referral-btn">
      <button type="button" className={`rf-popup-${REFERRAL_FACTORY_CODE}`}>
        <img src={giftcardIcon} alt="giftcard-icon" /> Refer a friend
      </button>
      <div className="referral_popover">
        <div className="referral_popover__title">Be the trusted advisor for you friends</div>
        <div className="referral_popover__content">Click here and get your referral link</div>
      </div>
    </div>
  );
};

export default ReferralBtn;
