// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BillingHistory .billing-history-container {
  display: grid;
  font-weight: 400;
  grid-template-columns: auto;
  grid-gap: 5px;
  gap: 5px;
}
#BillingHistory .billing-history-container .billing-history-container__item {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 5px;
  gap: 5px;
}
#BillingHistory .billing-history-container .billing-history-container__item .billing-history-container__item__date,
#BillingHistory .billing-history-container .billing-history-container__item .billing-history-container__item__description,
#BillingHistory .billing-history-container .billing-history-container__item .billing-history-container__item__amount {
  padding: 10px 20px;
  background-color: white;
}
@media (min-width: 768px) {
  #BillingHistory .billing-history-container .billing-history-container__item {
    grid-template-columns: 1fr 2fr 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/billing/history/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,gBAAA;EACA,2BAAA;EACA,aAAA;EAAA,QAAA;AAFJ;AAII;EACE,aAAA;EACA,2BAAA;EACA,aAAA;EAAA,QAAA;AAFN;AAIM;;;EAGE,kBAAA;EACA,uBAAA;AAFR;AAMI;EACE;IACE,kCAAA;EAJN;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#BillingHistory {\n  .billing-history-container {\n    display: grid;\n    font-weight: 400;\n    grid-template-columns: auto;\n    gap: 5px;\n\n    .billing-history-container__item {\n      display: grid;\n      grid-template-columns: auto;\n      gap: 5px;\n\n      .billing-history-container__item__date,\n      .billing-history-container__item__description,\n      .billing-history-container__item__amount {\n        padding: 10px 20px;\n        background-color: white;\n      }\n    }\n\n    @media (min-width: 768px) {\n      .billing-history-container__item {\n        grid-template-columns: 1fr 2fr 1fr;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
