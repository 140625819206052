import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';
import { DEFAULT_AMOUNT, PotentialGainLossText } from '../../utils';

const SingleYearOutcomeEmptyQuestion = ({ questions, question }) => {
  const choicesStyle = question?.description ? { marginTop: 0 } : { marginTop: 15 };
  const defaultAmount = question.data?.default_amount || DEFAULT_AMOUNT;

  return (
    <ReportEmptyQuestion questions={questions} question={question} withDescription>
      <View style={[styles.choices, choicesStyle]}>
        {question.data.labels.map(label => (
          <View style={styles.mark}>
            <View style={styles.markCheck} />
            <Text style={styles.markText}>
              <PotentialGainLossText defaultAmount={defaultAmount} option={label} />
            </Text>
          </View>
        ))}
      </View>
    </ReportEmptyQuestion>
  );
};

SingleYearOutcomeEmptyQuestion.propTypes = propTypes;

export default SingleYearOutcomeEmptyQuestion;
