import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { getPrismFactorName, OVERALL_FACTOR_ATTR } from '../common/prism-factor/utils';
import { INVESTOR_PRISM_FACTOR_ATTRS, PRISM_FACTOR_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';
import { getInvestorMinMaxPrismFactorAttrs } from '../utils';

const InvestorPrismOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const prismFactorAttr = filters?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
  const prismFactorName = getPrismFactorName(prismFactorAttr);

  const [minAttr, maxAttr] = getInvestorMinMaxPrismFactorAttrs(prismFactorAttr);

  return (
    <FilterContainer
      attrs={INVESTOR_PRISM_FACTOR_ATTRS}
      filters={filters}
      label={prismFactorName ? `Investor PRISM ${prismFactorName}` : 'Investor PRISM Overall'}
    >
      <MinMaxSliderFilter
        filters={filters}
        key={`investor-${minAttr}-${maxAttr}`}
        maxAttr={maxAttr}
        minAttr={minAttr}
        setFilters={setFilters}
        setRequiresPageIndexReset={setRequiresPageIndexReset}
      />
    </FilterContainer>
  );
};

InvestorPrismOverallFilter.propTypes = { ...filterPropTypes };

InvestorPrismOverallFilter.defaultProps = { ...filterDefaultProps };

export default InvestorPrismOverallFilter;
