import cn from 'classnames';
import {
  IndicatorsContainer,
  MenuList,
  Option,
  SingleValue
} from 'components/advisor/generic-selector';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import './styles.scss';
import {
  ACCOUNT_OPTION,
  ACCOUNT_TYPE,
  MODEL_PORTFOLIO_OPTION,
  MODEL_PORTFOLIO_TYPE,
  PORTFOLIO_OPTIONS,
  SECURITY_OPTION,
  SECURITY_TYPE,
  WEIGHTED_SELECT_STYLES,
  WEIGHT_AS_AMOUNT_FIELD_WIDTH,
  getKey,
  populateSuggestions
} from './utils';

const ENTER_KEY = 13;
const INVESTORS_URL = '/advisor/investors';
const PROSPECTS_URL = '/advisor/prospects';

const SelectorRow = ({
  error,
  onChange,
  onRemove,
  scope,
  type: sourceType,
  value,
  withPercentages
}) => {
  const { accountProvider, modelProvider, marketProvider } = useContext(AdvisorContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [type, setType] = useState(sourceType);

  const isEmpty = _.isEmpty(value);
  const numericFormatProps = withPercentages
    ? { suffix: '%' }
    : { prefix: '$', thousandSeparator: true };
  const provider = {
    [ACCOUNT_TYPE]: accountProvider,
    [MODEL_PORTFOLIO_TYPE]: modelProvider,
    [SECURITY_TYPE]: marketProvider
  };

  const setWeight = ({ floatValue: weight }) => {
    if (value) onChange({ ...value, weight: Number.isNaN(weight) ? 0 : weight });
  };

  const addRow = selection => onChange(selection, getKey(value));

  const removeRow = event => {
    if (!value || (event && event.type === 'keyup' && event.keyCode !== ENTER_KEY)) return;
    onRemove(value);
  };

  const loadAsyncOptions = term => {
    setLoading(true);
    setSuggestions([]);
    const params = { search: term || undefined };
    if (type === ACCOUNT_TYPE) {
      // allows getting both investor's and prospects' accounts
      params.all_object_types = true;

      // allows limiting the accounts according to the selected targets
      if (
        window.location.pathname.includes(INVESTORS_URL) ||
        window.location.pathname.includes(PROSPECTS_URL)
      ) {
        const investorIds = Array.from(new Set(scope.map(account => account.investor_id)));
        params.investor = investorIds;
      }
    }
    return provider[type].es
      .list(params)
      .then(populateSuggestions(type, scope, setSuggestions))
      .finally(() => {
        setLoading(false);
      });
  };

  const onInputChangeHandler = useCallback(
    _.debounce(term => {
      // prevents searches from being performed if the search term has not been changed
      if (term !== searchTerm) {
        loadAsyncOptions(term);
        setSearchTerm(term);
      }
    }, 500),
    [type, searchTerm]
  );

  const onFocusHandler = () => {
    if (_.isEmpty(suggestions)) loadAsyncOptions();
  };

  const getDefaultPlaceholder = () => {
    if (type === ACCOUNT_TYPE) return 'Select an account ...';
    if (type === SECURITY_TYPE) return 'Select a security ...';
    return 'Select a portfolio ...';
  };

  const getDefaultPortfolioType = () => {
    if (type === ACCOUNT_TYPE) return ACCOUNT_OPTION;
    if (type === SECURITY_TYPE) return SECURITY_OPTION;
    return MODEL_PORTFOLIO_OPTION;
  };

  const onChangePortfolioTypeHandler = ({ value: newType }) => {
    if (type !== newType) {
      setType(newType);
      setSuggestions([]);
    }
  };

  return (
    <div key={getKey(value)} className="weighted-portfolio-row">
      <Select
        defaultValue={getDefaultPortfolioType()}
        isDisabled={!!value}
        onChange={onChangePortfolioTypeHandler}
        options={PORTFOLIO_OPTIONS}
        styles={WEIGHTED_SELECT_STYLES}
      />

      <Select
        className="portfolio-select"
        components={{ MenuList, Option, SingleValue, IndicatorsContainer }}
        isLoading={loading}
        key={`${type}-${getKey(value)}`}
        onChange={addRow}
        onFocus={onFocusHandler}
        filterOption={(options, filter, currentValues) => options} // bypasses default frontend filtering done by the component
        onInputChange={onInputChangeHandler}
        options={suggestions}
        placeholder={getDefaultPlaceholder()}
        styles={WEIGHTED_SELECT_STYLES}
        value={value}
      />

      <div
        className={cn('portfolio-weight', { error })}
        style={withPercentages ? {} : { flex: `0 0 ${WEIGHT_AS_AMOUNT_FIELD_WIDTH}px` }}
      >
        <NumericFormat
          allowNegative={false}
          decimalScale={2}
          disabled={isEmpty}
          onValueChange={setWeight}
          value={isEmpty ? 0 : value.weight}
          {...numericFormatProps}
        />
      </div>

      <button aria-label="Remove" type="button" onClick={removeRow} title="Remove Portfolio">
        <i className="fs-icon-xmark-linear" />
      </button>
    </div>
  );
};

SelectorRow.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  scope: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.object,
  withPercentages: PropTypes.bool.isRequired
};

SelectorRow.defaultProps = {
  error: false,
  value: null
};

export default SelectorRow;
