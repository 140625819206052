import Choice from 'components/form/choice';
import Tooltip, { Directions } from 'components/tooltip';
import { AdvisorContext } from 'containers/advisor';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import './styles.scss';

const handleUpdateError = (message = 'There was an error updating your settings.') => {
  toast.error(() => <div>{message}</div>);
};

const ClassificationSettings = () => {
  /* Context */
  const {
    authProvider,
    companyProvider,
    user: {
      advisor: {
        company: {
          expand_asset_classification: expandAssetClassification,
          expand_asset_details: expandAssetDetails
        }
      }
    }
  } = useContext(AdvisorContext);

  /* Helpers & Handlers */
  const changeCompanySettings = async values => {
    try {
      const { data } = await companyProvider.update(values);
      if (data) {
        await authProvider.getUser();
        toast.success(() => <div>Your settings has been saved.</div>);
      } else handleUpdateError();
    } catch {
      handleUpdateError();
    }
  };

  const onChangeExpandAssetClassification = () => {
    const values = { expand_asset_classification: !expandAssetClassification };
    if (!values.expand_asset_classification) values.expand_asset_details = false;
    changeCompanySettings(values);
  };

  const onChangeExpandAssetDetails = () => {
    changeCompanySettings({ expand_asset_details: !expandAssetDetails });
  };

  return (
    <div id="ClassificationSettings">
      <h4>Customize level of detail</h4>
      <div>
        <div className="templates-meta-helper-container">
          Enable to show the sector in each asset class on the proposal and IPS
        </div>
        <Choice
          title="Expand asset classification to show sectors on the proposal and IPS"
          checked={expandAssetClassification}
          toggle={onChangeExpandAssetClassification}
        />
        <div>
          <img src="/img/classification-l2-preview.png" alt="" />
        </div>
      </div>

      <div>
        <div className="templates-meta-helper-container">
          Enable to show the specific holdings in each asset class on the proposal and IPS
        </div>
        <Tooltip
          text="You must enable sector level detail above first"
          direction={Directions.TOP}
          style={{ fontSize: 13, width: 300, height: 38, paddingTop: 10, opacity: 0.8 }}
          disabled={expandAssetClassification}
        >
          <Choice
            title="Expand asset classification to show holdings on the proposal and IPS"
            checked={expandAssetDetails}
            toggle={onChangeExpandAssetDetails}
            disabled={!expandAssetClassification}
          />
        </Tooltip>
        <div>
          <img src="/img/classification-l3-preview.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ClassificationSettings;
