import cn from 'classnames';
import DownloadIcon from 'components/svg-icons/download-icon';
import PortraitLandscapeIcon from 'components/svg-icons/portrait-landscape-icon';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { LANDSCAPE_ORIENTATION, PORTRAIT_ORIENTATION } from 'reports/constants';
import './styles.scss';

const DEFAULT_SCALE = 100;
const FIRST_PAGE = 1;
const PADDING_THRESHOLD = 20;
const PAGE_CONTAINER_MAX_WIDTH = 1100;
const SCALE_MULTIPLIER = 0.01;
const SCROLLBAR_WIDTH = 10;

const ReportMessage = ({ message }) => (
  <div className="report-controls" style={{ minHeight: 54, justifyContent: 'center' }}>
    <div />
    <div style={{ justifySelf: 'center' }}>{message}</div>
    <div />
  </div>
);

ReportMessage.propTypes = {
  message: PropTypes.string.isRequired
};

const ReportViewer = ({
  adaptativeWidth,
  changeOrientation,
  orientation,
  height,
  url,
  withCloseButton,
  onLoad
}) => {
  if (!url) return null;

  const pageContainerRef = useRef(null);
  const pageRef = useRef(null);

  const [metadata, setMetadata] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [pageContainerWidth, setPageContainerWidth] = useState(null);
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);
  const [pageWidth, setPageWidth] = useState(null);
  const [scale, setScale] = useState(DEFAULT_SCALE);

  useEffect(() => {
    if (onLoad) onLoad();
  }, []);

  const onDocumentLoadSuccess = async pdf => {
    setMetadata(await pdf.getMetadata());
    setNumPages(pdf.numPages);
  };

  const handlePageChange = idx => () => {
    setPageNumber(idx);
  };

  const handleOnChangeOrientation = () => {
    if (changeOrientation)
      changeOrientation(
        orientation === PORTRAIT_ORIENTATION ? LANDSCAPE_ORIENTATION : PORTRAIT_ORIENTATION
      );
  };

  useLayoutEffect(() => {
    if (pageContainerRef.current) setPageContainerWidth(pageContainerRef.current.offsetWidth);
  }, [pageContainerRef.current]);

  useLayoutEffect(() => {
    if (pageRef.current) setPageWidth(pageRef.current.offsetWidth);
  }, [pageRef.current]);

  useLayoutEffect(() => {
    if (!adaptativeWidth) return;
    const fullWidthScale =
      (Math.min(
        pageContainerWidth - PADDING_THRESHOLD - SCROLLBAR_WIDTH,
        PAGE_CONTAINER_MAX_WIDTH
      ) *
        DEFAULT_SCALE) /
      pageWidth;
    if (Number.isFinite(fullWidthScale)) setScale(fullWidthScale);
  }, [pageContainerWidth]);

  return (
    <div id="report-read-only-viewer">
      <Document
        file={url}
        error={<ReportMessage message="Something went wrong while generating the report" />}
        loading={<ReportMessage message="Loading ..." />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <div className="report-controls">
          <div className="report-controls__name">{metadata?.info?.Title}</div>
          <div className="report-controls__pages">
            {numPages ? `Page ${pageNumber} / ${numPages}` : '-'}
          </div>
          <div className="report-controls__actions">
            <button
              className="btn btn-outline-dark font-weight-normal"
              onClick={() => setScale(prev => prev + 15)}
              title="Zoom in"
              type="button"
            >
              +
            </button>
            <button
              className="btn btn-outline-dark font-weight-normal"
              onClick={() => setScale(prev => prev - 15)}
              title="Zoom out"
              type="button"
            >
              &#x2212;
            </button>

            <div className="actions__separator" />

            {changeOrientation && (
              <button
                aria-label="Change Orientation"
                className="btn btn-secondary p-0"
                onClick={handleOnChangeOrientation}
                title="Change orientation"
                type="button"
              >
                <PortraitLandscapeIcon />
              </button>
            )}

            <a
              aria-label="Download Report"
              className="btn btn-secondary p-0"
              download
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              title="Download"
            >
              <DownloadIcon />
            </a>

            {withCloseButton && (
              <button
                aria-label="Close Report"
                className="btn btn-secondary"
                data-dismiss="modal"
                title="Close"
                type="button"
              >
                <i className="icon-cross" />
              </button>
            )}
          </div>
        </div>

        <div className="report-preview">
          <div
            className="report-preview__navigation"
            style={{ maxHeight: height + PADDING_THRESHOLD }}
          >
            {Array.from(new Array(numPages), (_, idx) => (
              <Page
                className={cn({ 'page-selected': idx + 1 === pageNumber })}
                height={85}
                key={`page-${idx + 1}`}
                onClick={handlePageChange(idx + 1)}
                pageNumber={idx + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
          </div>
          <div
            className="report-preview__page"
            style={{ maxHeight: height + PADDING_THRESHOLD }}
            ref={pageContainerRef}
          >
            <Page
              canvasRef={pageRef}
              height={height}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={scale * SCALE_MULTIPLIER}
            />
          </div>
        </div>
      </Document>
    </div>
  );
};

ReportViewer.defaultProps = {
  adaptativeWidth: true,
  changeOrientation: null,
  height: 550,
  orientation: PORTRAIT_ORIENTATION,
  url: null,
  withCloseButton: false,
  onLoad: null
};

ReportViewer.propTypes = {
  adaptativeWidth: PropTypes.bool,
  changeOrientation: PropTypes.func,
  height: PropTypes.number,
  orientation: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  withCloseButton: PropTypes.bool,
  onLoad: PropTypes.func
};

export default ReportViewer;
