import PropTypes from 'prop-types';
import React from 'react';

const InvestorDriftCell = ({ investor }) => {
  const drift = investor.drift_summary?.overall ? investor.drift_summary.overall.toFixed(1) : '-';
  return <div>{drift}</div>;
};

InvestorDriftCell.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorDriftCell;
