import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import { getWithdrawalRequirementAnswer } from '../utils';

const WithdrawalRequirementQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  return (
    <ReportQuestion question={question}>
      <Text>{getWithdrawalRequirementAnswer(question)}</Text>
    </ReportQuestion>
  );
};

WithdrawalRequirementQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default WithdrawalRequirementQuestion;
