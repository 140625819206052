import NotesList, { NOTE_LAYOUTS } from 'components/advisor/accounts/notes-list';
import AccountNotesModal from 'components/advisor/accounts/notes-modal';
import { Option } from 'components/advisor/generic-selector';
import AccountExceptionsRow from 'components/advisor/investors/drift-exceptions/account-exception-row';
import LoadingButton from 'components/loading-button';
import Textarea from 'components/user-textarea';
import { AdvisorContext } from 'containers/advisor';
import withSubscription from 'hocs/subscription-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'redux';
import { getNoteActionOptions, NOTE_OTHER_ACTION_VALUE } from 'utils/drift';
import './styles.scss';

export const InvestorDetailsNotes = ({
  className,
  investor: investorProp,
  investorAccounts,
  location: { pathname },
  params: { id },
  prospect,
  prospectAccounts
}) => {
  const {
    authProvider,
    accountProvider,
    investorProvider,
    prospectProvider,
    user: {
      advisor: {
        company: { exceptions_actions: exceptionsActions }
      }
    },
    user
  } = useContext(AdvisorContext);

  const isProspect = pathname.includes('advisor/prospects/');
  const investor = isProspect ? prospect : investorProp;
  const investorId = _.isEmpty(investor) ? Number(id) : investor.id;
  const provider = isProspect ? prospectProvider : investorProvider;
  const accounts = isProspect ? prospectAccounts : investorAccounts;

  const accountsOptions = accounts.map(account => ({
    label: account.display_name,
    value: account.id,
    score: account?.prism_score_summary?.overall
  }));

  const [accountForNote, setAccountForNote] = useState(null);
  const [action, setAction] = useState(null);
  const [message, setMessage] = useState('');
  const [nonce, setNonce] = useState(0);
  const [selectedException, setSelectedException] = useState({});
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [target, setTarget] = useState(investor);
  const [withAccounts, setWithAccounts] = useState(accounts.map(account => account.id));

  const textAreaElementRef = useRef(null);

  const disableLeaveNoteButton =
    action === null || (action === NOTE_OTHER_ACTION_VALUE && !message) || submitting;

  const handleAccountForNote = account => {
    if (!account) setAccountForNote(null);
    else setAccountForNote(accounts.find(({ id }) => id === account.value));
  };

  const handleChangeMessage = event => {
    setMessage(event.target.value);
  };

  const handleFilterByAccount = account => {
    if (!account) {
      // when filtering by an investor, returns all its notes + account notes
      setWithAccounts(accounts.map(account => account.id));
      setTarget(investor);
    } else {
      // when filtering by an account, returns only its notes
      setWithAccounts(null);
      setTarget(accounts.find(({ id }) => id === account.value));
    }
  };

  const postSave = () => {
    Promise.all([provider.get(investor.id), provider.getAccounts(investor.id)]).then(() => {
      // update nonce each time investor change to force <AccountExceptionsRow /> to be updated
      setNonce(nonce + 1);

      setSubmitting(false);

      // clear possible remaining data from input controls
      const { clear } = textAreaElementRef.current.getWrappedInstance();
      clear();
    });
  };

  const handleSave = event => {
    event.preventDefault();

    setSubmitting(true);

    const note = {
      message: `${action ? `${action}. ` : ''}${message}`
    };

    if (accountForNote)
      accountProvider.addNote(accountForNote, note).then(() => {
        postSave();
      });
    else
      provider.addNote(investor, note).then(() => {
        postSave();
      });
  };

  const openNotesModal = (event, exception) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedException(exception);
    setShowNotesModal(true);
  };

  useEffect(() => {
    if (_.isEmpty(investor)) {
      provider.get(investorId);
      provider.getAccounts(investorId);
    }

    const isAccount = !!target.investor_id;
    if (isAccount) setTarget(accounts.find(({ id }) => id === target.id));
    else setTarget(investor);
  }, [JSON.stringify(investor)]);

  if (_.isEmpty(investor)) return '';

  return (
    <div id="investor-details-notes" className={className}>
      <AccountExceptionsRow
        id={investor.id}
        headers={!!investorId}
        openNotesModal={openNotesModal}
        nonce={nonce}
        isClient={!isProspect}
      />

      <div className="notes-form">
        <div className="notes-form__fields">
          <Select
            className="account-option"
            components={{ Option }}
            isClearable
            onChange={handleAccountForNote}
            options={accountsOptions}
            placeholder="Select an Account (optional)"
            styles={{ control: styles => ({ ...styles, fontSize: 14 }) }}
          />

          <Select
            className="msg-option"
            onChange={({ value }) => setAction(value)}
            options={getNoteActionOptions(
              exceptionsActions,
              authProvider.hasCompliancePermissionsOrAbove(user)
            )}
            placeholder="Action"
            styles={{
              control: styles => ({ ...styles, fontSize: 14 }),
              groupHeading: styles => ({ ...styles, color: 'black' })
            }}
          />

          <Textarea
            onChange={handleChangeMessage}
            placeholder={`Leave an optional note. Tag your team member by typing @.\nFor example, "@adam-smith"`}
            ref={textAreaElementRef}
          />
        </div>

        <LoadingButton
          loading={submitting}
          disabled={disableLeaveNoteButton}
          onClick={handleSave}
          className="btn btn-primary notes-form__create-btn"
        >
          <i className="fs-icon-edit-icon-blue" /> Leave a note
        </LoadingButton>
      </div>

      <Select
        className="account-option filter-by"
        options={accountsOptions}
        isClearable
        components={{ Option }}
        onChange={handleFilterByAccount}
        placeholder="All Accounts"
      />

      <NotesList target={target} layout={NOTE_LAYOUTS.COMPLETE} withAccounts={withAccounts} />

      {showNotesModal && (
        <AccountNotesModal
          {...selectedException}
          onHide={() => {
            postSave();
            setShowNotesModal(false);
          }}
          show
        />
      )}
    </div>
  );
};

InvestorDetailsNotes.propTypes = {
  className: PropTypes.string,
  investor: PropTypes.object.isRequired,
  investorAccounts: PropTypes.array.isRequired,
  investorId: PropTypes.number,
  location: PropTypes.object,
  params: PropTypes.object,
  prospect: PropTypes.object.isRequired,
  prospectAccounts: PropTypes.array.isRequired
};

InvestorDetailsNotes.defaultProps = {
  className: '',
  investorId: null,
  location: {},
  params: {}
};

export default compose(
  withSubscription({
    plan: 'business',
    id: 'notes',
    name: 'Notes & Alerts',
    inline: true
  }),
  connect(state => ({
    investor: state.investors.view || {},
    investorAccounts: state.investors.viewAccounts || [],
    prospect: state.prospects.view || {},
    prospectAccounts: state.prospects.viewAccounts || []
  }))
)(InvestorDetailsNotes);
