import PropTypes from 'prop-types';
import React from 'react';

const NewTag = () => <div className="new-tag">NEW</div>;

NewTag.propTypes = {
  account: PropTypes.object.isRequired
};

export default NewTag;
