import { VerboseErrorInput } from 'app/components/form';
import Disclosure from 'components/disclosure';
import Choice from 'components/form/choice';
import DriftRangeSlider from 'components/slider/drift-range';
import { AdvisorContext } from 'containers/advisor';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import './styles.scss';

const customStyle = {
  handle: {
    display: 'none'
  }
};

function FloatToDecimal(value) {
  return Number.isFinite(value) ? parseFloat(value.toFixed(2)) : '';
}

function DecimalToPercent(value) {
  return Number.isFinite(value) ? value * 100 : '';
}

function PercentToDecimal(value) {
  return Number.isFinite(value) ? parseFloat((value / 100).toFixed(4)) : '';
}

function FormSection({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div>{children}</div>
      <div className="form-save-wrapper">
        <button type="submit" className="btn btn-primary btn-small">
          Save
        </button>
      </div>
    </form>
  );
}

const Configuration = ({ fields, initializeForm, registerError }) => {
  const {
    authProvider,
    companyProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  useEffect(() => {
    const values = {
      account_value_threshold: company.account_value_threshold,
      allow_advisors_creating_benchmarks: company.allow_advisors_creating_benchmarks,
      allow_advisors_creating_templates: company.allow_advisors_creating_templates,
      allow_advisors_excluding_positions: company.allow_advisors_excluding_positions,
      allow_advisors_integrations_access: company.allow_advisors_integrations_access,
      allow_advisors_models_marketplace_access: company.allow_advisors_models_marketplace_access,
      allow_advisors_custom_securities_access: company.allow_advisors_custom_securities_access,
      allow_advisors_sharing_models: company.allow_advisors_sharing_models,
      allow_programmatic_target_score_override: company.allow_programmatic_target_score_override,
      allow_advisors_update_target_score_manually:
        company.allow_advisors_update_target_score_manually,
      allow_advisors_copy_risk_tolerance_link: company.allow_advisors_copy_risk_tolerance_link,
      allow_advisors_scan_iq_access: company.allow_advisors_scan_iq_access,
      enforce_second_factor: company.enforce_second_factor,
      mask_account_number: company.mask_account_number,
      default_target_management_fee: DecimalToPercent(company.default_target_management_fee),
      default_benchmark_management_fee: DecimalToPercent(company.default_benchmark_management_fee),
      default_recommended_management_fee: DecimalToPercent(
        company.default_recommended_management_fee
      ),
      drift_threshold_above: company.drift_threshold_above,
      drift_threshold_below: company.drift_threshold_below,
      exceptions_emails_enabled: company.exceptions_emails_enabled,
      exceptions_enabled: company.exceptions_enabled,
      exceptions_for_high_risk: company.exceptions_for_high_risk,
      exceptions_for_high_tolerance: company.exceptions_for_high_tolerance,
      exceptions_summary_emails_enabled: company.exceptions_summary_emails_enabled,
      market_downside_performance: company.market_downside_performance,
      show_built_in_questionnaire_templates: company.show_built_in_questionnaire_templates
    };
    initializeForm(values);
  }, []);

  const onSubmit = async (values, message) => {
    try {
      const response = await companyProvider.update(values);
      if (response.error) registerError(response);
      else {
        toast.success(message);
        authProvider.getUser();
      }
    } catch (error) {
      // Handle potential errors that may occur during the update process
      registerError(error);
    }
  };

  const onAdvisorPermissionsSubmit = e => {
    e.preventDefault();
    const values = {
      allow_advisors_creating_benchmarks: fields.allow_advisors_creating_benchmarks.value,
      allow_advisors_creating_templates: fields.allow_advisors_creating_templates.value,
      allow_advisors_excluding_positions: fields.allow_advisors_excluding_positions.value,
      allow_advisors_integrations_access: fields.allow_advisors_integrations_access.value,
      allow_advisors_models_marketplace_access:
        fields.allow_advisors_models_marketplace_access.value,
      allow_advisors_custom_securities_access: fields.allow_advisors_custom_securities_access.value,
      allow_advisors_sharing_models: fields.allow_advisors_sharing_models.value,
      allow_advisors_update_target_score_manually:
        fields.allow_advisors_update_target_score_manually.value,
      allow_advisors_copy_risk_tolerance_link: fields.allow_advisors_copy_risk_tolerance_link.value,
      allow_advisors_scan_iq_access: fields.allow_advisors_scan_iq_access.value
    };

    onSubmit(values, 'Advisor permissions saved successfully');
  };

  const onDriftExceptionsSubmit = e => {
    e.preventDefault();
    const values = {
      account_value_threshold: fields.account_value_threshold.value,
      drift_threshold_above: fields.drift_threshold_above.value,
      drift_threshold_below: fields.drift_threshold_below.value,
      exceptions_emails_enabled: fields.exceptions_emails_enabled.value,
      exceptions_enabled: fields.exceptions_enabled.value,
      exceptions_for_high_risk: fields.exceptions_for_high_risk.value,
      exceptions_for_high_tolerance: fields.exceptions_for_high_tolerance.value,
      exceptions_summary_emails_enabled: fields.exceptions_summary_emails_enabled.value
    };

    onSubmit(values, 'Drift exceptions saved successfully');
  };

  const onRiskToleranceQuestionnairesSubmit = e => {
    e.preventDefault();
    const values = {
      show_built_in_questionnaire_templates: fields.show_built_in_questionnaire_templates.value,
      allow_programmatic_target_score_override:
        fields.allow_programmatic_target_score_override.value
    };

    onSubmit(values, 'Risk tolerance questionnaires saved successfully');
  };

  const onProposalsSubmit = e => {
    e.preventDefault();
    const values = {
      default_target_management_fee: PercentToDecimal(fields.default_target_management_fee.value),
      default_benchmark_management_fee: PercentToDecimal(
        fields.default_benchmark_management_fee.value
      ),
      default_recommended_management_fee: PercentToDecimal(
        fields.default_recommended_management_fee.value
      )
    };

    onSubmit(values, 'Proposals saved successfully');
  };

  const onSecuritySubmit = e => {
    e.preventDefault();
    const values = {
      enforce_second_factor: fields.enforce_second_factor.value,
      mask_account_number: fields.mask_account_number.value
    };

    onSubmit(values, 'Security saved successfully');
  };

  const handleChange = (field, value) => {
    fields[field].onChange(value);
  };

  const onChangeChoice = field => () => {
    handleChange(field.name, !field.value);
  };

  const onChangeSlider = field => values => {
    handleChange(field.name, +values);
  };

  const onChangeInput =
    field =>
    ({ floatValue }) => {
      handleChange(field.name, floatValue);
    };

  const thresholdValueInput = {
    className: 'form-control',
    allowNegative: false,
    decimalScale: 0,
    onValueChange: onChangeInput(fields.account_value_threshold),
    prefix: '$',
    thousandSeparator: true,
    value: fields.account_value_threshold.initialValue
  };

  const generateInputConfig = (field, onChangeInput) => ({
    className: 'form-control',
    allowNegative: false,
    decimalScale: 2,
    onValueChange: onChangeInput(field),
    suffix: '%',
    value: FloatToDecimal(field.value),
    placeholder: 'Value in percentage, e.g, 1%'
  });

  const defaultTargetManagementFeeInput = generateInputConfig(
    fields.default_target_management_fee,
    onChangeInput
  );

  const defaultBenchmarkManagementFeeInput = generateInputConfig(
    fields.default_benchmark_management_fee,
    onChangeInput
  );

  const defaultRecommendedManagementFeeInput = generateInputConfig(
    fields.default_recommended_management_fee,
    onChangeInput
  );

  return (
    <div id="company-profile">
      <h1>Advisor Permissions</h1>
      <div className="content">
        <FormSection onSubmit={onAdvisorPermissionsSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.allow_advisors_integrations_access}
                title="Access integrations setup"
                toggle={onChangeChoice(fields.allow_advisors_integrations_access)}
              />
              <Choice
                {...fields.allow_advisors_models_marketplace_access}
                disabled={!company.models_marketplace_enabled}
                title="Access Market IQ"
                toggle={onChangeChoice(fields.allow_advisors_models_marketplace_access)}
              />
              <Choice
                {...fields.allow_advisors_scan_iq_access}
                disabled={!company.models_marketplace_enabled}
                title="Access Scan IQ"
                toggle={onChangeChoice(fields.allow_advisors_scan_iq_access)}
              />
              <Choice
                {...fields.allow_advisors_custom_securities_access}
                disabled={!company.models_marketplace_enabled}
                title="Manage Custom Security"
                toggle={onChangeChoice(fields.allow_advisors_custom_securities_access)}
              />
              <Choice
                {...fields.allow_advisors_update_target_score_manually}
                title="Set Risk tolerance manually"
                toggle={onChangeChoice(fields.allow_advisors_update_target_score_manually)}
              />
            </div>
            <div className="col">
              <Choice
                {...fields.allow_advisors_creating_benchmarks}
                title="Create benchmarks"
                toggle={onChangeChoice(fields.allow_advisors_creating_benchmarks)}
              />
              <Choice
                {...fields.allow_advisors_sharing_models}
                title="Share models"
                toggle={onChangeChoice(fields.allow_advisors_sharing_models)}
              />
              <Choice
                {...fields.allow_advisors_creating_templates}
                title="Create templates"
                toggle={onChangeChoice(fields.allow_advisors_creating_templates)}
              />
              <Choice
                {...fields.allow_advisors_excluding_positions}
                title="Exclude positions"
                toggle={onChangeChoice(fields.allow_advisors_excluding_positions)}
              />
              <Choice
                {...fields.allow_advisors_copy_risk_tolerance_link}
                title="Copy the fact finder link to use it externally"
                toggle={onChangeChoice(fields.allow_advisors_copy_risk_tolerance_link)}
              />
            </div>
          </div>
        </FormSection>
      </div>
      <h1>Drift Exceptions</h1>
      <div className="content">
        <FormSection onSubmit={onDriftExceptionsSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.exceptions_enabled}
                title="Enable drift exceptions"
                toggle={onChangeChoice(fields.exceptions_enabled)}
              />
              <Choice
                {...fields.exceptions_for_high_risk}
                title="Exceptions for high risk"
                toggle={onChangeChoice(fields.exceptions_for_high_risk)}
              />
              <Choice
                {...fields.exceptions_for_high_tolerance}
                title="Exceptions for low risk"
                toggle={onChangeChoice(fields.exceptions_for_high_tolerance)}
              />
              <div className="drift-container__range-slider">
                {Number.isFinite(fields.drift_threshold_above.initialValue) && (
                  <DriftRangeSlider
                    defaultValue={[fields.drift_threshold_above.initialValue]}
                    min={0}
                    max={9}
                    onChange={onChangeSlider(fields.drift_threshold_above)}
                    label="High risk threshold"
                    steps={0.1}
                    handleStyle={customStyle.handle}
                    trackStyle={customStyle.handle}
                    onAfter
                  />
                )}
              </div>
              {Number.isFinite(fields.account_value_threshold.initialValue) && (
                <VerboseErrorInput
                  {...fields.account_value_threshold.initialValue}
                  className="form-control"
                  label="Minimum account value"
                >
                  <NumericFormat {...thresholdValueInput} />
                </VerboseErrorInput>
              )}
            </div>
            <div className="col">
              <Choice
                {...fields.exceptions_emails_enabled}
                title="Enable drift email notifications"
                toggle={onChangeChoice(fields.exceptions_emails_enabled)}
              />
              <Choice
                {...fields.exceptions_summary_emails_enabled}
                title="Enable drift exceptions summary emails"
                toggle={onChangeChoice(fields.exceptions_summary_emails_enabled)}
              />
              <div style={{ marginTop: '17px', height: '25px' }} />
              <div className="drift-container__range-slider">
                {Number.isFinite(fields.drift_threshold_below.initialValue) && (
                  <DriftRangeSlider
                    defaultValue={[fields.drift_threshold_below.initialValue]}
                    min={0}
                    max={9}
                    onChange={onChangeSlider(fields.drift_threshold_below)}
                    label="Low risk threshold"
                    steps={0.1}
                    handleStyle={customStyle.handle}
                    trackStyle={customStyle.handle}
                    onAfter
                  />
                )}
              </div>
            </div>
          </div>
        </FormSection>
      </div>
      <h1>Risk Tolerance Questionnaires</h1>
      <div className="content">
        <FormSection onSubmit={onRiskToleranceQuestionnairesSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.show_built_in_questionnaire_templates}
                title="Show built in questionnaire templates"
                toggle={onChangeChoice(fields.show_built_in_questionnaire_templates)}
              />
            </div>
            <div className="col">
              <Choice
                {...fields.allow_programmatic_target_score_override}
                title="Override investment objective score set from integration"
                toggle={onChangeChoice(fields.allow_programmatic_target_score_override)}
              />
            </div>
          </div>
        </FormSection>
      </div>
      <h1>Proposals</h1>
      <div className="content">
        <FormSection onSubmit={onProposalsSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <div className="width-inp">
                <VerboseErrorInput
                  {...fields.default_target_management_fee}
                  className="form-control"
                  label="Default target management fee"
                >
                  <NumericFormat {...defaultTargetManagementFeeInput} />
                </VerboseErrorInput>
              </div>
            </div>
            <div className="col">
              <div className="width-inp">
                <VerboseErrorInput
                  {...fields.default_benchmark_management_fee}
                  className="form-control"
                  label="Default benchmark management fee"
                >
                  <NumericFormat {...defaultBenchmarkManagementFeeInput} />
                </VerboseErrorInput>
              </div>
            </div>
            <div className="col">
              <div className="width-inp">
                <VerboseErrorInput
                  {...fields.default_recommended_management_fee}
                  className="form-control"
                  label="Default recommended management fee"
                >
                  <NumericFormat {...defaultRecommendedManagementFeeInput} />
                </VerboseErrorInput>
              </div>
            </div>
          </div>
        </FormSection>
      </div>
      <h1>Security</h1>
      <div className="content">
        <FormSection onSubmit={onSecuritySubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.enforce_second_factor}
                title="Enforce Two Factor Authentication"
                toggle={onChangeChoice(fields.enforce_second_factor)}
              />
            </div>
            <div className="col">
              <Choice
                {...fields.mask_account_number}
                title="Mask account number"
                toggle={onChangeChoice(fields.mask_account_number)}
              />
            </div>
          </div>
        </FormSection>
      </div>
      <Disclosure />
    </div>
  );
};

Configuration.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  ...propTypesCheck
};

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'editCompany',
  fields: [
    'account_value_threshold',
    'allow_advisors_creating_benchmarks',
    'allow_advisors_creating_templates',
    'allow_advisors_excluding_positions',
    'allow_advisors_integrations_access',
    'allow_advisors_models_marketplace_access',
    'allow_advisors_custom_securities_access',
    'allow_advisors_sharing_models',
    'allow_programmatic_target_score_override',
    'allow_advisors_update_target_score_manually',
    'allow_advisors_copy_risk_tolerance_link',
    'allow_advisors_scan_iq_access',
    'default_benchmark_management_fee',
    'default_recommended_management_fee',
    'default_target_management_fee',
    'drift_threshold_above',
    'drift_threshold_below',
    'enforce_second_factor',
    'exceptions_emails_enabled',
    'exceptions_enabled',
    'exceptions_for_high_risk',
    'exceptions_for_high_tolerance',
    'exceptions_summary_emails_enabled',
    'mask_account_number',
    'show_built_in_questionnaire_templates'
  ],
  initialValues: {}
})(BackendValidation(Configuration));
