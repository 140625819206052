import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const OrSeparator = ({ text }) => (
  <div className="or-separator">
    <div className="or-separator__left" />
    <div className="or-separator__text">{text}</div>
    <div className="or-separator__right" />
  </div>
);

OrSeparator.propTypes = {
  text: PropTypes.string
};

OrSeparator.defaultProps = {
  text: 'OR'
};

export default OrSeparator;
