import {
  MultipleSelectedContainer as MultiValueContainer,
  CheckboxOption as Option
} from 'components/advisor/generic-selector';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import './styles.scss';

const MultiSelectFilter = ({ defaultOptions, onChange, options, placeholder }) => (
  <Select
    className="dropdown dropdown__multi-select"
    classNamePrefix="select"
    closeMenuOnSelect={false}
    components={{ Option, MultiValueContainer }}
    defaultValue={defaultOptions}
    hideSelectedOptions={false}
    isMulti
    isSearchable={false}
    onChange={onChange}
    options={options}
    placeholder={placeholder}
    styles={{
      control: styles => ({ ...styles, minHeight: 42 }),
      option: styles => ({ ...styles, backgroundColor: 'white', color: 'black' }),
      valueContainer: styles => ({
        ...styles,
        display: 'initial',
        height: '24px',
        maxWidth: '80%',
        fontSize: '0.9rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      })
    }}
  />
);

MultiSelectFilter.propTypes = {
  defaultOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  placeholder: PropTypes.string
};

MultiSelectFilter.defaultProps = {
  defaultOptions: [],
  placeholder: ''
};

export default MultiSelectFilter;
