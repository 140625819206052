import Card from 'components/card';
import Link from 'components/utils/link';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { getQuestionnaireIdFromTemplate } from 'utils/questionnaire';
import './styles.scss';
import QuestionnaireTemplatePropTypes from './types';

const QUESTIONNAIRE_DISABLED = true;

const QuestionnaireTemplatesDetail = ({ questionnaires, template }) => {
  const { questionProvider, user } = useContext(AdvisorContext);

  const templateId = getQuestionnaireIdFromTemplate(template.id, user.id, null);
  const questionnaire = questionnaires[templateId];

  useEffect(() => {
    questionProvider.getTemplateQuestions(template.id, user.id);
  }, []);

  return (
    <div id="questionnaire-templates-detail">
      <div className="header">
        <h5>
          <Link className="btn-transparent" to="/advisor/templates/fact-finder-forms/">
            &lsaquo; All Fact Finder Forms
          </Link>
        </h5>
        <div className="header__title">
          <h2 className="title">{template.name}</h2>
          <div className="bubble-rtq-wrapper">
            <div className="bubble-rtq" data-template-status={template.status}>
              <span>{template.status}</span>
            </div>
            {template.is_default && (
              <div className="bubble-rtq" data-template-status="default">
                <span>Default</span>
              </div>
            )}
          </div>
        </div>
        {template.description && <pre className="header__description">{template.description}</pre>}
      </div>

      <div className="content">
        {!_.isEmpty(questionnaire) &&
          questionnaire.map(({ description, question }) => (
            <Card>
              <div className="template-question" data-disable-template={QUESTIONNAIRE_DISABLED}>
                <div className="template-question__details">
                  <h3>{question}</h3>
                  {description && <p>{description}</p>}
                </div>
                {/* kept because it will be used soon when editing of the questionnaire templates is allowed */}
                {/* <div className="template-question__actions">
                  <button type="button" className="action" disabled={QUESTIONNAIRE_DISABLED}>
                    Mandatory Questions
                    <span className="fs-icon-lock lock-icon" />
                  </button>
                  <button type="button" className="action" disabled={QUESTIONNAIRE_DISABLED}>
                    Move Up
                    <span className="fs-icon-arrow-up" />
                  </button>
                  <button type="button" className="action" disabled={QUESTIONNAIRE_DISABLED}>
                    Move Down
                    <span className="fs-icon-arrow-down" />
                  </button>
                </div> */}
              </div>
            </Card>
          ))}
        {_.isEmpty(questionnaire) && <p className="no-questions">No Questions</p>}
      </div>
    </div>
  );
};

QuestionnaireTemplatesDetail.propTypes = {
  questionnaires: PropTypes.object.isRequired,
  template: QuestionnaireTemplatePropTypes.isRequired
};

export default connect(state => ({
  questionnaires: state.questions.questionnaires
}))(QuestionnaireTemplatesDetail);
