import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import './styles.scss';

const BillingPeriodSwitch = ({ current, onChange, intervals }) => (
  <div id="BillingPeriodSwitch">
    {intervals.map(interval => (
      <button
        key={`interval-${interval.id}`}
        type="button"
        className={cs({ '-selected': interval.id === current })}
        onClick={() => onChange(interval.id)}
      >
        {interval.highlight && <span className="interval-highlight">{interval.highlight}</span>}
        <span className="interval-name">{interval.text}</span>
      </button>
    ))}
  </div>
);

BillingPeriodSwitch.propTypes = {
  current: PropTypes.string.isRequired,
  intervals: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default BillingPeriodSwitch;
