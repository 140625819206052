// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investor-header-details-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.investor-header-details-wrapper .action-btn-wrapper {
  margin: auto 0px;
  display: flex;
  gap: 10px;
}
.investor-header-details-wrapper .btn.btn-danger {
  background-color: #c93539;
  border: 1px solid #c93539;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/details/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AADF;AAGE;EACE,gBAAA;EACA,aAAA;EACA,SAAA;AADJ;AAIE;EACE,yBAAA;EACA,yBAAA;EACA,gBAAA;AAFJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.investor-header-details-wrapper {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n\n  .action-btn-wrapper {\n    margin: auto 0px;\n    display: flex;\n    gap: 10px;\n  }\n\n  .btn.btn-danger {\n    background-color: #c93539;\n    border: 1px solid #c93539;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
