import { FormGroup, VerboseErrorInput } from 'components/form';
import { AuthenticationContext } from 'containers/auth';
import { propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validate = values => {
  const errors = {};
  errors.email =
    errors.email || validation.required(values.email) || validation.email(values.email);
  return errors;
};

const PasswordResetRequest = ({ fields: { email }, handleSubmit, invalid, submitting }) => {
  const [emailSent, setEmailSent] = useState(false);

  const { authProvider } = useContext(AuthenticationContext);

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit(values =>
      authProvider.resetPasswordRequest(values).then(({ data, error }) => {
        if (error) return Promise.reject(error.errors);
        setEmailSent(true);
        return { data, error };
      })
    )(event);
  };

  return (
    <>
      {emailSent ? (
        <div>
          <h1 className="text-sm-center signup-title mt-3 mb-1">Reset email has been sent</h1>
          <div className="text-sm-center mb-2">
            Please check your inbox for further instructions.
          </div>
        </div>
      ) : (
        <div>
          <h1 className="text-sm-center signup-title mt-3 mb-1">Forgot password?</h1>
          <p className="mb-2">
            Enter your email address below and we&apos;ll send you a link to reset your password.
          </p>
          <form autoComplete="off" onSubmit={onSubmit}>
            <FormGroup className="form-group" {...email}>
              <label>Email</label>
              <VerboseErrorInput
                type="text"
                className="form-control sign-up-field"
                {...email}
                placeholder="Enter email address"
              />
            </FormGroup>
            <div className="center-block pt-2">
              <button
                type="submit"
                className="center-block btn btn-primary btn-block"
                disabled={invalid || submitting}
              >
                {submitting ? 'Processing...' : 'Reset password'}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

PasswordResetRequest.contextTypes = {
  authProvider: PropTypes.object.isRequired
};

PasswordResetRequest.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'forgotPassword',
  fields: ['email'],
  initialValues: {},
  validate
})(PasswordResetRequest);
