import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const PermissionScopeWarning = ({ account, user, hasPermission }) => {
  const allowed = account.investor.advisor.user.id !== user.id;
  hasPermission(allowed);
  if (allowed)
    return (
      <div className="warning-notification">
        This is a <b>read-only</b> view because you are not the advisor for this account.
      </div>
    );
  return null;
};

PermissionScopeWarning.defaultProps = {
  hasPermission: () => {}
};

PermissionScopeWarning.propTypes = {
  account: PropTypes.object.isRequired,
  hasPermission: PropTypes.func,
  user: PropTypes.object.isRequired
};

export default connect(state => ({
  user: state.auth.user
}))(PermissionScopeWarning);
