/* eslint-disable import/prefer-default-export, no-underscore-dangle */
import config from 'app/config';
import _ from 'lodash';
import { saturate } from 'utils/colors';

const COLOR_OTHER = '#46091D';
const COLOR_PALETTE = {
  [config.asset.type.values.NONE]: { color: COLOR_OTHER },
  [config.asset.type.values.CASH]: { color: '#CA93F6' },
  [config.asset.type.values.EQUITY]: {
    [config.asset.subtype.values.US]: { color: '#6CEBE5' },
    [config.asset.subtype.values.INTERNATIONAL]: { color: '#53DED8' },
    color: '#8DF5EF'
  },
  [config.asset.type.values.FIXED_INCOME]: {
    [config.asset.subtype.values.US]: { color: '#78ACEE' },
    [config.asset.subtype.values.INTERNATIONAL]: { color: '#C5EAFF' },
    [config.asset.subtype.values.EMERGING]: { color: '#143968' },
    color: '#78ACEE'
  },
  [config.asset.type.values.REAL_ASSETS]: {
    [config.asset.subtype.values.COMMODITY]: { color: '#FAC84C' },
    color: '#7E2400'
  },
  [config.asset.type.values.ALTERNATIVES]: { color: '#ABF36C' },
  [config.asset.type.values.BALANCED]: {
    [config.asset.subtype.values.US]: { color: '#E2BF46' },
    [config.asset.subtype.values.GLOBAL]: { color: '#E4CF84' },
    color: '#FFC601'
  },
  [config.asset.type.values.OTHERS]: {
    [config.asset.subtype.values.OTHERS]: { color: '#FDB6F9' },
    color: COLOR_OTHER
  }
};

export const processTaxonomy = (taxonomy, resultObj = []) => {
  Object.entries(taxonomy)
    .filter(([l1Key]) => !l1Key.startsWith('__'))
    .forEach(([l1Key, l1]) => {
      // set type color
      const [type] = l1.__source;
      l1.__color = COLOR_PALETTE[type]?.color ?? COLOR_OTHER;

      const l2Entries = Object.entries(l1).filter(([l2Key]) => !l2Key.startsWith('__'));

      if (l2Entries.length)
        l2Entries.forEach(([l2Key, l2]) => {
          // set subtype color
          const [type, subtype, sector] = l2.__source;
          l2.__color =
            COLOR_PALETTE[type]?.[subtype]?.[sector]?.color ??
            COLOR_PALETTE[type]?.[subtype]?.color ??
            saturate(l1.__color, subtype);

          resultObj.push({
            color: l2.__color,
            parent: l1Key,
            title: _.truncate(`${l1Key} ${l2Key}`, { length: 20 }),
            value: (l2.__value * 100) / taxonomy.__value
          });
        });
      else
        resultObj.push({
          color: l1.__color,
          parent: l1Key,
          title: _.truncate(l1Key, { length: 20 }),
          value: (l1.__value * 100) / taxonomy.__value
        });
    });

  return resultObj;
};
