import cn from 'classnames';
import { STRATEGY_MESSAGE } from 'components/advisor/models/constants';
import LabelEdit from 'components/label-edit';
import QuestionMark from 'components/utils/question-mark';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';

import AccountTags from 'components/advisor/accounts/account-tags';
import './styles.scss';

const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

class AccountDetailsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { nickname: null };
  }

  handleOnSave = e => {
    const { account } = this.props;
    const { nickname } = this.state;
    const { accountProvider } = this.context;

    if (nickname) accountProvider.updatePatch(account.id, { nickname });
    else this.setState({ nickname: e?.target?.value });
  };

  handleOnchange = e => {
    this.setState({ nickname: e?.target?.value });
  };

  render() {
    const { nickname } = this.state;
    const { account, investor, toggleIncludeInPrism, toggleHeldAway, toggleEditAccountModal } =
      this.props;

    const { full_name: fullName } = investor;

    const isStrategy = account?.target_model?.is_strategy;
    const whichPath = investor.is_prospect ? 'prospects' : 'investors';

    if (_.isEmpty(account) || _.isEmpty(investor)) return null;

    return (
      <div className="account-details-header" data-strategy={isStrategy}>
        <div className="left-header investor-name">
          <Link to={`/advisor/${whichPath}/${investor.id}`}>{fullName}</Link>
        </div>
        <div className="left-header arrow-icon">
          <span className="fs-icon-angle-right" />
        </div>
        <div className="left-header account-name">
          <span>
            <LabelEdit
              inputValue={nickname}
              onSave={this.handleOnSave}
              label={account.display_name}
              inputOnChange={this.handleOnchange}
              toolTipText={!account.nickname ? 'Add a nickname' : account.name}
              labelOnClick={() => this.setState({ nickname: account.display_name })}
            />
          </span>
          {isStrategy ? (
            <div className="strategy-name">
              <span>Applying strategy {account.target_model.name}</span>
              <QuestionMark tooltipMessage={STRATEGY_MESSAGE} />
            </div>
          ) : null}
        </div>
        <AccountTags account={account} />
        <div className="actions">
          <div className="actions">
            <button
              className={cn(
                'btn',
                { 'btn-secondary': account.held_away },
                { 'btn-outline-secondary': !account.held_away },
                'edit-button'
              )}
              type="button"
              onClick={toggleHeldAway}
            >
              Convert to {account.held_away ? 'Managing' : 'Held-away'} Account{' '}
            </button>
            <button
              className={cn(
                'btn',
                { 'btn-secondary': account.excluded },
                { 'btn-outline-secondary': !account.excluded },
                'edit-button'
              )}
              type="button"
              onClick={() => toggleIncludeInPrism(`excluded-${account.id}`)}
            >
              {account.excluded ? 'Include' : 'Exclude'}
            </button>
          </div>
          <div className="actions">
            <button
              className={cn('btn', 'btn-secondary', 'edit-button')}
              type="button"
              onClick={() => toggleEditAccountModal(account)}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AccountDetailsHeader.propTypes = {
  investor: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  toggleIncludeInPrism: PropTypes.func.isRequired,
  toggleHeldAway: PropTypes.func.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired,
  getAccount: PropTypes.func.isRequired
};

AccountDetailsHeader.contextTypes = {
  accountProvider: PropTypes.object.isRequired
};

export default AccountDetailsHeader;
