import {
  ALLOWED_LOCALES,
  ENGLISH_LANGUAGE,
  LANGUAGE_URL_PARAM,
  SPANISH_LANGUAGE
} from './constants';
import formats from './formats.json';
import messages from './messages.json';

const SPANISH_NAVIGATOR_LANGUAGE_REGEX = /^es\b/;

export const getLocale = () => {
  const url = new URL(window.location.href);

  // allows automatically defining of the Spanish language if the URL starts with
  // `/inapp-view` and the regular expression is met
  if (
    window.location.pathname.startsWith('/inapp-view') &&
    SPANISH_NAVIGATOR_LANGUAGE_REGEX.test(window?.navigator?.language)
  ) {
    url.searchParams.set(LANGUAGE_URL_PARAM, SPANISH_LANGUAGE);
    window.history.replaceState(null, '', url);
  }

  const locale = url.searchParams.get(LANGUAGE_URL_PARAM) || ENGLISH_LANGUAGE;
  return ALLOWED_LOCALES.includes(locale) ? locale : ENGLISH_LANGUAGE;
};

export const getMessages = locale =>
  Object.keys(messages).reduce((acc, key) => ({ ...acc, [key]: messages[key][locale] }), {});

export const getFormats = () => formats;
