import PropTypes from 'prop-types';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import './styles.scss';

const { Range, Handle } = Slider;

const BRAND_PRIMARY_COLOR = '#09acf8';
const GRAPHITE_DARK = '#394346';
const GRAPHITE_GRAYISH_BLUE_COLOR = '#d3d6db';

const styles = {
  handleStyle: {
    backgroundColor: BRAND_PRIMARY_COLOR,
    borderColor: BRAND_PRIMARY_COLOR,
    width: 20,
    height: 20,
    marginTop: -8
  },
  mark: {
    color: GRAPHITE_DARK,
    fontSize: 14,
    marginTop: 5
  },
  overlayInnerStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: BRAND_PRIMARY_COLOR,
    fontWeight: '500',
    fontSize: 14,
    marginBottom: -8
  },
  railStyle: { backgroundColor: GRAPHITE_GRAYISH_BLUE_COLOR },
  trackStyle: { backgroundColor: BRAND_PRIMARY_COLOR }
};

const RangeSliderFilter = ({
  defaultValue,
  key,
  marks,
  max,
  min,
  onChange,
  steps,
  tooltipAlwaysVisible
}) => {
  const handle = ({ dragging, index, value, ...restProps }) => (
    <SliderTooltip
      key={index}
      placement="top"
      prefixCls="rc-slider-tooltip"
      overlay={value}
      overlayInnerStyle={styles.overlayInnerStyle}
      visible={tooltipAlwaysVisible}
    >
      <Handle dragging={dragging.toString()} value={value} {...restProps} />
    </SliderTooltip>
  );

  const startLabel = marks && marks.start ? marks.start : min;
  const endLabel = marks && marks.end ? marks.start : max;

  return (
    <div className="range-slider-container">
      <div className="range-slider-container__component">
        <Range
          allowCross={false}
          defaultValue={defaultValue}
          handle={handle}
          handleStyle={[styles.handleStyle, styles.handleStyle]}
          key={key}
          min={min}
          max={max}
          marks={{
            [min]: { style: styles.mark, label: startLabel },
            [max]: { style: styles.mark, label: endLabel }
          }}
          onChange={onChange}
          railStyle={styles.railStyle}
          step={steps}
          trackStyle={[styles.trackStyle]}
        />
      </div>
    </div>
  );
};

RangeSliderFilter.defaultProps = {
  key: Math.random().toString(36).substring(2),
  marks: null,
  steps: 1,
  tooltipAlwaysVisible: true
};

RangeSliderFilter.propTypes = {
  defaultValue: PropTypes.array.isRequired,
  key: PropTypes.string,
  marks: PropTypes.object,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  steps: PropTypes.number,
  tooltipAlwaysVisible: PropTypes.bool
};

export default RangeSliderFilter;
