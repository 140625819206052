import { GET_ACCOUNT_PRISM_INTENTS, GET_MODEL_PORTFOLIO_PRISM_INTENTS } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class PrismProvider extends DataProvider {
  getAccountPrismIntents() {
    return this.provider.get(`${config.apiBase}prism/intents/accounts/`).then(response => {
      if (!response.error) this.dispatch(GET_ACCOUNT_PRISM_INTENTS, { data: response.data });
      return response;
    });
  }

  getModelPortfolioPrismIntents() {
    return this.provider.get(`${config.apiBase}prism/intents/model-portfolio/`).then(response => {
      if (!response.error)
        this.dispatch(GET_MODEL_PORTFOLIO_PRISM_INTENTS, { data: response.data });
      return response;
    });
  }
}

export default PrismProvider;
