import PropTypes from 'prop-types';
import React from 'react';

const HouseholdDriftCell = ({ household }) => {
  const drift = household.drift_summary?.overall ? household.drift_summary.overall.toFixed(1) : '-';
  return <div>{drift}</div>;
};

HouseholdDriftCell.propTypes = {
  household: PropTypes.object.isRequired
};

export default HouseholdDriftCell;
