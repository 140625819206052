import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PotentialDownsideChart from 'components/charts/potential-downside-chart';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';

const maxDownside = (targetAnalytics, recommendedAnalytics, benchmarkAnalytics) =>
  Math.max(
    targetAnalytics?.summary?.max_drawdown?.value ?? 0,
    recommendedAnalytics?.summary?.max_drawdown?.value ?? 0,
    benchmarkAnalytics?.summary?.max_drawdown?.value ?? 0
  );

const PotentialDownside = ({ actionsDisabled, metadata, propertyKey, proposal, setMetadata }) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const maxDownsideValue = maxDownside(
    proposal.target_analytics,
    proposal.recommended_analytics,
    proposal.benchmark_analytics
  );

  return (
    <EditBoxText {...proposalMetadata}>
      <div id="potential-downside">
        {(proposal.target_analytics ||
          proposal.benchmark_analytics ||
          proposal.recommended_analytics) && (
          <div>
            <DateValue proposal={proposal} />

            <div
              className={cn('fancy-grid', {
                'no-recommended': !proposal.recommended_analytics,
                'no-benchmark': !proposal.benchmark_analytics
              })}
            >
              {proposal.target_analytics && (
                <div className="header">{proposal.target_label || 'Current Portfolio'}</div>
              )}
              {proposal.recommended_analytics && (
                <div className="header">{proposal.recommended_label}</div>
              )}
              {proposal.benchmark_analytics && (
                <div className="header">{proposal.benchmark_label}</div>
              )}

              {proposal.target_analytics && (
                <div className="potential-downside-chart-container">
                  <PotentialDownsideChart
                    color="#505e62"
                    maxValue={maxDownsideValue}
                    value={proposal.target_analytics.summary.max_drawdown.value}
                  />
                </div>
              )}
              {proposal.recommended_analytics && (
                <div className="potential-downside-chart-container">
                  <PotentialDownsideChart
                    color="#31e3ff"
                    maxValue={maxDownsideValue}
                    value={proposal.recommended_analytics.summary.max_drawdown.value}
                  />
                </div>
              )}
              {proposal.benchmark_analytics && (
                <div className="potential-downside-chart-container">
                  <PotentialDownsideChart
                    color="#af7cd3"
                    maxValue={maxDownsideValue}
                    value={proposal.benchmark_analytics.summary.max_drawdown.value}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </EditBoxText>
  );
};

PotentialDownside.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default PotentialDownside;
