/* eslint-disable import/prefer-default-export */

export const PROPOSAL_SELECT_STYLES = {
  control: styles => ({
    ...styles,
    minHeight: 38,
    borderRadius: 0,
    boxShadow: 'none',
    border: '1px solid white',
    '&:hover': {
      border: '1px solid white'
    }
  }),
  menu: styles => ({ ...styles, borderRadius: 0 }),
  option: styles => ({ ...styles, fontSize: '0.9rem' }),
  placeholder: styles => ({ ...styles, fontSize: '0.9rem' }),
  singleValue: styles => ({ ...styles, fontSize: '0.9rem' })
};
