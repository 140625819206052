import React from 'react';

const columns = onRemoveFollower => [
  {
    id: 'authorized-advisor',
    Header: 'Authorized Advisors',
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' },
    accessor: advisor => advisor.name
  },
  {
    id: 'remove',
    width: 100,
    Header: 'Remove',
    Cell: ({ original: { id } }) => (
      <button
        type="button"
        className="btn btn-remove-follower"
        onClick={() => {
          onRemoveFollower(id);
        }}
      >
        <span className="icon-minus" />
        Remove
      </button>
    )
  }
];

export default columns;
