import { AdvisorContext } from 'app/containers/advisor';
import Disclosure from 'components/disclosure';
import LoadingWave from 'components/loading-wave';
import { CustomerSupportEmailLink } from 'constants/contact';
import BillingCards from 'containers/advisor/billing/cards';
import BillingHistory from 'containers/advisor/billing/history';
import BillingSummary from 'containers/advisor/billing/summary';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const NoSubscriptionsAvailable = () => (
  <div id="Billing" className="billing-hero-message">
    <div className="text-center">
      <h1>No subscriptions available</h1>
      <p>
        Contact us at <CustomerSupportEmailLink /> for more information.
      </p>
    </div>
    <Disclosure />
  </div>
);

export const Billing = ({ subscription }) => {
  const { subscriptionProvider } = useContext(AdvisorContext);
  const [loading, setLoading] = useState(true);

  const loadSubscription = async () => {
    setLoading(true);
    await subscriptionProvider.get();
    setLoading(false);
  };

  useEffect(() => {
    loadSubscription();
  }, []);

  if (loading)
    return (
      <div id="Billing" className="billing-hero-message">
        <LoadingWave />
      </div>
    );

  if (!subscription) return <NoSubscriptionsAvailable />;

  return (
    <div id="Billing">
      <h1>Your payment details</h1>
      <BillingSummary />
      <h1>Your credit cards</h1>
      <BillingCards />
      <h1>Your payments</h1>
      <BillingHistory />
      <Disclosure />
    </div>
  );
};

Billing.defaultProps = {
  subscription: null
};

Billing.propTypes = {
  subscription: PropTypes.object
};

export default connect(state => ({
  subscription: state.subscription.current
}))(Billing);
