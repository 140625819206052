import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-drag-and-drop';
import './styles.scss';

const AccountsWithoutGoal = ({ goalInvestmentsReadOnly, children, setGoal }) => (
  <div className="accounts-without-goal">
    <h3>
      Accounts without a Goal
      {!goalInvestmentsReadOnly && ' - Drag and drop to move accounts to different goals'}.
    </h3>
    <Droppable
      types={['account']}
      onDrop={data => setGoal(data.account, null)}
      wrapperComponent={{ type: 'div' }}
    >
      {_.isEmpty(children) && <div className="empty" />}
      {!_.isEmpty(children) && children}
    </Droppable>
  </div>
);
export default AccountsWithoutGoal;

AccountsWithoutGoal.propTypes = {
  goalInvestmentsReadOnly: PropTypes.bool.isRequired,
  setGoal: PropTypes.func.isRequired,
  children: PropTypes.object
};

AccountsWithoutGoal.defaultProps = {
  children: null
};
