import { View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const TextEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion questions={questions} question={question} withDescription>
    <View
      style={[
        styles.containerBlankBox,
        { width: 350, marginTop: question?.description ? 0 : 15, marginBottom: 15 }
      ]}
    />
  </ReportEmptyQuestion>
);

TextEmptyQuestion.propTypes = propTypes;

export default TextEmptyQuestion;
