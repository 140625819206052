import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';
import Chart from '..';
import defaultOptions from './options';
import './styles.scss';

const BurndownChart = ({ data, hasRecommended, id, onChartReady, options, small }) => {
  const factor = small ? 1.4 : 1;

  const params = {
    id,
    data,
    style: { height: 350 / factor, width: 476 / factor },
    onChartReady,
    options: _.merge({}, defaultOptions, options)
  };

  if (!hasRecommended)
    params.options = {
      ...params.options,
      graphs: params.options.graphs.filter(g => g.id !== 'recommended')
    };

  params.options = {
    ...params.options,
    graphs: params.options.graphs.map(g => {
      if (options.recommendedName && g.id === 'recommended')
        return {
          ...g,
          title: options.recommendedName || 'Recommended'
        };
      if (options.targetName && g.id === 'target')
        return {
          ...g,
          title: options.targetName
        };
      return g;
    })
  };

  // calculate how frequent (in months) we should put guides and end with around 5 guides.
  const guidesFrequency = 12 * Math.min(Math.max(Math.floor(data.length / (12 * 5)), 1), 5);
  params.options.guides = data
    .filter(({ index }) => index > 0 && index % guidesFrequency === 0)
    .map(({ index }) => ({
      category: index,
      boldLabel: true,
      tickLength: 0,
      lineThickness: 0,
      label: `${index / 12}yr`
    }));

  params.options.graphs[0].balloonFunction = item =>
    `<b>${options.targetName || 'Current Portfolio'}:</b> ${formatMoney(item.dataContext.target)}`;

  if (hasRecommended)
    params.options.graphs[1].balloonFunction = item =>
      `<b>${options.recommendedName || 'Recommended'}:</b> ${formatMoney(
        item.dataContext.recommended
      )}`;

  return (
    <div className="burndown-chart">
      <Chart {...params} />
    </div>
  );
};

BurndownChart.propTypes = {
  data: PropTypes.array.isRequired,
  hasRecommended: PropTypes.bool,
  id: PropTypes.string,
  onChartReady: PropTypes.func,
  options: PropTypes.object,
  small: PropTypes.bool
};

BurndownChart.defaultProps = {
  hasRecommended: true,
  id: null,
  onChartReady: undefined,
  options: {},
  small: false
};

export default React.memo(BurndownChart);
