import withSubscription from 'hocs/subscription-validation';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DriftExceptionsList from './list';

export const DriftExceptions = ({ exceptions, meta }) => (
  <div id="drift-exceptions">
    <div className="drift-exceptions__wrapper">
      <DriftExceptionsList exceptions={exceptions} meta={meta} />
    </div>
  </div>
);

DriftExceptions.propTypes = {
  exceptions: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired
};

export default compose(
  withSubscription({
    plan: 'business',
    id: 'exceptions',
    name: 'Drift Exceptions'
  }),
  connect(state => ({
    exceptions: state.advisors.esExceptions,
    meta: state.advisors.esExceptionsMeta
  }))
)(DriftExceptions);
