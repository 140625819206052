/* eslint-disable react/no-array-index-key */
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { formatPercentage } from 'utils/utils';
import { STYLE_BOX_RANGE_COLORS, getTiles } from '../utils';
import styles from './styles';

const MorningstarStyleBoxChart = ({ data, style, withLegend }) => {
  const tiles = useMemo(() => getTiles(data, true), [JSON.stringify(data)]);
  return (
    <View style={[styles.morningstarStyleBoxChart, style]} wrap={false}>
      <View style={styles.styleBoxChart}>
        {tiles.map((row, rowIdx) => (
          <View key={`row-${rowIdx}`} style={styles.styleBoxChartRow}>
            {row.map(({ backgroundColor, color, display, type }, tileIdx) => (
              <View
                key={`tile-${rowIdx}-${tileIdx}`}
                style={[
                  styles.styleBoxChartItem,
                  styles[`${type}StyleBoxChartItem`],
                  { backgroundColor, color }
                ]}
              >
                <Text style={styles[`${type}StyleBoxChartItemText`]}>{display}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
      {withLegend && (
        <View style={styles.styleBoxLegend}>
          <View style={[styles.styleBoxLegendItem, { alignItems: 'flex-end', height: 10 }]}>
            <Text style={[styles.styleBoxLegendItemText]}>Weight %</Text>
          </View>
          {STYLE_BOX_RANGE_COLORS.map(range => {
            const min = range.min === 0 ? ' 0%' : formatPercentage(range.min, 100, 0);
            const max = formatPercentage(range.max, 100, 0);
            return (
              <View key={`${range.min}-${range.max}`} style={styles.styleBoxLegendItem}>
                <View
                  style={[styles.styleBoxLegendItemBox, { backgroundColor: range.background }]}
                />
                <Text style={styles.styleBoxLegendItemText}>
                  {min} - {max}
                </Text>
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};

MorningstarStyleBoxChart.defaultProps = {
  style: {},
  withLegend: false
};

MorningstarStyleBoxChart.propTypes = {
  data: PropTypes.array.isRequired,
  style: PropTypes.object,
  withLegend: PropTypes.bool
};

export default MorningstarStyleBoxChart;
