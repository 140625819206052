import React from 'react';
import { SECURITY_TARGET_TYPE } from 'containers/security/constants';
import { FormGroup } from '../../../../form';

const mapNonePositionToOther = (position, classification, type, valueSecurity) => {
  if (type === SECURITY_TARGET_TYPE) return valueSecurity.value === 0 ? 99 : valueSecurity.value;
  return position[classification].value === 0 ? 99 : position[classification].value;
};

export default ({
  position,
  classifications,
  classificationType,
  disabled,
  type,
  valueSecurity
}) => {
  const subtypeDisabled =
    type === SECURITY_TARGET_TYPE
      ? classificationType === 'subtype' && !(position.type || position.type === 0)
      : classificationType === 'subtype' && !(position.type.value || position.type.value === 0);

  const sectorDisabled =
    type === SECURITY_TARGET_TYPE
      ? classificationType === 'sector' &&
        !((position.type || position.type === 0) && (position.subtype || position.subtype === 0))
      : classificationType === 'sector' &&
        !(
          (position.type.value || position.type.value === 0) &&
          (position.subtype.value || position.subtype.value === 0)
        );

  const isDisabled = disabled || subtypeDisabled || sectorDisabled;

  return (
    <FormGroup {...position[classificationType]}>
      <div className="c-select-wrap">
        <select
          className="c-select form-control"
          disabled={isDisabled}
          style={{ minWidth: 121 }}
          onChange={event => {
            if (type === SECURITY_TARGET_TYPE) {
              return valueSecurity.onChange(event.target.value);
            }
            position[classificationType].onChange(event.target.value);
            if (classificationType === 'type') position.subtype.onChange('');
            if (['type', 'subtype'].includes(classificationType)) position.sector.onChange('');
          }}
          checked={position[classificationType].checked}
          name={position[classificationType].name}
          onBlur={position[classificationType].onBlur}
          onDragStart={position[classificationType].onDragStart}
          onDrop={position[classificationType].onDrop}
          onFocus={position[classificationType].onFocus}
          value={mapNonePositionToOther(position, classificationType, type, valueSecurity)}
        >
          <option value="" disabled>
            Select
          </option>
          {classifications[classificationType]
            .filter(t => t.value !== 0)
            .map(item => (
              <option value={item.value} key={item.value}>
                {item.name === 'None' ? 'Other' : item.name}
              </option>
            ))}
        </select>
      </div>
      {position[classificationType].touched && position[classificationType].error && (
        <span className="text-danger error">{position[classificationType].error}</span>
      )}
    </FormGroup>
  );
};
