import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const WithdrawalRequirementEmptyQuestion = ({ questions, question }) => {
  const constants = question.data?.constants || {};
  const hideAnnualIncome = question.data?.hide_annual_income;
  const hideOneShot = question.data?.hide_one_shot;

  return (
    <ReportEmptyQuestion questions={questions} question={question} withDescription>
      {hideAnnualIncome || (
        <View style={styles.multiOptions}>
          <View style={[styles.markCheck, { marginRight: 5 }]} />
          <Text>{constants.empty_report_annual_income_0}</Text>
          <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
          <Text>{constants.empty_report_annual_income_1}</Text>
        </View>
      )}
      {hideOneShot || (
        <View style={styles.multiOptions}>
          <View style={[styles.markCheck, { marginRight: 5 }]} />
          <Text>{constants.empty_report_one_shot_0}</Text>
          <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
          <Text>{constants.empty_report_one_shot_1}</Text>
        </View>
      )}
      <View style={styles.multiOptions}>
        <Text>{constants.empty_report_time_0}</Text>
        <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
        <Text>{constants.empty_report_time_1}</Text>
      </View>
    </ReportEmptyQuestion>
  );
};

WithdrawalRequirementEmptyQuestion.propTypes = propTypes;

export default WithdrawalRequirementEmptyQuestion;
