import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import { useAnnualTaxableIncomeQuestionDetails } from '../hooks';

const AnnualTaxableIncomeReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const {
    estimatedAnnualIncomeText,
    estimatedYearlyContributionText,
    questionText
  } = useAnnualTaxableIncomeQuestionDetails({ question });

  return (
    <ReadOnlyQuestion question={{ ...question, question: questionText }}>
      <p>
        {estimatedAnnualIncomeText}{' '}
        {question.answer.income ? formatMoney(question.answer.income) : '-'}
      </p>
      <p>
        {estimatedYearlyContributionText}{' '}
        {question.answer.contribution
          ? `${formatPercentage(question.answer.contribution, 100)} (${formatMoney(
              question.answer.income * question.answer.contribution
            )})`
          : '-'}
      </p>
    </ReadOnlyQuestion>
  );
};

AnnualTaxableIncomeReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default AnnualTaxableIncomeReadOnlyQuestion;
