/* eslint-disable react/no-did-update-set-state */
import Widget from 'components/advisor/dashboard/widgets/';
import useWidget, {
  DATASETS,
  widgetStorePropTypes,
  widgetStoreSelector
} from 'components/advisor/dashboard/widgets/hooks';
import DriftLevelDistributionChart from 'components/charts/drift-level-distribution-chart';
import { AdvisorContext } from 'containers/advisor';
import useDriftThreshold from 'hooks/drift-threshold';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import chartOptions, { DRIFT_LEVELS_DATA_OPTIONS } from './config';
import './styles.scss';

const ROUTES = {
  [DATASETS.CLIENTS]: '/advisor/investors',
  [DATASETS.HOUSEHOLDS]: '/advisor/households',
  [DATASETS.PROSPECTS]: '/advisor/prospects'
};

const processor = data => {
  /*
  convert the data in an object composed of:
    - value
    - description
    - color
    - type: "Accounts", "Clients", "Prospects" or "Households"
    - index: Define ordering
    - rate: Adjusted percentage that don't use decimals and prevent to add up
            to more than 100%. Based on the largest remainder method.
  */
  const total = Object.values(data).reduce((acum, val) => acum + val, 0);
  data = Object.entries(data).map(([level, value]) => {
    const rate = (value * 100) / total;
    const rateRnd = Math.floor(rate);
    return {
      ...DRIFT_LEVELS_DATA_OPTIONS[level],
      level,
      value,
      rate: rateRnd,
      rest: rate - rateRnd
    };
  });
  let rest = 100 - Object.values(data).reduce((acum, item) => acum + item.rate, 0);
  if (rest)
    data = _.sortBy(data, [o => -o.rest]).map(item => {
      rest -= 1;
      return { ...item, rate: rest >= 0 ? item.rate + 1 : item.rate };
    });

  return _.sortBy(data, [o => o.index]);
};

const DriftLevelWidget = ({ store }) => {
  const { driftThresholdAbove, driftThresholdBelow } = useDriftThreshold();
  const { routerActions } = useContext(AdvisorContext);

  const fetch = (provider, params) => provider.es.getDriftLevelDistribution(params);
  const { data, dataset, ...widget } = useWidget({
    fetch,
    store,
    processor
  });
  const hint = `Risk is higher than the threshold means risk is higher than
    tolerance by ${driftThresholdAbove} points. Tolerance is higher than the threshold
    means tolerance is higher than the risk by ${driftThresholdBelow} points. Risk and tolerance are
    matching means the drift is within the threshold.`;

  const onClick = ({
    dataItem: {
      dataContext: { level, value }
    }
  }) => {
    if (value) {
      const query = { drift_level: level.replace('_', '-') };
      routerActions.push({
        pathname: ROUTES[dataset],
        query
      });
    }
  };

  const listeners = {
    clickSlice: onClick,
    clickLabel: onClick,
    clickMarker: onClick
  };

  return (
    <Widget
      {...widget}
      hint={hint}
      dataset={dataset}
      title="Risk Drift from Tolerance"
      id="DriftLevelWidget"
    >
      <DriftLevelDistributionChart
        data={data}
        id={`DriftLevelDistributionChart-${Math.random()}`}
        className="drift-level-distribution-chart"
        listeners={listeners}
        options={chartOptions}
      />
    </Widget>
  );
};

DriftLevelWidget.propTypes = {
  store: PropTypes.shape(widgetStorePropTypes).isRequired
};

export default connect(state => ({
  store: widgetStoreSelector('driftLevelDistribution')(state)
}))(DriftLevelWidget);
