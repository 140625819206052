import { Text } from '@react-pdf/renderer';
import { questionSlugs as qs } from 'constants/questionnaire';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage, withCurrencyFormat } from 'utils/utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const RangeQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  let { value } = question.answer;
  if (question.data.format === 'currency') value = withCurrencyFormat(value, 'standard', 0);
  else if (question.data.format === 'percent') value = formatPercentage(value, 100, 0);
  else if (question.slug === qs.TIME_HORIZON && value > 0) value = `In ${value} Year(s)`;
  else if (question.slug === qs.TIME_HORIZON && value <= 0) value = `Now`;

  return (
    <ReportQuestion question={question}>
      <Text>{value}</Text>
    </ReportQuestion>
  );
};

RangeQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default RangeQuestion;
