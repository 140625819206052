/* eslint-disable no-await-in-loop, no-restricted-syntax */
import SendEmailForm from 'components/advisor/investors/email-form';
import { DUMMY_INVESTOR } from 'components/advisor/investors/email-tolerance-form/utils';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import { IPS_REVIEW_EMAIL } from 'containers/advisor/templates/company-meta/contants';
import { DEFAULT_IPS_REVIEW_EMAIL_TEMPLATE } from 'containers/advisor/templates/defaults';
import {
  DRAFT,
  IPS_REPORT_TYPE,
  PROPOSAL_REPORT_TYPE
} from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ProposalSignatureRequestEmailForm from '../signature-request-email-form';
import './styles.scss';
import { buildMessage } from './utils';

const SIGNATURE_TYPE = 'signature';

const EMAIL_FORM_STEP = 0;
const EMAIL_SENDING_STEP = 1;

const ProposalSignatureRequestModal = ({
  buttonDisabled,
  isIPS,
  reportId,
  reportInvestor,
  reportStatus,
  requiredSigners: sourceRequiredSigners,
  title
}) => {
  if (!sourceRequiredSigners.length) return null;

  const {
    ipsProvider,
    proposalProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const [isShown, setIsShown] = useState(false);
  const [requiredSigners, setRequiredSigners] = useState(sourceRequiredSigners);
  const [step, setStep] = useState(EMAIL_FORM_STEP);

  const reportType = isIPS ? IPS_REPORT_TYPE : PROPOSAL_REPORT_TYPE;

  useEffect(() => {
    setRequiredSigners(sourceRequiredSigners);
  }, [JSON.stringify(sourceRequiredSigners)]);

  const hide = () => {
    setIsShown(false);
    setStep(EMAIL_FORM_STEP);
  };
  const show = () => setIsShown(true);

  const onSubmit = async ({ cc, sender, subject, msgGenerator }) => {
    const provider = isIPS ? ipsProvider : proposalProvider;

    for (const signer of requiredSigners)
      await provider
        .createReview(reportId, reportInvestor.id, reportInvestor.is_prospect, {
          [signer.type]: signer.id,
          requires_signature: signer.signatureType === SIGNATURE_TYPE
        })
        .then(({ data, error }) => {
          if (!error)
            provider.generateReviewMail(reportId, reportInvestor.id, reportInvestor.is_prospect, {
              review_uuid: data.uuid,
              priority: signer.priority,
              email: signer.email,
              cc: cc ? cc.split(',') : undefined,
              sender,
              subject,
              message: buildMessage(
                signer,
                reportId,
                reportType,
                reportInvestor,
                data.review_url,
                msgGenerator
              )
            });
        });

    await provider.getReport(reportId, reportInvestor.id, reportInvestor.is_prospect);

    hide();
  };

  const initialValues = {
    ...DEFAULT_IPS_REVIEW_EMAIL_TEMPLATE,
    ...company.ips_review_email,
    email: DUMMY_INVESTOR.email
  };

  return (
    <>
      <button className="btn btn-success" disabled={buttonDisabled} onClick={show} type="button">
        Send for Signature
      </button>

      {ReactDOM.createPortal(
        <Modal
          id="signature-request-modal"
          className="modal-dialog--action-email"
          show={isShown}
          onHidden={hide}
        >
          <ModalHeader />
          <ModalBody>
            <h3 className="text-sm-center mb-1">{title}</h3>

            {step === EMAIL_FORM_STEP && (
              <ProposalSignatureRequestEmailForm
                nextStep={() => {
                  setStep(EMAIL_SENDING_STEP);
                }}
                requiredSigners={requiredSigners}
                setRequiredSigners={setRequiredSigners}
              />
            )}

            {step === EMAIL_SENDING_STEP && (
              <SendEmailForm
                className="target-score-questionnaire-email"
                emailDisabled
                initialValues={initialValues}
                investor={DUMMY_INVESTOR}
                onCancel={hide}
                submit={onSubmit}
                template={{
                  text: 'Edit Email Template',
                  url: '/advisor/templates/ips-review-email/'
                }}
                title=""
                type={IPS_REVIEW_EMAIL}
              />
            )}

            {reportStatus === DRAFT && step === EMAIL_SENDING_STEP && (
              <p className="draft-status-disclaimer">
                Once sent, the {isIPS ? 'IPS' : 'Proposal'} content will be locked.
                <br />
                You will only have access to the PDF version and cannot add or edit any part of it.
              </p>
            )}
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

ProposalSignatureRequestModal.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  isIPS: PropTypes.bool.isRequired,
  reportId: PropTypes.number.isRequired,
  reportInvestor: PropTypes.object.isRequired,
  reportStatus: PropTypes.string.isRequired,
  requiredSigners: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default ProposalSignatureRequestModal;
