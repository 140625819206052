import _ from 'lodash';
import {
  FETCHING,
  PROSPECT_LIST_ES,
  GET_PROSPECT_PRISM_DISTRIBUTION,
  PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION
} from 'constants/actions';
import DataProvider from './data';
import config from '../config';

class ProspectsElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/investor/`, {
        ...params,
        is_prospect: true
      })
      .then(({ data }) => {
        this.dispatch(PROSPECT_LIST_ES, {
          data: data.results.map(prospect => ({
            ...prospect,
            url: `/advisor/prospects/${prospect.id}/`
          }))
        });
        return data;
      });

    return promise;
  }

  getPrismDistribution(params) {
    return this.provider
      .get(`${config.apiBase}search/investor/risk_levels_distribution/`, {
        ...params,
        is_prospect: true
      })
      .then(({ data }) => this.dispatch(GET_PROSPECT_PRISM_DISTRIBUTION, { data }));
  }

  getDriftLevelDistribution(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/investor/drift_levels_distribution/`, {
        ...params,
        is_prospect: true
      })
      .then(({ data }) => {
        this.dispatch(PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION, { data });
        return data;
      });

    return promise;
  }
}

export default ProspectsElasticProvider;
