import React from 'react';
import './index.scss';

const Radio = props => (
  <div className="input-radio-container">
    <input type="radio" {...props} />
    <span className="input-radio-container__checkmark" />
  </div>
);

export default Radio;
