import { FormGroup } from 'components/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import AutosizeInput from 'components/autosize-input';
import { toast } from 'react-toastify';
import './styles.scss';

const STATUS_MESSAGE = {
  error: {
    status: 'error',
    message: "We couldn't setup Addepar integration."
  },
  success: {
    status: 'success',
    message: 'Thank you! Your integration should be live in the next 24-48 hours.'
  }
};

const useAddeparProduction = process.env.ADDEPAR_ENVIRONMENT === 'production';
const BASE_DOMAIN = useAddeparProduction ? 'addepar.com' : 'clientdev.addepar.com';
const SCOPE = 'portfolio+files_write+entities_write';

class AddeparIntegration extends PureComponent {
  constructor(props) {
    super(props);

    const firmSubdomain = props?.integration?.extra_data?.firm_name ?? '';
    const ready = useAddeparProduction || firmSubdomain;

    this.initialState = {
      firmSubdomain,
      linking: false,
      ready,
      step: ready ? 'link' : 'setup'
    };
    this.state = { ...this.initialState };

    this.setSubdomain = this.setSubdomain.bind(this);
    this.onSessionMessage = this.onSessionMessage.bind(this);
    this.checkExternalWindow = this.checkExternalWindow.bind(this);
    this.connect = this.connect.bind(this);
  }

  componentDidMount() {
    const { ready } = this.state;
    if (ready) this.startSession();
  }

  componentWillUnmount() {
    this.clean();
  }

  externalWindow;

  externalWindowCheckInterval;

  setSubdomain(event) {
    this.setState({ firmSubdomain: event.target.value });
  }

  connect(e) {
    e.preventDefault();
    const { firmSubdomain } = this.state;
    if (firmSubdomain) this.setState({ step: 'link' }, this.startSession);
    else toast.error('Something went wrong. Please contact support.');
  }

  async getSessionURL() {
    const { integrationProvider } = this.context;
    const { firmSubdomain } = this.state;
    let { integration } = this.props;

    if (!integration) {
      const { selectedSyncData } = this.props;
      const data = { ...selectedSyncData };
      if (firmSubdomain) data.firm_name = firmSubdomain;
      integration = await integrationProvider.setAddeparIntegrationInfo(data);
    }

    if (_.isEmpty(integration)) return null;

    const redirectHost = new URL(integration.redirect_url).host;
    this.setState({ redirectHost });

    let params = new URLSearchParams({
      response_type: 'code',
      state: integration.state,
      client_id: integration.client_id,
      redirect_uri: integration.redirect_url
    });
    params = `${params.toString()}&scope=${SCOPE}`;

    const oauthSubdomain = useAddeparProduction ? 'id' : firmSubdomain;
    return `https://${oauthSubdomain}.${BASE_DOMAIN}/oauth2/authorize/?${params}`;
  }

  async startSession() {
    const { onComplete } = this.props;
    const sessionURL = await this.getSessionURL();

    if (!sessionURL) {
      onComplete(STATUS_MESSAGE.error);
      return;
    }

    this.setState({ linking: true });

    const width = 600;
    const height = 800;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    this.externalWindow = window.open(
      sessionURL,
      'Integrate with Addepar',
      `width=${width},height=${height},left=${left},top=${top}`
    );
    window.addEventListener('message', this.onSessionMessage, false);
    this.externalWindowCheckInterval = window.setInterval(this.checkExternalWindow, 500);
  }

  checkExternalWindow() {
    const { linking } = this.state;
    if (this.externalWindow && this.externalWindow.closed && linking) this.finishSession(false);
  }

  onSessionMessage(e) {
    const { redirectHost } = this.state;
    const messageHost = new URL(e.origin).host;
    if (messageHost !== redirectHost) return;
    this.finishSession(e.data?.success);
  }

  finishSession = success => {
    this.clean(); // clean listeners and intervals immediately

    const { onComplete } = this.props;
    if (!success) {
      onComplete(STATUS_MESSAGE.error);
      return;
    }
    onComplete(STATUS_MESSAGE.success);
  };

  clean = () => {
    window.clearInterval(this.externalWindowCheckInterval);
    window.removeEventListener('message', this.onSessionMessage, false);
    this.setState(this.initialState);
  };

  render() {
    const {
      provider: { name, image }
    } = this.props;
    const { step, firmSubdomain } = this.state;

    return (
      <div id="AddeparIntegration">
        <img src={image} alt={name} width={300} />

        {step === 'setup' ? (
          <form className="setup" onSubmit={this.connect}>
            <h1>Please fill the firm subdomain to connect.</h1>
            <p className="text-danger">
              <strong>This step is NOT REQUIRED IN PRODUCTION!</strong>
            </p>
            <FormGroup className="form-group">
              <div className="firm-subdomain">
                <AutosizeInput
                  name="firm-subdomain"
                  value={firmSubdomain}
                  id="autosizeInput-firm-subdomain"
                  onChange={this.setSubdomain}
                  placeholder="firm subdomain"
                  minWidth="110px"
                />
                <span>.{BASE_DOMAIN}</span>
              </div>
            </FormGroup>

            <button type="submit" className="btn btn-primary" disabled={!firmSubdomain}>
              Connect
            </button>
          </form>
        ) : (
          <p>
            Connecting to {name}
            <br />
            Please wait ...
          </p>
        )}
      </div>
    );
  }
}

AddeparIntegration.contextTypes = {
  integrationProvider: PropTypes.object.isRequired
};

AddeparIntegration.propTypes = {
  onStart: PropTypes.func,
  onComplete: PropTypes.func,
  provider: PropTypes.object.isRequired,
  integration: PropTypes.object,
  selectedSyncData: PropTypes.object
};

AddeparIntegration.defaultProps = {
  integration: null,
  onStart: () => {},
  onComplete: () => {},
  selectedSyncData: {}
};

export default AddeparIntegration;
