import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import XYDistributionChart from '../xy-distribution-chart';

import './styles.scss';

const distributionToYXData = distribution => {
  if (!distribution) return null;

  // filter only clients/households with accounts/positions in it and with a score
  const distributionMapped = distribution
    .map(item => ({
      itemId: item.id,
      url: item.url,
      x: item.prism_overall ? item.prism_overall.toFixed(1) : 0,
      y: item.total_positions ?? item.total_accounts,
      name: item.name,
      value: Math.round(item.value ?? item.total_assets),
      bulletclass: `bullet-risk-${Math.round(item.prism_overall)}`,
      rounderScore: item.prism_overall ? Math.round(item.prism_overall) : 0
    }))
    .sort((a, b) => b.value - a.value);

  // Give some vertical padding to prevent the crop of bubbles
  // Using arbitrary values.
  if (distributionMapped.length) {
    const maxPositions = _.maxBy(distributionMapped, d => d.y).y;
    return distributionMapped.concat(
      {
        y: maxPositions + 11
      },
      { y: -5 }
    );
  }
  return distributionMapped;
};

const PrismDistributionChart = ({ id, distribution, title, handleClick, router }) => {
  if (_.isEmpty(distribution)) return null;

  const data = distributionToYXData(distribution);

  const defaultHandleClick = ({ item: { dataContext } }) => {
    router.push(dataContext.url);
  };

  return (
    <div className="prism-rating-distribution-chart">
      <XYDistributionChart
        id={id}
        data={data}
        yTitle={title}
        listeners={{ clickGraphItem: handleClick ?? defaultHandleClick }}
      />

      <div className="prism-risk-overlay">
        <div className="score-line" />
        <div className="labels">
          <span>1</span>
          <span>PRISM Rating</span>
          <span>10</span>
        </div>
      </div>
    </div>
  );
};

PrismDistributionChart.propTypes = {
  id: PropTypes.string,
  distribution: PropTypes.array,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

PrismDistributionChart.defaultProps = {
  id: 'prism-distribution-chart',
  distribution: []
};

export default withRouter(PrismDistributionChart);
