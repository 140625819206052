import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import { ROLES } from 'providers/auth';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import RolesSelect from '../../roles-select';
import { USER_ROLE_CHANGE_ERROR_MESSAGE, USER_ROLE_CHANGE_SUCCESSFUL_MESSAGE } from '../constants';

const InvitationRow = ({ invitation }) => {
  const { invitationProvider } = useContext(AdvisorContext);

  const id = `invitation-${invitation.id}`;
  const name = `${invitation.first_name} ${invitation.last_name}`;

  const resendInvitation = () =>
    invitationProvider.send(invitation.id).then(() => {
      toast.success('Your invitation has been resent');
    });

  const cancelInvitation = () => {
    invitationProvider.delete(invitation.id).then(response => {
      if (!response.error) {
        toast.success(
          <div className="invitation-notification">
            Invite has been cancelled for <b>{name}</b>
          </div>
        );
        invitationProvider.list();
      }
    });
  };

  const onRoleChange = option => {
    invitationProvider
      .update(invitation.id, { data__role: option.value })
      .then(response => {
        if (response.error) throw new Error();
        else {
          toast.success(USER_ROLE_CHANGE_SUCCESSFUL_MESSAGE(name, ROLES.labels[option.value]));
          invitationProvider.list();
        }
      })
      .catch(() => toast.error(USER_ROLE_CHANGE_ERROR_MESSAGE));
  };

  return (
    <tr key={id} className="parent-row">
      <td width="25%">{name}</td>

      <td aria-label="User Role Selection" className="text-left">
        <RolesSelect
          defaultValue={invitation.data.role}
          id={`role-${id}`}
          onChange={onRoleChange}
        />
      </td>

      <td className="text-left">
        <span className="member-inactive">
          Pending
          <br />
          <button type="button" className="btn btn-link p-0" onClick={resendInvitation}>
            Resend email
          </button>
        </span>
      </td>

      <td className="text-left">
        <span className="member-inactive">Disabled</span>
      </td>

      <td aria-label="Empty Row" />

      <td className="action action-cell hidden-bg">
        <button aria-label="Remove User" type="button">
          <TrashCanIcon
            className="trash-icon"
            onClick={cancelInvitation}
            title="Cancel Invitation"
          />
        </button>
      </td>
    </tr>
  );
};

InvitationRow.propTypes = {
  invitation: PropTypes.object.isRequired
};

export default InvitationRow;
