import cn from 'classnames';
import { LAYOUTS } from 'components/advisor/dashboard/widgets/';
import BookLevelExceptionsWidget from 'components/advisor/dashboard/widgets/book-level-exceptions-widget';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import ReactTable from 'react-table';
import './styles.scss';

const AdvisorDetailRow = ({ advisor }) => (
  <div className="surveillance-charts">
    <div className="result-container">
      <BookLevelExceptionsWidget layout={LAYOUTS.INLINE} forceAdvisor={advisor.id} />
    </div>
  </div>
);

AdvisorDetailRow.propTypes = {
  advisor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    prism_overall: PropTypes.number
  }).isRequired
};

const ComplianceSurveillanceList = ({ advisors }) => {
  const { authProvider, routerActions } = useContext(AdvisorContext);

  const goToAnalytics = async advisorId => {
    await authProvider.setAdvisorByManager(advisorId);
    routerActions.push('/advisor/analytics');
  };

  const [expanded, setExpanded] = useState({});
  const [filter, setFilter] = useState([]);
  const searchBy = value => setFilter([{ id: 'advisorName', value }]);
  const onSearch = e => searchBy(e.target.value);

  const visibleAdvisors = advisors.filter(
    advisor =>
      advisor.object_type === 'advisor' ||
      (advisor.object_type === 'executive' && advisor.total_accounts)
  );

  const surveillanceColumns = [
    {
      Header: () => (
        <span>
          Advisors <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 'advisorName',
      accessor: item => item.name,
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      sortable: true,
      minWidth: 180,
      className: 'text-left',
      headerClassName: 'text-left',
      Cell: row => (
        <span>
          <a
            className="prospect-name investor-cursor"
            onClick={() => goToAnalytics(row.original.id)}
          >
            {row.original.name}
          </a>
        </span>
      )
    },
    {
      Header: () => (
        <span>
          Prospects
          <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 2,
      minWidth: 100,
      filterable: false,
      accessor: item => (item && item.total_prospects) || 0
    },
    {
      Header: () => (
        <span>
          Clients <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 3,
      minWidth: 90,
      filterable: false,
      accessor: item => (item && item.total_clients) || 0
    },
    {
      Header: () => (
        <span>
          Households <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 4,
      width: 125,
      filterable: false,
      accessor: item => (item && item.total_households) || 0
    },
    {
      Header: () => (
        <span>
          Accounts <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 'totalAccounts',
      minWidth: 90,
      filterable: false,
      accessor: item => (item && item.total_accounts) || 0
    },
    {
      Header: () => (
        <span>
          Total Assets <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 6,
      width: 125,
      filterable: false,
      accessor: item => (item && item.total_assets) || 0,
      Cell: row =>
        row.original.total_assets ? (
          <FormattedNumber value={row.original.total_assets} format="currency" />
        ) : (
          <span>-</span>
        )
    },
    {
      Header: () => (
        <span>
          PRISM <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 7,
      minWidth: 90,
      filterable: false,
      accessor: item => (item && item.prism_overall) || 0,
      Cell: row =>
        row.original.prism_overall ? (
          <ScoreBubble score={row.original.prism_overall} className="risk-bubble" />
        ) : (
          <span>-</span>
        )
    },
    {
      Header: () => (
        <span>
          Risk Tolerance <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 8,
      minWidth: 90,
      filterable: false,
      accessor: item => (item && item.target_overall) || 0,
      Cell: row =>
        row.original.target_overall ? (
          <ScoreBubble score={row.original.target_overall} className="risk-bubble" />
        ) : (
          <span>-</span>
        )
    },
    {
      Header: () => (
        <span>
          Drift <img src="/img/sorting.svg" className="sorting" alt="" />
        </span>
      ),
      id: 9,
      width: 98,
      className: 'drift-td',
      filterable: false,
      accessor: item => (item?.['drift_overall'] ? Math.abs(item.drift_overall) : 0),
      Cell: ({ original: { drift_overall: driftOverall, is_healthy: isHealthy } }) => {
        if (driftOverall)
          return (
            <div className={cn('drift', { red: !isHealthy })}>
              {Math.abs(driftOverall).toFixed(1)}
            </div>
          );
        return <span>-</span>;
      }
    },
    {
      Header: '',
      id: 10,
      width: 50,
      expander: true,
      filterable: false,
      Expander: ({ isExpanded, ...rest }) => {
        if (rest.original && rest.original.prism_overall)
          return (
            <div className="expander-arrow-icon">
              {isExpanded ? (
                <img src="/img/icons/uparrow_blue.svg" alt="Collapse" />
              ) : (
                <img src="/img/icons/downarrow_blue.svg" alt="Expand" />
              )}
            </div>
          );

        return null;
      }
    }
  ];

  return (
    <div className="surveillance-table">
      <div className="surveillance-search">
        <div className="form-group search">
          <div>
            <input
              className="form-control react-autosuggest__input"
              placeholder="Search by advisor name"
              onChange={onSearch}
            />
            <span className="icon-search_icn" />
          </div>
        </div>
      </div>

      <ReactTable
        data={visibleAdvisors}
        columns={surveillanceColumns}
        defaultPageSize={10}
        minRows={0}
        showPageSizeOptions={false}
        noDataText="No Advisors."
        defaultSorted={[{ id: 'totalAccounts', desc: true }]}
        resizable={false}
        filterable
        filtered={filter}
        expanded={expanded}
        onFilteredChange={filtered => searchBy({ filtered })}
        defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            cursor: 'pointer'
          },
          onClick: e => {
            e.stopPropagation();
            const { expanded } = state;
            const path = rowInfo.nestingPath[0];
            const diff = { [path]: !expanded[path] };
            setExpanded({
              ...expanded,
              ...diff
            });
          }
        })}
        getTrProps={(state, rowInfo) => ({
          className: state.expanded[rowInfo.viewIndex] ? 'expanded ' : '',
          onClick: e => {
            e.preventDefault();
            e.stopPropagation();
          }
        })}
        SubComponent={row => <AdvisorDetailRow advisor={row.original} />}
      />
    </div>
  );
};

ComplianceSurveillanceList.propTypes = {
  advisors: PropTypes.array
};

ComplianceSurveillanceList.defaultProps = {
  advisors: []
};

ComplianceSurveillanceList.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired
};

export default ComplianceSurveillanceList;
