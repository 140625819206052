import { Polygon, Rect, Svg, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const RiskRangeTooltip = ({ height, label, range, reverse, score, style, width }) => {
  const roundedScore = Math.round(score);
  return (
    <View style={[{ width, height }, style]}>
      <Svg style={{ width, height }} viewBox="0 0 120 120">
        <Polygon
          style={[styles.riskRangeLabelBox, styles[`fColor${roundedScore}`]]}
          points={
            reverse
              ? '120 86.72 120 27 89.56 27 60.53 0 31.5 27 0 27 0 86.72 120 86.72'
              : '0 33.28 0 93 30.45 93 59.47 120 88.5 93 120 93 120 33.28 0 33.28'
          }
        />
        <Rect y={reverse ? '86.71' : '0'} width="120" height="33.29" style={styles.riskRangeBox} />
        <Text
          textAnchor="middle"
          x="50%"
          y={reverse ? '70' : '75'}
          width="120"
          style={styles.riskRangeBoxText}
        >
          {range}
        </Text>
        <Text
          textAnchor="middle"
          x="50%"
          y={reverse ? '108' : '22'}
          width="120"
          style={{ fontSize: 14 }}
        >
          {label}
        </Text>
      </Svg>
    </View>
  );
};

RiskRangeTooltip.propTypes = {
  height: PropTypes.number,
  label: PropTypes.string.isRequired,
  range: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  score: PropTypes.number.isRequired,
  style: PropTypes.object,
  width: PropTypes.number
};

RiskRangeTooltip.defaultProps = {
  height: 40,
  reverse: false,
  style: {},
  width: 40
};

export default RiskRangeTooltip;
