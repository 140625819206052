// page orientations

export const PORTRAIT_ORIENTATION = 'portrait';
export const LANDSCAPE_ORIENTATION = 'landscape';

export const PORTRAIT_ORIENTATION_OPTION = {
  label: 'Portrait',
  value: PORTRAIT_ORIENTATION
};

export const LANDSCAPE_ORIENTATION_OPTION = {
  label: 'Landscape',
  value: LANDSCAPE_ORIENTATION
};

export const ORIENTATIONS = [PORTRAIT_ORIENTATION_OPTION, LANDSCAPE_ORIENTATION_OPTION];

// page sizes

export const SIZE_A4 = 'A4';
export const SIZE_LETTER = 'LETTER';
export const SIZE_LEGAL = 'LEGAL';

export const SIZE_A4_OPTION = {
  label: 'A4',
  value: SIZE_A4
};

export const SIZE_LETTER_OPTION = {
  label: 'Letter',
  value: SIZE_LETTER
};

export const SIZE_LEGAL_OPTION = {
  label: 'Legal',
  value: SIZE_LEGAL
};

export const SIZES = [SIZE_A4_OPTION, SIZE_LETTER_OPTION, SIZE_LEGAL_OPTION];

// sections

export const ITEMS_THRESHOLD = 3;
