import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { RequestAction } from 'components/advisor/section-locked';
import { SCAN_IQ_ID, RESEARCH_IQ_ID, TAX_IQ_ID } from '../constants';
import '../styles.scss';

const CardAssistantAI = ({
  title,
  text,
  imgSrc,
  cardId,
  buttonText,
  buttonDisabled,
  requestAccess,
  action
}) => (
  <div className="ai-assistant__card-container">
    <h3 className="ai-assistant__card-title">{title}</h3>
    <span className="ai-assistant__card-text">{text}</span>
    <img
      className={cn({
        scan: cardId === SCAN_IQ_ID,
        tax: cardId === TAX_IQ_ID,
        research: cardId === RESEARCH_IQ_ID
      })}
      src={imgSrc}
      alt={title}
    />
    {requestAccess ? (
      <RequestAction
        className="btn btn-outline-secondary request-space ai-assistant__card-button"
        name={title}
      />
    ) : (
      <button
        type="button"
        className="btn btn-outline-secondary ai-assistant__card-button"
        disabled={buttonDisabled}
        onClick={action}
      >
        {buttonText}
      </button>
    )}
  </div>
);

CardAssistantAI.propTypes = {
  action: PropTypes.func,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  requestAccess: PropTypes.bool,
  buttonDisabled: PropTypes.bool
};

CardAssistantAI.defaultProps = {
  action: null,
  requestAccess: false,
  buttonDisabled: false
};

export default CardAssistantAI;
