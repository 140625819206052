import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { QuestionPropTypes } from '../../../props';
import { getParsedQuestion } from '../../../utils/helpers';
import styles from './styles';

const ReportEmptyQuestion = ({ children, questions, question, withDescription }) => {
  const questionName = getParsedQuestion(questions, question?.question);
  const questionDescription = question.data?.constants?.pdf_description || question.description;

  return (
    <View break={false} style={styles.question} wrap={false}>
      <View style={styles.bullet}>
        <Text style={styles.bulletText}>Q{question?.position}</Text>
      </View>
      <View style={styles.container}>
        <View>
          <Text>{questionName}</Text>
        </View>
        {withDescription && questionDescription && (
          <View style={styles.containerNote}>
            <Text>{questionDescription}</Text>
          </View>
        )}
        {children}
      </View>
    </View>
  );
};

ReportEmptyQuestion.propTypes = {
  children: PropTypes.any.isRequired,
  questions: PropTypes.array.isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired,
  withDescription: PropTypes.bool
};

ReportEmptyQuestion.defaultProps = {
  withDescription: false
};

export default ReportEmptyQuestion;
