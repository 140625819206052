/* eslint-disable import/no-cycle */
import { View } from '@react-pdf/renderer';
import { questionFlavors as qf, questionTypes as qt } from 'constants/questionnaire';
import AddressEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/address/pdf/empty';
import AnnualTaxableIncomeEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/annual-taxable-income/pdf/empty';
import BirthdayRetirementEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/birthday-retirement/pdf/empty';
import CashInvestableAssetsEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/cash-investable-assets/pdf/empty';
import ChoicesEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/choices/pdf/empty';
import ExpectedReturnEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/expected-return/pdf/empty';
import GoalApproachEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/goal-approach/pdf/empty';
import NumericEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/numeric/pdf/empty';
import RangeEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/range/pdf/empty';
import SectionSeparatorEmpty from 'containers/risk-tolerance-questionnaire/question/section-separator/pdf/empty';
import SingleYearOutcomeEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/single-year-outcome/pdf/empty';
import TextEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/text/pdf/empty';
import TolerableLossEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/tolerable-loss/pdf/empty';
import ValueRequirementEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/value-requirement/pdf/empty';
import WithdrawalRequirementEmptyQuestion from 'containers/risk-tolerance-questionnaire/question/withdrawal-requirement/pdf/empty';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';

const getComponentForQuestion = (type, flavor) => {
  if (type === qt.CUSTOM) {
    if (flavor === qf.ADDRESS) return AddressEmptyQuestion;
    if (flavor === qf.ANNUAL_TAXABLE_INCOME) return AnnualTaxableIncomeEmptyQuestion;
    if (flavor === qf.CASH_INVESTABLE_ASSETS) return CashInvestableAssetsEmptyQuestion;
    if (flavor === qf.EXPECTED_RETURN) return ExpectedReturnEmptyQuestion;
    if (flavor === qf.GOAL_APPROACH) return GoalApproachEmptyQuestion;
    if (flavor === qf.PORTFOLIO_CONCENTRATION) return ChoicesEmptyQuestion;
    if (flavor === qf.SINGLE_YEAR_OUTCOME) return SingleYearOutcomeEmptyQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return TolerableLossEmptyQuestion;
    if (flavor === qf.VALUE_REQUIREMENT) return ValueRequirementEmptyQuestion;
    if (flavor === qf.WITHDRAWAL_REQUIREMENT) return WithdrawalRequirementEmptyQuestion;
  }
  if ([qt.CUSTOM, qt.LEGACY].includes(type) && flavor === qf.BIRTHDAY_RETIREMENT)
    return BirthdayRetirementEmptyQuestion;
  if (type === qt.CHOICE) return ChoicesEmptyQuestion;
  if (type === qt.NUMERIC) return NumericEmptyQuestion;
  if (type === qt.RANGE) return RangeEmptyQuestion;
  if (type === qt.SECTION) return SectionSeparatorEmpty;
  if (type === qt.TEXT) return TextEmptyQuestion;
  return null;
};

const customPorfolioQuestion =
  'How much would you be comfortable investing in a single stock like Apple or Microsoft?';

const renderQuestion = (questions, question) => {
  const { question_type: type, data } = question;
  const QuestionComponent = getComponentForQuestion(type, data?.flavor);

  const isCustomPorfolio = type === qt.CUSTOM && data?.flavor === qf.PORTFOLIO_CONCENTRATION;
  const customPorFolioProps = { ...question, question: customPorfolioQuestion };

  if (QuestionComponent)
    return (
      <QuestionComponent
        key={question.position}
        question={isCustomPorfolio ? customPorFolioProps : question}
        questions={questions}
      />
    );

  return null;
};

const RiskToleranceQuestionnaireEmptyContent = ({ questions }) => (
  <BaseSection>
    {questions.map(question => (
      <View key={question.question}>{renderQuestion(questions, question)}</View>
    ))}
  </BaseSection>
);

RiskToleranceQuestionnaireEmptyContent.propTypes = {
  questions: PropTypes.array.isRequired
};

export default RiskToleranceQuestionnaireEmptyContent;
