import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useContext } from 'react';
import { AdvisorContext } from 'containers/advisor';
import MergeModal from './merge-modal';
import './styles.scss';

const MergeModalWrapper = ({ investor, investorAccounts, prospect, prospectAccounts, onClose }) => {
  const { isProspectSection } = useContext(AdvisorContext);

  const source = isProspectSection ? prospect : investor;
  const sourceAccounts = isProspectSection ? prospectAccounts : investorAccounts;

  if (!source) return null;

  const targetInvestorProp = {
    id: source.id,
    accounts: sourceAccounts.length,
    full_name: source.full_name,
    household: source.household?.name ?? '-',
    origin: source.origin ?? '-',
    is_prospect: source.is_prospect
  };

  return <MergeModal targetInvestorProp={targetInvestorProp} onClose={onClose} />;
};

MergeModalWrapper.propTypes = {
  investor: PropTypes.object,
  investorAccounts: PropTypes.array,
  prospect: PropTypes.object,
  prospectAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired
};

MergeModalWrapper.defaultProps = {
  investor: null,
  investorAccounts: [],
  prospect: null,
  prospectAccounts: []
};

export default connect(state => ({
  investor: state.investors.view,
  investorAccounts: state.investors.viewAccounts,
  prospect: state.prospects.view,
  prospectAccounts: state.prospects.viewAccounts
}))(MergeModalWrapper);
