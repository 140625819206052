/* eslint-disable react/no-unstable-nested-components */
import Disclosure from 'components/disclosure';
import DynamicTable from 'containers/table/dynamic';
import withSubscription from 'hocs/subscription-validation';
import PropTypes from 'prop-types';
import IntegrationProvider from 'providers/integrations';
import React, { PureComponent } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import {
  integrationsMetaSelector,
  integrationsSyncedAccountsSelector,
  integrationsUnassignedAccountsCountSelector
} from 'selectors/integrations';
import IntegrationAccountClientCell from './cell/ia-client';
import IntegrationAccountNameCell from './cell/ia-name';
import './styles.scss';

class IntegrationAccounts extends PureComponent {
  constructor() {
    super();
    this.state = { onlyUnassigned: undefined, solvedAllMissingAccounts: true };
  }

  componentDidUpdate() {
    const { unassignedAccounts } = this.props;
    const { solvedAllMissingAccounts } = this.state;

    if (unassignedAccounts > 0 && solvedAllMissingAccounts)
      this.setState({ solvedAllMissingAccounts: false });
  }

  onFetchData = params => {
    const { integrationProvider, meta } = this.props;
    return integrationProvider.es.listSyncedAccounts({ ...(meta?.params || {}), ...params });
  };

  toggleUnassignedAccounts = () => {
    this.setState(prevState => ({ onlyUnassigned: !prevState.onlyUnassigned }));
  };

  toggleUnassignedAccountsHandler = () => {
    this.toggleUnassignedAccounts();
  };

  onClientAssignment = () => {
    const { unassignedAccounts } = this.props;
    const { solvedAllMissingAccounts } = this.state;

    // get the new list of accounts again only if the number of unassigned
    // accounts reaches zero and not all accounts were previously resolved
    if (!solvedAllMissingAccounts && unassignedAccounts === 0)
      this.setState({ onlyUnassigned: false }, () => {
        this.onFetchData().then(() => {
          toast.success(() => (
            <div className="client-assigned-notification">
              <span role="img" aria-label="thumbs-up">
                👍
              </span>{' '}
              <p>
                <b>Great job! All of your accounts are mapped to clients.</b>
              </p>
            </div>
          ));
          this.setState({ solvedAllMissingAccounts: true });
        });
      });
  };

  deleteSelectedAccounts = async items => {
    const { integrationProvider } = this.props;

    const promisesArray = [];
    items.forEach(id => {
      promisesArray.push(integrationProvider.deleteSyncedAccount(id));
    });

    return Promise.all(promisesArray).then(() => {
      toast.success('Integration(s) removed successfully');
      this.onFetchData();
    });
  };

  render() {
    const { onlyUnassigned } = this.state;
    const { meta, syncedAccounts, unassignedAccounts } = this.props;

    const externalFilters = { assigned: onlyUnassigned ? false : undefined };

    const columns = [
      {
        accessorFn: row => row.number || row.name || '-',
        id: 'name',
        name: 'Account Number',
        cell: integration => <IntegrationAccountNameCell integration={integration} />,
        meta: { style: () => ({ width: 400 }) }
      },
      {
        accessorFn: row => row.value || 0,
        id: 'value',
        name: 'Value',
        cell: integration =>
          integration?.value ? (
            <FormattedNumber value={integration.value} format="currency" />
          ) : (
            '-'
          ),
        meta: { className: () => 'text-center', style: () => ({ width: 175 }) }
      },
      {
        accessorFn: row => row?.provider || '-',
        id: 'provider',
        name: 'Source',
        meta: { className: () => 'text-center' }
      },
      {
        accessorFn: row => row?.provider || '-',
        id: 'client',
        name: 'Client',
        cell: integration => (
          <IntegrationAccountClientCell
            integration={integration}
            onClientAssignment={this.onClientAssignment}
          />
        ),
        meta: { style: () => ({ width: 300 }) },
        enableSorting: false
      }
    ];

    return (
      <div id="integration-accounts">
        <div className="container">
          <div className="header">
            <h2>Accounts integration</h2>
          </div>
          {!!unassignedAccounts && (
            <div className="top-notification">
              <div className="notification-toggle" />
              <button
                type="button"
                className="btn btn-link"
                onClick={this.toggleUnassignedAccountsHandler}
              >
                {unassignedAccounts} Account(s)
              </button>{' '}
              are missing an associated client. These accounts are not included in any analytics
              until a client is assigned.
            </div>
          )}
          <div id="accounts-integration-table">
            <DynamicTable
              columns={columns}
              data={syncedAccounts}
              enableRowSelection
              externalFilters={externalFilters}
              label="Account"
              meta={meta}
              onDeleteSelectedItems={this.deleteSelectedAccounts}
              onFetchData={this.onFetchData}
              withTeamFilter={false}
            />
          </div>
          <Disclosure />
        </div>
      </div>
    );
  }
}

IntegrationAccounts.propTypes = {
  integrationProvider: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  syncedAccounts: PropTypes.array.isRequired,
  unassignedAccounts: PropTypes.number.isRequired
};

export default compose(
  withSubscription({
    plan: 'professional',
    id: 'account_integrations',
    name: 'Accounts Integration Matching'
  }),
  connect(
    state => ({
      meta: integrationsMetaSelector(state),
      syncedAccounts: integrationsSyncedAccountsSelector(state),
      unassignedAccounts: integrationsUnassignedAccountsCountSelector(state)
    }),
    dispatch => ({
      integrationProvider: new IntegrationProvider({ dispatch })
    })
  )
)(IntegrationAccounts);
