/* eslint-disable react/no-array-index-key */
import { Image, Text, View } from '@react-pdf/renderer';
import Row from 'app/reports/base/table';
import React from 'react';
import { getImageForReport } from 'reports/base/utils';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const ExpectedReturnEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion
    questions={questions}
    question={{ ...question, question: 'What yearly return do you expect to achieve?' }}
    withDescription
  >
    <View wrap={false}>
      {question.data?.expected_yearly_return_chart && (
        <Image
          src={getImageForReport(question.data.expected_yearly_return_chart)}
          style={styles.expectedYearlyReturnChart}
        />
      )}
      {question.data?.expected_yearly_return_table &&
        question.data.expected_yearly_return_table.map((sourceRow, rowIdx) => {
          const markStyle =
            rowIdx === 0 ? [styles.markCheck, { borderWidth: 0 }] : styles.markCheck;
          const mark = <View style={markStyle} />;
          const row = sourceRow.map((cell, cellIdx) => (
            <Text style={{ textAlign: 'center', fontSize: 8 }} key={`${rowIdx}-${cellIdx}`}>
              {cell}
            </Text>
          ));
          return (
            <Row rowStyle={styles.tabulatedSummary} elements={[mark, ...row]} withCustomElements />
          );
        })}
    </View>
  </ReportEmptyQuestion>
);

ExpectedReturnEmptyQuestion.propTypes = propTypes;

export default ExpectedReturnEmptyQuestion;
