import React from 'react';
import propTypes from '../../props';
import './styles.scss';

const ReadOnlyQuestion = ({ children, question }) => (
  <div className="read-only-question">
    <div className="read-only-question__title">
      {question.question_number ?? question.position}. {question.question}
    </div>
    <div className="read-only-question__answer">{children}</div>
  </div>
);

ReadOnlyQuestion.propTypes = propTypes;

export const ReadOnlyQuestionSkeleton = ({ question }) => (
  <ReadOnlyQuestion question={question}>
    <p>-</p>
  </ReadOnlyQuestion>
);

ReadOnlyQuestionSkeleton.propTypes = propTypes;

export default ReadOnlyQuestion;
