import { color } from '@amcharts/amcharts5';
import config from 'app/config';
import _ from 'lodash';
import { getScoreDataByScoreName } from 'providers/model';
import {
  prepareAssetDiversification,
  prepareCorrelation,
  prepareMedianPortfolioMove,
  prepareVolDiversification
} from 'utils/prism';

export const OVERALL_GAUGE = { height: '160px', width: '200px' };

export const STOCK_COLOR = '#006e00';
export const NON_STOCK_COLOR = '#646464';

export const composeTreemapData = (stock, nonStock) => {
  const stockOptions = Object.keys(stock).map(key => ({
    name: key,
    value: stock[key],
    color: color(STOCK_COLOR)
  }));

  const nonStockOptions = Object.keys(nonStock).map(key => ({
    name: key,
    value: nonStock[key],
    color: color(NON_STOCK_COLOR)
  }));

  const legend = [];

  if (stockOptions.length && nonStockOptions.length)
    legend.push(
      { name: 'Single Stock', color: STOCK_COLOR },
      { name: 'Non-Single Stock', color: NON_STOCK_COLOR }
    );
  else if (stockOptions.length) legend.push({ name: 'Stock holdings', color: STOCK_COLOR });
  else if (nonStockOptions.length)
    legend.push({ name: 'Non-stock holdings', color: NON_STOCK_COLOR });

  return { options: [...stockOptions, ...nonStockOptions], legend };
};

export const getChartData = data => {
  const {
    classifications,
    correlation_benchmarks_max: correlationBenchmarksMax,
    correlation_benchmarks_min: correlationBenchmarksMin,
    long_vol_fraction: longVolFraction,
    median_market_move: medianMarketMove,
    median_portfolio_move: medianPortfolioMove,
    relative_non_stock_weights: relativeNonStockWeights,
    relative_stock_weights: relativeStockWeights,
    short_vol_fraction: shortVolFraction
  } = data;

  if (medianMarketMove && medianPortfolioMove)
    return prepareMedianPortfolioMove(medianPortfolioMove, medianMarketMove);

  if (correlationBenchmarksMin && correlationBenchmarksMax)
    return prepareCorrelation(correlationBenchmarksMin, correlationBenchmarksMax);

  if (relativeStockWeights && relativeNonStockWeights)
    return composeTreemapData(relativeStockWeights, relativeNonStockWeights);

  if (classifications) return prepareAssetDiversification(classifications);

  if (_.isNumber(longVolFraction) && _.isNumber(shortVolFraction))
    return prepareVolDiversification(shortVolFraction, longVolFraction);

  return [];
};

/*
 * Given a risk analysis returns the 4 score factors and the overall
 */
export const getScoreFactors = ({ custom: customScore }) => {
  const {
    prism: {
      scores: { values: scores }
    }
  } = config;

  let concentrated = null;
  let volatility = null;
  let correlation = null;
  let tail = null;
  let overall = null;

  if (customScore) {
    concentrated = getScoreDataByScoreName(customScore.risk_scores[0], scores.CONCENTRATED_SCORE);
    volatility = getScoreDataByScoreName(customScore.risk_scores[0], scores.VOLATILITY_SCORE);
    correlation = getScoreDataByScoreName(customScore.risk_scores[0], scores.CORRELATION_SCORE);
    tail = getScoreDataByScoreName(customScore.risk_scores[0], scores.TAIL_SCORE);
    overall = customScore.risk_scores[0].overall_score;
  }

  return {
    concentrated,
    volatility,
    correlation,
    tail,
    overall
  };
};

/**
 * Given the risk analysis, return the expected scenarios for an account or model
 *
 * - Account --> riskAnalysis?.portfolio?.prism_score?.scenarios
 * - Model   --> riskAnalysis?.portfolio?.scenarios
 */
export const getScenarios = riskAnalysis => {
  let scenariosPerformance = [];
  if (riskAnalysis?.portfolio?.prism_score?.scenarios)
    scenariosPerformance = [...riskAnalysis.portfolio.prism_score.scenarios];
  else if (riskAnalysis?.portfolio?.scenarios)
    scenariosPerformance = [...riskAnalysis.portfolio.scenarios];

  const scenarios = config.prism.scenarios
    .filter(scenario => !!riskAnalysis[scenario])
    .map((scenario, i) => ({
      key: `scenario-${i}`,
      name: scenario,
      score: riskAnalysis?.[scenario]?.risk_scores?.[0]?.overall_score
    }));

  return { scenarios, scenariosPerformance };
};
