import React from 'react';
import './styles.scss';

const ColorsSection = () => (
  <section id="colors-section">
    <h2>Colors</h2>
    <div className="boxes">
      <div className="box stratifi-blue">
        <h3>StratiFi Blue</h3>
        <p>Links, buttons</p>
        <span>$primary: #09ACF8</span>
      </div>
      <div className="box hover-blue">
        <h3>Hover Blue</h3>
        <p>Hover for links</p>
        <span>$hover-blue: #40C0FB</span>
      </div>
      <div className="box inactive-blue contrast">
        <h3>Inactive Blue</h3>
        <p>Inactive links, buttons</p>
        <span>
          $inactive-blue: <i title="$primary, 30% Opacity">rgba(9, 172, 248, 0.3)</i>
        </span>
      </div>
      <div className="box primary-body-text">
        <h3>Primary Body Text</h3>
        <p>Body texts</p>
        <span>$gray-dark: #394346</span>
      </div>
      <div className="box secondary-body-text">
        <h3>Secondary Body Text</h3>
        <p>Supporting or interactive navigation</p>
        <span>$graphite-light: #7C8C94</span>
      </div>
      <div className="box inactive-text contrast">
        <h3>Inactive Text</h3>
        <p>Inactive or supporting texts</p>
        <span>
          $inactive-text: <i title="$gray-dark, 30% Opacity">rgba(57, 67, 70, 0.3)</i>
        </span>
      </div>
      <div className="box table-header contrast">
        <h3>Table Header</h3>
        <p>Table header, text box</p>
        <span>$mystic: #E3E7EE</span>
      </div>
      <div className="box background contrast">
        <h3>Background</h3>
        <p>Platform background</p>
        <span>$body-background: #F0F3F4</span>
      </div>
      <div className="box border-color contrast">
        <h3>Border Color</h3>
        <p>Table, text box border</p>
        <span>$border-color: #D9E1E4</span>
      </div>
      <div className="box prism-red">
        <h3>PRISM Red</h3>
        <p>Hight risk, warning</p>
        <span>$high-risk: #C93539</span>
      </div>
      <div className="box prism-yellow">
        <h3>PRISM Yellow</h3>
        <p>Neutral risk</p>
        <span>$neutral-risk: #F7941D</span>
      </div>
      <div className="box prism-green">
        <h3>PRISM Green</h3>
        <p>Low risk, new items</p>
        <span>$low-risk: #4EB63D</span>
      </div>
      <div className="box point-blue contrast">
        <h3>Point blue</h3>
        <p>Select navigation</p>
        <span>$info: #31E3FF</span>
      </div>
      <div className="box empty" />
      <div className="box empty" />
    </div>
  </section>
);

export default ColorsSection;
