// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-form .c-select-wrap:after {
  top: 16px;
}
.address-form .c-select-wrap .c-select {
  min-width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/address-form/styles.scss"],"names":[],"mappings":"AAEI;EACE,SAAA;AADN;AAII;EACE,eAAA;AAFN","sourcesContent":[".address-form {\n  .c-select-wrap {\n    &:after {\n      top: 16px;\n    }\n\n    .c-select {\n      min-width: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
