import React from 'react';
import WarningIcon from 'components/svg-icons/warning-icon';
import Choice from 'components/form/choice';
import './styles.scss';

const ButtonsSection = () => (
  <section id="buttons-section">
    <h2>Buttons</h2>
    <h3>Buttons</h3>
    <h4>Standard buttons</h4>

    <p>Height: 37px (Can be flexible depending on the case)</p>
    <p>Left and right padding: 30px</p>
    <p>Corner radius: 3px</p>
    <p>Hover: opacity: 80% (Except disabled button)</p>

    <div className="row row-buttons">
      <div className="col">
        <button type="button" className="btn btn-primary">
          Primary Action
        </button>
        <code>btn btn-primary</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-secondary">
          Secondary Action
        </button>
        <code>btn btn-secondary</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-secondary-2">
          Secondary Action 2
        </button>
        <code>btn btn-secondary-2</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-primary" disabled>
          Disabled Button
        </button>
        <code>btn btn-primary</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-red">
          Destructive Action
        </button>
        <code>btn btn-red</code>
      </div>
    </div>

    <h4>Small size buttons</h4>

    <p>Height: 30px</p>
    <p>Left and right padding: 22px</p>
    <p>Corner radius: 3px</p>
    <p>Hover: 80% opacity</p>

    <div className="row row-buttons">
      <div className="col">
        <button type="button" className="btn btn-primary btn-small">
          Primary Action
        </button>
        <code>btn btn-primary btn-small</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-secondary btn-small">
          Secondary Action
        </button>
        <code>btn btn-secondary btn-small</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-secondary-2 btn-small">
          Secondary Action 2
        </button>
        <code>btn btn-secondary-2 btn-small</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-primary btn-small" disabled>
          Disabled Button
        </button>
        <code>btn btn-primary btn-small</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-red btn-small">
          Destructive Action
        </button>
        <code>btn btn-red btn-small</code>
      </div>
    </div>

    <h4>PRISM buttons</h4>

    <p>Height: 37x30px</p>
    <p>Corner radius: 3px</p>
    <p>Font size: 16px, weight: 400</p>

    <div className="row row-prism">
      <div className="col">
        <button type="button" className="btn btn-box btn-green">
          1.5
        </button>
        <span>Low risk</span>
        <code>btn btn-box btn-green</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-box btn-yellow">
          4.5
        </button>
        <span>Neutral risk</span>
        <code>btn btn-box btn-yellow</code>
      </div>
      <div className="col">
        <button type="button" className="btn btn-box btn-red">
          9.5
        </button>
        <span>Hight risk</span>
        <code>btn btn-box btn-red</code>
      </div>
    </div>

    <h4>UI buttons</h4>

    <p>37x30px</p>
    <p>Corner radius: 3px</p>

    <div className="row row-plus-button">
      <button type="button" className="btn btn-dashed btn-plus">
        +
      </button>
      <span>Showing something needs to be added</span>
      <code>btn btn-box btn-dashed</code>
    </div>
    <div className="row row-plus-button">
      <button type="button" className="btn btn-primary btn-plus">
        +
      </button>
      <span>Actual button for adding something</span>
      <code>btn btn-box btn-primary</code>
    </div>
    <p>Icon buttons height: 30px - 25px depending on cases</p>
    <div className="box-container">
      <div className="box">
        <i className="fs-icon-edit-icon-blue" />
        <span>Edit</span>
        <code>{'<i className="fs-icon-edit-icon-blue" />'}</code>
      </div>
      <div className="box">
        <WarningIcon className="warning-icon" />
        <span>Warning icon</span>
        <code>{'<WarningIcon className="warning-icon" />'}</code>
      </div>
      <div className="box">
        <i className="icon-checkmark_circle" />
        <span>Confirmation</span>
        <code>{'<i className="icon-checkmark_circle" />'}</code>
      </div>
      <div className="box">
        <i className="fs-icon-cancel-round" />
        <span>Cancel</span>
        <code>{'<i className="fs-icon-cancel-round" />'}</code>
      </div>
      <div className="box">
        <i className="fs-icon-xmark-linear" />
        <span>Popup close button</span>
        <code>{'<i className="fs-icon-xmark-linear" />'}</code>
      </div>
    </div>

    <h4>Check boxes</h4>

    <p>30x30px</p>
    <p>Corner radius: 3px</p>
    <p>Inactive: 30% Opacity</p>

    <div className="checkboxes">
      <Choice title="Active, checked" checked toggle={() => {}} />
      <Choice title="Active, unchecked" checked={false} toggle={() => {}} />
      <Choice
        title="Active, unchecked, hover"
        className="fake-hover"
        checked={false}
        toggle={() => {}}
      />
      <Choice title="Inactive, checked" checked disabled toggle={() => {}} />
      <Choice title="Inactive, unchecked" checked={false} disabled toggle={() => {}} />
      <div className="code-block">
        <code>{'<Choice title="Title" checked={bool} disabled={bool} toggle={f} />'}</code>
      </div>
    </div>
  </section>
);

export default ButtonsSection;
