import clone from 'clone';
import PropTypes from 'prop-types';
import Chart from '..';
import defaultOptions from './options';

class ClusteredColumnChart extends Chart {
  className = 'clustered-column-chart';

  getOptions() {
    const themes = this.context.config.chart;
    return { ...clone(themes.common.default, false), ...defaultOptions, ...this.props.options };
  }
}

ClusteredColumnChart.contextTypes = {
  config: PropTypes.object.isRequired
};

ClusteredColumnChart.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.object
};

ClusteredColumnChart.defaultProps = {
  options: {}
};

export default ClusteredColumnChart;
