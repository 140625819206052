import AggregatedRiskScoreBubble from 'components/advisor/utils/score-bubble/aggregated-risk';
import { getPrismFactorName } from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';

const InvestorPrismCell = ({ investor, meta, prismScoresInProgress }) => {
  const investorAccounts = investor.accounts.map(account => account.id);
  const inProgress = prismScoresInProgress.some(accountId => investorAccounts.includes(accountId));
  const scoreName = getPrismFactorName(meta?.params?.[PRISM_FACTOR_ATTR]);

  return (
    <Link to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${investor.id}/prism`}>
      <AggregatedRiskScoreBubble
        element={investor}
        inProgress={inProgress}
        isListView
        scoreName={scoreName}
      />
    </Link>
  );
};

InvestorPrismCell.propTypes = {
  investor: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state)
}))(InvestorPrismCell);
