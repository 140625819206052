/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import Disclosure from 'components/disclosure';
import { preferredValuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CollaborationPagination from 'pages/collaboration-hub/pagination';
import CollaborationSearch from 'pages/collaboration-hub/search';
import DynamicTable from 'containers/table/dynamic';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TeamTable from 'pages/teams/team-table';
import { toast } from 'react-toastify';
import './styles.scss';

const tabs = {
  TEAMS: 'teams',
  ADVISORS: 'advisors'
};

const DEFAULT_TAB = tabs.TEAMS;
const VISIBILITY_PUBLIC = 'public';
const VISIBILITY_PRIVATE = 'private';

const ModelAccessControl = ({ advisors, params, meta, model }) => {
  const { advisorProvider, modelProvider, teamProvider } = useContext(AdvisorContext);

  const selectedOption = model.visibility;
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [teamData, setTeamData] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);

  const enabledActions = true;
  const debounceDelay = 500;
  const modelID = params?.id;
  const DEFAULT_ROLES_ORDERING = ['-role', 'user__first_name'];

  const fetchUsers = async params => advisorProvider.list({ ...params });

  const onAddOrRemoveAdvisor = _.debounce(advisor => {
    const advisorID = advisor.id;
    const advisors = model.advisors.map(a => a.id);
    const advisorHasAccess = advisors.find(id => id === advisorID) !== undefined;

    if (advisorHasAccess) advisors.splice(advisors.indexOf(advisorID), 1);
    else advisors.push(advisorID);

    modelProvider.patchModel(modelID, { advisors }).then(() => {
      toast.success('🔒 The model portfolio access has been updated.');
    });
  }, debounceDelay);

  const onAddOrRemoveAdvisorHandler = advisor => () => {
    onAddOrRemoveAdvisor(advisor);
  };

  const onAddOrRemoveTeam = _.debounce(rowInfo => {
    const { original: team } = rowInfo;
    const teamID = team.id;
    const teams = model.teams.map(t => t.id);
    const teamHasAccess = teams.find(id => id === teamID) !== undefined;

    if (teamHasAccess) teams.splice(teams.indexOf(teamID), 1);
    else teams.push(teamID);

    modelProvider.patchModel(modelID, { teams }).then(() => {
      toast.success('🔒 The model portfolio access has been updated.');
    });
  }, debounceDelay);

  const advisorColumns = [
    {
      accessorFn: row => `${row.user.first_name} ${row.user.last_name}`,
      id: 'user__first_name',
      name: 'Name',

      meta: { style: () => ({ width: 350 }) }
    },
    {
      accessorFn: row => row.role,
      id: 'actions',
      enableSorting: false,
      name: 'Actions',
      cell: advisor => (
        <div className="advisors-actions-container">
          <button
            type="button"
            className={cn(
              { 'disabled-action-btn': model.advisors.map(a => a.id).includes(advisor.id) },
              'advisors-btn-transparent'
            )}
            onClick={onAddOrRemoveAdvisorHandler(advisor)}
            aria-label="add"
            disabled={!model.advisors.map(a => a.id).includes(advisor.id)}
          >
            <i
              className={cn(
                { 'disabled-action-btn': model.advisors.map(a => a.id).includes(advisor.id) },
                'icon-cross-circle',
                'security--icon-cross'
              )}
            />
          </button>
          <button
            type="button"
            className={cn(
              { 'disabled-action-btn': !model.advisors.map(a => a.id).includes(advisor.id) },
              'advisors-btn-transparent'
            )}
            onClick={onAddOrRemoveAdvisorHandler(advisor)}
            aria-label="remove"
            disabled={model.advisors.map(a => a.id).includes(advisor.id)}
          >
            <i
              className={cn(
                { 'disabled-action-btn': !model.advisors.map(a => a.id).includes(advisor.id) },
                'icon-checkmark-circle ',
                'security--icon-checkmark'
              )}
            />
          </button>
        </div>
      ),
      meta: { className: () => 'text-center', style: () => ({ width: 125 }) }
    }
  ];

  useEffect(() => {
    modelProvider.get(modelID).then(response => {
      modelProvider.view(response.data);
    });
    teamProvider.getList().then(response => {
      setTeamData(response);
    });
    advisorProvider.clearAdvisors();
  }, []);

  const handleOptionChange = useCallback(
    _.debounce(values => {
      const modelVisibility = { visibility: values.target.value };

      modelProvider.patchModel(modelID, modelVisibility);
    }, debounceDelay),
    [modelProvider]
  );

  if (!model || !Object.keys(model).length) return null;

  return (
    <form autoComplete="off">
      <h2>Access control</h2>
      <section id="model-access-control-container" className="risk-analysis">
        <div>
          <div className="model-access-control--input-radio">
            <label className="input-radio-container radio-text__public">
              <input
                type="radio"
                value={VISIBILITY_PUBLIC}
                checked={selectedOption === VISIBILITY_PUBLIC}
                onChange={handleOptionChange}
              />
              <span className="input-radio-container__checkmark" />
              <span className="access-control--radio-text">
                Public: Visible for all the advisors of my company.
              </span>
            </label>
          </div>
          <div>
            <label className="input-radio-container radio-text__private">
              <input
                type="radio"
                value={VISIBILITY_PRIVATE}
                checked={selectedOption === VISIBILITY_PRIVATE}
                onChange={handleOptionChange}
                className="input-radio-container__checkmark"
              />
              <span className="input-radio-container__checkmark" />
              <span className="access-control--radio-text">
                Private: Apply granular access control.
              </span>
            </label>
          </div>
        </div>
        {selectedOption === VISIBILITY_PRIVATE && (
          <div className="access-control--tabs">
            <ul className="model-details-view__tabs tabs">
              <li className={cn({ active: tab === tabs.TEAMS })}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setTab(tabs.TEAMS)}
                  onKeyUp={() => {}}
                >
                  Teams
                </div>
              </li>
              <li className={cn({ active: tab === tabs.ADVISORS })}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setTab(tabs.ADVISORS)}
                  onKeyUp={() => {}}
                >
                  Advisor
                </div>
              </li>
            </ul>
            {tab === tabs.TEAMS && (
              <div>
                <div className="team-view__header model-team">
                  <div className="team-view__header-actions">
                    <CollaborationSearch searchCallback={setQuerySearch} />
                  </div>
                </div>
                <div className="team-view__content">
                  <TeamTable
                    data={teamData}
                    defaultPageSize={pageSize}
                    querySearch={querySearch}
                    enabledActions={enabledActions}
                    callbackActions={onAddOrRemoveTeam}
                    security={model}
                  />
                  <div className="access-control-team--pagination">
                    <CollaborationPagination paginationCallback={setPageSize} />
                  </div>
                </div>
              </div>
            )}

            {tab === tabs.ADVISORS && (
              <div>
                <DynamicTable
                  columns={advisorColumns}
                  data={advisors}
                  initialParams={{
                    ordering: DEFAULT_ROLES_ORDERING
                  }}
                  label="Advisor"
                  meta={meta}
                  onFetchData={fetchUsers}
                  hasFilter={false}
                />
              </div>
            )}
          </div>
        )}
      </section>
      <Disclosure />
    </form>
  );
};

ModelAccessControl.propTypes = {
  advisors: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  initializeForm: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  model: state.models.view,
  advisors: state.advisors.list,
  meta: state.advisors.listMeta
});

const ModelAccessControlWithoutRout = withRouter(ModelAccessControl);

export default connect(mapStateToProps)(ModelAccessControlWithoutRout);
