import { Defs, LinearGradient, Rect, Stop, Svg, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { PRISM_COLOR_0, PRISM_COLOR_5, PRISM_COLOR_9, PRISM_OFF_COLOR } from 'reports/base/styles';
import styles from './styles';

const MAX_SCORE = 10;
const SCORE_LINE_WIDTH = '150';

const TargetScoreLine = ({ name, value }) => {
  const scoreWidth = Number(SCORE_LINE_WIDTH) * (1 - value / MAX_SCORE);
  return (
    <View style={styles.scoreLineContainer}>
      <Svg viewBox={`0 0 ${SCORE_LINE_WIDTH} 5`} width={SCORE_LINE_WIDTH} style={styles.scoreLine}>
        <Defs>
          <LinearGradient id="prism-linear-gradient">
            <Stop offset="5%" stopColor={PRISM_COLOR_0} />
            <Stop offset="50%" stopColor={PRISM_COLOR_5} />
            <Stop offset="90%" stopColor={PRISM_COLOR_9} />
          </LinearGradient>
        </Defs>
        <Rect width={SCORE_LINE_WIDTH} height="5" fill="url('#prism-linear-gradient')" />
        <Rect width={`-${scoreWidth}`} height="5" x={SCORE_LINE_WIDTH} fill={PRISM_OFF_COLOR} />
      </Svg>
      <Text style={styles.scoreName}>{name}</Text>
    </View>
  );
};

TargetScoreLine.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default TargetScoreLine;
