import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DataLoadingIcon from '../../../svg-icons/data-loading-icon';

import './styles.scss';

const LoadingPrismDataMessage = ({ message, inset }) => (
  <div className={classnames('tab-content-dashboard loading-overlay', { '-inset': inset })}>
    <div className="text-sm-center bars-div">
      <DataLoadingIcon className="bars" color="#92dcf8" />
      <div className="text-sm-center text">{message}</div>
    </div>
  </div>
);

LoadingPrismDataMessage.defaultProps = {
  inset: false,
  message: 'Loading data...'
};

LoadingPrismDataMessage.propTypes = {
  inset: PropTypes.bool,
  message: PropTypes.string
};

export default LoadingPrismDataMessage;
