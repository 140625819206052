/* eslint-disable jsx-a11y/label-has-for */
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { VerboseErrorInput as Input, InputButton } from '..';

const MultiCheckboxField = ({
  field,
  flavor,
  intl,
  label,
  multiple,
  options,
  otherField,
  otherValue
}) => {
  const getCurrentValues = () => {
    let previousValues = [];

    if (!_.isUndefined(field.value) && field.value !== '') previousValues = field.value;
    else if (!_.isUndefined(field.initialValue) && field.initialValue !== '')
      previousValues = field.initialValue;

    const currentValues = _.isArray(previousValues) ? [...previousValues] : [previousValues];
    return currentValues;
  };

  const handleChange = (event, value) => {
    let values = getCurrentValues();

    if (event.target.checked)
      if (multiple) values.push(value);
      else values = [value];
    else values.splice(values.indexOf(value), 1);

    return field.onChange(values);
  };

  const values = getCurrentValues();

  return (
    <div className={cn('form-group', { [`multi--${flavor}`]: flavor })}>
      {label && <label>{label}</label>}
      <div data-question={field.name}>
        {options.map(option => (
          <InputButton
            checked={values.includes(option.value)}
            field={field}
            key={option.value}
            multiple={multiple}
            onChange={event => handleChange(event, option.value)}
            onBlur={() => field.onBlur(values)}
            option={option}
          />
        ))}
        {otherField && values.includes(otherValue) && (
          <Input
            {...otherField}
            type="text"
            placeholder={intl.formatMessage({ id: 'form.other-field.placeholder' })}
            className="form-control mt-2"
            maxLength={100}
          />
        )}
      </div>
    </div>
  );
};

MultiCheckboxField.propTypes = {
  field: PropTypes.object.isRequired,
  flavor: PropTypes.string,
  intl: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ).isRequired,
  otherField: PropTypes.object,
  otherValue: PropTypes.string
};

MultiCheckboxField.defaultProps = {
  flavor: '',
  label: '',
  multiple: false,
  otherField: null,
  otherValue: ''
};

export default injectIntl(MultiCheckboxField);
