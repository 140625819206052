export default {
  type: 'serial',
  categoryField: 'date',
  dataDateFormat: 'YYYY-MM-DD',
  categoryAxis: {
    gridPosition: 'start',
    dashLength: 2,
    parseDates: true,
    dateFormats: [
      { period: 'fff', format: 'JJ:NN:SS' },
      { period: 'ss', format: 'JJ:NN:SS' },
      { period: 'mm', format: 'JJ:NN' },
      { period: 'hh', format: 'JJ:NN' },
      { period: 'DD', format: 'MM/DD/YYYY' },
      { period: 'WW', format: 'MM/DD/YYYY' },
      { period: 'MM', format: 'MM/YYYY' },
      { period: 'YYYY', format: 'YYYY' }
    ]
  },
  chartCursor: {
    cursorAlpha: 0,
    cursorColor: '#000000',
    categoryBalloonEnabled: false
  },
  valueAxes: [
    {
      dashLength: 2,
      autoGridCount: false,
      axisAlpha: 0,
      gridAlpha: 0.1,
      labelsEnabled: false,
      gridCount: 3,
      minimum: 0,
      maximum: 10
    }
  ],
  graphs: [
    {
      cornerRadiusTop: 1,
      fillAlphas: 1,
      fillColors: ['#C93539', '#FAA502', '#4EB63D'],
      gradientOrientation: 'vertical',
      id: 'prism',
      lineThickness: 0,
      tabIndex: 0,
      title: 'Adjusted',
      type: 'smoothedLine',
      valueField: 'scoreOverall'
    },
    {
      balloon: {
        enabled: false
      },
      cornerRadiusTop: 1,
      fillAlphas: 0.5,
      fillColors: ['#666666'],
      id: 'target',
      lineColor: '#666666',
      lineThickness: 2,
      tabIndex: 0,
      title: 'Adjusted',
      type: 'line',
      valueField: 'targetOverall'
    }
  ]
};
