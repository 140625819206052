// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#proposal-form-rename-modal h4 {
  margin-bottom: 0;
  text-align: center;
}
#proposal-form-rename-modal .form-group {
  margin: 20px 0 0 0;
}
#proposal-form-rename-modal .form-group input.form-control {
  border-radius: 4px;
}
#proposal-form-rename-modal .form-group > span {
  font-size: 0.9rem;
}
#proposal-form-rename-modal #report-read-only-viewer {
  margin-top: 5px;
  margin-bottom: 20px;
}
#proposal-form-rename-modal .modal-actions {
  display: flex;
  justify-content: center;
  gap: 5px;
}
#proposal-form-rename-modal .modal-actions > button {
  min-width: 125px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/proposal/header/investor/form/rename-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,iBAAA;AAFN;AAME;EACE,eAAA;EACA,mBAAA;AAJJ;AAOE;EACE,aAAA;EACA,uBAAA;EACA,QAAA;AALJ;AAOI;EACE,gBAAA;AALN","sourcesContent":["#proposal-form-rename-modal {\n  h4 {\n    margin-bottom: 0;\n    text-align: center;\n  }\n\n  .form-group {\n    margin: 20px 0 0 0;\n\n    input.form-control {\n      border-radius: 4px;\n    }\n\n    & > span {\n      font-size: 0.9rem;\n    }\n  }\n\n  #report-read-only-viewer {\n    margin-top: 5px;\n    margin-bottom: 20px;\n  }\n\n  .modal-actions {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n\n    & > button {\n      min-width: 125px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
