import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import styles from './styles';
import propTypes from '../../../props';

const SectionSeparatorEmpty = ({ question }) => (
  <View style={styles.container} wrap={false}>
    <Text style={styles.title}>{question.question}</Text>
    {question.description && <Text style={styles.description}>{question.description}</Text>}
  </View>
);

SectionSeparatorEmpty.propTypes = propTypes;

export default SectionSeparatorEmpty;
