import { Modal, ModalBody, ModalHeader } from 'components/modal';
import WarningIcon from 'components/svg-icons/warning-icon';
import { CustomerSupportEmailLink } from 'constants/contact';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const MissingPrismModal = ({ onHide, show }) => (
  <Modal id="MissingPrismModal" className="modal-dialog modal-lg" show={show} onHidden={onHide}>
    <ModalHeader />
    <ModalBody>
      <WarningIcon className="warning-icon" />
      <p>
        PRISM Rating for this account has not been calculated. <br />
        We&apos;re working on analyzing this account. Please check back in 24-48 hours.
      </p>
      <p>
        If you have any questions, please contact
        <br />
        <CustomerSupportEmailLink />
      </p>
    </ModalBody>
  </Modal>
);

MissingPrismModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default MissingPrismModal;
