import LinkedAccountTable from 'components/advisor/investments/linked-account-table';
import SpinnerLoader from 'components/performance-spinner';
import withSubscription from 'hocs/subscription-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AccountProvider from 'providers/account';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';
import { capFirstChar } from 'utils/utils';
import config from '../../../config';

const {
  prism: { levels }
} = config;

class AccountsByRiskLevel extends Component {
  constructor(props) {
    super(props);
    const { query } = props.location;
    const activeTabId = query && query.by_level ? query.by_level : levels.HIGH;
    this.state = { loading: false, activeTabId };

    this.getData = this.getData.bind(this);
    this.doSearch = _.debounce(this.doSearch.bind(this), 500);
  }

  componentDidMount() {
    const { location } = this.props;
    this.getData(location.query);
  }

  onTab = ({ id }) => this.setState({ activeTabId: id });

  sortBy = ordering => {
    const { paramsByRiskLevel } = this.props;
    if (ordering) this.getData({ ...paramsByRiskLevel, ordering });
  };

  handleSearch = ({ target: { value } }) => this.doSearch(value);

  doSearch(search) {
    const { paramsByRiskLevel } = this.props;
    this.getData({ ...paramsByRiskLevel, search });
  }

  getData(params) {
    const { accountProvider } = this.props;
    this.setState({ loading: true });
    accountProvider.es.listByRiskLevel(params).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { activeTabId, loading } = this.state;

    const { accountsByRiskLevel, paramsByRiskLevel } = this.props;
    const accounts = accountsByRiskLevel[activeTabId];

    const tabs = Object.values(levels).map(level => ({
      id: level,
      name: `Accounts in ${capFirstChar(level)} ${
        !loading && level in accountsByRiskLevel
          ? `Risk (${accountsByRiskLevel[level].length})`
          : ''
      }`
    }));

    return (
      <div style={{ marginLeft: 205 }} className="mt-3">
        <div className="container">
          <div style={{ width: '23%' }}>
            <div className="form-group search">
              <div>
                <input
                  type="search"
                  className="form-control"
                  onChange={this.handleSearch}
                  placeholder="Search by Client or Account"
                />
                <span className="icon-search_icn" />
              </div>
            </div>
          </div>
          <div style={{ width: '100%', position: 'relative', display: 'inline-block' }}>
            <ul className="tabs">
              {tabs &&
                tabs.map(tab => (
                  <li key={tab.id} className={activeTabId === tab.id ? 'active' : ''}>
                    <button
                      type="button"
                      onClick={() => this.onTab(tab)}
                      className="btn-transparent"
                    >
                      {tab.name}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
          {loading && <SpinnerLoader spinnerLoading={loading} />}
          {!loading && _.isEmpty(accounts) && (
            <div className="empty">There are not accounts matching.</div>
          )}
          {!loading && !_.isEmpty(accounts) && (
            <LinkedAccountTable
              sortBy={this.sortBy}
              pagination={null}
              ordering={paramsByRiskLevel.ordering}
              accounts={accounts}
            />
          )}
        </div>
      </div>
    );
  }
}

AccountsByRiskLevel.defaultProps = {
  accountsByRiskLevel: {}
};

AccountsByRiskLevel.propTypes = {
  routerActions: PropTypes.object.isRequired,
  accountProvider: PropTypes.object.isRequired,
  paramsByRiskLevel: PropTypes.object.isRequired,
  accountsByRiskLevel: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.object
  }).isRequired
};

export default compose(
  withSubscription({
    plan: 'business',
    id: 'analytics',
    name: 'Analytics & Stats'
  }),
  connect(
    state => ({
      accountsByRiskLevel: state.accounts.byRiskLevel,
      paramsByRiskLevel: state.accounts.byRiskLevelParams
    }),
    dispatch => ({
      routerActions: bindActionCreators(routerActions, dispatch),
      accountProvider: new AccountProvider({ dispatch })
    })
  )
)(AccountsByRiskLevel);
