import { FETCHING, PRICE_LIST, PRICE_SET } from 'constants/actions';
import DataProvider from 'providers/data';
import config from '../config';

// kept in case it's needed again
export const FREEMIUM_PLAN = {
  id: 'starter',
  label: 'freemium',
  name: 'Starter',
  description: 'Risk analysis for portfolios and prospects',
  amount: 0,
  trial_days: 0,
  upgrade_allowed: false,
  metadata: {
    amount_info: 'Free Forever',
    interval: null,
    recommended: '1'
  }
};

class PriceProvider extends DataProvider {
  list() {
    this.dispatch(FETCHING);
    return this.provider.get(`${config.apiBase}billing/prices/`).then(({ data, error }) => {
      // kept in case it's needed again
      // if (!error) this.dispatch(PRICE_LIST, { data: [FREEMIUM_PLAN, ...data] });
      if (!error) this.dispatch(PRICE_LIST, { data });
      return data;
    });
  }

  setPrice(price) {
    this.dispatch(PRICE_SET, { data: price });
  }
}

export default PriceProvider;
