import EditBoxText from 'components/advisor/utils/edit-box-text';
import UpsideDownsideChart, { THEME } from 'components/charts/upside-downside-chart';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';

const ProposalUpDownCaptureComparison = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  setMetadata
}) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const market = {
    up: company.market_upside_performance,
    down: company.market_downside_performance,
    name: proposal.benchmark_label
  };
  const target = {
    upside: proposal.target.prism_score_summary.upside_capture_ratio / 10,
    downside: proposal.target.prism_score_summary.downside_capture_ratio / 10,
    name: proposal.target_label
  };
  const recommended = proposal.recommended
    ? {
        upside: proposal.recommended.prism_score_summary.upside_capture_ratio / 10,
        downside: proposal.recommended.prism_score_summary.downside_capture_ratio / 10,
        name: proposal.recommended_label
      }
    : null;

  return (
    <EditBoxText {...proposalMetadata}>
      <div className="description">
        <p>
          If the market was down {market.down}%, 90% of the time <b>{target.name}</b> performance
          may be -{Math.round(target.downside * market.down * 10)}%
          {recommended && (
            <>
              {' '}
              and <b>{recommended.name}</b> performance may be -
              {Math.round(recommended.downside * market.down * 10)}%
            </>
          )}
        </p>
        <p>
          If the market was up {market.up}%, 90% of the time <b>{target.name}</b> performance may be{' '}
          {Math.round(target.upside * market.up * 10)}%
          {recommended && (
            <>
              {' '}
              and <b>{recommended.name}</b> performance may be{' '}
              {Math.round(recommended.upside * market.up * 10)}%
            </>
          )}
        </p>
      </div>
      <div className="separator">
        <div className="label">Next 12 Months</div>
        <hr />
      </div>
      <div className="box-container">
        <UpsideDownsideChart
          target={target}
          targetName={proposal.target_label}
          recommended={recommended}
          recommendedName={proposal.recommended_label}
          theme={THEME.proposal}
          scale={390}
          withLegend
        />
      </div>
    </EditBoxText>
  );
};

ProposalUpDownCaptureComparison.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalUpDownCaptureComparison;
