// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#InvestorAccountList .help-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
#InvestorAccountList .text-help {
  font-size: 14px;
  color: #394346;
}

#InvestorAccountListRow .add-account {
  font-weight: 300;
  margin-top: -20px;
}
#InvestorAccountListRow .custom-line-height {
  line-height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/investors-account-list/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;AAFJ;AAIE;EACE,eAAA;EACA,cAAA;AAFJ;;AAOE;EACE,gBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,iBAAA;AALJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#InvestorAccountList {\n  .help-box {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: nowrap;\n  }\n  .text-help {\n    font-size: 14px;\n    color: #394346;\n  }\n}\n\n#InvestorAccountListRow {\n  .add-account {\n    font-weight: 300;\n    margin-top: -20px;\n  }\n\n  .custom-line-height {\n    line-height: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
