/* eslint-disable no-underscore-dangle */
import { Image } from '@react-pdf/renderer';
import React from 'react';
import { LANDSCAPE_ORIENTATION, PORTRAIT_ORIENTATION } from 'reports/constants';
import { BASE_PAGE_PADDING } from '../../../styles';

const A4_PAPER_WIDTH = 595;
const A4_PAPER_HEIGHT = 842;

const PORTRAIT_PAGE_CONTENT_WIDTH = A4_PAPER_WIDTH - BASE_PAGE_PADDING.padding * 2;
const LANDSCAPE_PAGE_CONTENT_WIDTH = A4_PAPER_HEIGHT - BASE_PAGE_PADDING.padding * 2;
const LANDSCAPE_MAX_IMAGE_HEIGHT = 435;

const PAGE_WIDTH = {
  [PORTRAIT_ORIENTATION]: PORTRAIT_PAGE_CONTENT_WIDTH,
  [LANDSCAPE_ORIENTATION]: LANDSCAPE_PAGE_CONTENT_WIDTH
};

const PIXEL_TO_POINT_RATIO = 12 / 16;

const normalizeImageStyle = (orientation, style) => {
  const normalizedStyle = style.reduce((acc, value) => ({ ...acc, ...value }), {});
  const width = Number.parseFloat(normalizedStyle?.width || 0) * PIXEL_TO_POINT_RATIO;
  const height = Number.parseFloat(normalizedStyle?.height || 0) * PIXEL_TO_POINT_RATIO;

  if (width > PAGE_WIDTH[orientation]) {
    normalizedStyle.height = (height / width) * PAGE_WIDTH[orientation];
    normalizedStyle.width = PAGE_WIDTH[orientation];

    if (
      orientation === LANDSCAPE_ORIENTATION &&
      normalizedStyle.height > LANDSCAPE_MAX_IMAGE_HEIGHT
    )
      normalizedStyle.height = LANDSCAPE_MAX_IMAGE_HEIGHT;
  }

  return normalizedStyle;
};

const renderer = orientation => {
  const parser = ({ element, style }) => {
    const { src } = element._rawAttrs;
    const normalizedStyle = normalizeImageStyle(orientation, style);
    return <Image src={src} style={[normalizedStyle, { objectFit: 'contain' }]} />;
  };
  return parser;
};

export default renderer;
