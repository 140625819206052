import { useStripe } from '@stripe/react-stripe-js';
import classnames from 'classnames';
import { Modal, ModalBody } from 'components/modal';
import SimpleSpinner from 'components/utils/simple-spinner';
import StripeElementsWrapper from 'components/utils/stripe-elements';
import Payment from 'containers/advisor/checkout/payment/form';
import PropTypes from 'prop-types';
import CardProvider from 'providers/cards';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { sleep } from 'utils/utils';
import './styles.scss';

export const AddCardModal = ({ cardProvider }) => {
  const [error, setError] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({ promise: null });

  const stripe = useStripe();

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const getPaymentMethodPromise = promise => {
    setPaymentMethod({ promise });
  };

  const onStripeError = (message = null) => {
    if (message) setError({ message });
    setProcessing(false);
  };

  const savePaymentMethod = async () => {
    setError(null);
    setProcessing(true);

    if (!paymentMethod.promise) {
      onStripeError('There was an error processing your payment method details. Please try again.');
      return;
    }

    // 1. Create the payment method
    const {
      paymentMethod: paymentMethodResponse,
      error: paymentMethodError
    } = await paymentMethod.promise();
    if (paymentMethodError) onStripeError(paymentMethodError.message);

    // 2. Get the setup intent secret identifier
    const { data: setupIntent } = await cardProvider.setupIntent();
    const setupIntentClientSecret = setupIntent?.['client_secret'];
    if (!setupIntentClientSecret) {
      onStripeError('There was an error processing your payment method details. Please try again.');
      return;
    }

    // 3. Setup the payment method
    const { error: confirmIntentError } = await stripe.confirmCardSetup(setupIntentClientSecret, {
      payment_method: paymentMethodResponse.id
    });
    if (confirmIntentError) {
      onStripeError(confirmIntentError.message);
      return;
    }

    // 4. Finish process
    await sleep(5);
    await cardProvider.list();
    toast.success('Credit card saved');
    setProcessing(false);
    hide();
  };

  return (
    <>
      <button type="button" className="btn btn-outline-primary btn-add-card" onClick={show}>
        Add a new card
      </button>
      <Modal
        id="AddCardModal"
        className="modal-lg"
        show={isShown}
        onHidden={hide}
        backdrop="static"
      >
        <ModalBody>
          <h1>Enter a new credit card</h1>
          <Payment getPaymentMethodPromise={getPaymentMethodPromise} />
          <div className="add-card-error-message">
            <p>{error && error.message}</p>
          </div>
          <div className="actions">
            <button type="button" className="btn btn-secondary" onClick={hide}>
              Cancel
            </button>
            <button
              type="button"
              className={classnames('btn', 'btn-primary', { 'btn-processing': processing })}
              onClick={savePaymentMethod}
              disabled={!paymentMethod.promise || processing}
            >
              {processing ? (
                <>
                  Saving... <SimpleSpinner light />
                </>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

AddCardModal.propTypes = {
  cardProvider: PropTypes.object.isRequired
};

const AddCardModalWithStripeElements = props => (
  <StripeElementsWrapper>
    <AddCardModal {...props} />
  </StripeElementsWrapper>
);

export default connect(
  null,
  dispatch => ({
    cardProvider: new CardProvider({ dispatch })
  })
)(AddCardModalWithStripeElements);
