import { Modal, ModalBody } from 'components/modal';
import SimpleSpinner from 'components/utils/simple-spinner';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withCurrencyFormat } from 'utils/utils';
import './styles.scss';

const priceSummary = price =>
  `${price.name} ${withCurrencyFormat(price.amount)}/${price.recurring?.interval ??
    price.interval}`;

export const UpdatePricePreview = ({
  currentPrice,
  targetPrice,
  invoicePreview,
  onConfirm,
  processing
}) => {
  const total = invoicePreview.items.reduce((acum, item) => acum + item.amount, 0);
  return (
    <div id="UpdatePricePreview">
      <h3>Change Plan</h3>
      <div className="summary">
        <div>Current Plan</div>
        <div>{priceSummary(currentPrice)}</div>
        <div>New Plan</div>
        <div>{priceSummary(targetPrice)}</div>
      </div>
      <div className="items">
        {invoicePreview.items.map(item => (
          <Fragment key={item.description}>
            <div>{item.description}</div>
            <div>{withCurrencyFormat(item.amount)}</div>
          </Fragment>
        ))}
      </div>
      <div className="total">Total {withCurrencyFormat(total)}</div>
      <div className="actions">
        <button
          type="button"
          className="btn btn-secondary-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          aria-label="Confirm"
          onClick={() => onConfirm(targetPrice)}
          disabled={processing}
        >
          {processing ? (
            <>
              Processing... <SimpleSpinner light />
            </>
          ) : (
            'Confirm'
          )}
        </button>
      </div>
    </div>
  );
};

UpdatePricePreview.propTypes = {
  currentPrice: PropTypes.object.isRequired,
  invoicePreview: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  targetPrice: PropTypes.object.isRequired
};

const UpdatePricePreviewModal = ({ content, onConfirm, onCancel, processing, show }) => (
  <Modal id="UpdatePricePreviewModal" className="modal-lg" show={show} onHide={onCancel}>
    <ModalBody>
      <UpdatePricePreview {...content} onConfirm={onConfirm} processing={processing} />
    </ModalBody>
  </Modal>
);

UpdatePricePreviewModal.propTypes = {
  content: PropTypes.shape(UpdatePricePreview.propTypes).isRequired,
  onConfirm: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  show: PropTypes.bool,
  onCancel: PropTypes.func
};

UpdatePricePreviewModal.defaultProps = {
  processing: false,
  show: false,
  onCancel: () => {}
};

export default UpdatePricePreviewModal;
