import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import ScoreBubble, { getInvestorId } from 'components/advisor/utils/score-bubble';
import { selectTargetScore } from 'utils/utils';

const ToleranceScoreBubble = (
  { element, allowNew, className, investor, onGenerate, scoreName, isProspect },
  { isProspectSection }
) => {
  const score = selectTargetScore(element.target_score_summary, scoreName);
  const investorId = !_.isEmpty(investor) ? investor.id : getInvestorId(element);
  const emailSent =
    (!_.isEmpty(investor) ? investor.target_questionnaire_mail_sent : false) ||
    element.target_questionnaire_mail_sent;

  if (score) {
    const mode = isProspectSection || isProspect ? 'prospects' : 'investors';
    let url;
    if (investorId) url = `/advisor/${mode}/${investorId}/account/${element.id}/risk-tolerance`;
    const bubble = (
      <ScoreBubble
        score={score}
        url={url}
        className={classnames('tolerance-bubble', className, {
          '-manual': element.target_score_manual
        })}
      />
    );

    if (element.target_score_manual)
      return (
        <span data-tip="Risk Tolerance manually entered" data-for="manual-target-score">
          {bubble}
          <ReactTooltip
            id="manual-target-score"
            className="manual-score-tutorial-tip"
            effect="solid"
            place="top"
          />
        </span>
      );

    return bubble;
  }

  if (!onGenerate) return null;

  if (emailSent)
    return (
      <span
        className="target-email-sent"
        data-for="email-sent"
        data-tip="Waiting for the client to submit the answers. Click the button to resend."
        onClick={e => onGenerate({ ...element, investor }, e)}
        onKeyUp={() => {}}
        role="button"
        tabIndex="0"
      >
        <img src="/img/pending_button.svg" alt="email sent" />
        <ReactTooltip id="email-sent" className="tutorial-tip" effect="solid" place="right" />
      </span>
    );

  if (localStorage.getItem(`investor-target-${investorId}`) || allowNew)
    return (
      <button
        className="btn table-btn get-target-score-btn btn-solid"
        onClick={e => onGenerate({ ...element, investor }, e)}
        type="button"
      >
        +
      </button>
    );

  return null;
};

ToleranceScoreBubble.defaultProps = {
  allowNew: true,
  className: '',
  investor: {},
  isProspect: false,
  onGenerate: null,
  scoreName: ''
};
ToleranceScoreBubble.propTypes = {
  allowNew: PropTypes.bool,
  className: PropTypes.string,
  element: PropTypes.object.isRequired,
  investor: PropTypes.object,
  isProspect: PropTypes.bool,
  onGenerate: PropTypes.func,
  scoreName: PropTypes.string
};
export default ToleranceScoreBubble;
