import cn from 'classnames';
import BillingPeriodSwitch from 'components/plans/billing-period-switch';
import Plan from 'components/plans/plan';
import PlansFeatures from 'containers/advisor/plans/plans-features';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const BILLING_INTERVALS = [
  {
    id: 'year',
    text: 'Pay Yearly',
    highlight: 'Save 25%'
  },
  {
    id: 'month',
    text: 'Pay Monthly'
  }
];

const Plans = ({ onSelectPrice, plans, upgrading }) => {
  const availablePeriods = new Set(plans.map(p => p.recurring?.interval));
  const defaultPeriod =
    availablePeriods.size === 1 ? availablePeriods.keys().next().value : BILLING_INTERVALS[0].id;
  const [period, setPeriod] = useState(defaultPeriod);
  return (
    <div id="Plans" className={cn({ upgrading })}>
      {!upgrading && (
        <>
          <h1>Select Your Plan</h1>
          <p>Pay upfront to save 25% on your annual subscription and get a better plan!</p>
        </>
      )}
      {availablePeriods.size > 1 && (
        <BillingPeriodSwitch intervals={BILLING_INTERVALS} current={period} onChange={setPeriod} />
      )}
      <div className="plans-list">
        {plans
          .filter(plan => !plan.recurring?.interval || plan.recurring?.interval === period)
          .map(plan => (
            <Plan {...plan} key={plan.id} onSelectPrice={onSelectPrice} upgrading={upgrading} />
          ))}
      </div>
      {!upgrading && <PlansFeatures />}
    </div>
  );
};

Plans.defaultProps = {
  upgrading: false
};

Plans.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectPrice: PropTypes.func.isRequired,
  upgrading: PropTypes.bool
};

export default Plans;
