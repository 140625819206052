import React from 'react';
import FilterContainer from '../common/filter-container';
import SingleSelectFilter from '../common/single-select';
import { EXCEPTION_AGE_RANGE_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const VIEW_ALL = { label: 'View All', value: [] };

const options = [
  VIEW_ALL,
  { label: 'Older than 120', value: [120] },
  { label: 'Between 90 - 120 days old', value: [90, 120] },
  { label: 'Between 60 - 90 days old', value: [60, 90] },
  { label: 'Between 30 - 60 days old', value: [30, 60] },
  { label: 'Less than 30 days old', value: [0, 30] }
];

const ExceptionAgeFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const getDefaultValue = () => {
    if (!filters[EXCEPTION_AGE_RANGE_ATTR]) return VIEW_ALL;
    const currentOption = filters[EXCEPTION_AGE_RANGE_ATTR];
    return options.find(option => option.value.join('__') === currentOption);
  };

  const defaultValue = getDefaultValue();

  const onChangeHandler = ({ value }) => {
    const useDefault = JSON.stringify(value) === JSON.stringify(VIEW_ALL.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [EXCEPTION_AGE_RANGE_ATTR]: useDefault ? undefined : value.join('__')
    }));
    setRequiresPageIndexReset(true);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_AGE_RANGE_ATTR]} filters={filters} label="Age">
      <SingleSelectFilter
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        options={options}
      />
    </FilterContainer>
  );
};

ExceptionAgeFilter.propTypes = { ...filterPropTypes };

ExceptionAgeFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionAgeFilter;
