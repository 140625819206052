import {
  INTEGRATION_CLIENT_ASSIGNMENT,
  INTEGRATION_COUNT_UNASSIGNED_ACCOUNTS,
  INTEGRATION_GET_CONNECTED,
  INTEGRATION_LIST_PROVIDERS,
  INTEGRATION_LIST_SYNCED_ACCOUNTS
} from 'constants/actions/integrations';

export const initialState = {
  connected: [],
  meta: {},
  providers: [],
  syncedAccounts: [],
  unassignedAccountsCount: 0
};

export default function integrations(state = initialState, action) {
  switch (action.type) {
    case INTEGRATION_CLIENT_ASSIGNMENT:
      return {
        ...state,
        syncedAccounts: Object.assign(
          state.syncedAccounts,
          state.syncedAccounts.map(el =>
            el.pk === action.data.id ? { ...el, account: action.data.account } : el
          )
        )
      };

    case INTEGRATION_COUNT_UNASSIGNED_ACCOUNTS:
      return { ...state, unassignedAccountsCount: action.count };

    case INTEGRATION_GET_CONNECTED:
      return { ...state, connected: action.data };

    case INTEGRATION_LIST_PROVIDERS:
      return { ...state, providers: action.results };

    case INTEGRATION_LIST_SYNCED_ACCOUNTS:
      return { ...state, syncedAccounts: action.results, meta: action.meta };

    default:
      return state;
  }
}
