import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.scss';

const ExpandableButton = ({ buttonLabel, onClick, isCollapsed }) => (
  <div className="expandable-button">
    <button
      type="button"
      className={cn('btn', 'btn-outline-dark', {
        collapsed: isCollapsed
      })}
      onClick={onClick}
    >
      {buttonLabel}
      <span className="fs-icon-angle-down arrow-icon" />
    </button>
  </div>
);

ExpandableButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ExpandableButton;
