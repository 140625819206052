import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PoweredBy = ({ imgUrl }) => (
  <span className="powered-by-stratifi">
    <FormattedMessage id="disclosure.powered-by" />
    <img src={imgUrl} alt="StratiFi" />
  </span>
);

PoweredBy.propTypes = {
  imgUrl: PropTypes.string.isRequired
};

export default PoweredBy;
