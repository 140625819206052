import { Text, View } from '@react-pdf/renderer';
import PortfolioComparisonChart from 'components/charts/portfolio-comparison-chart/pdf';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import { formatMoney, formatPercentage } from 'utils/utils';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import styles from './styles';

const ProposalSummary = ({ breakSection, metadata, proposal }) => {
  const { body, title } = metadata;
  const isDraft = proposal?.target?.target_label;

  const hasRecommended = !_.isEmpty(proposal.recommended_analytics);

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <ExtraDataSection isDraft={isDraft} proposal={proposal} withManagementFee />
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={[styles.cell, styles.headerFirstCell]} />
          <View style={[styles.cell, styles.headerCell]}>
            <Text style={styles.cellText}>Starting Value</Text>
          </View>
          <View style={[styles.cell, styles.targetHeaderCell]}>
            <Text style={styles.cellText}>{proposal.target_label || 'Current Portfolio'}</Text>
          </View>
          {hasRecommended && (
            <>
              <View style={[styles.cell, styles.recommendedHeaderCell]}>
                <Text style={styles.cellText}>{proposal.recommended_label}</Text>
              </View>
              <View style={[styles.cell, styles.headerCell, { marginRight: 0 }]}>
                <Text style={styles.cellText}>
                  {proposal.target_label || 'Current'} vs {proposal.recommended_label}
                </Text>
              </View>
            </>
          )}
        </View>

        <View style={[styles.row, styles.contentRow]}>
          <View style={[styles.cell, styles.contentFirstCell]}>
            <Text style={styles.cellText}>Total Value</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.cellText}>{formatMoney(proposal.starting_value)}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.cellText}>
              {formatMoney(proposal.target_analytics.cumulative_value)}
            </Text>
          </View>
          {hasRecommended && (
            <>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatMoney(proposal.recommended_analytics.cumulative_value)}
                </Text>
              </View>
              <View style={[styles.cell, { marginRight: 0 }]}>
                <PortfolioComparisonChart
                  isDraft={isDraft}
                  initial={proposal.starting_value}
                  target={parseInt(proposal.target_analytics.cumulative_value, 10)}
                  recommended={parseInt(proposal.recommended_analytics.cumulative_value, 10)}
                  withMoneyFormat
                />
              </View>
            </>
          )}
        </View>

        <View style={[styles.row, styles.contentRow]}>
          <View style={[styles.cell, styles.contentFirstCell]}>
            <Text style={styles.cellText}>Annualized Portfolio Return</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.cellText}>-</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.cellText}>
              {formatPercentage(proposal.target_analytics.summary.annualized_return)}
            </Text>
          </View>
          {hasRecommended && (
            <>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatPercentage(proposal.recommended_analytics.summary.annualized_return)}
                </Text>
              </View>
              <View style={[styles.cell, { marginRight: 0 }]}>
                <PortfolioComparisonChart
                  isDraft={isDraft}
                  target={proposal.target_analytics.summary.annualized_return}
                  recommended={proposal.recommended_analytics.summary.annualized_return}
                />
              </View>
            </>
          )}
        </View>

        <View style={[styles.row, styles.contentRow]}>
          <View style={[styles.cell, styles.contentFirstCell]}>
            <Text style={styles.cellText}>Annualized Portfolio Risk</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.cellText}>-</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.cellText}>
              {formatPercentage(proposal.target_analytics.summary.annualized_volatility)}
            </Text>
          </View>
          {hasRecommended && (
            <>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatPercentage(proposal.recommended_analytics.summary.annualized_volatility)}
                </Text>
              </View>
              <View style={[styles.cell, { marginRight: 0 }]}>
                <PortfolioComparisonChart
                  isDraft={isDraft}
                  target={proposal.target_analytics.summary.annualized_volatility}
                  recommended={proposal.recommended_analytics.summary.annualized_volatility}
                  reverse
                />
              </View>
            </>
          )}
        </View>
      </View>
    </BaseSection>
  );
};

ProposalSummary.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalSummary.defaultProps = {
  breakSection: false
};

export default ProposalSummary;
