import PropTypes from 'prop-types';
import React from 'react';

const ExceptionStatusCell = ({ exception, setCurrentException }) => {
  const onSetCurrentExceptionHandler = () => {
    setCurrentException(exception);
  };
  return (
    <button
      type="button"
      className="btn btn-link p-0"
      onClick={onSetCurrentExceptionHandler}
      style={{ fontSize: '0.9rem' }}
    >
      {exception.status_name}
    </button>
  );
};

ExceptionStatusCell.propTypes = {
  exception: PropTypes.object.isRequired,
  setCurrentException: PropTypes.func.isRequired
};

export default ExceptionStatusCell;
