import _ from 'lodash';
import moment from 'moment';
import { withCurrencyFormat } from 'utils/utils';

export const GENERIC_QUESTIONNAIRE_ID = 'rtq-generic';

export const INTERPOLATION_ANSWER_REGEX = /<answer\.([\w-]+)\.([\w-]+)\.?([\d]+)?>/g;
export const INTERPOLATION_REF_REGEX = /<ref\.(.+?)>/g;

// remove html tags, except <br /> that are replaced by a space
export const stripTags = text =>
  String(text)
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/<[^>]*>?/gm, '');

export const interpolateRef = (text, refs) => {
  let result = text;
  if (result)
    Array.from(result.matchAll(INTERPOLATION_REF_REGEX)).forEach(match => {
      const ref = match[1];
      const value = refs?.[ref];
      if (value === undefined) return;
      result = result.replace(match[0], value);
    });

  return result;
};

export const interpolateQuestionText = (text, answers, format = '') => {
  let result = text;
  if (result)
    Array.from(result.matchAll(INTERPOLATION_ANSWER_REGEX)).forEach(match => {
      const slug = match[1];
      const attr = match[2];
      const percentage = Number.parseInt(match[3], 10);

      const position = Number.parseInt(slug, 10);
      const key = position ? `question-${position}` : slug;

      let answer = answers?.[key]?.[attr];
      if (answer === undefined) return;

      if (_.isNumber(answer) && _.isNumber(percentage) && !_.isNaN(percentage))
        answer = Math.round((answer * percentage) / 100);

      if (format === 'currency')
        answer = withCurrencyFormat(answer, 'compact', 0).replace('M', 'MM');

      result = result.replace(match[0], answer);
    });

  return result;
};

export const interpolateQuestions = (questions, answers) =>
  questions.map(question => {
    const q = _.cloneDeep(question);
    q.question = interpolateQuestionText(q.question, answers);
    q.description = interpolateQuestionText(q.description, answers);

    if (q?.data?.labels)
      q.data.labels = q.data.labels.map(l => ({
        ...l,
        text: interpolateQuestionText(l.text, answers, l.format)
      }));

    if (q?.data?.refs)
      q.data.refs = Object.entries(q.data.refs).reduce(
        (acum, [k, v]) => ({ ...acum, [k]: interpolateQuestionText(v, answers) }),
        {}
      );

    return q;
  });

export const getQuestionnaireId = (investorId, accountId) => {
  if (investorId && accountId) return `rtq-${investorId}-${accountId}`;
  if (investorId) return `rtq-${investorId}`;
  return GENERIC_QUESTIONNAIRE_ID;
};

export const selectQuestionnaire = (questionnaires, investorId, accountId) => {
  const qId = getQuestionnaireId(investorId, accountId);
  return questionnaires[qId] || [];
};

export const getQuestionnaireIdFromTemplate = (templateId, investorId, accountId) => {
  if (investorId && accountId) return `rtq[${templateId}]:${investorId}-${accountId}`;
  if (investorId) return `rtq[${templateId}]:${investorId}`;
  return `rtq[${templateId}]`;
};

export const selectQuestionnaireFromTemplate = (
  questionnaires,
  templateId,
  investorId,
  accountId
) => {
  const qId = getQuestionnaireIdFromTemplate(templateId, investorId, accountId);
  return questionnaires[qId] || [];
};

export const returnToAmount = (returnPercentage, years, initialInvestment) =>
  Math.exp((returnPercentage * years) / 100) * initialInvestment;

export const amountToReturn = (goalAmount, years, initialInvestment, decimals = 0) => {
  // initialInvestment * yearlyReturn ^ years = goalAmount
  const totalReturn = goalAmount / initialInvestment;
  const yearlyReturn = 10 ** (Math.log10(totalReturn) / years);
  const decimalsMultiplier = 10 ** decimals;
  return Math.ceil((yearlyReturn - 1) * 100 * decimalsMultiplier) / decimalsMultiplier;
};

export const choicesToOptions = choices =>
  choices
    ? choices
        .sort((a, b) => (a.position || a.value) - (b.position || b.value))
        .map(c => ({
          description: c.description,
          id: c.value,
          image: c.image,
          label: c.display_text ?? c.text ?? c.value,
          value: c.value
        }))
    : [];

export const ageCalculator = (birthday, years = 0) =>
  (moment().diff(birthday, 'years') || 0) + years;

export const roundThousands = val => Math.round(val / 1000) * 1000;
