import Pagination from 'components/pagination';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import ChargeProvider from 'providers/charges';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { capFirstChar, withCurrencyFormat } from 'utils/utils';
import './styles.scss';

const BillingHistoryItem = ({ amount, created, payment_method: { card } }) => (
  <div className="billing-history-container__item">
    <div className="billing-history-container__item__date">{moment(created).format('LL')}</div>
    <div className="billing-history-container__item__description">
      {capFirstChar(card.brand)} Credit Card ending in {card.last4}
    </div>
    <div className="billing-history-container__item__amount text-right">
      {withCurrencyFormat(amount)}
    </div>
  </div>
);

BillingHistoryItem.propTypes = {
  amount: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  payment_method: PropTypes.shape({
    card: PropTypes.object.isRequired
  }).isRequired
};

export const BillingHistory = ({ chargeProvider, charges }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [prevPage, setPrevPage] = useState(false);
  const [nextPage, setNextPage] = useState(false);

  const handleCurrentPageChange = (pageNumber, event) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    chargeProvider.list(currentPage).then(data => {
      setTotalPages(data.total_pages);
      setPrevPage(!!data.previous);
      setNextPage(!!data.next);
    });
  }, [currentPage]);

  return (
    <div id="BillingHistory">
      {_.isEmpty(charges) && 'No charges have been made'}

      {!_.isEmpty(charges) && (
        <>
          <div className="billing-history-container">
            {charges.map(charge => (
              <BillingHistoryItem key={charge.id} {...charge} />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            hasPrevPage={prevPage}
            hasNextPage={nextPage}
            goToPage={handleCurrentPageChange}
          />
        </>
      )}
    </div>
  );
};

BillingHistory.propTypes = {
  chargeProvider: PropTypes.object.isRequired,
  charges: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(
  state => ({
    charges: state.charges.list
  }),
  dispatch => ({
    chargeProvider: new ChargeProvider({ dispatch })
  })
)(BillingHistory);
