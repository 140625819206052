import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const DeleteTemplateSectionModal = ({ label, onDelete, sectionKey, verb }) => {
  const [isShown, setIsShown] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const onSubmitHandler = async () => {
    setSubmitting(true);
    await onDelete(sectionKey);
    setSubmitting(false);
  };

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={show}>
        Delete
      </button>

      {ReactDOM.createPortal(
        <Modal id="delete-template-section" className="modal-lg" show={isShown} onHidden={hide}>
          <ModalHeader />
          <ModalBody>
            <h3 className="modal-title">
              {verb} {label}?
            </h3>
            <div className="description top">
              <div className="text">
                Are you sure you want to{' '}
                <span className="lowercase">
                  {verb} this {label}
                </span>
                ?
              </div>
              <div className="text text__warning padding">
                <span className="fs-icon-exclamation-circle warning-icon" />
                You can&apos;t revert this change.
              </div>
            </div>
            <div className="text-sm-center actions">
              <button type="button" className="btn cancel" onClick={hide}>
                Cancel
              </button>
              <LoadingButton
                className="btn btn-danger delete"
                loading={submitting}
                onClick={onSubmitHandler}
              >
                {verb}
              </LoadingButton>
            </div>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

DeleteTemplateSectionModal.defaultProps = {
  label: null
};

DeleteTemplateSectionModal.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  sectionKey: PropTypes.string.isRequired,
  verb: PropTypes.string.isRequired
};

export default DeleteTemplateSectionModal;
