import { AdvisorContext } from 'containers/advisor';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router';
import './styles.scss';

export const HIDE_MEEETING_CALENDAR_ALERT = 'hideMeeetingCalendarAlert';

const MeetingCalendarAlert = () => {
  const {
    user: {
      advisor: { meeting_calendar_link: meetingCalendarLink }
    }
  } = useContext(AdvisorContext);

  const [skip, setSkip] = useState(
    window.localStorage && window.localStorage.getItem(HIDE_MEEETING_CALENDAR_ALERT) === 'true'
  );

  const onSkip = () => {
    setSkip(prevSkip => {
      if (window.localStorage) window.localStorage.setItem(HIDE_MEEETING_CALENDAR_ALERT, !prevSkip);
      return !prevSkip;
    });
  };

  if (meetingCalendarLink || skip) return null;

  return (
    <div className="meeting-calendar-alert">
      <div className="meeting-calendar-alert__text">
        <span className="fs-icon-exclamation-circle warning-icon" />
        Streamline communication with clients by allowing them to book meetings directly in your
        preferred calendar.
      </div>
      <div className="meeting-calendar-alert__actions">
        <Link className="btn btn-primary" to="/advisor/me/profile#share-your-calendar">
          Connect my calendar now
        </Link>
        <button
          type="button"
          className="btn btn-link text-dark font-weight-normal"
          onClick={onSkip}
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default MeetingCalendarAlert;
