import React from 'react';
import FilterContainer from '../../common/filter-container';
import SingleSelectFilter from '../../common/single-select';
import { EXCEPTION_CREATED_RANGE_ATTR } from '../../constants';
import { filterDefaultProps, filterPropTypes } from '../../types';
import { VIEW_ALL, rangeOptions } from './utils';

const ExceptionCreatedFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const getDefaultValue = () => {
    if (!filters[EXCEPTION_CREATED_RANGE_ATTR]) return VIEW_ALL;
    const currentOption = filters[EXCEPTION_CREATED_RANGE_ATTR];
    return rangeOptions.find(option => option.value.join('__') === currentOption);
  };

  const defaultValue = getDefaultValue();

  const onChangeHandler = ({ value }) => {
    const useDefault = JSON.stringify(value) === JSON.stringify(VIEW_ALL.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [EXCEPTION_CREATED_RANGE_ATTR]: useDefault ? undefined : value.join('__')
    }));
    setRequiresPageIndexReset(true);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_CREATED_RANGE_ATTR]} filters={filters} label="Created">
      <SingleSelectFilter
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        options={rangeOptions}
      />
    </FilterContainer>
  );
};

ExceptionCreatedFilter.propTypes = { ...filterPropTypes };

ExceptionCreatedFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionCreatedFilter;
