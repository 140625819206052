import { Image, Text } from '@react-pdf/renderer';
import {
  ACCOUNT_TARGET_TYPE,
  INVESTOR_TARGET_TYPE
} from 'components/advisor/risk-analysis/constants';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from '../constants';
import styles from './styles';

const SecurityTypeConcentration = ({ portfolio, type }) => {
  const chart =
    type === ACCOUNT_TARGET_TYPE
      ? portfolio[`${SECURITY_TYPE_CONCENTRATION_CHART_ID}-${portfolio.id}`]
      : portfolio[SECURITY_TYPE_CONCENTRATION_CHART_ID];

  return (
    <BaseSection title="Security Type Concentration" wrap={false}>
      <Image src={chart} />
      {type === INVESTOR_TARGET_TYPE && (
        <Text style={styles.note}>
          This chart only considers positions from non-excluded accounts.
        </Text>
      )}
    </BaseSection>
  );
};

SecurityTypeConcentration.propTypes = {
  portfolio: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default SecurityTypeConcentration;
