// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Checkout .checkout-wrapper {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 30px;
}
#Checkout > .hero-form-container {
  margin: 30px auto 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/checkout/styles.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;AAFJ;AAKE;EACE,wBAAA;AAHJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#Checkout {\n  .checkout-wrapper {\n    max-width: 1140px;\n    margin: 0 auto;\n    margin-top: 30px;\n  }\n\n  & > .hero-form-container {\n    margin: 30px auto 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
