import DeleteHouseholdModal from 'components/advisor/households/delete-household-modal';
import HouseholdsInvestorList from 'components/advisor/households/households-investor-list';
import { HouseholdHeader } from 'components/advisor/households/households-header';
import SpinnerLoader from 'components/performance-spinner';
import withSubscription from 'hocs/subscription-validation';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';

class Household extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      deleteHouseholdModal: {
        show: false,
        household: {}
      }
    };

    this.fetchHousehold = this.fetchHousehold.bind(this);
    this.renameHousehold = this.renameHousehold.bind(this);
  }

  componentDidMount() {
    this.fetchHousehold();
  }

  componentDidUpdate(prevProps) {
    const { prismScoresInProgress: prevPrismScoresInProgress } = prevProps;
    const { prismScoresInProgress } = this.props;

    if (prismScoresInProgress.length < prevPrismScoresInProgress.length) this.fetchHousehold();
  }

  componentWillUnmount() {
    const { setupTargetScoreWizard } = this.context;
    setupTargetScoreWizard({});
  }

  fetchHousehold() {
    const {
      params: { id }
    } = this.props;

    const { householdProvider, setupTargetScoreWizard } = this.context;
    return householdProvider.get(id).then(({ data }) => {
      setupTargetScoreWizard({ investors: data.investors, refresh: this.fetchHousehold });
    });
  }

  showDeleteHouseholdModal = household => {
    this.setState({
      deleteHouseholdModal: {
        show: true,
        household
      }
    });
  };

  hideDeleteHouseholdModal = () => {
    this.setState({
      deleteHouseholdModal: {
        show: false,
        household: null
      }
    });
  };

  deleteHousehold = ({ id }) => {
    const { routerActions, householdProvider } = this.context;
    householdProvider.delete(id).then(() => {
      this.deleteModal.modal.hide();
      routerActions.push({ pathname: `/advisor/households` });
    });
  };

  renameHousehold = name => {
    const nameCheck = name.replace(/ /g, '');
    const { household } = this.props;
    const oldName = household.name;
    if (nameCheck !== '' && name !== oldName) {
      const { householdProvider } = this.context;
      const { household } = this.props;

      const householdId = household.id;

      const params = {
        name,
        id: householdId,
        action: 'rename'
      };

      return householdProvider.update(params).then(() => {
        householdProvider.get(householdId);
      });
    }
  };

  render() {
    const { householdProvider } = this.context;
    const { household, investors, householdSuggestedMembers } = this.props;

    const { currentScore, deleteHouseholdModal } = this.state;

    if (!household) return <SpinnerLoader spinnerLoading />;

    const numAccounts = household.investors.reduce(
      (acum, investor) => acum + investor.accounts.length,
      0
    );
    const prismOverall =
      household.aggregated_prism_scores && household.aggregated_prism_scores.overal
        ? household.aggregated_prism_scores.overall.toFixed(1)
        : null;

    return (
      <div className="household-investors">
        <HouseholdHeader
          householdProvider={householdProvider}
          household={household}
          renameHousehold={this.renameHousehold}
          showDeleteHouseholdModal={this.showDeleteHouseholdModal}
          currentScore={currentScore}
        />

        <HouseholdsInvestorList
          pagination={null}
          investorAccounts={household.investors}
          household={household}
          from="investor accounts"
          numAccounts={numAccounts}
          portfolioValue={household.portfolio_value}
          prismOverall={prismOverall}
          allInvestors={investors}
          sortBy={this.sortBy}
          householdSuggestedMembers={householdSuggestedMembers}
        />

        {deleteHouseholdModal.show && (
          <DeleteHouseholdModal
            {...deleteHouseholdModal}
            onHide={this.hideDeleteHouseholdModal}
            onDelete={this.deleteHousehold}
            ref={c => {
              this.deleteModal = c;
            }}
          />
        )}
      </div>
    );
  }
}

Household.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  setupTargetScoreWizard: PropTypes.func.isRequired
};

Household.defaultProps = {
  households: [],
  investors: []
};

Household.propTypes = {
  household: PropTypes.object.isRequired, // already fetched (see parent component)
  households: PropTypes.array,
  householdSuggestedMembers: PropTypes.array.isRequired,
  investors: PropTypes.array,
  investorStore: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default compose(
  withSubscription({
    plan: 'professional',
    id: 'households',
    name: 'Households'
  }),
  connect(state => ({
    household: state.households.view,
    householdsMeta: state.households.listMeta,
    householdStore: state.households,
    householdSuggestedMembers: state.households.suggestions,
    investors: state.investors.list || [],
    investorStore: state.investors,
    prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state),
    user: state.auth.user
  }))
)(Household);
