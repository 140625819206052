/* eslint-disable import/no-cycle */
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import { BaseCoverDataPropTypes } from 'reports/base/cover/types';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import RiskToleranceQuestionnaireEmptyContent from '../sections/risk-tolerance-questionnaire-empty-content';

const RiskToleranceQuestionnaireEmptyReport = ({
  advisor,
  coverData,
  orientation,
  questions,
  size,
  subtitle,
  title,
  user,
  withCustomBranding
}) => {
  const context = useMemo(
    () => ({ orientation, questions, user, withCustomBranding }),
    [JSON.stringify(questions), JSON.stringify(user), orientation, withCustomBranding]
  );

  return (
    <BaseDocument title={title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={advisor}
          data={coverData}
          orientation={orientation}
          size={size}
          subtitle={subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={advisor}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
        >
          <RiskToleranceQuestionnaireEmptyContent questions={questions} />

          {!!user?.advisor?.company?.report_disclosure && (
            <CustomSection
              breakSection
              title="Disclosure"
              body={user.advisor.company.report_disclosure}
            />
          )}
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

RiskToleranceQuestionnaireEmptyReport.propTypes = {
  advisor: PropTypes.object.isRequired,
  coverData: PropTypes.shape(BaseCoverDataPropTypes),
  orientation: PropTypes.string,
  questions: PropTypes.array.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

RiskToleranceQuestionnaireEmptyReport.defaultProps = {
  coverData: {},
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  title: 'Questionnaire',
  withCustomBranding: false
};

export default RiskToleranceQuestionnaireEmptyReport;
