import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Dropzone from 'components/dropzone';
import ProgressBar from 'components/progress-bar';
import './styles.scss';

const initialState = {
  file: null,
  fileUploaded: false,
  fileUploading: false,
  droppedFileName: null,
  progress: 0,
  currentTime: 0
};

const styles = {
  uploadingHeight: {
    height: 280
  },
  normalHeight: {
    height: 600
  }
};

export default class HouseholdsCSVModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    householdProvider: PropTypes.object.isRequired,
    onSubmitted: PropTypes.func.isRequired,
    updateHouseholds: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  cleanUpState = () => {
    this.setState(initialState);
  };

  onClose = () => {
    const { close } = this.props;
    close();
    this.cleanUpState();
  };

  downloadCSV = () => {
    const { householdProvider } = this.props;
    householdProvider.downloadCSV();
  };

  onDrop = files => {
    if (files.length)
      this.setState({ file: files[0], droppedFileName: files[0].name, fileUploaded: false });
    else toast.error('Invalid CSV file. Please fix them and try again.');
  };

  submit = () => {
    const { householdProvider, updateHouseholds, onSubmitted } = this.props;
    const { file, filename } = this.state;

    if (!file) return;

    const formData = new FormData();
    formData.append('file', file, filename);
    householdProvider.uploadCSV(formData).then(data => {
      this.setState({
        currentTime: 0,
        fileUploaded: true,
        progress: 100,
        withErrors: !!data.error
      });
      updateHouseholds();
    });
    this.setState({ fileUploading: true });

    onSubmitted(this.cleanUpState);
  };

  showNormal = () => {
    const { droppedFileName } = this.state;
    return (
      <ol>
        <li>
          <span>Download current information</span>
          <button type="button" onClick={this.downloadCSV}>
            Download CSV
          </button>
        </li>
        <li>
          Edit the CSV file
          <span className="caution">
            (Caution: Do not edit the ID column. Leave the ID empty for new Household.)
          </span>
        </li>
        <li>
          <span>Save & upload the file here</span>
          <div className="dropzone">
            <Dropzone onDrop={this.onDrop} accept="text/csv">
              {droppedFileName || 'Drag and drop or click here to upload file'}
            </Dropzone>
          </div>
        </li>
        <li>
          <button type="button" onClick={this.submit}>
            Submit
          </button>
        </li>
      </ol>
    );
  };

  showLoading = () => {
    const { fileUploaded, currentTime, progress, withErrors } = this.state;

    const resultMessage = withErrors
      ? 'There are some errors processing the csv, please check the file and try again.'
      : "Thank you! You have successfully updated your households' information";

    return (
      <div className="loading-tab">
        {fileUploaded ? (
          <div>{resultMessage}</div>
        ) : (
          <div>
            Updating households info... <br />
            <br />
            This might take a few seconds.
          </div>
        )}
        <div>
          <ProgressBar completed={progress} fillerColor="#92dcf8" />
        </div>
        <div className="text-sm-center actions">
          {fileUploaded && (
            <button
              className="btn btn-primary btn-success"
              onClick={this.onClose}
              type="button"
              tabIndex="0"
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { show } = this.props;
    const { fileUploading } = this.state;

    return show ? (
      <div className="modal-v2 success-household-modal">
        <div
          className="background"
          onClick={this.onClose}
          tabIndex="0"
          role="button"
          onKeyDown={() => {}}
        />
        <div
          className="popup popup-household-csv"
          style={fileUploading ? styles.uploadingHeight : styles.normalHeight}
        >
          <div className="header">
            <div className="title">Edit Household Information</div>
            <div
              className="img"
              onClick={this.onClose}
              tabIndex="0"
              role="button"
              onKeyDown={() => {}}
            >
              <img src="/img/x.png" alt="Close" />
            </div>
          </div>
          <div className="body">{fileUploading ? this.showLoading() : this.showNormal()}</div>
        </div>
      </div>
    ) : null;
  }
}
