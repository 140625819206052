/* eslint-disable react/no-unused-state, react/no-unused-class-component-methods */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { CSVModelMixin } from 'utils/mixins';

let delayTimer = null;

class GeneralDetails extends PureComponent {
  constructor(props) {
    super(props);
    CSVModelMixin.applyMixin(this);
    this.state = {
      basicProfileModal: false,
      showModal: false,
      householdSearch: '',
      householdId: null
    };
  }

  showModal = () => this.setState({ basicProfileModal: true });

  showAccountModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => this.setState({ basicProfileModal: false });

  hideEditModel() {
    this.setState({
      showModal: false
    });
    this.editModal.hide();
  }

  onHouseholdChange = e => {
    const { value } = e.target;
    const { householdProvider } = this.context;
    if (value === 0) {
      e.preventDefault();
      return;
    }

    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      householdProvider.list({ search: value });
    }, 200);
    this.setState({ householdSearch: value });
  };

  onHouseholdSelected = (_, { suggestion }) => {
    this.setState({ householdId: suggestion.id, householdSearch: suggestion.name });
  };
}

GeneralDetails.contextTypes = {
  config: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired
};

GeneralDetails.defaultProps = {
  investor: {}
};

GeneralDetails.propTypes = {
  investor: PropTypes.object,
  routerActions: PropTypes.object.isRequired,
  investorAccounts: PropTypes.array.isRequired,
  investorAccountsMeta: PropTypes.object.isRequired
};

export default GeneralDetails;
