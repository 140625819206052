import SectionLocked from 'components/advisor/section-locked';
import { SECTION_LOCKED_MAP, REGION_EXPOSURE } from 'components/advisor/section-locked/utils';
import CollapsibleTable from 'components/collapsible-table';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import MapChart from 'components/charts/world-chart';
import DisclaimerNote from '../common/disclaimer-note';
import { SECURITY_REGIONS, getSecurityDetails, processSecurityDetails } from '../common/utils';
import './styles.scss';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from './utils';

const GeographicExposure = ({ hiddenVal, id, onChartReady, portfolio, width }) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          region_exposure_enabled: regionExposureEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const { name, portfolioRiskId } = SECTION_LOCKED_MAP[REGION_EXPOSURE];

  if (!regionExposureEnabled)
    return (
      <div id="geographic-exposure">
        <SectionLocked id={portfolioRiskId} name={name} />
      </div>
    );

  const totalAssets = portfolio.value ?? 0;
  const securityRegions = getSecurityDetails(portfolio, SECURITY_REGIONS, totalAssets);

  const { data, total: totalSecurities } = processSecurityDetails(
    securityRegions,
    SECURITY_REGIONS,
    totalAssets
  );

  return (
    <div id="geographic-exposure">
      <div className="chart-container">
        <MapChart id={id} data={data.chart} onChartReady={onChartReady} width={width} />
      </div>
      <div className="collapsible-container">
        <CollapsibleTable
          bottomNameLabel="Total portfolio value"
          data={data.summary}
          expandAssetClassification={expandAssetDetails}
          headingLabels={{ name: '', value: 'Value', percent: '% of Portfolio' }}
          hiddenVal={hiddenVal}
          showBottomSection
          showHeadingRow
          showPercent
          expandAll
        />
        <DisclaimerNote totalAssets={totalAssets} totalSecurities={totalSecurities} />
      </div>
    </div>
  );
};

GeographicExposure.propTypes = {
  hiddenVal: PropTypes.bool,
  id: PropTypes.string,
  onChartReady: PropTypes.func,
  portfolio: PropTypes.object.isRequired,
  width: PropTypes.string
};

GeographicExposure.defaultProps = {
  hiddenVal: false,
  id: GEOGRAPHIC_EXPOSURE_CHART_ID,
  onChartReady: null,
  width: '100%'
};

export default GeographicExposure;
