import MultiCheckboxField from 'components/form/multi-checkbox-field';
import PropTypes from 'prop-types';
import React from 'react';
import { choicesToOptions } from 'utils/questionnaire';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import './styles.scss';

const ChoicesQuestion = ({ question, field, ...props }) => {
  const choices = choicesToOptions(question.data?.options);

  return (
    <Question {...props} question={question} nextDisabled={field.invalid}>
      <MultiCheckboxField
        field={field}
        options={choices}
        multiple={question.data?.multiple_answers_allowed}
        flavor={question.data?.flavor}
      />
    </Question>
  );
};

ChoicesQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ChoicesQuestion;
