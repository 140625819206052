import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';

const NumericQuestion = ({ question, field, ...props }) => (
  <Question {...props} nextDisabled={field.invalid} question={question}>
    <NumericFormat {...field} thousandSeparator />
    {field.touched && field.error && <span className="text-danger error">{field.error}</span>}
  </Question>
);

NumericQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default NumericQuestion;
