import { DROP_NOTIFICATIONS, LIST_NOTIFICATIONS } from 'constants/actions/notifications';
import _ from 'lodash';
import config from '../config';
import DataProvider from './data';

export default class NotificationsProvider extends DataProvider {
  list(params) {
    return this.provider.get(`${config.apiBase}notifications/`, params).then(response => {
      if (!response.error && response?.data?.results) {
        if (!_.isEmpty(response?.data?.results))
          this.dispatch(LIST_NOTIFICATIONS, { data: response.data.results });
        return response.data.results;
      }
      return Promise.reject(response);
    });
  }

  read(notificationIds) {
    return this.provider
      .post(`${config.apiBase}notifications/read/`, {
        notifications: notificationIds
      })
      .then(response => {
        if (response.error) return Promise.reject(response);
        this.dispatch(DROP_NOTIFICATIONS, { data: notificationIds });
        return notificationIds;
      });
  }
}
