/* eslint-disable react/no-unstable-nested-components */
import Link from 'components/utils/link';
import { SortableHeader } from 'components/utils/react-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import QuestionnaireTemplatePropTypes from './types';

const QuestionnaireTemplatesList = ({ templates }) => {
  const columns = [
    {
      columns: [
        {
          Header: <SortableHeader title="Name" />,
          accessor: 'name',
          className: 'td-align-left',
          headerClassName: 'th-align-left',
          minWidth: 50,
          Cell: ({ original: { name, id, is_default: isDefault } }) => (
            <>
              <Link to={`/advisor/templates/fact-finder-forms/${id}`}>{name}</Link>
              {isDefault && (
                <div
                  className="bubble-rtq"
                  data-template-status="default"
                  style={{ marginLeft: 5 }}
                >
                  <span>Default</span>
                </div>
              )}
            </>
          )
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Last updated by" />,
          accessor: 'last_updated_by',
          minWidth: 25,
          Cell: ({ value: lastUpdatedBy }) => lastUpdatedBy ?? 'StratiFi'
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Status" />,
          accessor: 'status',
          minWidth: 25,
          Cell: ({ original: { status } }) => (
            <div className="bubble-rtq-wrapper">
              <div className="bubble-rtq" data-template-status={status}>
                <span>{status}</span>
              </div>
            </div>
          )
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Last updated" />,
          accessor: 'modified',
          minWidth: 25,
          Cell: ({ value: modified }) => moment(modified).format('ll'),
          defaultSortDesc: true
        }
      ]
    }
  ];

  return (
    <div id="questionnaire-templates-list">
      <div className="header">
        <h4>Fact Finder Forms</h4>
      </div>

      <ReactTable
        className="templates-table"
        columns={columns}
        data={templates}
        defaultSorted={[{ id: 'modified', desc: true }]}
        minRows={0}
        noDataText="No Templates."
        resizable={false}
        showPagination={false}
      />
    </div>
  );
};

QuestionnaireTemplatesList.propTypes = {
  onCreate: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(QuestionnaireTemplatePropTypes).isRequired
};

export default QuestionnaireTemplatesList;
