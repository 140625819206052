import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { isNum } from 'utils/utils';

export const badgeSizes = {
  SMALL: 'small',
  LARGE: 'large'
};

const badgeSizeToWidth = {
  [badgeSizes.SMALL]: 73,
  [badgeSizes.LARGE]: 97
};

class ScoreLine extends Component {
  getBadgeStyles(score, offset) {
    const { badgeSize } = this.props;
    const offsets = { [badgeSizes.SMALL]: 12 - score, [badgeSizes.LARGE]: 24 - score };
    score = score < 5 ? score * 1.16 - 0.8 : score;
    return {
      left: `calc(${score * 10}% - ${badgeSizeToWidth[badgeSize] / 2 +
        (offset ? offsets[badgeSize] : 0)}px`
    };
  }

  render() {
    const {
      score,
      badgeSize,
      transparentScore,
      targetScore,
      manualScore,
      hideNumbers
    } = this.props;

    const cls = manualScore
      ? `target-score-badge score-badge-${badgeSize} score-badge-manual-risk-level-${Math.ceil(
          targetScore
        )}`
      : `target-score-badge score-badge-${badgeSize} score-badge-risk-level-${Math.ceil(
          targetScore
        )}`;
    return (
      <div className="score-line">
        <div className={`score-badge-container score-badge-container-${badgeSize}`}>
          <div
            className={`score-badge score-badge-risk-level-${Math.ceil(score)}`}
            style={this.getBadgeStyles(score)}
          >
            {score}
          </div>
          {isNum(transparentScore) && (
            <div
              className={`score-badge score-badge-transparent score-badge-${badgeSize} score-badge-risk-level-${Math.ceil(
                transparentScore
              )}`}
              style={this.getBadgeStyles(transparentScore)}
            >
              {transparentScore}
            </div>
          )}
        </div>
        <div className="score-line-spectrum" />
        {isNum(targetScore) ? (
          <div className="score-line-legend score-badge-container target-score-badge-container-small">
            <div className={hideNumbers ? 'none' : 'left'}>
              <strong>1</strong>
            </div>
            {manualScore ? (
              <div
                className={cls}
                style={this.getBadgeStyles(targetScore)}
                data-tip="Manually entered"
                data-for="target-score"
              >
                {targetScore}
                <div className="target-tooltip">Tolerance</div>
                <ReactTooltip
                  id="target-score"
                  className="manual-score-tutorial-tip widget"
                  effect="solid"
                  place="bottom"
                />
              </div>
            ) : (
              <div className={cls} style={this.getBadgeStyles(targetScore)}>
                {targetScore}
                <div className="target-tooltip">Tolerance</div>
              </div>
            )}
            <div className={hideNumbers ? 'none' : 'right'}>
              <strong>10</strong>{' '}
            </div>
          </div>
        ) : (
          <div className="score-line-legend">
            <div className="left">
              <strong>1</strong> (Low Risk)
            </div>
            <div className="right">
              (High Risk) <strong>10</strong>{' '}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ScoreLine.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeSize: PropTypes.string.isRequired,
  targetScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transparentScore: PropTypes.number,
  manualScore: PropTypes.bool,
  hideNumbers: PropTypes.bool
};

ScoreLine.defaultProps = {
  transparentScore: null,
  targetScore: null,
  score: undefined,
  manualScore: false,
  hideNumbers: false
};

export default ScoreLine;
