import cn from 'classnames';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes } from '../types';

const ProposalCustomSection = ({ actionsDisabled, metadata, propertyKey, setMetadata }) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  return (
    <EditBoxText
      {...proposalMetadata}
      className={cn(metadata.className, { customSection: metadata.custom })}
    />
  );
};

ProposalCustomSection.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalCustomSection;
