/* eslint-disable camelcase */
import { Text, View } from '@react-pdf/renderer';
import {
  questionFlavors as qf,
  questionSlugs as qs,
  questionTypes as qt,
  rangeMode
} from 'constants/questionnaire';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';
import ChoiceOption from './choice-option';

const RangeEmptyQuestion = ({ questions, question }) => {
  const buildRangeOptions = question => {
    if (!question) return null;

    if (question.data && question.data.slider_mode === rangeMode.ADVANCED_SLIDER) {
      const options = [];
      question.data.labels.forEach((label, idx) => {
        if (idx < question.data.labels.length - 1) {
          const text = `${label.text} - ${question.data.labels[idx + 1].text}`;
          options.push({ text });
        }
      });
      return options.map(option => <ChoiceOption text={option.text} />);
    }

    if (
      question.data?.slider_mode === rangeMode.PERCENTAGE_SLIDER ||
      (question.question_type === qt.CUSTOM && question.data?.flavor === qf.VALUE_REQUIREMENT)
    )
      return (
        <View>
          <View />
          {question.data.slider_mode === rangeMode.PERCENTAGE_SLIDER && <Text>%</Text>}
        </View>
      );

    if (question.data && question.data.labels)
      return question.data.labels.map(label => (
        <ChoiceOption text={label.pdf_text || label.text} />
      ));

    return null;
  };

  if (question.slug === qs.TIME_HORIZON)
    return (
      <ReportEmptyQuestion questions={questions} question={question}>
        <View style={styles.multiOptions}>
          <Text>In </Text>
          <View style={[styles.containerBlankBox, { marginHorizontal: 5 }]} />
          <Text> Year(s)</Text>
        </View>
      </ReportEmptyQuestion>
    );

  const otherOption = question.data?.constants?.other_option ? (
    <ChoiceOption isOtherChoice text={question.data.constants.other_option} />
  ) : null;

  return (
    <ReportEmptyQuestion questions={questions} question={question} withDescription>
      <View style={styles.choices}>
        {buildRangeOptions(question)}
        {otherOption}
      </View>
    </ReportEmptyQuestion>
  );
};

RangeEmptyQuestion.propTypes = propTypes;

export default RangeEmptyQuestion;
