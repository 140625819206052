import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW, TABLE_HEADER_BACKGROUND_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  row: { ...BASE_TABLE_ROW, alignItems: 'center', marginBottom: 10 },
  highlightedText: { fontWeight: 'bold' },
  accountsBreakdown: { marginTop: 10 },
  accountsBreakdownTitleRow: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR },
  accountsBreakdownContentRow: { justifyContent: 'center', alignItems: 'center' }
});

export default styles;
