/* eslint-disable import/prefer-default-export */
import {
  LARGE_CORE,
  LARGE_GROWTH,
  LARGE_VALUE,
  MID_CORE,
  MID_GROWTH,
  MID_VALUE,
  SMALL_CORE,
  SMALL_GROWTH,
  SMALL_VALUE
} from 'components/charts/morningstar-style-box-chart/utils';

export const INVESTMENT_STYLE_MAPPING = {
  [LARGE_GROWTH]: { background: '#388E3C', order: 1, text: '#081409' },
  [LARGE_CORE]: { background: '#81C784', order: 2, text: '#163017' },
  [LARGE_VALUE]: { background: '#C8E6C9', order: 3, text: '#244e26' },
  [MID_GROWTH]: { background: '#FFB300', order: 4, text: '#432f00' },
  [MID_CORE]: { background: '#FFD54F', order: 5, text: '#534000' },
  [MID_VALUE]: { background: '#FFECB3', order: 6, text: '#634b02' },
  [SMALL_GROWTH]: { background: '#F4511E', order: 7, text: '#2E1E19' },
  [SMALL_CORE]: { background: '#FF8A65', order: 8, text: '#3F0F00' },
  [SMALL_VALUE]: { background: '#FFCCBC', order: 9, text: '#7B2105' }
};
