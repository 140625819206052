import { Image, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import logo from 'assets/img/brand/stratifi-gradient-logo.png';

const StratifiBrand = ({ width, height, style }) => (
  <View style={{ ...style, width, height }}>
    <Image src={logo} style={{ objectFit: 'auto' }} />
  </View>
);

StratifiBrand.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object
};

StratifiBrand.defaultProps = {
  width: 200,
  height: 'auto',
  style: {}
};

export default StratifiBrand;
