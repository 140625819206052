import { questionSlugs as qs } from 'constants/questionnaire';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage, withCurrencyFormat } from 'utils/utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const RangeReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  let { value } = question.answer;
  if (question.data.format === 'currency') value = withCurrencyFormat(value, 'standard', 0);
  else if (question.data.format === 'percent') value = formatPercentage(value, 100, 0);
  else if (question.slug === qs.TIME_HORIZON && value > 0) value = `In ${value} Year(s)`;
  else if (question.slug === qs.TIME_HORIZON && value <= 0) value = `Now`;

  return (
    <ReadOnlyQuestion question={question}>
      <p>{value}</p>
    </ReadOnlyQuestion>
  );
};

RangeReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default RangeReadOnlyQuestion;
