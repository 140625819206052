/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import './styles.scss';

const SOURCE_OPTIONS = ['In-person', 'Transferred from PDF', 'Over a Phone call'];

const DatePickerWrapper = ({ header, value, onChange }) => {
  const formatCustomPlaceholder = () => {
    if (!value) return 'DD/MM YY';
    return value;
  };

  return (
    <div
      className="date-picker__container"
      style={{ width: '228px', display: 'flex', flexFlow: 'column' }}
    >
      <label
        htmlFor={header.toLowerCase().replace(/\s/g, '-')}
        style={{
          fontSize: '16px',
          color: '#212529'
        }}
      >
        {header}
      </label>
      <div className="date-picker__wrapper">
        <DatePicker
          required={false}
          maxDate={new Date()}
          placeholderText={formatCustomPlaceholder()}
          value={value}
          onChange={onChange}
          className="companies-datepicker"
        />
      </div>
    </div>
  );
};

const DocumentationNoteForm = ({ formData, onFormDataChange, sourceIsRequired }) => {
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleRadioChange = event => {
    const { value } = event.target;
    if (value === 'Other') {
      setShowOtherInput(true);
      onFormDataChange('answers_source', '');
    } else {
      setShowOtherInput(false);
      onFormDataChange('answers_source', value);
    }
  };

  const handleOtherInputChange = event => {
    onFormDataChange('answers_source', event.target.value);
  };

  const handleDatePickerChange = date => {
    if (date) onFormDataChange('answers_date', date);
    else onFormDataChange('answers_date', '');
  };

  return (
    <form id="documentation-note-introduction">
      <div className="content">
        <p className="documentation-note__title">
          Documentation Note {sourceIsRequired ? '(required)' : ''}
        </p>
        <div className="source-container">
          <p>How were these answers obtained?</p>
          {SOURCE_OPTIONS.map(option => (
            <div className="radio-container">
              <label>
                <input
                  type="radio"
                  name="answers_source"
                  value={option}
                  checked={formData.answers_source === option}
                  onChange={handleRadioChange}
                />
                <span className="radio__text">{option}</span>
              </label>
            </div>
          ))}
          <div className="radio-container">
            <label>
              <input
                type="radio"
                name="answers_source"
                value="Other"
                checked={showOtherInput}
                onChange={handleRadioChange}
              />
              <span className="radio__text">Other</span>
            </label>
            {showOtherInput && (
              <input
                type="text"
                placeholder="How?"
                value={formData.answers_source}
                onChange={handleOtherInputChange}
                className="form-control other-input__text"
                maxLength={100}
              />
            )}
          </div>
        </div>

        <DatePickerWrapper
          header="Date of the meeting"
          value={formData.answers_date}
          onChange={handleDatePickerChange}
        />
      </div>
    </form>
  );
};

DocumentationNoteForm.propTypes = {
  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
  sourceIsRequired: PropTypes.bool.isRequired
};

DatePickerWrapper.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  answersSource: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DocumentationNoteForm;
