import Signature from 'components/advisor/templates/sections/signature';
import PropTypes from 'prop-types';
import React from 'react';
import { getTargetInvestors } from 'utils/utils';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';

const ProposalSignature = ({
  actionsDisabled,
  managers,
  metadata,
  propertyKey,
  proposal,
  setMetadata
}) => {
  const investors = getTargetInvestors(proposal);
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  const signatureProps = {
    ...proposalMetadata,
    initialValues: { ...proposalMetadata.initialValues, signers: proposalMetadata.signers },
    investors,
    managers
  };
  return <Signature {...signatureProps} />;
};

ProposalSignature.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  managers: PropTypes.array.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalSignature;
