import React from 'react';
import FilterContainer from '../../common/filter-container';
import SingleSelectFilter from '../../common/single-select';
import {
  INVESTOR_TARGET_MODIFIED_AFTER_ATTR,
  INVESTOR_TARGET_MODIFIED_BEFORE_ATTR
} from '../../constants';
import { filterDefaultProps, filterPropTypes } from '../../types';
import {
  RISK_TOLERANCE_NOT_UPDATED,
  RISK_TOLERANCE_UPDATED,
  VIEW_ALL,
  rangeOptions
} from './utils';

const InvestorRiskToleranceUpdatedFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const onChangeHandler = ({ value }) => {
    if (!value?.length)
      setFilters(prevFilters => ({
        ...prevFilters,
        [INVESTOR_TARGET_MODIFIED_AFTER_ATTR]: undefined,
        [INVESTOR_TARGET_MODIFIED_BEFORE_ATTR]: undefined
      }));
    else {
      const [targetModifiedAfter, targetModifiedBefore] = value;
      setFilters(prevFilters => ({
        ...prevFilters,
        [INVESTOR_TARGET_MODIFIED_AFTER_ATTR]: targetModifiedAfter,
        [INVESTOR_TARGET_MODIFIED_BEFORE_ATTR]: targetModifiedBefore
      }));
    }
    setRequiresPageIndexReset(true);
  };

  const getDefaultValue = () => {
    if (
      !filters[INVESTOR_TARGET_MODIFIED_AFTER_ATTR] &&
      !filters[INVESTOR_TARGET_MODIFIED_BEFORE_ATTR]
    )
      return VIEW_ALL;
    if (
      !filters[INVESTOR_TARGET_MODIFIED_AFTER_ATTR] &&
      filters[INVESTOR_TARGET_MODIFIED_BEFORE_ATTR]
    )
      return RISK_TOLERANCE_NOT_UPDATED.options.find(option => {
        const [, targetModifiedBefore] = option.value;
        return targetModifiedBefore === filters[INVESTOR_TARGET_MODIFIED_BEFORE_ATTR];
      });
    return RISK_TOLERANCE_UPDATED.find(option => {
      const [targetModifiedAfter, targetModifiedBefore] = option.value;
      return (
        targetModifiedAfter === filters[INVESTOR_TARGET_MODIFIED_AFTER_ATTR] &&
        targetModifiedBefore === filters[INVESTOR_TARGET_MODIFIED_BEFORE_ATTR]
      );
    });
  };

  const defaultValue = getDefaultValue();

  return (
    <FilterContainer
      attrs={[INVESTOR_TARGET_MODIFIED_AFTER_ATTR, INVESTOR_TARGET_MODIFIED_BEFORE_ATTR]}
      filters={filters}
      label="Risk Tolerance Updated"
    >
      <SingleSelectFilter
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        options={rangeOptions}
      />
    </FilterContainer>
  );
};

InvestorRiskToleranceUpdatedFilter.propTypes = { ...filterPropTypes };

InvestorRiskToleranceUpdatedFilter.defaultProps = { ...filterDefaultProps };

export default InvestorRiskToleranceUpdatedFilter;
