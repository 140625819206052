// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-custom-filters-container {
  position: relative;
}
.btn-custom-filters-container > span {
  position: absolute;
  width: 22px;
  height: 22px;
  top: -8px;
  right: -8px;
  border: 2px solid #e3e7ed;
  border-radius: 100%;
  font-size: 0.7rem;
  font-weight: normal;
  line-height: 12px;
}

#sdt__filters-modal .stratifi-select .stratifi-sel__control {
  min-height: 42px;
}
#sdt__filters-modal .filters-modal__base {
  display: flex;
  gap: 30px;
}
#sdt__filters-modal .filters-modal__base > .filter-group {
  flex-grow: 1;
}
#sdt__filters-modal .filters-modal__base > .filter-group.filter-group--wide {
  min-width: 250px;
  flex-basis: 250px;
}
#sdt__filters-modal .filters-modal__actions {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e3e7ee;
  padding-top: 20px;
  margin-top: 40px;
  gap: 10px;
}
#sdt__filters-modal .filters-modal__actions > div {
  flex-grow: 1;
  max-width: 175px;
}
#sdt__filters-modal .filters-modal__actions > .btn.btn-link {
  padding: 0;
}
#sdt__filters-modal .filters-modal__actions .loading-button > .btn.btn-primary {
  width: 100%;
}
#sdt__filters-modal .modal-xl {
  max-width: 1300px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/table/dynamic/filters-modal/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AADJ;;AAME;EACE,gBAAA;AAHJ;AAME;EACE,aAAA;EACA,SAAA;AAJJ;AAMI;EACE,YAAA;AAJN;AAMM;EACE,gBAAA;EACA,iBAAA;AAJR;AASE;EACE,aAAA;EACA,8BAAA;EACA,6BAAA;EACA,iBAAA;EACA,gBAAA;EACA,SAAA;AAPJ;AASI;EACE,YAAA;EACA,gBAAA;AAPN;AAUI;EACE,UAAA;AARN;AAWI;EACE,WAAA;AATN;AAaE;EACE,iBAAA;AAXJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.btn-custom-filters-container {\n  position: relative;\n\n  & > span {\n    position: absolute;\n    width: 22px;\n    height: 22px;\n    top: -8px;\n    right: -8px;\n    border: 2px solid $athens-gray;\n    border-radius: 100%;\n    font-size: 0.7rem;\n    font-weight: normal;\n    line-height: 12px;\n  }\n}\n\n#sdt__filters-modal {\n  .stratifi-select .stratifi-sel__control {\n    min-height: 42px;\n  }\n\n  .filters-modal__base {\n    display: flex;\n    gap: 30px;\n\n    & > .filter-group {\n      flex-grow: 1;\n\n      &.filter-group--wide {\n        min-width: 250px;\n        flex-basis: 250px;\n      }\n    }\n  }\n\n  .filters-modal__actions {\n    display: flex;\n    justify-content: space-between;\n    border-top: 1px solid $gray-light-3;\n    padding-top: 20px;\n    margin-top: 40px;\n    gap: 10px;\n\n    & > div {\n      flex-grow: 1;\n      max-width: 175px;\n    }\n\n    & > .btn.btn-link {\n      padding: 0;\n    }\n\n    .loading-button > .btn.btn-primary {\n      width: 100%;\n    }\n  }\n\n  .modal-xl {\n    max-width: 1300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
