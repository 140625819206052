import React from 'react';
import PropTypes from 'prop-types';

const InvestorListNotification = ({ message, onClose }) => {
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <div
      className="alert alert-dark"
      role="alert"
      style={{ fontSize: '0.9rem', marginBottom: '0.6rem' }}
    >
      {message}
      <button type="button" onClick={onCloseHandler} className="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

InvestorListNotification.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default InvestorListNotification;
