import PropTypes from 'prop-types';
import SectionLocked from 'components/advisor/section-locked';
import Disclosure from 'components/disclosure';
import { AdvisorContext } from 'containers/advisor';
import React, { useContext, useState, useEffect } from 'react';
import ModelPortfolioCard from 'components/model-portfolio-card/index';
import { connect } from 'react-redux';
import SimpleSpinner from 'components/utils/simple-spinner';
import { getDefaultPageSize, setDefaultPageSize } from 'containers/table/dynamic/utils';
import PaginationFooter from 'components/pagination-footer';
import ModelProviderItem from 'components/model-provider-item';
import _ from 'lodash';
import './styles.scss';

const PAGINATION_VALUES = [12, 24, 48, 96];

const ModelsMarketplace = ({ marketplaceList, marketplaceListMeta, marketplaceProvidersList }) => {
  const {
    user: {
      advisor: {
        company: { models_marketplace_enabled: enabled }
      }
    },
    modelProvider
  } = useContext(AdvisorContext);

  if (!Object.keys(marketplaceListMeta).length)
    marketplaceListMeta = {
      params: {
        page: 1,
        page_size: PAGINATION_VALUES[0]
      },
      count: 0,
      totalPages: 0
    };

  const { page, page_size } = marketplaceListMeta.params;
  const { count, totalPages } = marketplaceListMeta;

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page);
  const [pageSize, setPageSize] = useState(getDefaultPageSize(PAGINATION_VALUES[0]));
  const [activeModelProviderItem, setActiveModelProviderItem] = useState(null);

  useEffect(() => {
    modelProvider.getMarketplaceProviders();
  }, []);

  // Creating a debounced fetchData function
  useEffect(() => {
    setLoading(true);
    const params = {
      page: currentPage,
      page_size: pageSize
    };

    if (activeModelProviderItem) params.company = activeModelProviderItem;

    modelProvider.getMarketplaces(params).then(() => setLoading(false));
  }, [currentPage, pageSize, activeModelProviderItem]);

  const onPageSizeChangeHandler = event => {
    const newPageSize = Number(event.target.value);
    setDefaultPageSize(newPageSize);
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const onPreviousPageChangeHandler = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextPageChangeHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPageChangeHandler = event => {
    const newPage = Number(event.target.value);
    setCurrentPage(newPage);
  };

  function parseCardData(cardData) {
    return {
      id: cardData.id,
      name: cardData.name,
      cover: cardData.cover,
      coverDefault: cardData.cover_default,
      logo: cardData.company?.logo,
      companyName: cardData.company?.name,
      displayName: cardData.display_name,
      inceptionDate: cardData.inception_date,
      type: cardData.type,
      description: cardData.description,
      prismScore: cardData.prism_score_summary.overall,
      subscriptionStatus: cardData.subscription_status
    };
  }

  const paginationFooterProps = {
    count,
    page,
    totalPages,
    pageSize,
    onPreviousPageChangeHandler,
    onPageSizeChangeHandler,
    onPageChangeHandler,
    onNextPageChangeHandler,
    paginationValues: PAGINATION_VALUES
  };

  const onModelProviderClickHandler = id => {
    setCurrentPage(1);
    if (activeModelProviderItem === id) {
      setActiveModelProviderItem(null);
      return;
    }
    setActiveModelProviderItem(id);
  };

  return (
    <div id="ModelsMarketplace">
      <div className="models-wrapper">
        {!enabled && <SectionLocked id="models_marketplace" name=" Market IQ" masked={false} />}
        {enabled && (
          <>
            <div className="header">
              <h2>Market IQ</h2>
            </div>
            <div className="header">
              <h3>Providers</h3>
            </div>

            <div className="models_list models_list__providers">
              {marketplaceProvidersList.map(item => (
                <ModelProviderItem
                  key={item.id}
                  {...item}
                  onClickHandler={onModelProviderClickHandler}
                  isActive={activeModelProviderItem === item.id}
                />
              ))}
            </div>

            <div className="models_list models_list__portfolio">
              {loading && (
                <div className="loading-overlay">
                  <SimpleSpinner size="40" />
                </div>
              )}
              {marketplaceList.map(item => (
                <ModelPortfolioCard key={item.id} {...parseCardData(item)} />
              ))}
            </div>

            <PaginationFooter {...paginationFooterProps} />
          </>
        )}
      </div>
      <Disclosure />
    </div>
  );
};

ModelsMarketplace.propTypes = {
  marketplaceList: PropTypes.array,
  marketplaceListMeta: PropTypes.object,
  marketplaceProvidersList: PropTypes.array
};

ModelsMarketplace.defaultProps = {
  marketplaceList: [],
  marketplaceListMeta: {},
  marketplaceProvidersList: []
};

export default connect(state => ({
  marketplaceList: state.models.marketplaceList,
  marketplaceListMeta: state.models.marketplaceListMeta,
  marketplaceProvidersList: state.models.marketplaceProvidersList
}))(ModelsMarketplace);
