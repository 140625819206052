/* global $ */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import { OrderingTableMixin } from 'utils/mixins';
import './styles.scss';

class LinkedAccountTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    OrderingTableMixin.applyMixin(this);
  }

  static styles = {
    sortStyles: {
      userSelect: 'none',
      cursor: 'pointer',
      position: 'absolute',
      width: 10,
      height: 10
    },
    wideCellStyles: {
      width: 126,
      paddingRight: 15
    },
    clientCellStyles: {
      width: 120
    },
    lastModifiedCellStyles: {
      width: 100
    },
    rrCell: {
      cursor: 'pointer',
      textAlign: 'center'
    },
    clickableCell: {
      cursor: 'pointer'
    },
    disabledButton: {
      background: '#D5D8DE',
      borderColor: '#D5D8DE'
    },
    pendingButton: {
      background: '#7B8C94',
      borderColor: '#7B8C94'
    },
    tooltip: {
      width: 168,
      marginLeft: -84,
      opacity: 0.8,
      color: '#dae3e6',
      fontSize: 13,
      fontWeight: 'normal',
      letterSpacing: 0,
      borderRadius: 0,
      padding: 0
    }
  };

  render() {
    const { accounts, pagination } = this.props;

    const { styles } = this.constructor;
    const { sortStyles, wideCellStyles, clientCellStyles } = styles;

    return (
      <div>
        <table className="table table-investment-linked">
          <thead>
            <tr>
              <td rowSpan={2} className="double-cell">
                PRISM&nbsp;
                <span onClick={this.sortByColumn.bind(this, 'prism_overall')} style={sortStyles}>
                  {this.renderSortIcon('prism')}
                </span>
              </td>
              <td rowSpan={2} className="double-cell" style={clientCellStyles}>
                Client&nbsp;
                <span onClick={this.sortByColumn.bind(this, 'investor_name')} style={sortStyles}>
                  {this.renderSortIcon('investor__name')}
                </span>
              </td>
              <td rowSpan={2} className="double-cell">
                Account&nbsp;
                <span onClick={this.sortByColumn.bind(this, 'number')} style={sortStyles}>
                  {this.renderSortIcon('number')}
                </span>
              </td>
              <td className="text-sm-right double-cell" rowSpan={2} style={wideCellStyles}>
                Account Value&nbsp;
                <span onClick={this.sortByColumn.bind(this, 'value')} style={sortStyles}>
                  {this.renderSortIcon('value')}
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            {accounts.map(account => (
              <tr key={account.id}>
                <td className="text-sm-center">
                  {account.prism_overall ? (
                    <Link
                      to={`/advisor/investors/${account.investor.id}/account/${account.id}/prism`}
                    >
                      <span
                        className={`risk-badge-with-score risk-level-${Math.ceil(
                          account.prism_overall
                        )}`}
                      >
                        {account.prism_overall}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
                <td className="client-name" style={{ whiteSpace: 'nowrap' }}>
                  <Link to={`/advisor/investors/${account.investor.id}`}>
                    {account.investor_name}
                  </Link>
                </td>
                <td
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: 314,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {account.display_name}
                </td>
                <td className="text-sm-right">
                  <FormattedNumber value={account.value} format="currency" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {pagination}
      </div>
    );
  }
}

LinkedAccountTable.defaultProps = {
  ordering: null,
  pagination: null
};

LinkedAccountTable.propTypes = {
  sortBy: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  ordering: PropTypes.string,
  pagination: PropTypes.object
};

export default LinkedAccountTable;
