import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';

const MetaTemplateReport = ({
  content,
  orientation,
  size,
  subtitle,
  title,
  user,
  withCustomBranding
}) => {
  const context = useMemo(() => ({ content, orientation, user, withCustomBranding }), [
    JSON.stringify(content),
    JSON.stringify(user),
    orientation,
    withCustomBranding
  ]);

  return (
    <BaseDocument title={title && subtitle ? `${title} for ${subtitle}` : title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={user.advisor}
          orientation={orientation}
          size={size}
          subtitle={subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={user.advisor}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
        >
          {content
            .filter(([_, section]) => !section.hidden)
            .map(([_, section]) => (
              <CustomSection
                title={section.title}
                body={section.body}
                sectionStyles={{ marginBottom: 15 }}
              />
            ))}

          {!!user?.advisor?.company?.report_disclosure && (
            <CustomSection
              breakSection
              title="Disclosure"
              body={user.advisor.company.report_disclosure}
            />
          )}
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

MetaTemplateReport.propTypes = {
  content: PropTypes.array.isRequired,
  orientation: PropTypes.string,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

MetaTemplateReport.defaultProps = {
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  title: '',
  withCustomBranding: false
};

export default MetaTemplateReport;
