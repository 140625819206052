import cs from 'classnames';
import Choice from 'components/form/choice';
import OrSeparator from 'components/or-separator';
import TooltipV2 from 'components/tooltip-v2';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { integrationsConnectedSelector } from 'selectors/integrations';
import {
  API_ENROLLMENT_ONLY,
  getIntegrationsSyncData,
  getIntegrationsSyncDataByProviders,
  getSyncDataOptions,
  hasCustomIntegrationHandler
} from '../utils';
import './styles.scss';

const SyncDataOptions = ({
  integrations,
  onConnectOrUpdate,
  prevSelectedSyncData,
  provider,
  updating
}) => {
  // notice these differences between data structures:
  // - prevSelectedSyncData (prop) and providedSyncData (variable) share the same structure.
  //   e.g. [{ id: 'sync_accounts', name: 'Accounts', description: ''}, { ... }, ...]
  // - selectedSyncData (state) has a different structure.
  //   e.g. { sync_accounts: true, ... }
  const connectedIntegrationsSyncData = getIntegrationsSyncData(integrations);
  const connectedIntegrationsSyncDataByProviders = getIntegrationsSyncDataByProviders(integrations);
  const hasApiEnrollmentOnlyAvailable = !!provider[API_ENROLLMENT_ONLY];
  const hasConnectedIntegrations = !_.isEmpty(integrations);
  const providedSyncData = getSyncDataOptions(provider);

  const [apiEnrollmentOnly, setApiEnrollmentOnly] = useState(false);
  const [selectedSyncData, setSelectedSyncData] = useState(
    providedSyncData.reduce((acc, syncDataOption) => ({ ...acc, [syncDataOption.id]: false }), {})
  );

  // if some key in `selectedSyncData` is true, it means there is at least
  // one item that can be synced from the provided data.
  const hasSelectedSyncData = Object.keys(selectedSyncData).some(d => selectedSyncData[d]);
  const showConnectedIntegrationsWarning =
    hasConnectedIntegrations &&
    !updating &&
    providedSyncData.some(option => connectedIntegrationsSyncData.includes(option.id));
  const tooltipId = `api-enrollment-only-tooltip`;

  const onConnectOrUpdateIntegration = () => {
    if (onConnectOrUpdate) {
      const data = { ...selectedSyncData };
      if (hasApiEnrollmentOnlyAvailable) data[API_ENROLLMENT_ONLY] = apiEnrollmentOnly;
      onConnectOrUpdate(data);
    }
  };

  const onToggleSyncDataOption = optionId => {
    setSelectedSyncData({ ...selectedSyncData, [optionId]: !selectedSyncData[optionId] });
  };

  const onSetAllSyncDataOptions = value => {
    setSelectedSyncData(
      Object.keys(selectedSyncData).reduce(
        (acc, syncDataOption) => ({ ...acc, [syncDataOption]: value }),
        {}
      )
    );
  };

  useEffect(() => {
    if (apiEnrollmentOnly) onSetAllSyncDataOptions(false);
  }, [apiEnrollmentOnly]);

  useEffect(() => {
    if (hasSelectedSyncData) setApiEnrollmentOnly(false);
  }, [hasSelectedSyncData]);

  // used only for assign previous selected sync data options when updating
  useEffect(() => {
    if (updating) {
      const hasApiEnrollmentOnly = prevSelectedSyncData.find(
        syncDataOption => syncDataOption.id === API_ENROLLMENT_ONLY
      );
      if (hasApiEnrollmentOnly) setApiEnrollmentOnly(true);
      else
        setSelectedSyncData(
          prevSelectedSyncData.reduce(
            (acc, syncDataOption) => ({ ...acc, [syncDataOption.id]: true }),
            {}
          )
        );
    }
  }, []);

  return (
    <div id="sync-data-options">
      {!_.isEmpty(providedSyncData) && (updating || hasCustomIntegrationHandler(provider)) && (
        <>
          <h1>
            {updating
              ? `Update integration with ${provider.name}`
              : `Select data to import from ${provider.name}`}
          </h1>
          <div className="provided-sync-data">
            <div className="provided-sync-data__info">Information to sync:</div>
            {providedSyncData.map(syncDataOption => (
              <div key={syncDataOption.id}>
                <Choice
                  checked={
                    selectedSyncData[syncDataOption.id] ||
                    (!updating && connectedIntegrationsSyncData.includes(syncDataOption.id))
                  }
                  toggle={() => onToggleSyncDataOption(syncDataOption.id)}
                  disabled={!updating && connectedIntegrationsSyncData.includes(syncDataOption.id)}
                />
                <span
                  className={cs('provided-sync-data__label', {
                    disabled:
                      !updating && !!connectedIntegrationsSyncDataByProviders[syncDataOption.id]
                  })}
                >
                  {!updating && connectedIntegrationsSyncDataByProviders[syncDataOption.id]
                    ? `${syncDataOption.name} (Currently importing from ${
                        connectedIntegrationsSyncDataByProviders[syncDataOption.id]
                      })`
                    : syncDataOption.name}
                </span>
              </div>
            ))}
            {hasApiEnrollmentOnlyAvailable && (
              <>
                <OrSeparator text="or" />
                <TooltipV2
                  className="api-enrollment-only-tooltip"
                  id={tooltipId}
                  label="Check this box if you only need to activate API access for your firm, allowing other advisors to connect their own integrations. Your credentials will not be used to pull information from Charles Schwab"
                  place="bottom"
                >
                  <div data-for={tooltipId} data-tip="" key={API_ENROLLMENT_ONLY}>
                    <Choice
                      checked={apiEnrollmentOnly}
                      toggle={() =>
                        setApiEnrollmentOnly(prevApiEnrollmentOnly => !prevApiEnrollmentOnly)
                      }
                    />
                    <span className="provided-sync-data__label">API enrollment only</span>
                  </div>
                </TooltipV2>
              </>
            )}
          </div>
          {showConnectedIntegrationsWarning && (
            <div className="sync-data-alert">
              <p>
                * If you want to replace the existing data, please disconnect it first. Go to the
                Connected integrations page and update the data selection.
              </p>
            </div>
          )}
          <div className="buttons-wrapper">
            <button type="button" data-dismiss="modal" className="btn btn-outline-primary">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConnectOrUpdateIntegration}
              disabled={!hasSelectedSyncData && !apiEnrollmentOnly && !updating}
            >
              {updating ? 'Update' : 'Connect'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

SyncDataOptions.propTypes = {
  integrations: PropTypes.array.isRequired,
  onConnectOrUpdate: PropTypes.func,
  prevSelectedSyncData: PropTypes.array,
  provider: PropTypes.object.isRequired,
  updating: PropTypes.bool
};

SyncDataOptions.defaultProps = {
  onConnectOrUpdate: null,
  prevSelectedSyncData: [],
  updating: false
};

export default connect(state => ({
  integrations: integrationsConnectedSelector(state)
}))(SyncDataOptions);
