import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  TABLE_CELL_HEADER_01,
  TABLE_CELL_HEADER_02,
  LIGHT_GRAPHITE_COLOR
} from 'reports/base/styles';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  rowPosition: {
    paddingBottom: 3,
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR
  },
  cell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  cellSummary: { flexDirection: 'row', justifyContent: 'space-between' },
  cellPosition: { paddingVertical: 2, paddingHorizontal: 2 },
  headerText: { textAlign: 'center' },
  recommendedCell: { ...TABLE_CELL_HEADER_02 },
  targetCell: { ...TABLE_CELL_HEADER_01 }
});

export default styles;
