import cn from 'classnames';
import { EDIT_RESTRICTED_SECTION } from 'components/advisor/utils/constants';
import { FormGroup, VerboseErrorInput } from 'components/form';
import Choice from 'components/form/choice';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { PREPARED_BY_OPTIONS, PREPARED_FOR_OPTIONS } from './constants';
import './styles.scss';

const validate = values => {
  const errors = {};
  errors.title = errors.title || validation.required(values.title);
  return errors;
};

const CoverSection = ({
  actionsDisabled,
  allowEdit,
  body,
  editing,
  fields,
  handleSubmit,
  showPreparedFor,
  invalid,
  location,
  propertyKey,
  resetForm,
  saveChanges,
  submitting,
  title,
  toggleEditing
}) => {
  const { authProvider, user } = useContext(AdvisorContext);

  const hasCompliancePermissions = authProvider.hasCompliancePermissions(user);
  const isTemplateView = location.pathname.match('advisor/templates/');
  const isTemplateViewWithAdminPermissions = isTemplateView && hasCompliancePermissions;

  const onPermissionsChange = values => () => saveChanges(propertyKey, { body, title, ...values });

  const onCancel = () => {
    resetForm();
    toggleEditing();
  };

  const onSubmit = values =>
    saveChanges(propertyKey, { body, ...values }).then(() => {
      toggleEditing();
    });

  return (
    <section
      id="cover-section"
      className={cn('cover-section', { 'cover-section--templates': isTemplateView })}
    >
      <div className={cn('body', { editing })}>
        <div className="body__actions">
          {!hasCompliancePermissions && !allowEdit && (
            <span className="hidden-msg">{EDIT_RESTRICTED_SECTION}</span>
          )}

          {!editing && (
            <AdministrativePermissionsTooltip
              renderTooltip={(!hasCompliancePermissions && !allowEdit) || actionsDisabled}
              tooltipId="cover-section-edit"
            >
              <button
                className="btn btn-secondary"
                disabled={
                  (!hasCompliancePermissions && !allowEdit) || actionsDisabled || submitting
                }
                onClick={toggleEditing}
                type="button"
              >
                Edit
              </button>
            </AdministrativePermissionsTooltip>
          )}

          {editing && (
            <>
              <button
                className="btn btn-link text-dark"
                disabled={submitting}
                onClick={onCancel}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-secondary"
                disabled={submitting || invalid}
                onClick={handleSubmit(onSubmit)}
                type="button"
              >
                {submitting ? 'Saving...' : 'Save'}
              </button>
            </>
          )}
        </div>

        <div className="body__content">
          <h2>Report Cover</h2>
          <div className="content__form">
            <div className="form__title">
              <span>Title</span>
              <FormGroup {...fields.title}>
                <VerboseErrorInput
                  className="form-control"
                  disabled={!editing}
                  placeholder="Add report title"
                  type="text"
                  {...fields.title}
                />
              </FormGroup>
            </div>

            {showPreparedFor && (
              <div className="form__prepared-for">
                <span>Prepared for</span>
                {PREPARED_FOR_OPTIONS.map(option => (
                  <label htmlFor={option.value} key={option.value}>
                    <input
                      type="radio"
                      {...fields.preparedFor}
                      checked={fields.preparedFor.value === option.value}
                      disabled={!editing}
                      onChange={() => fields.preparedFor.onChange(option.value)}
                      value={option.value}
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            )}

            <div className="form__prepared-by">
              <span>Prepared by</span>
              {PREPARED_BY_OPTIONS.map(option => (
                <label htmlFor={option.value} key={option.value}>
                  <input
                    type="radio"
                    {...fields.preparedBy}
                    checked={fields.preparedBy.value === option.value}
                    disabled={!editing}
                    onChange={() => fields.preparedBy.onChange(option.value)}
                    value={option.value}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isTemplateViewWithAdminPermissions && (
        <div className="permissions">
          <span className="permissions__title">Allow advisors to:</span>
          <ul>
            <li>
              <Choice
                checked={allowEdit}
                disabled={editing}
                title="Change content"
                toggle={onPermissionsChange({ allowEdit: !allowEdit })}
              />
            </li>
          </ul>
        </div>
      )}
    </section>
  );
};

CoverSection.propTypes = {
  actionsDisabled: PropTypes.bool,
  allowEdit: PropTypes.bool,
  body: PropTypes.string,
  editing: PropTypes.bool,
  fields: PropTypes.shape({
    preparedBy: PropTypes.object.isRequired,
    preparedFor: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  propertyKey: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  saveChanges: PropTypes.func,
  showPreparedFor: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleEditing: PropTypes.func.isRequired
};

CoverSection.defaultProps = {
  actionsDisabled: false,
  allowEdit: true,
  body: '',
  editing: false,
  saveChanges: () => {},
  showPreparedFor: true
};

export default withRouter(
  compose(
    connect((state, props) => ({
      form: 'cover-section',
      initialValues: {
        preparedBy: props.preparedBy,
        preparedFor: props.preparedFor,
        title: props.title
      }
    })),
    reduxForm({ fields: ['preparedBy', 'preparedFor', 'title'], validate })
  )(CoverSection)
);
