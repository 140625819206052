// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination .page-item .page-link {
  width: 42px;
  text-align: center;
}
.pagination .page-item .page-link:focus {
  outline: 0;
}
.pagination .page-item.active .page-link {
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pagination/styles.scss"],"names":[],"mappings":"AAII;EACE,WAAA;EACA,kBAAA;AAHN;AAKM;EACE,UAAA;AAHR;AAQM;EACE,UAAA;AANR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.pagination {\n  .page-item {\n    .page-link {\n      width: 42px;\n      text-align: center;\n\n      &:focus {\n        outline: 0;\n      }\n    }\n\n    &.active {\n      .page-link {\n        z-index: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
