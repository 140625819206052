import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE_ROW,
  FOR_LANDSCAPE_ORIENTATION,
  LIGHT_GRAPHITE_COLOR,
  TABLE_HEADER_BACKGROUND_COLOR
} from 'reports/base/styles';

export const styles = StyleSheet.create({
  text: { marginBottom: 20 },
  headerRow: {
    marginRight: 10,
    marginLeft: 31,
    [FOR_LANDSCAPE_ORIENTATION]: {
      marginRight: 15,
      marginLeft: 45
    }
  },
  headerCell: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR },
  contentCell: { borderWidth: 1, borderColor: LIGHT_GRAPHITE_COLOR, borderStyle: 'solid' },
  scenario: { paddingBottom: 5 },
  separator: {
    flexGrow: 1,
    paddingBottom: 5,
    marginVertical: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR
  },
  performanceContainer: {
    width: 400,
    marginHorizontal: 'auto',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: LIGHT_GRAPHITE_COLOR,
    marginTop: 5,
    padding: 5
  },
  performance: { ...BASE_TABLE_ROW, alignItems: 'center', fontSize: 8 },
  performanceBox: { width: 12, height: 12, marginRight: 5 },
  chartContainer: { marginTop: 15 },
  chart: {
    objectFit: 'scale-down',
    height: 200,
    [FOR_LANDSCAPE_ORIENTATION]: {
      height: 270
    }
  }
});

export default styles;
