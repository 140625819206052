import config from '../config';
import DataProvider from './data';

class InvestorGoalsElasticProvider extends DataProvider {
  async search(params) {
    const { data } = await this.provider.get(`${config.apiBase}search/investor_goal/`, params);
    return data.results;
  }
}

export default InvestorGoalsElasticProvider;
