import { PROMO_CODE_CLEAR, PROMO_CODE_GET } from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class PromoCodeProvider extends DataProvider {
  validate(code) {
    return this.provider.get(`${config.apiBase}billing/promocodes/${code}/`).then(response => {
      if (response?.data?.id) this.dispatch(PROMO_CODE_GET, { data: response.data });
      else this.clear();
      return response;
    });
  }

  clear() {
    this.dispatch(PROMO_CODE_CLEAR);
  }
}

export default PromoCodeProvider;
