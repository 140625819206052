import classnames from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import LoadingButton from 'components/loading-button';
import PropTypes from 'prop-types';
import React from 'react';
import { selectPrismScore } from 'utils/utils';

const AggregatedRiskScoreBubble = ({
  className,
  element,
  isListView,
  inProgress,
  scoreName,
  url,
  loadingButtonClassName
}) => {
  // investors, prospects, goals, households, etc have aggregated scores
  const score = selectPrismScore(element.aggregated_prism_scores, scoreName);

  if (score && score !== 0)
    return (
      <ScoreBubble
        url={url}
        score={score}
        className={classnames('risk-bubble -aggregated', className)}
      />
    );

  if (isListView && inProgress)
    return (
      <LoadingButton
        onClick={() => {}}
        className={loadingButtonClassName || 'btn table-btn get-target-score-btn btn-solid'}
        loading={inProgress}
      >
        +
      </LoadingButton>
    );

  return null;
};

AggregatedRiskScoreBubble.defaultProps = {
  className: '',
  isListView: false,
  inProgress: false,
  loadingButtonClassName: '',
  onClick: () => {},
  url: '',
  scoreName: ''
};

AggregatedRiskScoreBubble.propTypes = {
  className: PropTypes.string,
  element: PropTypes.object.isRequired,
  isListView: PropTypes.bool,
  inProgress: PropTypes.bool,
  loadingButtonClassName: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  scoreName: PropTypes.string
};

export default AggregatedRiskScoreBubble;
