import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export const CardOverview = ({ title, children, className }) => (
  <div className={`card-overview ${className}`}>
    <div className="card-header">{title}</div>
    <div className="card-body">{children}</div>
  </div>
);

CardOverview.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  className: PropTypes.string
};

CardOverview.defaultProps = {
  className: ''
};

export default CardOverview;
