/* eslint-disable no-underscore-dangle */
import { Image, Text, View } from '@react-pdf/renderer';
import {
  ACCOUNT_TARGET_TYPE,
  INVESTOR_TARGET_TYPE
} from 'components/advisor/risk-analysis/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { formatPercentage, getTaxonomyLevelIterator, withCurrencyFormat } from 'utils/utils';
import Row from '../../common/pdf/row';
import styles from '../../common/pdf/styles';
import { SECURITY_SECTORS, getSecurityDetails, processSecurityDetails } from '../../common/utils';
import { SECTOR_EXPOSURE_CHART_ID, PDF_IMAGE_STYLES } from '../utils';

const SectorExposure = ({
  body,
  breakSection,
  headingLabels,
  hiddenVal,
  portfolio,
  title,
  totalRowLabel,
  type
}) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          sector_exposure_enabled: sectorExposureEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  if (!sectorExposureEnabled) return null;

  const totalAssets = portfolio.value ?? 0;
  const securitySectors = getSecurityDetails(portfolio, SECURITY_SECTORS, totalAssets);

  const { data, total: totalSecurities } = processSecurityDetails(
    securitySectors,
    SECURITY_SECTORS,
    totalAssets
  );

  const chartId =
    type === ACCOUNT_TARGET_TYPE
      ? portfolio[`${SECTOR_EXPOSURE_CHART_ID}-${portfolio.id}`]
      : portfolio[SECTOR_EXPOSURE_CHART_ID];
  const rowProps = { hiddenVal, total: totalSecurities };

  return (
    <BaseSection title={title} body={body} breakSection={breakSection}>
      <View>
        <Image src={chartId} style={PDF_IMAGE_STYLES} />
      </View>
      <View style={styles.container}>
        <View style={styles.summaryContainer}>
          <View style={[styles.table, { marginTop: 0, maxHeight: 30 }]} wrap={false}>
            <View style={[styles.row, styles.rowTotal]}>
              <View style={styles.cell}>
                <Text>{headingLabels.name}</Text>
              </View>
              <View style={styles.cell}>
                {hiddenVal || <Text style={styles.cellText}>{headingLabels.value}</Text>}
              </View>
              <View style={[styles.cell, { marginRight: 0 }]}>
                <Text style={styles.cellText}>{headingLabels.percent}</Text>
              </View>
            </View>
          </View>

          <View style={[styles.table, { marginTop: 0 }]}>
            {getTaxonomyLevelIterator(data.summary).map(([l1Key, l1], l1Idx) => (
              <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
                <Row
                  color={l1.__color}
                  depth={1}
                  icon={l1.__report_icon}
                  label={l1Key}
                  level={l1}
                  {...rowProps}
                />

                {expandAssetDetails &&
                  getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                    <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                      <Row
                        color={l1.__color}
                        depth={2}
                        label={l2Key}
                        level={l2}
                        withParentIcon={!!l1.__report_icon}
                        {...rowProps}
                      />
                    </Fragment>
                  ))}
              </Fragment>
            ))}
          </View>

          <View style={styles.table} wrap={false}>
            <View style={[styles.row, styles.rowTotal]}>
              <View style={styles.cell}>
                <Text>{totalRowLabel}</Text>
              </View>
              <View style={styles.cell}>
                {hiddenVal || (
                  <Text style={styles.cellText}>
                    {withCurrencyFormat(totalSecurities, 'standard', 0)}
                  </Text>
                )}
              </View>
              <View style={[styles.cell, { marginRight: 0 }]}>
                <Text style={styles.cellText}>{formatPercentage(1, 100, 2)}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </BaseSection>
  );
};

SectorExposure.propTypes = {
  body: PropTypes.string,
  breakSection: PropTypes.bool,
  headingLabels: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired
  }),
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  title: PropTypes.string,
  totalRowLabel: PropTypes.string,
  type: PropTypes.string
};

SectorExposure.defaultProps = {
  body: '',
  breakSection: false,
  headingLabels: { name: '', value: 'Value', percent: '% of Portfolio' },
  hiddenVal: false,
  title: 'Sector Exposure',
  totalRowLabel: 'Total portfolio value',
  type: INVESTOR_TARGET_TYPE
};

export default SectorExposure;
