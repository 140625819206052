// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#DriftLevelWidget .drift-level-distribution-chart {
  width: 100%;
  height: 300px;
}
#DriftLevelWidget .amcharts-balloon-div {
  font-weight: bold;
}
#DriftLevelWidget .amcharts-legend-label,
#DriftLevelWidget .amcharts-legend-value {
  font-weight: bold;
}
#DriftLevelWidget .amcharts-pie-slice {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/dashboard/widgets/drift-level-widget/styles.scss"],"names":[],"mappings":"AAEE;EACE,WAAA;EACA,aAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAIE;;EAEE,iBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ","sourcesContent":["@import 'assets/scss/commons/variables';\n#DriftLevelWidget {\n  .drift-level-distribution-chart {\n    width: 100%;\n    height: 300px;\n  }\n\n  .amcharts-balloon-div {\n    font-weight: bold;\n  }\n  .amcharts-legend-label,\n  .amcharts-legend-value {\n    font-weight: bold;\n  }\n\n  .amcharts-pie-slice {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
