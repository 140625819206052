import Choice from 'components/form/choice';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const PushScores = ({ onComplete }) => {
  const [pushScores, setPushScores] = useState(false);

  const onTogglePushScores = () => {
    setPushScores(!pushScores);
  };

  const onSubmit = () => {
    if (onComplete) onComplete({ push_scores: pushScores });
  };

  return (
    <div id="push-scores">
      <h1>Do you want to send PRISM and Risk Tolerance scores to Addepar?</h1>
      <div className="push-scores-option">
        <div className="push-scores-option__description">
          <p>If you accept, scores will be automatically sent to your Addepar dashboard.</p>
        </div>
        <Choice checked={pushScores} toggle={onTogglePushScores} />
        <span className="push-scores-option__label">
          Send PRISM and Risk Tolerance scores to Addepar
        </span>
      </div>
      <div className="buttons-wrapper">
        <button type="button" data-dismiss="modal" className="btn btn-outline-primary">
          Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

PushScores.propTypes = {
  onComplete: PropTypes.func
};

PushScores.defaultProps = {
  onComplete: null
};

export default PushScores;
