import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { ageCalculator } from 'utils/questionnaire';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const DEFAULT_INCOME_VALUE = 100000;
const DEFAULT_CONTRIBUTION_VALUE = 0.1;

export const useAnnualTaxableIncomeQuestionDetails = ({ question }) => {
  const refs = question.data?.refs || {};
  const {
    estimated_annual_income: estimatedAnnualIncomeText,
    estimated_yearly_contribution: estimatedYearlyContributionText
  } = question.data?.constants || {};

  const age = ageCalculator(refs.birthday);
  const retirement = Number(refs.retirement) || 0;
  const isRetired = retirement - age <= 0;

  let questionText = question.question;
  if (!isRetired && question.data?.non_retired_question)
    questionText = question.data.non_retired_question;

  return {
    estimatedAnnualIncomeText,
    estimatedYearlyContributionText,
    isRetired,
    questionText,
    refs
  };
};

useAnnualTaxableIncomeQuestionDetails.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export const useAnnualTaxableIncome = ({ question, field }) => {
  const [yearlyContribution, setYearlyContribution] = useState(0);

  const isEntityQuestion = question.data?.is_entity || false;
  const isValidAmount = value => !_.isUndefined(value) && value !== '';

  const {
    estimatedAnnualIncomeText,
    estimatedYearlyContributionText,
    isRetired,
    questionText,
    refs
  } = useAnnualTaxableIncomeQuestionDetails({ question });

  const onIncomeChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (isValidAmount(value) && value !== field.income.value) field.income.onChange(value);
    }, 500),
    [field.income.value]
  );

  const onContributionChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (isValidAmount(value) && value !== field.contribution.value)
        field.contribution.onChange(value / 100);
    }, 500),
    [field.contribution.value]
  );

  useEffect(() => {
    if (isValidAmount(field.contribution.value) && isValidAmount(field.income.value))
      setYearlyContribution(field.contribution.value * field.income.value);
  }, [field.contribution.value, field.income.value]);

  useEffect(() => {
    if (!field.income.touched && !isValidAmount(field.income.value))
      field.income.autofill(question.data?.default_value?.income ?? DEFAULT_INCOME_VALUE);
  }, [field.income.touched, field.income.value, question.data?.default_value?.income]);

  useEffect(() => {
    if (isRetired && !isEntityQuestion) field.contribution.autofill(0);
    else if (!field.contribution.touched && !isValidAmount(field.contribution.value)) {
      const defaultContribution =
        question.data?.default_value?.contribution ?? DEFAULT_CONTRIBUTION_VALUE;
      field.contribution.autofill(defaultContribution);
    }
  }, [
    isRetired,
    field.contribution.touched,
    field.contribution.value,
    question.data?.default_value?.contribution
  ]);

  const incomeInputProps = {
    allowNegative: false,
    className: cn('input-income', { 'has-danger': field.income.touched && field.income.error }),
    decimalScale: 2,
    hasError: field.income.dirty && field.income.error,
    onValueChange: onIncomeChange,
    prefix: '$',
    thousandSeparator: true,
    value: field.income.value
  };

  const contributionInputProps = {
    allowNegative: false,
    className: cn('input-contribution', {
      'has-danger': field.contribution.touched && field.contribution.error
    }),
    decimalScale: 2,
    hasError: field.contribution.dirty && field.contribution.error,
    onValueChange: onContributionChange,
    suffix: '%',
    value: field.contribution.value * 100
  };

  return {
    contributionInputProps,
    estimatedAnnualIncomeText,
    estimatedYearlyContributionText,
    incomeInputProps,
    isEntityQuestion,
    isRetired,
    questionText,
    refs,
    yearlyContribution
  };
};

useAnnualTaxableIncome.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};
