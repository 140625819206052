import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const StandardContainer = ({ children }) => (
  <div id="standard-main-container">
    <div className="container-xl">{children}</div>
  </div>
);

StandardContainer.propTypes = {
  children: PropTypes.element
};

StandardContainer.defaultProps = {
  children: null
};

export default StandardContainer;
