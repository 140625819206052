import { Text, View } from '@react-pdf/renderer';
import PotentialDownsideChart from 'components/charts/potential-downside-chart/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import { CYAN_COLOR, GRAPHITE_COLOR, LILAC_COLOR } from 'reports/base/styles';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import styles from './styles';

const maxDownside = (targetAnalytics, recommendedAnalytics, benchmarkAnalytics) =>
  Math.max(
    targetAnalytics?.summary?.max_drawdown?.value ?? 0,
    recommendedAnalytics?.summary?.max_drawdown?.value ?? 0,
    benchmarkAnalytics?.summary?.max_drawdown?.value ?? 0
  );

const PotentialDownside = ({ breakSection, metadata, proposal }) => {
  const { body, title } = metadata;

  const maxDownsideValue = maxDownside(
    proposal.target_analytics,
    proposal.recommended_analytics,
    proposal.benchmark_analytics
  );

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <ExtraDataSection proposal={proposal} />

      <View style={styles.table}>
        <View style={styles.row}>
          {proposal.target_analytics && (
            <View style={[styles.cell, styles.targetHeaderCell]}>
              <Text style={styles.cellText}>{proposal.target_label || 'Current Portfolio'}</Text>
            </View>
          )}
          {proposal.recommended_analytics && (
            <View style={[styles.cell, styles.recommendedHeaderCell]}>
              <Text style={styles.cellText}>{proposal.recommended_label}</Text>
            </View>
          )}
          {proposal.benchmark_analytics && (
            <View style={[styles.cell, styles.benchmarkHeaderCell, { marginRight: 0 }]}>
              <Text style={styles.cellText}>{proposal.benchmark_label}</Text>
            </View>
          )}
        </View>

        <View style={styles.row}>
          {proposal.target_analytics && (
            <View style={styles.cell}>
              <PotentialDownsideChart
                color={GRAPHITE_COLOR}
                maxValue={maxDownsideValue}
                value={proposal.target_analytics.summary.max_drawdown.value}
              />
            </View>
          )}
          {proposal.recommended_analytics && (
            <View style={styles.cell}>
              <PotentialDownsideChart
                color={CYAN_COLOR}
                maxValue={maxDownsideValue}
                value={proposal.recommended_analytics.summary.max_drawdown.value}
              />
            </View>
          )}
          {proposal.benchmark_analytics && (
            <View style={[styles.cell, { marginRight: 0 }]}>
              <PotentialDownsideChart
                color={LILAC_COLOR}
                maxValue={maxDownsideValue}
                value={proposal.benchmark_analytics.summary.max_drawdown.value}
              />
            </View>
          )}
        </View>
      </View>
    </BaseSection>
  );
};

PotentialDownside.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired
};

PotentialDownside.defaultProps = {
  breakSection: false
};

export default PotentialDownside;
