import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const HeroFormContainer = ({ children }) => (
  <div className="hero-form-container">
    <div className="hero-form-container__form">{children}</div>
  </div>
);

HeroFormContainer.defaultProps = {
  children: null
};

HeroFormContainer.propTypes = {
  children: PropTypes.element
};

export default HeroFormContainer;
