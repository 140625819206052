// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#company-users .user-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
#company-users .user-logo.small {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/company-users/styles.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;AAFN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#company-users {\n  .user-logo {\n    width: 30px;\n    height: 30px;\n    margin-right: 10px;\n\n    &.small {\n      width: 20px;\n      height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
