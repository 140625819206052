// TODO: maybe to make it middleware
Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

// TODO: maybe to make it middleware
Storage.prototype.getObject = function (key) {
  const value = this.getItem(key);
  return value ? JSON.parse(value) : value;
};

const isMarketingWidgetIframe =
  window.location.pathname.match('inapp-view') && window.location !== window.parent.location;

// disable localStorage for marketing widget (issue SD-1421)
export default isMarketingWidgetIframe
  ? {
      getItem: () => {},
      setItem: () => {},
      removeItem: () => {}
    }
  : window.localStorage;
