import cn from 'classnames';
import NotesList, { NOTE_LAYOUTS } from 'components/advisor/accounts/notes-list';
import { CheckboxOption as Option, scoreColor } from 'components/advisor/generic-selector';
import GoalsHint from 'components/advisor/goals-hint';
import Choice from 'components/form/choice';
import { Modal, ModalHeader } from 'components/modal';
import Textarea from 'components/user-textarea';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Select from 'react-select';
import {
  EXCEPTION_STATUS,
  NOTE_OTHER_ACTION_VALUE,
  getNoteActionOptions,
  getNoteTypeOptions
} from 'utils/drift';
import './styles.scss';

const SNOOZE_TIME_IN_MONTHS = 6;

const DRIFT_STATUS_LABEL_COLOR = {
  'In progress': 'tag-label--in-progress',
  Resolved: 'tag-label--resolved',
  Closed: 'tag-label--closed'
};

const getManagerNames = (managers, defaultAdvisor) => {
  const advisors = managers && managers.length ? managers : [defaultAdvisor];
  return advisors.map(advisor => `${advisor.user.first_name} ${advisor.user.last_name}`).join(', ');
};

const DriftBox = ({ entity: { drift_summary: driftSummary, is_healthy: isHealthy } }) => {
  const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';
  return (
    <div className="drift-box">
      <span className={cn('btn', 'btn-box', { 'btn-grey': isHealthy, 'btn-red': !isHealthy })}>
        {drift}
      </span>
      Drift{!isHealthy && ' - Exception'}
    </div>
  );
};

DriftBox.propTypes = {
  entity: PropTypes.object.isRequired
};

const AccountNotesModal = ({ accountId, investorId, exceptionId, onHide, show, tags, user }) => {
  const {
    accountProvider,
    authProvider,
    investorProvider,
    user: {
      advisor: {
        company: {
          tolerance_to_goals_mapping: goalsMapping,
          exceptions_actions: exceptionsActions,
          exceptions_tags: exceptionsTags
        }
      }
    }
  } = useContext(AdvisorContext);

  const [account, setAccount] = useState(null);
  const [action, setAction] = useState(undefined);
  const [closeException, setClose] = useState(false);
  const [defaultTags, setDefaultTags] = useState([]);
  const [exception, setException] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [investor, setInvestor] = useState(null);
  const [message, setMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [snooze, setSnooze] = useState(false);
  const [snoozeTime, setSnoozeTime] = useState(SNOOZE_TIME_IN_MONTHS);

  const closeElement = useRef(null);
  const allowSnooze = authProvider.hasCompliancePermissionsOrAbove(user);

  useEffect(() => {
    if (accountId && !account) setAccount(accountId);
    if (!investorId) return;

    investorProvider.get(investorId, { with_all_accounts: true }).then(({ data: investor }) => {
      setInvestor(investor);

      investor.accounts.forEach(a => {
        if (a.id === accountId) setSelectedAccount(a);
      });

      investorProvider.getExceptions(investorId).then(exceptions => {
        exceptions.some(ex => {
          if (ex.account.id === accountId) {
            setSnooze(ex.status === EXCEPTION_STATUS.SNOOZED);
            setException({ ...ex, total: exceptions.length });
            return true;
          }
          return false;
        });
      });
    });
  }, [accountId, investorId]);

  useEffect(() => {
    setSelectedTags(tags);
    setDefaultTags(tags.map(element => ({ value: element, label: element })));
  }, [JSON.stringify(tags)]);

  const createNoteBody = message => {
    const note = {
      message,
      close_exception: closeException
    };

    if (account && snooze) {
      const monthsAgo = moment().add(snoozeTime, 'months');
      const daysBetween = monthsAgo.diff(moment(), 'days');
      note.snooze = daysBetween;
    }

    return note;
  };

  const filterByTags = tags => {
    if (tags && tags.length > 0) setSelectedTags(tags.map(t => t.label));
    else setSelectedTags([]);
  };

  const handleChange = e => {
    if (e.target.value === '') setMessage(undefined);
    else setMessage(e.target.value);
  };

  const markForSnooze = e => {
    setSnooze(e.target.checked);
  };

  const markForClose = e => {
    setClose(e.target.checked);
  };

  const updateSnoozeTime = e => {
    setSnoozeTime(e.target.value);
  };

  const handleSave = () => {
    setSaving(true);
    const note = createNoteBody(`${action ? `${action}. ` : ''}${message}`);
    const provider = account ? accountProvider : investorProvider;
    const target = account ? { id: account } : investor;

    if (!_.isEqual(selectedTags.sort(), tags.sort()))
      investorProvider.updateException(exceptionId, { tags: selectedTags }).then(() => {
        const addedTypes = selectedTags.filter(element => !tags.includes(element));
        const removedTypes = tags.filter(element => !selectedTags.includes(element));
        const userFullName = `${user.first_name} ${user.last_name}`;

        if (addedTypes.length) {
          const currentTypes = addedTypes.map(element => `"${element}"`).join(' and ');
          const messageAddedTypes = `${userFullName} added type ${currentTypes} to the exception`;
          if (messageAddedTypes) provider.addNote(target, createNoteBody(messageAddedTypes));
        }

        if (removedTypes.length) {
          const currentTypes = removedTypes.map(element => `"${element}"`).join(' and ');
          const messageRemovedTypes = `${userFullName} removed type ${currentTypes} from the exception`;
          if (messageRemovedTypes) provider.addNote(target, createNoteBody(messageRemovedTypes));
        }
      });

    if (action) provider.addNote(target, note);

    investorProvider
      .get(investor.id, { with_all_accounts: true })
      .then(({ data: investor }) => {
        setInvestor(investor);
        closeElement.current.click();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (!investor || (accountId && _.isEmpty(selectedAccount))) return null;

  const selectedGoal = investor.goals.find(g => g.id === selectedAccount.goal);
  const resolved =
    exception &&
    (exception.status === EXCEPTION_STATUS.CLOSED ||
      exception.status === EXCEPTION_STATUS.RESOLVED);

  const disableSaveButton =
    typeof action === 'undefined' || (action === NOTE_OTHER_ACTION_VALUE && !message);

  return ReactDOM.createPortal(
    <Modal
      id="account-notes-modal"
      className="modal-lg dropdown advisor-accounts-notes-modal-container"
      onHide={onHide}
      show={show}
    >
      <ModalHeader />

      <h2>
        {selectedAccount.deleted ? (
          <span>{selectedAccount.display_name}</span>
        ) : (
          <Link to={`/advisor/investors/${investor.id}/account/${selectedAccount.id}/overview`}>
            {selectedAccount.display_name}
          </Link>
        )}
        {(selectedAccount.tags ?? []).map(t => (
          <span className="account-tag">{t}</span>
        ))}
        {exception && (
          <span className={cn('tag-label', DRIFT_STATUS_LABEL_COLOR[exception.status])}>
            {exception.status}
          </span>
        )}
        <GoalsHint goalsMapping={goalsMapping} />
      </h2>

      {selectedGoal && <p className="goal-name">Investment goal: {selectedGoal.name}</p>}

      {selectedAccount?.prism_score_summary?.overall &&
        selectedAccount?.target_score_summary?.overall && (
          <div className="scores">
            <div>
              <span
                className={`btn btn-box btn-${scoreColor(
                  selectedAccount.prism_score_summary.overall
                )}`}
              >
                {selectedAccount.prism_score_summary.overall.toFixed(1)}
              </span>
              Portfolio Risk
            </div>
            <div>
              <span
                className={`btn btn-box btn-${scoreColor(
                  selectedAccount.target_score_summary.overall
                )}`}
              >
                {selectedAccount.target_score_summary.overall.toFixed(1)}
              </span>
              Risk Tolerance
            </div>
            <DriftBox entity={selectedAccount} />
          </div>
        )}

      {exception?.account && (
        <div className="advisor-info">
          <p className="name">
            Advisor: {getManagerNames(exception.account.managers, investor.advisor)}
          </p>
        </div>
      )}

      <div
        className="investor-info"
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {exception && (
          <p className="name">
            Client: <Link to={`/advisor/investors/${investor.id}`}>{investor.full_name}</Link>
          </p>
        )}

        {expanded &&
          investor?.aggregated_prism_scores?.overall &&
          investor?.aggregated_target_scores?.overall && (
            <div className="scores">
              <div>
                <span
                  className={`btn btn-box btn-${scoreColor(
                    investor.aggregated_prism_scores.overall
                  )}`}
                >
                  {investor.aggregated_prism_scores.overall.toFixed(1)}
                </span>
                Portfolio Risk
              </div>
              <div>
                <span
                  className={`btn btn-box btn-${scoreColor(
                    investor.aggregated_target_scores.overall
                  )}`}
                >
                  {investor.aggregated_target_scores.overall.toFixed(1)}
                </span>
                Risk Tolerance
              </div>
              <DriftBox entity={investor} />
            </div>
          )}

        {expanded && (
          <p>
            Total {investor.accounts.length} account(s) / {exception.total} unresolved exceptions
          </p>
        )}

        <div className="expander-arrow-icon">
          {expanded ? (
            <span className="fs-icon-angle-up" />
          ) : (
            <span className="fs-icon-angle-down" />
          )}
        </div>
      </div>

      <div className="modal-body">
        <div className="edit-section">
          <Select
            className="msg-option"
            onChange={({ value }) => setAction(value)}
            options={getNoteActionOptions(
              exceptionsActions,
              authProvider.hasCompliancePermissionsOrAbove(user)
            )}
            styles={{
              control: styles => ({
                ...styles,
                backgroundColor: '#ecfaff',
                fontSize: 14
              }),
              option: styles => ({
                ...styles,
                fontSize: 14
              }),
              groupHeading: styles => ({
                ...styles,
                color: 'black'
              })
            }}
            placeholder="Select an action (Required)"
          />

          {exceptionId && (
            <div className="advisor-accounts-notes-modal-container__labels">
              <Select
                check
                className="dropdown advisor-accounts-notes-modal-container__labels-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                components={{ Option }}
                defaultValue={defaultTags}
                hideSelectedOptions={false}
                isDisabled={!authProvider.hasCompliancePermissionsOrAbove(user)}
                isMulti
                name="Type"
                onChange={filterByTags}
                options={getNoteTypeOptions(exceptionsTags)}
                placeholder="Type"
                showFirstOption
                styles={{
                  singleValue: styles => ({
                    ...styles,
                    ':before': {
                      content: '"Type: "'
                    }
                  }),
                  option: styles => ({
                    ...styles,
                    backgroundColor: 'white',
                    color: 'inherit',
                    textAlign: 'left'
                  }),
                  control: styles => ({
                    ...styles,
                    width: '232px',
                    fontSize: 14
                  }),
                  multiValue: styles => ({
                    ...styles,
                    backgroundColor: '#ebf8fe',
                    border: '1px solid #09acf8',
                    borderRadius: 3
                  })
                }}
              />
            </div>
          )}

          <Textarea
            placeholder={`Leave an optional note. Tag your team member by typing @.\nFor example, "@adam-smith"`}
            onChange={handleChange}
          />
        </div>

        <NotesList layout={NOTE_LAYOUTS.MODAL} target={investor} withAccounts={[account]} />

        {allowSnooze && (
          <div className="compliance-options">
            <div className="compliance-option">
              {snooze && exception && exception.snoozed_until ? (
                <Choice
                  title={`Drift exception snoozed until ${moment(exception.snoozed_until).format(
                    'LL'
                  )}`}
                  checked={snooze}
                  toggle={markForSnooze}
                  disabled={resolved || closeException}
                />
              ) : (
                <>
                  <Choice
                    title="Snooze drift exception for"
                    checked={snooze}
                    toggle={markForSnooze}
                    disabled={resolved || closeException}
                  />
                  <input
                    type="text"
                    name="months"
                    onChange={updateSnoozeTime}
                    value={snoozeTime}
                    disabled={resolved || closeException}
                  />
                  <span>months</span>
                </>
              )}
            </div>
            <div className="compliance-option">
              <Choice
                title="Close this exception permanently."
                checked={closeException}
                toggle={markForClose}
                disabled={resolved || snooze}
              />
            </div>
          </div>
        )}

        <div className="actions">
          <button
            type="button"
            ref={closeElement}
            className="btn btn-secondary-2"
            data-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={saving || disableSaveButton}
            onClick={handleSave}
          >
            {saving ? 'Saving ...' : 'Save'}
          </button>
        </div>
      </div>
    </Modal>,
    document.getElementById('app-portal')
  );
};

AccountNotesModal.propTypes = {
  accountId: PropTypes.number.isRequired,
  investorId: PropTypes.number.isRequired,
  exceptionId: PropTypes.number,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  tags: PropTypes.array,
  user: PropTypes.object
};

AccountNotesModal.defaultProps = {
  exceptionId: null,
  onHide: () => {},
  show: false,
  tags: [],
  user: {}
};

export default connect(state => ({
  user: state.auth.user
}))(AccountNotesModal);
