// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker {
  position: relative;
  flex-basis: 165px;
}
.color-picker fieldset > div {
  display: none;
}
.color-picker .color-picker__example {
  position: absolute;
  top: 39px;
  left: 7px;
  height: 23px;
  width: 35px;
  border-radius: 3px;
  cursor: pointer;
}
.color-picker .color-picker__text {
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  gap: 2px;
}
.color-picker .color-picker__text.disabled {
  opacity: 0.3;
}
.color-picker .text--help {
  font-size: 0.65rem;
  font-weight: 400;
}
.color-picker .color-picker__input {
  padding-left: 50px;
  max-width: 130px;
}
.color-picker .color-picker__input:disabled {
  opacity: 0.3;
  background-color: white;
}
.color-picker .color-picker__modal {
  position: absolute;
  margin-top: 8px;
  z-index: 100;
}
.color-picker .modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/color-picker/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,iBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;AAHJ;AAKI;EACE,YAAA;AAHN;AAOE;EACE,kBAAA;EACA,gBAAA;AALJ;AAQE;EACE,kBAAA;EACA,gBAAA;AANJ;AAQI;EACE,YAAA;EACA,uBAAA;AANN;AAUE;EACE,kBAAA;EACA,eAAA;EACA,YAAA;AARJ;AAWE;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;AATJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.color-picker {\n  position: relative;\n  flex-basis: 165px;\n\n  fieldset > div {\n    display: none;\n  }\n\n  .color-picker__example {\n    position: absolute;\n    top: 39px;\n    left: 7px;\n    height: 23px;\n    width: 35px;\n    border-radius: 3px;\n    cursor: pointer;\n  }\n\n  .color-picker__text {\n    display: flex;\n    flex-direction: column;\n    padding-bottom: 2px;\n    gap: 2px;\n\n    &.disabled {\n      opacity: 0.3;\n    }\n  }\n\n  .text--help {\n    font-size: 0.65rem;\n    font-weight: 400;\n  }\n\n  .color-picker__input {\n    padding-left: 50px;\n    max-width: 130px;\n\n    &:disabled {\n      opacity: 0.3;\n      background-color: white;\n    }\n  }\n\n  .color-picker__modal {\n    position: absolute;\n    margin-top: 8px;\n    z-index: 100;\n  }\n\n  .modal__backdrop {\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
