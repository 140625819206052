import Choice from 'components/form/choice';
import React from 'react';
import FilterContainer from '../common/filter-container';
import { MODEL_IS_BENCHMARK_ATTR, MODEL_IS_STRATEGY_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const ModelOtherFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const onChangeBenchmarkHandler = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [MODEL_IS_BENCHMARK_ATTR]: !prevFilters[MODEL_IS_BENCHMARK_ATTR]
    }));
    setRequiresPageIndexReset(true);
  };

  const onChangeStrategyHandler = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [MODEL_IS_STRATEGY_ATTR]: !prevFilters[MODEL_IS_STRATEGY_ATTR] || undefined
    }));
    setRequiresPageIndexReset(true);
  };

  return (
    <FilterContainer
      attrs={[MODEL_IS_BENCHMARK_ATTR, MODEL_IS_STRATEGY_ATTR]}
      filters={filters}
      label="Others"
    >
      <div style={{ display: 'flex', gap: 5 }}>
        <Choice
          className="m-0"
          checked={!!filters?.[MODEL_IS_BENCHMARK_ATTR]}
          title="Benchmark"
          toggle={onChangeBenchmarkHandler}
        />
        <Choice
          className="m-0"
          checked={!!filters?.[MODEL_IS_STRATEGY_ATTR]}
          title="Strategy"
          toggle={onChangeStrategyHandler}
        />
      </div>
    </FilterContainer>
  );
};

ModelOtherFilter.propTypes = { ...filterPropTypes };

ModelOtherFilter.defaultProps = { ...filterDefaultProps };

export default ModelOtherFilter;
