// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline-text-editor > span {
  display: flex;
  gap: 5px;
}
.inline-text-editor .text-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.inline-text-editor .text-wrapper span {
  line-height: 20px;
  display: inline-block;
}
.inline-text-editor .text-wrapper span:focus {
  outline: none;
}
.inline-text-editor .text-wrapper[data-disabled=true] span,
.inline-text-editor .text-wrapper[data-disabled=true] button {
  cursor: not-allowed;
}
.inline-text-editor .text-wrapper[data-disabled=true] img {
  filter: grayscale(100%);
}
.inline-text-editor .btn-save:disabled {
  opacity: 0.3;
}
.inline-text-editor .editor-wrapper {
  max-width: 555px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: inline-block;
  overflow: hidden;
}
.inline-text-editor .editor-wrapper input {
  background-color: transparent;
  width: 100%;
  border: none;
}
.inline-text-editor .editor-wrapper input:focus {
  outline: none;
}
.inline-text-editor .icon {
  display: inline-block;
  height: 20px;
}
.inline-text-editor .underline {
  border-bottom: 1px dashed #09acf8;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/inline-text-editor/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,QAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAHJ;AAKI;EACE,iBAAA;EACA,qBAAA;AAHN;AAKM;EACE,aAAA;AAHR;AAQM;;EAEE,mBAAA;AANR;AASM;EACE,uBAAA;AAPR;AAYE;EACE,YAAA;AAVJ;AAaE;EACE,gBAAA;EACA,2BAAA;EAAA,sBAAA;EACA,qBAAA;EACA,gBAAA;AAXJ;AAaI;EACE,6BAAA;EACA,WAAA;EACA,YAAA;AAXN;AAaM;EACE,aAAA;AAXR;AAgBE;EACE,qBAAA;EACA,YAAA;AAdJ;AAiBE;EACE,iCAAA;EACA,WAAA;AAfJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.inline-text-editor {\n  & > span {\n    display: flex;\n    gap: 5px;\n  }\n\n  .text-wrapper {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    span {\n      line-height: 20px;\n      display: inline-block;\n\n      &:focus {\n        outline: none;\n      }\n    }\n\n    &[data-disabled='true'] {\n      span,\n      button {\n        cursor: not-allowed;\n      }\n\n      img {\n        filter: grayscale(100%);\n      }\n    }\n  }\n\n  .btn-save:disabled {\n    opacity: 0.3;\n  }\n\n  .editor-wrapper {\n    max-width: 555px;\n    min-width: fit-content;\n    display: inline-block;\n    overflow: hidden;\n\n    input {\n      background-color: transparent;\n      width: 100%;\n      border: none;\n\n      &:focus {\n        outline: none;\n      }\n    }\n  }\n\n  .icon {\n    display: inline-block;\n    height: 20px;\n  }\n\n  .underline {\n    border-bottom: 1px dashed $primary;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
