import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  FOR_LANDSCAPE_ORIENTATION,
  LIGHT_CYAN_COLOR,
  LIGHT_GRAPHITE_COLOR,
  TABLE_CELL_HEADER_01,
  TABLE_CELL_HEADER_02
} from 'reports/base/styles';

const CONTENT_CELL_BACKGROUND_COLOR = '#f7f7f7';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  label: { marginLeft: 5 },
  headerText: { textAlign: 'center' },
  headerTargetCell: { ...TABLE_CELL_HEADER_01 },
  headerRecommendedCell: { ...TABLE_CELL_HEADER_02, marginRight: 0 },
  contentCell: {
    ...BASE_TABLE_CELL,
    marginRight: 2,
    paddingVertical: 0,
    paddingHorizontal: 0
  },
  contentWrapper: { ...BASE_TABLE_ROW, marginBottom: 1, flexGrow: 1 },
  contentScore: {
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONTENT_CELL_BACKGROUND_COLOR,
    paddingVertical: 6
  },
  contentLabel: {
    flexGrow: 2,
    flexBasis: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 36,
    backgroundColor: CONTENT_CELL_BACKGROUND_COLOR,
    paddingVertical: 6,
    paddingHorizontal: 8
  },
  contentAmount: {
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: CONTENT_CELL_BACKGROUND_COLOR,
    paddingVertical: 6,
    paddingHorizontal: 8,
    textAlign: 'right',
    [FOR_LANDSCAPE_ORIENTATION]: {
      flexGrow: 1
    }
  },
  contentWeight: {
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: CONTENT_CELL_BACKGROUND_COLOR,
    paddingVertical: 6,
    paddingHorizontal: 8,
    textAlign: 'right',
    [FOR_LANDSCAPE_ORIENTATION]: {
      flexGrow: 1
    }
  },
  totalTargetCell: { backgroundColor: LIGHT_GRAPHITE_COLOR },
  totalRecommendedCell: { backgroundColor: LIGHT_CYAN_COLOR }
});

export default styles;
