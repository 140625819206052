import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatNumber } from '../utils';
import styles from './styles';

const Row = ({ isDraft, depth, label, target, recommended, benchmark }) => {
  let diffStyles = {};
  if (isDraft && target > recommended) diffStyles = styles.recommendedPositiveText;
  if (isDraft && target < recommended) diffStyles = styles.recommendedNegativeText;
  if (!isDraft && recommended > target) diffStyles = styles.recommendedPositiveText;
  if (!isDraft && recommended < target) diffStyles = styles.recommendedNegativeText;

  return (
    <View style={[styles.row, styles[`rowLevel${depth}`]]} wrap={false}>
      <View style={styles.cell}>
        <Text style={[styles[`cellTextLevel${depth}`], styles[`firstCellTextLevel${depth}`]]}>
          {label}
        </Text>
      </View>
      <View style={[styles.cell, styles.recommendedCell]}>
        {isDraft && (
          <Text
            style={[
              styles.cellText,
              styles.recommendedCellText,
              styles[`cellTextLevel${depth}`],
              diffStyles
            ]}
          >
            {formatNumber(target - recommended, true)}
          </Text>
        )}
        <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
          {formatNumber(target)}
        </Text>
      </View>
      {!_.isNil(recommended) && (
        <View style={[styles.cell, styles.recommendedCell]}>
          {!isDraft && (
            <Text
              style={[
                styles.cellText,
                styles.recommendedCellText,
                styles[`cellTextLevel${depth}`],
                diffStyles
              ]}
            >
              {formatNumber(recommended - target, true)}
            </Text>
          )}
          <Text
            style={[styles.cellText, styles.recommendedCellText, styles[`cellTextLevel${depth}`]]}
          >
            {formatNumber(recommended)}
          </Text>
        </View>
      )}
      {!_.isNil(benchmark) && (
        <View style={[styles.cell, { marginRight: 0 }]}>
          <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
            {formatNumber(benchmark)}
          </Text>
        </View>
      )}
    </View>
  );
};

Row.propTypes = {
  isDraft: PropTypes.bool,
  benchmark: PropTypes.number,
  depth: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  recommended: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired
};

Row.defaultProps = {
  isDraft: false,
  benchmark: null
};

export default Row;
