import Choice from 'components/form/choice';
import React, { useEffect, useState } from 'react';
import FilterContainer from '../common/filter-container';
import { MODEL_VISIBILITY_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const COMPANY_VISIBILITY = 0;
const DEFAULT_VISIBILITY = undefined;

const ModelVisibilityFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const [checked, setChecked] = useState(filters?.[MODEL_VISIBILITY_ATTR] === COMPANY_VISIBILITY);

  const onChangeHandler = () => {
    setChecked(prevChecked => !prevChecked);
    setRequiresPageIndexReset(true);
  };

  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [MODEL_VISIBILITY_ATTR]: checked ? COMPANY_VISIBILITY : DEFAULT_VISIBILITY
    }));
  }, [checked]);

  return (
    <FilterContainer attrs={[MODEL_VISIBILITY_ATTR]} filters={filters} label="Visibility">
      <Choice title="Company" checked={checked} toggle={onChangeHandler} />
    </FilterContainer>
  );
};

ModelVisibilityFilter.propTypes = { ...filterPropTypes };

ModelVisibilityFilter.defaultProps = { ...filterDefaultProps };

export default ModelVisibilityFilter;
