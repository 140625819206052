import { CustomerSupportEmailLink } from 'constants/contact';
import { AuthenticationContext } from 'containers/auth';
import HeaderAuth from 'containers/layout/header-dark';
import HeroFormContainer from 'pages/utils/hero-form-container';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import './styles.scss';

const Checkout = ({ children }) => {
  const { authProvider, userProvider, user } = useContext(AuthenticationContext);
  const upgrade = user?.advisor?.company?.status === 'active';

  return (
    <div>
      <HeaderAuth />
      <div id="Checkout">
        {/* pending notification for non-admin users */}
        {!authProvider.isAdmin(user) && userProvider.isPending(user) && (
          <HeroFormContainer>
            <h1 className="text-sm-center signup-title">Subscription On Hold</h1>
            <p>
              It seems there&apos;s an action required regarding the billing of your Stratifi
              subscription. Don&apos;t worry, your data will remain safe until the subscription is
              resumed. Once the payment is processed, you&apos;ll be able to fully utilize
              Stratifi&apos;s capabilities.
            </p>
            <p>
              Kindly coordinate with your company administrator to ensure the necessary steps are
              taken.
            </p>
            <p>
              While you&apos;re here, why not dive into our{' '}
              <a
                href="https://help.stratifi.com/video-lessons"
                rel="noopener noreferrer"
                target="_blank"
              >
                video tutorials
              </a>
              ? Discover insider tips and strategies to unlock the full potential of Stratifi for
              your clients.
            </p>
            <p className="mb-0">
              If you run into any issues or have questions, please reach out to us at{' '}
              <CustomerSupportEmailLink />.
            </p>
          </HeroFormContainer>
        )}

        {/* activate/upgrade notification for non-admin users */}
        {!authProvider.isAdmin(user) && !userProvider.isPending(user) && (
          <HeroFormContainer>
            <p className="mb-0">
              Please contact your company administrator to {upgrade ? 'upgrade' : 'activate'} the
              subscription. If you have any question, don&apos;t hesitate to contact us at{' '}
              <CustomerSupportEmailLink />.
            </p>
          </HeroFormContainer>
        )}

        {/* checkout flow for admin users */}
        {authProvider.isAdmin(user) && <div className="checkout-wrapper">{children}</div>}
      </div>
    </div>
  );
};

Checkout.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

Checkout.defaultProps = {
  children: null
};

Checkout.propTypes = {
  children: PropTypes.element
};

export default Checkout;
