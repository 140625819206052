// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ips-risk-tolerance-questionnaires {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ips-risk-tolerance-questionnaires--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/ips/body/sections/risk-tolerance/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AADF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.ips-risk-tolerance-questionnaires {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.ips-risk-tolerance-questionnaires--loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
