/* eslint-disable no-control-regex */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import {
  ALLOWEB_KEY_CODES,
  ALLOWED_IMAGE_FILE_SIZE,
  ALLOWED_IMAGE_MIME_TYPES,
  BASE_REACT_SUMMERNOTE_OPTIONS
} from './constants';
import './styles.scss';

const QuillField = ({ className, disabled, field, options, qfRef }) => {
  const quillFieldRef = qfRef || useRef(null);

  const [characterCounter, setCharacterCounter] = useState(null);

  const value = field.value === null ? field.initialValue : field.value;

  // Detects if the text includes special characters such as:
  // - Accented characters like á, é, í, ó, ú, etc.
  // - Special symbols like €, £, ¥, ©, ®, etc.
  // - Characters from other alphabets like Ω, Д, 漢, こんにちは, etc.
  // - Emojis 🎉, 😀, 🌟, etc.
  // - Special control or formatting characters.
  const hasSpecialCharacters = /[^\u0000-\u007F]/.test(value);

  // Allows to correctly parse HTML code
  const onInit = ({ summernote }) => {
    summernote('code', value);
  };

  const onImageUpload = images => {
    // images (FileList) does not support ordinary array methods
    for (let i = 0; i < images.length; i++) {
      // Stores as bas64 string in the text.
      // Should potentially be stored separately and include just the url
      const image = images[i];
      // Silently fails if condition is not met
      if (image.size <= ALLOWED_IMAGE_FILE_SIZE && ALLOWED_IMAGE_MIME_TYPES.includes(image.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // ReactSummernote.insertImage is a static method that allows a callback used to set
          // the width and height of the image as inline CSS styles. This will ensure that all images
          // have well-defined dimensions, which are necessary when using @react-pdf/renderer.
          ReactSummernote.insertImage(reader.result, ([image]) => {
            image.style.width = `${image.width}px`;
            image.style.height = `${image.height}px`;
          });
        };
        reader.readAsDataURL(image);
      }
    }
  };

  useEffect(() => {
    const textContentLength = quillFieldRef?.current?.noteEditable?.[0]?.textContent?.length;
    setCharacterCounter(textContentLength);
  }, [quillFieldRef?.current]);

  /* handleContentOnKeyDown & handleContentOnPaste
   * are used to prevent the user from typing more than the
   * allowed words when the maxLength option is set with a number value.
   */
  const handleContentOnKeyDown = e => {
    if (options.maxLength) {
      const textContentLength = e?.currentTarget?.textContent?.length;
      if (textContentLength >= options.maxLength && !ALLOWEB_KEY_CODES.includes(e.keyCode))
        e.preventDefault();
    }
  };

  const handleContentOnPaste = e => {
    if (options.maxLength) {
      const clipboardData = e?.clipboardData || e?.originalEvent?.clipboardData;
      const pastedText = clipboardData.getData('text/plain');
      const content = e.currentTarget.textContent + pastedText;

      if (content.length > options.maxLength) {
        e.preventDefault();
        const truncatedContent = content.substring(0, options.maxLength);
        e.currentTarget.textContent = truncatedContent;
      }
    }
  };

  const handleCounterDisplay = () => {
    if (options.maxLength) {
      const textContentLength = quillFieldRef?.current?.noteEditable?.[0]?.textContent?.length;
      const counter = `${textContentLength ?? characterCounter} / ${options.maxLength}`;
      return <span className="counter">{counter}</span>;
    }
    return null;
  };

  return (
    <div className="quill-field">
      <ReactSummernote
        {...field}
        className={className}
        disabled={disabled}
        onImageUpload={onImageUpload}
        onInit={onInit}
        options={{ ...BASE_REACT_SUMMERNOTE_OPTIONS, ...options }}
        ref={quillFieldRef}
        onKeyDown={handleContentOnKeyDown}
        onPaste={handleContentOnPaste}
      >
        {value}
      </ReactSummernote>
      {handleCounterDisplay()}
      {field.touched && field.error && <span className="text-danger error">{field.error}</span>}
      {hasSpecialCharacters && (
        <div className="special-characters-warning">
          <span className="fs-icon-exclamation-circle warning-icon" />
          Special characters have been detected. These characters may not display correctly in the
          PDF report.
        </div>
      )}
    </div>
  );
};

QuillField.defaultProps = {
  className: '',
  disabled: false,
  options: {},
  qfRef: null
};

QuillField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  options: PropTypes.object,
  qfRef: PropTypes.func
};

export default QuillField;
