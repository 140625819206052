import PropTypes from 'prop-types';

export const filterPropTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
  setRequiresPageIndexReset: PropTypes.func.isRequired
};

export const filterDefaultProps = {
  filters: {}
};
