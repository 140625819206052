import { MODEL_TYPES } from 'components/advisor/models/constants';
import React, { useMemo } from 'react';
import FilterContainer from '../common/filter-container';
import MultiSelectFilter from '../common/multi-select';
import { MODEL_TYPE_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const ModelTypeFilter = ({ filters, setFilters }) => {
  const onChangeHandler = options => {
    const selected = options.map(option => option.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [MODEL_TYPE_ATTR]: selected.length ? selected : undefined
    }));
  };

  const modelTypeOptions = useMemo(
    () => MODEL_TYPES.map(type => ({ label: type, value: type })),
    [JSON.stringify(MODEL_TYPES)]
  );

  return (
    <FilterContainer attrs={[MODEL_TYPE_ATTR]} filters={filters} label="Type">
      <MultiSelectFilter
        defaultOptions={modelTypeOptions.filter(type =>
          (filters?.[MODEL_TYPE_ATTR] || []).includes(type.value)
        )}
        onChange={onChangeHandler}
        options={modelTypeOptions}
        placeholder="View All"
      />
    </FilterContainer>
  );
};

ModelTypeFilter.propTypes = { ...filterPropTypes };

ModelTypeFilter.defaultProps = { ...filterDefaultProps };

export default ModelTypeFilter;
