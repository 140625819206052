import BubbleInput from 'app/components/form/bubble-input';
import { VerboseErrorInput as Input, Textarea } from 'components/form';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { cloneDeep } from 'lodash';
import CollaborationSelect from 'pages/collaboration-hub/select';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { CANCEL_BUTTON, DELETE_COLUMN, PRIMARY_COLUMN, SAVE_BUTTON } from '../constants';
import columns from './edit-columns';
import './styles.scss';

const validate = values => {
  const errors = {};
  errors.name = validation.required(values.name);
  return errors;
};

const TeamEdit = ({ data, errors, fields, onConfirm, onHide, ...rest }) => {
  const [members, setMembers] = useState([]);
  const [selectedRepCodes, setSelectedRepCodes] = useState([]);

  const onAddMember = (option, details) => {
    const newMember = {
      advisor: { id: option.value, user: { email: details.email, name: option.label } },
      is_primary: false
    };
    setMembers(prevMembers => [...prevMembers, newMember]);
  };

  const onRemoveMember = rowInfo => {
    const {
      original: { advisor }
    } = rowInfo;
    const filteredMembers = members.filter(member => member.advisor.id !== advisor.id);
    setMembers(filteredMembers);
  };

  const onSetPrimaryMember = rowInfo => {
    const { index } = rowInfo;
    setMembers(prevMembers => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        is_primary: !updatedMembers[index].is_primary
      };
      return updatedMembers;
    });
  };

  useEffect(() => {
    setMembers(cloneDeep(data?.team?.team_memberships) || []);
    setSelectedRepCodes(cloneDeep(data?.team?.rep_codes) || []);
  }, [JSON.stringify(data)]);

  const handleSubmit = e => {
    e.preventDefault();
    onConfirm(
      {
        ...data.team,
        name: fields.name.value,
        rep_codes: selectedRepCodes,
        description: fields.description.value,
        team_memberships: members.map(member => ({
          advisor: member.advisor.id,
          is_primary: member.is_primary
        }))
      },
      data.team.id
    );
  };

  const handleClickElements = (_, rowInfo, column) => {
    if (DELETE_COLUMN === column.id)
      return {
        onClick: e => {
          e.stopPropagation();
          onRemoveMember(rowInfo);
        }
      };

    if (PRIMARY_COLUMN === column.id)
      return {
        toggle: e => {
          e.stopPropagation();
          onSetPrimaryMember(rowInfo);
        }
      };

    return {};
  };

  return (
    <Modal id="team-delete-modal" className="modal-lg" onHide={() => onHide({})} show={data.show}>
      <ModalHeader />
      <ModalBody>
        <div className="text-left mt-3 mb-4">
          <h4>{rest.title} team</h4>
        </div>
        <form>
          <div className="team__detail">
            <label htmlFor="team-name" className="detail-input">
              <b>Team Name</b>
              <Input
                type="text"
                id="team-name"
                name="team-name"
                autoComplete="off"
                fieldsetClassName="input"
                {...fields.name}
              />
            </label>

            <BubbleInput
              name="team-description"
              label={<b>Rep Codes</b>}
              initialValue={data?.team?.rep_codes}
              onSelect={bubbles => setSelectedRepCodes(bubbles)}
              maxLength={50}
            />

            <label htmlFor="team-name" className="input mt-3 mb-4">
              <b>Team Description</b>
              <Textarea
                rows={2}
                type="text"
                maxLength="100"
                name="team-name"
                autofilled={false}
                {...fields.description}
              >
                {fields.description.value}
              </Textarea>
              <p className="input__area-counter">{fields?.description?.value?.length}/100</p>
            </label>

            <label htmlFor="collaboration-member">
              <b>Select Member</b>
              <CollaborationSelect
                onAdd={onAddMember}
                selectedOptions={members.map(member => member.advisor.id)}
              />
            </label>
          </div>
          <br />
          <div className="team__members">
            <ReactTable
              minRows={1}
              columns={columns}
              data={members || [{}]}
              getTdProps={handleClickElements}
              noDataText={<div style={{ marginTop: 50 }}>No members found.</div>}
              showPagination={false}
              sortable={false}
            />
          </div>

          <div className="text-right team__buttons">
            <button type="button" className="btn btn-black mr-2" onClick={() => onHide({})}>
              {CANCEL_BUTTON}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={errors.name}
            >
              {SAVE_BUTTON}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

TeamEdit.defaultProps = {
  data: {},
  fields: {},
  onConfirm: () => {},
  onHide: () => {},
  errors: {}
};

TeamEdit.propTypes = {
  data: PropTypes.object,
  fields: PropTypes.object,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  errors: PropTypes.object
};

export default compose(
  connect((_, { data }) => ({
    initialValues: {
      name: data?.team?.name || '',
      description: data?.team?.description || ''
    }
  })),
  reduxForm({
    form: 'editTeam',
    fields: ['name', 'repCodes', 'description'],
    initialValues: {},
    validate
  })
)(memo(TeamEdit));
