import cn from 'classnames';
import { AuthenticationContext } from 'containers/auth';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackAmplitudeEvent } from 'utils/tracking';
import propTypes from '../../props';
import './styles.scss';

const Question = ({
  children,
  className,
  hasPreviousAnswers,
  isFirst,
  isLast,
  isSectionSeparator,
  nextDisabled,
  nextStep,
  prevStep,
  question
}) => {
  const { authProvider, user } = useContext(AuthenticationContext);

  const isInvestor = authProvider.isInvestor(user);

  return (
    <div
      className={cn(`full-question ${question.question_type}`, {
        [question.data?.flavor]: question.data?.flavor,
        [className]: className
      })}
      data-investor={isInvestor}
    >
      {!isSectionSeparator && (
        <div className="header">
          <h5>
            <span>
              <FormattedMessage
                id="rtq.common.question"
                values={{ position: question.question_number ?? question.position }}
              />
            </span>{' '}
            {question.question}
          </h5>

          {question.description && <p className="description">{question.description}</p>}
        </div>
      )}
      <div className="content">{children}</div>

      {!isSectionSeparator && hasPreviousAnswers && !question.answer && (
        <p>
          <FormattedMessage id="rtq.common.new-question-version" />
        </p>
      )}

      <div className="actions">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            nextStep();
            if (!isLast)
              trackAmplitudeEvent('rtq.answer.next', {
                flavor: question?.data?.flavor,
                questionNumber: question?.position,
                questionType: question?.question_type
              });
          }}
          disabled={nextDisabled}
        >
          {isLast ? (
            <FormattedMessage id="rtq.common.submit" />
          ) : (
            <FormattedMessage id="rtq.common.next" />
          )}
        </button>
        <br />
        {!isFirst && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              prevStep();
              trackAmplitudeEvent('rtq.answer.back', {
                flavor: question?.data?.flavor,
                questionNumber: question?.position,
                questionType: question?.question_type
              });
            }}
          >
            &lt; <FormattedMessage id="rtq.common.back" />
          </button>
        )}
      </div>
    </div>
  );
};

Question.propTypes = propTypes;

Question.defaultProps = {
  className: '',
  isFirst: false,
  isLast: false,
  isSectionSeparator: false
};

export default Question;
