import React, { useMemo } from 'react';
import {
  PRISM_FACTOR_ATTR,
  ACCOUNT_PRISM_FACTOR_ATTRS,
  HOUSEHOLD_PRISM_FACTOR_ATTRS,
  INVESTOR_PRISM_FACTOR_ATTRS
} from '../../constants';
import { filterDefaultProps, filterPropTypes } from '../../types';
import FilterContainer from '../filter-container';
import SingleSelectFilter from '../single-select';
import { PRISM_FACTORS } from './utils';

const DEFAULT_VALUE = { label: 'Overall', value: undefined };

const PrismFactorFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const onChangeHandler = ({ value }) => {
    if (value !== filters[PRISM_FACTOR_ATTR]) {
      Object.keys(filters).forEach(filter => {
        if (
          [
            ...ACCOUNT_PRISM_FACTOR_ATTRS,
            ...HOUSEHOLD_PRISM_FACTOR_ATTRS,
            ...INVESTOR_PRISM_FACTOR_ATTRS
          ].includes(filter) &&
          Number.isFinite(filters[filter])
        )
          setFilters(prevFilters => ({ ...prevFilters, [filter]: undefined }));
      });
      setFilters(prevFilters => ({ ...prevFilters, [PRISM_FACTOR_ATTR]: value }));
      setRequiresPageIndexReset(true);
    }
  };

  const prismFactorOptions = useMemo(
    () => Object.entries(PRISM_FACTORS).map(([value, label]) => ({ label, value })),
    [JSON.stringify(PRISM_FACTORS)]
  );

  const defaultValue =
    prismFactorOptions.find(option => option.value === filters?.[PRISM_FACTOR_ATTR]) ||
    DEFAULT_VALUE;

  return (
    <FilterContainer
      attrs={[PRISM_FACTOR_ATTR]}
      filters={filters}
      label="PRISM Factor"
      description="(displayed in the PRISM column and used for filtering)"
    >
      <SingleSelectFilter
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        options={[DEFAULT_VALUE, ...prismFactorOptions]}
      />
    </FilterContainer>
  );
};

PrismFactorFilter.propTypes = { ...filterPropTypes };

PrismFactorFilter.defaultProps = { ...filterDefaultProps };

export default PrismFactorFilter;
