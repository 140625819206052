import React from 'react';
import FilterContainer from '../common/filter-container';
import RiskLevelFilter from '../common/risk-level';
import { INVESTOR_DRIFT_LEVEL_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const InvestorDriftLevelFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[INVESTOR_DRIFT_LEVEL_ATTR]}
    filters={filters}
    label="Investor Drift Level"
  >
    <RiskLevelFilter
      attr={INVESTOR_DRIFT_LEVEL_ATTR}
      filters={filters}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

InvestorDriftLevelFilter.propTypes = { ...filterPropTypes };

InvestorDriftLevelFilter.defaultProps = { ...filterDefaultProps };

export default InvestorDriftLevelFilter;
