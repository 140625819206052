import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const BubbleInput = ({
  initialValue,
  onSelect,
  placeholder,
  className,
  label,
  name,
  maxLength
}) => {
  const [inputValue, setInputValue] = useState('');
  const [bubbles, setBubbles] = useState(initialValue || []);

  const handleInputChange = e => {
    const { value } = e.target;
    const regex = new RegExp(`^[^,]{0,${maxLength}}$`);
    if (regex.test(value)) setInputValue(value);
  };

  const addBubble = () => {
    if (inputValue.trim() !== '') {
      const updatedBubbles = [...bubbles, inputValue.trim().replace(/[,]/gi, '')];
      setBubbles(updatedBubbles);
      onSelect(updatedBubbles);
      setInputValue('');
    }
  };

  const handleInputKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addBubble();
    }
  };

  const removeBubble = index => {
    const updatedBubbles = [...bubbles];
    updatedBubbles.splice(index, 1);
    onSelect(updatedBubbles);
    setBubbles(updatedBubbles);
  };

  return (
    <div className={`bubble-input ${className}`}>
      <label className="bubble-input__label" htmlFor={name}>
        {label}
        <input
          className="bubble-input__input"
          type="text"
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          placeholder={placeholder}
          autoComplete="off"
        />
        {inputValue && (
          <button className="bubble-input__add-btn" type="button" onClick={addBubble}>
            Add
          </button>
        )}
      </label>
      <div className="bubble-input__container">
        {bubbles.map((bubble, index) => (
          <div className="bubble-input__bubble" key={`${bubble}-${name}`}>
            <div>{bubble}</div>
            <button
              type="button"
              className="bubble-input__remove-button"
              onClick={() => removeBubble(index)}
            >
              <span>&times;</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

BubbleInput.defaultProps = {
  initialValue: [],
  onSelect: () => {},
  placeholder: '',
  className: '',
  label: '',
  maxLength: 20
};

BubbleInput.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number
};

export default BubbleInput;
