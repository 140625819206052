/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import Radio from 'components/form/radio';
import BaseSlider from 'components/slider/base';
import RangeSlider from 'components/slider/range';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';
import useWithdrawalRequirement, { TYPE_ANNUAL, TYPE_ONCE, getDefaultDuration } from './hooks';
import './styles.scss';

const SLIDER_OVERLAP_OFFSET = 9;

export const WithdrawalRequirementContent = ({ field, question }) => {
  const {
    age,
    amountInputProps,
    birthday,
    isAnnual,
    isEntityQuestion,
    labels,
    retirement,
    retireStartsIn,
    toggleIsAnnual
  } = useWithdrawalRequirement({ field, question });

  const constants = question.data?.constants || {};

  const defaultRange = useMemo(() => {
    const start = Math.max(0, (field.start?.value || retirement) - age);
    const duration = Number.isInteger(field.duration.value)
      ? field.duration.value
      : getDefaultDuration(start, age);
    const end = start + duration;
    return [start, end];
  }, [field.duration.value, field.start.value, age, retirement]);

  const [inner, setInner] = useState(defaultRange);

  const onChangeSlider = startIn => {
    const startAge = age + startIn;
    if (startAge !== field.start.value) field.start.onChange(startAge);
  };

  const onChangeRangeSlider = ([startIn, finishIn]) => {
    const startAge = age + startIn;
    const duration = finishIn - startIn;
    if (startAge !== field.start.value) field.start.onChange(startAge);
    if (duration !== field.duration.value) field.duration.onChange(duration);
  };

  // eslint-disable-next-line react/function-component-definition
  const getTooltipContent = withOverlap => (value, position) => {
    const bottomValue = isEntityQuestion ? age + value + moment(birthday).year() : age + value;
    let overlap = false;
    let label = '';

    if (position === 0) {
      overlap = inner[position + 1] - inner[position] < SLIDER_OVERLAP_OFFSET;
      label = (
        <FormattedMessage
          id="form.withdrawal-requirement.tooltip-start"
          defaultMessage={constants.tooltip_start}
          values={{ isAnnual }}
        />
      );
    }
    if (position === 1) {
      overlap = inner[position] - inner[position - 1] < SLIDER_OVERLAP_OFFSET;
      label = (
        <FormattedMessage
          id="form.withdrawal-requirement.tooltip-end"
          defaultMessage={constants.tooltip_end}
        />
      );
    }

    return (
      <div
        className={cn('tooltip-value', `tooltip-${position}`, { overlap: withOverlap && overlap })}
      >
        {label}
        <br />
        {bottomValue}
      </div>
    );
  };

  return (
    <div className="withdrawal-requirement-content">
      <div className="requirement-options">
        <div className={cn({ '-checked': isAnnual })}>
          <label htmlFor="annual">
            <Radio checked={isAnnual} onChange={toggleIsAnnual} />
            <div>
              <span style={{ marginRight: 10 }}>
                <FormattedMessage
                  id="form.withdrawal-requirement.annual-income-0"
                  defaultMessage={constants.annual_income_0}
                />
              </span>
              <NumericFormat {...amountInputProps} disabled={!isAnnual} className="input-amount" />
              <span style={{ marginLeft: 10 }}>
                <FormattedMessage
                  id="form.withdrawal-requirement.annual-income-1"
                  defaultMessage={constants.annual_income_1}
                  values={{
                    underline: str => <span className="text-decoration-underline">{str}</span>,
                    years: field.duration.value || '-'
                  }}
                />
              </span>
            </div>
          </label>
        </div>
        <div className={cn({ '-checked': !isAnnual })}>
          <label htmlFor="once">
            <Radio checked={!isAnnual} onChange={toggleIsAnnual} />
            <div>
              <span style={{ marginRight: 10 }}>
                <FormattedMessage
                  id="form.withdrawal-requirement.one-shot-0"
                  defaultMessage={constants.one_shot_0}
                />
              </span>
              <NumericFormat {...amountInputProps} disabled={isAnnual} className="input-amount" />
              <span style={{ marginLeft: 10 }}>
                <FormattedMessage
                  id="form.withdrawal-requirement.one-shot-1"
                  defaultMessage={constants.one_shot_1}
                  values={{
                    underline: str => <span className="text-decoration-underline">{str}</span>,
                    years: retireStartsIn
                  }}
                />
              </span>
            </div>
          </label>
        </div>
      </div>

      {field.type?.value === TYPE_ANNUAL && field.duration.value > 0 && (
        <div className="slider-content">
          <RangeSlider
            defaultValue={defaultRange}
            getTooltipContent={getTooltipContent(true)}
            handleDisabled={[false, false]}
            labels={labels}
            onSlide={setInner}
            onChange={onChangeRangeSlider}
            withHandle
            withTooltip
          />
        </div>
      )}

      {field.type?.value === TYPE_ONCE && (
        <div className="slider-content">
          <BaseSlider
            getTooltipContent={getTooltipContent(false)}
            labels={labels}
            onChange={onChangeSlider}
            value={retireStartsIn}
            withHandle
            withTooltip
          />
        </div>
      )}
    </div>
  );
};

WithdrawalRequirementContent.propTypes = {
  answers: PropTypes.object.isRequired,
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default WithdrawalRequirementContent;
