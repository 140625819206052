import { StyleSheet } from '@react-pdf/renderer';
import { BLIZZAR_BLUE_COLOR, PALE_CYAN_COLOR } from 'reports/base/styles';

const QUESTION_NOTE_BACKGROUND_COLOR = '#eaf0ee';
const QUESTION_NOTE_COLOR = '#303030';

export const styles = StyleSheet.create({
  question: {
    flexDirection: 'row',
    flexGrow: 1,
    marginBottom: 5
  },
  bullet: {
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: PALE_CYAN_COLOR
  },
  bulletText: {
    fontSize: 8,
    color: 'white',
    margin: 'auto',
    fontWeight: 'bold'
  },
  container: {
    flex: 1,
    marginTop: 4,
    marginBottom: 0,
    marginLeft: 15,
    flexGrow: 1
  },
  containerBlankBox: {
    height: 35,
    width: 60,
    marginVertical: 15,
    backgroundColor: BLIZZAR_BLUE_COLOR,
    borderRadius: 2
  },
  containerBlankBoxText: {
    fontSize: 8,
    marginBottom: 4
  },
  containerNote: {
    flexGrow: 1,
    marginVertical: 15,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: QUESTION_NOTE_BACKGROUND_COLOR,
    fontSize: 9,
    color: QUESTION_NOTE_COLOR
  },
  choices: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginHorizontal: 15,
    columnGap: 10,
    rowGap: 6
  },
  choicesSubtitle: {
    marginHorizontal: 15,
    marginBottom: 10
  },
  multiOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  mark: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexBasis: '45%',
    gap: 5
  },
  markCheck: {
    width: 20,
    height: 20,
    borderRadius: 2,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: PALE_CYAN_COLOR
  },
  markText: {
    flex: 1,
    fontSize: 9
  },
  otherChoiceBox: {
    flex: 2,
    height: 20,
    backgroundColor: BLIZZAR_BLUE_COLOR,
    borderRadius: 2
  },
  addressRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10
  },
  tabulatedSummary: {
    width: 300,
    margin: 'auto'
  },
  expectedYearlyReturnChart: {
    objectFit: 'contain',
    height: 100,
    marginTop: 25,
    marginBottom: 10
  }
});

export default styles;
