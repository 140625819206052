import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const TargetScoreLine = ({ name, value }) => (
  <div className="target-score-line">
    <div className="line">
      <div className="gray-line" style={{ width: `${10 * (10 - value)}%` }} />
    </div>
    <div className="score-name">
      <span>{name}</span>
    </div>
  </div>
);

TargetScoreLine.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default TargetScoreLine;
