import ErrorMessage from 'components/error-message';
import { FormGroup, VerboseErrorInput } from 'components/form';
import { propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router';
import MaxInvalidLoginAttemptsMessage from '../max-invalid-login-attempts-message';
import SocialAuth from '../social-auth';
import '../styles.scss';

const REASON_DELIMITER = ': ';
const MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED = 'MaxInvalidLoginAttemptsExceeded';

const EmailForm = ({
  backendErrors,
  emailRef,
  fields,
  onCheckEmail,
  onLogin,
  setSocialProvider,
  skipError,
  submitting
}) => {
  const [processing, setProcessing] = useState(false);

  const [errorReason, errorMessage] = backendErrors.general
    ? backendErrors.general.split(REASON_DELIMITER)
    : [];

  return (
    <div className="signup-form">
      {process.env.SIGNUP_LANDING_PAGE_URL ? (
        <a className="block text-right mb-2" href={process.env.SIGNUP_LANDING_PAGE_URL}>
          Sign Up
        </a>
      ) : (
        <Link to="/signup" className="block text-right mb-2">
          Create account
        </Link>
      )}

      <h1 className="text-sm-center signup-title">Sign In</h1>

      <div className="mt-2">
        <form onSubmit={onCheckEmail} autoComplete="on">
          <FormGroup
            className="form-group"
            {...fields.email}
            error={fields.email.error || errorMessage}
          >
            <VerboseErrorInput
              {...fields.email}
              className="form-control sign-up-field"
              disabled={processing}
              label="Email"
              placeholder="Enter your email"
              refCb={emailRef}
              type="text"
            />
          </FormGroup>

          {errorMessage &&
            (errorReason === MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED ? (
              <MaxInvalidLoginAttemptsMessage
                initialSeconds={Number(errorMessage)}
                resetFormErrors={skipError}
              />
            ) : (
              <ErrorMessage message={errorMessage} />
            ))}

          <div className="text-sm-center mt-5">
            <button
              type="submit"
              className="btn btn-primary btn-block center-block"
              disabled={submitting || processing || !fields.trusted_device_id.value}
            >
              {submitting || processing ? 'Validating...' : 'Next'}
            </button>
          </div>
        </form>
      </div>

      <SocialAuth
        onLogin={onLogin}
        setProcessing={setProcessing}
        setSocialProvider={setSocialProvider}
        trustedDeviceId={fields.trusted_device_id?.value}
      />
    </div>
  );
};

EmailForm.propTypes = {
  emailRef: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onCheckEmail: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  setSocialProvider: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

export default EmailForm;
