/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import SpinnerLoader from 'components/performance-spinner';
import { connect } from 'react-redux';
import { AllowNewItemsAutosuggest } from 'components/form';
import Disclosure from 'components/disclosure';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import WarningIcon from 'components/svg-icons/warning-icon';
import { toast } from 'react-toastify';
import { validation } from 'utils/form';
import SecurityReturnsParams from '../returns-params';
import SecurityOverview from '../details/overview';
import BulkUpdateSecuritiesModal from './bulk-update-securities-modal';
import './styles.scss';

const DEFAULT_DATE_COLUMN = 0;
const DEFAULT_VALUE_COLUMN = 1;
const DEFAULT_DATE_COLUMN_NAME = 'DATE';
const DEFAULT_VALUE_COLUMN_NAME = 'RETURN';

const validate = values => {
  const errors = { frequency: '', type: '', format: '' };

  errors.frequency = errors.frequency || validation.required(values.frequency);
  errors.type = errors.type || validation.required(values.type);
  if (values.type === 'returns')
    errors.format = errors.format || validation.required(values.format);
  if (values.frequency === 'monthly')
    errors.interpolation = errors.interpolation || validation.required(values.interpolation);

  return errors;
};

const debounceDelay = 500;

const CustomSecurityBulkTable = ({
  currentSecurityBulkSuggestions,
  currentSecurityReturnsBulkCSV,
  customSecurities,
  errors,
  fields,
  initializeForm,
  handleSubmit,
  currentSecuritySearch
}) => {
  const { marketProvider, customSecurityProvider, routerActions } = useContext(AdvisorContext);

  const [expanded, setExpanded] = useState({});
  const [showBulkUpdate, setShowBulkUpdate] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [disabledCreateSecurityButton, setDisabledCreateSecurityButton] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [securityName, setSecurityName] = useState({});

  const marketCustomSecurities = currentSecuritySearch || [];

  const showModal = () => setShowBulkUpdate(true);
  const hideModal = () => setShowBulkUpdate(false);

  const handleShowResponseModal = () => setShowResponseModal(true);
  const handleHideResponseModal = () => setShowResponseModal(false);

  const returnToSecurity = () => {
    routerActions.push('/advisor/securities');
  };

  const removeReturnsFile = (event, row) => {
    event.stopPropagation();
    const fileName = row.original.name;

    const updatedBulkFiles = currentSecurityReturnsBulkCSV.filter(f => f.name !== fileName);

    const updatedInputValues = { ...inputValues };
    delete updatedInputValues[fileName];

    setInputValues(updatedInputValues);
    customSecurityProvider.removeBulkReturnsCSV(updatedBulkFiles);

    setExpanded({ ...expanded, [fileName]: false });
  };

  const initializeFormStorage = parsedValues => {
    const values = {
      frequency: parsedValues.frequency || '',
      type: parsedValues.type || '',
      format: parsedValues.format || 'decimal',
      interpolation: parsedValues.interpolation || '',
      file_has_header: parsedValues.file_has_header || false,
      file_columns: parsedValues.file_columns || [],
      date_column: parsedValues.date_column || DEFAULT_DATE_COLUMN,
      value_column: parsedValues.value_column || DEFAULT_VALUE_COLUMN,
      date_column_name: parsedValues.date_column_name || DEFAULT_DATE_COLUMN_NAME,
      value_column_name: parsedValues.value_column_name || DEFAULT_VALUE_COLUMN_NAME
    };
    return initializeForm(values);
  };

  const securitySearch = useCallback(
    _.debounce(value => {
      if (value) customSecurityProvider.customSecuritySearch(value);
    }, debounceDelay),
    []
  );

  const handleInputChange = (fileName, newValue, id = null) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [fileName]: { ticker: newValue, id }
    }));
  };

  const handleInputChangeNewSearch = (fileName, newValue, event, id = null) => {
    const ticker = id === null ? newValue : event.nativeEvent.data;
    handleInputChange(fileName, ticker);
  };

  const onBlurFactory = fileName => {
    if (inputValues[fileName]?.id)
      setInputValues(prevValues => ({
        ...prevValues,
        [fileName]: {
          ...prevValues[fileName],
          ticker: prevValues[fileName]?.ticker || ''
        }
      }));
    else
      setInputValues(prevValues => ({
        ...prevValues,
        [fileName]: { ticker: '', id: null }
      }));
  };

  // Handle creating custom security in the bulk table.
  const handleCreateSecurity = (row, newSecurityValue) => {
    const fileName = row.name;
    setDisabledCreateSecurityButton({ ...disabledCreateSecurityButton, [fileName]: true });
    setExpanded({ ...expanded, [fileName]: !expanded[fileName] });
    customSecurityProvider
      .create(newSecurityValue)
      .then(response => {
        if (response.error) toast.error('An error occurred while creating the custom security.');
        else {
          handleInputChange(fileName, response.data.ticker, response.data.id);
          toast.success('🎊 The custom security was created successfully.');
        }
      })
      .finally(() => {
        setDisabledCreateSecurityButton({ ...disabledCreateSecurityButton, [fileName]: false });
      });
  };

  const onChangeChoice = field => event => {
    if (field.name === 'file_has_header') return field.onChange(!field.value);
    return field.onChange(event.value);
  };

  const hasNoId = () => Object.values(inputValues).some(value => value.id === null);

  // handle inport
  const handleImport = useCallback(
    _.debounce(values => {
      setIsProcessing(true);
      const formDataFiles = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value || value === false) formDataFiles.append(key, value);
      });

      currentSecurityReturnsBulkCSV.forEach(file => {
        if (inputValues[file.name]) {
          const fileId = inputValues[file.name].id;
          formDataFiles.append(fileId, file);
        }
      });

      customSecurityProvider
        .importBulkFilesCSV(formDataFiles)
        .then(response => {
          if (response.error) toast.error('An error occurred while importing the custom security.');
          else {
            handleShowResponseModal(true);
            toast.success('🎊 All securities returns were uploaded successfully.');
          }
        })
        .finally(() => {
          const fieldsToSave = ['frequency', 'type', 'format', 'interpolation', 'file_has_header'];
          const savedValues = _.pick(values, fieldsToSave);
          localStorage.setItem('securityReturnsFields', JSON.stringify(savedValues));
          setIsProcessing(false);
        });
    }, debounceDelay),
    [inputValues, currentSecurityReturnsBulkCSV]
  );

  useEffect(() => {
    marketProvider.getClassifications();
    const storedValues = localStorage.getItem('securityReturnsFields');
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      initializeFormStorage(parsedValues);
    }
    return () => {
      customSecurityProvider.removeBulkReturnsCSV([]);
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentSecurityReturnsBulkCSV))
      Object.keys(currentSecurityBulkSuggestions).forEach(fileName => {
        const fileData = currentSecurityBulkSuggestions[fileName];
        if (fileData && typeof fileData === 'object')
          handleInputChange(fileName, fileData.ticker, fileData.id);
        else handleInputChange(fileName, null);
      });
  }, [currentSecurityBulkSuggestions]);

  useEffect(() => {
    if (currentSecurityReturnsBulkCSV.length === 0) {
      fields.file_columns.onChange([]);
      fields.date_column.onChange(DEFAULT_DATE_COLUMN); // Reset to default value 0
      fields.value_column.onChange(DEFAULT_VALUE_COLUMN); // Reset to default value 1
      fields.date_column_name.onChange('');
      fields.value_column_name.onChange('');
      return () => {
        customSecurityProvider.clearReturns();
      };
    }

    const file = currentSecurityReturnsBulkCSV[0];
    fields.file.onChange(file);

    // Read the file
    const reader = new FileReader();

    reader.onload = e => {
      if (e.target && e.target.result) {
        const fileContent = e.target.result;
        const lines = fileContent.split('\n');

        if (lines.length > 0) {
          const columns = lines[0].split(',');

          fields.file_columns.onChange(columns);

          // Retrieve stored column names
          const storedValues = JSON.parse(localStorage.getItem('securityReturnsFields') || '{}');
          const storedDateColumnName = storedValues.date_column_name || '';
          const storedValueColumnName = storedValues.value_column_name || '';

          // Check if stored column names match any columns in the uploaded file
          const dateColumnIndex = columns.indexOf(storedDateColumnName);
          const valueColumnIndex = columns.indexOf(storedValueColumnName);

          if (dateColumnIndex !== -1) {
            fields.date_column.onChange(dateColumnIndex);
            fields.date_column_name.onChange(columns[dateColumnIndex]);
          } else {
            fields.date_column.onChange(DEFAULT_DATE_COLUMN); // Default to first column if no match
            fields.date_column_name.onChange('');
          }

          if (valueColumnIndex !== -1) {
            fields.value_column.onChange(valueColumnIndex);
            fields.value_column_name.onChange(columns[valueColumnIndex]);
          } else {
            fields.value_column.onChange(1); // Default to second column if no match
            fields.value_column_name.onChange('');
          }
        }
      }
    };

    reader.onerror = () => {
      console.error('There was an error reading the file');
      // Handle error here if needed
    };

    reader.readAsText(file);
  }, [currentSecurityReturnsBulkCSV]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleImport)}>
      <div id="security-bulk-table" className="bulk-table-container">
        <h2>Bulk Process Custom Securities</h2>
        <div className="security-bulk-table-header-container">
          <div className="security-bulk-params-container">
            <SecurityReturnsParams fields={fields} onChangeFields={onChangeChoice} />
          </div>

          <div className="security-bulk-table-buttons-container">
            <button className="btn btn-outline-primary " onClick={() => showModal()} type="button">
              Add more files
            </button>

            <button
              className="btn btn-primary"
              type="submit"
              disabled={
                !_.isEmpty(errors) ||
                hasNoId() ||
                _.isEmpty(currentSecurityReturnsBulkCSV) ||
                isProcessing
              }
            >
              Process Files
            </button>
          </div>
        </div>

        <table className="table table-bordered table-borderless-top">
          <thead className="thead-graphite bulk-table-title">
            <tr>
              <th>File</th>
              <th>Security</th>
              <th className="security-bulk-action-header">Action</th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(currentSecurityReturnsBulkCSV) && (
              <tr>
                <td colSpan="3" className="text-center">
                  No file to import
                </td>
              </tr>
            )}
            {currentSecurityReturnsBulkCSV.map(file => (
              <div
                className={cn(
                  { 'bulk-table__expanded': expanded[file.name] },
                  'security-bulk-table-container'
                )}
              >
                <tr key={file.name}>
                  <td className="bulk-table__row security-bulk-table__padding-left security-bulk-table__border-right">
                    <a className="prospect-name investor-cursor" href="!">
                      {file.name}
                    </a>
                  </td>

                  <td className="security-bulk-table__border-left security-bulk-table__padding-left security-bulk-table__border-right">
                    <div
                      className={cn(
                        { 'bulk-suggestion__hide': expanded[file.name] },
                        'loading-action-toast'
                      )}
                    >
                      {disabledCreateSecurityButton[file.name] ? (
                        <SpinnerLoader spinnerLoading />
                      ) : (
                        <div>
                          <div className="bulk-suggestion-container-position">
                            <AllowNewItemsAutosuggest
                              field={{ value: inputValues[file.name]?.ticker || '' }}
                              suggestions={marketCustomSecurities}
                              getSuggestionValue={suggestion => suggestion.ticker}
                              renderSuggestion={suggestion => (
                                <span>
                                  {suggestion.prism_overall ? (
                                    <ScoreBubble score={suggestion.prism_overall} />
                                  ) : (
                                    <span className="no-score-warning">
                                      <WarningIcon
                                        className="warning-icon"
                                        title="Score not available"
                                      />
                                    </span>
                                  )}{' '}
                                  {suggestion.ticker}: {suggestion.ticker_name}
                                </span>
                              )}
                              onSuggestionSelected={(_, { suggestion }) => {
                                handleInputChange(file.name, suggestion.ticker, suggestion.id);
                                setSecurityName(prevValues => ({
                                  ...prevValues,
                                  [file.name]: { ticker_name: suggestion.ticker_name }
                                }));
                              }}
                              onSuggestionsFetchRequested={({ value }) => {
                                securitySearch(value);
                              }}
                              inputProps={{
                                placeholder: 'Security',
                                onBlur: () => onBlurFactory(file.name),
                                value: inputValues[file.name]?.ticker || '',

                                onChange: (event, { newValue }) => {
                                  handleInputChangeNewSearch(
                                    file.name,
                                    newValue,
                                    event,
                                    inputValues[file.name]?.id || null
                                  );
                                  setSecurityName(prevValues => ({
                                    ...prevValues,
                                    [file.name]: { ticker_name: '' }
                                  }));
                                }
                              }}
                              theme={{
                                container:
                                  'dropdown react-autosuggest__container bulk-suggestion__container',
                                containerOpen: 'open react-autosuggest__container--open',
                                input:
                                  'form-control react-autosuggest__input bulk-suggestion__input',
                                suggestionsContainer:
                                  'dropdown-menu dropdown-menu-scale react-autosuggest__suggestions-container bulk-suggestion__dropDown ',
                                suggestion: 'dropdown-item react-autosuggest__suggestion',
                                suggestionFocused: 'react-autosuggest__suggestion--focused'
                              }}
                            />
                            <span className="icon-search_icn security-bulk-input-icon__position" />
                            <i
                              className={cn(
                                { 'bulk-suggestion__hide': inputValues[file.name]?.id === null },
                                'icon-checkmark-circle ',
                                'bulk--icon-checkmark'
                              )}
                            />
                          </div>
                          <span className="bulk-suggestion__security-name">
                            {securityName[file.name]?.ticker_name}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="security-bulk-table__border-left">
                    <div className="security-bulk-table-buttons-container security-bulk-table-buttons-container__action-button">
                      <button
                        type="button"
                        className="btn btn-outline-primary bulk-add-security-button "
                        disabled={disabledCreateSecurityButton[file.name]}
                        onClick={() =>
                          setExpanded({ ...expanded, [file.name]: !expanded[file.name] })
                        }
                      >
                        <i
                          className={cn(
                            'icon-add_item',
                            'security--icon-cross',
                            'bulk-add-security-icon'
                          )}
                        />
                        Create a new Security
                      </button>

                      <button
                        type="button"
                        className="btn btn-outline-secondary bulk-discard-button"
                        onClick={event => {
                          removeReturnsFile(event, { original: file });
                        }}
                      >
                        Discard File
                        <i className={cn('fs-icon-trash-1', 'security--icon-cross', 'bulk-icon')} />
                      </button>
                    </div>
                  </td>
                </tr>
                {expanded[file.name] && (
                  <tr className="expanded-row">
                    <td colSpan="3">
                      <SecurityOverview
                        isBulk
                        row={file}
                        bulkCreateSecurity={handleCreateSecurity}
                      />
                    </td>
                  </tr>
                )}
              </div>
            ))}
          </tbody>
        </table>
        <BulkUpdateSecuritiesModal
          onHide={hideModal}
          selectedPortfolioIds={customSecurities}
          show={showBulkUpdate}
        />

        <Modal
          className="modal-lg bulk__modal-body"
          show={showResponseModal}
          onHidden={handleHideResponseModal}
        >
          <ModalHeader />
          <ModalBody>
            <div className="security-bulk-response-modal">
              <h3 className="security-response__modal-title">Mass Import Processing</h3>
              <p className="description top">
                <div className="text security-response__text">
                  Bulk process started, you will be notified when the process is completed via Email
                </div>
              </p>
              <button
                type="button"
                className="btn btn-primary security-response__button"
                onClick={() => returnToSecurity()}
              >
                BACK TO SECURITY LIST
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Disclosure />
      </div>
    </form>
  );
};

CustomSecurityBulkTable.propTypes = {
  currentSecurityBulkSuggestions: PropTypes.array.isRequired,
  currentSecurityReturnsBulkCSV: PropTypes.array.isRequired,
  customSecurities: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  currentSecuritySearch: PropTypes.object.isRequired
};

CustomSecurityBulkTable.contextTypes = {
  marketProvider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  customSecurities: state.customSecurity.list,
  currentSecurityReturnsBulkCSV: state.customSecurity.currentSecurityReturnsBulkCSV,
  currentSecurityBulkSuggestions: state.customSecurity.currentSecurityBulkSuggestions,
  currentSecuritySearch: state.customSecurity.search,
  fields: [
    'file',
    'frequency',
    'type',
    'format',
    'interpolation',
    'file_has_header',
    'file_columns',
    'date_column',
    'date_column_name',
    'value_column',
    'value_column_name'
  ],
  initialValues: {
    file: '',
    frequency: '',
    type: '',
    format: 'decimal',
    interpolation: '',
    file_has_header: false,
    file_columns: [],
    date_column: DEFAULT_DATE_COLUMN,
    date_column_name: DEFAULT_DATE_COLUMN_NAME,
    value_column: DEFAULT_VALUE_COLUMN,
    value_column_name: DEFAULT_VALUE_COLUMN_NAME
  }
});

const CustomSecurityBulkTableWithForm = reduxForm({
  form: 'editBulkSecurity',
  enableReinitialize: true,
  validate
})(CustomSecurityBulkTable);

const CustomSecurityBulkTableWithOutRoute = withRouter(CustomSecurityBulkTableWithForm);

export default connect(mapStateToProps)(CustomSecurityBulkTableWithOutRoute);
