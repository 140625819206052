// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#CheckoutPaymentSummary .checkout-summary {
  display: flex;
  flex-direction: column;
}
#CheckoutPaymentSummary .checkout-summary .checkout-summary__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d3d6db;
  margin-bottom: 0.55rem;
}
#CheckoutPaymentSummary .checkout-summary .checkout-summary__row p {
  margin-bottom: 0.5rem;
}
#CheckoutPaymentSummary .checkout-summary .change-selected-price {
  padding: 0;
  font-weight: 500;
}
#CheckoutPaymentSummary .checkout-total {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
#CheckoutPaymentSummary .checkout-total .checkout-total__row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #394346;
  padding-top: 12px;
}
#CheckoutPaymentSummary .checkout-total .checkout-total__row p {
  font-weight: 500;
}
#CheckoutPaymentSummary .checkout-disclaimer p {
  font-size: 0.75rem;
  line-height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/checkout/payment/summary/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,sBAAA;AAFJ;AAII;EACE,aAAA;EACA,8BAAA;EACA,gCAAA;EACA,sBAAA;AAFN;AAIM;EACE,qBAAA;AAFR;AAMI;EACE,UAAA;EACA,gBAAA;AAJN;AAQE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AANJ;AAQI;EACE,aAAA;EACA,8BAAA;EACA,6BAAA;EACA,iBAAA;AANN;AAQM;EACE,gBAAA;AANR;AAWE;EACE,kBAAA;EACA,mBAAA;AATJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#CheckoutPaymentSummary {\n  .checkout-summary {\n    display: flex;\n    flex-direction: column;\n\n    .checkout-summary__row {\n      display: flex;\n      justify-content: space-between;\n      border-bottom: 1px solid $graphite-grayish-blue;\n      margin-bottom: 0.55rem;\n\n      p {\n        margin-bottom: 0.5rem;\n      }\n    }\n\n    .change-selected-price {\n      padding: 0;\n      font-weight: 500;\n    }\n  }\n\n  .checkout-total {\n    display: flex;\n    flex-direction: column;\n    margin-top: 30px;\n\n    .checkout-total__row {\n      display: flex;\n      justify-content: space-between;\n      border-top: 1px solid $gray-dark;\n      padding-top: 12px;\n\n      p {\n        font-weight: 500;\n      }\n    }\n  }\n\n  .checkout-disclaimer p {\n    font-size: 0.75rem;\n    line-height: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
