import { Text, View } from '@react-pdf/renderer';
import RiskRanges from 'containers/risk-tolerance-questionnaire/result/risk-ranges/pdf';
import ScoreContext from 'containers/risk-tolerance-questionnaire/result/score-context/pdf';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import RiskGauge from 'reports/base/charts/risk-gauge';
import ScoreBubble from 'reports/base/charts/score-bubble';
import BaseSection from 'reports/base/sections/base';
import styles from './styles';

const RiskToleranceScore = ({ targetSummary }) =>
  targetSummary?.overall ? (
    <RiskGauge width={80} height={80} risk={targetSummary.overall} withRiskScore />
  ) : (
    <View style={styles.box}>
      <Text style={styles.text}>?</Text>
    </View>
  );

RiskToleranceScore.propTypes = {
  targetSummary: PropTypes.object.isRequired
};

const RiskToleranceScoreCapacityAndPersonality = ({
  investorName,
  latestUpdate,
  latestUpdateFormat,
  lastQuestionnaireAnsweredBy,
  targetSummary,
  title
}) => (
  <BaseSection title={title} wrap={false}>
    <View style={styles.container}>
      <View style={styles.tolerance}>
        <RiskToleranceScore targetSummary={targetSummary} />
        <View style={styles.toleranceLatestUpdate}>
          {latestUpdate ? (
            <>
              <Text>Score latest update</Text>
              <Text>{moment(latestUpdate).format(latestUpdateFormat)}</Text>
            </>
          ) : (
            <Text>-</Text>
          )}
        </View>
      </View>
      <ScoreContext investorName={investorName} scoreSummary={targetSummary} />
    </View>
    {targetSummary?.overall &&
      targetSummary?.tolerance &&
      targetSummary.overall !== targetSummary.tolerance && (
        <View style={styles.adjustedScore}>
          <Text>
            Questionnaire score adjusted{' '}
            {lastQuestionnaireAnsweredBy && `by ${lastQuestionnaireAnsweredBy}`}
            {'. '}The original value was{' '}
          </Text>
          <ScoreBubble score={targetSummary.tolerance} />
        </View>
      )}
    {targetSummary?.overall && <RiskRanges score={targetSummary.overall} />}
  </BaseSection>
);

RiskToleranceScoreCapacityAndPersonality.propTypes = {
  investorName: PropTypes.string.isRequired,
  latestUpdate: PropTypes.string,
  latestUpdateFormat: PropTypes.string,
  lastQuestionnaireAnsweredBy: PropTypes.string,
  targetSummary: PropTypes.object.isRequired,
  title: PropTypes.string
};

RiskToleranceScoreCapacityAndPersonality.defaultProps = {
  latestUpdate: null,
  latestUpdateFormat: 'LL',
  lastQuestionnaireAnsweredBy: '',
  title: 'Risk Tolerance Score'
};

export default RiskToleranceScoreCapacityAndPersonality;
