import { VerboseErrorInput as Input } from 'components/form';
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { reduxForm } from 'redux-form';
import TwoFactorCompanyPolicy from '../company-policy';
import { ACTIVE_2FA } from '../utils/constants';
import { formHasError, handleToastError, handleToastSuccess, validate } from '../utils/helpers';
import TwoFactorMethod from '../method';

const TwoFactorActive = ({ user, activeSetupScreen, fields, handleSetupScreenType }) => {
  const [disableMethod, setDisableMethod] = useState('');
  const { authProvider } = useContext(AuthenticationContext);

  const is2FAappActivated = !!user?.all_mfa?.app;
  const is2FAemailActivated = !!user?.all_mfa?.email;
  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

  const handleSubmit = e => {
    e.preventDefault();

    const id = user?.all_mfa?.[disableMethod]?.device_id;
    const data = new FormData(e.target);
    const payload = { ...Object.fromEntries(data.entries()), device_type: disableMethod };

    authProvider
      .remove2FADevice(id, payload)
      .then(resp =>
        handleToastSuccess(resp, ACTIVE_2FA.SUCCESS, () => {
          authProvider.getUser();
          setDisableMethod('');
        })
      )
      .catch(() => handleToastError(ACTIVE_2FA.ERROR));
  };

  const handleSetupScreen = methodName => {
    activeSetupScreen(true);
    handleSetupScreenType(methodName);
  };

  return (
    <div className="container-fluid table-white">
      <div className="form-container">
        <div>
          <h4>{ACTIVE_2FA.TITLE}</h4>
          <p>{ACTIVE_2FA.DESCRIPTION}</p>
          <p />
        </div>
        <div>
          {disableMethod ? (
            <form onSubmit={handleSubmit}>
              <div>
                <p>{ACTIVE_2FA.ENABLED_MESSAGE}</p>
                <Input type="password" label="Current Password" {...fields.password} />
                <div className="disable-actions">
                  <button type="submit" className="btn btn-danger" disabled={formHasError(fields)}>
                    {ACTIVE_2FA.DISABLED_BUTTON}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => setDisableMethod('')}
                  >
                    {ACTIVE_2FA.CANCELED_BUTTON}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div>
              <p>{ACTIVE_2FA.CHOOSE_METHOD}</p>
              <br />
              <TwoFactorMethod
                onDisable={() => setDisableMethod('app')}
                onSetup={() => handleSetupScreen('app')}
                isRecommended
                isMethodActive={is2FAappActivated}
                methodTitle="Authenticator App"
                methodDescription={ACTIVE_2FA.AUTHENTICATION_APP_METHOD}
              />
              <br />
              <TwoFactorMethod
                onDisable={() => setDisableMethod('email')}
                onSetup={() => handleSetupScreen('email')}
                isMethodActive={is2FAemailActivated}
                methodTitle="Email"
                methodDescription={ACTIVE_2FA.EMAIL_METHOD}
              />
            </div>
          )}
        </div>
      </div>
      {isComplianceOrAbove && <TwoFactorCompanyPolicy />}
    </div>
  );
};

TwoFactorActive.defaultProps = {
  user: {},
  activeSetupScreen: () => {},
  handleSetupScreenType: () => {}
};

TwoFactorActive.propTypes = {
  user: PropTypes.object,
  fields: PropTypes.object.isRequired,
  activeSetupScreen: PropTypes.func,
  handleSetupScreenType: PropTypes.func
};

const reduxFormToProps = {
  form: 'activeScreen',
  fields: ['password'],
  validate
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default reduxForm(reduxFormToProps, mapStateToProps)(TwoFactorActive);
