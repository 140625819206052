import cn from 'classnames';
import React from 'react';
import './styles.scss';

const TabSwitch = ({ value, options, onChange }) => (
  <div className="TabSwitch">
    {options.map(option => (
      <button
        key={option.value}
        type="button"
        className={cn({ active: option.value === value })}
        onClick={() => onChange(option.value)}
      >
        {option.label}
      </button>
    ))}
  </div>
);

export default TabSwitch;
