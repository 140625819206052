/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import StepProgress from 'components/advisor/step-progress';
import PropTypes from 'prop-types';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

/**
 * Generic component for rendering views with multiple steps.
 * The only prop required prop is `config` which should be of the following shape:
 *
 * ```js
 * [
 *  {
 *    title: 'Step 1',
 *    component: Step1Component,
 *    stepProps: { propForComponent1: 'some value' }
 *  }
 * ]
 * ```
 *
 * `title` is a string which is displayed under the step number. `component` is rendered when
 * the user is on the corresponding step, `stepProps` are passed to `component`.
 */
const MultiStepView = forwardRef(
  (
    {
      config,
      headingClassName,
      initialStep,
      newStyleProgress,
      onLastStepSubmit,
      onStepBackward,
      onStepForward,
      rootClassName,
      stepClassName,
      stepFooter,
      withoutProgress
    },
    ref
  ) => {
    const [step, setStep] = useState(initialStep || 0);
    const [propsFromPrevStep, setPropsFromPrevStep] = useState(null);

    const configStep = config[step];
    const isFirst = step === initialStep;
    const isLast = step === config.length - 1;

    const nextStep = (event, propsFromPrevStep) => {
      if (event) event.persist();
      if (step >= config.length) return;
      setStep(step + 1);
      setPropsFromPrevStep(propsFromPrevStep);
      onStepForward();
    };

    const prevStep = event => {
      if (event) event.persist();
      if (step <= 0) return;
      setStep(step - 1);
      onStepBackward();
    };

    useImperativeHandle(ref, () => ({
      goToStep: step => {
        setStep(step);
      }
    }));

    const getHeading = () => {
      if (withoutProgress) return <div className="no-progress-heading">{configStep.title}</div>;
      if (newStyleProgress) return <div />;

      const titles = config.map((s, idx) => (
        <div className={cn('step', { active: step === idx, done: idx < step })} key={idx}>
          <div style={{ position: 'relative' }} />
          <span className="step-title">{s.title}</span>
        </div>
      ));

      return <div className="heading">{titles}</div>;
    };

    return (
      <div className={rootClassName}>
        {getHeading()}

        <div className={stepClassName}>
          {!withoutProgress && newStyleProgress ? (
            <div className={headingClassName}>
              <h3>{configStep.title}</h3>
              <StepProgress currentStep={step} stepsCount={config.length} isLast={isLast} />
            </div>
          ) : null}

          <configStep.component
            {...configStep.stepProps}
            {...propsFromPrevStep}
            step={step + 1}
            isFirst={isFirst}
            isLast={isLast}
            nextStep={isLast ? onLastStepSubmit || nextStep : nextStep}
            prevStep={prevStep}
          />
        </div>

        {stepFooter && <stepFooter />}
      </div>
    );
  }
);

MultiStepView.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
      title: PropTypes.string,
      stepProps: PropTypes.object
    })
  ).isRequired,
  headingClassName: PropTypes.string,
  initialStep: PropTypes.number,
  newStyleProgress: PropTypes.bool,
  onLastStepSubmit: PropTypes.func,
  onStepBackward: PropTypes.func,
  onStepForward: PropTypes.func,
  onStateChange: PropTypes.func,
  rootClassName: PropTypes.string,
  stepClassName: PropTypes.string,
  stepFooter: PropTypes.func,
  withoutProgress: PropTypes.bool
};

MultiStepView.defaultProps = {
  initialStep: 0,
  headingClassName: '',
  newStyleProgress: false,
  onLastStepSubmit: () => {},
  onStepBackward: () => {},
  onStepForward: () => {},
  onStateChange: () => {},
  rootClassName: 'multi-step-view',
  stepClassName: 'multi-step-view--step',
  stepFooter: null,
  withoutProgress: false
};

export default MultiStepView;
