import SpinnerLoader from 'components/performance-spinner';
import SortableList from 'components/sortable-list';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './styles.scss';
import TaxonomyRow from './taxonomy-row';

const handleUpdateError = (message = 'There was an error updating the label.') => {
  toast.error(() => <div>{message}</div>);
};

const normalizeTaxonomy = (taxonomy, textAttr) => ({
  ...taxonomy,
  id: taxonomy.slug,
  text: taxonomy.label || taxonomy[textAttr]
});

const ClassificationTaxonomies = ({ taxonomies }) => {
  /* Context */
  const { marketProvider } = useContext(AdvisorContext);

  /* States */
  const [collapseMap, setCollapseMap] = useState({});
  const [updateMap, setUpdateMap] = useState({});

  /* Effect */
  useEffect(() => {
    marketProvider.getTaxonomies();
  }, []);

  useEffect(() => {
    if (taxonomies)
      setCollapseMap(
        taxonomies
          .filter(t => !t.level_2)
          .reduce((acum, t) => ({ ...acum, [t.slug]: collapseMap[t.slug] ?? true }), {})
      );
  }, [JSON.stringify(taxonomies)]);

  /* Default UI */
  if (_.isEmpty(taxonomies)) return <SpinnerLoader spinnerLoading />;

  /* Helpers & Handlers */
  const toggleCollapsed = slug => {
    setCollapseMap({ ...collapseMap, [slug]: !collapseMap[slug] });
  };

  const onChangeLabel = async (slug, label) => {
    setUpdateMap({ ...updateMap, [slug]: true });

    try {
      const { data } = await marketProvider.updateTaxonomy(slug, { label });
      if (data) {
        await marketProvider.getTaxonomies();
        toast.success(() => <div>Your changes has been saved successfully.</div>);
      } else handleUpdateError();
    } catch {
      handleUpdateError();
    }

    setUpdateMap({ ...updateMap, [slug]: false });
  };

  const onChangeOrder = async items => {
    setUpdateMap(items.reduce((acum, { slug }) => ({ ...acum, [slug]: true }), updateMap));

    const promises = items.map(({ slug }, index) =>
      marketProvider.updateTaxonomy(slug, { order: index })
    );

    try {
      await Promise.allSettled(promises);
      await marketProvider.getTaxonomies();
      toast.success(() => <div>Your changes has been saved successfully.</div>);
    } catch {
      handleUpdateError('There was an error updating the order.');
    }

    setUpdateMap(items.reduce((acum, { slug }) => ({ ...acum, [slug]: false }), updateMap));
  };

  const nestedTaxonomies = taxonomies
    .filter(t => !t.level_2)
    .sort((a, b) => a.order - b.order)
    .map(t1 => ({
      ...normalizeTaxonomy(t1, 'level_1'),
      nested: taxonomies
        .filter(t2 => t2.level_1 === t1.level_1 && !!t2.level_2)
        .sort((a, b) => a.order - b.order)
        .map(t2 => normalizeTaxonomy(t2, 'level_2'))
    }));

  return (
    <div id="ClassificationTaxonomies">
      <h4>Customize asset classification</h4>
      <div className="templates-meta-helper-container">
        Customize the assets classification, drag and drop to change the order.
      </div>

      <DndProvider backend={HTML5Backend}>
        <SortableList
          type="l1"
          items={nestedTaxonomies}
          onChange={onChangeOrder}
          component={TaxonomyRow}
          collapseMap={collapseMap}
          updateMap={updateMap}
          disabled={Object.values(updateMap).some(i => i)}
          onChangeLabel={onChangeLabel}
          toggleCollapsed={toggleCollapsed}
          onChangeOrder={onChangeOrder}
        />
      </DndProvider>
    </div>
  );
};

ClassificationTaxonomies.propTypes = {
  taxonomies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      level_1: PropTypes.string,
      level_2: PropTypes.string,
      order: PropTypes.number.isRequired,
      sector: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      subtype: PropTypes.number.isRequired,
      type: PropTypes.number.isRequired
    })
  ).isRequired
};

export default connect(state => ({
  taxonomies: state.market.taxonomies
}))(ClassificationTaxonomies);
