export const QUESTIONNAIRE_TEMPLATE_URL_PARAM = 'questionnaire_template_id';

export const questionTypes = {
  CHOICE: 'choice',
  CUSTOM: 'custom',
  LEGACY: 'legacy',
  NUMERIC: 'numeric',
  RANGE: 'range',
  SECTION: 'section',
  TEXT: 'text'
};

export const questionFlavors = {
  ADDRESS: 'address',
  ANNUAL_TAXABLE_INCOME: 'annual-taxable-income',
  BIRTHDAY_RETIREMENT: 'birthday-retirement',
  CASH_INVESTABLE_ASSETS: 'cash-investable-assets',
  EXPECTED_RETURN: 'expected-return',
  GOAL_APPROACH: 'goal-approach',
  LOSS_REACTION: 'loss-reaction',
  PORTFOLIO_CONCENTRATION: 'portfolio-concentration',
  SINGLE_YEAR_OUTCOME: 'single-year-outcome',
  TOLERABLE_LOSS: 'tolerable-loss',
  VALUE_REQUIREMENT: 'value-requirement',
  WITHDRAWAL_REQUIREMENT: 'withdrawal-requirement'
};

export const questionSlugs = {
  TIME_HORIZON: 'time-horizon'
};

export const rangeMode = {
  ADVANCED_SLIDER: 'advanced-slider',
  REGULAR_SLIDER: 'regular-slider',
  PERCENTAGE_SLIDER: 'percentage-slider'
};
