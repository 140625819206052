import { createSelector } from 'reselect';
import { integrationsConnectedSelector } from './integrations';

export const esAdvisorsSelector = state => state.advisors.esList || [];

export const advisorStatsSelector = (state, advisorId) =>
  createSelector(
    [esAdvisorsSelector, integrationsConnectedSelector],
    (advisors, connectedIntegrations) => {
      const advisor = advisors.find(advisor => advisor.id === advisorId) || {};
      const integrations = connectedIntegrations
        .filter(integration => integration.advisor === advisorId)
        .reduce((acc, integration) => {
          const key = integration.provider_details.name;
          return { ...acc, [key]: (acc[key] ?? 0) + 1 };
        }, {});

      return {
        accounts: advisor.total_accounts ?? 0,
        clients: advisor.total_clients ?? 0,
        households: advisor.total_households ?? 0,
        integrations,
        prospects: advisor.total_prospects ?? 0
      };
    }
  )(state);
