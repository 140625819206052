import { questionFlavors as qf, questionTypes as qt } from 'constants/questionnaire';
import AddressQuestion from 'containers/risk-tolerance-questionnaire/question/address/pdf';
import AnnualTaxableIncomeQuestion from 'containers/risk-tolerance-questionnaire/question/annual-taxable-income/pdf';
import BirthdayRetirementQuestion from 'containers/risk-tolerance-questionnaire/question/birthday-retirement/pdf';
import CashInvestableAssetsQuestion from 'containers/risk-tolerance-questionnaire/question/cash-investable-assets/pdf';
import ChoicesQuestion from 'containers/risk-tolerance-questionnaire/question/choices/pdf';
import ExpectedReturnQuestion from 'containers/risk-tolerance-questionnaire/question/expected-return/pdf';
import GoalApproachQuestion from 'containers/risk-tolerance-questionnaire/question/goal-approach/pdf';
import LossReactionQuestion from 'containers/risk-tolerance-questionnaire/question/loss-reaction/pdf';
import NumericQuestion from 'containers/risk-tolerance-questionnaire/question/numeric/pdf';
import PortfolioConcentrationQuestion from 'containers/risk-tolerance-questionnaire/question/portfolio-concentration/pdf';
import RangeQuestion from 'containers/risk-tolerance-questionnaire/question/range/pdf';
import SectionSeparator from 'containers/risk-tolerance-questionnaire/question/section-separator/pdf';
import SingleYearOutcomeQuestion from 'containers/risk-tolerance-questionnaire/question/single-year-outcome/pdf';
import TextQuestion from 'containers/risk-tolerance-questionnaire/question/text/pdf';
import TolerableLossQuestion from 'containers/risk-tolerance-questionnaire/question/tolerable-loss/pdf';
import ValueRequirementQuestion from 'containers/risk-tolerance-questionnaire/question/value-requirement/pdf';
import WithdrawalRequirementQuestion from 'containers/risk-tolerance-questionnaire/question/withdrawal-requirement/pdf';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import BaseSection from 'reports/base/sections/base';
import { interpolateQuestions } from 'utils/questionnaire';
import styles from './styles';

const getComponentForQuestion = (type, flavor) => {
  if (type === qt.CUSTOM) {
    if (flavor === qf.ADDRESS) return AddressQuestion;
    if (flavor === qf.ANNUAL_TAXABLE_INCOME) return AnnualTaxableIncomeQuestion;
    if (flavor === qf.BIRTHDAY_RETIREMENT) return BirthdayRetirementQuestion;
    if (flavor === qf.CASH_INVESTABLE_ASSETS) return CashInvestableAssetsQuestion;
    if (flavor === qf.EXPECTED_RETURN) return ExpectedReturnQuestion;
    if (flavor === qf.GOAL_APPROACH) return GoalApproachQuestion;
    if (flavor === qf.LOSS_REACTION) return LossReactionQuestion;
    if (flavor === qf.PORTFOLIO_CONCENTRATION) return PortfolioConcentrationQuestion;
    if (flavor === qf.SINGLE_YEAR_OUTCOME) return SingleYearOutcomeQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return TolerableLossQuestion;
    if (flavor === qf.VALUE_REQUIREMENT) return ValueRequirementQuestion;
    if (flavor === qf.WITHDRAWAL_REQUIREMENT) return WithdrawalRequirementQuestion;
  }
  if (type === qt.LEGACY) {
    if (flavor === qf.BIRTHDAY_RETIREMENT) return BirthdayRetirementQuestion;
    if (flavor === qf.EXPECTED_RETURN) return ExpectedReturnQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return TolerableLossQuestion;
  }
  if (type === qt.CHOICE) return ChoicesQuestion;
  if (type === qt.NUMERIC) return NumericQuestion;
  if (type === qt.RANGE) return RangeQuestion;
  if (type === qt.SECTION) return SectionSeparator;
  if (type === qt.TEXT) return TextQuestion;
  return null;
};

const renderQuestion = (question, answers) => {
  const QuestionComponent = getComponentForQuestion(question.question_type, question.data?.flavor);

  if (QuestionComponent)
    return <QuestionComponent key={question.position} question={question} answers={answers} />;
  return null;
};

const RiskToleranceQuestionnaireContent = ({ breakSection, questions: rawQuestions, title }) => {
  const [questions, answers] = useMemo(() => {
    const answers = rawQuestions.reduce(
      (acum, q) => ({ ...acum, [q.slug || `question-${q.position}`]: q.answer }),
      {}
    );
    const qs = interpolateQuestions(rawQuestions, answers);
    return [qs, answers];
  }, [JSON.stringify(rawQuestions)]);

  return (
    <BaseSection title={title} style={styles.container} breakSection={breakSection}>
      {questions.sort((q1, q2) => q1.position - q2.position).map(q => renderQuestion(q, answers))}
    </BaseSection>
  );
};

RiskToleranceQuestionnaireContent.defaultProps = {
  breakSection: true,
  title: 'Form Answers'
};

RiskToleranceQuestionnaireContent.propTypes = {
  breakSection: PropTypes.bool,
  questions: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default RiskToleranceQuestionnaireContent;
