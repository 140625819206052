import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import './styles.scss';

const RangeValueFilter = ({ maxAttr, minAttr, filters, setFilters, setRequiresPageIndexReset }) => {
  const delayedOnChangeHandler = useCallback(
    _.debounce((attr, value) => {
      setFilters(prevFilters => ({ ...prevFilters, [attr]: value }));
      setRequiresPageIndexReset(true);
    }, 250),
    []
  );

  const onChangeHandler =
    attr =>
    ({ floatValue }) => {
      delayedOnChangeHandler(attr, floatValue);
      setRequiresPageIndexReset(true);
    };

  const rangeValueProps = {
    allowNegative: false,
    className: 'form-control',
    decimalScale: 2,
    prefix: '$',
    thousandSeparator: true
  };

  return (
    <div className="range-value">
      <NumericFormat
        {...rangeValueProps}
        defaultValue={filters?.[minAttr]}
        onValueChange={onChangeHandler(minAttr)}
        placeholder="From"
      />
      <NumericFormat
        {...rangeValueProps}
        defaultValue={filters?.[maxAttr]}
        onValueChange={onChangeHandler(maxAttr)}
        placeholder="To"
      />
    </div>
  );
};

RangeValueFilter.propTypes = {
  filters: PropTypes.object,
  maxAttr: PropTypes.string.isRequired,
  minAttr: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  setRequiresPageIndexReset: PropTypes.func.isRequired
};

RangeValueFilter.defaultProps = {
  filters: {}
};

export default RangeValueFilter;
