import cn from 'classnames';
import PropTypes from 'prop-types';
import { Handle, SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useMemo } from 'react';

const labelsToMarks = labels =>
  labels
    ? labels.reduce(
        (acum, label, index) => ({
          ...acum,
          [index]: {
            label: (
              <span
                className={cn({ '-empty': !label.text })}
                dangerouslySetInnerHTML={{ __html: label.text }}
              />
            ),
            value: label.value
          }
        }),
        {}
      )
    : {};

const useSlider = ({
  getTooltipContent,
  handleDisabled,
  labels,
  size,
  withHandle,
  withTooltip
}) => {
  const marks = useMemo(() => labelsToMarks(labels), [JSON.stringify(labels)]);
  const min = 0;
  const max = labels.length - 1;

  /* Helpers */
  const markToValue = mark => marks[mark].value;
  const valueToMark = val => {
    const mark = Object.entries(marks).reduce((best, mark) => {
      const offset = mark[1].value - val;
      const bestOffset = best[1].value - val;
      return offset + size <= 0 && offset > bestOffset ? mark : best;
    });
    return mark ? Number(mark[0]) : 0;
  };

  const sliderProps = {
    allowCross: false,
    marks,
    max,
    min,
    step: 1
  };

  if (!withHandle) sliderProps.handle = () => {};
  else if (withTooltip) {
    const getTooltipOverlay = (v, position) => getTooltipContent(marks[v]?.value, position);

    sliderProps.handle = ({ value: v, index, ...restProps }) => (
      <SliderTooltip
        prefixCls={`${!restProps.offset ? 'rc-offset-0' : ''} rtq rc-slider-tooltip`}
        overlay={getTooltipOverlay(v, index)}
        visible
        placement="top"
        className={cn({ disabled: handleDisabled?.[index] })}
        key={index}
      >
        <Handle value={v} {...restProps} handle />
      </SliderTooltip>
    );
  }

  return {
    marks,
    markToValue,
    max,
    min,
    sliderProps,
    valueToMark
  };
};

useSlider.propTypes = {
  getTooltipContent: PropTypes.func,
  handleDisabled: PropTypes.array,
  labels: PropTypes.array.isRequired,
  size: PropTypes.number,
  withHandle: PropTypes.bool,
  withTooltip: PropTypes.bool
};

useSlider.defaultProps = {
  getTooltipContent: v => v,
  handleDisabled: [],
  size: 0,
  withHandle: false,
  withTooltip: false
};

export default useSlider;
