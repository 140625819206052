import Select from 'components/select';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { advisorsToOptions } from './utils';

const SelectAdvisor = ({ advisors, onSelect, customOptions, ...rest }) => {
  const { advisorProvider, user } = useContext(AdvisorContext);

  if (!advisors) return null;

  const handleChange = option => {
    const advisor = advisors.find(advisor => advisor.id === option?.value);
    onSelect(option, advisor);
  };

  const options = useMemo(
    () => advisorsToOptions(advisors, user),
    [JSON.stringify(advisors), user.advisor.id]
  );

  useEffect(() => {
    advisorProvider.es.list({ get_all_advisors: true });
  }, []);

  return (
    <Select
      onChange={handleChange}
      options={customOptions ? customOptions(options) || options : options}
      customOptions={customOptions}
      {...rest}
    />
  );
};

SelectAdvisor.propTypes = {
  advisors: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  customOptions: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string
};

SelectAdvisor.defaultProps = {
  advisors: [],
  className: '',
  customOptions: null,
  onSelect: () => {},
  name: ''
};

export default connect(state => ({
  advisors: state.advisors.esList
}))(SelectAdvisor);
