import { FormGroup, VerboseErrorInput } from 'components/form';
import LoadingButton from 'components/loading-button';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';

const PromoCode = ({ promoCode, onApply, onClear }) => {
  const [showPromoCode, setShowPromoCode] = useState(!_.isEmpty(promoCode));
  const [value, setValue] = useState('');
  const [error, setError] = useState(undefined);
  const [pasted, setPasted] = useState(false);
  const [loading, setLoading] = useState(false);

  /* When the component is unmounted, clear the code */
  useEffect(() => onClear, []);

  const handleShow = useCallback(() => {
    setShowPromoCode(true);
  }, []);

  const handleCancel = useCallback(() => {
    setShowPromoCode(false);
    setValue('');
    onClear();
  }, []);

  const handleApply = code => {
    setLoading(true);
    setError(undefined);
    onApply(code)
      .then(response => {
        if (response?.data?.id) setError(undefined);
        else setError('Invalid promo code');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = e => {
    const newValue = e.target.value.toUpperCase();
    // if the code was pasted apply it immediately
    if (pasted) handleApply(newValue);

    setValue(newValue);
    setPasted(false);
  };

  const handlePaste = useCallback(() => {
    setPasted(true);
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    handleApply(value);
  };

  if (!showPromoCode)
    return (
      <div id="PromoCode">
        <p className="text-primary font-weight-bold">
          <button type="button" className="btn btn-link add-discount" onClick={handleShow}>
            <span role="img" aria-label="promo">
              🏷️
            </span>{' '}
            Add promo code
          </button>
        </p>
      </div>
    );

  return (
    <form id="PromoCode" name="promo-code" onSubmit={handleSubmit}>
      <label htmlFor="promo-code">Promo code</label>
      {_.isEmpty(promoCode) ? (
        <>
          <FormGroup className="form-group">
            <VerboseErrorInput
              type="text"
              autoComplete="off"
              autoFocus
              className="form-control sign-up-field promo-code-input"
              name="promo-code"
              value={value}
              onPaste={handlePaste}
              onChange={handleChange}
              error={error}
              touched={!!value}
              disabled={loading}
            />
          </FormGroup>
          <div className="actions">
            <LoadingButton
              className="btn btn-outline-primary"
              disabled={loading}
              form="promo-code"
              loading={loading}
              onClick={handleSubmit}
              spinnerColor="#09acf8"
              type="submit"
            >
              Apply
            </LoadingButton>
            <button
              disabled={loading}
              type="button"
              className="btn btn-outline-secondary"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <div className="validated-promo-code">
          <span className="code">{promoCode.code}</span>
          <span className="remove-promo-code">
            (
            <button type="button" className="btn btn-link" onClick={handleCancel}>
              Remove
            </button>
            )
          </span>
          <span className="coupon">{promoCode.coupon}</span>
        </div>
      )}
    </form>
  );
};

PromoCode.defaultProps = {
  promoCode: null
};

PromoCode.propTypes = {
  promoCode: PropTypes.object,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
};

export default PromoCode;
