import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE, BASE_TABLE_CELL, BASE_TABLE_ROW } from 'reports/base/styles';

const styles = StyleSheet.create({
  table: { ...BASE_TABLE },
  row: { ...BASE_TABLE_ROW },
  cell: {
    ...BASE_TABLE_CELL,
    paddingVertical: 0,
    paddingHorizontal: 10,
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
});

export default styles;
