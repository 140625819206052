import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import InviteUserModal from '../invite-user-modal';
import './styles.scss';

const CompanyUsersHeader = ({ fetchUsers, meta }) => {
  const delayedSearch = useCallback(
    _.debounce(search => {
      fetchUsers({ ...meta.params, page: 1, search: search || undefined });
    }, 500),
    [JSON.stringify(meta.params)]
  );

  const onChangeHandler = event => {
    const { value } = event.target;
    delayedSearch(value);
  };

  return (
    <div id="company-users-header">
      <h2>Users</h2>
      <div className="header-options">
        <InviteUserModal />
        <div className="form-group search">
          <div>
            <input
              className="form-control react-autosuggest__input"
              placeholder="Search by advisor name"
              onChange={onChangeHandler}
            />
            <span className="icon-search_icn" />
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyUsersHeader.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired
};

export default CompanyUsersHeader;
