// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taken-questionnaire-modal {
  padding: 2rem;
  text-align: center;
}
.taken-questionnaire-modal h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.taken-questionnaire-modal p {
  margin: 0 auto 1.5rem auto;
}
.taken-questionnaire-modal p.font-weight-bold {
  margin: 0 auto;
}
.taken-questionnaire-modal a.btn {
  min-width: 10rem;
  margin-top: 1.5rem;
}
.taken-questionnaire-modal .modal-content {
  padding: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/layout/header-advisor/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,kBAAA;AADF;AAGE;EACE,iBAAA;EACA,mBAAA;AADJ;AAIE;EACE,0BAAA;AAFJ;AAKE;EACE,cAAA;AAHJ;AAME;EACE,gBAAA;EACA,kBAAA;AAJJ;AAOE;EACE,eAAA;AALJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.taken-questionnaire-modal {\n  padding: 2rem;\n  text-align: center;\n\n  h1 {\n    font-size: 1.5rem;\n    margin-bottom: 1rem;\n  }\n\n  p {\n    margin: 0 auto 1.5rem auto;\n  }\n\n  p.font-weight-bold {\n    margin: 0 auto;\n  }\n\n  a.btn {\n    min-width: 10rem;\n    margin-top: 1.5rem;\n  }\n\n  .modal-content {\n    padding: 2.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
