/* eslint-disable no-underscore-dangle */
import {
  FETCHING,
  FETCHING_FAILURE,
  INVESTOR_GOAL_CREATE,
  INVESTOR_GOAL_DELETE,
  INVESTOR_GOAL_EDIT,
  INVESTOR_GOAL_GET,
  INVESTOR_GOAL_LIST
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';
import InvestorGoalsElasticProvider from './investor-goals.es';

class InvestorGoalsProvider extends DataProvider {
  static PAGE_SIZE = 20;

  get es() {
    if (!this._es) this._es = new InvestorGoalsElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  list(params) {
    this.dispatch(FETCHING);
    params = { ...params };

    return this.provider.get(`${config.apiBase}investors/goals/`, params).then(data => {
      if (!data.error)
        this.dispatch(INVESTOR_GOAL_LIST, {
          data: data.data.results || [],
          meta: {
            params,
            numPages: Math.ceil(data.data.count / this.constructor.PAGE_SIZE),
            count: data.data.count,
            countPage: this.countPage(params, data)
          }
        });
      else this.dispatch(FETCHING_FAILURE);
      return data;
    });
  }

  get(id) {
    this.dispatch(FETCHING);

    return this.provider.get(`${config.apiBase}investors/goals/${id}/`).then(data => {
      if (!data.error) this.dispatch(INVESTOR_GOAL_GET, { data: data.data });
      else this.dispatch(FETCHING_FAILURE);
      return data;
    });
  }

  create(goal) {
    this.dispatch(FETCHING);
    const data = { ...goal };

    return this.provider.post(`${config.apiBase}investors/goals/`, data).then(response => {
      if (!response.error) this.dispatch(INVESTOR_GOAL_CREATE, { data: response.data });
      else this.dispatch(FETCHING_FAILURE);
      return response;
    });
  }

  update(id, data) {
    this.dispatch(FETCHING);

    return this.provider.patch(`${config.apiBase}investors/goals/${id}/`, data).then(response => {
      if (!response.error) this.dispatch(INVESTOR_GOAL_EDIT, { data: response.data });
      else this.dispatch(FETCHING_FAILURE);
      return response;
    });
  }

  delete(goal) {
    this.dispatch(FETCHING);

    return this.provider.delete(`${config.apiBase}investors/goals/${goal.id}/`).then(data => {
      if (!data.error) this.dispatch(INVESTOR_GOAL_DELETE, { data: goal });
      else this.dispatch(FETCHING_FAILURE);
      return data;
    });
  }
}

export default InvestorGoalsProvider;
