import useProposalMetadata from 'components/advisor/proposal/body/sections/hooks';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import { BASE_REACT_SUMMERNOTE_OPTIONS } from 'components/utils/wysiwyg/constants';
import PropTypes from 'prop-types';
import React from 'react';

const CUSTOM_OPTIONS = {
  ...BASE_REACT_SUMMERNOTE_OPTIONS,
  toolbar: [
    ['insert', ['link']],
    ['misc', ['undo', 'redo']]
  ],
  maxLength: 250,
  height: 180
};

const FooterSection = ({ options, ...rest }) => {
  /*
   * The use of this hook is to reuse the implemented logic
   * in the proposals section and be able to reuse the same component in both places.
   */
  const metaProps = useProposalMetadata({ metadata: rest.metadata || {}, ...rest });
  const editBoxTextProps = rest.metadata ? metaProps : rest;

  return (
    <EditBoxText
      options={options}
      {...editBoxTextProps}
      className="sc-footer"
      disableTitleChange
      footer
    />
  );
};

FooterSection.defaultProps = {
  options: CUSTOM_OPTIONS
};

FooterSection.propTypes = {
  options: PropTypes.object
};

export default FooterSection;
