/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
import {
  ALLOWED_LOCALES,
  ENGLISH_LANGUAGE,
  LANGUAGE_URL_PARAM,
  SPANISH_LANGUAGE
} from 'lang/constants';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LanguageButton from './button';

const LanguageOptions = () => {
  const url = new URL(window.location.href);

  const [currentLocale, setCurrentLocale] = useState(url.searchParams.get(LANGUAGE_URL_PARAM));

  useEffect(() => {
    if (!currentLocale || !ALLOWED_LOCALES.includes(currentLocale)) {
      // adds the `lang` param to the URL if it doesn't exist
      // or if it's not a valid locale
      url.searchParams.set(LANGUAGE_URL_PARAM, ENGLISH_LANGUAGE);
      window.history.replaceState(null, '', url);
      setCurrentLocale(ENGLISH_LANGUAGE);
    } else if (url.searchParams.get(LANGUAGE_URL_PARAM) !== currentLocale) {
      // updates the `lang` param in the URL if it doesn't match the current locale
      url.searchParams.set(LANGUAGE_URL_PARAM, currentLocale);
      window.location.href = url.href;
    }
  }, [currentLocale]);

  const handleLocaleChange = locale => () => {
    setCurrentLocale(locale);
  };

  return (
    <div className="language-options">
      <label>
        <FormattedMessage id="rtq.header.language" />
      </label>
      <div>
        <LanguageButton
          currentLocale={currentLocale}
          locale={ENGLISH_LANGUAGE}
          handleLocaleChange={handleLocaleChange(ENGLISH_LANGUAGE)}
        />{' '}
        |{' '}
        <LanguageButton
          currentLocale={currentLocale}
          locale={SPANISH_LANGUAGE}
          handleLocaleChange={handleLocaleChange(SPANISH_LANGUAGE)}
        />
      </div>
    </div>
  );
};

LanguageOptions.defaultProps = {
  user: null
};

LanguageOptions.propTypes = {
  user: PropTypes.object
};

export default LanguageOptions;
