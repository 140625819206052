import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW, LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  highlightedText: { fontWeight: 'bold' },
  riskToleranceScore: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 },
  title: {
    ...BASE_TABLE_ROW,
    flexGrow: 1,
    paddingHorizontal: 10,
    paddingVertical: 8,
    alignItems: 'center',
    backgroundColor: LIGHT_GRAPHITE_COLOR
  }
});

export default styles;
