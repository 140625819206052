import cn from 'classnames';
import { FormGroup, VerboseErrorInput as Input } from 'components/form';
import CountryDropDown, { US_CODE } from 'components/form/country-drop-down';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { validation } from 'utils/form';
import config from '../../../config';
import './styles.scss';

export const validateAddress = (values, addressFieldName = 'address1') => {
  const errors = {};

  errors[addressFieldName] =
    errors[addressFieldName] ||
    validation.required(values[addressFieldName]) ||
    validation.address(values[addressFieldName]);

  errors.city = errors.city || validation.required(values.city) || validation.city(values.city);

  if (US_CODE === values.country) {
    errors.state = errors.state || validation.required(values.state);
    errors.postcode =
      errors.postcode ||
      validation.required(values.postcode) ||
      validation.zipCode(values.postcode);
  }

  return errors;
};

const AddressForm = ({ fields }) => {
  const isUSACountry = fields.country.value === US_CODE;

  useEffect(() => {
    if (!isUSACountry) fields.state.autofill('');
  }, [fields.country.value]);

  return (
    <div className="address-form">
      <div className="row row-address-1">
        <div className="col-sm-12 col-md-8">
          <FormGroup {...fields.address1}>
            <span className="label">Address</span>
            <Input type="text" className="form-control" {...fields.address1} />
          </FormGroup>
        </div>
        <div className="col-sm-12 col-md-4">
          <FormGroup {...fields.country}>
            <span className="label">Country</span>
            <CountryDropDown {...fields.country} />
          </FormGroup>
        </div>
      </div>
      <div className="row row-address-2">
        <div className={cn('col-sm-12', { 'col-md-6': isUSACountry, 'col-md-8': !isUSACountry })}>
          <FormGroup {...fields.city}>
            <span className="label">City</span>
            <Input type="text" className="form-control" {...fields.city} />
          </FormGroup>
        </div>
        {isUSACountry && (
          <div className="col-sm-12 col-md-2">
            <span className="label">State</span>
            <FormGroup {...fields.state}>
              <div className="c-select-wrap">
                <select
                  className="form-control c-select"
                  name={fields.state.name}
                  autoComplete={fields.state.name}
                  onChange={fields.state.onChange}
                  value={fields.state.value}
                >
                  <option value="">--------</option>
                  {Object.keys(config.address.states).map(item => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </FormGroup>
          </div>
        )}
        <div className="col-sm-12 col-md-4">
          <FormGroup {...fields.postcode}>
            <span className="label">Zip code</span>
            <Input type="text" className="form-control" {...fields.postcode} />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  fields: PropTypes.shape({
    address1: PropTypes.object.isRequired,
    city: PropTypes.object.isRequired,
    country: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    postcode: PropTypes.object.isRequired
  }).isRequired
};

export default AddressForm;
