/* eslint-disable react/no-unstable-nested-components */
import TabSwitch from 'components/tab-switch';
import Link from 'components/utils/link';
import { SortableHeader } from 'components/utils/react-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import { ARCHIVED, DRAFT, PUBLISHED } from './constants';
import ReportTemplatePropTypes from './types';

const STATUS_OPTIONS = [
  { value: PUBLISHED, label: 'Published' },
  { value: ARCHIVED, label: 'Archived' }
];

const ReportTemplatesList = ({ onCreate, templates, title, type }) => {
  const [statusFilter, setStatusFilter] = useState(PUBLISHED);
  const [search, setSearch] = useState('');

  // reset the filter status when the type of templates change
  useEffect(() => {
    setStatusFilter(PUBLISHED);
  }, [type]);

  useEffect(() => {
    setSearch('');
  }, [type, templates, statusFilter]);

  const data = useMemo(
    () =>
      templates.filter(
        t =>
          (statusFilter === PUBLISHED
            ? [PUBLISHED, DRAFT].includes(t.status)
            : t.status === ARCHIVED) && t.name.toLowerCase().includes(search.toLowerCase())
      ),
    [templates, statusFilter, search]
  );

  const columns = [
    {
      columns: [
        {
          Header: <SortableHeader title="Name" />,
          accessor: 'name',
          className: 'td-align-left',
          headerClassName: 'th-align-left',
          minWidth: 50,
          Cell: ({ original: { name, type, id } }) => (
            <Link to={`/advisor/templates/${type}/${id}`}>{name}</Link>
          )
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Approved" />,
          accessor: 'approved',
          minWidth: 25,
          Cell: ({ value: approved }) => (approved ? 'Yes' : 'No')
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Created by" />,
          accessor: 'created_by',
          minWidth: 25,
          Cell: ({ value: createdBy }) =>
            createdBy?.user ? `${createdBy.user.first_name} ${createdBy.user.last_name}` : '-'
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Last updated by" />,
          accessor: 'last_updated_by',
          minWidth: 25,
          Cell: ({ value: lastUpdatedBy }) => lastUpdatedBy ?? 'StratiFi'
        }
      ]
    },
    {
      columns: [
        {
          Header: <SortableHeader title="Last updated" />,
          accessor: 'modified',
          minWidth: 25,
          Cell: ({ value: modified }) => moment(modified).format('ll'),
          defaultSortDesc: true
        }
      ]
    }
  ];

  return (
    <div id="report-templates-list">
      <div className="header">
        <h4>{title} Templates</h4>
        <button type="button" className="btn btn-primary" onClick={onCreate}>
          Create a new template
        </button>
      </div>
      <div className="table-header">
        <TabSwitch options={STATUS_OPTIONS} onChange={setStatusFilter} value={statusFilter} />
        <div className="right__search">
          <input
            className="form-control"
            placeholder="Search ..."
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <span className="icon-search_icn" />
        </div>
      </div>
      <ReactTable
        className="templates-table"
        columns={columns}
        data={data}
        minRows={0}
        noDataText="No Templates."
        showPagination
        resizable={false}
        defaultSorted={[{ id: 'modified', desc: true }]}
      />
    </div>
  );
};

ReportTemplatesList.propTypes = {
  onCreate: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(ReportTemplatePropTypes).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default ReportTemplatesList;
