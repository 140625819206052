/* eslint-disable class-methods-use-this */
import config from 'app/config';
import clone from 'clone';
import Chart from '..';

const { serial } = config.chart;

class InvestmentHorizonChart extends Chart {
  getOptions = () => {
    const defaultOptions = { ...clone(serial.returnInvestmentHorizon, false) };
    const graphs = defaultOptions.graphs.map(element => ({ ...element, fixedColumnWidth: 11 }));
    return { ...defaultOptions, graphs };
  };
}

export default InvestmentHorizonChart;
