import config from '../config';
import DataProvider from './data';

class TrustedDeviceProvider extends DataProvider {
  register(deviceId, deviceUserAgent) {
    return this.provider.post(`${config.apiBase}trusted_devices/${deviceId}/register/`, {
      device_id: deviceId,
      device_user_agent: deviceUserAgent
    });
  }
}

export default TrustedDeviceProvider;
