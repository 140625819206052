import { Path, StyleSheet, Svg } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const styles = StyleSheet.create({
  default: {
    fill: 'black'
  }
});

const ArrowUp = ({ width, height, styles: customStyles }) => (
  <Svg style={{ width, height }} viewBox="0 0 400 512">
    <Path
      style={[styles.default, customStyles]}
      d="M17.4,202.3l160-160c6.2-6.3,14.4-9.4,22.6-9.4s16.4,3.1,22.6,9.4l160,160c12.5,12.5,12.5,32.8,0,45.3s-32.8,12.5-45.3,0
	L232,142.1v306.8c0,17.7-14.3,31.1-31.1,31.1S168,466.6,168,448.9V142.1L62.6,247.5c-12.5,12.5-32.8,12.5-45.3,0
	S4.9,214.8,17.4,202.3z"
    />
  </Svg>
);

ArrowUp.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.object
};

ArrowUp.defaultProps = {
  width: 400,
  height: 'auto',
  styles: {}
};

export default ArrowUp;
