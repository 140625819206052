// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#create-account-modal {
  overflow-y: auto;
}
#create-account-modal .download-csv-link {
  display: block;
  font-size: 0.9em;
}
#create-account-modal .investor-create-email-link-container > span, #create-account-modal .investor-create-email-link-container > div {
  height: 110px;
}
#create-account-modal .investor-create-email-link-container > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
#create-account-modal .investor-create-email-link-container #sub-text {
  font-size: 14px;
  font-weight: normal;
  padding-left: 0px;
  padding-top: 0px;
}
#create-account-modal .investor-create-email-link-container .investor-create-email-link-container__copy-link {
  border: 1px solid #09acf8;
  background: transparent;
  color: #09acf8;
  margin-top: 10px;
}
#create-account-modal .create-account-link-copied-container {
  padding: 2rem;
  text-align: center;
}
#create-account-modal .create-account-link-copied-container h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
#create-account-modal .create-account-link-copied-container p {
  width: 60%;
  margin: 0 auto 1.5rem auto;
}
#create-account-modal .create-account-link-copied-container p.font-weight-bold {
  margin: 0 auto;
}
#create-account-modal .create-account-link-copied-container button {
  min-width: 10rem;
}
#create-account-modal .modal-dialog.modal-dialog--action-email {
  width: 75vw;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/create/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,cAAA;EACA,gBAAA;AACJ;AAGI;EAEE,aAAA;AAFN;AAKI;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,yBAAA;AAHN;AAMI;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAJN;AAOI;EACE,yBAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AALN;AASE;EACE,aAAA;EACA,kBAAA;AAPJ;AASI;EACE,iBAAA;EACA,mBAAA;AAPN;AAUI;EACE,UAAA;EACA,0BAAA;AARN;AAWI;EACE,cAAA;AATN;AAYI;EACE,gBAAA;AAVN;AAcE;EACE,WAAA;AAZJ","sourcesContent":["#create-account-modal {\n  overflow-y: auto;\n\n  .download-csv-link {\n    display: block;\n    font-size: 0.9em;\n  }\n\n  .investor-create-email-link-container {\n    & > span,\n    & > div {\n      height: 110px;\n    }\n\n    & > div {\n      display: flex;\n      flex-direction: column;\n      align-items: flex-end;\n      justify-content: flex-end;\n    }\n\n    #sub-text {\n      font-size: 14px;\n      font-weight: normal;\n      padding-left: 0px;\n      padding-top: 0px;\n    }\n\n    .investor-create-email-link-container__copy-link {\n      border: 1px solid #09acf8;\n      background: transparent;\n      color: #09acf8;\n      margin-top: 10px;\n    }\n  }\n\n  .create-account-link-copied-container {\n    padding: 2rem;\n    text-align: center;\n\n    h1 {\n      font-size: 1.5rem;\n      margin-bottom: 1rem;\n    }\n\n    p {\n      width: 60%;\n      margin: 0 auto 1.5rem auto;\n    }\n\n    p.font-weight-bold {\n      margin: 0 auto;\n    }\n\n    button {\n      min-width: 10rem;\n    }\n  }\n\n  .modal-dialog.modal-dialog--action-email {\n    width: 75vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
