export default {
  type: 'serial',
  theme: 'none',
  categoryField: 'title',
  startDuration: 0,
  categoryAxis: {
    axisAlpha: 0,
    color: '#fff',
    gridPosition: 'start',
    position: 'left'
  },
  trendLines: [],
  graphs: [
    {
      balloonText: `Serie-1: [[value]]%`,
      columnWidth: 0.3,
      fillAlphas: 0.8,
      id: 'AmGraph-1',
      lineAlpha: 0.2,
      title: 'Serie-1',
      type: 'column',
      valueField: 'target'
    },
    {
      balloonText: `Serie-2: [[value]]%`,
      columnWidth: 0.3,
      fillAlphas: 0.8,
      id: 'AmGraph-2',
      lineAlpha: 0.2,
      title: 'Serie-2',
      type: 'column',
      valueField: 'recommended'
    }
  ],
  guides: [],
  valueAxes: [
    {
      id: 'ValueAxis-1',
      position: 'bottom',
      axisAlpha: 0
    }
  ],
  allLabels: [],
  balloon: {},
  titles: [],
  export: {
    enabled: true
  }
};
