import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  morningstarStyleBoxChart: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10
  },
  styleBoxChart: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  },
  styleBoxChartRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3
  },
  styleBoxChartItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  emptyStyleBoxChartItem: { flexBasis: 15, height: 10 },
  headerStyleBoxChartItem: {
    justifyContent: 'flex-end',
    flexBasis: 35,
    height: 10,
    fontSize: 7
  },
  typeStyleBoxChartItem: {
    alignItems: 'flex-end',
    flexBasis: 15,
    height: 35,
    fontSize: 7
  },
  resultStyleBoxChartItem: {
    flexBasis: 35,
    height: 35,
    fontWeight: 'bold',
    fontSize: 9
  },
  typeStyleBoxChartItemText: { transform: 'rotate(-90deg)' },
  styleBoxLegend: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  },
  styleBoxLegendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5
  },
  styleBoxLegendItemBox: { width: 10, height: 10 },
  styleBoxLegendItemText: { fontSize: 7 }
});

export default styles;
