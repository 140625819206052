import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  FOR_LANDSCAPE_ORIENTATION,
  LIGHT_GRAPHITE_COLOR
} from 'reports/base/styles';

const ANSWER_BACKGROUND_COLOR = '#f6f7f7';

export const styles = StyleSheet.create({
  row: {
    ...BASE_TABLE_ROW,
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: LIGHT_GRAPHITE_COLOR
  },
  cell: {
    ...BASE_TABLE_CELL,
    paddingVertical: 16,
    paddingHorizontal: 10,
    [FOR_LANDSCAPE_ORIENTATION]: {
      paddingVertical: 12
    }
  },
  answerCell: { backgroundColor: ANSWER_BACKGROUND_COLOR, textAlign: 'right', fontWeight: 'bold' }
});

export default styles;
