import {
  investorAccountsSelector,
  investorSelector,
  isProspectSelector
} from 'components/advisor/investors/selectors';
import ModelPortfolioBenchmark from 'components/advisor/model-portfolio-benchmark';
import AnalysisScores from 'components/advisor/risk-analysis/analysis-scores';
import InvestorPrismReportViewer from 'components/advisor/risk-analysis/report/investor-prism/viewer';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import TopRiskAttribution from 'components/advisor/risk-analysis/risk-analysis-target/top-risk-attribution';
import ScenariosScores from 'components/advisor/risk-analysis/scenarios-scores';
import {
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL,
  showSecurityDetailsSection
} from 'components/advisor/risk-analysis/securities/common/utils';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import PrismRating from 'components/utils/prism-rating';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import InvestorPropertyOverview from './investor-property-overview';
import {
  combinedPortfoliosSelector,
  investorsGoalsSelector,
  mostRiskyPositionsSelector,
  scenariosSelector,
  totalAssetsSelector
} from './selectors';
import './styles.scss';
import ToggableAccount from './toggable-account';

const InvestorPrism = props => {
  const { investorProvider, investorGoalsProvider, modelProvider, prospectProvider } =
    useContext(AdvisorContext);

  const {
    accounts,
    benchmarks,
    combinedPortfolios,
    goals,
    investor,
    isProspect,
    mostRiskyPositions,
    params: { id: investorId },
    scenarios,
    totalAssets
  } = props;

  const provider = isProspect ? prospectProvider : investorProvider;
  const { aggregated_prism_scores: aggrPrism, aggregated_target_scores: aggrTarget } = investor;

  useEffect(() => {
    investorGoalsProvider.list({ investor: investorId });
    provider.getAccounts(investorId);
    modelProvider.getCommonBenchmarks();
  }, []);

  const onChartReady = useCallback(
    (id, data) => {
      investorProvider.saveChartImage({ [id]: data });
    },
    [JSON.stringify(investor), JSON.stringify(accounts)]
  );

  const positions = accounts
    .filter(account => !account.excluded)
    .map(account => account.positions)
    .flat();

  return (
    <div className="prism-investor">
      <div className="overview">
        <div className="overview__properties">
          <InvestorPropertyOverview property="Goals" value={goals.length} />
          <InvestorPropertyOverview property="Accounts" value={accounts.length} />
          <InvestorPropertyOverview
            property="Total assets"
            value={<FormattedNumber value={totalAssets} format="currency" />}
          />
        </div>
        <InvestorPrismReportViewer
          accounts={accounts}
          benchmarks={benchmarks}
          investor={investor}
          scenarios={scenarios}
          totalAssets={totalAssets}
        />
      </div>

      {!_.isEmpty(aggrPrism) && (
        <div className="result-container prism-overall">
          <div className="result-heading">
            Portfolio Risk{' '}
            <span>
              Powered by <b>PRISM Rating &trade;</b>
            </span>
          </div>
          <PrismRating prismSummary={aggrPrism} targetSummary={aggrTarget} />
        </div>
      )}

      {!_.isEmpty(scenarios) && !!totalAssets && (
        <div className="scenarios">
          <h2>PRISM Analysis for Market Crisis Scenarios</h2>
          <ScenariosScores
            scenarios={scenarios}
            value={totalAssets}
            performance={investor.aggregated_scenarios}
          />
        </div>
      )}

      {aggrPrism && aggrTarget && (
        <div className="result-container prism-overall">
          <div className="result-heading">Benchmark PRISM Rating &trade;</div>
          <ModelPortfolioBenchmark
            benchmark={benchmarks}
            prismOverall={aggrPrism.overall}
            targetOverall={aggrTarget.overall}
          />
        </div>
      )}

      <div className="risk-factor-analysis">
        <h2>Risk Factor Analysis</h2>
        <AnalysisScores targetScore={aggrTarget} prismScore={aggrPrism} />
      </div>

      <div className="risk-factor-analysis hide-from-report">
        <h2>Assets Allocation</h2>
        <div className="overview">
          <PositionsAnalysis
            id={PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}
            onChartReady={onChartReady}
            portfolio={combinedPortfolios}
            className="fill-space"
          />
        </div>
      </div>

      {showSecurityDetailsSection(combinedPortfolios, SECURITY_STYLES) && (
        <div className="risk-factor-analysis box-position-analysis">
          <div className="header">
            <h2>Investment Style</h2>
          </div>
          <div className="investment-style-container">
            <InvestmentStyle portfolio={combinedPortfolios} />
          </div>
        </div>
      )}

      {showSecurityDetailsSection(combinedPortfolios, SECURITY_SECTORS) && (
        <div className="risk-factor-analysis box-position-analysis hide-from-report">
          <div className="header">
            <h2>Sector Exposure</h2>
          </div>
          <div className="sector-exposure-container">
            <SectorExposure
              id={SECTOR_EXPOSURE_CHART_ID}
              onChartReady={onChartReady}
              portfolio={combinedPortfolios}
            />
          </div>
        </div>
      )}

      {showSecurityDetailsSection(combinedPortfolios, SECURITY_UNDERLYING_MODEL) && (
        <div className="risk-factor-analysis box-position-analysis hide-from-report">
          <div className="header">
            <h2>Top 10 Holdings</h2>
          </div>
          <div className="sector-exposure-container">
            <TopHoldings portfolio={combinedPortfolios} />
          </div>
        </div>
      )}

      {showSecurityDetailsSection(combinedPortfolios, SECURITY_REGIONS) && (
        <div className="risk-factor-analysis box-position-analysis hide-from-report">
          <div className="header">
            <h2>Geographic Exposure</h2>
          </div>
          <div className="geographic-exposure-container">
            <GeographicExposure
              id={GEOGRAPHIC_EXPOSURE_CHART_ID}
              onChartReady={onChartReady}
              portfolio={combinedPortfolios}
            />
          </div>
        </div>
      )}

      {!_.isEmpty(positions) && !!totalAssets && (
        <div className="security-type-concentration hide-from-report">
          <h2>Security Type Concentration</h2>
          <SecurityTypeConcentration
            positions={positions}
            onChartReady={onChartReady}
            totalAssets={totalAssets}
          />
          <p>This chart only considers positions from non-excluded accounts.</p>
        </div>
      )}

      {!_.isEmpty(mostRiskyPositions) && !!totalAssets && (
        <div className="top-risk-attributions">
          <h2>Top Risk Attributions from each Account</h2>
          <TopRiskAttribution
            investor={investor}
            riskyPositions={mostRiskyPositions}
            riskyPositionsOnly
          />
        </div>
      )}

      <div className="toggable-accounts">
        <h2>Accounts</h2>
        {accounts.map(account => (
          <ToggableAccount
            investor={investor}
            account={account}
            key={`toggable-acc-${account.id}`}
          />
        ))}
      </div>
    </div>
  );
};

InvestorPrism.contextTypes = {
  investorGoalsProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired
};

InvestorPrism.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  benchmarks: PropTypes.arrayOf(PropTypes.object),
  combinedPortfolios: PropTypes.object.isRequired,
  goals: PropTypes.arrayOf(PropTypes.object),
  investor: PropTypes.object,
  isProspect: PropTypes.bool.isRequired,
  mostRiskyPositions: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.object.isRequired,
  scenarios: PropTypes.arrayOf(PropTypes.object),
  totalAssets: PropTypes.number
};

InvestorPrism.defaultProps = {
  accounts: [],
  benchmarks: [],
  goals: [],
  investor: null,
  mostRiskyPositions: [],
  scenarios: [],
  totalAssets: null
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    params: { id: investorId }
  } = ownProps;
  const { investorsGoals } = stateProps;

  return {
    ...stateProps,
    ...ownProps,
    goals: investorsGoals[investorId]
  };
};

export default connect(
  state => ({
    accounts: investorAccountsSelector(state),
    benchmarks: state.models.benchmark,
    combinedPortfolios: combinedPortfoliosSelector(state),
    investor: investorSelector(state),
    investorsGoals: investorsGoalsSelector(state),
    isProspect: isProspectSelector(state),
    mostRiskyPositions: mostRiskyPositionsSelector(state),
    scenarios: scenariosSelector(state),
    totalAssets: totalAssetsSelector(state),
    user: state.auth.user
  }),
  null,
  mergeProps
)(InvestorPrism);
