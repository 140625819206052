import { Path, StyleSheet, Svg } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const styles = StyleSheet.create({
  default: {
    fill: 'black'
  }
});

const ArrowDown = ({ width, height, styles: customStyles }) => (
  <Svg style={{ width, height }} viewBox="0 0 400 512">
    <Path
      style={[styles.default, customStyles]}
      d="M382.6,310.6l-160,160c-6.2,6.3-14.4,9.4-22.6,9.4s-16.4-3.1-22.6-9.4l-160-160c-12.5-12.5-12.5-32.8,0-45.3
	s32.8-12.5,45.3,0L168,370.8V64c0-17.7,14.3-31.1,31.1-31.1S232,46.3,232,64v306.8l105.4-105.4c12.5-12.5,32.8-12.5,45.3,0
	S395.1,298.1,382.6,310.6z"
    />
  </Svg>
);

ArrowDown.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.object
};

ArrowDown.defaultProps = {
  width: 400,
  height: 'auto',
  styles: {}
};

export default ArrowDown;
