import { ACCOUNT_TARGET_TYPE, MODEL_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';

const PortfolioOverallAllocation = ({ body, breakSection, portfolio, title, type }) => {
  const chart =
    type === ACCOUNT_TARGET_TYPE
      ? portfolio[`${PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}-${portfolio.id}`]
      : portfolio[PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID];

  return (
    <BaseSection title={title} body={body} breakSection={breakSection}>
      <PositionsAnalysis
        chart={chart}
        hiddenVal={type === MODEL_TARGET_TYPE}
        portfolio={portfolio}
        showHeadingRow
        showPercent
      />
    </BaseSection>
  );
};

PortfolioOverallAllocation.propTypes = {
  body: PropTypes.string,
  breakSection: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired
};

PortfolioOverallAllocation.defaultProps = {
  body: '',
  breakSection: false,
  title: 'Portfolio'
};

export default PortfolioOverallAllocation;
