import { ACCOUNT_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from 'utils/utils';
import Chart from '..';
import defaultOptions from './options';
import './styles.scss';

const HistoricalReturnsChart = ({
  data,
  hasBenchmark,
  hasRecommended,
  id,
  onChartReady,
  options
}) => {
  const params = {
    id,
    data,
    style: { height: 700, width: '100%' },
    onChartReady,
    options: _.merge({}, defaultOptions, options)
  };

  if (!hasRecommended)
    params.options = {
      ...params.options,
      graphs: params.options.graphs.filter(g => g.id !== 'recommended')
    };

  if (!hasBenchmark)
    params.options = {
      ...params.options,
      graphs: params.options.graphs.filter(g => g.id !== 'benchmark')
    };

  params.options = {
    ...params.options,
    graphs: params.options.graphs.map(g => {
      if (hasRecommended && options.recommendedName && g.id === 'recommended')
        return { ...g, title: options.recommendedName };
      if (hasBenchmark && options.benchmarkName && g.id === 'benchmark')
        return { ...g, title: options.benchmarkName };
      if (options.targetName && g.id === 'target') return { ...g, title: options.targetName };

      return g;
    })
  };

  params.options.graphs[0].balloonFunction = item => `
    <b>${options.targetName || 'Current Portfolio'}:</b> ${formatMoney(
    item.dataContext.target
  )}<br />
    ${
      hasRecommended && item.dataContext.recommended
        ? `<b>${options.recommendedName}:</b> ${formatMoney(item.dataContext.recommended)}<br />`
        : ''
    }
    ${
      hasBenchmark && item.dataContext.benchmark
        ? `<b>${options.benchmarkName}:</b> ${formatMoney(item.dataContext.benchmark)}`
        : ''
    }
  `;

  const max = data.reduce(
    (max, { target, recommended, benchmark }) => Math.max(max, target, recommended, benchmark),
    0
  );
  params.options.valueAxes[0].maximum = max;

  return (
    <div className="historical-returns-chart">
      <Chart {...params} fullWidth />
    </div>
  );
};

HistoricalReturnsChart.propTypes = {
  data: PropTypes.array.isRequired,
  hasBenchmark: PropTypes.bool,
  hasRecommended: PropTypes.bool,
  id: PropTypes.string,
  onChartReady: PropTypes.func,
  options: PropTypes.object,
  proposalType: PropTypes.string
};

HistoricalReturnsChart.defaultProps = {
  hasBenchmark: true,
  hasRecommended: true,
  id: 'historical-returns-chart',
  onChartReady: undefined,
  options: {},
  proposalType: ACCOUNT_PROPOSAL_TYPE
};

export default React.memo(HistoricalReturnsChart);
