import { irr } from 'financial';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { ageCalculator, interpolateRef, returnToAmount, roundThousands } from 'utils/questionnaire';
import { setPrecision, withCurrencyFormat } from 'utils/utils';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../props';

const DEFAULT_PERCENT = 5;

const useExpectedReturn = ({ question, field }) => {
  const refs = question.data?.refs || {};

  const [amount, setAmount] = useState(0);

  const age = ageCalculator(refs.birthday);
  const withdrawalStart = Number(refs['withdrawal-start']) || 0;

  // number of years until the user will start withdrawing the funds.
  const withdrawalStartsIn = Math.max(0, withdrawalStart - age);

  refs['retire-in'] = withdrawalStartsIn > 0 ? ` over ${withdrawalStartsIn} year(s)` : '';

  // year withdrawal amount
  const withdrawalAmount = Number(refs['withdrawal-amount']);
  const withdrawalDuration = Number(refs['withdrawal-duration']) || 0;

  // total amount that the user invested or is comfortable investing
  const initialInvestment =
    refs['liquid-assets'] && refs['percentage-assets-invested']
      ? Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])
      : 0;

  refs['assets-invested'] =
    withCurrencyFormat(initialInvestment, 'compact', 0).replace('M', 'MM') || 0;

  const questionText = useMemo(
    () => interpolateRef(question.question, refs),
    [JSON.stringify(refs)]
  );

  const questionDescription = useMemo(
    () => interpolateRef(question.description, refs),
    [JSON.stringify(refs)]
  );

  // total amount that the user needs for the retirement
  const goalInvestment = useMemo(() => {
    const val = roundThousands(
      refs['withdrawal-type'] === 'annual'
        ? Number(refs['withdrawal-amount']) * Number(refs['withdrawal-duration'])
        : Number(refs['withdrawal-amount'])
    );
    return Number.isFinite(val) && !Number.isNaN(val) ? setPrecision(val, 2) : 0;
  }, [JSON.stringify(refs)]);

  // amount that the user would invest per year
  const contributionPerYear = useMemo(
    () => Number(refs.income) * Number(refs.contribution) || 0,
    [JSON.stringify(refs)]
  );

  const recommendedReturn = useMemo(() => {
    const yearsOfContribution = withdrawalStartsIn - 1 >= 0 ? withdrawalStartsIn - 1 : 0;
    const val = withdrawalStartsIn
      ? irr([
          initialInvestment,
          ...Array(yearsOfContribution).fill(contributionPerYear),
          -goalInvestment
        ]) * 100
      : irr([-initialInvestment, ...Array(withdrawalDuration).fill(withdrawalAmount)]) * 100;

    return Number.isFinite(val) && !Number.isNaN(val) && val > 0 ? setPrecision(val, 2) : 0;
  }, [
    contributionPerYear,
    initialInvestment,
    goalInvestment,
    withdrawalStartsIn,
    withdrawalAmount,
    withdrawalDuration
  ]);

  /*
   * If a field is provided, means that this hook is being used in a form.
   * We must attach some effects to keep the field values updated
   */
  if (field) {
    // update initial expected return %
    useEffect(() => {
      const expected = question.data?.default_value?.expected ?? DEFAULT_PERCENT;
      if (!field.expected.value && !Number.isFinite(field.expected.value))
        field.expected.autofill(expected);
    }, [question.data?.default_value?.expected]);

    // update the needed return %
    useEffect(() => {
      if (field.needed.value !== recommendedReturn) field.needed.autofill(recommendedReturn);
    }, [recommendedReturn]);

    // update the expected return %
    useEffect(() => {
      if (field.expected.value) {
        const returnAmount = roundThousands(
          returnToAmount(field.expected.value, withdrawalStartsIn, initialInvestment)
        );
        setAmount(returnAmount);
      }
    }, [field.expected.value]);
  }

  return {
    amount,
    contributionPerYear,
    defaultAmount: 0,
    goalInvestment,
    initialInvestment,
    onChange: field?.expected?.onChange,
    questionDescription,
    questionText,
    recommendedReturn,
    refs,
    withdrawalAmount,
    withdrawalDuration,
    withdrawalStartsIn
  };
};

useExpectedReturn.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default useExpectedReturn;
