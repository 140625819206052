import { StyleSheet } from '@react-pdf/renderer';
import { LIGHT_GRAPHITE_COLOR, RISK_GREEN_COLOR, RISK_RED_COLOR } from 'reports/base/styles';

const styles = StyleSheet.create({
  scenario: {
    alignItems: 'center',
    border: 1,
    borderColor: LIGHT_GRAPHITE_COLOR,
    borderStyle: 'solid'
  },
  scenarioTitle: {
    width: 130,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 5,
    textAlign: 'center',
    backgroundColor: LIGHT_GRAPHITE_COLOR
  },
  scenarioText: { width: 130, fontSize: 8, paddingHorizontal: 10 },
  gainLossText: { marginTop: 5, marginBottom: 10 },
  upText: { color: RISK_GREEN_COLOR, fontWeight: 'bold' },
  downText: { color: RISK_RED_COLOR, fontWeight: 'bold' }
});

export default styles;
