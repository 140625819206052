// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scenarios-container {
  display: flex;
  gap: 10px;
}
.scenarios-container .scenario-container.box {
  flex: 1 1 25%;
}
.scenarios-container .scenario-container.box:last-child {
  margin-right: 0;
}
.scenarios-container .scenario-container.box .RiskOverall {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scenarios-container .scenario-container.box .RiskOverall .score-representation .number {
  font-size: 1.8rem;
}
.scenarios-container .scenario-container.box .RiskOverall .speedometer {
  height: 132px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/risk-analysis/scenarios-scores/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;AACJ;AACI;EACE,eAAA;AACN;AAEI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAAN;AAEM;EACE,iBAAA;AAAR;AAGM;EACE,aAAA;AADR","sourcesContent":[".scenarios-container {\n  display: flex;\n  gap: 10px;\n\n  .scenario-container.box {\n    flex: 1 1 25%;\n\n    &:last-child {\n      margin-right: 0;\n    }\n\n    .RiskOverall {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n\n      .score-representation .number {\n        font-size: 1.8rem;\n      }\n\n      .speedometer {\n        height: 132px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
