import Link from 'components/utils/link';
import React from 'react';

// Link to Overview
export const overviewLink = ({ securityID }) => (
  <Link to={`/advisor/securities/${securityID}/overview`}>Overview</Link>
);

export const returnsLink = ({ securityID }) => (
  <Link to={`/advisor/securities/${securityID}/returns`}>Returns</Link>
);

export const riskReturnsLink = ({ securityID }) => (
  <Link to={`/advisor/securities/${securityID}/returns-risk`}>Returns Risk</Link>
);

export const compareAnalyzeLink = ({ securityID }) => (
  <Link to={`/advisor/securities/${securityID}/performance-analysis`}>Compare and Analyze</Link>
);

export const underlyingHoldingLink = ({ securityID }) => (
  <Link to={`/advisor/securities/${securityID}/underlying-holding`}>Underlying Holding</Link>
);

export const accessControlLink = ({ securityID }) => (
  <Link to={`/advisor/securities/${securityID}/access-control`}>Access control</Link>
);
