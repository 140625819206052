import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import RiskGauge from 'reports/base/charts/risk-gauge';
import BaseSection from 'reports/base/sections/base';
import styles from './styles';

const Factor = ({ risk, text, title, tolerance }) => (
  <View style={styles.factor}>
    <Text style={styles.factorTitle}>{title}</Text>
    <RiskGauge width={80} height={80} risk={risk} tolerance={tolerance} withRiskScore />
    <Text style={styles.factorText}>{text}</Text>
  </View>
);

Factor.propTypes = {
  risk: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tolerance: PropTypes.number
};

Factor.defaultProps = {
  tolerance: undefined
};

const AnalysisScores = ({ prismSummary, targetSummary }) => (
  <BaseSection title="Risk Factor Analysis" wrap={false}>
    <View style={styles.factors}>
      <Factor
        risk={prismSummary.tail}
        tolerance={targetSummary?.tail}
        title="Tail Event Risk"
        text="Sudden and severe market losses happen. This score measures how vulnerable your portfolio is in the event of a market shock."
      />

      <Factor
        risk={prismSummary.correlation}
        tolerance={targetSummary?.correlation}
        title="Diversification Risk"
        text="When correlation in your portfolio is high, you lose the diversification benefits of your portfolio and expose yourself to a coordinated loss event."
      />

      <Factor
        risk={prismSummary.concentrated}
        tolerance={targetSummary?.concentrated}
        title="Concentrated Stock Risk"
        text="A large percentage of your portfolio exposure represented in only a few stocks makes your portfolio more susceptible to idiosyncratic risk."
      />

      <Factor
        risk={prismSummary.volatility}
        tolerance={targetSummary?.volatility}
        title="Volatility Risk"
        text="Breakdown of portfolio based on the gains and losses in holdings as the volatility in the markets changes. Assets that are positively correlated to volatility help reduce losses when volatility increases."
      />
    </View>
  </BaseSection>
);

AnalysisScores.propTypes = {
  prismSummary: PropTypes.object.isRequired,
  targetSummary: PropTypes.object
};

AnalysisScores.defaultProps = {
  targetSummary: {}
};

export default AnalysisScores;
