import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import { useContext } from 'react';

const DEFAULT_GRID_COLUMNS_NUMBER = 8;
const DEFAULT_PERCENTAGE_UPPER_LIMIT = 0.4;
const GRID_COLUMNS_RATIO = 20;

const getPerformanceSummary = (upside, downside, limit) => ({
  up: upside / limit,
  down: downside / limit
});

const useUpsideDownsideData = ({ benchmark, recommended, target }) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const marketUpsidePerformance = company.market_upside_performance / 100;
  const marketDownsidePerformance = company.market_downside_performance / 100;

  const targetUpsidePerformance = target.upside * marketUpsidePerformance * 10;
  const targetDownsidePerformance = target.downside * marketDownsidePerformance * 10;

  const recommendedUpsidePerformance = (recommended?.upside ?? 0) * marketUpsidePerformance * 10;
  const recommendedDownsidePerformance =
    (recommended?.downside ?? 0) * marketDownsidePerformance * 10;

  const benchmarkUpsidePerformance = (benchmark?.upside ?? 0) * marketUpsidePerformance * 10;
  const benchmarkDownsidePerformance = (benchmark?.downside ?? 0) * marketDownsidePerformance * 10;

  // maximum performance value used to define the limit required by the chart
  const maxPerformanceValue = Math.max(
    targetUpsidePerformance,
    targetDownsidePerformance,
    recommendedUpsidePerformance,
    recommendedDownsidePerformance,
    benchmarkUpsidePerformance,
    benchmarkDownsidePerformance,
    marketUpsidePerformance,
    marketDownsidePerformance
  );

  // number of columns needed to render the chart where
  // each number represents a 5% portion of it
  const gridColumnsNumber =
    maxPerformanceValue > DEFAULT_PERCENTAGE_UPPER_LIMIT
      ? Math.ceil(maxPerformanceValue * GRID_COLUMNS_RATIO)
      : DEFAULT_GRID_COLUMNS_NUMBER;

  // maximum percentage width that chart bars are allowed to grow
  const maxPerformanceVisualLimit = gridColumnsNumber / GRID_COLUMNS_RATIO;

  const spyPerformance = getPerformanceSummary(
    marketUpsidePerformance,
    marketDownsidePerformance,
    maxPerformanceVisualLimit
  );

  const targetPerformance = getPerformanceSummary(
    targetUpsidePerformance,
    targetDownsidePerformance,
    maxPerformanceVisualLimit
  );

  const recommendedPerformance = recommended
    ? getPerformanceSummary(
        recommendedUpsidePerformance,
        recommendedDownsidePerformance,
        maxPerformanceVisualLimit
      )
    : null;

  const benchmarkPerformance = benchmark
    ? getPerformanceSummary(
        benchmarkUpsidePerformance,
        benchmarkDownsidePerformance,
        maxPerformanceVisualLimit
      )
    : null;

  return {
    gridColumnsNumber,
    spyPerformance,
    targetPerformance,
    recommendedPerformance,
    benchmarkPerformance
  };
};

useUpsideDownsideData.propTypes = {
  benchmark: PropTypes.object,
  recommended: PropTypes.object,
  target: PropTypes.object.isRequired
};

useUpsideDownsideData.defaultProps = {
  benchmark: null,
  recommended: null
};

export default useUpsideDownsideData;
