import cn from 'classnames';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback } from 'react';
import useSlider from '../hooks';
import '../styles.scss';

const BaseSlider = ({
  className,
  defaultValue,
  getTooltipContent,
  labels,
  onAfterChange,
  onChange,
  size,
  value,
  withHandle,
  withTooltip
}) => {
  const { min, max, markToValue, sliderProps, valueToMark } = useSlider({
    getTooltipContent,
    labels,
    size,
    withHandle,
    withTooltip
  });

  /* Helpers */
  const valueToRange = value => {
    const mark = valueToMark(value);
    const bottom = Math.max(min, Math.min(mark, max - 1));
    const top = Math.max(min + 1, Math.min(mark + 1, max));
    return [bottom, top];
  };

  /* Handlers */
  const onSliderChange = useCallback(
    mark => {
      // adjust the mark selection according the track size
      const selection = Math.max(mark, size);
      const newValue = markToValue(selection);
      onChange(newValue);
    },
    [onChange]
  );

  if (size > 0) {
    const range = valueToRange(value);
    [sliderProps.startPoint, sliderProps.value] = range;
    sliderProps.included = true;
  } else {
    sliderProps.value = valueToMark(value);
    sliderProps.included = false;
  }

  return (
    <div className={cn(`range-slider-wrapper ${className}`, { '-withHandle': withHandle })}>
      <Slider
        {...sliderProps}
        defaultValue={valueToMark(defaultValue)}
        onChange={onSliderChange}
        onAfterChange={onAfterChange}
      />
    </div>
  );
};

BaseSlider.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  getTooltipContent: PropTypes.func,
  labels: PropTypes.array.isRequired,
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number,
  value: PropTypes.number,
  withHandle: PropTypes.bool,
  withTooltip: PropTypes.bool
};

BaseSlider.defaultProps = {
  className: '',
  defaultValue: 1,
  getTooltipContent: v => v,
  onAfterChange: () => {},
  size: 0,
  value: 1,
  withHandle: false,
  withTooltip: false
};

export default BaseSlider;
