// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.target-score-tooltip {
  padding: 5px 8px;
}

.manual-score-box {
  display: inline-block;
}

.card-overview .score-with-exclamation {
  display: inline-block;
  position: relative;
  max-width: none;
}
.card-overview .score-with-exclamation .score-bubble {
  display: inline-block;
}
.card-overview .score-with-exclamation .fs-icon-exclamation-circle {
  right: 6px;
  top: 2px;
}
.card-overview .score-with-exclamation .fs-icon-exclamation-circle:before {
  background-color: white;
  border-radius: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/utils/score-bubble/aggregated-tolerance/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAGE;EACE,qBAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAEI;EACE,qBAAA;AAAN;AAGI;EACE,UAAA;EACA,QAAA;AADN;AAGM;EACE,uBAAA;EACA,mBAAA;AADR","sourcesContent":[".target-score-tooltip {\n  padding: 5px 8px;\n}\n\n.manual-score-box {\n  display: inline-block;\n}\n\n.card-overview {\n  .score-with-exclamation {\n    display: inline-block;\n    position: relative;\n    max-width: none;\n\n    .score-bubble {\n      display: inline-block;\n    }\n\n    .fs-icon-exclamation-circle {\n      right: 6px;\n      top: 2px;\n\n      &:before {\n        background-color: white;\n        border-radius: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
