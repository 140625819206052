// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-thumbnail {
  border: 1px solid black;
}

.report-thumbnail__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/thumbnail/styles.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.report-thumbnail {\n  border: 1px solid black;\n}\n\n.report-thumbnail__placeholder {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
