import {
  CHANGE_REPORT_ORIENTATION,
  IPS_CLEAR,
  IPS_CREATE,
  IPS_GET,
  IPS_LIST,
  IPS_REPORTS_CREATE,
  IPS_REPORTS_GET,
  IPS_REPORTS_LIST,
  IPS_REPORTS_UPDATE,
  IPS_REPORT_CLEAR,
  IPS_REPORT_UNSAVED,
  IPS_SAVE_CHART_IMAGE,
  IPS_SAVE_REPORT_PDF_URL,
  IPS_SET_COLLAPSED_HOLDINGS,
  IPS_SET_COLLAPSED_REGIONS,
  IPS_SET_COLLAPSED_SECTORS,
  IPS_SET_COLLAPSED_STYLES,
  IPS_SET_COLLAPSED_TOP_HOLDINGS,
  IPS_UPDATE,
  IPS_VIEW
} from 'constants/actions';
import { PORTRAIT_ORIENTATION } from 'reports/constants';

const initialState = {
  charts: {},
  collapsedHoldings: [],
  collapsedStyles: [],
  collapsedSectors: [],
  collapsedTopHoldings: [],
  collapsedRegions: [],
  currentReport: null,
  currentReportSettings: { orientation: PORTRAIT_ORIENTATION, signaturePage: 0 },
  reports: {},
  url: null,
  view: {}
};

// eslint-disable-next-line default-param-last
export default function proxies(state = initialState, action) {
  switch (action.type) {
    case IPS_VIEW:
    case IPS_UPDATE:
    case IPS_GET:
    case IPS_CREATE:
      return {
        ...state,
        view: {
          ...action.data,
          proposal: {
            ...action.data.proposal,
            benchmark_label: action.data.proposal.benchmark_label || 'Benchmark',
            recommended_label: action.data.proposal.recommended_label || 'Model'
          }
        }
      };

    case IPS_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case IPS_CLEAR:
      return {
        ...state,
        charts: {},
        collapsedHoldings: [],
        collapsedStyles: [],
        collapsedSectors: [],
        collapsedTopHoldings: [],
        collapsedRegions: [],
        url: null,
        view: {}
      };

    case IPS_SAVE_CHART_IMAGE:
      return { ...state, charts: { ...state.charts, ...action.data } };

    case IPS_SET_COLLAPSED_HOLDINGS:
      return { ...state, collapsedHoldings: action.data };

    case IPS_SET_COLLAPSED_STYLES:
      return { ...state, collapsedStyles: action.data };

    case IPS_SET_COLLAPSED_SECTORS:
      return { ...state, collapsedSectors: action.data };

    case IPS_SET_COLLAPSED_TOP_HOLDINGS:
      return { ...state, collapsedTopHoldings: action.data };

    case IPS_SET_COLLAPSED_REGIONS:
      return { ...state, collapsedRegions: action.data };

    case IPS_REPORTS_LIST:
      return { ...state, reports: { ...state.reports, [action.id]: action.data } };

    case IPS_REPORTS_GET:
    case IPS_REPORTS_CREATE:
    case IPS_REPORTS_UPDATE:
      return { ...state, currentReport: action.data };

    case IPS_REPORT_UNSAVED:
      return { ...state, currentReport: { ...state.currentReport, unsaved: action.unsaved } };

    case IPS_SAVE_REPORT_PDF_URL:
      return { ...state, url: action.data };

    case IPS_REPORT_CLEAR:
      return { ...state, currentReport: null };

    case CHANGE_REPORT_ORIENTATION:
      return {
        ...state,
        currentReportSettings: { ...state.currentReportSettings, orientation: action.data }
      };

    default:
      return state;
  }
}
