import { CARD_LIST } from 'constants/actions';

const initialState = {
  list: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CARD_LIST:
      return {
        ...state,
        list: action.data.filter(pm => pm.type === 'card')
      };
    default:
      return state;
  }
}
