import { StyleSheet } from '@react-pdf/renderer';
import {
  PRISM_COLOR_0,
  PRISM_COLOR_1,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_4,
  PRISM_COLOR_5,
  PRISM_COLOR_6,
  PRISM_COLOR_7,
  PRISM_COLOR_8,
  PRISM_COLOR_9,
  PRISM_OFF_COLOR,
  PRISM_TOLERANCE_COLOR
} from '../../styles';

const styles = StyleSheet.create({
  fColor0: { fill: PRISM_COLOR_0 },
  fColor1: { fill: PRISM_COLOR_1 },
  fColor2: { fill: PRISM_COLOR_2 },
  fColor3: { fill: PRISM_COLOR_3 },
  fColor4: { fill: PRISM_COLOR_4 },
  fColor5: { fill: PRISM_COLOR_5 },
  fColor6: { fill: PRISM_COLOR_6 },
  fColor7: { fill: PRISM_COLOR_7 },
  fColor8: { fill: PRISM_COLOR_8 },
  fColor9: { fill: PRISM_COLOR_9 },
  fColorOff: { fill: PRISM_OFF_COLOR },
  tColor0: { color: PRISM_COLOR_0 },
  tColor1: { color: PRISM_COLOR_0 },
  tColor2: { color: PRISM_COLOR_1 },
  tColor3: { color: PRISM_COLOR_2 },
  tColor4: { color: PRISM_COLOR_3 },
  tColor5: { color: PRISM_COLOR_4 },
  tColor6: { color: PRISM_COLOR_5 },
  tColor7: { color: PRISM_COLOR_6 },
  tColor8: { color: PRISM_COLOR_7 },
  tColor9: { color: PRISM_COLOR_8 },
  tColor10: { color: PRISM_COLOR_9 },
  risk: { fill: 'black' },
  tolerance: { fill: PRISM_TOLERANCE_COLOR },
  text: { color: 'black', fontSize: 120 },
  riskScoreText: { textAlign: 'center', fontSize: 14, fontWeight: 'bold' }
});

export default styles;
