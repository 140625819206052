import PropTypes from 'prop-types';
import React, { Component } from 'react';

export const Directions = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

export default class Tooltip extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(Directions)).isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    containerStyles: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    style: null,
    disabled: false,
    containerStyles: {},
    className: ''
  };

  render() {
    const { disabled, children, text, style, direction, containerStyles, className } = this.props;
    const { styles } = this.constructor;
    if (disabled) return children;
    return (
      <div className="tooltipR" style={containerStyles}>
        {children}
        <span
          className={`tooltiptext tooltip-${direction} ${className}`}
          style={style || styles.defaultTooltip}
        >
          {text}
        </span>
      </div>
    );
  }

  static styles = {
    defaultTooltip: {
      width: 360,
      marginLeft: -177,
      opacity: 0.8,
      color: '#dae3e6',
      fontSize: 13,
      fontWeight: 'normal',
      textAlign: 'left',
      paddingLeft: 11,
      paddingRight: 5
    }
  };
}
