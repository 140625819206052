import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import Link from 'components/utils/link';
import './styles.scss';

const TABS = {
  'two-factor': '2FA',
  'change-password': 'Password'
};

const AdvisorSecurity = ({ children }) => {
  const pathSelected = children?.props?.route?.path || '';
  return (
    <div className="advisor-me-password">
      <div className="tabs-container">
        <ul className="tabs">
          {Object.entries(TABS).map(([key, val]) => (
            <Link key={key} className="btn-transparent" to={`/advisor/me/security/${key}`}>
              <li className={cs({ active: pathSelected === key })}>{val}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="security-container">{children}</div>
    </div>
  );
};

AdvisorSecurity.defaultProps = {
  children: null
};

AdvisorSecurity.propTypes = {
  children: PropTypes.element
};

export default AdvisorSecurity;
