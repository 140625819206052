import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './styles.scss';

const STATUS_MESSAGE = {
  'session-error': {
    status: 'session-error',
    message:
      'There was a connection error with our data providers. Please try again or contact our support team.'
  },
  success: {
    status: 'success',
    message: 'Thank you! Your integration should be live in the next 24-48 hours.'
  },
  exit: {
    status: 'exit',
    message: 'The process ended but was not satisfactory.'
  }
};

class SchwabOauthIntegration extends Component {
  constructor() {
    super();
    this.initialState = { code: null, loading: false, state: null };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.startSession();
  }

  componentWillUnmount() {
    if (this.externalWindowCheckInterval) clearInterval(this.externalWindowCheckInterval);
  }

  externalWindow;

  externalWindowCheckInterval;

  startSession = () => {
    this.setState({ loading: true });

    const url = new URL(`${process.env.CHARLES_SCHWAB_URL}/v1/oauth/authorize`);
    const state = Math.random().toString(36).substring(3);

    url.searchParams.set('client_id', process.env.CHARLES_SCHWAB_CLIENT_ID);
    url.searchParams.set('redirect_uri', process.env.CHARLES_SCHWAB_REDIRECT_URL);
    url.searchParams.set('state', state);

    const width = 600;
    const height = 800;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    this.externalWindow = window.open(
      url.href,
      '',
      `width=${width},height=${height},left=${left},top=${top}`
    );
    window.addEventListener('message', this.onSessionMessage, false);
    this.externalWindowCheckInterval = setInterval(this.checkExternalWindow, 500);
  };

  checkExternalWindow = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.externalWindow && this.externalWindow.closed && !this.state.code) this.finishSession();
  };

  onSessionMessage = event => {
    const redirectOrigin = new URL(process.env.CHARLES_SCHWAB_REDIRECT_URL).origin;
    if (event.origin !== redirectOrigin) return;
    if (event.data?.code && event.data?.state) {
      this.setState({ code: event.data.code, state: event.data.state });
      this.closeExternalWindow();
      this.processSession();
    }
  };

  processSession = () => {
    const { integrationProvider } = this.context;
    const { selectedSyncData } = this.props;
    const { code, state } = this.state;

    integrationProvider
      .setSchwabOauthIntegrationInfo({ code, state, ...selectedSyncData })
      .then(({ error }) => {
        if (error) throw new Error();
        else this.finishSession(STATUS_MESSAGE.success);
      })
      .catch(() => {
        this.finishSession(STATUS_MESSAGE['session-error']);
      });
  };

  finishSession = (status = STATUS_MESSAGE.exit) => {
    this.closeExternalWindow();
    this.setState(this.initialState);
    const { onComplete } = this.props;
    onComplete(status);
  };

  closeExternalWindow = () => {
    window.removeEventListener('message', this.onSessionMessage, false);
    if (this.externalWindowCheckInterval) clearInterval(this.externalWindowCheckInterval);
    if (this.externalWindow && !this.externalWindow.closed) this.externalWindow.close();
  };

  render() {
    const { code, loading } = this.state;
    const {
      provider: { name, image }
    } = this.props;

    return (
      <div id="salesforce-integration">
        <img src={image} alt={name} width={300} />
        {loading && !code && (
          <span>Please, sign in to Charles Schwab to continue the integration process.</span>
        )}
        {loading && code && (
          <span>
            Connecting to {name}
            <br />
            Please wait ...
          </span>
        )}
      </div>
    );
  }
}

SchwabOauthIntegration.contextTypes = {
  integrationProvider: PropTypes.object.isRequired
};

SchwabOauthIntegration.propTypes = {
  onStart: PropTypes.func,
  onComplete: PropTypes.func,
  provider: PropTypes.object.isRequired,
  selectedSyncData: PropTypes.object
};

SchwabOauthIntegration.defaultProps = {
  onStart: () => {},
  onComplete: () => {},
  selectedSyncData: {}
};

export default SchwabOauthIntegration;
