/* eslint-disable react/no-array-index-key */
import PlusIcon from 'assets/img/icons/plus-circle.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SimpleSignatureRow from './row';

const MIN_SIGNATURES_NUMBER = 1;

const SimpleSignature = ({
  propertyKey,
  saveChanges,
  signaturesNumber: initialSignaturesNumber
}) => {
  const [signaturesNumber, setSignaturesNumber] = useState(initialSignaturesNumber);

  const signaturesNumberHandler = signaturesNumber => {
    saveChanges(propertyKey, { signaturesNumber });
    return signaturesNumber;
  };

  const addSigner = () => {
    setSignaturesNumber(prevSignaturesNumber => signaturesNumberHandler(prevSignaturesNumber + 1));
  };

  const removeSigner = () => {
    setSignaturesNumber(prevSignaturesNumber => signaturesNumberHandler(prevSignaturesNumber - 1));
  };

  return (
    <>
      <h4>Prepared by</h4>
      <SimpleSignatureRow />

      <h4 className="mt-5">Approved by</h4>
      {Array.from(Array(signaturesNumber)).map((_, idx) => (
        <SimpleSignatureRow
          key={`signature-section-${idx}`}
          target="Client Name"
          onDelete={signaturesNumber > MIN_SIGNATURES_NUMBER ? removeSigner : null}
        />
      ))}

      <div className="signature__add">
        <button type="button" className="btn btn-link" onClick={addSigner}>
          <img src={PlusIcon} width={20} height={20} alt="Add" /> Add Signer
        </button>
      </div>
    </>
  );
};

SimpleSignature.defaultProps = {
  signaturesNumber: 1
};

SimpleSignature.propTypes = {
  propertyKey: PropTypes.string.isRequired,
  saveChanges: PropTypes.func.isRequired,
  signaturesNumber: PropTypes.number
};

export default SimpleSignature;
