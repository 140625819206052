import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const TooltipV2 = ({ children, label, renderTooltip, type, place, effect, ...rest }) => (
  <>
    {children}
    {renderTooltip ? (
      <ReactTooltip type={type} place={place} effect={effect} {...rest}>
        {label}
      </ReactTooltip>
    ) : null}
  </>
);

TooltipV2.defaultProps = {
  type: 'dark',
  place: 'right',
  effect: 'solid',
  renderTooltip: true
};

TooltipV2.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  type: PropTypes.string,
  place: PropTypes.string,
  effect: PropTypes.string,
  renderTooltip: PropTypes.bool
};

export default TooltipV2;
