import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const SliderBadge = ({ mark, level, sliderValue }) => {
  const { range, position, label } = mark;
  const badgeActiveClass = sliderValue >= range[0] && sliderValue <= range[1] ? '-active' : '';

  return (
    <div
      className={`risk-badge -range-level-${Math.ceil(level)} ${badgeActiveClass}`}
      key={label}
      data-position={position}
    >
      <div className="risk-badge__investor-type">
        <span>
          {range[0]} to {range[1]}
        </span>
      </div>
      <div className="risk-badge__range">
        <span>{label}</span>
      </div>
    </div>
  );
};

export const FixedBadge = ({ label, score }) => (
  <div className="fixed-score-badge">
    <div
      className={cn(
        'rc-slider-handle',
        'risk-slider__handle-dot',
        'handle-dot--fixed-score',
        `-dot-level-border-${Math.ceil(score)}`
      )}
      key={`fixed-score-${score}`}
    >
      <span className={`-dot-level-${Math.ceil(score)}`}>{score}</span>
    </div>
    {label && (
      <div className={cn('risk-badge', 'risk-badge--fixed-score')} data-position="bottom">
        <div className="risk-badge__investor-type">
          <span>{label}</span>
        </div>
      </div>
    )}
  </div>
);

SliderBadge.propTypes = {
  mark: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  sliderValue: PropTypes.number.isRequired
};

FixedBadge.propTypes = {
  label: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired
};
