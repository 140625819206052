import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const NumericReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  return (
    <ReadOnlyQuestion question={question}>
      <p>{question.answer.value}</p>
    </ReadOnlyQuestion>
  );
};

NumericReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default NumericReadOnlyQuestion;
