import ModelPortfolioBenchmark from 'components/advisor/model-portfolio-benchmark/pdf';
import PrismRiskAnalysis from 'components/advisor/risk-analysis/prism-risk-analysis/pdf';
import PortfolioOverallAllocation from 'components/advisor/risk-analysis/report/sections/portfolio-overall-allocation';
import RiskFactorsDescription from 'components/advisor/risk-analysis/report/sections/risk-factors-description';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/pdf';
import TopRiskAttribution from 'components/advisor/risk-analysis/risk-analysis-target/top-risk-attribution/pdf';
import ScenariosScores from 'components/advisor/risk-analysis/scenarios-scores/pdf';
import {
  DEFAULT_PORTFOLIO_TOTAL_ASSETS,
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL,
  showSecurityDetailsSection
} from 'components/advisor/risk-analysis/securities/common/utils';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure/pdf';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style/pdf';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure/pdf';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings/pdf';
import FocusedPrismFactorsChart from 'components/charts/focused-prism-factors-chart/pdf';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside/pdf';
import PrismRating from 'components/utils/prism-rating/pdf';
import { AdvisorContext } from 'containers/advisor';
import { SECURITY_TARGET_TYPE } from 'containers/security/constants';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import { BaseCoverDataPropTypes } from 'reports/base/cover/types';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import BaseSection from 'reports/base/sections/base';
import CustomSection from 'reports/base/sections/custom';
import { LANDSCAPE_ORIENTATION, PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import { ACCOUNT_TARGET_TYPE, MODEL_TARGET_TYPE } from '../../constants';
import {
  getScenarios,
  getScoreFactors
} from '../../risk-analysis-target/risk-analysis-content/utils';

const AccountOrModelPrismReport = ({
  benchmarks,
  coverData,
  orientation,
  portfolio,
  riskAnalysis,
  size,
  subtitle,
  taxonomies,
  title,
  type,
  user,
  withCustomBranding
}) => {
  const context = useMemo(
    () => ({
      benchmarks,
      orientation,
      portfolio,
      riskAnalysis,
      taxonomies,
      user,
      withCustomBranding
    }),
    [
      JSON.stringify(benchmarks),
      JSON.stringify(portfolio),
      JSON.stringify(riskAnalysis),
      JSON.stringify(taxonomies),
      JSON.stringify(user),
      orientation,
      withCustomBranding
    ]
  );

  const { scenarios, scenariosPerformance } = getScenarios(riskAnalysis);
  const {
    concentrated,
    volatility,
    correlation,
    tail,
    overall: prismOverall
  } = getScoreFactors(riskAnalysis);

  const advisor = portfolio.investor
    ? { ...portfolio.investor.advisor, company: user.advisor.company }
    : user.advisor;

  // adds by default a value of 100 in case of a model portfolio
  if (type === MODEL_TARGET_TYPE && (!portfolio.value || !Number.isFinite(portfolio.value)))
    portfolio.value = DEFAULT_PORTFOLIO_TOTAL_ASSETS;

  const isAccountOrModel = type === MODEL_TARGET_TYPE || type === ACCOUNT_TARGET_TYPE;

  return (
    <BaseDocument title={title && subtitle ? `${title} for ${subtitle}` : title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={advisor}
          data={coverData}
          orientation={orientation}
          size={size}
          subtitle={subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={advisor}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
        >
          {isAccountOrModel && <PortfolioOverallAllocation portfolio={portfolio} type={type} />}

          {isAccountOrModel && showSecurityDetailsSection(portfolio, SECURITY_STYLES) && (
            <InvestmentStyle
              breakSection={orientation === LANDSCAPE_ORIENTATION}
              hiddenVal={type === MODEL_TARGET_TYPE}
              portfolio={portfolio}
            />
          )}

          {isAccountOrModel && showSecurityDetailsSection(portfolio, SECURITY_SECTORS) && (
            <SectorExposure
              breakSection={orientation === LANDSCAPE_ORIENTATION}
              hiddenVal={type === MODEL_TARGET_TYPE}
              portfolio={portfolio}
              type={type}
            />
          )}

          {isAccountOrModel && showSecurityDetailsSection(portfolio, SECURITY_UNDERLYING_MODEL) && (
            <TopHoldings
              breakSection={orientation === LANDSCAPE_ORIENTATION}
              hiddenVal={type === MODEL_TARGET_TYPE}
              portfolio={portfolio}
            />
          )}

          {isAccountOrModel && showSecurityDetailsSection(portfolio, SECURITY_REGIONS) && (
            <GeographicExposure
              breakSection={orientation === LANDSCAPE_ORIENTATION}
              hiddenVal={type === MODEL_TARGET_TYPE}
              portfolio={portfolio}
              type={type}
            />
          )}

          {type === ACCOUNT_TARGET_TYPE && (
            <PrismRating
              breakSection={orientation === PORTRAIT_ORIENTATION}
              prismSummary={{
                concentrated: concentrated.value,
                volatility: volatility.value,
                correlation: correlation.value,
                tail: tail.value,
                overall: prismOverall
              }}
              targetSummary={portfolio?.target_score_summary}
            />
          )}

          {(type === MODEL_TARGET_TYPE || type === SECURITY_TARGET_TYPE) && (
            <PrismRatingUpsideDownside
              market={{
                up: user.advisor.company.market_upside_performance,
                down: user.advisor.company.market_downside_performance
              }}
              prismSummary={portfolio.prism_score_summary}
              targetSummary={portfolio?.target_score_summary}
              value={portfolio.value}
            />
          )}

          {riskAnalysis.custom && benchmarks.length && (
            <ModelPortfolioBenchmark
              benchmarks={benchmarks}
              prismOverall={prismOverall}
              targetOverall={portfolio?.target_score_summary?.overall}
            />
          )}

          <PrismRiskAnalysis
            portfolio={portfolio}
            prismSummary={{ concentrated, correlation, tail, volatility }}
            targetSummary={portfolio?.target_score_summary}
            type={type}
          />
          {(type === MODEL_TARGET_TYPE || type === SECURITY_TARGET_TYPE) &&
            portfolio.prism_score_summary && (
              <BaseSection title="PRISM Risk Factor Comparison" wrap={false}>
                <FocusedPrismFactorsChart targetSummary={portfolio.prism_score_summary} />
              </BaseSection>
            )}

          {(type === MODEL_TARGET_TYPE || type === ACCOUNT_TARGET_TYPE) && (
            <SecurityTypeConcentration portfolio={portfolio} type={type} />
          )}

          {(type === MODEL_TARGET_TYPE || type === ACCOUNT_TARGET_TYPE) && (
            <TopRiskAttribution
              positions={portfolio.positions}
              riskyPositions={portfolio.most_risky_positions}
              type={type}
            />
          )}

          <ScenariosScores
            performance={scenariosPerformance}
            scenarios={scenarios}
            value={portfolio.value}
          />

          <RiskFactorsDescription />

          {!!user?.advisor?.company?.report_disclosure && (
            <CustomSection
              breakSection
              title="Disclosure"
              body={user.advisor.company.report_disclosure}
            />
          )}
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

AccountOrModelPrismReport.propTypes = {
  benchmarks: PropTypes.array.isRequired,
  coverData: PropTypes.shape(BaseCoverDataPropTypes),
  orientation: PropTypes.string,
  portfolio: PropTypes.object.isRequired,
  riskAnalysis: PropTypes.object.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  taxonomies: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

AccountOrModelPrismReport.defaultProps = {
  coverData: {},
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  taxonomies: [],
  title: 'PRISM Report',
  type: ACCOUNT_TARGET_TYPE,
  withCustomBranding: false
};

export default AccountOrModelPrismReport;
