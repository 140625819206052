import HouseholdsTable from 'components/advisor/households/list';
import HouseholdsHeader from 'components/advisor/households/list-header/header';
import Disclosure from 'components/disclosure';
import withSubscription from 'hocs/subscription-validation';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';

class Households extends Component {
  getList = params => {
    const { householdProvider } = this.context;
    const { location } = this.props;
    return householdProvider.list(params, location.query);
  };

  render() {
    const {
      households,
      householdsMeta,
      householdSuggestedMembers,
      routerActions,
      selectedHouseholdIds,
      selectedHouseholds
    } = this.props;

    return (
      <div className="Households">
        <div className="households-container households-list-container">
          <HouseholdsHeader
            households={households}
            householdSuggestedMembers={householdSuggestedMembers}
            meta={householdsMeta}
          />

          <HouseholdsTable
            getList={this.getList}
            households={households}
            meta={householdsMeta}
            routerActions={routerActions}
            selectedHouseholdIds={selectedHouseholdIds}
            selectedHouseholds={selectedHouseholds}
          />

          <Disclosure />
        </div>
      </div>
    );
  }
}

Households.contextTypes = {
  advisorByManager: PropTypes.number.isRequired,
  householdProvider: PropTypes.object.isRequired
};

Households.propTypes = {
  households: PropTypes.array.isRequired,
  householdsMeta: PropTypes.object.isRequired,
  householdSuggestedMembers: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  selectedHouseholdIds: PropTypes.array.isRequired,
  selectedHouseholds: PropTypes.array.isRequired
};

export default compose(
  withSubscription({ plan: 'professional', id: 'households', name: 'Households' }),
  connect(
    state => ({
      households: state.households.list,
      householdsMeta: state.households.listMeta,
      householdSuggestedMembers: state.households.suggestions,
      selectedHouseholdIds: state.households.selectedHouseholdIds,
      selectedHouseholds: state.households.selectedHouseholds
    }),
    dispatch => ({
      routerActions: bindActionCreators(routerActions, dispatch)
    })
  )
)(Households);
