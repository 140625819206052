export default (targetName, recommendedName, benchmarkName) => ({
  graphs: [
    {
      balloonText: `${targetName}: [[value]]%`,
      columnWidth: 0.3,
      fillAlphas: 0.8,
      fillColors: ['#394346'],
      lineColor: ['#394346'],
      id: 'AmGraph-1',
      lineAlpha: 0.2,
      title: targetName,
      type: 'column',
      valueField: 'target'
    },
    {
      balloonText: `${recommendedName}: [[value]]%`,
      columnWidth: 0.3,
      fillAlphas: 0.8,
      fillColors: ['#30E3FE'],
      lineColor: ['#30E3FE'],
      id: 'AmGraph-2',
      lineAlpha: 0.2,
      title: recommendedName,
      type: 'column',
      valueField: 'recommended'
    },
    {
      balloonText: `${benchmarkName}: [[value]]%`,
      columnWidth: 0.3,
      fillAlphas: 0.8,
      fillColors: ['#AF7CD3'],
      lineColor: ['#AF7CD3'],
      id: 'AmGraph-3',
      lineAlpha: 0.2,
      title: benchmarkName,
      type: 'column',
      valueField: 'benchmark'
    }
  ],
  valueAxes: [
    {
      id: 'ValueAxis-1',
      labelFunction: (e, val) => `${val}%`,
      position: 'bottom',
      axisAlpha: 0
    }
  ]
});
