import { INVITATION_LIST } from 'constants/actions/invitations';

export const initialState = {
  list: []
};
export default function invitations(state = initialState, action) {
  switch (action.type) {
    case INVITATION_LIST:
      return { ...state, list: action.data };

    default:
      return state;
  }
}
