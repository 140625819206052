/* eslint-disable no-underscore-dangle */
import { Text, View } from '@react-pdf/renderer';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { formatPercentage, getTaxonomyLevelIterator, withCurrencyFormat } from 'utils/utils';
import Row from '../../common/pdf/row';
import styles from '../../common/pdf/styles';
import {
  SECURITY_UNDERLYING_MODEL,
  getSecurityDetails,
  processSecurityDetails
} from '../../common/utils';
import { TOP_HOLDINGS_TO_SHOW } from '../constants';

const TopHoldings = ({
  body,
  breakSection,
  headingLabels,
  hiddenVal,
  portfolio,
  title,
  totalRowLabel
}) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          top_holdings_enabled: topHoldingsEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  if (!topHoldingsEnabled) return null;

  const totalAssets = portfolio.value ?? 0;
  const securityTopHoldings = getSecurityDetails(portfolio, SECURITY_UNDERLYING_MODEL, totalAssets);

  const filteredSecurityTopHoldings = securityTopHoldings
    .sort((a, b) => b.value - a.value)
    .slice(0, TOP_HOLDINGS_TO_SHOW);

  const { data, total: totalSecurities } = processSecurityDetails(
    filteredSecurityTopHoldings,
    SECURITY_UNDERLYING_MODEL,
    totalAssets
  );

  const rowProps = { hiddenVal, total: totalSecurities };

  return (
    <BaseSection title={title} body={body} breakSection={breakSection}>
      <View style={[styles.table, { marginTop: 0, maxHeight: 30 }]} wrap={false}>
        <View style={[styles.row, styles.rowTotal]}>
          <View style={styles.cell}>
            <Text>{headingLabels.name}</Text>
          </View>
          <View style={styles.cell}>
            {hiddenVal || <Text style={styles.cellText}>{headingLabels.value}</Text>}
          </View>
          <View style={[styles.cell, { marginRight: 0 }]}>
            <Text style={styles.cellText}>{headingLabels.percent}</Text>
          </View>
        </View>
      </View>

      <View style={[styles.table, { marginTop: 0 }]}>
        {getTaxonomyLevelIterator(data.summary).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row depth={1} label={l1Key} level={l1} color={l1.__color} {...rowProps} />

            {expandAssetDetails &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row depth={2} label={l2Key} level={l2} color={l1.__color} {...rowProps} />
                </Fragment>
              ))}
          </Fragment>
        ))}
      </View>

      <View style={styles.table} wrap={false}>
        <View style={[styles.row, styles.rowTotal]}>
          <View style={styles.cell}>
            <Text>{totalRowLabel}</Text>
          </View>
          <View style={styles.cell}>
            {hiddenVal || (
              <Text style={styles.cellText}>
                {withCurrencyFormat(totalSecurities, 'standard', 0)}
              </Text>
            )}
          </View>
          <View style={[styles.cell, { marginRight: 0 }]}>
            <Text style={styles.cellText}>{formatPercentage(1, 100, 2)}</Text>
          </View>
        </View>
      </View>
    </BaseSection>
  );
};

TopHoldings.propTypes = {
  body: PropTypes.string,
  breakSection: PropTypes.bool,
  headingLabels: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired
  }),
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.array.isRequired,
  title: PropTypes.string,
  totalRowLabel: PropTypes.string
};

TopHoldings.defaultProps = {
  body: '',
  breakSection: false,
  headingLabels: { name: '', value: 'Value', percent: '% of Portfolio' },
  hiddenVal: false,
  title: 'Top 10 Holdings',
  totalRowLabel: 'Total portfolio value'
};

export default TopHoldings;
