import ScoreBubble from 'components/advisor/utils/score-bubble';
import WarningIcon from 'components/svg-icons/warning-icon';
import TooltipV2 from 'components/tooltip-v2';
import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { AllowNewItemsAutosuggest, FormGroup, Input } from '../../../form';
import TrashCanIcon from '../../../svg-icons/trash-can-icon';
import { EXCLUDED_MESSAGE } from '../constants';
import SelectClassification from './select-classification';
import './styles.scss';

export const mapPositions = positions =>
  positions
    .filter(p => !p.deleted)
    .map(p => ({
      ticker: p.ticker,
      ticker_name: p.ticker_name,
      type: p.type,
      subtype: p.subtype,
      sector: p.sector,
      value: p.value,
      excluded: p.excluded
    }));

class PositionsFormset extends Component {
  static TOTAL_PRECISION = 2;

  constructor(props) {
    super(props);
    this.securitySearch = _.debounce(this.securitySearchFetch, 500).bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { marketProvider } = this.props;
    marketProvider.getClassifications();
  }

  securitySearchFetch(value) {
    const { marketProvider } = this.props;
    marketProvider.es.securitySearch(value);
  }

  isOneValueFieldTouched() {
    const { positions } = this.props;
    return !!positions.filter(p => p.value.touched).length;
  }

  onSuggestionSelected(suggestion, position) {
    const { marketSecurities } = this.props;
    const newSuggestion = marketSecurities.filter(item => item.ticker === suggestion.ticker).pop();
    position.disabled = !!newSuggestion.ticker_name;

    let tickerName = '';
    let type;
    let subtype;
    let sector;
    let id;
    let prismOverall;

    if (newSuggestion)
      ({
        id,
        ticker_name: tickerName,
        type,
        subtype,
        sector,
        prism_overall: prismOverall
      } = newSuggestion);

    position.security.onChange(id);
    position.ticker_name.onChange(tickerName);
    position.type.onChange(type);
    position.subtype.onChange(subtype);
    position.sector.onChange(sector);
    position.prism_score_summary__overall.onChange(prismOverall);
  }

  get total() {
    const { positions, absoluteValues } = this.props;
    let total = positions.reduce((sum, position) => {
      try {
        let positionValue = 0;
        if (typeof position.value.value === 'string')
          positionValue = parseFloat(position.value.value);
        else positionValue = position.value.value;
        sum += position.deleted.value ? 0 : parseFloat(positionValue.toFixed(2)) || 0;
      } catch (e) {}
      return sum;
    }, 0);
    if (typeof total === 'string') total = parseFloat(total);
    if (absoluteValues) return numeral(parseFloat(total.toFixed(2))).format('$0,0.00');
    return parseFloat(total.toFixed(2));
  }

  render() {
    const {
      absoluteValues,
      activeExclude,
      allowAdvisorsExcludingPositions,
      classifications,
      isComplianceOrAbove,
      marketSecurities,
      positions,
      totalError,
      withExcludePositionsFeature,
      isViewOnly
    } = this.props;

    const reorder = d =>
      d.length
        ? d.push(
            d.splice(
              d.findIndex(v => v.name === 'Others' || v.name === 'Other'),
              1
            )[0]
          )
        : 0;

    reorder(classifications.type);
    reorder(classifications.subtype);
    reorder(classifications.sector);

    return (
      <div className="PositionsFormset">
        {!isViewOnly && (
          <div className="new-row">
            <button type="button" onClick={() => positions.addField({})} className="link">
              <i className="icon-add" /> Add new position
            </button>
          </div>
        )}
        <table className="table table-bordered table-borderless-top">
          <thead className="thead-graphite">
            <tr>
              <th>PRISM</th>
              <th>Ticker</th>
              <th>Asset / Security Name</th>
              <th>Asset class</th>
              <th>Subtype</th>
              <th>Segment</th>
              <th>{absoluteValues ? 'Amount' : 'Weight %'}</th>
            </tr>
          </thead>
          <tbody>
            {positions
              .filter(position => !position.deleted.value)
              .map((position, index) => ({ ...position, rowId: position.id.value || index }))
              .sort((a, b) => {
                if (a.id.value) return 1;
                if (b.id.value) return -1;
                return b.rowId - a.rowId;
              })
              .map((position, index, array) => {
                const isExcluded = position?.excluded?.value && activeExclude;
                return (
                  <tr key={position.rowId} {...(isExcluded && { className: 'excluded' })}>
                    <td>
                      {isExcluded && withExcludePositionsFeature && (
                        <TooltipV2
                          effect="float"
                          place="right"
                          id="excluded-info"
                          label={
                            <p style={{ textAlign: 'left', paddingTop: '1rem' }}>
                              {EXCLUDED_MESSAGE.TOOLTIP_MESSAGE}
                              <br />
                              {isComplianceOrAbove
                                ? EXCLUDED_MESSAGE.TOOLTIP_HAS_PERMISSIONS
                                : EXCLUDED_MESSAGE.TOOLTIP_NO_PERMISSIONS}
                            </p>
                          }
                        >
                          <div
                            data-tip=""
                            data-for="excluded-info"
                            className="excluded-info-wrapper"
                          >
                            <div className="excluded-info">
                              <span>!</span>
                            </div>
                          </div>
                        </TooltipV2>
                      )}
                      {position.prism_score_summary__overall.value && (
                        <ScoreBubble score={position.prism_score_summary__overall.value} />
                      )}
                      {position.id.value && !position.prism_score_summary__overall.value && (
                        <span className="no-score-warning">
                          <WarningIcon className="warning-icon" title="Score not available" />
                        </span>
                      )}
                    </td>
                    <td>
                      <AllowNewItemsAutosuggest
                        field={position.ticker}
                        suggestions={marketSecurities}
                        getSuggestionValue={suggestion => suggestion.ticker}
                        renderSuggestion={suggestion => (
                          <span>
                            {suggestion.prism_overall ? (
                              <ScoreBubble score={suggestion.prism_overall} />
                            ) : (
                              <span className="no-score-warning">
                                <WarningIcon className="warning-icon" title="Score not available" />
                              </span>
                            )}{' '}
                            {suggestion.ticker}: {suggestion.ticker_name}
                          </span>
                        )}
                        onSuggestionSelected={(_, { suggestion }) => {
                          this.onSuggestionSelected(suggestion, position);
                        }}
                        onSuggestionsFetchRequested={({ value }) => {
                          position.disabled = false;
                          this.securitySearch(value);
                        }}
                        autofocus={index === 0 && !position.pk}
                        inputProps={{
                          disabled: isViewOnly,
                          placeholder: 'Type Ticker',
                          value: position.ticker.value || '', // should always be string
                          onChange: (event, { newValue, method }) => {
                            // update input value
                            position.ticker.onChange(newValue.toUpperCase());

                            // reset target/dependent values
                            if (method === 'type') {
                              position.type.onChange(undefined);
                              position.subtype.onChange(undefined);
                              position.sector.onChange(undefined);
                              position.security.onChange(undefined);
                            }
                          }
                        }}
                        theme={{
                          container: 'dropdown react-autosuggest__container',
                          containerOpen: 'open react-autosuggest__container--open',
                          input: 'form-control react-autosuggest__input',
                          suggestionsContainer:
                            'dropdown-menu dropdown-menu-scale react-autosuggest__suggestions-container',
                          suggestion: 'dropdown-item react-autosuggest__suggestion',
                          suggestionFocused: 'react-autosuggest__suggestion--focused'
                        }}
                      />
                    </td>
                    <td>
                      <FormGroup {...position.ticker_name}>
                        <Input
                          {...position.ticker_name}
                          className="form-control"
                          disabled={isViewOnly || position.disabled}
                        />
                      </FormGroup>
                    </td>

                    <td>
                      <SelectClassification
                        position={position}
                        classifications={classifications}
                        classificationType="type"
                        disabled={isViewOnly}
                      />
                    </td>

                    <td>
                      <SelectClassification
                        position={position}
                        classifications={classifications}
                        classificationType="subtype"
                        disabled={isViewOnly}
                      />
                    </td>

                    <td>
                      <SelectClassification
                        position={position}
                        classifications={classifications}
                        classificationType="sector"
                        disabled={isViewOnly}
                      />
                    </td>

                    <td>
                      <FormGroup {...position.value}>
                        <NumericFormat
                          className="form-control"
                          name="value"
                          thousandSeparator
                          allowNegative
                          defaultValue={position.value.value}
                          onValueChange={value => position.value.onChange(value.value)}
                          isNumericString
                          disabled={isViewOnly}
                        />
                      </FormGroup>
                      {position.value.touched && position.value.error && (
                        <span className="text-danger error">{position.value.error}</span>
                      )}
                    </td>

                    {!isViewOnly && (
                      <td className="actions">
                        {(isComplianceOrAbove || allowAdvisorsExcludingPositions) &&
                          withExcludePositionsFeature && (
                            <TooltipV2
                              effect="float"
                              place="right"
                              id="exclude-action"
                              label={
                                <p style={{ textAlign: 'left', paddingTop: '1rem' }}>
                                  {isExcluded
                                    ? EXCLUDED_MESSAGE.ACTION_INCLUDE_TOOLTIP
                                    : EXCLUDED_MESSAGE.ACTION_EXCLUDE_TOOLTIP}
                                </p>
                              }
                            >
                              <div
                                data-tip=""
                                data-for="exclude-action"
                                className="exclude-action-wrapper"
                              >
                                <button
                                  type="button"
                                  className="exclude-action"
                                  title=""
                                  onClick={() => {
                                    position.excluded.onChange(!position.excluded.value);
                                  }}
                                >
                                  <img
                                    width={18}
                                    src={`/img/icons/account-${
                                      isExcluded ? 'included' : 'excluded'
                                    }.svg`}
                                    alt={`Account ${isExcluded ? 'Included' : 'Excluded'}`}
                                  />
                                </button>
                              </div>
                            </TooltipV2>
                          )}

                        <button
                          type="button"
                          onClick={() => {
                            position.deleted.onChange(true);
                          }}
                          className={array.length === 1 ? 'disabled' : ''}
                        >
                          <TrashCanIcon className="trash" />
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="total">
          <div className="text-right total-text">Total {absoluteValues ? 'Amount' : 'Weight'}</div>
          <h4 className="text-lighter text-right total-number">
            {this.total} {!absoluteValues ? '%' : ''}
          </h4>
          {totalError &&
            this.isOneValueFieldTouched() && [
              <div className="total-error-line" />,
              <div className="text-danger error total-error-message">{totalError}</div>
            ]}
        </div>
      </div>
    );
  }
}

PositionsFormset.propTypes = {
  absoluteValues: PropTypes.bool,
  activeExclude: PropTypes.bool,
  withExcludePositionsFeature: PropTypes.bool,
  allowAdvisorsExcludingPositions: PropTypes.bool,
  classifications: PropTypes.object,
  isComplianceOrAbove: PropTypes.bool,
  marketProvider: PropTypes.object.isRequired,
  marketSecurities: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  totalError: PropTypes.string,
  isViewOnly: PropTypes.bool
};

PositionsFormset.defaultProps = {
  absoluteValues: false,
  activeExclude: false,
  withExcludePositionsFeature: true,
  allowAdvisorsExcludingPositions: false,
  classifications: { type: [], subtype: [], sector: [] },
  isComplianceOrAbove: false,
  totalError: '',
  isViewOnly: false
};

export default connect(state => ({
  classifications: state.market.classifications || { type: [], subtype: [], sector: [] },
  allowAdvisorsExcludingPositions:
    state.auth.user.advisor.company.allow_advisors_excluding_positions
}))(PositionsFormset);
