import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import './styles.scss';

const DisclaimerNote = ({ onlyEquity, totalAssets, totalSecurities }) => {
  if (Math.floor(totalSecurities) === Math.floor(totalAssets)) return null;
  return (
    <div className="disclaimer-note">
      <p>
        The information provided above represents{' '}
        <FormattedNumber value={totalSecurities / totalAssets} format="percent" /> of the portfolio
        {onlyEquity ? ', which includes only equity stocks and equity-based funds.' : '.'}
      </p>
    </div>
  );
};

DisclaimerNote.propTypes = {
  onlyEquity: PropTypes.bool,
  totalAssets: PropTypes.number.isRequired,
  totalSecurities: PropTypes.number.isRequired
};

DisclaimerNote.defaultProps = {
  onlyEquity: false
};

export default DisclaimerNote;
