import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const Item = ({ id, disabled, children, findItem, moveItem, type }) => {
  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag] = useDrag({
    item: { type, id, originalIndex },
    canDrag: !disabled,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) moveItem(droppedId, originalIndex);
    }
  });

  const [, drop] = useDrop({
    accept: type,
    canDrop: () => !disabled,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        moveItem(draggedId, overIndex);
      }
    }
  });

  let cursor = 'move';
  if (disabled) cursor = 'not-allowed';
  else if (isDragging) cursor = 'grabbing';

  return (
    <div ref={node => drag(drop(node))} style={{ cursor, opacity: isDragging ? 0.1 : 1 }}>
      {children}
    </div>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  findItem: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

const SortableList = ({ component, disabled, items, onChange, type, ...props }) => {
  const [list, setList] = useState(items);

  useEffect(() => {
    setList(items);
  }, [JSON.stringify(items)]);

  const findItem = id => {
    const index = list.findIndex(c => c.id === id);
    return { index, item: list[index] };
  };

  const handleChange = useCallback(
    _.debounce(value => {
      onChange(value);
    }, 1000),
    [onChange]
  );

  const moveItem = (id, atIndex) => {
    const { item } = findItem(id);
    const newItems = list.filter(c => c.id !== id);
    newItems.splice(atIndex, 0, item);
    setList(newItems);
    handleChange(newItems);
  };

  const [, drop] = useDrop({ accept: type });

  const ItemComponent = component;
  return (
    <div ref={drop}>
      {list.map(item => (
        <Item
          disabled={disabled}
          findItem={findItem}
          id={item.id}
          key={item.id}
          moveItem={moveItem}
          type={type}
        >
          <ItemComponent {...props} {...item} />
        </Item>
      ))}
    </div>
  );
};

SortableList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  component: PropTypes.func.isRequired,
  disabled: PropTypes,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

SortableList.defaultProps = {
  disabled: false
};

export default SortableList;
