/* eslint-disable react/no-array-index-key */
import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ScoreBubble from 'reports/base/charts/score-bubble';
import { formatMoney, formatPercentage } from 'utils/utils';
import {
  RECOMMENDED_TYPE,
  TARGET_TYPE,
  byAccountAmount,
  byModelWeight,
  getEntitiesFromProposal,
  matchingModelsChartData
} from '../utils';
import styles from './styles';

const MatchingModelsChart = ({ proposal }) => {
  const accounts = getEntitiesFromProposal(proposal, TARGET_TYPE);
  const models = getEntitiesFromProposal(proposal, RECOMMENDED_TYPE);

  const targetName = proposal?.target_label || '';
  const recommendedName = proposal?.recommended_label || '';

  const modelsTotalValue = proposal.recommended_total_value ?? 0;
  const withPercentages = proposal.recommended_with_percentages;

  const { accountsOverallScore, accountsTotalAmount, maxNumberOfElements, modelsOverallScore } =
    matchingModelsChartData(accounts, models);

  const renderContentElements = (elements, type) =>
    Array.from(Array(maxNumberOfElements)).map((e, i) => {
      const element = elements[i];

      if (element && type === TARGET_TYPE) {
        const key = `${element.display_name.toLowerCase().replace(/\s/g, '-')}-${
          element.prism_overall
        }-${element.value}`;
        return (
          <View key={key} style={styles.contentWrapper} wrap={false}>
            <View style={styles.contentScore}>
              <ScoreBubble score={element.prism_overall} />
            </View>
            <View style={[styles.contentLabel, { marginRight: 1 }]}>
              <Text style={[styles.label, { display: 'flex' }]}>{element.display_name}</Text>
            </View>
            <View style={[styles.contentAmount, { marginRight: 1 }]}>
              <Text>{formatMoney(element.value)}</Text>
            </View>
            <View style={styles.contentWeight}>
              <Text>{formatPercentage(element.value / accountsTotalAmount, 100, 2)}</Text>
            </View>
          </View>
        );
      }

      if (element && type === RECOMMENDED_TYPE) {
        const key = `${element.display_name.toLowerCase().replace(/\s/g, '-')}-${
          element.prism_overall
        }-${element.weight}`;
        const amount = withPercentages
          ? (element.weight / 100) * accountsTotalAmount
          : element.amount ?? modelsTotalValue;
        return (
          <View key={key} style={styles.contentWrapper} wrap={false}>
            <View style={styles.contentScore}>
              <ScoreBubble score={element.prism_overall} />
            </View>
            <View style={[styles.contentLabel, { marginRight: 1 }]}>
              <Text style={[styles.label, { display: 'flex' }]}>{element.display_name}</Text>
            </View>
            <View style={[styles.contentAmount, { marginRight: 1 }]}>
              <Text>{formatMoney(amount)}</Text>
            </View>
            <View style={styles.contentWeight}>
              <Text>{formatPercentage(element.weight, 1, 2)}</Text>
            </View>
          </View>
        );
      }

      return (
        <View key={`empty-${type}-${i}`} style={styles.contentWrapper} wrap={false}>
          <View style={styles.contentScore} />
          <View style={[styles.contentLabel, { marginRight: 1 }]} />
          <View style={[styles.contentAmount, { marginRight: 1 }]} />
          <View style={styles.contentWeight} />
        </View>
      );
    });

  const hasModels = !_.isEmpty(models);

  return (
    <View style={styles.table}>
      <View style={styles.row} wrap={false}>
        <View style={[styles.cell, styles.headerTargetCell]}>
          <Text style={styles.headerText}>{targetName || 'Current portfolio'}</Text>
        </View>
        {hasModels && (
          <View style={[styles.cell, styles.headerRecommendedCell]}>
            <Text style={styles.headerText}>{recommendedName || 'Suggested model'}</Text>
          </View>
        )}
      </View>

      <View style={[styles.row, { marginBottom: 1 }]}>
        <View style={styles.contentCell}>
          {renderContentElements(accounts.sort(byAccountAmount), TARGET_TYPE)}
        </View>
        {hasModels && (
          <View style={[styles.contentCell, { marginRight: 0 }]}>
            {renderContentElements(models.sort(byModelWeight), RECOMMENDED_TYPE)}
          </View>
        )}
      </View>

      <View style={styles.row} wrap={false}>
        <View style={styles.contentCell}>
          <View style={styles.contentWrapper}>
            <View style={[styles.contentScore, styles.totalTargetCell]}>
              <ScoreBubble score={accountsOverallScore} />
            </View>
            <View style={[styles.contentLabel, styles.totalTargetCell]}>
              <Text>Total</Text>
            </View>
            <View style={[styles.contentAmount, styles.totalTargetCell]}>
              <Text>{formatMoney(accountsTotalAmount)}</Text>
            </View>
            <View style={[styles.contentWeight, styles.totalTargetCell]}>
              <Text>100%</Text>
            </View>
          </View>
        </View>
        {hasModels && (
          <View style={[styles.contentCell, { marginRight: 0 }]}>
            <View style={styles.contentWrapper}>
              <View style={[styles.contentScore, styles.totalRecommendedCell]}>
                <ScoreBubble score={modelsOverallScore} />
              </View>
              <View style={[styles.contentLabel, styles.totalRecommendedCell]}>
                <Text>Overall</Text>
              </View>
              <View style={[styles.contentAmount, styles.totalRecommendedCell]}>
                <Text>{formatMoney(withPercentages ? accountsTotalAmount : modelsTotalValue)}</Text>
              </View>
              <View style={[styles.contentWeight, styles.totalRecommendedCell]}>
                <Text>100%</Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

MatchingModelsChart.propTypes = {
  proposal: PropTypes.object.isRequired
};

export default MatchingModelsChart;
