import { StyleSheet } from '@react-pdf/renderer';
import {
  PRISM_COLOR_0,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_5,
  PRISM_COLOR_9
} from 'reports/base/styles';

const styles = StyleSheet.create({
  container: { alignItems: 'flex-start', maxWidth: 300 },
  badge: { height: 35, marginBottom: 10 },
  personalityType: { fontSize: 20, marginBottom: 10 },
  levelColor0: { color: PRISM_COLOR_0 },
  levelColor1: { color: PRISM_COLOR_0 },
  levelColor2: { color: PRISM_COLOR_2 },
  levelColor3: { color: PRISM_COLOR_2 },
  levelColor4: { color: PRISM_COLOR_3 },
  levelColor5: { color: PRISM_COLOR_3 },
  levelColor6: { color: PRISM_COLOR_5 },
  levelColor7: { color: PRISM_COLOR_5 },
  levelColor8: { color: PRISM_COLOR_5 },
  levelColor9: { color: PRISM_COLOR_5 },
  levelColor10: { color: PRISM_COLOR_9 }
});

export default styles;
