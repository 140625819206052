import { StyleSheet } from '@react-pdf/renderer';
import { PRISM_COLOR_0, PRISM_COLOR_4, PRISM_COLOR_9 } from 'reports/base/styles';

const styles = StyleSheet.create({
  container: { alignItems: 'flex-start', maxWidth: 300 },
  badge: { height: 35, marginBottom: 10 },
  capacity: { fontSize: 20, fontWeight: 'bold', marginBottom: 10 },
  levelColor0: { color: PRISM_COLOR_9 },
  levelColor1: { color: PRISM_COLOR_9 },
  levelColor2: { color: PRISM_COLOR_9 },
  levelColor3: { color: PRISM_COLOR_9 },
  levelColor4: { color: PRISM_COLOR_4 },
  levelColor5: { color: PRISM_COLOR_4 },
  levelColor6: { color: PRISM_COLOR_4 },
  levelColor7: { color: PRISM_COLOR_0 },
  levelColor8: { color: PRISM_COLOR_0 },
  levelColor9: { color: PRISM_COLOR_0 },
  levelColor10: { color: PRISM_COLOR_0 }
});

export default styles;
