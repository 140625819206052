import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW, FOR_LANDSCAPE_ORIENTATION } from 'reports/base/styles';

const styles = StyleSheet.create({
  scenarios: {
    ...BASE_TABLE_ROW,
    justifyContent: 'space-between',
    [FOR_LANDSCAPE_ORIENTATION]: {
      justifyContent: 'space-around'
    }
  }
});

export default styles;
