import React from 'react';
import { capFirstChar } from 'utils/utils';

import FEATURES from './features';
import PlanFeature from './plan-feature';

import './styles.scss';

const PlansFeatures = () => (
  <div className="plans-features">
    <h1>Full Features</h1>
    <div className="features-header">
      {Object.keys(FEATURES[0]).map((header, index) => (
        <div className={header} key={header.description}>
          {capFirstChar(header)}
        </div>
      ))}
    </div>
    <div className="features-rows">
      {FEATURES.map((feature, index) => (
        <PlanFeature {...feature} key={feature.description} />
      ))}
    </div>
  </div>
);

export default PlansFeatures;
