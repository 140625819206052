import AddressForm, { validateAddress } from 'components/advisor/address-form';
import Dropzone from 'components/dropzone';
import { VerboseErrorInput as Input } from 'components/form';
import Choice from 'components/form/choice';
import { US_CODE } from 'components/form/country-drop-down';
import ColorPicker from 'components/color-picker';
import SpinnerLoader from 'components/performance-spinner';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { AdvisorContext } from 'containers/advisor';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';

const validate = values => {
  const errors = {};
  errors.address = validateAddress(values.address);

  errors.brand_primary_color =
    errors.brand_primary_color ||
    (values.brand_primary_color ? validation.hexColor(values.brand_primary_color) : null);

  errors.brand_secondary_color =
    errors.brand_secondary_color ||
    (values.brand_secondary_color ? validation.hexColor(values.brand_secondary_color) : null);
  return errors;
};

const DEFAULT_PRIMARY_COLOR = '#4ea9f2';
const DEFAULT_SECONDARY_COLOR = '#000000';

const AdvisorBranding = ({
  errors,
  fields,
  handleSubmit,
  initializeForm,
  registerError,
  setAdvisorUser,
  updateAdvisorProfile,
  updateAdvisorProfileImage,
  user
}) => {
  const useCompanyNameRef = useRef(null);

  const { userProvider } = useContext(AdvisorContext);

  const disabledBrandColor = !userProvider.isBusinessOrAbove(user);

  useEffect(() => {
    if (
      user &&
      !user.advisor.company_name &&
      !fields.use_company_name.value &&
      _.isBoolean(fields.use_company_name.value)
    )
      useCompanyNameRef.current.focus();
  }, [fields.use_company_name.value]);

  useEffect(() => {
    if (!user) return;
    const defaultCountry = user.advisor.company.address?.country || US_CODE;
    const values = {
      company_name: user.advisor.company_name || user.advisor.company.name,
      address: {
        address1: user.advisor.address?.address1 ?? user.advisor.company.address?.address1,
        city: user.advisor.address?.city ?? user.advisor.company.address?.city,
        country: user.advisor.address?.country || defaultCountry,
        postcode: user.advisor.address?.postcode ?? user.advisor.company.address?.postcode,
        state: user.advisor.address?.state ?? user.advisor.company.address?.state
      },
      use_company_address:
        user.advisor.address && user.advisor.company.address
          ? user.advisor.address.id === user.advisor.company.address.id
          : true,
      use_company_name: !user.advisor.company_name,
      use_company_website: !user.advisor.website,
      brand_primary_color: user.advisor?.brand_primary_color,
      brand_secondary_color: user.advisor?.brand_secondary_color,
      website: user.advisor.website
    };
    initializeForm(values);
  }, [JSON.stringify(user)]);

  const onSubmit = useCallback(
    async values => {
      if (values.address && values.use_company_address) delete values.address;
      if (values.use_company_name) values.company_name = '';
      if (values.use_company_website) values.website = '';

      const response = await updateAdvisorProfile(values);

      if (response.error) {
        toast.error('Sorry, something went wrong');
        registerError(response);
      } else {
        toast.success('Profile saved successfully');
        setAdvisorUser();
      }
    },
    [JSON.stringify(user)]
  );

  const onSubmitFile = (fieldName, message) =>
    useCallback(
      async files => {
        let response;
        if (Array.isArray(files) && files.length)
          response = await updateAdvisorProfileImage(files[0], fieldName);
        else response = await updateAdvisorProfile({ [fieldName]: null });

        if (response.error) toast.error('There was a problem saving this file.');
        else {
          toast.success(message);
          setAdvisorUser();
        }
      },
      [fieldName]
    );

  const onSubmitLogo = onSubmitFile('logo', 'Personal logo is successfully updated');

  const getSelectedColor = fieldName => color => {
    const { [fieldName]: field } = fields;
    field.onChange(color);
  };

  if (!user) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="advisor-branding">
      <h1>Branding</h1>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="content">
          <div className="col col-data col__right">
            <div className="row row-logo">
              <div className="col col-logo">
                <span className="label">Personal logo</span>
                <span className="help-text">
                  If set, it will be used on reports instead of your company&apos;s
                </span>
                <div className="image-box image-box-container">
                  <Dropzone onDrop={onSubmitLogo}>
                    {user.advisor.logo ? (
                      <>
                        <img src={user.advisor.logo} alt="" />
                        <div id="logo-dropzone-area" className="dropzone-area overlay">
                          Change logo
                        </div>
                      </>
                    ) : (
                      <div id="logo-dropzone-area" className="dropzone-area empty">
                        Click to upload
                        <small>Or drag file here</small>
                      </div>
                    )}
                  </Dropzone>
                  <div className="help-text">
                    <span>
                      Recommended width: 900px or higher.
                      <br />
                      File type: jpg, png
                    </span>
                    <button
                      type="button"
                      className="btn btn-secondary btn-small"
                      onClick={onSubmitLogo}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-data">
            <div className="row row-website">
              <div className="col">
                <Choice
                  {...fields.use_company_website}
                  toggle={fields.use_company_website.onChange}
                  title="My website is the same as the company website"
                />
                {!fields.use_company_website.value && (
                  <>
                    <span className="label">Website</span>
                    <Input
                      type="text"
                      placeholder="https://companydomain.com"
                      className="form-control"
                      {...fields.website}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="row row-address">
              <div className="col">
                <Choice
                  {...fields.use_company_address}
                  toggle={fields.use_company_address.onChange}
                  title="My address is the same as the company address"
                />
                {!fields.use_company_address.value && <AddressForm fields={fields.address} />}
              </div>
            </div>

            <div className="row row-company-name">
              <div className="col">
                <Choice
                  {...fields.use_company_name}
                  toggle={fields.use_company_name.onChange}
                  title={`I'm doing business as ${user.advisor.company.name}`}
                />
                {!fields.use_company_name.value && (
                  <>
                    <span className="label">Company Name</span>
                    <Input
                      className="form-control"
                      placeholder="My Company"
                      refCb={useCompanyNameRef}
                      type="text"
                      {...fields.company_name}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-brand-colors col-brand-colors__margin">
              <span className="label pl-3">Brand colors</span>
              <div className="col color-picker-container">
                <ColorPicker
                  defaultColor={user.advisor.brand_primary_color || DEFAULT_PRIMARY_COLOR}
                  disabled={disabledBrandColor}
                  getSelectedColor={getSelectedColor('brand_primary_color')}
                  helpText="Used for color accents in proposals"
                  inputClassName="form-control"
                  label="Primary color"
                  labelClassName="color-picker-label"
                  error={fields.brand_primary_color.error}
                  touched={fields.brand_primary_color.touched}
                />
                <ColorPicker
                  defaultColor={user.advisor.brand_secondary_color || DEFAULT_SECONDARY_COLOR}
                  disabled={disabledBrandColor}
                  getSelectedColor={getSelectedColor('brand_secondary_color')}
                  helpText="Used for section titles in proposals"
                  inputClassName="form-control"
                  label="Secondary color"
                  labelClassName="color-picker-label"
                  error={fields.brand_secondary_color.error}
                  touched={fields.brand_secondary_color.touched}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="actions">
          <button type="submit" className="btn btn-primary" disabled={errors && !_.isEmpty(errors)}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

AdvisorBranding.propTypes = {
  errors: PropTypes.object.isRequired,
  setAdvisorUser: PropTypes.func.isRequired,
  updateAdvisorProfile: PropTypes.func.isRequired,
  updateAdvisorProfileImage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'editAdvisor',
  fields: [
    'address.address1',
    'address.city',
    'address.postcode',
    'address.state',
    'address.country',
    'brand_primary_color',
    'brand_secondary_color',
    'company_name',
    'use_company_address',
    'use_company_name',
    'use_company_website',
    'website'
  ],
  initialValues: {},
  validate
})(BackendValidation(AdvisorBranding));
