import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { MAX_DISCUSSION_TOPICS, OTHER_DISCUSSION_TOPIC } from '../constants';
import styles from './styles';

const DiscussionTopicsTop = ({ discussionTopics }) => {
  if (!discussionTopics.length) return null;

  const discussionTopicsSummary = Array.from(
    { length: MAX_DISCUSSION_TOPICS },
    (_, idx) => discussionTopics[idx] || '-'
  );

  return (
    <BaseSection title="Topics to discuss" wrap={false} style={{ marginBottom: 0 }}>
      <View style={styles.container}>
        <View style={styles.row}>
          {discussionTopicsSummary.map((dt, idx) => (
            <View style={styles.cell}>
              <Text style={styles.position}>{idx + 1}</Text>
              <Text style={styles.text}>
                {dt.startsWith(OTHER_DISCUSSION_TOPIC) ? (
                  <>
                    {OTHER_DISCUSSION_TOPIC}{' '}
                    <Text style={styles.otherContent}>
                      {dt.replace(OTHER_DISCUSSION_TOPIC, '')}
                    </Text>
                  </>
                ) : (
                  dt
                )}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </BaseSection>
  );
};

DiscussionTopicsTop.defaultProps = {
  discussionTopics: []
};

DiscussionTopicsTop.propTypes = {
  discussionTopics: PropTypes.array
};

export default DiscussionTopicsTop;
