import { StyleSheet } from '@react-pdf/renderer';
import { TABLE_HEADER_BACKGROUND_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  headerCell: { backgroundColor: TABLE_HEADER_BACKGROUND_COLOR, marginTop: 10 },
  chartWrapper: { flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between' },
  chart: { flexGrow: 1, objectFit: 'scale-down', margin: 1, maxHeight: 220 }
});

export default styles;
