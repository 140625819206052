import { DROP_NOTIFICATIONS, LIST_NOTIFICATIONS } from 'constants/actions/notifications';

export default function notifications(state = [], action) {
  switch (action.type) {
    case LIST_NOTIFICATIONS:
      return action.data;

    case DROP_NOTIFICATIONS: {
      const notificationIds = action.data;
      return state.filter(notification => !notificationIds.includes(notification.id));
    }

    default:
      return state;
  }
}
