import {
  ACCOUNT_UPDATE,
  CHANGE_CLASS_NAME,
  GET_INDIVIDUAL_PROSPECT,
  GET_PROSPECT_ACCOUNTS,
  GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD,
  GET_PROSPECT_PRISM_DISTRIBUTION,
  PROSPECTS_LIST,
  PROSPECT_CLEAR_LIST_META,
  PROSPECT_CREATE,
  PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION,
  PROSPECT_LIST_ES,
  PROSPECT_NOTE_LIST,
  PROSPECT_UPDATE_SELECTED_IDS
} from 'constants/actions';
import { getUniqueObjectsArray } from 'utils/utils';

export const initialState = {
  list: [],
  listMeta: {},
  notes: {},
  selectedProspectIds: [],
  selectedProspects: [],
  viewAccounts: []
};

export default function prospects(state = initialState, action) {
  switch (action.type) {
    case PROSPECTS_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case ACCOUNT_UPDATE: {
      const incomingAccount = action.data;
      const accounts = state.viewAccounts.map(a => {
        if (a.id === incomingAccount.id) return { ...a, ...incomingAccount };
        return a;
      });
      return { ...state, viewAccounts: [...accounts] };
    }

    case PROSPECT_CLEAR_LIST_META:
      return { ...state, listMeta: {} };

    case PROSPECT_LIST_ES:
      return { ...state, esList: action.data };

    case CHANGE_CLASS_NAME:
      return { ...state, buttonClass: action.data.buttonClass };

    case PROSPECT_UPDATE_SELECTED_IDS: {
      const { selectedProspectIds } = action.data;
      const prospects = getUniqueObjectsArray([...state.selectedProspects, ...state.list]);
      return {
        ...state,
        selectedProspectIds,
        selectedProspects: prospects.filter(prospect =>
          selectedProspectIds.includes(String(prospect.id))
        )
      };
    }

    case GET_INDIVIDUAL_PROSPECT:
      return { ...state, view: action.data };

    case GET_PROSPECT_ACCOUNTS:
      return { ...state, viewAccounts: action.accounts };

    case GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD:
      return { ...state, viewAccountsWithHousehold: action.accounts };

    case PROSPECT_CREATE:
      return { ...state, prospect: action.data };

    case GET_PROSPECT_PRISM_DISTRIBUTION:
      return { ...state, prismDistribution: action.data };

    case PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION:
      return { ...state, driftLevelDistribution: action.data };

    case PROSPECT_NOTE_LIST:
      return { ...state, notes: { ...state.notes, [action.target]: action.data } };

    default:
      return state;
  }
}
