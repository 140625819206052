import MetaTemplate from 'components/advisor/templates/meta';
import { AdvisorContext } from 'containers/advisor';
import { CRS_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';
import React, { useContext, useMemo, useState } from 'react';
import './styles.scss';
import prepareMetadata from './utils';

const TEMPLATE_NAME = 'crs_metadata';

const CRS = () => {
  const { user } = useContext(AdvisorContext);

  const initialContent = useMemo(
    () => prepareMetadata(user.advisor.company.crs_metadata || CRS_DEFAULT_TEMPLATE, user),
    [JSON.stringify(user)]
  );

  const [content, setContent] = useState(initialContent);

  // wrap setContent in a promise
  const handleSaveTemplate = async (_, content) => {
    setContent(prepareMetadata(content, user));
  };

  return (
    <div id="crs-template">
      <MetaTemplate
        allowPrint
        content={content}
        handleSaveTemplate={handleSaveTemplate}
        target={TEMPLATE_NAME}
        title="Form CRS"
      />
    </div>
  );
};

export default CRS;
