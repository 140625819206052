import SectionLocked from 'components/advisor/section-locked';
import { SECTION_LOCKED_MAP, TOP_HOLDINGS } from 'components/advisor/section-locked/utils';
import CollapsibleTable from 'components/collapsible-table';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import DisclaimerNote from '../common/disclaimer-note';
import {
  SECURITY_UNDERLYING_MODEL,
  getSecurityDetails,
  processSecurityDetails
} from '../common/utils';
import { TOP_HOLDINGS_TO_SHOW } from './constants';
import './styles.scss';

const TopHoldings = ({ hiddenVal, portfolio }) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          top_holdings_enabled: topHoldingsEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const { name, portfolioRiskId } = SECTION_LOCKED_MAP[TOP_HOLDINGS];

  if (!topHoldingsEnabled)
    return (
      <div id="top-holdings">
        <SectionLocked id={portfolioRiskId} name={name} />
      </div>
    );

  const totalAssets = portfolio.value ?? 0;
  const securityTopHoldings = getSecurityDetails(portfolio, SECURITY_UNDERLYING_MODEL, totalAssets);

  const filteredSecurityTopHoldings = securityTopHoldings
    .sort((a, b) => b.value - a.value)
    .slice(0, TOP_HOLDINGS_TO_SHOW);

  const { data, total: totalSecurities } = processSecurityDetails(
    filteredSecurityTopHoldings,
    SECURITY_UNDERLYING_MODEL,
    totalAssets
  );

  return (
    <div id="top-holdings">
      <div className="collapsible-container">
        <CollapsibleTable
          bottomNameLabel="Total portfolio value"
          data={data.summary}
          expandAssetClassification={expandAssetDetails}
          headingLabels={{ name: '', value: 'Value', percent: '% of Portfolio' }}
          hiddenVal={hiddenVal}
          showBottomSection
          showHeadingRow
          showPercent
          expandAll
        />
        <DisclaimerNote totalAssets={totalAssets} totalSecurities={totalSecurities} />
      </div>
    </div>
  );
};

TopHoldings.propTypes = {
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.array.isRequired
};

TopHoldings.defaultProps = {
  hiddenVal: false
};

export default TopHoldings;
