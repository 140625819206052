import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import PrismSection from 'reports/base/sections/prism';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import styles from './styles';

const ProposalPrism = ({ breakSection, metadata, proposal }) => {
  const hasRecommended = !!proposal.recommended;
  const { body, title } = metadata;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <ExtraDataSection proposal={proposal} />
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={[styles.cell, styles.targetCell]}>
            <Text style={styles.headerText}>{proposal.target_label || ''}</Text>
          </View>
          {hasRecommended && (
            <View style={[styles.cell, styles.recommendedCell, { marginRight: 0 }]}>
              <Text style={styles.headerText}>{proposal.recommended_label}</Text>
            </View>
          )}
        </View>
        <View style={styles.row}>
          <View style={styles.cell}>
            <PrismSection
              risk={proposal.target.prism_score_summary?.overall}
              tolerance={proposal.target.target_score_summary?.overall}
            />
          </View>
          {hasRecommended && (
            <View style={styles.cell}>
              <PrismSection
                risk={
                  proposal.recommended.prism_score_weighted ||
                  proposal.recommended.prism_score_summary?.overall
                }
              />
            </View>
          )}
        </View>
      </View>
    </BaseSection>
  );
};

ProposalPrism.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalPrism.defaultProps = {
  breakSection: false
};

export default ProposalPrism;
