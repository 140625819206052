import React from 'react';
import ClassificationSettings from './settings';
import './styles.scss';
import ClassificationTaxonomies from './taxonomies';

const AssetsClassification = () => (
  <div id="AssetsClassification">
    <ClassificationSettings />
    <ClassificationTaxonomies />
  </div>
);

export default AssetsClassification;
