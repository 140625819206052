import PropTypes from 'prop-types';
import React from 'react';

const IPSRiskToleranceHeader = ({ proposal }) => {
  const riskToleranceScore = proposal.target.target_score_summary.overall;
  return (
    <div style={{ marginBottom: 10 }}>
      <span>Your risk tolerance is</span>{' '}
      <span className={`risk-badge-with-score risk-level-${Math.ceil(riskToleranceScore)}`}>
        {riskToleranceScore}
      </span>{' '}
      <b>out of 10</b>.
    </div>
  );
};

IPSRiskToleranceHeader.propTypes = {
  proposal: PropTypes.object.isRequired
};

export default IPSRiskToleranceHeader;
