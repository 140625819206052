// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autosize-input {
  display: flex;
  box-sizing: border-box;
  width: -moz-min-content;
  width: min-content;
  margin: auto 0;
  width: 100%;
  overflow: hidden;
}
.autosize-input .autosize-input__container {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
}
.autosize-input .autosize-input__size {
  font-family: inherit;
  white-space: pre;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
  position: relative;
  opacity: 0;
  min-width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: top;
}
.autosize-input .autosize-input__input {
  height: 100%;
  font-family: inherit;
  background: none;
  color: inherit;
  top: 0;
  left: 0;
  font-size: inherit;
  line-height: inherit;
  padding: inherit;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/autosize-input/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,sBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,oBAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;EACA,yBAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,oBAAA;EACA,gBAAA;EACA,cAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;AADJ","sourcesContent":[".autosize-input {\n  display: flex;\n  box-sizing: border-box;\n  width: min-content;\n  margin: auto 0;\n  width: 100%;\n  overflow: hidden;\n\n  .autosize-input__container {\n    position: relative;\n    box-sizing: border-box;\n    display: inline-block;\n    max-width: 100%;\n    overflow: hidden;\n  }\n\n  .autosize-input__size {\n    font-family: inherit;\n    white-space: pre;\n    display: inline-block;\n    font-size: inherit;\n    line-height: inherit;\n    box-sizing: border-box;\n    position: relative;\n    opacity: 0;\n    min-width: 30px;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    user-select: none;\n    vertical-align: top;\n  }\n\n  .autosize-input__input {\n    height: 100%;\n    font-family: inherit;\n    background: none;\n    color: inherit;\n    top: 0;\n    left: 0;\n    font-size: inherit;\n    line-height: inherit;\n    padding: inherit;\n    position: absolute;\n    box-sizing: border-box;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
