import { InvestorContext } from 'containers/investor';
import NavigationInvestor from 'containers/navigation/investor';
import React, { useContext } from 'react';
import Header from '../header';

const HeaderInvestor = () => {
  const { authProvider, user } = useContext(InvestorContext);
  if (!authProvider.isInvestor(user)) return null;

  const {
    investor: { advisor }
  } = user;

  return <Header mode="investor" advisor={advisor} navigation={<NavigationInvestor />} />;
};

export default HeaderInvestor;
