/* global STANDARD_DATE_FORMAT */
import ProposalHeaderInvestor from 'components/advisor/proposal/header/investor';
import { suggestionsToPortfolios } from 'components/advisor/proposal/header/utils';
import { AdvisorContext } from 'containers/advisor';
import { camelizeKeys, decamelizeKeys } from 'humps';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import './styles.scss';

const IPSHeader = ({
  accountOptions,
  entityId,
  generateIPS,
  proposalType,
  scope,
  templateOptions,
  ...props
}) => {
  const { showTargetScoreWizard, ipsProvider } = useContext(AdvisorContext);

  if (!scope.aggregated_target_scores)
    return (
      <div className="ips-header no-tolerance-msg">
        <p>Risk Tolerance is needed to generate IPS</p>
        <button type="button" className="btn btn-primary" onClick={() => showTargetScoreWizard()}>
          Update Risk Tolerance
        </button>
      </div>
    );

  const onGenerate = values => {
    ipsProvider.clear();

    values.target = suggestionsToPortfolios(values.target, false);
    if (values.recommended)
      values.recommended = suggestionsToPortfolios(
        values.recommended,
        true,
        !values.recommendedWithPercentages
      );
    if (values.benchmark)
      values.benchmark = suggestionsToPortfolios(
        values.benchmark,
        true,
        !values.benchmarkWithPercentages
      );

    return new Promise((resolve, reject) => {
      generateIPS(decamelizeKeys(values))
        .then(response => {
          const { data, error } = response;
          if (_.isEmpty(data) || error) {
            const errors = error ? camelizeKeys(error.errors) : null;
            reject(errors);
          } else resolve();
        })
        .catch(error => {
          const errors = error ? camelizeKeys(error.errors) : null;
          reject(errors);
        });
    });
  };

  return (
    <div className="ips-header print-display-none">
      <ProposalHeaderInvestor
        accountOptions={accountOptions}
        entityId={entityId}
        isIPS
        onGenerate={onGenerate}
        proposalType={proposalType}
        scope={scope}
        templateOptions={templateOptions}
        {...props}
      />
    </div>
  );
};

IPSHeader.defaultProps = {
  entityId: null
};

IPSHeader.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityId: PropTypes.number,
  generateIPS: PropTypes.func.isRequired,
  proposalType: PropTypes.string.isRequired,
  scope: PropTypes.object.isRequired,
  templateOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default IPSHeader;
