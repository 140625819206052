import PropTypes from 'prop-types';
import React from 'react';
import Separator from '../separator';
import GoogleSignin from './google-signin';
import MicrosoftSignin from './microsoft-signin';
import './styles.scss';

const SocialAuth = ({ onLogin, setSocialProvider, setProcessing, trustedDeviceId }) => {
  const withGoogle = Boolean(process.env.SHOW_GOOGLE_SSO_BUTTON);
  const withMicrosoft = Boolean(process.env.SHOW_MICROSOFT_SSO_BUTTON);
  if (!withGoogle && !withMicrosoft) return null;
  return (
    <>
      <Separator />

      <div className="social-providers">
        {withMicrosoft && (
          <MicrosoftSignin
            onLogin={onLogin}
            setProcessing={setProcessing}
            setSocialProvider={setSocialProvider}
            trustedDeviceId={trustedDeviceId}
          />
        )}
        {withGoogle && (
          <GoogleSignin
            onLogin={onLogin}
            setProcessing={setProcessing}
            setSocialProvider={setSocialProvider}
            trustedDeviceId={trustedDeviceId}
          />
        )}
      </div>
    </>
  );
};

SocialAuth.propTypes = {
  onLogin: PropTypes.func.isRequired,
  setSocialProvider: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  trustedDeviceId: PropTypes.string
};

SocialAuth.defaultProps = {
  trustedDeviceId: null
};

export default SocialAuth;
