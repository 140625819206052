import cn from 'classnames';
import SimpleSpinner from 'components/utils/simple-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';
import './styles.scss';

class ClientAutocomplete extends Component {
  constructor() {
    super();
    this.state = { clientValue: '', clientSuggestions: [], clientSelected: null, assigning: false };
    this.onSuggestionsFetchRequested = _.debounce(this.onSuggestionsFetchRequested, 300);
  }

  componentDidMount() {
    const { investorName } = this.props;
    if (investorName) this.setState({ clientValue: investorName });
  }

  componentDidUpdate(prevProps) {
    const { investorName } = this.props;
    const { investorName: prevInvestorName } = prevProps;

    if (prevInvestorName !== investorName) this.setState({ clientValue: investorName });
  }

  getSuggestionValue = suggestion => suggestion.name;

  onChangeHandler = (event, { newValue }) => {
    if (newValue) this.setState({ clientValue: newValue });
    else this.setState({ clientValue: '', clientSelected: null });
  };

  onSuggestionsClearRequested = () => {
    const { investorName } = this.props;
    const emptyState = { clientSuggestions: [] };
    if (!investorName) emptyState.clientValue = '';
    this.setState(emptyState);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { investorProvider } = this.context;
    investorProvider.es.list({ search: value }).then(({ results }) => {
      this.setState({
        clientSuggestions: results.map(({ id, name }) => ({ id, name }))
      });
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { integrationProvider } = this.context;
    const { integration, onClientAssignment } = this.props;

    this.setState({ clientSelected: suggestion, assigning: true }, () => {
      const { clientSelected } = this.state;
      integrationProvider
        .assignClientSyncedAccount(integration.id, clientSelected.id)
        .then(() => {
          // successful notification
          toast.success(() => (
            <div className="client-assigned-notification">
              <span role="img" aria-label="thumbs-up">
                👍
              </span>{' '}
              <p>
                <b>{integration.name}</b> is assigned to <b>{clientSelected.name}</b>.
              </p>
            </div>
          ));

          // set the client name for the first time
          this.setState({ clientValue: clientSelected.name });

          // count unassigned accounts after successful response
          integrationProvider.es.countUnassignedSyncedAccounts().then(() => {
            onClientAssignment();
          });
        })
        .catch(error => {
          // error notification
          toast.error(() => <div>{error.message}</div>);

          // set the name of the original client in case of an error
          const { investorName } = this.props;
          if (investorName) this.setState({ clientValue: investorName });
        })
        .finally(() => {
          this.setState({ assigning: false });
        });
    });
  };

  renderSuggestion = client => <div>{client.name}</div>;

  render() {
    const { clientValue, clientSuggestions, assigning } = this.state;
    const { integration } = this.props;

    const inputProps = {
      onChange: this.onChangeHandler,
      placeholder: 'Search client name',
      value: clientValue
    };

    return (
      <div key={integration.id} className="client-autocomplete">
        {assigning && (
          <div className="client-autocomplete__assigning">
            <p>Assigning...</p> <SimpleSpinner />
          </div>
        )}

        {!assigning && (
          <>
            <Autosuggest
              suggestions={clientSuggestions}
              getSuggestionValue={this.getSuggestionValue}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
              theme={{
                container: 'dropdown react-autosuggest__container',
                containerOpen: 'open react-autosuggest__container--open',
                input: `form-control react-autosuggest__input ${cn({
                  'with-client': !!clientValue
                })}`,
                suggestionsContainer:
                  'dropdown-menu dropdown-menu-scale react-autosuggest__suggestions-container',
                suggestion: 'dropdown-item react-autosuggest__suggestion',
                suggestionFocused: 'react-autosuggest__suggestion--focused'
              }}
            />
            <span className={cn('icon-search_icn', { 'with-client': !!clientValue })} />
          </>
        )}
      </div>
    );
  }
}

ClientAutocomplete.contextTypes = {
  integrationProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired
};

ClientAutocomplete.propTypes = {
  integration: PropTypes.object,
  investorName: PropTypes.string,
  onClientAssignment: PropTypes.func.isRequired
};

ClientAutocomplete.defaultProps = {
  integration: null,
  investorName: null
};

export default ClientAutocomplete;
