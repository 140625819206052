/* eslint-disable camelcase, consistent-return, max-classes-per-file, no-underscore-dangle */
import _ from 'lodash';
import moment from 'moment';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import validator from 'validator';
import { dateDiff, isNum, parseDate } from './utils';

export const validation = {
  _regex: {
    int: /^[+-]?\d+$/,
    intPositive: /^\d+$/,
    float: /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/,
    floatPositive: /^(((\d{1,3},?)+(\.\d+)?)|(\.\d+))$/,
    floatPercentage: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    date: /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/, // YYYY-MM-DD,
    dateYearLast: /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/, // MM-DD-YYYY
    address: /^[a-zA-Z#\s\d\/.,]+$/,
    city: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
    zipCode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    hexColor: /^#[0-9a-fA-F]{3}(?:[0-9a-fA-F]{3})?$/,
    name: /^[a-z ,.'-]$/i,
    phone: /^\(\d{3}\) \d{3}-\d{4}$/,
    nonEmpty: /(.|\s)*\S(.|\s)*/,
    website:
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,9}(:[0-9]{1,9})?(\/.*)?$/
  },

  required: value => {
    if (value === false || value === 0) return;
    if (!value) return 'Required';
  },

  empty: value => {
    if (_.isEmpty(value)) return 'Required';
  },

  equal: (value, value2) => {
    if (value !== value2) return 'Should be equal';
  },

  minLength: (value, minLengthValue) => {
    if (value && value.length < minLengthValue) return `Please select ${minLengthValue} choice(s)`;
  },

  maxLength: (value, maxLengthValue) => {
    if (value && value.length > maxLengthValue) return `Only ${maxLengthValue} choice(s) allowed`;
  },

  number: value => validation.pattern(value, validation._regex.int, 'Invalid number'),

  isWebsite: value =>
    validation.pattern(value, validation._regex.website, 'Invalid Website format.'),

  int: value => validation.pattern(value, validation._regex.int, 'Invalid number'),

  intPositive: value =>
    validation.pattern(value, validation._regex.intPositive, 'Invalid positive number'),

  nonZero: value => {
    if (parseFloat(value) === 0) return 'Invalid non-zero value';
  },

  min: (value, threshold) => {
    if (parseInt(value, 10) < threshold)
      return `The value should be greater or equal than ${threshold}`;
  },

  max: (value, threshold) => {
    if (parseInt(value, 10) > threshold) return `The value should be less or equal ${threshold}`;
  },

  maxPercent: value => {
    if (parseInt(value, 10) > 100) return 'Max percentage is 100%';
  },

  isPercentage: (value, minValue = 0, maxValue = 100) => {
    if (parseInt(value, 10) < minValue || parseInt(value, 10) > maxValue)
      return `Percentage should be a value between ${minValue}% and ${maxValue}%`;
  },

  isFloatPercentage: value =>
    validation.pattern(
      value,
      validation._regex.floatPercentage,
      'Percentage should be a valid value between 0% and 100%'
    ),

  float: value => validation.pattern(value, validation._regex.float, 'Invalid number'),

  floatPositive: value =>
    validation.pattern(value, validation._regex.floatPositive, 'Invalid positive number'),

  email: value => validation.pattern(value, validation._regex.email, 'Invalid email'),

  url: value => {
    if (!validator.isURL(value)) return 'Invalid URL';
    return '';
  },

  date: value => validation.pattern(value, validation._regex.date, 'Invalid date (YYYY-MM-DD)'),

  dateYearLast: value =>
    validation.pattern(value, validation._regex.dateYearLast, 'Invalid date (MM-DD-YYYY)'),

  password: value =>
    // at least one uppercase
    // at least one lowercase
    // at least one number
    // at least one special character
    // at least 8 characters long
    validation.pattern(
      value,
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]).{8,}$/,
      'Weak password: at least 8 characters, one uppercase, one lowercase, one number, one special character'
    ),
  address(value, max_length = 100) {
    let error = this.maxLength(value, max_length);
    if (!error) error = this.pattern(value, this._regex.address, 'Invalid address');

    return error;
  },

  city(value) {
    return this.pattern(value, this._regex.city, 'Invalid city name');
  },

  zipCode(value) {
    return this.pattern(value, this._regex.zipCode, 'Invalid zipcode value');
  },

  hexColor(value) {
    return this.pattern(value, this._regex.hexColor, 'Invalid hex color');
  },

  name(value) {
    return this.pattern(value, this._regex.name, 'Invalid name value');
  },

  phone(value) {
    return this.pattern(value, this._regex.phone, 'Invalid phone value');
  },

  internationalPhone(value) {
    return isPossiblePhoneNumber(value) ? '' : 'Invalid international phone number';
  },

  nonEmptyHtml(value) {
    return this.pattern(value.replace(/<[^>]+>/g, ''), this._regex.nonEmpty, 'Invalid Empty HTML');
  },

  variableExistance(value, variable) {
    if (!value.includes(variable)) return `Missing required variable ${variable}`;
  },

  pattern: (value, pattern = /^.*$/, error = 'Invalid value') => {
    value = value ? `${value}` : '';
    if (!value.match(pattern)) return error;
  }
};

export const normalize = {
  url: (value, _) => {
    value = (value || '').replace(/^(http|https):\/\//, '');
    if (!value) return value;
    return `http://${value}`;
  },

  nDecimalPoints: (n, value, _) => {
    if (!value || !isNum(value)) return value;
    const valueStr = value.toString();
    const parts = value.toString().split('.');
    if (parts.length > 1 && parts[1].length < n) return value;
    return Number.parseFloat(valueStr.slice(0, parts[0].length + 1 + n));
  },

  float: (value, _) => {
    if (!value) return value;
    let [digits, decimals] = value.toString().split('.');
    digits = digits.replace(/[^\d]/g, '');
    digits = parseFloat(digits);
    return Number.isNaN(digits) ? null : digits.toLocaleString('en');
  },

  floatPercentage: (maxDigits, decimalPlaces, value, previousValue) => {
    if (!value || !isNum(value)) return value;

    const stringValue = value.toString();
    const [digits, decimals] = stringValue.split('.');

    // return the value if one of these two conditions were met:
    // - the number of decimal places corresponds to the maximum allowed
    // - the number of digits corresponds to the maximum allowed
    if (
      (decimals && decimals.length <= decimalPlaces) ||
      (!decimals && digits.length <= maxDigits - decimalPlaces)
    )
      return value;

    return previousValue;
  }
};

class Validator {
  constructor(value) {
    this.value = value;
    this.error = null;
  }

  required() {
    if (!this.error) this.error = validation.required(this.value);
    return this;
  }

  dateLegacy() {
    if (!this.error) this.error = validation.date(this.value);
    return this;
  }

  date() {
    if (!this.error) this.error = validation.dateYearLast(this.value);
    return this;
  }

  name() {
    if (!this.error) this.error = validation.name(this.value);
    return this;
  }

  password() {
    if (!this.error) this.error = validation.password(this.value);
    return this;
  }

  email() {
    if (!this.error) this.error = validation.email(this.value);
    return this;
  }

  address() {
    if (!this.error && this.value) this.error = validation.address(this.value);
    return this;
  }

  city() {
    if (!this.error && this.value) this.error = validation.city(this.value);
    return this;
  }

  zipCode() {
    if (!this.error && this.value) this.error = validation.zipCode(this.value);
    return this;
  }

  hexColor() {
    if (!this.error && this.value) this.error = validation.hexColor(this.value);
    return this;
  }

  int() {
    if (!this.error) this.error = validation.int(this.value);
    return this;
  }

  intPositive() {
    if (!this.error) this.error = validation.intPositive(this.value);
    return this;
  }

  float() {
    if (!this.error) this.error = validation.float(this.value);
    return this;
  }

  floatPositive() {
    if (!this.error) this.error = validation.floatPositive(this.value);
    return this;
  }

  min(threshold) {
    if (!this.error && Number.parseFloat(this.value) < threshold)
      this.error = `The value should be greater or equal than ${threshold}`;

    return this;
  }

  max(threshold) {
    if (!this.error && Number.parseFloat(this.value) > threshold)
      this.error = `The value should be less or equal ${threshold}`;

    return this;
  }

  any(predicate, reason = 'should satisfy the predicate') {
    if (!this.error && this.value.length && !this.value.filter(predicate).length)
      this.error = `At least one item ${reason}`;

    return this;
  }

  minDateDiff(diff, diffOp) {
    if (!this.error && this.value && this.value.length && this.value.length === 2) {
      const [date1, date2] = this.value;
      if (dateDiff(parseDate(date1, false), parseDate(date2, false), diffOp) < diff)
        this.error = 'Too narrow date range';
    }
    return this;
  }

  lessThanDate(dateStr) {
    if (parseDate(dateStr, false) <= parseDate(this.value, false))
      this.error = `${this.value} should be less than ${dateStr}`;

    return this;
  }
}

class BaseValidator {
  constructor(value) {
    this.value = value;
    this.error = null;
  }

  _validate(error) {
    if (this.error) return this;
    if (error) this.error = error;
    return this;
  }

  required = msg => {
    const error = validation.required(this.value);
    return this._validate(error && (msg || error));
  };
}

export class NumberValidator extends BaseValidator {
  min = (min, msg) => this._validate(this.value < min && (msg || `Should not be less than ${min}`));

  max = (max, msg) =>
    this._validate(this.value > max && (msg || `Should not be greater than ${max}`));
}

export class DateValidator extends BaseValidator {
  valid = msg => this._validate(validation.date(this.value) && (msg || 'Invalid date'));

  min = (min, msg) =>
    this._validate(moment(this.value).isBefore(min) && (msg || `Should not be before ${min}`));

  max = (max, msg) =>
    this._validate(moment(this.value).isAfter(max) && (msg || `Should not be after ${max}`));

  monthDifference = (date2, months, msg) => {
    const minDifferenceMonths = months;
    const momentDate1 = moment(this.value);
    const momentDate2 = moment(date2);

    const monthDifference = momentDate2.diff(momentDate1, 'months');

    return this._validate(
      monthDifference < minDifferenceMonths && (msg || `Minimum ${months} months required.`)
    );
  };
}

export const check = value => new Validator(value);

export const normalizeErrors = errors => {
  let result = {};
  Object.values(errors).forEach(error => {
    result = _.isArray(error) ? error.join(' ') : error;
  });
  return result;
};

export const readPercentageField = value => {
  // convert percentages input values, ex. 12% -> 12, 12%1 -> 121, % -> 0, 12 -> 1
  if (!value.match(/%/)) value = value.slice(0, -1);
  value = value.replace(/(^0+|[^0-9])/g, '');
  return Number(value || 0);
};
