import { StyleSheet } from '@react-pdf/renderer';
import { LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  text: { marginBottom: 10 },
  bold: { fontWeight: 700 },
  separator: {
    flexGrow: 1,
    paddingBottom: 5,
    marginVertical: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR
  }
});

export default styles;
