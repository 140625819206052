/* eslint-disable import/no-import-module-exports */
import { composeWithDevTools } from '@redux-devtools/extension';
import { AUTH_LOGOUT } from 'constants/actions/auth';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import reducers from './app/reducers';

function setupStore(browserHistory) {
  // init: redux reducer
  const appReducer = combineReducers({ ...reducers, routing: routerReducer });

  // we cleanup the state when a user log out.
  const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT) state = undefined;
    return appReducer(state, action);
  };

  // init: redux store
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(routerMiddleware(browserHistory)))
  );

  if (module.hot)
    // enable webpack hot module replacement for reducers
    module.hot.accept('./app/reducers', () => {
      const nextAppReducer = combineReducers(
        // eslint-disable-next-line global-require
        { ...require('./app/reducers').default, routing: routerReducer }
      );
      const nextRootReducer = (state, action) => {
        if (action.type === AUTH_LOGOUT) state = undefined;
        return nextAppReducer(state, action);
      };
      store.replaceReducer(nextRootReducer);
    });

  return store;
}

export default setupStore;
