import {
  ACCOUNT_COUNT_EXCLUDED,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_GET,
  ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION,
  ACCOUNT_LIST,
  ACCOUNT_LIST_ES,
  ACCOUNT_UPDATE,
  ADVISOR_LIST_ES,
  ADVISOR_STATS_GET,
  CARD_DELETE,
  CARD_LIST,
  CARD_SETUP_INTENT,
  CARD_SET_AS_DEFAULT,
  CHARGE_LIST,
  COMPANY_GET_USER_LIST,
  FETCHING,
  FETCHING_FAILURE,
  GET_ACCOUNTS_BY_RISK_LEVEL,
  GET_ACCOUNT_PRISM_DISTRIBUTION,
  GET_ACCOUNT_TARGET_RATING,
  GET_ALL_INVESTORS,
  GET_INDIVIDUAL_PROSPECT,
  GET_INVESTOR_PRISM_DISTRIBUTION,
  GET_PRISM_TARGET_HISTORY,
  GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD,
  HOUSEHOLD_GET,
  HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION,
  HOUSEHOLD_GET_MEMBER_SUGGESTIONS,
  HOUSEHOLD_LINK,
  HOUSEHOLD_LIST_ES,
  HOUSEHOLD_UNLINK,
  INVESTOR_CREATE,
  INVESTOR_DELETE,
  INVESTOR_GET,
  INVESTOR_GET_ACCOUNTS,
  INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD,
  INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION,
  INVESTOR_GET_PORTFOLIOS,
  INVESTOR_GET_PROPOSALS,
  INVESTOR_GOAL_CREATE,
  INVESTOR_GOAL_DELETE,
  INVESTOR_GOAL_EDIT,
  INVESTOR_GOAL_LIST,
  INVESTOR_LIST,
  INVESTOR_LIST_ES,
  INVESTOR_UPDATE,
  IPS_GET,
  IPS_REPORTS_CREATE,
  IPS_REPORTS_CREATE_REVIEW,
  IPS_REPORTS_GET,
  IPS_REPORTS_LIST,
  IPS_REPORTS_UPDATE,
  IPS_UPDATE,
  MODEL_LIST_ES,
  PRICE_GET,
  PRICE_LIST,
  PROPOSAL_GET,
  PROPOSAL_REPORTS_CREATE,
  PROPOSAL_REPORTS_CREATE_REVIEW,
  PROPOSAL_REPORTS_GET,
  PROPOSAL_REPORTS_LIST,
  PROPOSAL_REPORTS_UPDATE,
  PROPOSAL_UPDATE,
  PROSPECTS_LIST,
  PROSPECT_DELETE,
  PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION,
  PROSPECT_LIST_ES,
  SECURITY_LIST_ES,
  SNOOZE_ACCOUNT,
  WAKEUP_ACCOUNT
} from 'constants/actions';
import { TEMPLATE_CREATE, TEMPLATE_GET, TEMPLATE_LIST, TEMPLATE_UPDATE } from 'constants/templates';

export default function documents(state = {}, action) {
  const count = state.count || 0;

  switch (action.type) {
    case FETCHING:
      return { ...state, count: count + 1 };
    case ACCOUNT_COUNT_EXCLUDED:
    case ACCOUNT_CREATE:
    case ACCOUNT_DELETE:
    case ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION:
    case ACCOUNT_GET:
    case ACCOUNT_LIST_ES:
    case ACCOUNT_LIST:
    case ACCOUNT_UPDATE:
    case ADVISOR_LIST_ES:
    case ADVISOR_STATS_GET:
    case CARD_DELETE:
    case CARD_LIST:
    case CARD_SET_AS_DEFAULT:
    case CARD_SETUP_INTENT:
    case CHARGE_LIST:
    case COMPANY_GET_USER_LIST:
    case FETCHING_FAILURE:
    case GET_ACCOUNT_PRISM_DISTRIBUTION:
    case GET_ACCOUNT_TARGET_RATING:
    case GET_ACCOUNTS_BY_RISK_LEVEL:
    case GET_ALL_INVESTORS:
    case GET_INDIVIDUAL_PROSPECT:
    case GET_INVESTOR_PRISM_DISTRIBUTION:
    case GET_PRISM_TARGET_HISTORY:
    case GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD:
    case HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION:
    case HOUSEHOLD_GET_MEMBER_SUGGESTIONS:
    case HOUSEHOLD_GET:
    case HOUSEHOLD_LINK:
    case HOUSEHOLD_LIST_ES:
    case HOUSEHOLD_UNLINK:
    case INVESTOR_CREATE:
    case INVESTOR_DELETE:
    case INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD:
    case INVESTOR_GET_ACCOUNTS:
    case INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION:
    case INVESTOR_GET_PORTFOLIOS:
    case INVESTOR_GET_PROPOSALS:
    case INVESTOR_GET:
    case INVESTOR_GOAL_CREATE:
    case INVESTOR_GOAL_DELETE:
    case INVESTOR_GOAL_EDIT:
    case INVESTOR_GOAL_LIST:
    case INVESTOR_LIST_ES:
    case INVESTOR_LIST:
    case INVESTOR_UPDATE:
    case IPS_GET:
    case IPS_REPORTS_CREATE_REVIEW:
    case IPS_REPORTS_CREATE:
    case IPS_REPORTS_GET:
    case IPS_REPORTS_LIST:
    case IPS_REPORTS_UPDATE:
    case IPS_UPDATE:
    case MODEL_LIST_ES:
    case PRICE_GET:
    case PRICE_LIST:
    case PROPOSAL_GET:
    case PROPOSAL_REPORTS_CREATE_REVIEW:
    case PROPOSAL_REPORTS_CREATE:
    case PROPOSAL_REPORTS_GET:
    case PROPOSAL_REPORTS_LIST:
    case PROPOSAL_REPORTS_UPDATE:
    case PROPOSAL_UPDATE:
    case PROSPECT_DELETE:
    case PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION:
    case PROSPECT_LIST_ES:
    case PROSPECTS_LIST:
    case SECURITY_LIST_ES:
    case SNOOZE_ACCOUNT:
    case TEMPLATE_CREATE:
    case TEMPLATE_GET:
    case TEMPLATE_LIST:
    case TEMPLATE_UPDATE:
    case WAKEUP_ACCOUNT:
      return { ...state, count: count - 1 };
    default:
      return state;
  }
}
