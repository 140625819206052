import cn from 'classnames';
import Choice from 'components/form/choice';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import SignatureIcon from 'components/svg-icons/signature-icon';
import { AdvisorContext } from 'containers/advisor';
import humps from 'humps';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { byNonFixedSections, byTemplateSectionOrder } from 'utils/utils';
import './styles.scss';

const UP_ACTION = 'up';
const DONW_ACTION = 'down';

const CustomizeOrderModal = ({ handleSaveTemplate, templateContent, templateSettings }) => {
  const { authProvider, user } = useContext(AdvisorContext);

  const [allowSectionsOrder, setAllowSectionsOrder] = useState(
    !!templateSettings?.allowSectionsOrder
  );
  const [content, setContent] = useState(templateContent);
  const [isShown, setIsShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const isTemplateView = window.location.pathname.match('advisor/templates/');

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const onChangeOrder = (key, prevKey, nextKey, action) => () => {
    const recentContent = { ...content };
    const section = recentContent[key];
    const prevSection = prevKey ? recentContent[prevKey] : null;
    const nextSection = nextKey ? recentContent[nextKey] : null;

    if (action === UP_ACTION) {
      section.order -= 1;
      if (prevSection) prevSection.order += 1;
    } else {
      section.order += 1;
      if (nextSection) nextSection.order -= 1;
    }

    setContent(recentContent);
  };

  const onAllowSectionsOrderHandler = () => {
    setAllowSectionsOrder(prevAllowSectionsOrder => !prevAllowSectionsOrder);
  };

  const onSubmitHandler = async () => {
    setLoading(true);
    const settings = { ...(templateSettings || {}), allowSectionsOrder };
    return handleSaveTemplate(content, settings).finally(() => {
      setLoading(false);
      hide();
    });
  };

  useEffect(() => {
    setContent(templateContent);
  }, [JSON.stringify(templateContent)]);

  return (
    <>
      <button type="button" className="btn btn-secondary" onClick={show}>
        Customize Order
      </button>

      {ReactDOM.createPortal(
        <Modal id="customize-order-modal" className="modal-lg" show={isShown} onHidden={hide}>
          <ModalHeader />
          <ModalBody>
            <h4 className="modal-title">Template Customization</h4>

            <div className="sections">
              <div className="section section--locked">
                <div className="section__title">Cover</div>
                <span className="fs-icon-lock lock-icon" />
              </div>

              {Object.entries(content)
                .filter(byNonFixedSections)
                .sort(byTemplateSectionOrder)
                .map(([key, metadata], idx, sections) => {
                  const prevKey = sections[idx - 1] ? sections[idx - 1][0] : null;
                  const nextKey = sections[idx + 1] ? sections[idx + 1][0] : null;
                  const title = metadata.attachment
                    ? metadata.attachmentName
                    : metadata.title || humps.decamelize(key, { separator: ' ' });

                  return (
                    <div
                      key={key}
                      className={cn('section', {
                        'section--attachment': metadata.attachment,
                        'section--hidden': metadata.hidden,
                        'section--signature': metadata.signature
                      })}
                    >
                      <div className="section__title">
                        {metadata.attachment && <i className="fs-icon-attach" />} {title}
                      </div>
                      <div className="section__actions">
                        {metadata.hidden && (
                          <span className="hidden-message">This section is hidden</span>
                        )}
                        {metadata.signature && (
                          <span className="signature-section-message">
                            <SignatureIcon /> Signature section
                          </span>
                        )}
                        {idx !== 0 && (
                          <button
                            type="button"
                            className="btn btn-small btn-outline-primary"
                            onClick={onChangeOrder(key, prevKey, nextKey, UP_ACTION)}
                          >
                            &#9650;
                          </button>
                        )}
                        {idx !== sections.length - 1 && (
                          <button
                            type="button"
                            className="btn btn-small btn-outline-primary"
                            onClick={onChangeOrder(key, prevKey, nextKey, DONW_ACTION)}
                          >
                            &#9660;
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}

              <div className="section section--locked">
                <div className="section__title">Disclosure</div>
                <span className="fs-icon-lock lock-icon" />
              </div>
            </div>

            {isComplianceOrAbove && isTemplateView && (
              <div className="permissions">
                <label htmlFor="allow-sections-order">Allow advisors to:</label>
                <Choice
                  title="Reorder sections"
                  id="allow-sections-order"
                  checked={allowSectionsOrder}
                  toggle={onAllowSectionsOrderHandler}
                />
              </div>
            )}

            <div className="modal-actions">
              <button
                className="btn btn-outline-primary"
                disabled={loading}
                onClick={hide}
                type="button"
              >
                Cancel
              </button>
              <LoadingButton
                className="btn btn-primary"
                disabled={loading}
                loading={loading}
                onClick={onSubmitHandler}
              >
                Save Changes
              </LoadingButton>
            </div>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

CustomizeOrderModal.defaultProps = {
  templateSettings: {}
};

CustomizeOrderModal.propTypes = {
  handleSaveTemplate: PropTypes.func.isRequired,
  templateContent: PropTypes.object.isRequired,
  templateSettings: PropTypes.object
};

export default CustomizeOrderModal;
