import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';

const STRIPE_API_KEY = loadStripe(process.env.STRIPE_API_KEY);
const STRIPE_ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'
    }
  ]
};

const StripeElementsWrapper = ({ children }) => (
  <Elements stripe={STRIPE_API_KEY} options={STRIPE_ELEMENTS_OPTIONS}>
    {children}
  </Elements>
);

StripeElementsWrapper.propTypes = {
  children: PropTypes.element.isRequired
};

export default StripeElementsWrapper;
