import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { range } from 'utils/utils';
import './styles.scss';

const MAX_NUMBER_OF_PAGES_TO_SHOW = 8;

const Pagination = ({ currentPage, goToPage, hasNextPage, hasPrevPage, totalPages }) => {
  const visibleRange = () => {
    if (totalPages <= MAX_NUMBER_OF_PAGES_TO_SHOW) return range(1, totalPages);

    let leftEdge = currentPage - MAX_NUMBER_OF_PAGES_TO_SHOW / 2;
    let rightEdge = currentPage + MAX_NUMBER_OF_PAGES_TO_SHOW / 2;

    if (leftEdge < 1) {
      rightEdge += 1 - leftEdge;
      leftEdge = 1;
    } else if (rightEdge > totalPages) {
      leftEdge -= rightEdge - totalPages;
      rightEdge = totalPages;
    }

    return range(leftEdge, rightEdge);
  };

  return (
    <ul className="pagination flex justify-center">
      <li className={classnames('page-item', { disabled: !hasPrevPage })}>
        <button
          type="button"
          onClick={e => goToPage(currentPage - 1, e)}
          disabled={!hasPrevPage}
          className="page-link"
          aria-label="Previous"
          style={hasPrevPage ? { cursor: 'pointer' } : {}}
        >
          <span aria-hidden="true">«</span>
          <span className="sr-only">Previous</span>
        </button>
      </li>

      {visibleRange().map(number => (
        <li key={number} className={classnames('page-item', { active: currentPage === number })}>
          <button
            type="button"
            onClick={e => goToPage(number, e)}
            className="page-link"
            style={{ cursor: 'pointer' }}
          >
            {number}
          </button>
        </li>
      ))}

      <li className={classnames('page-item', { disabled: !hasNextPage })}>
        <button
          type="button"
          onClick={e => goToPage(currentPage + 1, e)}
          className="page-link"
          disabled={!hasNextPage}
          aria-label="Next"
          style={hasNextPage ? { cursor: 'pointer' } : {}}
        >
          <span aria-hidden="true">»</span>
          <span className="sr-only">Next</span>
        </button>
      </li>
    </ul>
  );
};

Pagination.defaultProps = {
  hasPrevPage: false,
  hasNextPage: false
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  hasPrevPage: PropTypes.bool,
  hasNextPage: PropTypes.bool
};

export default Pagination;
