import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const SelectWrapper = props => {
  const onSelectChange = ({ value }) => {
    const { onChange } = props;
    onChange(value);
  };

  const { header, options, value, styles } = props;
  return (
    <div style={{ ...styles }}>
      {header && (
        <label
          htmlFor={header.toLowerCase().replace(/\s/g, '-')}
          style={{
            fontSize: '16px',
            color: '#212529'
          }}
        >
          {header}
        </label>
      )}
      <Select
        options={options}
        value={options.find(option => option.value === value)}
        onChange={onSelectChange}
        style={{
          height: '45px',
          width: '100%',
          border: '1px solid #d3d6db',
          padding: '0 15px',
          borderRadius: '3px',
          outline: 'none'
        }}
      />
    </div>
  );
};

SelectWrapper.propTypes = {
  header: PropTypes.string,
  options: PropTypes.array.isRequired,
  styles: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

SelectWrapper.defaultProps = {
  styles: {},
  header: ''
};

export default SelectWrapper;
