// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t-modal {
  margin-top: 1rem;
}
.t-modal .header {
  margin-bottom: 2rem;
}
.t-modal .header__title {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
.t-modal .content {
  margin-bottom: 2rem;
}
.t-modal .content__p {
  width: 100%;
  font-size: 1rem;
  text-align: center;
}
.t-modal .footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#approve-template-modal .modal-body {
  padding: 0;
  margin: 1rem;
}
#approve-template-modal .btn {
  min-width: 7rem;
}
#approve-template-modal .fs-icon-exclamation-circle {
  font-size: 1.3rem;
  position: absolute;
  margin-left: -1.6rem;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/templates/approve-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AADI;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;AAGN;AAAE;EACE,mBAAA;AAEJ;AADI;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AAGN;AAAE;EACE,WAAA;EACA,aAAA;EACA,6BAAA;AAEJ;;AAIE;EACE,UAAA;EACA,YAAA;AADJ;AAGE;EACE,eAAA;AADJ;AAGE;EACE,iBAAA;EACA,kBAAA;EACA,oBAAA;AADJ","sourcesContent":[".t-modal {\n  margin-top: 1rem;\n  .header {\n    margin-bottom: 2rem;\n    &__title {\n      width: 100%;\n      font-size: 1.2rem;\n      text-align: center;\n    }\n  }\n  .content {\n    margin-bottom: 2rem;\n    &__p {\n      width: 100%;\n      font-size: 1rem;\n      text-align: center;\n    }\n  }\n  .footer {\n    width: 100%;\n    display: flex;\n    justify-content: space-around;\n  }\n}\n\n// this section rewrites some set styles of these classes\n#approve-template-modal {\n  .modal-body {\n    padding: 0;\n    margin: 1rem;\n  }\n  .btn {\n    min-width: 7rem;\n  }\n  .fs-icon-exclamation-circle {\n    font-size: 1.3rem;\n    position: absolute;\n    margin-left: -1.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
