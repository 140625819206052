/* globals Dropzone */
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ accept, children, maxSize, onDrop, maxFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({ accept, maxSize, onDrop, maxFiles });
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.any.isRequired,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func.isRequired,
  maxFiles: PropTypes.number
};

Dropzone.defaultProps = {
  accept: 'image/jpeg,image/png',
  maxSize: Infinity,
  maxFiles: ''
};

export default Dropzone;
