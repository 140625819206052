import React from 'react';

export default ({ className }) => (
  <svg id="Layer_1" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <title>edit_icon</title>
    <path
      d="M15.14.23a15,15,0,1,0,15,15A15,15,0,0,0,15.14.23ZM8.59,22.87l-1,.25c-.43.1-.65-.11-.56-.53l.82-3.7,3.35,3.35Zm3.6-1.61L8.81,17.9l8.56-8.56,3.37,3.37Zm10.9-10.88-1.29,1.3a.71.71,0,0,0-.1.14L18.28,8.4c.49-.49,1-1,1.48-1.48A1.47,1.47,0,0,1,21.85,7h0c.49.48,1,1,1.48,1.48a1.57,1.57,0,0,1,.35.66v.35A1.86,1.86,0,0,1,23.09,10.38Z"
      transform="translate(-0.14 -0.23)"
    />
  </svg>
);
