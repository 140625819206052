import { CHARGE_LIST } from 'constants/actions';

const initialState = {
  list: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHARGE_LIST:
      return {
        ...state,
        list: action.data
      };
    default:
      return state;
  }
}
