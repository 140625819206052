/* eslint-disable react/no-danger */
/* global STANDARD_DATE_FORMAT */
import cn from 'classnames';
import { EditBox, PageBreak } from 'components/advisor/utils/edit-box';
import 'components/advisor/utils/edit-box/styles.scss';
import Choice from 'components/form/choice';
import QuillField from 'components/utils/wysiwyg';
import humps from 'humps';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import SectionLocked from 'components/advisor/section-locked';
import { SECTION_LOCKED_MAP, sectionIsLocked } from 'components/advisor/section-locked/utils';

class EditBoxText extends EditBox {
  render() {
    const {
      actionsDisabled,
      allowChangeText,
      allowEdit,
      allowHide,
      body,
      children,
      className,
      customActions,
      disableTitleChange,
      editHeader,
      editing,
      fields,
      hidden,
      options,
      placeholder,
      propertyKey
    } = this.props;
    const { authProvider, user } = this.context;
    const admin = authProvider.hasCompliancePermissions(user);
    const customBody = this.getBody();
    const templateView = this.isTemplateView();
    const isTemplateViewWithAdminPermissions = admin && templateView;
    const isDisabledComponent = sectionIsLocked(propertyKey, user);

    // the propertyKey is converted from camelCase to kebab-case
    // to follow HTML attribute naming standard (used in SASS styles)
    const kebabCasePropertyKey = humps.decamelize(propertyKey, { separator: '-' });

    return (
      <>
        {customActions?.breakSection?.value && !hidden && <PageBreak />}
        <section
          id={`${kebabCasePropertyKey}-section`}
          className={cn(
            'edit-box advisor-edit-box-container',
            { 'advisor-edit-box-container--add-template': templateView },
            className,
            { hidden }
          )}
        >
          {!disableTitleChange && (editHeader || editing)
            ? this.editHeader()
            : this.getHeader({ useTooltip: true, actionsDisabled })}

          {!isDisabledComponent && (
            <div className={cn('body', { editing, hidden })}>
              {editing && (
                <>
                  {isTemplateViewWithAdminPermissions && (
                    <QuillField
                      className="body-wysiwyg"
                      disabled={!editing}
                      field={fields.body}
                      options={{ height: 200, ...options }}
                    />
                  )}
                  {!isTemplateViewWithAdminPermissions && (
                    <>
                      <div
                        className={cn('body', { hidden })}
                        dangerouslySetInnerHTML={{
                          __html: allowChangeText ? '' : this.readOnlyContent(body)
                        }}
                      />
                      <QuillField
                        className="body-wysiwyg no-edit"
                        disabled={!editing}
                        field={allowChangeText ? fields.body : fields.prepend}
                        options={{ height: 200, ...options }}
                      />
                    </>
                  )}
                </>
              )}

              {!editing && customBody && (
                <>
                  <div className="body-content" dangerouslySetInnerHTML={{ __html: customBody }} />
                  {placeholder && <div className="placeholder">{placeholder} will be here.</div>}
                </>
              )}

              {children && children}
            </div>
          )}

          {isDisabledComponent && (
            <SectionLocked
              id={SECTION_LOCKED_MAP[propertyKey].portfolioRiskId}
              name={SECTION_LOCKED_MAP[propertyKey].name}
            />
          )}

          {!isDisabledComponent && isTemplateViewWithAdminPermissions && (
            <div className="permissions">
              <span className="permissions__title">Actions Allowed:</span>
              <ul>
                <li>
                  <Choice
                    title="Add more content"
                    checked={allowEdit}
                    disabled={false}
                    toggle={() => this.setPermission({ allowEdit: !allowEdit })}
                  />
                </li>
                <li>
                  <Choice
                    title={`${hidden ? 'Show' : 'Hide'} this section`}
                    checked={allowHide}
                    disabled={false}
                    toggle={() => this.setPermission({ allowHide: !allowHide })}
                  />
                </li>
                <li>
                  <Choice
                    title="Change text"
                    checked={allowChangeText}
                    disabled={false}
                    toggle={() => this.setPermission({ allowChangeText: !allowChangeText })}
                  />
                </li>
                {Object.entries(customActions)
                  .filter(([_, action]) => action.permission)
                  .map(([actionId, action]) => (
                    <li key={`action-${propertyKey}-${actionId}`}>
                      <Choice
                        title={action.permission}
                        checked={action.allowed}
                        disabled={false}
                        toggle={() => this.setPermission({ [actionId]: !action.allowed })}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </section>
      </>
    );
  }
}

EditBoxText.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default compose(
  connect((state, props) => ({
    form: `${props.propertyKey}EditBox`,
    initialValues: { title: props.title }
  })),
  reduxForm({
    fields: ['body', 'prepend', 'title']
  })
)(EditBoxText);
