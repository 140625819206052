import Link from 'components/utils/link';
import React from 'react';

const MARKETPLACE_URL = '/advisor/marketplace';

export const aboutLink = ({ marketplaceID }) => (
  <Link to={`${MARKETPLACE_URL}/${marketplaceID}/about`}>About this model</Link>
);

export const overviewLink = ({ marketplaceID }) => (
  <Link to={`${MARKETPLACE_URL}/${marketplaceID}/overview`} disabled lock>
    Overview
  </Link>
);

export const portfolioRiskLink = ({ marketplaceID }) => (
  <Link to={`${MARKETPLACE_URL}/${marketplaceID}/portfolio-risk`} disabled lock>
    Portfolio Risk
  </Link>
);

export const compareAnalyzeLink = ({ marketplaceID }) => (
  <Link to={`${MARKETPLACE_URL}/${marketplaceID}/compare-analyze`} disabled lock>
    Compare and Analyze
  </Link>
);

export const strategyLink = ({ marketplaceID }) => (
  <Link to={`${MARKETPLACE_URL}/${marketplaceID}/strategy`} disabled lock>
    Strategy
  </Link>
);
