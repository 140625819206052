import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const CashInvestableAssetsReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  return (
    <ReadOnlyQuestion question={question}>
      <p>Cash and investable assets: {formatMoney(question.answer.investment)}</p>
      <p>
        Estimated invested:{' '}
        {question.answer.percentage
          ? `${formatPercentage(question.answer.percentage, 100)} (${formatMoney(
              question.answer.investment * question.answer.percentage
            )})`
          : '-'}
      </p>
    </ReadOnlyQuestion>
  );
};

CashInvestableAssetsReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default CashInvestableAssetsReadOnlyQuestion;
