import PropTypes from 'prop-types';
import MarketProvider from 'providers/market';
import React from 'react';

const CreatePortfolioModalBody = ({
  modelCSVId,
  showCSVUploadModal,
  showManualUploadModal,
  uploadCSVOption,
  type
}) => (
  <div id="CreatePortfolioModalBody">
    <p className="text-center" id="modal-header">
      {type === 'Security'
        ? 'This security does not have underlying holdings. Do you want to add them?'
        : 'Upload model portfolio'}
    </p>
    <p className="text-center option">Please select an option to upload</p>
    <div className="input-section">
      <div className="create-portfolio">
        <div className="row">
          <span>
            Upload your CSV File (Recommended)
            <i className="right-arrow" />
            <a
              key="getcsvtemplate"
              href={MarketProvider.downloadCSVTemplateUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="download-csv-link"
            >
              Download CSV template
            </a>
          </span>
          <input
            id={modelCSVId}
            type="file"
            style={{ display: 'none' }}
            onChange={uploadCSVOption}
            className="hidden"
          />
          <label htmlFor={modelCSVId} className="btn btn-primary" onClick={showCSVUploadModal}>
            Upload CSV
          </label>
        </div>
        <div className="row">
          <span>
            Manually input portfolio on the platform
            <i className="right-arrow" />
          </span>
          <button type="button" className="btn btn-secondary-2" onClick={showManualUploadModal}>
            Input Manually
          </button>
        </div>
      </div>
    </div>
  </div>
);

CreatePortfolioModalBody.defaultProps = {
  type: ''
};

CreatePortfolioModalBody.propTypes = {
  modelCSVId: PropTypes.string.isRequired,
  showCSVUploadModal: PropTypes.func.isRequired,
  showManualUploadModal: PropTypes.func.isRequired,
  uploadCSVOption: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default CreatePortfolioModalBody;
