/* global STANDARD_DATE_FORMAT */
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { ageCalculator } from 'utils/questionnaire';

export const DEFAULT_END_AGE = 100;
export const DEFAULT_ENTITY_END_AGE = 30;
export const TYPE_ANNUAL = 'annual';
export const TYPE_ONCE = 'once';

export const getDefaultDuration = (start, age, isEntityQuestion) =>
  Math.max(1, (isEntityQuestion ? DEFAULT_ENTITY_END_AGE : DEFAULT_END_AGE) - (start + age));

const getEmptyLabels = (n, start) =>
  Array(n - 1)
    .fill()
    .map((_, i) => ({ value: i + start + 1 }));

const getLabels = rawLabels => {
  let labels = rawLabels ?? [];

  // complete the labels with yearly empty marks to make the slider move by 1 year.
  labels = labels.reduce((acum, label, index) => {
    if (index === 0) return [label];
    const last = acum[acum.length - 1];
    const gap = label.value - last.value;
    return gap ? [...acum, ...getEmptyLabels(gap, last.value), label] : [...acum, label];
  }, []);

  return labels;
};

const useWithdrawalRequirement = ({ question, field }) => {
  const refs = question.data?.refs || {};

  const isEntityQuestion = question.data?.is_entity || false;
  const isValidAmount = value => !_.isUndefined(value) && value !== '';

  const initialInvestment =
    refs['liquid-assets'] && refs['percentage-assets-invested']
      ? Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])
      : 0;

  const birthday = refs.birthday || field.birthday.value;
  const age = ageCalculator(birthday);
  const retirement = Number(refs.retirement) || 0;
  refs['retire-in'] = retirement - age;
  const isRetired = refs['retire-in'] <= 0;

  const labels = getLabels(question.data?.labels);
  const isAnnual = field.type.value === TYPE_ANNUAL;
  const defaultAmount = isRetired
    ? initialInvestment
    : initialInvestment / Number(refs['retire-in']);

  const retireStartsIn = useMemo(
    () => field.start.value - age,
    [field.start.value, field.type.value, age]
  );

  const toggleIsAnnual = useCallback(() => {
    const newType = isAnnual ? TYPE_ONCE : TYPE_ANNUAL;
    if (field.type.value !== newType) field.type.onChange(newType);
  }, [isAnnual, field.type]);

  const onAmountChange = useCallback(
    _.debounce(({ floatValue: value }) => {
      if (isValidAmount(value) && value !== field.amount.value) field.amount.onChange(value);
    }, 500),
    [field.amount.value]
  );

  useEffect(() => {
    if (!field.type.value) field.type.autofill(TYPE_ANNUAL);
  }, [field.type.value]);

  useEffect(() => {
    if (!field.amount.touched && !isValidAmount(field.amount.value))
      field.amount.autofill(defaultAmount);
  }, [field.amount.touched, field.amount.value, defaultAmount]);

  useEffect(() => {
    if (!field.start?.value) field.start.autofill(retirement);
  }, [retirement]);

  useEffect(() => {
    // calculates the default withdrawal period based on the retirement question.
    // - start: years from now to the retirement age
    // - end: start + (100 years old - retirement age)
    // - when is not annual, the duration is 0
    if (isAnnual) {
      const start = Math.max(0, (field.start?.value || retirement) - age);
      const duration =
        Number.isInteger(field.duration.value) && field.duration.value > 0
          ? field.duration.value
          : getDefaultDuration(start, age, isEntityQuestion);
      if (duration !== field.duration.value) field.duration.onChange(duration);
    } else field.duration.onChange(0);
  }, [isAnnual]);

  useEffect(() => {
    if (isEntityQuestion && !field.birthday.value)
      field.birthday.autofill(moment().startOf('year').format(STANDARD_DATE_FORMAT));
  }, [isEntityQuestion, field.birthday.touched, field.birthday.value]);

  const amountInputProps = {
    allowNegative: false,
    decimalScale: 0,
    onValueChange: onAmountChange,
    prefix: '$',
    thousandSeparator: true,
    value: field.amount.value
  };

  return {
    age,
    amountInputProps,
    birthday,
    isAnnual,
    isEntityQuestion,
    isRetired,
    labels,
    refs,
    retirement,
    retireStartsIn,
    toggleIsAnnual
  };
};

export default useWithdrawalRequirement;
