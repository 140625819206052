/* global MONTH_YEAR_FORMAT */
import { formatPercentage } from 'utils/utils';

export const TARGET_ANALYTICS = 'target_analytics';
export const RECOMMENDED_ANALYTICS = 'recommended_analytics';
export const BENCHMARK_ANALYTICS = 'benchmark_analytics';

export const getRisksHeaders = (hasBenchmark, benchmarkLabel) => {
  const headers = ['', 'Portfolio Risk', 'Sharpe Ratio', 'Max Drawdown', 'Time to Recover'];
  if (hasBenchmark) {
    headers.push(`Upside Capture of ${benchmarkLabel}`);
    headers.push(`Downside Capture of ${benchmarkLabel}`);
  }
  return headers;
};

// Values beyond +5 or below -3 may signal potential data issues or anomalies.
const sharpeRatioLimits = {
  low: -3,
  high: 5
};

export const getRisksFromSource = (proposal, source, hasBenchmark) => {
  let sharpeRatio = proposal[source].summary.sharpe_ratio
    ? parseFloat(proposal[source].summary.sharpe_ratio).toFixed(2)
    : '-';

  if (
    sharpeRatio !== '-' &&
    (sharpeRatio < sharpeRatioLimits.low || sharpeRatio > sharpeRatioLimits.high)
  )
    sharpeRatio = '-';

  const data = [
    formatPercentage(proposal[source].summary.annualized_volatility),
    sharpeRatio,
    formatPercentage(proposal[source].summary.max_drawdown.value),
    `${proposal[source].summary.max_drawdown.duration} Months`
  ];
  if (hasBenchmark) {
    data.push(formatPercentage(proposal[source].summary.upside_capture));
    data.push(formatPercentage(proposal[source].summary.downside_capture));
  }
  return data;
};
