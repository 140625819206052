/* eslint-disable react/no-unstable-nested-components */
import Choice from 'components/form/choice';
import TabSwitch from 'components/tab-switch';
import Link from 'components/utils/link';
import { SortableHeader } from 'components/utils/react-table';
import TableItemDeleteModal from 'components/utils/table-item-delete-modal';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import ReactTable from 'react-table';
import { getReportUrl } from 'utils/utils';
import { ARCHIVED, DRAFT, IPS_REPORT_TYPE, PUBLISHED, isReportReadOnly } from './constants';
import ProposalOrIpsReportsPropTypes from './types';

const STATUS_OPTIONS = [
  { value: PUBLISHED, label: 'Active' },
  { value: ARCHIVED, label: 'Archived' }
];

const ProposalOrIpsReportsList = ({
  bulkDelete,
  bulkUpdate,
  loading,
  location: { pathname },
  reports,
  router,
  type
}) => {
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [statusFilter, setStatusFilter] = useState(PUBLISHED);

  const data = useMemo(
    () =>
      reports.filter(report => {
        if (statusFilter === PUBLISHED) return report.status !== ARCHIVED;
        return report.status === statusFilter;
      }),
    [JSON.stringify(reports), statusFilter]
  );

  const resetSelection = () => {
    setSelectedAll(false);
    setSelected([]);
  };

  // reset the filter status when the type of reports change
  useEffect(() => {
    setStatusFilter(PUBLISHED);
  }, [type]);

  // reset selected reports when the filter status change
  useEffect(() => {
    resetSelection();
  }, [statusFilter]);

  const generate = () => {
    router.push({ pathname: `${pathname}/new` });
  };

  const selectAllHandler = () => {
    if (selectedAll) setSelected([]);
    else setSelected(data.filter(d => !isReportReadOnly(d.status)).map(d => d.id));
    setSelectedAll(!selectedAll);
  };

  const selectHandler = id => event => {
    event.stopPropagation();
    if (selected.includes(id)) setSelected(selected.filter(s => s !== id));
    else setSelected([...selected, id]);
  };

  const updateStatusHandler = async () => {
    const data = { status: statusFilter === ARCHIVED ? DRAFT : ARCHIVED };
    await bulkUpdate(selected, data);
    resetSelection();
  };

  const deleteHandler = async () => {
    setSubmitting(true);
    await bulkDelete(selected);
    setSubmitting(false);
    setShowDeleteModal(false);
    resetSelection();
  };

  const toggleDeleteModal = status => () => {
    setShowDeleteModal(status);
  };

  const columns = [
    {
      Header: (
        <button
          aria-label="Delete in bulk"
          className="btn btn-transparent btn-trash"
          disabled={!selected.length || submitting}
          onClick={toggleDeleteModal(true)}
          type="button"
        >
          <span className="fs-icon-trash-1 trash-icon" />
        </button>
      ),
      sortable: false,
      columns: [
        {
          Header: () => (
            <Choice
              checked={selectedAll}
              disabled={!data.length}
              id="checkbox-select-all"
              small
              toggle={selectAllHandler}
            />
          ),
          id: 'delete-btn-checkbox',
          width: 60,
          sortable: false,
          accessor: item =>
            isReportReadOnly(item.status) ? null : (
              <Choice
                checked={selected.includes(item.id)}
                disabled={isReportReadOnly(item.status)}
                id={`checkbox-${item.id}`}
                small
                toggle={selectHandler(item.id)}
              />
            )
        }
      ]
    },
    {
      Header: (
        <div className="reports-table__archive-restore">
          <button
            className="btn btn-secondary"
            disabled={!selected.length || submitting}
            onClick={updateStatusHandler}
            type="button"
          >
            {statusFilter === ARCHIVED ? 'Restore' : 'Archive'}
          </button>
        </div>
      ),
      sortable: false,
      columns: [
        {
          Header: <SortableHeader title="Name" />,
          accessor: 'name',
          className: 'td-align-left',
          headerClassName: 'th-align-left',
          minWidth: 80,
          Cell: ({ value: name }) => name
        },
        {
          Header: <SortableHeader title="Status" />,
          accessor: 'status',
          className: 'text-capitalize text-center',
          minWidth: 25,
          Cell: ({ value: status }) => status.replace(/_/g, ' ')
        },
        {
          Header: <SortableHeader title="Created by" />,
          accessor: 'created_by',
          minWidth: 40,
          Cell: ({ value: createdBy }) =>
            createdBy?.user ? `${createdBy.user.first_name} ${createdBy.user.last_name}` : '-'
        },
        {
          Header: <SortableHeader title="Created" />,
          accessor: 'created',
          minWidth: 30,
          Cell: ({ value: created }) => moment(created).format('ll'),
          defaultSortDesc: true
        },
        {
          Header: <SortableHeader title="Last updated" />,
          accessor: 'modified',
          minWidth: 30,
          defaultSortDesc: true,
          Cell: ({ value: modified }) => moment(modified).format('ll')
        },
        {
          Header: null,
          maxWidth: 145,
          defaultSortDesc: false,
          Cell: ({ original: report }) => (
            <div className="reports-table__actions">
              <Link
                to={`${pathname}/${report.id}?mode=read-only`}
                className="btn btn-outline-primary btn-small"
              >
                Open
              </Link>
              <a
                className="btn btn-secondary btn-small font-weight-normal"
                target="_blank"
                rel="noopener noreferrer"
                href={getReportUrl(report)}
                download
              >
                PDF
              </a>
            </div>
          )
        }
      ]
    }
  ];

  return (
    <div id="proposal-or-ips-reports-list">
      <div className="header">
        <TabSwitch options={STATUS_OPTIONS} onChange={setStatusFilter} value={statusFilter} />
        <button type="button" className="btn btn-primary" onClick={generate}>
          Generate a new {type}
        </button>
      </div>

      <ReactTable
        className="reports-table"
        columns={columns}
        data={data}
        defaultSorted={[{ id: 'modified', desc: true }]}
        loading={loading}
        minRows={0}
        noDataText="No Reports."
        resizable={false}
        showPagination={false}
      />

      <TableItemDeleteModal
        show={showDeleteModal}
        submitting={submitting}
        items={selected}
        onHide={toggleDeleteModal(false)}
        onDelete={deleteHandler}
        verb="Delete"
        label={`${type === IPS_REPORT_TYPE ? 'IPS' : 'Proposal'} Report`}
      />
    </div>
  );
};

ProposalOrIpsReportsList.propTypes = {
  bulkDelete: PropTypes.func.isRequired,
  bulkUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  reports: PropTypes.arrayOf(ProposalOrIpsReportsPropTypes).isRequired,
  router: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default withRouter(ProposalOrIpsReportsList);
