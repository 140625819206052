import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { stripTags } from 'utils/questionnaire';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const GoalApproachQuestionEmptyQuestion = ({ questions, question }) => {
  const marginTopStyle = question?.description ? { marginTop: 0 } : { marginTop: 15 };

  const goals = question.data?.goals ? (
    question.data.goals.map(option => (
      <View style={styles.mark}>
        <View style={styles.markCheck} />
        <Text style={styles.markText}>{stripTags(option.text || option.value)}</Text>
      </View>
    ))
  ) : (
    <Text />
  );
  const approaches = question.data?.approaches ? (
    question.data.approaches.map(option => (
      <View style={styles.mark}>
        <View style={styles.markCheck} />
        <Text style={styles.markText}>{stripTags(option.text || option.value)}</Text>
      </View>
    ))
  ) : (
    <Text />
  );

  return (
    <ReportEmptyQuestion questions={questions} question={question} withDescription>
      <Text style={[styles.choicesSubtitle, marginTopStyle]}>Goal for the investment:</Text>
      <View style={styles.choices}>{goals}</View>
      <Text style={[styles.choicesSubtitle, { marginTop: 10 }]}>Approach to take:</Text>
      <View style={styles.choices}>{approaches}</View>
    </ReportEmptyQuestion>
  );
};

GoalApproachQuestionEmptyQuestion.propTypes = propTypes;

export default GoalApproachQuestionEmptyQuestion;
