// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scroll-to-top {
  margin-top: 10px;
}
#scroll-to-top .scorll-to-top__button {
  display: flex;
  padding: 2px 12px 0px 6px;
  gap: 10px;
  align-items: center;
  font-size: 12px;
}
#scroll-to-top span {
  height: 34px;
  font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/scroll-cards-index/scroll-to-top/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAEE;EACE,aAAA;EACA,yBAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAGE;EACE,YAAA;EACA,eAAA;AADJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#scroll-to-top {\n  margin-top: 10px;\n  .scorll-to-top__button {\n    display: flex;\n    padding: 2px 12px 0px 6px;\n    gap: 10px;\n    align-items: center;\n    font-size: 12px;\n  }\n\n  span {\n    height: 34px;\n    font-size: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
