/* eslint-disable camelcase */
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { MICROSOFT_SOCIAL_PROVIDER } from '../constants';
import createMsalInstance, { loginRequest } from './config';

const MicrosoftSignin = ({ onLogin, setProcessing, setSocialProvider, trustedDeviceId }) => {
  const { authProvider } = useContext(AuthenticationContext);

  const msalInstance = createMsalInstance();

  const handleLogin = () => {
    setProcessing(true);
    msalInstance
      .loginPopup(loginRequest)
      .then(({ accessToken }) => {
        setSocialProvider(MICROSOFT_SOCIAL_PROVIDER, accessToken);
        authProvider
          .loginWithMicrosoft({ access_token: accessToken, trusted_device_id: trustedDeviceId })
          .then(onLogin({}))
          .finally(() => {
            setProcessing(false);
          });
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  return (
    <div id="microsoft-signin">
      <button type="button" onClick={handleLogin}>
        <img src="/img/sso/microsoft-logo.svg" alt="Microsoft" />
        Sign in with Microsoft
      </button>
    </div>
  );
};

MicrosoftSignin.defaultProps = {
  trustedDeviceId: undefined
};

MicrosoftSignin.propTypes = {
  onLogin: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  setSocialProvider: PropTypes.func.isRequired,
  trustedDeviceId: PropTypes.string
};

export default MicrosoftSignin;
