import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useRef } from 'react';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import RiskAnalysisPositions from 'components/advisor/risk-analysis/risk-analysis-target/risk-analysis-positions';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import UnderlyingHoldingForm from './form';
import UnderlyingHoldingsEditModal, { modes } from './edit';
import { SECURITY_TARGET_TYPE } from '../../constants';
import './styles.scss';

const SecurityUnderlyingHolding = ({
  marketStore,
  params,
  security,
  securitiesPositions,
  currentSecurityUnderlyingHoldings,
  updateStep
}) => {
  const { customSecurityProvider } = useContext(AdvisorContext);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [preparing, setPreparing] = useState(false);

  const editModalRef = useRef(null);

  const DELAY = 500;
  const securityID = params?.id;

  const hideEditModal = () => {
    setShowEditModal(false);
    setLoadingRequest(false);
    setPreparing(false);
    if (editModalRef.current) editModalRef.current.hide();
  };

  useEffect(() => {
    setLoadingRequest(true);
    if (security)
      customSecurityProvider
        .getUnderlyingHoldings(securityID)
        .finally(() => setLoadingRequest(false));
    return () => {
      customSecurityProvider.clearUnderlyingHoldings();
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentSecurityUnderlyingHoldings)) setPreparing(true);
    setTimeout(() => {
      setShowEditModal(false);
      setPreparing(false);
    }, DELAY);
  }, [JSON.stringify(currentSecurityUnderlyingHoldings)]);

  const isLoading = () => {
    if (_.isEmpty(currentSecurityUnderlyingHoldings)) {
      setLoadingRequest(true);
      setTimeout(() => {
        setLoadingRequest(false);
      }, DELAY);
    }
  };

  if (loadingRequest) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="security-underlying-holding-container">
      <UnderlyingHoldingForm
        isCreatePage={!securityID}
        isLoading={isLoading}
        updateStep={updateStep}
      />
      {preparing ? (
        <LoadingPrismDataMessage message="Preparing Underlying holdings Porfolio" inset />
      ) : (
        <div>
          {!_.isEmpty(currentSecurityUnderlyingHoldings) && (
            <div className="underlying-holding--wrapper">
              <div className="header">
                <button
                  className="btn btn-secondary create-button"
                  onClick={() => {
                    setShowEditModal(true);
                    setPreparing(true);
                  }}
                  type="button"
                >
                  Edit
                </button>
              </div>
              <RiskAnalysisPositions
                portfolio={currentSecurityUnderlyingHoldings}
                type={SECURITY_TARGET_TYPE}
              />
              <div className="security-type-concentration-section box-container ">
                <div className="box-heading">
                  <span>Security Type Concentration</span>
                </div>
                <SecurityTypeConcentration
                  id={`${SECURITY_TYPE_CONCENTRATION_CHART_ID}-${currentSecurityUnderlyingHoldings.id}`}
                  positions={currentSecurityUnderlyingHoldings.positions}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        id="modelEditModal"
        className="modal-lg"
        show={showEditModal}
        onHidden={hideEditModal}
        ref={editModalRef}
      >
        <ModalHeader />
        <ModalBody>
          <UnderlyingHoldingsEditModal
            hideEditModel={hideEditModal}
            marketStore={marketStore}
            mode={modes.UPDATE}
            model={currentSecurityUnderlyingHoldings}
            positions={securitiesPositions}
            security={security}
          />
        </ModalBody>
      </Modal>
      <Disclosure />
    </div>
  );
};

SecurityUnderlyingHolding.defaultProps = {
  securitiesPositions: [],
  updateStep: null
};

SecurityUnderlyingHolding.propTypes = {
  marketStore: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
  currentSecurityUnderlyingHoldings: PropTypes.object.isRequired,
  securitiesPositions: PropTypes.array,
  updateStep: PropTypes.func
};

const mapStateToProps = state => ({
  marketStore: state.market,
  security: state.customSecurity.currentSecurity,
  securitiesPositions: state.market.securities.positions,
  currentSecurityUnderlyingHoldings: state.customSecurity.currentSecurityUnderlyingHoldings
});

const SecurityUnderlyingHoldingWithOutRout = withRouter(SecurityUnderlyingHolding);

export default connect(mapStateToProps)(SecurityUnderlyingHoldingWithOutRout);
