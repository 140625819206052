import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { modes } from 'components/advisor/models/edit';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import {
  CLICK_UPLOAD_MODEL_PORTFOLIO_CSV,
  CLICK_UPLOAD_MODEL_PORTFOLIO_MANUAL
} from 'constants/actstream';

import CreatePortfolioModalBody from './body';
import './styles.scss';

export class CreatePortfolioModal extends Component {
  constructor(props) {
    super(props);
    this.onHideModal = this.onHideModal.bind(this);
    this.showManualUploadModal = this.showManualUploadModal.bind(this);
    this.showCSVUploadModal = this.showCSVUploadModal.bind(this);
    this.routeOnClick = this.routeOnClick.bind(this);
  }

  routeOnClick(to) {
    this.modal.hide();
    const { routerActions } = this.props;
    routerActions.push(to);
  }

  onHideModal() {
    const { onHide } = this.props;
    onHide();
  }

  showCSVUploadModal() {
    const { actionProvider } = this.context;
    actionProvider.slack({ verb: CLICK_UPLOAD_MODEL_PORTFOLIO_CSV });
  }

  showManualUploadModal() {
    const { actionProvider } = this.context;
    const { showManualModal } = this.props;
    actionProvider.slack({ verb: CLICK_UPLOAD_MODEL_PORTFOLIO_MANUAL });
    this.onHideModal();
    showManualModal({}, modes.CREATE);
  }

  render() {
    const { show, uploadCSVOption, modelCSVId } = this.props;

    return (
      <Modal
        id="create-portfolio-modal"
        ref={c => (this.modal = c)}
        className="modal-lg"
        show={show}
        onHidden={this.onHideModal}
      >
        <ModalHeader />
        <ModalBody>
          <CreatePortfolioModalBody
            uploadCSVOption={uploadCSVOption}
            modelCSVId={modelCSVId}
            showCSVUploadModal={this.showCSVUploadModal}
            showManualUploadModal={this.showManualUploadModal}
          />
        </ModalBody>
      </Modal>
    );
  }
}

CreatePortfolioModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

CreatePortfolioModal.contextTypes = {
  actionProvider: PropTypes.object.isRequired
};

export default CreatePortfolioModal;
