import ComplianceSurveillanceTable from 'components/advisor/compliance-surveillance/list';
import ComplianceSurveillanceSummary from 'components/advisor/compliance-surveillance/summary';
import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import withSubscription from 'hocs/subscription-validation';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './styles.scss';

const ComplianceSurveillance = ({ advisors, stats }) => {
  const { advisorProvider, companyProvider } = useContext(AdvisorContext);

  useEffect(() => {
    companyProvider.es.getStats();
    advisorProvider.es.list();
  }, []);

  if (!advisors || !stats) return <SpinnerLoader spinnerLoading />;

  return (
    <div className="company-surveillance-container">
      <div className="company-surveillance-list-view">
        <div className="company-surveillance-wrapper">
          <h2>Compliance Surveillance</h2>
          <ComplianceSurveillanceSummary stats={stats} />
          <ComplianceSurveillanceTable advisors={advisors} />
          <Disclosure />
        </div>
      </div>
    </div>
  );
};

ComplianceSurveillance.propTypes = {
  advisors: PropTypes.array,
  stats: PropTypes.object
};

ComplianceSurveillance.defaultProps = {
  advisors: [],
  stats: {}
};

ComplianceSurveillance.contextTypes = {
  advisorProvider: PropTypes.object.isRequired,
  companyProvider: PropTypes.object.isRequired
};

export default compose(
  withSubscription({
    plan: 'business',
    id: 'surveillance',
    name: 'Compliance Surveillance'
  }),
  connect(state => ({
    advisors: state.advisors.esList,
    stats: state.company.stats
  }))
)(ComplianceSurveillance);
