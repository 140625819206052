import {
  IndicatorsContainer,
  MenuList,
  Option,
  SingleValue
} from 'components/advisor/generic-selector';
import GenericModal from 'components/generic-modal';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropType from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import AsyncSelect from 'react-select/async';
import './styles.scss';

const normalizeInvestorOptions = (investors, currentInvestor) => {
  let investorsMapped;
  if (investors && investors.length)
    investorsMapped = investors.reduce((filtered, investor) => {
      if (investor.id !== currentInvestor.id)
        filtered.push({
          value: investor.id,
          label: investor.name,
          score: investor.prism_overall ? investor.prism_overall.toFixed(1) : null
        });
      return filtered;
    }, []);

  return investorsMapped;
};

export const MoveAccounts = ({ isOpen, onRequestClose, moveAccounts, currentInvestor }) => {
  const { investorProvider, isProspectSection, prospectProvider } = useContext(AdvisorContext);
  const [selectedInvestor, setSelectedInvestor] = useState({});

  const getClientData = provider => (q, callback) =>
    setTimeout(() => {
      provider.es.list({ search: q }).then(({ results }) => {
        const normalizedOptions = normalizeInvestorOptions(results, currentInvestor);
        callback(normalizedOptions);
      });
    }, 500);
  const getInvestors = getClientData(investorProvider);
  const getProspects = getClientData(prospectProvider);

  // It's necessary for useCallback to have separate contacts for investors and prospects, otherwise, it misbehaves.
  const delayedInvestorQuery = useCallback(_.debounce(getInvestors, 500));
  const delayedProspectQuery = useCallback(_.debounce(getProspects, 500));

  const investorIsSelected = selectedInvestor && selectedInvestor.value;

  return (
    <GenericModal isOpen={isOpen} onRequestClose={onRequestClose} className="move-accounts">
      <h2 className="title" data-testid="contact-title">
        Assign account(s) to another client
      </h2>
      <h3>Select a client to assign</h3>
      <AsyncSelect
        className="investor-select"
        components={{ MenuList, Option, SingleValue, IndicatorsContainer }}
        isSearchable
        loadOptions={isProspectSection ? delayedProspectQuery : delayedInvestorQuery}
        onChange={elem => setSelectedInvestor(elem)}
        placeholder="Select an investor"
      />
      {investorIsSelected && (
        <div className="warning">
          <i className="fs-icon-exclamation-circle warning-icon" />
          You are moving selected account(s) to above client.
        </div>
      )}
      <div className="actions">
        <button type="button" className="btn btn-secondary-2" onClick={onRequestClose}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!investorIsSelected}
          onClick={() => moveAccounts(selectedInvestor.value)}
        >
          Submit
        </button>
      </div>
    </GenericModal>
  );
};

MoveAccounts.propTypes = {
  isOpen: PropType.bool,
  onRequestClose: PropType.func.isRequired,
  currentInvestor: PropType.object.isRequired,
  moveAccounts: PropType.func.isRequired
};

MoveAccounts.defaultProps = {
  isOpen: false
};

export default MoveAccounts;
