// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treemap-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.treemap-chart .treemap-chart__legend {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 0.85rem;
  gap: 20px;
}
.treemap-chart .treemap-chart__legend > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.treemap-chart .treemap-chart__legend .legend__dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/charts/treemap-chart/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAAN","sourcesContent":[".treemap-chart {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n\n  .treemap-chart__legend {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n    font-size: 0.85rem;\n    gap: 20px;\n\n    & > div {\n      display: flex;\n      align-items: center;\n      gap: 5px;\n    }\n\n    .legend__dot {\n      width: 12px;\n      height: 12px;\n      border-radius: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
