import classnames from 'classnames';
import Plans from 'components/plans';
import { CUSTOMER_SUPPORT_EMAIL } from 'constants/contact';
import moment from 'moment';
import PropTypes from 'prop-types';
import PriceProvider from 'providers/prices';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getPriceName } from 'utils/billing';
import { capFirstChar, withCurrencyFormat } from 'utils/utils';
import { AdvisorContext } from '../..';
import UpdatePricePreviewModal from '../update-price-preview';
import './styles.scss';

const BillingSummarySection = ({ children, fullSize, title }) => (
  <div className={classnames('billing-summary-container__item', { 'full-size': fullSize })}>
    <div className="billing-summary-container__item__header">{title}</div>
    <div className="billing-summary-container__item__content">{children}</div>
  </div>
);

BillingSummarySection.defaultProps = {
  fullSize: false
};

BillingSummarySection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  fullSize: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export const BillingSummary = ({ prices, priceProvider, subscription, upcomingInvoice }) => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [upgrading, setUpgrading] = useState(false);
  const [showUpgradePreview, setShowUpgradePreview] = useState(false);
  const [targetPrice, setTargetPrice] = useState(null);
  const [invoicePreview, setInvoicePreview] = useState(null);

  const { subscriptionProvider } = useContext(AdvisorContext);

  const toggleUpgradePlan = () => {
    setShowUpgrade(!showUpgrade);
  };

  const onSelectPrice = async price => {
    const { data: invoicePreview, error } = await subscriptionProvider.previewInvoice({
      price: price.id
    });
    if (error) {
      toast.error(() => <div>Sorry, something went wrong upgrading your plan.</div>);
      return;
    }
    setTargetPrice(price);
    setInvoicePreview(invoicePreview);
    setShowUpgradePreview(true);
  };

  const onCancelPrice = () => {
    setShowUpgradePreview(false);
    setInvoicePreview(null);
    setTargetPrice(null);
  };

  const onConfirmPrice = async price => {
    setUpgrading(true);
    const { error } = await subscriptionProvider.update(price);
    if (error) {
      onCancelPrice();
      toast.error(() => <div>{error.message}</div>);
    } else {
      onCancelPrice();
      toast.success(() => <div>Your subscription has been updated.</div>);
      window.location.reload();
    }
    setUpgrading(false);
  };

  useEffect(() => {
    priceProvider.list();
    subscriptionProvider.getUpcomingInvoice();
  }, []);

  const priceRecurringInterval = subscription.price.recurring?.interval;
  const plansToUpgrade = prices.filter(p => p.upgrade_allowed);
  const canUpgrade = !!plansToUpgrade.length;
  const upcomingSubscriptionItem = upcomingInvoice
    ? upcomingInvoice.items.find(i => !i.proration)
    : null;

  return (
    <div id="BillingSummary">
      <div className="billing-summary-container">
        <BillingSummarySection title="Current plan">
          {getPriceName(subscription.price)}
        </BillingSummarySection>
        <BillingSummarySection title="Status">
          <>
            {capFirstChar(subscription.status)}
            {canUpgrade && (
              <button
                type="button"
                className="btn btn-link btn-upgrade-plan"
                onClick={toggleUpgradePlan}
              >
                Upgrade {showUpgrade ? '▲' : '▼'}
              </button>
            )}
          </>
        </BillingSummarySection>
        {showUpgrade && canUpgrade && (
          <div className="upgrade-plan-container">
            <Plans plans={plansToUpgrade} onSelectPrice={onSelectPrice} upgrading />
          </div>
        )}
        <BillingSummarySection title="Next payment">
          {upcomingInvoice ? withCurrencyFormat(upcomingInvoice.total) : '-'}
        </BillingSummarySection>
        <BillingSummarySection title="Next billing date">
          {upcomingInvoice ? moment(upcomingInvoice.next_payment_attempt).format('LL') : '-'}
        </BillingSummarySection>
        <BillingSummarySection title="Number of users">
          {upcomingSubscriptionItem ? upcomingSubscriptionItem.quantity : '-'}
        </BillingSummarySection>
        {upcomingInvoice?.discount?.promotion_code && (
          <BillingSummarySection title="Promo Code" fullSize={priceRecurringInterval !== 'year'}>
            {upcomingInvoice.discount.promotion_code}
          </BillingSummarySection>
        )}
      </div>
      <p className="manage-subscription">
        Contact{' '}
        <a
          href={`mailto:${CUSTOMER_SUPPORT_EMAIL}?subject=I want to make changes to my StratiFi subscription`}
        >
          {CUSTOMER_SUPPORT_EMAIL}
        </a>{' '}
        if you need help managing your subscription
      </p>
      <UpdatePricePreviewModal
        show={showUpgradePreview}
        content={{ invoicePreview, targetPrice, currentPrice: subscription.price }}
        onConfirm={onConfirmPrice}
        onCancel={onCancelPrice}
        processing={upgrading}
      />
    </div>
  );
};

BillingSummary.defaultProps = {
  upcomingInvoice: null,
  user: null
};

BillingSummary.propTypes = {
  priceProvider: PropTypes.object.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object).isRequired,
  subscription: PropTypes.object.isRequired,
  upcomingInvoice: PropTypes.object,
  user: PropTypes.object
};

export default connect(
  state => ({
    prices: state.prices.list,
    subscription: state.subscription.current,
    upcomingInvoice: state.subscription.upcomingInvoice
  }),
  dispatch => ({
    priceProvider: new PriceProvider({ dispatch })
  })
)(BillingSummary);
