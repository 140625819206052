import TopHoldingsDistribution from 'components/advisor/proposal/securities/top-holdings-distribution';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';

const ProposalTopHoldings = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  proposalType,
  setMetadata
}) => {
  const {
    proposalProvider,
    user: {
      advisor: {
        company: { top_holdings_enabled: topHoldingsEnabled }
      }
    }
  } = useContext(AdvisorContext);

  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata,
    isDisabled: !topHoldingsEnabled
  });

  const setCollapsedTopHoldings = data => {
    proposalProvider.setCollapsedTopHoldings(data);
  };

  return (
    <EditBoxText {...proposalMetadata}>
      <TopHoldingsDistribution
        benchmark={proposal.benchmark}
        benchmarkName={proposal.benchmark_label}
        isDraft={proposal?.target?.is_draft}
        proposalType={proposalType}
        recommended={proposal.recommended}
        recommendedName={proposal.recommended_label}
        setCollapsedTopHoldings={setCollapsedTopHoldings}
        start={proposal.start}
        startingValue={proposal.starting_value}
        target={proposal.target}
        targetName={proposal.target_label}
      />
    </EditBoxText>
  );
};

ProposalTopHoldings.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalTopHoldings;
