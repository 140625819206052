// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ClientSelectAccounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  padding: 40px;
}
#ClientSelectAccounts .spinner {
  margin-top: 60px;
}
#ClientSelectAccounts h2 {
  font-size: 1.1rem;
  text-align: center;
}
#ClientSelectAccounts .accounts {
  margin: 30px;
}
#ClientSelectAccounts .accounts > div {
  display: flex;
  margin-top: 15px;
}
#ClientSelectAccounts .accounts > div:first-child {
  margin-top: 0;
}
#ClientSelectAccounts .accounts > div > * {
  line-height: 30px;
}
#ClientSelectAccounts .accounts > div .select-account {
  margin: 0;
}
#ClientSelectAccounts .accounts > div .select-account .c-input.c-checkbox {
  margin-right: 15px;
}
#ClientSelectAccounts .accounts > div .account-value {
  margin-left: 15px;
}
#ClientSelectAccounts .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#ClientSelectAccounts .actions .btn {
  min-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/client-select-accounts-modal/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,iBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAII;EACE,aAAA;EACA,gBAAA;AAFN;AAIM;EACE,aAAA;AAFR;AAKM;EACE,iBAAA;AAHR;AAMM;EACE,SAAA;AAJR;AAMQ;EACE,kBAAA;AAJV;AAQM;EACE,iBAAA;AANR;AAWE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AATJ;AAWI;EACE,gBAAA;AATN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#ClientSelectAccounts {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 200px;\n  padding: 40px;\n\n  .spinner {\n    margin-top: 60px;\n  }\n\n  h2 {\n    font-size: 1.1rem;\n    text-align: center;\n  }\n\n  .accounts {\n    margin: 30px;\n    & > div {\n      display: flex;\n      margin-top: 15px;\n\n      &:first-child {\n        margin-top: 0;\n      }\n\n      > * {\n        line-height: 30px;\n      }\n\n      .select-account {\n        margin: 0;\n\n        .c-input.c-checkbox {\n          margin-right: 15px;\n        }\n      }\n\n      .account-value {\n        margin-left: 15px;\n      }\n    }\n  }\n\n  .actions {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    .btn {\n      min-width: 150px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
