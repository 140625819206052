import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  LIGHT_GRAPHITE_COLOR,
  RISK_GREEN_COLOR,
  RISK_RED_COLOR
} from 'reports/base/styles';

const styles = StyleSheet.create({
  separator: {
    flexGrow: 1,
    paddingBottom: 5,
    marginVertical: 10,
    borderBottomWidth: '0.1mm',
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR
  },
  scenarioDescription: { ...BASE_TABLE_ROW, marginTop: 10 },
  description: { ...BASE_TABLE_CELL, flexBasis: 200 },
  upText: { color: RISK_GREEN_COLOR },
  downText: { color: RISK_RED_COLOR },
  boldText: { fontWeight: 'bold' },
  upsideDownsideLegend: { ...BASE_TABLE_CELL, justifyContent: 'flex-start', alignItems: 'center' },
  upsideDownsideTitle: {
    ...BASE_TABLE_ROW,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 8
  },
  downsideLegendBox: { width: 8, height: 8, marginRight: 3, backgroundColor: RISK_RED_COLOR },
  upsideLegendBox: { width: 8, height: 8, marginRight: 3, backgroundColor: RISK_GREEN_COLOR }
});

export default styles;
