import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import './styles.scss';

const DATE_FORMAT = 'lll';

const TargetTimestamps = ({
  createdAt,
  integrationLinkProvider,
  integrationLinkUpdatedAt,
  prismUpdatedAt,
  toleranceUpdatedAt
}) => (
  <div className="target-timestamps">
    <div className="target-timestamps__created">
      <img src="/img/pending_button.svg" alt="" />
      <span>Created:</span> {moment(createdAt).format(DATE_FORMAT)}
    </div>
    {integrationLinkProvider && integrationLinkUpdatedAt && (
      <div className="target-timestamps__synced_with">
        <img src="/img/pending_button.svg" alt="" />
        <span>Synced with {integrationLinkProvider}:</span>{' '}
        {moment(integrationLinkUpdatedAt).format(DATE_FORMAT)}
      </div>
    )}
    {prismUpdatedAt && (
      <div className="target-timestamps__prism-updated">
        <img src="/img/pending_button.svg" alt="" />
        <span>PRISM Updated:</span> {moment(prismUpdatedAt).format(DATE_FORMAT)}
      </div>
    )}
    {toleranceUpdatedAt && (
      <div className="target-timestamps__tolerance-updated">
        <img src="/img/pending_button.svg" alt="" />
        <span>Tolerance Updated:</span> {moment(toleranceUpdatedAt).format(DATE_FORMAT)}
      </div>
    )}
  </div>
);

TargetTimestamps.defaultProps = {
  integrationLinkProvider: '',
  integrationLinkUpdatedAt: '',
  prismUpdatedAt: '',
  toleranceUpdatedAt: ''
};

TargetTimestamps.propTypes = {
  createdAt: PropTypes.string.isRequired,
  integrationLinkProvider: PropTypes.string,
  integrationLinkUpdatedAt: PropTypes.string,
  prismUpdatedAt: PropTypes.string,
  toleranceUpdatedAt: PropTypes.string
};

export default TargetTimestamps;
