// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rta__autocomplete {
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-textarea/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF","sourcesContent":[".rta__autocomplete {\n  z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
