import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class StepProgress extends Component {
  static propTypes = {
    currentStep: PropTypes.number.isRequired,
    stepsCount: PropTypes.number.isRequired,
    isLast: PropTypes.bool
  };

  static defaultProps = {
    isLast: false
  };

  get lines() {
    const { currentStep, stepsCount, isLast } = this.props;
    const lines = [];
    const widthUnit = 100 / (stepsCount * 2);

    for (let i = 0; i < stepsCount + 1; ++i) {
      lines.push(
        <div
          key={i}
          style={{ width: `${widthUnit * (i === 0 || i === stepsCount ? 1 : 2)}%` }}
          className={classnames('progress-line', {
            passed: i < currentStep,
            active: i === currentStep || (isLast && i === stepsCount)
          })}
        />
      );
    }

    return lines;
  }

  get rounds() {
    const { currentStep, stepsCount } = this.props;
    const rounds = [];
    const stepStyling = { width: `${100 / stepsCount}%` };

    for (let i = 0; i < stepsCount; ++i) {
      rounds.push(
        <div key={i} style={stepStyling} className="progress-step">
          <div
            className={classnames('progress-step-number', {
              passed: i < currentStep,
              active: i === currentStep
            })}
          >
            {i + 1}
          </div>
        </div>
      );
    }

    return rounds;
  }

  render() {
    return (
      <div className="step-progress">
        <div className="progress-lines">{this.lines}</div>
        <div className="progress-steps">{this.rounds}</div>
      </div>
    );
  }
}
