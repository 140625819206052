import PropTypes from 'prop-types';
import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR, HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR } from '../constants';

const HouseholdDriftOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR, HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR]}
    filters={filters}
    label="Household Drift Overall"
  >
    <MinMaxSliderFilter
      filters={filters}
      maxAttr={HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR}
      minAttr={HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

HouseholdDriftOverallFilter.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
  setRequiresPageIndexReset: PropTypes.func.isRequired
};

HouseholdDriftOverallFilter.defaultProps = {
  filters: {}
};

export default HouseholdDriftOverallFilter;
