import cn from 'classnames';
import { MODEL_PROPOSAL_TYPE, SECURITY_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import CoverSection from 'components/advisor/templates/sections/cover';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes } from '../types';

const ProposalCoverSection = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposalType,
  setMetadata
}) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  return (
    <CoverSection
      {...proposalMetadata}
      className={cn(metadata.className)}
      showPreparedFor={
        proposalType !== MODEL_PROPOSAL_TYPE && proposalType !== SECURITY_PROPOSAL_TYPE
      }
    />
  );
};

ProposalCoverSection.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalCoverSection;
