import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSection from '../custom';
import styles from './styles';

const BaseSection = ({ body, breakSection, children, debug, style, title, wrap }) => (
  <View style={[styles.wrapper, style]} break={breakSection} debug={debug} wrap={wrap}>
    {(title || body) && <CustomSection title={title} body={body} />}
    <View style={styles.body}>{children}</View>
  </View>
);

BaseSection.propTypes = {
  body: PropTypes.string,
  breakSection: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  debug: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  wrap: PropTypes.bool
};

BaseSection.defaultProps = {
  body: '',
  breakSection: false,
  debug: false,
  style: {},
  title: '',
  wrap: true
};

export default BaseSection;
