import {
  teamColumnActions,
  teamColumnHeader,
  teamColumnHiden,
  teamColumnExpander,
  teamColumnEdit,
  teamColumnDelete,
  teamColumnRepCodes
} from './custom';

const columns = (enabledEdit, enabledActions, security) => [
  !enabledActions
    ? {
        width: 50,
        id: 'expander',
        expander: true,
        Expander: teamColumnExpander
      }
    : { width: 0, id: 'expander', expander: true, Expander: teamColumnHiden },
  {
    id: 'teamName',
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' },
    headerClassName: 'team-column',
    Header: teamColumnHeader('Name'),
    accessor: team => team.name
  },
  {
    id: 'repCodes',
    className: 'team-column',
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' },
    Header: teamColumnHeader('Rep-codes'),
    accessor: team => team.rep_codes.join(', '),
    Cell: ({ original: team }) => teamColumnRepCodes(team.rep_codes)
  },
  {
    id: 'externalID',
    Header: teamColumnHeader('External ID'),
    accessor: team => team.external_id,
    Cell: ({ original: team }) => team?.external_id || '-'
  },
  !enabledActions
    ? {
        id: 'totalAdvisors',
        Header: teamColumnHeader('Total Advisors'),
        Cell: ({ original: team }) => team?.team_memberships?.length,
        accessor: team => team?.team_memberships?.length
      }
    : {
        Header: 'Actions',
        id: 'actions',
        width: 150,
        Cell: ({ original: team }) => teamColumnActions(security, team)
      },
  ...(enabledEdit
    ? [
        {
          Header: 'Edit',
          id: 'edit',
          width: 70,
          Cell: teamColumnEdit
        },
        {
          Header: 'Delete',
          id: 'delete',
          width: 70,
          Cell: teamColumnDelete
        }
      ]
    : [])
];

export default columns;
