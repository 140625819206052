import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import Card from 'components/card';
import ExpandableButton from 'components/expandable-button';
import SpinnerLoader from 'components/performance-spinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { trackAmplitudeEvent } from 'utils/tracking';
import { isNum } from 'utils/utils';
import AdjustScore from '../adjust-score';
import Legend from '../legend';
import RiskRanges from '../risk-ranges';
import ScoreContext from '../score-context';
import './styles.scss';

const ScoreSummary = ({
  flavor,
  investors,
  mode,
  questionnaireId,
  scoreSummary,
  onUpdateScore,
  setDiscussionTopicsStep
}) => {
  const [investor] = investors;

  useEffect(() => {
    if (scoreSummary?.overall && !investor.is_default)
      trackAmplitudeEvent('rtq.finished', { score: scoreSummary?.overall });
  }, []);

  const [adjustScore, setAdjustScore] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showRiskLegend, setShowRiskLegend] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const isMarketing = params.get('source') === 'marketing';

  const account = mode.startsWith('account') ? investor?.accounts?.[0] : null;
  const investorName = investor?.full_name;
  const possessiveForm = investorName.endsWith('s') ? `${investorName}'` : `${investorName}'s`;

  const toggleRiskLenged = () => {
    setShowRiskLegend(prevShowRiskLeged => !prevShowRiskLeged);
  };

  const overall = scoreSummary?.overall;
  if (!overall) return null;

  const adjustQuestionnaireScore = async overall => {
    setIsSaving(true);

    onUpdateScore(questionnaireId, { adjusted_score: overall }, investor.is_prospect)
      .then(([{ error }]) => {
        if (error)
          toast.error(() => (
            <div>
              <FormattedMessage id="rtq.result.score-summary.update-error" values={{ flavor }} />
            </div>
          ));
        else {
          setAdjustScore(false);
          setDiscussionTopicsStep();
          toast.success(() => (
            <div>
              <FormattedMessage id="rtq.result.score-summary.update-success" />
            </div>
          ));
        }
      })
      .catch(() =>
        toast.error(() => (
          <div>
            <FormattedMessage id="rtq.result.score-summary.update-error" values={{ flavor }} />
          </div>
        ))
      )
      .finally(() => setIsSaving(false));
  };

  if (isSaving)
    return (
      <div id="score-summary">
        <SpinnerLoader spinnerLoading />
      </div>
    );

  return (
    <div id="score-summary">
      <div className="score-summary__title">
        <h4>
          <FormattedMessage
            id="rtq.result.score-summary.title"
            values={{ flavor, possessiveForm }}
          />
        </h4>
        <span>
          <FormattedMessage id="rtq.result.title" values={{ icon: <>&#127881;</> }} />
        </span>
        <br />
        <span>
          <FormattedMessage
            id="rtq.result.score-summary.investor-type"
            values={{ flavor, investorName }}
          />
        </span>
      </div>

      <Card>
        <ScoreContext flavor={flavor} investorName={investorName} scoreSummary={scoreSummary} />

        <div className="result-scores">
          {isNum(overall) && (
            <RiskOverall id="target-score-overall-gauge-full" score={overall} small />
          )}
        </div>

        {flavor === 'advisor' && mode === 'account' && investor?.is_prospect && account && (
          <div className="navigation-actions">
            <Link
              className="btn btn-back"
              to={`/advisor/${investor.is_prospect ? 'prospects/' : 'investors/'}`}
            >
              <FormattedMessage
                id="rtq.result.score-summary.investor-list-button"
                values={{ prospect: investor.is_prospect }}
              />
            </Link>
            {account.prism_score_summary && (
              <Link
                to={`/advisor/${investor.is_prospect ? 'prospects/' : 'investors/'}${
                  investor.id
                }/account/${account.id}/prism`}
                className="btn btn-primary"
              >
                <FormattedMessage id="rtq.result.score-summary.prism-report-button" />
              </Link>
            )}
            {!account.value && (
              <Link to={`/advisor/prospects?accountId=${account.id}`} className="btn btn-primary">
                <FormattedMessage id="rtq.result.score-summary.upload-portfolio-button" />
              </Link>
            )}
          </div>
        )}

        {!isMarketing && (
          <div className="questionnaire-actions">
            <button
              type="button"
              className="btn btn-outline-primary font-weight-normal"
              onClick={() => {
                setAdjustScore(true);
                trackAmplitudeEvent('rtq.adjust.view');
              }}
            >
              <FormattedMessage
                id="rtq.result.score-summary.adjust-score-button"
                values={{ flavor }}
              />
            </button>

            <button type="button" className="btn btn-primary" onClick={setDiscussionTopicsStep}>
              <FormattedMessage
                id="rtq.result.score-summary.feels-like-me-button"
                values={{ flavor, icon: <>&#x1F44D;</>, investorName }}
              />
            </button>
          </div>
        )}
      </Card>

      {!isMarketing && (
        <Card
          header={
            <div className="risk-header">
              <FormattedMessage id="rtq.result.risk-ranges.title" />
              <ExpandableButton
                isCollapsed={showRiskLegend}
                buttonLabel={<FormattedMessage id="rtq.result.risk-ranges.expandable-button" />}
                onClick={toggleRiskLenged}
              />
            </div>
          }
        >
          <RiskRanges flavor={flavor} id="range-score-context" scoreSummary={scoreSummary} />
          {showRiskLegend && <Legend withCapacity={!!scoreSummary?.capacity} />}
        </Card>
      )}

      <AdjustScore
        changeScore={adjustQuestionnaireScore}
        closeModal={() => setAdjustScore(false)}
        flavor={flavor}
        investorName={investorName}
        scoreSummary={scoreSummary}
        showModal={adjustScore}
      />
    </div>
  );
};

ScoreSummary.defaultProps = {
  investors: []
};

ScoreSummary.propTypes = {
  flavor: PropTypes.string.isRequired,
  investors: PropTypes.array,
  mode: PropTypes.string.isRequired,
  onQuestionnaireRestart: PropTypes.func.isRequired,
  questionnaireId: PropTypes.number.isRequired,
  scoreSummary: PropTypes.object.isRequired,
  onUpdateScore: PropTypes.func.isRequired,
  setDiscussionTopicsStep: PropTypes.func.isRequired
};

export default ScoreSummary;
