import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useContext } from 'react';
import { AdvisorContext } from 'containers/advisor';
import './styles.scss';

const InvestorDeleteModal = ({ items, onClickCloseModal }) => {
  const { investorProvider, prospectProvider, routerActions } = useContext(AdvisorContext);
  const [submitting, setSubmitting] = useState(false);
  const [investor] = items;
  const isProspect = window.location.pathname.includes('prospects');
  const label = isProspect ? 'Prospect' : 'Client';

  const onSubmit = async () => {
    setSubmitting(true);
    const provider = isProspect ? prospectProvider : investorProvider;
    await provider.delete(investor.id);
    toast.success(`${isProspect ? 'Prospect' : 'Client'}(s) removed successfully`);
    onClickCloseModal();
    routerActions.push(`advisor/${isProspect ? 'prospects' : 'investors'}`);
  };

  const entity = items.length > 1 ? `${label}s` : label;

  return (
    <Modal
      id="sdt__delete-modal"
      className="investor-delete-modal modal-lg"
      show
      onHidden={onClickCloseModal}
    >
      <ModalHeader />
      <ModalBody>
        <h3 className="modal-title">Delete {entity}?</h3>
        <div className="description top">
          <div className="text">
            Are you sure you want to delete{' '}
            <span className="coloured-text">
              {items.length} {entity}
            </span>
            ?
          </div>
          <div className="text text__warning padding">
            <span className="fs-icon-exclamation-circle warning-icon" />
            You can&apos;t revert this change.
          </div>
        </div>
        <div className="text-sm-center actions">
          <button type="button" className="btn cancel" onClick={onClickCloseModal}>
            Cancel
          </button>
          <LoadingButton
            className="btn btn-danger delete"
            disabled={submitting}
            loading={submitting}
            onClick={onSubmit}
          >
            Delete
          </LoadingButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

InvestorDeleteModal.propTypes = {
  items: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClickCloseModal: PropTypes.func.isRequired
};

export default InvestorDeleteModal;
