import { Text } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage } from 'utils/utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';
import usePortfolioConcentration from '../hooks';

const PortfolioConcentrationQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  const { questionText } = usePortfolioConcentration({ question });

  return (
    <ReportQuestion question={{ ...question, question: questionText }}>
      <Text>{formatPercentage(question.answer.value)}</Text>
    </ReportQuestion>
  );
};

PortfolioConcentrationQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default PortfolioConcentrationQuestion;
