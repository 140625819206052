import React from 'react';
import FilterContainer from '../common/filter-container';
import RiskLevelFilter from '../common/risk-level';
import { HOUSEHOLD_DRIFT_LEVEL_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const HouseholdDriftLevelFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => (
  <FilterContainer
    attrs={[HOUSEHOLD_DRIFT_LEVEL_ATTR]}
    filters={filters}
    label="Household Drift Level"
  >
    <RiskLevelFilter
      attr={HOUSEHOLD_DRIFT_LEVEL_ATTR}
      filters={filters}
      setFilters={setFilters}
      setRequiresPageIndexReset={setRequiresPageIndexReset}
    />
  </FilterContainer>
);

HouseholdDriftLevelFilter.propTypes = { ...filterPropTypes };

HouseholdDriftLevelFilter.defaultProps = { ...filterDefaultProps };

export default HouseholdDriftLevelFilter;
