import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const CollaborationSearch = ({ searchCallback }) => {
  const handleSearch = debounce(event => {
    searchCallback(event.target.value);
  }, 500);

  return (
    <div className="form-group search">
      <div>
        <input
          className="form-control react-autosuggest__input"
          placeholder="Search"
          onChange={handleSearch}
        />
        <span className="icon-search_icn" />
      </div>
    </div>
  );
};

CollaborationSearch.defaultProps = {
  data: [],
  searchCallback: () => {}
};

CollaborationSearch.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  searchCallback: PropTypes.func
};

export default React.memo(CollaborationSearch);
