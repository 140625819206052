import arrowDownIcon from 'assets/img/icons/arrow-down-white.svg';
import cn from 'classnames';
import RiskToleranceQuestionnaireReadOnly from 'containers/risk-tolerance-questionnaire/questionnaire/read-only';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const IPSRiskToleranceQuestionnaireToggleable = ({ account, investor, questions }) => {
  const [show, setShow] = useState(false);

  const toggleShowQuestionnaire = () => {
    setShow(prevShow => !prevShow);
  };

  if (!questions.length) return null;

  const toggleableId = account ? `${account.id}-${investor.id}` : investor.id;

  return (
    <div
      className="accordion questionnaire-toggleable"
      id={`questionnaire-toggleable-${toggleableId}`}
    >
      <div className="card">
        <div className="card-header" id={`questionnaire-header-${toggleableId}`}>
          <div className="card_header__data">
            <p className="mb-0">
              {account ? `${investor.name} - ${account.display_name}` : investor.name}
            </p>
          </div>
          <div className="card-header__actions">
            <button
              aria-controls={`questionnaire-content-${toggleableId}`}
              aria-expanded="false"
              className={cn('btn', 'btn-secondary', 'font-weight-normal', { collapsed: !show })}
              data-target={`#questionnaire-content-${toggleableId}`}
              onClick={toggleShowQuestionnaire}
              type="button"
            >
              View answers
              <img src={arrowDownIcon} className="arrow-icon" alt="arrow-icon" />
            </button>
          </div>
        </div>

        <div
          aria-labelledby={`questionnaire-header-${toggleableId}`}
          className={cn('collapse', { show })}
          data-parent={`#questionnaire-toggleable-${toggleableId}`}
          id={`questionnaire-content-${toggleableId}`}
        >
          <div className="card-body">
            <RiskToleranceQuestionnaireReadOnly questions={questions} />
          </div>
        </div>
      </div>
    </div>
  );
};

IPSRiskToleranceQuestionnaireToggleable.defaultProps = {
  account: null
};

IPSRiskToleranceQuestionnaireToggleable.propTypes = {
  account: PropTypes.object,
  investor: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired
};

export default IPSRiskToleranceQuestionnaireToggleable;
