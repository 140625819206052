import {
  IndicatorsContainer,
  MenuList,
  Option,
  SingleValue
} from 'components/advisor/generic-selector';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { PROPOSAL_SELECT_STYLES } from '../../header/common/form/utils';

const MultiPortfolioSelector = ({ suggestions, defaultValue, onChange, helptext }) => {
  const allSuggestions = suggestions.reduce((acum, category) => [...acum, ...category.options], []);

  if (allSuggestions && !allSuggestions.length)
    return <div className="target">You don&apos;t have valid accounts for this action.</div>;

  return (
    <div className="target">
      <label htmlFor="account-select">Click to add additional accounts for the household *</label>
      <Select
        className="account-select"
        components={{ MenuList, Option, SingleValue, IndicatorsContainer }}
        isMulti
        isSearchable
        noOptionsMessage={() => 'No more accounts are available.'}
        onChange={onChange}
        options={suggestions}
        placeholder="Select accounts"
        value={defaultValue}
        styles={PROPOSAL_SELECT_STYLES}
      />
      {helptext && <div className="accounts-input-tip">{helptext}</div>}
    </div>
  );
};

MultiPortfolioSelector.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
  onChange: PropTypes.func.isRequired,
  helptext: PropTypes.string
};

MultiPortfolioSelector.defaultProps = {
  defaultValue: [],
  helptext: ''
};

export default MultiPortfolioSelector;
