import cn from 'classnames';
import DisplayScore from 'components/utils/DisplayScore';
import SimpleSpinner from 'components/utils/simple-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';
import './styles.scss';

const MatchModel = ({ account, model, clientPath, isTargetModel }) => (
  <div className="match-model match-model-container">
    <div className="col col-name">
      <Link to={`/advisor/models/${model.id}`}>{model.name}</Link>
      {isTargetModel && <span className="target-model">Benchmark</span>}
    </div>
    <div className="col">
      <span>PRISM</span>
      <Link to={`/advisor/models/${model.id}/risk-analysis`}>
        <DisplayScore score={model?.prism_overall ?? model?.prism_score_summary?.overall} />
      </Link>
    </div>
    <div className="col">
      <Link
        className="btn btn-primary btn-sm proposal-link"
        to={`/advisor/${clientPath}/${account.investor.id}/proposals/new/?recommended=${model.id}`}
      >
        <i className="fs-icon-bolt" />
        Proposal
      </Link>
    </div>
  </div>
);

MatchModel.defaultProps = {
  isTargetModel: false
};

MatchModel.propTypes = {
  account: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  clientPath: PropTypes.string.isRequired,
  isTargetModel: PropTypes.bool
};

class InvestmentObjectivesMatchingModels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      targetModel: null
    };
  }

  componentDidMount() {
    const { modelProvider } = this.context;
    const { account } = this.props;

    if (account.target_model) {
      this.setState({ loading: true });
      modelProvider
        .get(account.target_model.id)
        .then(({ data: targetModel }) => {
          this.setState({ targetModel });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { models, account } = this.props;
    const { isProspectSection, showTargetScoreWizard } = this.context;
    const { loading, targetModel } = this.state;

    let showAddTargetMessage = false;
    let showAddPortfolioMessage = false;

    const clientPath = isProspectSection ? 'prospects' : 'investors';

    if (_.isEmpty(models) && !targetModel)
      if (account.target_score_summary && account.target_score_summary.overall)
        showAddPortfolioMessage = true;
      else showAddTargetMessage = true;

    if (loading)
      return (
        <div style={{ textAlign: 'center' }}>
          <SimpleSpinner />
        </div>
      );

    return (
      <div
        key={account.id}
        id="investment-objectives-matching-models"
        className={cn('matching-models', { empty: _.isEmpty(models) })}
      >
        {showAddPortfolioMessage && (
          <div className="no-target">
            You don&apos;t have model portfolios matching with the risk tolerance. We suggest to
            <Link to="/advisor/models" className="link">
              {' '}
              upload more model portfolios.
            </Link>
          </div>
        )}

        {showAddTargetMessage && (
          <div className="no-target">
            <button type="button" onClick={() => showTargetScoreWizard()} className="link">
              Please fill out PRISM target questionnaires to see matching models.
            </button>
          </div>
        )}

        {targetModel && (
          <MatchModel
            key={targetModel.id}
            model={targetModel}
            account={account}
            clientPath={clientPath}
            isTargetModel
          />
        )}

        {models &&
          models
            .filter(model => !targetModel || model.id !== targetModel.id)
            .map(model => (
              <MatchModel key={model.id} model={model} account={account} clientPath={clientPath} />
            ))}
      </div>
    );
  }
}

InvestmentObjectivesMatchingModels.contextTypes = {
  modelProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  isProspectSection: PropTypes.bool.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired
};

InvestmentObjectivesMatchingModels.propTypes = {
  account: PropTypes.object.isRequired,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      prism_overall: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired
    })
  )
};

InvestmentObjectivesMatchingModels.defaultProps = {
  models: []
};

export default InvestmentObjectivesMatchingModels;
