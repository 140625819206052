import PropTypes from 'prop-types';
import React from 'react';
import '../styles.scss';
import { capacityInfo } from '../utils/helpers';

const RiskCapacity = ({ score }) => {
  const capacity = capacityInfo(score);
  const level = Math.ceil(score);
  return (
    <div className="financial-capacity">
      <img
        className="financial-capacity__icon"
        src={capacity.src}
        alt={capacity.label}
        width={50}
      />
      <h4 className="financial-capacity__title">
        <b className={`-level-${level}`}>{capacity.label}</b>
      </h4>
      <p className="financial-capacity__meaning">{capacity.description}</p>
    </div>
  );
};

RiskCapacity.propTypes = {
  score: PropTypes.number.isRequired
};

export default RiskCapacity;
