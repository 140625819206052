import { useEffect, useState } from 'react';

const queries = {
  phone: '(max-width: 600px)',
  smallTablet: '(max-width: 800px)',
  tablet: '(max-width: 1100px)',
  desktop: '(min-width: 1101px)'
};

const useWindowSize = query => {
  const [matches, setMatches] = useState(window?.matchMedia(query)?.matches || false);

  useEffect(() => {
    const media = window?.matchMedia(query);
    const listener = () => setMatches(media.matches);

    if (media?.matches !== matches) setMatches(media.matches);
    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
};

export { useWindowSize, queries };
