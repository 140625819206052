import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { stripTags } from 'utils/questionnaire';
import styles from '../../../base/pdf/empty/styles';

const ChoiceOption = ({ isOtherChoice, text }) => (
  <View style={styles.mark}>
    <View style={styles.markCheck} />
    <Text style={styles.markText}>{stripTags(text)}</Text>
    {isOtherChoice && <View style={styles.otherChoiceBox} />}
  </View>
);

ChoiceOption.propTypes = {
  isOtherChoice: PropTypes.bool,
  text: PropTypes.string.isRequired
};

ChoiceOption.defaultProps = {
  isOtherChoice: false
};

export default ChoiceOption;
