import classnames from 'classnames';
import ScoreBubble, { getInvestorId } from 'components/advisor/utils/score-bubble';
import LoadingButton from 'components/loading-button';
import WarningIcon from 'components/svg-icons/warning-icon';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { selectPrismScore } from 'utils/utils';

const getAccountRiskUrl = (account, isProspect) => {
  const investorId = getInvestorId(account);
  const mode = isProspect ? 'prospects' : 'investors';
  return investorId ? `/advisor/${mode}/${investorId}/account/${account.id}/prism` : null;
};

const RiskScoreBubble = (
  { element, className, inProgress, onGenerate, scoreName, target, isStrategy },
  { isProspectSection }
) => {
  const score = selectPrismScore(element.prism_score_summary, scoreName);
  if (score) {
    let url;
    if (target === 'account') url = getAccountRiskUrl(element, isProspectSection);
    else if (target === 'model-portfolio') url = `/advisor/models/${element.id}/risk-analysis`;
    return (
      <ScoreBubble
        score={score}
        url={url}
        className={classnames('risk-bubble', className)}
        isStrategy={isStrategy || element.is_strategy}
      />
    );
  }

  if (onGenerate && (target !== 'account' || element.value > 0))
    return (
      <LoadingButton
        className="btn table-btn get-target-score-btn btn-solid"
        disabled={inProgress}
        loading={inProgress}
        onClick={() => onGenerate(element.id)}
      >
        +
      </LoadingButton>
    );

  if (element.value <= 0)
    return (
      <button
        type="button"
        className="btn table-btn get-target-score-btn btn-transparent"
        onClick={() => toast.error('Please add prospective value to see the PRISM rating')}
      >
        <WarningIcon
          role="button"
          aria-label="Action Required"
          className="warning-icon"
          title="Score not available"
        />
      </button>
    );

  return null;
};

RiskScoreBubble.defaultProps = {
  className: '',
  inProgress: false,
  isStrategy: false,
  onGenerate: null,
  scoreName: '',
  target: 'account'
};

RiskScoreBubble.propTypes = {
  element: PropTypes.object.isRequired,
  className: PropTypes.string,
  inProgress: PropTypes.bool,
  isStrategy: PropTypes.bool,
  onGenerate: PropTypes.func,
  scoreName: PropTypes.string,
  target: PropTypes.string
};

RiskScoreBubble.contextTypes = {
  isProspectSection: PropTypes.bool.isRequired
};
export default RiskScoreBubble;
