// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Signin .signin-wrapper,
#Signin .signup-wrapper,
#Signup .signin-wrapper,
#Signup .signup-wrapper {
  margin-top: 30px;
}
#Signin .signin-wrapper .hero-form-container,
#Signin .signup-wrapper .hero-form-container,
#Signup .signin-wrapper .hero-form-container,
#Signup .signup-wrapper .hero-form-container {
  margin: 0 auto;
}
#Signin .signin-wrapper .hero-form-container__form,
#Signin .signup-wrapper .hero-form-container__form,
#Signup .signin-wrapper .hero-form-container__form,
#Signup .signup-wrapper .hero-form-container__form {
  min-height: 30vh;
  max-width: 100vw;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#Signin .signin-wrapper .helper-text,
#Signin .signup-wrapper .helper-text,
#Signup .signin-wrapper .helper-text,
#Signup .signup-wrapper .helper-text {
  font-size: 0.9rem;
}

#Signin .qr-container {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
#Signin .qr-container > div {
  border: 1px solid #f1f1f1;
}
#Signin .qr-container > div img {
  width: 14rem;
}

@media (min-width: 700px) {
  #Signin .hero-form-container__form {
    min-width: 500px;
  }
  #Signup .hero-form-container__form {
    width: 700px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/signin/styles.scss"],"names":[],"mappings":"AAIE;;;;EAEE,gBAAA;AADJ;AAGI;;;;EACE,cAAA;AAEN;AACI;;;;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAIN;AADI;;;;EACE,iBAAA;AAMN;;AAAE;EACE,aAAA;EACA,uBAAA;EACA,YAAA;AAGJ;AADI;EACE,yBAAA;AAGN;AADM;EACE,YAAA;AAGR;;AAGA;EAEI;IACE,gBAAA;EADJ;EAME;IACE,YAAA;EAJJ;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#Signin,\n#Signup {\n  .signin-wrapper,\n  .signup-wrapper {\n    margin-top: 30px;\n\n    .hero-form-container {\n      margin: 0 auto;\n    }\n\n    .hero-form-container__form {\n      min-height: 30vh;\n      max-width: 100vw;\n      padding: 30px;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n    }\n\n    .helper-text {\n      font-size: 0.9rem;\n    }\n  }\n}\n\n#Signin {\n  .qr-container {\n    display: flex;\n    justify-content: center;\n    margin: 1rem;\n\n    & > div {\n      border: 1px solid #f1f1f1;\n\n      img {\n        width: 14rem;\n      }\n    }\n  }\n}\n\n@media (min-width: 700px) {\n  #Signin {\n    .hero-form-container__form {\n      min-width: 500px;\n    }\n  }\n\n  #Signup {\n    .hero-form-container__form {\n      width: 700px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
