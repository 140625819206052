import cn from 'classnames';
import { trackAmplitudeEvent } from 'utils/tracking';
import SpinnerLoader from 'components/performance-spinner';
import { MarketplaceDetailTabs as tabs } from 'constants/tabs';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { RequestAction } from 'components/advisor/section-locked';
import { withRouter, Link } from 'react-router';
import { connect } from 'react-redux';
import {
  aboutLink,
  overviewLink,
  portfolioRiskLink,
  compareAnalyzeLink,
  strategyLink
} from './links';
import './styles.scss';

const DEFAULT_TAB = tabs.OVERVIEW;

const MarketplaceDetail = ({ children, location, params, marketplace }) => {
  const { modelProvider } = useContext(AdvisorContext);
  const [isRequestBtnWiggling, setIsRequestBtnWiggling] = useState(false);

  const onLockedButtonClick = () => {
    setIsRequestBtnWiggling(true);
    setTimeout(() => {
      setIsRequestBtnWiggling(false);
    }, 2000); // Wiggle for 2 seconds
  };

  const [tab, setTab] = useState(DEFAULT_TAB);
  const [loading, setLoading] = useState(true);

  const marketplaceID = params?.id;

  const onRequestAccessClick = () => {
    trackAmplitudeEvent('marketplace.requested', { id: marketplaceID });
    modelProvider.postSubscription(marketplaceID);
  };

  const setCurrentTab = () => {
    const matchingTab = Object.values(tabs).find(value => location.pathname.includes(value));
    if (matchingTab && tab !== matchingTab) setTab(matchingTab);
  };

  useEffect(() => {
    setLoading(true);
    setCurrentTab();
    modelProvider.getMarketplace(marketplaceID).finally(() => {
      setLoading(false);
    });

    trackAmplitudeEvent('marketplace.view', { id: marketplaceID });
  }, []);

  useEffect(() => {
    setCurrentTab();
    setLoading(true);
    modelProvider.getMarketplace(marketplaceID).finally(() => {
      setLoading(false);
    });
  }, [location.pathname]);

  if (loading) return <SpinnerLoader spinnerLoading />;

  const subscriptionStatus = marketplace.subscription_status;

  let requestAccessText = '';
  let requestAccessClassName = '';

  if (subscriptionStatus == null) requestAccessText = 'Request Access';
  if (subscriptionStatus === 'pending') {
    requestAccessText = 'Access Requested';
    requestAccessClassName = 'disabled';
  }

  return (
    <div className="marketplace-details">
      <div>
        <div className="marketplace-details__title">
          <h3>{marketplace.name}</h3>
          {(subscriptionStatus === null || subscriptionStatus === 'pending') && (
            <RequestAction
              id={marketplaceID}
              name={marketplace.name}
              callback={onRequestAccessClick}
              className={cn(
                { wiggle: isRequestBtnWiggling },
                'btn btn-primary',
                requestAccessClassName
              )}
              btnText={requestAccessText}
            />
          )}
          {subscriptionStatus === 'accepted' && (
            <Link
              to={`/advisor/models/${marketplaceID}/overview`}
              className="btn btn-primary portfolio-model__link"
            >
              View in my Workspace
            </Link>
          )}
          {subscriptionStatus === 'revoked' && (
            <div className="rejected-msg">
              <span>The access to this model was not approved.</span>
              <br />
              <span>If you think this is an error please contact our support team.</span>
            </div>
          )}
        </div>
        <ul className="marketplace-details__tabs tabs">
          <li className={cn({ active: tab === tabs.ABOUT })}>{aboutLink({ marketplaceID })}</li>
          <li className={cn({ active: tab === tabs.OVERVIEW })}>
            <button onClick={onLockedButtonClick} type="button">
              {overviewLink({ marketplaceID })}
            </button>
          </li>
          <li className={cn({ active: tab === tabs.PORTFOLIO_RISK })}>
            <button onClick={onLockedButtonClick} type="button">
              {portfolioRiskLink({ marketplaceID })}
            </button>
          </li>
          <li className={cn({ active: tab === tabs.COMPARE_ANALYZE })}>
            <button onClick={onLockedButtonClick} type="button">
              {compareAnalyzeLink({ marketplaceID })}
            </button>
          </li>
          <li className={cn({ active: tab === tabs.STRATEGY })}>
            <button onClick={onLockedButtonClick} type="button">
              {strategyLink({ marketplaceID })}
            </button>
          </li>
        </ul>
      </div>
      {children}
    </div>
  );
};

MarketplaceDetail.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  marketplace: PropTypes.object.isRequired
};

MarketplaceDetail.contextTypes = {
  modelProvider: PropTypes.object.isRequired
};

const MarketplaceDetailWithRouter = withRouter(MarketplaceDetail);

export default connect(state => ({
  marketplace: state.models.currentMarketplace
}))(MarketplaceDetailWithRouter);
