/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';
import {
  BENCHMARK_ANALYTICS,
  RECOMMENDED_ANALYTICS,
  TARGET_ANALYTICS,
  getRisksFromSource,
  getRisksHeaders
} from './utils';

const ProposalRisk = ({ actionsDisabled, metadata, propertyKey, proposal, setMetadata }) => {
  const hasBenchmark = !!proposal.benchmark;
  const hasRecommended = !!proposal.recommended;
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const headers = getRisksHeaders(hasBenchmark, proposal.benchmark_label);
  const targetRisk = getRisksFromSource(proposal, TARGET_ANALYTICS, hasBenchmark);
  const recommendedRisk = hasRecommended
    ? getRisksFromSource(proposal, RECOMMENDED_ANALYTICS, hasBenchmark)
    : null;
  const benchmarkRisk = hasBenchmark
    ? getRisksFromSource(proposal, BENCHMARK_ANALYTICS, hasBenchmark)
    : null;

  return (
    <EditBoxText {...proposalMetadata}>
      <DateValue proposal={proposal} />
      <div
        className={cn('fancy-grid', {
          'no-recommended': !hasRecommended,
          'no-benchmark': !hasBenchmark
        })}
      >
        {headers.map(header => {
          const key = header ? header.toLowerCase().replace(/\s/g, '-') : 'empty';
          return (
            <div key={`ri-header-${key}`} className="header">
              {header}
            </div>
          );
        })}

        <div>{proposal.target_label || 'Current Portfolio'}</div>
        {targetRisk.map((value, i) => (
          <div key={`ri-target-${value}-${i}`}>{value}</div>
        ))}

        {hasRecommended && (
          <>
            <div>{proposal.recommended_label}</div>
            {recommendedRisk.map((value, i) => (
              <div key={`ri-recommended-${value}-${i}`}>{value}</div>
            ))}
          </>
        )}

        {hasBenchmark && (
          <>
            <div>{proposal.benchmark_label}</div>
            {benchmarkRisk.map((value, i) => (
              <div key={`ri-benchmark-${value}-${i}`}>{value}</div>
            ))}
          </>
        )}
      </div>
    </EditBoxText>
  );
};

ProposalRisk.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalRisk;
