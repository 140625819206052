import { getExternalQuestionnaireURL } from 'components/advisor/target-score-action/utils';

export const DUMMY_INVESTOR = {
  first_name: '[FIRST_NAME]',
  last_name: '[LAST_NAME]',
  full_name: '[NAME]',
  email: '[EMAIL]'
};

export const buildMessage = (investor, mode, questionnaireTemplateId, msgGenerator) => {
  const action = {
    text: 'Begin',
    url: getExternalQuestionnaireURL(investor, mode, questionnaireTemplateId)
  };
  return msgGenerator({ eInvestor: investor, eAction: action });
};
