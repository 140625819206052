import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Row = ({
  cellStyle,
  elements,
  firstCellStyle,
  lastCellStyle,
  rowStyle,
  withCustomElements,
  wrapRow
}) => (
  <View style={[styles.row, rowStyle]} wrap={wrapRow}>
    {elements.map((e, i, { length }) => {
      let customStyles = [styles.cell, cellStyle];
      if (i === 0) customStyles = [styles.cell, cellStyle, firstCellStyle];
      if (length - 1 === i) customStyles = [styles.cell, styles.lastCell, cellStyle, lastCellStyle];

      return (
        // eslint-disable-next-line react/no-array-index-key
        <View key={i} style={customStyles}>
          {withCustomElements && e}
          {!withCustomElements && <Text style={styles.text}>{e}</Text>}
        </View>
      );
    })}
  </View>
);

Row.propTypes = {
  cellStyle: PropTypes.object,
  elements: PropTypes.array.isRequired,
  firstCellStyle: PropTypes.object,
  lastCellStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  withCustomElements: PropTypes.bool,
  wrapRow: PropTypes.bool
};

Row.defaultProps = {
  cellStyle: {},
  firstCellStyle: {},
  lastCellStyle: {},
  rowStyle: {},
  withCustomElements: false,
  wrapRow: false
};

export default Row;
