import { PublicClientApplication } from '@azure/msal-browser';

const createMsalInstance = () => {
  const msalConfig = {
    auth: {
      clientId: process.env.AZURE_CLIENT_ID,
      authority: process.env.AZURE_AD_URL,
      redirectUri: process.env.AZURE_REDIRECT_URI
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };

  return new PublicClientApplication(msalConfig);
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read']
};

export default createMsalInstance;
