import { PERSONALITY_TYPES } from '../score-context/utils/constants';

/*
  This function converts the personality types to marks for the slider
  @return {object} marks

  The personality types have the properties: min, max, label and mark (position and value)
  The marks objects has the personality type value as key and the properties: position, range and label
*/

function getMarks() {
  return Object.values(PERSONALITY_TYPES).reduce((marks, personalityType) => {
    const {
      mark: { position, value },
      label
    } = personalityType;
    marks[value] = {
      position,
      range: [personalityType.min, personalityType.max],
      label
    };
    return marks;
  }, {});
}

const SCORE_MARK_POSITIONS = getMarks();

export default SCORE_MARK_POSITIONS;
