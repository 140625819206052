// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table.table-investment-linked .risk-badge-with-score {
  cursor: pointer;
}
.table.table-investment-linked .client-name {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investments/linked-account-table/styles.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;AAAJ","sourcesContent":[".table.table-investment-linked {\n  .risk-badge-with-score {\n    cursor: pointer;\n  }\n  .client-name {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
