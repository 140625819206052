import FeatureLimitReachedModal from 'components/advisor/feature-limit';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { prospectsLimitReachedSelector } from 'selectors/limits';
import { getFilterSubtitle } from 'utils/utils';
import InvestorsCsvUpdate from '../investor-csv-update';
import './styles.scss';

const InvestorHeader = ({ getList, meta, prospectsLimitReached, type }) => {
  const { authProvider, location, routerActions, user } = useContext(AdvisorContext);

  const [investorsLimitModalShown, setInvestorsLimitModalShown] = useState(false);

  const isClient = type === 'client';
  const isProspect = type === 'prospect';

  const kind = isProspect ? 'Prospect' : 'Client';
  const hasAddPermissions = isProspect
    ? authProvider.hasAddProspectsPermissions(user)
    : authProvider.hasAddClientsPermissions(user);
  const subtitle = getFilterSubtitle(location.query);

  const setLimitReached = () => {
    setInvestorsLimitModalShown(true);
  };

  const unsetLimitReached = () => {
    setInvestorsLimitModalShown(false);
  };

  const createInvestor = () => {
    if (isProspect && prospectsLimitReached) setLimitReached();
    else {
      const pathname = `/advisor/${isProspect ? 'prospects' : 'investors'}/create`;
      routerActions.push({ pathname });
    }
  };

  return (
    <>
      <div id="InvestorHeader" className="header">
        <div className="title-container">
          <h2>
            {kind}s{subtitle && <span className="subtitle">{subtitle}</span>}
          </h2>
        </div>
        <div className="right-header">
          {hasAddPermissions && (
            <button type="button" onClick={createInvestor} className="btn btn-primary">
              Add a {kind}
            </button>
          )}
          {isClient && <InvestorsCsvUpdate getList={getList} investorsMeta={meta} />}
        </div>
      </div>

      <FeatureLimitReachedModal
        content={{
          action: 'adding',
          kind: `${kind.toLowerCase()}s`
        }}
        show={investorsLimitModalShown}
        onHide={unsetLimitReached}
      />
    </>
  );
};

InvestorHeader.propTypes = {
  getList: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  prospectsLimitReached: PropTypes.bool,
  type: PropTypes.string.isRequired
};

InvestorHeader.defaultProps = {
  prospectsLimitReached: false
};

export default connect(state => ({
  prospectsLimitReached: prospectsLimitReachedSelector(state)
}))(InvestorHeader);
