import useDriftThreshold from 'hooks/drift-threshold';
import React from 'react';

const withDriftThreshold = Component => props => {
  const { driftThresholdAbove, driftThresholdBelow, isHealthyDrift } = useDriftThreshold();
  return (
    <Component
      driftThresholdAbove={driftThresholdAbove}
      driftThresholdBelow={driftThresholdBelow}
      isHealthyDrift={isHealthyDrift}
      {...props}
    />
  );
};

export default withDriftThreshold;
