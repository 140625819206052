/* eslint-disable react/no-array-index-key */
import PlusIcon from 'assets/img/icons/plus-circle.svg';
import SimpleSpinner from 'components/utils/simple-spinner';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import DigitalSignatureRow from './row';
import {
  DEFAULT_SIGNER,
  getInvestorOptions,
  getManagerOptions,
  getSignatureTypeOptions,
  getSignerTypeOptions
} from './utils';

const DigitalSignature = ({
  canAddOrSaveSigners,
  editing,
  investors,
  managers,
  setSigners,
  signers
}) => {
  const { user } = useContext(AdvisorContext);

  const investorOptions = getInvestorOptions(investors, signers, editing);
  const managerOptions = getManagerOptions(user, managers, signers, editing);
  const signatureTypeOptions = getSignatureTypeOptions();
  const signerTypeOptions = getSignerTypeOptions();

  const addSigner = () => {
    setSigners(prevSigners => [...prevSigners, { ...DEFAULT_SIGNER }]);
  };

  const removeSigner = index => {
    setSigners(prevSigners => prevSigners.filter((_, i) => i !== index));
  };

  const onSignerChange = (index, field, value) => {
    setSigners(prevSigners => {
      const signers = [...prevSigners];
      signers[index][field] = value;
      return signers;
    });
  };

  const digitalSignatureRowProps = {
    addSigner,
    editing,
    investorOptions,
    managerOptions,
    onSignerChange,
    removeSigner,
    removeSignerDisabled: signers.length < 2,
    signatureTypeOptions,
    signerTypeOptions
  };

  return (
    <div className="signature-section signature-section--digital">
      {_.isEmpty(signers) && (
        <div className="text-center signature-section__loading">
          <SimpleSpinner size="20" />
          <span>Please wait ...</span>
        </div>
      )}

      {!_.isEmpty(signers) &&
        signers.map((signer, index) => (
          <DigitalSignatureRow
            {...digitalSignatureRowProps}
            index={index}
            key={`digital-signature-row-${index}`}
            signer={signer}
          />
        ))}

      {editing && (
        <div className="signature__add">
          <button
            disabled={!canAddOrSaveSigners}
            type="button"
            className="btn btn-link"
            onClick={addSigner}
          >
            <img src={PlusIcon} width={20} height={20} alt="Add Signer" /> Add Signer
          </button>
        </div>
      )}
    </div>
  );
};

DigitalSignature.propTypes = {
  canAddOrSaveSigners: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  investors: PropTypes.array.isRequired,
  managers: PropTypes.array.isRequired,
  setSigners: PropTypes.func.isRequired,
  signers: PropTypes.array.isRequired
};

export default DigitalSignature;
