export const spinnerElements = [
  { rotate: 0, linear: -1.42857 },
  { rotate: 25, linear: -1.32653 },
  { rotate: 51, linear: -1.22449 },
  { rotate: 77, linear: -1.12245 },
  { rotate: 102, linear: -1.02041 },
  { rotate: 128, linear: -0.918367 },
  { rotate: 154, linear: -0.816327 },
  { rotate: 180, linear: -0.714286 },
  { rotate: 205, linear: -0.612245 },
  { rotate: 231, linear: -0.510204 },
  { rotate: 257, linear: -0.408163 },
  { rotate: 282, linear: -0.306122 },
  { rotate: 308, linear: -0.204082 },
  { rotate: 334, linear: -0.102041 }
];

export const animation = linearValue =>
  `1.42857s linear ${linearValue}s infinite normal none running spinner-line-fade-quick`;

export const transform = rotate => `rotate(${rotate}deg) translateX(60px)`;
