import Plans from 'components/plans';
import SplashLoading from 'components/splash-loading';
import { AuthenticationContext } from 'containers/auth';
import PropTypes from 'prop-types';
import AdvisorProvider from 'providers/advisor';
import PriceProvider, { FREEMIUM_PLAN } from 'providers/prices';
import SubscriptionProvider from 'providers/subscription';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import './styles.scss';

const CheckoutPrices = ({
  location,
  prices,
  priceProvider,
  subscriptionProvider,
  selectedPriceLabel
}) => {
  const {
    authProvider,
    routerActions,
    user: {
      advisor: {
        company: { trial_available: trialAvailable }
      }
    }
  } = useContext(AuthenticationContext);

  const [processing, setProcessing] = useState(false);

  const changingSelectedPrice = location?.state?.changingSelectedPrice;

  useEffect(() => {
    priceProvider.list();
    // if the user has a pre-selected price and it's not coming from the
    // payment view, assign the pre-selected price as selected price to be
    // automatically redirected to the payment view
    if (authProvider.signupPrice && !changingSelectedPrice)
      priceProvider.setPrice(authProvider.signupPrice);
  }, []);

  async function enableSubscription(price) {
    setProcessing(true);
    const priceId = price.id === FREEMIUM_PLAN.id ? FREEMIUM_PLAN.label : price.id;

    // try to create a subscription for the freemium plan
    const response = await subscriptionProvider.create({ id: priceId });
    if (response.data?.status === 'active' || response.data?.status === 'trial') {
      await authProvider.getUser();
      routerActions.push('/advisor/');
    } else toast.error(`Something went wrong activating your ${priceId} subscription.`);

    setTimeout(() => {
      setProcessing(false);
    }, 2000);
  }

  useEffect(() => {
    if (selectedPriceLabel)
      // go to the payment view only if the user has a selected paid price
      routerActions.push({
        pathname: '/checkout/payment/',
        state: { changingSelectedPrice: false }
      });
  }, [selectedPriceLabel]);

  const onSelectPrice = price => {
    if (price.trialDays && trialAvailable) enableSubscription(price);
    else priceProvider.setPrice(price.label);
  };

  return (
    <div id="CheckoutPrices">
      {processing && <SplashLoading text="We are preparing your account" duration={2} />}
      <Plans plans={prices} onSelectPrice={onSelectPrice} />
    </div>
  );
};

CheckoutPrices.contextTypes = {
  user: PropTypes.object.isRequired
};

CheckoutPrices.defaultProps = {
  selectedPriceLabel: null
};

CheckoutPrices.propTypes = {
  location: PropTypes.object.isRequired,
  priceProvider: PropTypes.object.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPriceLabel: PropTypes.string,
  subscriptionProvider: PropTypes.object.isRequired
};

const CheckoutPlansWithRouter = withRouter(CheckoutPrices);

export default connect(
  state => ({
    prices: state.prices.list,
    selectedPriceLabel: state.prices.selected
  }),
  dispatch => ({
    advisorProvider: new AdvisorProvider({ dispatch }),
    priceProvider: new PriceProvider({ dispatch }),
    subscriptionProvider: new SubscriptionProvider({ dispatch })
  })
)(CheckoutPlansWithRouter);
