/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PoweredBy from './poweredByStratifi';

const Disclosure = ({ config }) => {
  const terms = str => (
    <a target="_blank" rel="noopener noreferrer" href="https://www.stratifi.com/terms">
      {str}
    </a>
  );

  const privacy = str => (
    <a target="_blank" rel="noopener noreferrer" href="https://www.stratifi.com/privacypolicy">
      {str}
    </a>
  );

  return (
    <div className="disclosure" style={config.styles}>
      <div className="content">
        <p>
          <FormattedMessage id="disclosure.text" values={{ terms, privacy }} />{' '}
          <PoweredBy imgUrl="/img/logos/stratifi-logo.svg" />
        </p>
      </div>
    </div>
  );
};

Disclosure.defaultProps = {
  config: {}
};

Disclosure.propTypes = {
  config: PropTypes.object
};

export default Disclosure;
