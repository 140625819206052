import { preferredValuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import { isEmpty } from 'lodash';
import CollaborationPagination from 'pages/collaboration-hub/pagination';
import CollaborationSearch from 'pages/collaboration-hub/search';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { CREATE_SUCCESS, DELETE_SUCCESS, EDIT_SUCCESS, ERROR_MESSAGE, TITLE } from './constants';
import './styles.scss';
import TeamDeleteModal from './team-delete';
import TeamEditModal from './team-edit';
import TeamTable from './team-table';

const Teams = () => {
  const { authProvider, teamProvider, user } = useContext(AdvisorContext);

  const [deleteModal, setDeleteModal] = useState({});
  const [editModal, setEditModal] = useState({});
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);
  const [querySearch, setQuerySearch] = useState('');
  const [teamData, setTeamData] = useState([]);

  const editEnabled = authProvider.hasCompliancePermissionsOrAbove(user);

  const initTeamData = () => {
    teamProvider.getList().then(data => {
      setTeamData(data);
    });
  };

  useEffect(() => {
    initTeamData();
  }, []);

  const moemizeDeleteModal = useMemo(() => deleteModal, [JSON.stringify(deleteModal)]);
  const moemizeEditModal = useMemo(() => editModal, [JSON.stringify(editModal)]);

  const handleOnConfirmDelete = useCallback(id => {
    teamProvider
      .deleteTeam(id)
      .then(() => {
        initTeamData();
        setDeleteModal({});
        toast.success(() => DELETE_SUCCESS);
      })
      .catch(() => toast.error(() => ERROR_MESSAGE));
  }, []);

  const handleOnConfirmEdit = useCallback(team => {
    teamProvider
      .updateTeam(team)
      .then(() => {
        initTeamData();
        setEditModal({});
        toast.success(() => EDIT_SUCCESS);
      })
      .catch(() => toast.error(() => ERROR_MESSAGE));
  });

  const handleOnConfirmCreate = useCallback(team => {
    teamProvider
      .createTeam(team)
      .then(() => {
        initTeamData();
        setEditModal({});
        toast.success(() => CREATE_SUCCESS);
      })
      .catch(() => toast.error(() => ERROR_MESSAGE));
  });

  return (
    <div className="team-view">
      <div className="team-view__header">
        <div>
          <h2>{TITLE}</h2>
        </div>
        <div className="team-view__header-actions">
          <CollaborationSearch searchCallback={setQuerySearch} />
          <CollaborationPagination paginationCallback={setPageSize} />
          {editEnabled && (
            <button
              type="button"
              className="btn btn-primary btn-new-team"
              onClick={() => setEditModal({ team: {}, show: true, mode: 'create' })}
            >
              Create Team
            </button>
          )}
        </div>
      </div>
      <div className="team-view__content">
        <TeamTable
          data={teamData}
          callbackDelete={setDeleteModal}
          callbackEdit={setEditModal}
          defaultPageSize={pageSize}
          editEnabled={editEnabled}
          querySearch={querySearch}
        />
      </div>
      {!isEmpty(editModal) && editEnabled && (
        <TeamEditModal
          title={editModal.mode === 'create' ? 'Create' : 'Edit'}
          data={moemizeEditModal}
          onConfirm={editModal.mode === 'create' ? handleOnConfirmCreate : handleOnConfirmEdit}
          onHide={setEditModal}
        />
      )}
      {!isEmpty(deleteModal) && editEnabled && (
        <TeamDeleteModal
          data={moemizeDeleteModal}
          onConfirm={handleOnConfirmDelete}
          onHide={setDeleteModal}
        />
      )}
    </div>
  );
};

export default Teams;
