// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#household-table-container .filters__center .actions {
  display: flex;
  gap: 8px;
}
#household-table-container .filters__center .actions .btn {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/households/list/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,QAAA;AAFJ;AAII;EACE,gBAAA;AAFN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#household-table-container {\n  .filters__center .actions {\n    display: flex;\n    gap: 8px;\n\n    .btn {\n      font-weight: 400;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
