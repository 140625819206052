import clone from 'clone';
import PropTypes from 'prop-types';
import Chart from '..';

class PositionTypeChart extends Chart {
  className = 'position-type-chart';

  getOptions() {
    const themes = this.context.config.chart;
    return {
      ...clone(themes.common.default, false),
      ...clone(themes.pie.default, false),
      ...this.props.options
    };
  }
}

PositionTypeChart.contextTypes = {
  config: PropTypes.object.isRequired
};

export default PositionTypeChart;
