import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ageCalculator } from 'utils/questionnaire';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const BirthdayRetirementQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReportQuestionSkeleton question={question} />;

  const { employment } = question.answer;

  const age = ageCalculator(question.answer.birthday);
  const retirement = Number(question.answer.retirement) || 0;
  const retireIn = retirement - age;

  return (
    <ReportQuestion question={question}>
      <View>
        <Text>{age} years old</Text>
      </View>
      <View>
        <Text>
          {employment ? (
            <Text>{employment}</Text>
          ) : (
            <Text>
              {retireIn > 0 ? `I\u0027m planning to retire in ${retireIn} years` : 'I am retired'}
            </Text>
          )}
        </Text>
      </View>
    </ReportQuestion>
  );
};

BirthdayRetirementQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default BirthdayRetirementQuestion;
