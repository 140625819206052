import { StyleSheet } from '@react-pdf/renderer';
import {
  PRISM_COLOR_0,
  PRISM_COLOR_1,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_4,
  PRISM_COLOR_5,
  PRISM_COLOR_6,
  PRISM_COLOR_7,
  PRISM_COLOR_8,
  PRISM_COLOR_9,
  PRISM_TOLERANCE_COLOR
} from '../../styles';

export const styles = StyleSheet.create({
  body: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
  textContainer: { marginLeft: 15 },
  text: { flexDirection: 'row', alignItems: 'center', fontSize: 12 },
  toleranceText: {
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: PRISM_TOLERANCE_COLOR,
    paddingLeft: 10
  },
  color0: { color: PRISM_COLOR_0 },
  color1: { color: PRISM_COLOR_0 },
  color2: { color: PRISM_COLOR_1 },
  color3: { color: PRISM_COLOR_2 },
  color4: { color: PRISM_COLOR_3 },
  color5: { color: PRISM_COLOR_4 },
  color6: { color: PRISM_COLOR_5 },
  color7: { color: PRISM_COLOR_6 },
  color8: { color: PRISM_COLOR_7 },
  color9: { color: PRISM_COLOR_8 },
  color10: { color: PRISM_COLOR_9 }
});

export default styles;
