import cn from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import TooltipV2 from 'components/tooltip-v2';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './styles.scss';

const ModelAutosuggest = ({ id, onSelect, selected }) => {
  const { modelProvider } = useContext(AdvisorContext);

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onUnselect = () => {
    onSelect(null);
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => (
    <div className="model-list">
      <ScoreBubble
        isStrategy={suggestion.is_strategy}
        score={suggestion.prism_overall}
        className="risk-bubble"
      />
      <div className="model-list__name" data-strategy={suggestion.is_strategy}>
        <span>{suggestion.name}</span>
        {suggestion.is_strategy ? <span>Strategy</span> : null}
      </div>
    </div>
  );

  const renderInputComponent = inputProps => (
    <>
      {selected?.prism_overall ? (
        <ScoreBubble
          isStrategy={selected?.is_strategy}
          score={selected.prism_overall}
          className="risk-bubble"
        />
      ) : null}
      <input {...inputProps} value={_.truncate(inputProps.value, { length: 25 })} />
      {selected ? (
        <button type="button" onClick={onUnselect} className="model-unselect">
          <i role="button" aria-label="Remove Matching Model" className="fs-icon-close-button" />
        </button>
      ) : null}
      <span className={cn('icon-search_icn', { 'with-model': !!inputProps.value })} />
    </>
  );

  /*
   * Autosuggest will call this function every time you need to update suggestions.
   */
  const onSuggestionsFetchRequested = useCallback(
    _.debounce(async ({ value: newValue }) => {
      const params = { is_benchmark: false };
      if (newValue.trim().length) params.search = newValue.trim();
      const result = await modelProvider.es.list(params);
      setSuggestions(result);
    }, 750),
    []
  );

  /*
   * Autosuggest will call this function every time you need to clear suggestions.
   */
  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
    setValue(selected?.name ?? '');
  }, []);

  /*
   * Autosuggest will call this function when ...
   */
  const onSuggestionSelected = (event, { suggestion }) => {
    try {
      onSelect(suggestion);
    } catch (error) {
      // set the name of the original model in case of an error
      setValue(selected?.name ?? '');
    }
  };

  const shouldRenderSuggestions = value => value.trim().length || !selected;

  useEffect(() => {
    if (selected) setValue(selected.name);
  }, [JSON.stringify(selected)]);

  const inputProps = { onChange, placeholder: 'Search model name', value };
  const tooltipId = `model-autocomplete-${id}-tooltip`;
  const tooltipLabel = selected && value ? value : '';

  return (
    <TooltipV2
      className="model-autocomplete-tooltip"
      id={tooltipId}
      label={tooltipLabel}
      place="top"
    >
      <div className="model-autocomplete" data-for={tooltipId} data-tip="">
        <Autosuggest
          id={id}
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderInputComponent={renderInputComponent}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          inputProps={inputProps}
          theme={{
            container: `dropdown react-autosuggest__container ${cn({
              'with-model': !!value
            })}`,
            containerOpen: 'open react-autosuggest__container--open',
            input: 'react-autosuggest__input',
            suggestionsContainer:
              'dropdown-menu dropdown-menu-scale react-autosuggest__suggestions-container',
            suggestion: 'dropdown-item react-autosuggest__suggestion',
            suggestionFocused: 'react-autosuggest__suggestion--focused'
          }}
        />
      </div>
    </TooltipV2>
  );
};

ModelAutosuggest.propTypes = {
  id: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object
};

ModelAutosuggest.defaultProps = {
  id: 'model-autosuggest-id',
  selected: null
};

export default ModelAutosuggest;
