const apiHosts = {
  localhost: 'http://localhost:8000',
  '127.0.0.1': 'http://127.0.0.1:8000'
};

const apiHost = process.env.API_HOST || apiHosts[window.location.hostname] || 'testing-host';
// You could also use 'https://stratifi-be.ngrok.io' in case of an integration

const apiBase = `${apiHost}/api/`;
const authBase = `${apiHost}/api/auth/`;

const apiTokenLabel = 'Authorization';

const apiTokenValue = token => `JWT ${token}`;

const config = {
  apiBase,
  authBase,
  apiHost,
  apiTokenLabel,
  apiTokenValue,

  demo: {
    user: {
      username: 'user11',
      password: 'test'
    }
  },

  prism: {
    rangeNames: {
      GLOBAL_FINANCIAL_CRISIS: 'Global Financial Crisis',
      // EURO_CREDIT_CRISIS_2011: '2011 Euro Credit Crisis',
      BULL_MARKET_2013: '2013 Taper Tantrum',
      // STOCK_MARKET_SELLOFF: '2015–16 Market Sell-off',
      CUSTOM: 'custom'
    },
    scenarios: [
      // under list we have the property names that comes from the backend that are used to map RiskAnalysis.
      'Global Financial Crisis',
      '2011 Euro Credit Crisis',
      '2013 Taper Tantrum',
      '2015-16 Market Selloff',
      'Last 6 months',
      '2019',
      'Coronavirus Pandemic'
    ],
    levels: {
      LOW: 'low',
      NEUTRAL: 'neutral',
      HIGH: 'high'
    },
    scores: {
      values: {
        CONCENTRATED_SCORE: 'concentrated_score',
        VOLATILITY_SCORE: 'volatility_score',
        CORRELATION_SCORE: 'correlation_score',
        TAIL_SCORE: 'tail_score'
      },
      list: ['concentrated_score', 'volatility_score', 'correlation_score', 'tail_score']
    }
  },

  /* Minimum browser versions */
  supportedBrowsers: {
    CHROME: '49',
    FIREFOX: '47',
    OPERA: '40',
    IE: '11',
    EDGE: '13',
    SAFARI: '9.3.1'
  },

  // TODO: temp?
  status: {
    labels: {
      0: 'Undefined'
    }
  },

  invitation: {
    status: {
      values: {
        DRAFT: 1,
        CANCELED: 2,
        SENT: 3,
        RESENT: 4,
        DELIVERED: 5,
        BOUNCE: 6,
        OPENED: 7,
        SIGNEDUP: 9
      },
      labels: {
        1: 'Draft',
        2: 'Canceled',
        3: 'Sent',
        4: 'Resent',
        5: 'Delivered',
        6: 'Bounce',
        7: 'Opened',
        9: 'Signedup'
      }
    }
  },

  company: {
    type: {
      values: {},
      labels: {
        11: 'SEC Registered Investment Advisor',
        12: 'State Registered Investment Advisor',
        13: 'FINRA Registered Investment Advisor',
        21: 'Exempt Reporting Advisor'
      },
      list: [11, 12, 13, 21]
    }
  },

  account: {
    number: {
      numCharsShown: 4,
      hiddenSymbol: 'x'
    },
    status: {
      values: {
        OK: 1,
        SYNCING: 9
      },
      labels: {
        1: 'Ok',
        9: 'Syncing'
      }
    },
    type: {
      values: {
        INDIVIDUAL: 100,
        JOINT: 101,
        REVOCABLE_LIVING_TRUST: 200,
        IRREVOCABLE_LIVING_TRUST: 201,
        LLC: 300,
        S_CORP: 301,
        C_CORP: 302,
        SOLE_PROPRIETORSHIP: 303,
        LLLP_CORP: 304,
        TRADITIONAL_IRA: 400,
        SEP_IRA: 401,
        SIMPLE_IRA: 402,
        ROTH_IRA: 403,
        ROLLOVER_IRA: 404,
        HOUSEHOLD: 500
      },
      labels: {
        100: 'Individual',
        101: 'Joint',
        200: 'Revocable Living Trust',
        201: 'Irrevocable Living Trust',
        300: 'Limited Liability Company',
        301: 'S Corporation',
        302: 'C Corporation',
        303: 'Sole Proprietorship',
        304: 'Limited Liability Limited Partnership',
        400: 'Traditional IRA',
        401: 'Simplified Employee Pension IRA',
        402: 'Savings Incentive Match Plan for Employees IRA',
        403: 'Roth IRA',
        404: 'Rollover IRA',
        500: 'Household Account'
      },
      list: [100, 101, 200, 201, 300, 301, 302, 303, 304, 400, 401, 402, 403, 404]
    },
    document_type: {
      values: {
        TIPMA: 1,
        STANDARD: 2
      },
      labels: {
        1: 'TIPMA',
        2: 'STANDARD'
      },
      list: [1, 2]
    }
  },

  asset: {
    holdingsOrder: ['Equity', 'Fixed Income', 'Cash', 'Real Assets', 'Alternatives', 'Other'],
    classifications: {
      list: [1, 2, 3, 4, 5, 99],
      1: {
        list: [0],
        0: [0]
      },
      2: {
        list: [0, 1, 2],
        0: [0],
        1: [0, 1, 2, 3],
        2: [0, 4, 5, 6]
      },
      3: {
        list: [0, 1, 2, 3],
        0: [0],
        1: [0, 7, 8, 9, 10, 11],
        2: [0],
        3: [0]
      },
      4: {
        list: [0, 4, 5, 6, 99],
        0: [0],
        4: [0],
        5: [0],
        6: [0],
        99: [0]
      },
      5: {
        list: [0, 7, 8],
        0: [0],
        7: [0],
        8: [0]
      },
      99: {
        list: [0, 99],
        0: [0],
        99: [99]
      }
    },
    type: {
      values: {
        NONE: 0,
        CASH: 1,
        EQUITY: 2,
        FIXED_INCOME: 3,
        REAL_ASSETS: 4,
        ALTERNATIVES: 5,
        BALANCED: 6,
        OTHERS: 99
      },
      labels: {
        0: 'Other',
        1: 'Cash',
        2: 'Equity',
        3: 'Fixed Income',
        4: 'Real Assets',
        5: 'Alternatives',
        6: 'Balanced',
        99: 'Other'
      },
      list: [0, 1, 2, 3, 4, 5, 6, 99]
    },
    subtype: {
      values: {
        US: 1,
        INTERNATIONAL: 2,
        EMERGING: 3,
        COMMODITY: 5,
        GLOBAL: 9,
        OTHERS: 99
      },
      labels: {
        0: 'Other',
        1: 'US',
        2: 'International',
        3: 'Emerging',
        4: 'Real Estate',
        5: 'Commodity',
        6: 'Precious Metals',
        7: 'Hedge Fund',
        8: 'Private Equity',
        9: 'Global',
        99: 'Other'
      },
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 99]
    },
    sector: {
      labels: {
        0: 'Other',
        1: 'Large Cap',
        2: 'Mid Cap',
        3: 'Small Cap',
        4: 'Developed',
        5: 'Emerging',
        6: 'Frontier',
        7: 'Treasuries',
        8: 'Agency',
        9: 'Municipal',
        10: 'Investment Grade',
        11: 'High Yield',
        12: 'Credit',
        13: 'Equity Market Neutral',
        14: 'Macro',
        15: 'Relative Value',
        16: 'Volatility',
        17: 'Equity Hedge',
        18: 'Event Driven',
        19: 'Emerging Markets',
        20: 'Aggressive',
        21: 'Moderate',
        22: 'Conservative',
        99: 'Other'
      },
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 99]
    }
  },

  model: {
    status: {
      values: {
        ACTIVE: 1,
        ARCHIVE: 9
      },
      labels: {
        1: 'Active',
        9: 'Archive'
      }
    },
    multiplier: 1000000 // $1 milllion
  },

  strategy: {
    sections: [
      'STRATEGY_OVERVIEW',
      'STRATEGY_EDUCATION',
      'KEY_TAKEAWAYS',
      'KEY_METRICS',
      'RISK_RETURN',
      'MARKET_TREND',
      'STRESS_TESTING',
      'MONTHLY_RETURN',
      'TERMS',
      'DOCUMENTS',
      'DISCLAIMERS'
    ],
    objective: {
      values: {
        GROWTH: 1,
        INCOME: 3,
        PRESERVATION: 5
      },
      labels: {
        1: 'Growth',
        3: 'Income',
        5: 'Preservation'
      },
      list: [1, 3, 5]
    },
    type: {
      values: {
        RISK_REDUCTION: 1,
        RETURN_ENHANCEMENT: 3,
        TAIL_RISK: 5,
        CONCENTRATED_STOCK: 7
      },
      labels: {
        1: 'Diversified Portfolio Risk Reduction',
        3: 'Return Enhancement',
        5: 'Tail Risk Reduction',
        7: 'Single Stock'
      },
      list: [1, 3, 5, 7],
      alphas: [3, 5],
      defaultBenchmarks: {
        1: 'SPY',
        3: 'QAI',
        5: 'BSWN',
        7: 'AAPL'
      }
    },
    name: {
      STRATIFI_RETURN_ENHANCEMENT: 'StratiFi Return Enhancement',
      STRATIFI_TAIL_RISK: 'StratiFi Tail Risk'
    },
    performanceUpdate: {
      values: {
        DAILY: 1,
        MONTHLY: 3,
        QUARTERLY: 5
      },
      labels: {
        1: 'Daily',
        3: 'Monthly',
        5: 'Quarterly'
      },
      list: [1, 3, 5]
    },
    investment: {
      values: {
        USD250000: 250000,
        USD500000: 500000,
        USD1000000: 1000000,
        USD5000000: 5000000
      },
      labels: {
        250000: '> $250000',
        500000: '> $500000',
        1000000: '> $1000000',
        5000000: '> $5000000'
      },
      list: [250000, 500000, 1000000, 5000000]
    },
    metric: {
      // ui only
      values: {
        RETURN: 'annualized_return',
        VOLATILITY: 'annualized_volatility',
        SORTINO: 'sortino_ratio',
        SHARPE: 'sharpe_ratio',
        CALMAR: 'calmar_ratio',
        WINNING: 'winning_ratio',
        LOSING: 'losing_ratio',
        UPSIDE: 'upside_capture',
        DOWNSIDE: 'downside_capture',
        CAGR: 'cagr'
      },
      labels: {
        annualized_return: 'Annualized Return',
        annualized_volatility: 'Annualized Volatility',
        sortino_ratio: 'Sortino Ratio',
        sharpe_ratio: 'Sharpe Ratio',
        calmar_ratio: 'Calmar Ratio',
        winning_ratio: 'Winning Ratio',
        losing_ratio: 'Losing Ratio',
        upside_capture: 'Upside Capture',
        downside_capture: 'Downside Capture',
        cagr: 'CAGR'
      },
      list: [
        'annualized_return',
        'annualized_volatility',
        'sortino_ratio',
        'sharpe_ratio',
        'calmar_ratio',
        'winning_ratio',
        'losing_ratio',
        'upside_capture',
        'downside_capture',
        'cagr'
      ]
    },
    status: {
      values: {
        ACTIVE: 1,
        ANNOUNCED: 3,
        INACTIVE: 9
      }
    },
    value: 1000000 // for charts
  },

  proposal: {
    returnsTypes: ['actual', 'proxy'],
    periods: ['monthly', 'daily'],
    coverage: [0.25, 0.333333334, 0.5, 0.75, 1],
    minCoverage: 25,
    maxCoverage: 100,
    portfolioInit: 1000000,
    status: {
      values: {
        DRAFT: 0,
        GENERATED: 1,
        SENT: 3, // UNOPENED
        REVIEWED: 5,
        ACCEPTED: 7,
        INVESTED: 9
      },
      labels: {
        0: 'Draft',
        1: 'Generated',
        3: 'Sent',
        5: 'Reviewed',
        7: 'Accepted',
        9: 'Invested'
      },
      list: [1, 3, 5, 7, 9]
    }
  },

  document: {
    type: {
      values: {
        STATEMENT: 11,
        STATEMENT_TAX: 12,
        REPORT: 31,
        PRESENTATION: 51
      },
      labels: {
        11: 'Statement',
        12: 'Tax statement',
        31: 'Monthly return',
        51: 'Presentation'
      },
      list: [11, 12, 31, 51]
    }
  },

  address: {
    states: {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      AA: 'Armed Forces Americas',
      AE: 'Armed Forces Europe',
      AP: 'Armed Forces Pacific',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District of Columbia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming'
    }
  },

  chart: {
    common: {
      default: {
        addClassNames: true, // experimental
        fontFamily: 'Roboto',
        fontSize: 12, // sass: bootstrap: ~ $font-size-xs
        color: '#373a3c', // sass: bootstrap: $gray-dark
        balloon: {
          fontSize: 16, // sass: bootstrap: ~ $font-size-md
          color: '#e9ecef', // sass: bootsrap: $gray-200
          fillColor: '#394346', // sass: $graphite-dark
          fillAlpha: 1,
          shadowAlpha: 0,
          borderThickness: 0,
          borderAlpha: 0,
          adjustBorderColor: true,
          horizontalPadding: 12,
          verticalPadding: 8
          // maxWidth: 400,
          // cornerRadius: 3, // pointer works for cornerRadius 0 only
        },
        startDuration: 0
      }
    },
    serial: {
      default: {
        type: 'serial',
        valueAxes: [
          {
            inside: true, // Specifies whether values should be placed inside or outside plot area.
            axisAlpha: 0,
            position: 'right',
            ignoreAxisWidth: true,
            showFirstLabel: false, // Whether to show first axis label (and grid line) or not.
            showLastLabel: false, // Whether to show last axis label (and grid line) or not.
            gridAlpha: 0.075, // experimental
            unit: '%',
            unitPosition: 'right'
          }
        ],
        categoryAxis: {
          gridThickness: 0
        },
        zoomOutText: '' // to hide it
      },
      timeseries: {
        categoryField: 'date',
        categoryAxis: {
          parseDates: true,
          axisThickness: 0,
          gridThickness: 0,
          inside: true
        }
      },
      returnInvestmentHorizon: {
        type: 'serial',
        colors: ['#F0D9D9', '#E1F0DB'],
        theme: 'light',
        fontFamily: 'Roboto',
        addClassNames: true,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          labelsEnabled: false
        },
        valueAxes: [
          {
            axisAlpha: 0,
            gridAlpha: 0,
            autoGridCount: false,
            labelsEnabled: false,
            guides: [
              {
                lineColor: '#000000',
                lineAlpha: 0.1,
                value: 0.0,
                above: true,
                inside: false
              }
            ]
          }
        ],
        graphs: [
          {
            fillAlphas: 1.0,
            lineAlpha: 0.0,
            type: 'column',
            valueField: 'negative',
            clustered: false,
            fixedColumnWidth: window.innerWidth / 30,
            colorField: 'negativeColor',
            color: '#BB3F3D',
            showBalloon: false,
            fontSize: 12,
            labelText: ' ',
            classNameField: 'value-percentage',
            labelFunction(item) {
              if (item.dataContext.showLabel) return `${String(Math.abs(item.values.value))}%`;
              return '';
            }
          },
          {
            fillAlphas: 1.0,
            lineAlpha: 0.0,
            type: 'column',
            valueField: 'positive',
            colorField: 'positiveColor',
            fixedColumnWidth: window.innerWidth / 30,
            clustered: false,
            color: '#6BB44D',
            showBalloon: false,
            fontSize: 12,
            labelText: ' ',
            classNameField: 'value-percentage',
            labelFunction(item) {
              if (item.dataContext.showLabel) return `${String(Math.abs(item.values.value))}%`;
              return '';
            }
          }
        ]
      },
      scoreDistribution: {
        type: 'serial',
        categoryField: 'roundedScore',
        categoryAxis: {
          gridPosition: 'start',
          startOnAxis: true,
          gridColor: '#FFFFFF',
          gridCount: 4,
          tickLength: 0,
          title: 'PRISM Rating'
        },
        valueAxes: [
          {
            id: 'ValueAxis-1',
            fontSize: 11,
            gridColor: '#FFFFFF',
            labelFrequency: 2,
            labelOffset: -3,
            minVerticalGap: 27,
            tickLength: 0,
            title: 'Portfolios'
          }
        ],
        allLabels: [],
        balloon: {}
      },
      scoreDistributionCurrent: {
        graphs: [
          {
            cornerRadiusTop: 1,
            fillAlphas: 1,
            fillColors: ['#4EB63D', '#FAA502', '#C93539'],
            gradientOrientation: 'horizontal',
            id: 'current',
            lineColor: '#666666',
            lineThickness: 2,
            tabIndex: 0,
            title: 'Adjusted',
            type: 'smoothedLine',
            valueField: 'current'
          }
        ]
      },
      scoreDistributionCompare: {
        graphs: [
          {
            cornerRadiusTop: 1,
            fillAlphas: 1,
            fillColors: ['#4EB63D', '#FAA502', '#C93539'],
            gradientOrientation: 'horizontal',
            id: 'adjusted',
            lineColor: '#39E4FD',
            lineThickness: 5,
            tabIndex: 0,
            title: 'Adjusted',
            type: 'smoothedLine',
            valueField: 'adjusted'
          },
          {
            bulletBorderThickness: 0,
            bulletHitAreaSize: -3,
            bulletOffset: -1,
            fillColors: 'undefined',
            gapPeriod: 1,
            id: 'current',
            lineColor: '#666666',
            lineThickness: 2,
            showBalloon: false,
            tabIndex: 0,
            title: 'Current',
            type: 'smoothedLine',
            valueField: 'current'
          }
        ]
      },
      returnsPerformanceCumulative: {
        type: 'serial',
        categoryAxis: {
          gridPosition: 'start',
          dashLength: 2,
          axisAlpha: 0,
          parseDates: true,
          gridAlpha: 0,
          dateFormats: [
            { period: 'fff', format: 'JJ:NN:SS' },
            { period: 'ss', format: 'JJ:NN:SS' },
            { period: 'mm', format: 'JJ:NN' },
            { period: 'hh', format: 'JJ:NN' },
            { period: 'DD', format: 'MMM DD' },
            { period: 'WW', format: 'MM/YYYY' },
            { period: 'MM', format: 'MM/YYYY' },
            { period: 'YYYY', format: 'MM/YYYY' }
          ]
        },
        categoryField: 'date',
        valueAxes: [
          {
            id: 'ValueAxis-1',
            zeroGridAlpha: 0,
            axisAlpha: 0,
            dashLength: 2,
            inside: true,
            showFirstLabel: true,
            showLastLabel: false,
            title: '',
            unit: ''
          }
        ]
      },
      returnsCumulative: {
        pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
        precision: 2,
        zoomOutText: '', // to hide it
        graphs: [
          {
            id: 'overlayed',
            valueField: '0',
            type: 'smoothedLine',
            lineThickness: 2,
            fillAlphas: 1
          },
          {
            id: 'base',
            valueField: '1',
            type: 'line',
            lineThickness: 2,
            dashLengthField: 'dashLength'
          }
        ],
        colors: [
          '#a4def9', // sass: $primary-muted // backup: brand-danger-muted
          '#09adf9' // sass: $primary
        ],
        chartScrollbar: {
          enabled: false
        }
      },
      returnsCumulativeProposalPerformance: {
        valueAxes: [
          {
            inside: true, // Specifies whether values should be placed inside or outside plot area.
            axisAlpha: 0,
            position: 'right',
            ignoreAxisWidth: true,
            showFirstLabel: false, // Whether to show first axis label (and grid line) or not.
            showLastLabel: false, // Whether to show last axis label (and grid line) or not.
            gridAlpha: 0.075, // experimental
            unit: '$',
            unitPosition: 'left'
          }
        ]
      },
      returnsCumulativePerformanceAlpha: {
        pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
        zoomOutText: '', // to hide it
        marginRight: 0,
        fontFamily: 'Roboto',
        marginLeft: 27,
        inside: false,
        color: '#394346',
        fontSize: 13,
        addClassNames: true,
        balloon: {
          borderThickness: 1,
          borderColor: '#dfe1e5',
          color: '#000',
          fillAlpha: 1,
          fillColor: '#ffffff',
          shadowAlpha: 0,
          fontSize: 14,
          textAlign: 'left'
        },
        chartCursor: {
          categoryBalloonEnabled: false,
          enabled: true,
          categoryBalloonAlpha: 0,
          cursorColor: '#000',
          zoomable: false
        },
        graphs: [
          {
            id: 'overlayed',
            valueField: 'overlayed',
            type: 'line',
            lineThickness: 2
          },
          {
            id: 'benchmark',
            valueField: 'benchmark',
            type: 'line',
            lineThickness: 2,
            showBalloon: false
          }
        ],
        colors: ['#09acf8', '#8f9192']
      },
      returnsCumulativePerformance: {
        type: 'serial',
        categoryField: 'date',
        color: '#caf4f9',
        dataDateFormat: 'YYYY-MM-DD',
        autoMargins: false,
        autoMarginOffset: 0,
        marginRight: 0,
        marginTop: 17,
        marginLeft: 50,
        marginBottom: 20,
        graphs: [
          {
            id: 'vxx',
            title: 'VIX Tail Risk ETN',
            valueField: 'vxx',
            type: 'line',
            lineThickness: 2,
            showBalloon: false
          },
          {
            id: 'tail_risk',
            title: 'Tail Risk',
            valueField: 'tail_risk',
            type: 'line',
            lineThickness: 2
          }
        ],
        chartCursor: {
          categoryBalloonEnabled: false,
          enabled: true,
          categoryBalloonAlpha: 0,
          cursorColor: '#FFFFFF',
          zoomable: false
        },
        colors: ['#ffffff', '#9afd1b'],
        categoryAxis: {
          inside: false,
          gridAlpha: 0,
          startOnAxis: true,
          parseDates: true,
          axisColor: '#71AFDB',
          color: '#92DCF8'
        },
        valueAxes: [
          {
            inside: false,
            gridAlpha: 0,
            precision: 0,
            showFirstLabel: false,
            showLastLabel: false,
            color: '#92DCF8',
            axisColor: '#71AFDB'
          }
        ],
        balloon: {
          borderThickness: 0,
          color: '#FFFFFF',
          fillAlpha: 0.65,
          fillColor: '#000000'
        },
        legend: {
          enabled: false, // temp
          align: 'right',
          position: 'top',
          useGraphSettings: false,
          valueText: '',
          useMarkerColorForLabels: true
        }
      },
      returnsEnhancement: {
        type: 'serial',
        color: '#caf4f9',
        categoryField: 'date',
        dataDateFormat: 'YYYY-MM-DD',
        autoMargins: false,
        autoMarginOffset: 0,
        marginRight: 0,
        marginTop: 17,
        marginLeft: 50,
        marginBottom: 20,
        chartCursor: {
          enabled: true,
          categoryBalloonAlpha: 0,
          cursorColor: '#FFFFFF',
          zoomable: false,
          categoryBalloonEnabled: false
        },
        balloon: {
          borderThickness: 0,
          color: '#FFFFFF',
          fillAlpha: 0.35,
          fillColor: '#000000'
        },
        graphs: [
          {
            id: 'money',
            title: 'Money',
            valueField: 'money',
            type: 'line',
            lineThickness: 2,
            lineAlpha: 1,
            fillAlphas: 0.1,
            balloonText: '[[category]] $[[value]]'
          }
        ],
        colors: ['#9DFB43'],
        categoryAxis: {
          inside: false,
          gridAlpha: 0,
          axisColor: '#71AFDB',
          color: '#92DCF8',
          parseDates: true,
          startOnAxis: true
        },
        valueAxes: [
          {
            gridAlpha: 0,
            inside: false,
            position: 'left',
            color: '#92DCF8',
            showFirstLabel: false,
            showLastLabel: false,
            axisColor: '#71AFDB',
            integersOnly: true,
            unitPosition: 'left',
            id: 'investor-performance'
          }
        ],
        legend: {
          enabled: false,
          align: 'right',
          position: 'top',
          useGraphSettings: false,
          valueText: ''
        }
      },
      returnsCumulativePerformanceBeta: {
        pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
        zoomOutText: '', // to hide it
        marginRight: 0,
        fontFamily: 'Roboto',
        marginLeft: 35,
        inside: false,
        color: '#394346',
        fontSize: 13,
        addClassNames: true,
        chartCursor: {
          categoryBalloonEnabled: false,
          enabled: true,
          categoryBalloonAlpha: 0,
          cursorColor: '#000',
          zoomable: false
        },
        balloon: {
          borderThickness: 1,
          borderColor: '#dfe1e5',
          color: '#000',
          fillAlpha: 1,
          fillColor: '#ffffff',
          shadowAlpha: 0,
          fontSize: 14,
          textAlign: 'left'
        },
        graphs: [
          {
            id: 'overlayed',
            valueField: 'overlayed',
            type: 'line',
            lineThickness: 2,
            fillAlphas: 0.1
          },
          {
            id: 'base',
            valueField: 'base',
            type: 'line',
            lineThickness: 2,
            showBalloon: false
          },
          {
            id: 'benchmark',
            valueField: 'benchmark',
            type: 'line',
            lineThickness: 2,
            showBalloon: false
          }
        ],
        colors: ['#09acf8', '#000000', '#adadad'],
        valueAxes: [
          {
            inside: false,
            axisAlpha: 0,
            dashLength: 1.2,
            position: 'left',
            ignoreAxisWidth: true,
            showFirstLabel: true,
            showLastLabel: true,
            gridAlpha: 0.5,
            zeroGridAlpha: 0.1,
            unitPosition: 'right',
            id: 'investor-performance'
          }
        ]
      },
      returnsRisk: {
        pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
        precision: 2,
        graphs: [
          {
            id: 'overlayed',
            valueField: '0',
            type: 'smoothedLine',
            lineThickness: 4
          },
          {
            id: 'base',
            valueField: '1',
            type: 'line',
            lineThickness: 4
          }
        ],
        colors: ['#09adf9', '#a4def9']
      },
      returnsDownside: {
        type: 'serial',
        marginTop: 20,
        marginLeft: 50,
        marginBottom: 50,
        precision: 2,
        valueAxes: [
          {
            axisAlpha: 0,
            position: 'left',
            ignoreAxisWidth: true,
            showFirstLabel: false, // Whether to show first axis label (and grid line) or not.
            showLastLabel: false, // Whether to show last axis label (and grid line) or not.
            gridAlpha: 0.075 // experimental
          }
        ],
        graphs: [
          {
            id: 'overlayed',
            valueField: '0',
            type: 'smoothedLine',
            lineThickness: 2,
            fillAlphas: 1,
            fillToAxis: 'y'
          },
          {
            id: 'base',
            valueField: '1',
            type: 'line',
            lineThickness: 2,
            dashLengthField: 'dashLength'
          }
        ],
        colors: [
          '#a4def9', // sass: $primary-muted // backup: brand-danger-muted
          '#09adf9' // sass: $primary
        ],
        categoryField: 'date',
        categoryAxis: {
          parseDates: true,
          axisColor: '#eeeeee'
        }
      },
      returnsMonthly: {
        precision: 2,
        graphs: [
          { type: 'column', valueField: '0' },
          { type: 'column', valueField: '1' },
          { type: 'column', valueField: '2' }
        ],
        colors: [
          '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
          '#3d99Fc', // sass: $primary
          '#8fddc6' // sass: $success-muted
        ],
        chartScrollbar: {
          // don't use "lines" (only fills), cause it's not possible to set width for lines
          graph: 'base',
          oppositeAxis: false,
          scrollbarHeight: 80,
          offset: 10,
          gridAlpha: 0,
          backgroundAlpha: 0,
          color: '#373a3c', // sass: bootstrap: $gray-dark
          graphFillColor: '#e9ecef', // sass: $gray-200
          selectedGraphFillColor: '#c3ebfd', // sass: $success-muted
          selectedBackgroundColor: '#f8f9fa', // sass: bootstrap: $gray-100
          autoGridCount: true
        }
      },
      returnsBurndownBullAndBear: {
        balloon: {
          color: '#fff',
          borderThickness: 0,
          cornerRadius: 3,
          fillAlpha: 1,
          fillColor: '#45595f',
          shadowAlpha: 0.08,
          shadowColor: '#000'
        },
        chartCursor: {
          cursorColor: '#09ADF9',
          cursorPosition: 'mouse'
        },
        graphs: [
          {
            type: 'smoothedLine',
            lineThickness: 2,
            fillAlphas: 1,
            valueField: '0',
            balloonText: '$[[value]]'
          },
          {
            type: 'line',
            lineThickness: 2,
            valueField: '1',
            balloonText: '$[[value]]'
          }
        ],
        categoryField: '2',
        categoryAxis: {
          axisThickness: 0,
          gridThickness: 0,
          inside: true,
          autoGridCount: false,
          gridCount: 8,
          showFirstLabel: false
        },
        colors: [
          '#a4def9', // sass: $primary-muted // backup: brand-danger-muted
          '#09adf9', // sass: $primary
          '#09adf9' // sass: $primary
        ],
        chartScrollbar: {
          enabled: false
        }
      },
      burndown: {
        precision: 2,
        graphs: [
          { type: 'column', valueField: '0' },
          { type: 'column', valueField: '1' },
          { type: 'column', valueField: '2' }
        ],
        colors: [
          '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
          '#3d99Fc', // sass: $primary
          '#8fddc6' // sass: $success-muted
        ]
      },
      benchmarks: {
        fontSize: 14,
        categoryField: 'type',
        precision: 1,
        categoryAxis: {
          axisThickness: 0,
          gridThickness: 0,
          labelsEnabled: false
        },
        valueAxes: [
          {
            maximum: 50,
            minimum: -50, // to fix zero ruler position (to middle)
            labelsEnabled: false,
            totalTextColor: '#818a91', // sass: $gray-light
            axisColor: '#e0e6e8',
            axisThickness: 0,
            gridThickness: 0,
            stackType: 'regular',
            gridCount: 0,
            zeroGridAlpha: 0.1
          }
        ],
        balloon: { enabled: false },
        graphs: [
          {
            type: 'column',
            valueField: 'valueBase',
            fillAlphas: 1,
            lineColor: '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
            labelText: '[[value]]%',
            labelPosition: 'top',
            columnWidth: 0.5
          },
          {
            type: 'column',
            valueField: 'valueOverlay',
            clustered: false,
            fillAlphas: 1,
            lineColor: '#3d99fc', // sass: $primary
            labelText: '[[value]]%',
            labelPosition: 'top',
            columnWidth: 0.5
          }
        ]
      },
      benchmarksMirrow: {
        graphs: [
          {
            type: 'column',
            valueField: 'valueBasePositive',
            fillAlphas: 1,
            lineColor: '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
            labelText: '[[value]]%',
            labelPosition: 'top',
            columnWidth: 0.5
          },
          {
            type: 'column',
            valueField: 'valueOverlayPositive',
            fillAlphas: 1,
            lineColor: '#3d99Fc', // sass: $primary
            labelText: '[[value]]%',
            labelPosition: 'top',
            columnWidth: 0.5
          },
          {
            type: 'column',
            valueField: 'valueBaseNegative',
            fillAlphas: 1,
            clustered: false,
            lineColor: '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
            labelText: '[[value]]%',
            labelPosition: 'top',
            columnWidth: 0.5
          },
          {
            type: 'column',
            valueField: 'valueOverlayNegative',
            fillAlphas: 1,
            clustered: false,
            lineColor: '#3d99Fc', // sass: $primary
            labelText: '[[value]]%',
            labelPosition: 'top',
            columnWidth: 0.5
          }
        ]
      },
      benchmarksScenarios: {
        columnSpacing: 100,
        valueAxes: [
          {
            maximum: 0,
            labelsEnabled: false,
            totalTextColor: '#818a91', // sass: $gray-light
            axisColor: 'rgba(0, 0, 0, .1)',
            zeroGridAlpha: 0.1,
            axisThickness: 0,
            gridThickness: 0,
            stackType: 'regular',
            gridCount: 0
          }
        ],
        balloon: { enabled: false },
        graphs: [
          {
            type: 'column',
            valueField: 'valueBase',
            fillAlphas: 1,
            lineColor: '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
            columnWidth: 0.5,
            labelText: '[[value]]%',
            labelPosition: 'top'
          },
          {
            type: 'column',
            valueField: 'valueOverlay',
            clustered: false,
            fillAlphas: 1,
            lineColor: '#3d99Fc', // sass: $primary
            columnWidth: 0.5,
            labelText: '[[value]]%',
            labelPosition: 'top'
          },
          {
            type: 'column',
            valueField: 'maximum',
            clustered: false,
            fillAlphas: 0,
            lineAlpha: 0,
            bulletAlpha: 0,
            balloonText: '[[value]]%'
          }
        ]
      }
    },
    bubble: {
      default: {
        type: 'xy', // aka bubble chart
        theme: 'light'
      },
      distribution: {
        autoMargins: true,
        autoMarginOffset: 10,
        marginTop: 20,
        balloon: {
          fixedPosition: true,
          fillColor: '#000000',
          borderAlpha: 0,
          color: '#ffffff'
        },
        valueAxes: [
          {
            position: 'bottom',
            maximum: 11,
            minimum: 0,
            autoGridCount: false,
            gridCount: 11,
            axisAlpha: 0,
            zeroGridAlpha: 0,
            gridAlpha: 0.2,
            labelFunction: (e, val) => (val > 10 || val < 1 ? '' : val),
            labelFrequency: 1,
            dashLength: 2,
            gridThickness: 1,
            axisTitleOffset: 0,
            labelsEnabled: false
          },
          {
            axisAlpha: 0,
            zeroGridAlpha: 0,
            position: 'left',
            gridThickness: 0,
            title: 'Number of accounts',
            labelFunction: (e, val) => (val >= 0 ? val : ''),
            integersOnly: true
          }
        ],
        graphs: [
          {
            balloonText: `
              <span class="balloon-score-risk balloon-score-risk-[[rounderScore]]">[[x]]</span>
              <span class="score-info">[[name]]<br>$[[value]]</span>`,
            bullet: 'circle',
            minBulletSize: 14,
            maxBulletSize: 38,
            bulletBorderAlpha: 0,
            bulletAlpha: 0.6,
            lineAlpha: 0,
            fillAlphas: 0,
            valueField: 'value',
            xField: 'x',
            yField: 'y',
            classNameField: 'bulletclass'
          }
        ],
        marginLeft: 46,
        marginBottom: 35
      },
      scenarios: {
        graphs: [
          {
            // base graph (aka underline)
            // these values should go before overlayed, as it's supposed to be bigger
            // (to not overlap overlayed values)
            bullet: 'round',
            xField: 'xField',
            yField: 'yField',
            valueField: '0',
            lineAlpha: 0,
            lineColor: '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
            minBulletSize: 30,
            maxBulletSize: 150,
            showBalloon: false
          },
          {
            // overlayed graph
            bullet: 'round',
            xField: 'xField',
            yField: 'yField',
            valueField: '1',
            lineAlpha: 0,
            lineColor: '#3d99Fc', // sass: $primary
            minBulletSize: 30,
            maxBulletSize: 150,
            showBalloon: false
          },
          {
            // fake graph // for max fields
            bullet: 'round',
            xField: 'xField',
            yField: 'yField',
            valueField: 'maximum',
            lineAlpha: 0,
            bulletAlpha: 0,
            fillAlphas: 0,
            minBulletSize: 30,
            maxBulletSize: 150,
            showBalloon: false
          }
        ],
        valueAxes: [
          {
            minimum: -1, // to center chart
            maximum: 1, // to center chart
            axisAlpha: 0,
            gridThickness: 0,
            zeroGridAlpha: 0,
            labelsEnabled: false
          },
          {
            position: 'bottom', // don't remove to not to be ignored
            minimum: -1, // to center chart
            maximum: 1, // to center chart
            axisAlpha: 0,
            gridThickness: 0,
            zeroGridAlpha: 0,
            labelsEnabled: false
          }
        ]
      },
      returnsRisk: {
        marginTop: 20,
        marginLeft: 70,
        marginBottom: 50,
        graphs: [
          {
            // base graph (aka underline)
            // these values should go before overlayed, as it's supposed to be bigger
            // (to not overlap overlayed values)
            bullet: 'round',
            xField: 'x0',
            yField: 'y0',
            valueField: '0',
            lineAlpha: 0,
            lineColor: '#c3e9fe', // sass: $primary-muted // backup: brand-danger-muted
            minBulletSize: 30,
            maxBulletSize: 30,
            showBalloon: false
          },
          {
            // overlayed graph
            bullet: 'round',
            xField: 'x1',
            yField: 'y1',
            valueField: '1',
            lineAlpha: 0,
            lineColor: '#3d99Fc', // sass: $primary
            minBulletSize: 30,
            maxBulletSize: 30,
            showBalloon: false
          }
        ],
        valueAxes: [
          {
            position: 'left',
            axisAlpha: 0,
            zeroGridAlpha: 0,
            minMaxMultiplier: 1.5
          },
          {
            position: 'bottom', // don't remove to not to be ignored
            axisAlpha: 0,
            zeroGridAlpha: 0,
            fontSize: 14,
            minMaxMultiplier: 1.5
          }
        ],
        allLabels: [
          {
            text: 'Annualized Return',
            x: '2%',
            y: '50%',
            bold: true,
            align: 'middle',
            rotation: -90
          },
          {
            text: 'Annualized Volatility',
            x: '50%',
            y: '95%',
            bold: true,
            align: 'middle'
          }
        ]
      }
    },
    bar: {
      medianPortfolioMove: {
        type: 'serial',
        categoryField: 'title',
        graphs: [
          {
            fillAlphas: 1,
            id: 'median-portfolio-move-value',
            type: 'column',
            valueField: 'portfolio',
            valueText: '[[portfolio]]%',
            title: 'Model Portfolio',
            balloonFunction(item) {
              return `${parseFloat(item.values.value).toFixed(2)}%`;
            }
          },
          {
            fillAlphas: 1,
            id: 'median-market-move-value',
            type: 'column',
            valueField: 'market',
            valueText: '[[market]]%',
            title: 'Benchmark 60/40 Portfolio',
            balloonFunction(item) {
              return `${parseFloat(item.values.value).toFixed(2)}%`;
            }
          }
        ],
        valueAxes: [
          {
            guides: [
              {
                value: -100,
                fillColor: '#F00',
                inside: true,
                fillAlpha: 0.2,
                lineAlpha: 0
              }
            ]
          }
        ],
        legendEnabled: false,
        legend: {
          useGraphSettings: true,
          position: 'right',
          labelWidth: 80,
          valueWidth: 40
        },
        colors: ['#09adf9', '#a4def9'],
        theme: 'light',
        fontSize: 10
      },
      assetCorrelation: {
        type: 'serial',
        categoryField: 'title',
        colors: ['#09adf9'],
        graphs: [
          {
            closeField: 'max',
            fixedColumnWidth: 1,
            id: 'AmGraph-1',
            lineThickness: 12,
            openField: 'min',
            title: 'graph 1',
            type: 'column'
          }
        ],
        valueAxes: [
          {
            id: 'ValueAxis-1',
            stackType: 'regular',
            title: 'Correlation',
            minimum: -1,
            maximum: 1
          }
        ]
      }
    },
    pie: {
      default: {
        type: 'pie',
        valueField: 'value',
        titleField: 'title',
        pullOutDuration: 0,
        pullOutRadius: 0,
        startDuration: 0,
        labelsEnabled: false,
        addClassNames: true,
        precision: 0,
        radius: '30%', // 50% doesn't work
        innerRadius: '60%',
        colors: ['#31E3FF', '#FFFFFF']
      },
      multiPie: {
        baseRow: {
          labelsEnabled: false,
          pullOutRadius: '0%',
          colors: ['#394247', '#7B8C94'],
          showBalloon: () => {}
        },
        firstRow: {
          labelsEnabled: false,
          labelRadius: 14,
          pullOutRadius: '0%',
          innerRadius: '75%',
          showBalloon: () => {}
        },
        secondRow: {
          labelsEnabled: false,
          labelRadius: 20,
          pullOutRadius: '0%',
          innerRadius: '75%',
          showBalloon: () => {}
        }
      },
      orderedAssetClasses: [
        'equity',
        'fixed income',
        'cash',
        'real assets',
        'alternatives',
        'other'
      ],
      orderedAssetClassColors: ['#B5DF0C', '#4B93EE', '#77E5D6', '#3D5965', '#e0e0e0', '#A6BEDB'],
      positions: {
        balloonText: '[[title]] [[percents]]%',
        balloon: {
          fontSize: 14,
          color: '#FFFFFF',
          fillColor: '#000000',
          fillAlpha: 0.7,
          borderThickness: 0
        },
        colors: [
          '#31e3ff', // sass: $info
          '#a2b0b7',
          '#bdc9d0',
          '#e6ebee'
        ]
      },
      assetDiversification: {
        legend: {
          enabled: true,
          align: 'center',
          markerType: 'circle',
          markerSize: 8,
          position: 'right',
          valueWidth: 24,
          valueText: '[[value]]%',
          verticalGap: 3
        },
        balloon: {
          enabled: false
        },
        innerRadius: '25%',
        colors: [
          '#14aaf9',
          '#83cefc',
          '#16e5af',
          '#d97632',
          '#c8e23c',
          '#8029e5',
          '#98c670',
          '#3b556a',
          '#c4161c'
        ]
      },
      volatilityDiversification: {
        legend: {
          enabled: true,
          align: 'center',
          markerType: 'circle',
          markerSize: 8,
          position: 'right',
          valueWidth: 24,
          valueText: '[[value]]%',
          verticalGap: 3
        },
        balloon: {
          enabled: false
        },
        innerRadius: '25%',
        colors: ['#3b556a', '#09acf8']
      },
      positionsDonut: {
        balloon: {
          borderThickness: 0,
          color: '#FFFFFF',
          fillColor: '#000000',
          fillAlpha: 0.7
        },
        innerRadius: '50%',
        allLabels: [],
        colors: ['#0c3348', '#114e6e', '#2c6c95']
      },
      positionsOverlay: {
        balloon: {
          enabled: false
        },
        colors: [
          '#3d99fc', // sass: $primary
          'rgba(0, 0, 0, 0)'
        ]
      },
      positionsTypeAsset: {
        showBalloon: false,
        colorField: 'color'
      },
      assetColors: {
        colorField: 'color'
      },
      positionsType: {
        showBalloon: false,
        colors: [
          '#31e3ff', // sass: $info
          'rgba(189, 201, 208, 0.2)',
          '#bdc9d0',
          '#e6ebee'
        ]
      },
      positionsTypeOverlay: {
        radius: '47%', // 49% doesn't work
        innerRadius: '75%',
        showBalloon: false,
        colors: [
          '#09adf9', // sass: $primary
          '#e9ecef', // sass: $gray-200
          '#bdc9d0',
          '#e6ebee'
        ]
      },
      proposals: {
        radius: '47%', // 49% doesn't work
        innerRadius: '75%',
        balloonText: '[[title]]<br>[[percents]]%',
        colors: [
          '#31e3ff', // sass: $info
          '#a2b0b7',
          '#bdc9d0',
          '#e6ebee'
        ]
      },
      singleStockLimit: {
        type: 'pie',
        startDuration: 0,
        balloonText: '',
        innerRadius: '60%',
        labelText: '',
        pullOutRadius: 0,
        labelsEnabled: false,
        outlineAlpha: 1,
        outlineThickness: 10,
        titleField: 'category',
        valueField: 'value',
        colorField: 'color',
        tapToActivate: false,
        allLabels: [],
        balloon: {
          disableMouseEvents: true
        },
        legend: {
          enabled: false
        },
        titles: []
      }
    },
    partials: {
      guide: {
        fillAlpha: 0.2,
        fillColor: '#394346', // sass: $graphite-dark
        lineThickness: 0
      }
    },
    gauge: {
      dashboardVolatility: {
        type: 'gauge',
        arrows: [
          {
            id: 'GaugeArrow-1',
            nailBorderThickness: 0,
            nailRadius: 0,
            radius: '80%',
            color: '#77888F'
          }
        ],
        axes: [
          {
            axisThickness: 0,
            endAngle: 90,
            endValue: 40,
            id: 'GaugeAxis-1',
            startAngle: -90,
            valueInterval: 1000,
            labelOffset: -40,
            tickThickness: 0,
            bands: [
              {
                color: '#91DBFE',
                endValue: 15,
                id: 'GaugeBand-1',
                innerRadius: '90%',
                startValue: 0
              },
              {
                color: '#407CB2',
                endValue: 25,
                id: 'GaugeBand-2',
                innerRadius: '90%',
                startValue: 15
              },
              {
                color: '#1B2C3C',
                endValue: 40,
                id: 'GaugeBand-3',
                innerRadius: '90%',
                startValue: 25
              }
            ]
          }
        ],
        allLabels: [],
        balloon: {},
        titles: []
      },
      riskScore: {
        type: 'gauge',
        arrows: [
          {
            color: '#4DC950',
            id: 'risk-arrow',
            nailRadius: 12,
            radius: '40%',
            startWidth: 23
          }
        ],
        axes: [
          {
            axisAlpha: 0,
            axisThickness: 0,
            bandOutlineThickness: 4,
            bottomText: '',
            bottomTextYOffset: -20,
            endValue: 10,
            id: 'GaugeAxis-1',
            labelsEnabled: false,
            minorTickLength: 11,
            tickThickness: 0,
            valueInterval: 10,
            bands: [
              {
                alpha: 1,
                color: '#66CB33',
                endValue: 1,
                id: 'GaugeBand-1',
                startValue: 0
              },
              {
                color: '#89C332',
                endValue: 2,
                id: 'GaugeBand-2',
                startValue: 1
              },
              {
                color: '#AABC31',
                endValue: 3,
                id: 'GaugeBand-3',
                startValue: 2
              },
              {
                color: '#C5B430',
                endValue: 4,
                id: 'GaugeBand-4',
                startValue: 3
              },
              {
                color: '#E4A82F',
                endValue: 5,
                id: 'GaugeBand-5',
                startValue: 4
              },
              {
                color: '#F29B2E',
                endValue: 6,
                id: 'GaugeBand-6',
                startValue: 5
              },
              {
                color: '#EE772D',
                endValue: 7,
                id: 'GaugeBand-7',
                startValue: 6
              },
              {
                color: '#E6672F',
                endValue: 8,
                id: 'GaugeBand-8',
                startValue: 7
              },
              {
                color: '#D54D36',
                endValue: 9,
                id: 'GaugeBand-9',
                startValue: 8
              },
              {
                color: '#C7393D',
                endValue: 10,
                id: 'GaugeBand-10',
                startValue: 9
              }
            ]
          }
        ],
        allLabels: [
          {
            y: 190,
            x: 80,
            size: 14,
            color: '#66CB33',
            text: '1',
            bold: true
          },
          {
            y: 190,
            x: 200,
            size: 14,
            color: '#C7393D',
            text: '10',
            bold: true
          }
        ],
        balloon: {},
        titles: []
      },
      targetScoreResult: {
        startDuration: 0,
        arrows: [
          {
            color: '#92dcf8',
            id: 'risk-arrow',
            nailRadius: 9,
            radius: '40%',
            startWidth: 17
          }
        ],
        allLabels: [
          {
            y: '82%',
            x: '24%',
            size: 15,
            color: '#66CB33',
            text: '1',
            bold: true
          },
          {
            y: '82%',
            x: '69%',
            size: 15,
            color: '#C7393D',
            text: '10',
            bold: true
          }
        ]
      },
      prismTargetScoreOverall: {
        startDuration: 0,
        arrows: [
          {
            color: '#92dcf8',
            id: 'risk-arrow',
            nailRadius: 9,
            radius: '55%',
            startWidth: 17
          },
          {
            color: '#92dcf8',
            id: 'risk-arrow-2',
            nailRadius: 9,
            radius: '40%',
            startWidth: 17
          }
        ],
        allLabels: [
          {
            y: '82%',
            x: '24%',
            size: 15,
            color: '#66CB33',
            text: '1',
            bold: true
          },
          {
            y: '82%',
            x: '69%',
            size: 15,
            color: '#C7393D',
            text: '10',
            bold: true
          }
        ]
      }
    }
  },

  MATCH_MODELS_DIFFERENCE: 2
};

export default config;
