import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

import Widget from 'components/advisor/dashboard/widgets';
import useWidget, {
  DATASETS,
  widgetStorePropTypes,
  widgetStoreSelector
} from 'components/advisor/dashboard/widgets/hooks';
import { AdvisorContext } from 'containers/advisor';

import config from '../../../../../config';

import './styles.scss';

const { prism } = config;

const ROUTES = {
  [DATASETS.ACCOUNTS]: '/advisor/accounts/by-risk-level',
  [DATASETS.CLIENTS]: '/advisor/investors',
  [DATASETS.HOUSEHOLDS]: '/advisor/households',
  [DATASETS.PROSPECTS]: '/advisor/prospects'
};

const RiskLevelWidget = ({ store }) => {
  const fetch = (provider, params) => provider.es.getPrismDistribution(params);
  const { data, empty, dataset, ...widget } = useWidget({
    fetch,
    store
  });

  const { advisorByManager, routerActions } = useContext(AdvisorContext);

  const onClick = level => {
    const bucket = data[level];
    if (bucket) {
      const query = { by_level: level };
      if (advisorByManager !== 0) query.by_advisor = advisorByManager ?? store.user.advisor.id;
      routerActions.push({
        pathname: ROUTES[dataset],
        query
      });
    }
  };

  const title = (
    <>
      PRISM Rating <sup className="prism-rating-tm">TM</sup> Across {dataset}
    </>
  );

  return (
    <Widget {...widget} dataset={dataset} empty={empty} title={title} id="RiskLevelWidget">
      {!empty && (
        <div className="risk-content">
          <div>
            <div className="flex justify-between">
              <button
                type="button"
                className="risk-card top risk-card-low"
                onClick={() => onClick(prism.levels.LOW)}
              >
                {data.low}
              </button>
              <button
                type="button"
                className="risk-card top risk-card-neutral"
                onClick={() => onClick(prism.levels.NEUTRAL)}
              >
                {data.neutral}
              </button>
              <button
                type="button"
                className="risk-card top risk-card-high"
                onClick={() => onClick(prism.levels.HIGH)}
              >
                {data.high}
              </button>
            </div>
            <div className="flex justify-between fade">
              <button
                type="button"
                className="risk-card bottom risk-card-low"
                onClick={() => onClick(prism.levels.LOW)}
              >
                <FormattedNumber value={data.low_value} format="currency" />
              </button>
              <button
                type="button"
                className="risk-card bottom risk-card-neutral"
                onClick={() => onClick(prism.levels.NEUTRAL)}
              >
                <FormattedNumber value={data.neutral_value} format="currency" />
              </button>
              <button
                type="button"
                className="risk-card bottom risk-card-high"
                onClick={() => onClick(prism.levels.HIGH)}
              >
                <FormattedNumber value={data.high_value} format="currency" />
              </button>
            </div>
          </div>

          <div className="flex legend">
            <span className="risk-label risk-label-low">Low </span>
            <span className="risk-label risk-label-neutral">Moderate </span>
            <span className="risk-label risk-label-high">High </span>
          </div>
        </div>
      )}
    </Widget>
  );
};

RiskLevelWidget.propTypes = {
  store: PropTypes.shape(widgetStorePropTypes).isRequired
};

export default connect(state => ({
  store: widgetStoreSelector('prismDistribution')(state)
}))(RiskLevelWidget);
