import { FormGroup } from 'components/form';
import Choice from 'components/form/choice';
import { CustomerSupportEmailLink } from 'constants/contact';
import { ACTIVE_2FA, DEVICES_2FA } from 'containers/advisor/security/two-factor/utils/constants';
import { propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PinField from 'react-pin-field';
import './styles.scss';

const EMAIL_METHOD = 'email';
const APP_METHOD = 'app';

const PinCodeForm = ({ disabledMehod, devices, fields, onSubmit, pinRef, submitting }) => {
  const [formType, setFormType] = useState('app');
  const [showHelp, setShowHelp] = useState(false);

  const upperMethod = formType.toUpperCase();

  const enabledDevicesTitle = () => {
    if (formType === 'email') return DEVICES_2FA.EMAIL_METHOD_INPUT;
    return DEVICES_2FA.APP_METHOD_INPUT;
  };

  useEffect(() => {
    // by default, it changes the option in charge of remembering the device to avoid
    // requesting the verification code at the next login. This is done this way and not
    // through the `initialValues` of redux-form to ensure that the option is set to `true`
    // only when this component is rendered, and not throughout the entire login flow,
    // generating unexpected behavior (e.g. updating the validity date of the trusted device
    // when it's not necessary)
    fields.remember.onChange(true);
  }, []);

  useEffect(() => {
    if (devices?.length === 1 && devices[0] !== formType) setFormType(devices[0]);
  }, [devices]);

  return (
    <div className="signup-form">
      <h1 className="text-sm-center signup-title">{ACTIVE_2FA.TITLE}</h1>
      <br />
      <div className="mt-2">
        <form onSubmit={onSubmit} autoComplete="on">
          <FormGroup className="form-group mb-2" {...fields.pin}>
            <label htmlFor="pin">{enabledDevicesTitle()}</label>
            <div className="pin-field-container">
              <PinField
                name={fields.pin.name}
                className="pin-field"
                length={6}
                onChange={fields.pin.onChange}
                type="text"
                autoFocus
                validate="0123456789"
                inputMode="numeric"
              />
            </div>
          </FormGroup>

          <Choice
            {...fields.remember}
            toggle={fields.remember.onChange}
            title={DEVICES_2FA.REMEMBER_DEVICE}
          />

          <p className="helper-text">
            {devices?.length === 2 && (
              <button
                type="button"
                disabled={disabledMehod}
                onClick={() => {
                  const method = formType === APP_METHOD ? EMAIL_METHOD : APP_METHOD;
                  setFormType(method);
                  fields?.device_type?.onChange(method);
                }}
                className="btn btn-link btn-help"
                aria-label="help"
              >
                <b>Use your {formType === APP_METHOD ? 'email' : 'app'} verification method</b>
              </button>
            )}
            <br />
            <button
              type="button"
              onClick={() => setShowHelp(!showHelp)}
              className="btn btn-link btn-help"
              aria-label="help"
            >
              Having trouble?
            </button>
          </p>

          {showHelp ? (
            <p className="helper-text">
              {DEVICES_2FA.HELPER_TEXT[upperMethod][0]}
              <br />
              <br />
              {DEVICES_2FA.HELPER_TEXT[upperMethod][1]}
              <br />
              <br />
              {DEVICES_2FA.HELPER_TEXT[upperMethod][2]} <CustomerSupportEmailLink />{' '}
              {DEVICES_2FA.HELPER_TEXT[upperMethod][3]}
            </p>
          ) : null}

          <div className="text-sm-center pt-2">
            <button
              type="submit"
              className="btn btn-primary btn-block center-block"
              disabled={submitting || fields.pin.value === ''}
            >
              {submitting ? DEVICES_2FA.AUTHENTICATING : DEVICES_2FA.VERIFY_CODE}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

PinCodeForm.propTypes = {
  disabledMehod: PropTypes.bool.isRequired,
  devices: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pinRef: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

export default PinCodeForm;
