import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import {
  investorAccountsWithHouseholdSelector,
  investorSelector
} from 'components/advisor/investors/selectors';
import {
  INVESTOR_PROPOSAL_TYPE,
  MODE_URL_PARAM,
  PROSPECT_PROPOSAL_TYPE,
  READ_ONLY_MODE
} from 'components/advisor/proposal/constants';
import SignatureRequestTrail from 'components/advisor/proposal/header/investor/form/signature-request-trail';
import { setProposalSigners } from 'components/advisor/templates/sections/signature/digital-signature/utils';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import withSubscription from 'hocs/subscription-validation';
import _ from 'lodash';
import {
  REVIEWED,
  hasReportReviews,
  isReportReadOnly
} from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReportViewer from 'reports/viewer';
import { allModelsWithPrismSelector } from 'selectors/models';
import { ipsTemplateListSelector } from 'selectors/templates';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getReportUrl, getTargetInvestors } from 'utils/utils';
import IPSBody from './body';
import { IPSPropTypes } from './body/sections/types';
import IPSHeader from './header';
import './styles.scss';
import prepareMetadata from './utils';

const IPS = ({ accounts, currentReport, investor, ipsCharts, ips, models, params, templates }) => {
  const {
    accountProvider,
    clientProvider,
    investorProvider,
    ipsProvider,
    modelProvider,
    proposalProvider,
    prospectProvider,
    templateProvider,
    user
  } = useContext(AdvisorContext);

  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [managers, setManagers] = useState([]);

  const query = new URLSearchParams(window.location.search);
  const ready = !loading && !_.isEmpty(ips);
  const showReadOnlyReport = currentReport?.status !== REVIEWED || currentReport?.signed_pdf_report;

  const isProspect = window.location.pathname.includes('prospects');
  const isReadOnly =
    query.get(MODE_URL_PARAM) === READ_ONLY_MODE || isReportReadOnly(currentReport?.status);

  useEffect(() => {
    if (ips?.proposal?.target) {
      const investors = getTargetInvestors(ips.proposal);
      investors.forEach(investor => {
        const provider = investor.is_prospect ? prospectProvider : investorProvider;
        provider.getManagers(investor.id).then(managers => {
          setManagers(prevManagers => _.uniqBy(prevManagers.concat(managers), 'id'));
        });
      });
    }
  }, [JSON.stringify(ips?.proposal?.target)]);

  useEffect(() => {
    const initialLoad = [
      accountProvider.clearAccount(),
      clientProvider.get(params.id),
      clientProvider.getAccountsWithHousehold(params.id),
      templateProvider.list()
    ];

    setMainLoading(true);
    Promise.all(initialLoad).finally(() => {
      setMainLoading(false);
    });

    if (params.reportId) {
      setLoading(true);
      ipsProvider
        .getReport(params.reportId, params.id, isProspect)
        .then(async ({ data }) => {
          await ipsProvider.get(data.ips);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    modelProvider.listAll();

    return () => {
      ipsProvider.clear();
      ipsProvider.clearCurrentReport();
    };
  }, []);

  const generateIPS = values => {
    setLoading(true);
    const proposal = { ...values, start: values.period[0], end: values.period[1] };
    return ipsProvider
      .create({ proposal })
      .then(response => {
        trackAmplitudeEvent('ips.generated', {
          id: response.data.id,
          investor: investor.id,
          is_prospect: investor.is_prospect,
          with_recommended: !!proposal.recommended.length
        });
        return response;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveChanges = (key, properties) => {
    const content = prepareMetadata(ips, user);
    const propertyContent = content[key];

    setActionsDisabled(true);
    return proposalProvider
      .update(ips.proposal.id, {
        template_content: { ...content, [key]: { ...propertyContent, ...properties } }
      })
      .then(async () => {
        await ipsProvider.get(ips.id);
        if (currentReport) ipsProvider.setCurrentReportUnsaved(true);
      })
      .finally(() => {
        setActionsDisabled(false);
      });
  };

  const setInitialSigners = () => {
    if (user?.advisor?.company?.digital_signature_enabled) {
      const content = prepareMetadata(ips, user);
      setActionsDisabled(true);
      setProposalSigners(content, ips.proposal, proposalProvider)
        .then(async () => {
          await ipsProvider.get(ips.id);
          if (currentReport) ipsProvider.setCurrentReportUnsaved(true);
        })
        .finally(() => {
          setActionsDisabled(false);
        });
    }
  };

  const toggleVisibility = key => () => {
    const content = prepareMetadata(ips, user);
    return saveChanges(key, { hidden: !content[key].hidden });
  };

  const toggleCustomAction = key => actionId => () => {
    const content = prepareMetadata(ips, user);
    const section = content[key];
    if (section.customActions && section.customActions[actionId]) {
      const newSectionContent = {
        ...section,
        customActions: {
          ...section.customActions,
          [actionId]: {
            ...section.customActions[actionId],
            value: !section.customActions[actionId].value
          }
        }
      };
      return saveChanges(key, newSectionContent);
    }
    return null;
  };

  if (_.isEmpty(accounts) || mainLoading) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="IPS" className="ips">
      <div className="ips-container">
        <IPSHeader
          accountOptions={accounts.filter(a => !!a.prism_score_summary && !!a.target_score_summary)}
          entityId={ips?.id}
          generateIPS={generateIPS}
          loading={loading}
          managers={managers}
          modelOptions={models}
          proposalType={investor.is_prospect ? PROSPECT_PROPOSAL_TYPE : INVESTOR_PROPOSAL_TYPE}
          ready={ready}
          scope={investor}
          securityOptions={[]}
          templateOptions={templates}
        />

        {loading && !isReadOnly && (
          <LoadingPrismDataMessage
            message={params.reportId ? 'Getting IPS...' : 'Generating IPS...'}
            inset
          />
        )}

        {ready && !isReadOnly && (
          <IPSBody
            actionsDisabled={actionsDisabled}
            disclosure={null}
            ips={ips}
            ipsCharts={ipsCharts}
            isInvestorIPS
            isReadOnly={isReadOnly}
            managers={managers}
            saveChanges={saveChanges}
            setInitialSigners={setInitialSigners}
            toggleCustomAction={toggleCustomAction}
            toggleVisibility={toggleVisibility}
          />
        )}

        {isReadOnly && showReadOnlyReport && <ReportViewer url={getReportUrl(currentReport)} />}

        {isReadOnly && hasReportReviews(currentReport?.status) && (
          <SignatureRequestTrail report={currentReport} reviews={currentReport.reviews} />
        )}
      </div>
    </div>
  );
};

IPS.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  currentReport: PropTypes.object,
  investor: PropTypes.object,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  ipsCharts: PropTypes.object.isRequired,
  models: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired
};

IPS.defaultProps = {
  accounts: [],
  currentReport: null,
  investor: {},
  models: []
};

export default compose(
  withSubscription({
    plan: 'professional',
    id: 'ips',
    name: 'Investment Policy Statements',
    inline: true
  }),
  connect(state => ({
    accounts: investorAccountsWithHouseholdSelector(state),
    currentReport: state.ips.currentReport,
    investor: investorSelector(state),
    ips: state.ips.view,
    ipsCharts: state.ips.charts,
    models: allModelsWithPrismSelector(state),
    templates: ipsTemplateListSelector(state)
  }))
)(IPS);
