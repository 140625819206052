import cn from 'classnames';
import { FormGroup, InputButton, VerboseErrorInput } from 'components/form';
import QuestionMark from 'components/utils/question-mark';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { trackAmplitudeEvent } from 'utils/tracking';
import {
  COMPANY_SHARING_OPTION,
  MODEL_TYPES,
  PRIVATE_VISIBILITY,
  PUBLIC_VISIBILITY,
  STRATEGY_MESSAGE
} from '../constants';
import './styles.scss';

const controlDefaultStyles = (styles, state) => ({
  ...styles,
  boxShadow: 'none',
  borderColor: state.isFocused ? '#09acf8' : styles.borderColor,
  '&:hover': {
    borderColor: state.isFocused ? '#09acf8' : '#ced4da'
  }
});

const ModelMetaForm = ({
  isBenchmark,
  isStrategy,
  name,
  onFormChange,
  owner,
  team,
  trackingParams,
  type,
  visibility
}) => {
  const { teamProvider, authProvider, user } = useContext(AdvisorContext);
  const [share, setShare] = useState(false);
  const [sharingOptions, setSharingOptions] = useState([]);

  const isPublic = visibility.initialValue === PUBLIC_VISIBILITY;
  const isShared = isPublic || !!team.initialValue;
  const hasPermissionsToCreateStrategies = user?.advisor?.company?.allow_creating_strategies;
  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const showShare = user?.advisor?.company?.allow_advisors_sharing_models;
  const hasPermissionsToEditSharing = !owner || (owner && owner === user.advisor.id && showShare);
  const hasPermissionsToCreateBenchmarks =
    user?.advisor?.company?.allow_advisors_creating_benchmarks;

  const setPublicSharing = () => {
    team.onChange(null);
    visibility.onChange(PUBLIC_VISIBILITY);
  };

  const setPrivateSharing = () => {
    team.onChange(null);
    visibility.onChange(PRIVATE_VISIBILITY);
  };

  const onSharingOptionChange = ({ value }) => {
    if (onFormChange) onFormChange();
    if (value) {
      team.onChange(value);
      visibility.onChange(PRIVATE_VISIBILITY);
    } else setPublicSharing();
  };

  const onTypeChange = option => {
    if (onFormChange) onFormChange();
    if (option) type.onChange(option.value);
    else type.onChange('');
  };

  const toggleShare = () => {
    setShare(!share);
  };

  useEffect(() => {
    teamProvider.getList().then(data => {
      const teamOptions = data.map(team => ({
        label: team.name,
        value: team.id
      }));
      const currentTeamOption = teamOptions.find(option => option.value === team.initialValue);

      // if a team was previously assigned, only show it along with
      // the company in the sharing options
      setSharingOptions(
        currentTeamOption
          ? [COMPANY_SHARING_OPTION, currentTeamOption]
          : [COMPANY_SHARING_OPTION, ...teamOptions]
      );

      // changes the current value of the team field to reflect it correctly
      // in the <Select /> component
      if (currentTeamOption) team.onChange(currentTeamOption.value);
    });
  }, []);

  useEffect(() => {
    if (isShared) setShare(true);
  }, [isShared]);

  useEffect(() => {
    if (share && !team.initialValue) setPublicSharing();
    else setPrivateSharing();
  }, [share]);

  return (
    <div id="model-meta-form">
      <div className="row mb-2">
        <div className="col-8 model-name">
          <FormGroup {...name}>
            <VerboseErrorInput
              type="text"
              label="Model name"
              className="form-control"
              {...name}
              errorAfterLabel
            />
          </FormGroup>
        </div>
        <div className="col-4">
          <FormGroup {...type}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="type">Model type</label>
            {type.touched && type.error && <span className="text-danger error">{type.error}</span>}
            <CreatableSelect
              isClearable
              onChange={onTypeChange}
              options={MODEL_TYPES.map(option => ({ label: option, value: option }))}
              placeholder="Select or create ..."
              styles={{ control: controlDefaultStyles }}
              value={type.value ? { label: type.value, value: type.value } : undefined}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-sm-3 model-action-wrapper">
          <div
            className={cn('model-is-strategy', {
              'model-is-strategy--not-allowed': !hasPermissionsToCreateStrategies
            })}
          >
            <FormGroup {...isStrategy} styles={{ display: 'flex' }}>
              <InputButton
                disabled={!hasPermissionsToCreateStrategies}
                option={{ label: 'Set as a strategy' }}
                styles={{ display: 'flex' }}
                multiple
                {...isStrategy}
              />
              <div>
                <QuestionMark tooltipMessage={STRATEGY_MESSAGE} />
              </div>
            </FormGroup>
            {!hasPermissionsToCreateStrategies && process.env.STRATEGIES_LANDING_PAGE_URL && (
              <span className="model-is-strategy__learn-more">
                <a
                  href={process.env.STRATEGIES_LANDING_PAGE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackAmplitudeEvent('strategy.interest', trackingParams);
                  }}
                >
                  Learn more
                </a>{' '}
                about strategies
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-3 model-action-wrapper">
          <div
            className={cn('model-is-benchmark', 'model-visibility', {
              'is-shared': !hasPermissionsToCreateBenchmarks && !isComplianceOrAbove
            })}
          >
            <FormGroup {...isBenchmark} styles={{ display: 'flex' }}>
              <InputButton
                option={{ label: 'Set as a benchmark' }}
                styles={{ display: 'flex' }}
                multiple
                {...isBenchmark}
                disabled={!hasPermissionsToCreateBenchmarks && !isComplianceOrAbove}
                className={cn({
                  disabled: !hasPermissionsToCreateBenchmarks && !isComplianceOrAbove
                })}
              />
              <div>
                <QuestionMark tooltipMessage="" />
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

ModelMetaForm.propTypes = {
  isBenchmark: PropTypes.bool.isRequired,
  isStrategy: PropTypes.bool.isRequired,
  name: PropTypes.object.isRequired,
  onFormChange: PropTypes.func,
  owner: PropTypes.number,
  team: PropTypes.object.isRequired,
  trackingParams: PropTypes.object,
  type: PropTypes.object.isRequired,
  visibility: PropTypes.object.isRequired
};

ModelMetaForm.defaultProps = {
  onFormChange: null,
  owner: undefined,
  trackingParams: {}
};

export default ModelMetaForm;
