import PropTypes from 'prop-types';
import React, { Component } from 'react';

const STATUS_MESSAGE = {
  'session-error': {
    status: 'session-error',
    message:
      'There was a connection error with our data providers. Please try again or contact our support team.'
  },
  exit: {
    status: 'exit',
    message:
      'Your session with Charles Schwab has ended. If you completed the enrollment process, your data will be imported into StratiFi in the next 2-3 business days.'
  }
};

function sendDataToHub(url, text, signature) {
  const enrollmentWin = window.open('', '', 'location=yes,height=600,width=630');
  const formElem = enrollmentWin.document.createElement('form');

  const textElem = enrollmentWin.document.createElement('input');
  textElem.setAttribute('type', 'hidden');
  textElem.setAttribute('id', 'SourceData');
  textElem.setAttribute('name', 'SourceData');
  textElem.setAttribute('value', text);

  const signatureElem = enrollmentWin.document.createElement('input');
  signatureElem.setAttribute('type', 'hidden');
  signatureElem.setAttribute('id', 'DigitalSignature');
  signatureElem.setAttribute('name', 'DigitalSignature');
  signatureElem.setAttribute('value', signature);

  formElem.setAttribute('method', 'post');
  formElem.setAttribute('action', url);

  formElem.appendChild(textElem);
  formElem.appendChild(signatureElem);

  enrollmentWin.document.body.appendChild(formElem);
  enrollmentWin.document.forms[0].submit();
  return enrollmentWin;
}

class SchwabIntegration extends Component {
  constructor() {
    super();

    this.form = null;
    this.initialState = { session: null, loading: false };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.getSession();
  }

  componentWillUnmount() {
    if (this.popupClosedInterval) clearInterval(this.popupClosedInterval);
  }

  onSessionSuccess = session => {
    this.setState({ session, loading: false }, this.startSession);
  };

  onSessionError = error => {
    const { onComplete } = this.props;
    const sessionError = { ...STATUS_MESSAGE['session-error'] };
    if (error?.message) sessionError.message = error.message;
    onComplete(sessionError);
    this.setState({ loading: false });
  };

  getSession = () => {
    const { integrationProvider } = this.context;
    const { selectedSyncData } = this.props;
    this.setState({ loading: true });

    integrationProvider
      .setSchwabIntegrationInfo(selectedSyncData)
      .then(({ data, error }) => {
        if (error) this.onSessionError(data);
        else this.onSessionSuccess(data);
      })
      .catch(this.onSessionError);
  };

  startSession = () => {
    const { session } = this.state;
    if (!session) return;

    const enrollmentWin = sendDataToHub(
      session.application_url,
      session.payload,
      session.signature
    );
    this.popupClosedInterval = setInterval(() => {
      if (enrollmentWin.closed) {
        clearInterval(this.popupClosedInterval);
        this.finishSession(STATUS_MESSAGE.exit);
      }
    }, 1000);
  };

  finishSession = (status = STATUS_MESSAGE.exit) => {
    const { onComplete } = this.props;
    onComplete(status);

    this.setState(this.initialState);
  };

  render() {
    const { session, loading } = this.state;
    return (
      <div id="schwab-integration">
        {!loading && session && (
          <span>
            Please, complete the enrollment process in the pop-up with the Charles Schwab page.
          </span>
        )}
      </div>
    );
  }
}

SchwabIntegration.contextTypes = {
  integrationProvider: PropTypes.object.isRequired
};

SchwabIntegration.propTypes = {
  onStart: PropTypes.func,
  onComplete: PropTypes.func,
  provider: PropTypes.object.isRequired,
  selectedSyncData: PropTypes.object
};

SchwabIntegration.defaultProps = {
  onStart: () => {},
  onComplete: () => {},
  selectedSyncData: {}
};

export default SchwabIntegration;
