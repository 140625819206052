import CompanyUsersHeader from 'components/advisor/company-users/header';
import CompanyUsersList from 'components/advisor/company-users/list';
import Disclosure from 'components/disclosure';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const DEFAULT_INCLUDE_DELETED = true;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_ROLES_ORDERING = '-role, user__first_name';

const CompanyUsers = ({ advisors, invitations, meta }) => {
  const { advisorProvider, integrationProvider, invitationProvider } = useContext(AdvisorContext);

  const fetchUsers = async params => advisorProvider.list({ ...params });

  useEffect(() => {
    fetchUsers({
      page_size: DEFAULT_PAGE_SIZE,
      ordering: DEFAULT_ROLES_ORDERING,
      include_deleted: DEFAULT_INCLUDE_DELETED
    });
    invitationProvider.list();
    advisorProvider.es.list({ get_all: true });
    integrationProvider.getConnected();
  }, []);

  return (
    <div id="company-users">
      <CompanyUsersHeader fetchUsers={fetchUsers} meta={meta} />
      <CompanyUsersList
        advisors={advisors}
        fetchUsers={fetchUsers}
        invitations={invitations}
        meta={meta}
      />
      <Disclosure />
    </div>
  );
};

CompanyUsers.propTypes = {
  advisors: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  invitations: PropTypes.array.isRequired
};

export default connect(state => ({
  advisors: state.advisors.list,
  invitations: state.invitations.list,
  meta: state.advisors.listMeta
}))(CompanyUsers);
