import React, { useContext } from 'react';
import { AdvisorContext } from 'containers/advisor';
import CardAssistantAI from './card';
import { SCAN_IQ_ID, TAX_IQ_ID, RESEARCH_IQ_ID } from './constants';
import './styles.scss';

const AIAssistant = () => {
  const {
    user: {
      advisor: {
        company: { scan_iq_enabled: scanIqEnabled }
      }
    },
    routerActions
  } = useContext(AdvisorContext);

  const handRedirectScanIQ = () => {
    routerActions.push('/advisor/ai-assistant/scan-iq');
  };

  return (
    <div id="ai-assistant">
      <div className="ai-assistant__container">
        <h2 className="ai-assistant__title">
          Welcome to your, <br />
          <b>
            AI-Powered Personalized <br />
            Wealth Management Assistant
          </b>
        </h2>
        <p className="ai-assistant__sub-title">What can I do for you?</p>
        <div className="ai-assistant__content-container">
          <CardAssistantAI
            title="Scan IQ"
            text="Upload a PDF to process the statement. You can extract positions and create model portfolios with the data."
            imgSrc="/img/scan-ai/scan-iq.png"
            cardId={SCAN_IQ_ID}
            buttonText="Try it"
            buttonDisabled={false}
            requestAccess={!scanIqEnabled}
            action={handRedirectScanIQ}
          />
          <CardAssistantAI
            title="Tax IQ"
            text="Speed up your tax with smart workflows across our platform."
            imgSrc="/img/scan-ai/tax-iq.png"
            cardId={TAX_IQ_ID}
            buttonText="Coming soon"
            buttonDisabled
          />
          <CardAssistantAI
            title="Research IQ"
            text="Enhance your research investment and comparison process with the power of StratiFi’s AI."
            imgSrc="/img/scan-ai/research-iq.png"
            cardId={RESEARCH_IQ_ID}
            buttonText="Coming soon"
            buttonDisabled
          />
        </div>
      </div>
    </div>
  );
};

export default AIAssistant;
