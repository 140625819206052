// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#crs-template .edit-box .actions .btn,
#crs-template .edit-box .actions .cta {
  margin-top: 0;
  width: 67px;
  height: 38px;
}
#crs-template .edit-box .actions .cta {
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/crs/styles.scss"],"names":[],"mappings":"AAEI;;EAEE,aAAA;EACA,WAAA;EACA,YAAA;AADN;AAII;EACE,gBAAA;AAFN","sourcesContent":["#crs-template {\n  .edit-box .actions {\n    .btn,\n    .cta {\n      margin-top: 0;\n      width: 67px;\n      height: 38px;\n    }\n\n    .cta {\n      font-weight: 300;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
