import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const DisconnectIntegrationModal = ({ onCancel, onDisconnect, provider, show }) => {
  const [disconnecting, setDisconnecting] = useState(false);

  const onDisconnectIntegration = async () => {
    setDisconnecting(true);
    await onDisconnect();
    setDisconnecting(false);
  };

  return (
    <Modal id="disconnect-integration-modal" className="modal-lg" show={show} onHidden={onCancel}>
      <ModalBody>
        <h1 className="mt-1 mb-3">Disconnect {provider.name}?</h1>
        <div className="buttons-wrapper">
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary btn-secondary-transparent"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={onDisconnectIntegration}
            disabled={disconnecting}
          >
            {disconnecting ? 'Disconnecting...' : 'Disconnect'}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DisconnectIntegrationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func,
  provider: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

DisconnectIntegrationModal.defaultProps = {
  onDisconnect: async () => {}
};

export default DisconnectIntegrationModal;
