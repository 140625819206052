import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { formatMoney, formatPercentage } from 'utils/utils';
import './styles.scss';

const BAR_MAX_WIDTH = 100;

const PortfolioComparisonChart = ({
  id,
  initial,
  isDraft,
  recommended,
  reverse,
  target,
  withMoneyFormat
}) => {
  // this will change the direction of the arrows in this section,
  // causing the draft (current) model to be considered the recommended model
  // despite not being in the recommended position
  const diff = ((isDraft ? -1 : 1) * (recommended - target)) / initial;
  const format = withMoneyFormat ? formatMoney : formatPercentage;
  const maxValue = Math.max(target, recommended);

  const targetWidth = withMoneyFormat
    ? (target / maxValue) * BAR_MAX_WIDTH
    : target * BAR_MAX_WIDTH;
  const recommendedWidth = withMoneyFormat
    ? (recommended / maxValue) * BAR_MAX_WIDTH
    : recommended * BAR_MAX_WIDTH;

  const targetId = `${id}-target-value`;
  const recommendedId = `${id}-recommended-value`;

  return (
    <div className="portfolio-comparison-chart">
      <div>
        <i
          className={cn({
            'fs-icon-arrow-up': diff > 0,
            'fs-icon-arrow-down': diff < 0,
            positive: (reverse && diff < 0) || (!reverse && diff > 0),
            negative: (reverse && diff > 0) || (!reverse && diff < 0)
          })}
        />
        <span>{formatPercentage(Math.abs(diff))}</span>
      </div>

      <div className="portfolio-comparison-chart__bars">
        <div
          className="bars__target"
          style={{ width: targetWidth }}
          data-tip={format(target)}
          data-for={targetId}
        />
        <div
          className="bars__recommended"
          style={{ width: recommendedWidth }}
          data-tip={format(recommended)}
          data-for={recommendedId}
        />
      </div>

      <ReactTooltip
        id={targetId}
        className="portfolio-comparison-chart__tooltip"
        effect="solid"
        place="top"
      />
      <ReactTooltip
        id={recommendedId}
        className="portfolio-comparison-chart__tooltip"
        effect="solid"
        place="top"
      />
    </div>
  );
};

PortfolioComparisonChart.propTypes = {
  id: PropTypes.string.isRequired,
  initial: PropTypes.number,
  isDraft: PropTypes.bool,
  recommended: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
  target: PropTypes.number.isRequired,
  withMoneyFormat: PropTypes.bool
};

PortfolioComparisonChart.defaultProps = {
  initial: 1,
  isDraft: false,
  reverse: false,
  withMoneyFormat: false
};

export default React.memo(PortfolioComparisonChart);
