import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE, BASE_TABLE_CELL, BASE_TABLE_ROW } from 'reports/base/styles';

export const SIGNATURE_BOX_BACKGROUND_COLOR = '#e6f6fe';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 10 },
  cell: { ...BASE_TABLE_CELL, padding: 0, paddingRight: 10 },
  headerText: { fontSize: 12 },
  signatureBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    height: 40,
    backgroundColor: SIGNATURE_BOX_BACKGROUND_COLOR,
    overflow: 'hidden'
  },
  signatureBoxText: { padding: 5 },
  signatureText: { flexGrow: 1, fontSize: 8 }
});

export default styles;
