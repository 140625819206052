/* global $ */
import { BASE_REACT_SUMMERNOTE_OPTIONS } from 'components/utils/wysiwyg/constants';

const COMPANY_NAME = '%COMPANY_NAME%';
const COMPANY_NAME_RE = new RegExp(COMPANY_NAME, 'g');

const ADVISOR_FULL_NAME = '%ADVISOR_NAME%';
const ADVISOR_FULL_NAME_RE = new RegExp(ADVISOR_FULL_NAME, 'g');

const ADVISOR_FIRST_NAME = '%ADVISOR_FIRST_NAME%';
const ADVISOR_FIRST_NAME_RE = new RegExp(ADVISOR_FIRST_NAME, 'g');

const ADVISOR_LAST_NAME = '%ADVISOR_LAST_NAME%';
const ADVISOR_LAST_NAME_RE = new RegExp(ADVISOR_LAST_NAME, 'g');

const CLIENT_FULL_NAME = '%CLIENT_NAME%';
const CLIENT_FULL_NAME_RE = new RegExp(CLIENT_FULL_NAME, 'g');

// Function to create a custom button
export const customButton = (text, code) => context => {
  const { ui } = $.summernote;
  return ui
    .button({
      className: 'custom-btn',
      contents: `<span>${text}</span>`,
      tooltip: `Insert ${text}`,
      click: () => {
        context.invoke('editor.insertText', code);
      }
    })
    .render();
};

// Function to create a custom button group with a dropdown
export const customButtonGroup = (groupName, items) => context => {
  const { ui } = $.summernote;

  // create the dropdown button
  const buttonGroup = ui.buttonGroup([
    ui.button({
      className: 'dropdown-toggle custom-btn',
      contents: `${groupName} <span class="note-icon-caret"></span>`,
      data: {
        toggle: 'dropdown'
      }
    }),
    ui.dropdown({
      className: 'dropdown-style custom-buttons',
      contents: items.map(item => $('<li/>').append(customButton(item.text, item.code)(context)))
    })
  ]);

  // return the button group as a jQuery object
  return buttonGroup.render();
};

export const getEditorOptions = params => {
  const { buttons = {}, withAdvisor = true, withClient = true, withCompany = true } = params || {};
  const options = {
    ...BASE_REACT_SUMMERNOTE_OPTIONS,
    toolbar: [...BASE_REACT_SUMMERNOTE_OPTIONS.toolbar]
  };

  const customButtons = { ...buttons };

  if (withAdvisor)
    customButtons.advisor = customButtonGroup('Advisor', [
      { text: 'Full Name', code: ADVISOR_FULL_NAME },
      { text: 'First Name', code: ADVISOR_FIRST_NAME },
      { text: 'Last Name', code: ADVISOR_LAST_NAME }
    ]);

  if (withClient)
    customButtons.client = customButtonGroup('Client', [{ text: 'Name', code: CLIENT_FULL_NAME }]);

  if (withCompany)
    customButtons.company = customButtonGroup('Company', [{ text: 'Name', code: COMPANY_NAME }]);

  if (Object.keys(customButtons).length) {
    options.toolbar.push(['customButtons', Object.keys(customButtons)]);
    options.buttons = customButtons;
  }

  return options;
};

export const digest = (content, { advisor, client, clients, company, ...rest }) => {
  let digestContent = content;

  if (clients && clients.length === 1)
    digestContent = digestContent.replace(CLIENT_FULL_NAME_RE, clients[0].name);

  if (clients && clients.length > 1) {
    const names = clients.map(client => client.name);
    digestContent = digestContent.replace(
      CLIENT_FULL_NAME_RE,
      `${names.slice(0, -1).join(', ')} and ${names.slice(-1)}`
    );
  }

  if (client)
    digestContent = digestContent.replace(CLIENT_FULL_NAME_RE, client.full_name ?? client.name);

  if (advisor) {
    const { first_name: advisorFirstName, last_name: advisorLastName } = advisor;
    digestContent = digestContent
      .replace(ADVISOR_FULL_NAME_RE, `${advisorFirstName} ${advisorLastName}`)
      .replace(ADVISOR_FIRST_NAME_RE, advisorFirstName)
      .replace(ADVISOR_LAST_NAME_RE, advisorLastName);
  }

  if (company?.name) digestContent = digestContent.replace(COMPANY_NAME_RE, company.name);

  Object.keys(rest).forEach(key => {
    const re = new RegExp(key, 'g');
    digestContent = digestContent.replace(re, rest[key]);
  });

  return digestContent;
};
