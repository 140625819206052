import { Text, View } from '@react-pdf/renderer';
import UpsideDownsideChart from 'components/charts/upside-downside-chart/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import PrismSection from 'reports/base/sections/prism';
import { downUpCaptures } from 'utils/prism';
import { withCurrencyFormat } from 'utils/utils';
import styles from './styles';

const PerformanceContent = ({ capturePercentage, negative, value }) => {
  if (!capturePercentage) return <Text>-</Text>;
  return (
    <Text style={{ marginTop: 5 }}>
      {capturePercentage}%
      {value && (
        <>
          {' '}
          ({negative ? '-' : '+'}
          {withCurrencyFormat((value * capturePercentage) / 100, 'standard', 0)})
        </>
      )}
    </Text>
  );
};

PerformanceContent.propTypes = {
  capturePercentage: PropTypes.number,
  negative: PropTypes.bool,
  value: PropTypes.number
};

PerformanceContent.defaultProps = {
  capturePercentage: null,
  negative: false,
  value: null
};

const PrismRatingUpsideDownside = ({
  market,
  prismSummary: {
    overall: overallScore,
    tail: tailScore,
    volatility: volatilityScore,
    downside_capture_ratio: downCapture,
    upside_capture_ratio: upCapture
  },
  targetSummary,
  value
}) => {
  if (
    upCapture === undefined ||
    downCapture === undefined ||
    upCapture === null ||
    downCapture === null
  )
    [upCapture, downCapture] = downUpCaptures(volatilityScore, tailScore);

  const upCapturePercentage = Math.round(upCapture * market.up);
  const downCapturePercentage = Math.round(downCapture * market.down);

  return (
    <BaseSection title="Portfolio Risk" wrap={false}>
      <View>
        <PrismSection risk={overallScore} tolerance={targetSummary?.overall} />
        <Text style={styles.separator}>Next 12 Months</Text>
        <UpsideDownsideChart
          target={{ upside: upCapture / 10, downside: downCapture / 10 }}
          withLabels
        />
        <View style={styles.scenarioDescription}>
          <View style={styles.description}>
            <Text style={{ marginBottom: 10 }}>
              Historically, S&amp;P 500 has been between{' '}
              <Text style={styles.downText}>-{market.down}%</Text> and{' '}
              <Text style={styles.upText}>+{market.up}%</Text> about 90% of the time. If S&amp;P 500
              was down <Text style={styles.downText}>{market.down}%</Text> then this
              portfolio&apos;s performance may be{' '}
              {downCapturePercentage ? (
                <Text style={styles.downText}>{downCapturePercentage}%</Text>
              ) : (
                '-'
              )}{' '}
              and if S&amp;P 500 was up <Text style={styles.upText}>{market.up}%</Text> then this
              portfolio&apos;s performance may be{' '}
              {upCapturePercentage ? (
                <Text style={styles.upText}>{upCapturePercentage}%</Text>
              ) : (
                '-'
              )}
              .
            </Text>
            {downCapture + upCapture === 0 ? (
              <Text style={styles.boldText}>Downside capture: - Upside capture: -</Text>
            ) : (
              <Text style={styles.boldText}>
                Downside capture: {Math.round(downCapture * 100)}% - Upside capture:{' '}
                {Math.round(upCapture * 100)}%
              </Text>
            )}
          </View>
          <View style={styles.upsideDownsideLegend}>
            <View style={styles.upsideDownsideTitle}>
              <View style={styles.downsideLegendBox} />
              <Text>Downside performance</Text>
            </View>
            <PerformanceContent capturePercentage={downCapturePercentage} value={value} negative />
          </View>
          <View style={styles.upsideDownsideLegend}>
            <View style={styles.upsideDownsideTitle}>
              <View style={styles.upsideLegendBox} />
              <Text>Upside performance</Text>
            </View>
            <PerformanceContent capturePercentage={upCapturePercentage} value={value} />
          </View>
        </View>
      </View>
    </BaseSection>
  );
};

PrismRatingUpsideDownside.propTypes = {
  market: PropTypes.object.isRequired,
  prismSummary: PropTypes.object.isRequired,
  targetSummary: PropTypes.object,
  value: PropTypes.number
};

PrismRatingUpsideDownside.defaultProps = {
  targetSummary: {},
  value: null
};

export default PrismRatingUpsideDownside;
