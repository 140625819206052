import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from './spinner';
import './styles.scss';

const LoadingButton = ({
  children,
  className,
  disabled,
  loading,
  onClick,
  spinnerColor,
  spinnerSize
}) => (
  <div className="loading-button">
    <button
      className={cn(className, 'button')}
      data-loading={loading}
      data-testid="loading-button"
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <Spinner size={spinnerSize} color={spinnerColor} />
      <div className="button__label">{children}</div>
    </button>
  </div>
);

LoadingButton.defaultProps = {
  className: 'btn btn-primary btn-shadow btn-answer',
  disabled: false,
  spinnerColor: '#fff',
  spinnerSize: 0.08
};

LoadingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  spinnerColor: PropTypes.string,
  spinnerSize: PropTypes.number
};

export default LoadingButton;
