/* eslint-disable react/no-unstable-nested-components */
import SingleYearOutcomeChart from 'components/charts/single-year-outcome-chart';
import Slider from 'components/slider/base';
import { getLocale } from 'lang/utils';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import { calculateRecovery } from '../../utils';
import {
  DEFAULT_AMOUNT,
  DEFAULT_VALUE,
  PotentialGainLossText,
  NEGATIVE_COLOR,
  POSITIVE_COLOR
} from '../utils';
import './styles.scss';

const SingleYearOutcomeQuestion = ({ question, field, ...props }) => {
  const locale = getLocale();
  moment.locale(locale);

  const { labels } = question.data;

  const chartData = labels.map(label => ({
    positive: label.positive,
    negative: label.negative
  }));
  const defaultAmount = question.data?.default_amount || DEFAULT_AMOUNT;
  const defaultValue = question.data?.default_value || DEFAULT_VALUE;

  let selectedOption;
  if (Number.isFinite(field.value))
    selectedOption = labels.find(label => label.value === field.value);

  let lossAmount = 0;
  let totalYears = 0;
  if (selectedOption) {
    ({ lossAmount, totalYears } = calculateRecovery(selectedOption.negative, defaultAmount));
    if (chartData[selectedOption.value]) {
      chartData[selectedOption.value].positiveColor = POSITIVE_COLOR;
      chartData[selectedOption.value].negativeColor = NEGATIVE_COLOR;
    }
  }

  const onChange = useCallback(
    (value, autofill = false) => {
      if (autofill) field.autofill(value);
      else field.onChange(value);
    },
    [field]
  );

  useEffect(() => {
    if (!field.touched && (_.isUndefined(field.value) || field.value === ''))
      onChange(defaultValue, true);
  }, [field.touched, field.value, defaultValue]);

  const negativeFormat = str => <span style={{ color: NEGATIVE_COLOR }}>{str}</span>;
  const positiveFormat = str => <span style={{ color: POSITIVE_COLOR }}>{str}</span>;

  return (
    <Question {...props} question={question} nextDisabled={field.invalid}>
      {selectedOption && (
        <div className="chart-message">
          <PotentialGainLossText
            defaultAmount={defaultAmount}
            negativeFormat={negativeFormat}
            positiveFormat={positiveFormat}
            option={selectedOption}
          />
        </div>
      )}

      <div className="slider-container">
        <Slider
          defaultValue={defaultValue}
          labels={labels}
          onChange={onChange}
          value={field.value}
          withHandle
        />
      </div>

      <SingleYearOutcomeChart
        amount={defaultAmount}
        data={chartData}
        id="single-year-outcome"
        style={{ height: '250px', width: '100%', maxWidth: 700 }}
      />

      <div className="chart-message">
        <FormattedMessage
          id="rtq.question-5.loss-recover"
          values={{
            negative: negativeFormat,
            amount: <FormattedNumber value={-1 * lossAmount} format="currency" />,
            years: totalYears ? moment.duration(totalYears, 'y').humanize({ w: 0 }) : '-'
          }}
        />
      </div>
    </Question>
  );
};

SingleYearOutcomeQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default SingleYearOutcomeQuestion;
