import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import Row from 'reports/base/table';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import {
  BENCHMARK_ANALYTICS,
  RECOMMENDED_ANALYTICS,
  TARGET_ANALYTICS,
  getRisksFromSource,
  getRisksHeaders
} from '../utils';
import styles from './styles';

const ProposalRisk = ({ breakSection, metadata, proposal }) => {
  const hasBenchmark = !!proposal.benchmark;
  const hasRecommended = !!proposal.recommended;
  const { body, title } = metadata;

  const headers = getRisksHeaders(hasBenchmark, proposal.benchmark_label);
  const targetRisks = getRisksFromSource(proposal, TARGET_ANALYTICS, hasBenchmark);
  const recommendedRisks = hasRecommended
    ? getRisksFromSource(proposal, RECOMMENDED_ANALYTICS, hasBenchmark)
    : null;
  const benchmarkRisks = hasBenchmark
    ? getRisksFromSource(proposal, BENCHMARK_ANALYTICS, hasBenchmark)
    : null;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <ExtraDataSection proposal={proposal} />
      <Row
        elements={headers}
        cellStyle={styles.headerCell}
        firstCellStyle={styles.headerFirstCell}
        rowStyle={styles.firstRow}
      />
      <Row
        elements={[`${proposal.target_label || 'Current Portfolio'}`, ...targetRisks]}
        cellStyle={styles.contentCell}
        firstCellStyle={{ ...styles.contentFirstCell, ...styles.targetCell }}
      />
      {hasRecommended && (
        <Row
          elements={[proposal.recommended_label, ...recommendedRisks]}
          cellStyle={styles.contentCell}
          firstCellStyle={{ ...styles.contentFirstCell, ...styles.recommendedCell }}
        />
      )}
      {hasBenchmark && (
        <Row
          elements={[proposal.benchmark_label, ...benchmarkRisks]}
          cellStyle={styles.contentCell}
          firstCellStyle={{ ...styles.contentFirstCell, ...styles.benchmarkCell }}
        />
      )}
    </BaseSection>
  );
};

ProposalRisk.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalRisk.defaultProps = {
  breakSection: false
};

export default ProposalRisk;
