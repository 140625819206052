import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW, LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

const styles = StyleSheet.create({
  container: { ...BASE_TABLE_ROW, alignItems: 'center', justifyContent: 'space-between' },
  box: {
    width: 28,
    minWidth: 28,
    paddingVertical: 5,
    backgroundColor: LIGHT_GRAPHITE_COLOR,
    borderRadius: 2
  },
  text: { color: 'black', fontWeight: 700, textAlign: 'center' },
  tolerance: { alignItems: 'center', flexBasis: 150 },
  toleranceScore: { fontWeight: 'bold', marginBottom: 5 },
  toleranceLatestUpdate: {
    alignItems: 'center',
    marginTop: 8,
    fontSize: 8
  },
  adjustedScore: {
    ...BASE_TABLE_ROW,
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 30
  }
});

export default styles;
