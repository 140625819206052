import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedNumber } from 'react-intl';

const InvestorPortfolioValueCell = ({ investor, onAddAccountHandler }) => {
  const { authProvider, user } = useContext(AdvisorContext);

  const canAddAccounts = investor.is_prospect
    ? authProvider.hasAddProspectsPermissions(user)
    : authProvider.hasAddClientsPermissions(user);
  const totalAssets = Math.round(
    investor.accounts
      .filter(account => !account.excluded)
      .reduce((acc, account) => acc + account.value, 0)
  );

  if (totalAssets !== 0) return <FormattedNumber value={totalAssets} format="currency" />;

  if (!canAddAccounts) return '-';

  return (
    <button
      className="btn btn-link"
      onClick={onAddAccountHandler(investor)}
      style={{ fontSize: 14 }}
      type="button"
    >
      Add Account
    </button>
  );
};

InvestorPortfolioValueCell.propTypes = {
  investor: PropTypes.object.isRequired,
  onAddAccountHandler: PropTypes.func.isRequired
};

export default InvestorPortfolioValueCell;
