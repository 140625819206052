export const REPORTS_BASE_URL_REGEX = /\/(?:\d+|new)\/?$/;
export const REPORTS_BASE_URL_ENDS_WITH_ID = /\d+$/;
export const REPORTS_COPY_SUFFIX = '(copy)';

export const ARCHIVED_NOTIFICATION_VERB = 'archived';
export const CREATED_NOTIFICATION_VERB = 'created';
export const DELETED_NOTIFICATION_VERB = 'deleted';
export const PUBLISHED_NOTIFICATION_VERB = 'published';
export const RESTORED_NOTIFICATION_VERB = 'restored';
export const SAVED_NOTIFICATION_VERB = 'saved';
export const UPDATED_NOTIFICATION_VERB = 'updated';
