import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React from 'react';

const InvestorAccountsCell = ({ investor }) => {
  if (!investor.accounts?.length) return '';

  const excludedAccountsCount = investor.accounts.reduce(
    (acc, account) => (account.excluded ? acc + 1 : acc),
    0
  );

  const tooltipId = `excluded-accounts-tooltip-${investor.id}`;
  const tooltipLabel = `${excludedAccountsCount} ${
    excludedAccountsCount > 1 ? 'accounts are' : 'account is'
  } excluded from aggregated portfolio value and PRISM rating`;

  return (
    <TooltipV2
      className="excluded-accounts-tooltip"
      id={tooltipId}
      label={tooltipLabel}
      place="top"
    >
      {investor.accounts.length}
      {!!excludedAccountsCount && (
        <img
          style={{ position: 'absolute', height: 18, marginLeft: 5 }}
          alt="Excluded Accounts"
          className="account-warning-icon"
          data-for={tooltipId}
          data-tip=""
          src="/img/icons/warning_grey.svg"
        />
      )}
    </TooltipV2>
  );
};

InvestorAccountsCell.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorAccountsCell;
