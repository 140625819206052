import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CAPACITY_RANGES, PERSONALITY_TYPES } from '../score-context/utils/constants';
import './styles.scss';

const Legend = ({ parentClassName, withCapacity }) => (
  <div className={cn('risk-legend', parentClassName)}>
    <div className="risk-legend__section">
      <h2 className="risk-legend__section-title">Risk Willingness</h2>
    </div>
    <div className="risk-legend__container">
      {Object.values(PERSONALITY_TYPES).map(personalityType => (
        <div className="risk-legend__item" key={personalityType.label}>
          <h3 className={`risk-legend__item-title risk-${personalityType.max}`}>
            {personalityType.label}
          </h3>
          <p className="risk-legend__item-text">{personalityType.description.advisor}</p>
        </div>
      ))}
    </div>
    {withCapacity && (
      <>
        <div className="risk-legend__section">
          <h2 className="risk-legend__section-title">Risk Ability</h2>
        </div>
        <div className="risk-legend__container">
          {Object.values(CAPACITY_RANGES)
            .map(capacityRange => (
              <div className="risk-legend__item" key={capacityRange.label}>
                <img
                  className="financial-capacity__icon"
                  src={capacityRange.src}
                  alt={capacityRange.label}
                  width={30}
                />
                <h3 className={`risk-legend__item-title capacity-${capacityRange.max}`}>
                  {capacityRange.reportIcon}
                  {capacityRange.label}
                </h3>
                <p className="risk-legend__item-text">{capacityRange.description}</p>
              </div>
            ))
            .reverse()}
        </div>
      </>
    )}
  </div>
);

Legend.defaultProps = {
  parentClassName: '',
  withCapacity: false
};

Legend.propTypes = {
  parentClassName: PropTypes.string,
  withCapacity: PropTypes.bool
};

export default Legend;
