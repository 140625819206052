import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classnames from 'classnames';
import { connect } from 'react-redux';

import Link from 'components/utils/link';

import './styles.scss';

const CHECKLIST = [
  {
    url: '/advisor/me/company',
    keys: ['profile'],
    text: 'Set up profile under Settings. Update your website, email, phone number, and profile photo.',
    tag: 'beginner'
  },
  {
    url: '/advisor/models',
    keys: ['model_portfolio'],
    text: 'Add model portfolios and find out portfolio risk by PRISM rating.'
  },
  { url: '/advisor/prospects', keys: ['prospect'], text: 'Create prospects. ' },
  {
    url: '/advisor/investors',
    keys: ['target_score'],
    text: ' Send risk tolerance questionnaire to multiple clients (or manually enter).',
    tag: 'rookie'
  },
  {
    url: '/advisor/investors',
    keys: ['proposal', 'ips'],
    text: 'Generate proposal or IPS reports for your clients.'
  },
  { url: '/advisor/me/users', keys: ['invitation'], text: 'Invite team members and assign roles.' },
  { url: '/advisor/me/profile', keys: ['sync'], text: 'Sync your data.' },
  { url: '/advisor/households', keys: ['household'], text: 'Create Households.' },
  {
    url: '/advisor/analytics',
    keys: ['snooze'],
    text: 'Check Analytics page and try to add notes to mute notification.',
    tag: 'expert'
  }
];

export const OnboardingChecklist = ({
  user: {
    first_name: firstName,
    advisor: { on_boarding: onBoarding }
  }
}) => {
  const data = CHECKLIST.reduce((acum, { keys, ...info }, index) => {
    const checked = keys.every(key => onBoarding[key] || false);
    const streak = checked && (index ? acum[index - 1].streak : true);
    return [
      ...acum,
      {
        ...info,
        checked,
        streak
      }
    ];
  }, []);

  const modelColumns = [
    {
      sortable: false,
      columns: [
        {
          Header: '',
          id: 101,
          sortable: false,
          accessor: m => m.text,
          Cell: row => (
            <div className="container-circle-text">
              <i className="circle-check" />
              <span className="item-text">{row.original.text}</span>
              {row.original.tag && row.original.streak && (
                <span className={classnames('tag', row.original.tag)} />
              )}
            </div>
          )
        }
      ]
    },
    {
      width: 50,
      sortable: false,
      columns: [
        {
          Header: '',
          id: 100,
          width: 100,
          sortable: false,
          accessor: m => m.id,
          Cell: row =>
            row.original.url && !row.original.checked ? (
              <Link to={row.original.url} className="btn btn-small btn-primary">
                Go
                <i className="fs-icon-angle-right" />
              </Link>
            ) : null
        }
      ]
    }
  ];

  const completed = data[data.length - 1].streak;
  const level = data.reduce(
    (maxLevel, item) => (item.tag && item.streak ? item.tag : maxLevel),
    null
  );

  return (
    <div className="onboarding-checklist-container">
      <div className="models-wrapper">
        <div className="header">
          <div className="title-container" data-testid="form-title">
            <strong>Welcome, {firstName}</strong>
            Here&apos;s the guideline for you to maximize your benefit from the StratiFi platform.
          </div>
        </div>

        <div className="onboarding-checklist-data-table" data-testid="table-checklist">
          <div className="header-container">
            <div className="float-left">
              Onboarding Checklist:{' '}
              {level && <span className={classnames('level', level)}>{level}</span>}
            </div>
            {completed && (
              <div className="float-right message-finished">
                Congratulations You have finished onboarding.
              </div>
            )}
          </div>
          <ReactTable
            data={data}
            columns={modelColumns}
            minRows={9}
            showPagination={false}
            showPageSizeOptions={false}
            resizable={false}
            noDataText="No data."
            getTrProps={(state, rowInfo) => {
              if (!rowInfo) return {};
              return {
                className: rowInfo.original.checked ? 'checked' : ''
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};

OnboardingChecklist.propTypes = {
  user: PropTypes.object.isRequired
};

export default connect(state => ({
  user: state.auth.user
}))(OnboardingChecklist);
