import EmbeddedDocument from 'components/advisor/utils/embedded-document';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes } from '../types';

const ProposalEmbeddedDocument = ({ actionsDisabled, metadata, propertyKey, setMetadata }) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  return (
    <EmbeddedDocument
      {...proposalMetadata}
      attachmentName={metadata.attachmentName}
      url={metadata.url}
    />
  );
};

ProposalEmbeddedDocument.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalEmbeddedDocument;
