import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Choice from 'components/form/choice';
import { formatMoney } from 'utils/utils';
import { trackAmplitudeEvent } from 'utils/tracking';

import './styles.scss';

export class ClientSelectAccounts extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      accountIds: props.accounts.reduce((acum, account) => ({ ...acum, [account.id]: true }), {})
    };
  }

  toggle(accountId) {
    const { accountIds } = this.state;
    this.setState({
      accountIds: { ...accountIds, [accountId]: !accountIds[accountId] }
    });
  }

  submit() {
    const { onSelectAccounts, accounts } = this.props;
    const { accountIds } = this.state;
    const selectedIds = Object.entries(accountIds)
      .filter(([_, selected]) => selected)
      .map(([id]) => parseInt(id, 10));
    onSelectAccounts(selectedIds);

    const totalAccounts = accounts?.length;
    const accountsSelected = Object.values(accountIds).filter(el => el).length;
    const rate = totalAccounts ? accountsSelected / totalAccounts : 0;

    trackAmplitudeEvent('tolerance_modal.selected_accounts', {
      totalAccounts,
      accountsSelected,
      rate
    });
  }

  render() {
    const { accounts } = this.props;
    const { accountIds } = this.state;

    return (
      <div id="ClientSelectAccounts">
        <h2>Please select accounts that should have the same risk tolerance.</h2>
        <div className="accounts">
          {accounts.map(account => (
            <div key={`selectable-account-${account.id}`}>
              <Choice
                title=""
                checked={accountIds[account.id]}
                disabled={false}
                toggle={() => this.toggle(account.id)}
                className="select-account"
              />
              <span className="account-name">{account.display_name}</span>
              <span className="account-value">
                <b>{formatMoney(account.value)}</b>
              </span>
            </div>
          ))}
        </div>
        <div className="actions">
          <button
            className="btn btn-primary"
            type="button"
            disabled={!Object.values(accountIds).some(x => x)}
            onClick={this.submit}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

ClientSelectAccounts.propTypes = {
  accounts: PropTypes.array.isRequired,
  onSelectAccounts: PropTypes.func.isRequired
};

export default ClientSelectAccounts;
