import { StyleSheet } from '@react-pdf/renderer';
import { CYAN_COLOR, GRAPHITE_COLOR, LILAC_COLOR } from '../../styles';

export const styles = StyleSheet.create({
  legend: { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: 8 },
  legendBox: { width: 10, height: 10, marginRight: 5 },
  targetLegendBox: { backgroundColor: GRAPHITE_COLOR },
  recommendedLegendBox: { marginLeft: 15, backgroundColor: CYAN_COLOR },
  benchmarkLegendBox: { marginLeft: 15, backgroundColor: LILAC_COLOR }
});

export default styles;
