import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const BAR_MAX_HEIGHT = 100;

const PotentialDownsideChart = ({ color, maxValue, value }) => {
  const height = (value / maxValue) * BAR_MAX_HEIGHT;
  return (
    <View style={styles.chart}>
      <View style={[styles.bar, { backgroundColor: color, height }]} />
      <Text style={styles.text}>-{(value * 100).toFixed(2)}%</Text>
    </View>
  );
};

PotentialDownsideChart.propTypes = {
  color: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default PotentialDownsideChart;
