import cn from 'classnames';
import { REQUEST_LOCKED_FEATURE_ACCESS } from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import './styles.scss';

export const RequestAction = ({ id, name, className, callback, btnText }) => {
  const { actionProvider } = useContext(AdvisorContext);

  const [isRequested, setIsRequested] = useState(false);

  const requestAccessCta =
    btnText ||
    process.env.REQUEST_LOCKED_FEATURE_ACCESS_CTA ||
    `${isRequested ? 'Requested' : 'Request'} Access`;
  const requestAccessUrl = process.env.REQUEST_LOCKED_FEATURE_ACCESS_URL;

  const onRequestAccess = () => {
    if (callback) callback();

    const params = { verb: REQUEST_LOCKED_FEATURE_ACCESS, data: { name } };
    actionProvider.slack(params);
    toast.success(
      '🙌 We have received your request and informed the sales team. We will be in touch soon.',
      { toastId: `${id}-request`, autoClose: 10000 }
    );
    setIsRequested(true);
  };

  return (
    <div className="section-locked__actions">
      {requestAccessUrl ? (
        <a
          className={className}
          href={requestAccessUrl}
          rel="noopener noreferrer"
          target="_blank"
          onClick={callback}
        >
          {requestAccessCta}
        </a>
      ) : (
        <button
          className={className}
          disabled={isRequested}
          onClick={onRequestAccess}
          type="button"
        >
          {requestAccessCta}
        </button>
      )}
    </div>
  );
};

const SectionLocked = ({ id, name, masked }) => (
  <div className={cn('section-locked', { masked, 'section-locked--no-image': !id })}>
    <p>
      The {name} feature is not accessible under your current plan.
      <br />
      Reach out to our sales team for more information.
    </p>
    <RequestAction id={id} name={name} className="btn btn-lg btn-primary" />
    {id && (
      <div className="section-locked__placeholder">
        <img src={`/img/sections/${id}.png`} alt={`${name} Locked`} />
      </div>
    )}
  </div>
);

RequestAction.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  callback: PropTypes.func,
  btnText: PropTypes.string
};

RequestAction.defaultProps = {
  id: null,
  className: '',
  callback: null,
  btnText: ''
};

SectionLocked.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  masked: PropTypes.bool
};

SectionLocked.defaultProps = {
  id: null,
  masked: true
};

export default SectionLocked;
