// base colors
export const GRAPHITE_COLOR = '#505e62';
export const LIGHT_GRAPHITE_COLOR = '#e3e7ee';
export const CYAN_COLOR = '#30e3fe';
export const LIGHT_CYAN_COLOR = '#d3e9f3';
export const LILAC_COLOR = '#af7cd3';
export const LIGHT_LILAC_COLOR = '#e8e0ee';
export const BLIZZAR_BLUE_COLOR = '#b4e4f8';
export const PALE_CYAN_COLOR = '#09acf8';

export const TABLE_HEADER_BACKGROUND_COLOR = LIGHT_GRAPHITE_COLOR;

export const PRISM_COLOR_0 = '#66cb33';
export const PRISM_COLOR_1 = '#89c332';
export const PRISM_COLOR_2 = '#aabc31';
export const PRISM_COLOR_3 = '#c5b430';
export const PRISM_COLOR_4 = '#e4a82f';
export const PRISM_COLOR_5 = '#f29b2e';
export const PRISM_COLOR_6 = '#ee772d';
export const PRISM_COLOR_7 = '#e6672f';
export const PRISM_COLOR_8 = '#d54d36';
export const PRISM_COLOR_9 = '#c7393d';
export const PRISM_OFF_COLOR = '#d4d6db';
export const PRISM_TOLERANCE_COLOR = '#92dcf8';

export const RISK_GREEN_COLOR = '#33cf39';
export const RISK_RED_COLOR = '#d6353c';

// @react-pdf/renderer has a media query called '@media orientation: landscape',
// but it doesn't seem to work properly, so the min-width media query was used
// instead to apply a mobile-first approach.

// the minimum width value used is 650pt, which is larger than any of the widths
// used by most common paper sizes such as:
// - A4:     [595.28,  841.89]
// - LEGAL:  [612.00, 1008.00]
// - LETTER: [612.00,  792.00],
export const FOR_LANDSCAPE_ORIENTATION = '@media min-width: 650';

// base font family used throughout all reports
export const BASE_FONT_FAMILY = { fontFamily: 'Roboto' };

// base page body text used throughout all reports
export const BASE_PAGE_BODY_TEXT = { fontSize: 10 };

// base page padding used throughout all reports
export const BASE_PAGE_PADDING = { padding: 25 };

// base page title used throughout all reports
export const BASE_PAGE_TITLE = { fontSize: 20, marginBottom: 10 };

// base table styles used throughout all reports
export const BASE_TABLE = { flexGrow: 1 };
export const BASE_TABLE_CELL = {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
  padding: 5,
  justifyContent: 'center'
};
export const BASE_TABLE_ROW = { flexDirection: 'row', flexFlow: 'row wrap' };

export const TABLE_CELL_CATEGORY_01 = {
  borderLeftWidth: 5,
  borderLeftStyle: 'solid',
  borderLeftColor: GRAPHITE_COLOR,
  backgroundColor: LIGHT_GRAPHITE_COLOR
};
export const TABLE_CELL_CATEGORY_02 = {
  borderLeftWidth: 5,
  borderLeftStyle: 'solid',
  borderLeftColor: CYAN_COLOR,
  backgroundColor: LIGHT_CYAN_COLOR
};
export const TABLE_CELL_CATEGORY_03 = {
  borderLeftWidth: 5,
  borderLeftStyle: 'solid',
  borderLeftColor: LILAC_COLOR,
  backgroundColor: LIGHT_LILAC_COLOR
};

export const TABLE_CELL_HEADER_01 = {
  borderTopWidth: 5,
  borderTopStyle: 'solid',
  borderTopColor: GRAPHITE_COLOR,
  backgroundColor: LIGHT_GRAPHITE_COLOR
};
export const TABLE_CELL_HEADER_02 = {
  borderTopWidth: 5,
  borderTopStyle: 'solid',
  borderTopColor: CYAN_COLOR,
  backgroundColor: LIGHT_CYAN_COLOR
};
export const TABLE_CELL_HEADER_03 = {
  borderTopWidth: 5,
  borderTopStyle: 'solid',
  borderTopColor: LILAC_COLOR,
  backgroundColor: LIGHT_LILAC_COLOR
};
