/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import AccountNotesModal from 'components/advisor/accounts/notes-modal';
import Disclosure from 'components/disclosure';
import DownloadIcon from 'components/svg-icons/download-icon';
import { AdvisorContext } from 'containers/advisor';
import DynamicTable from 'containers/table/dynamic';
import ExceptionAgeFilter from 'containers/table/dynamic/filters/exception/age';
import ExceptionCreatedFilter from 'containers/table/dynamic/filters/exception/created';
import ExceptionDriftRangeFilter from 'containers/table/dynamic/filters/exception/drift-range';
import ExceptionStatusFilter from 'containers/table/dynamic/filters/exception/status';
import ExceptionTypeFilter from 'containers/table/dynamic/filters/exception/type';
import ExceptionValueRangeFilter from 'containers/table/dynamic/filters/exception/value-range';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { EXCEPTION_STATUS } from 'utils/drift';
import { fromNow } from 'utils/utils';
import ExceptionDriftCell from './cell/exception-drift';
import ExceptionStatusCell from './cell/exception-status';
import './styles.scss';

const DriftExceptionsList = ({ exceptions, meta }) => {
  const { advisorProvider } = useContext(AdvisorContext);

  const [exception, setException] = useState(null);

  const onFetchData = params =>
    advisorProvider.es.listExceptions({ ...(meta?.params || {}), ...params });

  const isSnoozedOrClosed = status =>
    EXCEPTION_STATUS.SNOOZED === status || EXCEPTION_STATUS.CLOSED === status;

  const downloadReport = () => advisorProvider.es.downloadReport(meta?.params || {});

  const columnClassNameHandler = ({ row }) =>
    cn('text-center', { 'td--opaque': isSnoozedOrClosed(row?.original?.status_name) });

  const setCurrentException = (exception = null) => {
    setException(exception);
  };

  const onHideHandler = () => {
    setTimeout(() => {
      onFetchData();
      setException(null);
    }, 500);
  };

  const columns = [
    {
      accessorFn: row => row.status_name,
      id: 'status',
      name: 'Status',
      cell: exception => (
        <ExceptionStatusCell exception={exception} setCurrentException={setCurrentException} />
      ),
      meta: { className: columnClassNameHandler, style: () => ({ width: 95 }) }
    },
    {
      accessorFn: row => row.account_name,
      id: 'account_name',
      name: 'Account',
      cell: exception => (
        <>
          {exception.account_name}{' '}
          {(exception.account_tags ?? []).map(tag => (
            <span className="account-tag">{tag}</span>
          ))}
        </>
      ),
      meta: { className: columnClassNameHandler }
    },
    {
      accessorFn: row => row.account_number || '-',
      id: 'account_number',
      name: 'Number',
      meta: { className: columnClassNameHandler, style: () => ({ width: 95 }) }
    },
    {
      accessorFn: row => row.investor_name,
      id: 'investor_name',
      name: 'Client',
      meta: { className: columnClassNameHandler, style: () => ({ width: 135 }) }
    },
    {
      accessorFn: row => row.managers || row.advisor_name,
      id: 'advisor_name',
      name: 'Advisor',
      meta: { className: columnClassNameHandler, style: () => ({ width: 150 }) }
    },
    {
      accessorFn: row => row.value,
      id: 'value',
      name: 'Portfolio Value',
      cell: exception => <FormattedNumber value={exception.value} format="currency" />,
      meta: { className: columnClassNameHandler, style: () => ({ width: 150 }) }
    },
    {
      accessorFn: row => row.drift,
      id: 'drift',
      name: 'Drift',
      cell: exception => <ExceptionDriftCell exception={exception} />,
      meta: {
        className: ({ row }) =>
          cn(
            'text-center',
            'td-drift',
            { 'td-drift--red': !row?.original?.is_healthy },
            { 'td--opaque': isSnoozedOrClosed(row?.original?.status_name) }
          ),
        style: () => ({ position: 'relative', width: 75 })
      }
    },
    {
      accessorFn: row => row.age,
      id: 'age',
      name: 'Age',
      cell: exception => (_.isNil(exception.age) ? '-' : `${exception.age} days old`),
      meta: { className: columnClassNameHandler, style: () => ({ width: 95 }) }
    },
    {
      accessorFn: row => row.value,
      id: 'modified',
      name: 'Last updated',
      cell: exception => `${fromNow(exception.modified)} ago`,
      meta: { className: columnClassNameHandler, style: () => ({ width: 125 }) }
    }
  ];

  const headerFixedActions = loading => (
    <div className="download-report">
      <button
        type="button"
        disabled={!exceptions.length || loading}
        onClick={downloadReport}
        className="btn btn-secondary download"
      >
        <DownloadIcon />
        <div className="download-text">Download</div>
      </button>
    </div>
  );

  return (
    <div id="drift-exceptions-list" className="advisor-investor-drift-exceptions-container">
      <div className="header">
        <h2>Drift Exceptions</h2>
      </div>

      <div className="drift-exceptions-table">
        <DynamicTable
          columns={columns}
          data={exceptions}
          headerFixedActions={headerFixedActions}
          filterComponents={[
            [],
            [
              ExceptionStatusFilter,
              ExceptionCreatedFilter,
              ExceptionAgeFilter,
              ExceptionTypeFilter
            ],
            [ExceptionDriftRangeFilter, ExceptionValueRangeFilter]
          ]}
          initialParams={{
            status__terms: ['To Do', 'In Progress'].join('__')
          }}
          label="Exception"
          meta={meta}
          onFetchData={onFetchData}
          withTeamFilter={false}
        />
      </div>

      {!!exception && (
        <AccountNotesModal
          accountId={exception.account}
          exceptionId={exception.id}
          investorId={exception.investor}
          onHide={onHideHandler}
          show
          tags={exception.tags}
        />
      )}

      <Disclosure />
    </div>
  );
};

DriftExceptionsList.propTypes = {
  exceptions: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired
};

export default DriftExceptionsList;
