import {
  REGION_EXPOSURE,
  SECTOR_EXPOSURE,
  TOP_HOLDINGS,
  INVESTMENT_STYLE
} from 'components/advisor/section-locked/utils';

export const CRS_DEFAULT_TEMPLATE = {
  introduction: {
    allowEdit: true,
    allowHide: true,
    order: 1,
    title: 'Introduction',
    body: `
      <ul>
        <li><b>Date:</b></li>
        <li><b>Firm:</b><br /></li>
        <li><b>Advisor:</b> [State whether you are registered with the SEC as a broker-dealer, investment adviser, or both.]<br /></li>
        <li>[Indicate that brokerage and investment advisory services and fees differ and that it is important for the retail investor to understand the differences.]<br /></li>
        <li>[If applicable, may also include reference to FINRA or Securities Investor Protection Corporation membership in a manner consistent with other rules or regulations (e.g., FINRA rule 2210)]<br /></li>
        <li>[State that free and simple tools are available to research firms and financial professionals at Investor.gov/CRS, which also provides educational materials about broker-dealers, investment advisers, and investing.]</li>
      </ul>
    `,
    hidden: false
  },
  investmentService: {
    allowEdit: true,
    allowHide: true,
    order: 2,
    title: 'What investment services and advice can you provide me?',
    body: `
      <p>[State that you offer investment advisory services to retail investors. If you are a dual registrant, state that you offer both brokerage and investment advisory services to retail investors. Summarize the principal services, accounts, or investments you make available to retail investors, and any material limitations on such services. State the particular types of principal investment advisory services you offer to retail investors, including, for example, financial planning and wrap fee programs. Include specific references (e.g., include hyperlinks, mouse-over windows, or other means) to more detailed information. At a minimum, include the same or equivalent information to that required by the Form ADV, Part 2A brochure (Items 4 and 7 of Part 2A or Items 4.A. and 5 of Part 2A Appendix 1). ]</p>
      <p><b>Given my financial situation, should I choose an investment advisory service? Why or why not?</b></p>
      <p><b>If dual registrant: Given my financial situation, should I choose an investment advisory service? Should I choose a brokerage service? Should I choose both types of services? Why or why not?</b></p>
      <p><b>How will you choose investments to recommend to me?</b></p>
      <p><b>What is your relevant experience, including your licenses, education and other qualifications? What do these qualifications mean?</b></p>


    `,
    hidden: false
  },
  fees: {
    allowEdit: true,
    allowHide: true,
    order: 3,
    title: 'What fees will I pay?',
    body: `
      <p>
        [Summarize principal fees and costs that retail investors will incur for investment advisory services, including how frequently they are assessed and the conflicts of interest they create. Describe ongoing asset-based fees, fixed fees, wrap fee program fees, or other direct fee arrangements. RIAs with wrap program fees explain fees associated with program include most transaction costs and fees to BD or bank that has custody of these assets, and therefore are higher than typical
        asset-based advisory fee. In addressing conflict, may include statement that more assets in account means more fees, thus incentive to encourage client to increase assets in account. Describe other fees and costs client will pay directly or indirectly. List examples of most common categories (e.g., custodian fees, account maintenance fees, fees related to mutual funds and variable annuities, and other transactional fees and product-level fees)]
      </p>
      <p>
        You are required to describe any ongoing asset-based fees, fixed fees, wrap fee program fees, or other direct fee arrangements. If applicable, an investment adviser may explain that asset-based fees associated with a wrap fee program will include most transaction costs and fees to a broker-dealer or bank that has custody of
        these assets, and therefore are higher than a typical asset-based advisory fee.
      </p>
      <p>
        With respect to addressing conflicts of interest, an investment adviser that charges an asset-based fee could, for example, include a statement that the more assets there are in a retail investor's advisory account, the more a retail investor will pay in fees, and the firm may therefore have an incentive to encourage the retail investor to increase the assets in his or her account.
      </p>
      <p>
        Describe other fees and costs related to your investment advisory services and investments in addition to the firm's principal fees and costs disclosed above that the retail investor will pay directly or indirectly. List examples of the categories of the most common fees and costs applicable to retail investors (e.g., custodian fees, account maintenance fees, fees related to mutual funds and variable annuities, and other transactional fees and product-level fees).
      </p>
      <p>
        <b>You will pay fees and costs whether you make or lose money on your investments. Fees and costs will reduce any amount of money you make on your investments over time. Please make sure you understand what fees and costs you are paying.</b>
      </p>
      <p>
        [Must include specific references (e.g., include hyperlinks, mouse-over windows, or other means) to more detailed information about your fees and costs that, at a minimum, include the same or equivalent information to that required by the Form ADV, Part 2A brochure (specifically Items 5.A.,B., C., and D.).]
      </p>
      <p>
        <b>Help me understand how these fees and costs might affect my investments. If I give you $10,000 to invest, how much will go to fees and costs, and how much will be invested for me?</b>
      </p>
      <p>
        <b>What are your legal obligations to me when acting as my investment adviser? How else does your firm make money and what conflicts of interest do you have?</b>
      </p>
      <p>
        [Dual registrant that prepares single relationship summary, use heading:]<br />
        <b>What are your legal obligations to me when providing recommendations as my broker-dealer or when acting as my investment adviser? How else does your firm make money and what conflicts of interest do you have?</b>
      </p>
      <p>
        [Standalone advisers (or dual registrant preparing Form CRS as adviser) include (emphasis required): ]<br />
        <b>When we act as your investment adviser, we have to act in your best interest and not put out interest ahead of yours. At the same time, the way we make money creates some conflicts with your interests. You should understand and ask us about these conflicts because they can affect the investment advice we provide you. Here are some examples to help you understand what this means.</b>
      </p>
      <p>
        [Dual registrant that prepares a single relationship summary and provides recommendations subject to Regulation Best Interest as a broker-dealer, include (emphasis required):]<br />
        <b>When we provide you with a recommendation as your broker-dealer or act as your investment adviser, we have to act in your best interest and not put our interest ahead of yours. At the same time, the way we make money creates some conflicts with your interests. You should understand and ask us about these conflicts because they can affect the recommendations and investment advice we provide you. Here are some examples to help you understand what this means.</b>
      </p>
      <p>
        [Dual registrant that prepares a single relationship summary and does not provide recommendations subject to Regulation Best Interest as a broker- dealer, include
        (emphasis required): ]<br />
        <b>We do not provide recommendations as your broker-dealer. When we act as your investment adviser, we have to act in your best interest and not put our interests ahead of yours. At the same time, the way we make money creates some conflicts with your interest. You should understand and ask us about these conflicts because they can affect the services and investment advice we provide you. Here are some examples to help you understand what this means.</b>
      </p>
      <p>
        <b>How might your conflicts of interest affect me, and how will you address them?</b>
      </p>
      <p>
        <b>How do your financial professionals make money?</b>
      </p>
    `,
    hidden: false
  },
  legalHistory: {
    allowEdit: true,
    allowHide: true,
    order: 4,
    title: 'Do you or your financial professionals have legal or disciplinary history?',
    body: `
      <p>
        [State "Yes" if you or any of your financial professionals currently disclose, or are required to disclose, the following information:]
      </p>
      <p>
        <b>Disciplinary information in your Form ADV (Item 11 of Part 1A or Item 9 of Part 2A)</b>
      </p>
      <p>
        <b>Legal or disciplinary history in your Form BD, if applicable (Items 11 A-K) (except to the extent such information is not released to BrokerCheck, pursuant to FINRA Rule 8312).</b>
      </p>
      <p>
        <b>Disclosures for any of your financial professionals in Items 14 A-M on Form U4, or in Items 7A or 7C-F of Form U5, or, if applicable, on Form U6 (except to the extent such information is not released to BrokerCheck, pursuant to FINRA Rule 8312).</b>
      </p>
      <p>
        [State "No" if neither you nor any of your financial professionals currently disclose, or are required to disclose, the information listed above.]
      </p>
      <p>
        <b>Search Tool: Direct the retail investor to visit Investor.gov/CRS for a free and simple search tool to research you and your financial professionals.</b>
      </p>
      <p>
        <b>Conversation Starter: As a financial professional, do you have any disciplinary history? For what type of conduct?</b>
      </p>
    `,
    hidden: false
  },
  additionalInformation: {
    allowEdit: true,
    allowHide: true,
    order: 5,
    title: 'Additional information',
    body: `
      <p>
        [State where the retail investor can find additional information about your investment advisory services and request a copy of the relationship summary. This information should be disclosed prominently at the end of the relationship summary]
      </p>
      <p>
        <b>You will pay fees and costs whether you make or lose money on your investments. Fees and costs will reduce any amount of money you make on your investments over time. Please make sure you understand what fees and costs you are paying.</b>
      </p>
      <p>
        [Include a telephone number where retail investors can request up-to-date information and request a
        copy of the relationship summary.]
      </p>
      <p>
        <b>Who is my primary contact person? Is he or she a representative of an investment adviser or a broker-dealer? Who can I talk to if I have concerns about how this person is treating me?</b>
      </p>
    `,
    hidden: false
  }
};

export const PROPOSAL_DEFAULT_TEMPLATE = {
  _cover: {
    body: '',
    cover: true,
    order: 0,
    title: 'Investment Proposal',
    preparedBy: 'advisor',
    preparedFor: 'investor',
    hidden: false,
    allowEdit: true,
    allowHide: false,
    allowChangeText: false
  },
  prism: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      PRISM, StratiFi's portfolio risk tool, identifies and pinpoints threats presented by four key sources of risk - volatility, correlation, concentrated stock, and tail-event. To simplify the understanding of each threat, it uses a ten-point scale with 1 being very low risk and 10 being very high risk.
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 1,
    title: 'PRISM Rating'
  },
  matchingModels: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 2,
    title: 'Matching Models',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  riskFactorComparison: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 3,
    title: 'PRISM Risk Factor Comparison',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  upDownCaptureComparison: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 4,
    title: 'Up & Down Capture Comparison',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  marketScenarioAnalysis: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 5,
    title: 'Risk Analysis for the Market Scenario',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  holdings: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 6,
    title: 'Holdings',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  [REGION_EXPOSURE]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 7,
    title: 'Geographic Exposure'
  },
  [SECTOR_EXPOSURE]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 8,
    title: 'Sector Exposure'
  },
  [TOP_HOLDINGS]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 9,
    title: 'Top 10 Holdings'
  },
  [INVESTMENT_STYLE]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 10,
    title: 'Investment Style',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  potentialDownside: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    hidden: false,
    order: 11,
    title: 'Potential Downside (Max Drawdown)',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    }
  },
  summary: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      Based on your risk tolerance, we recommend a portfolio more aligned with your financial objectives. In this document, we will present a visual breakdown of what your account would look like if you were to choose our suggested model portfolio with the managing fee of %PROPOSED_MANAGEMENT_FEE%.
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 12,
    title: 'Summary'
  },
  performance: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      The following section showcases how each of the different portfolio compositions has performed over time since their inception with the managing fee of %PROPOSED_MANAGEMENT_FEE%.
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 13,
    title: 'Performance'
  },
  historicalReturn: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 14,
    title: 'Historical Return'
  },
  risk: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 15,
    title: 'Portfolio Risk'
  },
  burndown: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      The chart below compares the current portfolio with the suggested portfolio. It shows that both the portfolios have nearly the same outcome in the lucky scenario where there is a bull market followed by a bear market. However, in the unlucky scenario where there is a bear market followed by the bull market, the high-risk portfolio suffers losses that deplete the portfolio, to a level that cannot support client withdrawal needs, resulting in financial ruin.
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 16,
    title: 'Sequence Risk for Retirees'
  },
  cost: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      expandAssets: {
        label: 'Toggle assets',
        permission: 'Toggle assets',
        allowed: true,
        value: true
      },
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 17,
    title: 'Cost Analysis'
  },
  yieldAnalysis: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      expandAssets: {
        label: 'Toggle assets',
        permission: 'Toggle assets',
        allowed: true,
        value: true
      },
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    hidden: false,
    order: 18,
    title: 'Yield Analysis'
  },
  _footer: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    footer: true,
    hidden: true,
    order: 0,
    title: 'Report Footer'
  }
};

export const IPS_DEFAULT_TEMPLATE = {
  _cover: {
    body: '',
    cover: true,
    order: 0,
    title: 'Investment Policy Statement',
    preparedBy: 'advisor',
    preparedFor: 'investor',
    hidden: false,
    allowEdit: true,
    allowHide: false,
    allowChangeText: false
  },
  purpose: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        The purpose of this Investment Policy Statement (IPS) is to assist you and your investment
        advisor in effectively supervising, monitoring, and evaluating the investment of your
        portfolio. Your investment program is defined in various sections of the IPS by:
        <ol>
          <li>
            Stating in a written document expectations, objectives, and guidelines for the
            investment of all assets.
          </li>
          <li>
            Setting forth an investment structure for managing the portfolio. This structure
            includes various asset classes, asset allocations, and acceptable ranges that, in total,
            are expected to produce an appropriate level of overall diversification and total
            investment return over the investment time horizon.
          </li>
          <li>Encouraging effective communications.</li>
          <li>
            Establishing a criteria to select, monitor, evaluate, and compare the performance of
            money managers on a regular basis.
          </li>
          <li>
            Complying with all applicable fiduciary, prudence, and due diligence requirements
            that experienced investment professionals utilize; and with all applicable laws, rules,
            and regulations from various local, state, federal, and international political entities
            that may impact the assets.
          </li>
        </ol>
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 1,
    saveChanges: true,
    title: 'Purpose'
  },
  background: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        This IPS has been prepared for <b>%CLIENT_NAME%</b>. The assets covered by this IPS currently total approximately are
        <b>%ACCOUNT_VALUE%</b> in market value.
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 2,
    saveChanges: true,
    title: 'Background'
  },
  executiveSummary: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        The Investment Policy Statement considers the information you have provided about your present
        and future financial circumstances. It is to document and ensure long-term adherence to an
        investment program. It covers the policies, practices and procedures for managing your investment assets.
      </p>
    `,
    currentAssets: '%ACCOUNT_VALUE%',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    investmentHorizon: '',
    order: 3,
    returnObjective: '',
    riskToleranceScore: '%RISK_TOLERANCE_SCORE%',
    saveChanges: true,
    title: 'Executive Summary',
    hidden: false
  },
  statementOfObjectives: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <h3>Goals:</h3>
      <p>
        This IPS describes a carefully thought out investment process the advisor assumes to be appropriate for your situation.
        We will help you maximize returns within cautious levels of risk, and to meet the following stated investment objectives:
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 4,
    saveChanges: true,
    title: 'Statement of Objectives'
  },
  riskTolerance: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        The portfolio's long time horizon coupled with your risk tolerance, along with prospects
        for the future, current financial condition, and several other factors suggest collectively
        some interim fluctuations in market value and rates of return may be tolerated to achieve
        the investment objective.
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 5,
    saveChanges: true,
    title: 'Risk Tolerance'
  },
  riskToleranceChart: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: true,
    editable: false,
    hidden: false,
    order: 6,
    saveChanges: false,
    title: ''
  },
  matchingModels: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 7,
    saveChanges: true,
    title: 'Matching Models'
  },
  assetClassPreference: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        You understand long-term investment performance, in large part, is primarily a function
        of asset class mix. You have reviewed the long-term performance characteristics of the
        broad asset classes, focusing on balancing the risks and rewards. At a minimum, the
        portfolio will be reviewed annually to determine if rebalancing is needed. The range of
        commitment would be to the following as asset allocations:
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 8,
    saveChanges: true,
    title: 'Asset Class Preference'
  },
  distributionSummary: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: true,
    editable: false,
    hidden: false,
    order: 9,
    saveChanges: false,
    title: ''
  },
  governance: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <h3>
        Monitoring and Review
      </h3>
      <p>
        The Advisor will review this IPS with you at least annually to determine
        whether stated investment objectives are still relevant, and the continued
        feasibility of achieving the same. It is not expected that the IPS will
        change frequently. In particular, short-term changes in the financial
        markets should not require adjustments to the IPS.
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: true,
    hidden: false,
    order: 10,
    saveChanges: true,
    title: 'Governance'
  },
  expectedReturns: {
    accountOnly: true,
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        The long-term expected rate of return on your portfolio is shown below. It
        is based on the expected long-term total return for each asset class and
        its percentage composition in your portfolio. You expressed expected
        return <b>%ANSWER_LINE%</b>%. Actual performance will vary from these assumed rates.
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    editable: false,
    hidden: false,
    order: 11,
    saveChanges: true,
    title: 'Expected Returns'
  },
  [INVESTMENT_STYLE]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: true,
    editable: true,
    hidden: false,
    order: 12,
    saveChanges: false,
    title: 'Investment Style'
  },
  [SECTOR_EXPOSURE]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: true,
    editable: true,
    hidden: false,
    order: 13,
    saveChanges: false,
    title: 'Sector Exposure'
  },
  [TOP_HOLDINGS]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: true,
    editable: true,
    hidden: false,
    order: 14,
    saveChanges: false,
    title: 'Top 10 Holdings'
  },
  [REGION_EXPOSURE]: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: true,
    editable: true,
    hidden: false,
    order: 15,
    saveChanges: false,
    title: 'Geographic Exposure'
  },
  investmentPolicyReview: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: `
      <p>
        The Investment Policy Statement for <b>%CLIENT_NAME%</b>
        has been reviewed, approved and adopted with the assistance of
        %ADVISOR_NAME% of %COMPANY_NAME%.
      </p>
      <p>
        By signing below, the parties confirm they have read and accept the content of this
        document. Each party acknowledges that they understand the information presented and agree
        to proceed based on this understanding.
      </p>
    `,
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: true
      }
    },
    editable: true,
    hidden: false,
    order: 16,
    saveChanges: true,
    title: 'Investment Policy Review'
  },
  signature: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    customActions: {
      breakSection: {
        label: 'Place Section on a New Page',
        allowed: true,
        value: false
      }
    },
    disabledEditing: false,
    editable: true,
    order: 17,
    saveChanges: false,
    signature: true,
    title: ''
  },
  _footer: {
    allowChangeText: true,
    allowEdit: true,
    allowHide: true,
    body: '',
    footer: true,
    hidden: true,
    order: 0,
    title: 'Report Footer'
  }
};

export const DEFAULT_QUESTIONNAIRE_EMAIL_TEMPLATE = {
  subject: 'Risk Assessment',
  message: `
    Hi %CLIENT_NAME%,<br /><br />
    Hope you are doing well. I am emailing you a link to a questionnaire that will help me understand your risk tolerance and investment objectives better.
    I will use the results of this assessment to align the investments in your account(s) with your preferences.
    The questionnaire should only take a few minutes of your time.
    Please click the link below to get started.
    <br /><br />
    %QUESTIONNAIRE_URL%
    <br /><br />
    Let me know if you have any questions.<br /><br />
    Best Regards,<br />
    %ADVISOR_FIRST_NAME% %ADVISOR_LAST_NAME%
  `
};

export const DEFAULT_CONNECT_EMAIL_TEMPLATE = {
  subject: 'Please Share Your Accounts',
  message: `
    Hi %CLIENT_NAME%,<br /><br />
    Hope you are doing well. I am emailing you a link to easily share your account with me.
    Access to your account will help me understand your portfolio risk and design a strategy to better achieve your investment goals.
    The process should only take a few minutes of your time.
    Please click the link below to get started.
    <br /><br />
    %QUESTIONNAIRE_URL%
    <br /><br />
    Let me know if you have any questions.<br /><br />
    Best Regards,<br />
    %ADVISOR_FIRST_NAME% %ADVISOR_LAST_NAME%
  `
};

export const DEFAULT_IPS_REVIEW_EMAIL_TEMPLATE = {
  subject: '[StratiFi] Investment Policy Statement Review',
  message: `
    Hi %CLIENT_NAME%,
    <br /><br />
    Hope this message finds you well. To ensure that we are aligned with your investment goals and strategies,
    I would like to ask you to review the Investment Policy Statement. This document outlines the guiding
    principles of your investment strategy and is crucial for maintaining our commitment to managing your portfolio
    according to your preferences.
    <br /><br />
    You can review the document by clicking on the link below:
    <br /><br />
    %REVIEW_URL%
    <br /><br />
    The process should only take a few minutes. Your review will confirm that we have accurately captured your
    investment objectives and that you agree with the proposed strategies.
    <br /><br />
    Please do not hesitate to reach out if you have any questions or if there is anything specific you would like
    to discuss regarding the policy.
    <br /><br />
    Best Regards,<br />
    %ADVISOR_FIRST_NAME% %ADVISOR_LAST_NAME%
  `
};

export const IPS_DEFAULT_SETTINGS = { allowSectionsOrder: true };

export const PROPOSAL_DEFAULT_SETTINGS = { allowSectionsOrder: true };

export default {
  ips: IPS_DEFAULT_TEMPLATE,
  ips_settings: IPS_DEFAULT_SETTINGS,
  proposal: PROPOSAL_DEFAULT_TEMPLATE,
  proposal_settings: PROPOSAL_DEFAULT_SETTINGS,
  crs_metadata: CRS_DEFAULT_TEMPLATE,
  questionnaire_email: DEFAULT_QUESTIONNAIRE_EMAIL_TEMPLATE,
  ips_review_email: DEFAULT_IPS_REVIEW_EMAIL_TEMPLATE
};
