export default {
  type: 'serial',
  categoryField: 'date',
  dataDateFormat: 'YYYY-MM-DD',
  colors: ['#DBDBDB', '#31E3FF', '#AF7CD3'],
  categoryAxis: {
    parseDates: true
  },
  chartCursor: {
    enabled: true,
    color: '#000000',
    cursorColor: '#999',
    categoryBalloonDateFormat: 'MMM YYYY'
  },
  graphs: [
    {
      fillAlphas: 0.75,
      fillColors: '#DBDBDB',
      id: 'target',
      lineThickness: 0,
      title: 'Current Portfolio',
      valueField: 'target'
    },
    {
      id: 'recommended',
      lineThickness: 2,
      showBalloon: false,
      title: 'Recommended',
      valueField: 'recommended'
    },
    {
      id: 'benchmark',
      lineThickness: 2,
      showBalloon: false,
      title: 'Benchmark',
      valueField: 'benchmark'
    }
  ],
  valueAxes: [
    {
      id: 'ValueAxis-1',
      labelFunction: (e, val) => `$${val}`
    }
  ],
  balloon: {
    borderColor: '#DFE1E5',
    borderThickness: 1,
    color: '#000',
    fillAlpha: 1,
    shadowAlpha: 0,
    textAlign: 'left'
  },
  legend: {
    enabled: true,
    align: 'right',
    marginLeft: 0,
    marginRight: 0,
    markerLabelGap: 7,
    markerSize: 14,
    position: 'top',
    valueWidth: 0
  }
};
