/* eslint-disable no-underscore-dangle */
import {
  CLASSIFICATIONS_GET,
  CLASSIFICATIONS_TAXONOMY_GET,
  SECURITY_GET,
  SECURITY_LOADED_CSV_POSITIONS
} from 'constants/actions';
import numeral from 'numeral';
import config from '../config';
import DataProvider from './data';
import MarketElasticProvider from './market.es';

const getParsedNumber = value => {
  const parsedValue = Number.parseFloat(value);
  if (Number.isNaN(parsedValue)) return numeral(value)._value;
  return parsedValue;
};

const securitiesToPositions = (securities, positionsValues) =>
  Array.isArray(securities)
    ? securities.map(
        ({
          id: security,
          ticker,
          type,
          subtype,
          sector,
          ticker_name: tickerName,
          prism_overall: prismOverall
        }) => ({
          ticker,
          security,
          type,
          subtype,
          sector,
          ticker_name: tickerName,
          prism_score_summary__overall: prismOverall,
          value: positionsValues[ticker]
        })
      )
    : [];

class MarketProvider extends DataProvider {
  static downloadCSVTemplateUrl = `${config.apiHost}/static/csv_templates/model_portfolio.csv`;

  static downloadCSVTemplateUrlProspect = `${config.apiHost}static/csv_templates/prospect_portfolio.csv`;

  get es() {
    if (!this._es) this._es = new MarketElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  getClassifications() {
    return this.provider.get(`${config.apiBase}securities/classifications/`).then(data => {
      this.dispatch(CLASSIFICATIONS_GET, data);
      return data;
    });
  }

  getTaxonomies() {
    return this.provider.get(`${config.apiBase}securities/taxonomies/`).then(({ data }) => {
      if (data) return this.dispatch(CLASSIFICATIONS_TAXONOMY_GET, { data });
      return null;
    });
  }

  updateTaxonomy(slug, data) {
    return this.provider.put(`${config.apiBase}securities/taxonomies/${slug}/`, data);
  }

  securityGet(id) {
    const promise =
      // GET '/api/securities/:id'
      this._provider.get(`${config.apiBase}securities/${id}/`).then(data => {
        if (!data.error)
          this.dispatch(SECURITY_GET, {
            data: data.data
          });
        return data;
      });

    return promise;
  }

  async list(tickers) {
    return this._provider.get(`${config.apiBase}securities/?ticker=${tickers.join(',')}`);
  }

  async create(tickers) {
    return this._provider.post(`${config.apiBase}securities/`, { tickers });
  }

  async addCSVDataToFormPositions(rawData) {
    const positionsValues = rawData.reduce((acum, [ticker, value]) => {
      const cleanedTicker = ticker.trim().toUpperCase();
      const cleanedValue = getParsedNumber(value);
      if (!!cleanedTicker && cleanedValue && !Number.isNaN(cleanedValue))
        acum[cleanedTicker] = cleanedValue;
      return acum;
    }, {});

    const tickers = Object.keys(positionsValues);

    const positions = [];
    if (tickers.length) {
      const { data } = await this.list(tickers);

      positions.push(...securitiesToPositions(data, positionsValues));

      const positionsTickers = positions.map(({ ticker }) => ticker);
      const missingTickers = tickers.filter(t => !positionsTickers.includes(t));

      // try to create new securities
      if (missingTickers.length) {
        const {
          data: { valid, invalid }
        } = await this.create(missingTickers);

        // append the new positions
        positions.push(...securitiesToPositions(valid, positionsValues));

        // append the invalid tickers
        positions.push(
          ...invalid.map(ticker => ({
            ticker,
            value: positionsValues[ticker]
          }))
        );
      }
    }

    this.dispatch(SECURITY_LOADED_CSV_POSITIONS, {
      data: {
        positions: positions.sort(
          (a, b) => (b.security ? b.value : -1) - (a.security ? a.value : -1)
        )
      }
    });
  }
}

export default MarketProvider;
