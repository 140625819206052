import cn from 'classnames';
import DeleteTemplateSectionModal from 'components/advisor/templates/delete-template-section-modal';
import Dropzone from 'components/dropzone';
import Choice from 'components/form/choice';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import { AdvisorContext } from 'containers/advisor';
import humps from 'humps';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { VISIBILITY_RESTRICTED_SECTION } from '../constants';
import { PageBreak } from '../edit-box';
import './styles.scss';

const MAX_FILE_SIZE = '10MB';
const MAX_FILE_SIZE_ERROR = 'Attachment is too large';
const MAX_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024;
const INVALID_FILE_MESSAGE = `The file you tried to upload is not valid. Make sure your file is a valid PDF under ${MAX_FILE_SIZE} and try uploading again.`;

const EmbeddedDocument = ({
  actionsDisabled,
  allowHide,
  attachmentName,
  body,
  className,
  deleteSection,
  editing,
  hidden,
  location,
  propertyKey,
  saveChanges,
  title,
  toggleEditing,
  toggleVisibility,
  url
}) => {
  const { attachmentsProvider, authProvider, user } = useContext(AdvisorContext);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metadata, setMetadata] = useState({ allowHide, hidden });

  const allowHideOption = editing ? metadata.allowHide : allowHide;
  const hasCompliancePermissions = authProvider.hasCompliancePermissions(user);
  const isTemplateView = location.pathname.match('advisor/templates/');
  const isTemplateViewWithAdminPermissions = isTemplateView && hasCompliancePermissions;

  const getSectionKey = (id, name) => `${name.replace(/\.pdf$/gi, '')}-${id}-embedded-document`;

  const onDrop = files => {
    if (files.length) setFile(files[0]);
    else toast.error(INVALID_FILE_MESSAGE);
  };

  const onPermissionsChange = values => () => {
    if (editing) setMetadata(prevMetadata => ({ ...prevMetadata, ...values }));
    else saveChanges(propertyKey, { body, hidden, title, ...values });
  };

  const onSaveEmbeddedDocument = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('attachment', file);

    return attachmentsProvider
      .uploadAttachment(formData)
      .then(({ data, error }) => {
        if (error || !data) {
          if (error?.errors?.attachment?.message === MAX_FILE_SIZE_ERROR) {
            toast.error(INVALID_FILE_MESSAGE);
            return;
          }
          throw Error();
        }
        saveChanges(getSectionKey(data.id, data.name), {
          ...metadata,
          attachment: true,
          attachmentId: data.id,
          attachmentName: data.name,
          body: '',
          url: data.attachment
        });
        toggleEditing();
      })
      .catch(() => {
        toast.error('Sorry, something went wrong while uploading the PDF file.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // the propertyKey is converted from camelCase to kebab-case
  // to follow HTML attribute naming standard (used in SASS styles)
  const kebabCasePropertyKey = humps.decamelize(propertyKey, { separator: '-' });

  return (
    <>
      {!hidden && <PageBreak text="Custom PDF Page Break" />}
      <section
        id={`${kebabCasePropertyKey}-section`}
        className={cn(
          'embedded-document-box',
          { 'embedded-document-box--templates': isTemplateView },
          className,
          { hidden }
        )}
      >
        {editing && (
          <>
            <div className="embedded-document-box__edit-actions">
              <button
                className="btn btn-link text-dark"
                disabled={loading}
                onClick={toggleEditing}
                type="button"
              >
                Cancel
              </button>

              <button
                className="btn btn-secondary"
                disabled={loading || !file}
                onClick={onSaveEmbeddedDocument}
                type="button"
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>

            <div className={cn('body', { editing, hidden })}>
              <div className="dropzone">
                <Dropzone accept="application/pdf" maxSize={MAX_FILE_SIZE_IN_BYTES} onDrop={onDrop}>
                  {file?.name || (
                    <>
                      <span>Drag and drop or click here to upload a file</span>
                      <span>(Max file size {MAX_FILE_SIZE})</span>
                    </>
                  )}
                </Dropzone>
              </div>
            </div>
          </>
        )}

        {!editing && (
          <div className={cn('body', { hidden })}>
            <a
              className="btn btn-outline-dark btn-sm embedded-document-link"
              href={url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {attachmentName} <span className="embedded-document-link__icon" />
            </a>

            <div className="body__actions">
              {!hasCompliancePermissions && !allowHideOption && (
                <span className="hidden-msg">{VISIBILITY_RESTRICTED_SECTION}</span>
              )}

              {isTemplateViewWithAdminPermissions && (
                <DeleteTemplateSectionModal
                  label="embedded PDF section"
                  onDelete={deleteSection}
                  sectionKey={propertyKey}
                  verb="Delete"
                />
              )}

              <AdministrativePermissionsTooltip
                renderTooltip={(!hasCompliancePermissions && !allowHide) || actionsDisabled}
                tooltipId={`${kebabCasePropertyKey}-section-edit`}
              >
                <button
                  className="btn btn-secondary btn-secondary-transparent"
                  disabled={(!hasCompliancePermissions && !allowHide) || actionsDisabled || loading}
                  onClick={toggleVisibility}
                  type="button"
                >
                  {hidden ? 'Show' : 'Hide'}
                </button>
              </AdministrativePermissionsTooltip>
            </div>
          </div>
        )}

        {isTemplateViewWithAdminPermissions && (
          <div className="permissions">
            <span className="permissions__title">Allow advisors to:</span>
            <ul>
              <li>
                <Choice
                  title={`${hidden ? 'Show' : 'Hide'} this section`}
                  checked={allowHideOption}
                  toggle={onPermissionsChange({ allowHide: !allowHideOption })}
                />
              </li>
            </ul>
          </div>
        )}
      </section>
      {!hidden && <PageBreak text="Custom PDF Page Break" />}
    </>
  );
};

EmbeddedDocument.propTypes = {
  actionsDisabled: PropTypes.bool,
  allowHide: PropTypes.bool,
  attachmentName: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  deleteSection: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  hidden: PropTypes.bool,
  location: PropTypes.object.isRequired,
  propertyKey: PropTypes.string.isRequired,
  saveChanges: PropTypes.func,
  title: PropTypes.string.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  url: PropTypes.string
};

EmbeddedDocument.defaultProps = {
  actionsDisabled: false,
  allowHide: true,
  attachmentName: '',
  body: '',
  className: '',
  editing: false,
  hidden: false,
  saveChanges: () => {},
  url: null
};

export default withRouter(EmbeddedDocument);
