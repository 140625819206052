import { Image, Text, View } from '@react-pdf/renderer';
import {
  CONCENTRATED_FACTOR_CHART_ID,
  CORRELATION_FACTOR_CHART_ID,
  TAIL_FACTOR_CHART_ID,
  VOLATILITY_FACTOR_CHART_ID
} from 'components/advisor/risk-analysis/risk-analysis-target-score/constants';
import { getChartData } from 'components/advisor/risk-analysis/risk-analysis-target/risk-analysis-content/utils';
import PropTypes from 'prop-types';
import React from 'react';
import RiskGauge from 'reports/base/charts/risk-gauge';
import BaseSection from 'reports/base/sections/base';
import { ACCOUNT_TARGET_TYPE } from '../../constants';
import styles from './styles';

const CERULEAN_COLOR = '#00abf4';
const SAIL_COLOR = '#9cddf7';

const Factor = ({ chart, legend, risk, style, text, title, tolerance }) => (
  <View style={[styles.factor, style]} wrap={false}>
    <Text style={styles.factorTitle}>{title}</Text>
    <View style={styles.factorContent}>
      <View style={styles.factorCell}>
        <RiskGauge width={80} height={80} risk={risk} tolerance={tolerance} withRiskScore />
      </View>
      <View style={styles.factorCell}>
        <Image src={chart} style={styles.factorChart} />
        {legend && (
          <View style={styles.factorChartLegend}>
            {legend.map((option, idx) => {
              const legendBoxMargin = { marginRight: idx < legend.length - 1 ? 5 : 0 };
              return (
                <View key={option.name} style={[styles.factorChartLegendBox, legendBoxMargin]}>
                  <View style={[styles.factorChartLegendDot, { backgroundColor: option.color }]} />
                  <Text>{option.name}</Text>
                </View>
              );
            })}
          </View>
        )}
      </View>
      <View style={[styles.factorCell, styles.factorText]}>
        <Text>{text}</Text>
      </View>
    </View>
  </View>
);

Factor.propTypes = {
  chart: PropTypes.string.isRequired,
  legend: PropTypes.array,
  risk: PropTypes.number.isRequired,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tolerance: PropTypes.number
};

Factor.defaultProps = {
  legend: null,
  style: {},
  tolerance: undefined
};

const PrismRiskAnalysis = ({ portfolio, prismSummary, targetSummary, type }) => {
  const getChartImage = chartId => {
    if (type === ACCOUNT_TARGET_TYPE) return portfolio[`${chartId}-${portfolio.id}`];
    return portfolio[chartId];
  };

  const portfolioPrefix = portfolio?.investor?.user ? 'your' : 'model';

  const { legend: concentratedChartLegend } = getChartData(prismSummary.concentrated.data);
  const tailChartLegend = [
    { name: 'Current Portfolio', color: CERULEAN_COLOR },
    { name: 'Benchmark 60/40 Portfolio', color: SAIL_COLOR }
  ];

  return (
    <BaseSection title="PRISM Risk Analysis" breakSection>
      <View style={styles.factors}>
        <Factor
          chart={getChartImage(TAIL_FACTOR_CHART_ID)}
          legend={tailChartLegend}
          risk={prismSummary.tail.value}
          tolerance={targetSummary?.tail}
          title="Tail Event Risk Rating"
          text={`Sudden and severe market losses happen. This score measures how vulnerable ${portfolioPrefix} portfolio is in the event of a market shock.`}
        />

        <Factor
          chart={getChartImage(CORRELATION_FACTOR_CHART_ID)}
          risk={prismSummary.correlation.value}
          tolerance={targetSummary?.correlation}
          title="Diversification Risk Rating"
          text={`When correlation in ${portfolioPrefix} portfolio is high, you lose the diversification benefits of your portfolio and expose yourself to a coordinated loss event.`}
        />

        <Factor
          chart={getChartImage(CONCENTRATED_FACTOR_CHART_ID)}
          legend={concentratedChartLegend}
          risk={prismSummary.concentrated.value}
          tolerance={targetSummary?.concentrated}
          title="Concentrated Stock Risk Rating"
          text={`A large percentage of ${portfolioPrefix} portfolio exposure represented in only a few stocks makes your portfolio more susceptible to idiosyncratic risk.`}
        />

        <Factor
          chart={getChartImage(VOLATILITY_FACTOR_CHART_ID)}
          risk={prismSummary.volatility.value}
          style={{ marginBottom: 0 }}
          tolerance={targetSummary?.volatility}
          title="Volatility Risk Rating"
          text="Breakdown of portfolio based on the gains and losses in holdings as the volatility in the markets changes. Assets that are positively correlated to volatility help reduce losses when volatility increases."
        />
      </View>
    </BaseSection>
  );
};

PrismRiskAnalysis.propTypes = {
  portfolio: PropTypes.object.isRequired,
  prismSummary: PropTypes.object.isRequired,
  targetSummary: PropTypes.object,
  type: PropTypes.string.isRequired
};

PrismRiskAnalysis.defaultProps = {
  targetSummary: {}
};

export default PrismRiskAnalysis;
