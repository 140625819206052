import React from 'react';
import { Link } from 'react-router';
import LockIcon from 'assets/img/icons/lock.svg';
import './styles.scss';

const ExtendedLink = props => {
  const { disabled, children, className, lock } = props;
  if (disabled)
    return (
      <span className={`disabled-link ${className || ''}`}>
        <span className="disabled-link__content">{children}</span>
        {lock && <img className="lock" src={LockIcon} alt="lock" />}
      </span>
    );
  return <Link {...props} />;
};

export default ExtendedLink;
