// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details-view .proposal-header .heading,
.account-details-view .disclosure {
  display: none;
}
.account-details-view .note-message {
  font-weight: 400;
}
.account-details-view .note-div {
  margin: 0;
}
.account-details-view .proposal-text > .info-text {
  padding: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/accounts/details/view/styles.scss"],"names":[],"mappings":"AACE;;EAEE,aAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,SAAA;AAFJ;AAKE;EACE,aAAA;AAHJ","sourcesContent":[".account-details-view {\n  .proposal-header .heading,\n  .disclosure {\n    display: none;\n  }\n\n  .note-message {\n    font-weight: 400;\n  }\n\n  .note-div {\n    margin: 0;\n  }\n\n  .proposal-text > .info-text {\n    padding: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
