import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const DynamicTableDeleteModal = ({ forbiddenDelete, items, label, onDelete, setRowSelection }) => {
  const [isShown, setIsShown] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const handleOnSubmit = async () => {
    setSubmitting(true);
    await onDelete(items)
      .then(async () => {
        setRowSelection({});
        hide();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const entity = items.length > 1 ? `${label}s` : label;
  const tooltipId = `remove-selected-tooltip`;
  const tooltipLabel = forbiddenDelete
    ? `You can only delete your own ${label.toLowerCase()}s. Contact the administrator if you want to delete this.`
    : 'Remove selected';

  return (
    <>
      {!!items.length && (
        <TooltipV2
          className="dynamic-table-header-action"
          id={tooltipId}
          label={tooltipLabel}
          place="top"
        >
          <div data-for={tooltipId} data-tip="">
            <button
              aria-label="Remove"
              className="btn btn-link text-dark btn-remove-selected"
              disabled={forbiddenDelete}
              onClick={show}
              type="button"
            >
              <img
                alt="Remove"
                src="/img/icons/dynamic-table/trash-can-regular.svg"
                style={{ height: 18 }}
              />
            </button>
          </div>
        </TooltipV2>
      )}

      {ReactDOM.createPortal(
        <Modal id="sdt__delete-modal" className="modal-lg" show={isShown} onHidden={hide}>
          <ModalHeader />
          <ModalBody>
            <h3 className="modal-title">Delete {entity}?</h3>
            <div className="description top">
              <div className="text">
                Are you sure you want to delete{' '}
                <span className="coloured-text">
                  {items.length} {entity}
                </span>
                ?
              </div>
              <div className="text text__warning padding">
                <span className="fs-icon-exclamation-circle warning-icon" />
                You can&apos;t revert this change.
              </div>
            </div>
            <div className="text-sm-center actions">
              <button type="button" className="btn cancel" onClick={hide}>
                Cancel
              </button>
              <LoadingButton
                className="btn btn-danger delete"
                disabled={submitting}
                loading={submitting}
                onClick={handleOnSubmit}
              >
                Delete
              </LoadingButton>
            </div>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

DynamicTableDeleteModal.propTypes = {
  forbiddenDelete: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  setRowSelection: PropTypes.func.isRequired
};

DynamicTableDeleteModal.defaultProps = {
  forbiddenDelete: false,
  items: []
};

export default DynamicTableDeleteModal;
