import cn from 'classnames';
import InvestmentHorizonChart from 'components/charts/investment-horizon-chart';
import Slider from 'components/slider/base';
import { fv } from 'financial';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { roundThousands } from 'utils/questionnaire';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useExpectedReturn from '../hooks';
import EXPECTED_RETURN_CHART_DATA, { NEGATIVE_COLOR, POSITIVE_COLOR } from './chart';
import './styles.scss';
import { getExpectedReturn } from './utils';

const InvestmentHorizonSkeletonChart = React.memo(() => (
  <InvestmentHorizonChart
    id="investment-horizon-skeleton-chart"
    style={{ height: '250px', width: '100%', filter: 'grayscale(100%)' }}
    data={EXPECTED_RETURN_CHART_DATA}
  />
));

const ExpectedReturnQuestion = ({ question, field, ...props }) => {
  const [loading, setLoading] = useState(false);

  const {
    contributionPerYear,
    goalInvestment,
    initialInvestment,
    onChange,
    questionDescription,
    questionText,
    recommendedReturn,
    withdrawalAmount,
    withdrawalDuration,
    withdrawalStartsIn
  } = useExpectedReturn({ field, question });

  const getTooltipContent = useCallback(
    value => (
      <div className="expected-return-tooltip">
        <span className="expected-return-tooltip__portfolio-value">
          <FormattedMessage id="rtq.question-8.final-portfolio-value" />
        </span>
        <br />
        <FormattedNumber
          value={
            withdrawalStartsIn
              ? fv(value / 100, withdrawalStartsIn, -contributionPerYear, -initialInvestment)
              : fv(value / 100, withdrawalDuration, withdrawalAmount, -initialInvestment)
          }
          format="currency"
        />
      </div>
    ),
    [
      contributionPerYear,
      initialInvestment,
      withdrawalAmount,
      withdrawalDuration,
      withdrawalStartsIn
    ]
  );

  const handleOnChange = value => {
    setLoading(true);
    onChange(value);
  };

  const handleOnAfterChange = () => {
    setLoading(false);
  };

  let recommendedReturnDisplay;

  if (recommendedReturn > 1)
    recommendedReturnDisplay = (
      <FormattedNumber value={recommendedReturn / 100} format="percent" maximumFractionDigits={2} />
    );
  else if (recommendedReturn > 0) recommendedReturnDisplay = '< 1%';
  else recommendedReturnDisplay = <FormattedNumber value={0} format="percent" />;

  let positiveAmount = 0;
  let negativeAmount = 0;
  const expectedReturn = getExpectedReturn(field.expected.value);

  const chartData = EXPECTED_RETURN_CHART_DATA.map(a => ({ ...a })); // clone the original chart data
  if (expectedReturn && chartData[expectedReturn]) {
    chartData[expectedReturn].positiveColor = POSITIVE_COLOR;
    chartData[expectedReturn].negativeColor = NEGATIVE_COLOR;
    positiveAmount = roundThousands((initialInvestment * chartData[expectedReturn].positive) / 100);
    negativeAmount = roundThousands((initialInvestment * chartData[expectedReturn].negative) / 100);
  }

  return (
    <Question
      {...props}
      question={{ ...question, question: questionText, description: questionDescription }}
      nextDisabled={field.needed.invalid || field.expected.invalid}
    >
      <div className="investment-value">
        <div className="goal-input">
          <div className="box">
            <span>
              <FormattedMessage id="rtq.question-8.initial-investment" />
            </span>
            <span>
              <FormattedNumber value={initialInvestment} format="currency" />
            </span>
          </div>
          <div className="box">
            <span>
              <FormattedMessage id="rtq.question-8.goal-investment" />
            </span>
            <span>
              <FormattedNumber value={goalInvestment} format="currency" />
            </span>
          </div>
          <div className="box">
            <span>
              <FormattedMessage
                id="rtq.question-8.yearly-contribution-or-withdrawal"
                values={{ withdrawalStartsIn }}
              />
            </span>
            <span>
              <FormattedNumber
                value={withdrawalStartsIn ? contributionPerYear : withdrawalAmount}
                format="currency"
              />
            </span>
          </div>
          <div className="box">
            <span>
              <FormattedMessage id="rtq.question-8.yearly-return-needed" />
            </span>
            <span>{recommendedReturnDisplay}</span>
          </div>
        </div>
      </div>

      <Slider
        value={field.expected.value}
        withHandle
        withTooltip
        getTooltipContent={getTooltipContent}
        className={cn({ '-withDots': question.data?.dots })}
        defaultValue={field.expected.initialValue}
        labels={question.data?.labels}
        onChange={handleOnChange}
        onAfterChange={handleOnAfterChange}
      />

      <div className="chart-container">
        {loading ? (
          <InvestmentHorizonSkeletonChart />
        ) : (
          <InvestmentHorizonChart
            id="investment-horizon-chart"
            style={{ height: '250px', width: '100%' }}
            data={chartData}
          />
        )}
        <div className={cn('chart-container__indicators', { 'indicators--loading': loading })}>
          <div className="loss">
            <FormattedMessage id="rtq.question-8.potential-yearly-loss" />
            <br />
            {!loading && (
              <>
                <FormattedNumber value={negativeAmount} format="currency" /> (
                {chartData[expectedReturn]?.negative ?? 0}%)
              </>
            )}
          </div>
          <div className="gain">
            <FormattedMessage id="rtq.question-8.potential-yearly-gain" />
            <br />
            {!loading && (
              <>
                <FormattedNumber value={positiveAmount} format="currency" /> (+
                {chartData[expectedReturn]?.positive ?? 0}%)
              </>
            )}
          </div>
        </div>
      </div>
    </Question>
  );
};

ExpectedReturnQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ExpectedReturnQuestion;
