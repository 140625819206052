import cn from 'classnames';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SecurityOverview from '../details/overview';
import SecurityReturns from '../details/returns';
import SecurityUnderlyingHolding from '../details/underlying-holding';
import PostCreate from './post-create';
import {
  INITIAL_STEP,
  RETURNS_STEP,
  UNDERLYING_HOLDING_STEP,
  POST_CREATE_STEP,
  RETURNS_FILE_WAS_IMPORTED,
  UNDERLYING_HOLDING_WAS_SAVED
} from '../constants';
import './styles.scss';

const DEFAULT_STEP = INITIAL_STEP;

const CustomSecurityCreate = ({ params, security }) => {
  const { marketProvider, customSecurityProvider, routerActions } = useContext(AdvisorContext);

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(DEFAULT_STEP);
  const [returnsWereImported, setReturnsWereImported] = useState(false);
  const [underlyingHoldingsWereSaved, setUnderlyingHoldingsWereSaved] = useState(false);

  const securityID = params?.id;

  const handleChangeStep = useCallback((currentStep, updatedCall) => {
    setStep(currentStep);
    if (updatedCall === RETURNS_FILE_WAS_IMPORTED) setReturnsWereImported(true);
    if (updatedCall === UNDERLYING_HOLDING_WAS_SAVED) setUnderlyingHoldingsWereSaved(true);
  }, []);

  useEffect(() => {
    if (returnsWereImported && underlyingHoldingsWereSaved)
      routerActions.push(`/advisor/securities/${security.id}/overview`);
  }, [returnsWereImported, underlyingHoldingsWereSaved]);

  useEffect(() => {
    setLoading(true);
    marketProvider.getClassifications();
    customSecurityProvider.get(securityID).finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="custom-security-create">
      <h3>Create Custom Security</h3>
      {step === POST_CREATE_STEP && (
        <PostCreate
          updateStep={handleChangeStep}
          returnsStepCompleted={returnsWereImported}
          underlyingHoldingsStepCompleted={underlyingHoldingsWereSaved}
          security={security}
        />
      )}
      <div className={cn({ 'security-create__child-component--hide': step !== INITIAL_STEP })}>
        <SecurityOverview updateStep={handleChangeStep} isVisible={step === INITIAL_STEP} />
      </div>
      {step === RETURNS_STEP && (
        <SecurityReturns updateStep={handleChangeStep} isVisible={step === RETURNS_STEP} />
      )}
      <div
        className={cn({
          'security-create__child-component--hide': step !== UNDERLYING_HOLDING_STEP
        })}
      >
        <SecurityUnderlyingHolding updateStep={handleChangeStep} />
      </div>
    </div>
  );
};

CustomSecurityCreate.propTypes = {
  security: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

const CustomSecurityCreateWithRouter = withRouter(CustomSecurityCreate);

export default connect(state => ({
  security: state.customSecurity.currentSecurity
}))(CustomSecurityCreateWithRouter);
