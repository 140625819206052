// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.household-header {
  width: 100%;
  max-width: 1120px;
  margin: 3rem auto 0 auto;
}
.household-header .trash-icon {
  width: 15px;
  height: 15px;
  fill: white;
  cursor: pointer;
}
.household-header .tooltiptext {
  min-width: 150px;
}
.household-header__container {
  display: flex;
  max-width: 1120px;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/households/households-header/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,wBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,iBAAA;EACA,8BAAA;AADJ","sourcesContent":[".household-header {\n  width: 100%;\n  max-width: 1120px;\n  margin: 3rem auto 0 auto;\n\n  .trash-icon {\n    width: 15px;\n    height: 15px;\n    fill: white;\n    cursor: pointer;\n  }\n\n  .tooltiptext {\n    min-width: 150px;\n  }\n\n  &__container {\n    display: flex;\n    max-width: 1120px;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
