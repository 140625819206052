import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import AggregatedRiskScoreBubble from 'components/advisor/utils/score-bubble/aggregated-risk';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import {
  OVERALL_FACTOR_ATTR,
  getPrismFactorName
} from 'containers/table/dynamic/filters/common/prism-factor/utils';
import { PRISM_FACTOR_ATTR } from 'containers/table/dynamic/filters/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import InvestorAccountsRow from '../investor-accounts';
import './styles.scss';

const InvestorGoalRow = ({ data: investor, meta, ...rest }) => {
  const [expanded, setExpanded] = useState({});

  const buildDefaultGoal = accounts => ({
    accounts,
    id: investor.id,
    investor,
    is_default: true,
    name: 'Accounts without goal'
  });

  const accountsWithoutGoals = investor.accounts.filter(account => !account.goal);
  const data = accountsWithoutGoals.length
    ? [...investor.goals, buildDefaultGoal(accountsWithoutGoals)]
    : investor.goals;

  const prismFactorAttr = meta?.params?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
  const prismFactorName = getPrismFactorName(prismFactorAttr);

  const columns = [
    { id: 'goal-select' },
    {
      accessorFn: row => row.name,
      id: 'goal-name',
      cell: ({ row: { original: goal } }) => (
        <div>
          <i className="fs-icon-goal" />
          <span>{goal.name}</span>
          {goal.is_default && investor.goals.length === 0 && (
            <Link
              className="btn btn-small btn-outline-primary"
              to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${
                goal.investor
              }/investment-objectives`}
            >
              Add Goals
            </Link>
          )}
        </div>
      ),
      meta: { className: () => 'td-goal-name' },
      colSpan: 2
    },
    {
      accessorFn: row => (row.accounts && !!row.accounts.length ? row.accounts.length : null),
      id: 'goal-accounts',
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row => (!row.is_default && row.value ? row.value : 0),
      id: 'goal-value',
      cell: ({ row: { original: goal } }) =>
        !goal.is_default && goal.value ? (
          <FormattedNumber value={goal.value} format="currency" />
        ) : null,
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        !row.is_default && row.aggregated_prism_scores
          ? row.aggregated_prism_scores[prismFactorAttr]
          : null,
      id: 'goal-prism',
      cell: ({ row: { original: goal } }) =>
        !goal.is_default && !_.isEmpty(goal.accounts) ? (
          <AggregatedRiskScoreBubble
            element={goal}
            url={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${
              goal.investor
            }/prism`}
            scoreName={prismFactorName}
          />
        ) : null,
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        !row.is_default && row.aggregated_target_scores
          ? row.aggregated_target_scores.overall
          : null,
      id: 'goal-risk-tolerance',
      cell: ({ row: { original: goal } }) =>
        !goal.is_default && !_.isEmpty(goal.accounts) ? (
          <AggregatedToleranceScoreBubble
            element={{ ...goal, id: goal.investor, is_prospect: investor.is_prospect }}
          />
        ) : null,
      meta: { className: () => 'text-center' }
    },
    {
      accessorFn: row =>
        !row.is_default && row.drift_summary?.overall ? row.drift_summary.overall.toFixed(1) : null,
      id: 'goal-drift',
      cell: ({ row: { original: goal } }) => {
        if (!goal.is_default && !_.isEmpty(goal.accounts))
          return goal.drift_summary?.overall ? goal.drift_summary.overall.toFixed(1) : '-';
        return null;
      },
      meta: {
        className: ({ row: { original: goal } }) =>
          cn(
            'text-center',
            { 'td-drift': !goal.is_default && !_.isEmpty(goal.accounts) },
            { 'td-drift--red': !_.isEmpty(goal.accounts) && !goal.is_healthy }
          ),
        style: () => ({ width: 75 })
      }
    },
    {
      id: 'goal-expander',
      header: '',
      cell: ({ row }) =>
        row.getCanExpand() && (
          <button
            className="btn btn-link sdt__expand"
            onClick={row.getToggleExpandedHandler()}
            style={{ cursor: 'pointer' }}
            type="button"
          >
            {row.getIsExpanded() ? (
              <span className="fs-icon-angle-up" />
            ) : (
              <span className="fs-icon-angle-down" />
            )}
          </button>
        ),
      enableSorting: false,
      meta: {
        className: () => 'text-center',
        style: () => ({ minWidth: 50 })
      }
    }
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    getSubRows: row => row.accounts,
    onExpandedChange: setExpanded,
    state: { expanded }
  });

  useEffect(() => {
    if (meta?.params?.search) {
      const goals = [0, ...investor.goals.map(goal => goal.id)];
      setExpanded(goals.reduce((acc, id) => ({ ...acc, [id]: true }), {}));
    } else table.resetExpanded();
  }, [JSON.stringify(meta?.params)]);

  if (investor.goals.length === 0) {
    return (
      <InvestorAccountsRow
        isProspect={investor.is_prospect}
        data={investor}
        meta={meta}
        {...rest}
      />
    );
  }

  return table.getRowModel().rows.map(row => (
    <Fragment key={row.id}>
      {row.depth === 0 && (
        <tr key={row.id} className="tr-goal">
          {row.getVisibleCells().map(cell => {
            const meta = cell.column.columnDef?.meta || {};
            const columnProps = {
              className: meta?.className ? meta.className(cell.getContext()) : undefined,
              style: meta?.style ? meta.style(cell.getContext()) : {}
            };
            return (
              <td {...columnProps} colSpan={cell.column.columnDef?.colSpan ?? 1} key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      )}
      {row.getIsExpanded() && (
        <InvestorAccountsRow
          isProspect={investor.is_prospect}
          data={row.original}
          meta={meta}
          {...rest}
        />
      )}
    </Fragment>
  ));
};

InvestorGoalRow.propTypes = {
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default InvestorGoalRow;
