/* eslint-disable no-underscore-dangle */
import { Identify, identify, init, reset, track } from '@amplitude/analytics-browser';

export const IS_VALID_USER_REGEX = /^(?:(?!stratifi|test|selenium|automation|webdrivertest01).)*$/i;

const START_LOGROCKET_IMPERSONATE = 'startLogRocketImpersonate';
const STOP_LOGROCKET_IMPERSONATE = 'stopLogRocketImpersonate';
const TRACKED_AMPLITUDE_LOGIN_EVENT = 'trackedAmplitudeLoginEvent';

const getUserInformation = user => {
  const { advisor, investor } = user;

  const id = user?.id;
  const email = user?.email;
  const name = `${user?.first_name} ${user?.last_name}`;

  let role;
  let companyName;
  let subscriptionPlan;
  let advisorId;

  if (advisor) {
    role = advisor?.role;
    companyName = advisor?.company?.name;
    subscriptionPlan = advisor?.company?.product;
    advisorId = advisor?.id;
  } else if (investor) {
    role = investor?.is_prospect ? 'prospect' : 'investor';
    companyName = investor?.advisor?.company?.name;
    subscriptionPlan = investor?.advisor?.company?.product;
  }

  return {
    advisorId,
    id,
    name,
    email,
    role,
    companyName,
    subscriptionPlan
  };
};

/* LogRocket tracking */

export const startLogRocketImpersonate = () => {
  if (window.LogRocket && window.localStorage)
    window.localStorage.setItem(START_LOGROCKET_IMPERSONATE, true);
};

export const stopLogRocketImpersonate = () => {
  if (window.LogRocket && window.localStorage)
    window.localStorage.setItem(STOP_LOGROCKET_IMPERSONATE, true);
};

export const recordLogRocketEvent = (event, params = {}) => {
  if (window.LogRocket) window.LogRocket.track(event, params);
};

export const startLogRocket = (user, params = {}) => {
  if (
    user?.email &&
    IS_VALID_USER_REGEX.test(user.email) &&
    window.LogRocket &&
    !user?.advisor?.company?.is_internal
  ) {
    window.LogRocket.init('uxcnq4/stratifi');

    if (
      window.LogRocket &&
      window.localStorage &&
      (window.localStorage.getItem(START_LOGROCKET_IMPERSONATE) ||
        window.localStorage.getItem(STOP_LOGROCKET_IMPERSONATE))
    ) {
      window.LogRocket.startNewSession();
      if (window.localStorage.getItem(START_LOGROCKET_IMPERSONATE))
        recordLogRocketEvent('Impersonating');
      window.localStorage.removeItem(START_LOGROCKET_IMPERSONATE);
      window.localStorage.removeItem(STOP_LOGROCKET_IMPERSONATE);
    }

    const { id, name, email, role, companyName, subscriptionPlan } = getUserInformation(user);

    window.LogRocket.identify(String(id), {
      name,
      email,
      role,
      companyName,
      subscriptionPlan,
      ...params
    });
  }
};

/* HubSpot tracking */

export const startHubSpot = (user, params = {}) => {
  if (
    user?.email &&
    user.advisor &&
    IS_VALID_USER_REGEX.test(user.email) &&
    !user?.advisor?.company?.is_internal
  ) {
    const { id, email } = getUserInformation(user);
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['identify', { email, id }]);
    _hsq.push(['trackPageView']);
  }
};

export const trackHubSpotPageView = path => {
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);
};

/* Amplitude tracking */

const trackAmplitudeLoginEvent = (event, params) => {
  if (window.sessionStorage && !window.sessionStorage.getItem(TRACKED_AMPLITUDE_LOGIN_EVENT)) {
    track(event, params);
    window.sessionStorage.setItem(TRACKED_AMPLITUDE_LOGIN_EVENT, true);
  }
};

export const initAmplitude = userEmail => {
  init(process.env.AMPLITUDE_KEY, userEmail);
};

export const startAmplitude = user => {
  if (
    user?.email &&
    IS_VALID_USER_REGEX.test(user.email) &&
    process.env.STRATIFI_ENV === 'production' &&
    !user?.advisor?.company?.is_internal
  ) {
    const { id, name, email, role, companyName, subscriptionPlan, advisorId } =
      getUserInformation(user);

    initAmplitude(email);

    /* Identifying user */

    const identifyObj = new Identify();

    identifyObj.set('id', id);
    identifyObj.set('advisor', advisorId);
    identifyObj.set('role', role);
    identifyObj.set('name', name);
    identifyObj.set('email', email);
    identifyObj.set('companyName', companyName);
    identifyObj.set('subscriptionPlan', subscriptionPlan);
    identify(identifyObj);

    /* Tracking login event */

    const loginEvent = ['prospect', 'investor'].includes(role) ? 'investor.login' : 'advisor.login';

    trackAmplitudeLoginEvent(loginEvent, {
      id,
      role,
      email,
      companyName,
      subscriptionPlan,
      advisorId
    });

    /* Tracking LogRocket event */

    if (window.LogRocket)
      window.LogRocket.getSessionURL(sessionURL => {
        track('logRocket', { id, email, sessionURL });
      });
  }
};

export const trackAmplitudeEvent = (event, params = {}) => {
  if (process.env.STRATIFI_ENV === 'production') track(event, params);
};

export const stopAmplitude = () => {
  reset();
  window.sessionStorage.removeItem(TRACKED_AMPLITUDE_LOGIN_EVENT);
};
