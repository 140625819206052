import DistributionSummary from 'components/advisor/proposal/distribution-summary/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { combinePortfolios } from 'utils/utils';
import { IPSPropTypes, MetadataPropTypes } from '../../types';

const IPSDistributionSummary = ({ breakSection, ips, metadata, orientation }) => {
  const { body, title } = metadata;

  const {
    proposal,
    recommended_snapshots: recommendedSnapshots,
    target_snapshots: targetSnapshots
  } = ips;

  const distributionProps = {
    target: combinePortfolios(targetSnapshots),
    targetName: proposal.target_label
  };

  const hasRecommended = !!proposal.recommended;
  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = combinePortfolios(recommendedSnapshots);

    // Assign weighted positions instead of the plain ones if possible
    if (proposal?.recommended?.positions)
      distributionProps.recommended.positions = proposal?.recommended?.positions;
  }

  return (
    <BaseSection body={body} title={title} breakSection={breakSection} wrap>
      <DistributionSummary orientation={orientation} proposal={proposal} {...distributionProps} />
    </BaseSection>
  );
};

IPSDistributionSummary.propTypes = {
  breakSection: PropTypes.bool,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  orientation: PropTypes.string.isRequired
};

IPSDistributionSummary.defaultProps = {
  breakSection: false
};

export default IPSDistributionSummary;
