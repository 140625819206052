/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import { questionFlavors as qf, questionTypes as qt } from 'constants/questionnaire';
import AddressQuestion from 'containers/risk-tolerance-questionnaire/question/address/full';
import AnnualTaxableIncomeQuestion from 'containers/risk-tolerance-questionnaire/question/annual-taxable-income/full';
import BirthdayRetirementQuestion from 'containers/risk-tolerance-questionnaire/question/birthday-retirement/full';
import CashInvestableAssetsQuestion from 'containers/risk-tolerance-questionnaire/question/cash-investable-assets/full';
import ChoicesQuestion from 'containers/risk-tolerance-questionnaire/question/choices/full';
import ExpectedReturnQuestion from 'containers/risk-tolerance-questionnaire/question/expected-return/full';
import GoalApproachQuestion from 'containers/risk-tolerance-questionnaire/question/goal-approach/full';
import LossReactionQuestion from 'containers/risk-tolerance-questionnaire/question/loss-reaction/full';
import NumericQuestion from 'containers/risk-tolerance-questionnaire/question/numeric/full';
import PortfolioConcentrationQuestion from 'containers/risk-tolerance-questionnaire/question/portfolio-concentration/full';
import RangeQuestion from 'containers/risk-tolerance-questionnaire/question/range/full';
import SectionSeparator from 'containers/risk-tolerance-questionnaire/question/section-separator/full';
import SingleYearOutcomeQuestion from 'containers/risk-tolerance-questionnaire/question/single-year-outcome/full';
import TextQuestion from 'containers/risk-tolerance-questionnaire/question/text/full';
import TolerableLossQuestion from 'containers/risk-tolerance-questionnaire/question/tolerable-loss/full';
import ValueRequirementQuestion from 'containers/risk-tolerance-questionnaire/question/value-requirement/full';
import WithdrawalRequirementQuestion from 'containers/risk-tolerance-questionnaire/question/withdrawal-requirement/full';
import MultiStepView from 'hocs/multistep-view';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { range } from 'utils/utils';
import withRiskToleranceForm from './form';
import './styles.scss';

const BORDER_TOP_HEIGHT = 7;

const getComponentForQuestion = (type, flavor) => {
  if (type === qt.CUSTOM) {
    if (flavor === qf.ADDRESS) return AddressQuestion;
    if (flavor === qf.ANNUAL_TAXABLE_INCOME) return AnnualTaxableIncomeQuestion;
    if (flavor === qf.BIRTHDAY_RETIREMENT) return BirthdayRetirementQuestion;
    if (flavor === qf.CASH_INVESTABLE_ASSETS) return CashInvestableAssetsQuestion;
    if (flavor === qf.EXPECTED_RETURN) return ExpectedReturnQuestion;
    if (flavor === qf.GOAL_APPROACH) return GoalApproachQuestion;
    if (flavor === qf.LOSS_REACTION) return LossReactionQuestion;
    if (flavor === qf.PORTFOLIO_CONCENTRATION) return PortfolioConcentrationQuestion;
    if (flavor === qf.SINGLE_YEAR_OUTCOME) return SingleYearOutcomeQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return TolerableLossQuestion;
    if (flavor === qf.VALUE_REQUIREMENT) return ValueRequirementQuestion;
    if (flavor === qf.WITHDRAWAL_REQUIREMENT) return WithdrawalRequirementQuestion;
  }
  if (type === qt.CHOICE) return ChoicesQuestion;
  if (type === qt.NUMERIC) return NumericQuestion;
  if (type === qt.RANGE) return RangeQuestion;
  if (type === qt.SECTION) return SectionSeparator;
  if (type === qt.TEXT) return TextQuestion;
  return null;
};

const QuestionnaireQuestions = ({
  answers,
  fields,
  flavor,
  investors,
  hasPreviousAnswers,
  handleSubmit,
  mode,
  questionnaireId,
  questions
}) => {
  const formRef = useRef(null);
  const multiStepViewRef = useRef(null);

  const [currentQuestionPosition, setCurrentQuestionPosition] = useState(1);
  const [questionnaireMaxHeight, setQuestionnaireMaxHeight] = useState('auto');

  const totalAssets = useMemo(() => {
    if (mode === 'investor' || mode === 'accounts' || mode === 'account') {
      const [investor] = investors;
      return investor.accounts.reduce((total, a) => total + a.value, 0);
    }
    if (mode === 'investors')
      return investors.reduce(
        (grandTotal, i) => grandTotal + i.accounts.reduce((total, a) => total + a.value, 0),
        0
      );
    return 0;
  }, [mode, JSON.stringify(investors)]);

  const stepsConfig = useMemo(
    () =>
      questions.map(question => {
        const key = question.slug || `question-${question.position}`;
        return {
          component: getComponentForQuestion(question.question_type, question.data?.flavor),
          stepProps: {
            answers,
            field: fields[key],
            flavor,
            hasPreviousAnswers,
            key,
            question,
            questionnaireId,
            totalAssets
          }
        };
      }),
    [
      JSON.stringify(answers),
      JSON.stringify(questions),
      JSON.stringify(fields),
      questionnaireId,
      flavor
    ]
  );

  const onStepForward = () => setCurrentQuestionPosition(currentQuestionPosition + 1);
  const onStepBackward = () => setCurrentQuestionPosition(currentQuestionPosition - 1);

  const onlyQuestions = question => !question.is_section;
  const hasQuestionAnswered = question => {
    if (!question) return false;
    return answers[question.slug] && !_.isEmpty(answers[question.slug]);
  };

  const hasAllQuestionsAnswered =
    questions.filter(onlyQuestions).filter(q => hasQuestionAnswered(q.slug)).length ===
    questions.filter(onlyQuestions).length;

  const isFormDirty = Object.values(fields).some(
    field => field?.dirty || Object.values(field).some(f => f?.dirty)
  );
  const isFormInvalid = Object.values(fields).some(
    field => field?.invalid || Object.values(field).some(f => f?.invalid)
  );

  const goToQuestion =
    (question, step, force = false) =>
    _ => {
      if (hasQuestionAnswered(question) || force) {
        setCurrentQuestionPosition(step + 1);
        multiStepViewRef?.current?.goToStep(step);
      }
    };

  /**
   * Allows defining the maximum size of the questionnaire index in order to use
   * `overflow-y: auto` and improve navigation in long questionnaires. The use of `setTimeout`
   * in this way is a workaround to wait for the component referenced by `formRef` to render
   * correctly and calculate the current height
   */
  useEffect(() => {
    if (formRef.current)
      setTimeout(() => {
        setQuestionnaireMaxHeight(formRef.current.offsetHeight + BORDER_TOP_HEIGHT);
      }, 0);
  }, [currentQuestionPosition]);

  return (
    <div className="questionnaire-questions">
      {flavor === 'advisor' && (
        <div
          className="questionnaire-questions__index"
          style={{ maxHeight: questionnaireMaxHeight }}
        >
          {questions.map((question, idx) => {
            const prevQuestion = questions[idx - 1];
            const prevQuestionIsFirst = idx - 1 === 0;
            const hasPrevQuestionAnswered =
              (prevQuestionIsFirst && prevQuestion?.is_section) ||
              hasQuestionAnswered(prevQuestion);
            return question.is_section ? (
              <div
                key={question.slug}
                className={cn('index__section-separator', {
                  allowed: hasQuestionAnswered(question) || hasPrevQuestionAnswered || idx === 0
                })}
                onClick={goToQuestion(question, idx, hasPrevQuestionAnswered || idx === 0)}
              >
                {question.data.name}
              </div>
            ) : (
              <div
                key={question.slug}
                className={cn({
                  allowed: hasQuestionAnswered(question) || hasPrevQuestionAnswered,
                  'font-weight-bold': idx === currentQuestionPosition - 1,
                  'text-primary': hasQuestionAnswered(question)
                })}
                onClick={goToQuestion(question, idx, hasPrevQuestionAnswered)}
              >
                {question.question_number ?? question.position}. {question.data.name}
              </div>
            );
          })}
          {hasAllQuestionsAnswered && isFormDirty && (
            <button
              type="button"
              className="btn btn-primary"
              disabled={!hasAllQuestionsAnswered || !isFormDirty || isFormInvalid}
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      )}

      <div
        className={cn('questionnaire-questions__content', {
          'prism-questions-card': flavor === 'advisor',
          'prism-investor-content': flavor === 'investor'
        })}
      >
        <div className="prism-questions-progress">
          {range(1, questions.length).map(i => (
            <div
              key={i}
              className={cn('progress-item', {
                'progress-item-active': i <= currentQuestionPosition
              })}
              style={{
                width: `${99 / questions.length}%`,
                borderTopRightRadius: i === currentQuestionPosition ? '5px' : 0,
                borderBottomRightRadius: i === currentQuestionPosition ? '5px' : 0
              }}
            />
          ))}
        </div>

        <form
          onSubmit={handleSubmit}
          ref={formRef}
          className="risk-tolerance-questionnaire-inline-form"
        >
          {!!stepsConfig.length && (
            <MultiStepView
              config={stepsConfig}
              onStepForward={onStepForward}
              onStepBackward={onStepBackward}
              onLastStepSubmit={handleSubmit}
              ref={multiStepViewRef}
              withoutProgress
            />
          )}
        </form>
      </div>
    </div>
  );
};

QuestionnaireQuestions.propTypes = {
  answers: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  flavor: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasPreviousAnswers: PropTypes.bool.isRequired,
  investors: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
  questionProvider: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired
};

export default withRiskToleranceForm({ form: 'risk-tolerance-questionnaire-form' })(
  QuestionnaireQuestions
);
