import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { lowerAlpha, orderedAlpha, upperAlpha } from 'react-pdf-html/dist/ordered.type';

const renderer = (bulletOverrides, contentOverrides) => {
  const parser = ({ element, stylesheets, style, children }) => {
    const list = element.closest('ol, ul');
    const ordered = list?.tag === 'ol' || element.parentNode.tag === 'ol';

    const bulletStyles = stylesheets.map(stylesheet => stylesheet.li_bullet);
    const contentStyles = stylesheets.map(stylesheet => ({
      ...stylesheet.li_content,
      flexBasis: 0
    }));
    const listStyles =
      list?.style?.reduce((combined, listStyle) => ({ ...combined, ...listStyle }), {}) || {};
    const itemStyles = element.style.reduce(
      (combined, itemStyle) => ({ ...combined, ...itemStyle }),
      {}
    );

    const listStyleType =
      itemStyles.listStyleType ||
      itemStyles.listStyle ||
      listStyles.listStyleType ||
      listStyles.listStyle ||
      '';

    let bullet;
    if (listStyleType.includes('none')) bullet = false;
    else if (listStyleType.includes('url('))
      bullet = <Image src={listStyleType.match(/\((.*?)\)/)[1].replace(/(['"])/g, '')} />;
    else if (ordered)
      if (lowerAlpha.includes(listStyleType))
        bullet = <Text>{orderedAlpha[element.indexOfType].toLowerCase()}.</Text>;
      else if (upperAlpha.includes(listStyleType))
        bullet = <Text>{orderedAlpha[element.indexOfType].toUpperCase()}.</Text>;
      else bullet = <Text>{element.indexOfType + 1}.</Text>;
    else bullet = <Text>•</Text>;

    return (
      <View style={style}>
        {bullet && <View style={{ ...bulletStyles, ...bulletOverrides }}>{bullet}</View>}
        <View style={{ ...contentStyles, ...contentOverrides }}>{children}</View>
      </View>
    );
  };
  return parser;
};

export default renderer;
