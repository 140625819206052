import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { stripTags } from 'utils/questionnaire';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const BirthdayRetirementEmptyQuestion = ({ questions, question }) => {
  const choicesStyle = question?.description ? { marginTop: 0 } : { marginTop: 15 };

  return (
    <ReportEmptyQuestion
      questions={questions}
      question={{ ...question, question: 'What is your current age?' }}
    >
      <View style={styles.containerBlankBox} />
      <Text>What is your employment status?</Text>
      <View style={[styles.choices, choicesStyle]}>
        {question.data.options.map(option => (
          <View style={styles.mark}>
            <View style={styles.markCheck} />
            <Text style={styles.markText}>{stripTags(option.text || option.value)}</Text>
          </View>
        ))}
      </View>
    </ReportEmptyQuestion>
  );
};

BirthdayRetirementEmptyQuestion.propTypes = propTypes;

export default BirthdayRetirementEmptyQuestion;
