import React from 'react';
import FilterContainer from '../common/filter-container';
import MinMaxSliderFilter from '../common/min-max-slider';
import { getPrismFactorName, OVERALL_FACTOR_ATTR } from '../common/prism-factor/utils';
import { HOUSEHOLD_PRISM_FACTOR_ATTRS, PRISM_FACTOR_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';
import { getHouseholdMinMaxPrismFactorAttrs } from '../utils';

const HouseholdPrismOverallFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const prismFactorAttr = filters?.[PRISM_FACTOR_ATTR] || OVERALL_FACTOR_ATTR;
  const prismFactorName = getPrismFactorName(prismFactorAttr);

  const [minAttr, maxAttr] = getHouseholdMinMaxPrismFactorAttrs(prismFactorAttr);

  return (
    <FilterContainer
      attrs={HOUSEHOLD_PRISM_FACTOR_ATTRS}
      filters={filters}
      label={prismFactorName ? `Household PRISM ${prismFactorName}` : 'Household PRISM Overall'}
    >
      <MinMaxSliderFilter
        filters={filters}
        key={`household-${minAttr}-${maxAttr}`}
        maxAttr={maxAttr}
        minAttr={minAttr}
        setFilters={setFilters}
        setRequiresPageIndexReset={setRequiresPageIndexReset}
      />
    </FilterContainer>
  );
};

HouseholdPrismOverallFilter.propTypes = { ...filterPropTypes };

HouseholdPrismOverallFilter.defaultProps = { ...filterDefaultProps };

export default HouseholdPrismOverallFilter;
