import { questionTypes as qt } from 'constants/questionnaire';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import useExpectedReturn from '../hooks';

const ExpectedReturnReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  if (question.question_type === qt.LEGACY)
    return (
      <ReadOnlyQuestion question={question}>
        <p>{formatMoney(question.answer.amount)}</p>
      </ReadOnlyQuestion>
    );

  const { questionText } = useExpectedReturn({ question });
  return (
    <ReadOnlyQuestion question={{ ...question, question: questionText }}>
      <p>Yearly return expected: {formatPercentage(question.answer.expected, 1)}</p>
      <p>Yearly return needed: {formatPercentage(question.answer.needed, 1)}</p>
    </ReadOnlyQuestion>
  );
};

ExpectedReturnReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ExpectedReturnReadOnlyQuestion;
