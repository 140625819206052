import SpinnerLoader from 'components/performance-spinner';
import React from 'react';
import { toast } from 'react-toastify';

export const loadingActionToast = message =>
  toast.info(
    <div className="loading-action-toast">
      <SpinnerLoader spinnerLoading />
      <div className="text-div">{message}</div>
    </div>,
    {
      closeButton: true,
      autoClose: false,
      closeOnClick: false,
      className: 'proposal-toast'
    }
  );

export const copiedModelsToast = (amount, failed = false) => {
  const message = `${amount} model(s) ${failed ? 'were not created' : 'created successfully'}.`;
  if (failed) toast.error(message, { autoClose: 3000 });
  else toast.success(message, { autoClose: 3000 });
};
