import { useStripe } from '@stripe/react-stripe-js';
import classnames from 'classnames';
import StripeElementsWrapper from 'components/utils/stripe-elements';
import Payment from 'containers/advisor/checkout/payment/form';
import CheckoutPaymentSummary from 'containers/advisor/checkout/payment/summary';
import { AuthenticationContext } from 'containers/auth';
import HeroFormContainer from 'pages/utils/hero-form-container';
import PropTypes from 'prop-types';
import PriceProvider from 'providers/prices';
import PromoCodeProvider from 'providers/promo-code';
import SubscriptionProvider from 'providers/subscription';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sleep } from 'utils/utils';
import './styles.scss';

const CheckoutPayment = ({
  priceProvider,
  prices,
  promoCode,
  promoCodesProvider,
  selectedPriceLabel,
  subscriptionProvider
}) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({ promise: null });

  const stripe = useStripe();
  const { authProvider, routerActions } = useContext(AuthenticationContext);

  const selectedPrice = selectedPriceLabel
    ? prices.find(price => price.label === selectedPriceLabel)
    : null;
  const trialDays = selectedPrice?.trial_days || 0;

  const getPaymentMethodPromise = promise => {
    setPaymentMethod({ promise });
  };

  const onChangeSelectedPriceId = () => {
    priceProvider.setPrice(null);
    routerActions.push({ pathname: '/checkout/plans/', state: { changingSelectedPrice: true } });
  };

  const onApplyPromoCode = code => promoCodesProvider.validate(code);

  const onClearPromoCode = () => promoCodesProvider.clear();

  const onStripeError = (message = null) => {
    if (message) setError({ message });
    setProcessing(false);
  };

  const onStripeCheckoutStart = async () => {
    setError(null);
    setProcessing(true);

    if (!paymentMethod.promise) {
      onStripeError('There was an error processing your payment method details. Please try again.');
      return;
    }

    // 1. create the payment method
    const {
      paymentMethod: paymentMethodResponse,
      error: paymentMethodError
    } = await paymentMethod.promise();
    if (paymentMethodError) {
      onStripeError(paymentMethodError.message);
      return;
    }

    // 2. create the subscription and get the payment intent identifier
    const {
      data: subscriptionResponse,
      error: subscriptionError
    } = await subscriptionProvider.create(selectedPrice, promoCode?.id);
    if (subscriptionError) {
      onStripeError(subscriptionError.message);
      return;
    }

    // 3. confirm the payment
    const paymentIntentSecretId = subscriptionResponse?.subscription?.payment_intent;
    const setupIntentSecretId = subscriptionResponse?.subscription?.setup_intent;

    let confirmCardAction;
    let intentId;

    if (paymentIntentSecretId) {
      confirmCardAction = stripe.confirmCardPayment;
      intentId = paymentIntentSecretId;
    } else if (setupIntentSecretId) {
      confirmCardAction = stripe.confirmCardSetup;
      intentId = setupIntentSecretId;
    } else {
      onStripeError('There was an error processing your selected plan. Please try again.');
      return;
    }

    const { error: confirmIntentError } = await confirmCardAction(intentId, {
      payment_method: paymentMethodResponse.id
    });
    if (confirmIntentError) {
      onStripeError(confirmIntentError.message);
      return;
    }

    // 4. finish process
    await sleep(5);
    await authProvider.getUser();
    routerActions.push('/advisor/');

    setProcessing(false);
  };

  useEffect(() => {
    if (!selectedPriceLabel) routerActions.push({ pathname: '/checkout/plans/' });
  }, []);

  return (
    <div id="CheckoutPayment">
      <HeroFormContainer>
        <>
          <h1 className="text-sm-center signup-title">
            Start {trialDays ? 'your 14 day free trial' : 'with StratiFi'}
          </h1>
          {selectedPrice && (
            <>
              <Payment getPaymentMethodPromise={getPaymentMethodPromise} showCardHolderField />
              <CheckoutPaymentSummary
                onChangeSelectedPrice={onChangeSelectedPriceId}
                onApplyPromoCode={onApplyPromoCode}
                onClearPromoCode={onClearPromoCode}
                prices={prices}
                promoCode={promoCode}
                selectedPrice={selectedPrice}
              />
            </>
          )}
          <div className="checkout-error-message">
            <p>{error && error.message}</p>
          </div>
          <div className="mt-2 center-block">
            <input
              value={
                processing ? 'Processing...' : `${trialDays ? 'Start your free trial' : 'Start'}`
              }
              type="button"
              className={classnames('btn', 'btn-primary', 'btn-block', {
                'btn-processing': processing
              })}
              onClick={onStripeCheckoutStart}
              disabled={!paymentMethod.promise || processing}
            />
          </div>
        </>
      </HeroFormContainer>
    </div>
  );
};

CheckoutPayment.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

CheckoutPayment.defaultProps = {
  prices: [],
  selectedPriceLabel: null
};

CheckoutPayment.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.object),
  priceProvider: PropTypes.object.isRequired,
  promoCode: PropTypes.object.isRequired,
  promoCodesProvider: PropTypes.object.isRequired,
  subscriptionProvider: PropTypes.object.isRequired,
  selectedPriceLabel: PropTypes.string
};

const CheckoutPaymentWithStripeElements = props => (
  <StripeElementsWrapper>
    <CheckoutPayment {...props} />
  </StripeElementsWrapper>
);

export default connect(
  state => ({
    prices: state.prices.list,
    promoCode: state.promoCode,
    selectedPriceLabel: state.prices.selected
  }),
  dispatch => ({
    priceProvider: new PriceProvider({ dispatch }),
    promoCodesProvider: new PromoCodeProvider({ dispatch }),
    subscriptionProvider: new SubscriptionProvider({ dispatch })
  })
)(CheckoutPaymentWithStripeElements);
