import PropTypes from 'prop-types';
import React from 'react';

import ScoreBubble from 'components/advisor/utils/score-bubble';
import { numToRiskScaleString } from 'utils/utils';
import './styles.scss';

const ScoreBubbleDescription = ({ score, scoreDisplay }) => {
  const scoreOverall = Math.ceil(score);
  return (
    <div className="bubble-score-container">
      <ScoreBubble score={score} scoreDisplay={scoreDisplay} />
      <div
        className={`bubble-score-container__score score-representation-${scoreOverall} score-representation-full-prism `}
      >
        <span className="text">{numToRiskScaleString(score)}</span>
      </div>
    </div>
  );
};

ScoreBubbleDescription.propTypes = {
  score: PropTypes.number.isRequired,
  scoreDisplay: PropTypes.number.isRequired
};

export default ScoreBubbleDescription;
