import cn from 'classnames';
import { FormGroup, VerboseErrorInput } from 'components/form';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import 'react-toggle/style.css';
import { validation } from 'utils/form';

const ITEM_TYPE = 'DRAGGABLE_ITEM';

const DraggableEmail = ({ index, item, onChangeHandler, onMoveItemHandler, reorderEnabled }) => {
  const ref = useRef(null);

  const error = validation.required(item?.email) || validation.email(item.email);
  const commonProps = { dirty: true, error, touched: true };

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover: draggedItem => {
      if (reorderEnabled && draggedItem.index !== index) {
        onMoveItemHandler(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, type: ITEM_TYPE },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  if (reorderEnabled) drag(drop(ref));
  else drop(ref);

  return (
    <div
      className={cn('draggable-email', { 'allow-reorder': reorderEnabled })}
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <span>{item.name}</span>
      <FormGroup {...commonProps}>
        <VerboseErrorInput
          {...commonProps}
          className={cn('form-control', { 'is-invalid': !!error })}
          disabled={item.type === 'advisor'}
          label="Email Address"
          onChange={event => onChangeHandler(item.id, item.type, event.target.value)}
          type="text"
          value={item.email}
        />
      </FormGroup>
    </div>
  );
};

DraggableEmail.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onMoveItemHandler: PropTypes.func.isRequired,
  reorderEnabled: PropTypes.bool.isRequired
};

export default DraggableEmail;
