/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';
import LanguageOptions from '../language';

const NavigationAnonymous = ({ name }) => (
  <div className="account-info">
    {name && (
      <div>
        <span>{name}</span>
      </div>
    )}
    <LanguageOptions />
  </div>
);

NavigationAnonymous.propTypes = {
  name: PropTypes.string.isRequired
};

export default NavigationAnonymous;
