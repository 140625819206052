import RiskAnalysisTargetScore from 'components/advisor/risk-analysis/risk-analysis-target-score';
import {
  CONCENTRATED_SCORE,
  CORRELATION_SCORE,
  TAIL_SCORE,
  VOLATILITY_SCORE
} from 'components/advisor/risk-analysis/risk-analysis-target-score/constants';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const RATING_GAUGE_DIMENSIONS = { height: 100, width: 150 };
const OVERALL_GAUGE = { height: '140px', width: '200px' };
const OVERALL_OPTION = {
  innerRadius: '80%',
  radius: '100%',
  arrowRadius: '60%',
  arrowNailRadius: 5,
  arrowStartWith: 10
};

const AnalysisScores = ({ prismScore, targetScore }) => {
  const {
    concentrated: concentratedScore,
    correlation: correlationScore,
    tail: tailScore,
    volatility: volatilityScore
  } = prismScore;

  return (
    <div className="analysis-container">
      {tailScore && (
        <RiskAnalysisTargetScore
          score={parseFloat(tailScore.toFixed(1))}
          scoreName={TAIL_SCORE}
          overallStyles={OVERALL_GAUGE}
          scoreStyles={RATING_GAUGE_DIMENSIONS}
          overallOptions={OVERALL_OPTION}
          target={targetScore && targetScore.tail ? parseFloat(targetScore.tail.toFixed(1)) : null}
          text="Sudden and severe market losses happen. This score measures how vulnerable your portfolio is in the event of a market shock."
          title="Tail Event Risk"
          titleChart="Median Portfolio Move"
        />
      )}
      {correlationScore && (
        <RiskAnalysisTargetScore
          score={parseFloat(correlationScore.toFixed(1))}
          scoreName={CORRELATION_SCORE}
          overallStyles={OVERALL_GAUGE}
          scoreStyles={RATING_GAUGE_DIMENSIONS}
          overallOptions={OVERALL_OPTION}
          target={
            targetScore && targetScore.correlation
              ? parseFloat(targetScore.correlation.toFixed(1))
              : null
          }
          title="Diversification Risk"
          titleChart="Your asset correlation to other assets"
          text="When correlation in your portfolio is high, you lose the diversification benefits of your portfolio and expose yourself to a coordinated loss event."
        />
      )}
      {concentratedScore && (
        <RiskAnalysisTargetScore
          score={parseFloat(concentratedScore.toFixed(1))}
          scoreName={CONCENTRATED_SCORE}
          overallStyles={OVERALL_GAUGE}
          scoreStyles={RATING_GAUGE_DIMENSIONS}
          overallOptions={OVERALL_OPTION}
          target={
            targetScore && targetScore.concentrated
              ? parseFloat(targetScore.concentrated.toFixed(1))
              : null
          }
          title="Concentrated Stock Risk"
          titleChart="What your portfolio looks like"
          text="A large percentage of your portfolio exposure represented in only a few stocks makes your portfolio more susceptible to idiosyncratic risk."
        />
      )}
      {volatilityScore && (
        <RiskAnalysisTargetScore
          score={parseFloat(volatilityScore.toFixed(1))}
          scoreName={VOLATILITY_SCORE}
          overallStyles={OVERALL_GAUGE}
          scoreStyles={RATING_GAUGE_DIMENSIONS}
          overallOptions={OVERALL_OPTION}
          target={
            targetScore && targetScore.volatility
              ? parseFloat(targetScore.volatility.toFixed(1))
              : null
          }
          title="Volatility Risk"
          titleChart="Your asset diversification"
          text="Breakdown of portfolio based on the gains and losses in holdings as the volatility in the markets changes. Assets that are positively correlated to volatility help reduce losses when volatility increases."
        />
      )}
    </div>
  );
};

AnalysisScores.propTypes = {
  prismScore: PropTypes.object.isRequired,
  targetScore: PropTypes.object
};

AnalysisScores.defaultProps = {
  targetScore: null
};

export default AnalysisScores;
