import { valuesPerPage, preferredValuesPerPage, setPageSelected } from 'constants/pagination';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

export const PaginationDropdown = ({ onChange, ...rest }) => {
  const value = preferredValuesPerPage();

  const selectPagination = pagination => {
    setPageSelected(pagination);
    onChange(pagination);
  };

  return (
    <div>
      <Select
        isSearchable={false}
        onChange={selectPagination}
        options={valuesPerPage}
        value={value}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            fontSize: '14px',
            borderColor: '#7c8c94'
          }),
          option: styles => ({ ...styles, fontSize: '14px' })
        }}
        {...rest}
      />
    </div>
  );
};

PaginationDropdown.propTypes = {
  onChange: PropTypes.func
};

PaginationDropdown.defaultProps = {
  onChange: () => {}
};

export default PaginationDropdown;
