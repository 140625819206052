import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import './styles.scss';

export const FeatureLimitReached = ({ action, kind, text, title }) => (
  <div id="FeatureLimitReached">
    <h3>{title || `${kind} Limit Reached`}</h3>
    <p>
      {text && <strong>{text}</strong>}
      {!text && (
        <>
          <strong>
            You have reached a limit for {action} {kind} for your plan.
          </strong>{' '}
          If you would like to add more {kind}, please upgrade your plan.
        </>
      )}
    </p>
    <div className="actions">
      <button type="button" className="btn btn-secondary-2" data-dismiss="modal" aria-label="Close">
        Do it later
      </button>
      <Link className="btn btn-primary" to="/advisor/me/billing">
        Upgrade
      </Link>
    </div>
  </div>
);

FeatureLimitReached.propTypes = {
  action: PropTypes.string,
  kind: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

FeatureLimitReached.defaultProps = {
  action: '',
  kind: '',
  text: '',
  title: ''
};

const FeatureLimitReachedModal = ({ content, show, onHide }) => (
  <Modal id="FeatureLimitReachedModal" className="modal-sm" show={show} onHide={onHide}>
    <ModalBody>
      <FeatureLimitReached {...content} />
    </ModalBody>
  </Modal>
);

FeatureLimitReachedModal.propTypes = {
  content: PropTypes.shape({
    action: PropTypes.string,
    kind: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func
};

FeatureLimitReachedModal.defaultProps = {
  show: false,
  onHide: () => {}
};

export default FeatureLimitReachedModal;
