import { Circle, Defs, G, LinearGradient, Rect, Stop, Svg, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { PRISM_COLOR_0, PRISM_COLOR_5, PRISM_COLOR_9 } from 'reports/base/styles';
import BenchmarkTooltip from './benchmark-tooltip';
import styles from './styles';

const MAX_SCORE = 10;
const SCORE_LINE_WIDTH = 400;

const ModelPortfolioBenchmark = ({ benchmarks, prismOverall, targetOverall }) => (
  <BaseSection title="Benchmark PRISM Rating &trade;" wrap={false}>
    <View style={styles.benchmarkContainer}>
      <View style={styles.benchmark}>
        {benchmarks.map(
          (benchmark, idx) =>
            idx % 2 === 0 && (
              <BenchmarkTooltip
                key={benchmark.id}
                ticker={benchmark.display_name}
                score={benchmark.prism_score_summary.overall}
                style={{
                  position: 'absolute',
                  left: `${benchmark.prism_score_summary.overall * 10}%`
                }}
              />
            )
        )}
      </View>

      <View style={styles.benchmarkScoreLineContainer}>
        <Svg
          viewBox={`0 0 ${SCORE_LINE_WIDTH} 15`}
          width={SCORE_LINE_WIDTH}
          style={styles.benchmarkScoreLine}
        >
          <Defs>
            <LinearGradient id="prism-linear-gradient">
              <Stop offset="5%" stopColor={PRISM_COLOR_0} />
              <Stop offset="50%" stopColor={PRISM_COLOR_5} />
              <Stop offset="90%" stopColor={PRISM_COLOR_9} />
            </LinearGradient>
          </Defs>
          <G>
            <Rect width={SCORE_LINE_WIDTH} height="5" y="5" fill="url('#prism-linear-gradient')" />
            <Rect height="15" width="1" x="0" y="0" fill={PRISM_COLOR_0} />
            <Rect height="15" width="1" x={SCORE_LINE_WIDTH - 1} y="0" fill={PRISM_COLOR_9} />
          </G>
          <G>
            <Circle
              cx={(prismOverall * SCORE_LINE_WIDTH) / MAX_SCORE}
              cy="7.5"
              r="7.5"
              style={styles.prismScore}
            />
            {targetOverall && (
              <Circle
                cx={(targetOverall * SCORE_LINE_WIDTH) / MAX_SCORE}
                cy="7.5"
                r="7.5"
                style={styles.targetScore}
              />
            )}
          </G>
        </Svg>
      </View>

      <View style={styles.benchmark}>
        {benchmarks.map(
          (benchmark, idx) =>
            idx % 2 !== 0 && (
              <BenchmarkTooltip
                key={benchmark.id}
                ticker={benchmark.display_name}
                score={benchmark.prism_score_summary.overall}
                style={{
                  position: 'absolute',
                  left: `${benchmark.prism_score_summary.overall * 10}%`
                }}
                reverse
              />
            )
        )}
      </View>

      <View style={styles.benchmarkLegend}>
        <View style={[styles.benchmarkLegendBox, { marginRight: 10 }]}>
          <View style={styles.prismLegend} />
          <Text>Current PRISM</Text>
        </View>
        {targetOverall && (
          <View style={styles.benchmarkLegendBox}>
            <View style={styles.targetLegend} />
            <Text>Risk Tolerance</Text>
          </View>
        )}
      </View>
    </View>
  </BaseSection>
);

ModelPortfolioBenchmark.propTypes = {
  benchmarks: PropTypes.array,
  prismOverall: PropTypes.number,
  targetOverall: PropTypes.number
};

ModelPortfolioBenchmark.defaultProps = {
  benchmarks: [],
  prismOverall: null,
  targetOverall: null
};

export default ModelPortfolioBenchmark;
