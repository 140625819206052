// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.available-integration-provider {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e3e7ee;
  border-radius: 0.25rem;
  background-color: white;
}
.available-integration-provider .available-integration-provider__no-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
}
.available-integration-provider .available-integration-provider__no-brand > span {
  font-size: 1.5rem;
}
.available-integration-provider img {
  max-height: 125px;
}
.available-integration-provider .btn-connect,
.available-integration-provider .btn-request {
  width: 70%;
  max-width: 200px;
}
.available-integration-provider .btn-connect {
  font-weight: 400;
  margin: 1rem;
}
.available-integration-provider .btn-request {
  margin: 0 1rem 1rem;
}
@media (min-width: 1200px) {
  .available-integration-provider .available-integration-provider__no-brand {
    height: 150px;
  }
  .available-integration-provider img {
    max-height: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/integrations-dashboard/available-provider/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,uBAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AADJ;AAGI;EACE,iBAAA;AADN;AAKE;EACE,iBAAA;AAHJ;AAME;;EAEE,UAAA;EACA,gBAAA;AAJJ;AAME;EACE,gBAAA;EACA,YAAA;AAJJ;AAME;EACE,mBAAA;AAJJ;AAOE;EACE;IACE,aAAA;EALJ;EAQE;IACE,iBAAA;EANJ;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.available-integration-provider {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid $gray-light-3;\n  border-radius: 0.25rem;\n  background-color: white;\n\n  .available-integration-provider__no-brand {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 125px;\n\n    & > span {\n      font-size: 1.5rem;\n    }\n  }\n\n  img {\n    max-height: 125px;\n  }\n\n  .btn-connect,\n  .btn-request {\n    width: 70%;\n    max-width: 200px;\n  }\n  .btn-connect {\n    font-weight: 400;\n    margin: 1rem;\n  }\n  .btn-request {\n    margin: 0 1rem 1rem;\n  }\n\n  @media (min-width: 1200px) {\n    .available-integration-provider__no-brand {\n      height: 150px;\n    }\n\n    img {\n      max-height: 150px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
