import cn from 'classnames';
import Disclosure from 'components/disclosure';
import Choice from 'components/form/choice';
import Link from 'components/utils/link';
import { CustomerSupportEmailLink } from 'constants/contact';
import { AdvisorContext } from 'containers/advisor';
import withSubscription from 'hocs/subscription-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { integrationsConnectedSelector } from 'selectors/integrations';
import './styles.scss';

const AVAILABLE = 'available';
const CONNECTED = 'connected';

const TABS = {
  [AVAILABLE]: {
    title: 'Available integrations',
    url: '/advisor/integrations/available'
  },
  [CONNECTED]: {
    title: 'Connected integrations',
    url: '/advisor/integrations/connected'
  }
};

const IntegrationsDashboard = ({ children, integrations, location, user }) => {
  const { pathname } = location;
  const { authProvider, integrationProvider, routerActions, companyProvider } = useContext(
    AdvisorContext
  );

  const setMultipleIntegrations = value => {
    // multipleIntegrations ? advisor-level integrations : company-level integrations
    companyProvider
      .update({ multiple_integrations: value })
      .then(() => {
        toast.success(`The integrations will work at ${value ? 'advisor' : 'company'} level`);
        authProvider.getUser();
      })
      .catch(() => {
        toast.error('Something went wrong changing the integrations configuration');
      });
  };
  const useAdvisorLevelIntegrations = () => {
    setMultipleIntegrations(true);
  };
  const useCompanyLevelIntegrations = () => {
    setMultipleIntegrations(false);
  };

  useEffect(() => {
    const {
      [CONNECTED]: { url }
    } = TABS;
    // Redirect to 'Connected integrations' section if user doesn't
    // have admin or manager permissions and integrations are
    // configured at company-level.
    if (!authProvider.hasManagerPermissions(user) && !user.advisor.company.multiple_integrations)
      routerActions.push(url);
  }, [integrations, user.advisor.company.multiple_integrations]);

  useEffect(() => {
    integrationProvider.getConnected();
    integrationProvider.listProviders();
  }, []);

  const hasIntegrations = !_.isEmpty(integrations);

  return (
    <div id="integrations-dashboard">
      {authProvider.hasManagerPermissions(user) && (
        <div className="options">
          <div className="integrations-level">
            <Choice
              checked={user.advisor.company.multiple_integrations}
              toggle={useAdvisorLevelIntegrations}
              disabled={hasIntegrations}
            />
            <span className="integrations-level__label">Advisor Level Integration</span>
            <Choice
              checked={!user.advisor.company.multiple_integrations}
              toggle={useCompanyLevelIntegrations}
              disabled={hasIntegrations}
            />
            <span className="integrations-level__label">Company Level Integration</span>
          </div>
          {hasIntegrations && (
            <p className="integrations-alert">
              Please contact support if you want to change this setting. Use the support chat at the
              bottom right of the screen, or contact at <CustomerSupportEmailLink />
            </p>
          )}
        </div>
      )}
      <ul className="tabs">
        {Object.entries(TABS)
          .filter(
            ([key]) =>
              // 'Available integrations' will be visible only if
              // - user is manager or above, or
              // - integrations are configured at advisor-level.
              key !== AVAILABLE ||
              authProvider.hasManagerPermissions(user) ||
              user.advisor.company.multiple_integrations
          )
          .map(([key, { title, url }]) => (
            <li className={cn({ active: pathname.includes(key) })} key={key}>
              <Link
                className="btn-transparent"
                to={url}
                disabled={key === CONNECTED && !hasIntegrations}
              >
                {title}
              </Link>
            </li>
          ))}
      </ul>
      {children}
      <Disclosure />
    </div>
  );
};

IntegrationsDashboard.defaultProps = {
  children: null
};

IntegrationsDashboard.propTypes = {
  children: PropTypes.element,
  integrations: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const IntegrationsDashboardWithRouter = withRouter(IntegrationsDashboard);

export default compose(
  withSubscription({
    plan: 'professional',
    id: 'integrations',
    name: 'Integrations',
    inline: true
  }),
  connect(state => ({
    integrations: integrationsConnectedSelector(state),
    user: state.auth.user
  }))
)(IntegrationsDashboardWithRouter);
