// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-slider-container {
  display: flex;
  flex-direction: column;
}
.range-slider-container .range-slider-container__component {
  margin: 30px 10px;
}

.rc-slider-tooltip-arrow {
  display: none;
}

.rc-slider-mark-text {
  font-weight: 400;
}

.rc-slider-tooltip {
  z-index: 1050;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/table/dynamic/filters/common/range-slider/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,iBAAA;AADJ;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,aAAA;AAFF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.range-slider-container {\n  display: flex;\n  flex-direction: column;\n\n  .range-slider-container__component {\n    margin: 30px 10px;\n  }\n}\n\n.rc-slider-tooltip-arrow {\n  display: none;\n}\n\n.rc-slider-mark-text {\n  font-weight: 400;\n}\n\n.rc-slider-tooltip {\n  z-index: 1050;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
