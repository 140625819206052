import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import PropTypes from 'prop-types';
import React from 'react';

const InvestorRiskToleranceCell = ({ investor, onSelectRiskToleranceHandler }) => (
  <AggregatedToleranceScoreBubble element={investor} onClick={onSelectRiskToleranceHandler} />
);

InvestorRiskToleranceCell.propTypes = {
  investor: PropTypes.object.isRequired,
  onSelectRiskToleranceHandler: PropTypes.func.isRequired
};

export default InvestorRiskToleranceCell;
