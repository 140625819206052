// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#collaboration-hub {
  margin-top: 32px;
}
#collaboration-hub > .header h2 {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 38px;
}
#collaboration-hub > .header span {
  font-size: 0.9rem;
}
#collaboration-hub ul.tabs {
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/collaboration-hub/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAII;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAFN;AAKI;EACE,iBAAA;AAHN;AAOE;EACE,gBAAA;AALJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#collaboration-hub {\n  margin-top: 32px;\n\n  & > .header {\n    h2 {\n      margin-bottom: 0;\n      font-size: 2rem;\n      line-height: 38px;\n    }\n\n    span {\n      font-size: 0.9rem;\n    }\n  }\n\n  ul.tabs {\n    margin-top: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
