// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#connected-integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}
#connected-integrations .no-connected-integrations {
  grid-column: 1/-1;
  margin: 1rem 0 0;
}
@media (min-width: 1200px) {
  #connected-integrations {
    grid-template-columns: repeat(4, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/integrations-dashboard/connected/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;EAAA,SAAA;AADF;AAGE;EACE,iBAAA;EACA,gBAAA;AADJ;AAIE;EAVF;IAWI,qCAAA;EADF;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#connected-integrations {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 1rem;\n\n  .no-connected-integrations {\n    grid-column: 1 / -1;\n    margin: 1rem 0 0;\n  }\n\n  @media (min-width: 1200px) {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
