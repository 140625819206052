import { createSelector } from 'reselect';

export const notificationsSelector = state => state.notifications;

export const filteredNotificationsSelector = (state, criteria) =>
  createSelector(notificationsSelector, notifications => {
    const isString = val => typeof val === 'string' || val instanceof String;

    if (criteria) {
      const byCriteria = x => x.message.match(isString(criteria) ? criteria : criteria.join('|'));
      return notifications.filter(byCriteria);
    }

    return notifications;
  })(state);
