import MultiCheckboxField from 'components/form/multi-checkbox-field';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import usePortfolioConcentration from '../hooks';
import './styles.scss';

const PortfolioConcentrationQuestion = ({ question, field, ...props }) => {
  const { choices, initialInvestment, questionText } = usePortfolioConcentration({
    field,
    question
  });

  return (
    <Question
      {...props}
      question={{ ...question, question: questionText }}
      nextDisabled={field.invalid}
    >
      <MultiCheckboxField
        field={field}
        options={choices}
        multiple={question.data?.multiple_answers_allowed}
        flavor="image-choices"
      />

      <div className="concentration-amount">
        <FormattedNumber value={initialInvestment * Number(field.value)} format="currency" />
      </div>
    </Question>
  );
};

PortfolioConcentrationQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default PortfolioConcentrationQuestion;
