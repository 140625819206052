import { INVOICE_UPCOMING, SUBSCRIPTION_GET } from 'constants/actions';

export const initialState = {
  upcomingInvoice: null,
  current: null
};

export default function subscription(state = initialState, action) {
  switch (action.type) {
    case INVOICE_UPCOMING:
      return { ...state, upcomingInvoice: action.data };

    case SUBSCRIPTION_GET:
      return { ...state, current: action.data };

    default:
      return state;
  }
}
