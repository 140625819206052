import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { QuestionPropTypes } from '../../props';
import styles from './styles';

const SectionSeparator = ({ question }) => (
  <View style={styles.row} wrap={false}>
    <Text style={styles.title}>{question.question}</Text>
    {question.description && <Text style={styles.description}>{question.description}</Text>}
  </View>
);

SectionSeparator.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default SectionSeparator;
