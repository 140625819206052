import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

const SortableHeader = ({ title }) => (
  <>
    <span
      data-tip="Hold down shift to sort by more than one column."
      data-for="react-table-sort-header-tooltip"
    >
      {title}
      <img src="/img/sorting.svg" className="sorting" alt="" />
    </span>
    <ReactTooltip
      id="react-table-sort-header-tooltip"
      className="react-table-sort-header-tooltip"
      effect="solid"
      place="bottom"
      delayShow={1000}
    />
  </>
);

const DriftBox = ({ isHealthy, snoozed, value, withExceptions }) => {
  let dataTipMsg = null;
  if (withExceptions && isHealthy)
    dataTipMsg = `The client's overall drift is smaller than the threshold, but there are account(s) with exception(s).`;
  else if (snoozed) dataTipMsg = "This account's exception is snoozed.";

  return (
    <div
      className={cn('drift-box', {
        'with-exceptions': withExceptions,
        red: !isHealthy,
        snoozed
      })}
      data-tip={dataTipMsg}
      data-for="drift-box"
    >
      {withExceptions && isHealthy && <i className="fs-icon-exclamation-circle" />}
      {snoozed && <i className="fs-icon-bell-slash" />}
      {value ? parseFloat(Number(value).toFixed(1)) : '-'}
      <ReactTooltip id="drift-box" className="drift-box-tip" effect="solid" place="top" />
    </div>
  );
};

SortableHeader.propTypes = {
  title: PropTypes.string.isRequired
};

DriftBox.propTypes = {
  isHealthy: PropTypes.bool.isRequired,
  snoozed: PropTypes.bool,
  value: PropTypes.number.isRequired,
  withExceptions: PropTypes.bool
};

DriftBox.defaultProps = {
  withExceptions: false,
  snoozed: false
};

export { DriftBox, SortableHeader };
