import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE_ROW,
  FOR_LANDSCAPE_ORIENTATION,
  LIGHT_GRAPHITE_COLOR
} from 'reports/base/styles';

const styles = StyleSheet.create({
  factors: {
    ...BASE_TABLE_ROW,
    justifyContent: 'space-between',
    [FOR_LANDSCAPE_ORIENTATION]: {
      justifyContent: 'space-around'
    }
  },
  factor: {
    alignItems: 'center',
    border: 1,
    borderColor: LIGHT_GRAPHITE_COLOR,
    borderStyle: 'solid'
  },
  factorTitle: {
    width: 130,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 5,
    textAlign: 'center',
    backgroundColor: LIGHT_GRAPHITE_COLOR
  },
  factorText: { width: 130, fontSize: 8, paddingHorizontal: 10, paddingTop: 5, paddingBottom: 10 }
});

export default styles;
