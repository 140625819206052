import React from 'react';
import {
  CUSTOM_SECURITY_CLEAR,
  CUSTOM_SECURITY_CREATE,
  CUSTOM_SECURITY_EDIT,
  CUSTOM_SECURITY_GET,
  CUSTOM_SECURITY_LIST,
  CUSTOM_SECURITY_RETURNS_BULK_NAME_SUGGESTIONS,
  CUSTOM_SECURITY_RETURNS_BULK_REMOVE,
  CUSTOM_SECURITY_RETURNS_BULK_SAVE,
  CUSTOM_SECURITY_RETURNS_CLEAR,
  CUSTOM_SECURITY_RETURNS_CREATE,
  CUSTOM_SECURITY_RETURNS_GET,
  CUSTOM_SECURITY_SAVE_CURRENT,
  CUSTOM_SECURITY_UNDERLYING_HOLDINGS_CLEAR,
  CUSTOM_SECURITY_UNDERLYING_HOLDINGS_GET,
  CUSTOM_SECURITY_SEARCH
} from 'constants/actions';
import { toast } from 'react-toastify';

const initialState = {
  currentSecurity: {},
  currentSecurityCreateReturns: {},
  currentSecurityReturns: {},
  currentSecurityUnderlyingHoldings: {},
  currentSecurityReturnsBulkCSV: [],
  currentSecurityBulkSuggestions: {},
  search: [],
  list: [],
  meta: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_SECURITY_LIST:
      return { ...state, list: action.data, meta: action.meta || {} };

    case CUSTOM_SECURITY_CREATE:
      return {
        ...state,
        list: [...state.list, action.data],
        meta: { ...state.meta, count: state.meta.count + 1 }
      };

    case CUSTOM_SECURITY_EDIT:
      return {
        ...state,
        currentSecurity: action.data.data,
        list: state.list.map(security => {
          if (security.id === action.data.id) return action.data;
          return security;
        })
      };

    case CUSTOM_SECURITY_GET:
      return {
        ...state,
        currentSecurity: action.data,
        list: state.list.map(security => {
          if (security.id === action.data.id) return action.data;
          return security;
        })
      };

    case CUSTOM_SECURITY_RETURNS_GET:
      return { ...state, currentSecurityReturns: action.data };

    case CUSTOM_SECURITY_SAVE_CURRENT:
      return { ...state, currentSecurity: action.data };

    case CUSTOM_SECURITY_CLEAR:
      return { ...state, currentSecurity: {} };

    case CUSTOM_SECURITY_RETURNS_CREATE:
      return { ...state, currentSecurityCreateReturns: action.data };

    case CUSTOM_SECURITY_RETURNS_CLEAR:
      return { ...state, currentSecurityCreateReturns: {} };

    case CUSTOM_SECURITY_UNDERLYING_HOLDINGS_GET:
      return { ...state, currentSecurityUnderlyingHoldings: action.data };

    case CUSTOM_SECURITY_UNDERLYING_HOLDINGS_CLEAR:
      return {
        ...state,
        currentSecurityUnderlyingHoldings: {}
      };
    case CUSTOM_SECURITY_RETURNS_BULK_SAVE: {
      const uniqueFiles = [];
      const fileNames = new Set(state.currentSecurityReturnsBulkCSV.map(file => file.name));
      const duplicateFiles = new Set();

      action.files.forEach(file => {
        if (!fileNames.has(file.name)) {
          fileNames.add(file.name);
          uniqueFiles.push(file);
        } else duplicateFiles.add(file.name);
      });

      const DuplicatesToast = ({ duplicates }) => (
        <div>
          The following files are duplicated and were not imported:{' '}
          <b style={{ fontWeight: 600 }}>{duplicates}</b>.
        </div>
      );

      if (duplicateFiles.size > 0) {
        const duplicatesList = Array.from(duplicateFiles).join(', ');
        toast.error(<DuplicatesToast duplicates={duplicatesList} />);
      }

      return {
        ...state,
        currentSecurityReturnsBulkCSV: [...state.currentSecurityReturnsBulkCSV, ...uniqueFiles]
      };
    }
    case CUSTOM_SECURITY_RETURNS_BULK_REMOVE:
      return {
        ...state,
        currentSecurityReturnsBulkCSV: [...action.files]
      };

    case CUSTOM_SECURITY_RETURNS_BULK_NAME_SUGGESTIONS:
      return {
        ...state,
        currentSecurityBulkSuggestions: action.data
      };

    case CUSTOM_SECURITY_SEARCH:
      return { ...state, search: action.data };

    default:
      return state;
  }
};
