import PropTypes from 'prop-types';

export const IPSPropTypes = {
  data: PropTypes.object,
  id: PropTypes.number,
  proposal: PropTypes.object,
  target_snapshot: PropTypes.object,
  recommended_snapshot: PropTypes.object
};

export const MetadataPropTypes = {
  allowChangeText: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowHide: PropTypes.bool,
  body: PropTypes.string.isRequired,
  currentAssets: PropTypes.string,
  custom: PropTypes.bool,
  hidden: PropTypes.bool,
  investmentHorizon: PropTypes.string,
  order: PropTypes.number,
  returnObjective: PropTypes.string,
  riskToleranceScore: PropTypes.string,
  title: PropTypes.string.isRequired
};
