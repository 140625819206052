// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  .burndown-chart .chart {
    margin: 0 auto;
    max-height: 612px;
    max-width: 700px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/charts/burndown-chart/styles.scss"],"names":[],"mappings":"AAAA;EAEI;IACE,cAAA;IACA,iBAAA;IACA,gBAAA;EAAJ;AACF","sourcesContent":["@media print {\n  .burndown-chart {\n    .chart {\n      margin: 0 auto;\n      max-height: 612px;\n      max-width: 700px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
