import cn from 'classnames';
import MultiCheckboxField from 'components/form/multi-checkbox-field';
import PropTypes from 'prop-types';
import React, { createRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { choicesToOptions } from 'utils/questionnaire';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';
import useBirthdayRetirement from '../hooks';
import './styles.scss';

const BirthdayRetirementQuestion = ({ question, field, ...props }) => {
  const { initialAge, onChangeAge } = useBirthdayRetirement({ field, question });

  const ageInput = createRef();

  const choices = choicesToOptions(question.data?.options);

  useEffect(() => {
    if (ageInput?.current) ageInput.current.focus();
  }, []);

  return (
    <Question
      {...props}
      question={question}
      nextDisabled={field.birthday.error || field.employment.error || field.retirement.error}
    >
      <div className="age" data-investor={props?.flavor === 'investor'}>
        <div>
          <span>
            <FormattedMessage id="rtq.question-1.i-am" />
          </span>
          <fieldset
            className={cn({ 'has-danger': field.birthday.touched && field.birthday.error })}
          >
            <NumericFormat
              allowNegative={false}
              decimalScale={0}
              defaultValue={initialAge}
              getInputRef={ageInput}
              max={150}
              min={1}
              onValueChange={onChangeAge}
            />
          </fieldset>
          <span>
            <FormattedMessage id="rtq.question-1.years-old" />
          </span>
        </div>
        {field.birthday.dirty && field.birthday.error && (
          <div className="error">
            <FormattedMessage id="rtq.question-1.age-error" />
          </div>
        )}
      </div>
      <div className="employment-status">
        <MultiCheckboxField
          field={field.employment}
          options={choices}
          multiple={question.data?.multiple_answers_allowed}
          flavor={question.data?.flavor}
        />
      </div>
    </Question>
  );
};

BirthdayRetirementQuestion.propTypes = {
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  flavor: PropTypes.string.isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default BirthdayRetirementQuestion;
