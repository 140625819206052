import Pagination from 'components/pagination';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AdvisorRow from './advisor-row';
import InvitationRow from './invitation-row';
import './styles.scss';

const CompanyUsersList = ({ advisors, fetchUsers, invitations, meta }) => {
  const { authProvider, routerActions, user } = useContext(AdvisorContext);

  const [currentPage, setCurrentPage] = useState(1);

  const params = meta?.params || {};

  if (!authProvider.hasManagerPermissions(user)) routerActions.push({ pathname: `/advisor/` });

  const refreshUsers = () => {
    fetchUsers({ ...meta.params, page: currentPage });
  };

  const goToPage = (page, event) => {
    event.preventDefault();
    if (page !== 0 && page <= meta.total_pages && page !== currentPage) {
      setCurrentPage(page);
      fetchUsers({ ...meta.params, page });
    }
  };

  useEffect(() => {
    const { search, page } = params;
    if (search && page !== currentPage) setCurrentPage(page);
  }, [JSON.stringify(params)]);

  return (
    <div id="company-users-list">
      <table className="table expandable-table">
        <thead>
          <tr>
            <td width="25%">Name</td>
            <td width="30%" className="text-left">
              Role
            </td>
            <td>Status</td>
            <td>2FA Status</td>
            <td aria-label="Column Spacer" width="15%" />
            <td aria-label="Column Spacer" width="60" />
          </tr>
        </thead>
        <tbody>
          {advisors.map(advisor => (
            <AdvisorRow
              advisor={advisor}
              key={`advisor-${advisor.id}`}
              refreshUsers={refreshUsers}
            />
          ))}
          {currentPage === 1 &&
            invitations.map(invitation => (
              <InvitationRow invitation={invitation} key={`invitation-${invitation.id}`} />
            ))}
        </tbody>
      </table>

      {!_.isEmpty(advisors) && (
        <Pagination
          currentPage={currentPage}
          totalPages={meta.total_pages}
          hasPrevPage={!!meta.previous}
          hasNextPage={!!meta.next}
          goToPage={goToPage}
        />
      )}
    </div>
  );
};

CompanyUsersList.propTypes = {
  advisors: PropTypes.array.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired
};

export default CompanyUsersList;
