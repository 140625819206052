import clone from 'clone';
import PropTypes from 'prop-types';
import Chart from '..';

class DriftLevelDistributionChart extends Chart {
  className = 'chart-distributions chart-xy';

  getOptions(newProps) {
    const themes = this.context.config.chart;
    const { data } = newProps || this.props;
    const options = {
      ...clone(themes.common.default, false),
      ...clone(this.props.options, false)
    };
    const total = data.reduce((acum, { value }) => acum + value, 0);
    options.allLabels[1].text = total;
    return options;
  }
}

DriftLevelDistributionChart.contextTypes = {
  config: PropTypes.object.isRequired
};

DriftLevelDistributionChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  options: PropTypes.object
};

DriftLevelDistributionChart.defaultProps = {
  data: []
};

export default DriftLevelDistributionChart;
