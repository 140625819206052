import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {
  ADVISOR_SIGNER_TYPE,
  ME_PREFIX,
  SIGNATURE_TYPE_ATTR,
  SIGNER_ID_ATTR,
  SIGNER_NAME_ATTR,
  SIGNER_TYPE_ATTR
} from './utils';

const DigitalSignatureRow = ({
  editing,
  index,
  investorOptions,
  managerOptions,
  onSignerChange,
  removeSigner,
  removeSignerDisabled,
  signatureTypeOptions,
  signer,
  signerTypeOptions
}) => {
  const signerOptions =
    signer.type === ADVISOR_SIGNER_TYPE ? managerOptions || [] : investorOptions;

  const onSignerChangeHandler =
    (index, field) =>
    ({ label, value }) => {
      onSignerChange(index, field, value);
      if (field === SIGNER_ID_ATTR)
        // keeps the name synced for use in the report
        onSignerChange(index, SIGNER_NAME_ATTR, label.replace(`${ME_PREFIX} `, ''));
    };

  return (
    <div className="digital-signature-row">
      <Select
        isDisabled={!editing}
        isSearchable={false}
        key={`type-${index}`}
        name={`signers[${index}].${SIGNER_TYPE_ATTR}`}
        onChange={onSignerChangeHandler(index, SIGNER_TYPE_ATTR)}
        options={signerTypeOptions}
        placeholder="Signer Type"
        styles={{ control: styles => ({ ...styles, borderRadius: 0 }) }}
        value={signerTypeOptions.find(signerTypeOption => signerTypeOption.value === signer.type)}
      />
      <Select
        isDisabled={!editing}
        key={`${signer.type}-value-${index}`}
        name={`signers[${index}].${SIGNER_ID_ATTR}`}
        onChange={onSignerChangeHandler(index, SIGNER_ID_ATTR)}
        options={signerOptions}
        placeholder="Signer"
        styles={{ control: styles => ({ ...styles, borderRadius: 0 }) }}
        value={signerOptions.find(signerOption => signerOption.value === signer.id)}
      />
      <Select
        isDisabled={!editing}
        isSearchable={false}
        key={`signature-type-${index}`}
        name={`signers[${index}].${SIGNATURE_TYPE_ATTR}`}
        onChange={onSignerChangeHandler(index, SIGNATURE_TYPE_ATTR)}
        options={signatureTypeOptions}
        placeholder="Type"
        styles={{ control: styles => ({ ...styles, borderRadius: 0 }) }}
        value={signatureTypeOptions.find(
          signatureTypeOption => signatureTypeOption.value === signer.signatureType
        )}
      />
      <div className="signature-section__box">
        <div>Date</div>
      </div>
      <button
        className="btn btn-link text-dark digital-signature-row__remove"
        disabled={!editing || removeSignerDisabled}
        onClick={() => removeSigner(index)}
        title="Remove Signer"
        type="button"
      >
        <i role="button" aria-label="Remove Signer" className="fs-icon-close-button" />
      </button>
    </div>
  );
};

DigitalSignatureRow.propTypes = {
  editing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  investorOptions: PropTypes.array.isRequired,
  managerOptions: PropTypes.array.isRequired,
  onSignerChange: PropTypes.func.isRequired,
  removeSigner: PropTypes.func.isRequired,
  removeSignerDisabled: PropTypes.bool.isRequired,
  signatureTypeOptions: PropTypes.array.isRequired,
  signer: PropTypes.object.isRequired,
  signerTypeOptions: PropTypes.array.isRequired
};

export default DigitalSignatureRow;
