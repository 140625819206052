import DistributionSummary from 'components/advisor/proposal/distribution-summary';
import {
  BENCHMARK_POSITIONS_ANALYSIS_CHART_ID,
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID,
  TARGET_POSITIONS_ANALYSIS_CHART_ID
} from 'components/advisor/proposal/distribution-summary/constants';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';

const ProposalHoldings = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  proposalCharts,
  proposalType,
  setMetadata
}) => {
  const { proposalProvider } = useContext(AdvisorContext);
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const onChartReady = useCallback(
    (id, data) => {
      proposalProvider.saveChartImage({ [id]: data });
    },
    [
      proposalCharts[TARGET_POSITIONS_ANALYSIS_CHART_ID],
      proposalCharts[RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID],
      proposalCharts[BENCHMARK_POSITIONS_ANALYSIS_CHART_ID]
    ]
  );

  const setCollapsedHoldings = data => {
    proposalProvider.setCollapsedHoldings(data);
  };

  return (
    <EditBoxText {...proposalMetadata}>
      <DistributionSummary
        benchmark={proposal.benchmark}
        benchmarkName={proposal.benchmark_label}
        isDraft={proposal?.target?.is_draft}
        onChartReady={onChartReady}
        proposalType={proposalType}
        recommended={proposal.recommended}
        recommendedName={proposal.recommended_label}
        setCollapsedHoldings={setCollapsedHoldings}
        start={proposal.start}
        startingValue={proposal.starting_value}
        target={proposal.target}
        targetName={proposal.target_label}
      />
    </EditBoxText>
  );
};

ProposalHoldings.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalCharts: PropTypes.object.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalHoldings;
