import { StyleSheet } from '@react-pdf/renderer';
import { LIGHT_GRAPHITE_COLOR } from 'reports/base/styles';

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    marginBottom: 30
  },
  title: {
    paddingBottom: 5,
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: LIGHT_GRAPHITE_COLOR,
    fontSize: 12,
    fontWeight: 'bold'
  },
  description: {
    marginTop: 5,
    fontSize: 8
  }
});

export default styles;
