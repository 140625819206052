/* eslint-disable import/prefer-default-export */
import { InvestorContext } from 'containers/investor';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const useIntegrationToken = ({ id }) => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState(0);

  const receivedRedirectUri = window.location.href;

  const { integrationProvider } = useContext(InvestorContext);
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem(`plaid:${id}:token`));
      return;
    }
    const getIntegrationToken = async () => {
      try {
        const res = await integrationProvider.getPlaidIntegrationToken();
        if (!res?.data) throw new Error('Bad response');
        setToken(res?.data?.link_token);
        setConnectedAccounts(res?.data?.total_accounts ?? 0);
        localStorage.setItem(`plaid:${id}:token`, res?.data?.link_token);
      } catch (err) {
        setToken(null);
      } finally {
        setLoading(false);
      }
    };
    getIntegrationToken();
  }, []);

  const handlePlaidIntegrationPublicToken = async publicToken => {
    try {
      const body = { public_token: publicToken };
      const res = await integrationProvider.setPlaidIntegrationPublicToken(body);
      setConnected(true);
      return res;
    } catch (err) {
      return toast.error(
        () => 'Unable to share your accounts. Please try again later or contact your administrator.'
      );
    }
  };

  return {
    connected,
    connectedAccounts,
    handlePlaidIntegrationPublicToken,
    isOAuthRedirect,
    loading,
    receivedRedirectUri,
    token
  };
};
