import cn from 'classnames';
import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React from 'react';
import { EXCEPTION_STATUS } from 'utils/drift';

const ExceptionDriftCell = ({ exception }) => {
  const tooltipId = `exception-drift-tooltip-${exception.id}`;
  const snoozed = [EXCEPTION_STATUS.SNOOZED, EXCEPTION_STATUS.CLOSED].includes(
    exception.status_name
  );

  return (
    <TooltipV2
      className="exception-drift-tooltip"
      id={tooltipId}
      label="This account's exception is snoozed."
      place="top"
      renderTooltip={snoozed}
    >
      <div className={cn({ snoozed })} data-tip="" data-for={tooltipId}>
        {exception.drift ? parseFloat(Number(exception.drift).toFixed(1)) : '-'}
        {snoozed && <i className="fs-icon-bell-slash" />}
      </div>
    </TooltipV2>
  );
};

ExceptionDriftCell.propTypes = {
  exception: PropTypes.object.isRequired
};

export default ExceptionDriftCell;
