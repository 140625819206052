import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const ScoreBubble = ({ score }) => {
  const scoreText = score.toFixed(1) === '10.0' ? '10' : score.toFixed(1);
  const roundedScore = Math.round(Number.parseFloat(scoreText));

  return (
    <View style={[styles.box, styles[`bgColor${roundedScore}`]]}>
      <Text style={styles.text}>{scoreText}</Text>
    </View>
  );
};

ScoreBubble.propTypes = {
  score: PropTypes.number.isRequired
};

export default ScoreBubble;
