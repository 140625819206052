import cn from 'classnames';
import AggregatedRiskScoreBubble from 'components/advisor/utils/score-bubble/aggregated-risk';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import RiskScoreBubble from 'components/advisor/utils/score-bubble/risk';
import ToleranceScoreBubble from 'components/advisor/utils/score-bubble/tolerance';
import ExpandArrow from 'components/svg-icons/expand-arrow';
import MinusIcon from 'components/svg-icons/minus-icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { triggerAccountPrismScore } from 'utils/prism';
import './styles.scss';

class HouseholdsInvestorListRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      investor: {},
      accountsShownFor: []
    };
  }

  toggleAccountsShown = ({ id }) => {
    const { accountsShownFor } = this.state;
    this.setState({
      accountsShownFor: accountsShownFor.includes(id)
        ? accountsShownFor.filter(hId => hId !== id)
        : accountsShownFor.concat(id)
    });
  };

  areAccountsShown = ({ id }) => {
    const { accountsShownFor } = this.state;
    return accountsShownFor.includes(id);
  };

  getPrismScore = accountId => {
    const { accountProvider } = this.context;
    triggerAccountPrismScore(accountId, accountProvider);
  };

  renderAccountRow = account => {
    const { drift_summary: driftSummary, is_healthy: isHealthy } = account;
    const { investor, prismScoresInProgress } = this.props;
    const { showTargetScoreWizard } = this.context;
    const { getPrismScore } = this;

    const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';

    return (
      <tr key={`a${account.id}`} className="children-row">
        <td />
        <td className="text-center">{account.display_name}</td>
        <td className="text-right">
          <FormattedNumber value={account.value} format="currency" />
        </td>
        <td />
        <td className="text-center prism-score">
          <RiskScoreBubble
            element={account}
            inProgress={prismScoresInProgress.includes(account.id)}
            onGenerate={() => getPrismScore(account.id)}
          />
        </td>
        <td className="text-center">
          {account.target_score_summary ? (
            <ToleranceScoreBubble
              element={account}
              onGenerate={() => showTargetScoreWizard({ investors: [investor] })}
            />
          ) : (
            '-'
          )}
        </td>
        <td className={cn('text-sm-center', 'drift', { red: !isHealthy })}>
          <span>{drift}</span>
        </td>
        <td />
      </tr>
    );
  };

  render() {
    const { showTargetScoreWizard } = this.context;
    const { investor, prismScoresInProgress, toggleUnlinkHouseholdModal } = this.props;
    const { accountsShownFor, investor: stateInvestor } = this.state;
    const { drift_summary: driftSummary, is_healthy: isHealthy } = investor;

    const drift = driftSummary?.overall ? driftSummary.overall.toFixed(1) : '-';
    const preferredInvestor = Object.keys(stateInvestor).length > 0 ? stateInvestor : investor;
    const investorAccounts = preferredInvestor.accounts.map(a => a.id);
    const inProgress = prismScoresInProgress.some(a => investorAccounts.includes(a));

    return (
      <tbody className="households-investor-list-row">
        <tr
          key={`investor${investor.id}`}
          className={investor.accounts.length > 0 ? 'parent-row' : 'child-row'}
        >
          <td>
            <span className="household-name-label">
              <Link
                to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${investor.id}`}
              >
                {investor.full_name}
              </Link>
            </span>
          </td>
          <td className="text-sm-center">{investor.accounts.length}</td>
          <td className="text-sm-right">
            <FormattedNumber value={Math.round(investor.portfolio_value)} format="currency" />
          </td>
          <td />
          <td className="text-sm-center prism-score">
            <AggregatedRiskScoreBubble element={investor} inProgress={inProgress} isListView />
          </td>
          <td className="text-sm-center">
            <AggregatedToleranceScoreBubble
              element={investor}
              onClick={() => showTargetScoreWizard({ investors: [investor] })}
            />
          </td>
          <td className={cn('text-sm-center', 'drift', { red: !isHealthy })}>
            <span>{drift}</span>
          </td>
          <td onClick={() => this.toggleAccountsShown(investor)}>
            {investor.accounts.length > 0 && (
              <ExpandArrow
                className={accountsShownFor.length > 0 ? 'expand-arrow-up' : 'expand-arrow-down'}
              />
            )}
          </td>
          <td
            className="action hidden-bg action-cell"
            id="delete_icon"
            onClick={e => e.stopPropagation()}
          >
            <a
              className="minus-icon"
              href="javascript:void(0);"
              onClick={() => toggleUnlinkHouseholdModal(investor)}
            >
              <MinusIcon className="minus-icon" title="Remove" />
            </a>
          </td>
        </tr>
        {this.areAccountsShown(preferredInvestor) &&
          investor.accounts.map(account => this.renderAccountRow(account))}
      </tbody>
    );
  }
}

HouseholdsInvestorListRow.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired
};

HouseholdsInvestorListRow.propTypes = {
  household: PropTypes.object.isRequired,
  investor: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  toggleUnlinkHouseholdModal: PropTypes.func.isRequired
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state)
}))(HouseholdsInvestorListRow);
