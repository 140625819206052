import classnames from 'classnames';
import { Modal, ModalBody } from 'components/modal';
import SimpleSpinner from 'components/utils/simple-spinner';
import PropTypes from 'prop-types';
import CardProvider from 'providers/cards';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { capFirstChar, sleep } from 'utils/utils';
import './styles.scss';

export const CardActions = ({ card, cardProvider, onlyOneCardSaved }) => {
  const [isShown, setIsShown] = useState(false);
  const [processingDefault, setProcessingDefault] = useState(false);
  const [processingRemove, setProcessingRemove] = useState(false);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const removeCard = cardId => async () => {
    setProcessingRemove(true);
    try {
      await cardProvider.delete(cardId);
      await sleep(2);
      await cardProvider.list();
      toast.success('Credit card removed');
    } catch {
      toast.error('There was an error removing your credit card');
    } finally {
      setProcessingRemove(false);
    }
  };

  const setAsDefaultCard = cardId => async () => {
    setProcessingDefault(true);
    try {
      await cardProvider.setAsDefault(cardId);
      await sleep(2);
      await cardProvider.list();
      toast.success('Credit card set as default');
    } catch {
      toast.error('There was an error setting your credit card as default');
    } finally {
      setProcessingDefault(false);
    }
  };

  return (
    <>
      <div className="billing-cards-container__card__set-as-default">
        <button
          type="button"
          className="btn btn-link"
          onClick={setAsDefaultCard(card.id)}
          disabled={processingDefault}
        >
          {processingDefault ? (
            <>
              Processing... <SimpleSpinner />
            </>
          ) : (
            'Set as default card'
          )}
        </button>
      </div>

      {!onlyOneCardSaved && (
        <div className="billing-cards-container__card__delete">
          <button
            type="button"
            className="btn btn-link text-danger"
            onClick={show}
            disabled={processingDefault}
          >
            Remove
          </button>
        </div>
      )}

      <Modal
        id="RemoveCardModal"
        className="modal-lg"
        show={isShown}
        onHidden={hide}
        backdrop="static"
      >
        <ModalBody>
          <h1>Remove credit card</h1>
          <p>
            Are you sure you want to remove the card {capFirstChar(card.brand)} ending in{' '}
            {card.last4}?
          </p>
          <div className="actions">
            <button type="button" className="btn btn-secondary" onClick={hide}>
              Cancel
            </button>
            <button
              type="button"
              className={classnames('btn', 'btn-danger', { 'btn-processing': processingRemove })}
              onClick={removeCard(card.id)}
              disabled={processingRemove}
            >
              {processingRemove ? (
                <>
                  Removing... <SimpleSpinner light />
                </>
              ) : (
                'Remove'
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

CardActions.defaultProps = {
  onlyOneCardSaved: false
};

CardActions.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired
  }).isRequired,
  cardProvider: PropTypes.object.isRequired,
  onlyOneCardSaved: PropTypes.bool
};

export default connect(
  null,
  dispatch => ({
    cardProvider: new CardProvider({ dispatch })
  })
)(CardActions);
