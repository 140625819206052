import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';

const ProposalPrism = ({ actionsDisabled, metadata, propertyKey, proposal, setMetadata }) => {
  const hasRecommended = !!proposal.recommended;
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  return (
    <EditBoxText {...proposalMetadata}>
      <DateValue proposal={proposal} />
      <div className={cn('fancy-grid scores', { 'no-recommended': !hasRecommended })}>
        <div className="box-header left gray">{proposal.target_label || 'Current Portfolio'}</div>

        {hasRecommended && (
          <div className="box-header right blue">{proposal.recommended_label}</div>
        )}

        <div className="prism-result-box left">
          <RiskOverall
            id="proposal-target-score-overall"
            score={proposal.target.prism_score_summary?.overall}
            target={proposal.target.target_score_summary?.overall}
            small
          />
        </div>

        {hasRecommended && (
          <div className="prism-result-box right">
            <RiskOverall
              id="proposal-recommended-score-overall"
              score={
                proposal.recommended.prism_score_weighted ||
                proposal.recommended.prism_score_summary?.overall
              }
              small
            />
          </div>
        )}
      </div>
    </EditBoxText>
  );
};

ProposalPrism.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalPrism;
