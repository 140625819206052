// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#typography-section .table {
  margin: 25px 0;
}
#typography-section .table .row {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}
#typography-section .table .row-28 {
  font-size: 28px;
  font-weight: 400;
}
#typography-section .table .row-24 {
  font-size: 24px;
  font-weight: 400;
}
#typography-section .table .row-18 {
  font-size: 18px;
  font-weight: 400;
}
#typography-section .table .row-16 {
  font-size: 16px;
  font-weight: 300;
}
#typography-section .table .row-14 {
  font-size: 14px;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/style-guide/typography-section/styles.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEI;EACE,aAAA;EACA,eAAA;EACA,cAAA;AAAN;AAGI;EACE,eAAA;EACA,gBAAA;AADN;AAII;EACE,eAAA;EACA,gBAAA;AAFN;AAKI;EACE,eAAA;EACA,gBAAA;AAHN;AAMI;EACE,eAAA;EACA,gBAAA;AAJN;AAOI;EACE,eAAA;EACA,gBAAA;AALN","sourcesContent":["#typography-section {\n  .table {\n    margin: 25px 0;\n\n    .row {\n      display: flex;\n      flex-wrap: wrap;\n      margin: 30px 0;\n    }\n\n    .row-28 {\n      font-size: 28px;\n      font-weight: 400;\n    }\n\n    .row-24 {\n      font-size: 24px;\n      font-weight: 400;\n    }\n\n    .row-18 {\n      font-size: 18px;\n      font-weight: 400;\n    }\n\n    .row-16 {\n      font-size: 16px;\n      font-weight: 300;\n    }\n\n    .row-14 {\n      font-size: 14px;\n      font-weight: 300;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
