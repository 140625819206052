// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#company-users-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 60px 15px 0;
}
#company-users-header h2 {
  font-size: 2rem;
  margin-bottom: 0;
}
#company-users-header .header-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
#company-users-header .header-options .form-group.search {
  margin-bottom: 0;
}
#company-users-header .header-options .form-group.search .react-autosuggest__input {
  height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/company-users/header/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;AADF;AAGE;EACE,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAIM;EACE,YAAA;AAFR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#company-users-header {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px 60px 15px 0;\n\n  h2 {\n    font-size: 2rem;\n    margin-bottom: 0;\n  }\n\n  .header-options {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 10px;\n\n    .form-group.search {\n      margin-bottom: 0;\n\n      .react-autosuggest__input {\n        height: 38px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
