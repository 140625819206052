import PropTypes from 'prop-types';
import cn from 'classnames';
import React from 'react';
import './styles.scss';

const ModelProviderItem = ({ id, name, website, logo, isActive, onClickHandler }) => (
  <div
    className={cn('model-provider-item', { 'model-provider-item--active': isActive })}
    onClick={() => onClickHandler(id)}
  >
    <div
      className="model-provider-item__logo"
      style={{
        backgroundImage: `url(${logo})`
      }}
    />
  </div>
);

ModelProviderItem.defaultProps = {
  id: '',
  name: '',
  website: '',
  logo: '',
  isActive: false,
  onClickHandler: () => {}
};

ModelProviderItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  logo: PropTypes.string,
  isActive: PropTypes.bool,
  onClickHandler: PropTypes.func
};

export default ModelProviderItem;
