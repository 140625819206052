import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const getFullName = advisor => `${advisor.user.first_name} ${advisor.user.last_name}`;

class UserTextarea extends Component {
  UNSAFE_componentWillMount() {
    const { companyProvider } = this.context;
    companyProvider.listAdvisors();
  }

  onCaretPositionChange = () => {
    const { onChange } = this.props;
    const { value } = this.rta.state;
    if (onChange)
      onChange({
        target: {
          value
        }
      });
  };

  getValue = () => {
    const { value } = this.rta.state;
    return value;
  };

  clear = () => {
    this.rta.setState({ value: '' });
  };

  render() {
    const { advisors, placeholder } = this.props;
    return (
      <ReactTextareaAutocomplete
        className="user-textarea"
        placeholder={placeholder}
        loadingComponent={() => <span>Loading</span>}
        ref={rta => {
          this.rta = rta;
        }}
        onCaretPositionChange={this.onCaretPositionChange}
        trigger={{
          '@': {
            dataProvider: token =>
              _.filter(advisors, a =>
                getFullName(a)
                  .toLowerCase()
                  .includes(token.toLowerCase())
              ),
            component: ({ entity }) => <div>{getFullName(entity)}</div>,
            output: item => `@${item.user.tagname}`
          }
        }}
      />
    );
  }
}

UserTextarea.propTypes = {
  advisors: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

UserTextarea.defaultProps = {
  advisors: [],
  onChange: null,
  placeholder: ''
};

UserTextarea.contextTypes = {
  companyProvider: PropTypes.object.isRequired
};

export default connect(
  state => ({
    advisors: state.company.colleagues
  }),
  null,
  null,
  { withRef: true }
)(UserTextarea);
