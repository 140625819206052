import { investorSelector } from 'components/advisor/investors/selectors';
import SimpleSpinner from 'components/utils/simple-spinner';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getInvestorReportCoverData } from 'reports/utils';
import './styles.scss';
import ToggableQuestionnaire from './toggable-questionnaire';

const FactFinderForms = ({ investor }) => {
  const { investorProvider, prospectProvider, questionProvider, showTargetScoreWizard } =
    useContext(AdvisorContext);

  const [groupedQuestionnaires, setGroupedQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportCoverData, setReportCoverData] = useState({});

  useEffect(() => {
    setLoading(true);
    questionProvider
      .listQuestionnaires(investor.id, investor.is_prospect)
      .then(({ data: questionnaires }) => {
        // transforms the list of questionnaires to group them by month and year
        const groupedQuestionnaires = _.groupBy(questionnaires, item =>
          moment(item.created).startOf('month')
        );
        const sortedGroups = Object.keys(groupedQuestionnaires).sort(
          (a, b) => moment(b).valueOf() - moment(a).valueOf()
        );
        const groupedQuestionnairesSorted = sortedGroups.reduce(
          (acc, group) => [...acc, [moment(group).format("MMM 'YY"), groupedQuestionnaires[group]]],
          []
        );

        // allows a single request to obtain the cover data of the reports as they are in the
        // context of the investor. This would avoid making one request per report in the timeline
        getInvestorReportCoverData(investor, investorProvider, prospectProvider)
          .then(data => {
            setReportCoverData(data);
          })
          .finally(() => {
            setGroupedQuestionnaires(groupedQuestionnairesSorted);
            setLoading(false);
          });
      });
  }, []);

  return (
    <div id="investor-questionnaires">
      <div className="investor-questionnaires__header">
        <h2>Timeline</h2>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            showTargetScoreWizard({ isScoredQuestionnaireTemplate: false });
          }}
        >
          Send Form
        </button>
      </div>

      {loading && (
        <div className="investor-questionnaires__message">
          <SimpleSpinner /> Loading ...
        </div>
      )}

      {!loading && _.isEmpty(groupedQuestionnaires) && (
        <div className="investor-questionnaires__message">No Forms</div>
      )}

      {!loading && !_.isEmpty(groupedQuestionnaires) && (
        <div className="investor-questionnaires__content">
          {groupedQuestionnaires.map(([date, questionnaires], gI) => (
            <div className="grouped-questionnaires" key={date}>
              <div className="grouped-questionnaire__date">{date}</div>
              <div className="grouped-questionnaire__content">
                {questionnaires.map((questionnaire, qI) => (
                  <ToggableQuestionnaire
                    key={questionnaire.id}
                    defaultHidden={gI !== 0 || qI !== 0}
                    investor={investor}
                    questionnaire={questionnaire}
                    reportCoverData={reportCoverData}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

FactFinderForms.defaultProps = {
  investor: null
};

FactFinderForms.propTypes = {
  investor: PropTypes.object
};

export default connect(state => ({
  investor: investorSelector(state)
}))(FactFinderForms);
