import ErrorMessage from 'components/error-message';
import { FormGroup, VerboseErrorInput } from 'components/form';
import { propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import MaxInvalidLoginAttemptsMessage from '../max-invalid-login-attempts-message';
import Separator from '../separator';
import '../styles.scss';

const REASON_DELIMITER = ': ';
const MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED = 'MaxInvalidLoginAttemptsExceeded';

const PassForm = ({
  backendErrors,
  fields,
  onChangeEmail,
  onSubmit,
  onUseMagicLink,
  skipError,
  submitting,
  username
}) => {
  const [errorReason, errorMessage] = backendErrors.general
    ? backendErrors.general.split(REASON_DELIMITER)
    : [];

  return (
    <div className="signup-form pass-form">
      <div className="header">
        <span role="img" aria-label="email">
          🔐
        </span>
        <h1 className="signup-title">Hi {username}!</h1>
      </div>

      <p className="mt-3">Login using your StratiFi password.</p>

      <form onSubmit={onSubmit} autoComplete="on">
        <FormGroup
          className="form-group"
          {...fields.password}
          error={fields.password.error || errorMessage}
        >
          <VerboseErrorInput
            {...fields.password}
            className="form-control sign-up-field"
            // label="Login using your StratiFi password."
            placeholder="Enter your password"
            type="password"
          />
        </FormGroup>

        <div className="text-sm-center mt-5 mb-5">
          <button
            type="submit"
            className="btn btn-primary btn-block center-block"
            disabled={submitting || !fields.trusted_device_id.value}
          >
            {submitting ? 'Authenticating...' : 'Sign in'}
          </button>
        </div>
      </form>

      <div className="actions">
        <button
          className="btn btn-link p-0 font-weight-normal"
          onClick={onChangeEmail}
          type="button"
        >
          Not you? Click here to sign in as a different user.
        </button>

        <Link to="/forgot-password">Forgot password?</Link>
      </div>

      <div className="mt-2">
        {errorMessage && errorReason === MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED && (
          <MaxInvalidLoginAttemptsMessage
            initialSeconds={Number(errorMessage)}
            resetFormErrors={skipError}
          />
        )}

        {errorMessage && errorReason !== MAX_INVALID_LOGIN_ATTEMPTS_EXCEEDED && (
          <ErrorMessage message={errorMessage} />
        )}

        <Separator />

        <div className="bottom-links mt-3">
          <button className="btn btn-link p-0" onClick={onUseMagicLink} type="button">
            Login with an one-time link sent to your email
          </button>
        </div>
      </div>
    </div>
  );
};

PassForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onSendMagicLink: PropTypes.func.isRequired,
  onUsePassword: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  ...propTypesCheck
};

export default PassForm;
