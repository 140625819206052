import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TooltipV2 from 'components/tooltip-v2';

const Typographic = ({ label, id }) => {
  const textRef = useRef(null);
  const [hasEllipsis, setHasEllipsis] = useState(false);

  const checkEllipsis = () => {
    if (textRef?.current) {
      const isOverflowing = textRef?.current?.scrollWidth > textRef?.current?.offsetWidth;
      setHasEllipsis(isOverflowing);

      if (isOverflowing && !hasEllipsis) setHasEllipsis(true);
      if (!isOverflowing && !hasEllipsis) setHasEllipsis(false);
    }
  };

  useEffect(() => {
    checkEllipsis();

    const resizeObserver = new ResizeObserver(checkEllipsis);
    resizeObserver.observe(textRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [label]);

  return (
    <TooltipV2
      place="top"
      id={`${id}-id`}
      renderTooltip={hasEllipsis}
      className="compare-portfolio-tooltip"
      offset={{ top: 20, left: 5 }}
      label={<p>{label}</p>}
    >
      <p
        ref={textRef}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: 0 }}
        data-for={`${id}-id`}
        data-tip="React-tooltip"
      >
        {label}
      </p>
    </TooltipV2>
  );
};

Typographic.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default Typographic;
