import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'components/modal';
import Choice from 'components/form/choice';

import './styles.scss';

class HeldAwayAccountsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heldAway: false
    };
    this.onHideModal = this.onHideModal.bind(this);
    this.toggleHeldAway = this.toggleHeldAway.bind(this);
    this.submitHeldAway = this.submitHeldAway.bind(this);
  }

  onHideModal() {
    const { onHide } = this.props;
    onHide();
  }

  toggleHeldAway() {
    const { heldAway } = this.state;
    this.setState({ heldAway: !heldAway });
  }

  submitHeldAway() {
    const { heldAway } = this.state;
    const { onSubmit } = this.props;
    onSubmit(heldAway);
  }

  render() {
    const { show } = this.props;
    const { heldAway } = this.state;
    return (
      <Modal
        id="held-away-modal"
        ref={c => (this.modal = c)}
        className="held-away client-select-account-md"
        onHidden={this.onHideModal}
        show={show}
      >
        <ModalHeader />
        <ModalBody>
          <div className="accounts-no-target-body">
            <h2 className="title"> Are you managing this account? </h2>
          </div>
          <div className="held-away-options">
            <Choice
              title="Yes"
              checked={!heldAway}
              disabled={false}
              toggle={() => this.toggleHeldAway()}
              className="select-account-no-target"
            />
            <Choice
              title="No, this is a held-away account"
              checked={heldAway}
              disabled={false}
              toggle={() => this.toggleHeldAway()}
              className="select-account-no-target"
            />
          </div>
          <div className="info">
            Held-away accounts are not aggregated to the client.
            <br />
            You can change this by clicking the icon
            <span>
              {' '}
              <img src="/img/icons/user_plus.svg" alt="include-user-icon" />
            </span>
          </div>
          <div className="next-button">
            <button className="btn btn-primary" type="button" onClick={this.submitHeldAway}>
              Next
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

HeldAwayAccountsModal.propTypes = {
  investor: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default HeldAwayAccountsModal;
