import Choice from 'components/form/choice';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const MergeAccounts = ({ onComplete }) => {
  const [mergeAccounts, setMergeAccounts] = useState(false);

  const onToggleMergeAccounts = () => {
    setMergeAccounts(!mergeAccounts);
  };

  const onSubmit = () => {
    // This is used only for Orion integrations. The idea is to overwrite
    // the merge_accounts attribute based on what the user wants to happen in the end.
    if (onComplete) onComplete({ merge_accounts: mergeAccounts });
  };

  return (
    <div id="merge-accounts">
      <h1>Combine sub-accounts</h1>
      <div className="merge-accounts-options">
        <div className="merge-accounts-options__description">
          <p>In case there are sub-accounts, do you want to combine them into one account?</p>
        </div>
        <div className="merge-accounts-options__choices">
          <div>
            <Choice checked={mergeAccounts} toggle={onToggleMergeAccounts} />
            <span className="merge-accounts-options__choices__label">
              Yes, I want to combine them into one account.
            </span>
          </div>
          <div>
            <Choice checked={!mergeAccounts} toggle={onToggleMergeAccounts} />
            <span className="merge-accounts-options__choices__label">
              No, I want them as separate accounts.
            </span>
          </div>
        </div>
      </div>
      <div className="buttons-wrapper center">
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Continue
        </button>
      </div>
    </div>
  );
};

MergeAccounts.propTypes = {
  onComplete: PropTypes.func
};

MergeAccounts.defaultProps = {
  onComplete: null
};

export default MergeAccounts;
