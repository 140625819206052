import { createSelector } from 'reselect';

const IN_PROGRESS_STATUS = 'in_progress';
const SUCCESS_STATUS = 'success';

const accountPrismIntentsSelector = state => state.prism.accountPrismIntents;
const modelPortfolioPrismIntentsSelector = state => state.prism.modelPortfolioPrismIntents;

export const accountsWithPrismIntentsInProgressSelector = createSelector(
  [accountPrismIntentsSelector],
  prismIntents =>
    prismIntents.filter(intent => intent.status === IN_PROGRESS_STATUS).map(intent => intent.target)
);

export const accountsWithScoredPrismIntentsSelector = createSelector(
  [accountPrismIntentsSelector],
  prismIntents =>
    prismIntents
      .filter(intent => intent.status === SUCCESS_STATUS && Number.isFinite(intent.score))
      .reduce((intents, intent) => ({ ...intents, [intent.target]: intent.score }), {})
);

export const modelPortfoliosWithPrismIntentsInProgressSelector = createSelector(
  [modelPortfolioPrismIntentsSelector],
  prismIntents =>
    prismIntents.filter(intent => intent.status === IN_PROGRESS_STATUS).map(intent => intent.target)
);

export const modelPortfoliosWithScoredPrismIntentsSelector = createSelector(
  [modelPortfolioPrismIntentsSelector],
  prismIntents =>
    prismIntents
      .filter(intent => intent.status === SUCCESS_STATUS && Number.isFinite(intent.score))
      .reduce((intents, intent) => ({ ...intents, [intent.target]: intent.score }), {})
);
