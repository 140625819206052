import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_CELL,
  BASE_TABLE_ROW,
  TABLE_CELL_HEADER_01,
  TABLE_CELL_HEADER_02,
  TABLE_CELL_HEADER_03
} from 'reports/base/styles';

export const styles = StyleSheet.create({
  table: { ...BASE_TABLE, marginTop: 10 },
  row: { ...BASE_TABLE_ROW, marginBottom: 2 },
  cell: { ...BASE_TABLE_CELL, marginRight: 2, paddingVertical: 8, paddingHorizontal: 10 },
  cellText: { textAlign: 'center' },
  targetHeaderCell: { ...TABLE_CELL_HEADER_01 },
  recommendedHeaderCell: { ...TABLE_CELL_HEADER_02 },
  benchmarkHeaderCell: { ...TABLE_CELL_HEADER_03 }
});

export default styles;
