import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_TABLE,
  BASE_TABLE_ROW,
  FOR_LANDSCAPE_ORIENTATION,
  LIGHT_GRAPHITE_COLOR,
  PRISM_COLOR_0,
  PRISM_COLOR_1,
  PRISM_COLOR_2,
  PRISM_COLOR_3,
  PRISM_COLOR_4,
  PRISM_COLOR_5,
  PRISM_COLOR_6,
  PRISM_COLOR_7,
  PRISM_COLOR_8,
  PRISM_COLOR_9,
  PRISM_OFF_COLOR
} from 'reports/base/styles';

const styles = StyleSheet.create({
  fColor0: { fill: PRISM_COLOR_0 },
  fColor1: { fill: PRISM_COLOR_0 },
  fColor2: { fill: PRISM_COLOR_1 },
  fColor3: { fill: PRISM_COLOR_2 },
  fColor4: { fill: PRISM_COLOR_3 },
  fColor5: { fill: PRISM_COLOR_4 },
  fColor6: { fill: PRISM_COLOR_5 },
  fColor7: { fill: PRISM_COLOR_6 },
  fColor8: { fill: PRISM_COLOR_7 },
  fColor9: { fill: PRISM_COLOR_8 },
  fColor10: { fill: PRISM_COLOR_9 },
  fColorOff: { fill: PRISM_OFF_COLOR },
  container: {
    alignItems: 'center',
    marginTop: 30,
    paddingBottom: 15,
    border: 1,
    borderColor: LIGHT_GRAPHITE_COLOR,
    borderStyle: 'solid',
    [FOR_LANDSCAPE_ORIENTATION]: {
      marginTop: 15,
      paddingBottom: 7
    }
  },
  title: {
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 15,
    backgroundColor: LIGHT_GRAPHITE_COLOR,
    [FOR_LANDSCAPE_ORIENTATION]: {
      marginBottom: 7
    }
  },
  riskRangeContainer: { ...BASE_TABLE, width: 440 },
  riskRange: { ...BASE_TABLE_ROW, position: 'relative', height: 40, paddingHorizontal: 20 },
  riskRangeLabelBox: { fill: PRISM_OFF_COLOR },
  riskRangeBox: { fill: LIGHT_GRAPHITE_COLOR },
  riskRangeBoxText: { fill: 'white', fontSize: 22 },
  riskRangeLineContainer: {
    ...BASE_TABLE_ROW,
    position: 'relative',
    justifyContent: 'center'
  },
  riskRangeLine: { marginVertical: 15 },
  score: { fill: 'white' },
  scoreText: { fontSize: 6 }
});

export default styles;
