import QuillField from 'components/utils/wysiwyg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './styles.scss';

class TextTemplate extends PureComponent {
  static propTypes = {
    handleSaveTemplate: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired,
    content: PropTypes.string
  };

  static defaultProps = {
    content: ''
  };

  constructor(props) {
    super(props);
    this.saveText = this.saveText.bind(this);
  }

  saveText() {
    const { handleSaveTemplate, target } = this.props;
    const value = this.wyswyg.editor.summernote('code');
    return handleSaveTemplate(target, value);
  }

  render() {
    const { content } = this.props;

    return (
      <div className="text-template">
        <div className="body">
          <QuillField
            field={{ value: content }}
            qfRef={e => {
              this.wyswyg = e;
            }}
          />
          <div className="actions">
            <button type="button" onClick={this.saveText} className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TextTemplate;
