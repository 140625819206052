// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.held-away.client-select-account-md .accounts-no-target-body {
  min-height: 0px;
}
.held-away.client-select-account-md .accounts-no-target-body h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.held-away.client-select-account-md .held-away-options {
  padding-left: 140px;
  margin: 10px;
}
.held-away.client-select-account-md .held-away-options .asset-classes-choice {
  margin-bottom: 10px;
}
.held-away.client-select-account-md .held-away-options .asset-classes-choice.c-checkbox {
  margin-right: 10px;
}
.held-away.client-select-account-md .info {
  font-size: 13px;
  margin-top: 35px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}
.held-away.client-select-account-md .info img {
  width: 30px;
}
.held-away.client-select-account-md .next-button {
  text-align: center;
  margin: 15px;
}
.held-away.client-select-account-md .next-button .btn {
  width: 150px;
}

#held-away-modal .modal-content {
  padding-left: 0;
  padding-right: 0;
}
#held-away-modal .accounts-no-target-body {
  text-align: center;
}
#held-away-modal .close {
  top: 1rem;
  right: 1rem;
  position: absolute;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/held-away-account-modal/styles.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;AADN;AAEM;EACE,eAAA;EACA,mBAAA;AAAR;AAII;EACE,mBAAA;EACA,YAAA;AAFN;AAGM;EACE,mBAAA;AADR;AAGQ;EACE,kBAAA;AADV;AAMI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EAIA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAPN;AAEM;EACE,WAAA;AAAR;AAOI;EACE,kBAAA;EACA,YAAA;AALN;AAMM;EACE,YAAA;AAJR;;AAWE;EACE,eAAA;EACA,gBAAA;AARJ;AAUE;EACE,kBAAA;AARJ;AAWE;EACE,SAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;AATJ","sourcesContent":[".held-away {\n  &.client-select-account-md {\n    .accounts-no-target-body {\n      min-height: 0px;\n      h2 {\n        font-size: 20px;\n        margin-bottom: 20px;\n      }\n    }\n\n    .held-away-options {\n      padding-left: 140px;\n      margin: 10px;\n      .asset-classes-choice {\n        margin-bottom: 10px;\n\n        &.c-checkbox {\n          margin-right: 10px;\n        }\n      }\n    }\n\n    .info {\n      font-size: 13px;\n      margin-top: 35px;\n      margin-bottom: 30px;\n      img {\n        width: 30px;\n      }\n      margin-left: 30px;\n      margin-right: 30px;\n      text-align: center;\n    }\n\n    .next-button {\n      text-align: center;\n      margin: 15px;\n      .btn {\n        width: 150px;\n      }\n    }\n  }\n}\n\n#held-away-modal {\n  .modal-content {\n    padding-left: 0;\n    padding-right: 0;\n  }\n  .accounts-no-target-body {\n    text-align: center;\n  }\n\n  .close {\n    top: 1rem;\n    right: 1rem;\n    position: absolute;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
