import { COMPANY_GET_USER_LIST } from 'constants/actions';

export const initialState = {
  compPerfFailFlags: {},
  fullRangeStrategy: {},
  view: {}
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case COMPANY_GET_USER_LIST:
      if (state && state.callDate > action.data.callDate) return state;
      return {
        ...state,
        company: { list: action.data.data, callDate: action.data.callDate }
      };

    default:
      return state;
  }
}
