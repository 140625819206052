import EditBoxText from 'components/advisor/utils/edit-box-text';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const SignaturePlaceholder = ({ saveChanges, target, ...rest }) => {
  const documentType = target === 'ips' ? 'Investment Policy Statement (IPS)' : 'Proposal';
  return (
    <EditBoxText
      {...rest}
      className="signature-placeholder"
      saveChanges={(label, value) =>
        saveChanges(label, { ...value, editable: true, signature: true })
      }
    >
      <div className="signature-placeholder__fields">
        <p>Signer name</p>
        <p>Signer email</p>
        <p>Signature</p>
        <p>Signature date</p>
      </div>
      <p>
        Please note: This is a placeholder. After generating the {documentType}, customize this
        section to include the appropriate signers.
      </p>
    </EditBoxText>
  );
};

SignaturePlaceholder.defaultProps = {};

SignaturePlaceholder.propTypes = {
  saveChanges: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired
};

export default SignaturePlaceholder;
