import cn from 'classnames';
import { FormGroup, VerboseErrorInput } from 'components/form';
import { Modal, ModalBody } from 'components/modal';
import TooltipV2 from 'components/tooltip-v2';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { reduxForm } from 'redux-form';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import ReportViewer from 'reports/viewer';
import { validation } from 'utils/form';
import './styles.scss';

const validate = values => {
  const errors = {};
  errors.name = errors.name || validation.required(values.name);
  return errors;
};

const ModalButton = ({ buttonClassName, buttonDisabled, buttonText, onClick }) => (
  <button
    aria-label="open-modal"
    className={cn('btn', buttonClassName)}
    disabled={buttonDisabled}
    onClick={onClick}
    type="button"
  >
    {buttonText}
  </button>
);

ModalButton.propTypes = {
  buttonClassName: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const ProposalFormRenameModal = ({
  buttonClassName,
  buttonDisabled,
  buttonText,
  defaultName,
  fields: { name },
  initializeForm,
  isIPS,
  isReadOnly,
  process,
  reportOrientation,
  reportType,
  reportUrl,
  tooltipId,
  tooltipText
}) => {
  const { ipsProvider, proposalProvider } = useContext(AdvisorContext);

  const provider = isIPS ? ipsProvider : proposalProvider;

  const [isShown, setIsShown] = useState(false);
  const [saving, setSaving] = useState(false);

  const nameRef = useRef(null);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  useEffect(() => {
    initializeForm({ name: defaultName });
    if (reportOrientation !== PORTRAIT_ORIENTATION)
      provider.changeCurrentReportOrientation(PORTRAIT_ORIENTATION);
  }, [isShown]);

  useEffect(() => {
    // ensures that the focus action is performed after the modal
    // has finished its opening animation
    if (isShown && nameRef.current)
      setTimeout(() => {
        nameRef.current.focus();
      }, 350);
  }, [isShown, nameRef.current]);

  const handleOnSave = async event => {
    event.stopPropagation();
    setSaving(true);
    await process(name.value);
    setSaving(false);
    hide();
  };

  const handleOnChangeOrientation = value => {
    provider.changeCurrentReportOrientation(value);
  };

  return (
    <>
      {tooltipId && tooltipText && (
        <TooltipV2 place="bottom" id={`${tooltipId}-tooltip`} label={tooltipText}>
          <div data-for={`${tooltipId}-tooltip`} data-tip="">
            <ModalButton
              buttonClassName={buttonClassName}
              buttonDisabled={buttonDisabled}
              buttonText={buttonText}
              onClick={show}
            />
          </div>
        </TooltipV2>
      )}

      {(!tooltipId || !tooltipText) && (
        <ModalButton
          buttonClassName={buttonClassName}
          buttonDisabled={buttonDisabled}
          buttonText={buttonText}
          onClick={show}
        />
      )}

      {ReactDOM.createPortal(
        <Modal className="modal-lg" id="proposal-form-rename-modal" onHidden={hide} show={isShown}>
          <ModalBody>
            <h4>Save the {reportType}</h4>
            <form autoComplete="off">
              <div className="row">
                <div className="col-sm-12">
                  <FormGroup {...name} className="form-group">
                    <span className="label">Name</span>
                    <VerboseErrorInput
                      {...name}
                      className="form-control"
                      placeholder={`Type a name for this ${reportType}`}
                      refCb={nameRef}
                      type="text"
                    />
                  </FormGroup>
                </div>
              </div>

              <ReportViewer
                adaptativeWidth={false}
                changeOrientation={isReadOnly ? null : handleOnChangeOrientation}
                height={480}
                orientation={reportOrientation}
                url={reportUrl}
              />

              <div className="modal-actions">
                <button type="button" className="btn btn-outline-primary" onClick={hide}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleOnSave}
                  disabled={saving}
                >
                  {saving ? 'Saving...' : 'Save'}
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

ProposalFormRenameModal.defaultProps = {
  defaultName: '',
  reportUrl: null,
  tooltipId: null,
  tooltipText: null
};

ProposalFormRenameModal.propTypes = {
  buttonClassName: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  defaultName: PropTypes.string,
  fields: PropTypes.object.isRequired,
  initializeForm: PropTypes.func.isRequired,
  isIPS: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  process: PropTypes.func.isRequired,
  reportOrientation: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  reportUrl: PropTypes.string,
  tooltipId: PropTypes.string,
  tooltipText: PropTypes.string
};

export default reduxForm({
  form: 'proposal-rename-modal-form',
  fields: ['name'],
  initialValues: {},
  enableReinitialize: true,
  validate
})(React.memo(ProposalFormRenameModal));
