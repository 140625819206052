import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import Legend from 'reports/base/sections/legend';
import useUpsideDownsideData from '../hooks';
import styles from './styles';

export const THEME = {
  default: 'default',
  proposal: 'proposal'
};

const createHeaderGridColumns = (columns, even = true) =>
  Array.from(Array(columns)).map((c, i, arr) => {
    const center = Math.round(columns / 2);

    let customStyles = even && i !== 0 && i !== arr.length - 1 ? { flexGrow: 2 } : {};
    if (even && i === 0) customStyles = { ...customStyles, textAlign: 'left' };
    if (even && i === arr.length - 1) customStyles = { ...customStyles, textAlign: 'right' };

    let value = `${(i + 1 - center) * 10}%`;
    if (i + 1 < center) value = `-${(center - (i + 1)) * 10}%`;
    if (i + 1 === center) value = 0;

    return (
      <Text key={`pdf-udc-header-${value}`} style={[styles.headerCell, customStyles]}>
        {value}
      </Text>
    );
  });

const createGridColumns = (number, cellStyles, firstCellStyles, lastCellStyles) =>
  Array.from(Array(number)).map((c, i, arr) => {
    let customStyles = {};
    customStyles = i === 0 ? [cellStyles, firstCellStyles] : cellStyles;
    customStyles = i === arr.length - 1 ? [cellStyles, lastCellStyles] : cellStyles;
    // eslint-disable-next-line react/no-array-index-key
    return <View key={i} style={customStyles} />;
  });

const UpsideDownsideChart = ({
  benchmark,
  benchmarkName,
  target,
  targetName,
  recommended,
  recommendedName,
  theme,
  withLabels,
  withLegend,
  withRecommended
}) => {
  const {
    gridColumnsNumber,
    spyPerformance,
    targetPerformance,
    recommendedPerformance,
    benchmarkPerformance
  } = useUpsideDownsideData({ benchmark, recommended, target });

  const recommendedBar = recommended ? recommendedPerformance : spyPerformance;
  const useProposalTheme = theme === THEME.proposal;

  const themeStyles = {
    targetBar: useProposalTheme ? styles.proposalTargetBar : {},
    recommendedBar: useProposalTheme ? styles.proposalRecommendedBar : {},
    benchmarkBar: useProposalTheme ? styles.proposalBenchmarkBar : {}
  };
  if (!recommended) themeStyles.recommendedBar.opacity = 0.5;

  return (
    <>
      {withLegend && (
        <Legend
          targetName={targetName || 'Current portfolio'}
          recommendedName={recommended ? recommendedName || 'Model' : 'S&P 500'}
          benchmarkName={benchmarkName || 'Benchmark'}
          withBenchmark={!!benchmarkPerformance}
          withRecommended={withRecommended}
        />
      )}
      <View style={styles.table}>
        <View style={[styles.row, styles.header]}>
          {withLabels && <View style={[styles.cell, styles.labels]} />}
          {gridColumnsNumber % 2 === 0
            ? createHeaderGridColumns(gridColumnsNumber + 1)
            : createHeaderGridColumns(gridColumnsNumber, false)}
        </View>
        <View style={[styles.row, styles.chart]}>
          {withLabels && (
            <View style={[styles.cell, styles.labels]}>
              <Text style={styles.label}>{targetName || 'Current portfolio'}</Text>
              {withRecommended && (
                <Text style={styles.label}>
                  {recommended ? recommendedName || 'Model' : 'S&P 500'}
                </Text>
              )}
              {benchmarkPerformance && (
                <Text style={styles.label}>{benchmarkName || 'Benchmark'}</Text>
              )}
            </View>
          )}
          <View style={[styles.cell, styles.grid]}>
            {createGridColumns(
              gridColumnsNumber,
              styles.downsideCell,
              null,
              styles.firstDownsideCell
            )}
            <View style={[styles.barsContainer, { alignItems: 'flex-end' }]}>
              <View
                style={[
                  styles.downsideBar,
                  themeStyles.targetBar,
                  { width: `${targetPerformance.down * 100}%` }
                ]}
              />
              {withRecommended && (
                <View
                  style={[
                    styles.downsideBar,
                    themeStyles.recommendedBar,
                    { width: `${recommendedBar.down * 100}%` }
                  ]}
                />
              )}
              {benchmarkPerformance && (
                <View
                  style={[
                    styles.downsideBar,
                    themeStyles.benchmarkBar,
                    { width: `${benchmarkPerformance.down * 100}%` }
                  ]}
                />
              )}
            </View>
          </View>
          <View style={[styles.cell, styles.grid]}>
            {createGridColumns(gridColumnsNumber, styles.upsideCell, styles.firstUpsideCell, null)}
            <View style={[styles.barsContainer, { alignItems: 'flex-start' }]}>
              <View
                style={[
                  styles.upsideBar,
                  themeStyles.targetBar,
                  { width: `${targetPerformance.up * 100}%` }
                ]}
              />
              {withRecommended && (
                <View
                  style={[
                    styles.upsideBar,
                    themeStyles.recommendedBar,
                    { width: `${recommendedBar.up * 100}%` }
                  ]}
                />
              )}
              {benchmarkPerformance && (
                <View
                  style={[
                    styles.upsideBar,
                    themeStyles.benchmarkBar,
                    { width: `${benchmarkPerformance.up * 100}%` }
                  ]}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

UpsideDownsideChart.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  target: PropTypes.object.isRequired,
  targetName: PropTypes.string,
  theme: PropTypes.string,
  withLabels: PropTypes.bool,
  withLegend: PropTypes.bool,
  withRecommended: PropTypes.bool
};

UpsideDownsideChart.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  recommended: null,
  recommendedName: '',
  targetName: '',
  theme: THEME.default,
  withLabels: false,
  withLegend: false,
  withRecommended: true
};

export default UpsideDownsideChart;
