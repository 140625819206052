// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled-link {
  cursor: not-allowed;
}
.disabled-link__content {
  opacity: 0.5;
  display: inline-block;
}
.disabled-link .lock {
  display: inline-block;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/utils/link/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,YAAA;EACA,qBAAA;AACJ;AAEE;EACE,qBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".disabled-link {\n  cursor: not-allowed;\n\n  &__content {\n    opacity: 0.5;\n    display: inline-block;\n  }\n\n  .lock {\n    display: inline-block;\n    margin-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
