import PropTypes from 'prop-types';
import React from 'react';
import WithdrawalRequirementContent from '..';
import Question from '../../base/full';
import { QuestionFieldPropTypes, QuestionPropTypes } from '../../props';

const WithdrawalRequirementQuestion = ({ answers, field, question, ...props }) => (
  <Question
    {...props}
    answers={answers}
    question={{ ...question }}
    nextDisabled={
      field.type.invalid || field.amount.invalid || field.start.invalid || field.duration.invalid
    }
  >
    <WithdrawalRequirementContent field={field} question={question} />
  </Question>
);

WithdrawalRequirementQuestion.propTypes = {
  answers: PropTypes.object.isRequired,
  field: PropTypes.shape(QuestionFieldPropTypes).isRequired,
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default WithdrawalRequirementQuestion;
