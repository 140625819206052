import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ModelCombineForm from './form';
import './styles.scss';

const CombinePortfoliosModal = ({ meta, onCreate, onHide, onLimitReached, portfolios, show }) => {
  const totalPortfolios = portfolios.length;
  if (!totalPortfolios) return null;
  const weight = parseFloat((100 / totalPortfolios).toFixed(2));
  const initialValues = {
    portfolios: portfolios.map(({ id }) => ({ portfolio: id, weight }))
  };

  return (
    <Modal id="model-combine-modal" className="modal-lg" show={show} onHide={onHide}>
      <ModalHeader />
      <ModalBody>
        <ModelCombineForm
          meta={meta}
          initialValues={initialValues}
          portfolios={portfolios}
          onHide={onHide}
          onCreate={onCreate}
          onLimitReached={onLimitReached}
        />
      </ModalBody>
    </Modal>
  );
};

CombinePortfoliosModal.propTypes = {
  meta: PropTypes.object.isRequired,
  modelsLimitReached: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onLimitReached: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  portfolios: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired
};

CombinePortfoliosModal.defaultProps = {
  modelsLimitReached: false
};

export default connect(
  state => ({
    meta: state.models.listMeta,
    models: state.models.selectedModels,
    selectedModelIds: state.models.selectedModelIds
  }),
  null,
  ({ models, selectedModelIds, ...stateProps }, dispatchProps, ownProps) => {
    const portfolios = models.filter(m => selectedModelIds.includes(`${m.id}`));
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      portfolios
    };
  }
)(CombinePortfoliosModal);
