import { View } from '@react-pdf/renderer';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const NumericEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion questions={questions} question={question} withDescription>
    <View style={styles.containerBlankBox} />
  </ReportEmptyQuestion>
);

NumericEmptyQuestion.propTypes = propTypes;

export default NumericEmptyQuestion;
