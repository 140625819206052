import { CustomerSupportEmailLink } from 'constants/contact';
import { AuthenticationContext } from 'containers/auth';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import './styles.scss';

const EmailVerificationSent = () => {
  const { authProvider } = useContext(AuthenticationContext);

  const resendVerificationEmail = useMemo(
    () => () => {
      authProvider
        .resendVerificationEmail()
        .then(({ data }) => {
          if (!_.isEmpty(data)) toast.success(() => <div>Verification email sent.</div>);
          else toast.error(() => <div>Sorry, something went wrong.</div>);
        })
        .catch(() => {
          toast.error(() => <div>Sorry, something went wrong.</div>);
        });
    },
    [authProvider]
  );

  return (
    <div id="EmailVerificationSent">
      <h1 className="text-sm-center signup-title mb-2">Thank you for choosing StratiFi</h1>
      <p className="mb-2">
        A verification email has been sent to your email account, Please check your inbox to verify.
      </p>
      {authProvider.signupEmail && (
        <div className="mb-2">
          <button type="button" className="btn btn-link" onClick={resendVerificationEmail}>
            Resend the verification email
          </button>
        </div>
      )}
      <div className="mb-1">
        <CustomerSupportEmailLink label="Contact us" />
      </div>
    </div>
  );
};

EmailVerificationSent.contextTypes = {
  authProvider: PropTypes.object.isRequired
};

export default EmailVerificationSent;
