import CreateNewClientForm from 'components/advisor/investors/create-form';
import PropTypes from 'prop-types';
import AccountProvider from 'providers/account';
import AdvisorProvider from 'providers/advisor';
import HouseholdProvider from 'providers/household';
import InvestorProvider from 'providers/investor';
import MarketProvider from 'providers/market';
import ModelProvider from 'providers/model';
import ProspectProvider from 'providers/prospects';
import ServerErrorsProvider from 'providers/server-errors-provider';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';

class CreateNewClient extends PureComponent {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  getChildContext() {
    const {
      accountProvider,
      householdProvider,
      investorProvider,
      marketProvider,
      modelProvider,
      prospectProvider
    } = this.props;

    return {
      accountProvider,
      householdProvider,
      investorProvider,
      marketProvider,
      modelProvider,
      prospectProvider
    };
  }

  componentDidMount() {
    const { advisorProvider } = this.props;
    advisorProvider.es.list({ get_all_advisors: true });
  }

  render() {
    const { advisors, errorsProvider, households, routerActions, user } = this.props;
    const type = window.location.pathname.includes('investors') ? 'investor' : 'prospect';

    return (
      <div>
        <div className="pb-3" style={{ paddingLeft: '205px' }}>
          <div id="create-new-client" className="container">
            <CreateNewClientForm
              advisors={advisors}
              households={households}
              errorsProvider={errorsProvider}
              routerActions={routerActions}
              type={type}
              user={user}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateNewClient.childContextTypes = {
  accountProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired
};

CreateNewClient.defaultProps = { advisors: [], households: [] };

CreateNewClient.propTypes = {
  advisors: PropTypes.array,
  advisorProvider: PropTypes.object.isRequired,
  errorsProvider: PropTypes.object.isRequired,
  households: PropTypes.array,
  routerActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  ...CreateNewClient.childContextTypes
};

export default connect(
  state => ({
    advisors: state.advisors.esList,
    households: state.households.list,
    user: state.auth.user
  }),
  dispatch => ({
    accountProvider: new AccountProvider({ dispatch }),
    advisorProvider: new AdvisorProvider({ dispatch }),
    errorsProvider: new ServerErrorsProvider({ dispatch }),
    householdProvider: new HouseholdProvider({ dispatch }),
    investorProvider: new InvestorProvider({ dispatch }),
    marketProvider: new MarketProvider({ dispatch }),
    modelProvider: new ModelProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch)
  })
)(CreateNewClient);
