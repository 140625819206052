// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-question.text > .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 65%;
}
.full-question.text > .content .form-group {
  flex-grow: 1;
  margin-bottom: 0;
}
.full-question.text > .content .form-group > fieldset {
  flex-basis: 500px;
}

@media (max-width: 600px) {
  .full-question.text > .content {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/risk-tolerance-questionnaire/question/text/full/styles.scss","webpack://./src/assets/scss/commons/_app-mixin.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;AAHJ;AAKI;EACE,YAAA;EACA,gBAAA;AAHN;AAKM;EACE,iBAAA;AAHR;;ACNE;EDiBE;IACE,WAAA;EAPJ;AACF","sourcesContent":["@import 'assets/scss/commons/app-mixin';\n@import 'assets/scss/commons/variables';\n\n.full-question.text {\n  & > .content {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    width: 65%;\n\n    .form-group {\n      flex-grow: 1;\n      margin-bottom: 0;\n\n      & > fieldset {\n        flex-basis: 500px;\n      }\n    }\n  }\n}\n\n@include phone {\n  .full-question.text {\n    & > .content {\n      width: 100%;\n    }\n  }\n}\n","$phone-width: 600px;\n$small-tablet-width: 800px;\n$tablet-width: 1100px;\n$small-desktop-width: 1000px;\n$desktop-width: 1101px;\n\n@mixin phone {\n  @media (max-width: #{$phone-width}) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: #{$small-tablet-width}) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: #{$tablet-width}) {\n    @content;\n  }\n}\n\n@mixin small-desktop {\n  @media (max-width: #{$small-desktop-width}) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: #{$desktop-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
