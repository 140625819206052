import PropTypes from 'prop-types';
import React from 'react';
import SingleSelectFilter from '../single-select';
import { filterDefaultProps, filterPropTypes } from '../../types';

const DEFAULT_VALUE = { label: 'View All', value: undefined };

const RISK_LEVEL_OPTIONS = [
  DEFAULT_VALUE,
  { label: 'High', value: 'high' },
  { label: 'Neutral', value: 'neutral' },
  { label: 'Low', value: 'low' },
  { label: 'No Score', value: 'no-score' }
];

const RiskLevelFilter = ({ attr, filters, setFilters, setRequiresPageIndexReset }) => {
  const onChangeHandler = ({ value }) => {
    setFilters(prevFilters => ({ ...prevFilters, [attr]: value }));
    setRequiresPageIndexReset(true);
  };

  return (
    <SingleSelectFilter
      defaultValue={
        RISK_LEVEL_OPTIONS.find(option => option.value === filters?.[attr]) || DEFAULT_VALUE
      }
      onChange={onChangeHandler}
      options={RISK_LEVEL_OPTIONS}
      placeholder="View All"
    />
  );
};

RiskLevelFilter.propTypes = { ...filterPropTypes, attr: PropTypes.string.isRequired };

RiskLevelFilter.defaultProps = { ...filterDefaultProps };

export default RiskLevelFilter;
