import { GRAPHITE_COLOR, CYAN_COLOR, LILAC_COLOR } from 'reports/base/styles';

export const COLORS = {
  target: GRAPHITE_COLOR,
  recommended: CYAN_COLOR,
  benchmark: LILAC_COLOR
};

export const PDF_IMAGE_STYLES = {
  width: '100%',
  maxHeight: '500px'
};
