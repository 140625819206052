/* eslint-disable react/no-array-index-key */
import { Text, View } from '@react-pdf/renderer';
import Row from 'app/reports/base/table';
import React from 'react';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const TolerableLossEmptyQuestion = ({ questions, question }) => (
  <ReportEmptyQuestion questions={questions} question={question} withDescription>
    <View style={{ marginTop: 15 }} wrap={false}>
      {question.data?.loss_tolerance_table &&
        question.data.loss_tolerance_table.map((sourceRow, rowIdx) => {
          const markStyle =
            rowIdx === 0 ? [styles.markCheck, { borderWidth: 0 }] : styles.markCheck;
          const mark = <View style={markStyle} />;
          const row = sourceRow.map((cell, cellIdx) => (
            <Text style={{ textAlign: 'center', fontSize: 8 }} key={`${rowIdx}-${cellIdx}`}>
              {cell}
            </Text>
          ));
          return (
            <Row rowStyle={styles.tabulatedSummary} elements={[mark, ...row]} withCustomElements />
          );
        })}
    </View>
  </ReportEmptyQuestion>
);

TolerableLossEmptyQuestion.propTypes = propTypes;

export default TolerableLossEmptyQuestion;
