import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import { ROLES } from 'providers/auth';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { toast } from 'react-toastify';
import './styles.scss';

const Impersonate = ({ users }) => {
  const { authProvider, user } = useContext(AdvisorContext);
  const [selectedOption, selectOption] = useState(null);
  const { loggedUser } = authProvider;

  if (
    !authProvider.isAuthenticated(loggedUser) ||
    !authProvider.isAuthenticated(user) ||
    !loggedUser.is_staff
  )
    return null;

  const options = users
    .filter(u => u.id !== loggedUser.id)
    .map(u => ({ label: u.name, value: u.id }));

  const impersonateUser = ({ value }) => {
    if (!value) return;

    authProvider.startImpersonate(value).catch(() => {
      toast.error(() => <div>Sorry, something went wrong.</div>);
      selectOption(null);
    });
  };

  const releaseImpersonation = () => {
    authProvider.stopImpersonate().catch(() => {
      toast.error(() => <div>Sorry, something went wrong.</div>);
    });
  };

  useEffect(() => {
    if (user.is_staff) authProvider.listUsers();
  }, [loggedUser.is_staff, user.is_staff]);

  return (
    <div id="Impersonate">
      {loggedUser.id !== user.id && (
        <div className="release">
          <span>
            Acting as {user.first_name} {user.last_name} | {ROLES.labels[user.advisor.role]}
          </span>
          <button type="button" onClick={releaseImpersonation}>
            Release
          </button>
        </div>
      )}

      {loggedUser.id === user.id && users && users.length ? (
        <Select
          blurInputOnSelect
          className="user-select"
          classNamePrefix="user-sel"
          defaultValue={options.find(a => a.value === user.id)}
          filterOption={createFilter({ ignoreAccents: false })}
          isSearchable
          onChange={impersonateUser}
          options={options}
          placeholder="Search a user"
          styles={{ placeholder: provided => ({ ...provided, opacity: '0.5' }) }}
          value={selectedOption}
        />
      ) : null}
    </div>
  );
};

Impersonate.propTypes = {
  users: PropTypes.array
};

Impersonate.defaultProps = {
  users: []
};

export default connect(state => ({
  users: state.auth.users
}))(Impersonate);
