import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage } from 'utils/utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';
import usePortfolioConcentration from '../hooks';

const PortfolioConcentrationReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const { questionText } = usePortfolioConcentration({ question });

  return (
    <ReadOnlyQuestion question={{ ...question, question: questionText }}>
      <p>{formatPercentage(question.answer.value)}</p>
    </ReadOnlyQuestion>
  );
};

PortfolioConcentrationReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default PortfolioConcentrationReadOnlyQuestion;
