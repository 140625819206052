import ErrorMessage from 'components/error-message';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const MaxInvalidLoginAttemptsMessage = ({ initialSeconds, resetFormErrors }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  const duration = moment.duration(seconds, 'seconds');
  const minutesText =
    duration.minutes() > 0
      ? `${duration.minutes()} minute${duration.minutes() > 1 ? 's, ' : ', '}`
      : '';
  const secondsText = `${duration.seconds()} seconds.`;

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    return () => interval && clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!seconds) resetFormErrors();
  }, [seconds]);

  return (
    <ErrorMessage
      message={`Your account has been locked due to multiple failed login attempts, please try again in ${minutesText}${secondsText}`}
    />
  );
};

MaxInvalidLoginAttemptsMessage.propTypes = {
  initialSeconds: PropTypes.number.isRequired,
  resetFormErrors: PropTypes.func.isRequired
};

export default MaxInvalidLoginAttemptsMessage;
