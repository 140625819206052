import { StyleSheet } from '@react-pdf/renderer';
import { BASE_TABLE_ROW, BASE_TABLE_CELL } from 'reports/base/styles';

const BULLET_COLOR = '#00abf4';
const BOX_COLOR = '#f6f7f7';

export const styles = StyleSheet.create({
  title: { fontSize: 14 },
  text: { marginBottom: 15 },
  listItem: { flexDirection: 'row', alignItems: 'center', marginBottom: 5 },
  bullet: {
    width: 12,
    height: 12,
    borderRadius: 12,
    marginRight: 5,
    backgroundColor: BULLET_COLOR
  },
  row: { ...BASE_TABLE_ROW },
  cell: { ...BASE_TABLE_CELL },
  chartContainer: { flexGrow: 0, flexShrink: 0, flexBasis: 200, justifyContent: 'flex-start' },
  chart: { objectFit: 'contain', maxHeight: 120, marginBottom: 15 },
  box: { backgroundColor: BOX_COLOR, padding: 10 }
});

export default styles;
