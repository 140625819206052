// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-locked {
  width: 100%;
  text-align: center;
}
.section-locked .section-locked__placeholder {
  display: inline-block;
}
.section-locked .section-locked__placeholder img {
  width: 980px;
}
.section-locked.masked .section-locked__placeholder {
  max-height: 350px;
  overflow: hidden;
}
.section-locked.masked .section-locked__placeholder img {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, transparent 30%, #f0f3f4 90%, #f0f3f4 100%);
          mask-image: linear-gradient(to top, transparent 0%, transparent 30%, #f0f3f4 90%, #f0f3f4 100%);
}
.section-locked > p {
  max-width: 600px;
  margin: 0 auto;
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
}
.section-locked .section-locked__actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/section-locked/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,kBAAA;AADF;AAGE;EACE,qBAAA;AADJ;AAGI;EACE,YAAA;AADN;AAMI;EACE,iBAAA;EACA,gBAAA;AAJN;AAMM;EACE,uGAAA;UAAA,+FAAA;AAJR;AAeE;EACE,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAbJ;AAgBE;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,gBAAA;AAdJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.section-locked {\n  width: 100%;\n  text-align: center;\n\n  .section-locked__placeholder {\n    display: inline-block;\n\n    img {\n      width: 980px;\n    }\n  }\n\n  &.masked {\n    .section-locked__placeholder {\n      max-height: 350px;\n      overflow: hidden;\n\n      img {\n        mask-image: linear-gradient(\n          to top,\n          transparent 0%,\n          transparent 30%,\n          $body-background 90%,\n          $body-background 100%\n        );\n      }\n    }\n  }\n\n  & > p {\n    max-width: 600px;\n    margin: 0 auto;\n    font-style: normal;\n    line-height: 1.5;\n    font-weight: 400;\n  }\n\n  .section-locked__actions {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
