import EditBoxText from 'components/advisor/utils/edit-box-text';
import MatchingModelsChart from 'components/charts/matching-models-chart';
import PropTypes from 'prop-types';
import React from 'react';

const IPSMatchingModels = ({ ips: { proposal }, ...rest }) => (
  <EditBoxText {...rest}>
    <MatchingModelsChart proposal={proposal} />
  </EditBoxText>
);

IPSMatchingModels.propTypes = {
  ips: PropTypes.shape({ proposal: PropTypes.object }).isRequired
};

export default IPSMatchingModels;
