import PropTypes from 'prop-types';
import React from 'react';
import AddeparIntegration from './addepar';
import AltruistIntegration from './altruist';
import BlackDiamondIntegration from './blackdiamond';
import ByAllAccountsIntegration from './byallaccounts';
import OrionIntegration from './orion';
import RaymondJamesIntegration from './raymond_james';
import RedtailIntegration from './redtail';
import SalesforceIntegration from './salesforce';
import SchwabIntegration from './schwab';
import SchwabOauthIntegration from './schwab-oauth';
import './styles.scss';
import WealthboxIntegration from './wealthbox';

const ProviderConnection = ({ integration, onComplete, provider, selectedSyncData }) => {
  const { provider: providerId, name } = provider;

  const withIntegrationWrapper = CustomIntegrationHandler => (
    <CustomIntegrationHandler
      onComplete={onComplete}
      provider={provider}
      integration={integration}
      selectedSyncData={selectedSyncData}
    />
  );

  return (
    <div id="provider-connection">
      {name === 'Raymond James' && withIntegrationWrapper(RaymondJamesIntegration)}
      {providerId === 'Addepar' && withIntegrationWrapper(AddeparIntegration)}
      {providerId === 'Altruist' && withIntegrationWrapper(AltruistIntegration)}
      {providerId === 'BlackDiamond' && withIntegrationWrapper(BlackDiamondIntegration)}
      {providerId === 'ByAllAccounts' && withIntegrationWrapper(ByAllAccountsIntegration)}
      {providerId === 'Charles Schwab OAuth' && withIntegrationWrapper(SchwabOauthIntegration)}
      {providerId === 'Charles Schwab' && withIntegrationWrapper(SchwabIntegration)}
      {providerId === 'Orion' && withIntegrationWrapper(OrionIntegration)}
      {providerId === 'Redtail' && withIntegrationWrapper(RedtailIntegration)}
      {providerId === 'Salesforce' && withIntegrationWrapper(SalesforceIntegration)}
      {providerId === 'Wealthbox' && withIntegrationWrapper(WealthboxIntegration)}
    </div>
  );
};

ProviderConnection.propTypes = {
  onComplete: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  integration: PropTypes.object,
  selectedSyncData: PropTypes.object
};

ProviderConnection.defaultProps = {
  integration: null,
  selectedSyncData: {}
};

export default ProviderConnection;
