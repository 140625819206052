// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  #Header {
    display: none;
  }
}
#Header .beta-notification {
  margin: auto;
}
#Header .beta-icon {
  margin-right: 10px;
  background: #467bb8;
  padding: 4px;
  border-radius: 3px;
  color: white;
}
#Header .trial-icon {
  margin-right: 10px;
  background: #54ce1e;
  padding: 4px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 1px;
  color: white;
}
#Header .default-logo {
  height: 2.1rem;
  width: 6rem;
}
#Header .custom-logo {
  max-height: 56px;
  max-width: 170px;
  margin: auto 0;
}
#Header .company-name {
  line-height: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #Header .beta-notification {
    margin-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/layout/header/styles.scss"],"names":[],"mappings":"AAEA;EACE;IACE,aAAA;EADF;AACF;AAKE;EACE,YAAA;AAHJ;AAME;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AAJJ;AAOE;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AALJ;AAQE;EACE,cAAA;EACA,WAAA;AANJ;AASE;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;AAPJ;AAUE;EACE,cAAA;AARJ;;AAYA;EAEI;IACE,gBAAA;EAVJ;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n@media print {\n  #Header {\n    display: none;\n  }\n}\n\n#Header {\n  .beta-notification {\n    margin: auto;\n  }\n\n  .beta-icon {\n    margin-right: 10px;\n    background: #467bb8;\n    padding: 4px;\n    border-radius: 3px;\n    color: white;\n  }\n\n  .trial-icon {\n    margin-right: 10px;\n    background: #54ce1e;\n    padding: 4px;\n    padding-right: 16px;\n    padding-left: 16px;\n    border-radius: 1px;\n    color: white;\n  }\n\n  .default-logo {\n    height: 2.1rem;\n    width: 6rem;\n  }\n\n  .custom-logo {\n    max-height: 56px;\n    max-width: 170px;\n    margin: auto 0;\n  }\n\n  .company-name {\n    line-height: 1;\n  }\n}\n\n@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {\n  #Header {\n    .beta-notification {\n      margin-top: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
