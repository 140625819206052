import _ from 'lodash';
import { formatMoney } from 'utils/utils';

export const RECOMMENDED_TYPE = 'recommended';
export const TARGET_TYPE = 'target';

export const getEntitiesFromProposal = (proposal, type) => {
  if (!proposal[type]) return [];

  if (proposal[type].group_details) return proposal[type].group_details;

  const entity = {
    display_name: proposal[type].display_name,
    id: proposal[type].id,
    prism_overall: proposal[type].prism_score_summary.overall,
    weight: 100
  };
  if (type === TARGET_TYPE) entity.value = proposal[type].value;
  if (type === RECOMMENDED_TYPE && Number.isFinite(proposal[type]?.amount))
    entity.amount = proposal[type].amount;

  return [entity];
};

export const getAmountOrWeight = (element, modelsTotalValue, withPercentages) => {
  if (Number.isFinite(element.value)) return formatMoney(element.value);
  return withPercentages
    ? `${element.weight}%`
    : formatMoney((element.weight / 100) * modelsTotalValue);
};

export const matchingModelsChartData = (accounts, models) => {
  const hasModels = !_.isEmpty(models);

  const maxNumberOfElements = hasModels
    ? Math.max(accounts.length, models.length)
    : accounts.length;

  const accountsTotalAmount = accounts.reduce((acc, account) => acc + account.value, 0);
  const accountsOverallScore = accounts.reduce(
    (acc, account) => acc + account.prism_overall * (account.value / accountsTotalAmount),
    0
  );
  const modelsOverallScore = hasModels
    ? models.reduce((acc, model) => acc + model.prism_overall * (model.weight / 100), 0)
    : 0;

  return { maxNumberOfElements, accountsTotalAmount, accountsOverallScore, modelsOverallScore };
};

export const byAccountAmount = (a, b) => a.value - b.value;

export const byModelWeight = (a, b) => a.weight - b.weight;
