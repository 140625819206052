import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import { ucid, learnMoreHref, learnMoreCta, earlyAccess } from './constants';

const ViralLoops = ({ isMarketing, user }) => {
  if (!isMarketing) return null;

  const [viralLoopsInfo, setViralLoopsInfo] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const getViralLoopsInfo = async () => {
    const response = await window.ViralLoops.getCampaign(ucid);
    setViralLoopsInfo(response.info);
    setCampaign(response);
  };

  const participateInViralLoops = async () => {
    await campaign.identify(user).catch(error => {
      console.error('[Viral Loops] Participation error', error);
    });
    campaign.openModal();
    setIsEnrolled(true);
  };

  useEffect(() => {
    if (!viralLoopsInfo) getViralLoopsInfo();
  }, []);

  return (
    <div className="viral-loops">
      <div className="viral-loops__container">
        <div className="viral-loops__element">
          <div className="viral-loops__title">
            <h3>{viralLoopsInfo?.popup?.form?.participation?.header?.noReferrer?.text}</h3>
          </div>
          <div className="viral-loops__actions">
            <a
              type="button"
              className="btn btn-primary viral-loops__button"
              href={learnMoreHref}
              target="_blank"
              rel="noreferrer"
            >
              {learnMoreCta}
            </a>
            <button
              type="button"
              className="btn btn-light viral-loops__button"
              onClick={participateInViralLoops}
            >
              {isEnrolled ? viralLoopsInfo?.textCodes?.changed_trigger : earlyAccess}
            </button>
          </div>
        </div>
        <div className="viral-loops__elements">
          <div className="viral-loops__img-container-2">
            <img src="/img/prism-questions-guest/viral-loops-2.png" alt="Viral Loops #2" />
          </div>
          <div className="viral-loops__img-container-1">
            <img src="/img/prism-questions-guest/viral-loops-1.png" alt="Viral Loops #1" />
          </div>
        </div>
      </div>
      <form-widget mode="popup" component="customButton" ucid={ucid} />
    </div>
  );
};

ViralLoops.propTypes = {
  isMarketing: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

export default ViralLoops;
