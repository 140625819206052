import PlusIcon from 'assets/img/icons/plus-circle.svg';
import cn from 'classnames';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import EmbeddedDocument from 'components/advisor/utils/embedded-document';
import SignaturePlaceholder from 'components/advisor/utils/signature-placeholder';
import SignatureIcon from 'components/svg-icons/signature-icon';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import DEFAULT_TEMPLATES from 'containers/advisor/templates/defaults';
import humps from 'humps';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { byTemplateSectionOrder } from 'utils/utils';
import {
  ADVISOR_NAME,
  CLIENT_NAME,
  PROPOSED_MANAGEMENT_FEE,
  getSectionEditorOptions
} from './editor-options';
import MetaTemplateReportViewer from './report/viewer';
import TemplateSection from './section';
import './styles.scss';

const TEMPLATES = {
  proposal: 'proposal',
  ips: 'ips'
};

const CUSTOM_SECTION_TYPE = 'custom';
const EMBEDDED_DOCUMENT_SECTION_TYPE = 'embedded';
const SIGNATURE_SECTION_TYPE = 'signature';

const MetaTemplate = ({
  actionsDisabled,
  allowPrint,
  content,
  handleSaveTemplate,
  target,
  title
}) => {
  const [selectedSectionKey, setSelectedSectionKey] = useState({});
  const [newSectionPermisssions, setNewSectionPermisssions] = useState({
    allowEdit: true,
    allowHide: true,
    allowChangeText: true
  });

  const isProposalOrIPSTemplate = target === TEMPLATES.proposal || target === TEMPLATES.ips;
  const filteredContent = Object.entries(content)
    .filter(([_, section]) => !('editable' in section) || section.editable)
    .sort(byTemplateSectionOrder);
  const sectionEditorOptions = getSectionEditorOptions(target);
  const templateDefaultSections =
    target === TEMPLATES.proposal
      ? Object.keys(DEFAULT_TEMPLATES.proposal)
      : Object.keys(DEFAULT_TEMPLATES.ips);

  const saveSection = (label, value) =>
    handleSaveTemplate(target, {
      ...content,
      [label]: {
        ...value,
        title: value.title || DEFAULT_TEMPLATES[target][label].title,
        body: value.body
      }
    });

  const deleteSection = async sectionKey => {
    // Delete the desired section, sort the remaining sections using the
    // current order attribute and then apply a reduce function to define the
    // new order of the sections taking the index of the loop
    delete content[sectionKey];
    const updatedTemplate = Object.entries(content)
      .sort(byTemplateSectionOrder)
      .reduce((acc, [key, content], i) => ({ ...acc, [key]: { ...content, order: i + 1 } }), {});

    await handleSaveTemplate(target, updatedTemplate);
  };

  const clear = () => {
    setSelectedSectionKey({});
  };

  const saveCustomSection = (label, value, previousOrder) => {
    let processedLabel = humps.camelize(label);
    if (Object.prototype.hasOwnProperty.call(content, processedLabel))
      processedLabel = `${processedLabel}${Object.keys(content).length + 1}`;
    if (target === TEMPLATES.ips) value.saveChanges = true;

    const updatedContent = {
      ...content,
      [processedLabel]: {
        ...value,
        body: value.body,
        custom: true,
        customActions: {
          breakSection: {
            label: 'Place Section on a New Page',
            allowed: true,
            value: value.customActions?.breakSection?.value ?? false
          }
        },
        hidden: false,
        order: previousOrder + 1,
        title: label
      }
    };

    Object.entries(updatedContent).forEach(element => {
      if (element[1].title !== label && element[1].order >= previousOrder + 1)
        updatedContent[element[0]].order = element[1].order + 1;
    });

    clear();
    return handleSaveTemplate(target, updatedContent, true);
  };

  const updateNewSectionPermissions = values => {
    const { allowHide, allowEdit, allowChangeText } = values;
    setNewSectionPermisssions({ allowHide, allowEdit, allowChangeText });
  };

  return (
    <div className="meta-template advisor-meta-templates-container">
      {allowPrint && (
        <div className="meta-template__report">
          <MetaTemplateReportViewer content={filteredContent} title={title} />
        </div>
      )}

      {isProposalOrIPSTemplate && (
        <ul className="templates-meta-helper-container">
          <li>
            Use the special buttons in the editor toolbar to insert tags. These tags reference
            dynamic information like client and advisor names.
            <br />
            <span>
              Example: This report was generated for {CLIENT_NAME} by {ADVISOR_NAME}.
            </span>
          </li>
          {target === TEMPLATES.proposal && (
            <li>
              Utilize the &apos;management fees&apos; tags to reference the fees established during
              the proposal generation process.
              <br />
              <span>Example: … with the managing fee of {PROPOSED_MANAGEMENT_FEE}.</span>
            </li>
          )}
        </ul>
      )}

      <div className="body">
        {filteredContent.map(([key, section]) => (
          <Fragment key={key}>
            <>
              {section.footer && <div className="section-divider" />}
              <TemplateSection
                actionsDisabled={actionsDisabled}
                deleteSection={deleteSection}
                disableTitleChange={section.footer}
                isCustomSection={
                  isProposalOrIPSTemplate ? !templateDefaultSections.includes(key) : undefined
                }
                key={`section-${key}`}
                label={key}
                propertyKey={key}
                saveSection={saveSection}
                section={section}
                target={target}
              />

              {section.cover && <div className="section-divider" />}

              {selectedSectionKey.key === key &&
                selectedSectionKey.type === CUSTOM_SECTION_TYPE && (
                  <EditBoxText
                    admin
                    editHeader
                    editing
                    key={`new-section-${key}`}
                    options={sectionEditorOptions}
                    propertyKey={`${key}Custom`}
                    saveChanges={(label, value) => saveCustomSection(label, value, section.order)}
                    toggleEditing={clear}
                    updateNewSectionPermissions={updateNewSectionPermissions}
                    {...newSectionPermisssions}
                  />
                )}

              {selectedSectionKey.key === key &&
                selectedSectionKey.type === EMBEDDED_DOCUMENT_SECTION_TYPE && (
                  <EmbeddedDocument
                    editing
                    key={`new-section-${key}`}
                    propertyKey={`${key}EmbeddedDocument`}
                    saveChanges={(label, value) => saveCustomSection(label, value, section.order)}
                    toggleEditing={clear}
                  />
                )}

              {selectedSectionKey.key === key &&
                selectedSectionKey.type === SIGNATURE_SECTION_TYPE && (
                  <SignaturePlaceholder
                    admin
                    editHeader
                    editing
                    key={`new-section-${key}`}
                    options={sectionEditorOptions}
                    propertyKey={`${key}Signature`}
                    saveChanges={(label, value) => saveCustomSection(label, value, section.order)}
                    toggleEditing={clear}
                    updateNewSectionPermissions={updateNewSectionPermissions}
                    {...newSectionPermisssions}
                  />
                )}

              {!section.cover &&
                !section.footer &&
                selectedSectionKey.key !== key &&
                isProposalOrIPSTemplate && (
                  <div
                    className="advisor-meta-templates-container__add-section"
                    key={`new-section-${key}`}
                    data-disabled={actionsDisabled}
                  >
                    <AdministrativePermissionsTooltip
                      renderTooltip={actionsDisabled}
                      tooltipId={`add-section-${key}`}
                      wrapperClassName="add-section__content"
                    >
                      <img
                        alt="Custom section"
                        className={cn('plus-icon', { opaque: actionsDisabled })}
                        height={20}
                        src={PlusIcon}
                        width={20}
                      />
                      <button
                        type="button"
                        onClick={() => setSelectedSectionKey({ key, type: CUSTOM_SECTION_TYPE })}
                        disabled={actionsDisabled}
                      >
                        <span>Add a custom section</span>
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          setSelectedSectionKey({ key, type: EMBEDDED_DOCUMENT_SECTION_TYPE })
                        }
                        disabled={actionsDisabled}
                      >
                        <span>Embed a PDF</span>
                      </button>
                      <button
                        type="button"
                        onClick={() => setSelectedSectionKey({ key, type: SIGNATURE_SECTION_TYPE })}
                        disabled={actionsDisabled}
                      >
                        <span className="add-signature-box">
                          <SignatureIcon /> Add signature box
                        </span>
                      </button>
                    </AdministrativePermissionsTooltip>
                  </div>
                )}
            </>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

MetaTemplate.defaultProps = {
  actionsDisabled: false,
  allowPrint: false,
  title: ''
};

MetaTemplate.propTypes = {
  actionsDisabled: PropTypes.bool,
  allowPrint: PropTypes.bool,
  content: PropTypes.object.isRequired,
  handleSaveTemplate: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default MetaTemplate;
