import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const ChoicesReadOnlyQuestion = ({ question }) => {
  if (_.isNil(question?.answer)) return <ReadOnlyQuestionSkeleton question={question} />;

  const selection = question.data?.options
    .filter(choice => question.answer.value.includes(choice.value))
    .map(c => c.display_text ?? c.text ?? c.value)
    .join(', ');

  return (
    <ReadOnlyQuestion question={question}>
      <p>{selection}</p>
    </ReadOnlyQuestion>
  );
};

ChoicesReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ChoicesReadOnlyQuestion;
