import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { stripTags } from 'utils/questionnaire';
import ReportEmptyQuestion from '../../../base/pdf/empty';
import styles from '../../../base/pdf/empty/styles';
import propTypes from '../../../props';

const ChoicesEmptyQuestion = ({ questions, question }) => {
  const choicesStyle = question?.description ? { marginTop: 0 } : { marginTop: 15 };

  const questionMark = question?.data?.options ? (
    question?.data?.options.map(option => (
      <View style={styles.mark}>
        <View style={styles.markCheck} />
        <Text style={styles.markText}>
          {stripTags(option.display_text ?? option.text ?? option.value)}
        </Text>
      </View>
    ))
  ) : (
    <Text />
  );

  return (
    <ReportEmptyQuestion questions={questions} question={question} withDescription>
      <View style={[styles.choices, choicesStyle]}>{questionMark}</View>
    </ReportEmptyQuestion>
  );
};

ChoicesEmptyQuestion.propTypes = propTypes;

export default ChoicesEmptyQuestion;
