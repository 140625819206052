// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#UpdatePricePreviewModal .modal-content {
  padding: 2.5rem;
}
#UpdatePricePreviewModal .modal-dialog {
  width: 800px;
}

#UpdatePricePreview h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.166667;
  font-style: normal;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
}
#UpdatePricePreview .summary,
#UpdatePricePreview .items {
  padding-bottom: 20px;
  display: grid;
  grid-row-gap: 5px;
  row-gap: 5px;
}
#UpdatePricePreview .summary > div:nth-child(2n+1),
#UpdatePricePreview .items > div:nth-child(2n+1) {
  font-weight: bold;
}
#UpdatePricePreview .summary > div:nth-child(2n),
#UpdatePricePreview .items > div:nth-child(2n) {
  text-align: right;
}
#UpdatePricePreview .summary {
  grid-template-columns: 1fr 1fr;
}
#UpdatePricePreview .items {
  grid-template-columns: 2fr 1fr;
}
#UpdatePricePreview .total {
  font-weight: bold;
  text-align: right;
}
#UpdatePricePreview .actions {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#UpdatePricePreview button,
#UpdatePricePreview a {
  flex: 0 1 45%;
  max-width: 180px;
}

.modal-backdrop.fade.show + .modal-backdrop.fade.show {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/advisor/billing/update-price-preview/styles.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;;AAIE;EACE,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,0BAAA;AADJ;AAIE;;EAEE,oBAAA;EACA,aAAA;EACA,iBAAA;EAAA,YAAA;AAFJ;AAII;;EACE,iBAAA;AADN;AAGI;;EACE,iBAAA;AAAN;AAIE;EACE,8BAAA;AAFJ;AAKE;EACE,8BAAA;AAHJ;AAME;EACE,iBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;AALJ;AAQE;;EAEE,aAAA;EACA,gBAAA;AANJ;;AAUA;EACE,aAAA;AAPF","sourcesContent":["#UpdatePricePreviewModal {\n  .modal-content {\n    padding: 2.5rem;\n  }\n  .modal-dialog {\n    width: 800px;\n  }\n}\n#UpdatePricePreview {\n  h3 {\n    font-size: 1.5rem;\n    font-weight: 500;\n    line-height: 1.166667;\n    font-style: normal;\n    letter-spacing: 0em;\n    text-align: center;\n    margin-bottom: 20px;\n    text-transform: capitalize;\n  }\n\n  .summary,\n  .items {\n    padding-bottom: 20px;\n    display: grid;\n    row-gap: 5px;\n\n    & > div:nth-child(2n + 1) {\n      font-weight: bold;\n    }\n    & > div:nth-child(2n) {\n      text-align: right;\n    }\n  }\n\n  .summary {\n    grid-template-columns: 1fr 1fr;\n  }\n\n  .items {\n    grid-template-columns: 2fr 1fr;\n  }\n\n  .total {\n    font-weight: bold;\n    text-align: right;\n  }\n\n  .actions {\n    margin-top: 30px;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n  }\n\n  button,\n  a {\n    flex: 0 1 45%;\n    max-width: 180px;\n  }\n}\n\n.modal-backdrop.fade.show + .modal-backdrop.fade.show {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
