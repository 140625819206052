import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const AccessEndorsementsDeleteModal = ({ data, onConfirm, onHide, show }) => {
  const { followee } = data;

  const onDeleteHandler = async () => {
    await onConfirm(
      followee?.id,
      [],
      `You have removed all the access endorsements for ${followee.name}'s information.`
    );
    onHide();
  };

  return (
    <Modal id="access-endorsements-delete-modal" className="modal-sm" onHide={onHide} show={show}>
      <ModalHeader />
      <ModalBody>
        <p className="text-center">
          Are you sure you want to delete all the access endorsements for <b>{followee?.name}</b>
          &apos;s information?
        </p>
        <div className="actions">
          <button type="button" className="btn btn-black" onClick={onHide}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={onDeleteHandler}>
            Delete
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

AccessEndorsementsDeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AccessEndorsementsDeleteModal;
